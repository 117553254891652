import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Popup
} from "semantic-ui-react";
import { isEmptyValue } from "../../../../utils/tasksUtils";
import styles from "./Sidebar.module.css";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import GeneralInformationSection from "./components/GeneralInformationSection";
import AssignNewWorkersForm from "./components/AssignNewWorkersForm";
import { get, isEmpty } from "lodash";
import { updateLaborGroup } from "../../../../actions/LaborGroups/laborGroups";
import { RestoreIcon } from "../../../../assets/icons/RestoreIcon";
import { employeeOptionsSelector } from "../../../../selectors/employee";

const DataRow = ({ name, value }) => (
  <Grid.Row>
    <Grid.Column width={5}>{name}</Grid.Column>
    <Grid.Column width={11}>{isEmptyValue(value)}</Grid.Column>
  </Grid.Row>
);

DataRow.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string
};

const LaborGroupSidebar = ({
  update,
  isArchive,
  group,
  onClose,
  Can,
  onEditClick,
  onDeleteItem
}) => {
  const [assignNewWorkersShown, setAssignNewWorkersShown] = useState(false);
  const dispatch = useDispatch();
  const employees = useSelector(employeeOptionsSelector);

  const validateValues = async values => {
    const errors = {};
    const { employees } = values;

    if (!get(values, "employees")) {
      errors["employees"] = "Required field";
    }

    if (!employees.length) {
      errors.employees = true;
    }

    return errors;
  };

  const employeeIds = group?.employeeIds && group.employeeIds;

  function onSubmit(values) {
    dispatch(
      updateLaborGroup(group.id, {
        name: group.name,
        employeeIds: values.employees
      })
    )
      .then(setAssignNewWorkersShown(false))
      .then(update);
  }

  useEffect(() => {
    if (!group) {
      setAssignNewWorkersShown(false);
    }
  }, [group]);

  if (isEmpty(group)) {
    onClose();
  }

  useEffect(() => {
    if (assignNewWorkersShown) {
      const container = document.querySelector("#labor_group_scroll_container");
      container.scrollTop = container.scrollHeight;
    }
  }, [assignNewWorkersShown]);

  return (
    !!group && (
      <Container fluid className={styles.root}>
        <Grid className={styles.sidebarHeader}>
          <Grid.Row columns={"equal"} verticalAlign="middle">
            <Grid.Column className={styles.headerColumn}>
              <Header className={styles.name} as="h1">
                {group.name}
              </Header>
            </Grid.Column>
            <Can I="update" a="groups">
              <Grid.Column floated="right">
                {isArchive ? (
                  <Popup
                    inverted
                    content={"Restore"}
                    position="bottom center"
                    size="tiny"
                    trigger={
                      <Button
                        onClick={e => onDeleteItem(e, group.id, "Restore")}
                        className={styles.deleteButton}
                        floated="right"
                        icon={<RestoreIcon />}
                        size="small"
                      />
                    }
                  />
                ) : (
                  <Popup
                    inverted
                    content={"Edit"}
                    position="bottom center"
                    size="tiny"
                    trigger={
                      <Button
                        onClick={onEditClick}
                        className={styles.editButton}
                        floated="right"
                        icon={<Icon name="edit" />}
                        size="small"
                      />
                    }
                  />
                )}
              </Grid.Column>
            </Can>
            <Grid.Column width={1} floated="right">
              <Popup
                inverted
                content={"Close"}
                position="bottom center"
                size="tiny"
                trigger={
                  <Button
                    onClick={onClose}
                    className={styles.closeButton}
                    floated="right"
                    icon={<Icon className="tuf-times" />}
                    size="small"
                  />
                }
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid
          className={styles.infoContainer}
          id={"labor_group_scroll_container"}
        >
          <GeneralInformationSection group={group} employeesList={employees} />
          {!assignNewWorkersShown && !isArchive && (
            <Container fluid className={styles.textButtonContainer}>
              <Button
                basic
                type="button"
                onClick={() => setAssignNewWorkersShown(true)}
                className={styles.textButton}
                disabled={assignNewWorkersShown}
              >
                <Icon name="plus" />
                Assign new workers
              </Button>
            </Container>
          )}
          {assignNewWorkersShown && (
            <Grid.Row columns={1} className={styles.assignWorkersContainer}>
              <Grid.Column className={styles.assignWorkersColumn}>
                <Formik
                  validateOnChange={true}
                  validate={validateValues}
                  enableReinitialize
                  initialValues={
                    employeeIds ? { employees: employeeIds } : { employees: [] }
                  }
                  onSubmit={onSubmit}
                >
                  <AssignNewWorkersForm
                    groupId={group.id}
                    onCancel={() => setAssignNewWorkersShown(false)}
                    shown={assignNewWorkersShown}
                  />
                </Formik>
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
        <div className={styles.sidebarFooter}>
          <Button
            basic
            size="small"
            className={styles.actionButton}
            onClick={e =>
              onDeleteItem(e, group.id, !isArchive ? "Archive" : "Delete")
            }
            color={isArchive ? "red" : "green"}
          >
            {isArchive ? (
              <span style={{ display: "flex" }}>
                <Icon name="trash alternate" /> Delete
              </span>
            ) : (
              <span>
                {" "}
                <Icon name="archive" /> Archive
              </span>
            )}
          </Button>
        </div>
      </Container>
    )
  );
};

LaborGroupSidebar.propTypes = {
  group: PropTypes.object
};

export default LaborGroupSidebar;
