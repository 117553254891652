import React from "react";
import filterTypes from "components/Table/filterTypes";
import BasicCell from "components/Table/components/BasicCell";
import {
  activeIngredientsSelector,
  chemicalTypeOptionsSelector,
  matterOptionsSelector,
  trackInventoryOptionsSelector,
  typeOptionsSelector
} from "selectors/chemicals";
import { get, join, map } from "lodash";
import {
  fetchActiveIngredients,
  fetchFilterValues
} from "../actions/Chemicals";
import { uppercaseFirstLetter } from "./string";
import { matterToUnits } from "./constToUnits";
import Numeric from "components/Numeric/Numeric";

export const getChemicalsTableColumns = () => {
  const columnsConfig = {
    name: {
      withSort: true,
      type: "string",
      id: "name",
      title: "Product",
      cellWidth: 2 // of 16, see Semantic Table
    },
    type: {
      title: "Product type",
      type: "string",
      withSort: true,
      id: "type",
      filterId: "type",
      accessor: ({ type }) => get(type, "type", ""),
      filter: {
        type: filterTypes.MultiSelect,
        selector: typeOptionsSelector,
        loaderAction: fetchFilterValues
      }
    },
    trackInventory: {
      withSort: true,
      id: "trackInventory",
      type: "string",
      filterId: "trackInventory",
      title: "Track inventory",
      accessor: ({ trackInventory }) => (trackInventory ? "Yes" : "No"),
      filter: {
        type: filterTypes.SingleSelect,
        selector: trackInventoryOptionsSelector
      }
    },
    matter: {
      withSort: true,
      id: "matter",
      type: "string",
      filterId: "matter",
      title: "Matter",
      accessor: ({ matter }) => uppercaseFirstLetter(matter),
      filter: {
        type: filterTypes.SingleSelect,
        selector: matterOptionsSelector
      }
    },
    activeIngredient: {
      withSort: false,
      id: "activeIngredient",
      type: "string",
      filterId: "activeIngredient",
      title: "Active ingredients",
      accessor: data =>
        join(
          map(
            get(data, "activeIngredients"),
            ({ activeIngredient }) => activeIngredient
          ),
          ", "
        ),
      disableSortBy: true,
      filter: {
        type: filterTypes.MultiSelect,
        selector: activeIngredientsSelector,
        withAsyncSearch: true,
        loaderAction: fetchActiveIngredients
      }
    },
    expectedInventory: {
      withSort: true,
      title: "Expected inventory",
      type: "number",
      accessor: ({ expectedInventory, matter }) => {
        return expectedInventory ? (
          <>
            <Numeric
              fractionDigits={expectedInventory > 1000 ? 0 : 2}
              value={expectedInventory ? expectedInventory : "—"}
              commaSeparatorOnThousands
            />
            {matterToUnits(matter)}
          </>
        ) : (
          "-"
        );
      },
      id: "expectedInventory"
    },
    price: {
      withSort: true,
      id: "price",
      type: "number",
      title: "Price ($/unit)",
      accessor: ({ price, matter, inventoryAvgCost, trackInventory }) => {
        return (
          <>
            {!!inventoryAvgCost ? "$" : ""}
            <Numeric
              fractionDigits={
                (trackInventory ? inventoryAvgCost : price) > 1000 ? 0 : 2
              }
              value={trackInventory ? inventoryAvgCost : price}
              commaSeparatorOnThousands
              units={`/${matterToUnits(matter)} `}
              unitsPosition="right"
            />
          </>
        );
      }
    }
  };

  return Object.entries(columnsConfig).map(([id, targetColumn]) => ({
    id,
    ...targetColumn,
    accessor: targetColumn.accessor || id,
    Header: targetColumn.title,
    Cell: targetColumn.Cell || BasicCell,
    ...(targetColumn.sortFields ? { sortFields: targetColumn.sortFields } : {}),
    ...(targetColumn.filter ? { filter: targetColumn.filter } : {})
  }));
};
