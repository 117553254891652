import { DEFAULT_LOCATION } from "../Dashboard";
import { SCOUTING_TABLE_COLUMNS } from "./tableColumnsConfig";

export const actionType = {
  SET_SCOUTING_DATA: "SCOUTING/SET_SCOUTING_DATA",
  FETCH_SCOUTING_START: "SCOUTING/FETCH_SCOUTING_START",
  FETCH_SCOUTING_FAIL: "SCOUTING/FETCH_SCOUTING_FAIL",

  FETCH_SCOUTING_FILTER: "SCOUTING/FETCH_SCOUTING_FILTER",
  FETCH_SCOUTING_FILTER_START: "SCOUTING/FETCH_SCOUTING_FILTER_START",
  FETCH_SCOUTING_FILTER_FAIL: "SCOUTING/FETCH_SCOUTING_FILTER_FAIL",

  SET_CURRENT_SCOUTING: "SCOUTING/SET_CURRENT_SCOUTING",
  FETCH_SCOUTING_BY_ID_START: "SCOUTING/FETCH_SCOUTING_BY_ID_START",
  FETCH_SCOUTING_BY_ID_FAIL: "SCOUTING/FETCH_SCOUTING_BY_ID_FAIL",

  UPDATE_SCOUTING_START: "SCOUTING/UPDATE_SCOUTING_START",
  UPDATE_SCOUTING: "SCOUTING/UPDATE_SCOUTING",
  UPDATE_SCOUTING_FAIL: "SCOUTING/UPDATE_SCOUTING_FAIL",

  ARCHIVE_SCOUTING_START: "SCOUTING/ARCHIVE_SCOUTING_START",
  ARCHIVE_SCOUTING: "SCOUTING/ARCHIVE_SCOUTING",
  ARCHIVE_SCOUTING_FAIL: "SCOUTING/ARCHIVE_SCOUTING_FAIL",

  UNARCHIVE_SCOUTING_START: "SCOUTING/UNARCHIVE_SCOUTING_START",
  UNARCHIVE_SCOUTING_FAIL: "SCOUTING/UNARCHIVE_SCOUTING_FAIL",

  UPLOAD_SCOUTING_ATTACHMENTS_START:
    "SCOUTING/UPLOAD_SCOUTING_ATTACHMENTS_START",
  UPLOAD_SCOUTING_ATTACHMENTS: "SCOUTING/UPLOAD_SCOUTING_ATTACHMENTS",
  UPLOAD_SCOUTING_ATTACHMENTS_ERROR:
    "SCOUTING/UPLOAD_SCOUTING_ATTACHMENTS_ERROR",

  PRINT_SCOUTING_START: "SCOUTING/PRINT_SCOUTING_START",
  PRINT_SCOUTING_END: "SCOUTING/PRINT_SCOUTING_END",

  DELETE_ATTACHMENT: "SCOUTING/DELETE_ATTACHMENT",
  EDIT_SCOUTING_ATTACHMENT: "SCOUTING/EDIT_SCOUTING_ATTACHMENT",
  EDIT_SCOUTING: "SCOUTING/EDIT_SCOUTING",

  CLEAR_ERROR: "SCOUTING/CLEAR_ERROR"
};

export { DEFAULT_LOCATION, SCOUTING_TABLE_COLUMNS };
