import { createSelector } from "reselect";
import { get } from "lodash";

const tableDataSelector = state =>
  state.reports.harvest.fieldScanning.harvestByArea.tableData;
const tableListDataSelector = state =>
  state.reports.harvest.fieldScanning.harvestByArea.listData;
const areasListSelector = state => state.areas.list.content;

export const formaterData = (data, areasList) => {
  if (areasList.length) {
    const { content, ...rest } = data;
    const contentUpdated = content
      .map(block => {
        const areaData = areasList.find(
          ({ id }) => id === get(block, "areaId", "")
        );
        return {
          areaName: get(areaData, "name", "-"),
          size: get(areaData, "size", "-"),
          firstBarcode: block.firstBarcode,
          lastBarcode: block.lastBarcode,
          withholdingPeriod: block.withholdingPeriod,
          withPatches: !!block.children,
          ...(block.children
            ? block.children.reduce(
                (prevPatch, current, index, childrens) => {
                  const weightInTonne =
                    prevPatch.weight +
                    current.items.reduce(
                      (prev, { binWeight, count }) =>
                        prev + (binWeight * count) / 1000,
                      0
                    );

                  const blockCost =
                    prevPatch.cost +
                    current.items.reduce((prev, curr) => prev + curr.cost, 0);
                  const blockBinUnitsNumber =
                    prevPatch.binUnitsNumber +
                    current.items.reduce((prev, curr) => prev + curr.count, 0);
                  const areaData = areasList.find(
                    ({ id }) => id === current.areaId
                  );

                  const { areaVarietyId } = current.items[0];
                  const variety =
                    areaVarietyId &&
                    areaData.varieties.find(({ id }) => id === areaVarietyId);

                  return {
                    ...prevPatch,
                    ...(variety && {
                      varieties: prevPatch.varieties.includes(
                        variety.variety.name
                      )
                        ? [...prevPatch.varieties]
                        : [...prevPatch.varieties, variety.variety.name],

                      numberOfPlants: childrens.reduce(
                        (prev, children, index, arr) => {
                          const areaData = areasList.find(
                            ({ id }) => id === children.areaId
                          );
                          const { areaVarietyId } = children.items[0];
                          const variety =
                            areaVarietyId &&
                            areaData.varieties.find(
                              ({ id }) => id === areaVarietyId
                            );
                          if (index !== arr.length - 1) {
                            return {
                              numberOfPlants:
                                prev.numberOfPlants +
                                ((variety && variety.numberOfPlants) || 0)
                            };
                          }
                          return (
                            prev.numberOfPlants +
                            ((variety && variety.numberOfPlants) || 0)
                          );
                        },
                        { numberOfPlants: 0 }
                      ),

                      crops: prevPatch.crops.includes(variety.variety.crop.name)
                        ? [...prevPatch.crops]
                        : [...prevPatch.crops, variety.variety.crop.name],
                      avgKgPerTree: childrens.reduce(
                        (prev, children, index, arr) => {
                          const areaData = areasList.find(
                            ({ id }) => id === children.areaId
                          );
                          const { areaVarietyId } = children.items[0];
                          const variety =
                            areaVarietyId &&
                            areaData.varieties.find(
                              ({ id }) => id === areaVarietyId
                            );
                          const childrenWeight = children.items.reduce(
                            (prev, { binWeight, count }) =>
                              prev + binWeight * count,
                            0
                          );
                          if (index !== arr.length - 1) {
                            return {
                              weightSum: prev.weightSum + childrenWeight,
                              numberOfPlantsSum:
                                prev.numberOfPlantsSum +
                                ((variety && variety.numberOfPlants) || 0)
                            };
                          }
                          return (
                            (prev.weightSum + childrenWeight) /
                            (prev.numberOfPlantsSum +
                              ((variety && variety.numberOfPlants) || 0))
                          );
                        },
                        { weightSum: 0, numberOfPlantsSum: 0 }
                      )
                    }),
                    size: prevPatch.size + areaData.size,
                    weight: weightInTonne,
                    tonnePerHa:
                      weightInTonne / (prevPatch.size + areaData.size),
                    cost: blockCost,
                    costPerTonne: blockCost / weightInTonne,
                    costPerBin: blockCost / blockBinUnitsNumber,
                    binUnits: current.items.reduce((prev, curr) => {
                      const binUnit = `${curr.unitName}`;
                      return prev.includes(binUnit) ? prev : [...prev, binUnit];
                    }, prevPatch.binUnits),

                    binUnitsNumber: blockBinUnitsNumber,

                    subRows: [
                      ...(prevPatch && prevPatch.subRows),
                      current.items.reduce(
                        (prev, curr, _, items) => {
                          const lastItem = items[items.length - 1];
                          const variety =
                            lastItem.areaVarietyId &&
                            areaData.varieties.find(
                              ({ id }) => id === lastItem.areaVarietyId
                            );
                          const patchCost = prev.cost + curr.cost;
                          const patchBinUnitsNumber =
                            prev.binUnitsNumber + curr.count;
                          const weightInTonne =
                            (curr.binWeight * curr.count) / 1000;
                          const weight = prev.weight + weightInTonne;
                          return {
                            ...prev,
                            areaName: areaData.name,
                            size: areaData.size,
                            firstBarcode: current.firstBarcode,
                            lastBarcode: current.lastBarcode,
                            withholdingPeriod: current.withholdingPeriod,
                            binUnitsNumber: patchBinUnitsNumber,
                            cost: patchCost,
                            weight,
                            tonnePerHa: weight / areaData.size,
                            costPerTonne: patchCost / weight,
                            costPerBin: patchCost / patchBinUnitsNumber,
                            binUnits: prev.binUnits.includes(`${curr.unitName}`)
                              ? prev.binUnits
                              : [...prev.binUnits, `${curr.unitName}`],
                            ...(variety && {
                              varieties: [variety.variety.name],
                              crops: [variety.variety.crop.name],
                              numberOfPlants: variety.numberOfPlants,
                              avgKgPerTree: variety.numberOfPlants
                                ? (weight * 1000) / variety.numberOfPlants
                                : 0
                            }),
                            isSubRows: true,
                            subRows: current.items.map(
                              ({
                                count,
                                binWeight,
                                unitName,
                                cost,
                                firstBarcode,
                                lastBarcode
                              }) => ({
                                firstBarcode,
                                lastBarcode,
                                binUnitsNumber: count,
                                weight: (binWeight * count) / 1000,
                                binUnits: `${unitName} ${binWeight} Kg`,
                                tonnePerHa:
                                  (binWeight * count) / 1000 / areaData.size,
                                cost,
                                costPerTonne:
                                  cost / ((binWeight * count) / 1000),
                                costPerBin: cost / count
                              })
                            )
                          };
                        },
                        {
                          binUnitsNumber: 0,
                          weight: 0,
                          cost: 0,
                          costPerTonne: 0,
                          tonnePerHa: 0,
                          numberOfPlants: 0,
                          isSubRows: true,
                          costPerBin: 0,
                          binUnits: [],
                          varieties: [],
                          crops: [],
                          subRows: [],
                          avgKgPerTree: 0
                        }
                      )
                    ]
                  };
                },
                {
                  size: 0,
                  binUnits: [],
                  binUnitsNumber: 0,
                  weight: 0,
                  cost: 0,
                  costPerTonne: 0,
                  tonnePerHa: 0,
                  isSubRows: true,
                  numberOfPlants: 0,
                  costPerBin: 0,
                  avgKgPerTree: 0,
                  varieties: [],
                  crops: [],
                  subRows: []
                }
              )
            : block.items.reduce(
                (prev, curr, _, items) => {
                  const lastItem = items[items.length - 1];
                  const variety =
                    lastItem.varietyId &&
                    areaData.varieties.find(
                      ({ variety }) => variety.id === lastItem.varietyId
                    );
                  const weightInTonne = (curr.binWeight * curr.count) / 1000;
                  const weight = prev.weight + weightInTonne;
                  const numberOfPlants =
                    lastItem.areaVarietyId &&
                    variety.id === lastItem.areaVarietyId &&
                    variety.numberOfPlants;
                  return {
                    ...prev,
                    binUnitsNumber: prev.binUnitsNumber + curr.count,
                    weight,
                    tonnePerHa: prev.tonnePerHa + weightInTonne / areaData.size,
                    numberOfPlants,
                    cost: prev.cost + curr.cost,
                    costPerTonne: curr.cost / weightInTonne,
                    costPerBin: curr.cost / curr.count,
                    binUnits: prev.binUnits.includes(`${curr.unitName}`)
                      ? prev.binUnits
                      : [...prev.binUnits, `${curr.unitName}`],
                    ...(variety && {
                      varieties: [variety.variety.name],
                      crops: [variety.variety.crop.name],
                      numberOfPlants,
                      avgKgPerTree:
                        numberOfPlants && (weight * 1000) / numberOfPlants
                    }),
                    subRows: [
                      ...prev.subRows,
                      {
                        firstBarcode: curr.firstBarcode,
                        lastBarcode: curr.lastBarcode,
                        binUnitsNumber: curr.count,
                        weight: weightInTonne,
                        binUnits: `${curr.unitName}`,
                        tonnePerHa: weightInTonne / areaData.size,
                        cost: curr.cost,
                        costPerTonne: curr.cost / weightInTonne,
                        costPerBin: curr.cost / curr.count
                      }
                    ]
                  };
                },
                {
                  binUnitsNumber: 0,
                  weight: 0,
                  tonnePerHa: 0,
                  numberOfPlants: 0,
                  cost: 0,
                  costPerTonne: 0,
                  costPerBin: 0,
                  binUnits: [],
                  avgKgPerTree: 0,
                  varieties: [],
                  crops: [],
                  subRows: []
                }
              ))
        };
      }, {})
      .map(({ subRows, withPatches, ...rest }) => ({
        ...rest,
        ...(withPatches
          ? {
              subRows: subRows.map(({ subRows, ...rest }) =>
                subRows.length > 1 ? { ...rest, subRows } : rest
              )
            }
          : subRows.length > 1 && { subRows })
      }));

    return {
      ...rest,
      content: contentUpdated
    };
  }
  return {
    content: []
  };
};

export const selectTableStructuredData = createSelector(
  [tableDataSelector, areasListSelector],
  (tableData, areasList) => {
    return formaterData(tableData, areasList);
  }
);

export const selectExpotStructuredData = createSelector(
  [tableListDataSelector, areasListSelector],
  (listData, areasList) => {
    return formaterData(listData, areasList);
  }
);
