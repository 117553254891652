import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "react-table-6/react-table.css";
import { bindActionCreators } from "redux";
import { abilitiesSelector } from "../../../selectors/user";
import "../../../components/LocalDataTable/LocalDataTable.module.css";
import {
  fetchEmployees,
  deleteEmployee,
  employeesStatusChange,
  deleteEmployees,
  fetchEmployeesTypes
} from "../../../actions/Employee/employees";
import { fetchContractorsList } from "../../../actions/Contractors/contractors";
import { fetchLaborGroupsList } from "../../../actions/LaborGroups/laborGroups";
import { Button } from "semantic-ui-react";
import TablePageHolder from "../../../components/TablePageHolder/TablePageHolder";
import { getHashParameter } from "../../../utils/hashToObject";
import TableActionButtons from "../components/TableActionButtons/TableActionButtons";
import { keyBy, startCase, camelCase } from "lodash";
import Sidebar from "./Sidebar/Sidebar";
import AddUpdateEmployeeModal from "./AddUpdateEmployeeModal/AddUpdateEmployeeModal";
import filterTypes from "../../../components/Table/filterTypes";
import {
  contractorNamesSelector,
  employeesTypesSelector,
  groupNamesSelector
} from "../../../selectors/employee";
import { userBERolesSelector } from "selectors/user";
import { sortingDifferenceNames } from "./constants";
import { ActionMessage } from "../components/ActionMessage/ActionMessage";
import ConfirmationModal from "../components/ConfirmationModal/ConfirmationModal";
import BasicCell from "components/Table/components/BasicCell";
import styles from "./Employees.module.css";
import { DeletedMessage } from "../components/ActionMessage/DeletedMessage";
import Numeric from "components/Numeric/Numeric";

class Employees extends Component {
  state = {
    tableData: [],
    tableColumns: [],
    sideBarShown: false,
    activeItem: {},
    activeItemId: null,
    archiveItemId: null,
    addEditModalOpen: false,
    itemId: null,
    selectedItems: [],
    tempArchivedItems: [],
    tempDeletedItems: [],
    actionType: "",
    dataRefetch: 1,
    confirmationModalOpen: false,
    editSectionIndex: null
  };

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { data } = this.props;
    if (prevProps.data !== data) this.prepareData();
  }

  getData = params => {
    const { isArchivedView, actions } = this.props;
    this.setSelectedItems([]);
    actions.fetchAction({ ...params, archived: isArchivedView });
  };

  prepareData = () => {
    const { data, actions, Can } = this.props;
    const columnsNames = {
      firstName: {
        withSort: true,
        type: "string",
        id: "firstName",
        title: "Name",
        cellWidth: 2,
        accessor: row => row.firstName + " " + row.lastName
      },
      types: {
        title: "Role",
        withSort: true,
        type: "string",
        id: "types",
        filterId: "types",
        cellWidth: 2,
        accessor: row => startCase(camelCase(row.type)),
        filter: {
          text: "Role",
          type: filterTypes.MultiSelect,
          selector: employeesTypesSelector,
          loaderAction: actions.fetchEmployeesTypes
        }
      },
      groupIds: {
        title: "Group",
        withSort: true,
        type: "string",
        id: "groupIds",
        filterId: "groupIds",
        cellWidth: 2,
        accessor: row => (row.employeeGroup ? row.employeeGroup.name : "—"),
        filter: {
          text: "Group",
          type: filterTypes.MultiSelect,
          selector: groupNamesSelector,
          loaderAction: actions.fetchLaborGroupsList
        }
      },
      contractorIds: {
        title: "Contractor",
        withSort: true,
        type: "string",
        id: "contractorIds",
        filterId: "contractorIds",
        cellWidth: 2,
        accessor: row => (row.contractor ? row.contractor.name : "—"),
        filter: {
          text: "Contractor",
          type: filterTypes.MultiSelect,
          selector: contractorNamesSelector,
          loaderAction: actions.fetchContractorsList
        }
      },
      email: {
        title: "Email",
        withSort: true,
        type: "string",
        id: "email",
        cellWidth: 2
      },
      ratePerHour: {
        title: (
          <Can I={"rate_manage"} a="employees">
            Rate($/hr)
          </Can>
        ),
        withSort: true,
        id: "ratePerHour",
        type: "number",
        cellWidth: 2,
        accessor: row => (
          <Can I="rate_manage" a="employees">
            <>
              {" "}
              <Numeric
                fractionDigits={Number(row.ratePerHour) > 1000 ? 0 : 2}
                value={Number(row.ratePerHour)}
                commaSeparatorOnThousands
                units="$ "
                unitsPosition="left"
                defaultValue="-"
              />
              /hr
              {/* {row.ratePerHour
                ? `$${Number(row.ratePerHour).toFixed(2)}/hr`
                : "—"} */}
            </>
          </Can>
        )
      },
      notes: {
        title: "Notes",
        cellWidth: 2.5,
        accessor: row =>
          <div className={styles.notesWrapper}>{row.notes}</div> || "—",
        disableSortBy: true
      },
      actions: {
        title: "",
        cellWidth: 1.5,
        className: styles.actionCells,
        accessor: row => row.id,
        disableSortBy: true
      }
    };
    const tableColumns = [];
    const tableData = [];

    Object.entries(columnsNames).map(([id, targetColumn]) => {
      const columnToAdd = {
        id,
        ...targetColumn,
        accessor: targetColumn.accessor || id,
        Header: targetColumn.title,
        Cell: targetColumn.Cell || BasicCell,
        className: `cell_${targetColumn.cellWidth} ${targetColumn.className ||
          ""}`,
        ...(targetColumn.sortFields
          ? { sortFields: targetColumn.sortFields }
          : {}),
        disableSortBy: targetColumn.disableSortBy,
        ...(targetColumn.filter ? { filter: targetColumn.filter } : {}),
        cellWidth: targetColumn.cellWidth
      };

      tableColumns.push(columnToAdd);
    });

    if (data && data.content) {
      data.content.forEach(row => {
        const rowData = {
          rights: {
            update: "update",
            delete: "delete"
          }
        };
        Object.keys(row).forEach(key => {
          switch (key) {
            default:
              rowData[key] = row[key] || "";
              break;
          }
        });
        tableData.push(rowData);
      });
    }

    this.setState({
      tableData,
      tableColumns
    });
  };

  updateTable = () => {
    this.setState({ dataRefetch: this.state.dataRefetch + 1 });
  };

  setCreateUpdateModalShown = (e, value, editSectionIndex) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState(prevState => ({
      addEditModalOpen: true,
      itemId: !!value && value,
      editSectionIndex
    }));
  };

  setCreateUpdateModalHidden = () => {
    this.setState({
      addEditModalOpen: false
    });
  };

  setSelectedItems = items => {
    this.setState({ selectedItems: [...items] });
  };

  selectedEmployees = () => {
    const { selectedItems } = this.state;
    const idsMap = keyBy(selectedItems);

    return this.state.tableData.filter((_, index) => !!idsMap[index]);
  };

  onArchiveItems = () => {
    const { selectedItems } = this.state;
    const { isArchivedView, actions } = this.props;
    const idsMap = keyBy(selectedItems);

    const itemsIds = this.state.tableData
      .filter((_, index) => !!idsMap[index])
      .map(({ id }) => id);
    this.setSelectedItems([]);
    this.setState({
      tempArchivedItems: itemsIds
    });
    actions.archiveAction(!isArchivedView, itemsIds).then(this.updateTable);
  };

  actionMessageUndo = () => {
    const { tempArchivedItems } = this.state;
    const { isArchivedView, actions } = this.props;
    actions
      .archiveAction(isArchivedView, [tempArchivedItems])
      .then(this.updateTable);
    this.setState({
      tempArchivedItems: []
    });
  };

  onDeleteItems = () => {
    const { selectedItems } = this.state;
    const { actions, error } = this.props;
    const idsMap = keyBy(selectedItems);

    const itemsIds = this.state.tableData
      .filter((_, index) => !!idsMap[index])
      .map(({ id }) => id);
    this.setSelectedItems([]);
    actions
      .deleteEmployees(itemsIds)
      .then(() => {
        if (!error) {
          this.setState({
            tempDeletedItems: itemsIds
          });
        }
      })
      .then(this.updateTable);
  };

  onChangeItem = () => {
    const { archiveItemId } = this.state;
    const { isArchivedView, actions, error } = this.props;
    if (this.state.actionType === "Delete") {
      actions
        .deleteAction(archiveItemId)
        .then(() => {
          if (!error) {
            this.setState({
              tempDeletedItems: [archiveItemId]
            });
          }
          this.setState({
            confirmationModalOpen: false,
            activeItemId: null
          });
        })
        .then(this.updateTable);
    } else {
      actions
        .archiveAction(!isArchivedView, [archiveItemId])
        .then(() => {
          this.setState({
            tempArchivedItems: [archiveItemId]
          });
          this.setState({
            confirmationModalOpen: false
          });
        })
        .then(this.updateTable);
    }
  };

  onArchiveItemClick = (e, value, actionType) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      confirmationModalOpen: true,
      archiveItemId: value && value,
      actionType
    });
  };

  onConfirmationModalClose = () => {
    this.setState({
      confirmationModalOpen: false
    });
  };

  renderCell = props => {
    const {
      value,
      column: { Header }
    } = props;

    return (
      <div>
        <div className="cellTitle hide-md">{Header}</div>
        <div className="cellValue">{value}</div>
      </div>
    );
  };

  onRowClick = (e, row) => {
    if (row) {
      this.setState(prevState => ({
        ...prevState,
        activeItem: row.original,
        activeItemId: row.original.id
      }));
    }
  };

  clearActiveItemId = () => {
    this.setState(prevState => ({
      ...prevState,
      activeItemId: null,
      activeItem: {}
    }));
  };

  hasPermissions = row => {
    const { bePermissions } = this.props;
    const role = row.original.type.toLowerCase();
    const ourPermissions = bePermissions.filter(el => el.includes("manage"));

    return ourPermissions.includes(`employees:manage_${role}`);
  };

  render() {
    const {
      route,
      location,
      content,
      currentPage,
      totalPages,
      isFetching,
      Can,
      totalElements,
      isArchivedView,
      error
    } = this.props;
    const {
      activeItemId,
      activeItem,
      tableData,
      actionType,
      itemId,
      updateTable,
      archiveItemId,
      confirmationModalOpen,
      tableColumns,
      selectedItems,
      addEditModalOpen,
      tempDeletedItems,
      tempArchivedItems
    } = this.state;

    const expandedContent =
      activeItemId && content.find(e => e.id === activeItemId);
    const itemToEdit =
      itemId && content.find(employee => employee.id === itemId);
    const itemToArchive =
      archiveItemId && content.find(employee => employee.id === archiveItemId);
    const defaultSort = getHashParameter(location, "sort") || "";

    return (
      <>
        <AddUpdateEmployeeModal
          Can={Can}
          open={addEditModalOpen}
          onClose={this.setCreateUpdateModalHidden}
          item={itemToEdit}
          itemId={activeItemId && activeItemId}
          update={updateTable}
          editSectionIndex={this.state.editSectionIndex}
        />
        <ConfirmationModal
          open={confirmationModalOpen}
          selectedItem={itemToArchive}
          actionType={actionType}
          onChangeItem={this.onChangeItem}
          onClose={this.onConfirmationModalClose}
        />
        <TablePageHolder
          actionsButtons={
            selectedItems.length ? (
              <TableActionButtons
                isArchive={isArchivedView}
                onDeleteButtonClick={this.onDeleteItems}
                onArchiveButtonClick={this.onArchiveItems}
                selectedItems={this.selectedEmployees}
                actionsLocation="employees"
              />
            ) : null
          }
          wideTableClass={styles.scrollableTableWidth}
          sortingDifferenceNames={sortingDifferenceNames}
          defaultSort={defaultSort}
          totalPages={totalPages}
          currentPage={currentPage}
          totalElements={totalElements}
          getData={this.getData}
          isFetching={isFetching}
          location={location}
          mainButton={
            !isArchivedView && (
              <Can I="add" a="employees">
                <Button
                  primary
                  className={styles.addButton}
                  onClick={e => this.setCreateUpdateModalShown(e)}
                >
                  Add
                </Button>
              </Can>
            )
          }
          onRowClick={this.onRowClick}
          onSidebarHidden={() => this.clearActiveItemId()}
          sidebarShown={!!activeItemId}
          pageTitle={route.name}
          route={route}
          selectedItems={selectedItems}
          setSelectedItems={this.setSelectedItems}
          sidebarComponent={
            <Sidebar
              isArchive={isArchivedView}
              id={expandedContent && expandedContent}
              employee={expandedContent}
              Can={Can}
              hasPermissionsToEdit={this.hasPermissions}
              onDeleteItem={this.onArchiveItemClick}
              onClose={this.clearActiveItemId}
              onEditClick={this.setCreateUpdateModalShown}
            />
          }
          Can={Can}
          accessName="employees"
          tableColumns={tableColumns}
          tableData={tableData}
          updateAction="update"
          onUpdateModalOpen={this.setCreateUpdateModalShown}
          onArchiveItem={this.onArchiveItemClick}
          isArchivedView={isArchivedView}
          dataRefetch={this.state.dataRefetch}
          hasPermissions={this.hasPermissions}
          withoutSort={false}
          firstColumnFixed={false}
          fixed={false}
        />
        {!this.props.error && (
          <DeletedMessage
            items={tempDeletedItems}
            onClose={() => this.setState({ tempDeletedItems: [] })}
            locationName="Employee"
          />
        )}
        {!!tempArchivedItems.length && (
          <ActionMessage
            items={tempArchivedItems}
            onClose={() => this.setState({ tempArchivedItems: [] })}
            onUndo={this.actionMessageUndo}
            locationName="Employee"
            isArchivedView={isArchivedView}
          />
        )}
      </>
    );
  }
}

Employees.propTypes = {
  content: PropTypes.array.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  totalElements: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  isFetching: PropTypes.bool.isRequired,
  lastLoaded: PropTypes.bool,
  data: PropTypes.object,
  actions: PropTypes.object,
  error: PropTypes.object,
  route: PropTypes.object,
  location: PropTypes.object,
  Can: PropTypes.func,
  online: PropTypes.bool
};

const mapStateToProps = (state, props) => {
  const isArchivedView = props.location.pathname.includes("archived");

  const {
    employee: {
      data,
      data: {
        content,
        number,
        totalPages,
        totalElements,
        size,
        last,
        isFetching
      },
      error
    },
    offline: { online }
  } = state;

  return {
    isArchivedView,
    data,
    content,
    isFetching,
    currentPage: number,
    totalPages,
    totalElements,
    size,
    lastLoaded: last,
    Can: abilitiesSelector(state),
    bePermissions: userBERolesSelector(state),
    error,
    online
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        fetchAction: fetchEmployees,
        deleteAction: deleteEmployee,
        archiveAction: employeesStatusChange,
        deleteEmployees,
        fetchEmployeesTypes,
        fetchContractorsList,
        fetchLaborGroupsList
      },
      dispatch
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Employees);
