import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Modal, Checkbox, Input, Container } from "semantic-ui-react";
import { useFormikContext } from "formik";
import { fetchCreateTemplateAndTask } from "actions/Recommendations/recommendations";
import styles from "../styles.module.css";

const SaveRecommendationButton = ({ isEditing }) => {
  const {
    handleSubmit,
    isValid,
    dirty,
    values,
    setFieldValue
  } = useFormikContext();
  const [isTemplateModalShown, setIsTemplateModalShown] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [createTemplateChecked, setCreateTemplateChecked] = useState(false);
  const dispatch = useDispatch();

  const onClick = () => {
    if (createTemplateChecked) {
      setIsTemplateModalShown(true);
    } else {
      handleSubmit();
    }
  };

  const onCreateTemplateButtonClick = () => {
    dispatch(fetchCreateTemplateAndTask(values, templateName)).then(
      ({ id }) => {
        setIsTemplateModalShown(false);
        setFieldValue("templateId", id);
        handleSubmit();
      }
    );
  };

  return (
    <>
      <Modal size={"mini"} open={isTemplateModalShown}>
        <Modal.Header>Create a template</Modal.Header>
        <Modal.Content>
          <p>Add template name</p>
          <Input fluid onChange={(_, { value }) => setTemplateName(value)} />
          <Container fluid className={styles.buttonsContainer}>
            <Button color="grey" onClick={() => setIsTemplateModalShown(false)}>
              Cancel
            </Button>
            <Button positive onClick={onCreateTemplateButtonClick}>
              Create
            </Button>
          </Container>
        </Modal.Content>
      </Modal>
      <div className={styles.footerContainer}>
        <Checkbox
          label="Save as a template"
          onChange={() => setCreateTemplateChecked(active => !active)}
          checked={createTemplateChecked}
          className={styles.checkbox}
        />
        <Button
          primary
          size="large"
          type="submit"
          disabled={isEditing || !isValid || !dirty}
          onClick={onClick}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default SaveRecommendationButton;
