import React, { useEffect, useState } from "react";
import { Button, Form } from "semantic-ui-react";
import { Field, Formik, useFormikContext } from "formik";
import { get, map } from "lodash";
import SelectFarm from "./SelectFarmInput";
import styles from "./Farm.module.css";
import PropTypes from "prop-types";

const REQUIRED_FIELDS = ["farm"];

const FarmForm = ({
  farmData,
  onSubmit,
  onCancel,
  farmsListCheck,
  deleteButtonCheck,
  deleteAddingForm
}) => {
  const { values: parentValues } = useFormikContext();
  const [resetRequired, setResetRequired] = useState(false);
  useEffect(() => {
    if (resetRequired) {
      setResetRequired(false);
    }
  }, [resetRequired]);

  if (resetRequired) {
    return null;
  }
  return (
    <Formik
      enableReinitialize
      validate={values => {
        const errors = {};
        REQUIRED_FIELDS.forEach(fieldName => {
          if (!get(values, fieldName)) {
            errors[fieldName] = true;
          }
        });
        return errors;
      }}
      onSubmit={values => {
        onSubmit(values);
        setResetRequired(true);
      }}
      initialValues={farmData || {}}
    >
      {props => (
        <Form onSubmit={props.handleSubmit}>
          <Form.Group>
            <Form.Field fluid width={16} required>
              <label>Farm</label>
              <Field
                as={SelectFarm}
                name="farm"
                required
                value={get(props.values.name, "id")}
                usedProductsIds={map(parentValues.farms, "farm.id")}
                onChange={({ key, text }) => {
                  props.setFieldValue("farm", { id: key, name: text });
                }}
              />
            </Form.Field>
          </Form.Group>
          <div className={styles.buttonContainer}>
            {onCancel && (
              <Button type="button-text button" onClick={onCancel}>
                Cancel
              </Button>
            )}
            {deleteButtonCheck && farmsListCheck ? (
              <Button
                className={styles.cancelBtn}
                type="submit"
                onClick={deleteAddingForm}
              >
                Cancel
              </Button>
            ) : null}
            <Button
              type="submit"
              className={
                !props.dirty || !props.isValid
                  ? styles.disabledBtn
                  : "button-text color-green"
              }
              disabled={!props.dirty || !props.isValid}
            >
              {farmData ? "Edit" : "Save"}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

FarmForm.propTypes = {
  farmData: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func
};

export default FarmForm;
