import { axiosProxy } from "../../utils/axiosProxy";
import { actionType } from "../../constants/Dashboard";
import { toEndDateString, toStartDateString } from "../../utils/dateUtils";

const url = "/dashboard";

export const fetchBinsPerArea = (selectedTaskTypes, from, to) => async (
  dispatch,
  getState
) => {
  const { areas } = getState();

  if (!areas.list.content.length) return;
  try {
    const params = new URLSearchParams({
      unpaged: true,
      generalTaskTypes: selectedTaskTypes
    });

    from && params.append("from", toStartDateString(from));
    to && params.append("to", toEndDateString(to));

    const { data } = await axiosProxy({
      method: "GET",
      params,
      url: `${url}/binsPerArea`
    });

    const binsPerArea = data.content.reduce((prev, { areaId, cost, count }) => {
      const areaData = areas.list.content.find(({ id }) => id === areaId);
      if (prev[areaData.farm.id]) {
        prev[areaData.farm.id] = {
          ...prev[areaData.farm.id],
          bins: prev[areaData.farm.id].bins + count,
          cost: prev[areaData.farm.id].cost + cost,
          blocksStat: {
            ...prev[areaData.farm.id].blocksStat,
            ...(areaData.parent
              ? prev[areaData.farm.id].blocksStat[areaData.parent.id]
                ? {
                    [areaData.parent.id]: {
                      ...prev[areaData.farm.id].blocksStat[areaData.parent.id],
                      bins:
                        prev[areaData.farm.id].blocksStat[areaData.parent.id]
                          .bins + count,
                      cost:
                        prev[areaData.farm.id].blocksStat[areaData.parent.id]
                          .cost + cost,
                      areasStat: {
                        ...prev[areaData.farm.id].blocksStat[areaData.parent.id]
                          .areasStat,
                        [areaData.id]: {
                          areaName: areaData.name,
                          bins: count,
                          cost
                        }
                      }
                    }
                  }
                : {
                    [areaData.parent.id]: {
                      blockName: areaData.parent.name,
                      bins: count,
                      cost,
                      areasStat: {
                        [areaData.id]: {
                          areaName: areaData.name,
                          bins: count,
                          cost
                        }
                      }
                    }
                  }
              : {
                  [areaData.id]: {
                    blockName: areaData.name,
                    bins: count,
                    cost,
                    areasStat: {}
                  }
                })
          }
        };
        return prev;
      }
      return {
        ...prev,
        [areaData.farm.id]: {
          farmName: areaData.farm.name,
          bins: count,
          cost,
          blocksStat: areaData.parent
            ? {
                [areaData.parent.id]: {
                  blockName: areaData.parent.name,
                  bins: count,
                  cost,
                  areasStat: {
                    [areaData.id]: {
                      areaName: areaData.name,
                      bins: count,
                      cost
                    }
                  }
                }
              }
            : {
                [areaData.id]: {
                  blockName: areaData.name,
                  bins: count,
                  cost,
                  areasStat: {}
                }
              }
        }
      };
    }, {});

    await dispatch({
      type: actionType.DASHBOARD_BINS_PER_AREA,
      payload: binsPerArea
    });
  } catch (error) {
    return { error };
  }
};

export const fetchBinsPerAreaEmployee = date => async (dispatch, getState) => {
  const { employee, areas } = await getState();
  if (!employee.list.content.length || !areas.list.content.length) return;

  dispatch({
    type: actionType.DASHBOARD_BINS_PER_AREA_PER_EMPLOYEE_START
  });

  try {
    const params = new URLSearchParams({
      unpaged: true,
      from: toStartDateString(date),
      to: toEndDateString(date)
    });

    const { data } = await axiosProxy({
      method: "GET",
      params,
      url: `${url}/binsPerAreaPerEmployee`
    });
    const { binsPerGroup } = data.content.reduce(
      (prev, { employeeId, areaId, part }) => {
        const employeeData = employee.list.content.find(
          ({ id }) => id === employeeId
        );
        const areaData = areas.list.content.find(({ id }) => id === areaId);
        if (!employeeData.employeeGroup) {
          if (prev.binsPerGroup.employeesWithoutGroup) {
            prev.binsPerGroup.employeesWithoutGroup = {
              ...prev.binsPerGroup.employeesWithoutGroup,
              bins: prev.binsPerGroup.employeesWithoutGroup.bins + part,
              location: [
                ...prev.binsPerGroup.employeesWithoutGroup.location,
                areaData.name
              ],
              employees: {
                ...prev.binsPerGroup.employeesWithoutGroup.employees,
                [employeeData.id]: {
                  id: employeeData.id,
                  name: `${employeeData.firstName} ${employeeData.lastName}`,
                  bins: prev.binsPerGroup.employeesWithoutGroup.employees[
                    employeeData.id
                  ]
                    ? prev.binsPerGroup.employeesWithoutGroup.employees[
                        employeeData.id
                      ].bins + part
                    : part,
                  location: prev.binsPerGroup.employeesWithoutGroup.employees[
                    employeeData.id
                  ]
                    ? prev.binsPerGroup.employeesWithoutGroup.employees[
                        employeeData.id
                      ].location.concat([areaData.name])
                    : [areaData.name]
                }
              }
            };

            return prev;
          }
          return {
            ...prev,
            binsPerGroup: {
              ...prev.binsPerGroup,
              employeesWithoutGroup: {
                groupId: "-",
                groupName: "Workers without group",
                bins: part,
                location: [areaData.name],
                employees: {
                  [employeeData.id]: {
                    id: employeeData.id,
                    name: `${employeeData.firstName} ${employeeData.lastName}`,
                    bins: part,
                    location: [areaData.name]
                  }
                }
              }
            }
          };
        } else {
          if (prev.binsPerGroup[employeeData.employeeGroup.id]) {
            prev.binsPerGroup[employeeData.employeeGroup.id] = {
              ...prev.binsPerGroup[employeeData.employeeGroup.id],
              bins:
                prev.binsPerGroup[employeeData.employeeGroup.id].bins + part,
              location: [
                ...prev.binsPerGroup[employeeData.employeeGroup.id].location,
                areaData.name
              ],
              employees: {
                ...prev.binsPerGroup[employeeData.employeeGroup.id].employees,
                [employeeData.id]: {
                  id: employeeData.id,
                  name: `${employeeData.firstName} ${employeeData.lastName}`,
                  bins: prev.binsPerGroup[employeeData.employeeGroup.id]
                    .employees[employeeData.id]
                    ? prev.binsPerGroup[employeeData.employeeGroup.id]
                        .employees[employeeData.id].bins + part
                    : part,
                  location: prev.binsPerGroup[employeeData.employeeGroup.id]
                    .employees[employeeData.id]
                    ? prev.binsPerGroup[
                        employeeData.employeeGroup.id
                      ].employees[employeeData.id].location.concat([
                        areaData.name
                      ])
                    : [areaData.name]
                }
              }
            };
            return prev;
          }
          return {
            ...prev,
            binsPerGroup: {
              ...prev.binsPerGroup,
              [employeeData.employeeGroup.id]: {
                groupId: employeeData.employeeGroup.id,
                groupName: employeeData.employeeGroup.name,
                bins: part,
                location: [areaData.name],
                employees: {
                  [employeeData.id]: {
                    id: employeeData.id,
                    name: `${employeeData.firstName} ${employeeData.lastName}`,
                    bins: part,
                    location: [areaData.name]
                  }
                }
              }
            }
          };
        }
      },
      {
        binsPerGroup: {},
        binsPerWorker: {}
      }
    );

    await dispatch({
      type: actionType.DASHBOARD_BINS_PER_AREA_PER_EMPLOYEE,
      payload: binsPerGroup
    });
  } catch (error) {
    dispatch({
      type: actionType.DASHBOARD_BINS_PER_AREA_PER_EMPLOYEE_FAIL,
      payload: error
    });
    return { error };
  }
};

export const fetchBinsWeightPerArea = (areaId, yieldDateRange) => async (
  dispatch,
  getState
) => {
  const { start, end } = yieldDateRange;

  try {
    const { areas } = getState();
    const params = new URLSearchParams({
      from: start,
      to: end,
      areaId
    });

    const res = await axiosProxy({
      method: "GET",
      params,
      url: `${url}/binsWeightPerArea`
    });

    const size = areas.list.content.find(a => a.id === areaId).size;

    return {
      count: res.data.count,
      weight: res.data.weight,
      size
    };
  } catch (error) {}
};
