import ReactExport from "react-data-export";
import React from "react";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";

import styles from "./ExportToExcel.module.css";
import ToolTip from "../ToolTip";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExportToExcel = ({
  columns,
  tableRef,
  rawData,
  name,
  className,
  alternativeButton,
  hideElement
}) => {
  const rawDataIsPresent = rawData.length > 0;
  const tableDataIsPresent =
    tableRef.current &&
    tableRef.current.getResolvedState() &&
    tableRef.current.getResolvedState().columns &&
    tableRef.current.getResolvedState().columns.length > 0;

  const sheetData = rawDataIsPresent
    ? rawData
    : tableDataIsPresent
    ? tableRef.current.getResolvedState().sortedData
    : [];

  const tableColumns =
    rawDataIsPresent && !tableDataIsPresent
      ? columns
      : tableDataIsPresent
      ? tableRef.current.getResolvedState().columns
      : [];

  const filteredData = sheetData.map(data =>
    Object.fromEntries(
      Object.entries(data).filter(
        ([, value]) => typeof value === "string" || typeof value === "number"
      )
    )
  );

  return (
    <ExcelFile
      filename={name}
      element={
        <ToolTip content="Export to Excel">
          {alternativeButton ? (
            <Button icon="file excel">Excel</Button>
          ) : (
            <Button
              icon="file excel"
              className={`${styles.exportButton} ${className} transparent`}
            />
          )}
        </ToolTip>
      }
      hideElement={hideElement}
    >
      <ExcelSheet data={filteredData} name={name}>
        {tableColumns.map(column => {
          return (
            <ExcelColumn
              label={column.Header}
              value={column.id}
              key={column.id}
            />
          );
        })}
      </ExcelSheet>
    </ExcelFile>
  );
};

ExportToExcel.propTypes = {
  alternativeButton: PropTypes.bool,
  className: PropTypes.any,
  columns: PropTypes.array,
  name: PropTypes.string,
  rawData: PropTypes.array,
  sheetName: PropTypes.string,
  tableRef: PropTypes.object,
  hideElement: PropTypes.bool
};

ExportToExcel.defaultProps = {
  alternativeButton: false,
  columns: [],
  name: "exported_data",
  rawData: [],
  tableRef: {},
  hideElement: false
};

export default ExportToExcel;
