import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Icon } from 'semantic-ui-react';

class CheckboxDropdown extends PureComponent {
  static propTypes = {
    ...Dropdown.propTypes,
    className: PropTypes.string,
    icon: PropTypes.any,
    options: PropTypes.array,
    value: PropTypes.array,
    onSelect: PropTypes.func,
  };

  toggleOption = ({ event, option }) => {
    const { onChange, value } = this.props;
    const selectedItems = [...value];

    if (event) {
      event.stopPropagation();
    }
    const optionIndex = selectedItems.findIndex(item => item.key === option.key);

    if (optionIndex >= 0) {
      selectedItems.splice(optionIndex, 1);
    } else {
      selectedItems.push(option);
    }

    if (onChange) {
      onChange(event, selectedItems, option);
    }
  };

  render() {
    const { options, value: selectedItems, ...rest } = this.props;
    return (
      <Dropdown {...rest} value={null} onChange={() => {}}>
        <Dropdown.Menu>
          {options.map(option => (
            <Dropdown.Item key={option.key} onClick={event => this.toggleOption({ event, option })}>
              <Icon
                name={`${
                  selectedItems.findIndex(item1 => item1.key === option.key) >= 0 ? 'check ' : ''
                }square outline`}
              />
              {option.text}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

export default CheckboxDropdown;
