import PropTypes from "prop-types";
import React, { useState } from "react";
import DatetimeRangePicker from "react-datetime-range-picker";
import styles from "../NewCost.module.css";
import moment from "moment";
import { Icon } from "semantic-ui-react";

const DateRangePicker = ({ checker, onChange, name, value, disabled }) => {
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const handleChange = ({ start, end }) =>
    onChange({
      start: moment(start).format("YYYY-MM-DD"),
      end: moment(end).format("YYYY-MM-DD")
    });
  return (
    <div className={styles.wrapper}>
      <label
        className={
          checker === "range"
            ? styles.dateRangeLabel
            : styles.dateRangeLabelDisabled
        }
      >
        From
      </label>
      <div className={styles.inputWrapper}>
        {value && value.start ? (
          `${moment(value.start).format("DD-MM-YYYY")} - `
        ) : (
          <span className={styles.placeholder}>dd/mm/yyyy - </span>
        )}{" "}
        {value && value.end ? (
          moment(value.end).format("DD-MM-YYYY")
        ) : (
          <span className={styles.placeholder}>
            dd/mm/yyyy
            <Icon
              className={styles.calendareIcon}
              name="calendar alternate outline"
            />
          </span>
        )}
      </div>
      <DatetimeRangePicker
        inputProps={{
          disabled: checker !== "range"
        }}
        name={name}
        value={value}
        initialValue={value}
        className={styles.dateRangePicker}
        viewMode={"days"}
        timeFormat={false}
        dateFormat={"DD-MM-YYYY"}
        onChange={handleChange}
      />
    </div>
  );
};

DateRangePicker.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired
};

DateRangePicker.defaultProps = {
  disabled: false
};

export default DateRangePicker;
