import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Grid, Input, Icon, Form } from "semantic-ui-react";
import Calendar from "react-calendar";
import { bindActionCreators } from "redux";
import { setDate } from "../../../../actions/SprayDiary/common";
import PropTypes from "prop-types";

class SprayTaskPlannedDatePicker extends Component {
  state = {
    trigger: false
  };

  componentDidUpdate(prevProps) {
    const { id } = this.props;

    if (prevProps.id !== id) {
      document.activeElement.blur();
    }
  }

  onChange = date => {
    this.props.actions.setDate(date);
    this.setState({ trigger: false });
  };

  toggleShowCalendar = () => {
    this.setState({ trigger: !this.state.trigger });
  };

  render() {
    const { id, title, plannedDate, valid } = this.props;
    return (
      <React.Fragment>
        <Grid.Row className="sprayField">
          <Grid.Column className="section">
            <Form.Field required className="sprayField">
              <label className="title">{title}</label>
              <Input
                fluid
                {...(!id ? { autoFocus: true } : {})}
                error={!valid}
                size="large"
                onClick={this.toggleShowCalendar}
                value={
                  plannedDate ? moment(plannedDate).format("DD/MM/YYYY") : ""
                }
                placeholder="DD/MM/YYYY"
                icon={<Icon className="tuf-calendar-alt" />}
              />

              {this.state.trigger && (
                <Calendar
                  value={plannedDate ? new Date(plannedDate) : new Date()}
                  onChange={this.onChange}
                />
              )}
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
      </React.Fragment>
    );
  }
}

SprayTaskPlannedDatePicker.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  Can: PropTypes.func,
  actions: PropTypes.object,
  valid: PropTypes.bool,
  plannedDate: PropTypes.object
};

const mapStateToProps = ({
  sprayDiary: {
    id,
    plannedDate,
    validations: { plannedDate: valid }
  }
}) => ({
  id,
  plannedDate,
  valid
});

const mapDispatchToProps = (dispatch, props) => ({
  actions: bindActionCreators(
    {
      setDate: date => setDate(`plannedDate`, date)
    },
    dispatch
  )
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SprayTaskPlannedDatePicker);
