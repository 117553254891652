import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import checkMark from "./check-mark.svg";
import styles from "./styles.module.css";

export const STATE = {
  DONE: styles.done,
  IN_PROGRESS: styles.inProgress,
  EMPTY: styles.empty
};

const TaskIndicator = ({ onClick, number, state, title }) => {
  return (
    <div onClick={onClick} className={classNames(styles.indicator, state)}>
      <span className={styles.digit}>
        {state === STATE.DONE ? <img src={checkMark} alt="" /> : number}
      </span>
      <span className={styles.title}>{title}</span>
    </div>
  );
};

TaskIndicator.propTypes = {
  number: PropTypes.number,
  onClick: PropTypes.func,
  state: PropTypes.string,
  title: PropTypes.string
};

export default TaskIndicator;
