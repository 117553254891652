import React from "react";
import { Modal, Button, Header } from "semantic-ui-react";

const IncludeMediaConfirmationModal = ({ isShown, onYesClick, onNoClick }) => {
  return (
    <Modal closeOnEscape={false} closeOnDimmerClick={false} open={isShown}>
      <Modal.Content>
        <Header>
          Do you want to include the media (photos) in the printout/pdf?{" "}
        </Header>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onNoClick} negative>
          No
        </Button>
        <Button onClick={onYesClick} positive>
          Yes
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default IncludeMediaConfirmationModal;
