import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Dropdown, Form, Grid, Input } from "semantic-ui-react";
import SegmentClosableControlled from "../../../../../components/SegmentClosableControlled";
import {
  fetchTaskPaymentMethods,
  setField
} from "../../../../../actions/GeneralTaskCreator/taskCreator";
import SelectMachinery from "../../../../../components/SelectMachinery";
import TaskStepFooter from "../TaskStepFooter";
import CropRelatedFields from "./CropRelatedFields";
import Review from "./Review";
import { instructionStageValidation } from "../../../../../reducers/GeneralTaskCreator";
import "./dropdownTree.css";
import DropdownTreeSelect from "react-dropdown-tree-select";
import "react-dropdown-tree-select/dist/styles.css";
import { farmsBlockSelector, farmsWithAreas } from "selectors/farms";

const InstructionsStep = ({
  isOpen,
  allowedFields,
  mandatoryFields,
  onArrowClick
}) => {
  const dispatch = useDispatch();
  const selectTreeRef = useRef();
  const selectTreeCustomBtnsContainerRef = useRef();
  const data = useSelector(
    state => state.taskCreator.taskStages.instructions,
    shallowEqual
  );
  const areasList = useSelector(farmsWithAreas)
    .map(({ areas }) => areas)
    .flat();
  const farmList = useSelector(farmsBlockSelector);
  const {
    isFetching: isPaymentMethodsFetching,
    content: paymentMethodsOptions
  } = useSelector(state => state.taskCreator.taskPaymentMethods, shallowEqual);

  const paymentOptions = paymentMethodsOptions.map(item => ({
    id: item,
    text: item.replace(/_/gi, " "),
    value: item
  }));
  const instructionStageIsValid = instructionStageValidation(data);

  useEffect(() => {
    dispatch(fetchTaskPaymentMethods());
  }, []);

  useEffect(() => {
    if (selectTreeRef.current) {
      const mutationCallback = mutationsList => {
        for (const mutation of mutationsList) {
          if (
            mutation.type === "attributes" &&
            mutation.attributeName === "aria-expanded"
          ) {
            if (mutation.target.getAttribute("aria-expanded") === "true") {
              const dropdownContent = selectTreeRef.current.node.querySelector(
                "div.dropdown-content"
              );
              const searchInput = dropdownContent.querySelector("input.search");
              const input = document.createElement("input");
              input.type = "text";
              input.placeholder = "Search Block and Patch...";
              input.className = "search custom";
              input.oninput = e => {
                const val = e.target.value.trim();

                if (!val) {
                  setFieldValue("expandedFarmsIds", []);
                  setFieldValue("expandedAreasIds", []);
                }

                setFieldValue("treeSelectSearchFilter", val);

                if (selectTreeCustomBtnsContainerRef.current) {
                  selectTreeCustomBtnsContainerRef.current.style.display = val
                    ? "none"
                    : "block";
                }
              };
              input.oncut = () => {
                if (selectTreeCustomBtnsContainerRef.current) {
                  selectTreeCustomBtnsContainerRef.current.style.display =
                    "block";
                }
                setFieldValue("expandedFarmsIds", []);
                setFieldValue("expandedAreasIds", []);
                setFieldValue("treeSelectSearchFilter", "");
              };

              searchInput.replaceWith(input);
              input.focus();
            } else {
              setFieldValue("expandedFarmsIds", []);
              setFieldValue("expandedAreasIds", []);
              setFieldValue("treeSelectSearchFilter", "");
            }
          }
        }
      };

      const observer = new MutationObserver(mutationCallback);
      const searchInputContainer = selectTreeRef.current.node.querySelector(
        ".dropdown-trigger"
      );
      observer.observe(searchInputContainer, {
        attributes: true,
        attributeOldValue: true
      });
    }
  }, [selectTreeRef, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      selectTreeCustomBtnsContainerRef.current = null;
    }
  }, [isOpen]);

  const setFieldValue = (fieldName, fieldValue) =>
    dispatch(setField("instructions", fieldName, fieldValue, mandatoryFields));

  const handleSelectAll = isChecked => () => {
    const [
      selectAllBtn,
      selectNoneBtn
    ] = selectTreeCustomBtnsContainerRef.current.querySelectorAll(
      ".select-btn"
    );

    if (isChecked) {
      selectAllBtn.classList.add("active");
      selectNoneBtn.classList.remove("active");
    } else {
      selectAllBtn.classList.remove("active");
      selectNoneBtn.classList.add("active");
    }

    const selectedAreas = farmList.map(area => area.value).flat();
    setFieldValue(
      "farms",
      isChecked
        ? [
            ...new Map(
              selectedAreas.map(area => [area.farm.id, area.farm])
            ).values()
          ]
        : []
    );
    setFieldValue("areas", isChecked ? selectedAreas : []);
    setFieldValue("treeSelectSearchFilter", "");
  };

  const onChange = (_, selectedNodes) => {
    const selectedAreas = selectedNodes.map(area => area.value).flat();
    setFieldValue("farms", [
      ...new Map(selectedAreas.map(area => [area.farm.id, area.farm])).values()
    ]);
    setFieldValue("areas", selectedAreas);
  };

  const onNodeToggle = currentNode => {
    if (currentNode.type === "farm") {
      const expandedFarmsIdsNew = data.expandedFarmsIds.includes(
        currentNode.farmId
      )
        ? data.expandedFarmsIds.filter(id => id !== currentNode.farmId)
        : [...data.expandedFarmsIds, currentNode.farmId];

      const expandedAreasIdsNew = data.expandedAreasIds.filter(areaId => {
        const foundArea = areasList.find(a => a.id === areaId);
        if (foundArea) {
          return expandedFarmsIdsNew.includes(foundArea.farm.id);
        }

        return false;
      });

      setFieldValue("expandedFarmsIds", expandedFarmsIdsNew);
      setFieldValue("expandedAreasIds", expandedAreasIdsNew);
    } else if (currentNode.type === "block") {
      const expandedAreasIdsNew = data.expandedAreasIds.includes(currentNode.id)
        ? data.expandedAreasIds.filter(id => id !== currentNode.id)
        : [...data.expandedAreasIds, currentNode.id];

      setFieldValue("expandedAreasIds", expandedAreasIdsNew);
    }
  };

  const handleSelectOnFocus = () => {
    if (selectTreeCustomBtnsContainerRef.current) {
      if (data.treeSelectSearchFilter === "") {
        selectTreeCustomBtnsContainerRef.current.style.display = "block";
      }
    } else {
      const div = document.createElement("div");
      div.innerText = "Select ";

      const span1 = document.createElement("span");
      span1.innerText = "All";
      span1.className = "select-btn select-all";
      span1.onclick = handleSelectAll(true);

      const separator = document.createElement("span");
      separator.innerText = " / ";

      const span2 = document.createElement("span");
      span2.innerText = "None";
      span2.className = "select-btn select-none";
      span2.onclick = handleSelectAll(false);

      div.className = "custom-buttons-container";
      div.appendChild(span1);
      div.appendChild(separator);
      div.appendChild(span2);

      selectTreeRef.current.node.firstChild.appendChild(div);
      selectTreeCustomBtnsContainerRef.current = div;
    }
  };

  const handleSelectOnBlur = () => {
    selectTreeCustomBtnsContainerRef.current.style.display = "none";
  };

  return (
    <SegmentClosableControlled
      onArrowClick={onArrowClick}
      title={"Instructions"}
      open={isOpen}
    >
      {isOpen && (
        <>
          <Grid>
            <Grid.Row required>
              <Grid.Column required>
                <label className="areaSelectorlabel">
                  Select Block/Patch by Farm
                </label>
                <DropdownTreeSelect
                  ref={selectTreeRef}
                  data={farmList}
                  className="dropdownTree"
                  texts={{ placeholder: " " }}
                  showPartiallySelected
                  keepOpenOnSelect
                  keepTreeOnSearch
                  keepChildrenOnSearch
                  clearSearchOnChange
                  inlineSearchInput
                  onChange={onChange}
                  onNodeToggle={onNodeToggle}
                  onFocus={handleSelectOnFocus}
                  onBlur={handleSelectOnBlur}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <CropRelatedFields
                allowedFields={allowedFields}
                mandatoryFields={mandatoryFields}
                onChange={({ target: { name, value } }) =>
                  setFieldValue(name, value)
                }
                crop={data.crop}
                variety={data.variety}
                areas={data.areas}
              />
            </Grid.Row>
            <Grid.Row>
              <Grid.Column largeScreen={4} mobile={16}>
                <Form.Field
                  required={mandatoryFields.includes("EQUIPMENTS")}
                  disabled={!allowedFields.includes("EQUIPMENTS")}
                >
                  <label>Required equipment</label>
                  <SelectMachinery
                    native
                    name="equipment"
                    search
                    multiple
                    value={data.equipment}
                    onChange={(_, data) =>
                      setFieldValue("equipment", data.value)
                    }
                    disabled={!allowedFields.includes("EQUIPMENTS")}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column largeScreen={4} mobile={16}>
                <Form.Field
                  required={mandatoryFields.includes("NUMBER_OF_EMPLOYEES")}
                  disabled={!allowedFields.includes("NUMBER_OF_EMPLOYEES")}
                >
                  <label>Number of employees</label>
                  <Input
                    fluid
                    disabled={!allowedFields.includes("NUMBER_OF_EMPLOYEES")}
                    onChange={(_, data) =>
                      setFieldValue("numberOfEmployees", data.value)
                    }
                    name={"numberOfEmployees"}
                    value={data.numberOfEmployees}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column largeScreen={4} mobile={16}>
                <Form.Field
                  required={mandatoryFields.includes("PAYMENT_METHOD")}
                  disabled={!allowedFields.includes("PAYMENT_METHOD")}
                >
                  <label>Payment method</label>
                  <Dropdown
                    disabled={!allowedFields.includes("PAYMENT_METHOD")}
                    name="paymentMethod"
                    closeOnChange
                    fluid
                    selection
                    loading={isPaymentMethodsFetching}
                    value={data.paymentMethod}
                    options={paymentOptions}
                    onChange={(_, data) =>
                      setFieldValue("paymentMethod", data.value)
                    }
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <TaskStepFooter isValid={instructionStageIsValid} />
        </>
      )}
      {isOpen || <Review instructionsStep={data} />}
    </SegmentClosableControlled>
  );
};

InstructionsStep.propTypes = {
  allowedFields: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  mandatoryFields: PropTypes.array.isRequired,
  onArrowClick: PropTypes.func.isRequired
};

export default React.memo(InstructionsStep);
