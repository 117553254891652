import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import SegmentClosable from "components/SegmentClosable";
import SelectMachinery from "./components/SelectMachinery";
import SelectChemicals from "./components/SelectChemicals/SelectChemicals";
import { useFormikContext } from "formik";
import { get, isEqual } from "lodash";
import { APPLICATION_TYPES } from "constants/Recommendations/types";
import { selectedChemicalsSelector } from "selectors/sprayDiary";
import { actionType as sprayDiaryActions } from "constants/SprayDiary";

const Operations = ({ selectedChemicals }) => {
  const formikContext = useFormikContext();
  const dispatch = useDispatch();
  // TODO: Avoid the hacky way, move the management
  useEffect(() => {
    if (!isEqual(selectedChemicals, formikContext.values.chemicals)) {
      const newChemicals = selectedChemicals.map(chemical => {
        return {
          chemical,
          chemicalId: get(chemical, "id"),
          dilutionRate: get(chemical, "rate.rate"),
          chemicalRateId: get(chemical, "rate.id"),
          batchNumber: get(chemical, "batchNumber"),
          dateOfManufacture: get(chemical, "dateOfManufacture")
        };
      });
      formikContext.setFieldValue("chemicals", newChemicals);
    }
  }, [selectedChemicals]);

  useEffect(() => {
    dispatch({
      type: sprayDiaryActions.SET_SELECTED_CHEMICALS,
      payload: []
    });
  }, []);
  return (
    <SegmentClosable title="Operations">
      {APPLICATION_TYPES[formikContext.values.applicationType] !==
        APPLICATION_TYPES.FERTIGATION && (
        <SelectMachinery formikContext={formikContext} />
      )}
      <SelectChemicals />
    </SegmentClosable>
  );
};

const mapStateToProps = state => {
  return {
    selectedChemicals: selectedChemicalsSelector(state),
    areasList: state.areas.list.content,
    machineryList: state.sprayDiary.machineryList.content
  };
};

export default connect(mapStateToProps)(Operations);
