import React, { Component } from "react";
import { createSelector } from "reselect";
import { get, join, map } from "lodash";
import { matterToUnits } from "utils/constToUnits";
import Numeric from "components/Numeric/Numeric";
const tableDataSelector = state => state.reports.shedReportProductType.data;
const tableListDataSelector = state => state.reports.shedReportProductType.list;
const areasListSelector = state => state.areas.list.content;
const chemicalListSelector = state => state.chemical.list;
const farmListSelector = state => state.farms.summary;

export const formaterData = (data, areasList, farmsList, chemicalList) => {
  const { content, ...rest } = data;
  const restContent = content?.content && {
    number: content.number,
    numberOfElements: content.numberOfElements,
    size: content.size,
    totalElements: content.totalElements,
    totalPages: content.totalPages
  };
  const contentUpdated =
    content?.content && content.content.length
      ? content.content
          ?.sort(function(a, b) {
            var nameA = a.typeName?.toLowerCase(),
              nameB = b.typeName?.toLowerCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
          })
          .map(block => {
            return {
              ...block?.items
                ?.sort(function(a, b) {
                  var nameA = chemicalList.content
                      .find(({ id }) => id == a.chemicalId)
                      ?.name?.toLowerCase(),
                    nameB = chemicalList.content
                      .find(({ id }) => id == b.chemicalId)
                      ?.name.toLowerCase();
                  if (nameA < nameB) return -1;
                  if (nameA > nameB) return 1;
                  return 0;
                })
                .reduce(
                  (prev, curr, _, items) => {
                    return {
                      name: get(block, "typeName", "-"),
                      matter: get(block, "matter", "-"),
                      plannedQuantity: (
                        <Numeric
                          fractionDigits={
                            items.reduce(
                              (prev, curr) => prev + curr.plannedQty,
                              0
                            ) > 1000
                              ? 0
                              : 2
                          }
                          value={items.reduce(
                            (prev, curr) => prev + curr.plannedQty,
                            0
                          )}
                          commaSeparatorOnThousands
                          units={` ${matterToUnits(
                            get(
                              chemicalList.content?.find(
                                ({ id }) => id == curr.chemicalId
                              ),
                              "matter"
                            )
                          )}`}
                          unitsPosition="right"
                        />
                      ),
                      actualQuantity: (
                        <Numeric
                          fractionDigits={
                            items.reduce(
                              (prev, curr) => prev + curr.actualQty,
                              0
                            ) > 1000
                              ? 0
                              : 2
                          }
                          value={items.reduce(
                            (prev, curr) => prev + curr.actualQty,
                            0
                          )}
                          commaSeparatorOnThousands
                          units={` ${matterToUnits(
                            get(
                              chemicalList.content.find(
                                ({ id }) => id == curr.chemicalId
                              ),
                              "matter"
                            )
                          )}`}
                          unitsPosition="right"
                        />
                      ),
                      difference: (
                        <Numeric
                          fractionDigits={
                            items.reduce((prev, curr) => prev + curr.diff, 0) >
                            1000
                              ? 0
                              : 2
                          }
                          value={items.reduce(
                            (prev, curr) => prev + curr.diff,
                            0
                          )}
                          commaSeparatorOnThousands
                          units={` ${matterToUnits(
                            get(
                              chemicalList.content.find(
                                ({ id }) => id == curr.chemicalId
                              ),
                              "matter"
                            )
                          )}`}
                          unitsPosition="right"
                        />
                      ),
                      totalCost: (
                        <Numeric
                          fractionDigits={
                            items.reduce((prev, curr) => prev + curr.cost, 0) >
                            1000
                              ? 0
                              : 2
                          }
                          value={items.reduce(
                            (prev, curr) => prev + curr.cost,
                            0
                          )}
                          commaSeparatorOnThousands
                          units="$ "
                          unitsPosition="left"
                        />
                      ),
                      printButtonContainer: "",
                      subRows: [
                        ...prev.subRows,
                        {
                          name: (
                            <div style={{ paddingLeft: "10px" }}>
                              {
                                chemicalList.content.find(
                                  ({ id }) => id == curr.chemicalId
                                )?.name
                              }
                            </div>
                          ),
                          matter: join(
                            map(
                              chemicalList.content.find(
                                ({ id }) => id == curr.chemicalid
                              )?.activeIngredients,
                              ({ activeIngredient }) => activeIngredient
                            ),
                            ", "
                          ),
                          plannedQuantity: (
                            <Numeric
                              fractionDigits={curr.plannedQty > 1000 ? 0 : 2}
                              value={curr.plannedQty}
                              commaSeparatorOnThousands
                            />
                          ),
                          actualQuantity: (
                            <Numeric
                              fractionDigits={curr.actualQty > 1000 ? 0 : 2}
                              value={curr.actualQty}
                              commaSeparatorOnThousands
                            />
                          ),
                          difference: (
                            <Numeric
                              fractionDigits={curr.diff > 1000 ? 0 : 2}
                              value={curr.diff}
                              commaSeparatorOnThousands
                            />
                          ),
                          totalCost: (
                            <Numeric
                              fractionDigits={curr.cost > 1000 ? 0 : 2}
                              value={curr.cost}
                              commaSeparatorOnThousands
                              units="$ "
                              unitsPosition="left"
                            />
                          ),
                          buttonContainer: "",
                          printButtonContainer: ""
                        }
                      ]
                    };
                  },
                  {
                    name: "",
                    matter: "",
                    plannedQuantity: null,
                    actualQuantity: null,
                    difference: null,
                    totalCost: null,
                    buttonContainer: null,
                    printButtonContainer: null,
                    subRows: [
                      {
                        name: (
                          <b
                            style={{
                              paddingLeft: "10px",
                              height: "48px",
                              borderTop: "1px solid #DEDEDF",
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              backgroundColor: "#F6F7F8"
                            }}
                          >
                            {" "}
                            Products
                          </b>
                        ),
                        matter: (
                          <b
                            style={{
                              height: "48px",
                              borderTop: "1px solid #DEDEDF",
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              backgroundColor: "#F6F7F8"
                            }}
                          >
                            Active ingredient
                          </b>
                        ),
                        plannedQuantity: (
                          <b
                            style={{
                              height: "48px",
                              borderTop: "1px solid #DEDEDF",
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              backgroundColor: "#F6F7F8"
                            }}
                          >
                            Planned Qty (L/Kg)
                          </b>
                        ),
                        actualQuantity: (
                          <b
                            style={{
                              height: "48px",
                              borderTop: "1px solid #DEDEDF",
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              backgroundColor: "#F6F7F8"
                            }}
                          >
                            Actual Qty (L/Kg)
                          </b>
                        ),
                        difference: (
                          <b
                            style={{
                              height: "48px",
                              borderTop: "1px solid #DEDEDF",
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              backgroundColor: "#F6F7F8"
                            }}
                          >
                            Difference (L/Kg)
                          </b>
                        ),
                        totalCost: (
                          <b
                            style={{
                              height: "48px",
                              borderTop: "1px solid #DEDEDF",
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              backgroundColor: "#F6F7F8"
                            }}
                          >
                            Total cost ($)
                          </b>
                        ),
                        buttonContainer: (
                          <b
                            style={{
                              height: "48px",
                              borderTop: "1px solid #DEDEDF",
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              backgroundColor: "#F6F7F8"
                            }}
                          ></b>
                        ),
                        printButtonContainer: (
                          <b
                            style={{
                              height: "48px",
                              borderTop: "1px solid #DEDEDF",
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              backgroundColor: "#F6F7F8"
                            }}
                          ></b>
                        )
                      }
                    ]
                  }
                )
            };
          }, {})
      : [];

  const contentUpdatedNew = contentUpdated
    ? contentUpdated.map(item => {
        return {
          ...item,
          subRows: item.subRows.map((row, index) => {
            if (index === item.subRows.length - 1) {
              row.isSubRowsLastItem = true;
            }
            return row;
          })
        };
      })
    : [];

  return {
    ...rest,
    ...restContent,
    content: contentUpdatedNew
  };
};
export const selectTableStructuredData = createSelector(
  [
    tableDataSelector,
    areasListSelector,
    farmListSelector,
    chemicalListSelector
  ],
  (tableData, areasList, farmsList, chemicalList) => {
    return formaterData(tableData, areasList, farmsList, chemicalList);
  }
);

export const selectExpotStructuredData = createSelector(
  [
    tableListDataSelector,
    areasListSelector,
    farmListSelector,
    chemicalListSelector
  ],
  (listData, areasList, farmsList, chemicalList) => {
    return formaterData(listData, areasList, farmsList, chemicalList);
  }
);
