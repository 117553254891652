import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, TableCell, TableRow } from "semantic-ui-react";
import PickerBreakdownTable from "./PickerBreakdownTable";
import _ from "lodash";

const PickerRow = ({ data }) => {
  const [isExpanded, setExpanded] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell>
          <Button
            active={isExpanded}
            onClick={() => setExpanded(!isExpanded)}
            compact
            icon="dropdown"
          />
        </TableCell>
        <TableCell>{data.employeeId}</TableCell>
        <TableCell>{`${data.employeeFirstName} ${data.employeeLastName}`}</TableCell>
        <TableCell>{data.employeeGroupName || "-"}</TableCell>
        <TableCell>{_.round(data.totalQuantity, 2)}</TableCell>
        <TableCell>{_.round(data.totalPrice)}</TableCell>
      </TableRow>
      {isExpanded && (
        <TableRow>
          <TableCell colSpan={9}>
            <PickerBreakdownTable employeeId={data.employeeId} />
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

PickerRow.propTypes = {
  data: PropTypes.object
};

export default PickerRow;
