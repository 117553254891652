import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import ReactDOMServer from 'react-dom/server';

import ExpandedTablePrintLayout from './ExpandedTablePrintLayout';
import { copyStyles } from '../../../utils/styles';

const ExpandedTablePrintButton = ({ title, headerGroups, prepareRow, rows, className, ...extraProps }) => {
  const print = useCallback(() => {
    const newWindow = window.open();
    newWindow.document.title = ``;
    copyStyles(window.document, newWindow.document);
    newWindow.document.body.innerHTML = ReactDOMServer.renderToString(
      <ExpandedTablePrintLayout
        title={title}
        headerGroups={headerGroups}
        prepareRow={prepareRow}
        rows={rows}
        className={className}
      />,
    );
    newWindow.focus();
    setTimeout(function() {
      newWindow.print();
    }, 2000);
  }, [title, headerGroups, prepareRow, rows, className]);

  return <Button {...extraProps} type="button" className={'transparent'} icon={'print'} onClick={print} />;
};

ExpandedTablePrintButton.propTypes = {
  title: PropTypes.string,
  headerGroups: PropTypes.array.isRequired,
  prepareRow: PropTypes.func.isRequired,
  rows: PropTypes.array.isRequired,
  className: PropTypes.string,
};

ExpandedTablePrintButton.defaultProps = {
  title: '',
};

export default ExpandedTablePrintButton;
