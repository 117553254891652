import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  Button,
  Dropdown,
  Form,
  Grid,
  Header,
  Input,
  Radio,
  Segment
} from "semantic-ui-react";
import {
  cropsOptionsSelector,
  parentBlocksFilterSelector,
  varietyOptionsSelector
} from "../../../../../selectors/sprayDiary";
import { farmsOptionsSelector } from "../../../../../selectors/farms";

import styles from "./AreasFilters.module.css";

const AreasFilters = ({
  filters,
  resetFilters,
  applyFilters,
  changeFilter
}) => {
  const varietiesList = useSelector(varietyOptionsSelector);
  const cropsList = useSelector(cropsOptionsSelector);
  const parentBlocksList = useSelector(parentBlocksFilterSelector);
  const farmsList = useSelector(farmsOptionsSelector);

  return (
    <Segment>
      <Header as="h4">Filter by</Header>
      <Grid verticalAlign="middle" className={styles.filtersGrid}>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} widescreen={4} largeScreen={4}>
            Farm
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} widescreen={12} largeScreen={12}>
            <Dropdown
              name="farms"
              search
              closeOnChange
              noResultsMessage="Nothing was found"
              fluid
              placeholder="Select farms"
              selection
              multiple
              value={filters.farms}
              options={farmsList}
              onChange={changeFilter}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} widescreen={4} largeScreen={4}>
            Crop
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} widescreen={12} largeScreen={12}>
            <Dropdown
              name="crops"
              search
              closeOnChange
              noResultsMessage="Nothing was found"
              fluid
              placeholder="Select crops"
              selection
              multiple
              value={filters.crops}
              options={cropsList}
              onChange={changeFilter}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} widescreen={4} largeScreen={4}>
            Variety
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} widescreen={12} largeScreen={12}>
            <Dropdown
              name="varieties"
              search
              closeOnChange
              noResultsMessage="Nothing was found"
              fluid
              placeholder="Select varieties"
              selection
              multiple
              value={filters.varieties}
              options={varietiesList}
              onChange={changeFilter}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} widescreen={4} largeScreen={4}>
            Parent Block
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} widescreen={12} largeScreen={12}>
            <Dropdown
              name="parentBlock"
              search
              closeOnChange
              noResultsMessage="Nothing was found"
              fluid
              placeholder="Select parent block"
              selection
              multiple
              value={filters.parentBlock}
              options={parentBlocksList}
              onChange={changeFilter}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} widescreen={4} largeScreen={4}>
            Age
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} widescreen={12} largeScreen={12}>
            <Form className={styles.ageForm}>
              <Form.Group inline>
                <Form.Field className={styles.optional}>
                  <label>From</label>
                  <Input
                    onChange={changeFilter}
                    type="number"
                    format="integer"
                    name="ageFrom"
                    value={filters.ageFrom}
                    min={0}
                    step={1}
                  />
                </Form.Field>
                <Form.Field className={styles.optional}>
                  <label>To</label>
                  <Input
                    onChange={changeFilter}
                    type="number"
                    format="integer"
                    name="ageTo"
                    value={filters.ageTo}
                    min={0}
                    step={1}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} widescreen={4} largeScreen={4}>
            Show archived
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} widescreen={12} largeScreen={12}>
            <Radio
              name="showArchived"
              toggle
              checked={filters.showArchived}
              onChange={changeFilter}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign="right">
            <Button onClick={() => resetFilters()}>Reset</Button>
            <Button primary onClick={applyFilters}>
              Apply
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

AreasFilters.propTypes = {
  filters: PropTypes.object,
  resetFilters: PropTypes.func,
  applyFilters: PropTypes.func,
  changeFilter: PropTypes.func
};

export default AreasFilters;
