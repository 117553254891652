import React from "react";
import { Button, Form } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { setPPEWorn } from "../../../../../actions/SprayDiary/common";

const PersonalProtectionEquipment = () => {
  const dispatch = useDispatch();
  const doSetPPEWorn = value => dispatch(setPPEWorn(value));
  const { ppeWorn } = useSelector(state => state.sprayDiary);

  return (
    <Form.Field required>
      <label>
        <b>
          Was Personal Protection Equipment (P.P.E.) worn while undertaking this
          task?
        </b>
      </label>
      <br />
      <Button.Group basic>
        <Button onClick={() => doSetPPEWorn(true)} active={ppeWorn === true}>
          Yes
        </Button>
        <Button onClick={() => doSetPPEWorn(false)} active={ppeWorn === false}>
          No
        </Button>
      </Button.Group>
    </Form.Field>
  );
};

export default PersonalProtectionEquipment;
