import { axiosProxy } from '../../utils/axiosProxy';
import { actionType } from '../../constants/Stock';

const url = '/stock';

export const fetchStockList = () => async dispatch => {
  try {
    dispatch({
      type: actionType.FETCH_STOCK_LIST_START,
    });

    const res = await axiosProxy({
      method: 'GET',
      url: `${url}`,
      params: {
        unpaged: true,
      },
    });

    dispatch({
      type: actionType.FETCH_STOCK_LIST,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: actionType.FETCH_STOCK_LIST_FAIL,
      payload: error,
    });
  }
};

export const fetchStock = ({ page, size, search, sort, unpaged = false }) => async dispatch => {
  try {
    dispatch({
      type: actionType.FETCH_STOCK_START,
    });

    const params = new URLSearchParams();
    if (unpaged) {
      params.append('unpaged', true);
    } else {
      params.append('page', page);
      params.append('size', size);
    }

    if (search) {
      params.append('search', search);
    }

    (sort || ['id,asc']).forEach(field => {
      params.append('sort', field);
    });

    const res = await axiosProxy({
      method: 'GET',
      params,
      url,
    });

    await dispatch({
      type: actionType.FETCH_STOCK,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.FETCH_STOCK_FAIL,
      payload: error,
    });
  }
};

export const getStockById = id => async dispatch => {
  try {
    dispatch({
      type: actionType.GET_STOCK_BY_ID_START,
    });
    const res = await axiosProxy({
      method: 'GET',
      url: `${url}/${id}`,
    });

    dispatch({
      type: actionType.GET_STOCK_BY_ID,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.GET_STOCK_BY_ID_FAIL,
      payload: error,
    });
    return Promise.reject(error);
  }
};

export const getStockByVarietyIdAndBoxUnitId = (varietyId, boxUnitId) => async dispatch => {
  try {
    dispatch({
      type: actionType.GET_STOCK_ITEM_BY_VARIETY_ID_AND_BOX_UNIT_ID_START,
    });

    const params = new URLSearchParams();
    params.append('varietyId', varietyId);
    params.append('boxUnitId', boxUnitId);

    const res = await axiosProxy({
      method: 'GET',
      params,
      url: `${url}/items`,
    });
    dispatch({
      type: actionType.GET_STOCK_ITEM_BY_VARIETY_ID_AND_BOX_UNIT_ID,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    if (error.response.status !== 404) {
      dispatch({
        type: actionType.GET_STOCK_ITEM_BY_VARIETY_ID_AND_BOX_UNIT_ID_FAIL,
        payload: error,
      });
    } else {
      dispatch({
        type: actionType.GET_STOCK_ITEM_BY_VARIETY_ID_AND_BOX_UNIT_ID,
        payload: {},
      });
      return null;
    }

    return Promise.reject(error);
  }
};

export const addStock = data => async dispatch => {
  try {
    dispatch({
      type: actionType.ADD_STOCK_START,
    });
    const res = await axiosProxy({
      method: 'POST',
      url,
      data,
    });

    await dispatch({
      type: actionType.ADD_STOCK,
      payload: res.data,
    });

    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.ADD_STOCK_FAIL,
      payload: error,
    });
    return { error };
  }
};

export const updateStock = (id, data) => async dispatch => {
  try {
    dispatch({
      type: actionType.UPDATE_STOCK_START,
    });
    const res = await axiosProxy({
      method: 'PUT',
      data,
      url: `${url}/${id}`,
    });

    await dispatch({
      type: actionType.UPDATE_STOCK,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.UPDATE_STOCK_FAIL,
      payload: error,
    });
    return { error };
  }
};

export const deleteStock = id => async dispatch => {
  try {
    dispatch({
      type: actionType.DELETE_STOCK_START,
    });
    await axiosProxy({
      method: 'DELETE',
      url: `${url}/${id}`,
    });

    await dispatch({
      type: actionType.DELETE_STOCK,
      payload: id,
    });
  } catch (error) {
    dispatch({
      type: actionType.DELETE_STOCK_FAIL,
      payload: error,
    });
  }
};

export const clearError = () => dispatch => {
  dispatch({
    type: actionType.CLEAR_STOCK_ERROR,
  });
};
