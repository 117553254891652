import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "react-table-6/react-table.css";
import ListHolder from "../../../components/ListHolder";
import { abilitiesSelector } from "../../../selectors/user";
import { deletePallet, fetchPallets } from "../../../actions/Pallets/pallets";
import moment from "moment";
import Sidebar from "./Sidebar";
import ShowMore from "../../../components/ShowMore";
import {
  getHashParameter,
  setHashParameter
} from "../../../utils/hashToObject";

const PAGE_SIZE = 10;

class Pallets extends Component {
  state = {
    tableData: [],
    tableColumns: [],
    sideBarShown: false,
    activeItemId: null
  };

  componentDidMount() {
    const { location } = this.props;
    const isAllocatedToDispatch = getHashParameter(
      location,
      "isAllocatedToDispatch"
    );

    if (!isAllocatedToDispatch)
      setHashParameter(location, "isAllocatedToDispatch", "false");

    this.prepareData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { data } = this.props;
    if (prevProps.data !== data) this.prepareData();
  }

  prepareData = () => {
    const { data } = this.props;
    const columnsNames = {
      id: {
        title: "id",
        width: 12
      },
      createDate: {
        title: "Date",
        accessor: d =>
          d.createDate && moment(d.createDate).format("DD/MM/YYYY"),
        width: 8
      },
      variety: {
        title: "Variety",
        accessor: d => d.variety && d.variety.name,
        width: 8,
        sortFields: ["variety.name"]
      },
      boxUnit: {
        title: "Box unit",
        accessor: d => d.boxUnit && d.boxUnit.displayName,
        width: 8,
        sortFields: ["boxUnit.displayName"]
      },
      area: {
        title: "Areas",
        accessor: d =>
          d.areas
            .map(
              ({ area }) =>
                `${area.parent ? `${area.parent.name} - ` : ""}${area.name}`
            )
            .join(", "),
        width: 8
      },
      registrationNumber: {
        title: "Registration numbers",
        accessor: d =>
          d.areas
            .map(({ area }) => area.registrationNumber)
            .filter(n => !!n)
            .join(", "),
        width: 8
      },
      unitsOnPallet: {
        title: "Units on pallet",
        accessor: d => d.unitsOnPallet,
        width: 8
      },
      order: {
        title: "Dispatch id",
        accessor: d => d.dispatch && d.dispatch.id,
        width: 8
      },
      isAllocatedToDispatch: {
        title: "",
        width: "auto",
        accessor: null,
        className: "d-none",
        filter: {
          name: "Allocated to dispatch",
          type: "SingleSelectFilter",
          options: [
            { id: "yes", text: "Yes", value: "true" },
            { id: "no", text: "No", value: "false" }
          ]
        }
      },
      notes: {
        title: "notes",
        accessor: d => <ShowMore length={15}>{d.notes}</ShowMore>,
        width: 8
      },
      actions: {
        title: "",
        width: "auto",
        accessor: d => d.id,
        className: "textRight actions"
      }
    };
    const tableColumns = [];
    const tableData = [];

    Object.keys(columnsNames).forEach(column => {
      const targetColumn = columnsNames[column];
      const columnToAdd = {
        id: column,
        accessor: targetColumn.accessor || column,
        Header: targetColumn.title,
        Cell: this.renderCell,
        className: `cell_${targetColumn.width} ${targetColumn.className || ""}`,
        ...(targetColumn.sortFields
          ? { sortFields: targetColumn.sortFields }
          : {}),
        disableSort: targetColumn.disableSort,
        filter: targetColumn.filter
      };

      tableColumns.push(columnToAdd);
    });

    if (data && data.content) {
      data.content.forEach(row => {
        const rowData = {
          rights: {
            update: "update",
            delete: "delete"
          }
        };
        Object.keys(row).forEach(key => {
          switch (key) {
            default:
              rowData[key] = row[key] || "";
              break;
          }
        });
        tableData.push(rowData);
      });
    }

    this.setState({
      tableData,
      tableColumns
    });
  };

  renderCell = props => {
    const {
      value,
      column: { Header }
    } = props;

    return (
      <div>
        <div className="cellTitle hide-md">{Header}</div>
        <div className="cellValue">{value}</div>
      </div>
    );
  };

  setActiveItem = activeItemId => {
    this.setState({
      activeItemId
    });
  };

  render() {
    const {
      route,
      location,
      currentPage,
      totalPages,
      isFetching,
      Can,
      fetchAction,
      size,
      totalElements,
      deleteAction,
      error,
      online,
      data: { content }
    } = this.props;

    const { tableData, tableColumns, activeItemId } = this.state;
    const expandedContent =
      activeItemId && content.find(e => e.id === activeItemId);
    const editLink = expandedContent
      ? `/harvest/pallets/pallet/${expandedContent.id}`
      : null;
    return (
      <ListHolder
        accessName="pallets"
        Can={Can}
        addNewPath={"/harvest/pallets/pallet"}
        updateAction="update"
        editLink={editLink}
        route={route}
        location={location}
        currentPage={currentPage}
        size={size || PAGE_SIZE}
        totalPages={totalPages}
        rawData={content}
        data={tableData}
        columns={tableColumns}
        getData={fetchAction}
        delData={deleteAction}
        isFetching={isFetching && !totalPages}
        totalElements={totalElements}
        withDateRange={false}
        setActiveItem={this.setActiveItem}
        error={error}
        online={online}
        defaultSort="id,desc"
        exportToExcel={true}
      >
        <Sidebar
          id={expandedContent && expandedContent.id}
          pallet={expandedContent}
          Can={Can}
        />
      </ListHolder>
    );
  }
}

Pallets.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  totalElements: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  fetchAction: PropTypes.func.isRequired,
  deleteAction: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  lastLoaded: PropTypes.bool,
  data: PropTypes.object,
  error: PropTypes.object,
  route: PropTypes.object,
  location: PropTypes.object,
  Can: PropTypes.func,
  online: PropTypes.bool
};

const mapStateToProps = state => {
  const {
    pallets: {
      isFetching,
      data,
      data: { content, number, totalPages, totalElements, size, last },
      error
    },
    offline: { online }
  } = state;
  return {
    data,
    content,
    isFetching,
    currentPage: number,
    totalPages,
    totalElements,
    size,
    lastLoaded: last,
    Can: abilitiesSelector(state),
    error,
    online
  };
};

const mapDispatchToProps = {
  fetchAction: fetchPallets,
  deleteAction: deletePallet
};

export default connect(mapStateToProps, mapDispatchToProps)(Pallets);
