import React, { useEffect, useState } from "react";
import {
  Form,
  Segment,
  Button,
  Icon,
  Input,
  Container,
  Popup
} from "semantic-ui-react";
import { useDispatch, connect } from "react-redux";
import { useFormikContext, FieldArray, Field } from "formik";
import styles from "./BatchNumberInput.module.css";
import { getBatchNumbersLastYear } from "actions/Spray/tasks";
import { isEmpty } from "lodash";

const BatchNumberInputField = ({ onChange, suffix }) => {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (isEmpty(values.batchNumbers)) {
      setFieldValue("batchNumbers", [""]);
    }
  }, [values.batchNumbers]);

  useEffect(() => {
    onChange(values.batchNumbers, "batchNumbers", suffix);
  }, [values]);

  return (
    <Form className={styles.root}>
      <FieldArray
        name="batchNumbers"
        render={arrayHelpers => (
          <div>
            {(values.batchNumbers || [""]).map((formItem, index) => (
              <div className={styles.inputButtonWrapper}>
                <div className={styles.inputWrapper} key={index}>
                  <Segment className={styles.ingredientSection}>
                    <Field
                      as={Input}
                      name={`batchNumbers.${index}`}
                      className={styles.batchNumberInput}
                    />
                    <Popup
                      content="Delete active batch number"
                      position="bottom center"
                      inverted
                      trigger={
                        <Icon
                          name="trash alternate"
                          className={styles.removeActiveIngredient}
                          onClick={() => {
                            return (
                              arrayHelpers.remove(index),
                              values.batchNumbers.splice(index, 1),
                              onChange(
                                [...values.batchNumbers],
                                "batchNumbers",
                                suffix
                              )
                            );
                          }}
                        />
                      }
                    />
                  </Segment>
                </div>
              </div>
            ))}
            <Container fluid className={styles.textButtonContainer}>
              <Button
                type="button"
                onClick={() => {
                  return arrayHelpers.insert(0, "");
                }}
                className={styles.textButton}
              >
                <Icon name="plus" />
                Add batch number
              </Button>
            </Container>
          </div>
        )}
      />
    </Form>
  );
};
const mapStateToProps = state => {
  return {
    batchNumbers: state.spray.batchNumbers
  };
};

export default connect(mapStateToProps)(BatchNumberInputField);
