import React from "react";
import { Grid, Dropdown, Form } from "semantic-ui-react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { setApplicationType } from "../../../actions/SprayDiary/common";
import { shedsListSelector } from "../../../selectors/sheds";
import styles from "./createSprayTask.module.css";

const options = [
  { key: 1, text: "Spray", value: "SPRAY" },
  { key: 2, text: "Spread", value: "SPREAD" },
  { key: 3, text: "Fertigation", value: "FERTIGATION" }
];
const ApplicationType = ({ actions, applicationType }) => {
  return (
    <Grid.Row>
      <Grid.Column>
        <Form.Field>
          <label>Application type</label>
          <Dropdown
            closeOnChange
            closeOnBlur
            selection
            fluid
            placeholder="Select type"
            name="applicationType"
            scrolling
            className={styles.shedsDropdown}
            openOnFocus
            options={options}
            value={applicationType || null}
            onChange={(_, data) => {
              return actions.setApplicationType(
                data.value !== "" ? data.value : "Spray"
              );
            }}
          />
        </Form.Field>
      </Grid.Column>
    </Grid.Row>
  );
};

const mapStateToProps = ({ sprayDiary: { applicationType } }) => ({
  applicationType
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setApplicationType
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationType);
