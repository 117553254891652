import PropTypes from "prop-types";
import React, { useState } from "react";
import classNames from "classnames";
import styles from "./MapFilter.module.css";
import { Checkbox, Icon } from "semantic-ui-react";
import DateRangePicker from "../../../../../components/DateRangePicker";
import { connect, useDispatch } from "react-redux";
import { checkToken, getOrganisations, getUrl } from "actions/JhonDeere";
import Loader from "components/Loader";

const MapFilter = ({
  className,
  yieldDateRange,
  tagsDateRange,
  setYieldDateRange,
  setTagsDateRange,
  setShowMachineries,
  showMachineries,
  yieldActive,
  setYieldActive,
  tagsActive,
  setTagsActive,
  isFetching
}) => {
  const [opened, setOpened] = useState(false);

  const dispatch = useDispatch();

  const onShowMachineries = async () => {
    const currentURL = {
      clientRedirectUrl: window.location.href.replace(/\?.*$/g, "")
    };
    setShowMachineries(!showMachineries);
    if (!showMachineries) {
      const tokenLocalChecker = await dispatch(checkToken());
      if (tokenLocalChecker) {
        dispatch(getOrganisations());
      } else {
        let actualUrl;
        dispatch(getUrl(currentURL, url => (actualUrl = url))).then(() => {
          if (actualUrl) {
            window.location.href = actualUrl;
          }
        });
      }
    }
  };

  return (
    <div
      className={classNames(
        className,
        styles.dropdown,
        opened && styles.opened
      )}
    >
      <div className={styles.headingRow} onClick={() => setOpened(!opened)}>
        <span className={styles.title}>Map filter</span>
        <Icon name={"dropdown"} style={{ lineHeight: 1 }} />
      </div>
      {opened && (
        <>
          <hr className={styles.hr} />
          <div
            className={styles.headingRow}
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            <span>Yield</span>
            <Checkbox
              className={styles.checkbox}
              toggle
              checked={yieldActive}
              onChange={() => setYieldActive(!yieldActive)}
            />
          </div>
          <DateRangePicker
            name={"yieldDateRange"}
            onChange={setYieldDateRange}
            value={yieldDateRange}
          />
          <div
            className={styles.headingRow}
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            <span>Tags</span>
            <Checkbox
              className={styles.checkbox}
              toggle
              checked={tagsActive}
              onChange={() => setTagsActive(!tagsActive)}
            />
          </div>
          <DateRangePicker
            name={"tagsDateRange"}
            onChange={setTagsDateRange}
            value={tagsDateRange}
          />
          <div
            className={styles.headingRow}
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            <span className={styles.titleWrapper}>
              Show Machinery {isFetching && <Loader />}{" "}
            </span>
            <Checkbox
              disabled={isFetching}
              className={styles.checkbox}
              toggle
              checked={showMachineries}
              onChange={() => onShowMachineries()}
            />
          </div>
        </>
      )}
    </div>
  );
};

MapFilter.propTypes = {
  className: PropTypes.string,
  setTagsActive: PropTypes.func,
  setTagsDateRange: PropTypes.func,
  setYieldActive: PropTypes.func,
  setYieldDateRange: PropTypes.func,
  tagsActive: PropTypes.bool,
  tagsDateRange: PropTypes.shape({
    end: PropTypes.string.isRequired,
    start: PropTypes.string.isRequired
  }),
  yieldActive: PropTypes.bool,
  yieldDateRange: PropTypes.shape({
    end: PropTypes.string.isRequired,
    start: PropTypes.string.isRequired
  })
};
function mapStateToProps(state) {
  const {
    johnDeere: { url, checkTokenStatus, isFetching }
  } = state;
  return {
    url,
    checkTokenStatus,
    isFetching
  };
}
export default connect(mapStateToProps)(MapFilter);
