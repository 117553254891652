export const actionTypes = {
  NUTRIENT_BREAKDOWN_TABLE_DATA_START:
    "REPORTS/NUTRIENT_BREAKDOWN_TABLE_DATA_START",
  NUTRIENT_BREAKDOWN_TABLE_DATA: "REPORTS/NUTRIENT_BREAKDOWN_TABLE_DATA",
  NUTRIENT_BREAKDOWN_TABLE_DATA_FAILED:
    "REPORTS/NUTRIENT_BREAKDOWN_TABLE_DATA_FAILED",

  NUTRIENT_BREAKDOWN_TABLE_DATA_LIST_START:
    "REPORTS/NUTRIENT_BREAKDOWN_TABLE_DATA_LIST_START",
  NUTRIENT_BREAKDOWN_TABLE_DATA_LIST:
    "REPORTS/NUTRIENT_BREAKDOWN_TABLE_DATA_LIST",
  NUTRIENT_BREAKDOWN_TABLE_DATA_LIST_FAILED:
    "REPORTS/NUTRIENT_BREAKDOWN_TABLE_DATA_LIST_FAILED",

  PRODUCT_TYPE_USAGE_TABLE_DATA_START:
    "REPORTS/PRODUCT_TYPE_USAGE_TABLE_DATA_START",
  PRODUCT_TYPE_USAGE_TABLE_DATA: "REPORTS/PRODUCT_TYPE_USAGE_TABLE_DATA",
  PRODUCT_TYPE_USAGE_TABLE_DATA_FAILED:
    "REPORTS/PRODUCT_TYPE_USAGE_TABLE_DATA_FAILED",

  PRODUCT_TYPE_USAGE_TABLE_DATA_LIST_START:
    "REPORTS/PRODUCT_TYPE_USAGE_TABLE_DATA_LIST_START",
  PRODUCT_TYPE_USAGE_TABLE_DATA_LIST:
    "REPORTS/PRODUCT_TYPE_USAGE_TABLE_DATA_LIST",
  PRODUCT_TYPE_USAGE_TABLE_DATA_LIST_FAILED:
    "REPORTS/PRODUCT_TYPE_USAGE_TABLE_DATA_LIST_FAILED",

  PRODUCT_USAGE_TABLE_DATA_START: "REPORTS/PRODUCT_USAGE_TABLE_DATA_START",
  PRODUCT_USAGE_TABLE_DATA: "REPORTS/PRODUCT_USAGE_TABLE_DATA",
  PRODUCT_USAGE_TABLE_DATA_FAILED: "REPORTS/PRODUCT_USAGE_TABLE_DATA_FAILED",

  PRODUCT_USAGE_TABLE_DATA_LIST_START:
    "REPORTS/PRODUCT_USAGE_TABLE_DATA_LIST_START",
  PRODUCT_USAGE_TABLE_DATA_LIST: "REPORTS/PRODUCT_USAGE_TABLE_DATA_LIST",
  PRODUCT_USAGE_TABLE_DATA_LIST_FAILED:
    "REPORTS/PRODUCT_USAGE_TABLE_DATA_LIST_FAILED"
};
