import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Message } from 'semantic-ui-react';
import styles from './ErrorBar.module.css';

const ErrorBar = ({ showError, onHide, error, className }) => {
  return showError ? (
    <div className={`${styles.errorHolder} ${className}`}>
      {onHide && (
        <Button className="button-text" onClick={onHide}>
          <Icon name="close" />
        </Button>
      )}
      <Message color="red">{error && error.message ? error.message : 'Error occurred. Try again later.'}</Message>
    </div>
  ) : null;
};

ErrorBar.propTypes = {
  showError: PropTypes.bool,
  onHide: PropTypes.func,
  error: PropTypes.object,
  className: PropTypes.string,
};

ErrorBar.defaultProps = {
  className: '',
};
export default ErrorBar;
