import React from "react";

import CollapsibleBlock from "../../../../../components/CollapsibleBlock";
import { Table } from "semantic-ui-react";
import { get } from "lodash";
import { isEmptyValue } from "../../../../../utils/tasksUtils";
import styles from "../Sidebar.module.css";

const ContactDetailsSection = ({ employee, editButton }) => {
  return (
    <CollapsibleBlock
      title="Contact Details"
      headerOptionalComponent={editButton}
      className={styles.blockContainer}
    >
      <Table className={styles.sectionTable} definition fixed>
        <Table.Body>
          <Table.Row>
            <Table.Cell width={6}>Email</Table.Cell>
            <Table.Cell width={10}>
              {isEmptyValue(get(employee, "email"))}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Phone</Table.Cell>
            <Table.Cell>{isEmptyValue(get(employee, "phone"))}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </CollapsibleBlock>
  );
};

export default ContactDetailsSection;
