export default async function asyncForEach(array, callback) {
  try {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  } catch (e) {
    //eslint-disable-next-line
    console.log(e);
  }
}
