import React from "react";
import PropTypes from "prop-types";
import { Menu, Checkbox, Grid, Form } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  setRowsToSpray,
  setActualRowsToSprayCheckState,
  setActualRowsToSpray
} from "../../../../actions/SprayDiary/common";

const RowsToSpray = ({
  rowsToSpray,
  actions,
  status,
  actualRowsToSprayChecked,
  actualRowsToSpray,
  rowsToSprayOptions
}) => {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column widescreen={4} largeScreen={6} mobile={16} tablet={16}>
          <Form.Field className={status === "COMPLETED" ? "disabled" : ""}>
            <label className="title">Rows to spray</label>
            <Menu fluid compact className="sprayMenu">
              {rowsToSprayOptions.map(option => (
                <Menu.Item
                  key={option.key}
                  active={rowsToSpray === option.value}
                  onClick={
                    status !== "COMPLETED"
                      ? () => actions.setRowsToSpray(option.value)
                      : null
                  }
                >
                  {option.text}
                </Menu.Item>
              ))}
            </Menu>
          </Form.Field>
        </Grid.Column>
        {status === "COMPLETED" && (
          <Grid.Column widescreen={4} largeScreen={6} mobile={16} tablet={16}>
            <Form.Field required className="sprayField">
              <label className={`title`}>
                <Checkbox
                  onChange={(_, data) =>
                    actions.setActualRowsToSprayCheckState(data.checked)
                  }
                  checked={actualRowsToSprayChecked}
                />
                I sprayed the rows in this manner
              </label>
              {!actualRowsToSprayChecked && (
                <Menu fluid compact className="sprayMenu">
                  {rowsToSprayOptions.map(option => (
                    <Menu.Item
                      key={option.key}
                      active={actualRowsToSpray === option.value}
                      onClick={() => actions.setActualRowsToSpray(option.value)}
                    >
                      {option.text}
                    </Menu.Item>
                  ))}
                </Menu>
              )}
            </Form.Field>
          </Grid.Column>
        )}
      </Grid.Row>
    </Grid>
  );
};

RowsToSpray.propTypes = {
  rowsToSpray: PropTypes.string,
  status: PropTypes.string,
  actualRowsToSprayChecked: PropTypes.bool,
  actualRowsToSpray: PropTypes.any,
  actions: PropTypes.object,
  rowsToSprayOptions: PropTypes.array
};

const mapStateToProps = ({
  sprayDiary: {
    rowsToSprayOptions,
    actualRowsToSprayChecked,
    status,
    rowsToSpray,
    actualRowsToSpray
  }
}) => ({
  rowsToSprayOptions,
  rowsToSpray,
  actualRowsToSprayChecked,
  status,
  actualRowsToSpray
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setRowsToSpray,
      setActualRowsToSprayCheckState,
      setActualRowsToSpray
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(RowsToSpray);
