import { actionType } from '../../constants/WeatherStation';
import { axiosProxy } from '../../utils/axiosProxy';

const url = '/weather_stations';

export const clearWeatherStationState = () => async dispatch => {
  await dispatch({
    type: actionType.WEATHER_STATION_CLEAR_STATE,
  });
};

export const fetchWeatherStationsList = () => async dispatch => {
  try {
    dispatch({
      type: actionType.FETCH_WEATHER_STATIONS_LIST_START,
    });

    const params = new URLSearchParams();
    params.append('include_extra_data', false);
    params.append('unpaged', true);

    ['displayName,asc'].forEach(field => {
      params.append('sort', field);
    });

    const res = await axiosProxy({
      method: 'GET',
      params,
      url,
    });

    await dispatch({
      type: actionType.FETCH_WEATHER_STATIONS_LIST,
      payload: res.data.content,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.FETCH_WEATHER_STATIONS_LIST_FAIL,
      payload: error,
    });
  }
};

export const fetchWeatherStations = ({ page, size, search, sort }) => async dispatch => {
  try {
    dispatch({
      type: actionType.FETCH_WEATHER_STATIONS_START,
    });

    const params = new URLSearchParams();
    params.append('page', page);
    params.append('size', size);

    if (search) {
      params.append('search', search);
    }

    (sort || ['id,asc']).forEach(field => {
      params.append('sort', field);
    });

    const res = await axiosProxy({
      method: 'GET',
      params,
      url,
    });

    await dispatch({
      type: actionType.FETCH_WEATHER_STATIONS,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.FETCH_WEATHER_STATIONS_FAIL,
      payload: error,
    });
  }
};

export const getWeatherStationById = id => async dispatch => {
  try {
    dispatch({
      type: actionType.GET_WEATHER_STATION_BY_ID_START,
    });
    const res = await axiosProxy({
      method: 'GET',
      url: `${url}/${id}`,
    });

    dispatch({
      type: actionType.GET_WEATHER_STATION_BY_ID,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.GET_WEATHER_STATION_BY_ID_FAIL,
      payload: error,
    });
    return Promise.reject(error);
  }
};

export const addWeatherStation = data => async dispatch => {
  try {
    dispatch({
      type: actionType.ADD_WEATHER_STATION_START,
    });
    const res = await axiosProxy({
      method: 'POST',
      url,
      data,
    });

    await dispatch({
      type: actionType.ADD_WEATHER_STATION,
      payload: res.data,
    });

    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.ADD_WEATHER_STATION_FAIL,
      payload: error,
    });
    return { error };
  }
};

export const updateWeatherStation = (id, data) => async dispatch => {
  try {
    dispatch({
      type: actionType.UPDATE_WEATHER_STATION_START,
    });
    const res = await axiosProxy({
      method: 'PUT',
      data,
      url: `${url}/${id}`,
    });

    await dispatch({
      type: actionType.UPDATE_WEATHER_STATION,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.UPDATE_WEATHER_STATION_FAIL,
      payload: error,
    });
    return { error };
  }
};

export const deleteWeatherStation = id => async dispatch => {
  try {
    dispatch({
      type: actionType.DELETE_WEATHER_STATION_START,
    });
    await axiosProxy({
      method: 'DELETE',
      url: `${url}/${id}`,
    });

    await dispatch({
      type: actionType.DELETE_WEATHER_STATION,
      payload: id,
    });
  } catch (error) {
    dispatch({
      type: actionType.DELETE_WEATHER_STATION_FAIL,
      payload: error,
    });
  }
};

export const getFormattedWeatherStationsDataBetween = (id, dataGroup, from, to) => async dispatch => {
  try {
    dispatch({
      type: actionType.WEATHER_STATION_GET_DATA_BETWEEN_START,
    });

    const params = new URLSearchParams();
    params.append('formatted', true);
    params.append('from', from);
    params.append('to', to);
    params.append('dataGroup', dataGroup);
    const res = await axiosProxy({
      method: 'GET',
      params,
      url: `${url}/${id}/data/between`,
    });

    await dispatch({
      type: actionType.WEATHER_STATION_GET_DATA_BETWEEN,
      payload: { dataGroup, ...res.data },
    });
  } catch (error) {
    dispatch({
      type: actionType.WEATHER_STATION_GET_DATA_BETWEEN_FAIL,
      payload: error,
    });
  }
};

export const getTaskWeatherCondition = (id, from, to) => async dispatch => {
  try {
    dispatch({
      type: actionType.WEATHER_STATION_GET_TASK_WEATHER_CONDITION_START,
    });

    const params = new URLSearchParams();
    params.append('from', from);
    params.append('to', to);
    const res = await axiosProxy({
      method: 'GET',
      params,
      url: `${url}/${id}/task_weather_condition`,
    });
    await dispatch({
      type: actionType.WEATHER_STATION_GET_TASK_WEATHER_CONDITION,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: actionType.WEATHER_STATION_GET_TASK_WEATHER_CONDITION_FAIL,
      payload: error,
    });
  }
};

export const setShowCharts = show => dispatch => {
  dispatch({
    type: actionType.WEATHER_STATION_SET_STATION_DATA_FIELD,
    payload: { showCharts: show },
  });
};

export const setShowDataTable = show => dispatch => {
  dispatch({
    type: actionType.WEATHER_STATION_SET_STATION_DATA_FIELD,
    payload: { showStationDataTable: show },
  });
};

export const clearError = () => dispatch => {
  dispatch({
    type: actionType.WEATHER_STATION_CLEAR_ERROR,
  });
};
