import { BaseModel } from "./base.model";

export class AreaVariety extends BaseModel {
  age;
  rootstock;
  rowsCount;
  rowsSpacing;
  plantsSpacing;
  plantsSpacingAcrossTheBed;
  numberOfPlants;

  constructor(data = {}) {
    super(data);

    if (data.variety && data.variety.crop) {
      this.crop = data.variety.crop;
    }
  }

  get name() {
    return this.variety ? this.variety.name : "";
  }
}
