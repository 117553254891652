export const actionType = {
  GENERATE_TAG_BARCODES_START: "TAG_BARCODES/GENERATE_TAG_BARCODES_START",
  GENERATE_TAG_BARCODES: "TAG_BARCODES/GENERATE_TAG_BARCODES",
  GENERATE_TAG_BARCODES_FAIL: "TAG_BARCODES/GENERATE_TAG_BARCODES_FAIL",
  TAG_BARCODES_CLEAR_SERIES: "TAG_BARCODES/CLEAR_SERIES",
  TAG_BARCODES_CLEAR_ERROR: "TAG_BARCODES/CLEAR_ERROR",

  FETCH_SCANNED_TAG_BARCODES: "TAG_BARCODES/FETCH_SCANNED_TAG_BARCODES",
  FETCH_SCANNED_TAG_BARCODES_START:
    "TAG_BARCODES/FETCH_SCANNED_TAG_BARCODES_START",
  FETCH_SCANNED_TAG_BARCODES_FAIL:
    "TAG_BARCODES/FETCH_SCANNED_TAG_BARCODES_FAIL"
};
