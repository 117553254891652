import PropTypes from "prop-types";

export const areaTypes = ["BLOCK", "PATCH"];
export const scoutingIssueTypes = {
  PEST_AND_DISEASE: "PEST_AND_DISEASE",
  NUTRIENT_DEFICIENCY: "NUTRIENT_DEFICIENCY",
  GENERAL: "GENERAL"
};

export const scoutingIssueAttachmentShape = PropTypes.shape({
  id: PropTypes.number,
  attachment: PropTypes.shape({
    subPath: PropTypes.string,
    id: PropTypes.string,
    presignedUrl: PropTypes.string,
    name: PropTypes.string
  }),
  comment: PropTypes.string,
  coordinates: PropTypes.arrayOf(PropTypes.number)
});

export const scoutingAreaIssueShape = PropTypes.shape({
  id: PropTypes.number,
  type: PropTypes.oneOf(Object.keys(scoutingIssueTypes)),
  rowNumber: PropTypes.number,
  attachments: PropTypes.arrayOf(scoutingIssueAttachmentShape),
  comment: PropTypes.string,
  pressureRating: PropTypes.number
});

export const scoutingAreaShape = PropTypes.shape({
  id: PropTypes.number,
  area: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    farm: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      farmStructure: PropTypes.string,
      archived: PropTypes.bool
    }),
    type: PropTypes.oneOf(areaTypes),
    parent: null,
    hasSubAreas: PropTypes.bool,
    registrationNumber: PropTypes.string,
    isRotating: PropTypes.bool,
    size: PropTypes.number,
    comments: null,
    archived: PropTypes.bool,
    geojson: PropTypes.shape({
      type: PropTypes.string,
      features: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
          geometry: PropTypes.shape({
            type: PropTypes.string,
            coordinates: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
          })
        })
      )
    })
  }),
  areaId: PropTypes.number,
  comment: PropTypes.string,
  areaIssues: PropTypes.arrayOf(scoutingAreaIssueShape)
});

export const scoutingShape = PropTypes.shape({
  archived: PropTypes.bool,
  areas: PropTypes.arrayOf(scoutingAreaShape),
  comment: PropTypes.string,
  doneOn: PropTypes.string,
  draft: PropTypes.bool,
  generalTaskResponse: PropTypes.any,
  id: PropTypes.number,
  name: PropTypes.string,
  creator: PropTypes.shape({
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.shape
  }),
  recommendations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      taskName: PropTypes.string
    })
  ),
  recommendationId: null,
  setOn: PropTypes.string
});

export const scoutingFormElementShape = PropTypes.shape({
  formik: PropTypes.shape({
    handleSubmit: PropTypes.func,
    handleChange: PropTypes.func,
    setFieldValue: PropTypes.func,
    values: scoutingShape
  })
});
