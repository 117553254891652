import PropTypes from "prop-types";
import React, { memo } from "react";
import {
  Table,
  TableBody,
  TableHeader as Header,
  TableHeaderCell,
  TableRow
} from "semantic-ui-react";
import TaskRow from "./TaskRow/index";
import styles from "./index.module.css";
import "../../index.css";

const OperatingCostTable = ({ gruppedArray }) => {
  return (
    <div className={styles.breakdownTableContainer}>
      <Table className={styles.operatinCostTable} unstackable>
        <Header>
          <TableRow>
            <TableHeaderCell style={{ width: 30 }} />
            <TableHeaderCell>Cost type</TableHeaderCell>
            <TableHeaderCell style={{ width: "180px" }}>Total</TableHeaderCell>
          </TableRow>
        </Header>
        <TableBody>
          {gruppedArray.map((item, i) => {
            return <TaskRow key={`item_${i}`} task={item} />;
          })}
        </TableBody>
      </Table>
    </div>
  );
};

OperatingCostTable.propTypes = {
  breakdownData: PropTypes.any,
  totalBreakdown: PropTypes.any
};

export default memo(OperatingCostTable);
