export const actionType = {
  FETCH_DISPATCHES_START: 'DISPATCHES/FETCH_START',
  FETCH_DISPATCHES: 'DISPATCHES/FETCH',
  FETCH_DISPATCHES_FAIL: 'DISPATCHES/FETCH_FAIL',

  FETCH_DISPATCHES_LIST_START: 'DISPATCHES/FETCH_LIST_START',
  FETCH_DISPATCHES_LIST: 'DISPATCHES/FETCH_LIST',
  FETCH_DISPATCHES_LIST_FAIL: 'DISPATCHES/FETCH_LIST_FAIL',

  GET_DISPATCH_BY_ID_START: 'DISPATCHES/GET_BY_ID_START',
  GET_DISPATCH_BY_ID: 'DISPATCHES/GET_BY_ID',
  GET_DISPATCH_BY_ID_FAIL: 'DISPATCHES/GET_BY_ID_FAIL',

  ADD_DISPATCH_START: 'DISPATCHES/ADD_START',
  ADD_DISPATCH: 'DISPATCHES/ADD',
  ADD_DISPATCH_FAIL: 'DISPATCHES/ADD_FAIL',

  UPDATE_DISPATCH_START: 'DISPATCHES/UPDATE_START',
  UPDATE_DISPATCH: 'DISPATCHES/UPDATE',
  UPDATE_DISPATCH_FAIL: 'DISPATCHES/UPDATE_FAIL',

  DELETE_DISPATCH_START: 'DISPATCHES/DELETE_START',
  DELETE_DISPATCH: 'DISPATCHES/DELETE',
  DELETE_DISPATCH_FAIL: 'DISPATCHES/DELETE_FAIL',

  CLEAR_DISPATCHES_ERROR: 'DISPATCHES/CLEAR_DISPATCHES_ERROR',
};
