import React, { useEffect } from "react";
import SelectedAreasTable from "routes/Recommendations/components/SelectedAreasTable";
import { bindActionCreators } from "redux";
import { fetchAreasList } from "actions/Areas/areas";
import { connect, useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { get } from "lodash";
import { Header } from "semantic-ui-react";
import { parentBlocksSelector } from "selectors/sprayDiary";

const SelectedAreasContainer = ({ areasList, isFetching, actions }) => {
  const { values: recommendationTask } = useFormikContext();
  const { showParentBlocks } = useSelector(state => state.applicationSettings);
  const parentBlocks = useSelector(parentBlocksSelector);

  useEffect(() => {
    if (!areasList && !isFetching) {
      actions.fetchAreasList();
    }
  }, [areasList, isFetching]);

  if (!get(recommendationTask, "areas.length")) {
    return null;
  }

  return (
    <>
      <Header as="h3">Apply to areas</Header>
      <SelectedAreasTable
        recommendationTask={recommendationTask}
        showParentBlocksOnly={showParentBlocks}
        parentBlocks={parentBlocks}
        withTanksExplanation
      />
    </>
  );
};

const mapStateToProps = state => {
  return {
    areasList: state.areas.list.content,
    isFetching: state.areas.isFetching
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchAreasList
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectedAreasContainer);
