import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  Segment,
  Button,
  Form,
  Input,
  Message,
  Icon,
  Confirm
} from "semantic-ui-react";
import { Field, useFormik } from "formik";
import SwitchButton from "components/SwitchButton";
import styles from "./Inventory.module.css";
import * as Yup from "yup";
import { shedsOptionsSelector } from "selectors/sheds";
import { get } from "lodash";

const options = [
  {
    key: "STOCK_ADDITION",
    label: "Add",
    value: "ADD"
  },
  {
    key: "STOCK_DELETION",
    label: "Remove",
    value: "REMOVE"
  }
];

let operationTypes = [
  {
    key: "STOCK_CORRECTION",
    text: "Stock correction",
    value: "STOCK_CORRECTION"
  },
  {
    key: "SHED_TRANSFER",
    text: "Shed transfer",
    value: "SHED_TRANSFER"
  },
  {
    key: "OTHER",
    text: "Other",
    value: "OTHER"
  }
];

const InventoryEditForm = ({
  onSubmit,
  onCancel,
  sheds,
  shedName,
  shedId,
  inventory: {
    productName,
    initialPrice,
    currentInventory,
    unit,
    lastPrice,
    firstPrice
  }
}) => {
  const [secondOpen, setSecondOpen] = useState(false);
  const shedsOptions = useSelector(shedsOptionsSelector).filter(
    item => item.key !== shedId
  );
  const validationSchema = Yup.object().shape({
    price: Yup.number().required("Price is required"),
    actionOption: Yup.string().required("Operation type is required"),
    quantity: Yup.number().required("Phone number is not valid"),
    // shedTo: Yup.number().required("Shed is required") || {},
    expectedInventory: Yup.number()
      .required("quantity is required")
      .notOneOf([Yup.ref("currentInventory")], "Passwords don't match!")
  });
  const {
    values,
    handleSubmit,
    dirty = currentInventory < 0 ? true : dirty,
    isValid,
    setValues,
    initialValues
  } = useFormik({
    initialValues: {
      currentInventory,
      price: currentInventory < 0 ? "" : firstPrice,
      operationType: "STOCK_CORRECTION",
      actionOption: "REMOVE",
      quantity: currentInventory < 0 ? currentInventory * -1 : "",
      expectedInventory: currentInventory < 0 ? 0 : ""
    },
    onSubmit,
    validationSchema
  });
  const valuesValidation = values.price > 0;

  const operation = useMemo(() => {
    if (values.currentInventory < 0 || sheds.length <= 1) {
      return operationTypes.filter(o => o.key !== "SHED_TRANSFER");
    } else {
      return operationTypes;
    }
  });

  const handleInputChange = (accesser, data) => {
    const inputName =
      typeof accesser === "object" && accesser !== null
        ? accesser.target.name
        : accesser;
    const inputValue =
      typeof data === "object" && data !== null ? data.value : data;
    switch (inputName) {
      case "expectedInventory":
        setValues({
          ...values,
          [inputName]: inputValue,
          quantity: Math.abs(values.currentInventory - inputValue),
          actionOption: values.currentInventory > inputValue ? "REMOVE" : "ADD"
        });
        break;
      case "quantity":
        setValues({
          ...values,
          [inputName]: inputValue,
          expectedInventory: values.actionOption
            ? values.currentInventory +
              inputValue * (values.actionOption === "ADD" ? 1 : -1)
            : values.expectedInventory,
          operationType:
            values.actionOption === "REMOVE" &&
            values.operationType !== "SHED_TRANSFER" &&
            inputValue == values.currentInventory
              ? "STOCK_DELETION"
              : values.operationType === "SHED_TRANSFER"
              ? "SHED_TRANSFER"
              : "STOCK_CORRECTION"
        });
        break;
      case "actionOption":
        setValues({
          ...values,
          [inputName]: inputValue,
          expectedInventory: values.quantity
            ? values.currentInventory +
              values.quantity * (inputValue === "ADD" ? 1 : -1)
            : values.expectedInventory,
          price: inputValue === "REMOVE" ? firstPrice : lastPrice
        });
        break;
      case "price":
        setValues({
          ...values,
          [inputName]:
            values.actionOption === "REMOVE" ? firstPrice : inputValue
        });
        break;
      case "operationType":
        const actionOption = inputValue === "SHED_TRANSFER" ? "REMOVE" : "ADD";
        const price = inputValue === "SHED_TRANSFER" ? firstPrice : lastPrice;
        setValues({
          ...values,
          currentInventory,
          [inputName]: inputValue,
          price,
          actionOption,
          expectedInventory: values.quantity
            ? values.currentInventory +
              values.quantity * (actionOption === "ADD" ? 1 : -1)
            : values.expectedInventory
        });
      case "shedTo":
        setValues({
          ...values,
          [inputName]: inputValue,
          expectedInventory: values.quantity
            ? values.currentInventory - values.quantity
            : values.expectedInventory,
          operationType:
            operation === "SHED_TRANSFER" && values.expectedInventory != 0
              ? "SHED_TRANSFER"
              : "STOCK_DELETION"
        });
      case "reason":
        setValues({
          ...values,
          [inputName]: inputValue
        });
        break;
    }
  };
  return (
    <Segment basic className={styles.editForm}>
      <Form>
        <h1 className={styles.productHeader}>{productName}</h1>
        <Form.Group>
          <Form.Field width={8} className={styles.productRow}>
            <Field
              as={Input}
              disabled
              className={
                values.currentInventory < 0
                  ? "negativeElement"
                  : "editCurrentInventoryField"
              }
              name="currentInventory"
              label="Expected inventory"
              value={values.currentInventory}
            />
          </Form.Field>
          <Form.Field width={8}>
            <label
              className={
                values.expectedInventory < 0 && styles.warningStyle_label
              }
            >
              Current inventory
            </label>
            <Input
              min={0}
              name="expectedInventory"
              className={values.expectedInventory < 0 && "warningStyle"}
              label={unit}
              labelPosition="right"
              required
              value={values.expectedInventory}
              onChange={handleInputChange}
              onKeyPress={event => {
                if (!/^[\.0-9]*$/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field width={16} required>
            <Form.Dropdown
              selection
              search
              name="operationType"
              label="Reason"
              required
              options={operation}
              onChange={(_, value) => handleInputChange("operationType", value)}
              value={values.operationType}
            />
          </Form.Field>
        </Form.Group>

        {values.operationType === "OTHER" && (
          <Form.Group>
            <Form.Field width={16}>
              <label>Your reason</label>
              <Input
                min={0}
                placeholder="Type the reason"
                maxLength="25"
                name="reason"
                onChange={handleInputChange}
              />
              <div className={styles.reasonMessage}>
                The name length is 24 characters max
              </div>
            </Form.Field>
          </Form.Group>
        )}

        {values.operationType === "SHED_TRANSFER" && (
          <Form.Group>
            <Form.Field required width={16}>
              <Form.Dropdown
                selection
                search
                name="shedTo"
                label="Transfer to shed"
                required
                options={shedsOptions}
                onChange={(_, value) => handleInputChange("shedTo", value)}
                value={get(values, "chemicalId")}
              />
            </Form.Field>
          </Form.Group>
        )}
        <Form.Group>
          <Form.Field className={styles.switchButton} width={5} required>
            <Field
              as={SwitchButton}
              items={options}
              title="Choose an option"
              name="actionOption"
              required
              value={
                values.operationType === "SHED_TRANSFER"
                  ? (values.actionOption = "REMOVE")
                  : values.currentInventory < 0
                  ? (values.actionOption = "ADD")
                  : values.actionOption
              }
              width={16}
              disabled={
                values.operationType === "SHED_TRANSFER" ||
                values.currentInventory < 0
              }
              onChange={(name, value) => handleInputChange(name, value)}
            />
          </Form.Field>
          <Form.Field width={5}>
            <label
              className={
                values.expectedInventory < 0 && styles.warningStyle_label
              }
            >
              {values.operationType === "SHED_TRANSFER"
                ? "Quantity to be transfered"
                : "Quantity to add/remove"}
            </label>
            <Input
              min={0}
              name="quantity"
              className={values.expectedInventory < 0 && "warningStyle"}
              label={unit}
              labelPosition="right"
              value={values.quantity}
              onChange={handleInputChange}
              onKeyPress={event => {
                if (!/^[\.0-9]*$/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </Form.Field>
          <Form.Field
            width={6}
            required /* ={
              values.currentInventory < 0 && values.expectedInventory !== 0
            } */
          >
            <label>Price ($/{unit})</label>
            <Input
              min={0}
              name="price"
              label={`$/${unit}`}
              labelPosition="right"
              required /* ={
                values.currentInventory < 0 && values.expectedInventory !== 0
              } */
              value={values.price}
              disabled={
                values.actionOption === "REMOVE" &&
                values.operationType !== "SHED_TRANSFER" &&
                !!values.price
              }
              onChange={handleInputChange}
              onKeyPress={event => {
                if (!/^[\.0-9]*$/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </Form.Field>
        </Form.Group>
        {values.expectedInventory == 0 && values.expectedInventory !== "" && (
          <Message className={styles.warningMessagesInModal} color="yellow">
            <Icon name="info circle" />
            {values.currentInventory < 0
              ? `Your expected inventory for  ${productName} is negative. Please add a price to 
              reconciliate your expected inventory.
              `
              : `As you will have zero ${productName} at ${currentInventory} in
            ${shedName} after the edit, this product will disappear
            form your shed's inventory`}
          </Message>
        )}
        {values.expectedInventory < 0 &&
          values.operationType !== "SHED_TRANSFER" && (
            <Message className={styles.warningMessagesInModal} color="red">
              <Icon name="info circle" />
              You can't remove more than your current inventory level for this
              product.
            </Message>
          )}
        {values.expectedInventory < 0 &&
          values.operationType === "SHED_TRANSFER" && (
            <Message className={styles.warningMessagesInModal} color="red">
              <Icon name="info circle" />
              Quantity to be transferred should be positive
            </Message>
          )}

        <div className={styles.productEditButtonContainer}>
          {onCancel && (
            <Button
              className={styles.cancelBtn}
              onClick={() => {
                values.quantity || values.expectedInventory
                  ? setSecondOpen(true)
                  : onCancel();
              }}
              quantity
            >
              Cancel
            </Button>
          )}
          <Button
            className={
              !dirty ||
              !isValid ||
              (values.operationType === "SHED_TRANSFER" && !values.shedTo) ||
              !valuesValidation
                ? styles.disabledBtn
                : styles.saveBtn
            }
            disabled={
              !isValid ||
              values.expectedInventory < 0 ||
              (values.operationType === "SHED_TRANSFER" && !values.shedTo) ||
              !valuesValidation
            }
            onClick={handleSubmit}
          >
            Save
          </Button>
        </div>
      </Form>
      <Confirm
        className={styles.confirmModal}
        content="Are you sure you want to close the modal window?"
        open={secondOpen}
        onCancel={() => setSecondOpen(false)}
        onConfirm={() => onCancel()}
      />
    </Segment>
  );
};

InventoryEditForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func
};

export default InventoryEditForm;
