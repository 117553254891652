import { actionType } from '../../constants/Menu';

const initialState = {
  selectedMenu: null,
};

export default (state = initialState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case actionType.SELECT_MENU_ITEM:
      return {
        ...state,
        selectedMenu: action.payload,
      };
    default:
      return state;
  }
};
