import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import CollapsibleBlock from "components/CollapsibleBlock";
import { chemicalsOptionsSelector } from "selectors/chemicals";
import {
  addThresholds,
  deleteThresholds
} from "../../../../../../../actions/Sheds";
import { Container, Grid, Button, Segment } from "semantic-ui-react";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import ThresholdForm from "./ThresholdForm";
import ThresholdRow from "./ThresholdRow";
import styles from "./Threshold.module.css";

const Thresholds = ({ shed, setThresholdChekcer }) => {
  const { values, setFieldValue, validateForm } = useFormikContext();
  const thresholdsList = useSelector(chemicalsOptionsSelector);
  const [addButtonCheck, setAddButtonCheck] = useState(true);
  const [deleteButtonCheck, setDeleteButtonCheck] = useState(false);
  const thresholdsListCheck = values.thresholds?.length;

  const onNewThresholdAdd = async thresholdValues => {
    setFieldValue(
      "thresholds",
      values.thresholds
        ? [...values.thresholds, thresholdValues]
        : [thresholdValues]
    );
    validateForm();
    setAddButtonCheck(true);
  };

  const addProduct = () => {
    setAddButtonCheck(false);
    setDeleteButtonCheck(true);
  };
  const deleteAddingForm = () => {
    setAddButtonCheck(true);
  };
  useEffect(() => {
    setThresholdChekcer(!addButtonCheck);
  }, [addButtonCheck, thresholdsListCheck]);
  return (
    <Segment>
      <CollapsibleBlock title="Thresholds" className={styles.root}>
        {!isEmpty(values.thresholds) && (
          <Grid columns="equal" as={Container}>
            <Grid.Column className={styles.rowLabel}>Product</Grid.Column>
            <Grid.Column className={styles.rowLabel}>Thresholds</Grid.Column>
            <Grid.Column />
            {values.thresholds.map(threshold => (
              <ThresholdRow
                thresholdsList={thresholdsList}
                thresholdData={threshold}
                key={threshold.id}
                thresholds={values.thresholds}
                shed={shed}
              />
            ))}
          </Grid>
        )}

        {thresholdsListCheck && addButtonCheck ? (
          <div className={styles.buttonContainer}>
            <Button
              type="submit"
              className="button-text color-green"
              onClick={addProduct}
            >
              + Add threshold
            </Button>
          </div>
        ) : null}

        {(!addButtonCheck || !thresholdsListCheck) && (
          <Segment>
            <ThresholdForm
              thresholdsListCheck={thresholdsListCheck}
              thresholdsList={thresholdsList}
              deleteAddingForm={deleteAddingForm}
              deleteButtonCheck={deleteButtonCheck}
              onSubmit={onNewThresholdAdd}
            />
          </Segment>
        )}
      </CollapsibleBlock>
    </Segment>
  );
};
export default Thresholds;
