import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { keyBy, get } from "lodash";
import {
  Grid,
  Header,
  Button,
  Segment,
  Sidebar,
  Icon,
  Container
} from "semantic-ui-react";
import { connect } from "react-redux";
import { abilitiesSelector } from "selectors/user";
import { getScoutingTaskReadableId, printScoutingList } from "utils/scouting";
import { getScoutingTableColumns } from "utils/scouting";
import Layout from "components/Layout";
import ListTable from "components/ListTable";
import ScoutingSidebar from "./components/ScoutingSidebar";
import Blank from "../../Blank";
import {
  archiveScoutingTasks,
  fetchScoutingTasks,
  fetchScouting,
  printingReportEnd,
  printingReportStart,
  setCurrentScouting,
  unArchiveScoutingTasks
} from "actions/Scouting/scouting";
import { onlineSelector } from "selectors/common";
import { scoutingShape } from "constants/Scouting/types";
import { bindActionCreators } from "redux";
import farmReportStyles from "../../Farms/Farms.module.css";
import styles from "./ScoutingList.module.css";
import TasksArchivedMessage from "./components/TasksArchivedMessage";
import IncludeMediaConfirmationModal from "./components/IncludeMediaConfirmationModal";
import { resizeImgOfData } from "utils/scouting";
import { setHashParameter } from "utils/hashToObject";

const ScoutingList = ({
  data,
  route,
  location,
  Can,
  currentScouting,
  isFetching,
  isDeleting,
  isPrinting,
  scoutingData = {},
  online,
  actions
}) => {
  const [tableColumns, setTableColumns] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [archivedItemsIds, setArchivedItems] = useState(null);
  const [itemsToPrint, setItemsToPrint] = useState(null);
  const [isArchivedView] = useState(location.pathname.includes("archived"));
  const { content: scoutingList } = scoutingData;

  const defaultSeason = data?.reduce(function(prev, current) {
    return prev.startAt > current.startAt ? prev : current;
  }, {});
  const defaultDate = [defaultSeason?.startAt, defaultSeason?.endAt];

  useEffect(() => {
    defaultSeason?.startAt &&
      defaultSeason?.endAt &&
      setHashParameter(location, "empty", defaultDate, null);
  }, []);
  const onRowClick = useCallback(
    ({ original: newScouting }) => {
      const selectedScoutingID = currentScouting ? currentScouting.id : null;
      const newScoutingID = newScouting ? newScouting.id : null;

      actions.setCurrentScouting(
        selectedScoutingID === newScoutingID ? null : newScouting
      );
    },
    [currentScouting]
  );

  const getScoutingData = useCallback(
    requestData => {
      const { filters } = requestData;
      const archived = location.pathname.includes("archived");
      const draft = location.pathname.includes("draft");
      setTimeout(() => {
        actions.fetchScoutingData({
          defaultDate,
          ...requestData,
          archived,
          draft
        });
      }, 650);
      setSelectedItems([]);
    },
    [location]
  );

  const printCurrentScouting = () => {
    setItemsToPrint([currentScouting]);
  };

  const printSelectedItems = () => {
    const idsMap = keyBy(
      selectedItems.map(i => Number(i.replace(/^\D+/g, "")))
    );
    const selectedScoutingTasks = scoutingList.filter(({ id }) => !!idsMap[id]);

    setItemsToPrint(selectedScoutingTasks);
  };

  const archiveSelectedTasks = () => {
    const idsList = selectedItems.map(i => Number(i.replace(/^\D+/g, "")));

    actions.archiveScoutingTasks(idsList).then(a => {
      setArchivedItems(idsList);
    });
    setSelectedItems([]);
  };

  const undoTasksArchiving = useCallback(() => {
    actions.unArchiveScoutingTasks(archivedItemsIds);
    setArchivedItems(null);
  }, [archivedItemsIds]);

  const onSidebarHide = useCallback(
    event => {
      const hasClosest = get(event, "target.closest");

      if (
        hasClosest &&
        (event.target.closest(".modals") ||
          (currentScouting &&
            event.target.closest(`#row-group-${currentScouting.id}`)))
      ) {
        return;
      }

      actions.setCurrentScouting(null);
    },
    [currentScouting]
  );

  const onIncludeMediaResponse = useCallback(
    async include => {
      setItemsToPrint(null);

      if (itemsToPrint) {
        actions.printingReportStart();
        const newItemsToPrint = include
          ? await resizeImgOfData(itemsToPrint)
          : itemsToPrint;
        actions.printingReportEnd();
        printScoutingList(newItemsToPrint, include);
      }
    },
    [itemsToPrint]
  );

  useEffect(
    function prepareData() {
      if (!scoutingList) {
        return;
      }

      const tableColumns = getScoutingTableColumns();
      const tableData = [];

      scoutingList.forEach(row => {
        const rowData = {
          rights: {
            update: "update",
            delete: "delete"
          }
        };

        Object.keys(row).forEach(key => {
          rowData[key] = row[key] || "";
        });
        tableData.push(rowData);
      });

      setTableColumns(tableColumns);
      setTableData(tableData);
    },
    [scoutingList]
  );

  return (
    <>
      <Can not I={"access_module"} a={"scouting"}>
        <Blank route={route} location={location} />
      </Can>
      <Can I={"access_module"} a={"scouting"}>
        <Layout
          route={route}
          location={location}
          classForMain={farmReportStyles.mainHolder}
        >
          <Sidebar.Pushable className={styles.holder}>
            <Sidebar
              animation="overlay"
              direction="right"
              visible={!!currentScouting}
              className={farmReportStyles.sidebarHolder}
              onHide={onSidebarHide}
            >
              {!!currentScouting && (
                <ScoutingSidebar
                  onPrintClick={printCurrentScouting}
                  isPrinting={isPrinting}
                  scouting={currentScouting}
                  Can={Can}
                  onClose={() => actions.setCurrentScouting(null)}
                />
              )}
            </Sidebar>
            <Sidebar.Pusher>
              <Grid divided="vertically">
                <Grid.Row columns={2} style={{ paddingBottom: 0 }}>
                  <Grid.Column>
                    <Header as="h2">{route.name}</Header>
                  </Grid.Column>
                  <Grid.Column />
                </Grid.Row>
              </Grid>
              <Segment>
                <Container fluid textAlign="right">
                  <Button
                    basic
                    className={styles.actionButton}
                    color="green"
                    size="large"
                    disabled={!selectedItems.length || isArchivedView}
                    onClick={archiveSelectedTasks}
                  >
                    <Icon name="archive" /> Archive
                  </Button>
                  <Button
                    basic
                    className={styles.actionButton}
                    color="green"
                    size="large"
                    disabled={!selectedItems.length || isPrinting}
                    onClick={printSelectedItems}
                  >
                    <span>
                      {!isPrinting ? (
                        <Icon name="print" />
                      ) : (
                        <Icon name="circle notch loading icon" />
                      )}
                      Print
                    </span>
                  </Button>
                </Container>
                <Grid>
                  <div style={{ width: "100%" }} className={styles.p1em}>
                    <ListTable
                      filterName={"empty"}
                      currentPage={scoutingData.number}
                      pageSize={scoutingData.size}
                      totalPages={scoutingData.totalPages}
                      data={tableData}
                      columns={tableColumns}
                      getTrProps={(state, rowInfo) => ({
                        onClick: () => {
                          onRowClick(rowInfo);
                        }
                      })}
                      getData={getScoutingData}
                      isFetching={isFetching || isDeleting}
                      totalElements={scoutingData.totalElements}
                      withDateRange={false}
                      withSearch={false}
                      online={online}
                      accessName={"scouting"}
                      addNewPath={"/"}
                      editPath={"/"}
                      Can={Can}
                      showConfirm={() => {}}
                      selectedItems={selectedItems}
                      withSelection
                      items
                      keyField={"id"}
                      setSelectedItems={setSelectedItems}
                      withItemsCount={false}
                      defaultSort={"id,desc"}
                    />
                  </div>
                </Grid>
              </Segment>
            </Sidebar.Pusher>
          </Sidebar.Pushable>
        </Layout>
      </Can>
      <TasksArchivedMessage
        archivedItemsIds={archivedItemsIds}
        onClose={() => setArchivedItems(null)}
        onUndo={undoTasksArchiving}
        getPrefixFunction={getScoutingTaskReadableId}
      />
      <IncludeMediaConfirmationModal
        isShown={!!itemsToPrint}
        onNoClick={() => onIncludeMediaResponse(false)}
        onYesClick={() => onIncludeMediaResponse(true)}
      />
    </>
  );
};

const scoutingDataShape = PropTypes.shape({
  content: PropTypes.arrayOf(scoutingShape),
  totalPages: PropTypes.number,
  totalElements: PropTypes.number,
  number: PropTypes.number
});

ScoutingList.propTypes = {
  Can: PropTypes.func,
  currentScouting: scoutingShape,
  location: PropTypes.any,
  route: PropTypes.any,
  scoutingData: scoutingDataShape,
  online: PropTypes.bool,
  isFetching: PropTypes.bool,
  isDeleting: PropTypes.bool,
  actions: PropTypes.shape({
    archiveScoutingTasks: PropTypes.func.isRequired,
    fetchScoutingData: PropTypes.func.isRequired,
    unArchiveScoutingTasks: PropTypes.func.isRequired,
    setCurrentScouting: PropTypes.func.isRequired
  }).isRequired
};

const mapStateToProps = state => {
  const {
    settings: { data },
    scouting: {
      isPrinting,
      isFetching,
      isDeleting,
      data: scoutingData,
      currentScouting
    }
  } = state;

  return {
    data: data.seasons,
    Can: abilitiesSelector(state),
    online: onlineSelector(state),
    scoutingData,
    currentScouting,
    isFetching,
    isPrinting,
    isDeleting
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchScoutingData: fetchScoutingTasks,
      archiveScoutingTasks,
      unArchiveScoutingTasks,
      printingReportStart,
      printingReportEnd,
      setCurrentScouting
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(ScoutingList);
