import { axiosProxy } from "../../utils/axiosProxy";
import _ from "lodash";
import { actionType } from "../../constants/Areas";
import { fetchAreasLog } from "actions/Areas/areas";

export const getAreasList = () => async dispatch => {
  let url = "/areas";

  try {
    dispatch({
      type: actionType.GET_AREAS_START
    });
    const res = await axiosProxy({
      method: "GET",
      url
    });

    dispatch({
      type: actionType.GET_AREAS,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: actionType.GET_AREAS_FAIL,
      payload: error
    });
  }
};

export const addArea = ({ data, blockId, parentId }) => async dispatch => {
  let url = "/areas";
  try {
    dispatch({
      type: actionType.ADD_AREA_START
    });
    const res = await axiosProxy({
      method: "POST",
      data,
      url
    });

    await dispatch({
      type: actionType.ADD_AREA,
      payload: { block: res.data, blockId, parentId }
    });
    res.data && fetchAreasLog();
    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.ADD_AREA_FAIL,
      payload: error
    });
  }
};

export const updateArea = ({ data, blockId }) => async dispatch => {
  let url = `/areas/${blockId}`;
  try {
    dispatch({
      type: actionType.UPDATE_AREA_START
    });
    const res = await axiosProxy({
      method: "PUT",
      data,
      url
    });
    await dispatch({
      type: actionType.UPDATE_AREA,
      payload: res.data
    });
    res.data && dispatch(fetchAreasLog());

    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.UPDATE_AREA_FAIL,
      payload: error
    });
  }
};

export const createOrUpdateStructure = ({
  block,
  patches,
  blockId,
  savingStructure
}) => async dispatch => {
  const entityId = block._id;
  let entityBlock;

  try {
    dispatch({
      type: actionType.UPDATE_STRUCTURE_START,
      payload: savingStructure
    });

    if (entityId) {
      entityBlock = await updateArea({ data: block, blockId: block._id })(
        dispatch
      );
    } else {
      entityBlock = await addArea({ data: block, blockId })(dispatch);
    }

    const updatePatch = async ({ id, data }, parentId) => {
      data = { ...data, parent: { id: parentId } };
      if (data._id) {
        await updateArea({ data, blockId: data._id, parentId: blockId })(
          dispatch
        );
      } else {
        await addArea({ data, blockId: id, parentId: blockId })(dispatch);
      }
    };

    if (!_.isEmpty(patches)) {
      await Promise.all(
        patches.map(patch => {
          return updatePatch(patch, entityBlock.id);
        })
      );
    }
    dispatch({
      type: actionType.UPDATE_STRUCTURE
    });
    dispatch(fetchAreasLog());
  } catch (error) {
    dispatch({
      type: actionType.UPDATE_STRUCTURE_FAIL,
      payload: error
    });
  }
};

export const archiveArea = (areaId, archive = true) => async dispatch => {
  let url = `/areas/${areaId}/archive`;
  const params = new URLSearchParams();
  params.append("archive", archive.toString());

  try {
    dispatch({
      type: actionType.ARCHIVE_AREA_START
    });
    await axiosProxy({
      method: "PUT",
      url,
      params
    });

    await dispatch({
      type: actionType.ARCHIVE_AREA,
      payload: { areaId, archive }
    });
  } catch (error) {
    dispatch({
      type: actionType.ARCHIVE_AREA_FAIL,
      payload: error
    });
  }
};

export const deleteArea = blockId => async dispatch => {
  let url = `/areas/${blockId}`;
  try {
    dispatch({
      type: actionType.DELETE_AREA_START
    });
    const res = await axiosProxy({
      method: "DELETE",
      url
    });

    await dispatch({
      type: actionType.DELETE_AREA,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: actionType.DELETE_AREA_FAIL,
      payload: error
    });
  }
};
