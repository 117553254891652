import { Grid, Header } from "semantic-ui-react";
import React from "react";
import classNames from "classnames";
import {
  APPLICATION_TYPES,
  INTERVENTION_TYPES,
  RECOMMENDATION_STATUSES,
  recommendationTaskShape,
  URGENCY_LEVELS
} from "constants/Recommendations/types";
import { getScoutingTaskReadableId } from "utils/scouting";
import { map } from "lodash";
import { getUserDisplayedName } from "utils/user";
import styles from "./RecommendationsPrintout.module.css";

const GeneralInfoSection = ({ taskData }) => {
  const {
    applicationType,
    assignees,
    comment,
    creator,
    interventionType,
    status,
    scoutingId,
    urgencyLevel
  } = taskData;
  const generalInfoData = [
    { title: "Task Status", value: RECOMMENDATION_STATUSES[status], width: 3 },
    {
      title: "Linked to Scouting Task",
      value: getScoutingTaskReadableId(scoutingId),
      width: 5
    },
    { title: "Urgency level", value: URGENCY_LEVELS[urgencyLevel] },
    { title: "Application type", value: APPLICATION_TYPES[applicationType] },
    {
      title: "Assignees",
      value: map(assignees, ({ employee }) => getUserDisplayedName(employee))
        .filter(Boolean)
        .join(", "),
      width: 3
    },
    {
      title: "Intervention type",
      value: INTERVENTION_TYPES[interventionType],
      width: 5
    },
    { title: "Creator", value: getUserDisplayedName(creator) },
    { title: "Comments", value: comment, width: 16 }
  ];

  return (
    <Grid.Row>
      <Grid.Column width={16}>
        <Header className="bold" size="medium">
          General info
        </Header>
      </Grid.Column>
      {generalInfoData.map(({ title, value, width }) => (
        <Grid.Column width={width || 4} key={title}>
          <Header
            size="small"
            className={classNames("uppercase", styles.valueHeader)}
          >
            {title}
          </Header>
          <Header size="small" className="bold m-0">
            {value || "—"}
          </Header>
        </Grid.Column>
      ))}
    </Grid.Row>
  );
};

GeneralInfoSection.propTypes = {
  taskData: recommendationTaskShape
};

export default GeneralInfoSection;
