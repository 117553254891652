export const actionTypes = {
  FETCH_PICKER_REPORT_TABLE_DATA_START:
    "REPORTS/FETCH_PICKER_REPORT_TABLE_DATA_START",
  FETCH_PICKER_REPORT_TABLE_DATA: "REPORTS/SET_PICKER_REPORT_TABLE_DATA",
  FETCH_PICKER_REPORT_TABLE_DATA_FAILED:
    "REPORTS/FETCH_PICKER_REPORT_TABLE_DATA_FAILED",

  FETCH_PICKER_REPORT_TABLE_LIST_START:
    "REPORTS/FETCH_PICKER_REPORT_TABLE_LIST_START",
  SET_PICKER_REPORT_TABLE_LIST: "REPORTS/SET_PICKER_REPORT_TABLE_LIST",
  FETCH_PICKER_REPORT_TABLE_LIST_FAILED:
    "REPORTS/FETCH_PICKER_REPORT_TABLE_LIST_FAILED"
};
