import React, { useState, useCallback, useEffect, useRef } from "react";
import { useSelector, connect, useDispatch } from "react-redux";
import { useFormikContext } from "formik";
import {
  Grid,
  Segment,
  Button,
  Header,
  Dimmer,
  Loader,
  Container,
  Message,
  Icon
} from "semantic-ui-react";
import Filters from "components/Table/components/Filters";
import { useReactToPrint } from "react-to-print";
import filterTypes from "components/Table/filterTypes";
import {
  chemicalsOptionsSelector,
  productTypeSelector
} from "../../../../../../../selectors/chemicals";
import { filtersOptions } from "./constants";
import { matterToUnits } from "utils/constToUnits";
import { changeOperation, getOperationsList } from "actions/Sheds";
import {
  omit,
  xor,
  get,
  isEmpty,
  _pick,
  _intersectionBy,
  _intersection,
  _filter,
  _some
} from "lodash";
import _ from "lodash";
import InventoryForm from "./InventoryForm";
import InventoryRow from "./InventoryRow";
import styles from "./Inventory.module.css";
import PrintModal from "./component/printoutTypeModal";
import PrintoutInventory from "./component/InventoryPrintout/PrintoutInventory";
import PhysicalStocktake from "./component/PhysicalStocktake/PhysicalStocktake";
import { excelExport } from "utils/excelExport";
import moment from "moment";

const Inventory = ({ shed, sheds, productList }) => {
  const { values, setFieldValue, validateForm } = useFormikContext();
  const inventoryListCheck = shed.buckets?.length;
  const dispatch = useDispatch();
  const inventoryList = useSelector(chemicalsOptionsSelector);
  const productType = useSelector(productTypeSelector);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [addButtonCheck, setAddButtonCheck] = useState(true);
  const [deleteButtonCheck, setDeleteButtonCheck] = useState(true);
  const [bucketsList, setBucketsList] = useState(shed.buckets);
  const [closeMessages, setCloseMessages] = useState(false);
  const [open, setOpen] = useState(false);
  const [printoutType, setPrintOutType] = useState("inventory");
  const [printExpandedData, setPrintExpandedData] = useState(false);
  const componentRef = useRef();
  const headerColumn = [
    "Product",
    "Avg ($/unit)",
    "Expected inventory",
    "Date added",
    "Price ($/unit)"
  ];

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });
  const printButton = () => {
    handlePrint();
  };
  useEffect(() => {
    dispatch(getOperationsList(shed.id));
  }, []);
  const onNewInventoryAdd = bucket => {
    let buckets = [];
    if (values.buckets) {
      buckets = [...values.buckets];
      const bucketIndex = buckets.findIndex(
        b => b.price == bucket.price && b.chemicalId === bucket.chemicalId
      );
      if (bucketIndex > -1) {
        buckets[bucketIndex] = {
          ...buckets[bucketIndex],
          quantity: +buckets[bucketIndex].quantity + +bucket.quantity
        };
      } else {
        buckets = [...buckets, bucket];
      }
    } else {
      buckets.push(bucket);
    }
    setFieldValue("buckets", buckets);
    validateForm();
    setAddButtonCheck(true);

    const { name, totalPrice, createdAt, ...data } = bucket;
    dispatch(changeOperation({ ...data, shedId: shed.id }));
  };

  const addProduct = () => {
    setAddButtonCheck(false);
    setDeleteButtonCheck(true);
  };

  const deleteAddingForm = () => {
    setAddButtonCheck(true);
  };

  const onFilterChange = useCallback(
    (filterKey, value) => {
      const currentFilterElement = filtersOptions.find(
        ({ filterId }) => filterId === filterKey
      );
      if (value === null) {
        setSelectedFilters(filters => omit(filters, filterKey));
      } else if (currentFilterElement.type === filterTypes.MultiSelect) {
        setSelectedFilters(filters => {
          const newValue = xor(filters[filterKey], [value]);
          if (!newValue.length) {
            delete filters[filterKey];
            return { ...filters };
          } else {
            return { ...filters, [filterKey]: newValue };
          }
        });
      } else {
        setSelectedFilters(filters => ({ ...filters, [filterKey]: value }));
      }
    },
    [filtersOptions, setSelectedFilters]
  );

  const clearFilters = useCallback(() => {
    setSelectedFilters({});
  }, [selectedFilters]);

  useEffect(() => {
    const { product, type, matter } = selectedFilters;
    const filteredBuckets = values.buckets.filter(
      bucket =>
        (!product || product.includes(bucket.chemicalId)) &&
        (!type || type.includes(bucket.chemicalTypeId)) &&
        (!matter || matter === bucket.matter)
    );
    setBucketsList(filteredBuckets);
  }, [values.buckets, selectedFilters]);

  const { negative, positive } = bucketsList.reduce(
    (
      { negative, positive },
      { price, quantity, chemicalId, name, matter, createdAt },
      _,
      initialBuckets
    ) => {
      const isNegative =
        shed.pendingOperations &&
        shed.pendingOperations.content
          .map(operation => operation.chemicalId)
          .includes(chemicalId);
      const currentNegativeItems = negative[chemicalId];
      const currentPositiveItems = positive[chemicalId];

      let negativeItemsNew = { ...negative };
      let positiveItemsNew = { ...positive };
      if (isNegative) {
        let tempObj = {};

        const currentProductPendingOperations = shed.pendingOperations?.content.filter(
          o => o.chemicalId === chemicalId
        );
        if (currentNegativeItems) {
          tempObj = {
            ...currentNegativeItems,
            expectedInventory:
              Number(quantity) + currentNegativeItems.expectedInventory,
            buckets: [
              ...currentNegativeItems.buckets,
              {
                price,
                quantity,
                createdAt
              }
            ]
          };
        } else {
          tempObj = {
            expectedInventory: Number(quantity),
            price: Number(price),
            pendingOperations: currentProductPendingOperations,
            buckets: [
              {
                price,
                quantity,
                createdAt
              }
            ]
          };
        }

        negativeItemsNew = {
          ...negative,
          [chemicalId]: {
            chemicalId,
            productName: name,
            initialPrice: initialBuckets[initialBuckets.length - 1].price,
            createdAt,
            unit: matterToUnits(matter),
            ...tempObj
          }
        };
      } else {
        positiveItemsNew = {
          ...positive,
          [chemicalId]: {
            chemicalId,
            productName: name,
            initialPrice: initialBuckets[initialBuckets.length - 1].price,
            createdAt,
            unit: matterToUnits(matter),
            ...(currentPositiveItems
              ? {
                  expectedInventory:
                    Number(quantity) + currentPositiveItems.expectedInventory,
                  price: (
                    (Number(price) * Number(quantity) +
                      currentPositiveItems.price *
                        currentPositiveItems.expectedInventory) /
                    (currentPositiveItems.expectedInventory + Number(quantity))
                  ).toFixed(2),
                  buckets: [
                    ...currentPositiveItems.buckets,
                    {
                      price,
                      quantity,
                      createdAt
                    }
                  ]
                }
              : {
                  expectedInventory: Number(quantity),
                  price: Number(price),
                  buckets: [
                    {
                      price,
                      quantity,
                      createdAt
                    }
                  ]
                })
          }
        };
      }

      return {
        negative: negativeItemsNew,
        positive: positiveItemsNew
      };
    },
    {
      positive: {},
      negative: {}
    }
  );

  const positiveItems = Object.values(positive);
  const negativeItems = Object.values(negative);

  const newNegativeItems = Object.values(
    (shed.pendingOperations?.content || [])
      .map(item => ({
        buckets: [],
        chemicalId: item.chemicalId,
        quantity: item.quantity,
        createdAt: item.createdAt,
        pendingOperations: [item],
        price: item.price,
        productName: productList.find(c => c.id === item.chemicalId)?.name,
        unit: "L"
      }))
      .filter(item => {
        const checkId = bucketsList.map(c => c.chemicalId);
        return checkId.every(id => {
          return id !== item.chemicalId;
        });
      })
      .reduce(function(results, org) {
        (results[org.chemicalId] = results[org.chemicalId] || []).push(org);
        return results;
      }, {})
  );

  const newNegativeItemsData = !!newNegativeItems?.length
    ? newNegativeItems.map(item => {
        return item.reduce(
          (prev, curr) => {
            return {
              buckets: [],
              chemicalId: curr.chemicalId,
              createdAt: curr.createdAt,
              expectedInventory: null,
              pendingOperations: [...prev.pendingOperations, curr],
              price: curr.price,
              productName: productList.find(c => c.id === curr.chemicalId)
                ?.name,
              unit: matterToUnits(
                get(
                  productList.find(c => c.id === curr.chemicalId),
                  "matter"
                )
              )
            };
          },
          {
            buckets: [],
            chemicalId: null,
            quantity: null,
            createdAt: null,
            pendingOperations: [],
            price: null,
            productName: null,
            unit: null
          }
        );
      })
    : [];
  const negativeDataForPrint = negativeItems
    .concat(newNegativeItemsData)
    ?.sort(function(a, b) {
      var nameA = a.productName?.toLowerCase(),
        nameB = b.productName?.toLowerCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });
  const positiveDataForPrint = positiveItems?.sort(function(a, b) {
    var nameA = a.productName?.toLowerCase(),
      nameB = b.productName?.toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });

  const handleExcelExport = useCallback(() => {
    const dataForExport = [...positiveDataForPrint, ...negativeDataForPrint];

    const newData = dataForExport
      .map(item => {
        return !item.pendingOperations
          ? item.buckets.map(i => {
              return {
                productName: item.productName,
                avgUnit: +item.price,
                expectedInventory: +i.quantity,
                dateAdded: moment(i.createdAt).format("DD/MM/YYYY"),
                price: +i.price
              };
            })
          : !item.buckets.length
          ? item.pendingOperations.map(p => {
              return {
                productName: item.productName,
                avgUnit: "-",
                expectedInventory: +p.quantity,
                dateAdded: moment(p.createdAt).format("DD/MM/YYYY"),
                price: "-"
              };
            })
          : [...item.buckets, ...item.pendingOperations].map((i, index) => {
              return {
                productName: item.productName,
                avgUnit: "-",
                expectedInventory: +i.quantity,
                dateAdded: moment(item.createdAt).format("DD/MM/YYYY"),
                price: +i.price
              };
            });
      })
      .flat();
    const excelData = newData.map(
      ({ productName, avgUnit, expectedInventory, dateAdded, price }) => {
        return {
          ...{ productName: productName || "-" },
          ...{ avgUnit: avgUnit && avgUnit !== "-" ? avgUnit.toFixed(2) : "-" },
          ...{
            expectedInventory:
              expectedInventory && expectedInventory !== "-"
                ? expectedInventory.toFixed(2)
                : "-"
          },
          ...{ dateAdded: dateAdded || "-" },
          ...{ price: price && price !== "-" ? price.toFixed(2) : "-" }
        };
      }
    );
    excelExport(excelData, headerColumn);
  });

  const negativeData = negativeItems
    .concat(newNegativeItemsData)
    .filter(bucket => {
      const { product, type, matter } = selectedFilters;
      return (
        (!product || product.includes(bucket.chemicalId)) &&
        (!type || type.includes(bucket.chemicalTypeId)) &&
        (!matter || matter === bucket.matter)
      );
    });

  return (
    <>
      {
        <div style={{ display: "none" }}>
          {printoutType == "inventory" && (
            <PrintoutInventory
              negativeData={negativeDataForPrint}
              positiveData={positiveDataForPrint}
              title={`${shed.name} (Inventory)`}
              checmicals={productList}
              shed={shed}
              ref={componentRef}
            />
          )}
          {printoutType == "physicalStocktake" && (
            <PhysicalStocktake
              negativeData={negativeDataForPrint}
              positiveData={positiveDataForPrint}
              title={`${shed.name} (Stocktake)`}
              checmicals={productList}
              shed={shed}
              ref={componentRef}
            />
          )}
        </div>
      }
      <Segment className={styles.sideBarInventory}>
        {shed.pendingOperations && shed.pendingOperations.isFetching ? (
          <Dimmer active inverted>
            <Loader />
          </Dimmer>
        ) : (
          <>
            {open && (
              <PrintModal
                open={open}
                printButton={printButton}
                setOpen={setOpen}
                printoutType={printoutType}
                setPrintOutType={setPrintOutType}
              />
            )}
            <div className={styles.filterbuttonWrapper}>
              <div className={styles.filterContainer}>
                <Filters
                  options={filtersOptions}
                  selectedFilters={selectedFilters}
                  onFilterChange={onFilterChange}
                  clearFilters={clearFilters}
                />
              </div>
              <div className={styles.prinButtonContainer}>
                <div className={styles.iconWrapper}>
                  <Icon onClick={() => handleExcelExport()} name="download" />
                </div>
                <div className={styles.iconWrapper}>
                  <Icon name="print" onClick={() => setOpen(true)} />
                </div>
              </div>
            </div>

            {(negativeItems.length > 0 || !isEmpty(newNegativeItemsData)) && (
              <>
                <Header className={styles.negativeProductHeader}>
                  Negative inventory products
                </Header>
                {!closeMessages && (
                  <Message className={styles.warrningMessages} color="yellow">
                    <Grid
                      className={styles.root}
                      columns="equal"
                      as={Container}
                    >
                      <Grid.Row className={styles.messageRowContainer}>
                        <Grid.Column
                          className={styles.warrningMessagesIconWrapper}
                          verticalAlign="middle"
                          width={1}
                        >
                          <Icon
                            className={styles.warrningMessagesIcon}
                            name="info circle"
                          />
                        </Grid.Column>
                        <Grid.Column
                          className={styles.warrningMessagesContentWrapper}
                          width={13}
                        >
                          To resolve your negative inventory, please press on
                          the product's edit button and add a price. This will
                          automatically update your pending movements for this
                          product in this shed and update your application cost
                          reports.
                        </Grid.Column>
                        <Grid.Column
                          className={styles.messagesCloseButtonContainer}
                          verticalAlign="middle"
                          width={2}
                        >
                          {" "}
                          <Button
                            onClick={() => setCloseMessages(true)}
                            className={styles.messagesCloseButton}
                          >
                            Got it
                          </Button>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Message>
                )}

                <Grid className={styles.root} columns="equal" as={Container}>
                  <Grid.Row className={styles.labelWrapper} width={16}>
                    <Grid.Column className={styles.productRowLabel}>
                      Product
                    </Grid.Column>
                    <Grid.Column className={styles.priceRowLabel}>
                      Price ($/unit)
                    </Grid.Column>
                    <Grid.Column className={styles.expectedInventoryRowLabel}>
                      Expected inventory
                    </Grid.Column>
                  </Grid.Row>

                  {negativeData
                    ?.sort(function(a, b) {
                      var nameA = a.productName?.trim().toLowerCase(),
                        nameB = b.productName?.trim().toLowerCase();
                      if (nameA < nameB) return -1;
                      if (nameA > nameB) return 1;
                      return 0;
                    })
                    .map(
                      item =>
                        item && (
                          <Grid.Row className={styles.rowContainer}>
                            <InventoryRow
                              inventoryList={inventoryList}
                              sheds={sheds}
                              productList={productList}
                              productType={productType}
                              inventoryData={item}
                              shed={shed}
                              key={item?.id}
                              isNegative={true}
                            />
                          </Grid.Row>
                        )
                    )}
                </Grid>
              </>
            )}
            {positiveItems.length > 0 && (
              <>
                <Header className={styles.positiveProductHeader}>
                  Positive inventory products
                </Header>
                <Grid className={styles.root} columns="equal" as={Container}>
                  <Grid.Row className={styles.labelWrapper} width={16}>
                    <Grid.Column className={styles.productRowLabel}>
                      Product
                    </Grid.Column>
                    <Grid.Column className={styles.priceRowLabel}>
                      Avg ($/unit)
                    </Grid.Column>
                    <Grid.Column className={styles.expectedInventoryRowLabel}>
                      Expected inventory
                    </Grid.Column>
                  </Grid.Row>
                  {positiveItems
                    ?.sort(function(a, b) {
                      var nameA = a.productName?.trim().toLowerCase(),
                        nameB = b.productName?.trim().toLowerCase();
                      if (nameA < nameB) return -1;
                      if (nameA > nameB) return 1;
                      return 0;
                    })
                    .map(
                      item =>
                        item && (
                          <Grid.Row className={styles.rowContainer}>
                            <InventoryRow
                              inventoryList={inventoryList}
                              sheds={sheds}
                              productList={productList}
                              productType={productType}
                              inventoryData={item}
                              shed={shed}
                              key={item?.id}
                            />
                          </Grid.Row>
                        )
                    )}
                </Grid>
              </>
            )}
            {addButtonCheck &&
            (inventoryListCheck || !isEmpty(newNegativeItemsData)) ? (
              <div className={styles.buttonContainer}>
                <Button
                  type="submit"
                  className="button-text color-green"
                  onClick={addProduct}
                >
                  + Add a product
                </Button>
              </div>
            ) : null}
            {(!addButtonCheck ||
              (!inventoryListCheck && isEmpty(newNegativeItemsData))) && (
              <Segment className={styles.sideBarInventoryForm}>
                <InventoryForm
                  inventoryListCheck={inventoryListCheck}
                  buckets={values.buckets}
                  deleteAddingForm={deleteAddingForm}
                  newNegativeItemsData={newNegativeItemsData}
                  deleteButtonCheck={deleteButtonCheck}
                  onSubmit={onNewInventoryAdd}
                />
              </Segment>
            )}
          </>
        )}
      </Segment>
    </>
  );
};
export default connect((state, props) => {
  return {
    productList: state.chemical.list.content
  };
})(Inventory);
