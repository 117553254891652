import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "react-table-6/react-table.css";

import { bindActionCreators } from "redux";

import styles from "./Vendors.module.css";
import ListHolder from "../../../components/ListHolder";
import { abilitiesSelector } from "../../../selectors/user";
import { deleteVendor, fetchVendors } from "../../../actions/Vendors/vendors";
import Sidebar from "./Sidebar";

const PAGE_SIZE = 10;

class Vendors extends Component {
  state = {
    tableData: [],
    tableColumns: [],
    sideBarShown: false,
    activeItemId: null
  };

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { data } = this.props;
    if (prevProps.data !== data) this.prepareData();
  }

  prepareData = () => {
    const { data } = this.props;
    const columnsNames = {
      name: {
        title: "Name",
        width: 8
      },
      actions: {
        title: "",
        width: "auto",
        accessor: d => d.id,
        className: "textRight actions"
      }
    };
    const tableColumns = [];
    const tableData = [];

    Object.keys(columnsNames).forEach(column => {
      const targetColumn = columnsNames[column];
      const columnToAdd = {
        id: column,
        accessor: targetColumn.accessor || column,
        Header: targetColumn.title,
        Cell: targetColumn.Cell || this.renderCell,
        className: `cell_${targetColumn.width} ${targetColumn.className || ""}`,
        ...(targetColumn.sortFields
          ? { sortFields: targetColumn.sortFields }
          : {}),
        disableSort: targetColumn.disableSort
      };

      tableColumns.push(columnToAdd);
    });

    if (data && data.content) {
      data.content.forEach(row => {
        const rowData = {
          rights: {
            update: "update",
            delete: "delete"
          }
        };
        Object.keys(row).forEach(key => {
          switch (key) {
            default:
              rowData[key] = row[key] || "";
              break;
          }
        });
        tableData.push(rowData);
      });
    }

    this.setState({
      tableData,
      tableColumns
    });
  };

  renderCell = props => {
    const {
      value,
      column: { Header }
    } = props;

    return (
      <div>
        <div className="cellTitle hide-md">{Header}</div>
        <div className="cellValue">{value}</div>
      </div>
    );
  };

  setActiveItem = activeItemId => {
    this.setState({
      activeItemId
    });
  };

  render() {
    const {
      route,
      location,
      content,
      currentPage,
      totalPages,
      isFetching,
      Can,
      size,
      totalElements,
      error,
      online,
      actions
    } = this.props;
    const { activeItemId, tableData, tableColumns } = this.state;
    const expandedContent =
      activeItemId && content.find(e => e.id === activeItemId);
    const editLink = expandedContent
      ? `/harvest/vendors/vendor/${expandedContent.id}`
      : null;
    return (
      <ListHolder
        content={content}
        accessName="vendors"
        Can={Can}
        addNewPath={"/harvest/vendors/vendor"}
        route={route}
        location={location}
        currentPage={currentPage}
        size={size || PAGE_SIZE}
        totalPages={totalPages}
        rawData={content}
        data={tableData}
        columns={tableColumns}
        getData={actions.fetchAction}
        delData={actions.deleteAction}
        isFetching={isFetching && !totalPages}
        totalElements={totalElements}
        withDateRange={false}
        setActiveItem={this.setActiveItem}
        error={error}
        online={online}
        updateAction="update"
        editLink={editLink}
        defaultSort="name,asc"
        className={styles.listHolder}
      >
        {" "}
        <Sidebar
          id={expandedContent && expandedContent.id}
          vendor={expandedContent}
          Can={Can}
        />
      </ListHolder>
    );
  }
}

Vendors.propTypes = {
  content: PropTypes.array.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  totalElements: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  isFetching: PropTypes.bool.isRequired,
  lastLoaded: PropTypes.bool,
  data: PropTypes.object,
  actions: PropTypes.object,
  error: PropTypes.object,
  route: PropTypes.object,
  location: PropTypes.object,
  Can: PropTypes.func,
  online: PropTypes.bool
};

const mapStateToProps = state => {
  const {
    vendors: {
      isFetching,
      data,
      data: { content, number, totalPages, totalElements, size, last },
      error
    },
    offline: { online }
  } = state;
  return {
    data,
    content,
    isFetching,
    currentPage: number,
    totalPages,
    totalElements,
    size,
    lastLoaded: last,
    Can: abilitiesSelector(state),
    error,
    online
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      { fetchAction: fetchVendors, deleteAction: deleteVendor },
      dispatch
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Vendors);
