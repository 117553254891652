import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  Form,
  Grid,
  Segment,
  Button,
  Icon,
  Header,
  Container,
  Popup
} from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { debounce, get, isEmpty, map } from "lodash";
import { FastField, useFormikContext, FieldArray, Field } from "formik";
import { FERTILISER } from "constants/Chemicals/types";
import { YES_NO_OPTIONS } from "constants/Chemicals";
import SwitchButton from "components/SwitchButton";
import CollapsibleBlock from "components/CollapsibleBlock";
import { activeIngredientsSelector } from "selectors/chemicals";
import { fetchActiveIngredients } from "actions/Chemicals";
import styles from "../InventoryModal.module.css";

const ChemicalPropertiesEditSection = ({ editSectionIndex }) => {
  const [searchQuery, setSearchQuery] = useState();
  const { values, setFieldValue } = useFormikContext();
  const dispatch = useDispatch();
  const isFertiliser = get(values.type, "type") === FERTILISER;
  const { options: activeIngredientsOptions, isFetching } = useSelector(
    activeIngredientsSelector
  );
  const shownOptions = map(activeIngredientsOptions, ({ label, ...rest }) => ({
    ...rest,
    text: label
  }));
  const titlePrefix = isFertiliser ? "Fertiliser" : "Chemical";
  const [newIngredientInputShown, setNewIngredientInputShown] = useState(false);

  const performSearch = useCallback(searchQuery => {
    dispatch(fetchActiveIngredients({ search: searchQuery }));
  }, []);

  const debouncedSearchHandler = useMemo(
    () => debounce(performSearch, 500),
    []
  );

  useEffect(() => {
    debouncedSearchHandler(searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    if (isEmpty(values.activeIngredients)) {
      setNewIngredientInputShown(true);
    }
  }, [values.activeIngredients]);

  return (
    <Segment>
      <CollapsibleBlock
        className={styles.root}
        defaultActive={!editSectionIndex || editSectionIndex === 3}
        title={`${titlePrefix} properties`}
      >
        <Form>
          <Grid>
            <Grid.Column width={7} className={styles.chemicalPropertyColumn}>
              <div className={styles.poisonScheduleField}>
                <FastField
                  fluid
                  as={Form.Input}
                  label="Poison schedule"
                  name="poisonSchedule"
                />
              </div>

              <Form.Field>
                <label>Active ingredient</label>
                <FieldArray
                  name="activeIngredients"
                  render={arrayHelpers => (
                    <div>
                      {map(values.activeIngredients, (ingredient, index) => (
                        <Segment className={styles.ingredientSection}>
                          <Header
                            floated="left"
                            className={styles.ingredientHeader}
                            size="small"
                          >
                            {ingredient.activeIngredient}
                          </Header>
                          <Popup
                            content="Delete active ingredient"
                            position="bottom center"
                            inverted
                            trigger={
                              <Icon
                                name="trash alternate"
                                className={styles.removeActiveIngredient}
                                onClick={() => arrayHelpers.remove(index)}
                              />
                            }
                          />
                        </Segment>
                      ))}
                      {newIngredientInputShown && (
                        <Field
                          fluid
                          as={Form.Select}
                          allowAdditions
                          search
                          searchQuery={searchQuery}
                          onSearchChange={(_, { searchQuery }) => {
                            setSearchQuery(searchQuery);
                          }}
                          loading={isFetching}
                          name="newIngredient"
                          onChange={(_, { value }) => {
                            arrayHelpers.push({ activeIngredient: value });
                            setNewIngredientInputShown(false);
                            setSearchQuery("");
                          }}
                          options={shownOptions}
                          noResultsMessage={
                            <div className={styles.activeIngredientMessage}>
                              <Header>Add new active ingredient</Header>
                              <Header size="small">
                                Enter the full name of the ingredient properly
                              </Header>
                            </div>
                          }
                        />
                      )}
                      <Container fluid className={styles.textButtonContainer}>
                        <Button
                          type="button"
                          onClick={() => setNewIngredientInputShown(true)}
                          className={styles.textButton}
                          disabled={newIngredientInputShown}
                        >
                          <Icon name="plus" />
                          Add active ingredient
                        </Button>
                      </Container>
                    </div>
                  )}
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column width={2} />
            <Grid.Column
              width={7}
              className={styles.activeIngredientChemicalPropertyColumn}
            >
              {!isFertiliser && (
                <>
                  <FastField
                    as={Form.Input}
                    label="Resistance group 1"
                    name="resistanceGroup1"
                  />
                  <FastField
                    as={Form.Input}
                    label="Resistance group 2"
                    name="resistanceGroup2"
                  />
                </>
              )}
              <Form.Group widths="equal">
                <Field
                  as={SwitchButton}
                  items={YES_NO_OPTIONS}
                  name="dangerousProduct"
                  value={values.dangerousProduct}
                  onChange={setFieldValue}
                  title="Dangerous product"
                />
                <Field
                  as={SwitchButton}
                  items={YES_NO_OPTIONS}
                  name="organic"
                  value={values.organic}
                  onChange={setFieldValue}
                  title="Organic"
                />
              </Form.Group>
              {values.organic && (
                <Form.Group>
                  <Field
                    as={Form.Input}
                    fluid
                    label="AOC Registration number"
                    name="registrationNumber"
                    width={16}
                  />
                </Form.Group>
              )}
            </Grid.Column>
          </Grid>
        </Form>
      </CollapsibleBlock>
    </Segment>
  );
};

export default ChemicalPropertiesEditSection;
