import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Splash from '../../../components/Splash';
import { signOut } from '../../../actions/User';

function LogOff() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(signOut());
  }, [dispatch]);

  return <Splash loading>Signing Out...</Splash>;
}

export default LogOff;
