import React, { PureComponent, createRef } from "react";
import ReactDOM from "react-dom";
import { diff } from "deep-object-diff";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
// eslint-disable-next-line
import { Button, Dropdown, Input, Icon, Tab, Menu } from "semantic-ui-react";
import { orderBy, truncate, debounce } from "lodash";

import { history } from "../../../store";
import {
  filtersOptionsSelector,
  paginationSelector
} from "../../../selectors/spray";
import {
  fetchSprayTasks,
  getTasksIdsList,
  getCropsList,
  getVarietiesList,
  setFieldValue,
  setUrlString
} from "../../../actions/Spray/tasks";
import { getAreasList } from "../../../actions/SprayDiary/areas";
import { getChemicalsList } from "../../../actions/SprayDiary/chemicals";
import { getEmployeeList } from "../../../actions/SprayDiary/employee";
import { getFarmsSummary } from "../../../actions/Farms/farms";
import { getTaskNames } from "../../../actions/SprayDiary/common";
import DatePicker from "../../../components/DatePicker";
import styles from "./TasksFilters.module.css";
import { Mixpanel } from "../../../tools/mixpanel/Mixpanel";
import { MixpanelEvents } from "../../../tools/mixpanel/MixpanelEvents";
import { seasons } from "selectors/seasonsSelector";

class TasksFilters extends PureComponent {
  state = {
    addOptions: [],
    activeFilters: {},
    filtersToShow: [],
    filtersCount: 0,
    queryString: null,
    listNode: null,
    isAlreadyCalledOnce: false,
    prevProps: {}
  };
  filters = {
    statusTabs: {
      alias: "status",
      name: "Status",
      type: "tabs",
      options: "statusOptions",
      hidden: true
    },
    status: {
      name: "Status",
      type: "multiSelectItems",
      options: "statusOptions"
    },
    id: {
      name: "ID",
      type: "multiSelectItems",
      options: "idsOptions",
      numbers: true
    },
    taskName: {
      name: "Task Name",
      type: "multiSelectItems",
      options: "namesOptions",
      loaderAction: getTaskNames
    },
    growthStage: {
      name: "Growth Stage",
      type: "multiSelectItems",
      options: "growthStageOptions"
    },
    farm: {
      name: "Farm",
      type: "multiSelectItems",
      options: "farmsOptions",
      numbers: true
    },
    area: {
      name: "Area",
      type: "multiSelectItems",
      options: "areasOptions",
      numbers: true
    },
    variety: {
      name: "Variety",
      type: "multiSelectItems",
      options: "varietyOptions",
      numbers: true
    },
    crop: {
      name: "Crop",
      type: "multiSelectItems",
      options: "cropsOptions",
      numbers: true
    },
    chemical: {
      name: "Chemical",
      type: "multiSelectItems",
      options: "chemicalsOptions",
      numbers: true
    },
    applicationType: {
      name: "Type",
      type: "multiSelectItems",
      options: "typeOptions",
      numbers: false
    },
    date: {
      name: "Date",
      type: "dateRange",
      options: "rangeOptions",
      defaultSelected: true
      //permanent: true,
    },
    plannedDate: {
      name: "Planned date",
      type: "dateRange",
      options: "rangeOptions"
    },
    workingTime: {
      name: "Working time",
      type: "dateRange",
      options: "rangeOptions"
    },
    endDate: {
      name: "End Date",
      type: "dateRange",
      options: "rangeOptions"
    },
    progress: {
      name: "Progress",
      type: "inputRange",
      options: "rangeOptions"
    },
    totalHectares: {
      name: "Total Hectares",
      type: "inputRange",
      options: "rangeOptions"
    },
    assignee: {
      name: "Operator",
      type: "multiSelectItems",
      options: "employeesOptions",
      numbers: true
    },
    supervisor: {
      name: "Supervisor",
      type: "multiSelectItems",
      options: "employeesOptions",
      numbers: true
    },
    seen: {
      name: "Seen",
      type: "switchType",
      options: "switchOptions"
    },
    season: {
      name: "Season",
      type: "singleSelect",
      options: "seasonsOptionsSelector",
      defaultSelected: this.props.fromReportPage ? false : true
    }
  };
  filtersRef = createRef();

  componentDidMount() {
    const {
      urlString,
      activeFiltersMatch,
      actions,
      online,
      defaultSort,
      location,
      dispatch
    } = this.props;
    if ((defaultSort && !location.hash) || (urlString && !activeFiltersMatch)) {
      this.setUrl(urlString || "", true, false, defaultSort);
    } else {
      if (activeFiltersMatch && urlString !== activeFiltersMatch) {
        actions.setUrlString({ online, urlString: activeFiltersMatch });
      }

      this.filtersToObject({ addDefaultSelected: true });
    }

    // Fetch initial Filters data; TODO: Make it loading only after filter item was mounted, but once per filers
    Object.values(this.filters).forEach(item => {
      const { loaderAction } = item;

      if (typeof loaderAction === "function") {
        dispatch(loaderAction());
      }
    });

    document.body.addEventListener("mousedown", this.handleBlur);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      activeFiltersMatch,
      filtersOptions,
      online,
      pagination,
      tasks,
      location
    } = this.props;
    const { isAlreadyCalledOnce, queryString } = this.state;
    const { filtersOptions: prevFiltersOptions } = prevProps;
    this.setState({
      prevProps: prevProps
    });
    if (
      location.hash !== prevProps.location.hash ||
      activeFiltersMatch !== prevProps.activeFiltersMatch ||
      (online && !prevProps.online)
    ) {
      this.filtersToObject({
        addDefaultSelected:
          activeFiltersMatch === prevProps.activeFiltersMatch ||
          (location.hash && !prevProps.location.hash)
      });
    }
    if (online && !prevProps.online && tasks.data.content.length === 0) {
      this.getSprayTasks(
        queryString,
        pagination && pagination.page ? pagination.page : 0
      );
    }
    if (isAlreadyCalledOnce && !prevState.isAlreadyCalledOnce) {
      this.loadDataForFiltersOptions();
    }
  }

  componentWillUnmount() {
    document.body.removeEventListener("mousedown", this.handleBlur);
  }

  handleBlur = event => {
    const { listNode } = this.state;
    // eslint-disable-next-line
    if (listNode && !ReactDOM.findDOMNode(listNode).contains(event.target)) {
      if (listNode && listNode.classList.contains("visible")) {
        listNode.click();
      }
    }
  };

  loadDataForFiltersOptions = () => {
    const { actions } = this.props;
    actions.getFarmsSummary();
    actions.getVarietiesList();
    actions.getCropsList();
    actions.getTasksIdsList();
    actions.getAreasList();
    actions.getChemicalsList();
    actions.getEmployeeList();
  };

  prepareOptions = (options, value) => {
    return options
      ? orderBy(
          options.map(option => ({
            ...option,
            checked: value.includes(option.value.toString())
          })),
          ["checked", item => item.text.toLowerCase()],
          ["desc"]
        )
      : null;
  };

  getQueryString = (type, value, alias, filter, queryString) => {
    const dateRange = () => {
      value.forEach((value, index) => {
        if (value) {
          queryString = `${queryString ? `${queryString}&` : ""}${alias ||
            filter}${index === 0 ? "After" : "Before"}=${value}`;
        }
      });
      return queryString;
    };

    const inputRange = () => {
      value.forEach((value, index) => {
        if (value) {
          queryString = `${queryString ? `${queryString}&` : ""}${alias ||
            filter}${index === 0 ? "GreaterThan" : "LessThan"}=${value}`;
        }
      });
      return queryString;
    };

    const defaultValue = () => {
      value.forEach(value => {
        if (value) {
          queryString = `${queryString ? `${queryString}&` : ""}${alias ||
            filter}=${value}`;
        }
      });
      return queryString;
    };
    const singleSelect = () => {
      value.forEach((value, index) => {
        if (value) {
          queryString = `${queryString ? `${queryString}&` : ""}${
            index === 0 ? "dateAfter" : "dateBefore"
          }=${value}`;
        }
      });
      return queryString;
    };

    const filterTypes = {
      dateRange,
      inputRange,
      defaultValue,
      singleSelect
    };

    return (filterTypes[type] || filterTypes.defaultValue)();
  };

  getTaskByFilters = async () => {
    const {
      activeFilters,
      queryString: queryStringPrev,
      isAlreadyCalledOnce
    } = this.state;
    const { pagination, location, urlString } = this.props;
    const queryString = Object.keys(activeFilters).reduce(
      (queryString, filter) => {
        const targetFilter = this.filters[filter];
        const currentFilter = activeFilters[filter];

        return this.getQueryString(
          targetFilter.type,
          currentFilter.value,
          targetFilter.alias,
          filter,
          queryString
        );
      },
      ""
    );

    if (queryString !== queryStringPrev || !isAlreadyCalledOnce) {
      const page =
        location.hash === "#fromSave" && pagination ? pagination.page : 0;
      if (
        !isAlreadyCalledOnce &&
        (location.hash !== this.state.prevProps.location.hash ||
          this.props.activeFiltersMatch !==
            this.state.prevProps.activeFiltersMatch)
      ) {
        const newQueryString = this.props.fromReportPage
          ? queryString?.substr(42)
          : queryString;
        await this.getSprayTasksCall(newQueryString, page);
      } else {
        const newQueryString = this.props.fromReportPage
          ? queryString?.substr(42)
          : queryString;
        this.getSprayTasks(newQueryString, page);
      }
    }

    if (location.hash === "#fromSave") {
      this.setUrl(urlString, true, true);
    }

    this.setState({
      queryString,
      isAlreadyCalledOnce: true
    });
  };

  getSprayTasksCall = async (search, page = 0) => {
    const { actions, sort, pagination } = this.props;
    let newFilters = {};
    for (const property in this.state.activeFilters) {
      newFilters = {
        ...newFilters,
        [property]: this.state.activeFilters[property].value
      };
    }
    await actions.fetchSprayTasks({
      search,
      filters: newFilters,
      sort,
      pagination: { ...pagination, page }
    });
    actions.setFieldValue({ fieldName: "queryString", fieldValue: search });
  };

  getSprayTasks = debounce(this.getSprayTasksCall, 500);

  getValueToShow = (type, value, targetOptions) => {
    const defaultSeason = this.props.data.seasons?.reduce(function(
      prev,
      current
    ) {
      return prev.startAt > current.startAt ? prev : current;
    },
    {});
    const defaultDate = {
      key: defaultSeason?.seasonName,
      text: defaultSeason?.seasonName,
      value: [defaultSeason?.startAt, defaultSeason?.endAt]
    };
    const singleSelect = () => {
      const targetOption = targetOptions?.find(
        option => JSON.stringify(option.value) === JSON.stringify(value)
      );
      return targetOption ? targetOption.text : "ALL";
    };
    const multiSelect = () => {
      return truncate(
        orderBy(
          value.reduce((toShow, item) => {
            const targetOption = targetOptions.find(
              option => option.value === item
            );
            if (targetOption) {
              toShow.push(targetOption.text);
            }

            return toShow;
          }, []),
          [item => item.toLowerCase()]
        ).join(", "),
        {
          length: 40
        }
      );
    };
    const dateRange = () => {
      return value[0] || value[1]
        ? `${value[0] ? moment(value[0]).format("DD/MM/YYYY") : "Any Time"} - ${
            value[1] ? moment(value[1]).format("DD/MM/YYYY") : "Any Time"
          }`
        : "Any Time";
    };
    const inputRange = () => {
      return value[0] || value[1]
        ? `${value[0] ? value[0] : "Any"} - ${value[1] ? value[1] : "Any"}`
        : "Any";
    };
    const defaultValue = () => {
      const targetOption = targetOptions.find(
        option => option.value.toString() === value[0]
      );
      return targetOption ? targetOption.text : defaultDate.text;
    };

    const dataTypes = {
      multiSelect,
      multiSelectItems: multiSelect,
      singleSelect,
      dateRange,
      inputRange,
      defaultValue
    };
    return (dataTypes[type] || dataTypes.defaultValue)();
  };

  fillFilters = activeToSplit => {
    const { filtersOptions } = this.props;
    const { activeFilters: currentFilters } = this.state;

    const activeFilters = activeToSplit.reduce((gotFilters, filter) => {
      if (filter && filter.indexOf("=") > 0) {
        const filterToValue = filter.split("=");
        let value = filterToValue[1].length ? filterToValue[1].split(",") : [];
        const targetFilter = this.filters[filterToValue[0]];
        if (targetFilter) {
          const targetOptions = filtersOptions[targetFilter.options];
          const searchOptions = this.prepareOptions(
            filtersOptions[targetFilter.options],
            value
          );

          if (targetFilter.numbers) {
            value = value.map(item => Number(item));
          }
          const valueToShow = this.getValueToShow(
            targetFilter.type,
            value,
            targetOptions
          );

          const defaultSeason = this.props.data.seasons?.reduce(function(
            prev,
            current
          ) {
            return prev.startAt > current.startAt ? prev : current;
          },
          {});
          const defaultDate = {
            key: defaultSeason?.seasonName,
            text: defaultSeason?.seasonName,
            value: [defaultSeason?.startAt, defaultSeason?.endAt]
          };
          targetFilter.name === "Season" && !this.props.fromReportPage
            ? (gotFilters[filterToValue[0]] = {
                ...(currentFilters[filterToValue[0]] || {}),
                search: "",
                searchOptions,
                value: value.length ? value : defaultDate.value,
                options: filtersOptions[this.filters[filterToValue[0]].options],
                valueToShow: valueToShow ? valueToShow : "All",
                ref: React.createRef(),
                props: targetFilter
              })
            : (gotFilters[filterToValue[0]] = {
                ...(currentFilters[filterToValue[0]] || {}),
                search: "",
                searchOptions,
                value,
                options: filtersOptions[this.filters[filterToValue[0]].options],
                valueToShow: valueToShow ? valueToShow : "All",
                ref: React.createRef(),
                props: targetFilter
              });
        }
      }
      return gotFilters;
    }, {});

    const addOptions = Object.keys(this.filters)
      .filter(item => !activeFilters[item] && !this.filters[item].hidden)
      .map(item => ({
        key: item,
        text: this.filters[item].name,
        value: item,
        label: { icon: "plus" }
      }));

    const filtersToShow = orderBy(
      Object.keys(activeFilters).map(filter => ({
        name: filter,
        permanent: this.filters[filter].permanent
      })),
      ["permanent"]
    ).map(filter => filter.name);

    return {
      activeFilters,
      filtersToShow,
      filtersCount: Object.keys(activeFilters).length,
      addOptions
    };
  };

  filtersToObject = async ({
    addDefaultSelected,
    clearActiveFilters = false
  }) => {
    const { activeFiltersMatch } = this.props;
    const { activeFilters: currentFilters } = this.state;

    const activeToSplit = (clearActiveFilters
      ? ""
      : activeFiltersMatch || ""
    ).split(":");

    /*  this.props.fromReportPage && delete filters["season"].defaultSelected */

    Object.keys(this.filters).forEach(filter => {
      if (
        ((this.filters[filter].defaultSelected && addDefaultSelected) ||
          this.filters[filter].permanent) &&
        !activeToSplit.find(active => active.indexOf(filter) === 0)
      ) {
        if (!activeToSplit[0]) {
          activeToSplit[0] = `${filter}=`;
        } else {
          activeToSplit.unshift(`${filter}=`);
        }
      }
    });

    const { activeFilters } = this.fillFilters(activeToSplit);

    if (clearActiveFilters) {
      this.mixpanelTrackActiveFilters(activeFilters, {
        action: "clearFilters"
      });
    }

    await this.setFilters(
      clearActiveFilters
        ? activeFilters
        : { ...currentFilters, ...activeFilters }
    );
    this.getTaskByFilters();
  };

  filtersToString = (filters = {}) => {
    return Object.keys(filters).reduce((urlString, item) => {
      return filters[item].value && filters[item].value[0] !== undefined
        ? `${urlString}${urlString ? ":" : ""}${item}=${filters[
            item
          ].value.join(",")}`
        : urlString;
    }, "");
  };

  setUrl = (urlString, replace = false, clearHash = false, defaultSort) => {
    const { activeTaskMatch, route, location } = this.props;
    const targetLocation = `${route.href}${
      urlString ? `/${urlString}` : activeTaskMatch ? "/" : ""
    }${activeTaskMatch ? `/${activeTaskMatch}` : ""}`;
    const targetHash =
      location.hash && !clearHash
        ? `${location.hash}`
        : defaultSort
        ? `#sort=${defaultSort}`
        : "";
    const targetUrl = `${targetLocation}${targetHash}`;
    if (targetUrl !== `${location.pathname}${location.hash}`) {
      history[replace ? "replace" : "push"](targetUrl);
    }
  };

  mixpanelTrackActiveFilters(activeFilters, props) {
    Mixpanel.track(MixpanelEvents.SET_FILTERS, {
      ...props,
      filters: Object.keys(activeFilters),
      filtersValues: Object.keys(activeFilters).reduce((map, filter) => {
        map[filter] = activeFilters[filter].value;
        return map;
      }, {})
    });
  }

  setFilters = (activeFilters = {}) => {
    const { actions, online, urlString } = this.props;
    const urlStringToSet = this.filtersToString(activeFilters);

    const activeToSplit = Object.keys(activeFilters).map(
      filter => `${filter}=${activeFilters[filter].value.join(",")}`
    );

    const {
      activeFilters: activeFiltersToSave,
      filtersCount,
      addOptions,
      filtersToShow
    } = this.fillFilters(activeToSplit);

    if (urlStringToSet !== urlString) {
      actions.setUrlString({ online, urlString: urlStringToSet });
    }

    return this.setState(
      {
        activeFilters: activeFiltersToSave,
        filtersCount,
        addOptions,
        filtersToShow
      },
      () => {
        this.setUrl(urlStringToSet);
      }
    );
  };

  onFiltersChange = event => {
    const targetDropdown = this.filtersRef.current;
    if (targetDropdown && event) {
      // need timeout to wait setState from dropdown
      setTimeout(() => {
        if (targetDropdown.state.value) {
          this.activateFilter(null, { value: targetDropdown.state.value });
        }
      }, 150);
    }
  };

  activateFilter = (_, data, value = [], defaultOpen = true) => {
    const { activeFilters } = this.state;
    const { filtersOptions } = this.props;
    activeFilters[data.value] = {
      value,
      options: filtersOptions[this.filters[data.value].options],
      defaultOpen
    };
    if (data.value === "status") {
      delete activeFilters.statusTabs;
    }
    this.mixpanelTrackActiveFilters(activeFilters, {
      action: "activateFilter"
    });
    this.setFilters(activeFilters);
  };

  removeFilter = (_, filter) => {
    const { activeFilters } = this.state;
    delete activeFilters[filter];
    this.mixpanelTrackActiveFilters(activeFilters, { action: "removeFilter" });
    this.setFilters(activeFilters);
  };

  clearFilters = () => {
    this.filtersToObject({
      addDefaultSelected: true,
      clearActiveFilters: true
    });
  };

  changeFilterSearch = event => {
    const { activeFilters } = this.state;
    const { filtersOptions } = this.props;
    const { name, value } = event.target;
    const targetFilter = this.filters[name];

    const searchOptions = this.prepareOptions(
      filtersOptions[targetFilter.options].filter(
        option => option.text.toLowerCase().indexOf(value.toLowerCase()) >= 0
      ),
      activeFilters[name].value
    );
    this.mixpanelTrackActiveFilters(activeFilters, {
      action: "changeFilterSearch"
    });
    this.setState({
      activeFilters: {
        ...activeFilters,
        [name]: { ...activeFilters[name], searchOptions }
      }
    });
  };

  setStatusFilter = (_, data) => {
    const { activeIndex } = data;
    const {
      filtersOptions: { statusOptions },
      online
    } = this.props;
    if (!online) {
      return false;
    }
    this.removeFilter(null, "status");
    if (activeIndex) {
      this.activateFilter(
        null,
        { value: "statusTabs" },
        [statusOptions[activeIndex - 1].value],
        false
      );
    } else {
      this.removeFilter(null, "statusTabs");
    }
  };

  toggleOption = ({ event, filter, value }) => {
    if (event) {
      event.stopPropagation();
    }
    const { activeFilters } = this.state;
    const targetFilter = activeFilters[filter];
    const valueIndex = targetFilter.value.findIndex(item => item === value);
    if (valueIndex >= 0) {
      targetFilter.value.splice(valueIndex, 1);
    } else {
      targetFilter.value.push(value);
    }

    this.mixpanelTrackActiveFilters(activeFilters, { action: "toggleOption" });
    this.setFilters(activeFilters);
  };

  onListChange = ({ filter, value }) => {
    const { activeFilters } = this.state;
    const targetFilter = activeFilters[filter];
    targetFilter.value = value;
    this.mixpanelTrackActiveFilters(activeFilters, { action: "onListChange" });
    this.setFilters(activeFilters);
    this.correctPosition();
  };

  onSwitchChange = ({ filter, value }) => {
    const { activeFilters } = this.state;
    const targetFilter = activeFilters[filter];
    targetFilter.value = [value];
    this.mixpanelTrackActiveFilters(activeFilters, {
      action: "onSwitchChange"
    });
    this.setFilters(activeFilters);
    this.correctPosition();
  };

  toggleRangeOption = ({ filter, value, option }) => {
    const { activeFilters } = this.state;
    const targetFilter = activeFilters[filter];
    targetFilter.value[option === "from" ? 0 : 1] = value;
    this.mixpanelTrackActiveFilters(activeFilters, {
      action: "toggleRangeOption"
    });
    this.setFilters(activeFilters);
  };

  correctPosition = () => {
    const { listNode } = this.state;

    setTimeout(() => {
      if (listNode) {
        const dropDown = listNode.querySelector(".dropdownMenu");
        if (dropDown) {
          dropDown.style.marginLeft = 0;
          const bounding = dropDown.getBoundingClientRect();
          if (bounding.x + bounding.width + 15 > window.innerWidth) {
            dropDown.style.marginLeft = `${window.innerWidth -
              bounding.width -
              bounding.x -
              15}px`;
          }
        }
      }
    }, 10);
  };
  openList = (event, data) => {
    const { activeFilters } = this.state;
    const { name: filter } = data;

    setTimeout(() => {
      // eslint-disable-next-line
      const listNode = activeFilters[filter]
        ? ReactDOM.findDOMNode(activeFilters[filter].ref.current)
        : null;
      if (!listNode) {
        return;
      }

      if (listNode.querySelector("input")) {
        listNode.querySelector("input").focus();
      }
      if (filter) {
        activeFilters[filter].defaultOpen = false;
      }

      this.setState(
        {
          listNode,
          activeFilters
        },
        () => {
          this.correctPosition();
        }
      );
    });
  };

  closeList = () => {
    this.setState({
      listNode: null
    });
  };

  getFilter = filter => {
    const { online } = this.props;
    const { activeFilters } = this.state;
    const targetFilter = this.filters[filter];
    const activeFilter = activeFilters[filter];

    const disableChecker =
      !!activeFilters.date &&
      !!activeFilters.date?.value.filter(item => !!item).length
        ? "date"
        : !!activeFilters.season && !!activeFilters.season?.value.length
        ? "season"
        : null;
    const multiSelect = (
      <Dropdown
        filter={targetFilter}
        name={filter}
        labeled
        floating
        button
        text={`${targetFilter.name}: ${activeFilter.valueToShow}`}
        closeOnChange={false}
        icon={null}
        disabled={!online}
        defaultOpen={activeFilter.defaultOpen}
        onOpen={this.correctPosition}
        ref={activeFilter.ref}
      >
        <Dropdown.Menu className={`dropdownMenu ${styles.dropdownMenu}`}>
          <Input
            name={filter}
            icon="search"
            className="search"
            onChange={this.changeFilterSearch}
            onClick={e => e.stopPropagation()}
          />
          <Dropdown.Divider />
          {activeFilter.searchOptions && activeFilter.searchOptions.length ? (
            activeFilter.searchOptions.map(option => (
              <Dropdown.Item
                key={option.key}
                onClick={event =>
                  this.toggleOption({ event, filter, value: option.value })
                }
                className={option.checked ? styles.checked : null}
              >
                <Icon
                  name={`${option.checked ? "check " : ""}square outline`}
                />
                {option.text}
              </Dropdown.Item>
            ))
          ) : (
            <div className={styles.nothing}>Nothing found</div>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
    const singleSelect = (
      <Dropdown
        filter={targetFilter}
        name={filter}
        labeled
        button
        floating
        text={`${targetFilter.name}: ${activeFilter.valueToShow}`}
        closeOnChange={false}
        closeOnBlur={false}
        icon={null}
        disabled={!online || disableChecker === "date"}
        onOpen={this.openList}
        onClose={this.closeList}
        ref={activeFilter.ref}
      >
        <Dropdown.Menu
          className={`dropdownMenu ${styles.dropdownMenu} ${styles.selectItemsHolder}`}
        >
          <Dropdown
            // disabled={this.props.tasks.isFetching}
            fluid
            search={!this.props.tasks.isFetching}
            options={activeFilter.searchOptions}
            className={styles.selectItems}
            onChange={(_, data) =>
              this.onListChange({ filter, value: data.value })
            }
            value={activeFilter.value}
          />
        </Dropdown.Menu>
      </Dropdown>
    );
    const multiSelectItems = (
      <Dropdown
        filter={targetFilter}
        name={filter}
        labeled
        button
        floating
        text={`${targetFilter.name}: ${activeFilter.valueToShow}`}
        closeOnChange={false}
        closeOnBlur={false}
        icon={null}
        disabled={!online}
        defaultOpen={activeFilter.defaultOpen}
        onOpen={this.openList}
        onClose={this.closeList}
        ref={activeFilter.ref}
      >
        <Dropdown.Menu
          className={`dropdownMenu ${styles.dropdownMenu} ${styles.selectItemsHolder}`}
        >
          <Dropdown
            // disabled={this.props.tasks.isFetching}
            fluid
            multiple
            search={!this.props.tasks.isFetching}
            options={activeFilter.searchOptions}
            className={styles.selectItems}
            onChange={(_, data) =>
              this.onListChange({ filter, value: data.value })
            }
            defaultOpen
            value={activeFilter.value}
          />
        </Dropdown.Menu>
      </Dropdown>
    );

    const dateRange = (
      <Dropdown
        filter={targetFilter}
        name={filter}
        labeled
        button
        text={`${targetFilter.name}: ${activeFilter.valueToShow}`}
        closeOnChange={false}
        icon={null}
        disabled={
          !online ||
          (disableChecker === "season" && targetFilter.name === "Date")
        }
        onOpen={this.correctPosition}
        ref={activeFilter.ref}
      >
        <Dropdown.Menu
          onClick={event => event.stopPropagation()}
          className={`dropdownMenu ${styles.dropdownMenu} ${styles.dateRangeHolder}`}
        >
          <div className={styles.filterHolder}>
            {activeFilter.options?.map(option => (
              <DatePicker
                key={option.key}
                size="small"
                value={activeFilter.value[option.key === "from" ? 0 : 1]}
                onChange={date =>
                  this.toggleRangeOption({
                    filter,
                    option: option.key,
                    value: date
                  })
                }
                labelText={option.text}
                className={styles.datePicker}
              />
            ))}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );

    const inputRange = (
      <Dropdown
        filter={targetFilter}
        name={filter}
        labeled
        button
        text={`${targetFilter.name}: ${activeFilter.valueToShow}`}
        closeOnChange={false}
        icon={null}
        disabled={!online}
        defaultOpen={activeFilter.defaultOpen}
        onOpen={this.correctPosition}
        ref={activeFilter.ref}
      >
        <Dropdown.Menu
          onClick={event => event.stopPropagation()}
          className={`dropdownMenu ${styles.dateRangeHolder}`}
        >
          <div className={styles.filterHolder}>
            {activeFilter.options?.map(option => (
              <div className={styles.inputHolder} key={option.key}>
                <label>
                  <div>{option.text}</div>
                  <div>
                    <Input
                      key={option.key}
                      type="number"
                      fluid
                      value={activeFilter.value[option.key === "from" ? 0 : 1]}
                      numeric
                      onChange={event =>
                        this.toggleRangeOption({
                          filter,
                          option: option.key,
                          value: event.target.value
                        })
                      }
                    />
                  </div>
                </label>
              </div>
            ))}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );

    const switchType = (
      <Dropdown
        filter={targetFilter}
        name={filter}
        labeled
        button
        text={`${targetFilter.name}: ${activeFilter.valueToShow}`}
        closeOnChange={false}
        icon={null}
        disabled={!online}
        defaultOpen={activeFilter.defaultOpen}
        onOpen={this.correctPosition}
        ref={activeFilter.ref}
      >
        <Dropdown.Menu
          onClick={event => event.stopPropagation()}
          className={`dropdownMenu ${styles.dateRangeHolder}`}
        >
          <div className={styles.filterHolder}>
            <Menu fluid className={styles.switchMenu}>
              {activeFilter.options?.map(option => (
                <Menu.Item
                  key={option.key}
                  active={
                    activeFilter.value[0] === option.value ||
                    (activeFilter.value.length === 0 && !option.value)
                  }
                  onClick={() =>
                    this.onSwitchChange({ filter, value: option.value })
                  }
                >
                  {option.text}
                </Menu.Item>
              ))}
            </Menu>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );

    const defaultType = <span />;

    const componentsTypes = {
      multiSelect,
      multiSelectItems,
      singleSelect,
      dateRange,
      inputRange,
      switchType,
      defaultType
    };

    const FilterComponent =
      componentsTypes[targetFilter.type] || componentsTypes.defaultType;

    return !targetFilter.hidden ? (
      <div key={filter} className={styles.filterBox}>
        {FilterComponent}
        {!targetFilter.permanent && (
          <Button
            icon="close"
            className="button-text"
            disabled={
              !online ||
              (disableChecker === "date" &&
                activeFilter.props.name === "Season") ||
              (disableChecker === "season" &&
                activeFilter.props.name === "Date")
            }
            onClick={event => this.removeFilter(event, filter)}
          />
        )}
      </div>
    ) : null;
  };

  clearButton = (classNames = "") => {
    const { urlString } = this.props;
    const { activeFilters } = this.state;
    return (
      (Object.keys(activeFilters).filter(
        filter => !activeFilters[filter].props?.permanent
      ).length > 0 ||
        urlString) && (
        <span className={`${classNames} ${styles.clearHolder}`}>
          <Button
            className="button-text"
            content="Clear All"
            onClick={this.clearFilters}
          />
        </span>
      )
    );
  };

  render() {
    const {
      // eslint-disable-next-line
      filtersOptions: { statusOptions },
      online
    } = this.props;
    const {
      addOptions,
      activeFilters,
      filtersCount,
      filtersToShow
    } = this.state;

    // eslint-disable-next-line
    const statusFilter = activeFilters.status || activeFilters.statusTabs;
    return (
      <div className={styles.tabsAndFilters}>
        <div className={styles.filtersHolder}>
          <div className={styles.filterAdd}>
            <Dropdown
              floating
              labeled
              button
              // disabled={!addOptions.length || !online}
              className={`icon ${styles.buttonFilters}`}
              search
              icon={
                <>
                  <Icon name="sliders horizontal" />
                  <span className={styles.countFilters}>{filtersCount}</span>
                </>
              }
              text="Add Filter"
              selection
              options={addOptions}
              onClose={this.onFiltersChange}
              selectOnBlur={false}
              ref={this.filtersRef}
            />
          </div>
          {this.clearButton("hide-md-inline-block")}
          {filtersToShow.length > 0 && (
            <span className={styles.filtersItems}>
              {filtersToShow.map(this.getFilter)}
            </span>
          )}
          {this.clearButton("show-md-inline-block")}
        </div>
      </div>
    );
  }
}

TasksFilters.propTypes = {
  Can: PropTypes.func,
  dispatch: PropTypes.func,
  route: PropTypes.object,
  activeFilters: PropTypes.string,
  defaultSort: PropTypes.string,
  filtersOptions: PropTypes.object,
  activeTask: PropTypes.string,
  activeFiltersMatch: PropTypes.string,
  activeTaskMatch: PropTypes.string,
  online: PropTypes.bool,
  actions: PropTypes.object,
  urlString: PropTypes.string,
  sort: PropTypes.array,
  location: PropTypes.object,
  pagination: PropTypes.object,
  tasks: PropTypes.object
};

TasksFilters.defaultProps = {};

function mapStateToProps(state) {
  const {
    spray: { urlString, sort, queryString, tasks },
    hourlyPayroll: { fromReportPage },
    settings: { data },
    router: { location }
  } = state;
  return {
    filtersOptions: filtersOptionsSelector(state),
    fromReportPage,
    urlString,
    sort,
    data,
    location,
    pagination: paginationSelector(state),
    queryString,
    tasks
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        // pageChecker,
        getFarmsSummary,
        getVarietiesList,
        getCropsList,
        fetchSprayTasks,
        getTasksIdsList,
        setFieldValue,
        setUrlString,
        getAreasList,
        getChemicalsList,
        getEmployeeList
      },
      dispatch
    ),
    dispatch
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TasksFilters);
