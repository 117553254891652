import { actionType } from "../../constants/SprayDiary";
import { history } from "../../store";

export const getEmployeeList = () => (dispatch, getState) => {
  const { employee } = getState();
  dispatch({
    type: actionType.GET_EMPLOYEE_LIST_SUCCESS,
    payload: employee.list
  });
};

export const selectEmployees = (employees, fieldName) => dispatch => {
  dispatch({
    type: actionType.SELECT_EMPLOYEES,
    payload: { employees, fieldName }
  });
};

export const selectSingleEmployee = (employee, fieldName) => dispatch => {
  dispatch({
    type: actionType.SELECT_SINGLE_EMPLOYEE,
    payload: { employee, fieldName }
  });
};
