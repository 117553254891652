import { Select } from 'semantic-ui-react';
import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

const timezoneOptions = moment.tz.names().map(name => ({ key: name, text: name, value: name }));

const TimezoneSelect = React.memo(({ timezone, changeValue }) => {
  return (
    <Select
      options={timezoneOptions}
      search
      value={timezone}
      required
      id="timezone"
      name="timezone"
      filterOption={false}
      onChange={(event, data) => {
        event.preventDefault();
        changeValue({ target: { name: 'timezone', value: data.value } });
      }}
    />
  );
});

TimezoneSelect.propTypes = {
  timezone: PropTypes.string.isRequired,
  changeValue: PropTypes.func,
};

TimezoneSelect.defaultProps = {
  changeValue: () => {},
};

export default TimezoneSelect;
