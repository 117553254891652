import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Grid, Input, Form, Menu, Icon } from "semantic-ui-react";
import { bindActionCreators } from "redux";
import {
  setWindSpeed,
  setTemperature,
  setWindDirection,
  setDeltaT
} from "../../../../../actions/SprayDiary/common";
import {
  directionSelector,
  strengthsSelector
} from "../../../../../selectors/sprayDiary";
import UpdateInfoFromWeatherStationButton from "../UpdateInfoFromWeatherStationButton";

const SprayConditions = ({
  status,
  windStrength,
  deltaT,
  temperature,
  temperatureValidation,
  windStrengthValidation,
  windDirectionValidation,
  actions: { setTemperature, setWindSpeed, setWindDirection, setDeltaT },
  directionOptions,
  strengthsOptions,
  windDirection
}) => {
  if (status !== "COMPLETED") return null;
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column
          mobile={16}
          tablet={16}
          computer={4}
          largeScreen={3}
          widescreen={2}
        >
          <Form.Field required className="sprayField">
            <label className="title">Temperature</label>
            <div className="icon-label">
              <Icon className="tuf-thermometer-half" />
              <Input
                error={!temperatureValidation}
                type="number"
                value={temperature}
                onChange={e => setTemperature(e.target.value)}
                label="°C"
                labelPosition="right"
                fluid
              />
            </div>
          </Form.Field>
        </Grid.Column>
        <Grid.Column
          mobile={16}
          tablet={16}
          computer={12}
          largeScreen={9}
          widescreen={6}
        >
          <Form.Field
            required
            className={`sprayField ${!windDirectionValidation ? "error" : ""}`}
          >
            <label className="title">Wind direction</label>
            <div className="icon-label">
              <Icon className="tuf-compass-solid" />
              <Menu fluid className="sprayMenu">
                {directionOptions.map(option => (
                  <Menu.Item
                    key={option.key}
                    active={windDirection === option.value}
                    onClick={() => setWindDirection(option.value)}
                  >
                    {option.icon && (
                      <Icon
                        name={option.icon.name}
                        style={option.icon.style || null}
                      />
                    )}
                    {option.text}
                  </Menu.Item>
                ))}
              </Menu>
            </div>
          </Form.Field>
        </Grid.Column>
        <Grid.Column
          mobile={16}
          tablet={16}
          computer={4}
          largeScreen={3}
          widescreen={2}
        >
          <Form.Field className="sprayField">
            <label className="title">Delta T</label>
            <div className="icon-label">
              <Input
                type="number"
                value={deltaT}
                onChange={e => setDeltaT(e.target.value)}
                fluid
              />
            </div>
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column
          mobile={16}
          tablet={16}
          computer={16}
          largeScreen={12}
          widescreen={8}
        >
          <Form.Field
            required
            className={`sprayField ${!windStrengthValidation ? "error" : ""}`}
          >
            <label className="title">Wind strength</label>
            <div className="icon-label">
              <Icon className="tuf-wind" />
              <Menu fluid className="sprayMenu">
                {strengthsOptions.map(option => (
                  <Menu.Item
                    key={option.key}
                    active={windStrength === option.value}
                    onClick={() => setWindSpeed(option.value)}
                  >
                    {option.text}
                  </Menu.Item>
                ))}
              </Menu>
            </div>
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column
          mobile={16}
          tablet={16}
          computer={16}
          largeScreen={12}
          widescreen={8}
        >
          <UpdateInfoFromWeatherStationButton />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const mapStateToProps = state => {
  const {
    sprayDiary: {
      status,
      windStrength,
      temperature,
      deltaT,
      windDirection,
      validations: {
        temperature: temperatureValidation,
        windStrength: windStrengthValidation,
        windDirection: windDirectionValidation
      }
    }
  } = state;

  return {
    status,
    windStrength,
    temperature,
    deltaT,
    windDirection,
    temperatureValidation,
    windStrengthValidation,
    windDirectionValidation,
    strengthsOptions: strengthsSelector(state),
    directionOptions: directionSelector(state)
  };
};

SprayConditions.propTypes = {
  status: PropTypes.string,
  windStrength: PropTypes.bool,
  temperature: PropTypes.bool,
  windDirectionValidation: PropTypes.bool,
  windStrengthValidation: PropTypes.bool,
  deltaT: PropTypes.string,
  temperatureValidation: PropTypes.object,
  directionOptions: PropTypes.array,
  windDirection: PropTypes.string,
  strengthsOptions: PropTypes.array,
  actions: PropTypes.object
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setWindSpeed,
      setTemperature,
      setDeltaT,
      setWindDirection
    },
    dispatch
  )
});
export default connect(mapStateToProps, mapDispatchToProps)(SprayConditions);
