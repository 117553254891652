import PropTypes from "prop-types";
import React from "react";
import { Grid, Header } from "semantic-ui-react";
import moment from "moment";
import Logo from "../../../../components/Logo";

const PrintLayout = ({ date, client, orderNumber, pallets, notes }) => {
  const dateToShow = date ? moment(date).format("DD/MM/YYYY") : "-";
  return (
    <Grid>
      <Grid.Row columns={2}>
        <Grid.Column verticalAlign={"middle"}>
          <Header as={"h1"} style={{ fontWeight: "bold" }}>
            Dispatch Report
          </Header>
        </Grid.Column>
        <Grid.Column textAlign={"right"}>
          <Logo />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2} style={{ borderBottom: "2px solid black" }}>
        <Grid.Column>
          <Header as="h2">Information</Header>
        </Grid.Column>
        <Grid.Column textAlign={"right"}>
          <b>{dateToShow}</b>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={4} style={{ borderBottom: "2px solid black" }}>
        <Grid.Column>
          <Header style={{ fontWeight: "bold" }}>Client/Buyer</Header>
          <span>{client}</span>
        </Grid.Column>
        <Grid.Column>
          <Header style={{ fontWeight: "bold" }}>Order number</Header>
          <span>{orderNumber}</span>
        </Grid.Column>
        <Grid.Column>
          <Header style={{ fontWeight: "bold" }}>Number of pallets</Header>
          <span>{pallets.length}</span>
        </Grid.Column>
        <Grid.Column>
          <Header style={{ fontWeight: "bold" }}>Total number of units</Header>
          <span>
            {pallets.reduce((sum, pallet) => sum + pallet.unitsOnPallet, 0)}
          </span>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Header style={{ fontWeight: "bold" }}>Pallets</Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row style={{ borderBottom: "2px solid black" }}>
        <Grid.Column>
          <table style={{ width: "100%", textAlign: "left" }}>
            <thead>
              <tr>
                <th>ID</th>
                <th>Date</th>
                <th>Variety</th>
                <th>Box Units</th>
                <th>Area</th>
                <th>Registration</th>
                <th>Units on Pallet</th>
              </tr>
            </thead>
            <tbody>
              {pallets.map((pallet, i) => (
                <tr key={`pallet_${i}`}>
                  <td>{pallet.id}</td>
                  <td>{pallet.createDate}</td>
                  <td>{pallet.variety.name}</td>
                  <td>{pallet.boxUnit.displayName}</td>
                  <td>
                    {pallet.areas.map(({ area }) => area.name).join(", ")}
                  </td>
                  <td>{pallet.areas[0].area.registrationNumber}</td>
                  <td>{pallet.unitsOnPallet}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Header style={{ fontWeight: "bold" }}>Notes</Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <span>{notes}</span>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column mobile={4} computer={4} largeScreen={4} floated="right">
          <strong>Signature</strong>
          <br />
          <strong>_____________________________</strong>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

PrintLayout.propTypes = {
  client: PropTypes.string.isRequired,
  date: PropTypes.object,
  notes: PropTypes.string,
  orderNumber: PropTypes.string.isRequired,
  pallets: PropTypes.arrayOf(
    PropTypes.shape({ numberOfUnits: PropTypes.number })
  ).isRequired
};

export default PrintLayout;
