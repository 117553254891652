import React from "react";
import moment from "moment";
import filterTypes from "components/Table/filterTypes";
import BasicCell from "components/Table/components/BasicCell";
import { matterToUnits } from "./constToUnits";
import { Icon, Popup, Button } from "semantic-ui-react";
import { chemicalsOptionsFilterSelector } from "selectors/chemicals";
import { shedListSelector } from "selectors/sheds";
import { supplierSelector } from "selectors/purchaseHistory";
import DateRangeSelect from "components/Table/components/Filters/DateRangeSelect";
import { get } from "lodash";
import AttachmentLabel from "components/Attachments/AttachmentLabel";
import style from "../routes/Chemicals/PurchaseHistory/PurchaseHistory.module.css";
import Numeric from "components/Numeric/Numeric";
export const getPurchaseHistoryTableColumns = (
  editCheck,
  printButton,
  purchaseDelete
) => {
  const columnsConfig = {
    orderNumber: {
      title: "Receipt  Number",
      withAttachFiles: true,
      type: "number",
      withSort: true,
      accessor: ({ orderNumber }) => {
        return <div className={style.valueWrapper}>{orderNumber}</div>;
      },
      cellWidth: 2
    },
    orderDate: {
      accessor: ({ orderDate }) => {
        return moment(orderDate).format("DD/MM/YYYY");
      },
      title: "Order date",
      withAttachFiles: true,
      filterId: "chemicalIds",
      type: "number",
      withSort: true,
      cellWidth: 2,
      filter: {
        title: "Product",
        type: filterTypes.MultiSelect,
        selector: chemicalsOptionsFilterSelector
      }
    },
    deliveryDate: {
      title: "Delivery date",
      withAttachFiles: true,
      accessor: ({ deliveryDate }) => {
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const moment = require("moment-timezone");
        const userTimeZoneDate = moment.utc(deliveryDate);
        const convertedDate = userTimeZoneDate
          .tz(userTimeZone)
          .format("YYYY-MM-DDTHH:mm:ss");

        return moment(convertedDate).format("DD/MM/YYYY");
      },
      type: "number",
      withSort: true,
      filterId: "shedIds",
      cellWidth: 2,
      filter: {
        title: "Sheds",
        type: filterTypes.MultiSelect,
        selector: shedListSelector
      }
    },
    supplier: {
      title: "Suppliers",
      filterId: "supplier",
      withAttachFiles: true,
      type: "string",
      withSort: true,
      cellWidth: 2,
      filter: {
        title: "Suppliers",
        type: filterTypes.MultiSelect,
        selector: supplierSelector
      }
    },

    totalCost: {
      type: "number",
      id: "totalCost",
      withAttachFiles: true,
      withSort: true,
      title: "Total price($)",
      accessor: ({ products }) => {
        return (
          <Numeric
            fractionDigits={
              products.reduce((prev, curr) => prev + +curr.totalCost, 0) > 1000
                ? 0
                : 2
            }
            value={products.reduce((prev, curr) => prev + +curr.totalCost, 0)}
            commaSeparatorOnThousands
            units="$ "
            unitsPosition="left"
            defaultValue="-"
          />
        );
      },
      cellWidth: 2
    },
    attachment: {
      cellWidth: 2,
      withSort: false,
      withAttachFiles: true,
      title: "Order receipt",
      accessor: ({ attachments, id }) => {
        return (
          <div className={style.wrapper}>
            <div className={style.attachmentContainer}>
              {attachments &&
                attachments.map(item => {
                  return (
                    <Popup
                      inverted
                      position="bottom center"
                      content={"Edit"}
                      size="tiny"
                      trigger={
                        <AttachmentLabel
                          attachment={item}
                          withDownloadIcon={true}
                        />
                      }
                    />
                  );
                })}
            </div>
            <div className={style.iconWrapper}>
              <Popup
                inverted
                position="bottom center"
                content={"Edit"}
                size="tiny"
                trigger={
                  <div className={style.editButtonWrapper}>
                    <Button
                      onClick={() => editCheck(id)}
                      className={style.editButton}
                      floated="right"
                      icon={<Icon name="edit" />}
                      size="small"
                    />
                  </div>
                }
              />
              <Popup
                inverted
                position="bottom center"
                content={"Print"}
                size="tiny"
                trigger={
                  <Button
                    onClick={event => {
                      return printButton(id), event.stopPropagation();
                    }}
                    className={style.printButton}
                    floated="right"
                    icon={<Icon name="print" />}
                    size="small"
                  />
                }
              />
              <Popup
                inverted
                position="bottom center"
                content={"Delete"}
                size="tiny"
                trigger={
                  <Button
                    onClick={event => {
                      return purchaseDelete(id), event.stopPropagation();
                    }}
                    className={style.deleteButton}
                    floated="right"
                    icon={<Icon name="trash alternate" />}
                    size="small"
                  />
                }
              />
            </div>
          </div>
        );
      }
    }
  };

  return Object.entries(columnsConfig).map(([id, targetColumn]) => ({
    id,
    ...targetColumn,
    accessor: targetColumn.accessor || id,
    Header: targetColumn.title,
    Cell: targetColumn.Cell || BasicCell,
    ...(targetColumn.sortFields ? { sortFields: targetColumn.sortFields } : {}),
    ...(targetColumn.filter ? { filter: targetColumn.filter } : {})
  }));
};
