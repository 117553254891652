import { actionType } from "../../constants/LaborGroups";
import { axiosProxy } from "../../utils/axiosProxy";
import { fetchEmployeesLog } from "../Employee/employees";

const url = "/employee_groups";

export const addNewLaborGroup = ({ name, employeeIds }) => async dispatch => {
  try {
    dispatch({
      type: actionType.LABOR_GROUP_CREATE_START
    });
    const res = await axiosProxy({
      method: "POST",
      data: {
        name,
        employeeIds
      },
      url
    });

    dispatch({
      type: actionType.LABOR_GROUP_CREATE,
      payload: res
    });
    dispatch(fetchEmployeesLog());
    return res;
  } catch (error) {
    dispatch({
      type: actionType.LABOR_GROUP_CREATE_FAIL,
      payload: error
    });
  }
};

export const updateLaborGroup = (
  id,
  { name, employeeIds }
) => async dispatch => {
  try {
    dispatch({
      type: actionType.LABOR_GROUP_UPDATE_START
    });
    const res = await axiosProxy({
      method: "PUT",
      data: { name, employeeIds },
      url: `${url}/${id}`
    });

    dispatch({
      type: actionType.LABOR_GROUP_UPDATE,
      payload: res
    });
    dispatch(fetchEmployeesLog());
    return res;
  } catch (error) {
    dispatch({
      type: actionType.LABOR_GROUP_UPDATE_FAIL,
      payload: error
    });
  }
};

export const deleteLaborGroup = id => async dispatch => {
  try {
    dispatch({
      type: actionType.LABOR_GROUP_DELETE_START
    });
    const res = await axiosProxy({
      method: "DELETE",
      url: `${url}/${id}`
    });

    dispatch({
      type: actionType.LABOR_GROUP_DELETE,
      payload: { id, res }
    });

    return res;
  } catch (error) {
    dispatch({
      type: actionType.LABOR_GROUP_DELETE_FAIL,
      payload: error
    });
  }
};

export const fetchLaborGroups = ({
  archived = false,
  page,
  size,
  search,
  sort,
  unpaged = false,
  filters
}) => async dispatch => {
  try {
    const params = new URLSearchParams();
    if (unpaged) {
      params.append("unpaged", true);
    } else {
      dispatch({
        type: actionType.FETCH_LABOR_GROUPS_START
      });

      params.append("page", page);
      params.append("size", size);
    }

    if (search) params.append("search", search);
    if (filters)
      filters.forEach(([filter, value]) => params.append(filter, value));

    (sort || ["id,asc"]).forEach(field => {
      params.append("sort", field);
    });
    if (archived) {
      params.append("archived", archived);
    } else {
      params.append("archived", false);
    }

    const res = await axiosProxy({
      method: "GET",
      params,
      url
    });

    await dispatch({
      type: actionType.FETCH_LABOR_GROUPS,
      payload: res.data
    });

    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.FETCH_LABOR_GROUPS_FAIL,
      payload: error
    });
  }
};

export const laborGroupStatusChange = (
  archive,
  laborGroupIds
) => async dispatch => {
  try {
    dispatch({
      type: actionType.ARCHIVE_LABOR_GROUP_START
    });

    await axiosProxy({
      method: "PUT",
      url: `${url}/archive/batch?archive=${archive}&ids=${laborGroupIds.join(
        "&ids="
      )}`
    });

    dispatch({
      type: actionType.ARCHIVE_LABOR_GROUP,
      payload: laborGroupIds
    });
    dispatch(fetchEmployeesLog());
  } catch (error) {
    dispatch({
      type: actionType.ARCHIVE_LABOR_GROUP_FAIL,
      payload: error
    });
  }
};

export const deleteLaborGroups = laborGroupIds => async dispatch => {
  try {
    dispatch({
      type: actionType.LABOR_GROUPS_DELETE_START
    });

    await axiosProxy({
      method: "DELETE",
      url: `${url}/batch?ids=${laborGroupIds.join("&ids=")}`
    });

    dispatch({
      type: actionType.LABOR_GROUPS_DELETE,
      payload: laborGroupIds
    });
  } catch (error) {
    dispatch({
      type: actionType.LABOR_GROUPS_DELETE_FAIL,
      payload: error
    });
  }
};

export const fetchLaborGroupsList = () => async dispatch => {
  try {
    dispatch({
      type: actionType.FETCH_LABOR_GROUPS_LIST_START
    });

    const res = await axiosProxy({
      method: "GET",
      url: `${url}`,
      params: {
        unpaged: true,
        archived: false
      }
    });

    dispatch({
      type: actionType.FETCH_LABOR_GROUPS_LIST,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: actionType.FETCH_LABOR_GROUPS_LIST_FAIL,
      payload: error
    });
  }
};

export const clearFormData = () => dispatch => {
  dispatch({
    type: actionType.LABOR_GROUP_FORM_CLEAR
  });
};

export const setFormData = payload => dispatch => {
  dispatch({
    type: actionType.LABOR_GROUP_FORM_SET,
    payload
  });
};

export const handleChange = (name, value) => dispatch => {
  dispatch({
    type: actionType.LABOR_GROUP_FORM_CHANGE,
    payload: {
      name,
      value
    }
  });
};

export const handleAssignFormChange = (name, value) => dispatch => {
  dispatch({
    type: actionType.LABOR_GROUP_ASSIGN_FORM_CHANGE,
    payload: {
      name,
      value
    }
  });
};

export const clearAssignFormData = () => dispatch => {
  dispatch({
    type: actionType.LABOR_GROUP_ASSIGN_FORM_CLEAR
  });
};

export const clearError = () => dispatch => {
  dispatch({
    type: actionType.CLEAR_LABOR_GROUP_ERROR
  });
};
