import {
  calcBlockTanksValue,
  setFullTank,
  setPartTank
} from "../reducers/SprayDiary";
import { find, get, keyBy, map, reduce } from "lodash";
import { APPLICATION_TYPES } from "../constants/Recommendations/types";
import { copyStyles } from "./styles";
import ReactDOMServer from "react-dom/server";
import React from "react";
import RecommendationsPrintout from "../routes/Recommendations/components/RecommendationsPrintout";

export const calculateChemicalsValues = (
  newTaskData,
  machineryList,
  areasList
) => {
  const {
    machineryId,
    conc,
    literPerHectare,
    areas,
    rowWidth,
    rowsToSpray,
    chemicals = []
  } = newTaskData;
  const machinery = machineryList.content.find(m => m.id === machineryId);
  const machinerySize = get(machinery, "size");
  const areasMap = keyBy(areasList, "id");
  const totalAreasSize = reduce(
    areas,
    (sum, { areaId }) => sum + get(areasMap[areaId], "size"),
    0
  );
  const totalTanksValue = calcBlockTanksValue(
    totalAreasSize,
    literPerHectare,
    machinerySize,
    rowsToSpray,
    rowWidth
  );
  const processedChemicals = map(chemicals, chemicalData => {
    const { chemical } = chemicalData;
    const rate = chemical.rate;
    const fullTanks = setFullTank(
      !!machinery,
      rate.rateType,
      rate.minRate,
      rate.maxRate,
      rate.rate,
      get(machinery, "size"),
      conc,
      literPerHectare,
      totalAreasSize
    );
    const partTanks = machineryId
      ? setPartTank(fullTanks, totalTanksValue)
      : null;

    return {
      ...chemicalData,
      qtyPerFullVat: fullTanks,
      qtyPerPartTank: partTanks
    };
  });

  return { chemicals: processedChemicals, totalTanks: totalTanksValue };
};

export const validateRecommendationForm = values => {
  const errors = {};
  const {
    rowWidth,
    applicationType,
    plannedDate,
    literPerHectare,
    assignees,
    chemicals,
    machinery
  } = values;

  if (!rowWidth || rowWidth < 0 || rowWidth > 100) {
    errors.rowWidth = true;
  }
  if (
    APPLICATION_TYPES[applicationType] === APPLICATION_TYPES.SPRAY &&
    !literPerHectare
  ) {
    errors.literPerHectare = "Required";
  }
  if (!plannedDate) {
    errors.plannedDate = "Required";
  }
  if (!assignees || !assignees.length) {
    errors.assignees = "Required";
  }
  if (!chemicals || !chemicals.length) {
    errors.chemicals = "Required";
  }
  if (machinery && !values.conc) {
    errors.conc = "Required";
  }

  return errors;
};

export const getRecommendationTaskReadableId = recommendationTaskId =>
  recommendationTaskId
    ? `RT-${String(recommendationTaskId).padStart(3, "0")}`
    : null;

export const convertRecommendationChemicalToSprayDiaryFormat = taskData => {
  return taskData.chemicals.map(recommendationChemicalData => {
    const {
      chemical,
      dilutionRate,
      chemicalRateId,
      ...rest
    } = recommendationChemicalData;
    const neededRate =
      find(chemical.rates, rate => rate.id === chemicalRateId) ||
      chemical.rates[0];
    const rate = { ...neededRate, rate: dilutionRate };

    return {
      chemical: { ...chemical, rate, ...rest },
      ...rest,
      dilutionRate,
      chemicalRateId
    };
  });
};

export const printRecommendationsList = tasksToPrint => {
  const newWindow = window.open();

  if (!newWindow || !tasksToPrint) {
    return;
  }
  newWindow.document.title = `Scouting Report`;
  copyStyles(window.document, newWindow.document);

  const recommendationTasks = tasksToPrint.map(task => ({
    ...task,
    chemicals: convertRecommendationChemicalToSprayDiaryFormat(task)
  }));
  newWindow.document.body.innerHTML = ReactDOMServer.renderToString(
    <RecommendationsPrintout tasksToPrint={recommendationTasks} />
  );
  newWindow.print();
};
