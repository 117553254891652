import React from "react";
import PropTypes from "prop-types";
import { Form, Grid } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setGrowthStage } from "../../../../actions/SprayDiary/common";

const GrowthStage = ({ growthStage, actions: { setGrowthStage } }) => {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column widescreen={4} largeScreen={6} mobile={16} tablet={16}>
          <Form.Field>
            <Form.Input
              fluid
              label="Growth stage"
              placeholder="Growth stage"
              name="name"
              size="large"
              value={growthStage}
              onChange={({ target: { value } }) => setGrowthStage(value)}
            />
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

GrowthStage.propTypes = {
  growthStage: PropTypes.any,
  actions: PropTypes.object
};

const mapStateToProps = ({ sprayDiary: { growthStage } }) => ({ growthStage });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setGrowthStage
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(GrowthStage);
