import React from "react";
import {
  Container,
  Header,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow
} from "semantic-ui-react";
import Numeric from "../../../components/Numeric";
import { GeneralTask } from "../../../models/general_task.model";
import { useSelector } from "react-redux";
import "./index.css";
const Irrigation = () => {
  const {
    data: { content: fetchedTasks }
  } = useSelector(state => state.generalTasks);

  // Filter only the Irrigation Tasks
  const tasksForProcessing = fetchedTasks
    .map(t => new GeneralTask(t))
    .filter(t => t.type.id === 11);

  return (
    <Container className="irrigation">
      <Header as="h2">Irrigation:</Header>
      <Table className="irrigationTable" unstackable>
        <TableHeader>
          <TableRow>
            <TableHeaderCell style={{ width: 30 }} />
            <TableHeaderCell>Task ID</TableHeaderCell>
            <TableHeaderCell>Total Irrigation Water (L)</TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {tasksForProcessing.map(task => (
            <TableRow>
              <TableCell />
              <TableCell>GT-{task.id}</TableCell>
              <TableCell>{task.totalIrrigationWater || 0} L</TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell>
              <b>Total</b>
            </TableCell>
            <TableCell />
            <TableCell>
              <b>
                <Numeric
                  fractionDigits={2}
                  value={tasksForProcessing.reduce(
                    (a, b) => a + (b.totalIrrigationWater || 0),
                    0
                  )}
                  commaSeparatorOnThousands
                />
              </b>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Container>
  );
};

export default Irrigation;
