import React from "react";
import { Icon, Table, Label, Header, Popup } from "semantic-ui-react";
import { isEmptyValue } from "utils/tasksUtils";
import CollapsibleBlock from "components/CollapsibleBlock";
import { formatDate } from "utils/dateUtils";
import styles from "../Sidebar.module.css";
import AttachmentLabel from "components/Attachments/AttachmentLabel";

const AdditionalInformationSection = ({ chemical, editButton }) => {
  const fileNameExtensions = name => {
    const m = name?.match(/\.([^.]+)$/);
    return m && m[1];
  };
  const fileName = name => {
    return name.replace(/\..*/, "");
  };
  return (
    <CollapsibleBlock
      className={styles.blockContainer}
      headerOptionalComponent={editButton}
      title="Additional information"
    >
      <Table className={styles.sectionTable} definition fixed>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Label link</Table.Cell>
            <Table.Cell singleLine>
              {chemical.labelAttachment ? (
                <Popup
                  inverted
                  position="left center"
                  content={chemical.labelAttachment?.name}
                  size="mini"
                  trigger={
                    <div className={styles.attachmentContainer}>
                      {chemical && (
                        <AttachmentLabel
                          attachment={chemical.labelAttachment}
                          withDownloadIcon={true}
                        />
                      )}
                    </div>
                  }
                />
              ) : (
                chemical.label && (
                  <Header
                    as={"a"}
                    size={"small"}
                    target="_blank"
                    href={chemical.label}
                  >
                    {chemical.label}
                  </Header>
                )
              )}
              {!chemical.labelAttachment &&
                !chemical.label &&
                isEmptyValue(chemical.label)}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>SDS link</Table.Cell>
            <Table.Cell singleLine>
              {chemical.sdsAttachment ? (
                <Popup
                  inverted
                  position="left center"
                  content={chemical.sdsAttachment?.name}
                  size="mini"
                  trigger={
                    <div className={styles.attachmentContainer}>
                      {chemical && (
                        <AttachmentLabel
                          attachment={chemical.sdsAttachment}
                          withDownloadIcon={true}
                        />
                      )}
                    </div>
                  }
                />
              ) : (
                chemical.sds && (
                  <Header
                    as={"a"}
                    size={"small"}
                    target="_blank"
                    href={chemical.sds}
                  >
                    {chemical.sds}
                  </Header>
                )
              )}
              {!chemical.sdsAttachment &&
                !chemical.sds &&
                isEmptyValue(chemical.sds)}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Chemical user permit No</Table.Cell>
            <Table.Cell singleLine>
              {isEmptyValue(chemical.chemicalUserPermingNumber)}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Chemical user permit exp. date</Table.Cell>
            <Table.Cell singleLine>
              {chemical.permitExpDate
                ? formatDate(chemical.permitExpDate)
                : isEmptyValue(chemical.permitExpDate)}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.notesCell}>Notes</Table.Cell>
            <Table.Cell>{isEmptyValue(chemical.notes)}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Attachments</Table.Cell>
            <Table.Cell>
              {chemical.attachments
                ? chemical.attachments.map(item => (
                    <Popup
                      inverted
                      position="left center"
                      content={item.name}
                      size="mini"
                      trigger={
                        <div className={styles.attachmentContainer}>
                          {chemical && (
                            <AttachmentLabel
                              attachment={item}
                              withDownloadIcon={true}
                            />
                          )}
                        </div>
                      }
                    />
                  ))
                : null}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </CollapsibleBlock>
  );
};

export default AdditionalInformationSection;
