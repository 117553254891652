import React, { PureComponent } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Accordion,
  Button,
  Confirm,
  Form,
  Grid,
  Header,
  Icon,
  Modal
} from "semantic-ui-react";

import {
  setBlockField,
  setBlockFieldsMultiple,
  setFarmField,
  setPatchField
} from "../../../../actions/Farms/addNewFarm";
import { getCropsList } from "../../../../actions/Farms/crops";
import { fetchAreasLog } from "../../../../actions/Areas/areas";
import {
  addArea,
  createOrUpdateStructure,
  updateArea
} from "../../../../actions/Farms/areas";
import PatchFormHeader from "./PatchFormHeader";
import PatchFormBody from "./PatchFormBody";
import { validateBlock } from "../validation";
import { AreaVariety } from "../../../../models/area_variety.model";
import {
  currentBlockSelector,
  patchesToShowSelector
} from "../../../../selectors/addFarm";
import ToolTip from "../../../../components/ToolTip";
import SwitchButton from "../../../../components/SwitchButton";
import styles from "./StepTwo.module.css";

const NO_PATCHES = "NO_PATCHES";
const PATCHES = "PATCHES";
const buildTypes = [
  { id: NO_PATCHES, value: NO_PATCHES, label: "Single Patch" },
  { id: PATCHES, value: PATCHES, label: "Multiple Patches" }
];

class StepTwo extends PureComponent {
  state = {
    errors: {},
    buttonDisabled: true,
    showErrors: false,
    openModal: false,
    openDeletePatchConfirm: null
  };

  componentDidMount() {
    const { actions, crops } = this.props;
    if (crops.data.length <= 0) {
      actions.getCropsList();
    }
    this.checkButtonsDisabled();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { newFarm, mapMode } = this.props;
    if (newFarm !== prevProps.newFarm || mapMode !== prevProps.mapMode) {
      this.checkButtonsDisabled();
    }
  }

  checkButtonsDisabled = () => {
    const { newFarm, mapMode } = this.props;
    const currentBlock = (newFarm.blocks || []).find(
      block => block.id === newFarm.currentBlock
    );
    const errors = validateBlock(currentBlock);

    this.setState({
      buttonDisabled: !!errors || newFarm.isFetching || mapMode !== "view"
    });
  };

  setBlockPatchField = (blockOrPatch, fieldName, fieldValue) => {
    const { actions, newFarm } = this.props;
    if (blockOrPatch.type === "block") {
      actions.setBlockField({
        blockId: blockOrPatch.id,
        fieldName,
        fieldValue
      });
    } else {
      actions.setPatchField({
        blockId: newFarm.currentBlock,
        patchId: blockOrPatch.id,
        fieldName,
        fieldValue
      });
    }
  };

  changeValue = (blockOrPatch, fieldName, fieldValue) => {
    const { handlePatchMode } = this.props;
    this.setState({ showErrors: false, errors: {} });

    const varietyIndex = fieldName
      .split(".")
      .slice(-2, -1)
      .pop();

    if (fieldName.indexOf("plantsPerHectare") !== -1) {
      this.setBlockPatchField(
        blockOrPatch,
        `attributes.varieties.${varietyIndex}.numberOfPlants`,
        (fieldValue * blockOrPatch.size).toFixed()
      );
    }

    switch (fieldName) {
      case "attributes.crop":
        this.setBlockPatchField(blockOrPatch, fieldName, fieldValue);
        this.setBlockPatchField(blockOrPatch, "attributes.varieties", [
          new AreaVariety({})
        ]);
        break;
      case "patchMode":
        if (!handlePatchMode(fieldValue)) {
          break;
        }
      // eslint-disable-next-line
      default:
        this.setBlockPatchField(blockOrPatch, fieldName, fieldValue);
    }
  };

  handleClickAccordion = (patch, titleProps) => {
    const { actions } = this.props;
    const { active } = titleProps;

    actions.setFarmField({
      fieldName: "currentPatch",
      fieldValue: active ? null : patch.id
    });

    this.props.handleSwitchPatch();
  };

  handleDeletePatch = (e, patch) => {
    const { currentBlock, handleDeletePatch } = this.props;
    this.hideDeletePatchConfirm();
    e.stopPropagation();
    handleDeletePatch(currentBlock.id, patch.id);
  };

  handleActivatePatch = (e, patch) => {
    const { currentBlock, handleActivatePatch } = this.props;
    e.stopPropagation();
    patch && handleActivatePatch(currentBlock.id, patch.id);
  };

  addNewPatch = () => {
    const { newFarm } = this.props;
    const currentBlock = newFarm.blocks.find(
      block => block.id === newFarm.currentBlock
    );

    const errors = validateBlock(
      currentBlock,
      currentBlock.patchMode === NO_PATCHES
    );

    if (_.isEmpty(errors)) {
      this.props.addNewPatch();
    } else {
      this.setState({ errors });
    }
  };

  getGEOJSONPolygon = polygon => {
    const vertices = polygon.getPath();
    const coordinates = [];

    for (let corner = 0; corner < vertices.getLength(); corner++) {
      const xy = vertices.getAt(corner);
      coordinates.push([xy.lat(), xy.lng()]);
    }
    coordinates.push(coordinates[0]);

    return {
      coordinates,
      size: window.google.maps.geometry.spherical.computeArea(vertices)
    };
  };

  getGEOJSONCollection = currentBlock => {
    const { polygonsToAdd } = this.props;
    const featureCollection = {
      type: "FeatureCollection",
      features: []
    };

    currentBlock.polygons.forEach(item => {
      const targetPolygon = polygonsToAdd.find(polygon => polygon.id === item);
      const jsonPolygon = targetPolygon
        ? this.getGEOJSONPolygon(targetPolygon)
        : null;
      const feature = jsonPolygon
        ? {
            type: "Feature",
            properties: {
              name: currentBlock.name,
              area: jsonPolygon.size
            },
            geometry: {
              type: "Polygon",
              coordinates: jsonPolygon.coordinates
            }
          }
        : null;
      if (feature) {
        featureCollection.features.push(feature);
      }
    });

    return featureCollection;
  };

  prepareBlockData = (currentBlock, parentId) => {
    const { newFarm } = this.props;
    const blockDataToSend = {
      farm: {
        id: newFarm.id
      },
      _id: currentBlock._id,
      name: currentBlock.name,
      type:
        newFarm.constructed === "PATCHES_ONLY" || parentId ? "PATCH" : "BLOCK",
      geojson: this.getGEOJSONCollection(currentBlock)
    };

    if (currentBlock.type === "block") {
      blockDataToSend.hasSubAreas = currentBlock.patchMode === PATCHES;
    }

    if (currentBlock.patchMode !== PATCHES) {
      blockDataToSend.size = Number(currentBlock.size) || null;
      blockDataToSend.isRotating = currentBlock.attributes.isRotating || false;
      blockDataToSend.varieties = currentBlock.attributes.varieties
        .map(variety => {
          let outputVariety = Object.assign({}, variety);
          for (const key of Object.keys(outputVariety)) {
            if (outputVariety[key] === null) {
              delete outputVariety[key];
            }
          }
          delete outputVariety.isRotating;
          delete outputVariety.overlapping;
          delete outputVariety.crop;
          delete outputVariety.age;
          if (outputVariety.variety) {
            outputVariety.variety = { id: outputVariety.variety.id };
          }
          if (!outputVariety.variety) {
            outputVariety = undefined;
          }
          return outputVariety;
        })
        .filter(variety => variety);
    }

    blockDataToSend.parent = parentId ? { id: parentId } : null;
    blockDataToSend.registrationNumber =
      currentBlock.attributes.registrationNumber || null;
    blockDataToSend.comments = currentBlock.attributes.notes || null;
    blockDataToSend.archived = currentBlock.archived;
    return blockDataToSend;
  };

  handleAddAnotherBlock = async (event, saveAndList, savingStructure) => {
    const { handleAddAnotherBlock, actions, newFarm } = this.props;
    const currentBlock = newFarm.blocks.find(
      block => block.id === newFarm.currentBlock
    );
    const errors = validateBlock(
      currentBlock,
      currentBlock.patchMode === NO_PATCHES
    );
    // EDGE is ignoring button type for some reason and doing form submit on button click.
    // So, it's going to reload page. To avoid it,prevent default action from button clicks added.
    if (event) {
      event.preventDefault();
    }

    if (errors) {
      return this.setState({ errors });
    }

    const data = this.prepareBlockData(currentBlock);
    let patchesData = [];
    if (currentBlock.patchMode === PATCHES) {
      patchesData = currentBlock.patches.reduce((next, patch) => {
        return [
          ...next,
          { id: patch.id, data: this.prepareBlockData(patch, patch.id) }
        ];
      }, []);
    }

    actions
      .createOrUpdateStructure({
        block: data,
        patches: patchesData,
        blockId: currentBlock.id,
        savingStructure
      })
      .then(() => handleAddAnotherBlock(saveAndList));
  };

  getPatchErrors = itemIndex => {
    const searchStr = `patches.${itemIndex}`;

    return _.reduce(
      this.state.errors,
      (next, error, key) => {
        if (key.search(searchStr) === 0) {
          const patchKey = key
            .split(".")
            .splice(2)
            .join(".");
          return { ...next, [patchKey]: error };
        }
        return next;
      },
      {}
    );
  };

  showDeletePatchConfirm = patch =>
    this.setState({ openDeletePatchConfirm: patch });
  hideDeletePatchConfirm = () =>
    this.setState({ openDeletePatchConfirm: null });

  render() {
    const {
      stageMode,
      drawMode,
      newFarm,
      currentBlock,
      patchesToShow,
      crops,
      handleCancel,
      savingStructure
    } = this.props;
    const {
      buttonDisabled,
      showErrors,
      openModal,
      errors,
      openDeletePatchConfirm
    } = this.state;

    // No block return nothing
    if (!currentBlock) {
      return null;
    }

    const activeIndex =
      patchesToShow &&
      patchesToShow.findIndex(patch => patch.id === newFarm.currentPatch);
    const hasPatches = _.size(_.get(currentBlock, "patches")) > 0;
    const editMode = !!currentBlock._id;
    return (
      <Form className={styles.stepHolder}>
        <div className={styles.stepContent}>
          <div className={styles.stepCount}>STEP 2 / 2</div>
          <Header as="h2" className={styles.stepTitle}>
            {editMode ? "Edit" : "Add New"}{" "}
            {newFarm.constructed === "PATCHES_ONLY" ? "Patch" : "Block"}
          </Header>
          <PatchFormHeader
            currentBlock={currentBlock}
            type={newFarm.constructed === "PATCHES_ONLY" ? "Patch" : "Block"}
            changeValue={this.changeValue}
            errors={errors}
            sizeRequired={_.get(currentBlock, "patchMode") === NO_PATCHES}
          />

          <Form.Group>
            <SwitchButton
              name="patchMode"
              title="How many patches the block has?"
              onChange={(name, value) =>
                this.changeValue(currentBlock, name, value)
              }
              value={currentBlock.patchMode}
              items={buildTypes}
              required
              disabled={hasPatches || editMode}
            />
          </Form.Group>
          {currentBlock && currentBlock.patchMode === NO_PATCHES && (
            <PatchFormBody
              currentBlock={currentBlock}
              type={newFarm.constructed === "PATCHES_ONLY" ? "Patch" : "Block"}
              changeValue={this.changeValue}
              crops={crops}
              errors={errors}
              showErrors={showErrors}
            />
          )}
          {currentBlock && currentBlock.patchMode === "PATCHES" && (
            <Accordion fluid>
              {patchesToShow.map((patch, index) => (
                <React.Fragment key={`patch_${patch.id}`}>
                  <Accordion.Title
                    active={activeIndex === index}
                    index={index}
                    onClick={(_, titleProps) =>
                      this.handleClickAccordion(patch, titleProps)
                    }
                  >
                    <Icon name="dropdown" />
                    {patch.name ? patch.name : `Patch ${index + 1}`}
                    {hasPatches && patch.archived ? (
                      <ToolTip content="Activate">
                        <Icon
                          className={`tuf-unarchive ${styles.blockRemoveIcon}`}
                          onClick={e => this.handleActivatePatch(e, patch)}
                        />
                      </ToolTip>
                    ) : (
                      <>
                        <ToolTip content={patch._id ? "Archive" : "Delete"}>
                          <Icon
                            className={`${
                              patch._id ? "tuf-archive" : "close"
                            } ${styles.blockRemoveIcon}`}
                            onClick={() => this.showDeletePatchConfirm(patch)}
                          />
                        </ToolTip>
                        <Confirm
                          open={
                            openDeletePatchConfirm &&
                            openDeletePatchConfirm.id === patch.id
                          }
                          content={`Are you sure you want to ${
                            patch._id ? "archive" : "delete"
                          } patch?`}
                          onCancel={this.hideDeletePatchConfirm}
                          onConfirm={e => this.handleDeletePatch(e, patch)}
                        />
                      </>
                    )}
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === index}>
                    <PatchFormHeader
                      key={`patch_header_${patch.id}`}
                      currentBlock={patch}
                      type="Patch"
                      errors={this.getPatchErrors(index)}
                      changeValue={this.changeValue}
                      showErrors={showErrors}
                      patchId={patch.id}
                    />
                    <PatchFormBody
                      key={`patch_body_${patch.id}`}
                      currentBlock={patch}
                      type="Patch"
                      changeValue={this.changeValue}
                      showErrors={showErrors}
                      errors={this.getPatchErrors(index)}
                      crops={crops}
                      patchId={patch.id}
                    />
                  </Accordion.Content>
                </React.Fragment>
              ))}
              {drawMode && stageMode === "addPatch" && (
                <>
                  <Accordion.Title
                    active={activeIndex === patchesToShow.length}
                    index={patchesToShow.length}
                  >
                    <Icon name="dropdown" />
                    Patch {patchesToShow.length + 1}
                  </Accordion.Title>
                  <Accordion.Content
                    active={activeIndex === patchesToShow.length}
                  >
                    We will add it once you click save letting us know youre
                    done.{" "}
                  </Accordion.Content>
                </>
              )}
            </Accordion>
          )}
          {currentBlock &&
            currentBlock.patchMode === "PATCHES" &&
            !buttonDisabled && (
              <div className={styles.addNewHolder}>
                <Button onClick={this.addNewPatch}>Add New Patch</Button>
              </div>
            )}
        </div>
        <div className={styles.stepActions}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={4}>
                <div className={styles.verticalMiddle}>
                  <Button disabled={newFarm.isFetching} onClick={handleCancel}>
                    Cancel
                  </Button>
                </div>
              </Grid.Column>
              <Grid.Column width={12}>
                <div
                  className={`${styles.verticalMiddle} ${styles.buttonsHolder} right`}
                >
                  {!editMode && (
                    <Button
                      loading={savingStructure === "save_and_add_another"}
                      disabled={buttonDisabled || savingStructure}
                      onClick={event =>
                        this.handleAddAnotherBlock(
                          event,
                          false,
                          "save_and_add_another"
                        )
                      }
                    >
                      {currentBlock._id ? "Save and Add" : "Add"} Another
                    </Button>
                  )}

                  <Button
                    loading={savingStructure === "save"}
                    disabled={buttonDisabled || savingStructure}
                    onClick={event =>
                      this.handleAddAnotherBlock(event, true, "save")
                    }
                  >
                    {currentBlock._id
                      ? "Save"
                      : `Add ${
                          newFarm.constructed === "PATCHES_ONLY"
                            ? "Patch"
                            : "Block"
                        }`}
                  </Button>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <Modal size="tiny" open={openModal} onClose={this.handleCloseModal}>
          <Modal.Header>Add at least one Patch </Modal.Header>

          <Modal.Actions>
            <Button onClick={this.handleCancel}>Cancel</Button>
            <Button onClick={this.addNewPatch}>Draw Patch</Button>
          </Modal.Actions>
        </Modal>
      </Form>
    );
  }
}

StepTwo.propTypes = {
  handleCancel: PropTypes.func,
  handlePatchMode: PropTypes.func,
  drawMode: PropTypes.bool,
  isPatchInside: PropTypes.bool,
  handleAddAnotherBlock: PropTypes.func,
  toggleDrawing: PropTypes.func,
  handleSwitchPatch: PropTypes.func,
  currentBlock: PropTypes.object,
  newFarm: PropTypes.object,
  actions: PropTypes.object,
  stageMode: PropTypes.string,
  mapMode: PropTypes.string,
  setStep: PropTypes.func,
  addNewPatch: PropTypes.func,
  handleDeletePatch: PropTypes.func,
  crops: PropTypes.object,
  isFetching: PropTypes.bool,
  savingStructure: PropTypes.bool,
  polygonsToAdd: PropTypes.array,
  patchesToShow: PropTypes.array,
  handleActivatePatch: PropTypes.func
};

StepTwo.defaultProps = {};

function mapStateToProps(state) {
  const {
    farms: {
      newFarm,
      constructed,
      areas: { savingStructure }
    },
    crops
  } = state;
  return {
    newFarm,
    currentBlock: currentBlockSelector(state),
    patchesToShow: patchesToShowSelector(state),
    constructed,
    crops,
    savingStructure
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setBlockField,
        setFarmField,
        setPatchField,
        getCropsList,
        fetchAreasLog,
        addArea,
        createOrUpdateStructure,
        updateArea,
        setBlockFieldsMultiple
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StepTwo);
