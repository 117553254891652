import React, { memo, useMemo } from "react";
import PropTypes from "prop-types";
import { InfoWindow } from "@react-google-maps/api";
import { capitalize, take } from "lodash";

import styles from "./GoogleMaps.module.css";
import ImageSlider from "../ImageSlider";

const InfoField = ({ name, value }) => (
  <div className={styles.infoField}>
    <b>{name}: </b>
    <span>{value}</span>
  </div>
);

const BinDetailPopup = ({ bins, clearInfo }) => {
  const mappedBins = useMemo(
    () =>
      bins.map(bin => {
        const { harvestUnit } = bin;

        const areaType = capitalize(bin.area.type.toLowerCase());
        const allWorkersCount = bin.employeeBinParts.length;
        const firstFiveWorkers = take(bin.employeeBinParts, 5)
          .map(ebp => `${ebp.employee.firstName} ${ebp.employee.lastName}`)
          .join(", ");
        const leftWorkersCount = allWorkersCount - 5;

        return {
          images: (bin.attachments || []).map(a => a.presignedUrl),
          areaType,
          areaName: bin.area.name,
          harvestUnit,
          allWorkersCount,
          firstFiveWorkers,
          leftWorkersCount
        };
      }),
    [bins]
  );

  return (
    <InfoWindow
      onCloseClick={clearInfo}
      position={bins[0].position}
      options={{ pixelOffset: { height: -30, width: 0 } }}
    >
      <div className={styles.infoHolder}>
        <div style={{ width: mappedBins.length * 225 }}>
          {mappedBins.map((bin, id) => (
            <div key={"field" + bin.areaName + id} className={styles.scanCard}>
              <ImageSlider width={215} height={130} images={bin.images} />
              <div style={{ width: 215, marginTop: 20 }}>
                <InfoField name={bin.areaType} value={bin.areaName} />
                <InfoField
                  name={"Harvest Unit"}
                  value={`${bin.harvestUnit.netWeight} KG ${bin.harvestUnit.name}`}
                />
                <InfoField
                  name={"Worker"}
                  value={`${bin.firstFiveWorkers} ${
                    bin.leftWorkersCount > 0
                      ? ` + ${bin.leftWorkersCount} others`
                      : ""
                  } `}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </InfoWindow>
  );
};

BinDetailPopup.propTypes = {
  bins: PropTypes.array,
  clearInfo: PropTypes.func
};

InfoField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string
};

export default memo(BinDetailPopup);
