import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { Checkbox, Grid } from "semantic-ui-react";
import ToggleField from "../../../../components/ToggleField";
import TaskRow from "./../TaskRow";
import AreasTable from "../AreasTable";
import { connect, useSelector } from "react-redux";
import {
  areasSelector,
  parentBlocksSelector
} from "../../../../selectors/sprayDiary";
import { setActualRowsToSprayCheckState } from "../../../../actions/SprayDiary/common";

import styles from "./Tabs.module.css";
import { switchViewMode } from "../../../../actions/Application/application";
import _ from "lodash";

function Location({
  applicationType,
  sprayTask,
  fieldToEdit,
  onChange,
  toggleEditField,
  areasOptions,
  updateTask,
  showParentBlocks,
  actions,
  areasList,
  shouldDisableFieldsEdit
}) {
  const handleActualRowsToSprayCheckState = async (_, data) => {
    await actions.setActualRowsToSprayCheckState(data.checked);
    // updateTask(); ??? TODO investigate
  };
  const parentBlocks = useSelector(parentBlocksSelector);
  const listOfBlocks = {};
  const arrayOfAllParentBlocks = Object.values(parentBlocks);
  const selectedAreasIndexed = Object.fromEntries(
    sprayTask.selectedAreas.map(area => [area.id, area])
  );

  arrayOfAllParentBlocks.forEach(area => {
    const selectedPatchesIDs = _.intersection(
      area.areaIDs,
      sprayTask.selectedAreas.map(area => area.id)
    );
    if (
      !_.isEmpty(area.areaIDs) &&
      _.isEqual(area.areaIDs, selectedPatchesIDs)
    ) {
      listOfBlocks[area.id] = {
        id: area.id,
        actualTanks: area.areaIDs
          .map(id => selectedAreasIndexed[id].actualTanks)
          .reduce((a, b) => parseFloat(a) + parseFloat(b), 0),
        name: area.name,
        size: area.areaSize.reduce((a, b) => a + b, 0),
        areaIDs: area.areaIDs,
        varietiesList: [],
        plannedLiters: 0,
        done: sprayTask.selectedAreas
          .filter(a => selectedPatchesIDs.includes(a.id))
          .every(a => a.done),
        tanks: 0
      };
    }
  });

  const arrayOfBlocks = Object.values(listOfBlocks);

  arrayOfBlocks.map(b => {
    b.areaIDs.forEach(id => {
      listOfBlocks[b.id].plannedLiters +=
        selectedAreasIndexed[id].plannedLiters;
      listOfBlocks[b.id].tanks += selectedAreasIndexed[id].tanks;
      listOfBlocks[b.id].varietiesList.push(
        `${selectedAreasIndexed[id].varieties.map(
          v => v.variety.name
        )} - ${selectedAreasIndexed[id].varieties.map(
          v => v.variety.crop.name
        )}`
      );
    });
  });

  return (
    <div className={styles.tabHolder}>
      <div className={styles.areasHolder}>
        <div>
          <ToggleField
            name="selectedAreas"
            inputType="dropdown"
            multiple
            value={sprayTask.selectedAreas.map(area => area.id)}
            options={areasOptions}
            toggleEditField={toggleEditField}
            fieldToEdit={fieldToEdit}
            onChange={onChange}
            emptyLabel="Apply to areas"
            hardLabel="Apply to areas"
            renderLabel={item => item.shortText}
            rules={{ required: true }}
            disabled={shouldDisableFieldsEdit}
          />
          {arrayOfBlocks.length >= 1 && (
            <div
              className={styles.viewSelector}
              onClick={() => actions.switchViewMode()}
            >
              {showParentBlocks ? "Show parent block only" : "Show all patches"}
            </div>
          )}
        </div>
      </div>

      <AreasTable
        updateTask={updateTask}
        areasList={areasList}
        showParentBlocks={showParentBlocks}
        sprayTask={sprayTask}
        startWizard
      />
      <Grid verticalAlign="middle" className="no-margin">
        <TaskRow
          label="Row width"
          name="widthPerRow"
          inputType="textInput"
          value={sprayTask.widthPerRow}
          toggleEditField={toggleEditField}
          fieldToEdit={fieldToEdit}
          onChange={onChange}
          //          error={!widthPerRowValidation}
          fluid
          inputLabel={{ content: "%", inverted: "true" }}
          labelPosition="right"
          rules={{ required: true, numeric: true, valueMax: 100 }}
          disabled={shouldDisableFieldsEdit}
        />
        {applicationType === "SPRAY" && (
          <TaskRow
            label="L per Ha"
            name="literPerHectare"
            inputType="textInput"
            value={sprayTask.literPerHectare}
            toggleEditField={toggleEditField}
            fieldToEdit={fieldToEdit}
            onChange={onChange}
            fluid
            inputLabel={{ content: "L", inverted: "true" }}
            labelPosition="right"
            rules={{ required: true, numeric: true }}
            disabled={shouldDisableFieldsEdit}
          />
        )}

        <TaskRow
          label="Rows To Spray"
          name="rowsToSpray"
          inputType="radioMenu"
          value={sprayTask.rowsToSpray}
          toggleEditField={toggleEditField}
          fieldToEdit={fieldToEdit}
          options={sprayTask.rowsToSprayOptions}
          onChange={onChange}
          disabled={shouldDisableFieldsEdit}
          //          error={!widthPerRowValidation}
        />
        <TaskRow
          label="Growth Stage"
          name="growthStage"
          inputType="textInput"
          value={sprayTask.growthStage}
          toggleEditField={toggleEditField}
          fieldToEdit={fieldToEdit}
          onChange={onChange}
          disabled={shouldDisableFieldsEdit}
        />
        {sprayTask.status === "COMPLETED" && (
          <TaskRow
            label={
              <Checkbox
                onChange={handleActualRowsToSprayCheckState}
                checked={sprayTask.actualRowsToSprayChecked}
                label="I sprayed the rows in this manner"
              />
            }
            name="actualRowsToSpray"
            inputType={
              sprayTask.actualRowsToSprayChecked ? "hidden" : "radioMenu"
            }
            value={sprayTask.actualRowsToSpray}
            toggleEditField={toggleEditField}
            fieldToEdit={fieldToEdit}
            options={sprayTask.rowsToSprayOptions}
            onChange={onChange}
            //          error={!widthPerRowValidation}
            disabled={shouldDisableFieldsEdit}
          />
        )}
      </Grid>
    </div>
  );
}

Location.propTypes = {
  sprayTask: PropTypes.object,
  fieldToEdit: PropTypes.object,
  actions: PropTypes.object,
  updateTask: PropTypes.func,
  onChange: PropTypes.func,
  toggleEditField: PropTypes.func,
  areasOptions: PropTypes.array,
  areasList: PropTypes.object,
  showParentBlocks: PropTypes.bool,
  checkAndUpdate: PropTypes.func,
  startWizard: PropTypes.func,
  shouldDisableFieldsEdit: PropTypes.bool
};

Location.defaultProps = {};

const mapStateToProps = state => {
  const {
    sprayDiary: { applicationType }
  } = state;
  return {
    areasOptions: areasSelector(state),
    applicationType
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setActualRowsToSprayCheckState,
      switchViewMode
    },
    dispatch
  )
});
export default connect(mapStateToProps, mapDispatchToProps)(Location);
