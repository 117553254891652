import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { Dropdown, Form, Grid, Input } from "semantic-ui-react";
import {
  APPLICATION_TYPES,
  PRESSURE_UNITS
} from "constants/Recommendations/types";
import { FastField, Field, useFormikContext } from "formik";
import { objectToSelectOptions } from "utils/objectUtils";
import { NO_RESULTS_MESSAGE } from "constants/shared/messages";
import styles from "./styles.module.css";

const pressureUnitOptions = objectToSelectOptions(PRESSURE_UNITS);

const SelectMachinery = () => {
  const { setFieldValue, values, handleChange } = useFormikContext();
  const { machineryId, applicationType, machinerySpeed } = values;
  const machineryList = useSelector(state => state.sprayDiary.machineryList);
  const isSpreadTask =
    APPLICATION_TYPES[applicationType] === APPLICATION_TYPES.SPREAD;

  const options = useMemo(
    () =>
      machineryList.content
        .filter(m => (m.archived ? m.id === machineryId : true))
        .map(item => {
          return {
            key: item.id,
            text: `${item.name} ${item.size ? `(size ${item.size} L)` : ""}`,
            value: item.id
          };
        }),
    [machineryList]
  );

  const onChangeMachinery = useCallback(
    (event, data) => {
      const machinery = machineryList.content.find(
        item => data.value === item.id
      );

      setFieldValue("machineryId", data.value);
      setFieldValue("machinery", machinery);
      event.preventDefault();
      event.stopPropagation();
    },
    [machineryList, setFieldValue]
  );

  return (
    <Grid className={styles.machineryHolder}>
      <Grid.Row className={styles.machineryRow}>
        <Grid.Column computer={4} mobile={16}>
          <Form.Field>
            <label className="title">Machinery</label>
            <Dropdown
              search
              clearable
              closeOnChange
              noResultsMessage={NO_RESULTS_MESSAGE}
              fluid
              value={machineryId}
              placeholder="Select machinery"
              loading={machineryList.isFetching}
              options={options}
              onChange={onChangeMachinery}
              selection
            />
          </Form.Field>
        </Grid.Column>
        {!!machineryId && (
          <>
            {!isSpreadTask && (
              <>
                <Grid.Column computer={4} mobile={8}>
                  <FastField
                    required
                    as={Form.Input}
                    label="Concentration/(x)"
                    fluid
                    name="conc"
                    type="number"
                  />
                </Grid.Column>
                <Grid.Column computer={4} mobile={8}>
                  <FastField
                    as={Form.Input}
                    label="Nozzle"
                    name="nozzle"
                    fluid
                  />
                </Grid.Column>
                <Grid.Column computer={4} mobile={8}>
                  <FastField
                    as={Form.Input}
                    label="Nozzles per side"
                    name="nozzlesPerSide"
                    type="number"
                    fluid
                  />
                </Grid.Column>
              </>
            )}
            <Grid.Column computer={4} mobile={8}>
              <Form.Field className="sprayField">
                <FastField as={Form.Input} label="Gear" name="gear" fluid />
              </Form.Field>
            </Grid.Column>
            {!isSpreadTask && (
              <Grid.Column computer={4} mobile={8}>
                <Field
                  as={Form.Input}
                  fluid
                  label="Select Pressure"
                  type="number"
                  onChange={(_, { value }) => {
                    setFieldValue("pressure", value);

                    if (!values.pressureUnit) {
                      setFieldValue("pressureUnit", PRESSURE_UNITS.PSI);
                    }
                  }}
                  name="pressure"
                  action={
                    <Dropdown
                      className={styles.pressureUnitDropdown}
                      button
                      floating
                      options={pressureUnitOptions}
                      value={values.pressureUnit}
                      color="green"
                      onChange={(_, { value }) => {
                        setFieldValue("pressureUnit", value);
                      }}
                    />
                  }
                  labelPosition="right"
                />
              </Grid.Column>
            )}
            <Grid.Column computer={4} mobile={8} className={styles.speedColumn}>
              <Form.Field>
                <label className="title">Speed</label>
                <Input
                  fluid
                  value={machinerySpeed}
                  type="number"
                  onChange={handleChange}
                  name="machinerySpeed"
                  label={{ content: "km/h", inverted: "true" }}
                  labelPosition="right"
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column computer={4} mobile={8}>
              <FastField
                as={Form.Input}
                label="RPM"
                fluid
                name="pto"
                type="number"
              />
            </Grid.Column>
            <Grid.Column computer={4} mobile={8}>
              <Form.Field>
                <FastField as={Form.Input} label="RPM" fluid name="rpm" />
              </Form.Field>
            </Grid.Column>
          </>
        )}
      </Grid.Row>
    </Grid>
  );
};

export default SelectMachinery;
