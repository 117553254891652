export const actionType = {
  SET_RECOMMENDATIONS: "RECOMMENDATIONS/SET_RECOMMENDATIONS",
  FETCH_RECOMMENDATIONS_START: "RECOMMENDATIONS/FETCH_RECOMMENDATIONS_START",
  SET_RECOMMENDATIONS_ERROR: "RECOMMENDATIONS/SET_RECOMMENDATIONS_ERROR",

  SET_TEMPLATES: "RECOMMENDATIONS/SET_TEMPLATES",
  FETCH_TEMPLATES_START: "RECOMMENDATIONS/FETCH_TEMPLATES_START",
  FETCH_TEMPLATES_FAIL: "RECOMMENDATIONS/FETCH_TEMPLATES_FAIL",

  SET_CURRENT_TASK: "RECOMMENDATIONS/SET_CURRENT_TASK",
  SET_IS_EDITING: "RECOMMENDATIONS/SET_IS_EDITING",
  CLEAR_ERROR: "RECOMMENDATIONS/CLEAR_ERROR",

  ARCHIVE_TASKS_START: "RECOMMENDATIONS/ARCHIVE_TASKS_START",
  ARCHIVE_TASKS: "RECOMMENDATIONS/ARCHIVE_TASKS",
  ARCHIVE_TASKS_FAIL: "RECOMMENDATIONS/ARCHIVE_TASKS_FAIL"
};
