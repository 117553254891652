import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "react-table-6/react-table.css";
import ListHolder from "../../components/ListHolder";
import MachinerySidebar from "./Sidebar";

import {
  changeMachineryPageAction,
  changePageSizeAction,
  deleteMachineryAction,
  fetchMachineryAction
} from "../../actions/Machinery/machineries";
import { abilitiesSelector } from "../../selectors/user";
import ShowMore from "../../components/ShowMore";

const PAGE_SIZE = 10;

class Machinery extends Component {
  state = {
    tableData: [],
    tableColumns: [],
    sideBarShown: false,
    activeItemId: null
  };

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { data } = this.props;
    if (prevProps.data !== data) this.prepareData();
  }

  prepareData = () => {
    const { data } = this.props;
    const columnsNames = {
      name: {
        title: "Name",
        width: 8
      },
      make: {
        title: "Make",
        width: 8
      },
      model: {
        title: "Model",
        width: 8
      },
      size: {
        title: "Size",
        width: 8
      },
      ratePerHour: {
        title: "Rate per hr",
        width: 8
      },
      notes: {
        title: "Notes",
        width: 8,
        accessor: row => <ShowMore length={40}>{row.notes}</ShowMore>
      },
      actions: {
        title: "",
        width: "auto",
        accessor: d => d.id,
        className: "textRight actions"
      }
    };
    const tableColumns = [];
    const tableData = [];

    Object.keys(columnsNames).forEach(column => {
      const targetColumn = columnsNames[column];
      const columnToAdd = {
        id: column,
        accessor: targetColumn.accessor || column,
        Header: targetColumn.title,
        Cell: this.renderCell,
        className: `cell_${targetColumn.width} ${targetColumn.className || ""}`,
        ...(targetColumn.sortFields
          ? { sortFields: targetColumn.sortFields }
          : {})
      };

      tableColumns.push(columnToAdd);
    });

    if (data && data.content) {
      data.content.forEach(row => {
        const rowData = {
          rights: {
            update: "update",
            delete: "delete"
          }
        };
        Object.keys(row).forEach(key => {
          switch (key) {
            case "ratePerHour":
              rowData[key] = row[key] >= 0 ? `$${row[key].toFixed(2)}` : "";
              break;
            case "size":
              rowData[key] =
                row[key] >= 0
                  ? `${row[key]} ${row.sizeUnit === "LITER" ? "L" : "Kg"}`
                  : "";
              break;
            default:
              rowData[key] = row[key] || "";
              break;
          }
        });
        tableData.push(rowData);
      });
    }

    this.setState({
      tableData,
      tableColumns
    });
  };

  renderCell = props => {
    const {
      value,
      column: { Header }
    } = props;

    return (
      <div>
        <div className="cellTitle hide-md">{Header}</div>
        <div className="cellValue">{value}</div>
      </div>
    );
  };

  setActiveItem = activeItemId => {
    this.setState({
      activeItemId
    });
  };

  render() {
    const {
      route,
      location,
      machinery,
      currentPage,
      totalPages,
      isFetching,
      Can,
      fetchMachinery,
      size,
      totalElements,
      deleteMachinery,
      error,
      online,
      data: { content }
    } = this.props;

    const { activeItemId, tableData, tableColumns } = this.state;

    const expandedMachinery =
      activeItemId && machinery.find(e => e.id === activeItemId);
    const editLink = expandedMachinery
      ? `/machinery/add_new_machinery/${expandedMachinery.id}`
      : null;

    return (
      <ListHolder
        content={machinery}
        accessName="machineries"
        Can={Can}
        addNewPath={"/machinery/add_new_machinery"}
        route={route}
        location={location}
        currentPage={currentPage}
        size={size || PAGE_SIZE}
        totalPages={totalPages}
        rawData={content.filter(c => !c.archived)}
        data={tableData.filter(c => !c.archived)}
        columns={tableColumns}
        getData={fetchMachinery}
        delData={deleteMachinery}
        isFetching={isFetching && !totalPages}
        totalElements={totalElements}
        withDateRange={false}
        setActiveItem={this.setActiveItem}
        error={error}
        online={online}
        updateAction="update"
        editLink={editLink}
        defaultSort="name,asc"
      >
        <MachinerySidebar machinery={expandedMachinery} Can={Can} />
      </ListHolder>
    );
  }
}

Machinery.propTypes = {
  machinery: PropTypes.array.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  totalElements: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  fetchMachinery: PropTypes.func.isRequired,
  changeMachineryPage: PropTypes.func.isRequired,
  changePageSize: PropTypes.func.isRequired,
  deleteMachinery: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  lastLoaded: PropTypes.bool,
  data: PropTypes.object,
  error: PropTypes.object,
  route: PropTypes.object,
  location: PropTypes.object,
  Can: PropTypes.func,
  online: PropTypes.bool
};

const mapStateToProps = state => ({
  data: state.machinery.data,
  machinery: state.machinery.data.content,
  isFetching: state.machinery.data.isFetching,
  currentPage: state.machinery.data.number,
  totalPages: state.machinery.data.totalPages,
  totalElements: state.machinery.data.totalElements,
  size: state.machinery.data.size,
  lastLoaded: state.machinery.data.last,
  Can: abilitiesSelector(state),
  error: state.machinery.error,
  online: state.offline.online
});

const mapDispatchToProps = {
  fetchMachinery: fetchMachineryAction,
  changeMachineryPage: changeMachineryPageAction,
  changePageSize: changePageSizeAction,
  deleteMachinery: deleteMachineryAction
};

const connectedMachinery = connect(
  mapStateToProps,
  mapDispatchToProps
)(Machinery);

export default connectedMachinery;
