import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Table, TableCell } from "semantic-ui-react";
import { round } from "lodash";
import Numeric from "../../../../components/Numeric";

const TotalCostSubRow = ({ data, costsType }) => {
  const [isExpanded, setExpanded] = useState(false);
  const title =
    costsType === "labour" ? "Labour Costs ($)" : "Machinery Costs ($)";
  const dataSelector =
    costsType === "labour"
      ? data.sprayTaskLabourCost
      : data.sprayTaskMachineryCost;

  const applicationInfo = {
    totalCost: round(dataSelector, 2),
    costPerHa: data.areaSize
      ? round(dataSelector / data.areaSize, 2)
      : "No information",
    costPerHarvestUnit: data.binCount
      ? round(dataSelector / data.binCount, 2)
      : "No information"
  };

  return (
    <>
      <Table.Row style={{ fontWeight: "bold" }}>
        <TableCell width={1}>
          <Button
            active={isExpanded}
            onClick={() => setExpanded(!isExpanded)}
            compact
            icon="dropdown"
          />
        </TableCell>
        <Table.Cell width={3}>{title}</Table.Cell>
        <Table.Cell width={1} />
        <Table.Cell width={2}>
          <Numeric
            value={data.costPerHarvestUnit}
            fractionDigits={2}
            commaSeparatorOnThousands
            units="$ "
            unitsPosition="left"
          />
        </Table.Cell>
        <Table.Cell width={2}>
          <Numeric
            value={data.costPerHa}
            fractionDigits={2}
            commaSeparatorOnThousands
            units="$ "
            unitsPosition="left"
          />
        </Table.Cell>
        <Table.Cell width={2}>
          <Numeric
            value={data.totalCosts}
            fractionDigits={2}
            commaSeparatorOnThousands
            units="$ "
            unitsPosition="left"
          />
        </Table.Cell>
      </Table.Row>
      {isExpanded && (
        <TableCell colSpan={16}>
          <Table colSpan={16} unstackable>
            <Table.Body>
              <Table.Row>
                <Table.Cell width={1} />
                <TableCell width={3}>{`Application Task - ${title}`}</TableCell>
                <Table.Cell width={1} />
                <Table.Cell width={2}>
                  <Numeric
                    value={applicationInfo.costPerHarvestUnit}
                    fractionDigits={2}
                    commaSeparatorOnThousands
                    units="$ "
                    unitsPosition="left"
                  />
                </Table.Cell>
                <Table.Cell width={2}>
                  <Numeric
                    value={applicationInfo.costPerHa}
                    fractionDigits={2}
                    commaSeparatorOnThousands
                    units="$ "
                    unitsPosition="left"
                  />
                </Table.Cell>
                <Table.Cell width={2}>
                  <Numeric
                    value={applicationInfo.totalCost}
                    fractionDigits={2}
                    commaSeparatorOnThousands
                    units="$ "
                    unitsPosition="left"
                  />
                </Table.Cell>
              </Table.Row>
              {data.tasks.map(task => {
                const taskCost =
                  costsType === "machinery"
                    ? task.machineryCost
                    : task.labourCost;
                return (
                  <>
                    <Table.Row>
                      <Table.Cell width={1} />
                      <TableCell width={3}>
                        {`${task.generalTaskType} - ${title}`}
                      </TableCell>
                      <Table.Cell width={1} />
                      <Table.Cell width={2}>
                        <Numeric
                          value={
                            data.binCount
                              ? round(taskCost / data.binCount, 2)
                              : "No information"
                          }
                          fractionDigits={2}
                          commaSeparatorOnThousands
                          units="$ "
                          unitsPosition="left"
                        />
                      </Table.Cell>
                      <Table.Cell width={2}>
                        <Numeric
                          value={
                            data.areaSize
                              ? round(taskCost / data.areaSize, 2)
                              : "No information"
                          }
                          fractionDigits={2}
                          commaSeparatorOnThousands
                          units="$ "
                          unitsPosition="left"
                        />
                      </Table.Cell>
                      <Table.Cell width={2}>
                        <Numeric
                          value={round(taskCost, 2)}
                          fractionDigits={2}
                          commaSeparatorOnThousands
                          units="$ "
                          unitsPosition="left"
                        />
                      </Table.Cell>
                    </Table.Row>
                    {costsType === "labour" && (
                      <Table.Row>
                        <Table.Cell width={1}></Table.Cell>
                        <TableCell width={3}>
                          {`${task.generalTaskType} - Alt Labour Costs($)`}
                        </TableCell>
                        <Table.Cell width={1}></Table.Cell>
                        <Table.Cell width={2}>
                          <Numeric
                            value={
                              data.binCount
                                ? round(task.altLabourCost / data.binCount, 2)
                                : "No information"
                            }
                            fractionDigits={2}
                            commaSeparatorOnThousands
                            units="$ "
                            unitsPosition="left"
                          />
                        </Table.Cell>
                        <Table.Cell width={2}>
                          <Numeric
                            value={
                              data.areaSize
                                ? round(task.altLabourCost / data.areaSize, 2)
                                : "No information"
                            }
                            fractionDigits={2}
                            commaSeparatorOnThousands
                            units="$ "
                            unitsPosition="left"
                          />
                        </Table.Cell>
                        <Table.Cell width={2}>
                          <Numeric
                            value={round(task.altLabourCost, 2)}
                            fractionDigits={2}
                            commaSeparatorOnThousands
                            units="$ "
                            unitsPosition="left"
                          />
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </>
                );
              })}
            </Table.Body>
          </Table>
        </TableCell>
      )}
    </>
  );
};

TotalCostSubRow.propTypes = {
  data: PropTypes.shape({
    areaSize: PropTypes.number,
    binCount: PropTypes.number,
    costPerHa: PropTypes.number,
    costPerHarvestUnit: PropTypes.number,
    sprayTaskLabourCost: PropTypes.number,
    sprayTaskMachineryCost: PropTypes.number,
    totalCosts: PropTypes.number,
    tasks: PropTypes.arrayOf(
      PropTypes.shape({
        areaId: PropTypes.number,
        generalTaskType: PropTypes.string,
        labourCost: PropTypes.number,
        machineryCost: PropTypes.number
      })
    )
  }),
  costsType: PropTypes.oneOf(["machinery", "labour"]).isRequired
};

export default TotalCostSubRow;
