import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef
} from "react";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import TablePageHolder from "../../../components/TablePageHolder/TablePageHolder";
import PurchaseHistoryModal from "./components/Modal/PurchaseHistoryModal";
import {
  getPurchaseHistory,
  getPurchaseHistoryListForPrint,
  getPurchaseHistorySupplierss,
  getPurchaseHistoryManufactures,
  getPurchaseHistoryBatchNumber,
  setActiveItemId,
  deletePurchase
} from "actions/PurchaseHistory/index";
import { fetchListSheds } from "actions/Sheds/index";
import { Button, Modal } from "semantic-ui-react";
import { abilitiesSelector } from "selectors/user";
import { activeItemSelector } from "selectors/purchaseHistory";
import { getPurchaseHistoryTableColumns } from "utils/purchaseHistory";
import { getPurchaseOrderColumns } from "utils/purchaseHistoryOrder";
import { keyBy } from "lodash";
import { shedShape } from "../../../constants/Sheds/types";
import _ from "lodash";
import styles from "./PurchaseHistory.module.css";
import PurchaseOrderModal from "./components/Modal/PurchaseOrderModal/PurchaseOrderModal";
import PrintPurchaseOrder from "./components/printoutPage/PrintPurchaseOrder";
import { useReactToPrint } from "react-to-print";
import {
  chemicalsOptionsSelector,
  productTypeSelector
} from "selectors/chemicals";
import { shedListSelector } from "selectors/sheds";
import { getHashParameters, removeHashParameters } from "utils/hashToObject";

const PurchaseHistory = ({
  isFetching,
  location,
  route,
  tableData,
  activeItem,
  printData,
  productsList,
  sheds,
  fetchPurchaseToggle
}) => {
  const dispatch = useDispatch();
  const [activeItemsId, setactiveItemsId] = useState();
  const [itemIdForDelete, setItemIdForDelete] = useState();
  const [openConfirm, setOpenConfirm] = useState(false);
  const componentRef = useRef();
  const [selectedItemsIds, setSelectedItems] = useState([]);
  const [filtersList, setFiltersList] = useState([]);
  const [isCreateUpdateModalShown, setCreateUpdateModalShown] = useState();
  const [isCreateOrderModalShown, setCreateOrderModalShown] = useState(false);
  const [dataRefetch, setDataRefetch] = useState(1);
  const chemicalsList = useSelector(chemicalsOptionsSelector);
  const typeSelector = useSelector(productTypeSelector);
  const shedList = useSelector(shedListSelector);
  const fetchPrintoutAllInformation = () => {
    return dispatch(getPurchaseHistoryListForPrint(filtersList));
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  const printButton = async id => {
    await setactiveItemsId(id);
    fetchPrintoutAllInformation().then(() => {
      handlePrint();
    });
  };

  const purchaseDelete = async id => {
    setItemIdForDelete(id);
    setOpenConfirm(true);
  };
  const onDeleteItem = () => {
    dispatch(deletePurchase(itemIdForDelete, filtersList)).then(
      setOpenConfirm(false)
    );
  };

  const editCheck = id => {
    setCreateUpdateModalShown(true);
    onRowClick(id);
  };
  const getData = useCallback(
    async params => {
      setFiltersList(params);
      dispatch(getPurchaseHistory({ ...params }));
      dispatch(getPurchaseHistorySupplierss());
      dispatch(getPurchaseHistoryManufactures());
      dispatch(getPurchaseHistoryBatchNumber());
    },
    [getPurchaseHistory, fetchPurchaseToggle]
  );
  const parametrs = getHashParameters(location);
  useEffect(() => {
    dispatch(setActiveItemId(+parametrs.purchaseOrderId));
    setCreateOrderModalShown(true);
  }, [parametrs.purchaseOrderId]);

  const onRowClick = useCallback(
    (event, row) => {
      dispatch(setActiveItemId(row?.original?.id));
      setCreateOrderModalShown(true);
    },
    [activeItem]
  );
  const breadcrumbSections = [];
  useEffect(() => {
    dispatch(fetchListSheds());
  }, []);
  const tableColumns = getPurchaseHistoryTableColumns(
    editCheck,
    printButton,
    purchaseDelete,
    setItemIdForDelete
  );
  const orderColumns = getPurchaseOrderColumns(
    chemicalsList,
    shedList,
    activeItem,
    typeSelector
  );
  return (
    <>
      <Modal
        size="mini"
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
      >
        <Modal.Content>
          <p>
            Delete order{" "}
            {tableData.content.find(i => i.id === itemIdForDelete)?.orderNumber}{" "}
            ?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            className={styles.cancelDltBtn}
            onClick={() => setOpenConfirm(false)}
          >
            Cancel
          </Button>
          <Button
            className={styles.deleteBtn}
            positive
            onClick={() => onDeleteItem()}
          >
            Delete
          </Button>
        </Modal.Actions>
      </Modal>
      <div style={{ display: "none" }}>
        <PrintPurchaseOrder
          shedList={sheds}
          productsList={productsList}
          printData={printData}
          activeItemsId={activeItemsId}
          ref={componentRef}
        />
      </div>
      <PurchaseHistoryModal
        filtersList={filtersList}
        open={isCreateUpdateModalShown}
        onClose={() => {
          return (
            setCreateUpdateModalShown(false), dispatch(setActiveItemId(null))
          );
        }}
        purchaseHistory={activeItem}
      />
      <PurchaseOrderModal
        selectedItemsIds={selectedItemsIds}
        setSelectedItems={setSelectedItems}
        isFetching={isFetching}
        location={location}
        getData={getData}
        tableColumns={orderColumns}
        tableData={tableData}
        route={route}
        purchaseOrder={activeItem}
        setCreateOrderModalShown={setCreateOrderModalShown}
        open={
          activeItem && !isCreateUpdateModalShown && isCreateOrderModalShown
        }
      />
      <TablePageHolder
        mainButton={
          <Button
            primary
            className={styles.addButton}
            onClick={() => {
              return (
                dispatch(setActiveItemId(null)), setCreateUpdateModalShown(true)
              );
            }}
          >
            Add
          </Button>
        }
        withoutSort={false}
        breadcrumbSections={breadcrumbSections}
        onRowClick={onRowClick}
        getData={getData}
        isFetching={isFetching}
        location={location}
        pageTitle={route.name}
        route={route}
        selectedItems={selectedItemsIds}
        setSelectedItems={setSelectedItems}
        tableColumns={tableColumns}
        tableData={tableData}
        withSelection={false}
        fixed={false}
      />
    </>
  );
};

PurchaseHistory.propTypes = {
  Can: PropTypes.func,
  tableData: PropTypes.shape({
    content: PropTypes.arrayOf(shedShape)
  }).isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  totalElements: PropTypes.number.isRequired,
  route: PropTypes.object,
  filterValues: PropTypes.object,
  location: PropTypes.object
};
export default connect(state => {
  return {
    Can: abilitiesSelector(state),
    tableData: state.purchaseHistory.data,
    productsList: state.chemical.list,
    sheds: state.shed.list,
    printData: state.purchaseHistory.print,
    filterValues: state.purchaseHistory.filters,
    isFetching: state.purchaseHistory.data.isFetching,
    error: state.purchaseHistory.error,
    activeItem: activeItemSelector(state),
    fetchPurchaseToggle: state.purchaseHistory.fetchPurchaseToggle
  };
})(PurchaseHistory);
