import React, { useState } from "react";
import {
  Accordion,
  Form,
  Grid,
  Segment,
  Input,
  TextArea,
  Icon
} from "semantic-ui-react";
import TextareaAutosize from "react-textarea-autosize";
import { get, without, range, keyBy } from "lodash";
import PropTypes from "prop-types";
import Slider from "rc-slider";
import { getColorByPressureRating, getReadableIssueType } from "utils/scouting";
import {
  scoutingAreaIssueShape,
  scoutingIssueTypes,
  scoutingShape
} from "constants/Scouting/types";
import "rc-slider/assets/index.css";
import styles from "./styles.module.css";

const MIN_PRESSURE_RATING = 1;
const MAX_PRESSURE_RATING = 5;
const PRESSURE_RATING_MARKS = keyBy(
  range(MIN_PRESSURE_RATING, MAX_PRESSURE_RATING + 1)
);

const getColorStyle = value => ({
  backgroundColor: getColorByPressureRating(value)
});

const EditScoutingIssuesAccordion = ({ areaIssues, formik, areaIndex }) => {
  const [activeIssueIndex, setActiveIssueIndex] = useState(0);
  const handleIssueClick = (e, { index }) => {
    setActiveIssueIndex(activeIndex => (activeIndex === index ? -1 : index));
  };
  const { values } = formik;

  if (!areaIssues) {
    return null;
  }

  const onPressureRatingChange = (value, issueIndex) => {
    formik.setFieldValue(
      `areas[${areaIndex}].areaIssues[${issueIndex}].pressureRating`,
      value
    );
  };
  const onDeleteIssueClick = issueIndex => {
    const issuesPath = `areas[${areaIndex}].areaIssues`;
    const currentAreaIssues = get(values, issuesPath);
    const areaIssues = without(
      currentAreaIssues,
      currentAreaIssues[issueIndex]
    );

    formik.setFieldValue(issuesPath, areaIssues);
  };

  return (
    <Accordion fluid>
      {areaIssues.map((issue, index) => {
        const title = `Issue ${index + 1}: ${getReadableIssueType(issue.type)}`;
        const issueData = values.areas[areaIndex].areaIssues[index];
        const namePrefix = `areas[${areaIndex}].areaIssues[${index}]`;
        const pressureRatingShown =
          issue.type === scoutingIssueTypes.PEST_AND_DISEASE;

        return (
          <Segment.Group>
            <Segment className={styles.issue}>
              <Accordion.Title
                active={index === activeIssueIndex}
                index={index}
                onClick={handleIssueClick}
                className={styles.issueTitle}
                content={
                  <>
                    {title}
                    <Icon
                      name="trash alternate outline"
                      color="green"
                      className={styles.deleteIcon}
                      onClick={() => onDeleteIssueClick(index)}
                    />
                  </>
                }
              />
              <Accordion.Content active={index === activeIssueIndex}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={6}>
                      <Form.Field>
                        <label>Row</label>
                        <Input
                          type={"number"}
                          placeholder="Row"
                          name={`${namePrefix}.rowNumber`}
                          value={issueData.rowNumber}
                          onChange={formik.handleChange}
                        />
                      </Form.Field>
                    </Grid.Column>
                    {pressureRatingShown && (
                      <Grid.Column width={10}>
                        <Form.Field>
                          <label>Pressure rating</label>
                          <Slider
                            min={MIN_PRESSURE_RATING}
                            max={MAX_PRESSURE_RATING}
                            value={issueData.pressureRating}
                            onChange={value =>
                              onPressureRatingChange(value, index)
                            }
                            trackStyle={getColorStyle(issueData.pressureRating)}
                            handleStyle={getColorStyle(
                              issueData.pressureRating
                            )}
                            activeDotStyle={getColorStyle(
                              issueData.pressureRating
                            )}
                            marks={PRESSURE_RATING_MARKS}
                          />
                        </Form.Field>
                      </Grid.Column>
                    )}
                    <Grid.Column width={16}>
                      <Form.Field>
                        <label>Comment about the issue</label>
                        <TextArea
                          as={TextareaAutosize}
                          size="large"
                          placeholder="Comment"
                          name={`${namePrefix}.comment`}
                          value={issueData.comment}
                          onChange={formik.handleChange}
                          // onInput={adjustElementHeightToContent}
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Accordion.Content>
            </Segment>
          </Segment.Group>
        );
      })}
    </Accordion>
  );
};

EditScoutingIssuesAccordion.propTypes = {
  areaIndex: PropTypes.number,
  areaIssues: PropTypes.arrayOf(scoutingAreaIssueShape),
  formik: PropTypes.shape({
    handleSubmit: PropTypes.func,
    handleChange: PropTypes.func,
    setFieldValue: PropTypes.func,
    values: scoutingShape
  })
};
export default EditScoutingIssuesAccordion;
