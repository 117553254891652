import React from "react";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";
import _ from "lodash";

import styles from "./AddNewFarm.module.css";

// eslint-disable-next-line react/prop-types
export default ({ mapMode, onSave, onEdit, onDelete, onCancel, drawStack }) => {
  const saveDisabled = _.isEmpty(drawStack);
  const deleteDisabled = _.isEmpty(drawStack);

  return (
    <div className={styles.mapButtons}>
      {mapMode === "view" && (
        <Button secondary onClick={onEdit}>
          Edit
        </Button>
      )}
      {mapMode === "edit" && (
        <React.Fragment>
          <Button secondary onClick={onCancel}>
            Cancel
          </Button>
          <Button secondary onClick={onDelete} disabled={deleteDisabled}>
            Delete
          </Button>
          <Button secondary onClick={onSave} disabled={saveDisabled}>
            Save
          </Button>
        </React.Fragment>
      )}
    </div>
  );
};
