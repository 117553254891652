import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";
import {
  Button,
  Checkbox,
  Grid,
  Icon,
  Loader,
  Segment
} from "semantic-ui-react";
import WorkingTimes from "../WorkingTimes";
import {
  setActualRowsToSpray,
  setActualRowsToSprayCheckState,
  setSprayDiaryField
} from "../../../../actions/SprayDiary/common";
import { setChemicalItemToAdd } from "../../../../actions/SprayDiary/chemicals";
import { setAreaValue } from "../../../../actions/SprayDiary/areas";
import TaskRow from "../TaskRow";
import AreasTable from "../AreasTable";
import {
  directionSelector,
  strengthsSelector
} from "../../../../selectors/sprayDiary";
import CircularProgressbar from "../../../../components/CircularProgressbar";
import styles from "./Wizard.module.css";
import UpdateInfoFromWeatherStationButton from "../../CreateSprayTask/SprayForm/UpdateInfoFromWeatherStationButton";
import { getBatchNumbersLastYear } from "actions/Spray/tasks";

function Wizard({
  sprayTask,
  wizardStep,
  fieldToEdit,
  toggleEditField,
  batchNumbers,
  onChange,
  shouldDisableFieldsEdit,
  setStep,
  areasList,
  showParentBlocks,
  stepsValidations,
  actions,
  strengthsOptions,
  directionOptions,
  finishWizard,
  totalSteps,
  getTimesList,
  closeWizard,
  overlappingDate
}) {
  useEffect(() => {
    if (wizardStep === 0) {
      toggleEditField({
        name: "workingTimesWizard",
        value: getTimesList(),
        rules: { notIncludeInArray: ["(--:--)", "(00:00)"] }
      });
    }
  }, [wizardStep, toggleEditField, getTimesList]);

  const onFieldChange = useCallback(
    (value, name, suffix) => {
      switch (name) {
        /*   case "batchNumber":
            actions.setChemicalItemToAdd(suffix, { [name]: value });
            break; */
        case "batchNumbers":
          actions.setChemicalItemToAdd(suffix, { [name]: value });
          break;
        case "actualRowsToSprayChecked":
          actions.setActualRowsToSprayCheckState(value);
          break;
        case "actualRowsToSpray":
          actions.setActualRowsToSpray(value);
          break;
        default:
          actions.setSprayDiaryField({
            fieldName: name,
            fieldValue: value
          });
          break;
      }
    },
    [actions]
  );

  const nextStep = useCallback(() => {
    if (wizardStep === 2) {
      sprayTask.selectedAreas.forEach(area => {
        if (!area.actualTanks) {
          actions.setAreaValue(area.id, {
            actualTanks: area.tanks ? area.tanks.toFixed(3) : null
          });
        }
      });
    }
    setStep(true);
  }, [wizardStep, sprayTask, actions, setStep]);

  return (
    <div className={styles.wizardHolder}>
      <div className={styles.wizardHolderIn}>
        <div className={styles.title}>
          Additional information is required before we can mark ST-
          {sprayTask.id} as completed.
        </div>

        <Segment
          className={`${styles.stepsHolder} ${
            styles[`step${totalSteps}${wizardStep}`]
          }`}
        >
          {wizardStep === 0 && (
            <>
              <div className={styles.stepName}>
                How long did it take you to complete the task?
              </div>
              <div className={styles.fieldsHolder}>
                <TaskRow
                  name="workingTimesWizard"
                  inputType="component"
                  toggleEditField={toggleEditField}
                  fieldToEdit={fieldToEdit}
                  wide
                  component={
                    <WorkingTimes
                      sprayTask={sprayTask}
                      overlappingDate={overlappingDate}
                      toggleEditField={toggleEditField}
                      fieldToEdit={fieldToEdit}
                      onChange={onChange}
                      heavy
                      editAllMode
                    />
                  }
                  editAllMode
                />
              </div>
            </>
          )}
          {wizardStep === 1 && (
            <>
              <div className={styles.stepName}>
                What batch numbers did you use?
              </div>
              <div className={styles.fieldsHolder}>
                <Grid verticalAlign="middle" className="no-margin">
                  {sprayTask &&
                    sprayTask.selectedChemicals.map(item => {
                      return (
                        <React.Fragment key={item.id}>
                          <TaskRow
                            label={item.name}
                            suffix={item.id}
                            name="batchNumber"
                            inputType="batchNumber"
                            value={item.batchNumber}
                            onBatchNumberChange={onFieldChange}
                            item={item}
                            sprayTask={sprayTask}
                            fluid
                            editAllMode
                          />
                        </React.Fragment>
                      );
                    })}
                </Grid>
              </div>
            </>
          )}
          {wizardStep === 2 && (
            <>
              <div className={styles.stepName}>
                Did you use the same amount of tanks as planned?
              </div>
              <div className={styles.fieldsHolder}>
                <AreasTable
                  hideCheckBoxes
                  sprayTask={sprayTask}
                  areasList={areasList}
                  showParentBlocks={showParentBlocks}
                />
                <Grid verticalAlign="middle" className="no-margin">
                  <TaskRow
                    label="Rows To Spray"
                    value={
                      <span>
                        {
                          sprayTask.rowsToSprayOptions.find(
                            option =>
                              option.value === sprayTask.actualRowsToSpray
                          ).text
                        }
                      </span>
                    }
                  />

                  <TaskRow
                    label={
                      <Checkbox
                        onChange={(_, data) =>
                          onFieldChange(
                            data.checked,
                            "actualRowsToSprayChecked"
                          )
                        }
                        checked={sprayTask.actualRowsToSprayChecked}
                        label="I sprayed the rows in this manner"
                      />
                    }
                    name="actualRowsToSpray"
                    suffix="wizard"
                    inputType={
                      sprayTask.actualRowsToSprayChecked
                        ? "hidden"
                        : "radioMenu"
                    }
                    value={sprayTask.actualRowsToSpray}
                    options={sprayTask.rowsToSprayOptions}
                    onChange={onFieldChange}
                    editAllMode
                  />
                </Grid>
              </div>
            </>
          )}
          {wizardStep === 3 && (
            <>
              <div className={styles.stepName}>
                What were the conditions while spraying?
              </div>
              <div className={styles.fieldsHolder}>
                <Grid verticalAlign="middle" className="no-margin">
                  <TaskRow
                    label="Temperature"
                    name="temperature"
                    inputType="inputText"
                    value={sprayTask.temperature}
                    onChange={onFieldChange}
                    inputLabel={{ content: "°C", inverted: "true" }}
                    labelPosition="right"
                    wide
                    editAllMode
                    rules={{ required: true, numeric: true }}
                    contentBeforeInput={{
                      component: (
                        <div className={styles.rowIcon}>
                          <Icon className="tuf-thermometer-half" />
                        </div>
                      )
                    }}
                  />
                  <TaskRow
                    label="Wind direction"
                    name="windDirection"
                    inputType="radioMenu"
                    value={sprayTask.windDirection}
                    options={directionOptions}
                    onChange={onFieldChange}
                    borderless
                    wide
                    editAllMode
                    className={styles.groupMenu}
                    rules={{ required: true }}
                    contentBeforeInput={{
                      component: (
                        <div className={styles.rowIcon}>
                          <Icon className="tuf-compass-solid" />
                        </div>
                      )
                    }}
                  />
                  <TaskRow
                    label="Wind strength"
                    name="windStrength"
                    inputType="radioMenu"
                    value={sprayTask.windStrength}
                    options={strengthsOptions}
                    onChange={onFieldChange}
                    borderless
                    wide
                    editAllMode
                    className={styles.groupMenu}
                    rules={{ required: true }}
                    contentBeforeInput={{
                      component: (
                        <div className={styles.rowIcon}>
                          <Icon className="tuf-wind" />
                        </div>
                      )
                    }}
                  />
                  <TaskRow
                    label="Delta T"
                    name="deltaT"
                    inputType="inputText"
                    value={sprayTask.deltaT}
                    onChange={onFieldChange}
                    wide
                    editAllMode
                    rules={{ numeric: true }}
                  />
                </Grid>
                <UpdateInfoFromWeatherStationButton />
              </div>
            </>
          )}
          {wizardStep === 4 && (
            <>
              <div className={styles.stepName}>
                Was the PPE worn while undertaking this task?
              </div>
              <div className={styles.fieldsHolder}>
                <Grid verticalAlign="middle" className="no-margin">
                  <TaskRow
                    name="ppeWorn"
                    inputType={"radioMenu"}
                    value={sprayTask.ppeWorn}
                    options={[
                      { text: "Yes", value: true },
                      { text: "No", value: false }
                    ]}
                    onChange={onFieldChange}
                    borderless
                    wide
                    editAllMode
                    contentBeforeInput={{
                      component: (
                        <div className={styles.rowIcon}>
                          <Icon name={"question circle outline"} />
                        </div>
                      )
                    }}
                    className={styles.groupMenu}
                    rules={{ required: true }}
                  />
                </Grid>
              </div>
            </>
          )}
          {wizardStep === 5 && (
            <div className={styles.doneStep}>
              <CircularProgressbar value={100} className={styles.doneBar} />
              <div>
                Great job!
                <br />
                You’re all done.
              </div>
              <Button
                className="button-text color-green"
                size="large"
                onClick={closeWizard}
              >
                Back to the task overview
              </Button>
            </div>
          )}
          <div className={styles.buttonsHolder}>
            <Grid verticalAlign="middle" className="no-margin" columns={2}>
              <Grid.Row>
                <Grid.Column>
                  {wizardStep > 0 && (
                    <Button className="color-green" onClick={() => setStep()}>
                      Back
                    </Button>
                  )}
                </Grid.Column>
                <Grid.Column textAlign="right">
                  {wizardStep < stepsValidations.length - 1 && (
                    <Button
                      onClick={wizardStep < 4 ? nextStep : finishWizard}
                      primary
                      disabled={!stepsValidations[wizardStep]}
                    >
                      {wizardStep < 4 ? "Next" : "Finish"}
                    </Button>
                  )}

                  {wizardStep === stepsValidations.length - 1 && (
                    <Button onClick={closeWizard} primary>
                      Done
                    </Button>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </Segment>
      </div>
    </div>
  );
}

Wizard.propTypes = {
  sprayTask: PropTypes.object,
  wizardStep: PropTypes.number,
  setStep: PropTypes.func,
  stepsValidations: PropTypes.array,
  directionOptions: PropTypes.array,
  actions: PropTypes.object,
  finishWizard: PropTypes.func,
  onChange: PropTypes.func,
  totalSteps: PropTypes.number,
  closeWizard: PropTypes.func,
  fieldToEdit: PropTypes.object,
  toggleEditField: PropTypes.func,
  getTimesList: PropTypes.func,
  strengthsOptions: PropTypes.array,
  areasList: PropTypes.array,
  showParentBlocks: PropTypes.bool
};

Wizard.defaultProps = {
  wizardStep: 0
};
const mapStateToProps = state => {
  return {
    strengthsOptions: strengthsSelector(state),
    directionOptions: directionSelector(state),
    batchNumbers: state.spray.batchNumbers
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setChemicalItemToAdd,
        setSprayDiaryField,
        setAreaValue,
        setActualRowsToSprayCheckState,
        setActualRowsToSpray
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Wizard);
