import PropTypes from "prop-types";
import React from "react";
import { Table } from "semantic-ui-react";
import TotalCostRow from "./TotalCostRow";
import Loader from "../../../../components/Loader";

const TotalCostPerAreaTable = ({ areaStats }) => {
  return (
    <>
      <Table>
        <Table.Header>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell>Farm Name</Table.HeaderCell>
          <Table.HeaderCell>Area Size (Ha)</Table.HeaderCell>
          <Table.HeaderCell>Cost/Harvest Unit</Table.HeaderCell>
          <Table.HeaderCell>Cost/Ha ($/Ha)</Table.HeaderCell>
          <Table.HeaderCell>Total Costs ($)</Table.HeaderCell>
        </Table.Header>
        <Table.Body>
          {areaStats ? (
            areaStats.map(area => <TotalCostRow area={area} />)
          ) : (
            <Loader />
          )}
        </Table.Body>
      </Table>
    </>
  );
};

TotalCostPerAreaTable.propTypes = {
  areaStats: PropTypes.shape({
    areasStat: PropTypes.object.isRequired,
    blockName: PropTypes.string.isRequired
  })
};

export default TotalCostPerAreaTable;
