import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { Form } from "semantic-ui-react";
import { chemicalsOptionsSelector } from "selectors/chemicals";

const SelectProduct = ({
  onChange,
  value,
  name,
  usedProductsIds,
  usedBucketsIds
}) => {
  const chemicalsOptions = useSelector(chemicalsOptionsSelector);
  const filteredChemicalsOptions = chemicalsOptions.filter(
    o =>
      (!usedProductsIds.includes(o.value) || o.value === value) &&
      usedBucketsIds.includes(o.key)
  );
  return (
    <Form.Dropdown
      fluid
      placeholder="Select product"
      options={filteredChemicalsOptions}
      onChange={(_, { value }) =>
        onChange(filteredChemicalsOptions.find(item => item.value === value))
      }
      search
      selection
      name={name}
      value={value}
    />
  );
};

SelectProduct.propTypes = {
  error: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.array.isRequired,
    PropTypes.number.isRequired
  ]),
  usedProductsIds: PropTypes.array
};

SelectProduct.defaultProps = {
  error: false,
  onChange: () => {},
  required: false
};

export default SelectProduct;
