import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Grid, Dropdown } from "semantic-ui-react";
import { Form, Input, TextArea } from "formsy-semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";

import HorizontalSelect from "../../../../components/HorizontalSelect/HorizontalSelect";
import {
  addMachinery,
  updateMachinery,
  clearMachineryError
} from "../../../../actions/Machinery/machineries";
import { history } from "../../../../store";
import {
  getErrorFields,
  validationErrors
} from "../../../../utils/validationRules";
import ErrorLabel from "../../../../components/ErrorLabel";
import Alert from "../../../../components/Alert";
import { abilitiesSelector } from "../../../../selectors/user";
import { valueToString } from "../../../../utils/tasksUtils";
import SegmentClosable from "../../../../components/SegmentClosable";
import { scrollIntoError } from "../../../../utils/scrollIntoError";
import styles from "./MachineryForm.module.css";

const initialValues = {
  name: "",
  herbicide: false,
  make: "",
  model: "",
  code: "",
  size: "",
  sizeUnit: "LITER",
  ratePerHour: "",
  notes: "",
  trackerId: "",
  trackerKey: ""
};

class MachineryForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      machinery: props.initialValues ? props.initialValues : initialValues,
      initialTrackerId: (props.initialValues
        ? props.initialValues
        : initialValues
      ).trackerId,
      errorsInSegments: {}
    };
    this.formRef = React.createRef();
    this.isSubmitted = false;
  }

  changeValue = (fieldName, value) => {
    const { machinery } = this.state;
    this.setState({
      machinery: {
        ...machinery,
        [fieldName]: value
      }
    });
  };

  handleErrors = () => {
    const { formRef } = this.props;
    const formsyForm = (formRef || this.formRef).current.formsyForm;
    const errorFields = getErrorFields(formsyForm);
    this.setState({
      errorsInSegments: {
        information: {
          error: errorFields.name || errorFields.ratePerHour
        }
      }
    });
    setTimeout(scrollIntoError, 300);
  };

  onValidSubmit = async () => {
    if (this.isSubmitted) {
      return null;
    }
    this.isSubmitted = true;
    const {
      actions: { addMachinery, updateMachinery },
      onSubmitForm
    } = this.props;
    const { machinery } = this.state;

    const machineryData = _.omit(machinery, ["id", "isFetching"]);

    this.setState({
      errorsInSegments: {}
    });

    let res;

    if (machinery.id) {
      res = await updateMachinery(machinery.id, machineryData, true);
    } else {
      res = await addMachinery(machineryData, true);
    }
    this.isSubmitted = false;

    if (!res.error) {
      if (_.isFunction(onSubmitForm)) {
        onSubmitForm(res);
      } else {
        history.push("/machinery");
      }
    }
  };

  closeAlert = () => {
    const { clearMachineryError } = this.props.actions;
    clearMachineryError();
  };

  onSubmit = () => {
    const { formRef } = this.props;
    const targetRef = formRef || this.formRef;
    targetRef.current.submit();
  };

  render() {
    const {
      herbicideOptions,
      sizeUnitOptions,
      modal,
      error,
      Can,
      showMobileHeader,
      formRef
    } = this.props;

    const { machinery, initialTrackerId, errorsInSegments } = this.state;

    const columnMiddle = modal
      ? {
          width: 16
        }
      : {
          mobile: 16,
          tablet: 16,
          computer: 10,
          largeScreen: 10,
          widescreen: 10
        };

    const columnMiddleRight = modal
      ? {
          width: 16
        }
      : {
          mobile: 16,
          tablet: 16,
          computer: 6,
          largeScreen: 6,
          widescreen: 6
        };

    const trackerIdHasChanged = initialTrackerId !== machinery.trackerId;
    return (
      <Form
        onValidSubmit={this.onValidSubmit}
        onInvalidSubmit={this.handleErrors}
        loading={false}
        ref={formRef || this.formRef}
      >
        <div className={`${styles.sprayGrid} ${modal ? styles.forModal : ""}`}>
          <div className={styles.sprayFormContainer} id="sprayGrid">
            <div>
              <SegmentClosable
                title="Machinery Information"
                errors={errorsInSegments.information}
              >
                <Grid>
                  <Grid.Row>
                    <Grid.Column {...columnMiddle}>
                      <Form.Input
                        fluid
                        {...(!machinery || !machinery.id
                          ? { autoFocus: true }
                          : {})}
                        label="Machinery Name"
                        placeholder="Machinery Name"
                        name="name"
                        size="large"
                        required
                        validationErrors={validationErrors({
                          isDefaultRequiredValue: "Name"
                        })}
                        errorLabel={ErrorLabel}
                        value={machinery.name}
                        onChange={event =>
                          this.changeValue("name", event.target.value)
                        }
                      />
                    </Grid.Column>
                    <Grid.Column {...columnMiddleRight}>
                      <Form.Field>
                        <label>
                          <div>Is this machine a herbicide unit?</div>
                          <HorizontalSelect
                            name="herbicide"
                            options={herbicideOptions}
                            selected={machinery.herbicide}
                            onSelect={option =>
                              this.changeValue("herbicide", option.value)
                            }
                          />
                        </label>
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column {...columnMiddle}>
                      <Form.Input
                        fluid
                        label="Model"
                        placeholder="Select the Model"
                        name="model"
                        size="large"
                        value={machinery.model}
                        onChange={event =>
                          this.changeValue("model", event.target.value)
                        }
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column {...columnMiddle}>
                      <Form.Input
                        fluid
                        label="Make"
                        placeholder="Select the Make"
                        name="make"
                        size="large"
                        value={machinery.make}
                        onChange={event =>
                          this.changeValue("make", event.target.value)
                        }
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column {...columnMiddle}>
                      <Form.Input
                        fluid
                        label="Code"
                        name="code"
                        size="large"
                        value={machinery.code}
                        onChange={event =>
                          this.changeValue("code", event.target.value)
                        }
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Form.Field required className="field-medium">
                        <label htmlFor="ratePerHour">Machinery Rate/Hr</label>
                        <Input
                          id="ratePerHour"
                          name="ratePerHour"
                          size="large"
                          required
                          validations="isNotNegativeFloat"
                          validationErrors={validationErrors({
                            isDefaultRequiredValue: "Machinery Rate/Hr"
                          })}
                          errorLabel={ErrorLabel}
                          value={valueToString(machinery.ratePerHour)}
                          onChange={event =>
                            this.changeValue("ratePerHour", event.target.value)
                          }
                          label="$"
                          labelPosition="right"
                          fluid
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Form.Field className="field-medium">
                        <label>Size</label>
                        <Input
                          name="size"
                          size="large"
                          fluid
                          value={valueToString(machinery.size)}
                          onChange={event =>
                            this.changeValue("size", event.target.value)
                          }
                          validations="isNotNegativeFloat"
                          validationErrors={validationErrors({
                            isNotNegativeFloat: "Size"
                          })}
                          errorLabel={ErrorLabel}
                          label={
                            <Dropdown
                              name="sizeUnit"
                              value={machinery.sizeUnit}
                              options={sizeUnitOptions}
                              onChange={(event, data) =>
                                this.changeValue("sizeUnit", data.value)
                              }
                            />
                          }
                          labelPosition="right"
                          className="field-medium"
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column {...columnMiddle}>
                      <Form.Field>
                        <label htmlFor="notes">Notes</label>
                        <TextArea
                          name="notes"
                          size="large"
                          value={machinery.notes}
                          onChange={event =>
                            this.changeValue("notes", event.target.value)
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </SegmentClosable>
              <SegmentClosable
                title="Tracker Information"
                errors={errorsInSegments.trackerInformation}
              >
                <Grid>
                  <Grid.Row>
                    <Grid.Column {...columnMiddle}>
                      <Form.Input
                        fluid
                        label="Tracker id"
                        placeholder="Tracker id"
                        name="trackerId"
                        size="large"
                        validationErrors={validationErrors({
                          isDefaultRequiredValue: "Tracker id"
                        })}
                        errorLabel={ErrorLabel}
                        value={machinery.trackerId}
                        onChange={event =>
                          this.changeValue("trackerId", event.target.value)
                        }
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column {...columnMiddle}>
                      <Form.Input
                        fluid
                        label="Tracker key"
                        placeholder={
                          !machinery.trackerId || trackerIdHasChanged
                            ? "Tracker key"
                            : "***********"
                        }
                        name="trackerKey"
                        size="large"
                        required={trackerIdHasChanged}
                        validations={!trackerIdHasChanged && "shouldBeEmpty"}
                        validationErrors={validationErrors({
                          isDefaultRequiredValue: "Tracker key",
                          shouldBeEmpty: "since `Tracker id` has not changed"
                        })}
                        errorLabel={ErrorLabel}
                        value={machinery.trackerKey}
                        onChange={event =>
                          this.changeValue("trackerKey", event.target.value)
                        }
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </SegmentClosable>
            </div>
          </div>
          {!showMobileHeader && (
            <div className={`${styles.sprayFormFooter} show-sm`}>
              <div className="text-right">
                {machinery.id ? (
                  <Can I="update" a="machineries">
                    <Button
                      primary
                      size="large"
                      type="button"
                      onClick={this.onSubmit}
                    >
                      Save
                    </Button>
                  </Can>
                ) : (
                  <Can I="add" a="machineries">
                    <Button
                      primary
                      size="large"
                      type="button"
                      onClick={this.onSubmit}
                    >
                      Save
                    </Button>
                  </Can>
                )}
              </div>
            </div>
          )}
        </div>
        <Alert
          open={!!error}
          size="mini"
          onClose={this.closeAlert}
          mainContent={<p>Error occurred</p>}
        />
      </Form>
    );
  }
}

MachineryForm.propTypes = {
  modal: PropTypes.bool,
  error: PropTypes.bool,
  sizeUnitOptions: PropTypes.any,
  herbicideOptions: PropTypes.any,
  showMobileHeader: PropTypes.bool,
  formRef: PropTypes.func,
  Can: PropTypes.func,
  onSubmitForm: PropTypes.func,
  initialValues: PropTypes.object,
  actions: PropTypes.object
};

const mapStateToProps = state => {
  const {
    machinery: { herbicideOptions, sizeUnitOptions, error }
  } = state;
  return {
    herbicideOptions,
    sizeUnitOptions,
    error,
    Can: abilitiesSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      addMachinery,
      updateMachinery,
      clearMachineryError
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(MachineryForm);
