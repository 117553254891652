import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Dropdown, Grid, Icon } from "semantic-ui-react";
import styles from "./TableSprayTask.module.css";
import TaskSeenIcon from "../TaskSeenIcon";
import AreasColumn from "./AreasColumn";
import TaskPlannedOrEndDate from "./TaskPlannedOrEndDate";
import ShowMoreColumn from "./ShowMoreColumn";
import CircularProgressbar from "../CircularProgressbar";
import Numeric from "../Numeric";

function RowAdmin({
  task,
  setActiveTask,
  taskData,
  statuses,
  handleDeleteTask,
  handlePrintTask
}) {
  const [showAll, toggleShowAll] = useState(false);
  const supervisorsToShow = useMemo(() => {
    return taskData && taskData.supervisors
      ? taskData.supervisors.map(item => item.employee)
      : [];
  }, [taskData]);

  const assigneesToShow = useMemo(() => {
    return taskData && taskData.assignees
      ? taskData.assignees.map(item => item.employee)
      : [];
  }, [taskData]);

  return (
    <Grid.Row
      onClick={() => setActiveTask(task.id || task._id)}
      className={task._id && styles.newTask}
    >
      <Grid.Column
        mobile={8}
        tablet={1}
        computer={1}
        largeScreen={1}
        widescreen={1}
      >
        ST-{task.id || "New"}
        <TaskSeenIcon
          employees={taskData.supervisors}
          className={styles.iconEye}
        />
        <br />
        {task.taskName}
      </Grid.Column>
      <Grid.Column
        mobile={8}
        tablet={2}
        computer={2}
        largeScreen={2}
        widescreen={1}
        className={styles.columnStatus}
      >
        <Icon name="circle" className={styles[`status${task.status}`]} />
        {statuses[task.status]}
      </Grid.Column>
      <Grid.Column
        mobile={1}
        tablet={1}
        computer={1}
        largeScreen={1}
        widescreen={1}
      >
        {task.applicationType}
      </Grid.Column>
      <AreasColumn
        mobile={4}
        tablet={2}
        computer={1}
        largeScreen={2}
        widescreen={1}
        items={taskData.blocksNames}
        showAll={showAll}
        onChange={toggleShowAll}
      />
      <ShowMoreColumn
        mobile={4}
        tablet={1}
        computer={1}
        largeScreen={1}
        widescreen={1}
        items={taskData.chemicals}
        showAll={showAll}
        onChange={toggleShowAll}
        renderItem={item => (
          <div className={styles.chemicalName}>{item.name}</div>
        )}
      />
      <Grid.Column
        mobile={8}
        tablet={1}
        computer={1}
        largeScreen={1}
        widescreen={1}
        className={styles.columnProgress}
      >
        <div className={styles.progressHolder}>
          <CircularProgressbar
            value={taskData.donePercent}
            text={`${taskData.donePercent}%`}
          />
        </div>
      </Grid.Column>
      <ShowMoreColumn
        mobile={4}
        tablet={2}
        computer={2}
        largeScreen={2}
        widescreen={1}
        items={supervisorsToShow}
        showAll={showAll}
        onChange={toggleShowAll}
        renderItem={item => (
          <div className={styles.chemicalName}>
            {item.firstName} {item.lastName}
          </div>
        )}
      />
      <ShowMoreColumn
        mobile={4}
        tablet={2}
        computer={2}
        largeScreen={2}
        items={assigneesToShow}
        showAll={showAll}
        onChange={toggleShowAll}
        renderItem={item => (
          <div className={styles.chemicalName}>
            {item.firstName} {item.lastName}
          </div>
        )}
      />
      <Grid.Column
        mobile={8}
        tablet={2}
        computer={2}
        largeScreen={2}
        widescreen={2}
      >
        <TaskPlannedOrEndDate task={task} />
      </Grid.Column>
      <Grid.Column
        mobile={8}
        tablet={1}
        computer={1}
        largeScreen={1}
        widescreen={1}
      >
        <Numeric
          value={
            task.status === "COMPLETED"
              ? taskData.actualTotalTanks
              : taskData.plannedTanks
          }
          fractionDigits={2}
          commaSeparatorOnThousands
        />
      </Grid.Column>
      <Grid.Column width={1} className={styles.columnActions} textAlign="right">
        <span
          className={styles.printHolder}
          onClick={event => handlePrintTask(event, task)}
        >
          <Icon name="print" />
        </span>
        <Dropdown
          icon="ellipsis vertical"
          iconposition="right"
          floating
          button
          className={`button-text ${styles.buttonMore}`}
        >
          <Dropdown.Menu direction="left">
            <Dropdown.Item
              onClick={event =>
                handleDeleteTask(event, { id: task.id, _id: task._id })
              }
            >
              Delete Task
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Grid.Column>
    </Grid.Row>
  );
}

RowAdmin.propTypes = {
  task: PropTypes.object.isRequired,
  handleDeleteTask: PropTypes.func,
  handlePrintTask: PropTypes.func,
  setActiveTask: PropTypes.func,
  taskData: PropTypes.object,
  statuses: PropTypes.object
};

RowAdmin.defaultProps = {
  task: {}
};

export default RowAdmin;
