import Joi from 'joi-browser';
import _ from 'lodash';

const NO_PATCHES = 'NO_PATCHES';
const PATCHES = 'PATCHES';

const spacingValidation = {
  numberOfPlants: Joi.number()
    .min(0)
    .allow('')
    .allow(null)
    .optional(),
  rowsCount: Joi.number()
    .min(0)
    .allow('')
    .allow(null)
    .optional(),
  rowSpacing: Joi.number()
    .min(0)
    .allow('')
    .allow(null)
    .optional(),
  plantsSpacing: Joi.number()
    .min(0)
    .allow('')
    .allow(null)
    .optional(),
  plantsSpacingAcrossTheBed: Joi.number()
    .min(0)
    .allow('')
    .allow(null)
    .optional(),
};

const varietySchema = Joi.object().keys({
  ...spacingValidation,
  variety: Joi.object().required(),
  overlapping: Joi.boolean().invalid(true),
  plantingAt: Joi.date()
    .iso()
    .allow('')
    .optional(),
  pickingAt: Joi.optional(),
});

let rotatingVarietySchemaKeys = {
  variety: Joi.object().required(),
  overlapping: Joi.boolean().invalid(true),
  plantingAt: Joi.date()
    .iso()
    .required(),
  pickingAt: Joi.date()
    .iso()
    .allow('')
    .min(Joi.ref('plantingAt'))
    .optional(),
  ...spacingValidation,
};
const rotatingVarietySchema = Joi.object().keys(rotatingVarietySchemaKeys);

const firstRotatingSchema = Joi.object().keys({
  ...rotatingVarietySchemaKeys,
  variety: Joi.object().when('crop', {
    is: Joi.exist(),
    then: Joi.object().required(),
    otherwise: null,
  }),
  plantingAt: Joi.date().when('crop', {
    is: Joi.exist(),
    then: Joi.date()
      .iso()
      .required(),
    otherwise: null,
  }),
});

const attributeSchema = Joi.when(
  Joi.object({
    isRotating: Joi.boolean().valid(true),
    varieties: Joi.array().length(1),
  }),
  {
    then: Joi.object().keys({
      varieties: Joi.array()
        .items(firstRotatingSchema)
        .optional(),
    }),
    otherwise: Joi.object().keys({
      varieties: Joi.array().when('isRotating', {
        is: true,
        then: Joi.array()
          .items(rotatingVarietySchema)
          .required(),
        otherwise: Joi.array()
          .items(varietySchema)
          .required(),
      }),
    }),
  },
);

const patchSchema = Joi.object().keys({
  name: Joi.string().required(),
  size: Joi.number()
    .greater(0)
    .required(),
  attributes: attributeSchema,
});

const blockSchema = Joi.object().keys({
  name: Joi.string().required(),
  size: Joi.when('patchMode', {
    is: NO_PATCHES,
    then: Joi.number()
      .greater(0)
      .required(),
    otherwise: Joi.optional(),
  }),
  patchMode: Joi.string()
    .valid(PATCHES, NO_PATCHES)
    .required(),
  patches: Joi.array().when('patchMode', {
    is: PATCHES,
    then: Joi.array().items(patchSchema),
    otherwise: Joi.optional(),
  }),
  attributes: Joi.when('patchMode', {
    is: NO_PATCHES,
    then: attributeSchema,
    otherwise: Joi.optional(),
  }),
});

const getFarmSchema = () => {
  return Joi.object().keys({
    name: Joi.string().required(),
    blocks: Joi.array().items(blockSchema),
  });
};

const prepareErrors = errors => {
  return errors.reduce((next, error) => {
    const path = error.path.join('.');
    return { ...next, [path]: [..._.get(next, path, []), error.message] };
  }, {});
};

export const validateFarm = data => {
  const farmSchema = getFarmSchema();
  const result = farmSchema.validate(data, {
    abortEarly: false,
    allowUnknown: true,
  });

  if (result && result.error) {
    return prepareErrors(result.error.details);
  }

  return null;
};

export const validateBlock = data => {
  const result = blockSchema.validate(data, {
    abortEarly: false,
    allowUnknown: true,
  });

  if (result && result.error) {
    return prepareErrors(result.error.details);
  }

  return null;
};
