import React, { useState } from "react";
import { useFormikContext, Field } from "formik";
import { Icon, Segment, Grid } from "semantic-ui-react";
import { get } from "lodash";
import styles from "./Product.module.css";
import { matterToUnits } from "utils/constToUnits";
import { useSelector } from "react-redux";
import { shedsListSelector } from "selectors/sheds";
import { chemicalsOptionsSelector } from "selectors/chemicals";
import ProductForm from "./ProductForm";
import Numeric from "components/Numeric/Numeric";

const ProductRow = ({
  productData,
  quantities,
  setValidation,
  setCheckForm,
  checkForm,
  productsListCheck,
  deleteButtonCheck,
  setProductId,
  setDirty,
  checmicalId,
  productIndex
}) => {
  const { values, setFieldValue, setValues } = useFormikContext();
  const [openTable, toggleOpenTable] = useState();
  const productList = useSelector(chemicalsOptionsSelector);
  return (
    <Grid container as={Segment} className={styles.wrapper}>
      <Grid.Row className={styles.wrapperRow}>
        <div className={styles.columnContainer}>
          <Grid.Column className={styles.nameColumn}>
            <label className={styles.productHeader}>
              {values.products
                ? get(
                    productList.find(item => item.key == checmicalId),
                    "text"
                  )
                : "-"}
            </label>
          </Grid.Column>
          <div className={styles.costQtyContainer}>
            <Grid.Column className={styles.costColumn}>
              <Numeric
                fractionDigits={
                  get(values.products[productIndex], "totalCost") > 1000 ? 0 : 2
                }
                value={get(values.products[productIndex], "totalCost")}
                commaSeparatorOnThousands
                units="$"
                unitsPosition="left"
                defaultValue="-"
              />
            </Grid.Column>
            <Grid.Column className={styles.dotColumn}>
              <span className={styles.dot}></span>
            </Grid.Column>
            <Grid.Column className={styles.quantityColumn}>
              <Numeric
                fractionDigits={
                  values.products[productIndex].quantities.reduce(
                    (prev, curr) => prev + +curr.unitsNumber * curr.unitSize,
                    0
                  ) > 1000
                    ? 0
                    : 2
                }
                value={values.products[productIndex].quantities.reduce(
                  (prev, curr) => prev + +curr.unitsNumber * curr.unitSize,
                  0
                )}
                commaSeparatorOnThousands
                units={
                  values.products
                    ? matterToUnits(
                        get(
                          productList.find(
                            item =>
                              item.key ==
                                values.products.find(item => item).chemicalId &&
                              item
                          ),
                          "matter"
                        )
                      )
                    : "Kg"
                }
                unitsPosition="rigth"
                defaultValue="-"
              />
            </Grid.Column>
          </div>
        </div>
        <div className={styles.iconContainer}>
          <Grid.Column className={styles.chevronContainer}>
            <div
              className={`${styles.toggleIcon} ${
                openTable ? styles.openTable : ""
              }`}
            >
              <Icon
                className={openTable ? "tuf-chevron-down" : "tuf-chevron-right"}
                onClick={() => toggleOpenTable(!openTable)}
              />
            </div>
          </Grid.Column>
        </div>
      </Grid.Row>

      {openTable && (
        <Grid.Row className={styles.formContainer}>
          <ProductForm
            productIndex={productIndex}
            deleteButtonCheck={deleteButtonCheck}
            setProductId={setProductId}
            productsListCheck={productsListCheck}
            checkForm={checkForm}
            productData={productData}
            setValidation={setValidation}
            setCheckForm={setCheckForm}
            setDirty={setDirty}
            quantities={quantities}
            closeProduct={() => toggleOpenTable(false)}
            onSubmit={newVal => {
              setFieldValue(`products.${productIndex}`, newVal);
              toggleOpenTable(false);
            }}
          />
        </Grid.Row>
      )}
    </Grid>
  );
};

export default ProductRow;
