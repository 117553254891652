import React from "react";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";
import styles from "./TableSprayTask.module.css";
import ShowMoreColumn from "./ShowMoreColumn";

function AreasColumn({
  mobile,
  tablet,
  computer,
  largeScreen,
  items,
  showAll,
  onChange
}) {
  return (
    <ShowMoreColumn
      mobile={mobile}
      tablet={tablet}
      computer={computer}
      largeScreen={largeScreen}
      items={items}
      showAll={showAll}
      onChange={onChange}
      className={`${styles.blockName} ${styles.areasColumn} ${
        items.find(block => block.done) ? styles.hasDone : ""
      }`}
      renderItem={item => (
        <div className={styles.blockName}>
          {item.parent ? `${item.parent.name} - ` : ""}
          {item.name}
          {item.done && <Icon name="check" color="green" />}
        </div>
      )}
    />
  );
}

AreasColumn.propTypes = {
  mobile: PropTypes.number,
  tablet: PropTypes.number,
  computer: PropTypes.number,
  largeScreen: PropTypes.number,
  taskData: PropTypes.object,
  items: PropTypes.array,
  showAll: PropTypes.bool,
  onChange: PropTypes.func
};

export default AreasColumn;
