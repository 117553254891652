import React from "react";
import CollapsibleBlock from "../../../../../components/CollapsibleBlock";
import { Table } from "semantic-ui-react";
import { get } from "lodash";
import { isEmptyValue } from "../../../../../utils/tasksUtils";
import moment from "moment";
import styles from "../Sidebar.module.css";

const EmploymentSection = ({ employee, editButton, Can }) => {
  return (
    <CollapsibleBlock
      title="Employment"
      headerOptionalComponent={editButton}
      className={styles.blockContainer}
    >
      <Table className={styles.sectionTable} definition fixed>
        <Table.Body>
          <Table.Row>
            <Table.Cell width={6}>Start Date</Table.Cell>
            <Table.Cell width={10}>
              {isEmptyValue(
                moment(get(employee, "startDate")).format("DD/MM/YYYY")
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Contractor</Table.Cell>
            <Table.Cell>
              {isEmptyValue(get(employee, "contractor.name"))}
            </Table.Cell>
          </Table.Row>
          <Can I={"rate_manage"} a="employees">
            <Table.Row>
              <Table.Cell>Rate ($/hr)</Table.Cell>
              <Table.Cell>
                {employee.ratePerHour && employee.ratePerHour !== 0
                  ? `$ ${employee.ratePerHour.toFixed(2)}/hr`
                  : "—"}
              </Table.Cell>
            </Table.Row>
          </Can>
        </Table.Body>
      </Table>
    </CollapsibleBlock>
  );
};

export default EmploymentSection;
