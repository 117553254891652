import React from "react";
import CollapsibleBlock from "../../../../../components/CollapsibleBlock";
import { Icon, Label, Table, Popup } from "semantic-ui-react";
import { get } from "lodash";
import { isEmptyValue } from "../../../../../utils/tasksUtils";
import styles from "../Sidebar.module.css";
import AttachmentLabel from "components/Attachments/AttachmentLabel";

const AdditionalInformationSection = ({ employee, editButton }) => {
  return (
    <CollapsibleBlock
      title="Additional Information"
      headerOptionalComponent={editButton}
      className={styles.blockContainer}
    >
      <Table className={styles.sectionTable} definition fixed>
        <Table.Body>
          <Table.Row>
            <Table.Cell width={6}>Medical conditions/ allergies</Table.Cell>
            <Table.Cell width={10}>
              {isEmptyValue(get(employee, "medicalConditions"))}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Notes</Table.Cell>
            <Table.Cell>{isEmptyValue(get(employee, "notes"))}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Attachments</Table.Cell>
            <Table.Cell className={styles.attachmentCell}>
              {employee.attachments?.length
                ? employee.attachments.map(item => {
                    const filename = item.name.split(".");
                    const extension = filename.pop();

                    return (
                      <Popup
                        inverted
                        position="left center"
                        content={item.name}
                        size="mini"
                        trigger={
                          <div className={styles.attachmentContainer}>
                            {item && (
                              <AttachmentLabel
                                attachment={item}
                                withDownloadIcon={true}
                              />
                            )}
                          </div>
                        }
                      />
                    );
                  })
                : null}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </CollapsibleBlock>
  );
};

export default AdditionalInformationSection;
