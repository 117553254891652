let db;

let openRequest = indexedDB.open("changelog_db", 1);

openRequest.onupgradeneeded = function(e) {
  db = e.target.result;
  if (!db.objectStoreNames.contains("store")) {
    const storeOS = db.createObjectStore("store", { keyPath: "name" });
  }
  getStoreData();
};

openRequest.onsuccess = function(e) {
  db = e.target.result;
  getStoreData();
};

const getStoreData = (key = "araes") => {
  return new Promise(resolve => {
    let openRequest = indexedDB.open("changelog_db", 1);
    openRequest.onsuccess = () => {
      if (db.objectStoreNames.contains("store")) {
        let db = openRequest.result;
        const tx = db.transaction("store", "readonly");
        const store = tx.objectStore("store");
        const res = store.get(key);
        res.onsuccess = () => {
          resolve(res.result);
        };
      }
    };
  });
};

export const getParsableItemFromStorage = async key => {
  let item = await getStoreData(key);

  if (!item) {
    return undefined;
  }
  return item;
};

export const getParsableItemsFromStorage = async keys => {
  return await keys.reduce(async (previousPromise, key) => {
    let jobsArray = await previousPromise;

    const jobPostings = await getParsableItemFromStorage(key);
    return {
      ...jobsArray,
      [key]: jobPostings
    };
  }, Promise.resolve({}));
};
