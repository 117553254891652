export const actionType = {
  ADD_EMPLOYEE_START: "EMPLOYEES/ADD_EMPLOYEE_START",
  ADD_EMPLOYEE: "EMPLOYEES/ADD_EMPLOYEE",
  ADD_EMPLOYEE_FAIL: "EMPLOYEES/ADD_EMPLOYEE_FAIL",

  UPDATE_EMPLOYEE_START: "EMPLOYEES/UPDATE_EMPLOYEE_START",
  UPDATE_EMPLOYEE: "EMPLOYEES/UPDATE_EMPLOYEE",
  UPDATE_EMPLOYEE_FAIL: "EMPLOYEES/UPDATE_EMPLOYEE_FAIL",

  FETCH_ARCHIVED_EMPLOYEES_LIST_START:
    "EMPLOYEES/FETCH_ARCHIVED_EMPLOYEES_LIST_START",
  FETCH_ARCHIVED_EMPLOYEES_LIST: "EMPLOYEES/FETCH_ARCHIVED_EMPLOYEES_LIST",
  FETCH_ARCHIVED_EMPLOYEES_LIST_FAIL:
    "EMPLOYEES/FETCH_ARCHIVED_EMPLOYEES_LIST_FAIL",

  FETCH_EMPLOYEES_LIST_START: "EMPLOYEES/FETCH_EMPLOYEES_LIST_START",
  FETCH_EMPLOYEES_LIST: "EMPLOYEES/FETCH_EMPLOYEES_LIST",
  FETCH_EMPLOYEES_LIST_FAIL: "EMPLOYEES/FETCH_EMPLOYEES_LIST_FAIL",

  CHANGE_LAST_UPDATE_DATE: "EMPLOYEES/CHANGE_LAST_UPDATE_DATE",
  FETCH_EMPLOYEES_START: "EMPLOYEES/FETCH_EMPLOYEES_START",
  FETCH_EMPLOYEES: "EMPLOYEES/FETCH_EMPLOYEES",
  FETCH_EMPLOYEES_ERROR: "EMPLOYEES/FETCH_EMPLOYEES_ERROR",

  FETCH_EMPLOYEES_BASIC_INFORMATION_START:
    "EMPLOYEES/FETCH_EMPLOYEES_BASIC_INFORMATION_START",
  FETCH_EMPLOYEES_BASIC_INFORMATION:
    "EMPLOYEES/FETCH_EMPLOYEES_BASIC_INFORMATION",
  FETCH_EMPLOYEES_BASIC_INFORMATION_ERROR:
    "EMPLOYEES/FETCH_EMPLOYEES_BASIC_INFORMATION_ERROR",

  FETCH_EMPLOYEE_START: "EMPLOYEES/FETCH_EMPLOYEE_START",
  FETCH_EMPLOYEE: "EMPLOYEES/FETCH_EMPLOYEE",
  FETCH_EMPLOYEE_FAIL: "EMPLOYEES/FETCH_EMPLOYEE_FAIL",

  CHANGE_EMPLOYEES_PAGE: "EMPLOYEES/CHANGE_EMPLOYEES_PAGE",
  CHANGE_PAGE_SIZE: "EMPLOYEES/CHANGE_PAGE_SIZE",

  DELETE_EMPLOYEE_START: "EMPLOYEES/DELETE_EMPLOYEE_START",
  DELETE_EMPLOYEE: "EMPLOYEES/DELETE_EMPLOYEE",
  DELETE_EMPLOYEE_FAIL: "EMPLOYEES/DELETE_EMPLOYEE_FAIL",

  DELETE_EMPLOYEES_START: "EMPLOYEES/DELETE_EMPLOYEES_START",
  DELETE_EMPLOYEES: "EMPLOYEES/DELETE_EMPLOYEES",
  DELETE_EMPLOYEES_FAIL: "EMPLOYEES/DELETE_EMPLOYEES_FAIL",

  UPLOAD_EMPLOYEE_ATTACHMENT_START:
    "EMPLOYEES/UPLOAD_EMPLOYEE_ATTACHMENT_START",
  UPLOAD_EMPLOYEE_ATTACHMENT: "EMPLOYEES/UPLOAD_EMPLOYEE_ATTACHMENT",
  UPLOAD_EMPLOYEE_ATTACHMENT_ERROR:
    "EMPLOYEES/UPLOAD_EMPLOYEE_ATTACHMENT_ERROR",
  UPLOAD_EMPLOYEE_ATTACHMENT_PROGRESS:
    "EMPLOYEES/UPLOAD_EMPLOYEE_ATTACHMENT_PROGRESS",
  CREATE_EMPLOYEE_ATTACHMENT_CANCEL_TOKEN:
    "CREATE_EMPLOYEE_ATTACHMENT_CANCEL_TOKEN",

  UPLOAD_EMPLOYEE_PICTURE_START: "EMPLOYEES/UPLOAD_EMPLOYEE_PICTURE_START",
  UPLOAD_EMPLOYEE_PICTURE: "EMPLOYEES/UPLOAD_EMPLOYEE_PICTURE",
  UPLOAD_EMPLOYEE_PICTURE_ERROR: "EMPLOYEES/UPLOAD_EMPLOYEE_PICTURE_ERROR",

  CLEAR_EMPLOYEE_ERROR: "EMPLOYEES/CLEAR_EMPLOYEE_ERROR",

  FETCH_EMPLOYEES_TYPES_START: "EMPLOYEES/FETCH_EMPLOYEES_TYPES_START",
  FETCH_EMPLOYEES_TYPES: "EMPLOYEES/FETCH_EMPLOYEES_TYPES",
  FETCH_EMPLOYEES_TYPES_FAIL: "EMPLOYEES/FETCH_EMPLOYEES_TYPES_FAIL",

  ARCHIVE_EMPLOYEES_START: "EMPLOYEES/ARCHIVE_EMPLOYEES_START",
  ARCHIVE_EMPLOYEES: "EMPLOYEES/ARCHIVE_EMPLOYEES",
  ARCHIVE_EMPLOYEES_FAIL: "EMPLOYEES/ARCHIVE_EMPLOYEES_FAIL"
};
