import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Header, Icon, Image, Popup, Segment } from "semantic-ui-react";
import localStorage from "localStorage";
import { connect, useDispatch } from "react-redux";
import Layout from "../../../components/Layout";
import { forceUpdateApp, getBackEndBuildNumber } from "../../../actions/Common";
import styles from "./Setting.module.css";
import { fetchSettings } from "actions/Settings";
import BusinessInformation from "./BusinessInformation";
import { Formik } from "formik";
import Seasons from "./Seasons";

function Settings({ route, location, settings }) {
  const dispatch = useDispatch();
  const [backEndBuildNumber, setBackEndBuildNumber] = useState("-");
  const [editCheck, setEditCheck] = useState(false);
  const appVersion = localStorage.getItem("appVersion");
  const wasUpdated = localStorage.getItem("forceUpdate");
  const setForceUpdate = () => {
    dispatch(forceUpdateApp(true));
  };
  useEffect(() => {
    dispatch(getBackEndBuildNumber()).then(setBackEndBuildNumber);
    return () => {
      localStorage.removeItem("forceUpdate");
    };
  }, []);
  return (
    <Layout route={route} location={location}>
      <div className={styles.header}>General information</div>
      <Segment>
        <div className={styles.systemInformationHeader}>System Information</div>
        {wasUpdated && (
          <Header className={styles.updateInfoWrapper} as="h4">
            Application was updated
          </Header>
        )}
        <div> Version and Force Update feature </div>
        <div className={styles.informationWrapper}>
          <div className={styles.informationFieldContainer}>
            <div className={styles.informationWrapperHeader}>
              Application version
            </div>
            <div className={styles.informationWrapperValue}>
              {(appVersion || "").replace(/\./gi, ":")}
            </div>
          </div>
          <div className={styles.informationFieldContainer}>
            <div className={styles.informationWrapperHeader}>
              {" "}
              BackEnd version:
            </div>
            <div className={styles.informationWrapperValue}>
              {(backEndBuildNumber || "").replace(/\./gi, ":")}
            </div>
          </div>
          <div className={styles.informationFieldContainer}>
            <div className={styles.informationWrapperHeader}>
              {" "}
              General information
            </div>
            <div className={styles.informationWrapperValue}>
              Plese, clear your brouser’s cache and stored data. You can
              alternatively click the “Force update” button below. But first,
              you should close all other tabs with this Application in it.
            </div>
          </div>
          <div className={styles.updateBtnContainer}>
            <Button className={styles.updateBtn} onClick={setForceUpdate}>
              Force update
            </Button>
          </div>
        </div>
      </Segment>
      <Segment>
        <div className={styles.systemInformationHeader}>
          Business information
          {settings.name && !editCheck && (
            <Popup
              inverted
              position="bottom center"
              content={"Edit"}
              size="tiny"
              trigger={
                <div className={styles.editButtonWrapper}>
                  <Button
                    onClick={() => setEditCheck(true)}
                    className={styles.editButton}
                    floated="right"
                    icon={<Icon name="edit" />}
                    size="small"
                  />
                </div>
              }
            />
          )}
        </div>
        <div>About company and ABN </div>
        {settings.name && !editCheck ? (
          <div className={styles.companyInformation}>
            <div className={styles.businessInformationWrapper}>
              <div className={styles.businessInformationHeader}>
                Company logo
              </div>
              <div className={styles.businessInformationValue}>
                {settings.picture?.presignedUrl ? (
                  <Image
                    avatar
                    className={styles.companyLogo}
                    src={settings.picture?.presignedUrl}
                  />
                ) : (
                  <div className={styles.companyDisabledLogo} />
                )}
              </div>
            </div>
            <div className={styles.businessInformationWrapper}>
              <div className={styles.businessInformationHeader}>
                {" "}
                Business name
              </div>
              <div className={styles.businessInformationValue}>
                {" "}
                {settings.name || "-"}
              </div>
            </div>
            <div className={styles.businessInformationWrapper}>
              <div className={styles.businessInformationHeader}> ABN</div>
              <div className={styles.businessInformationValue}>
                {settings.abn || "-"}
              </div>
            </div>
          </div>
        ) : (
          <Formik>
            <BusinessInformation
              settings={settings}
              setEditCheck={setEditCheck}
              editCheck={editCheck}
            />
          </Formik>
        )}
      </Segment>
      <Segment>
        <div className={styles.systemInformationHeader}>Seasons</div>
        <div className={styles.seasonInfo}>
          By adding your seasons to TieUp Farming, you will have an option to
          choose them as part of the system’s filtering system
        </div>
        <Formik>
          <Seasons settings={settings} />
        </Formik>
      </Segment>
    </Layout>
  );
}

Settings.propTypes = {
  location: PropTypes.object,
  route: PropTypes.object
};

/* export default Settings; */

export default connect((state, props) => {
  return {
    settings: state.settings.data
  };
})(Settings);
