import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, Icon } from "semantic-ui-react";
import moment from "moment";
import DatePicker from "../../../../../components/DatePicker";

import styles from "./TrackerViewerControlBar.module.css";
import {
  clearTrackerRoute,
  getTrackerRoute
} from "../../../../../actions/Machinery/machineries";
import { toUnixInUTC } from "../../../../../utils/timezoneUtils";

const TrackerViewerControlBar = props => {
  const {
    machineryId,
    actions: { clearTrackerRoute, getTrackerRoute }
  } = props;
  const [from, setFrom] = useState(moment(moment.now()).format("YYYY-MM-DD"));
  const [to, setTo] = useState(moment(moment.now()).format("YYYY-MM-DD"));

  const refresh = useCallback(() => {
    getTrackerRoute(
      machineryId,
      toUnixInUTC(from),
      toUnixInUTC(
        moment(to)
          .add(1, "days")
          .format("YYYY-MM-DD")
      )
    );
  }, [machineryId, from, to, getTrackerRoute]);

  useEffect(() => {
    clearTrackerRoute();
    refresh();
  }, [machineryId]); // eslint-disable-line

  return (
    <div className={styles.barHolder}>
      <div className={styles.barHolder}>
        <div>
          <div className={styles.barHolderIn}>
            <div>From:</div>
            <DatePicker
              value={from}
              onChange={date => setFrom(date)}
              size="small"
              className={styles.dateInput}
            />
          </div>
        </div>
        <div>
          <div className={styles.barHolderIn}>
            <div>To:</div>
            <DatePicker
              value={to}
              onChange={date => setTo(date)}
              size="small"
              className={styles.dateInput}
            />
          </div>
        </div>
      </div>
      <div>
        <Button onClick={refresh}>
          <Icon name="repeat" />
          <span>Refresh</span>
        </Button>
      </div>
    </div>
  );
};

TrackerViewerControlBar.propTypes = {
  machineryId: PropTypes.number.isRequired,
  actions: PropTypes.object
};

TrackerViewerControlBar.defaultProps = {};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      { clearTrackerRoute, getTrackerRoute },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrackerViewerControlBar);
