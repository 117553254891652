import React, { useCallback } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Checkbox,
  Dropdown,
  Grid,
  Header,
  Icon,
  Input,
  Radio,
  Segment
} from "semantic-ui-react";
import { history } from "../../store";
import styles from "./HeaderFarms.module.css";
import { Link } from "react-router-dom";

const handleShowFarm = (event, farmId, show, toggleShowFarm) => {
  event.stopPropagation();
  toggleShowFarm(farmId, show);
};

const handleAddNewFarm = route => {
  history.push(`${route.href}/add_new_farm`);
};

function HeaderFarms({
  farms,
  toggleShowFarm,
  selectedFarmId,
  route,
  showArchived,
  toggleShowArchived,
  search,
  setFarmsSearch
}) {
  const shownAll = !farms.find(farm => !farm.show);
  const farmsToShow = farms.filter(
    farm => farm.show && (!selectedFarmId || selectedFarmId === farm.id)
  );
  const handelRawDataViewClick = useCallback(() => {
    history.push(`${route.href}/records`);
  }, [route]);

  return (
    <Segment basic className={styles.headerFarms}>
      <Grid>
        <Grid.Row>
          <Grid.Column mobile={10} computer={3} largeScreen={3}>
            {selectedFarmId && farmsToShow[0] ? (
              <>
                <Link
                  className={"ui"}
                  style={{ marginRight: 10 }}
                  to={"/farms"}
                >
                  <Icon name="angle left" />
                </Link>
                <Header as="h2" className={styles.farmName}>
                  {farmsToShow[0].name}
                </Header>
              </>
            ) : !selectedFarmId ? (
              <Header as="h2">
                {farmsToShow.length} farms
                <Dropdown
                  text=" "
                  multiple
                  className={styles.allFarms}
                  scrolling
                >
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={handelRawDataViewClick}>
                      Raw data view
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <div className={styles.checkBoxes}>
                      <Checkbox
                        label="All"
                        checked={shownAll}
                        onChange={event => {
                          handleShowFarm(
                            event,
                            null,
                            !shownAll,
                            toggleShowFarm
                          );
                        }}
                      />
                      {farms.map(farm => (
                        <Checkbox
                          key={`farm_${farm.id}`}
                          label={farm.name}
                          checked={farm.show}
                          onChange={event => {
                            handleShowFarm(
                              event,
                              farm.id,
                              !farm.show,
                              toggleShowFarm
                            );
                          }}
                        />
                      ))}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </Header>
            ) : null}
          </Grid.Column>
          <Grid.Column mobile={4} computer={4} largeScreen={4} floated="right">
            <div className={styles.rightTools}>
              <Button
                primary
                size="large"
                className={styles.addNew}
                floated="right"
                onClick={() => handleAddNewFarm(route)}
              >
                Add
              </Button>
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} computer={5} largeScreen={5}>
            <span className={styles.archiveToggle}>
              <span>Show archived</span>
              <Radio
                toggle
                checked={showArchived}
                onChange={() => {
                  toggleShowArchived(!showArchived);
                }}
              />
            </span>
            <Input
              icon="search"
              className={styles.searchInput}
              placeholder="Search"
              fluid
              value={search}
              onChange={e => {
                setFarmsSearch(e.target.value);
              }}
              size={"large"}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
}

HeaderFarms.propTypes = {
  farms: PropTypes.array.isRequired,
  selectedFarmId: PropTypes.number,
  route: PropTypes.object,
  showArchived: PropTypes.bool.isRequired,
  toggleShowFarm: PropTypes.func,
  toggleShowArchived: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
  setFarmsSearch: PropTypes.func.isRequired
};

HeaderFarms.defaultProps = {
  farms: []
};

export default HeaderFarms;
