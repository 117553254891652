import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Button,
  Form,
  Grid,
  Icon,
  Message,
  Modal,
  Header
} from "semantic-ui-react";
import moment from "moment";
import WorkingTime, { createDefaultWorkingTime } from "./WorkingTime";
import { setWorkingTimes } from "../../../../actions/SprayDiary/common";
import styles from "./WorkingTimes.module.css";

export function validateWorkingTimes(workingTimes) {
  return workingTimes.map(workingTime => {
    const emptyHoursValue = ":00";
    const { workingFrom, workingTo } = workingTime;

    if (
      workingFrom === emptyHoursValue ||
      workingTo === emptyHoursValue ||
      workingFrom === workingTo
    )
      return false;

    const fromMoment = moment(workingFrom, "HH:mm");
    const toMoment = moment(workingTo, "HH:mm");
    return fromMoment.isBefore(toMoment);
  });
}

export function calcTotalWorkingTimes(workingTimes) {
  const countDuration = timeDuration => {
    const duration = moment.duration(timeDuration.unix(), "seconds");
    const hours = Math.floor(duration.asHours());
    const mins = Math.floor(duration.asMinutes()) - hours * 60;
    return hours + ":" + (mins < 10 ? "0" : "") + mins;
  };
  const each = [];
  const totalDuration = workingTimes.reduce((total, workingTime) => {
    const { workingFrom, workingTo } = workingTime;
    if (workingTo && workingFrom) {
      const fromMoment = moment(workingFrom, "HH:mm");
      const toMoment = moment(workingTo, "HH:mm");

      if (!toMoment.isBefore(fromMoment)) {
        const duration = moment.duration(toMoment.diff(fromMoment));
        each.push(countDuration(moment.utc(0).add(duration)));
        return total.add(duration);
      }
    }
    return total;
  }, moment.utc(0));

  return { total: countDuration(totalDuration), each };
}

class WorkingTimes extends Component {
  state = {
    workTimeIndexToDelete: null,
    showDeleteModal: false
  };

  addWorkingTime = () => {
    const { workingTimes, actions } = this.props;
    let newWorkingTimes = workingTimes.slice(0);
    newWorkingTimes.push(createDefaultWorkingTime());
    actions.setWorkingTimes(newWorkingTimes);
  };

  updateWorkingTime = (workTimeIndex, updatedWorkingTime) => {
    const { workingTimes, actions } = this.props;
    let newWorkingTimes = workingTimes.slice(0);
    newWorkingTimes[workTimeIndex] = updatedWorkingTime;
    actions.setWorkingTimes(newWorkingTimes);
  };

  handleDeleteWorkingTime = () => {
    const { workingTimes, actions } = this.props;
    const { workTimeIndexToDelete } = this.state;
    let newWorkingTimes = workingTimes.slice(0);
    newWorkingTimes.splice(workTimeIndexToDelete, 1);
    actions.setWorkingTimes(newWorkingTimes);
    this.hideDeleteModal();
  };

  deleteWorkingTimeItem = workTimeIndexToDelete => {
    this.setState({
      workTimeIndexToDelete,
      showDeleteModal: true
    });
  };

  hideDeleteModal = () => {
    this.setState({
      workTimeIndexToDelete: null,
      showDeleteModal: false
    });
  };

  render() {
    const {
      workingTimes,
      totalTimesValidation,
      isOverlapping,
      test
    } = this.props;
    const { showDeleteModal } = this.state;
    const workingTimeList = workingTimes.map(i => ({
      start: i.workingFrom,
      end: i.workingTo,
      date: i.date
    }));
    return (
      <React.Fragment>
        <Modal
          size="mini"
          open={showDeleteModal}
          onClose={this.hideDeleteModal}
        >
          <Modal.Header>Delete working time</Modal.Header>
          <Modal.Content>
            <p>Are you sure you want to delete this working time?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.hideDeleteModal}>No</Button>
            <Button positive onClick={this.handleDeleteWorkingTime}>
              Yes
            </Button>
          </Modal.Actions>
        </Modal>
        {workingTimes.length > 1 && <Header as="h3">Working time</Header>}
        {workingTimes.map((workingTime, index) => {
          const emptyHoursValue = ":00";
          const { workingFrom, workingTo } = workingTime;
          const workingTimeValidation =
            workingFrom === emptyHoursValue ||
            workingTo === emptyHoursValue ||
            workingFrom === workingTo;

          return (
            <React.Fragment
              key={`workingTime${
                workingTime.id ? workingTime.id : workingTime._id
              }`}
            >
              <Grid.Row className={`${styles.sprayField} ${styles.dateRow}`}>
                <Grid.Column
                  className={`${styles.sprayField} ${styles.required}`}
                >
                  <Form.Field required className="sprayField">
                    <label className="title">
                      Working time
                      {workingTimes.length > 1 ? ` ${index + 1}` : ""}
                    </label>
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              {workingTimeValidation && (
                <div className={styles.errorMessage}>
                  <Message compact={false} size="tiny" color="red">
                    Please, set working time
                  </Message>
                </div>
              )}
              <WorkingTime
                workingTime={workingTime}
                showDeleteButton={workingTimes.length > 1}
                onDelete={() => this.deleteWorkingTimeItem(index)}
                onChange={updatedWorkingTime =>
                  this.updateWorkingTime(index, updatedWorkingTime)
                }
                label="Working time"
                showLabel={index === 0}
                validation={
                  totalTimesValidation && totalTimesValidation.length > index
                    ? totalTimesValidation[index]
                    : true
                }
              />
            </React.Fragment>
          );
        })}
        <div className={styles.plusIcon}>
          <Button
            className={`${styles.plusIcon} transparent`}
            icon
            onClick={() => {
              this.addWorkingTime();
            }}
          >
            <Icon name="plus circle" size="large" />
          </Button>
        </div>
        {workingTimes.length > 1 && (
          <Grid.Row>
            <Grid.Column>
              <Message color="blue">
                <Icon name="warning" /> Total quantity of hours:{" "}
                <span className={styles.totalWorkingTime}>
                  {calcTotalWorkingTimes(workingTimes).total} hours
                </span>
              </Message>
            </Grid.Column>
          </Grid.Row>
        )}
        {isOverlapping(workingTimeList) && (
          <Grid.Row>
            <Grid.Column>
              <Message color="red">
                <Icon name="warning" />
                <span className={styles.totalWorkingTime}>
                  Date range must not overlap
                </span>
              </Message>
            </Grid.Column>
          </Grid.Row>
        )}
      </React.Fragment>
    );
  }
}

WorkingTimes.propTypes = {
  workingTimes: PropTypes.array,
  actions: PropTypes.object,
  totalTimesValidation: PropTypes.bool
};

const mapStateToProps = ({ sprayDiary }) => ({
  workingTimes:
    sprayDiary.workingTimes.length !== 0
      ? sprayDiary.workingTimes
      : [createDefaultWorkingTime()],
  totalTimesValidation: sprayDiary.validations.workingTimes
    ? null
    : validateWorkingTimes(sprayDiary.workingTimes)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setWorkingTimes
    },
    dispatch
  )
});
export default connect(mapStateToProps, mapDispatchToProps)(WorkingTimes);
