import React, { useCallback, useEffect, useState } from "react";
import { get } from "lodash";
import { Modal, Button, Confirm, Checkbox } from "semantic-ui-react";
import styles from "./PrindAndExportModal.module.css";

const PrindAndExportModal = ({
  open,
  printButton,
  setOpen,
  printColumns,
  printData,
  excel,
  handleExcelExport,
  sectionModal,
  numberofParent = 4
}) => {
  const [allChecked, setAllChecked] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);

  const checkHandler = item => {
    selectedItem.includes(item.name)
      ? setSelectedItem(selectedItem.filter(i => i != item.name))
      : setSelectedItem([...selectedItem, item.name]);
  };
  const handleSelectAll = e => {
    setAllChecked(!allChecked);
    setSelectedItem(printColumns && printColumns.map(item => item.name));
    allChecked && setSelectedItem([]);
  };
  return (
    <Modal
      className={styles.modalPrintAndExport}
      open={open}
      closeOnDimmerClick={false}
      closeOnEscape={true}
      size="tiny"
    >
      <Modal.Header className={styles.modalHeader}>
        Choose the {excel ? "excel" : "print"} options
      </Modal.Header>
      <>
        <div className={styles.allButton}>
          <Checkbox
            className={styles.reportsCheckBox}
            label="All"
            onChange={() => handleSelectAll()}
          />
        </div>
        {sectionModal ? (
          <>
            <Modal.Content className={styles.contentWithSectionModal}>
              <span className={styles.modalHeader}>Parent report</span>
              {printColumns &&
                printColumns.slice(0, numberofParent).map((item, index) => (
                  <div className={styles.checkboxWrapper}>
                    <Checkbox
                      className={styles.reportsCheckBox}
                      checked={selectedItem.includes(item.name)}
                      value={item.name}
                      label={item.name}
                      onChange={() => checkHandler(item)}
                    />
                  </div>
                ))}
            </Modal.Content>
            <Modal.Content className={styles.childrenContentWithSectionModal}>
              <span className={styles.modalHeader}>Child report</span>
              {printColumns &&
                printColumns
                  .slice(-(printColumns.length - numberofParent))
                  .map((item, index) => (
                    <div className={styles.checkboxWrapper}>
                      <Checkbox
                        className={styles.reportsCheckBox}
                        checked={selectedItem.includes(item.name)}
                        value={item.name}
                        label={item.name}
                        onChange={() => checkHandler(item)}
                      />
                    </div>
                  ))}
            </Modal.Content>
          </>
        ) : (
          <Modal.Content>
            {printColumns &&
              printColumns.map((item, index) => (
                <div className={styles.checkboxWrapper}>
                  <Checkbox
                    className={styles.reportsCheckBox}
                    checked={selectedItem.includes(item.name)}
                    value={item.name}
                    label={item.name}
                    onChange={() => checkHandler(item)}
                  />
                </div>
              ))}
          </Modal.Content>
        )}
        <Modal.Actions className={styles.modalAction}>
          <Button
            className={styles.reportsModalCancelButton}
            content="Cancel"
            onClick={() => {
              setAllChecked(false);
              setSelectedItem([]);
              setOpen(false);
            }}
          />
          <Button
            disabled={selectedItem.length < 1}
            className={
              selectedItem.length < 1
                ? styles.reportsModalDisabledButton
                : styles.reportsModalButton
            }
            content={excel ? "Export" : "Print"}
            onClick={() => {
              setAllChecked(false);
              setOpen(false);
              excel
                ? handleExcelExport(
                    printColumns
                      .filter(item => selectedItem.some(i => i === item.name))
                      .map(item => item.name)
                  )
                : printButton(selectedItem);
              setTimeout(() => {
                setSelectedItem([]);
              }, 1000);
            }}
          />
        </Modal.Actions>
      </>
    </Modal>
  );
};

export default PrindAndExportModal;
