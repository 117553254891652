import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Mixpanel } from '../../tools/mixpanel/Mixpanel';
import { MixpanelEvents } from '../../tools/mixpanel/MixpanelEvents';
const RoutesPageHolder = props => {
  const { routesPages, route } = props;
  useEffect(() => {
    Mixpanel.track(MixpanelEvents.SCREEN_APPEAR, route);
  }, [route]);

  const Component = routesPages[route.component];
  return <Component {...props} />;
};

RoutesPageHolder.propTypes = {
  routesPages: PropTypes.object,
  route: PropTypes.object,
};

export default RoutesPageHolder;
