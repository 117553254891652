import React, { Component } from "react";
import PropTypes from "prop-types";
import Alert from "../Alert";
import Loader from "../Loader";
import styles from "./ImageInput.module.css";

const allowedTypes = ["image/jpeg", "image/gif", "image/png", "image/x-eps"];

class ImageInput extends Component {
  state = {
    imagePath: "",
    showError: false,
    errorMessage: ""
  };

  componentDidMount() {
    this.setState({
      imagePath: this.props.imagePath ? this.props.imagePath : ""
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.imagePath !== this.props.imagePath)
      this.setState({
        imagePath: this.props.imagePath ? this.props.imagePath : ""
      });
  }
  clearImage = () => {
    this.setState({
      imagePath: ""
    });
  };

  dataURLtoFile = (dataUrl, filename) => {
    const arr = dataUrl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  resizeImage = (image, maxWidth, maxHeight, quality) => {
    let canvas = document.createElement("canvas");
    let width = image.width;
    let height = image.height;

    if (width > height) {
      if (width > maxWidth) {
        height = Math.round((height * maxWidth) / width);
        width = maxWidth;
      }
    } else {
      if (height > maxHeight) {
        width = Math.round((width * maxHeight) / height);
        height = maxHeight;
      }
    }

    canvas.width = width;
    canvas.height = height;

    let ctx = canvas.getContext("2d");
    ctx.drawImage(image, 0, 0, width, height);

    return canvas.toDataURL("image/jpeg", quality);
  };

  resize = (file, maxWidth, maxHeight, fn) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const image = new Image();
      image.src = reader.result;
      image.onload = () => {
        fn(this.resizeImage(image, maxWidth, maxHeight, 0.8));
      };
    };
  };

  fileInputRef = React.createRef();

  closeAlert = () => {
    this.setState({
      showError: false,
      errorMessage: ""
    });
  };

  fileChange = e => {
    let file = e.target.files[0];

    if (!file) {
      return;
    }

    if (!allowedTypes.includes(file.type)) {
      this.setState({
        showError: true,
        errorMessage: "The file type is not allowed!"
      });
      return;
    }

    const { maxWidth, maxHeight } = this.props;

    this.resize(file, maxWidth, maxHeight, resizedDataUrl => {
      this.setState({
        imagePath: resizedDataUrl
      });
      const profilePicture = this.dataURLtoFile(
        resizedDataUrl,
        "profile-image.jpg"
      );
      this.props.onChange(profilePicture);
    });
  };

  onImageClick = event => {
    event.preventDefault();
    event.stopPropagation();
    const { isLoading } = this.props;
    if (isLoading) {
      return;
    }
    this.fileInputRef.current.click();
  };

  render() {
    const { showError, errorMessage, imagePath } = this.state;
    const { isLoading } = this.props;

    return (
      <div className={styles.avatarWrapper}>
        <Alert
          open={showError}
          size="mini"
          onClose={this.closeAlert}
          mainContent={<p>{errorMessage}</p>}
        />
        <div
          className={`cursor-pointer ${styles.avatarHolder}`}
          style={
            imagePath
              ? { backgroundImage: `url(${imagePath})` }
              : { background: this.props.roleColor || "#EBF6E9" }
          }
          onClick={this.onImageClick}
        >
          {!imagePath && (
            <span>
              {this.props.nameInitials ? this.props.nameInitials : null}
            </span>
          )}
        </div>
        {isLoading && <Loader absolute />}
        <input
          ref={this.fileInputRef}
          type="file"
          hidden
          onChange={this.fileChange}
          accept={allowedTypes.join(",")}
        />
      </div>
    );
  }
}

ImageInput.propTypes = {
  maxWidth: PropTypes.number,
  maxHeight: PropTypes.number,
  isLoading: PropTypes.bool,
  imagePath: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

ImageInput.defaultProps = {
  maxWidth: 600,
  maxHeight: 600
};

export default ImageInput;
