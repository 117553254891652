import React from "react";
import PropTypes from "prop-types";
import QRCode from "qrcode.react";

import styles from "./CustomEmployeesBarcodesPrintLayout.module.css";
import { generateBarcodeSeries } from "../../../utils/employeesBarcodes";

const CustomEmployeesBarcodesPrintLayout = ({
  employeeBarcodes,
  itemsInPage,
  className,
  pageStyle
}) => {
  const barcodes = generateBarcodeSeries(
    employeeBarcodes.employee,
    employeeBarcodes.firstBarcode,
    employeeBarcodes.lastBarcode
  );

  const itemsInPageRange = [...Array(itemsInPage).keys()];

  return (
    <div className={`${styles.block} ${className} block`}>
      <style>{`${pageStyle} html,body {margin: 0px;}`}</style>
      <div className={`${styles.page} page`}>
        {Array.from(Array(barcodes.length).keys())
          .filter(i => i % itemsInPage === 0)
          .map(pageOffset => (
            <div
              key={`page_${pageOffset}`}
              className={`${styles.pageBreak} pageBreak`}
            >
              {itemsInPageRange.map(itemOffset => {
                const i = pageOffset + itemOffset;
                const barcode = barcodes[i];
                return (
                  i < barcodes.length && (
                    <div
                      key={barcode.barcode}
                      className={`${styles.barcodeHolder} barcodeHolder`}
                    >
                      <div
                        key={barcode.barcode}
                        className={`${styles.barcodeDataHolder} barcodeDataHolder`}
                      >
                        <QRCode
                          renderAs="svg"
                          className={`${styles.barcode} barcode`}
                          value={barcode.barcode}
                        />
                        <div className={`${styles.textHolder} textHolder`}>
                          <div className={`${styles.text} text`}>
                            {barcode.numberPart}
                          </div>
                          <br />
                          <div className={`${styles.text} text`}>
                            {barcode.employeePart}
                          </div>
                          <br />
                          <div className={`${styles.text} text`}>
                            {barcode.employee.firstName}
                            {barcode.employee.lastName &&
                              ` ${barcode.employee.lastName[0]}`}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                );
              })}
            </div>
          ))}
      </div>
    </div>
  );
};

CustomEmployeesBarcodesPrintLayout.propTypes = {
  employeeBarcodes: PropTypes.array.isRequired,
  itemsInPage: PropTypes.number,
  className: PropTypes.any,
  pageStyle: PropTypes.any
};

CustomEmployeesBarcodesPrintLayout.defaultProps = {};

export default CustomEmployeesBarcodesPrintLayout;
