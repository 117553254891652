import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { Grid, Header, Segment, Sidebar } from "semantic-ui-react";
import PropTypes from "prop-types";
import { get } from "lodash";
import classNames from "classnames";
import Table from "../Table";
import styles from "./TablePageHolder.module.css";
import { ActionsColumn } from "./components/ActionsColumn";

const TablePageHolder = ({
  defaultDate,
  dateType,
  withoutSearch,
  withPagination,
  actionsButtons,
  numberofParent,
  backButton,
  breadcrumbSections,
  className,
  defaultSort,
  firstColumnFixed,
  getData,
  pendingMovementsLength,
  isFetching,
  location,
  mainButton,
  onRowClick,
  open,
  setOpen,
  setFilters,
  printColumns,
  printData,
  excel,
  handleExcelExport,
  printButton,
  withoutSort,
  onSidebarHidden,
  pageTitle,
  rowId,
  route,
  selectedItems,
  setSelectedItems,
  sidebarComponent,
  sidebarShown,
  tableColumns,
  totalPages,
  totalElements,
  Can,
  withBorder,
  accessName,
  onUpdateModalOpen,
  onArchiveItem,
  isArchivedView,
  dataRefetch,
  sortingDifferenceNames,
  hasPermissions,
  wideTableClass,
  openTable,
  tableData,
  withSelection = true,
  withHeader = false,
  singleLine,
  fixed,
  pendingMovements,
  withTable,
  tableType,
  withMoreButton,
  sectionModal = false
}) => {
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const renderActionsColumn = ({ value, row }) => {
    return (
      <ActionsColumn
        value={value}
        row={row}
        hasPermissions={hasPermissions}
        isArchivedView={isArchivedView}
        accessName={accessName}
        onUpdateModalOpen={onUpdateModalOpen}
        onArchiveItem={onArchiveItem}
        Can={Can}
      />
    );
  };

  const extendColumns = () => {
    const actionsIndex = tableColumns.findIndex(
      column => column.id === "actions"
    );
    if (actionsIndex >= 0) {
      tableColumns[actionsIndex].Cell = renderActionsColumn;
    }
    return tableColumns;
  };

  useEffect(() => {
    setSidebarVisible(sidebarShown);
  }, [sidebarShown]);

  return (
    <Sidebar.Pushable className={classNames(styles.root, className)}>
      {React.isValidElement(sidebarComponent) && (
        <Sidebar
          animation="overlay"
          direction="right"
          visible={sidebarVisible}
          onHide={e => {
            if (
              get(e, "target.closest") &&
              (e.target.closest(".modals") || e.target.closest(".dimmer"))
            ) {
              return;
            }
            setSidebarVisible(false);
          }}
          onHidden={onSidebarHidden}
          className={styles.sidebarWrapper}
        >
          <div className={styles.sidebarHolder}>
            {React.cloneElement(sidebarComponent, {
              onClose: () => setSidebarVisible(false)
            })}
          </div>
        </Sidebar>
      )}
      <Layout
        route={route}
        location={location}
        classForMain={styles.mainHolder}
      >
        <Sidebar.Pusher className={styles.pageWrapper}>
          {breadcrumbSections && <div className={styles.breadcrumb} />}
          <Grid>
            <Grid.Row columns={2} verticalAlign="middle">
              {backButton && (
                <div className={styles.actionsContainer}>{backButton}</div>
              )}
              <Grid.Column>
                <Header as="h1" className={styles.pageTitle}>
                  {pageTitle}
                </Header>
              </Grid.Column>
              {mainButton && (
                <Grid.Column textAlign="right">{mainButton}</Grid.Column>
              )}
            </Grid.Row>
          </Grid>
          <Segment className={styles.tableWrapper}>
            <Table
              withTable={withTable}
              numberofParent={numberofParent}
              withMoreButton={withMoreButton}
              tableType={tableType}
              actionButtons={actionsButtons}
              pendingMovementsLength={pendingMovementsLength}
              backButton={backButton}
              withoutSort={withoutSort}
              data={tableData.content || tableData}
              defaultSort={defaultSort}
              firstColumnFixed={firstColumnFixed}
              columns={extendColumns() || tableColumns}
              getData={getData}
              loading={isFetching}
              defaultDate={defaultDate}
              dateType={dateType}
              selectedItems={selectedItems}
              withHeader={withHeader}
              open={open}
              setOpen={setOpen}
              setFilters={setFilters}
              printColumns={printColumns}
              setSelectedItems={setSelectedItems}
              printData={printData}
              excel={excel}
              handleExcelExport={handleExcelExport}
              actions
              rowId={rowId}
              withBorder={withBorder}
              totalElements={tableData.totalElements || totalElements}
              pageCount={tableData.totalPages || totalPages}
              pageSize={tableData.size}
              currentPage={tableData.number}
              onRowClick={onRowClick}
              containerClassName={styles.tableContainer}
              openTable={openTable}
              printButton={printButton}
              withSelection={withSelection}
              withoutSearch={withoutSearch}
              withPagination={withPagination}
              withSearch
              dataRefetch={dataRefetch}
              wideTableClass={wideTableClass}
              sortingDifferenceNames={sortingDifferenceNames}
              singleLine={singleLine}
              fixed={fixed}
              pendingMovements={pendingMovements}
              sectionModal={sectionModal}
            />
          </Segment>
        </Sidebar.Pusher>
      </Layout>
    </Sidebar.Pushable>
  );
};

TablePageHolder.propTypes = {
  breadcrumbSections: PropTypes.arrayOf(
    PropTypes.shape({ key: PropTypes.string, active: PropTypes.bool })
  )
};

TablePageHolder.defaultProps = {
  breadcrumbSections: null
};

export default TablePageHolder;
