import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, Form, Grid, TextArea } from "semantic-ui-react";
import DatePicker from "../../components/DatePicker";
import { submitReport } from "../../actions/Reports/reports";
import Loader from "../../components/Loader";

const reportValidationSchema = Yup.object()
  .shape({
    comments: Yup.string().required(),
    typeFullName: Yup.string().required(),
    from: Yup.date().required(),
    to: Yup.date().required()
  })
  .required();

const ReportForm = ({ typeFullName }) => {
  const dispatch = useDispatch();
  const { isSubmitting } = useSelector(state => state.reports);
  const [isFormSubmitted, setSubmitted] = useState(false);

  const submitForm = useCallback(values => {
    dispatch(
      submitReport({
        ...values,
        from: values.from + "T00:00",
        to: values.to + "T00:00"
      })
    );
    setSubmitted(true);
  }, []);

  const formik = useFormik({
    initialValues: {
      comments: "",
      from: null,
      to: null,
      typeFullName
    },
    validationSchema: reportValidationSchema,
    onSubmit: submitForm
  });

  if (isSubmitting)
    return (
      <div style={{ height: 280 }}>
        <Loader />
      </div>
    );

  if (isFormSubmitted)
    return (
      <span>
        Thank you for submitting your report request, we will email you this
        report shortly.
      </span>
    );

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Grid>
        <Grid.Row>
          <Grid.Column
            mobile={16}
            computer={8}
            largeScreen={8}
            tablet={8}
            widescreen={8}
          >
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Form.Field required>
                    <label>From</label>
                    <DatePicker
                      name={"from"}
                      value={formik.values.from}
                      onChange={date =>
                        formik.handleChange({
                          target: { name: "from", value: date }
                        })
                      }
                      size="small"
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column>
                  <Form.Field required>
                    <label>To</label>
                    <DatePicker
                      name={"to"}
                      value={formik.values.to}
                      onChange={date =>
                        formik.handleChange({
                          target: { name: "to", value: date }
                        })
                      }
                      size="small"
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Form.Field required>
                    <label>Additional Requests</label>
                    <TextArea
                      style={{ width: "100%" }}
                      size="large"
                      value={formik.values.comments}
                      placeholder="Type additional request..."
                      name="comments"
                      onChange={formik.handleChange}
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column floated="left" />
                <Grid.Column width={4} floated="right" textAlign="right">
                  <Button
                    fluid
                    primary
                    disabled={!formik.dirty || !formik.isValid}
                  >
                    Submit
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

ReportForm.propTypes = {
  typeFullName: PropTypes.string.isRequired
};

export default ReportForm;
