import filterTypes from "components/Table/filterTypes";
import {
  chemicalTypeOptionsSelector,
  chemicalsOptionsFilterSelector,
  productsOptionsFilterSelector,
  matterOptionsSelector
} from "selectors/chemicals";
import { get } from "lodash";
import { fetchFilterValues } from "../../../../../../../actions/Chemicals";
import { uppercaseFirstLetter } from "../../../../../../../utils/string";

export const filtersOptions = [
  {
    filterId: "product",
    title: "Product",
    type: filterTypes.MultiSelect,
    selector: productsOptionsFilterSelector
  },
  {
    filterId: "type",
    title: "Type",
    accessor: ({ type }) => get(type, "type", ""),
    type: filterTypes.MultiSelect,
    selector: chemicalTypeOptionsSelector,
    loaderAction: fetchFilterValues
  },
  {
    filterId: "matter",
    title: "Matter",
    accessor: ({ matter }) => uppercaseFirstLetter(matter),
    type: filterTypes.SingleSelect,
    selector: matterOptionsSelector
  }
];
