import { actionType } from '../../constants/Notifications';

const initialState = {
  isSending: false,
  success: false,
  showMessage: false,
  error: '',
};

export default (state = initialState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case actionType.SEND_NOTIFICATION_START:
      return {
        ...state,
        isSending: true,
        showMessage: false,
      };
    case actionType.SEND_NOTIFICATION:
      return {
        ...state,
        isSending: false,
        success: action.payload,
        showMessage: true,
      };
    case actionType.SEND_NOTIFICATION_FAIL:
      return {
        ...state,
        success: false,
        showMessage: true,
        isSending: false,
        error: action.payload,
      };
    case actionType.SEND_NOTIFICATION_CLOSE_MESSAGE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
