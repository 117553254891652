export const sortingDifferenceNames = [
  {
    columnName: "groupIds",
    sortName: "employeeGroup"
  },
  {
    columnName: "types",
    sortName: "type"
  },
  {
    columnName: "contractorIds",
    sortName: "contractor"
  }
];
