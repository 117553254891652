import { axiosProxy } from "../../utils/axiosProxy";
import { actionType } from "../../constants/EmployeeGroups";

const url = "/employee_groups";

export const fetchEmployeeGroups = ({
  page,
  size,
  search,
  sort = ["id,asc"],
  unpaged = false
}) => async dispatch => {
  try {
    dispatch({ type: actionType.FETCH_EMPLOYEE_GROUPS_START });

    const params = new URLSearchParams();
    if (unpaged) {
      params.append("unpaged", true);
    } else {
      params.append("page", page);
      params.append("size", size);
    }

    if (search) params.append("search", search);

    (sort || ["id,asc"]).forEach(field => params.append("sort", field));

    const res = await axiosProxy({ method: "GET", params, url });

    await dispatch({
      type: actionType.FETCH_EMPLOYEE_GROUPS,
      payload: res.data
    });
  } catch (error) {
    dispatch({ type: actionType.FETCH_EMPLOYEE_GROUPS_ERROR, payload: error });
  }
};
export const fetchEmployeeGroupsList = () => async dispatch => {
  try {
    dispatch({ type: actionType.FETCH_EMPLOYEE_GROUPS_LIST_START });

    const res = await axiosProxy({
      method: "GET",
      params: {
        unpaged: true
      },
      url
    });

    await dispatch({
      type: actionType.FETCH_EMPLOYEE_GROUPS_LIST,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: actionType.FETCH_EMPLOYEE_GROUPS_LIST_ERROR,
      payload: error
    });
  }
};

export const clearError = () => dispatch =>
  dispatch({ type: actionType.CLEAR_EMPLOYEE_GROUPS_ERROR });
