import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import filterTypes from "components/Table/filterTypes";
import { connect, useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { Icon, Button } from "semantic-ui-react";
import TablePageHolder from "components/TablePageHolder/TablePageHolder";
import Numeric from "components/Numeric";
import {
  selectExpotStructuredData,
  selectTableStructuredData,
  formaterData
} from "selectors/reports/harvest/pickTopack/harvestByArea";
import { boxUnitOptionsSelector } from "../../../../../selectors/boxUnits";
import { farmsOptionsFilterSelector } from "../../../../../selectors/farms";
import {
  blockSelector,
  blocListSelector,
  cropsOptionSelector,
  patchSelector,
  patchListSelector,
  varietiesOptionSelector
} from "selectors/areas";
import {
  fetchHarvestByAreaTableData,
  fetchHarvestByAreaExportData
} from "actions/Reports/harvest/pickToPack/harvestByArea";
import { fetchBoxUnitsList } from "actions/BoxUnits/boxUnits";
import { fetchCropsList } from "actions/Farms/crops";
import { Chip } from "../../components/Chip";
import _, { get, pickBy, pick, isEmpty } from "lodash";
import PrintReports from "./PrintHarvestTable/PrintReports";
import DateRangeSelect from "components/Table/components/Filters/DateRangeSelect";
import { excelExport } from "utils/excelExport";
import { history } from "../../../../../store";
import styles from "./HarvestByArea.module.css";
import { seasonsOptionsSelector } from "selectors/seasonsSelector";
const headerColumn = {
  areaName: "Area",
  crops: "Crop",
  varieties: "Variety",
  size: "Size (Ha)",
  binUnits: "Box units",
  binUnitsNumber: "No.units",
  weight: "Weight (T)",
  tonnePerHa: "Tonne/Ha (T/Ha)",
  cost: "Cost ($)",
  costPerTonne: "Cost/Tonne ($/T)",
  costPerBin: "Cost/Unit ($/Unit)",
  avgKgPerTree: "Avg. Kg/Tree",
  firstBarcode: "First Harvest",
  lastBarcode: "Last Harvest",
  withholdingPeriod: "Last WHP"
};
const PickToPackHarvestByArea = ({ location, route, isFetching }) => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({
    cropIds: [],
    varietyIds: [],
    blockIds: [],
    patchIds: [],
    harvestUnitIds: [],
    seasons: [],
    from: "",
    to: ""
  });
  const farmsList = useSelector(farmsOptionsFilterSelector);
  const blockList = useSelector(blocListSelector);
  const patchList = useSelector(patchListSelector);
  const varietiesList = useSelector(state =>
    varietiesOptionSelector(filters)(state)
  );
  const cropsList = useSelector(cropsOptionSelector);
  const binUnitsList = useSelector(boxUnitOptionsSelector);
  const { content } = useSelector(selectExpotStructuredData);
  const [selectItem, setSelectItem] = useState();
  const [printExpandedData, setPrintExpandedData] = useState(false);
  const [open, setOpen] = useState(false);
  const [filtersList, setFiltersList] = useState([]);

  const componentRef = useRef();
  const [expandedRow, setExpandedRow] = useState(false);
  const [excel, setExcel] = useState();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });
  const areasList = useSelector(state => state.areas.list.content);

  const getData = useCallback(
    params => {
      setFiltersList(params);
      dispatch(fetchHarvestByAreaTableData(params));
    },
    [fetchHarvestByAreaTableData]
  );

  const columns = [
    {
      name: "Area",
      id: 1
    },
    {
      name: "Size (Ha)",
      id: 2
    },
    {
      name: "Crop",
      id: 3
    },
    {
      name: "Variety",
      id: 4
    },
    {
      name: "Box units",
      id: 5
    },
    {
      name: "No.units",
      id: 6
    },
    {
      name: "Weight (T)",
      id: 7
    },
    {
      name: "Tonne/Ha (T/Ha)",
      id: 8
    },
    {
      name: "Cost ($)",
      id: 9
    },
    {
      name: "Cost/Tonne ($/T)",
      id: 10
    },
    {
      name: "Cost/Unit ($/Unit)",
      id: 11
    },
    {
      name: "Avg. Kg/Tree",
      id: 12
    },
    {
      name: "First Harvest",
      id: 13
    },
    {
      name: "Last Harvest",
      id: 14
    },
    {
      name: "Last WHP",
      id: 15
    }
  ];

  const fetchPrintoutAllInformation = () => {
    return dispatch(fetchHarvestByAreaExportData(filtersList));
  };

  const fetchExportAllInformation = () => {
    return dispatch(fetchHarvestByAreaExportData(filtersList));
  };

  const handleExcelExport = useCallback(
    async selectedItem => {
      const data = await dispatch(fetchHarvestByAreaExportData(filtersList));

      const { content } = formaterData(data, areasList);

      if (content.length) {
        const headerNewData = pickBy(headerColumn, function(value, key) {
          return selectedItem && selectedItem.find(item => value === item);
        });

        const newContent = content
          .reduce((prev, block) => {
            const filtredBlock = !block.subRows && block;
            return [
              ...prev,
              pick(filtredBlock, Object.keys(headerNewData)),
              ...(block.subRows
                ? block.subRows.reduce(
                    (prev, { subRows, isSubRows, ...rest }) => {
                      const temp = [...prev];
                      if (isSubRows) {
                        !subRows &&
                          temp.push({
                            ...pick(rest, Object.keys(headerNewData))
                          });
                      }
                      if (subRows) {
                        subRows.map((i, index) => {
                          const newRest = {
                            ...rest,
                            areaName: rest.areaName,
                            crops: rest.crops,
                            varieties: rest.varieties,
                            binUnits: i.binUnits,
                            binUnitsNumber: i.binUnitsNumber,
                            avgKgPerTree: "",
                            size: rest.size
                          };
                          return temp.push({
                            ...pick(i, Object.keys(headerNewData)),
                            ...pick(newRest, Object.keys(headerNewData))
                          });
                        });
                      }

                      if (!isSubRows) {
                        temp.push({
                          ...pick(block, Object.keys(headerNewData)),

                          ...pick(rest, Object.keys(headerNewData))
                        });
                      }
                      return temp;
                    },
                    []
                  )
                : [])
            ];
          }, [])
          .filter(item => !isEmpty(item));
        const expanded = newContent.map(
          ({
            areaName,
            crops,
            varieties,
            size,
            binUnits,
            binUnitsNumber,
            weight,
            tonnePerHa,
            cost,
            costPerTonne,
            costPerBin,
            avgKgPerTree,
            firstBarcode,
            lastBarcode,
            withholdingPeriod
          }) => ({
            ...(areaName && { areaName }),
            ...(size && {
              size: isFinite(size) ? size.toFixed(size > 1000 ? 0 : 2) : "-"
            }),
            ...(crops &&
              crops.length && {
                crops: !!crops.length
                  ? crops.map(item => item).join(",  ")
                  : "-"
              }),
            ...(varieties &&
              varieties.length && {
                varieties:
                  varieties && !!varieties.length && varieties.length >= 1
                    ? varieties.map(item => item).join(",  ")
                    : "-"
              }),
            ...(binUnits &&
              binUnits.length && {
                binUnits: Array.isArray(binUnits)
                  ? binUnits.length > 1
                    ? `${binUnits.length} Types`
                    : binUnits[0]
                  : binUnits
              }),
            ...(binUnitsNumber && {
              binUnitsNumber: isFinite(binUnitsNumber) ? binUnitsNumber : "-"
            }),
            ...(weight && {
              weight: isFinite(weight)
                ? weight.toFixed(weight > 1000 ? 0 : 2)
                : "-"
            }),
            ...(tonnePerHa && {
              tonnePerHa: isFinite(tonnePerHa)
                ? tonnePerHa.toFixed(tonnePerHa > 1000 ? 0 : 2)
                : "-"
            }),
            ...(cost && {
              cost: isFinite(cost) ? cost.toFixed(cost > 1000 ? 0 : 2) : "-"
            }),
            ...(costPerTonne && {
              costPerTonne: isFinite(costPerTonne)
                ? costPerTonne.toFixed(costPerTonne > 1000 ? 0 : 2)
                : "-"
            }),
            ...(costPerBin && {
              costPerBin: isFinite(costPerBin)
                ? costPerBin.toFixed(costPerBin > 1000 ? 0 : 2)
                : "-"
            }),
            ...((avgKgPerTree == null ||
              avgKgPerTree == undefined ||
              avgKgPerTree) && {
              avgKgPerTree: Number.isFinite(avgKgPerTree)
                ? avgKgPerTree.toFixed(avgKgPerTree > 1000 ? 0 : 2)
                : ""
            }),
            ...(firstBarcode && {
              firstBarcode: firstBarcode
                ? moment(firstBarcode).format("DD/MM/YYYY HH:mm")
                : "-"
            }),
            ...(lastBarcode && {
              lastBarcode: lastBarcode
                ? moment(lastBarcode).format("DD/MM/YYYY HH:mm")
                : "-"
            }),
            ...(withholdingPeriod
              ? {
                  withholdingPeriod: withholdingPeriod
                    ? moment(withholdingPeriod).format("DD/MM/YYYY")
                    : "-"
                }
              : {
                  withholdingPeriod: ""
                })
          })
        );
        const filename = "harvest_by_area";
        excelExport(expanded, selectedItem, filename);
      }
    },
    [areasList, filtersList]
  );
  const tableData = useSelector(selectTableStructuredData);
  const printData = useSelector(selectExpotStructuredData);
  useEffect(() => {
    return setExpandedRow(false);
  }, [tableData.number]);
  const columnsConfig = useMemo(
    () => [
      {
        accessor: "areaName",
        id: "expander",
        Header: "Area",
        filterId: "date",
        withSort: false,
        Footer: <div className={styles.totalRow}>Total</div>,
        Cell: ({ value, row }) => {
          return row.canExpand ? (
            <span
              {...row.getToggleRowExpandedProps({
                style: {
                  paddingLeft: `${row.depth * 1.5}rem`
                }
              })}
            >
              <Icon
                onClick={() => setExpandedRow(row.id)}
                name={`angle ${row.isExpanded ? "up" : "down"}`}
              />
              {value}
            </span>
          ) : row.depth == 1 ? (
            <span style={{ paddingLeft: `${3}rem` }}>{value}</span>
          ) : (
            <span style={{ paddingLeft: `${1.5}rem` }}>{value}</span>
          );
        },
        filter: {
          title: "Date",
          type: filterTypes.DateRangeSelect,
          selector: <DateRangeSelect loading={isFetching} />
        },
        minWidth: 280
      },
      {
        Header: "Size (Ha)",
        id: "size",
        filterId: "farmIds",
        withSort: false,
        accessor: "size",
        Cell: ({ value }) => (
          <Numeric
            fractionDigits={value > 1000 ? 0 : 2}
            value={value}
            commaSeparatorOnThousands
            defaultValue="-"
          />
        ),
        filter: {
          title: "Farm",
          type: filterTypes.MultiSelect,
          selector: farmsOptionsFilterSelector
        },
        Footer: info => {
          const total = useMemo(
            () =>
              info.sortedRows.reduce(
                (sum, row) => (row.original.size || 0) + sum,
                0
              ),
            [info.rows]
          );

          return (
            <div className={styles.totalRow}>
              {" "}
              <Numeric
                fractionDigits={total > 1000 ? 0 : 2}
                value={total}
                commaSeparatorOnThousands
                defaultValue="-"
              />
            </div>
          );
        }
      },
      {
        accessor: "crops",
        Header: "Crop",
        id: "crops",
        filterId: "harvestUnitIds",
        withSort: false,
        Cell: ({ value, row }) => {
          return value && value.length !== 0 ? (
            Array.isArray(value) && value.length > 1 ? (
              <Chip items={value} label="Types" />
            ) : (
              value
            )
          ) : (
            "-"
          );
        },

        filter: {
          title: "Box units",
          type: filterTypes.MultiSelect,
          selector: boxUnitOptionsSelector,
          loaderAction: fetchBoxUnitsList
        }
      },
      {
        accessor: "varieties",
        id: "variety",
        filterId: "cropIds",
        withSort: false,
        Cell: ({ value }) =>
          value && value.length !== 0 ? (
            Array.isArray(value) && value.length > 1 ? (
              <Chip items={value} label="Types" />
            ) : (
              value
            )
          ) : (
            "-"
          ),
        Header: "Variety",
        filter: {
          title: "Crop",
          type: filterTypes.MultiSelect,
          selector: cropsOptionSelector,
          loaderAction: fetchCropsList
        }
      },
      {
        accessor: "binUnits",
        id: "binUnits",
        filterId: "varietyIds",
        withSort: false,
        Cell: ({ value }) =>
          value ? (
            Array.isArray(value) && value.length > 1 ? (
              <Chip items={value} label="Types" />
            ) : (
              value
            )
          ) : (
            "-"
          ),
        Header: "Box units",
        filter: {
          title: "Variety",
          type: filterTypes.MultiSelect,
          selector: state => varietiesOptionSelector(filters)(state)
        }
      },
      {
        accessor: "binUnitsNumber",
        id: "noUnits",
        filterId: "blockIds",
        withSort: true,
        type: "number",
        Header: "No. units",
        filter: {
          title: "Block",
          type: filterTypes.MultiSelect,
          selector: state => blockSelector(filters)(state)
        },
        Footer: info => {
          const total = useMemo(
            () =>
              info.sortedRows.reduce(
                (sum, row) => row.original.binUnitsNumber + sum,
                0
              ),
            [info.rows]
          );

          return (
            <div className={styles.totalRow}>
              <Numeric
                fractionDigits={0}
                value={total}
                commaSeparatorOnThousands
                defaultValue="-"
              />
            </div>
          );
        }
      },
      {
        accessor: "weight",
        id: "weight",
        filterId: "patchIds",
        withSort: true,
        type: "number",
        Cell: ({ value }) => (
          <Numeric
            fractionDigits={value > 1000 ? 0 : 2}
            value={value}
            commaSeparatorOnThousands
            defaultValue="-"
          />
        ),
        Header: "Weight (T)",
        filter: {
          title: "Patch",
          type: filterTypes.MultiSelect,
          selector: state => patchSelector(filters)(state)
        },
        Footer: info => {
          const total = useMemo(
            () =>
              info.sortedRows.reduce(
                (sum, row) => row.original.weight + sum,
                0
              ),
            [info.rows]
          );

          return (
            <div className={styles.totalRow}>
              <Numeric
                fractionDigits={total > 1000 ? 0 : 2}
                value={total}
                commaSeparatorOnThousands
                defaultValue="-"
              />
            </div>
          );
        }
      },
      {
        accessor: "tonnePerHa",
        id: "tonnePerHa",
        withSort: true,
        type: "number",
        Cell: ({ value }) =>
          value && isFinite(value) ? (
            <Numeric
              fractionDigits={value > 1000 ? 0 : 2}
              value={value}
              commaSeparatorOnThousands
              defaultValue="-"
            />
          ) : (
            "-"
          ),
        filterId: "seasons",
        filter: {
          title: "Season",
          type: filterTypes.SingleSelect,
          selector: seasonsOptionsSelector
        },
        Header: "Tonne/Ha (T/Ha)",
        Footer: info => {
          const totalSize = info.sortedRows.reduce(
            (sum, row) => row.original.size + sum,
            0
          );
          const totalWeight = info.sortedRows.reduce(
            (sum, row) => row.original.weight + sum,
            0
          );
          const total = useMemo(() => totalWeight / totalSize);

          return (
            <div className={styles.totalRow}>
              <Numeric
                fractionDigits={total > 1000 ? 0 : 2}
                value={total}
                commaSeparatorOnThousands
                defaultValue="-"
              />
            </div>
          );
        }
      },
      {
        withSort: true,
        type: "number",
        id: "cost",
        accessor: "cost",
        Cell: ({ value }) => (
          <Numeric
            fractionDigits={value > 1000 ? 0 : 2}
            value={value}
            commaSeparatorOnThousands
            units="$"
            unitsPosition="left"
            defaultValue="-"
          />
        ),
        Header: "Cost ($)",
        Footer: info => {
          const total = useMemo(
            () =>
              info.sortedRows.reduce((sum, row) => row.original.cost + sum, 0),
            [info.rows]
          );

          return (
            <div className={styles.totalRow}>
              <Numeric
                fractionDigits={total > 1000 ? 0 : 2}
                value={total}
                commaSeparatorOnThousands
                units="$"
                unitsPosition="left"
                defaultValue="-"
              />
            </div>
          );
        }
      },
      {
        accessor: "costPerTonne",
        withSort: true,
        type: "number",
        id: "costPerTonne",
        Cell: ({ value }) => (
          <Numeric
            fractionDigits={value > 1000 ? 0 : 2}
            value={value}
            commaSeparatorOnThousands
            units="$"
            unitsPosition="left"
            defaultValue="-"
          />
        ),
        Header: "Cost/Tonne ($/T)",
        Footer: info => {
          const totalCost = info.sortedRows.reduce(
            (sum, row) => row.original.cost + sum,
            0
          );
          const totalWeight = info.sortedRows.reduce(
            (sum, row) => row.original.weight + sum,
            0
          );
          const total = useMemo(() => totalCost / totalWeight);

          return (
            <div className={styles.totalRow}>
              <Numeric
                fractionDigits={total > 1000 ? 0 : 2}
                value={total}
                commaSeparatorOnThousands
                units="$"
                unitsPosition="left"
                defaultValue="-"
              />
            </div>
          );
        }
      },
      {
        withSort: true,
        type: "number",
        id: "costPerUnit",
        accessor: "costPerBin",
        Cell: ({ value }) => (
          <Numeric
            fractionDigits={value > 1000 ? 0 : 2}
            value={value}
            commaSeparatorOnThousands
            units="$"
            unitsPosition="left"
            defaultValue="-"
          />
        ),
        Header: "Cost/Unit ($/Unit)",
        Footer: info => {
          const totalCost = info.sortedRows.reduce(
            (sum, row) => row.original.cost + sum,
            0
          );
          const totalUnit = info.sortedRows.reduce(
            (sum, row) => row.original.binUnitsNumber + sum,
            0
          );

          const total = useMemo(() => totalCost / totalUnit);
          return (
            <div className={styles.totalRow}>
              <Numeric
                fractionDigits={total > 1000 ? 0 : 2}
                value={total}
                commaSeparatorOnThousands
                units="$"
                unitsPosition="left"
                defaultValue="-"
              />
            </div>
          );
        }
      },
      {
        accessor: "avgKgPerTree",
        withSort: true,
        type: "number",
        id: "weightPerTree",
        Cell: ({ value }) =>
          value && isFinite(value) ? (
            <Numeric
              fractionDigits={value > 1000 ? 0 : 2}
              value={value}
              commaSeparatorOnThousands
              defaultValue="-"
            />
          ) : (
            "-"
          ),
        Header: "Avg.Kg/Tree",
        Footer: info => {
          const totalPlants = info.sortedRows.reduce(
            (sum, row) => row.original.numberOfPlants + sum,
            0
          );
          const totalWeight = info.sortedRows.reduce(
            (sum, row) => row.original.weight + sum,
            0
          );
          const total = useMemo(() => (totalWeight * 1000) / totalPlants);
          return total && isFinite(total) ? (
            <div className={styles.totalRow}>
              <Numeric
                fractionDigits={total > 1000 ? 0 : 2}
                value={total}
                commaSeparatorOnThousands
                defaultValue="-"
              />
            </div>
          ) : (
            "-"
          );
        }
      },
      {
        Header: "First Harvest",
        withSort: false,
        id: "firstBarcode",
        accessor: "firstBarcode",
        Cell: ({ value }) =>
          value ? moment(value).format("DD/MM/YYYY HH:mm") : "-"
      },
      {
        Header: "Last Harvest",
        withSort: false,
        id: "lastBarcode",
        accessor: "lastBarcode",
        Cell: ({ value }) =>
          value ? moment(value).format("DD/MM/YYYY HH:mm") : "-"
      },
      {
        Header: "Last WHP",
        withSort: false,
        id: "withholdingPeriod",
        accessor: "withholdingPeriod",
        Cell: ({ value }) => (
          <span style={{ paddingRight: `${2}rem` }}>
            {value ? moment(value).format("DD/MM/YYYY") : "-"}
          </span>
        )
      }
    ],
    [filters]
  );
  const handleBackClick = () => {
    history.push({
      pathname: "/reports",
      state: {
        activeNode: location.state.reportNode
      }
    });
  };
  const printButton = selectedItem => {
    setSelectItem(selectedItem);
    fetchPrintoutAllInformation().then(() => {
      setPrintExpandedData(true);
      handlePrint();
    });
  };

  return (
    <>
      <div style={{ display: "none" }}>
        <PrintReports
          title="Harvest by area"
          showExpandedData={printExpandedData}
          farmsList={farmsList}
          blockList={blockList}
          patchList={patchList}
          cropsList={cropsList}
          varietiesList={varietiesList}
          binUnitsList={binUnitsList}
          cropIds={filters.cropIds}
          varietyIds={filters.varietyIds}
          blockIds={filters.blockIds}
          patchIds={filters.patchIds}
          farmIds={filters.farmIds}
          binUnitsIds={filters.harvestUnitIds}
          tableData={content}
          selectedItem={selectItem}
          data={printData}
          from={filters.from}
          to={filters.to}
          ref={componentRef}
        />
      </div>
      <TablePageHolder
        backButton={
          <Button
            floated="right"
            className={styles.backIconWrapper}
            onClick={handleBackClick}
          >
            <Icon className={styles.backIcon} name="angle left" />
          </Button>
        }
        actionsButtons={
          <>
            <Button
              size="small"
              floated="right"
              className={styles.printButton}
              onClick={() => {
                return setExcel(true), setOpen(true);
              }}
            >
              <div className={styles.iconWrapper}>
                <Icon name="download" />
                Export
              </div>
            </Button>
            <Button
              size="small"
              floated="right"
              className={styles.printButton}
              onClick={() => {
                return setExcel(false), setOpen(true);
              }}
            >
              <div className={styles.iconWrapper}>
                <Icon name="print" />
                Print
              </div>
            </Button>
          </>
        }
        rowId={expandedRow}
        getData={getData}
        isFetching={tableData.isFetching}
        location={location}
        onRowClick={() => {}}
        open={open}
        setFilters={setFilters}
        printColumns={columns}
        setOpen={setOpen}
        handleExcelExport={handleExcelExport}
        printButton={printButton}
        printData={printData}
        excel={excel}
        pageTitle={route.name}
        route={route}
        tableColumns={columnsConfig}
        withSelection={false}
        withBorder={true}
        tableData={tableData}
        withoutSort={false}
        firstColumnFixed={true}
        singleLine
        fixed={false}
      />
    </>
  );
};

PickToPackHarvestByArea.propTypes = {
  Can: PropTypes.func,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  totalElements: PropTypes.number.isRequired,
  route: PropTypes.object,
  filterValues: PropTypes.object,
  location: PropTypes.object
};

export default connect((state, props) => {
  return {
    filterValues: state.packedBoxes.filters,
    isFetching: state.packedBoxes.data.isFetching,
    error: state.packedBoxes.error
  };
})(PickToPackHarvestByArea);
