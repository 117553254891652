import { createSelector } from "reselect";
import { map } from "lodash";

const task = state => state.sprayDiary;
const chemicals = state => state.chemical;
const sheds = state => state.shed.data;
export const chemicalsList = state => state.chemical.list;
export const areasList = state => state.areas.list;

const chemicalsTypesSelector = createSelector([task], sprayDiary => {
  const {
    chemicalsTypes: { data }
  } = sprayDiary;
  return data
    ? data.map(type => ({ key: type.id, text: type.type, value: type.id }))
    : [];
});

const dilutionRateTypesSelector = createSelector([task], sprayDiary => {
  const { dilutionRateTypes } = sprayDiary;
  return dilutionRateTypes
    ? Object.keys(dilutionRateTypes).map(type => ({
        key: type,
        text: `/${dilutionRateTypes[type]}`,
        value: type
      }))
    : [];
});

const periodUnitsSelector = createSelector([task], sprayDiary => {
  const { periodUnits } = sprayDiary;
  return periodUnits
    ? Object.keys(periodUnits).map(type => ({
        key: type,
        text: periodUnits[type],
        value: type
      }))
    : [];
});

const selectedChemicalsSelector = createSelector([task], sprayDiary => {
  const { selectedChemicals } = sprayDiary;
  return selectedChemicals.filter(chemical => chemical);
});

export const matterOptionsSelector = createSelector(
  [chemicals],
  chemicalsData => {
    const { filters } = chemicalsData;

    return { options: filters.matterOptions };
  }
);

export const trackInventoryOptionsSelector = createSelector(
  [chemicals],
  chemicalsData => {
    const { filters } = chemicalsData;

    return { options: filters.trackInventoryOptions };
  }
);

export const productsOptionsSelector = createSelector(
  [chemicalsList],
  ({ content }) =>
    content
      ? content
          .filter(({ trackInventory }) => trackInventory == true)
          .map(({ id, name, matter }) => ({
            key: id,
            text: name,
            value: id,
            matter
          }))
          .sort((b1, b2) => b1.text.localeCompare(b2.text))
      : []
);

export const productsOptionsFilterSelector = createSelector(
  [productsOptionsSelector],
  productsOptions => ({
    options: productsOptions
      .map(({ text, ...restProperties }) => ({
        ...restProperties,
        label: text
      }))
      .sort(function(a, b) {
        var nameA = a.label?.trim().toLowerCase(),
          nameB = b.label?.trim().toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      })
  })
);

export const activeIngredientOptionssSelector = createSelector(
  [chemicalsList],
  ({ content }) => ({
    options: content
      ? content.map(({ id, name, matter }) => ({
          key: id,
          label: name,
          value: `${id}`,
          matter
        }))
      : []
  })
);

export const thresholdsOptionsSelector = createSelector(
  [chemicalsList, sheds],
  ({ content }) =>
    content
      ? content
          .filter(
            ({ id }) =>
              id ==
              sheds.content.find(({ buckets }) => buckets.find(({ id }) => id))
          )
          .map(({ id, name, matter }) => ({
            key: id,
            text: name,
            value: id,
            matter
          }))
      : []
);
export const chemicalsOptionsSelector = createSelector(
  [chemicalsList],
  ({ content }) =>
    content
      ? content.map(({ id, name, matter }) => ({
          key: id,
          text: name,
          value: id,
          matter
        }))
      : []
);
export const areasOptionsSelector = createSelector([areasList], ({ content }) =>
  content
    ? content.map(({ id, name }) => ({
        key: id,
        text: name
      }))
    : []
);

export const chemicalsOptionsFilterSelector = createSelector(
  [chemicalsOptionsSelector],
  chemicalsOptions => ({
    options: chemicalsOptions.map(({ text, ...restProperties }) => ({
      ...restProperties,
      label: text
    }))
  })
);
export const productTypeSelector = createSelector(
  [chemicalsList],
  ({ content }) =>
    content
      ? content.map(product => ({
          name: product.name,
          matter: product.matter,
          type: product.type?.type,
          activeIngredient: product.properties?.activeIngredient,
          unit: product.unit,
          price: product.price,
          id: product.id
        }))
      : []
);

export const typeOptionsSelector = createSelector(
  [chemicals],
  chemicalsData => {
    const { list } = chemicalsData;
    return {
      options: [
        ...new Map(
          list.content
            .map(item => ({
              value: item.type.id,
              key: item.type.id,
              label: item.type.type
            }))
            .filter((item, i, ar) => item !== false)
            .map(item => [item["key"], item])
        ).values()
      ]
    };
  }
);
export const chemicalTypeOptionsSelector = createSelector(
  [chemicals],
  chemicalsData => {
    const { filters } = chemicalsData;

    return {
      isFetching: filters.isFetching,
      options: filters.chemicalTypeOptions
    };
  }
);

export const unitsSelector = createSelector([chemicals], chemicalsData => {
  const { units } = chemicalsData;
  return units;
});

export const activeIngredientsSelector = createSelector(
  [chemicals],
  chemicalsData => {
    const { activeIngredients } = chemicalsData;
    return {
      ...activeIngredients,
      options: map(activeIngredients.content, value => ({
        value,
        key: value,
        label: value
      }))
    };
  }
);

export const activeItemSelector = createSelector([chemicals], chemicalsData => {
  const { activeItem, data } = chemicalsData;
  if (activeItem.id) {
    return data.content.find(({ id }) => activeItem.id === id);
  }
  return null;
});

export const chemicalNameOptionsSelector = createSelector(
  [chemicals],
  chemicalsData => {
    const { filters } = chemicalsData;

    return {
      isFetching: filters.isFetching,
      options: filters.chemicalTypeOptions
    };
  }
);
export {
  chemicalsTypesSelector,
  dilutionRateTypesSelector,
  periodUnitsSelector,
  selectedChemicalsSelector
};
