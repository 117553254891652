import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Dropdown, Form, Grid } from "semantic-ui-react";
import {
  selectEmployees,
  selectSingleEmployee
} from "../../../../actions/SprayDiary/employee";
import { onlineSelector } from "../../../../selectors/common";
import { get } from "lodash";

class EmployeeList extends Component {
  state = {};

  get transformedEmployee() {
    const {
      showArchived,
      selectedEmployees,
      multiple,
      employee: { content: employees }
    } = this.props;
    const selectedEmployeesIDS = multiple
      ? selectedEmployees.map(item => get(item, "employee.id"))
      : [selectedEmployees.id];

    return employees
      .filter(e =>
        showArchived
          ? e.archived
            ? selectedEmployeesIDS.includes(e.id)
            : true
          : e.archived === false
      )
      .map(content => ({
        key: content.id,
        text: `${content.firstName}  ${content.lastName}${
          content.archived ? " (archived)" : ""
        }`,
        value: content.id
      }));
  }

  render() {
    const {
      selectedEmployees,
      actions,
      type,
      fieldName,
      employee: { isFetching },
      validation,
      online,
      multiple,
      required,
      disabled
    } = this.props;
    let selectedEmployeesList = multiple
      ? selectedEmployees.map(item => get(item, "employee.id"))
      : selectedEmployees.id;
    return (
      <Grid.Row>
        <Grid.Column className={`spraySection`}>
          <Form.Field required={required} className="sprayField">
            <label className="title">{type}</label>
            <Dropdown
              error={!validation}
              closeOnBlur
              closeOnChange
              search
              multiple={multiple}
              loading={isFetching && online}
              placeholder="Select employee"
              selection
              fluid
              noResultsMessage="Nothing was found"
              value={selectedEmployeesList}
              options={this.transformedEmployee}
              onChange={(_, data) =>
                multiple
                  ? actions.selectEmployees(data.value, fieldName)
                  : actions.selectSingleEmployee(data.value, fieldName)
              }
              disabled={disabled}
            />
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
    );
  }
}

EmployeeList.defaultProps = {
  disabled: false
};

EmployeeList.propTypes = {
  employee: PropTypes.object,
  fieldName: PropTypes.string,
  type: PropTypes.string,
  actions: PropTypes.object,
  validation: PropTypes.bool,
  multiple: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  showArchived: PropTypes.bool,
  online: PropTypes.bool,
  selectedEmployees: PropTypes.array
};

const mapStateToProps = (state, props) => {
  const { sprayDiary } = state;
  return {
    online: onlineSelector(state),
    employee: sprayDiary.employee,
    selectedEmployees: sprayDiary[props.fieldName],
    validation:
      typeof sprayDiary.validations[props.fieldName] !== "undefined"
        ? sprayDiary.validations[props.fieldName]
        : true
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      selectEmployees: selectEmployees,
      selectSingleEmployee: selectSingleEmployee
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeList);
