export const actionType = {
  FETCH_STOCK_START: 'STOCK/FETCH_START',
  FETCH_STOCK: 'STOCK/FETCH',
  FETCH_STOCK_FAIL: 'STOCK/FETCH_FAIL',

  FETCH_STOCK_LIST_START: 'STOCK/FETCH_LIST_START',
  FETCH_STOCK_LIST: 'STOCK/FETCH_LIST',
  FETCH_STOCK_LIST_FAIL: 'STOCK/FETCH_LIST_FAIL',

  GET_STOCK_BY_ID_START: 'STOCK/GET_BY_ID_START',
  GET_STOCK_BY_ID: 'STOCK/GET_BY_ID',
  GET_STOCK_BY_ID_FAIL: 'STOCK/GET_BY_ID_FAIL',

  GET_STOCK_ITEM_BY_VARIETY_ID_AND_BOX_UNIT_ID_START: 'STOCK/GET_STOCK_ITEM_BY_VARIETY_ID_AND_BOX_UNIT_ID_START',
  GET_STOCK_ITEM_BY_VARIETY_ID_AND_BOX_UNIT_ID: 'STOCK/GET_STOCK_ITEM_BY_VARIETY_ID_AND_BOX_UNIT_ID',
  GET_STOCK_ITEM_BY_VARIETY_ID_AND_BOX_UNIT_ID_FAIL: 'STOCK/GET_STOCK_ITEM_BY_VARIETY_ID_AND_BOX_UNIT_ID_FAIL',

  ADD_STOCK_START: 'STOCK/ADD_START',
  ADD_STOCK: 'STOCK/ADD',
  ADD_STOCK_FAIL: 'STOCK/ADD_FAIL',

  UPDATE_STOCK_START: 'STOCK/UPDATE_START',
  UPDATE_STOCK: 'STOCK/UPDATE',
  UPDATE_STOCK_FAIL: 'STOCK/UPDATE_FAIL',

  DELETE_STOCK_START: 'STOCK/DELETE_START',
  DELETE_STOCK: 'STOCK/DELETE',
  DELETE_STOCK_FAIL: 'STOCK/DELETE_FAIL',

  CLEAR_STOCK_ERROR: 'STOCK/CLEAR_STOCK_ERROR',
};
