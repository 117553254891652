import PropTypes from "prop-types";
import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Form } from "formsy-semantic-ui-react";
import { Dropdown } from "semantic-ui-react";
import { getFarmsList } from "../../actions/Farms/farms";

const SelectFarm = ({
  date,
  actions: { getFarmsList },
  farmsList,
  isFetching,
  error,
  shouldFetch,
  native,
  filter,
  showArchived,
  returnEntity,
  ...extraProps
}) => {
  useEffect(() => {
    getFarmsList();
  }, []);

  const filterArchived = data =>
    showArchived || !data.hasOwnProperty("archived")
      ? true
      : data.archived === false;

  const options = useMemo(
    () =>
      farmsList.filter(filterArchived).map(item => {
        return {
          key: item.id,
          text: item.name,
          value: returnEntity ? item : item.id
        };
      }),
    [farmsList]
  );

  const props = {
    noResultsMessage: "Nothing was found",
    fluid: true,
    ...extraProps,
    error,
    closeOnBlur: true,
    search: true,
    loading: isFetching,
    selection: true,
    renderLabel: item => item.text,
    options,
    className: extraProps.className ? extraProps.className : ""
  };

  return native ? <Dropdown {...props} /> : <Form.Dropdown {...props} />;
};

SelectFarm.propTypes = {
  actions: PropTypes.object.isRequired,
  className: PropTypes.any,
  date: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  farmsList: PropTypes.array.isRequired,
  filter: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  native: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  returnEntity: PropTypes.bool,
  shouldFetch: PropTypes.bool,
  showArchived: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.number])
};

SelectFarm.defaultProps = {
  disabled: false,
  error: false,
  farmsList: [],
  filter: () => true,
  isFetching: false,
  native: false,
  onChange: () => {},
  returnEntity: false,
  showArchived: false,
  value: []
};

const mapStateToProps = props => {
  const {
    farms: {
      data: { content },
      isFetching
    }
  } = props;
  return {
    farmsList: content,
    isFetching
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getFarmsList
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectFarm);
