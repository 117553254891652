import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SelectArea from "./SelectArea";
import SelectMachinery from "./SelectMachinery";
import SelectChemicals from "./SelectChemicals/SelectChemicals";
import RowsToSpray from "./RowsToSpray";
import GrowthStage from "./GrowthStage";
import SprayConditions from "./SprayConditions/SprayConditions";
import SelectedAreasTable from "./SelectedAreasTable/SelectedAreasTable";
import SegmentClosable from "../../../../components/SegmentClosable";
import PersonalProtectionEquipment from "./PersonalProtectionEquipment/PersonalProtectionEquipment";

const SprayForm = ({
  completed,
  validations: {
    selectedAreas,
    conc,
    widthPerRow,
    selectedChemicals,
    literPerHectare,
    windDirection,
    windStrength,
    temperature
  },
  isPreviouslyCreatedTask
}) => {
  const errorLocation = !(selectedAreas && widthPerRow && literPerHectare);
  const errorOperations = !(conc && selectedChemicals);
  const errorConditions = !(
    completed &&
    windDirection &&
    windStrength &&
    temperature
  );
  const errorsInSegment = {
    errorLocation: { error: errorLocation },
    errorOperations: { error: errorOperations },
    errorConditions: { error: errorConditions }
  };

  return (
    <>
      <SegmentClosable title="Location" errors={errorsInSegment.errorLocation}>
        <SelectArea />
        <RowsToSpray />
        <GrowthStage />
        <SelectedAreasTable />
      </SegmentClosable>
      <SegmentClosable
        title="Operations"
        errors={errorsInSegment.errorOperations}
      >
        <SelectMachinery isPreviouslyCreatedTask={isPreviouslyCreatedTask} />
        <SelectChemicals
          completed={completed}
          isPreviouslyCreatedTask={isPreviouslyCreatedTask}
        />
      </SegmentClosable>
      {completed && (
        <>
          <SegmentClosable
            title="Spray Conditions"
            errors={errorsInSegment.errorConditions}
          >
            <SprayConditions />
          </SegmentClosable>
          <SegmentClosable
            title="Personal Protection Equipment"
            errors={errorsInSegment.errorConditions}
          >
            <PersonalProtectionEquipment />
          </SegmentClosable>
        </>
      )}
    </>
  );
};

SprayForm.propTypes = {
  completed: PropTypes.bool,
  validations: PropTypes.object,
  isPreviouslyCreatedTask: PropTypes.bool
};

const mapStateToProps = state => {
  const {
    sprayDiary: { validations }
  } = state;

  return {
    validations
  };
};

export default connect(mapStateToProps)(SprayForm);
