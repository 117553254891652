import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Grid, Segment, Button } from "semantic-ui-react"; // eslint-disable-line

import UserMenuTop from "../UserMenuTop";
// eslint-disable-next-line
import styles from "./HeaderMain.css";
import { userSelector } from "../../selectors/user";

// eslint-disable-next-line
function HeaderMain({
  setMenuOpen,
  online,
  user,
  menuOpen,
  menuCollections,
  pathname
}) {
  const toggleMenuOpen = useCallback(() => {
    setMenuOpen(!menuOpen);
  }, [menuOpen, setMenuOpen]);
  const closeMenu = useCallback(
    event => {
      if (
        !(
          event.target.classList.contains("buttonMenu") ||
          event.target.closest(".buttonMenu")
        )
      ) {
        setMenuOpen(false);
      }
    },
    [setMenuOpen]
  );

  return (
    <Segment basic className="header-main" onClick={closeMenu}>
      <Grid>
        <Grid.Row>
          <Grid.Column only="mobile tablet" className="column-menu" width={2}>
            <div className="vertical-middle center">
              <Button
                icon="bars"
                onClick={toggleMenuOpen}
                className="buttonMenu"
              />
            </div>
          </Grid.Column>
          <Grid.Column
            mobile={4}
            tablet={4}
            computer={2}
            className="logo-column"
          >
            <div className="logo-holder">
              <Link to="/">
                <div className="logo-tuf" />
              </Link>
            </div>
          </Grid.Column>
          <Grid.Column mobile={4} tablet={3} computer={5}>
            <div className="vertical-middle">
              {!online && <span className="status-offline">Offline</span>}
            </div>
          </Grid.Column>
          <Grid.Column
            only="computer"
            className="column-user"
            tablet={5}
            computer={4}
            largeScreen={3}
            widescreen={2}
            floated="right"
          >
            <div
              style={{ marginRight: "33px" }}
              className="vertical-middle right"
            >
              <UserMenuTop
                menuCollections={menuCollections}
                pathname={pathname}
                user={user.profile}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
}

HeaderMain.propTypes = {
  route: PropTypes.object.isRequired,
  setMenuOpen: PropTypes.func.isRequired,
  menuOpen: PropTypes.bool,
  user: PropTypes.object
};

HeaderMain.defaultProps = {
  route: { name: "{%name%}" },
  setMenuOpen: () => {}
};

function mapStateToProps(state) {
  return {
    user: userSelector(state)
  };
}

export default connect(mapStateToProps)(HeaderMain);
