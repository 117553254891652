import React, { useEffect, useState } from "react";
import { Modal, Button, Confirm, Form, Icon, Header } from "semantic-ui-react";
import { Formik } from "formik";

import styles from "./ImageModal.module.css";
import ImageSlider from "./ImageSlider";

const HarvestUnitsImageModal = ({
  open,
  onClose,
  unit,
  blockName,
  urlList
}) => {
  const [secondOpen, setSecondOpen] = useState(false);
  const [activeUrl, setActiveUrl] = useState("");

  return (
    <Formik
      // validate={validateValues}
      enableReinitialize
      validateOnChange={false}
      // onSubmit={onSubmit}
      initialValues={unit || {}}
    >
      {({ values, dirty, isValid, setValues, handleSubmit }) => (
        <Form>
          <Modal
            open={open}
            closeOnDimmerClick={false}
            closeOnEscape={false}
            size="small"
            onClick={e => e.stopPropagation()}
            className={styles.root}
          >
            <Modal.Header className={styles.modalHeader}>
              <div className={styles.modalHeaderNameWrapper}>{blockName}</div>
              {/* <div className={styles.modalHeaderIconWrapper}>
                <Icon name="edit" /> Edit
              </div> */}
            </Modal.Header>

            <Modal.Content scrolling className={styles.containerStyles}>
              <ImageSlider setActiveUrl={setActiveUrl} slides={urlList} />
            </Modal.Content>
            <Modal.Actions className={styles.modalActionsButton}>
              <Button
                content="Cancel"
                color="green"
                basic
                onClick={() => {
                  setSecondOpen(true);
                }}
              />
              <div className={styles.exportButtonWrapper}>
                <Header
                  className={styles.exportButton}
                  as="a"
                  color="green"
                  href={activeUrl.url}
                  download
                  target="_blank"
                >
                  <Icon name="download" />
                  <span className={styles.exportButtonHeader}>Export</span>
                </Header>
              </div>
            </Modal.Actions>

            <Confirm
              className={styles.confirmModal}
              content="Are you sure you want to close modal window"
              open={secondOpen}
              onCancel={() => setSecondOpen(false)}
              onConfirm={() => {
                onClose();
                setSecondOpen(false);
              }}
            />
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default HarvestUnitsImageModal;
