import React from "react";
import PropTypes from "prop-types";
import {
  Checkbox,
  Dropdown,
  Header,
  Icon,
  Label,
  Segment,
  Table
} from "semantic-ui-react";
import Loader from "../Loader";
import styles from "./TableFarm.module.css";
import AreaRow from "./AreaRow";
import AreaWithSubAreas from "./AreaWithSubAreas";
import ToolTip from "../ToolTip";

const countBlocksPatches = farm => {
  const blocksNum = farm.areas
    ? farm.areas.filter(area => area.type === "BLOCK" && !area.archived).length
    : null;
  const archivedBlocksNum = farm.areas
    ? farm.areas.filter(area => area.type === "BLOCK" && area.archived).length
    : null;
  const patchesNum = farm.areas
    ? farm.areas.filter(area => area.type === "PATCH" && !area.archived).length
    : null;
  const archivedPatchesNum = farm.areas
    ? farm.areas.filter(area => area.type === "PATCH" && area.archived).length
    : null;
  return { blocksNum, archivedBlocksNum, patchesNum, archivedPatchesNum };
};

const countSize = (farm, showArchived) => {
  return farm.areas
    ? farm.areas
        .reduce((areaSize, area) => {
          if (showArchived) return areaSize + area.size;
          if (area.archived) return areaSize;
          return areaSize + area.size;
        }, 0)
        .toFixed(2)
    : null;
};

const countOpenTasks = (areas, showArchived) => {
  return areas
    .filter(area => showArchived || !area.archived)
    .reduce((tasks, area) => tasks + area.tasksCount.OPEN, 0);
};

const collectAreaAndSubAreas = (farm, showArchived) => {
  return farm.areas
    ? farm.areas
        .filter(area => showArchived || !area.archived)
        .reduce(function(rv, area) {
          if (area.parent) {
            (rv[area.parent.id] = rv[area.parent.id] || []).push(area);
          } else {
            rv[area.id] = rv[area.id] ? rv[area.id] : null;
          }

          return rv;
        }, {})
    : null;
};

const collectAreaToMap = areas => {
  return areas.reduce(function(rv, area) {
    rv[area.id] = area;
    return rv;
  }, {});
};

function TableFarm({
  farm,
  toggleOpen,
  setActivePatch,
  handleArchiveArea,
  handleEdit,
  setActiveFarm,
  selectedFarmId,
  handleArchiveFarm,
  handleDeleteFarm,
  isFetching,
  handleAddBlock,
  handleFarmClick,
  showArchived
}) {
  const blocksPatchesNum = countBlocksPatches(farm, showArchived);
  const farmSize = countSize(farm, showArchived);
  let areaToSubAreas = collectAreaAndSubAreas(farm, showArchived);
  let areasMap = collectAreaToMap(farm.areas);
  let sortedBlocksToShow = Object.entries(areaToSubAreas).sort((key1, key2) => {
    return areasMap[key1[0]]?.name
      ?.replace(/\s/g, "")
      .localeCompare(areasMap[key2[0]]?.name.replace(/\s/g, ""), undefined, {
        sensitivity: "base",
        numeric: true
      });
  });

  sortedBlocksToShow.forEach(([_, value]) => {
    if (value) {
      value.sort((patch1, patch2) => {
        return patch1.name.localeCompare(patch2.name);
      });
    }
  });

  const openTasks = countOpenTasks(farm.areas);
  const openTable = farm.open || (selectedFarmId && selectedFarmId === farm.id);
  return (
    <Segment className={styles.tableFarmHolder}>
      <Table
        selectable
        className={`${styles.tableFarm} ${openTable ? styles.tableOpen : ""}`}
      >
        {farm.areas ? (
          <Table.Header className={farm.archived ? styles.archivedFarm : null}>
            <Table.Row>
              <Table.HeaderCell width={1}>
                <div
                  className={`${styles.toggleIcon} ${
                    selectedFarmId ? styles.openTable : ""
                  }`}
                >
                  <Icon
                    className={
                      openTable ? "tuf-chevron-down" : "tuf-chevron-right"
                    }
                    onClick={() => toggleOpen(farm.id, !farm.open)}
                  />
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell width={3}>
                <Header
                  data-id={"farm-header"}
                  size={"large"}
                  className={
                    selectedFarmId && selectedFarmId === farm.id
                      ? styles.farmNameSelected
                      : styles.farmName
                  }
                  onClick={e => {
                    e.preventDefault();
                    !(selectedFarmId && selectedFarmId === farm.id) &&
                      setActiveFarm(farm.id);
                  }}
                >
                  {farm.name}
                </Header>
              </Table.HeaderCell>
              <Table.HeaderCell width={1}>
                <div>
                  {blocksPatchesNum.blocksNum} Blocks
                  {showArchived && blocksPatchesNum.archivedBlocksNum > 0 && (
                    <div className={styles.archived}>
                      ({blocksPatchesNum.archivedBlocksNum} Archived)
                    </div>
                  )}
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell width={1}>
                <div>
                  {(blocksPatchesNum.patchesNum > 0 ||
                    blocksPatchesNum.archivedPatchesNum > 0) && (
                    <div>{blocksPatchesNum.patchesNum} Patches </div>
                  )}
                  {showArchived && blocksPatchesNum.archivedPatchesNum > 0 && (
                    <div className={styles.archived}>
                      ({blocksPatchesNum.archivedPatchesNum} Archived)
                    </div>
                  )}
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell colSpan="3">
                <div>{farmSize} Ha</div>
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center" width={1}>
                <Icon className={`tuf-tasks ${styles.tasksListIcon}`} />
                <Label circular color={openTasks ? "olive" : "grey"}>
                  {openTasks}
                </Label>
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center" width={1}>
                <Icon
                  className={`${styles.farmCostBreakdownIcon}`}
                  onClick={() => handleFarmClick(farm)}
                />
              </Table.HeaderCell>
              <Table.HeaderCell width={1} textAlign="center">
                {!farm.archived ? (
                  <ToolTip content="Edit">
                    <Icon
                      className={`tuf-pen ${styles.tasksEditIcon}`}
                      onClick={event => handleEdit(event, farm.id)}
                    />
                  </ToolTip>
                ) : (
                  <Icon className="blank" />
                )}
                <Dropdown
                  icon="ellipsis vertical"
                  iconposition="right"
                  floating
                  button
                  className={`button-text ${styles.buttonMore}`}
                >
                  <Dropdown.Menu direction="left">
                    {!farm.archived && (
                      <Dropdown.Item
                        onClick={event => handleAddBlock(event, farm.id)}
                      >
                        Add Block
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item
                      onClick={() => handleArchiveFarm(farm, !farm.archived)}
                    >
                      {farm.archived ? "Activate" : "Archive"} Farm
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleDeleteFarm(farm)}>
                      Delete Farm
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
        ) : (
          <Table.Header className={farm.archived ? styles.archivedFarm : null}>
            <Table.Row>
              <Table.HeaderCell width={1}>
                <Loader className={styles.loader} />
                <Checkbox disabled />
              </Table.HeaderCell>
              <Table.HeaderCell width={15}>
                <Header size={"large"} className={styles.farmNameSelected}>
                  {farm.name}
                </Header>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
        )}

        {openTable &&
          farm.areas &&
          (sortedBlocksToShow.length ? (
            sortedBlocksToShow.map(([key, areas]) => {
              let area = areasMap[key];
              return area ? (
                <Table.Body
                  key={`area_with_sub_areas_${area.id}`}
                  className={farm.archived ? styles.archivedFarm : null}
                >
                  {isFetching ? (
                    <Loader />
                  ) : area && area.hasSubAreas ? (
                    <>
                      <AreaWithSubAreas
                        farm={farm}
                        area={area}
                        setActivePatch={setActivePatch}
                        handleFarmClick={handleFarmClick}
                        subAreas={areas ? areas : []}
                        handleArchiveArea={handleArchiveArea}
                        handleEdit={handleEdit}
                        countOpenTasks={countOpenTasks}
                        archivedFarm={farm.archived}
                      />
                    </>
                  ) : (
                    <AreaRow
                      farm={farm}
                      area={area}
                      handleFarmClick={handleFarmClick}
                      handleArchiveArea={handleArchiveArea}
                      handleEdit={handleEdit}
                      setActivePatch={setActivePatch}
                      blockRow={true}
                      archivedFarm={farm.archived}
                    />
                  )}
                </Table.Body>
              ) : null;
            })
          ) : (
            <Table.Body>
              <Table.Row>
                <Table.Cell textAlign="center" colSpan={10}>
                  No Blocks in this Farm
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          ))}
      </Table>
    </Segment>
  );
}

TableFarm.propTypes = {
  farm: PropTypes.object.isRequired,
  toggleOpen: PropTypes.func,
  setActivePatch: PropTypes.func,
  handleArchiveArea: PropTypes.func,
  setActiveFarm: PropTypes.func,
  handleEdit: PropTypes.func,
  handleAddBlock: PropTypes.func,
  selectedFarmId: PropTypes.number,
  handleArchiveFarm: PropTypes.func,
  handleDeleteFarm: PropTypes.func,
  handleFarmClick: PropTypes.func,
  showArchived: PropTypes.bool
};

TableFarm.defaultProps = {
  farm: {}
};

export default TableFarm;
