import React from "react";
import PropTypes from "prop-types";
import { Header, Container } from "semantic-ui-react";

import "./index.css";
import Logo from "./Logo";
import { scoutingShape } from "constants/Scouting/types";
import { get } from "lodash";
import ScoutingReportAreaElement from "./ScoutingReportAreaElement";

const ScoutingReport = ({ scoutingList, withImages }) => {
  return (
    <Container fluid>
      {scoutingList.map((scouting, index) => {
        const { creator, comment, areas = [], name } = scouting;
        const generalInfoData = [
          { title: "Task Name", value: name },
          {
            title: "Farm",
            value: get(areas[0], "area.farm.name")
          },
          {
            title: "Task Done by",
            value: `${get(creator, "firstName")} ${get(creator, "lastName")}`
          },
          comment && {
            title: "Comments",
            value: comment
          }
        ];

        return (
          <div className={`full-width ${index > 0 ? "page-break-before" : ""}`}>
            <div className={"print-page-header-row"}>
              <div className={"scouting-title print-page-header-column"}>
                <Header as={"h1"} className={"bold"}>
                  Scouting task: SC-{scouting.id}
                </Header>
              </div>
              <div className="scouting-logo">
                <Logo />
              </div>
            </div>
            <div className={"full-width print-page-root"}>
              <div className={"info-header"}>
                <Header as={"h2"} className={"bold"}>
                  General information
                </Header>
              </div>
              {generalInfoData.map(({ title, value }) => (
                <div key={title} className={"info-row d-flex"}>
                  <div className="info-title">
                    <Header as={"h4"} className={"capitalize font medium"}>
                      {title}
                    </Header>
                  </div>
                  <div className="info-value">
                    <Header as={"h4"} className={"font slim"}>
                      {value}
                    </Header>
                  </div>
                </div>
              ))}
              <div className="full-width">
                <Header as={"h2"} className={"bold info-header"}>
                  Block Scouting Information
                </Header>
                {areas.map((scoutingAreaData, index) => (
                  <>
                    {index !== 0 && <hr className={"block-divide-line"} />}
                    <ScoutingReportAreaElement
                      key={scoutingAreaData.id}
                      scoutingArea={scoutingAreaData}
                      withImages={withImages}
                    />
                  </>
                ))}
              </div>
            </div>
          </div>
        );
      })}
    </Container>
  );
};

ScoutingReport.propTypes = {
  scoutingList: PropTypes.arrayOf(scoutingShape)
};

export default ScoutingReport;
