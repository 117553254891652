import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import CollapsibleBlock from "components/CollapsibleBlock";
import { Button } from "semantic-ui-react";
import { Container, Grid, Segment } from "semantic-ui-react";
import FarmForm from "./FarmForm";
import FarmRow from "./FarmRow";
import styles from "./Farm.module.css";

const Farms = ({ shed, setFarmChecker }) => {
  const { values, setFieldValue, validateForm } = useFormikContext();
  const [addButtonChecks, setAddButtonChecks] = useState(true);
  const [deleteButtonCheck, setDeleteButtonCheck] = useState(false);
  const farmsListCheck = values.farms?.length;

  const onNewFarmAdd = farmValues => {
    setFieldValue(
      "farms",
      values.farms ? [...values.farms, farmValues] : [farmValues]
    );
    validateForm();
    setAddButtonChecks(true);
  };

  const addProduct = () => {
    setAddButtonChecks(false);
    setDeleteButtonCheck(true);
  };

  const deleteAddingForm = () => {
    setAddButtonChecks(true);
  };
  useEffect(() => {
    setFarmChecker(!addButtonChecks);
  }, [addButtonChecks, farmsListCheck]);
  return (
    <Segment>
      <CollapsibleBlock title="Farms" className={styles.root}>
        <Grid columns="equal" as={Container}>
          <Grid.Column className={styles.rowLabel}>Farm</Grid.Column>
          {values.farms.map(farm => (
            <FarmRow key={farm.id} farmData={farm} shed={shed} />
          ))}
        </Grid>
        {farmsListCheck && addButtonChecks ? (
          <div className={styles.buttonContainer}>
            <Button
              type="submit"
              className="button-text color-green"
              onClick={addProduct}
            >
              + Add farm
            </Button>
          </div>
        ) : null}
        {(!addButtonChecks || !farmsListCheck) && (
          <Segment>
            <FarmForm
              farmsListCheck={farmsListCheck}
              deleteButtonCheck={deleteButtonCheck}
              deleteAddingForm={deleteAddingForm}
              onSubmit={onNewFarmAdd}
            />
          </Segment>
        )}
      </CollapsibleBlock>
    </Segment>
  );
};

export default Farms;
