import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";

import styles from "./Tabs.module.css";
import {
  clearRoute,
  getTaskRoute
} from "../../../../actions/SprayDiary/machinery";
import { diff } from "deep-object-diff";
import { activeTaskRouteSelector } from "../../../../selectors/spray";
import { RouteViewer } from "../../../../components/RouteViewer";
import { toUnixInTimezone, toUnixInUTC } from "../../../../utils/timezoneUtils";
import { usePrevious } from "utils/hooks";
import { createTimezoneSelector } from "../../../../selectors/user";

const TaskRoute = ({
  sprayTask,
  route,
  isFetching,
  timezone,
  actions: { getTaskRoute, clearRoute }
}) => {
  const prev = usePrevious({
    id: sprayTask.id,
    selectedMachinery: sprayTask.selectedMachinery,
    workingTimes: sprayTask.workingTimes,
    status: sprayTask.status,
    inProgressAt: sprayTask.inProgressAt
  });

  useEffect(() => {
    if (
      prev &&
      prev.id === sprayTask.id &&
      prev.status === sprayTask.status &&
      prev.inProgressAt === sprayTask.inProgressAt &&
      Object.keys(diff(prev.selectedMachinery, sprayTask.selectedMachinery))
        .length === 0 &&
      Object.keys(diff(prev.workingTimes, sprayTask.workingTimes)).length === 0
    ) {
      return;
    }

    if (
      sprayTask.status === "OPEN" ||
      !sprayTask.selectedMachinery ||
      !sprayTask.selectedMachinery.trackerId
    ) {
      clearRoute(sprayTask.id);
      return;
    }

    let timeRanges = [];
    if (sprayTask.status === "IN_PROGRESS" && sprayTask.inProgressAt) {
      timeRanges = [
        {
          from: toUnixInTimezone(sprayTask.inProgressAt, timezone),
          to: toUnixInTimezone(
            moment
              .unix(
                Math.min(
                  moment().unix(),
                  moment(sprayTask.inProgressAt)
                    .add(3, "days")
                    .unix()
                )
              )
              .format("YYYY-MM-DD HH:mm:ss"),
            timezone
          )
        }
      ];
    } else if (
      sprayTask.status === "COMPLETED" &&
      sprayTask.workingTimes &&
      sprayTask.workingTimes.length > 0
    ) {
      timeRanges = sprayTask.workingTimes.map(workingTime => {
        return {
          from: toUnixInUTC(`${workingTime.date} ${workingTime.workingFrom}`),
          to: toUnixInUTC(`${workingTime.date} ${workingTime.workingTo}`)
        };
      });
    } else {
      clearRoute(sprayTask.id);
      return;
    }
    getTaskRoute(sprayTask.id, sprayTask.selectedMachinery.id, timeRanges);
    //eslint-disable-next-line
  }, [
    sprayTask.id,
    sprayTask.status,
    sprayTask.inProgressAt,
    sprayTask.selectedMachinery,
    sprayTask.workingTimes
  ]);
  return (
    <div className={`${styles.tabHolder} ${styles.fill}`}>
      <RouteViewer isFetching={isFetching} route={route} />
    </div>
  );
};

TaskRoute.propTypes = {
  route: PropTypes.array,
  isFetching: PropTypes.bool,
  sprayTask: PropTypes.object,
  actions: PropTypes.object,
  timezone: PropTypes.string
};

TaskRoute.defaultProps = {};

const mapStateToProps = (state, props) => ({
  route: activeTaskRouteSelector(state, {
    activeTask: props.id || props._id
  }),
  isFetching: state.spray.tasks.routes.isFetching,
  timezone: createTimezoneSelector(state)
});
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ getTaskRoute, clearRoute }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskRoute);
