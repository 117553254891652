import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Segment, Image, Header, Button, Icon } from 'semantic-ui-react';
import styles from './AddFarmPrompt.module.css';
import blank from '../../../assets/img/blank.png';
import { history } from '../../../store';
import Weather from '../../../components/Weather';


const handleAddFarm = route => {
  history.push(`${route.href}/add_new_farm`);
};

function AddFarmPrompt(props) {
  const { route } = props;
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={11}>
          <Segment basic className={styles.promptHolder} textAlign="center">
            <Image src={blank} size="medium" centered />
            <Header size="large">How does your farm look? </Header>
            <Header className={styles.subTitle} size="medium">
              First, we need to set up your farm.
            </Header>

            <Button
              icon
              labelPosition="right"
              type="submit"
              onClick={() => handleAddFarm(route)}
            >
              Add Farm
              <Icon name="right arrow" />
            </Button>
          </Segment>
        </Grid.Column>
        <Grid.Column width={5}>
          <Segment basic className={styles.promptHolder}>
            <Weather
              forecast="5days"
              apikey={process.env.REACT_APP_OPEN_WEATHER_API_UX}
              type="geo"
              lat="-37.81"
              lon="144.96"
            />
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

AddFarmPrompt.propTypes = {
  route: PropTypes.object,
};

AddFarmPrompt.defaultProps = {};

export default AddFarmPrompt;
