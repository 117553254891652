import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef
} from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Layout from "../../../components/Layout";
import styles from "../../Farms/Farms.module.css";
import { Button, Grid, Header, Icon, Segment } from "semantic-ui-react";
import style from "./TotalCostPerArea.module.css";
import Pagination from "../../../components/ListTable/Pagination";
import { onlineSelector } from "../../../selectors/common";
import Filters from "../../../components/Filters";
import { getFiltersFromLocations } from "../../../utils/routeHelpers";
import {
  fetchCostsPerArea,
  fetchCostsPerAreaList
} from "../../../actions/Reports/reports";
import TotalCostPerAreaTable from "./components/TotalCostPerAreaTable";
import Loader from "../../../components/Loader";
import { history } from "../../../store";
import { copyStyles } from "../../../utils/styles";
import ReactDOMServer from "react-dom/server";
import PrintReport from "./components/PrintReport";
import { sum, round, debounce } from "lodash";
import { excelExport } from "utils/excelExport";
import moment from "moment";
import { usePrevious } from "utils/hooks";
import { setHashParameter } from "utils/hashToObject";

const defaultFiltersOptions = [
  {
    name: "Farm Name",
    type: "FarmMultiSelectFilterName",
    id: "farmName"
  },
  {
    name: "Area Name",
    type: "AreaMultiSelectFilter",
    id: "areaId"
  },
  {
    name: "Date",
    type: "DateRangeFilter",
    id: "date"
  },
  {
    id: "seasons",
    name: "Season",
    type: "SeasonSingleSelectFilter"
  }
];

const TotalCostPerArea = ({
  data,
  route,
  location,
  actions: { fetchCostsPerArea, fetchCostsPerAreaList },
  isFetching,
  content,
  reportPerAreaCostsList,
  pagination,
  areas,
  online
}) => {
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const rawFilters = getFiltersFromLocations(defaultFiltersOptions, location);

  const prevLocation = usePrevious(location);
  const prevPageSize = usePrevious(pageSize);
  const prevPageNumber = usePrevious(pageNumber);
  const { filters, from, to } = useMemo(() => {
    const { dateFrom, dateTo, ...filters } = rawFilters;
    const from = rawFilters.dateFrom ? rawFilters.dateFrom[0] : null;
    const to = rawFilters.dateTo ? rawFilters.dateTo[0] : null;

    return {
      filters,
      from,
      to
    };
  }, [location.hash]);
  const disableChecker = !!filters.seasons
    ? "seasons"
    : !!from || !!to
    ? "date"
    : null;

  const handleExcelExport = useCallback(
    async filters => {
      const data = await fetchCostsPerAreaList(filters);
      const costsHeader = [
        ...[
          ...new Set(
            data.content
              ?.map(item => {
                return item.generalTaskCosts?.map(type => type.generalTaskType);
              })
              .flat()
          )
        ],
        ...[
          ...new Set(
            data.content
              ?.map(item => {
                return item.generalTaskCosts?.map(type => type.generalTaskType);
              })
              .flat()
          )
        ]
      ].sort();

      const headerColumn = [
        "Area Name",
        "Area Size(Ha)	",
        "No of plants",
        "Chemical / Fertiliser Costs($)",
        "Operating Costs ($)",
        "Labour Costs ($)",
        "Application Task - Labour Costs($)",
        ...costsHeader.map((item, index) =>
          index % 2 == 0
            ? `${item} - Labour Costs($)`
            : `${item} - Alt Labour Costs($)`
        ),
        "Machinery Costs($)",
        "Application Task - Machinery Costs($)",
        ...costsHeader
          .map(
            (item, index) => index % 2 == 0 && `${item} - Machinery Costs($)`
          )
          .filter(item => item),
        "Cost / Harvest Unit",
        "Cost / Ha($ / Ha)",
        "Cost / plant	",
        "Total Costs($)"
      ];
      if (data.content?.length && !isFetching) {
        const excelData =
          data.content &&
          data.content.map(
            (
              {
                areaName,
                areaSize,
                areaId,
                sprayTaskLabourCost,
                sprayTaskMachineryCost,
                generalTaskCosts,
                chemicalCost,
                binCount,
                otherExpensesCost,
                overheadsCost
              },
              index
            ) => {
              const totalCost =
                sprayTaskLabourCost +
                sprayTaskMachineryCost +
                chemicalCost +
                otherExpensesCost +
                overheadsCost +
                sum(
                  generalTaskCosts.map(task =>
                    task.machineryCost ? task.machineryCost : 0
                  )
                ) +
                sum(
                  generalTaskCosts.map(task =>
                    task.labourCost ? task.labourCost : 0
                  )
                ) +
                sum(
                  generalTaskCosts.map(task =>
                    task.altLabourCost ? task.altLabourCost : 0
                  )
                );
              const totalCostPerHa =
                areaSize && areaSize != 0
                  ? (sprayTaskLabourCost +
                      sprayTaskMachineryCost +
                      chemicalCost +
                      otherExpensesCost +
                      overheadsCost +
                      sum(
                        generalTaskCosts.map(task =>
                          task.machineryCost ? task.machineryCost : 0
                        )
                      ) +
                      sum(
                        generalTaskCosts.map(task =>
                          task.labourCost ? task.labourCost : 0
                        )
                      ) +
                      sum(
                        generalTaskCosts.map(task =>
                          task.altLabourCost ? task.altLabourCost : 0
                        )
                      )) /
                    areaSize
                  : "No information";

              const totalPlants = areas.list.content
                .find(item => item.id === areaId)
                ?.varieties.reduce(
                  (prev, curr) => prev + curr.numberOfPlants,
                  0
                );

              const labourCostValue = {};
              const labourAltCostValue = {};
              const machineryCostValue = {};
              costsHeader.forEach((item, index) => {
                const foundTask = generalTaskCosts.find(
                  ({ generalTaskType }) => item === generalTaskType
                );

                labourCostValue[
                  `labourCost${item}`.replace(/ /g, "_")
                ] = foundTask?.labourCost
                  ? foundTask?.labourCost.toFixed(2)
                  : "0.00";
                labourAltCostValue[
                  `labourCost${item}Alt`.replace(/ /g, "_")
                ] = foundTask?.altLabourCost
                  ? foundTask?.altLabourCost.toFixed(2)
                  : "0.00";

                machineryCostValue[
                  `machineryCost${item}`.replace(/ /g, "_")
                ] = foundTask ? foundTask.machineryCost.toFixed(2) : "0.00";
              });
              const concetedObject = Object.assign(
                labourCostValue,
                labourAltCostValue
              );

              const sorted = Object.keys(labourCostValue)
                .sort()
                .reduce(function(acc, key) {
                  acc[key] = round(labourCostValue[key], 2).toFixed(2);
                  return acc;
                }, {});
              return {
                ...{ areaName: areaName || "-" },
                ...{ areaSize: areaSize.toFixed(2) || 0 },
                ...{ noOfPlants: totalPlants.toFixed(2) || 0 },
                ...{ chemicalCost: chemicalCost.toFixed(2) || 0 },
                ...{
                  operatingCosts:
                    round(otherExpensesCost + overheadsCost, 2).toFixed(2) || 0
                },
                ...{
                  labourCosts:
                    round(
                      sprayTaskLabourCost +
                        generalTaskCosts.reduce(
                          (prev, curr) => prev + curr.labourCost,
                          0
                        ) +
                        generalTaskCosts.reduce(
                          (prev, curr) => prev + curr.altLabourCost,
                          0
                        ),
                      2
                    ).toFixed(2) || 0
                },
                ...{
                  sprayTaskLabourCost:
                    round(sprayTaskLabourCost, 2).toFixed(2) || 0
                },
                ...sorted,
                ...{
                  machineryCosts:
                    round(
                      sprayTaskMachineryCost +
                        generalTaskCosts.reduce(
                          (prev, curr) => prev + curr.machineryCost,
                          0
                        ),
                      2
                    ).toFixed(2) || 0
                },
                ...{
                  sprayTaskMachineryCost:
                    round(sprayTaskMachineryCost, 2).toFixed(2) || 0
                },
                ...machineryCostValue,
                ...{
                  costPerUnits: binCount
                    ? round(totalCost / binCount, 2).toFixed(2)
                    : "No information"
                },
                ...{
                  costPerHa: isNaN(totalCostPerHa)
                    ? "No information"
                    : round(totalCostPerHa, 2).toFixed(2)
                },
                ...{
                  costPerPlant:
                    totalPlants !== 0 &&
                    isFinite(totalPlants) &&
                    isFinite(totalCost)
                      ? round(totalCost / totalPlants, 3).toFixed(2)
                      : "No information"
                },
                ...{
                  totalCost: round(totalCost, 2).toFixed(2)
                }
              };
            }
          );

        const dataeFromFilter = rawFilters.dateFrom
          ? rawFilters.dateFrom &&
            moment(rawFilters.dateFrom).format("DD/MM/YYYY")
          : "";
        const dataeTOFilter = `${
          rawFilters.dateTo
            ? rawFilters.dateTo &&
              moment(rawFilters.dateTo).format("DD/MM/YYYY")
            : ""
        } `;
        const dateCheker = dataeFromFilter && dataeTOFilter ? "-" : "";
        const farms = rawFilters.farmName?.length
          ? `Farm-${rawFilters.farmName[0]}`
          : "";

        const areasName = rawFilters.areaId?.length
          ? `Areas-${areas.list.content
              .filter(item => rawFilters.areaId.some(a => a == item.id))
              .map(item => item.name)
              .join(", ")} `
          : "";
        const fileName = `total_cost_per_area ${`${dataeFromFilter}${dateCheker}`}${dataeTOFilter} ${farms} ${areasName}`;
        excelExport(excelData, headerColumn, fileName);
      }
    },
    [reportPerAreaCostsList.content]
  );

  useEffect(() => {
    setPageNumber(0);
  }, [location.hash]);

  const fetchCosts = useRef(
    debounce((...params) => {
      return !isFetching && fetchCostsPerArea(...params);
    }, 300)
  );
  useEffect(() => {
    if (
      prevLocation ||
      prevPageNumber !== pageNumber ||
      prevPageSize !== pageSize ||
      prevLocation.hash !== location.hash
    ) {
      fetchCosts.current({
        page: pageNumber,
        size: pageSize,
        filters,
        from,
        to
      });
    }
  }, [pageSize, pageNumber, location]);

  const itemsCount = {
    itemFrom: pagination.number * pageSize + 1,
    itemTo: Math.min(
      pagination.number * pageSize + 1 + pageSize - 1,
      pagination.totalElements
    )
  };
  const handleBackClick = () => {
    history.push({
      pathname: "/reports",
      state: {
        activeNode: location.state.reportNode
      }
    });
  };

  const print = async () => {
    await fetchCostsPerAreaList(filters);
    if (!isFetching) {
      const newWindow = window.open();
      newWindow.document.title = `Total Cost Per Block`;
      copyStyles(window.document, newWindow.document);
      newWindow.document.body.innerHTML = ReactDOMServer.renderToString(
        <PrintReport data={content} />
      );
      newWindow.print();
    }
  };
  const defaultSeason = data.seasons?.reduce(function(prev, current) {
    return prev.startAt > current.startAt ? prev : current;
  }, {});
  const defaultDate = [defaultSeason?.startAt, defaultSeason?.endAt];
  useEffect(() => {
    defaultSeason?.startAt &&
      defaultSeason?.endAt &&
      setHashParameter(location, "seasons", defaultDate, null);
  }, []);

  return (
    <Layout route={route} location={location} classForMain={styles.mainHolder}>
      <div className={style.p1em}>
        <Header as="h2">
          <span className={style.backIconWrapper} onClick={handleBackClick}>
            <Icon name="angle left" className={style.backIcon} />
          </span>
          {route.name}
        </Header>
        <Segment>
          <Grid className={styles.filtersGrid} verticalAlign="middle">
            <Grid.Row verticalAlign="middle">
              <Grid.Column
                mobile={16}
                tablet={10}
                computer={10}
                largeScreen={10}
              >
                <Filters
                  disabled={isFetching}
                  disableChecker={disableChecker}
                  options={defaultFiltersOptions}
                  location={location}
                />
              </Grid.Column>
              <Grid.Column
                mobile={16}
                tablet={6}
                computer={6}
                largeScreen={6}
                floated="right"
                textAlign="right"
              >
                <Button
                  disabled={isFetching}
                  style={{ marginLeft: 20 }}
                  onClick={() => handleExcelExport(rawFilters)}
                >
                  <Icon name="download" />
                  <span>Export</span>
                </Button>
                <Button style={{ marginLeft: 10 }} onClick={print}>
                  <Icon name="print" />
                  <span>Print</span>
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          {isFetching ? (
            <Loader />
          ) : (
            <TotalCostPerAreaTable areaStats={content} />
          )}
          <Pagination
            currentPage={pageNumber}
            totalElements={pagination.totalElements}
            pageSize={10}
            online={online}
            itemsCount={itemsCount}
            onPageChangeOwn={pageNumber => setPageNumber(pageNumber)}
            pages={pagination.totalPages}
            updatePageSize={(_, data) => {
              setPageSize(data.value);
              setPageNumber(0);
            }}
            pageSizeOptions={[10, 20, 50, 100]}
          />
        </Segment>
      </div>
    </Layout>
  );
};

TotalCostPerArea.propTypes = {
  isFetching: PropTypes.bool,
  online: PropTypes.bool,
  location: PropTypes.object,
  actions: PropTypes.object,
  pagination: PropTypes.object,
  route: PropTypes.object,
  content: PropTypes.any
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchCostsPerArea,
      fetchCostsPerAreaList
    },
    dispatch
  )
});

const mapStateToProps = state => {
  const {
    settings: { data },
    areas,
    reports: {
      reportsReducerNeedToRefactor: {
        isFetching,
        reportPerAreaCosts: {
          content,
          number,
          totalPages,
          totalElements,
          numberOfElements,
          size
        },
        reportPerAreaCostsList
      }
    }
  } = state;

  return {
    data,
    areas,
    isFetching,
    content,
    reportPerAreaCostsList,
    online: onlineSelector(state),
    pagination: { number, totalPages, totalElements, numberOfElements, size }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TotalCostPerArea);
