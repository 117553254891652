import configAuth from './configAuth';

export default {
  ...configAuth,
  notCached: {
    cacheName: `workbox-precache-v2-${process.env.REACT_APP_URL}/`,
    env: process.env.REACT_APP_ENVIRONMENT,
    list: [
      { name: 'meta.json', allowOffline: true },
      { name: 'silent-renew.html', allowOffline: true },
      { name: process.env.REACT_APP_AUTH_URL, allowOffline: false },
      { name: process.env.REACT_APP_API_URL, allowOffline: false },
      { name: process.env.REACT_APP_SENTRY_DSN, allowOffline: false },
      { name: 'openweathermap.org', allowOffline: false },
      { name: 'chrome-extension://', allowOffline: false },
      { name: '.hot-update.js', allowOffline: false },
    ],
  },
  env: process.env.REACT_APP_ENVIRONMENT,
};
