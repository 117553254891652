import React, { useEffect, useState } from "react";
import { get, isEmpty } from "lodash";
import { Modal, Button, Confirm } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import GeneralInformation from "./components/GeneralInformation";
import CropRates from "./components/CropRatesEditSection/CropRates";
import ChemicalPropertiesEditSection from "./components/ChemicalProperties";
import {
  createChemical,
  searchProductsByName,
  updateChemical,
  fetchChemicalsLog
} from "actions/Chemicals";
import ElementWeightEditSection from "./components/ElementWeight";
import AdditionalInformationEditSection from "./components/AdditionalInformation";
import { PRODUCT_INITIAL_VALUES } from "constants/Chemicals";
import { GENERIC_VALUE } from "selectors/crops";
import styles from "./InventoryModal.module.css";

const REQUIRED_FIELDS = ["name", "matter", "unit", "type"];

const InventoryModal = ({ open, onClose, chemical, editSectionIndex }) => {
  const [secondOpen, setSecondOpen] = useState(false);
  const [prevProductName, setPrevProductName] = useState("");
  const [check, setCheck] = useState(true);
  const [checkEditForm, setCheckEditForm] = useState();
  const [valuesList, setValuesList] = useState();
  const [errorsList, setErrorsList] = useState();
  const dispatch = useDispatch();

  const validateValues = async values => {
    const errors = {};
    const { name, rates, price, trackInventory } = values;

    REQUIRED_FIELDS.forEach(filedName => {
      if (!get(values, filedName)) {
        errors[filedName] = "Required field";
      }
    });

    if (name && name !== get(chemical, "name") && name !== prevProductName) {
      setPrevProductName(name);

      const { content } = await searchProductsByName(name);

      if (content.some(product => product.name === name)) {
        errors.name = "Product with the same name already exists";
      }
    }

    if (isEmpty(rates)) {
      errors.rates = true;
    }

    return errors;
  };
  useEffect(() => {
    if (
      valuesList &&
      !valuesList.trackInventory &&
      !valuesList.price &&
      valuesList.price !== 0
    ) {
      setErrorsList(true);
    } else {
      setErrorsList(false);
    }
  });

  function onSubmit(values) {
    const valuesNew = {
      ...values,
      rates: values.rates.map(({ crop, ...restRate }) => ({
        ...restRate,
        ...(crop?.id !== GENERIC_VALUE && { crop })
      }))
    };

    const object = valuesList.elements;
    let newObject;
    for (const property in object) {
      newObject = {
        ...newObject,
        [property]: +`${object[property]}`.replace(",", ".")
      };
    }
    const fixedValue = {
      ...valuesNew,
      elements: newObject
    };
    if (chemical) {
      dispatch(updateChemical(fixedValue.id, fixedValue)).then(onClose);
      dispatch(fetchChemicalsLog(fetchChemicalsLog));
    } else {
      dispatch(createChemical(fixedValue)).then(onClose);
      dispatch(fetchChemicalsLog(fetchChemicalsLog));
    }
  }

  return (
    <Modal
      open={open}
      closeOnDimmerClick={false}
      closeOnEscape={false}
      onClose={onClose}
      size="large"
      onClick={e => e.stopPropagation()}
      className={styles.root}
    >
      <Modal.Header>{!!chemical ? "Edit" : "Add"} a product</Modal.Header>
      <Formik
        validate={validateValues}
        enableReinitialize
        validateOnChange={false}
        onSubmit={onSubmit}
        initialValues={chemical || PRODUCT_INITIAL_VALUES}
      >
        {props => (
          setValuesList(props.values),
          (
            <>
              <Modal.Content scrolling className={styles.modalContentContainer}>
                <GeneralInformation
                  chemical={chemical}
                  editSectionIndex={editSectionIndex}
                />
                <CropRates
                  setCheck={setCheck}
                  setCheckEditForm={setCheckEditForm}
                  editSectionIndex={editSectionIndex}
                />
                <ChemicalPropertiesEditSection
                  editSectionIndex={editSectionIndex}
                />
                <ElementWeightEditSection editSectionIndex={editSectionIndex} />
                <AdditionalInformationEditSection
                  editSectionIndex={editSectionIndex}
                />
              </Modal.Content>
              <Modal.Actions>
                <Button
                  className={styles.cancelButton}
                  content="Cancel"
                  onClick={() => {
                    props.dirty ? setSecondOpen(true) : onClose();
                  }}
                />
                <Button
                  content="Save"
                  color="green"
                  className={styles.saveButton}
                  onClick={props.submitForm}
                  disabled={
                    !props.isValid ||
                    !props.dirty ||
                    check ||
                    errorsList ||
                    checkEditForm
                  }
                />
              </Modal.Actions>
            </>
          )
        )}
      </Formik>
      <Confirm
        className={styles.confirmModal}
        content="Are you sure you want to close modal window"
        open={secondOpen}
        onCancel={() => setSecondOpen(false)}
        onConfirm={() => {
          onClose();
          setSecondOpen(false);
        }}
      />
    </Modal>
  );
};

export default InventoryModal;
