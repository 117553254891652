import React from "react";
import { Table } from "semantic-ui-react";
import CollapsibleBlock from "components/CollapsibleBlock";
import { shedShape } from "constants/Sheds/types";
import { get } from "lodash";
import styles from "../Sidebar.module.css";

const GeneralInformationSection = ({ shed }) => {
  return (
    <CollapsibleBlock
      className={styles.generalInfoTitle}
      title="General Information"
    >
      <Table definition fixed>
        <Table.Body>
          <Table.Row>
            <Table.Cell className={styles.addressRow}>Address</Table.Cell>
            <Table.Cell className={styles.addressValueRow}>
              {get(shed, "address")}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.comentsRow}>Comments</Table.Cell>
            <Table.Cell className={styles.comentsValueRow}>
              {get(shed, "comments")}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </CollapsibleBlock>
  );
};

GeneralInformationSection.propTypes = {
  shed: shedShape
};

export default GeneralInformationSection;
