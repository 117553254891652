import React from "react";
import { Checkbox, Form, Grid } from "semantic-ui-react";

import styles from "./CheckedInputRow.module.css";
import PropTypes from "prop-types";

const columnWidthWithCheckbox = {
  mobile: 14,
  tablet: 14,
  computer: 10,
  largeScreen: 10,
  widescreen: 10
};

const columnWidthWithoutCheckbox = {
  mobile: 16,
  tablet: 16,
  computer: 10,
  largeScreen: 10,
  widescreen: 10
};

const selectColumnWidth = {
  mobile: 2,
  tablet: 2,
  computer: 1,
  largeScreen: 1,
  widescreen: 1
};

const CheckedInputRow = ({
  name,
  label,
  required,
  showCheckbox,
  children,
  isActive,
  setActive
}) => {
  const active = !showCheckbox || isActive(name);
  return (
    <>
      <Grid.Row
        className={`${styles.labelRow} ${!active ? styles.disabled : ""}`}
      >
        <Grid.Column>
          <Form.Field required={required && active}>
            <label htmlFor={name}>{label}</label>
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className={styles.inputRow}>
        {showCheckbox && (
          <Grid.Column {...selectColumnWidth} verticalAlign={"middle"}>
            <Checkbox
              checked={active}
              onChange={(_, data) => setActive(name, data.checked)}
            />
          </Grid.Column>
        )}
        <Grid.Column
          {...(showCheckbox
            ? columnWidthWithCheckbox
            : columnWidthWithoutCheckbox)}
          className={`${
            showCheckbox ? styles.childrenColumnWithCheckbox : ""
          } ${!active ? styles.disabled : ""}`}
        >
          <Form.Field required={required && active}>{children}</Form.Field>
        </Grid.Column>
      </Grid.Row>
    </>
  );
};

CheckedInputRow.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  children: PropTypes.node,
  showCheckbox: PropTypes.bool,
  isActive: PropTypes.func,
  setActive: PropTypes.func
};

CheckedInputRow.defaultProps = {
  required: false,
  showCheckbox: false
};

export default CheckedInputRow;
