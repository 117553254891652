import { actionType } from "../../constants/ApplicationsRecordByBlock";

const initialState = {
  data: {
    content: [],
    number: 0,
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
    size: 10
  },
  list: {
    content: [],
    number: 0,
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
    size: 10
  },
  isFetching: false,
  error: null
};

export default (state = initialState, action) => {
  if (!action) return state;

  switch (action.type) {
    case actionType.FETCH_SPRAY_TASKS_START: {
      return {
        ...state,
        isFetching: true
      };
    }

    case actionType.FETCH_SPRAY_TASKS: {
      return {
        ...state,
        data: {
          ...action.payload
        },
        isFetching: false
      };
    }
    case actionType.FETCH_SPRAY_TASKS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFetching: false
      };
    }
    case actionType.FETCH_SPRAY_TASKS_LIST_START: {
      return {
        ...state,
        isFetching: true
      };
    }
    case actionType.FETCH_SPRAY_TASKS_LIST: {
      return {
        ...state,
        list: {
          ...action.payload
        },
        isFetching: false
      };
    }
    case actionType.FETCH_SPRAY_TASKS_LIST_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFetching: false
      };
    }
    default:
      return state;
  }
};
