import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Form } from "formsy-semantic-ui-react";
import { getUsedVarietiesList } from "../../actions/Varieties";
import { Dropdown } from "semantic-ui-react";

const SelectUsedVariety = ({
  actions: { getUsedVarietiesList },
  varietiesList,
  isFetching,
  required,
  error,
  minItemForSearch,
  onChange,
  native,
  ...extraProps
}) => {
  useEffect(() => {
    getUsedVarietiesList();
  }, [getUsedVarietiesList]);

  const options = useMemo(
    () =>
      varietiesList
        .sort(content => content.name)
        .map(content => ({
          key: content.id,
          text: content.name,
          value: content.id
        })),
    [varietiesList]
  );

  useEffect(() => {
    if (required && options && options.length === 1) {
      onChange(null, { options, value: options[0].value });
    }
  }, [options]); // eslint-disable-line

  const props = {
    placeholder: "Select variety",
    fluid: true,
    ...extraProps,
    error,
    closeOnBlur: true,
    onChange,
    loading: isFetching,
    selection: true,
    required,
    search: options.length >= minItemForSearch,
    noResultsMessage: "Nothing was found",
    options
  };
  return native ? <Dropdown {...props} /> : <Form.Dropdown {...props} />;
};

SelectUsedVariety.propTypes = {
  actions: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  minItemForSearch: PropTypes.bool,
  native: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
  showArchived: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.array.isRequired,
    PropTypes.number.isRequired
  ]),
  varietiesList: PropTypes.array.isRequired
};

SelectUsedVariety.defaultProps = {
  disabled: false,
  error: false,
  isFetching: false,
  minItemForSearch: 10,
  native: false,
  onChange: () => {},
  required: false,
  showArchived: false,
  value: [],
  varietiesList: []
};

const mapStateToProps = ({
  varieties: {
    used_varieties: { content, isFetching }
  }
}) => {
  return {
    varietiesList: content,
    isFetching
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getUsedVarietiesList
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectUsedVariety);
