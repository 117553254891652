import React from "react";
import styles from "./index.module.css";
import { Button } from "semantic-ui-react";
import { history } from "../../store";
import { useDispatch } from "react-redux";
import { signOut } from "actions/User";
const LogoutPage = ({ changeRequestStatus }) => {
  const dispatch = useDispatch();
  const onChange = async () => {
    history.push("/account/logoff");
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.messageContainer}>
        <div className={styles.message}>
          Please contact your system administrator to add to a specific realm
        </div>
      </div>

      <div className={styles.buttonContainer}>
        <Button
          className={styles.logOutbtn}
          content="Log Out"
          onClick={() => {
            return changeRequestStatus(), onChange();
          }}
        />
      </div>
    </div>
  );
};

export default LogoutPage;
