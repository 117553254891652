import React from "react";
import PropTypes from "prop-types";
import { findKey } from "lodash";
import { Transition } from "semantic-ui-react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { clearApiResponsesAll } from "../../actions/ApiResults";

import { onlineSelector } from "../../selectors/common";
import OfflineMessageInfo from "../OfflineMessageInfo";

class OfflineMessages extends React.Component {
  state = {
    routes: [],
    showOfflineMessage: !this.props.online,
    showApiFailMessage: false,
    showApiSuccessMessage: false
  };

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { outbox, online, apiResults } = this.props;
    if (online !== prevProps.online) {
      this.setState({ showOfflineMessage: !online });
      setTimeout(this.closeOffline, 5000);
    }
    if (outbox.length === 0 && prevProps.outbox.length > 0) {
      const offLineErrors = findKey(apiResults.errors, ["online", false]);
      const offLineSuccess = findKey(apiResults.success, ["online", false]);
      if (offLineErrors) {
        this.setState({
          showApiFailMessage: true,
          showApiSuccessMessage: false,
          showOfflineMessage: false
        });
        setTimeout(this.closeApiResults, 5000);
      } else if (offLineSuccess) {
        this.setState({
          showApiFailMessage: false,
          showApiSuccessMessage: true,
          showOfflineMessage: false
        });
        setTimeout(this.closeApiResults, 5000);
      }
    }
  }

  closeOffline = () => {
    this.setState({ showOfflineMessage: false });
  };

  closeApiResults = () => {
    const { actions } = this.props;
    actions.clearApiResponsesAll();
    this.setState({
      showApiFailMessage: false,
      showApiSuccessMessage: false
    });
  };

  render() {
    const {
      showOfflineMessage,
      showApiFailMessage,
      showApiSuccessMessage
    } = this.state;

    return (
      <div>
        <Transition
          visible={
            showOfflineMessage && !(showApiFailMessage || showApiSuccessMessage)
          }
          animation="fade"
          duration={1000}
        >
          <OfflineMessageInfo
            message="Your’e offline. Some functionality may be unavailable"
            color="black"
            onClose={this.closeOffline}
          />
        </Transition>
        <Transition
          visible={showApiFailMessage}
          animation="fade"
          duration={1000}
        >
          <OfflineMessageInfo
            message="We were unable to update offline changes. Please revisit your task again"
            color="red"
            onClose={this.closeApiResults}
          />
        </Transition>
        <Transition
          visible={showApiSuccessMessage}
          animation="fade"
          duration={1000}
        >
          <OfflineMessageInfo
            message="Offline changes were updated successfully"
            color="green"
            onClose={this.closeApiResults}
          />
        </Transition>
      </div>
    );
  }
}

OfflineMessages.propTypes = {
  outbox: PropTypes.array,
  online: PropTypes.bool,
  apiResults: PropTypes.object,
  actions: PropTypes.object,
  clearApiResponsesAll: PropTypes.func
};

OfflineMessages.defaultProps = {};

function mapStateToProps(state) {
  const {
    apiResults,
    offline: { outbox }
  } = state;
  return {
    outbox,
    apiResults,
    online: onlineSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        clearApiResponsesAll
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OfflineMessages);
