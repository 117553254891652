export const actionType = {
  FETCH_PIECE_RATE_PAYROLLS_EXPANDED_DATA_START:
    "PIECE_RATE_PAYROLL/FETCH_PIECE_RATE_PAYROLLS_EXPANDED_DATA_START",
  FETCH_PIECE_RATE_PAYROLLS_EXPANDED_DATA:
    "PIECE_RATE_PAYROLL/FETCH_PIECE_RATE_PAYROLLS_EXPANDED_DATA",
  FETCH_PIECE_RATE_PAYROLLS_EXPANDED_DATA_FAIL:
    "PIECE_RATE_PAYROLL/FETCH_PIECE_RATE_PAYROLLS_EXPANDED_DATA_FAIL",
  FETCH_PIECE_RATE_PAYROLLS_SUMMARY_START:
    "PIECE_RATE_PAYROLL/FETCH_PIECE_RATE_PAYROLLS_SUMMARY_START",
  SET_PIECE_RATE_PAYROLLS_SUMMARY:
    "PIECE_RATE_PAYROLL/SET_PIECE_RATE_PAYROLLS_SUMMARY",
  FETCH_PIECE_RATE_PAYROLLS_SUMMARY_FAIL:
    "PIECE_RATE_PAYROLL/FETCH_PIECE_RATE_PAYROLLS_SUMMARY_FAIL",
  FETCH_PIECE_RATE_PAYROLLS_ALL_INFORMATION_START:
    "PIECE_RATE_PAYROLL/FETCH_PIECE_RATE_PAYROLLS_ALL_INFORMATION_START",
  SET_PIECE_RATE_PAYROLLS_ALL_INFORMATION:
    "PIECE_RATE_PAYROLL/SET_PIECE_RATE_PAYROLLS_ALL_INFORMATION",
  FETCH_PIECE_RATE_PAYROLLS_ALL_INFORMATION_FAIL:
    "PIECE_RATE_PAYROLL/FETCH_PIECE_RATE_PAYROLLS_ALL_INFORMATION_FAIL"
};
