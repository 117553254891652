import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import PropTypes from "prop-types";
import { Button, Grid, Header, Message, TextArea } from "semantic-ui-react";
import { Form } from "formsy-semantic-ui-react";
import ObjectID from "bson-objectid";

import AllocatePalletsTable from "./AllocatePalletsTable";
import { calcNotAllocatedPallets } from "../../Stock";
import moment from "moment";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { addBatchPallet } from "../../../../../actions/Pallets/pallets";
import styles from "./AllocatePallets.module.css";
import PrintPalletsLabelButton from "../../../Pallets/Labels/PrintPalletsLabelButton";
import SelectPackingShed from "../../../../../components/SelectPackingShed";
import { validationErrors } from "../../../../../utils/validationRules";
import ErrorLabel from "../../../../../components/ErrorLabel";

const AllocatePallets = ({ stock, actions: { addBatchPallet } }) => {
  const initialItem = useMemo(
    () => ({
      _id: ObjectID().toHexString(),
      area: { id: null },
      count: calcNotAllocatedPallets(stock)
    }),
    [stock]
  );
  const [items, setItems] = useState([initialItem]);
  const [packingShed, setPackingShed] = useState(null);
  const [notes, setNotes] = useState(null);
  const [pallets, setPallets] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {
    setItems([initialItem]);
    setNotes(null);
    setPallets(null);
    setIsCreating(false);
  }, [initialItem, stock]);

  const onValidSubmit = useCallback(() => {
    setIsCreating(true);
    const request = {
      createDate: moment().format("YYYY-MM-DD"),
      variety: { id: stock.variety.id },
      boxUnit: { id: stock.boxUnit.id },
      packingShed: { id: packingShed.id },
      unitsOnPallet: stock.boxUnit.unitsPerPallet,
      notes,
      items: items.map(item => ({
        areas: [
          {
            area: {
              id: item.area.id
            }
          }
        ],
        count: item.count
      })),
      updateStock: true
    };

    addBatchPallet(request)
      .then(resp => {
        if (resp.error) {
          setIsCreating(false);
          return;
        }

        setPallets(resp.pallets);
        setItems([initialItem]);
        setIsCreating(false);
      })
      .catch(() => {
        setIsCreating(false);
      });
    //eslint-disable-next-line
  }, [stock, items, notes, packingShed, addBatchPallet, initialItem]);

  const handleErrors = useCallback(() => {}, []);
  const notAllocatedPallets = calcNotAllocatedPallets(stock);

  const showCreatePalletForm = notAllocatedPallets > 0 && !pallets;
  return (
    <>
      {showCreatePalletForm && (
        <>
          <Grid>
            <Grid.Row>
              <Grid.Column width={16}>
                <Header>Allocate units to pallets</Header>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Form
            onValidSubmit={onValidSubmit}
            onInvalidSubmit={handleErrors}
            loading={false}
            autoComplete="off"
            ref={formRef}
          >
            <Grid>
              <AllocatePalletsTable
                stock={stock}
                items={items}
                setItems={setItems}
              />
              <Grid.Row>
                <Grid.Column width={13}>
                  <Form.Field required className="sprayField">
                    <label>Select packing shed</label>
                    <SelectPackingShed
                      name="packingShed"
                      validation={"isRequired"}
                      validationErrors={validationErrors({
                        isDefaultRequiredValue: "Packing shed"
                      })}
                      errorLabel={ErrorLabel}
                      required
                      value={packingShed && packingShed.id}
                      onChange={(_, data) => setPackingShed({ id: data.value })}
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className={styles.tableItem}>
                <Grid.Column width={13}>
                  <Form.Field>
                    <label>Notes</label>
                    <TextArea
                      name="notes"
                      size="large"
                      value={notes}
                      onChange={event => setNotes(event.target.value)}
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={13}>
                  <Button
                    loading={isCreating}
                    disabled={isCreating}
                    type="button"
                    onClick={() => formRef.current.submit()}
                  >
                    Create pallets
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </>
      )}
      {pallets && (
        <>
          <Grid>
            <Grid.Row>
              <Grid.Column width={16}>
                <Message color={"blue"}>
                  {pallets.length} pallets has been created successfully
                </Message>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <PrintPalletsLabelButton
                  pallets={pallets}
                  loading={isCreating}
                  disabled={isCreating}
                >
                  Print Labels
                </PrintPalletsLabelButton>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </>
      )}
    </>
  );
};

AllocatePallets.propTypes = {
  stock: PropTypes.object.isRequired,
  actions: PropTypes.object
};

AllocatePallets.defaultProps = {};

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ addBatchPallet }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AllocatePallets);
