import React from "react";
import Layout from "../Layout";
import { Container, Grid, Header, Segment, Sidebar } from "semantic-ui-react";
import classNames from "classnames";
import ListTable from "../ListTable";
import styles from "./PageWithTableHolder.module.css";

const PageWithTableHolder = ({
  filterName = "",
  actionsButtons,
  className,
  defaultSort,
  getData,
  isFetching,
  location,
  onRowClick,
  pageTitle,
  mainButton,
  route,
  selectedItems,
  setSelectedItems,
  sidebarComponent,
  tableData,
  tableColumns
}) => {
  return (
    <Sidebar.Pushable className={classNames(styles.root, className)}>
      {sidebarComponent}
      <Layout
        route={route}
        location={location}
        classForMain={styles.mainHolder}
      >
        <Sidebar.Pusher className={styles.pageWrapper}>
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column>
                <Header as="h2">{pageTitle}</Header>
              </Grid.Column>
              {mainButton && (
                <Grid.Column textAlign="right">{mainButton}</Grid.Column>
              )}
            </Grid.Row>
          </Grid>
          <Segment>
            <Grid>
              {actionsButtons && (
                <Grid.Row>
                  <Grid.Column width={16}>
                    <Container fluid textAlign="right">
                      {actionsButtons}
                    </Container>
                  </Grid.Column>
                </Grid.Row>
              )}
              <Container fluid>
                <ListTable
                  filterName={filterName}
                  columns={tableColumns}
                  currentPage={tableData.number}
                  data={tableData.content}
                  defaultSort={defaultSort}
                  getData={getData}
                  getTrProps={(state, rowInfo) => ({
                    onClick: () => onRowClick(rowInfo.original)
                  })}
                  isFetching={isFetching}
                  keyField={"id"}
                  pageSize={tableData.size}
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  tableClassName={styles.table}
                  totalElements={tableData.totalElements}
                  totalPages={tableData.totalPages}
                  withDateRange={false}
                  withItemsCount={false}
                  withSearch={false}
                  withSelection
                />
              </Container>
            </Grid>
          </Segment>
        </Sidebar.Pusher>
      </Layout>
    </Sidebar.Pushable>
  );
};

export default PageWithTableHolder;
