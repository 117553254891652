import moment from "moment";
import {
  ExtAreaFilter,
  ExtBoxUnitFilter,
  ExtUsedVarietyFilter
} from "../../../components/ExpandedLocalDataTable/externalFilters";
import { includes } from "../../../components/ExpandedTable/filters";

export const columns = [
  {
    id: "id",
    Header: "Id",
    accessor: d => d.id
  },
  {
    id: "createDate",
    Header: "Date",
    accessor: d => d.createDate && moment(d.createDate).format("DD/MM/YYYY")
  },
  {
    id: "variety",
    Header: "Variety",
    accessor: d => d.variety && d.variety.name,
    sortFields: ["variety.name"],
    ExternalFilter: ExtUsedVarietyFilter,
    filter: includes("variety.id")
  },
  {
    id: "boxUnit",
    Header: "Box unit",
    accessor: d => d.boxUnit && d.boxUnit.displayName,
    sortFields: ["boxUnit.displayName"],
    ExternalFilter: ExtBoxUnitFilter,
    filter: includes("boxUnit.id")
  },
  {
    id: "area",
    Header: "Area",
    accessor: d =>
      d.areas.map(
        ({ area }) =>
          `${area.parent ? `${area.parent.name} - ` : ""}${area.name}`
      ),
    sortFields: ["area.parent.name", "area.name"],
    ExternalFilter: ExtAreaFilter,
    filter: includes("area.id")
  },
  {
    id: "registrationNumber",
    Header: "Registration",
    accessor: d =>
      d.areas.map(({ area }) => area.registrationNumber).join(", "),
    sortFields: ["area.registrationNumber"]
  },
  {
    id: "unitsOnPallet",
    Header: "Units on pallet",
    accessor: d => d.unitsOnPallet
  }
];

export const exelColumns = [
  {
    id: "id",
    Header: "Id",
    accessor: d => d.id
  },
  {
    id: "dispatchDate",
    Header: "Dispatch Date",
    accessor: d => d.createDate && moment(d.createDate).format("DD/MM/YYYY")
  },
  {
    id: "createDate",
    Header: "Pallet Date",
    accessor: d => d.createDate && moment(d.createDate).format("DD/MM/YYYY")
  },
  {
    id: "vendor",
    Header: "Client/Buyer",
    accessor: d => d.vendor
  },
  {
    id: "orderNumber",
    Header: "Order number",
    accessor: d => d.orderNumber
  },
  {
    id: "notes",
    Header: "Notes",
    accessor: d => d.notes
  },
  {
    id: "variety",
    Header: "Variety",
    accessor: d => d.variety && d.variety.name,
    sortFields: ["variety.name"],
    ExternalFilter: ExtUsedVarietyFilter,
    filter: includes("variety.id")
  },
  {
    id: "boxUnit",
    Header: "Box unit",
    accessor: d => d.boxUnit && d.boxUnit.displayName,
    sortFields: ["boxUnit.displayName"],
    ExternalFilter: ExtBoxUnitFilter,
    filter: includes("boxUnit.id")
  },
  {
    id: "area",
    Header: "Area",
    accessor: d =>
      d.area &&
      `${d.area.parent ? `${d.area.parent.name} - ` : ""}${d.area.name}`,
    sortFields: ["area.parent.name", "area.name"],
    ExternalFilter: ExtAreaFilter,
    filter: includes("area.id")
  },
  {
    id: "registrationNumber",
    Header: "Registration",
    accessor: d => d.area && d.area.registrationNumber,
    sortFields: ["area.registrationNumber"]
  },
  {
    id: "unitsOnPallet",
    Header: "Units on pallet",
    accessor: d => d.unitsOnPallet
  }
];
