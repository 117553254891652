export const ROLE_COLORS = {
  OWNER: "#DED500",
  EXTERNAL_AGRONOMIST: "#37B963",
  OPERATOR: "#E87432",
  INTERNAL_AGRONOMIST: "#B4CA2D",
  WORKER: "#B25E21",
  SUPERVISOR: "#1DC5CF",
  FARM_MANAGER: "#3A67BF",
  ADMIN: "#AD3ABF",
  HEAD_OFFICE: "#DE4267"
};
