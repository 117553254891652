import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid, Segment, Button, Header, Dropdown } from 'semantic-ui-react';
import { history } from '../../../store';
import styles from './HeaderSprayTasks.module.css';
import { Mixpanel } from '../../../tools/mixpanel/Mixpanel';
import { MixpanelEvents } from '../../../tools/mixpanel/MixpanelEvents';

/*
const getStatusCounts = (tasks, statuses) => {
  const count = Object.assign({}, statuses);
  Object.keys(count).forEach(status => {
    count[status] = tasks.content.filter(task => task.status === status).length;
  });
  return count;
};

 */
// eslint-disable-next-line
function HeaderSprayTasks({ tasks, statuses, route, Can, online, activeFiltersMatch, activeTaskMatch }) {
  const onAddClick = () => {
    Mixpanel.track(MixpanelEvents.BUTTON_CLICK, {
      action: 'add_task',
    });
    history.push('/spray_diary/create_spray_task');
  };

  const handelRawDataViewClick = useCallback(() => {
    history.push(`${route.href}/records`);
  }, [route]);

  //const countStatus = getStatusCounts(tasks, statuses);
  return (
    <>
      <Segment basic className={styles.headerTasks}>
        <Grid>
          <Grid.Row>
            <Grid.Column width={11}>
              <Header as="h2" className={styles.taskName}>
                {route.name}
                <Can I="view" a="/spray_diary/records">
                  <Dropdown text={' '} floating className={styles.dropdownHeaderMenu}>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={handelRawDataViewClick}>Raw data view</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Can>
              </Header>
            </Grid.Column>
            <Grid.Column width={5} textAlign="right">
              <Can I="add" a="spray_tasks">
                <Button primary size="large" onClick={onAddClick}>
                  Add
                </Button>
              </Can>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </>
  );
}

HeaderSprayTasks.propTypes = {
  farms: PropTypes.array.isRequired,
  selectedFarmId: PropTypes.number,
  route: PropTypes.object,
  Can: PropTypes.func,
  online: PropTypes.bool,
  activeFiltersMatch: PropTypes.string,
  activeTaskMatch: PropTypes.string,
};

HeaderSprayTasks.defaultProps = {
  farms: [],
};

export default HeaderSprayTasks;
