import PropTypes from "prop-types";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import { Form } from "formsy-semantic-ui-react";
import { Button, Dropdown } from "semantic-ui-react";
import ObjectId from "bson-objectid";

import styles from "./SelectWithSelectAll.module.css";
import ReactDOM from "react-dom";

const SelectWithSelectAll = ({
  loading,
  options,
  validation,
  showSelectAll,
  multiple,
  value,
  native,
  additionalOptions,
  ...extraProps
}) => {
  const dropdownId = useMemo(() => ObjectId().toHexString(), []);
  const [showBars, setShowBars] = useState(false);
  const selectAllRef = useRef(null);

  const handleBlurOnEsc = useCallback(
    e => {
      if (e.key === "Escape") {
        const dropdown = document.getElementById(dropdownId);

        if (dropdown) {
          setShowBars(false);
          const input = dropdown.getElementsByTagName("input")[0];

          if (input) {
            input.blur();
          }
        }
      }
    },
    [dropdownId]
  );

  const handleBlur = useCallback(
    e => {
      const dropdown = document.getElementById(dropdownId);
      if (
        (dropdown &&
          // eslint-disable-next-line
          !ReactDOM.findDOMNode(dropdown).contains(e.target) &&
          !selectAllRef.current) ||
        (selectAllRef.current &&
          // eslint-disable-next-line
          !ReactDOM.findDOMNode(selectAllRef.current).contains(e.target))
      ) {
        setShowBars(false);
      }
    },
    [dropdownId]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleBlurOnEsc);
    document.body.addEventListener("click", handleBlur);

    // returned function will be called on component unmount
    return () => {
      window.removeEventListener("keydown", handleBlurOnEsc);
      document.body.removeEventListener("click", handleBlur);
    };
  }, [handleBlur, handleBlurOnEsc]);

  const arrangeItems = useCallback(() => {
    const dropdown = document.getElementById(dropdownId);
    if (showBars && dropdown) {
      const optionsHolder = dropdown.getElementsByClassName("menu")[0];

      if (selectAllRef.current) {
        const dropdownBounding = dropdown.getBoundingClientRect();
        const optionsBounding = optionsHolder.getBoundingClientRect();
        const selectAllBounding = selectAllRef.current.getBoundingClientRect();

        if (optionsBounding.y > dropdownBounding.y) {
          selectAllRef.current.style.paddingTop = `${optionsHolder.offsetHeight -
            1}px`;
        } else {
          selectAllRef.current.style.top = `${-optionsBounding.height -
            selectAllBounding.height +
            1}px`;
        }
      }
    }
  }, [dropdownId, showBars, selectAllRef]);

  useEffect(() => {
    arrangeItems();
  }, [arrangeItems, showBars, value]);

  const totalOptions = [...additionalOptions, ...options];

  const shouldShowSelectAll =
    multiple && showSelectAll && value.length < totalOptions.length && showBars;
  const props = {
    placeholder: "Select employee",
    noResultsMessage: "Nothing was found",
    fluid: true,
    ...extraProps,
    id: dropdownId,
    value,
    error: !validation,
    closeOnBlur: true,
    loading,
    selection: true,
    options: totalOptions,
    className: `${styles.dropdown} ${shouldShowSelectAll && styles.showBar}`,
    onFocus: () => setShowBars(true),
    multiple
  };

  return (
    <div
      className={`${styles.dropdownHolder} ${
        extraProps.className ? extraProps.className : ""
      }`}
    >
      {native ? <Dropdown {...props} /> : <Form.Dropdown {...props} />}
      {shouldShowSelectAll && (
        <div className={styles.selectAllFooter} ref={selectAllRef}>
          <div className={styles.selectAllHolder}>
            <Button
              onClick={() => {
                extraProps.onChange &&
                  extraProps.onChange(null, {
                    value: totalOptions.map(option => option.value)
                  });
                setShowBars(false);
              }}
              fluid
            >
              Select All
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

SelectWithSelectAll.propTypes = {
  additionalOptions: PropTypes.array.isRequired,
  className: PropTypes.any,
  disabled: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  multiple: PropTypes.bool,
  native: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.any,
  showSelectAll: PropTypes.bool.isRequired,
  validation: PropTypes.bool.isRequired,
  value: PropTypes.array.isRequired
};

SelectWithSelectAll.defaultProps = {
  additionalOptions: [],
  disabled: false,
  loading: false,
  native: false,
  onChange: () => {},
  showSelectAll: false,
  validation: true,
  value: []
};

export default SelectWithSelectAll;
