import React, { useState, useCallback, useEffect } from "react";
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Menu,
  Dimmer,
  Popup,
  Loader
} from "semantic-ui-react";
import { get, isEmpty } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import { abilitiesSelector } from "selectors/user";
import {
  createShed,
  searchProductsByName,
  updateShed,
  pendingProduct,
  changeArchivedStatus
} from "actions/Sheds";
import GeneralInformation from "./components/GeneralInformation";
import { shedShape } from "constants/Sheds/types";
import Inventory from "./components/Inventory";
import Farms from "./components/Farms";
import Thresholds from "./components/Thresholds";
import ShedsActionButtons from "../ShedsActionButtons";
import { SHED_INITIAL_VALUES } from "constants/Sheds";
import styles from "./Sidebar.module.css";
import { useParams } from "react-router";

const REQUIRED_FIELDS = ["name"];
function Sidebar({
  shed,
  onClose,
  onEditClick,
  chemicalList,
  setOpenMessages,
  isArchivedView,
  sheds
}) {
  const dispatch = useDispatch();
  const Can = useSelector(abilitiesSelector);
  const [activeTab, setactiveTab] = useState("Inventory");
  const [negativeThresholds, setNegativeThresholds] = useState();

  const onArchiveClick = () => {
    dispatch(changeArchivedStatus([shed.id], !isArchivedView));
  };

  const handleItemClick = (e, { name }) => {
    setactiveTab(name);
  };
  if (!shed) {
    return null;
  }
  const validateValues = async values => {
    const errors = {};
    const { name } = values;

    REQUIRED_FIELDS.forEach(filedName => {
      if (!get(values, filedName)) {
        errors[filedName] = "Required field";
      }
    });

    if (name && name !== get(shed, "name")) {
      const { content } = await searchProductsByName(name);

      if (content.some(product => product.name === name)) {
        errors.name = "Shed with the same name already exists";
      }
    }

    return errors;
  };

  function onSubmit(values) {
    if (shed) {
      dispatch(updateShed(values.id, values));
    } else {
      dispatch(createShed(values));
    }
  }
  return (
    <Container fluid className={styles.root}>
      <Grid className={styles.sidebarHeader}>
        <Grid.Row columns={"equal"} verticalAlign="middle">
          <Grid.Column className={styles.headerColumn}>
            <Header className={styles.name} as="h1">
              {shed.name}
            </Header>
          </Grid.Column>
          <Can I="update" a="sheds">
            <Grid.Column floated="right">
              <Popup
                inverted
                position="bottom center"
                content={"Edit"}
                size="tiny"
                trigger={
                  <div className={styles.editButtonWrapper}>
                    <Button
                      onClick={onEditClick}
                      className={styles.editButton}
                      floated="right"
                      icon={<Icon name="edit" />}
                      size="small"
                    />
                  </div>
                }
              />
            </Grid.Column>
          </Can>
          <Grid.Column width={1} floated="right">
            <Popup
              inverted
              position="bottom center"
              content={"Close"}
              size="tiny"
              trigger={
                <Button
                  onClick={onClose}
                  className={styles.closeButton}
                  floated="right"
                  icon={<Icon className="tuf-times" />}
                  size="small"
                />
              }
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Formik
        validate={validateValues}
        enableReinitialize
        validateOnChange={false}
        onSubmit={onSubmit}
        initialValues={shed || SHED_INITIAL_VALUES}
      >
        {props => (
          <>
            <div className={styles.generalInformationWrapper}>
              <GeneralInformation shed={shed} />
            </div>
            <Grid className={styles.body}>
              <div className={styles.tabsHolder}>
                <Menu pointing secondary className={styles.tabsMenu}>
                  <Menu.Item
                    name="Inventory"
                    active={activeTab === "Inventory"}
                    onClick={handleItemClick}
                  />
                  <Menu.Item
                    name="Thresholds"
                    active={activeTab === "Thresholds"}
                    onClick={handleItemClick}
                  >
                    <div className={styles.headerWrapper}>
                      Thresholds
                      {negativeThresholds > 0 && shed && (
                        <div className={styles.countWrapper}>
                          {negativeThresholds}
                        </div>
                      )}
                    </div>
                  </Menu.Item>
                  <Menu.Item
                    name="Farms"
                    active={activeTab === "Farms"}
                    onClick={handleItemClick}
                  />
                </Menu>
                <div
                  className={`${styles.tabItem} ${
                    activeTab === "Inventory" ? styles.visibleTab : ""
                  }`}
                >
                  <Inventory
                    chemicalList={chemicalList}
                    shed={shed}
                    sheds={sheds}
                  />
                </div>
                <div
                  className={`${styles.tabItem} ${
                    activeTab === "Thresholds" ? styles.visibleTab : ""
                  }`}
                >
                  <Thresholds
                    setNegativeThresholds={setNegativeThresholds}
                    shed={shed}
                  />
                </div>
                <div
                  className={`${styles.tabItem} ${
                    activeTab === "Farms" ? styles.visibleTab : ""
                  }`}
                >
                  <Farms shed={shed} />
                </div>
              </div>
            </Grid>
          </>
        )}
      </Formik>
      <div className={styles.buttonContainer}>
        <ShedsActionButtons
          archiveButtonTitle={isArchivedView ? "Restore" : "Archive"}
          archiveButtonIcon="archive"
          onArchiveButtonClick={onArchiveClick}
          selectedProducts={shed}
          setOpenMessages={setOpenMessages}
          withModal={!isArchivedView}
        />
      </div>
    </Container>
  );
}

Sidebar.propTypes = {
  shed: shedShape
};
export default Sidebar;
