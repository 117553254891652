import React, { useCallback, useEffect, useState } from "react";
import QRCode from "qrcode";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";
import { bindActionCreators } from "redux";
import {
  BlobProvider,
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View
} from "@react-pdf/renderer";

import { generateBarcodeSeries } from "../../../utils/employeesBarcodes";
import {
  infoMessageType,
  messagesIds,
  putMessage
} from "../../../actions/InfoMessages";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row"
  },
  section: {
    width: 71,
    alignItems: "center",
    marginVertical: 12
  },
  barcode: {
    width: 57,
    height: 57
  },
  text: {
    fontSize: 6
  }
});

const EmployeesBarcodesPDF = ({
  employeeBarcodes,
  onClick,
  onPDFshow,
  actions: { putMessage }
}) => {
  const [generatePdfInProgress, setGeneratePdfInProgress] = useState(false);
  const [barcodeWithQr, setBarcodeWithQr] = useState(null);

  useEffect(() => {
    if (generatePdfInProgress) {
      const generateBarcodesWithQr = async () =>
        Promise.all(
          generateBarcodeSeries(
            employeeBarcodes.employee,
            employeeBarcodes.firstBarcode,
            employeeBarcodes.lastBarcode
          ).map(async barcode => ({
            ...barcode,
            qr: await QRCode.toDataURL(barcode.barcode, { type: "png" })
          }))
        ).then(res => setBarcodeWithQr(res));
      generateBarcodesWithQr();
    }
  }, [generatePdfInProgress, employeeBarcodes]);

  const generateDocument = useCallback(() => {
    return (
      <Document>
        {Array.from(Array(barcodeWithQr.length).keys())
          .filter(i => i % 4 === 0)
          .map(pageOffset => (
            <Page
              key={`page_${pageOffset}`}
              size={[284, 113]}
              style={styles.page}
            >
              {[0, 1, 2, 3].map(itemOffset => {
                const i = pageOffset + itemOffset;
                return (
                  i < barcodeWithQr.length && (
                    <View key={`item_${i}`} style={styles.section}>
                      <Image src={barcodeWithQr[i].qr} style={styles.barcode} />
                      <Text style={styles.text}>
                        {barcodeWithQr[i].numberPart}
                      </Text>
                      <Text style={styles.text}>
                        {barcodeWithQr[i].employeePart}
                      </Text>
                      <Text style={styles.text}>
                        {barcodeWithQr[i].employee.firstName}{" "}
                        {barcodeWithQr[i].employee.lastName &&
                          barcodeWithQr[i].employee.lastName[0]}
                      </Text>
                    </View>
                  )
                );
              })}
            </Page>
          ))}
      </Document>
    );
  }, [barcodeWithQr]);

  const handleBlobCreation = useCallback(
    ({ blob, url, loading, error }) => {
      if (!loading && url) {
        window.open(url, "_blank", "noopener,noreferrer");
        onPDFshow();
      }

      if (error) {
        putMessage({
          type: infoMessageType.ERROR,
          messageId: messagesIds.GENERIC,
          extraData: {
            message: `Error while trying to generate PDF`
          }
        });
      }

      if (!loading) {
        setGeneratePdfInProgress(false);
      }

      return null;
    },
    [putMessage, onPDFshow, setGeneratePdfInProgress]
  );

  return (
    <>
      <Button
        primary
        size="large"
        type="button"
        loading={generatePdfInProgress}
        disabled={generatePdfInProgress}
        onClick={() => {
          onClick(() => setGeneratePdfInProgress(true));
        }}
      >
        PDF
      </Button>
      {generatePdfInProgress && barcodeWithQr && (
        <BlobProvider document={generateDocument()}>
          {handleBlobCreation}
        </BlobProvider>
      )}
    </>
  );
};

EmployeesBarcodesPDF.propTypes = {
  employeeBarcodes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onPDFshow: PropTypes.func.isRequired,
  actions: PropTypes.object
};

EmployeesBarcodesPDF.defaultProps = {
  onClick: defaultAction => {
    defaultAction();
  },
  onPDFshow: () => {}
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      putMessage
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeesBarcodesPDF);
