import PropTypes from "prop-types";
import React from "react";
import { Header, Icon, Segment } from "semantic-ui-react";
import styles from "./SegmentClosableControlled.module.css";

const SegmentClosableControlled = ({
  title,
  children,
  open = null,
  onArrowClick
}) => {
  return (
    <Segment className={styles.segmentHolder}>
      <div className={styles.headerHolder}>
        <div className={styles.chevron}>
          <Icon
            onClick={() => {
              if (!open) onArrowClick();
            }}
            className={open ? "tuf-chevron-down" : "tuf-chevron-right"}
          />
        </div>
        <div>
          <Header as="h2" className={`${styles.headerTitle}`}>
            {title}
          </Header>
        </div>
      </div>
      <div className={`${styles.childrenHolder} ${open ? styles.open : ""}`}>
        {children}
      </div>
    </Segment>
  );
};

SegmentClosableControlled.propTypes = {
  children: PropTypes.element.isRequired,
  onArrowClick: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired
};

SegmentClosableControlled.defaultProps = {
  open: false
};

export default SegmentClosableControlled;
