import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";
import { bindActionCreators } from "redux";

import { abilitiesSelector } from "../../../../../selectors/user";
import { getParentPageHref } from "../../../../../utils/routeHelpers";
import LayoutOverlay from "../../../../../components/LayoutOverlay";
import styles from "./EditPackedBoxes.module.css";
import { history } from "../../../../../store";
import Loader from "../../../../../components/Loader";
import { getPackedBoxById } from "../../../../../actions/PackedBoxes/packedBoxes";
import EditPackedBoxesForm from "./EditPackedBoxesForm";

class EditPackedBoxes extends Component {
  state = {
    isFetching: false,
    packedBoxes: null
  };

  constructor(props) {
    super(props);
    this.packedBoxForm = React.createRef();
  }

  async componentWillMount() {
    const { match } = this.props;
    const { id } = match.params;
    if (id) {
      this.getPackedBoxById(id);
    } else {
      this.setState({
        isFetching: false
      });
    }
  }

  getPackedBoxById = id => {
    const {
      actions: { getPackedBoxById }
    } = this.props;
    this.setState({
      isFetching: true
    });

    getPackedBoxById(id)
      .then(packedBoxes => {
        this.setState({
          isFetching: false,
          packedBoxes
        });
      })
      .catch(error => {
        if (error.response.status === 404) {
          history.push("/404");
        }
        this.setState({
          isFetching: false
        });
      });
  };

  doSubmitForm = () => {
    if (this.packedBoxForm.current) {
      this.packedBoxForm.current.submit();
    }
  };

  render() {
    const { route, Can } = this.props;
    const { isFetching, packedBoxes } = this.state;

    return (
      <LayoutOverlay
        closeLink={getParentPageHref(route)}
        route={route}
        childrenOnly
        footer={
          <div className={styles.buttonsFooter}>
            <Can I="add" a="packed_boxes">
              <Button primary size="large" onClick={this.doSubmitForm}>
                Save
              </Button>
            </Can>
          </div>
        }
      >
        {isFetching ? (
          <Loader />
        ) : (
          <EditPackedBoxesForm
            route={route}
            initialValues={packedBoxes}
            formRef={this.packedBoxForm}
          />
        )}
      </LayoutOverlay>
    );
  }
}

EditPackedBoxes.propTypes = {
  route: PropTypes.object.isRequired,
  packedBox: PropTypes.object,
  match: PropTypes.object,
  actions: PropTypes.object,
  Can: PropTypes.func
};

EditPackedBoxes.defaultProps = {
  route: { name: "{%name%}" }
};

const mapStateToProps = state => {
  return {
    Can: abilitiesSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getPackedBoxById
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPackedBoxes);
