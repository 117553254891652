import { actionType } from "../../constants/Scouting";
import { set } from "lodash";

const initialState = {
  data: { content: [] },
  preArchivedTasks: null,
  currentScouting: null,
  uploadingAttachments: [],
  filterData: null,
  isFilterDataFetching: false,
  isFetching: false,
  isPrinting: false,
  isUpdating: false,
  isDeleting: false,
  error: null
};

export default (state = initialState, action) => {
  if (!action) return state;

  switch (action.type) {
    case actionType.FETCH_SCOUTING_START:
    case actionType.FETCH_SCOUTING_BY_ID_START:
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case actionType.SET_SCOUTING_DATA:
      return {
        ...state,
        data: action.payload,
        preArchivedTasks: null,
        isFetching: false
      };
    case actionType.FETCH_SCOUTING_FAIL:
    case actionType.FETCH_SCOUTING_BY_ID_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };

    case actionType.SET_CURRENT_SCOUTING:
      return {
        ...state,
        currentScouting: action.payload,
        isFetching: false
      };

    case actionType.UPDATE_SCOUTING_START:
      return {
        ...state,
        isUpdating: true
      };

    case actionType.UPDATE_SCOUTING:
      return {
        ...state,
        currentScouting: action.payload,
        isUpdating: false
      };

    case actionType.ARCHIVE_SCOUTING_START:
      return {
        ...state,
        isDeleting: true
      };

    case actionType.ARCHIVE_SCOUTING:
      return {
        ...state,
        preArchivedTasks: state.data.content,
        data: {
          ...state.data,
          content: state.data.content.filter(
            sc => !action.payload.includes(sc.id)
          )
        },
        isDeleting: false
      };

    case actionType.ARCHIVE_SCOUTING_FAIL:
      return {
        ...state,
        error: action.payload,
        isDeleting: false
      };

    case actionType.UNARCHIVE_SCOUTING_START:
      return {
        ...state,
        isFetching: true
      };

    case actionType.UNARCHIVE_SCOUTING_FAIL:
      return {
        ...state,
        error: action.payload,
        isFetching: false
      };

    case actionType.UPDATE_SCOUTING_FAIL:
      return {
        ...state,
        error: action.payload,
        isUpdating: false
      };

    case actionType.EDIT_SCOUTING:
      return {
        ...state,
        data: {
          content: state.data.content.map(sc => {
            if (sc.id === action.payload.id) {
              return {
                ...sc,
                [action.payload.name]: action.payload.value
              };
            }
            return sc;
          })
        }
      };

    case actionType.DELETE_ATTACHMENT:
      return {
        ...state,
        data: {
          content: [
            {
              ...state.data.content[0],
              attachments: [
                ...state.data.content[0].attachments.filter(
                  a => a.id !== action.payload
                )
              ]
            }
          ]
        }
      };

    case actionType.EDIT_SCOUTING_ATTACHMENT:
      return {
        ...state,
        data: {
          content: [
            {
              ...state.data.content[0],
              attachments: [
                ...state.data.content[0].attachments.map(a => {
                  if (a.id !== action.payload.id) {
                    const temp = { ...a };
                    set(temp, action.payload.name, action.payload.value);
                    return temp;
                  }
                  return a;
                })
              ]
            }
          ]
        }
      };

    case actionType.FETCH_SCOUTING_FILTER_START:
      return {
        ...state,
        isFilterDataFetching: true
      };
    case actionType.FETCH_SCOUTING_FILTER:
      return {
        ...state,
        isFilterDataFetching: false,
        filterData: action.payload
      };

    case actionType.FETCH_SCOUTING_FILTER_FAIL:
      return {
        ...state,
        error: action.payload,
        isFilterDataFetching: false
      };

    case actionType.PRINT_SCOUTING_START:
      return {
        ...state,
        isPrinting: true
      };

    case actionType.PRINT_SCOUTING_END:
      return {
        ...state,
        isPrinting: false
      };
    default:
      return state;
  }
};
