import { actionType } from "../../constants/GeneralTaskWorkingTimes";
import { axiosProxy } from "../../utils/axiosProxy";
import { WorkingTime } from "../../models/working_time.model";
import {
  addWorkingTimeToGeneralTask,
  editWorkingTimeOfGeneralTask,
  updateGeneralTaskStatus
} from "../GeneralTasks/generalTasks";
import { getRefreshToken } from "actions/User";

const url = "/general_task/working_times";

export const fetchGeneralTasksWorkingTimes = ({
  page,
  size,
  filters,
  dateFrom,
  dateTo,
  unpaged = false
}) => async dispatch => {
  try {
    dispatch({ type: actionType.GENERAL_TASK_WORKING_TIMES_FETCH_START });

    const params = new URLSearchParams();

    if (unpaged) {
      params.append("unpaged", "true");
    } else {
      params.append("page", page);
      params.append("size", size);
    }

    if (dateFrom)
      params.append(
        "startAtAfter",
        new Date(dateFrom).toISOString().substr(0, 19)
      );
    if (dateTo)
      params.append(
        "endAtBefore",
        new Date(dateTo).toISOString().substr(0, 19)
      );

    Object.entries(filters).forEach(
      ([name, value]) => value && params.append(name, value.toString())
    );

    const res = await axiosProxy({ method: "GET", params, url });

    dispatch({
      type: actionType.GENERAL_TASK_WORKING_TIMES_FETCH,
      payload: {
        ...res.data,
        content: res.data.content.map(wt => new WorkingTime(wt))
      }
    });
    return res.data;
  } catch (e) {
    dispatch({
      type: actionType.GENERAL_TASK_WORKING_TIMES_FETCH_ERROR,
      payload: e
    });
  }
};

export const updateWorkingTime = (id, data) => async (dispatch, getState) => {
  const {
    areas,
    employee,
    sprayDiary,
    contractors,
    employeeGroups
  } = getState();
  try {
    dispatch({
      type: actionType.GENERAL_TASK_WORKING_TIME_UPDATE_START,
      payload: { id }
    });

    const res = await axiosProxy({
      method: "PUT",
      data,
      url: `${url}/${id}`
    });
    const newData = {
      ...res.data,
      areas: areas.list.content
        .filter(area => res.data.areas.some(({ areaId }) => areaId === area.id))
        .map(filtredArea => {
          return {
            area: filtredArea,
            areaId: filtredArea.id
          };
        }),
      contractor: contractors.list.content.find(
        contractor => res.data.contractorId === contractor.id
      ),
      employee: employee.list.content.find(
        employee => res.data.employeeId === employee.id
      ),
      employeesGroup: employeeGroups.list.content.find(
        employeeGroup => res.data.employeesGroupId === employeeGroup.id
      )
    };

    editWorkingTimeOfGeneralTask(newData)(dispatch);

    dispatch({
      type: actionType.GENERAL_TASK_WORKING_TIME_UPDATE,
      payload: newData
    });
  } catch (e) {
    dispatch({
      type: actionType.GENERAL_TASK_WORKING_TIME_UPDATE_FAIL,
      payload: e
    });
    e.response.status === 401 &&
      dispatch(getRefreshToken()).then(dispatch(updateWorkingTime(id, data)));
  }
};

export const createWorkingTime = ({ formData }) => async (
  dispatch,
  getState
) => {
  const {
    areas,
    employee,
    sprayDiary,
    contractors,
    employeeGroups
  } = getState();
  try {
    dispatch({ type: actionType.GENERAL_TASK_WORKING_TIME_CREATE_START });

    const res = await axiosProxy({ method: "POST", url, data: formData });
    const newData = {
      ...res.data,
      areas: areas.list.content
        .filter(area => res.data.areas.some(({ areaId }) => areaId === area.id))
        .map(filtredArea => {
          return {
            area: filtredArea,
            areaId: filtredArea.id
          };
        }),
      contractor: contractors.list.content.find(
        contractor => res.data.contractorId === contractor.id
      ),
      employee: employee.list.content.find(
        employee => res.data.employeeId === employee.id
      ),
      employeesGroup: employeeGroups.list.content.find(
        employeeGroup => res.data.employeesGroupId === employeeGroup.id
      )
    };
    addWorkingTimeToGeneralTask(newData)(dispatch);
    updateGeneralTaskStatus(formData.generalTaskId)(dispatch);

    dispatch({
      type: actionType.GENERAL_TASK_WORKING_TIME_CREATE,
      payload: { workingTime: newData }
    });
  } catch (e) {
    dispatch({
      type: actionType.GENERAL_TASK_WORKING_TIME_CREATE_FAIL,
      payload: e
    });
    e.response.status === 401 &&
      dispatch(getRefreshToken()).then(dispatch(createWorkingTime(formData)));
  }
};

export const deleteGeneralTaskWorkingTime = id => async dispatch => {
  try {
    dispatch({ type: actionType.GENERAL_TASK_WORKING_TIME_DELETE_START });
    const res = await axiosProxy({
      method: "DELETE",
      url: `${url}/${id}`
    });

    dispatch({
      type: actionType.GENERAL_TASK_WORKING_TIME_DELETE,
      payload: { id, res }
    });

    return res;
  } catch (e) {
    e.response.status === 401 &&
      dispatch(getRefreshToken()).then(
        dispatch(deleteGeneralTaskWorkingTime(id))
      );
    dispatch({
      type: actionType.GENERAL_TASK_WORKING_TIME_DELETE_FAIL,
      payload: e
    });
  }
};

export const archiveGeneralTaskWorkingTime = id => async dispatch => {
  try {
    dispatch({ type: actionType.GENERAL_TASK_WORKING_TIME_ARCHIVE_START });

    await axiosProxy({
      url: url + `/${id}/archive`,
      method: "PUT"
    });

    dispatch({
      type: actionType.GENERAL_TASK_WORKING_TIME_ARCHIVE,
      payload: id
    });
  } catch (e) {
    e.response.status === 401 &&
      dispatch(getRefreshToken()).then(
        dispatch(archiveGeneralTaskWorkingTime(id))
      );
    dispatch({ type: actionType.GENERAL_TASK_WORKING_TIME_ARCHIVE_FAIL });
  }
};

export const clearError = () => dispatch =>
  dispatch({ type: actionType.CLEAR_ERROR });
