import React from "react";
import PropTypes from "prop-types";
import { Dropdown, Button, Icon } from "semantic-ui-react";
import styles from "./PrintoutButton.module.css";

const btnConfigs = {
  pdf: {
    icon: "print",
    text: "Print"
  },
  excel: {
    icon: "file excel",
    text: "Export"
  }
};

const PrintoutButton = ({ type, printoutAdditionalOptions, ...rest }) => {
  if (!type) return null;

  const { icon, text } = btnConfigs[type];

  return printoutAdditionalOptions ? (
    <Dropdown
      inline
      basic
      className={styles.printoutDropdown}
      trigger={
        <span>
          <Icon name={icon} /> {text}
        </span>
      }
      icon={<Icon name="angle down" />}
    >
      <Dropdown.Menu>
        {printoutAdditionalOptions.map((option, index) => (
          <Dropdown.Item key={`printout_option_${index}`} {...option} />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  ) : (
    <Button basic className={styles.btnCustomized} {...rest}>
      <Icon name={icon} />
      {text}
    </Button>
  );
};

PrintoutButton.propTypes = {
  type: PropTypes.oneOf(["pdf", "excel"]).isRequired,
  printoutAdditionalOptions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired
    })
  )
};

export default PrintoutButton;
