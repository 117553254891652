import React from "react";
import { Button, Table, Label, Popup } from "semantic-ui-react";
import PropTypes from "prop-types";
import { Form, Input, TextArea, Select } from "formsy-semantic-ui-react";

import { validationErrors } from "../../../../utils/validationRules";

import styles from "../AddNewChemical.module.css";
const ErrorLabel = <Label color="red" pointing />;

function TableRowEdit({
  index,
  cropsOptions,
  updateValue,
  crop,
  rateTitle,
  validRow,
  updateCropRow,
  editCropRow,
  setAddCropUpdateValidation,
  allFieldsEmpty
}) {
  return (
    <Table.Row>
      <Table.Cell width={3}>
        <div className={styles.title}>Crop</div>
        <Select
          name={`${index}_crop.id`}
          className={`small ${styles.selectCrop}`}
          value={crop.id.key}
          fluid
          options={cropsOptions}
          search
          placeholder="Select Crop"
          validationErrors={validationErrors()}
          errorLabel={ErrorLabel}
          onChange={(event, data) =>
            updateValue(
              index,
              "id",
              data.options.find(option => option.value === data.value)
            )
          }
        />
      </Table.Cell>
      <Table.Cell>
        <div className={styles.title}>Purpose</div>
        <Form.Field className={styles.inputRate}>
          <Input
            size="small"
            fluid
            name={`${index}_crop.purpose`}
            validations={{ isExtraParameterTrue: validRow(index)[1] }}
            value={crop.purpose}
            validationErrors={validationErrors({
              isExtraParameterTrue: `${
                crop.id
                  ? `${crop.id.text} with (${
                      crop.purpose ? `purpose  ${crop.purpose}` : "no purpose"
                    })  already exists`
                  : null
              }`
            })}
            errorLabel={ErrorLabel}
            onChange={event =>
              updateValue(index, "purpose", event.target.value)
            }
          />
        </Form.Field>
      </Table.Cell>
      <Table.Cell className={styles.dashField} width={1}>
        <div className={styles.title}>
          {rateTitle} <span className={styles.signRequired} />
        </div>
        <Form.Field className={styles.inputRate}>
          <Input
            size="small"
            name={`${index}_crop.minRate`}
            fluid
            value={crop.minRate}
            validations={allFieldsEmpty ? "isPositive" : null}
            validationErrors={validationErrors()}
            errorLabel={ErrorLabel}
            placeholder="min"
            onChange={event =>
              updateValue(index, "minRate", event.target.value)
            }
            className={styles.minWidth}
          />
        </Form.Field>
      </Table.Cell>
      <Table.Cell className={styles.dashFieldAfter} width={1}>
        <div className={styles.title} />
        <Form.Field className={styles.inputRate}>
          <Input
            size="small"
            name={`${index}_crop.maxRate`}
            fluid
            value={crop.maxRate}
            validations={
              crop.maxRate && crop.maxRate !== "" && crop.minRate > crop.maxRate
                ? "isMinBiggerThanMax"
                : allFieldsEmpty
                ? "isNotNegativeFloat"
                : null
            }
            validationErrors={validationErrors({
              isDefaultRequiredValue: "Max. Rate"
            })}
            errorLabel={ErrorLabel}
            placeholder="max"
            onChange={event =>
              updateValue(index, "maxRate", event.target.value)
            }
            className={styles.minWidth}
          />
        </Form.Field>
      </Table.Cell>
      <Table.Cell>
        <div className={styles.title}>
          <Popup content="Withholding Period" trigger={<span>WHP</span>} />{" "}
          <span className={styles.signRequired} />
        </div>
        <Form.Field className={styles.inputRate}>
          <Input
            size="small"
            name={`${index}_crop.withholdingPeriod`}
            fluid
            value={
              !isNaN(crop.withholdingPeriod)
                ? crop.withholdingPeriod.toString()
                : ""
            }
            validations={
              allFieldsEmpty
                ? { isNotNegativeInteger: true, isRequired: true }
                : null
            }
            validationErrors={validationErrors()}
            errorLabel={ErrorLabel}
            onChange={event =>
              updateValue(index, "withholdingPeriod", event.target.value)
            }
            className={styles.minWidth}
          />
        </Form.Field>
      </Table.Cell>
      <Table.Cell>
        <div className={styles.title}>Comments</div>
        <Form.Field className={styles.inputRate}>
          <TextArea
            className="small"
            rows={1}
            name={`${index}_crop.comment`}
            value={crop.comment}
            onChange={event =>
              updateValue(index, "comment", event.target.value)
            }
          />
        </Form.Field>
      </Table.Cell>
      <Table.Cell className={styles.newButtonsWrapper}>
        <Button
          size="small"
          onClick={() => updateCropRow(index)}
          positive={true}
          className={[
            styles.addNewChemicalButtonWrapper,
            crop.notValidAnimation ? styles.editCropValidation : null
          ]}
          onAnimationEnd={() => {
            setAddCropUpdateValidation(index);
          }}
          type="button"
        >
          Update
        </Button>
        <Button
          className={styles.addNewChemicalButtonWrapper}
          size="small"
          onClick={() => editCropRow(index)}
          type="button"
        >
          Cancel
        </Button>
      </Table.Cell>
    </Table.Row>
  );
}

TableRowEdit.propTypes = {
  index: PropTypes.number.isRequired,
  cropsOptions: PropTypes.array.isRequired,
  updateValue: PropTypes.func.isRequired,
  duplicatedCropAndPurpose: PropTypes.bool.isRequired,
  allFieldsEmpty: PropTypes.bool,
  crop: PropTypes.object.isRequired,
  rateTitle: PropTypes.string.isRequired,
  validRow: PropTypes.func.isRequired,
  updateCropRow: PropTypes.func.isRequired,
  editCropRow: PropTypes.func.isRequired,
  setAddCropUpdateValidation: PropTypes.func.isRequired
};

export default TableRowEdit;
