export const actionType = {
  SET_NEW_FARM_FIELD: "FARMS/SET_NEW_FARM_FIELD",
  SET_NEW_POLYGON: "FARMS/SET_NEW_POLYGON",
  SET_NEW_FARM: "FARMS/SET_NEW_FARM",
  CLEAR_NEW_POLYGON: "FARMS/CLEAR_NEW_POLYGON",
  INIT_NEW_BLOCK: "FARMS/INIT_NEW_BLOCK",
  SET_NEW_BLOCK_FIELD: "FARMS/SET_NEW_BLOCK_FIELD",
  SET_NEW_BLOCK_FIELDS: "FARMS/SET_NEW_BLOCK_FIELDS",
  REMOVE_BLOCK_BY_ID: "FARMS/REMOVE_BLOCK_BY_ID",
  ARCHIVE_BLOCK_BY_ID: "FARMS/ARCHIVE_BLOCK_BY_ID",
  REMOVE_PATCHES_FROM_BLOCK: "FARMS/REMOVE_PATCHES_FROM_BLOCK",
  ADD_PATCHES_TO_BLOCK: "FARMS/ADD_PATCHES_TO_BLOCK",
  SET_NEW_PATCH_FIELD: "FARMS/SET_NEW_PATCH_FIELD",
  SET_CURRENT_BLOCK: "FARMS/SET_CURRENT_BLOCK",
  SET_CURRENT_PATCH: "FARMS/SET_CURRENT_PATCH",
  REMOVE_ALL_BLOCKS: "FARMS/REMOVE_ALL_BLOCKS",

  TOGGLE_SHOW_ARCHIVED: "FARMS/TOGGLE_SHOW_ARCHIVED",
  TOGGLE_FARM_LIST_SHOW_ARCHIVED: "FARMS/TOGGLE_FARM_LIST_SHOW_ARCHIVED",
  TOGGLE_OPEN_FARM_TABLE: "FARMS/TOGGLE_OPEN_FARM_TABLE",
  SET_FARMS_SEARCH: "FARMS/SET_FARMS_SEARCH",
  SET_FARM_FIELD: "FARMS/SET_FARM_FIELD",
  SET_ACTIVE_BLOCK: "FARMS/SET_ACTIVE_BLOCK",

  DELETE_FARM_BLOCK_START: "FARMS/DELETE_FARM_BLOCK_START",
  DELETE_FARM_BLOCK: "FARMS/DELETE_FARM_BLOCK",
  DELETE_FARM_BLOCK_FAIL: "FARMS/DELETE_FARM_BLOCK_FAIL",

  GET_FARMS_LIST_START: "FARMS/GET_FARMS_LIST_START",
  GET_FARMS_LIST: "FARMS/GET_FARMS_LIST",
  GET_FARMS_LIST_FAIL: "FARMS/GET_FARMS_LIST_FAIL",

  ADD_NEW_FARM_START: "FARMS/ADD_NEW_FARM_START",
  ADD_NEW_FARM: "FARMS/ADD_NEW_FARM",
  ADD_NEW_FARM_FAIL: "FARMS/ADD_NEW_FARM_FAIL",

  UPDATE_FARM_START: "FARMS/UPDATE_FARM_START",
  UPDATE_FARM: "FARMS/UPDATE_FARM",
  UPDATE_FARM_FAIL: "FARMS/UPDATE_FARM_FAIL",

  GET_FARM_AREAS_START: "FARMS/GET_FARM_AREAS_START",
  GET_FARM_AREAS: "FARMS/GET_FARM_AREAS",
  GET_FARM_AREAS_FAIL: "FARMS/GET_FARM_AREAS_FAIL",

  GET_FARM_START: "FARMS/GET_FARM_START",
  GET_FARM: "FARMS/GET_FARM",
  GET_FARM_FAIL: "FARMS/GET_FARM_FAIL",

  ARCHIVE_FARM_START: "FARMS/ARCHIVE_FARM_START",
  ARCHIVE_FARM: "FARMS/ARCHIVE_FARM",
  ARCHIVE_FARM_FAIL: "FARMS/ARCHIVE_FARM_FAIL",

  DELETE_FARM_START: "FARMS/DELETE_FARM_START",
  DELETE_FARM: "FARMS/DELETE_FARM",
  DELETE_FARM_FAIL: "FARMS/DELETE_FARM_FAIL",

  GET_FARMS_SUMMARY_START: "FARMS/GET_FARMS_SUMMARY_START",
  GET_FARMS_SUMMARY: "FARMS/GET_FARMS_SUMMARY",
  GET_FARMS_SUMMARY_FAIL: "FARMS/GET_FARMS_SUMMARY_FAIL",

  GET_FARM_SUMMARY: "FARMS/GET_FARM_SUMMARY",
  GET_FARM_SUMMARY_START: "FARMS/GET_FARM_SUMMARY_START",
  GET_FARM_SUMMARY_FAIL: "FARMS/GET_FARM_SUMMARY_FAIL",

  SET_SUMMARY_FIELD: "FARMS/SET_SUMMARY_FIELD",
  DELETE_SUMMARY_FARM_AREA: "FARMS/DELETE_SUMMARY_FARM_AREA",

  NEW_FARM_CLEAR_ERROR: "FARMS/NEW_FARM_CLEAR_ERROR",
  FARM_CLEAR_ERROR: "FARMS/CLEAR_ERROR",

  FETCH_CHEMICALS_BREAKDOWN_START: "FARMS/FETCH_CHEMICALS_BREAKDOWN_START",
  FETCH_CHEMICALS_BREAKDOWN: "FARMS/FETCH_CHEMICALS_BREAKDOWN",
  FETCH_CHEMICALS_BREAKDOWN_FAIL: "FARMS/FETCH_CHEMICALS_BREAKDOWN_FAIL",

  FETCH_SPRAY_TASKS_FOR_AREA_START: "FARMS/FETCH_SPRAY_TASKS_FOR_AREA_START",
  FETCH_SPRAY_TASKS_FOR_AREA_SUCCESS:
    "FARMS/FETCH_SPRAY_TASKS_FOR_AREA_SUCCESS",
  FETCH_SPRAY_TASKS_FOR_AREA_FAIL: "FARMS/FETCH_SPRAY_TASKS_FOR_AREA_FAIL",

  //Farm Side Panel General Task Report
  FETCH_TASK_COST_BREAKDOWN_START: "FARMS/FETCH_TASK_COST_BREAKDOWN_START",
  FETCH_TASK_COST_BREAKDOWN: "FARMS/FETCH_TASK_COST_BREAKDOWN",
  FETCH_TASK_COST_BREAKDOWN_FAIL: "FARMS/FETCH_TASK_COST_BREAKDOWN_FAIL",

  FETCH_OPERATING_COST_BREAKDOWN_START:
    "FARMS/FETCH_OPERATING_COST_BREAKDOWN_START",
  FETCH_OPERATING_COST_BREAKDOWN: "FARMS/FETCH_OPERATING_COST_BREAKDOWN",
  FETCH_OPERATING_COST_BREAKDOWN_FAIL:
    "FARMS/FETCH_OPERATING_COST_BREAKDOWN_FAIL"
};

export const MATTER_TYPE_LIQUID = "LIQUID";
export const MATTER_TYPE_SOLID = "SOLID";

export const PER_HECTARE = "PER_HECTARE";
export const PER_100_LITERS = "PER_100_LITERS";

export const SPRAY_TASKS_COST_BREAKDOWN_TITLE =
  "Application tasks cost breakdown:";
