import React from "react";
import {
  ELEMENTS_LIST,
  ELEMENTS_LIST_SIDEBAR
} from "constants/Chemicals/types";
import CollapsibleBlock from "components/CollapsibleBlock";
import { Grid, Table } from "semantic-ui-react";
import { chunk, get } from "lodash";
import styles from "../Sidebar.module.css";

const elementsChunks = chunk(
  ELEMENTS_LIST_SIDEBAR,
  ELEMENTS_LIST_SIDEBAR.length / 3
);

const ElementWeightSection = ({ chemical, editButton }) => {
  return (
    <CollapsibleBlock
      className={styles.blockContainer}
      headerOptionalComponent={editButton}
      title={
        chemical.matter == "LIQUID"
          ? "Element weight (W/V, %)"
          : "Element weight (W/W, %)"
      }
    >
      <Grid>
        <Grid.Row columns={4}>
          {elementsChunks.map((chunk, i) => (
            <Grid.Column key={i}>
              <Table
                className={styles.sectionTable}
                basic
                celled
                definition
                fixed
              >
                <Table.Body>
                  {chunk.map(element => (
                    <Table.Row>
                      <Table.Cell width={6}>
                        <b>{element}</b>
                      </Table.Cell>
                      <Table.Cell>
                        {get(chemical, `elements[${element}]`, "-")}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    </CollapsibleBlock>
  );
};

export default ElementWeightSection;
