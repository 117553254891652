import React from "react";
import PropTypes from "prop-types";
import { Modal } from "semantic-ui-react";
import LayoutOverlay from "../../components/LayoutOverlay";
import styles from "./AddNewModal.module.css";

function AddNewModal({ open, title, formToShow, onClose, actions }) {
  return (
    <Modal
      size={"tiny"}
      open={open}
      onClose={onClose}
      closeIcon
      className={styles.modalChemical}
    >
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        <LayoutOverlay childrenOnly>{formToShow}</LayoutOverlay>
      </Modal.Content>
      <Modal.Actions>{actions}</Modal.Actions>
    </Modal>
  );
}

AddNewModal.propTypes = {
  open: PropTypes.bool,
  formToShow: PropTypes.object,
  onClose: PropTypes.func,
  actions: PropTypes.object,
  title: PropTypes.string
};

AddNewModal.defaultProps = {
  title: ""
};
export default AddNewModal;
