import React from "react";
import PropTypes from "prop-types";
import { Grid, Header, Icon, Image } from "semantic-ui-react";
import { get } from "lodash";
import { scoutingAreaShape } from "constants/Scouting/types";
import styles from "../ScoutingSidebar.module.css";
import {
  getIssuesReportedTitleText,
  getReadableIssueType
} from "utils/scouting";

const ScoutingReportAreaElement = ({ scoutingArea, withImages = true }) => {
  const { area, comment, areaIssues } = scoutingArea;
  const issuesReported = get(areaIssues, "length", 0);
  const reportedIssuesTitleText = getIssuesReportedTitleText(issuesReported);

  return (
    <div
      className={`full-width issue-row-padded ${
        Array.isArray(areaIssues) && areaIssues.length === 1
          ? "avoid-row-splitting"
          : ""
      }`}
    >
      <div>
        <div className="d-flex">
          <span className={"bold block-name"}>{area.name}</span>
          <span className={"block-issues-info"}>
            {`(${reportedIssuesTitleText})`}
          </span>
        </div>
        <div>
          {comment && (
            <Header className={"slim"} as={"h4"}>
              {comment}
            </Header>
          )}
        </div>
      </div>
      {Array.isArray(areaIssues) &&
        areaIssues.map((issue, index) => (
          <div className="issue-section full-width" key={index}>
            <Grid.Column width={16}>
              <Header className="medium underlined mb-1">
                {area.name}, Issue {index + 1}:{" "}
                {getReadableIssueType(issue.type)}
              </Header>
              <Grid>
                {!!issue.pressureRating && (
                  <Grid.Row
                    centered
                    verticalAlign={"middle"}
                    columns={2}
                    textAlign={"left"}
                    className={"issue-row-padded"}
                  >
                    <Grid.Column width={5} floated={"left"}>
                      <Header as={"h4"} className={"capitalize medium"}>
                        Pressure Rating
                      </Header>
                    </Grid.Column>
                    <Grid.Column width={11} floated={"left"}>
                      <Header as={"h3"} className={"slim m-0"}>
                        {issue.pressureRating}/5
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                )}
                <Grid.Row
                  centered
                  verticalAlign={"middle"}
                  columns={2}
                  textAlign={"left"}
                  floated={!issue.pressureRating ? "left" : "right"}
                  className={"issue-row-padded"}
                >
                  <Grid.Column width={5}>
                    <Header as={"h4"} className={"capitalize medium"}>
                      Row
                    </Header>
                  </Grid.Column>
                  <Grid.Column width={11}>
                    <Header as={"h3"} className={"slim m-0"}>
                      {issue.rowNumber}
                    </Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row
                  centered
                  verticalAlign={"top"}
                  columns={2}
                  textAlign={"left"}
                  className={"issue-row-padded"}
                >
                  <Grid.Column width={5}>
                    <Header as={"h4"} className={"capitalize medium"}>
                      Comments
                    </Header>
                  </Grid.Column>
                  <Grid.Column width={11}>
                    <Header as={"h4"} className={"slim"}>
                      {issue.comment}
                    </Header>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>

            {withImages && (
              <>
                {issue.attachments[0] && (
                  <div className="full-width image-row">
                    <div className="w-45">
                      <div className={"image-wrapper"}>
                        <div className={"image-container"}>
                          <Image
                            fluid
                            className={"report-image"}
                            src={issue.attachments[0].attachment.presignedUrl}
                          />
                        </div>
                      </div>
                      <div className={"medium"}>
                        {issue.attachments[0].attachment.name}
                      </div>
                    </div>
                    {issue.attachments[1] && (
                      <div className="w-45">
                        <div className={"image-wrapper"}>
                          <div className={"image-container"}>
                            <Image
                              fluid
                              className={"report-image"}
                              src={issue.attachments[1].attachment.presignedUrl}
                            />
                          </div>
                        </div>
                        <div className={"medium"}>
                          {issue.attachments[1].attachment.name}
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {issue.attachments[2] && (
                  <div className="full-width image-row">
                    <div className="w-45">
                      <div className={"image-wrapper"}>
                        <div className={"image-container"}>
                          <Image
                            fluid
                            className={"report-image"}
                            src={issue.attachments[2].attachment.presignedUrl}
                          />
                        </div>
                      </div>
                      <div className={"medium"}>
                        {issue.attachments[2].attachment.name}
                      </div>
                    </div>
                    {issue.attachments[3] && (
                      <div className="w-45">
                        <div className={"image-wrapper"}>
                          <div className={"image-container"}>
                            <Image
                              fluid
                              className={"report-image"}
                              src={issue.attachments[3].attachment.presignedUrl}
                            />
                          </div>
                        </div>
                        <div className={"medium"}>
                          {issue.attachments[3].attachment.name}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        ))}
    </div>
  );
};

ScoutingReportAreaElement.propTypes = {
  scoutingArea: scoutingAreaShape,
  onIssueAttachmentClick: PropTypes.func
};

export default ScoutingReportAreaElement;
