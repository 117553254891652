import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { Button, Grid, Icon, Message, Modal } from "semantic-ui-react";
import { connect } from "react-redux";
import moment from "moment";
import {
  addNewChemical,
  setChemicalFromList,
  deleteChemicalItem,
  setChemicalItemToAdd,
  addChemicalItem
} from "../../../../actions/SprayDiary/chemicals";
import styles from "./WorkingTimes.module.css";

import WorkingTime, {
  computeWorkingTime,
  createDefaultWorkingTime
} from "../../CreateSprayTask/WorkingTimes/WorkingTime";
import { setWorkingTimes } from "../../../../actions/SprayDiary/common";
import TaskRow from "../TaskRow";
import { calcTotalWorkingTimes } from "../../CreateSprayTask/WorkingTimes/WorkingTimes";

export const prepareTimeToShow = workingTime => {
  return `${moment(workingTime.date).format("DD/MM/YYYY")} ${moment(
    workingTime.workingFrom,
    "HH:mm:ss"
  ).format("hh:mm a")}-${moment(workingTime.workingTo, "HH:mm:ss").format(
    "hh:mm a"
  )} (${computeWorkingTime(workingTime)})`;
};

class WorkingTimes extends Component {
  state = {
    workTimeIndexToDelete: null,
    showDeleteModal: false
  };

  componentDidMount() {
    const { sprayTask } = this.props;
    if (
      sprayTask.status === "COMPLETED" &&
      sprayTask.workingTimes.length === 0
    ) {
      this.addWorkingTime();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { sprayTask } = this.props;
    if (
      sprayTask !== prevProps.sprayTask &&
      sprayTask.status === "COMPLETED" &&
      sprayTask.workingTimes.length === 0
    ) {
      this.addWorkingTime();
    }
  }

  addWorkingTime = async () => {
    const {
      sprayTask: { workingTimes },
      actions,
      onChange
    } = this.props;
    let newWorkingTimes = workingTimes.slice(0);
    newWorkingTimes.push(createDefaultWorkingTime());
    await actions.setWorkingTimes(newWorkingTimes);
    onChange(
      newWorkingTimes.map(workingTime => prepareTimeToShow(workingTime))
    );
  };

  updateWorkingTime = async (workTimeIndex, updatedWorkingTime) => {
    const {
      sprayTask: { workingTimes },
      actions,
      onChange
    } = this.props;
    let newWorkingTimes = workingTimes.slice(0);
    newWorkingTimes[workTimeIndex] = updatedWorkingTime;
    await actions.setWorkingTimes(newWorkingTimes);
    onChange(
      newWorkingTimes.map(workingTime => prepareTimeToShow(workingTime))
    );
  };

  handleDeleteWorkingTime = async () => {
    const {
      sprayTask: { workingTimes },
      actions,
      onChange
    } = this.props;
    const { workTimeIndexToDelete } = this.state;
    let newWorkingTimes = workingTimes.slice(0);
    newWorkingTimes.splice(workTimeIndexToDelete, 1);
    await actions.setWorkingTimes(newWorkingTimes);
    this.hideDeleteModal();
    onChange(
      newWorkingTimes.map(workingTime => prepareTimeToShow(workingTime))
    );
  };

  deleteWorkingTimeItem = workTimeIndexToDelete => {
    this.setState({
      workTimeIndexToDelete,
      showDeleteModal: true
    });
  };

  hideDeleteModal = () => {
    this.setState({
      workTimeIndexToDelete: null,
      showDeleteModal: false
    });
  };

  render() {
    const {
      sprayTask,
      toggleEditField,
      fieldToEdit,
      editAllMode,
      overlappingDate
    } = this.props;
    const { showDeleteModal } = this.state;

    return (
      <>
        <Modal
          size="mini"
          open={showDeleteModal}
          onClose={this.hideDeleteModal}
        >
          <Modal.Header>Delete working time</Modal.Header>
          <Modal.Content>
            <p>Are you sure you want to delete this working time?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.hideDeleteModal}>No</Button>
            <Button positive onClick={this.handleDeleteWorkingTime}>
              Yes
            </Button>
          </Modal.Actions>
        </Modal>
        <Grid className="no-margin">
          {sprayTask &&
            sprayTask.workingTimes.map((workingTime, index) => (
              <TaskRow
                key={`workingTime${
                  workingTime.id ? workingTime.id : workingTime._id
                }`}
                label=""
                name="workingTime"
                inputType="component"
                suffix={index}
                component={
                  <Grid className={`no-margin ${styles.timeHolder}`}>
                    <WorkingTime
                      label="Working time"
                      workingTime={workingTime}
                      onDelete={() => this.deleteWorkingTimeItem(index)}
                      onChange={updatedWorkingTime =>
                        this.updateWorkingTime(index, updatedWorkingTime)
                      }
                      showLabel={false}
                      validation={
                        !(
                          fieldToEdit &&
                          fieldToEdit.error &&
                          fieldToEdit.error.indexes.includes(index)
                        )
                      }
                    />
                  </Grid>
                }
                value={prepareTimeToShow(workingTime)}
                toggleEditField={toggleEditField}
                fieldToEdit={fieldToEdit}
                extraColumn={
                  sprayTask.workingTimes.length > 1
                    ? {
                        size: 2,
                        className: styles.noPadding,
                        content: (
                          <div className={styles.deleteHolder}>
                            <Button
                              disabled={sprayTask.workingTimes.length === 1}
                              className="button-text"
                              icon="delete"
                              onClick={() => this.deleteWorkingTimeItem(index)}
                            />
                          </div>
                        )
                      }
                    : null
                }
                wide
                editAllMode={editAllMode}
              />
            ))}
          <TaskRow
            label=""
            value={
              <div className={`${styles.addHolder} ${styles.wide}`}>
                <Button
                  icon={{ name: "plus circle", size: "large", color: "green" }}
                  onClick={this.addWorkingTime}
                />
              </div>
            }
            wide
          />
          {overlappingDate && (
            <Grid.Row>
              <Grid.Column>
                <Message color="red">
                  <Icon name="warning" />
                  <span className={styles.totalWorkingTime}>
                    Date range must not overlap
                  </span>
                </Message>
              </Grid.Column>
            </Grid.Row>
          )}
          {sprayTask && sprayTask.workingTimes.length > 1 && (
            <Grid.Row>
              <Grid.Column>
                <Message color="blue">
                  <Icon name="warning" /> Total quantity of hours:{" "}
                  <span className={styles.totalWorkingTime}>
                    {calcTotalWorkingTimes(sprayTask.workingTimes).total} hours
                  </span>
                </Message>
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </>
    );
  }
}

WorkingTimes.propTypes = {
  sprayTask: PropTypes.object,
  actions: PropTypes.object,
  toggleEditField: PropTypes.func,
  fieldToEdit: PropTypes.object,
  updateTask: PropTypes.func,
  onChange: PropTypes.func,
  heavy: PropTypes.bool,
  wide: PropTypes.bool,
  editAllMode: PropTypes.bool
};

WorkingTimes.defaultProps = {
  heavy: false,
  wide: false,
  editAllMode: false,
  updateTask: () => {}
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      addNewChemical,
      setChemicalFromList,
      deleteChemicalItem,
      setChemicalItemToAdd,
      addChemicalItem,
      setWorkingTimes
    },
    dispatch
  )
});

export default connect(null, mapDispatchToProps)(WorkingTimes);
