import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MultiSelectFilter from "./MultiSelectFilter/MultiSelectFilter";
import PropTypes from "prop-types";
import { fetchGeneralBarcodesIDs } from "../../../actions/GeneralBarcodes/generalBarcodes";

const BarcodeIDMultiSelectFilter = ({
  actions: { fetchList },
  list,
  isFetching,
  ...extraProps
}) => {
  useEffect(() => {
    fetchList({ unpaged: true });
  }, [fetchList]);
  const sortedList = list.sort((a, b) => parseFloat(a.id) - parseFloat(b.id));

  const options = useMemo(
    () =>
      sortedList.map(item => ({
        key: item.id,
        text: item.id,
        value: item.id
      })),
    [list]
  );

  return (
    <MultiSelectFilter
      options={options}
      numbers={true}
      loading={isFetching}
      {...extraProps}
    />
  );
};

const mapStateToProps = ({
  generalBarcodes: {
    barcodeIDs: { content, isFetching }
  }
}) => {
  return {
    list: content,
    isFetching
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchList: fetchGeneralBarcodesIDs }, dispatch)
});

BarcodeIDMultiSelectFilter.propTypes = {
  isFetching: PropTypes.bool,
  list: PropTypes.array,
  actions: PropTypes.object
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BarcodeIDMultiSelectFilter);
