import PropTypes from "prop-types";
import React from "react";
import styles from "./CustomGeneralPomonaBarcodesPrintLayout.module.css";
import { generateBarcodeSeries } from "../../../../utils/generalBarcodes";
import QRCode from "qrcode.react";
const MySVG = require("./pomona-logo.svg");

const CustomGeneralPomonaBarcodesPrintLayout = ({
  labels,
  series,
  itemsInPage,
  className,
  barcodeStyle,
  pageBreakStyles,
  pageStyle
}) => {
  const barcodes = generateBarcodeSeries(
    labels,
    series.firstBarcode,
    series.lastBarcode
  );
  const itemsInPageRange = [...Array(itemsInPage).keys()];

  return (
    <div className={`${styles.block} ${className} block`}>
      <style>{`${pageStyle} html,body {margin: 0px;}`}</style>
      <div className={`${styles.page} page`}>
        {Array.from(Array(barcodes.length).keys())
          .filter(i => i % itemsInPage === 0)
          .map(pageOffset => (
            <>
              <div
                key={`page_${pageOffset}`}
                className={`${pageBreakStyles || styles.pageBreak} pageBreak`}
              >
                {itemsInPageRange.map(itemOffset => {
                  const i = pageOffset + itemOffset;
                  const barcode = barcodes[i];
                  return (
                    i < barcodes.length && (
                      <div
                        key={`bc_${i}`}
                        className={`${styles.barcodeHolder} barcodeHolder`}
                      >
                        <div
                          key={`bc_${i}`}
                          className={`${styles.barcodeDataHolder} barcodeDataHolder`}
                        >
                          <div className={styles.dasheswrapper}>
                            <span className={styles.dashes}>—</span>
                            <span className={styles.dashes}>—</span>
                          </div>
                          <div className={styles.qrCodeWrapper}>
                            <QRCode
                              renderAs="svg"
                              className={`${barcodeStyle ||
                                styles.barcode} barcode`}
                              value={barcode.numberPart}
                            />
                            <div className={`${styles.textHolder} textHolder`}>
                              <div className={`${styles.text} text`}>
                                {barcode.numberPart}
                              </div>
                            </div>
                          </div>
                          <div className={styles.varietySection}>
                            VARIETY:
                            <span className={styles.valueVarietyField}>
                              {labels.varietyLabel}
                            </span>
                          </div>
                          <div className={styles.blockSection}>
                            BLOCK:
                            <span className={styles.valueBlockField} />
                          </div>
                          <div className={styles.datePropertyStampWrapper}>
                            <div className={styles.datePropertySection}>
                              <div className={styles.dateWrapper}>
                                <div className={styles.dateLabel}>DATE</div>
                              </div>
                              <div className={styles.propertyWrapper}>
                                <div className={styles.propertyLabel}>
                                  PROPERTY
                                </div>
                              </div>
                            </div>
                            <div className={styles.stampSection}></div>
                          </div>
                          <div className={styles.pickerSection}>
                            PICKER:
                            <span className={styles.valuePickerField} />
                          </div>
                          <div className={styles.dasheswrapperBottom}>
                            <span className={styles.dashes}>—</span>
                            <span className={styles.squarLogoWrapper}>
                              <span className={styles.squar} />
                              <span className={styles.logoContainer}>
                                {" "}
                                <img
                                  className={styles.logo}
                                  src={MySVG}
                                  alt="SVG Logo"
                                />
                              </span>
                            </span>
                            <span className={styles.dashes}>—</span>
                          </div>
                        </div>
                      </div>
                    )
                  );
                })}
              </div>
            </>
          ))}
      </div>
    </div>
  );
};

CustomGeneralPomonaBarcodesPrintLayout.propTypes = {
  barcodeStyle: PropTypes.any,
  className: PropTypes.string,
  fullHeight: PropTypes.bool,
  itemsInPage: PropTypes.number,
  labels: PropTypes.object,
  minimized: PropTypes.bool,
  onlyQR: PropTypes.bool,
  pageBreakStyles: PropTypes.any,
  pageStyle: PropTypes.any,
  qrHeight: PropTypes.number,
  qrWidth: PropTypes.number,
  series: PropTypes.object,
  smallBarcode: PropTypes.any,
  largeAreaLayout: PropTypes.bool
};

CustomGeneralPomonaBarcodesPrintLayout.defaultProps = {
  qrHeight: 130,
  qrWidth: 130,
  largeAreaLayout: false
};

export default CustomGeneralPomonaBarcodesPrintLayout;
