export const COUNTRY_OPTIONS_WITH_CODE = [
  { text: 'Afghanistan', key: 'af', value: 'af' },
  { text: 'Åland Islands', key: 'ax', value: 'ax' },
  { text: 'Albania', key: 'al', value: 'al' },
  { text: 'Algeria', key: 'dz', value: 'dz' },
  { text: 'American Samoa', key: 'as', value: 'as' },
  { text: 'Andorra', key: 'ad', value: 'ad' },
  { text: 'Angola', key: 'ao', value: 'ao' },
  { text: 'Anguilla', key: 'ai', value: 'ai' },
  { text: 'Antarctica', key: 'aq', value: 'aq' },
  { text: 'Antigua and Barbuda', key: 'ag', value: 'ag' },
  { text: 'Argentina', key: 'ar', value: 'ar' },
  { text: 'Armenia', key: 'am', value: 'am' },
  { text: 'Aruba', key: 'aw', value: 'aw' },
  { text: 'Australia', key: 'au', value: 'au' },
  { text: 'Austria', key: 'at', value: 'at' },
  { text: 'Azerbaijan', key: 'az', value: 'az' },
  { text: 'Bahamas', key: 'bs', value: 'bs' },
  { text: 'Bahrain', key: 'bh', value: 'bh' },
  { text: 'Bangladesh', key: 'bd', value: 'bd' },
  { text: 'Barbados', key: 'bb', value: 'bb' },
  { text: 'Belarus', key: 'by', value: 'by' },
  { text: 'Belgium', key: 'be', value: 'be' },
  { text: 'Belize', key: 'bz', value: 'bz' },
  { text: 'Benin', key: 'bj', value: 'bj' },
  { text: 'Bermuda', key: 'bm', value: 'bm' },
  { text: 'Bhutan', key: 'bt', value: 'bt' },
  { text: 'Bolivia, Plurinational State of', key: 'bo', value: 'bo' },
  { text: 'Bonaire, Sint Eustatius and Saba', key: 'bq', value: 'bq' },
  { text: 'Bosnia and Herzegovina', key: 'ba', value: 'ba' },
  { text: 'Botswana', key: 'bw', value: 'bw' },
  { text: 'Bouvet Island', key: 'bv', value: 'bv' },
  { text: 'Brazil', key: 'br', value: 'br' },
  { text: 'British Indian Ocean Territory', key: 'io', value: 'io' },
  { text: 'Brunei Darussalam', key: 'bn', value: 'bn' },
  { text: 'Bulgaria', key: 'bg', value: 'bg' },
  { text: 'Burkina Faso', key: 'bf', value: 'bf' },
  { text: 'Burundi', key: 'bi', value: 'bi' },
  { text: 'Cambodia', key: 'kh', value: 'kh' },
  { text: 'Cameroon', key: 'cm', value: 'cm' },
  { text: 'Canada', key: 'ca', value: 'ca' },
  { text: 'Cape Verde', key: 'cv', value: 'cv' },
  { text: 'Cayman Islands', key: 'ky', value: 'ky' },
  { text: 'Central African Republic', key: 'cf', value: 'cf' },
  { text: 'Chad', key: 'td', value: 'td' },
  { text: 'Chile', key: 'cl', value: 'cl' },
  { text: 'China', key: 'cn', value: 'cn' },
  { text: 'Christmas Island', key: 'cx', value: 'cx' },
  { text: 'Cocos (Keeling) Islands', key: 'cc', value: 'cc' },
  { text: 'Colombia', key: 'co', value: 'co' },
  { text: 'Comoros', key: 'km', value: 'km' },
  { text: 'Congo', key: 'cg', value: 'cg' },
  { text: 'Congo, the Democratic Republic of the', key: 'cd', value: 'cd' },
  { text: 'Cook Islands', key: 'ck', value: 'ck' },
  { text: 'Costa Rica', key: 'cr', value: 'cr' },
  { text: "Côte d'Ivoire", key: 'ci', value: 'ci' },
  { text: 'Croatia', key: 'hr', value: 'hr' },
  { text: 'Cuba', key: 'cu', value: 'cu' },
  { text: 'Curaçao', key: 'cw', value: 'cw' },
  { text: 'Cyprus', key: 'cy', value: 'cy' },
  { text: 'Czech Republic', key: 'cz', value: 'cz' },
  { text: 'Denmark', key: 'dk', value: 'dk' },
  { text: 'Djibouti', key: 'dj', value: 'dj' },
  { text: 'Dominica', key: 'dm', value: 'dm' },
  { text: 'Dominican Republic', key: 'do', value: 'do' },
  { text: 'Ecuador', key: 'ec', value: 'ec' },
  { text: 'Egypt', key: 'eg', value: 'eg' },
  { text: 'El Salvador', key: 'sv', value: 'sv' },
  { text: 'Equatorial Guinea', key: 'gq', value: 'gq' },
  { text: 'Eritrea', key: 'er', value: 'er' },
  { text: 'Estonia', key: 'ee', value: 'ee' },
  { text: 'Ethiopia', key: 'et', value: 'et' },
  { text: 'Falkland Islands (Malvinas)', key: 'fk', value: 'fk' },
  { text: 'Faroe Islands', key: 'fo', value: 'fo' },
  { text: 'Fiji', key: 'fj', value: 'fj' },
  { text: 'Finland', key: 'fi', value: 'fi' },
  { text: 'France', key: 'fr', value: 'fr' },
  { text: 'French Guiana', key: 'gf', value: 'gf' },
  { text: 'French Polynesia', key: 'pf', value: 'pf' },
  { text: 'French Southern Territories', key: 'tf', value: 'tf' },
  { text: 'Gabon', key: 'ga', value: 'ga' },
  { text: 'Gambia', key: 'gm', value: 'gm' },
  { text: 'Georgia', key: 'ge', value: 'ge' },
  { text: 'Germany', key: 'de', value: 'de' },
  { text: 'Ghana', key: 'gh', value: 'gh' },
  { text: 'Gibraltar', key: 'gi', value: 'gi' },
  { text: 'Greece', key: 'gr', value: 'gr' },
  { text: 'Greenland', key: 'gl', value: 'gl' },
  { text: 'Grenada', key: 'gd', value: 'gd' },
  { text: 'Guadeloupe', key: 'gp', value: 'gp' },
  { text: 'Guam', key: 'gu', value: 'gu' },
  { text: 'Guatemala', key: 'gt', value: 'gt' },
  { text: 'Guernsey', key: 'gg', value: 'gg' },
  { text: 'Guinea', key: 'gn', value: 'gn' },
  { text: 'Guinea-Bissau', key: 'gw', value: 'gw' },
  { text: 'Guyana', key: 'gy', value: 'gy' },
  { text: 'Haiti', key: 'ht', value: 'ht' },
  { text: 'Heard Island and McDonald Islands', key: 'hm', value: 'hm' },
  { text: 'Holy See (Vatican City State)', key: 'va', value: 'va' },
  { text: 'Honduras', key: 'hn', value: 'hn' },
  { text: 'Hong Kong', key: 'hk', value: 'hk' },
  { text: 'Hungary', key: 'hu', value: 'hu' },
  { text: 'Iceland', key: 'is', value: 'is' },
  { text: 'India', key: 'in', value: 'in' },
  { text: 'Indonesia', key: 'id', value: 'id' },
  { text: 'Iran, Islamic Republic of', key: 'ir', value: 'ir' },
  { text: 'Iraq', key: 'iq', value: 'iq' },
  { text: 'Ireland', key: 'ie', value: 'ie' },
  { text: 'Isle of Man', key: 'im', value: 'im' },
  { text: 'Israel', key: 'il', value: 'il' },
  { text: 'Italy', key: 'it', value: 'it' },
  { text: 'Jamaica', key: 'jm', value: 'jm' },
  { text: 'Japan', key: 'jp', value: 'jp' },
  { text: 'Jersey', key: 'je', value: 'je' },
  { text: 'Jordan', key: 'jo', value: 'jo' },
  { text: 'Kazakhstan', key: 'kz', value: 'kz' },
  { text: 'Kenya', key: 'ke', value: 'ke' },
  { text: 'Kiribati', key: 'ki', value: 'ki' },
  { text: "Korea, Democratic People's Republic of", key: 'kp', value: 'kp' },
  { text: 'Korea, Republic of', key: 'kr', value: 'kr' },
  { text: 'Kuwait', key: 'kw', value: 'kw' },
  { text: 'Kyrgyzstan', key: 'kg', value: 'kg' },
  { text: "Lao People's Democratic Republic", key: 'la', value: 'la' },
  { text: 'Latvia', key: 'lv', value: 'lv' },
  { text: 'Lebanon', key: 'lb', value: 'lb' },
  { text: 'Lesotho', key: 'ls', value: 'ls' },
  { text: 'Liberia', key: 'lr', value: 'lr' },
  { text: 'Libya', key: 'ly', value: 'ly' },
  { text: 'Liechtenstein', key: 'li', value: 'li' },
  { text: 'Lithuania', key: 'lt', value: 'lt' },
  { text: 'Luxembourg', key: 'lu', value: 'lu' },
  { text: 'Macao', key: 'mo', value: 'mo' },
  { text: 'Macedonia, the Former Yugoslav Republic of', key: 'mk', value: 'mk' },
  { text: 'Madagascar', key: 'mg', value: 'mg' },
  { text: 'Malawi', key: 'mw', value: 'mw' },
  { text: 'Malaysia', key: 'my', value: 'my' },
  { text: 'Maldives', key: 'mv', value: 'mv' },
  { text: 'Mali', key: 'ml', value: 'ml' },
  { text: 'Malta', key: 'mt', value: 'mt' },
  { text: 'Marshall Islands', key: 'mh', value: 'mh' },
  { text: 'Martinique', key: 'mq', value: 'mq' },
  { text: 'Mauritania', key: 'mr', value: 'mr' },
  { text: 'Mauritius', key: 'mu', value: 'mu' },
  { text: 'Mayotte', key: 'yt', value: 'yt' },
  { text: 'Mexico', key: 'mx', value: 'mx' },
  { text: 'Micronesia, Federated States of', key: 'fm', value: 'fm' },
  { text: 'Moldova, Republic of', key: 'md', value: 'md' },
  { text: 'Monaco', key: 'mc', value: 'mc' },
  { text: 'Mongolia', key: 'mn', value: 'mn' },
  { text: 'Montenegro', key: 'me', value: 'me' },
  { text: 'Montserrat', key: 'ms', value: 'ms' },
  { text: 'Morocco', key: 'ma', value: 'ma' },
  { text: 'Mozambique', key: 'mz', value: 'mz' },
  { text: 'Myanmar', key: 'mm', value: 'mm' },
  { text: 'Namibia', key: 'na', value: 'na' },
  { text: 'Nauru', key: 'nr', value: 'nr' },
  { text: 'Nepal', key: 'np', value: 'np' },
  { text: 'Netherlands', key: 'nl', value: 'nl' },
  { text: 'New Caledonia', key: 'nc', value: 'nc' },
  { text: 'New Zealand', key: 'nz', value: 'nz' },
  { text: 'Nicaragua', key: 'ni', value: 'ni' },
  { text: 'Niger', key: 'ne', value: 'ne' },
  { text: 'Nigeria', key: 'ng', value: 'ng' },
  { text: 'Niue', key: 'nu', value: 'nu' },
  { text: 'Norfolk Island', key: 'nf', value: 'nf' },
  { text: 'Northern Mariana Islands', key: 'mp', value: 'mp' },
  { text: 'Norway', key: 'no', value: 'no' },
  { text: 'Oman', key: 'om', value: 'om' },
  { text: 'Pakistan', key: 'pk', value: 'pk' },
  { text: 'Palau', key: 'pw', value: 'pw' },
  { text: 'Palestine, State of', key: 'ps', value: 'ps' },
  { text: 'Panama', key: 'pa', value: 'pa' },
  { text: 'Papua New Guinea', key: 'pg', value: 'pg' },
  { text: 'Paraguay', key: 'py', value: 'py' },
  { text: 'Peru', key: 'pe', value: 'pe' },
  { text: 'Philippines', key: 'ph', value: 'ph' },
  { text: 'Pitcairn', key: 'pn', value: 'pn' },
  { text: 'Poland', key: 'pl', value: 'pl' },
  { text: 'Portugal', key: 'pt', value: 'pt' },
  { text: 'Puerto Rico', key: 'pr', value: 'pr' },
  { text: 'Qatar', key: 'qa', value: 'qa' },
  { text: 'Réunion', key: 're', value: 're' },
  { text: 'Romania', key: 'ro', value: 'ro' },
  { text: 'Russian Federation', key: 'ru', value: 'ru' },
  { text: 'Rwanda', key: 'rw', value: 'rw' },
  { text: 'Saint Barthélemy', key: 'bl', value: 'bl' },
  { text: 'Saint Helena, Ascension and Tristan da Cunha', key: 'sh', value: 'sh' },
  { text: 'Saint Kitts and Nevis', key: 'kn', value: 'kn' },
  { text: 'Saint Lucia', key: 'lc', value: 'lc' },
  { text: 'Saint Martin (French part)', key: 'mf', value: 'mf' },
  { text: 'Saint Pierre and Miquelon', key: 'pm', value: 'pm' },
  { text: 'Saint Vincent and the Grenadines', key: 'vc', value: 'vc' },
  { text: 'Samoa', key: 'ws', value: 'ws' },
  { text: 'San Marino', key: 'sm', value: 'sm' },
  { text: 'Sao Tome and Principe', key: 'st', value: 'st' },
  { text: 'Saudi Arabia', key: 'sa', value: 'sa' },
  { text: 'Senegal', key: 'sn', value: 'sn' },
  { text: 'Serbia', key: 'rs', value: 'rs' },
  { text: 'Seychelles', key: 'sc', value: 'sc' },
  { text: 'Sierra Leone', key: 'sl', value: 'sl' },
  { text: 'Singapore', key: 'sg', value: 'sg' },
  { text: 'Sint Maarten (Dutch part)', key: 'sx', value: 'sx' },
  { text: 'Slovakia', key: 'sk', value: 'sk' },
  { text: 'Slovenia', key: 'si', value: 'si' },
  { text: 'Solomon Islands', key: 'sb', value: 'sb' },
  { text: 'Somalia', key: 'so', value: 'so' },
  { text: 'South Africa', key: 'za', value: 'za' },
  { text: 'South Georgia and the South Sandwich Islands', key: 'gs', value: 'gs' },
  { text: 'South Sudan', key: 'ss', value: 'ss' },
  { text: 'Spain', key: 'es', value: 'es' },
  { text: 'Sri Lanka', key: 'lk', value: 'lk' },
  { text: 'Sudan', key: 'sd', value: 'sd' },
  { text: 'Suriname', key: 'sr', value: 'sr' },
  { text: 'Svalbard and Jan Mayen', key: 'sj', value: 'sj' },
  { text: 'Swaziland', key: 'sz', value: 'sz' },
  { text: 'Sweden', key: 'se', value: 'se' },
  { text: 'Switzerland', key: 'ch', value: 'ch' },
  { text: 'Syrian Arab Republic', key: 'sy', value: 'sy' },
  { text: 'Taiwan, Province of China', key: 'tw', value: 'tw' },
  { text: 'Tajikistan', key: 'tj', value: 'tj' },
  { text: 'Tanzania, United Republic of', key: 'tz', value: 'tz' },
  { text: 'Thailand', key: 'th', value: 'th' },
  { text: 'Timor-Leste', key: 'tl', value: 'tl' },
  { text: 'Togo', key: 'tg', value: 'tg' },
  { text: 'Tokelau', key: 'tk', value: 'tk' },
  { text: 'Tonga', key: 'to', value: 'to' },
  { text: 'Trinidad and Tobago', key: 'tt', value: 'tt' },
  { text: 'Tunisia', key: 'tn', value: 'tn' },
  { text: 'Turkey', key: 'tr', value: 'tr' },
  { text: 'Turkmenistan', key: 'tm', value: 'tm' },
  { text: 'Turks and Caicos Islands', key: 'tc', value: 'tc' },
  { text: 'Tuvalu', key: 'tv', value: 'tv' },
  { text: 'Uganda', key: 'ug', value: 'ug' },
  { text: 'Ukraine', key: 'ua', value: 'ua' },
  { text: 'United Arab Emirates', key: 'ae', value: 'ae' },
  { text: 'United Kingdom', key: 'gb', value: 'gb' },
  { text: 'United States', key: 'us', value: 'us' },
  { text: 'United States Minor Outlying Islands', key: 'um', value: 'um' },
  { text: 'Uruguay', key: 'uy', value: 'uy' },
  { text: 'Uzbekistan', key: 'uz', value: 'uz' },
  { text: 'Vanuatu', key: 'vu', value: 'vu' },
  { text: 'Venezuela, Bolivarian Republic of', key: 've', value: 've' },
  { text: 'Viet Nam', key: 'vn', value: 'vn' },
  { text: 'Virgin Islands, British', key: 'vg', value: 'vg' },
  { text: 'Virgin Islands, U.S.', key: 'vi', value: 'vi' },
  { text: 'Wallis and Futuna', key: 'wf', value: 'wf' },
  { text: 'Western Sahara', key: 'eh', value: 'eh' },
  { text: 'Yemen', key: 'ye', value: 'ye' },
  { text: 'Zambia', key: 'zm', value: 'zm' },
  { text: 'Zimbabwe', key: 'zw', value: 'zw' },
];
