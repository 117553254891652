import { actionType } from "../../constants/JhonDeere";

const initialState = {
  url: "",
  isFetching: false,
  checkTokenStatus: false,
  machines: [],
  locations: []
};

export default (state = initialState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case actionType.GET_LAST_LOCATION_START:
    case actionType.COLLECTED_LAST_LOCATION_START:
    case actionType.GET_URL_START:
      return {
        ...state,
        isFetching: true
      };
    case actionType.GET_URL:
      return {
        ...state,
        url: action.payload,
        isFetching: false
      };
    case actionType.GET_URL_FAIL:
      return {
        ...state,
        isFetching: false
      };
    case actionType.FETCH_TOKEN_START:
      return {
        ...state,
        isFetching: true
      };
    case actionType.FETCH_TOKEN:
      return {
        ...state,
        url: "",
        checkTokenStatus: true,
        isFetching: false
      };
    case actionType.FETCH_TOKEN_FAIL:
      return {
        ...state,
        isFetching: false
      };
    case actionType.CHECK_TOKEN_START:
      return {
        ...state,
        isFetching: true,
        checkTokenStatus: false
      };
    case actionType.CHECK_TOKEN:
      return {
        ...state,
        checkTokenStatus: true
      };
    case actionType.CHECK_TOKEN_FAIL:
      return {
        ...state,
        checkTokenStatus: false
      };
    case actionType.GET_MACHINES_START:
    case actionType.GET_ORGANIZATIONS_START:
      return {
        ...state,
        isFetching: true
      };
    case actionType.GET_MACHINES:
      return {
        ...state,
        // isFetching: false,
        machines: action.payload
      };
    case actionType.GET_MACHINES_FAIL:
      return {
        ...state,
        isFetching: false
      };
    case actionType.COLLECTED_LAST_LOCATION:
      return {
        ...state,
        isFetching: false,
        locations: action.payload
      };
    case actionType.GET_LAST_LOCATION:
      return {
        ...state,
        locations: [...state.locations, ...action.payload.values],
        isFetching: false
      };
    default:
      return state;
  }
};
