export const actionType = {
  FETCH_LABOR_GROUPS_START: "LABOR_GROUPS/FETCH_LABOR_GROUPS_START",
  FETCH_LABOR_GROUPS: "LABOR_GROUPS/FETCH_LABOR_GROUPS",
  FETCH_LABOR_GROUPS_FAIL: "LABOR_GROUPS/FETCH_LABOR_GROUPS_FAIL",
  CLEAR_LABOR_GROUP_ERROR: "LABOR_GROUPS/CLEAR_LABOR_GROUP_ERROR",

  FETCH_LABOR_GROUPS_LIST_START: "LABOUR_GROUPS/FETCH_LABOUR_GROUPS_LIST_START",
  FETCH_LABOR_GROUPS_LIST: "LABOUR_GROUPS/FETCH_LABOUR_GROUPS_LIST",
  FETCH_LABOR_GROUPS_LIST_FAIL: "LABOUR_GROUPS/FETCH_LABOUR_GROUPS_LIST_FAIL",

  LABOR_GROUP_FORM_SET: "LABOR_GROUPS/LABOR_GROUP_FORM_SET",
  LABOR_GROUP_FORM_CHANGE: "LABOR_GROUPS/LABOR_GROUP_FORM_CHANGE",
  LABOR_GROUP_FORM_CLEAR: "LABOR_GROUPS/LABOR_GROUP_FORM_CLEAR",

  LABOR_GROUP_CREATE_START: "LABOR_GROUPS/CREATE_START",
  LABOR_GROUP_CREATE: "LABOR_GROUPS/CREATE",
  LABOR_GROUP_CREATE_FAIL: "LABOR_GROUPS/CREATE_FAIL",

  LABOR_GROUP_UPDATE_START: "LABOR_GROUPS/UPDATE_START",
  LABOR_GROUP_UPDATE: "LABOR_GROUPS/UPDATE",
  LABOR_GROUP_UPDATE_FAIL: "LABOR_GROUPS/UPDATE_FAIL",

  LABOR_GROUP_DELETE_START: "LABOR_GROUPS/DELETE_START",
  LABOR_GROUP_DELETE: "LABOR_GROUPS/DELETE",
  LABOR_GROUP_DELETE_FAIL: "LABOR_GROUPS/DELETE_FAIL",

  LABOR_GROUPS_DELETE_START: "LABOR_GROUPS/DELETE_LABOR_GROUPS_START",
  LABOR_GROUPS_DELETE: "LABOR_GROUPS/DELETE_LABOR_GROUPS",
  LABOR_GROUPS_DELETE_FAIL: "LABOR_GROUPS/DELETE_LABOR_GROUPS_FAIL",

  LABOR_GROUP_ASSIGN_FORM_CHANGE: "LABOR_GROUPS/ASSIGN_FORM_CHANGE",
  LABOR_GROUP_ASSIGN_FORM_CLEAR: "LABOR_GROUPS/ASSIGN_FORM_CLEAR",

  ARCHIVE_LABOR_GROUP_START: "LABOR_GROUPS/ARCHIVE_LABOR_GROUPS_START",
  ARCHIVE_LABOR_GROUP: "LABOR_GROUPS/ARCHIVE_LABOR_GROUPS",
  ARCHIVE_LABOR_GROUP_FAIL: "LABOR_GROUPS/ARCHIVE_LABOR_GROUPS_FAIL"
};
