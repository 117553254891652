import PropTypes from "prop-types";
import React from "react";
import QRCode from "qrcode.react";
import styles from "./GeneralBarcodeLargeArea.module.css";

const GeneralBarcodeLargeArea = ({ barcode }) => {
  const { numberPart, ...labels } = barcode;

  const blockLabel =
    labels.blockLabel && labels.patchLabel
      ? `${labels.blockLabel} - ${labels.patchLabel}`
      : labels.blockLabel;

  return (
    <div className={styles.barcode + " barcode"}>
      <div className={styles.top}>
        <span>{blockLabel}</span>
      </div>
      <div className={styles.bottom}>
        <div className={styles.left}>
          <QRCode
            className={"qr-code " + styles.qrCode}
            renderAs="svg"
            value={numberPart}
          />
          <span>{numberPart}</span>
        </div>
        <div className={styles.right}>
          <span>{labels.dateLabel}</span>
          <span>{labels.farmLabel}</span>
          <span>{labels.varietyLabel}</span>
          <span style={{ padding: "0 10px" }}>{labels.comment}</span>
        </div>
      </div>
    </div>
  );
};

GeneralBarcodeLargeArea.propTypes = {
  barcode: PropTypes.object.isRequired
};

export default GeneralBarcodeLargeArea;
