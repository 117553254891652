import React from "react";

import PropTypes from "prop-types";

import { Grid, Icon, Popup, Segment } from "semantic-ui-react";
import styles from "./Tasks.module.css";
function RowHeader({ Can }) {
  return (
    <Segment basic className={styles.tasksListHeader}>
      <Grid className={styles.tasksListHeaderGrid}>
        <Can I="add" a="spray_tasks">
          <Grid.Row>
            <Grid.Column
              mobile={1}
              tablet={1}
              computer={1}
              largeScreen={1}
              widescreen={1}
            >
              ID
            </Grid.Column>
            <Grid.Column largeScreen={2} widescreen={1}>
              Status
            </Grid.Column>
            <Grid.Column
              mobile={1}
              tablet={1}
              computer={1}
              largeScreen={1}
              widescreen={1}
            >
              Type
            </Grid.Column>
            <Grid.Column width={1}>Areas</Grid.Column>
            <Grid.Column width={1}>Chemicals</Grid.Column>
            <Grid.Column
              mobile={1}
              tablet={1}
              computer={1}
              largeScreen={1}
              widescreen={1}
              textAlign="center"
            >
              Progress
            </Grid.Column>
            <Grid.Column width={2}>Supervisors</Grid.Column>
            <Grid.Column width={2}>Operators</Grid.Column>
            <Grid.Column
              mobile={8}
              tablet={2}
              computer={2}
              largeScreen={2}
              widescreen={2}
            >
              Date
              <Popup
                className={styles.popup}
                trigger={<Icon name="info circle" />}
                content={
                  <div>
                    <div>
                      <Icon name="clock outline" color="brown" /> - &nbsp;
                      Planned date
                    </div>
                    <div>
                      <Icon name="calendar outline" /> - &nbsp; End date
                    </div>
                  </div>
                }
                size="small"
                basic
              />
            </Grid.Column>
            <Grid.Column
              mobile={8}
              tablet={1}
              computer={1}
              largeScreen={1}
              widescreen={1}
            >
              Total Vat
            </Grid.Column>
          </Grid.Row>
        </Can>
        <Can not I="add" a="spray_tasks">
          <Grid.Row>
            <Grid.Column
              mobile={1}
              tablet={2}
              computer={2}
              largeScreen={2}
              widescreen={2}
            >
              ID
            </Grid.Column>
            <Grid.Column largeScreen={2} widescreen={1}>
              Status
            </Grid.Column>
            <Grid.Column width={2}>Areas</Grid.Column>
            <Grid.Column width={2}>Total ha</Grid.Column>
            <Grid.Column width={1}>Total Tank</Grid.Column>
            <Grid.Column width={2}>Instructions</Grid.Column>
            <Grid.Column width={2}>Operator</Grid.Column>
            <Grid.Column
              mobile={8}
              tablet={2}
              computer={2}
              largeScreen={2}
              widescreen={2}
            >
              Date
              <Popup
                className={styles.popup}
                trigger={<Icon name="info circle" />}
                content={
                  <div>
                    <div>
                      <Icon name="clock outline" color="brown" /> - &nbsp;
                      Planned date
                    </div>
                    <div>
                      <Icon name="calendar outline" /> - &nbsp; End date
                    </div>
                  </div>
                }
                size="small"
                basic
              />
            </Grid.Column>
            <Grid.Column
              mobile={8}
              tablet={1}
              computer={1}
              largeScreen={1}
              widescreen={2}
            >
              Total Vat
            </Grid.Column>
          </Grid.Row>
        </Can>
      </Grid>
    </Segment>
  );
}

RowHeader.propTypes = {
  Can: PropTypes.func
};

RowHeader.defaultProps = {};

export default RowHeader;
