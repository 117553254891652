import { actionType } from "../../constants/PendingMovements";

const initialState = {
  list: {
    content: [],
    isFetching: false
  },
  data: {
    content: [],
    isFetching: false
  },
  filters: {
    date: {
      content: [],
      isFetching: false
    },
    product: {
      content: [],
      error: null,
      isFetching: false
    },
    shed: {
      id: null,
      isEditing: false
    }
  }
};
export default (state = initialState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case actionType.PENDING_OPERATIONS_START:
      return {
        ...state,
        data: {
          ...state.data,
          isFetching: true
        }
      };
    case actionType.PENDING_OPERATIONS: {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
          isFetching: false
        }
      };
    }
    case actionType.PENDING_OPERATIONS_LIST_START:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: true
        }
      };
    case actionType.PENDING_OPERATIONS_LIST: {
      return {
        ...state,
        list: {
          ...state.list,
          ...action.payload,
          isFetching: false
        }
      };
    }

    default:
      return state;
  }
};
