import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Button, Container, Grid, Icon, Message } from "semantic-ui-react";
import TaskRow from "../../TaskRow";
import { connect, useSelector } from "react-redux";
import SelectDilusionRate from "../../../CreateSprayTask/SprayForm/SelectChemicals/SelectDilusionRate";
import { matterToUnits, rateToUnits } from "../../../../../utils/constToUnits";
import { calculateChemicalQuantity } from "utils/chemicalBreakdownUtils";
import { calcTotalHectare } from "utils/tasksUtils";
import Numeric from "../../../../../components/Numeric";
import styles from "./ChemicalItem.module.css";
import { unitsSelector } from "selectors/chemicals";

function ChemicalItem({
  item,
  chemicalsList,
  list,
  shedId,
  applicationType,
  showAddNewChemical,
  sprayTask,
  sprayTask: {
    selectedAreas,
    selectedChemicals,
    selectedMachinery,
    rowsToSpray,
    widthPerRow,
    literPerHectare,
    conc
  },
  chemicalsOptions,
  totalTanks,
  toggleEditField,
  fieldToEdit,
  onChange,
  onChangeChemical,
  onChangeDilution,
  editAllMode,
  deleteChemicalItem,
  shouldDisableFieldsEdit
}) {
  const [filteredOptions, setOptions] = useState(
    (applicationType === "SPRAY"
      ? chemicalsList.content
      : chemicalsList.content.filter(item => {
          return item.rates.some(i => i.rateType === "PER_HECTARE");
        })
    ).map(item => ({
      key: item.id,
      text: `${item.name}`,
      value: item.id
    }))
  );

  const selectedShedProductsId = list.content
    .find(({ id }) => id === shedId)
    ?.buckets.map(item => item.chemicalId);

  const selectedShedProduct = [
    {
      key: -3,
      text: (
        <b>{`${
          list.content.find(item => item.id === shedId)?.name
        } inventory`}</b>
      ),
      value: -3,
      className: "selectedShedProductsHeaders"
    },
    ...(applicationType === "SPRAY"
      ? chemicalsList.content
      : chemicalsList.content.filter(item =>
          item.rates.some(i => i.rateType === "PER_HECTARE")
        )
    )
      .filter(product => {
        return selectedShedProductsId?.some(
          chemicalId => product.id === chemicalId
        );
      })
      .map(item => ({
        key: item.id,
        text: `${item.name}`,
        value: item.id
      }))
      ?.sort(function(a, b) {
        var nameA = a.text?.toLowerCase(),
          nameB = b.text?.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      })
  ];
  const { content, isFetching } = useSelector(unitsSelector);
  const multiplier = item => {
    const foundElement = content.find(({ id }) => id === item);

    if (foundElement) {
      return 1 / foundElement.multiplicationFactor;
    }

    return 1;
  };
  const multiplierValue = useMemo(() => multiplier(item.rate.unit));
  const restProducts = [
    {
      key: -5,
      text: <b>Other products</b>,
      value: -5,
      className: "restProductsHeaders"
    },
    ...(applicationType === "SPRAY"
      ? chemicalsList.content
      : chemicalsList.content.filter(item =>
          item.rates.some(i => i.rateType === "PER_HECTARE")
        )
    )
      .filter(product => {
        return selectedShedProductsId?.every(
          chemicalId => product.id != chemicalId
        );
      })
      ?.map(item => ({
        key: item.id,
        text: item.name,
        value: item.id,
        color: "#fffaf2",
        className: "restProducts"
      }))
      .sort(function(a, b) {
        var nameA = a.text?.toLowerCase(),
          nameB = b.text?.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      })
  ];

  const sortedProductList = selectedShedProduct.concat(restProducts);

  const optionsWithSelectedShed = [...sortedProductList];
  const { quantity: chemicalQty, units: chemicalUnits } = useMemo(() => {
    const totalHectares = calcTotalHectare(selectedAreas);
    return calculateChemicalQuantity(
      item,
      totalHectares,
      rowsToSpray,
      widthPerRow,
      literPerHectare,
      conc
    );
  }, [selectedAreas, item]);

  useEffect(() => {
    const selectedChemicalsIds = selectedChemicals.map(chemical => chemical.id);

    setOptions(
      (applicationType === "SPRAY"
        ? chemicalsList.content
        : chemicalsList.content.filter(item =>
            item.rates.some(i => i.rateType === "PER_HECTARE")
          )
      )
        .map(item => ({
          key: item.id,
          text: `${item.name}`,
          value: item.id
        }))
        .filter(
          option =>
            !selectedChemicalsIds.includes(option.value) ||
            option.value === item.id
        )
    );
  }, [item, selectedChemicals, chemicalsOptions]);

  const getInfoMessage = () => {
    return (
      <Message color="blue">
        <Icon name="warning" /> The chemicals you have selected have{" "}
        <strong>{item.reentry} day(s)</strong> of Re-Entry{" "}
        {item.rate && item.rate.withholdingPeriod >= 0 && (
          <span>
            and {<strong>{item.rate.withholdingPeriod} day(s)</strong>} of
            Withholding
          </span>
        )}{" "}
        Period
      </Message>
    );
  };
  const unitFormater = item => {
    let unit;
    if (item === "liter") {
      unit = "L";
    } else if (item === "kilogram") {
      unit = "kg";
    } else if (item === "milliliter") {
      unit = "mL";
    } else {
      unit = "g";
    }
    return unit;
  };

  return (
    <div className={styles.chemicalHolder}>
      {!editAllMode && selectedChemicals.length > 1 && (
        <Button
          className={`button-text ${styles.buttonDel}`}
          icon="delete"
          onClick={() => deleteChemicalItem(item.id)}
        />
      )}

      <Grid verticalAlign="middle" className="no-margin">
        <TaskRow
          label="Name"
          name="selectedChemical"
          suffix={item.id}
          inputType="dropdown"
          placeholder="Select chemical"
          emptyLabel="Select chemical"
          value={item.id}
          options={
            shedId && !!list.content.length
              ? optionsWithSelectedShed
              : filteredOptions.sort(function(a, b) {
                  var nameA = a.text?.toLowerCase(),
                    nameB = b.text?.toLowerCase();
                  if (nameA < nameB) return -1;
                  if (nameA > nameB) return 1;
                  return 0;
                })
          }
          toggleEditField={toggleEditField}
          fieldToEdit={fieldToEdit}
          onChange={onChangeChemical}
          editAllMode={editAllMode}
          search
          disabled={shouldDisableFieldsEdit}
        />
        <TaskRow
          label="Type"
          name="chemicalType"
          value={<span>{item.type ? item.type.type : null}</span>}
          disabled={shouldDisableFieldsEdit}
        />
        {((fieldToEdit &&
          fieldToEdit.name === "selectedChemical" &&
          fieldToEdit.suffix === item.id) ||
          (editAllMode && item.id)) && (
          <Grid.Row>
            <Grid.Column>{getInfoMessage()}</Grid.Column>
          </Grid.Row>
        )}
        <TaskRow
          label="Target"
          name="chemicalTarget"
          suffix={item.id}
          inputType="component"
          value={[
            item.rate.crop && item.rate.crop.name
              ? item.rate.crop.name
              : "Generic",
            item.rate.purpose
          ]
            .filter(item => item)
            .join(" - ")}
          component={
            <SelectDilusionRate
              item={item}
              setItem={onChangeDilution}
              error={fieldToEdit && fieldToEdit.error}
            />
          }
          toggleEditField={toggleEditField}
          fieldToEdit={fieldToEdit}
          editAllMode={editAllMode}
          rules={{ required: { keyToCheck: "rate.id" } }}
          disabled={shouldDisableFieldsEdit}
        />
        <TaskRow
          label="Rate"
          name="chemicalRate.rate"
          suffix={item.id}
          inputType="rateInput"
          disabled={!(item.rate && item.rate.id)}
          value={
            item.rate && item.rate.rate
              ? item.rate.rate.toString().slice(-1) != "."
                ? item.rate.rate * multiplierValue
                : item.rate.rate
              : ""
          }
          toggleEditField={toggleEditField}
          fieldToEdit={fieldToEdit}
          valueToSet={item.rate}
          onChange={onChange}
          minRate={item.rate.minRate}
          multiplierValue={multiplierValue}
          unit={item.rate.unit}
          fluid
          editAllMode={editAllMode}
          inputLabel={{
            content: !!Object.keys(item.rate).length
              ? unitFormater(item.rate.unit)
              : "—",
            inverted: "true"
          }}
          labelPosition="right"
          rules={{ required: true, numeric: true }}
        />
        <TaskRow
          label="Rate Type"
          name="chemicalRateType"
          value={<span>{rateToUnits(item.rate.rateType)}</span>}
          fieldToEdit={fieldToEdit}
          disabled={shouldDisableFieldsEdit}
        />
        <TaskRow
          label="Total Quantity"
          name="chemicalQuantity"
          value={
            <Numeric
              value={
                !!Object.keys(item.rate).length
                  ? chemicalQty * multiplier(item.rate.unit)
                  : ""
              }
              units={
                !!Object.keys(item.rate).length
                  ? unitFormater(item.rate.unit)
                  : "—"
              }
            />
          }
          fieldToEdit={fieldToEdit}
        />
        <TaskRow
          label="Total Quantity"
          name="chemicalQuantity"
          value={
            <Numeric
              value={
                !!Object.keys(item.rate).length
                  ? chemicalQty * multiplier(item.rate.unit)
                  : ""
              }
              units={
                !!Object.keys(item.rate).length
                  ? unitFormater(item.rate.unit)
                  : "—"
              }
            />
          }
          fieldToEdit={fieldToEdit}
        />
        <TaskRow
          label={
            selectedMachinery && selectedMachinery.id
              ? "Qty Per Full Tank"
              : "Full Qty"
          }
          name="chemicalFullTank"
          value={
            <Numeric
              value={
                !!Object.keys(item.rate).length
                  ? totalTanks < 1
                    ? 0
                    : item.fullTanks * multiplier(item.rate.unit)
                  : ""
              }
              fractionDigits={totalTanks < 1 ? 0 : 3}
              units={
                !!Object.keys(item.rate).length
                  ? unitFormater(item.rate.unit)
                  : "—"
              }
            />
          }
          fieldToEdit={fieldToEdit}
          disabled={shouldDisableFieldsEdit}
        />
        {selectedMachinery && selectedMachinery.id && (
          <TaskRow
            label="Qty Per Part Tank"
            name="chemicalPartTanks"
            value={
              <Numeric
                value={
                  !!Object.keys(item.rate).length
                    ? item.partTanks * multiplier(item.rate.unit)
                    : ""
                }
                units={
                  !!Object.keys(item.rate).length
                    ? unitFormater(item.rate.unit)
                    : "—"
                }
              />
            }
            fieldToEdit={fieldToEdit}
            disabled={shouldDisableFieldsEdit}
          />
        )}
        <>
          {(item.batchNumbers || []).map((batchNumber, index) => {
            return (
              !showAddNewChemical && (
                <TaskRow
                  label={index === 0 ? "Batch No." : " "}
                  name={`chemicalBatchNumber${index}`}
                  suffix={item.id}
                  item={item}
                  index={index}
                  inputType="batchNumbersInSideBar"
                  value={batchNumber}
                  showAddNewChemical={showAddNewChemical}
                  toggleEditField={toggleEditField}
                  fieldToEdit={fieldToEdit}
                  sprayTask={sprayTask}
                  onBatchNumberChange={onChange}
                  disabled={!item.name}
                  fluid
                  editAllMode={editAllMode}
                  rules={{
                    ...(item.batchRequired ? { required: false } : {})
                  }}
                />
              )
            );
          })}
          <TaskRow
            label={showAddNewChemical ? "Batch No." : " "}
            name="addForm"
            suffix={item.id}
            item={item}
            inputType="addForm"
            toggleEditField={toggleEditField}
            fieldToEdit={fieldToEdit}
            sprayTask={sprayTask}
            onBatchNumberChange={onChange}
            disabled={!item.name}
            fluid
            editAllMode={editAllMode}
            rules={{
              ...(item.batchRequired ? { required: false } : {})
            }}
          />
        </>
        <TaskRow
          label="WHP"
          name="withholdingPeriod"
          value={
            <Numeric
              value={
                item.rate.withholdingPeriod > 0
                  ? item.rate.withholdingPeriod
                  : null
              }
              fractionDigits={0}
              units={"days"}
            />
          }
          fieldToEdit={fieldToEdit}
          disabled={shouldDisableFieldsEdit}
        />
        <TaskRow
          label="Re-entry"
          name="reentry"
          value={
            <Numeric
              value={item.reentry > 0 ? item.reentry : null}
              fractionDigits={0}
              units={"days"}
            />
          }
          fieldToEdit={fieldToEdit}
          disabled={shouldDisableFieldsEdit}
        />
      </Grid>
    </div>
  );
}
const mapStateToProps = state => {
  const {
    sprayDiary: { chemicalsList, shedId, applicationType },
    shed: { list }
  } = state;

  return {
    batchNumbers: state.spray.batchNumbers,
    chemicalsList,
    shedId,
    applicationType,
    list
  };
};

ChemicalItem.propTypes = {
  editAllMode: PropTypes.bool,
  item: PropTypes.object,
  sprayTask: PropTypes.object,
  chemicalsOptions: PropTypes.array,
  totalTanks: PropTypes.number,
  toggleEditField: PropTypes.func,
  fieldToEdit: PropTypes.object,
  onChange: PropTypes.func,
  onChangeChemical: PropTypes.func,
  onChangeDilution: PropTypes.func,
  deleteChemicalItem: PropTypes.func,
  shouldDisableFieldsEdit: PropTypes.bool
};

ChemicalItem.defaultProps = {
  editAllMode: false
};

export default connect(mapStateToProps)(ChemicalItem);
