import React from "react";

const Logo = () => (
  <svg
    height="80px"
    viewBox="0 0 256 108"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <polygon
        id="path-1"
        points="0.06 0.935 34.001 0.935 34.001 34.936 0.06 34.936"
      />
    </defs>
    <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="TUF_Logo_Final_2">
        <polyline
          id="Fill-43"
          fill="#D2DB2F"
          points="48.247 60.984 19.952 32.689 21.667 30.974 49.963 59.269 48.247 60.984"
        />
        <polyline
          id="Fill-44"
          fill="#D2DB2F"
          points="44.257 64.975 15.962 36.679 17.677 34.963 45.973 63.259 44.257 64.975"
        />
        <polyline
          id="Fill-45"
          fill="#D2DB2F"
          points="40.267 68.964 11.971 40.669 13.687 38.954 41.983 67.249 40.267 68.964"
        />
        <polyline
          id="Fill-46"
          fill="#D2DB2F"
          points="36.277 72.955 7.981 44.66 9.696 42.944 37.992 71.24 36.277 72.955"
        />
        <g id="Group-50" transform="translate(0.000000, 46.000000)">
          <mask id="mask-2" fill="white" />
          <g id="Clip-48" />
          <polyline
            id="Fill-47"
            fill="#D2DB2F"
            mask="url(#mask-2)"
            points="32.285 30.946 3.99 2.65 5.706 0.935 34.001 29.23 32.285 30.946"
          />
          <polyline
            id="Fill-49"
            fill="#D2DB2F"
            mask="url(#mask-2)"
            points="28.295 34.936 0 6.641 1.715 4.925 30.011 33.221 28.295 34.936"
          />
        </g>
        <polyline
          id="Fill-51"
          fill="#2BAB6E"
          points="28.393 83.671 26.677 81.955 54.973 53.661 56.688 55.376 28.393 83.671"
        />
        <polyline
          id="Fill-52"
          fill="#2BAB6E"
          points="32.383 87.661 30.667 85.947 58.963 57.651 60.679 59.366 32.383 87.661"
        />
        <polyline
          id="Fill-53"
          fill="#2BAB6E"
          points="36.373 91.652 34.658 89.936 62.953 61.641 64.669 63.357 36.373 91.652"
        />
        <polyline
          id="Fill-54"
          fill="#2BAB6E"
          points="40.364 95.642 38.649 93.927 66.943 65.631 68.659 67.346 40.364 95.642"
        />
        <polyline
          id="Fill-55"
          fill="#2BAB6E"
          points="44.355 99.632 42.639 97.916 70.935 69.621 72.65 71.337 44.355 99.632"
        />
        <polyline
          id="Fill-56"
          fill="#2BAB6E"
          points="48.345 103.623 46.629 101.908 74.925 73.612 76.64 75.327 48.345 103.623"
        />
        <polyline
          id="Fill-57"
          fill="#2BAB6E"
          points="52.335 107.613 50.619 105.898 78.915 77.602 80.63 79.318 52.335 107.613"
        />
        <polyline
          id="Fill-58"
          fill="#0B8B42"
          points="81.65 80.936 53.355 52.641 55.069 50.925 83.365 79.221 81.65 80.936"
        />
        <polyline
          id="Fill-59"
          fill="#0B8B42"
          points="85.64 76.946 57.345 48.65 59.06 46.935 87.356 75.23 85.64 76.946"
        />
        <polyline
          id="Fill-60"
          fill="#0B8B42"
          points="89.631 72.955 61.335 44.66 63.051 42.944 91.346 71.24 89.631 72.955"
        />
        <polyline
          id="Fill-61"
          fill="#0B8B42"
          points="93.621 68.964 65.325 40.669 67.041 38.954 95.336 67.249 93.621 68.964"
        />
        <polyline
          id="Fill-62"
          fill="#0B8B42"
          points="97.611 64.975 69.317 36.679 71.031 34.963 99.326 63.259 97.611 64.975"
        />
        <polyline
          id="Fill-63"
          fill="#0B8B42"
          points="101.602 60.984 73.307 32.689 75.022 30.974 103.318 59.269 101.602 60.984"
        />
        <polyline
          id="Fill-64"
          fill="#0B8B42"
          points="105.592 56.995 77.297 28.699 79.012 26.983 107.308 55.279 105.592 56.995"
        />
        <polyline
          id="Fill-65"
          fill="#84BF41"
          points="52.335 54.259 50.619 52.543 78.915 24.247 80.63 25.963 52.335 54.259"
        />
        <polyline
          id="Fill-66"
          fill="#84BF41"
          points="48.345 50.269 46.629 48.553 74.925 20.258 76.64 21.973 48.345 50.269"
        />
        <polyline
          id="Fill-67"
          fill="#84BF41"
          points="44.355 46.278 42.639 44.562 70.935 16.267 72.65 17.983 44.355 46.278"
        />
        <polyline
          id="Fill-68"
          fill="#84BF41"
          points="40.364 42.288 38.649 40.573 66.943 12.277 68.659 13.992 40.364 42.288"
        />
        <polyline
          id="Fill-69"
          fill="#84BF41"
          points="36.373 38.297 34.658 36.581 62.953 8.286 64.669 10.002 36.373 38.297"
        />
        <polyline
          id="Fill-70"
          fill="#84BF41"
          points="32.383 34.307 30.667 32.592 58.963 4.296 60.679 6.011 32.383 34.307"
        />
        <polyline
          id="Fill-71"
          fill="#84BF41"
          points="28.393 30.317 26.677 28.601 54.973 0.306 56.688 2.022 28.393 30.317"
        />
        <polyline
          id="Fill-72"
          fill="#84BF41"
          points="127.989 22.48 118.954 22.48 118.954 45.408 113.201 45.408 113.201 22.48 104.209 22.48 104.209 17.281 127.989 17.281 127.989 22.48"
        />
        <path
          d="M131.399,45.408 L137.153,45.408 L137.153,24.312 L131.399,24.312 L131.399,45.408 Z M137.152,19.38 C137.152,20.999 135.855,22.241 134.259,22.241 C132.662,22.241 131.399,20.999 131.399,19.38 C131.399,17.727 132.662,16.487 134.259,16.487 C135.855,16.487 137.152,17.727 137.152,19.38 L137.152,19.38 Z"
          id="Fill-73"
          fill="#84BF41"
        />
        <path
          d="M146.318,32.75 L155.948,32.75 C155.905,30.066 153.819,28.489 151.389,28.489 C148.96,28.489 146.999,30.108 146.318,32.75 Z M161.148,36.798 L146.318,36.798 C147.041,39.484 149.258,40.975 152.283,40.975 C154.585,40.975 156.459,40.165 158.165,38.844 L160.551,42.765 C158.165,44.768 155.352,45.918 152.112,45.918 C145.422,45.918 140.564,41.103 140.564,34.881 C140.564,28.659 145.38,23.843 151.602,23.843 C157.525,23.843 161.531,28.19 161.531,33.645 C161.531,34.839 161.318,36.075 161.148,36.798 L161.148,36.798 Z"
          id="Fill-74"
          fill="#84BF41"
        />
        <path
          d="M188.893,33.774 C188.893,41.317 183.779,45.918 176.96,45.918 C170.1,45.918 165.028,41.317 165.028,33.774 L165.028,17.281 L170.781,17.281 L170.781,34.199 C170.781,38.418 173.466,40.591 176.96,40.591 C180.498,40.591 183.14,38.418 183.14,34.199 L183.14,17.281 L188.893,17.281 L188.893,33.774"
          id="Fill-75"
          fill="#0B8B42"
        />
        <path
          d="M208.758,34.881 C208.758,31.301 206.329,29.042 203.303,29.042 C200.661,29.042 197.976,30.789 197.976,34.839 C197.976,38.844 200.618,40.676 203.303,40.676 C206.287,40.676 208.758,38.46 208.758,34.881 Z M214.597,34.839 C214.597,41.188 210.335,45.918 204.368,45.918 C201.769,45.918 199.553,44.981 198.062,43.362 L198.062,53.078 L192.309,53.078 L192.309,24.312 L197.593,24.312 L197.593,26.954 C199.041,24.952 201.429,23.843 204.368,23.843 C210.335,23.843 214.597,28.489 214.597,34.839 L214.597,34.839 Z"
          id="Fill-76"
          fill="#0B8B42"
        />
        <polyline
          id="Fill-77"
          fill="#1A1919"
          points="127.127 68.11 114.385 68.11 114.385 75.142 124.825 75.142 124.825 80.34 114.385 80.34 114.385 91.038 108.632 91.038 108.632 62.911 127.127 62.911 127.127 68.11"
        />
        <path
          d="M141.745,83.154 L141.745,82.898 L137.228,82.898 C135.353,82.898 134.287,83.621 134.287,85.029 C134.287,86.478 135.523,87.329 137.314,87.329 C139.742,87.329 141.745,85.624 141.745,83.154 Z M147.498,78.508 L147.498,91.038 L142.214,91.038 L142.214,88.992 C140.722,90.568 138.506,91.548 135.778,91.548 C131.176,91.548 128.492,88.821 128.492,85.241 C128.492,81.406 131.603,79.105 136.46,79.105 L141.745,79.105 L141.745,78.166 C141.745,75.866 140.424,74.46 137.824,74.46 C135.567,74.46 133.989,75.526 132.241,77.358 L129.259,73.82 C131.73,70.88 134.756,69.473 138.464,69.473 C143.833,69.473 147.498,72.499 147.498,78.508 L147.498,78.508 Z"
          id="Fill-78"
          fill="#1A1919"
        />
        <path
          d="M163.782,69.942 L163.782,75.014 L162.248,75.014 C158.925,75.014 156.581,76.718 156.581,80.511 L156.581,91.038 L150.827,91.038 L150.827,69.942 L156.112,69.942 L156.112,72.499 C157.519,70.667 159.607,69.687 161.908,69.687 C162.675,69.687 163.229,69.772 163.782,69.942"
          id="Fill-79"
          fill="#1A1919"
        />
        <path
          d="M198.826,77.954 L198.826,91.038 L193.074,91.038 L193.074,78.763 C193.074,76.249 191.582,74.672 189.323,74.672 C187.022,74.672 185.445,76.292 185.445,78.636 L185.445,91.038 L179.693,91.038 L179.693,78.763 C179.693,76.249 178.2,74.672 175.943,74.672 C173.641,74.672 172.065,76.249 172.065,78.763 L172.065,91.038 L166.311,91.038 L166.311,69.942 L171.595,69.942 L171.595,71.818 C173.002,70.368 175.047,69.473 177.562,69.473 C180.416,69.473 182.547,70.582 183.826,72.499 C185.403,70.667 187.917,69.473 190.858,69.473 C195.887,69.473 198.826,72.883 198.826,77.954"
          id="Fill-80"
          fill="#1A1919"
        />
        <path
          d="M202.262,91.038 L208.016,91.038 L208.016,69.942 L202.262,69.942 L202.262,91.038 Z M208.015,63.728 C208.015,65.347 206.718,66.587 205.121,66.587 C203.526,66.587 202.262,65.347 202.262,63.728 C202.262,62.075 203.526,60.833 205.121,60.833 C206.718,60.833 208.015,62.075 208.015,63.728 L208.015,63.728 Z"
          id="Fill-81"
          fill="#1A1919"
        />
        <path
          d="M230.904,77.954 L230.904,91.038 L225.195,91.038 L225.195,78.721 C225.195,76.292 223.618,74.63 221.274,74.63 C218.93,74.63 217.268,76.292 217.268,78.721 L217.268,91.038 L211.514,91.038 L211.514,69.942 L216.799,69.942 L216.799,71.86 C218.248,70.411 220.379,69.473 222.978,69.473 C227.922,69.473 230.904,72.883 230.904,77.954"
          id="Fill-82"
          fill="#1A1919"
        />
        <path
          d="M249.746,79.828 C249.746,75.951 247.062,74.374 244.42,74.374 C241.437,74.374 238.965,76.377 238.965,79.872 C238.965,83.367 241.479,85.539 244.42,85.539 C247.105,85.539 249.746,83.707 249.746,79.828 Z M255.414,69.942 L255.414,88.694 C255.414,95.81 250.854,99.219 244.505,99.219 C239.902,99.219 236.535,97.43 234.15,95.299 L237.175,91.292 C239.263,92.911 241.309,94.02 244.036,94.02 C247.275,94.02 249.661,92.444 249.661,89.034 L249.661,87.883 C248.17,89.502 245.868,90.398 243.226,90.398 C237.431,90.398 233.126,86.179 233.126,79.914 C233.126,73.565 237.473,69.473 243.226,69.473 C246.294,69.473 248.681,70.582 250.13,72.584 L250.13,69.942 L255.414,69.942 L255.414,69.942 Z"
          id="Fill-83"
          fill="#1A1919"
        />
        <polyline
          id="Fill-84"
          fill="#D2DB2F"
          points="53.653 54.98 25.657 26.983 23.942 28.699 51.939 56.695 53.653 54.98"
        />
      </g>
    </g>
  </svg>
);

export default Logo;
