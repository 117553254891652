import React from "react";
import PropTypes from "prop-types";

import {
  Header,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow
} from "semantic-ui-react";

const SprayTasksTable = ({ title, columns, data }) => {
  return (
    <div>
      {title && (
        <Header as={"h3"} style={{ marginTop: 30 }}>
          {title}
        </Header>
      )}
      <Table style={{ width: "98%" }}>
        <TableHeader>
          <TableRow>
            {columns.map((column, i) => (
              <TableHeaderCell key={`column_header_${i}`}>
                <TableCell>{column.Header}</TableCell>
              </TableHeaderCell>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {data.map((item, i) => (
            <TableRow key={`table_body_${i}`}>
              {columns.map((column, j) => (
                <TableCell key={`cell_${i}_${j}`}>
                  {item[column.accessor]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

SprayTasksTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  title: PropTypes.string
};

export default SprayTasksTable;
