import { createSelector } from "reselect";
import { get } from "lodash";

const tableDataSelector = state =>
  state.reports.harvest.fieldScanning.harvestByVariety.tableData;
const tableListDataSelector = state =>
  state.reports.harvest.fieldScanning.harvestByVariety.listData;
const varietiesListSelector = state => state.varieties.data;
const areasListSelector = state => state.areas.list.content;

export const formaterData = (data, varietiesList, areasList) => {
  const { content, ...rest } = data;
  const contentUpdated = content.length
    ? content
        .map(block => {
          const varietiesList =
            areasList &&
            areasList.find(({ varieties }) =>
              varieties.find(
                ({ variety }) => variety && variety.id == block.varietyId
              )
            );

          const varietyData =
            varietiesList &&
            varietiesList.varieties.find(
              ({ variety }) => variety.id == block.varietyId
            );

          return {
            varietyName: get(varietyData && varietyData.variety, "name", "-"),
            ...block.items.reduce(
              (prev, curr, _, items) => {
                const weightInTonne = (curr.binWeight * curr.count) / 1000;
                const weight = prev.weight + weightInTonne;
                return {
                  ...prev,
                  size: block.areaSize,
                  binUnitsNumber: prev.binUnitsNumber + curr.count,
                  weight,
                  tonnePerHa: weight / block.areaSize,
                  cost: prev.cost + curr.cost,
                  costPerTonne: (prev.cost + curr.cost) / weight,
                  costPerBin:
                    (prev.cost + curr.cost) /
                    (prev.binUnitsNumber + curr.count),
                  binUnits: prev.binUnits.includes(
                    `${curr.unitName} ${curr.binWeight} Kg`
                  )
                    ? prev.binUnits
                    : [
                        ...prev.binUnits,
                        `${curr.unitName} ${curr.binWeight} Kg`
                      ],
                  numberOfPlants: block.numberOfPlants,
                  avgKgPerTree:
                    block.numberOfPlants &&
                    (weight * 1000) / block.numberOfPlants,
                  subRows: [
                    ...prev.subRows,
                    {
                      size: curr.areaSize,
                      binUnitsNumber: curr.count,
                      weight: weightInTonne,
                      binUnits: `${curr.unitName} ${curr.binWeight} Kg`,
                      tonnePerHa: weightInTonne / curr.areaSize,
                      cost: curr.cost,
                      costPerTonne: curr.cost / weightInTonne,
                      costPerBin: curr.cost / curr.count,
                      avgKgPerTree:
                        curr.numberOfPlants &&
                        (weightInTonne * 1000) / curr.numberOfPlants
                    }
                  ]
                };
              },
              {
                binUnitsNumber: 0,
                weight: 0,
                tonnePerHa: 0,
                numberOfPlants: 0,
                cost: 0,
                costPerTonne: 0,
                costPerBin: 0,
                binUnits: [],
                avgKgPerTree: 0,
                varieties: [],
                crops: [],
                subRows: []
              }
            )
          };
        }, {})
        .map(({ subRows, withPatches, ...rest }) => ({
          ...rest,
          ...(withPatches
            ? {
                subRows: subRows.map(({ subRows, ...rest }) =>
                  subRows.length > 1 ? { ...rest, subRows } : rest
                )
              }
            : subRows.length > 1 && { subRows })
        }))
    : [];

  return {
    ...rest,
    content: contentUpdated
  };
};

export const selectTableStructuredData = createSelector(
  [tableDataSelector, varietiesListSelector, areasListSelector],
  (tableData, varietiesList, areasList) => {
    return formaterData(tableData, varietiesList, areasList);
  }
);

export const selectExpotStructuredData = createSelector(
  [tableListDataSelector, varietiesListSelector, areasListSelector],
  (listData, varietiesList, areasList) => {
    return formaterData(listData, varietiesList, areasList);
  }
);
