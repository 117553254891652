import React from "react";
import { Button } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ChemicalForm from "routes/Chemicals/AddNewChemical/ChemicalForm";
import { addNewChemical } from "actions/SprayDiary/chemicals";
import PropTypes from "prop-types";
import AddNewModal from "components/AddNewModal";
// eslint-disable-next-line
import styles from "./AddChemicalModal.module.css";
const EditForm = React.forwardRef((props, ref) => (
  <ChemicalForm isNewChemical={true} {...props} formRef={ref} />
));

class AddChemicalModal extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  onValidSubmit = async data => {
    const { actions, onSelectChemical, onClose } = this.props;
    const chemical = await actions.addNewChemical(data);

    if (chemical.id) {
      onSelectChemical("new", chemical.id);
    }
    onClose();
  };

  handleSaveAction = () => {
    this.formRef.current.submit();
  };

  render() {
    const { open, onClose } = this.props;
    return (
      <AddNewModal
        open={open}
        title="Add New Chemical"
        formToShow={
          <EditForm ref={this.formRef} onValidSubmit={this.onValidSubmit} />
        }
        onClose={onClose}
        actions={
          <Button primary size="large" onClick={this.handleSaveAction}>
            Add chemical
          </Button>
        }
      />
    );
  }
}

AddChemicalModal.propTypes = {
  actions: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSelectChemical: PropTypes.func
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        addNewChemical
      },
      dispatch
    )
  };
}

export default connect(null, mapDispatchToProps)(AddChemicalModal);
