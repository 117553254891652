export const actionType = {
  FETCH_SPRAY_TASKS_START:
    "APPLICATIONS_RECORD_BY_BLOCK/FETCH_SPRAY_TASKS_START",
  FETCH_SPRAY_TASKS: "APPLICATIONS_RECORD_BY_BLOCK/FETCH_SPRAY_TASKS",
  FETCH_SPRAY_TASKS_FAIL: "APPLICATIONS_RECORD_BY_BLOCK/FETCH_SPRAY_TASKS_FAIL",

  FETCH_SPRAY_TASKS_LIST_START:
    "APPLICATIONS_RECORD_BY_BLOCK/FETCH_SPRAY_TASKS_LIST_START",
  FETCH_SPRAY_TASKS_LIST: "APPLICATIONS_RECORD_BY_BLOCK/FETCH_SPRAY_TASKS_LIST",
  FETCH_SPRAY_TASKS_LIST_FAIL:
    "APPLICATIONS_RECORD_BY_BLOCK/FETCH_SPRAY_TASKS_LIST_FAIL"
};
