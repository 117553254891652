import PropTypes from "prop-types";
import {
  MATTER_TYPE_LIQUID as LIQUID,
  MATTER_TYPE_SOLID as SOLID,
  PER_100_LITERS,
  PER_HECTARE
} from "../Farms";

export const SHED_MATTERS = {
  LIQUID: "Liquid",
  SOLID: "Solid"
};

export const FERTILISER = "Fertiliser";

export const shedShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  trackShed: PropTypes.bool,
  matter: PropTypes.oneOf(Object.keys(SHED_MATTERS)),
  type: {
    id: PropTypes.number,
    type: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([FERTILISER])])
  },
  price: PropTypes.number,
  batchRequired: PropTypes.bool,
  rates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      crop: {
        id: PropTypes.number,
        name: PropTypes.string,
        spacingType: PropTypes.string // TODO: Use Enum
      },
      purpose: PropTypes.string,
      minRate: PropTypes.number,
      maxRate: PropTypes.number,
      rateType: PropTypes.oneOf([PER_100_LITERS, PER_HECTARE]),
      withholdingPeriod: PropTypes.number,
      comment: PropTypes.string,
      reentry: PropTypes.number
    })
  ),
  notes: PropTypes.string,
  sds: PropTypes.string,
  sdsAttachment: null, // TODO: Attachment
  label: PropTypes.string,
  labelAttachment: null, // TODO: Attachment
  poisonSchedule: PropTypes.string,
  resistanceGroup1: PropTypes.string,
  resistanceGroup2: PropTypes.string,

  dangerousProduct: PropTypes.bool,
  organic: PropTypes.bool,
  registrationNumber: PropTypes.string,
  attachments: PropTypes.shape({
    subPath: PropTypes.string,
    id: PropTypes.string,
    presignedUrl: PropTypes.string,
    name: PropTypes.string
  }),
  shedUserPermitNumber: PropTypes.string,
  permitExpDate: PropTypes.string,
  archived: PropTypes.bool,
  unit: PropTypes.string
});
