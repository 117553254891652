import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { Form } from "semantic-ui-react";
import { getChemicalsList } from "actions/SprayDiary/chemicals";
import { isEmpty } from "lodash";
import { productsOptionsSelector } from "selectors/chemicals";

const SelectProduct = ({
  actions: { getChemicalsList },
  chemicalsList,
  isFetching,
  onChange,
  value,
  name
}) => {
  const chemicalOptions = useSelector(productsOptionsSelector);
  useEffect(() => {
    if (!isFetching && isEmpty(chemicalsList)) {
      getChemicalsList();
    }
  }, [isFetching, getChemicalsList]);
  return (
    <Form.Dropdown
      fluid
      placeholder="Select product"
      options={chemicalOptions}
      onChange={(_, { value }) =>
        onChange(chemicalOptions.find(item => item.value === value))
      }
      search
      selection
      name={name}
      value={value}
    />
  );
};

SelectProduct.propTypes = {
  actions: PropTypes.object.isRequired,
  chemicalsList: PropTypes.array.isRequired,
  disabled: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  minItemForSearch: PropTypes.bool,
  native: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
  showArchived: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.array.isRequired,
    PropTypes.number.isRequired
  ])
};

SelectProduct.defaultProps = {
  chemicalsList: [],
  disabled: false,
  error: false,
  isFetching: false,
  minItemForSearch: 10,
  native: false,
  onChange: () => {},
  required: false,
  showArchived: false
};

const mapStateToProps = state => {
  return {
    chemicalsList: productsOptionsSelector(state),
    isFetching: state.chemical.data.isFetching
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getChemicalsList
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectProduct);
