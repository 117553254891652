import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";
import Loader from "../../../../components/Loader";
import { abilitiesSelector } from "../../../../selectors/user";
import { getParentPageHref } from "../../../../utils/routeHelpers";
import { history } from "../../../../store";
import LayoutOverlay from "../../../../components/LayoutOverlay";
// eslint-disable-next-line
import styles from "./AddDispatch.module.css";
import { getDispatchById } from "../../../../actions/Dispatches/dispatches";
import DispatchesForm from "./DispatchForm/DispatchForm";

class AddDispatch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      dispatch: null
    };
    this.dispatchForm = React.createRef();
  }
  async componentWillMount() {
    const { match } = this.props;
    const { id } = match.params;
    if (id) {
      this.getDispatch(id);
    } else {
      this.setState({
        isFetching: false
      });
    }
  }

  getDispatch = id => {
    const {
      actions: { getDispatchById }
    } = this.props;
    this.setState({
      isFetching: true
    });

    getDispatchById(id)
      .then(dispatch => {
        this.setState({
          isFetching: false,
          dispatch
        });
      })
      .catch(error => {
        if (error.response.status === 404) {
          history.push("/404");
        }
        this.setState({
          isFetching: false
        });
      });
  };

  async componentDidUpdate(prevProps, prevState) {
    const { match } = this.props;
    const { id } = match.params;

    if (id && id !== prevProps.match.params.id) {
      this.getDispatch(id);
    }
  }

  doSubmitForm = () => {
    if (this.dispatchForm.current) {
      this.dispatchForm.current.submit();
    }
  };

  render() {
    const { dispatch, isFetching } = this.state;
    const { route, match, Can } = this.props;
    const { id } = match.params;
    return (
      <LayoutOverlay
        closeLink={getParentPageHref(route)}
        route={{
          ...route,
          name: id ? route.name.replace("Add", "Edit") : route.name
        }}
        childrenOnly
        footer={
          <div className={styles.buttonsFooter}>
            {dispatch && dispatch.id ? (
              <Can I="update" a="dispatches">
                <Button primary size="large" onClick={this.doSubmitForm}>
                  Save
                </Button>
              </Can>
            ) : (
              <Can I="add" a="dispatches">
                <Button primary size="large" onClick={this.doSubmitForm}>
                  Save
                </Button>
              </Can>
            )}
          </div>
        }
      >
        {isFetching ? (
          <Loader />
        ) : (
          <DispatchesForm
            route={route}
            initialValues={dispatch}
            formRef={this.dispatchForm}
          />
        )}
      </LayoutOverlay>
    );
  }
}

AddDispatch.propTypes = {
  route: PropTypes.object.isRequired,
  Dispatch: PropTypes.object,
  match: PropTypes.object,
  actions: PropTypes.object,
  Can: PropTypes.func
};

AddDispatch.defaultProps = {
  route: { name: "{%name%}" }
};

const mapStateToProps = state => {
  return {
    Can: abilitiesSelector(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ getDispatchById }, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDispatch);
