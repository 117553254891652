import { actionType } from "constants/PendingMovements";
import { axiosProxy } from "utils/axiosProxy";
import { omitBy, isNil } from "lodash";
import uuid from "uuid/v4";

let uniqId;

export const getPendingOperations = (
  { page, search, size, to, from, unpaged = false, filters },
  shedIds
) => async dispatch => {
  await dispatch({
    type: actionType.PENDING_OPERATIONS_START,
    payload: shedIds
  });
  try {
    const paramsObject = omitBy(
      {
        page,
        search,
        size,
        unpaged,
        to,
        sort: "id,desc",
        from,
        status: "PENDING"
      },
      isNil
    );
    const params = new URLSearchParams(paramsObject);

    if (Array.isArray(filters)) {
      filters.forEach(([filter, value]) => {
        const from = value.slice(0, 10);
        const to = value.slice(-10);
        return filter !== "seasons"
          ? params.append(filter, value)
          : (params.append("from", `${from}T00:00:00`),
            params.append("to", `${to}T23:59:59`));
      });
    }
    uniqId = uuid();
    const res = await axiosProxy({
      method: "GET",
      params,
      uniqId,
      url: `/sheds/operations`
    });
    if (uniqId === res.config.headers["X-REQUEST-ID"]) {
      await dispatch({
        type: actionType.PENDING_OPERATIONS,
        payload: res.data
      });
    }
    return res.data;
  } catch (error) {}
};
export const getPendingOperationsList = shedIds => async (
  dispatch,
  getState
) => {
  const { pendingMovements } = getState();
  await dispatch({
    type: actionType.PENDING_OPERATIONS_LIST_START,
    payload: shedIds
  });
  try {
    const res = await axiosProxy({
      method: "GET",
      params: {
        unpaged: true,
        status: "PENDING"
      },
      url: `/sheds/operations`
    });
    await dispatch({
      type: actionType.PENDING_OPERATIONS_LIST,
      payload: res.data
    });
    localStorage.setItem(
      "pendingMovements",
      JSON.stringify({
        ...pendingMovements,
        list: { ...res.data }
      })
    );
    return res.data;
  } catch (error) {}
};
