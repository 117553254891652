import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Dropdown } from "semantic-ui-react";
import { onlineSelector } from "selectors/common";
import { NO_RESULTS_MESSAGE } from "constants/shared/messages";

const SelectChemicalDropdown = ({
  chemicalsList,
  value,
  selectedChemicals,
  validation,
  onChange,
  id,
  online
}) => {
  const selectedIds = selectedChemicals.filter(c => !c.toAdd).map(c => c.id);
  const options = useMemo(
    () =>
      chemicalsList && chemicalsList.content
        ? chemicalsList.content
            .filter(chemical =>
              chemical.archived
                ? id === "new"
                  ? false
                  : chemical.id === value
                : true
            )
            .filter(listItem => !selectedIds.includes(listItem.id))
        : [],
    [chemicalsList, selectedIds, value, id]
  );
  const [searchQuery, setSearchQuery] = useState("");

  return (
    <Dropdown
      error={!validation && !value}
      search
      fluid
      searchQuery={searchQuery}
      onClose={() => setSearchQuery("")}
      onSearchChange={(_, { searchQuery }) => setSearchQuery(searchQuery)}
      noResultsMessage={NO_RESULTS_MESSAGE}
      selectOnBlur={false}
      selectOnNavigation={false}
      closeOnChange
      selection
      placeholder="Select chemical"
      onChange={(_, data) => onChange(id, data.value)}
      value={value || null}
      loading={chemicalsList.isFetching && online}
      options={[
        ...options.map(item => ({
          key: item.id,
          text: item.name,
          value: item.id
        }))
      ]}
    />
  );
};

const mapStateToProps = state => {
  const {
    sprayDiary: {
      chemicalsList,
      selectedChemicals,
      validations: {
        selectedChemicals: selectedChemicalsValidation,
        addChemicalSelection: addChemicalSelectionValidation
      }
    }
  } = state;

  return {
    chemicalsList,
    selectedChemicals,
    validation: selectedChemicalsValidation && addChemicalSelectionValidation,
    online: onlineSelector(state)
  };
};

SelectChemicalDropdown.propTypes = {
  validation: PropTypes.bool,
  online: PropTypes.bool,
  selectedChemicals: PropTypes.func,
  onChange: PropTypes.func,
  id: PropTypes.number,
  value: PropTypes.any,
  chemicalsList: PropTypes.object
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({}, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectChemicalDropdown);
