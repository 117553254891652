import React, { useRef, useCallback } from "react";
import PropTypes from "prop-types";
import MachineryForm from "../../../../Machinery/AddNewMachinery/MachineryForm";
import AddNewModal from "../../../../../components/AddNewModal";

import { Button } from "semantic-ui-react";

const EditForm = React.forwardRef((props, ref) => (
  <MachineryForm {...props} formRef={ref} />
));

const AddMachineryModal = ({ open, onClose, onSubmit }) => {
  const formRef = useRef(null);
  const handleSaveAction = useCallback(() => {
    formRef.current.submit();
  }, [formRef]);

  return (
    <AddNewModal
      open={open}
      title="Add New Machinery"
      formToShow={
        <EditForm ref={formRef} onSubmitForm={onSubmit} showMobileHeader />
      }
      onClose={onClose}
      actions={
        <Button primary size="large" onClick={handleSaveAction}>
          Add machinery
        </Button>
      }
    />
  );
};

AddMachineryModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
};

export default AddMachineryModal;
