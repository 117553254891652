import React, { PureComponent } from "react";
import { Menu } from "semantic-ui-react";
import classNames from "classnames";
import PropTypes from "prop-types";

class HorizontalSelect extends PureComponent {
  state = {
    options: this.props.options,
    selected: this.props.selected,
    onSelect: this.props.onSelect
  };

  static propTypes = {
    options: PropTypes.array,
    selected: PropTypes.any,
    onSelect: PropTypes.func,
    disabled: PropTypes.bool,
    style: PropTypes.shape()
  };

  setSelected = selected => {
    this.setState({
      selected: selected
    });
  };

  render() {
    const { disabled, className } = this.props;
    return (
      <React.Fragment>
        <Menu
          compact
          className={classNames("sprayMenu", className)}
          style={this.props.style}
        >
          {this.state.options.map(option => (
            <Menu.Item
              disabled={disabled}
              key={option.key}
              active={this.state.selected === option.value}
              onClick={() => {
                this.setSelected(option.value);
                if (this.state.onSelect) {
                  this.state.onSelect(option);
                }
              }}
            >
              {option.text}
            </Menu.Item>
          ))}
        </Menu>
      </React.Fragment>
    );
  }
}

export default HorizontalSelect;
