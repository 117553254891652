import PropTypes from "prop-types";
import React, { useEffect, useMemo } from "react";
import { Grid } from "semantic-ui-react";
import { Form } from "formsy-semantic-ui-react";
import SelectWithSelectAll from "../../../../../components/SelectWithSelectAll";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getVarietiesList } from "../../../../../actions/Varieties";
import { useSelector } from "react-redux";

const CropRelatedFields = ({
  areas,
  varietiesList,
  actions: { getVarietiesList },
  crop,
  variety,
  onChange,
  mandatoryFields,
  allowedFields
}) => {
  const data = useSelector(state => state.areas.list.content);
  useEffect(() => {
    if (!data) getVarietiesList();
  }, [getVarietiesList, data]);

  const crops = useMemo(() => {
    const crops = {};
    const varieties = [
      ...new Set(data.map(area => area.varieties).flat())
    ].filter(a => !!a);
    const list = varieties.length ? varieties : varietiesList;

    list.forEach(variety => {
      const tempVariety = variety.variety ? variety.variety : variety;
      const crop = tempVariety.crop;

      if (crops[crop.id]) {
        if (
          crops[crop.id].varieties.filter(v => v.id === tempVariety.id)
            .length === 0
        ) {
          crops[crop.id].varieties = [...crops[crop.id].varieties, tempVariety];
        }
      } else {
        crops[crop.id] = {
          ...crop,
          varieties: [tempVariety]
        };
      }
    });

    return crops;
  }, [varietiesList, data]);

  const cropsOptions = useMemo(
    () =>
      Object.values(crops).map(content => ({
        key: content.id,
        text: `${content.name}`,
        value: content.id
      })),
    [crops]
  );

  crop = crop && (crop.varieties ? crop : crops[crop.id]);

  const varietyOptions = useMemo(
    () =>
      crop
        ? crop.varieties.map(content => ({
            key: content.id,
            text: `${content.name}`,
            value: content.id
          }))
        : [],
    [crop]
  );

  const cropID = crop ? crop.id : null;
  const varietyID = variety ? variety.id : null;

  return (
    <>
      <Grid.Column largeScreen={4} mobile={16}>
        <Form.Field
          required={mandatoryFields.includes("VARIETY")}
          disabled={!allowedFields.includes("VARIETY")}
        >
          <label>Crop</label>
          <SelectWithSelectAll
            placeholder="Select Crop"
            showSelectAll={false}
            name={"crop"}
            noResultsMessage="Nothing was found"
            disabled={!allowedFields.includes("VARIETY")}
            fluid
            closeOnBlur
            selection
            native
            clearable
            value={cropID}
            onChange={(_, data) => {
              onChange({
                target: { name: "crop", value: crops[data.value] }
              });
              onChange({ target: { name: "variety", value: null } });
            }}
            options={cropsOptions}
          />
        </Form.Field>
      </Grid.Column>
      <Grid.Column largeScreen={4} mobile={16}>
        <Form.Field
          required={mandatoryFields.includes("VARIETY")}
          disabled={!allowedFields.includes("VARIETY")}
        >
          <label>Variety</label>
          <SelectWithSelectAll
            placeholder="Select Variety"
            showSelectAll={false}
            name={"farm"}
            noResultsMessage="Nothing was found"
            fluid
            closeOnBlur
            clearable
            native
            selection
            disabled={
              !crop ||
              !varietyOptions.length ||
              !allowedFields.includes("VARIETY")
            }
            value={varietyID}
            onChange={(_, data) => {
              onChange({
                target: {
                  name: "variety",
                  value: crop.varieties.find(v => v.id === data.value)
                }
              });
            }}
            options={varietyOptions}
          />
        </Form.Field>
      </Grid.Column>
    </>
  );
};

const mapStateToProps = state => ({
  varietiesList: state.varieties.data
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getVarietiesList
    },
    dispatch
  )
});

CropRelatedFields.propTypes = {
  actions: PropTypes.objectOf(),
  allowedFields: PropTypes.array,
  data: PropTypes.array.isRequired,
  crop: PropTypes.object,
  mandatoryFields: PropTypes.array,
  onChange: PropTypes.func,
  varietiesList: PropTypes.array,
  variety: PropTypes.object
};

CropRelatedFields.defaultProps = {
  data: []
};

export default connect(mapStateToProps, mapDispatchToProps)(CropRelatedFields);
