import React, { useState, useEffect, useMemo } from "react";
import { getRateWithUnits } from "utils/constToUnits";
import { Popup, Icon, Segment, Grid, Button } from "semantic-ui-react";
import { get } from "lodash";
import { isEmptyValue } from "utils/tasksUtils";
import CropRateForm from "./CropRateForm";
import { useFormikContext } from "formik";
import styles from "./CropRatesSection.module.css";

const CropRateRow = ({
  content,
  chemicalRate,
  index,
  cropListCheck,
  deleteButtonCheck,
  setCheckEditForm
}) => {
  const { values, setFieldValue } = useFormikContext();
  const [isEditing, setIsEditing] = useState(false);
  const { maxRate, minRate, crop, rateType, unit, ...rate } = chemicalRate;

  const multiplier = useMemo(() => {
    const foundElement = content.find(({ id }) => id === unit);

    if (foundElement) {
      return 1 / foundElement.multiplicationFactor;
    }

    return 1;
  }, [content, unit]);

  const rateValue = maxRate
    ? `${minRate * multiplier} - ${getRateWithUnits(
        maxRate,
        rateType,
        unit,
        multiplier
      )}`
    : getRateWithUnits(minRate, rateType, unit, multiplier);

  const onDeleteClick = () => {
    setFieldValue(
      "rates",
      values.rates.filter(i => i !== chemicalRate)
    );
  };
  useEffect(() => {});
  if (isEditing) {
    setCheckEditForm(true);
    return (
      <Grid.Row className={styles.editFormContainer} as={Segment}>
        <Grid.Column>
          <CropRateForm
            cropListCheck={cropListCheck}
            cropIndex={index}
            deleteButtonCheck={deleteButtonCheck}
            setCheckEditForm={setCheckEditForm}
            chemicalRate={chemicalRate}
            onSubmit={newValue => {
              setFieldValue(
                "rates",
                values.rates.map(i => (i === chemicalRate ? newValue : i))
              );
              setIsEditing(false);
              setCheckEditForm(false);
            }}
            onCancel={() => {
              return setCheckEditForm(false), setIsEditing(false);
            }}
            unit={unit}
          />
        </Grid.Column>
      </Grid.Row>
    );
  }
  return (
    <Grid.Row className={styles.rowContainer} as={Segment}>
      <Grid.Column className={styles.rowCrop}>
        {get(crop, "name", "Generic")}
      </Grid.Column>

      <Popup
        trigger={
          <Grid.Column className={styles.rowPurpose}>
            {isEmptyValue(rate.purpose)}
          </Grid.Column>
        }
        content={
          <div className={styles.meesageContainer}>
            {" "}
            {isEmptyValue(rate.purpose)}
          </div>
        }
        inverted
        position="bottom center"
        disabled={!rate.purpose}
      />
      <Popup
        trigger={
          <Grid.Column className={styles.rowRate}>{rateValue}</Grid.Column>
        }
        content={<div className={styles.meesageContainer}> {rateValue}</div>}
        inverted
        position="bottom center"
        disabled={!rateValue}
      />
      <Popup
        trigger={
          <Grid.Column className={styles.rowReentr}>
            {isEmptyValue(rate.reentry)} days
          </Grid.Column>
        }
        content={
          <div className={styles.meesageContainer}>
            {" "}
            {isEmptyValue(rate.reentry)} days
          </div>
        }
        inverted
        position="bottom center"
        disabled={!rate.reentry}
      />
      <Popup
        trigger={
          <Grid.Column className={styles.rowWHP}>
            {isEmptyValue(rate.withholdingPeriod)} days
          </Grid.Column>
        }
        position="bottom center"
        content={
          <div className={styles.meesageContainer}>
            {isEmptyValue(rate.withholdingPeriod)} days
          </div>
        }
        inverted
        disabled={!rate.withholdingPeriod}
      />

      <Popup
        trigger={
          <Grid.Column className={styles.rowComments}>
            {isEmptyValue(rate.comment)}
          </Grid.Column>
        }
        position="top left "
        content={<div className={styles.meesageContainer}>{rate.comment}</div>}
        inverted
        disabled={!rate.comment}
      />
      <Grid.Column className={styles.rowButtonCell}>
        <Popup
          inverted
          content={"Edit"}
          size="tiny"
          position="bottom center"
          trigger={
            <div className={styles.editButtonWrapper}>
              <Button
                onClick={() => setIsEditing(true)}
                className={styles.editButton}
                floated="right"
                icon={<Icon name="edit" />}
                size="small"
              />
            </div>
          }
        />
        <Popup
          inverted
          position="bottom center"
          content={"Delete"}
          size="tiny"
          trigger={
            <Button
              className={styles.closeButton}
              onClick={onDeleteClick}
              floated="right"
              icon={<Icon name="trash alternate" />}
              size="small"
            />
          }
        />
      </Grid.Column>
    </Grid.Row>
  );
};

export default CropRateRow;
