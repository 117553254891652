import React, { Component } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Button,
  Checkbox,
  Dropdown,
  Form,
  FormField,
  Input,
  Label,
  TextArea
} from "semantic-ui-react";
import { getVarieties } from "../../../../../actions/Farms/crops";
import styles from "./PatchFormBody.module.css";
import DatePicker from "../../../../../components/DatePicker";
import { AreaVariety } from "../../../../../models/area_variety.model";
import isIterable from "../../../../../utils/isIterable";
import Moment from "moment";
import { extendMoment } from "moment-range";
import objectPath from "object-path";

const moment = extendMoment(Moment);

/*TODO crops/variety names should be taken from redux
 * */
class PatchFormBody extends Component {
  state = {
    cropsOptions: [],
    varietyOptions: [],
    showCalendar: false,
    searchQuery: {
      crop: "",
      variety: ""
    }
  };

  async componentDidMount() {
    const { currentBlock } = this.props;
    if (
      !currentBlock.attributes.varieties ||
      currentBlock.attributes.varieties.length === 0
    ) {
      this.changeValue(`attributes.varieties`, [new AreaVariety({})]);
    }
    this.prepareCrops();
    if (isIterable(currentBlock.attributes.varieties)) {
      for (let i = 0; i < currentBlock.attributes.varieties.length; i++) {
        const variety = currentBlock.attributes.varieties[i];
        this.handleDateChange("plantingAt", variety.plantingAt, i);
        if (variety.crop) {
          this.prepareVariety(variety.crop.id);
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { crops } = this.props;

    if (crops !== prevProps.crops) {
      this.prepareCrops();
    }
    this.restoreCrop();
    this.validatePlantingRange();
  }

  restoreCrop() {
    const { crops, currentBlock } = this.props;
    if (currentBlock.attributes.varieties) {
      currentBlock.attributes.varieties.forEach((variety, index) => {
        if (variety.variety && !variety.crop) {
          const crop = crops.data.find(crop => {
            let matchingVariety;
            if (crop.varieties) {
              matchingVariety = crop.varieties.find(
                _variety => _variety.id === variety.variety.id
              );
            }
            return matchingVariety;
          });
          if (crop) {
            this.changeValue(`attributes.varieties.${index}.crop`, crop);
          }
        }
      });
    }
  }

  prepareCrops = () => {
    const { crops } = this.props;
    this.setState({
      cropsOptions: crops.data.map(crop => ({
        text: crop.name,
        value: crop.id,
        key: crop.id
      }))
    });
  };

  prepareVariety = async cropId => {
    const { crops, actions } = this.props;
    const crop = (_.get(crops, "data") || []).find(crop => crop.id === cropId);
    const gotVarieties = crop ? crop.varieties : null;
    if (gotVarieties) {
      this.setState({
        varietyOptions: gotVarieties.map(variety => ({
          text: variety.name,
          value: variety.id,
          key: variety.id
        }))
      });
    } else {
      await actions.getVarieties(cropId);
      this.setState({
        varietyOptions: this.props.crops.data
          .find(crop => crop.id === cropId)
          .varieties.map(variety => ({
            text: variety.name,
            value: variety.id,
            key: variety.id
          }))
      });
    }
  };

  clearSearchQuery = fieldName => {
    const { searchQuery } = this.state;
    searchQuery[fieldName] = "";

    this.setState({
      searchQuery
    });
  };

  calculateNumberOfPlants(fieldName, fieldValue) {
    const { changeValue, currentBlock } = this.props;

    const field = fieldName
      .split(".")
      .slice(-1)
      .pop();
    const varietyIndex = fieldName
      .split(".")
      .slice(-2, -1)
      .pop();

    if (["rowSpacing", "plantsSpacing"].indexOf(field) > -1) {
      if (currentBlock.attributes.varieties[varietyIndex]) {
        const rowSpacing =
          field === "rowSpacing"
            ? fieldValue
            : currentBlock.attributes.varieties[varietyIndex].rowSpacing;
        const plantsSpacing =
          field === "plantsSpacing"
            ? fieldValue
            : currentBlock.attributes.varieties[varietyIndex].plantsSpacing;
        const spacingType =
          currentBlock.attributes.varieties[varietyIndex].crop.spacingType;
        const multiplier = spacingType === "DOUBLE_SPACING" ? 2 : 1;

        const numberOfPlants =
          parseFloat(currentBlock.size) * (plantsSpacing > 0 && rowSpacing > 0)
            ? Math.round(
                parseFloat(currentBlock.size) *
                  (10000 / rowSpacing / plantsSpacing) *
                  multiplier
              )
            : 0;
        changeValue(
          currentBlock,
          `attributes.varieties.${varietyIndex}.numberOfPlants`,
          numberOfPlants
        );
      }
    }
  }

  changeValue = async (fieldName, fieldValue) => {
    const { changeValue, currentBlock } = this.props;

    if (objectPath.get(currentBlock, fieldName) !== fieldValue) {
      await changeValue(currentBlock, fieldName, fieldValue);
    }

    if (fieldName.indexOf(".crop") > -1) {
      this.prepareVariety(fieldValue.id);
    }

    this.calculateNumberOfPlants(fieldName, fieldValue);

    if (fieldName === "attributes.isRotating") {
      let varieties;
      if (fieldValue === true) {
        varieties = currentBlock.attributes.varieties;
      } else {
        varieties = currentBlock.attributes.varieties.slice(0, 1);
      }
      changeValue(currentBlock, `attributes.varieties`, varieties);
      currentBlock.attributes.varieties.forEach((variety, index) => {
        changeValue(
          currentBlock,
          `attributes.varieties.${index}.isRotating`,
          fieldValue
        );
        changeValue(
          currentBlock,
          `attributes.varieties.${index}.pickingAt`,
          null
        );
      });
    }
  };

  handleDateChange = (field, date, index) => {
    this.setState({ showCalendar: false });
    this.changeValue(`attributes.varieties.${index}.${field}`, date);
    this.changeValue(
      `attributes.varieties.${index}.age`,
      date ? moment().diff(moment(date, "YYYY-MM-DD"), "years") : ""
    );
  };

  plantingRange(index) {
    const { currentBlock } = this.props;
    const variety = currentBlock.attributes.varieties[index];
    const plantingAt = variety.plantingAt
      ? moment(variety.plantingAt, "YYYY-MM-DD")
      : null;
    const pickingAt = variety.pickingAt
      ? moment(variety.pickingAt, "YYYY-MM-DD")
      : null;
    return moment.range(plantingAt, pickingAt);
  }

  validatePlantingRange() {
    const { currentBlock } = this.props;

    for (let i = 0; i < currentBlock.attributes.varieties.length; i++) {
      const currentPlantingRange = this.plantingRange(i);

      let overlapping = false;
      for (let j = 0; j < currentBlock.attributes.varieties.length; j++) {
        if (
          i === j ||
          (!currentBlock.attributes.varieties[j].plantingAt &&
            !currentBlock.attributes.varieties[j].pickingAt)
        ) {
          continue;
        }
        const plantingRange = this.plantingRange(j);
        if (
          currentPlantingRange.center().unix() !== 0 &&
          plantingRange.overlaps(currentPlantingRange)
        ) {
          overlapping = true;
          break;
        }
      }
      this.changeValue(`attributes.varieties.${i}.overlapping`, overlapping);
    }
  }

  toggleShowCalendar = event => {
    const { showCalendar } = this.state;
    event.target.blur();
    this.setState({ showCalendar: !showCalendar });
  };

  handleSearchChange = (fieldName, fieldValue) => {
    const { searchQuery } = this.state;
    searchQuery[fieldName] = fieldValue;
    this.setState({ searchQuery });
  };

  varietyFormField(variety, index, options) {
    return (
      <Form.Field width={options.width}>
        <label>
          <div>{options.title}</div>
          <Input
            className={styles.spinner}
            size="large"
            value={
              (options.renderValue && options.renderValue(variety)) ||
              variety[options.fieldName] ||
              ""
            }
            placeholder={options.type === "number" ? "0" : ""}
            type={options.type}
            onChange={event =>
              this.changeValue(
                `attributes.varieties.${index}.${options.fieldName}`,
                event.target.value
              )
            }
            label={
              options.units ? { content: "m", inverted: "true" } : undefined
            }
            labelPosition={options.units ? "right" : undefined}
          />
        </label>
      </Form.Field>
    );
  }

  renderPlantsPerHectareValue = variety => {
    const plantsPerHectare = (
      variety.numberOfPlants / this.props.currentBlock.size
    ).toFixed();
    return plantsPerHectare > 0 ? plantsPerHectare : "";
  };

  renderNoSpacing(variety) {
    const { currentBlock } = this.props;
    const index = currentBlock.attributes.varieties.indexOf(variety);

    return (
      <>
        <Form.Group widths="equal">
          {this.varietyFormField(variety, index, {
            fieldName: "numberOfPlants",
            type: "number",
            title: "Estimated number of plants"
          })}
          {this.varietyFormField(variety, index, {
            fieldName: "plantsPerHectare",
            type: "number",
            title: "Estimated plants per hectare",
            renderValue: this.renderPlantsPerHectareValue
          })}
        </Form.Group>
      </>
    );
  }

  renderRowSpacing(variety) {
    const { currentBlock } = this.props;
    const index = currentBlock.attributes.varieties.indexOf(variety);
    return (
      <>
        <Form.Group>
          {this.varietyFormField(variety, index, {
            fieldName: "rowsCount",
            type: "number",
            title: "No. of rows",
            width: 4
          })}
          {this.varietyFormField(variety, index, {
            fieldName: "rowSpacing",
            type: "number",
            title: "Row spacing",
            width: 6,
            units: true
          })}
          {this.varietyFormField(variety, index, {
            fieldName: "plantsSpacing",
            type: "number",
            title: "Plants spacing",
            width: 6,
            units: true
          })}
        </Form.Group>
        <Form.Group>
          {this.varietyFormField(variety, index, {
            fieldName: "rowsPrefix",
            type: "string",
            title: "Rows prefix",
            width: 6
          })}
          {this.varietyFormField(variety, index, {
            fieldName: "firstRowNumber",
            type: "number",
            title: "First row number",
            width: 6
          })}
        </Form.Group>
        <Form.Group widths="equal">
          {this.varietyFormField(variety, index, {
            fieldName: "numberOfPlants",
            type: "number",
            title: "No. of plants"
          })}
          {this.varietyFormField(variety, index, {
            fieldName: "plantsPerHectare",
            type: "number",
            title: "Plants per hectare",
            renderValue: this.renderPlantsPerHectareValue
          })}
        </Form.Group>
      </>
    );
  }

  renderDoubleSpacing(variety) {
    const { currentBlock } = this.props;
    const index = currentBlock.attributes.varieties.indexOf(variety);
    return (
      <>
        <Form.Group>
          {this.varietyFormField(variety, index, {
            fieldName: "rowsCount",
            type: "number",
            title: "No. of rows",
            width: 8
          })}
          {this.varietyFormField(variety, index, {
            fieldName: "rowSpacing",
            type: "number",
            title: "Row spacing",
            width: 8,
            units: true
          })}
        </Form.Group>
        <Form.Group>
          {this.varietyFormField(variety, index, {
            fieldName: "plantsSpacing",
            type: "number",
            title: "Plants spacing down the row",
            width: 8,
            units: true
          })}
          {this.varietyFormField(variety, index, {
            fieldName: "plantsSpacingAcrossTheBed",
            type: "number",
            title: "Plants spacing across the bed",
            width: 8,
            units: true
          })}
        </Form.Group>

        <Form.Group widths="equal">
          {this.varietyFormField(variety, index, {
            fieldName: "numberOfPlants",
            type: "number",
            title: "No. of Plants"
          })}
          {this.varietyFormField(variety, index, {
            fieldName: "plantsPerHectare",
            type: "number",
            title: "Plants per hectare",
            renderValue: this.renderPlantsPerHectareValue
          })}
        </Form.Group>
      </>
    );
  }

  renderSpacingForm(variety) {
    const spacingType =
      variety.crop && variety.crop.spacingType
        ? variety.crop.spacingType
        : "NO_SPACING";

    return (
      <>
        {(() => {
          switch (spacingType) {
            case "NO_SPACING":
              return this.renderNoSpacing(variety);
            case "ROW_SPACING":
              return this.renderRowSpacing(variety);
            case "DOUBLE_SPACING":
              return this.renderDoubleSpacing(variety);
            default:
              return null;
          }
        })()}
      </>
    );
  }

  renderVariety(variety) {
    const { type, crops, errors, currentBlock } = this.props;
    const { searchQuery } = this.state;
    const index = currentBlock.attributes.varieties.indexOf(variety);
    const crop = this.props.crops.data.find(
      crop => crop.id === variety.crop.id
    );

    return (
      <>
        <Form.Group widths="equal">
          <Form.Field
            required
            error={_.has(errors, "attributes.variety")}
            width={10}
          >
            <label>Variety</label>
            <Dropdown
              size="large"
              placeholder={`Select ${type.toLowerCase()} variety`}
              value={variety.variety ? variety.variety.id : null}
              options={crop.varieties.map(variety => ({
                text: variety.name,
                value: variety.id,
                key: variety.id
              }))}
              disabled={crops.isFetching}
              onClose={() => this.clearSearchQuery("variety")}
              onChange={(event, element) => {
                return this.changeValue(
                  `attributes.varieties.${index}.variety`,
                  {
                    id: element.value,
                    name: element.options.find(
                      option => option.value === element.value
                    ).text
                  }
                );
              }}
              onSearchChange={(event, { searchQuery }) =>
                this.handleSearchChange(`variety${index}`, searchQuery)
              }
              search
              searchQuery={searchQuery.variety[index]}
              selection
            />
          </Form.Field>
          <Form.Field />
        </Form.Group>
        {variety.variety && (
          <>
            <Form.Group>
              <Form.Field
                required={currentBlock.attributes.isRotating}
                width={10}
                className={styles.calendarHolder}
              >
                <label>Date of Planting</label>
                <DatePicker
                  value={variety.plantingAt}
                  onChange={date =>
                    this.handleDateChange("plantingAt", date, index)
                  }
                />
              </Form.Field>
              {currentBlock.attributes.isRotating && (
                <Form.Field width={10} className={styles.calendarHolder}>
                  <label>Date of Picking</label>
                  <DatePicker
                    className={styles.rightAlignPicker}
                    value={variety.pickingAt}
                    onChange={date =>
                      this.handleDateChange("pickingAt", date, index)
                    }
                  />
                  {moment(variety.plantingAt).isAfter(
                    moment(variety.pickingAt || null)
                  ) && (
                    <Label basic color="red">
                      The picking date must be greater than the planting date
                    </Label>
                  )}
                </Form.Field>
              )}
              {!currentBlock.attributes.isRotating && (
                <Form.Field width={6}>
                  <label>
                    <div>Age</div>
                    <Input
                      size="large"
                      value={variety.age}
                      placeholder="0"
                      disabled
                    />
                  </label>
                </Form.Field>
              )}
            </Form.Group>
            {variety.overlapping && (
              <Label basic color="red">
                These dates are overlapping with another crop
              </Label>
            )}
            <Form.Group>
              <Form.Field width={10}>
                <label>
                  <div>Rootstock</div>
                  <Input
                    size="large"
                    value={variety.rootstock}
                    placeholder=""
                    onChange={event =>
                      this.changeValue(
                        `attributes.varieties.${index}.rootstock`,
                        event.target.value
                      )
                    }
                  />
                </label>
              </Form.Field>
            </Form.Group>
            {this.renderSpacingForm(variety)}
          </>
        )}
      </>
    );
  }

  renderCropSelection(variety) {
    const { type, crops, errors, currentBlock } = this.props;
    const { cropsOptions, searchQuery } = this.state;

    const index = currentBlock.attributes.varieties.indexOf(variety);

    return (
      <>
        <Form.Field
          required={
            !currentBlock.attributes.isRotating ||
            (currentBlock.attributes.varieties &&
              currentBlock.attributes.varieties.length > 1)
          }
          error={_.has(errors, "attributes.crop")}
          width={10}
        >
          <label>Crop</label>
          <Dropdown
            size="large"
            placeholder={`Select ${type.toLowerCase()} crop`}
            value={variety.crop ? variety.crop.id : null}
            options={cropsOptions}
            disabled={crops.isFetching}
            onClose={() => this.clearSearchQuery("crop")}
            onChange={(event, element) => {
              this.changeValue(
                `attributes.varieties.${index}.crop`,
                crops.data.find(crop => crop.id === element.value)
              );
              this.changeValue(
                `attributes.varieties.${index}.variety`,
                undefined
              );
            }}
            onSearchChange={(event, { searchQuery }) =>
              this.handleSearchChange(`crop${index}`, searchQuery)
            }
            search
            searchQuery={searchQuery.crop[index]}
            selection
          />
        </Form.Field>
      </>
    );
  }

  addAreaVariety = () => {
    const { currentBlock } = this.props;
    this.changeValue(
      `attributes.varieties.${currentBlock.attributes.varieties.length}`,
      new AreaVariety({ isRotating: currentBlock.attributes.isRotating })
    );
  };

  renderAreaVarieties() {
    const { currentBlock } = this.props;
    let varieties = [];
    if (isIterable(currentBlock.attributes.varieties)) {
      for (let variety of currentBlock.attributes.varieties) {
        const crop = this.props.crops.data.find(
          crop => crop.id === (variety.crop ? variety.crop.id : null)
        );

        varieties.push(
          <div
            key={`variety_age_${currentBlock.attributes.varieties.indexOf(
              variety
            )}`}
          >
            <Form.Group widths="equal">
              {this.renderCropSelection(variety)}
              {currentBlock.attributes.varieties.length > 1 ||
              currentBlock.attributes.isRotating ? (
                <Form.Field className={styles.closeicon}>
                  <Button
                    icon={{ name: "x" }}
                    onClick={() => {
                      const index = currentBlock.attributes.varieties.indexOf(
                        variety
                      );
                      const newVarieties = _.clone(
                        currentBlock.attributes.varieties
                      );
                      newVarieties.splice(index, 1);

                      this.changeValue(
                        "attributes.varieties",
                        newVarieties
                      ).then(() => {
                        if (newVarieties.length === 0) {
                          this.addAreaVariety();
                        }
                      });
                    }}
                  />
                </Form.Field>
              ) : (
                <FormField />
              )}
            </Form.Group>
            {variety.crop &&
              crop &&
              crop.varieties &&
              this.renderVariety(variety)}
          </div>
        );
      }
    }

    return (
      <>
        {varieties}
        {currentBlock.attributes.isRotating && (
          <Form.Field className={styles.addVarietyButtonContainer}>
            <Button
              icon={{ name: "plus circle", size: "large", color: "green" }}
              onClick={this.addAreaVariety}
            />
          </Form.Field>
        )}
      </>
    );
  }

  render() {
    const { currentBlock } = this.props;

    return (
      <>
        <Form.Group>
          <Form.Field width={10}>
            <label>
              <div>Registration Number</div>
              <Input
                size="large"
                value={currentBlock.attributes.registrationNumber}
                onChange={event =>
                  this.changeValue(
                    "attributes.registrationNumber",
                    event.target.value
                  )
                }
              />
            </label>
          </Form.Field>
          <Form.Field>
            <label>Rotating</label>
            <Checkbox
              toggle
              disabled={
                currentBlock.attributes.varieties &&
                currentBlock.attributes.varieties.length > 1
              }
              icons={{
                checked: null,
                unchecked: null
              }}
              onChange={(event, checkboxProps) => {
                this.changeValue(
                  "attributes.isRotating",
                  checkboxProps.checked
                );
              }}
              checked={currentBlock.attributes.isRotating || false}
            />
          </Form.Field>
        </Form.Group>
        {this.renderAreaVarieties()}
        <Form.Group>
          <Form.Field width={16}>
            <label>
              <div>Comments & Notes</div>
              <TextArea
                size="large"
                className={styles.textarea}
                value={currentBlock.attributes.notes}
                placeholder=""
                onChange={event =>
                  this.changeValue(`attributes.notes`, event.target.value)
                }
              />
            </label>
          </Form.Field>
        </Form.Group>
      </>
    );
  }
}

PatchFormBody.propTypes = {
  currentBlock: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  changeValue: PropTypes.func.isRequired,
  crops: PropTypes.object,
  actions: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool
};

PatchFormBody.defaultProps = {
  patch: { name: "", size: "" },
  type: "Block",
  changeValue: () => {}
};

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ getVarieties }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PatchFormBody);
