import React, { Component } from "react";
import { CallbackComponent } from "redux-oidc";
import PropTypes from "prop-types";
import { history } from "../../store";

class AuthCallBack extends Component {
  checkBackUrl = () => {
    const backUrl = localStorage.getItem("backUrl");
    localStorage.removeItem("backUrl");
    history.replace(backUrl || "/");
  };

  onError = () => {
    history.replace("/");
  };

  render() {
    const { userManager } = this.props;
    return (
      <CallbackComponent
        userManager={userManager}
        successCallback={this.checkBackUrl}
        errorCallback={this.onError}
      >
        <div />
      </CallbackComponent>
    );
  }
}

AuthCallBack.propTypes = {
  route: PropTypes.object.isRequired,
  userManager: PropTypes.any
};

AuthCallBack.defaultProps = {
  route: { name: "{%name%}" }
};

export default AuthCallBack;
