import React from "react";
import PropTypes from "prop-types";
import { Grid, Table } from "semantic-ui-react";
import moment from "moment";
import { sumBy } from "lodash";
import styles from "./PrintReport.module.css";
import Logo from "../../../Logo";
import Numeric from "../../../Numeric";
import { minutesToHM } from "../../../../utils/timeUtils";
import { cropText } from "../../../../utils/string";

const PrintReport = ({
  data,
  locationName,
  startDate,
  endDate,
  combinedTasks
}) => {
  const totalMachineryCost = sumBy(combinedTasks, "totalMachineryCost");
  const totalLabourCost = sumBy(combinedTasks, "totalLabourCost");
  const totalWorkingTime = sumBy(combinedTasks, "totalWorkingTimeInMinutes");
  const costPerAreaPerTask = sumBy(combinedTasks, "costPerAreaPerTask");

  return (
    <div className={styles.reportWrapper}>
      <style>{`@page { size: portrait !important;} html,body {margin: 0px;}`}</style>
      <div className={styles.reportHeaderRow}>
        <div>
          <div className={styles.reportHeader}>Location - {locationName}</div>
          {startDate && endDate && (
            <div className={styles.reportSubHeader}>
              {`Date: ${moment(startDate).format("DD/MM/YY")} - ${moment(
                endDate
              ).format("DD/MM/YY")}`}
            </div>
          )}
        </div>
        <Logo width={140} height={60} />
      </div>
      <Grid.Row>
        <div className={styles.tableHeader}>General Tasks Cost Breakdown</div>
        <Table basic="very" striped className={styles.tableElement}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Task ID</Table.HeaderCell>
              <Table.HeaderCell>Task Name</Table.HeaderCell>
              <Table.HeaderCell>Task Type</Table.HeaderCell>
              <Table.HeaderCell>SubTask</Table.HeaderCell>
              <Table.HeaderCell>Working Time</Table.HeaderCell>
              <Table.HeaderCell>Machinery Cost</Table.HeaderCell>
              <Table.HeaderCell>Labour Cost</Table.HeaderCell>
              <Table.HeaderCell>Cost ($)/Ha/Task</Table.HeaderCell>
              <Table.HeaderCell>Total Costs ($)</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.map((task, i) => (
              <Table.Row key={`task_row_${i}`}>
                <Table.Cell>{task.taskId}</Table.Cell>
                <Table.Cell>{task.generalTaskName}</Table.Cell>
                <Table.Cell>{task.generalTaskType}</Table.Cell>
                <Table.Cell>
                  {cropText(task.generalTaskSubtaskName, 50) || "-"}
                </Table.Cell>
                <Table.Cell>
                  {minutesToHM(task.totalWorkingTimesMins)}
                </Table.Cell>
                <Table.Cell>
                  <Numeric
                    value={task.machineryCost ? task.machineryCost : 0}
                    fractionDigits={2}
                    commaSeparatorOnThousands
                    units="$ "
                    unitsPosition="left"
                  />
                </Table.Cell>
                <Table.Cell>
                  <Numeric
                    value={task.labourCost ? task.labourCost : 0}
                    fractionDigits={2}
                    commaSeparatorOnThousands
                    units="$ "
                    unitsPosition="left"
                  />
                </Table.Cell>
                <Table.Cell>
                  <Numeric
                    value={task.calculatedCostPerHectare}
                    fractionDigits={2}
                    commaSeparatorOnThousands
                    units="$ "
                    unitsPosition="left"
                  />
                  /Ha
                </Table.Cell>
                <Table.Cell>
                  <Numeric
                    value={task.totalCosts}
                    fractionDigits={2}
                    commaSeparatorOnThousands
                    units="$ "
                    unitsPosition="left"
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan={4}>Total</Table.HeaderCell>
              <Table.HeaderCell>
                {minutesToHM(totalWorkingTime)}
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Numeric
                  value={totalMachineryCost}
                  fractionDigits={2}
                  commaSeparatorOnThousands
                  units="$ "
                  unitsPosition="left"
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Numeric
                  value={totalLabourCost}
                  fractionDigits={2}
                  commaSeparatorOnThousands
                  units="$ "
                  unitsPosition="left"
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Numeric
                  value={costPerAreaPerTask}
                  fractionDigits={2}
                  commaSeparatorOnThousands
                  units="$ "
                  unitsPosition="left"
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Numeric
                  value={totalMachineryCost + totalLabourCost}
                  fractionDigits={2}
                  commaSeparatorOnThousands
                  units="$ "
                  unitsPosition="left"
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </Grid.Row>
      <div className={styles.reportCreatedRow}>
        Report created {moment().format("DD/MM/YYYY")} using Tie Up Farming
      </div>
    </div>
  );
};

PrintReport.propTypes = {
  locationName: PropTypes.string,
  data: PropTypes.object.isRequired,
  endDate: PropTypes.any,
  startDate: PropTypes.any,
  size: PropTypes.number,
  combinedTasks: PropTypes.array
};

export default PrintReport;
