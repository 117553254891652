import animateScrollTo from 'animated-scroll-to';

export const scrollIntoError = (elementToScroll = '#sprayGrid') => {
  const elements = document.getElementsByClassName('error');
  const requiredElement = elements ? elements[0] : null;
  if (requiredElement) {
    animateScrollTo(requiredElement, {
      elementToScroll: document.querySelector(elementToScroll) || window,
      verticalOffset: -40,
    });
    const inputs = requiredElement.getElementsByTagName('input');
    if (inputs && inputs[0]) {
      inputs[0].focus();
    }
  }
};
