import moment from "moment";

export const toStartDateString = date =>
  moment(date).format("YYYY-MM-DD") + "T00:00:00";
export const toEndDateString = date =>
  moment(date).format("YYYY-MM-DD") + "T23:59:59";

export const enumerateDaysBetweenDates = (dateFrom, dateTo) => {
  if (!dateFrom || !dateTo) return [];

  dateTo = new Date(dateTo);

  let dates = [];
  //to avoid modifying the original date
  const theDate = new Date(dateFrom);
  while (theDate <= dateTo) {
    dates = [...dates, new Date(theDate)];
    theDate.setDate(theDate.getDate() + 1);
  }
  return dates;
};

export const toUIDateTimeFormat = date =>
  moment(date).format("DD/MM/YYYY HH:mm");

export const formatDate = date =>
  date ? moment(date).format("DD/MM/YYYY") : "-";

export const convertDatesToCertainRange = (from, to, rangeSize, rangeType) => {
  const currentDate = moment().format("YYYY-MM-DD");
  const range = {
    dateFrom: from,
    dateTo: to
  };

  if (!from && !to) {
    range.dateFrom = currentDate;
    range.dateTo = currentDate;
  } else if (!from && to) {
    range.dateFrom = moment(to)
      .subtract(rangeSize, rangeType)
      .format("YYYY-MM-DD");
    range.dateTo = to;
  } else if (from && !to) {
    range.dateFrom = from;
    range.dateTo = moment(from)
      .add(rangeSize, rangeType)
      .format("YYYY-MM-DD");
  } else {
    const monthsDiff = moment(to).diff(moment(from), rangeType, true);
    range.dateFrom =
      monthsDiff > rangeSize
        ? moment(to)
            .subtract(rangeSize, rangeType)
            .format("YYYY-MM-DD")
        : from;
    range.dateTo = to;
  }

  return range;
};
