import React from "react";
import PropTypes from "prop-types";

import { Icon, Label, Table } from "semantic-ui-react";
import styles from "./TableFarm.module.css";
import { Area } from "../../models/block.model";
import moment from "moment";
import ToolTip from "../ToolTip";

const AreaRow = ({
  farm,
  area,
  setActivePatch,
  handleArchiveArea,
  handleEdit,
  blockRow,
  archivedFarm
}) => {
  const areaModel = new Area(area);
  const areaVariety = areaModel.areaVarietyByDate(moment());

  return (
    <Table.Row
      onClick={() => setActivePatch(area, farm)}
      className={`${styles.rowArea} ${blockRow ? styles.rowBlock : ""} ${
        area.archived && !archivedFarm ? styles.archivedFarm : ""
      }`}
    >
      <Table.Cell />
      <Table.Cell>
        <div className={styles.blockName}>
          <span>{area.name}</span>
        </div>
      </Table.Cell>
      <Table.Cell colSpan={2}>
        <div className={styles.verticalMiddle}>
          {areaVariety.variety ? (
            areaVariety.variety.name
          ) : (
            <span className={styles.vacant}>Vacant</span>
          )}
        </div>
      </Table.Cell>
      <Table.Cell>{area.size} Ha</Table.Cell>
      <Table.Cell textAlign="center">
        {area.withholdingDays ? (
          <div className={`${styles.statusHolder} ${styles.timer}`}>
            <Icon name="stopwatch" />
            <div>
              {area.withholdingDays} days
              <br />
              Withholding Period
            </div>
          </div>
        ) : (
          <div className={`${styles.statusHolder} ${styles.clock}`}>
            <Icon className="tuf-check-circle" />
            <div>
              No
              <br />
              Withholding
            </div>
          </div>
        )}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {area.reentryDays ? (
          <div className={`${styles.statusHolder} ${styles.timer}`}>
            <Icon name="stopwatch" />
            <div>
              {" "}
              {area.reentryDays} days
              <br />
              Re-entry Period
            </div>
          </div>
        ) : (
          <div className={`${styles.statusHolder} ${styles.clock}`}>
            <i className="icon">
              <Icon className="tuf-check-circle" />
            </i>
            <div>
              No
              <br />
              Re-entry
            </div>
          </div>
        )}
      </Table.Cell>
      <Table.Cell textAlign="center">
        <Icon className={`tuf-tasks ${styles.tasksListIcon}`} />
        <Label
          circular
          color={area.tasksCount && area.tasksCount.OPEN ? "olive" : "grey"}
        >
          {area.tasksCount ? area.tasksCount.OPEN : 0}
        </Label>
      </Table.Cell>
      <Table.Cell />
      <Table.Cell textAlign="center">
        {!area.archived ? (
          <ToolTip content="Edit">
            <Icon
              className={`tuf-pen ${styles.tasksEditIcon}`}
              onClick={event => handleEdit(event, farm.id, area)}
            />
          </ToolTip>
        ) : (
          <Icon className="blank" />
        )}
        <ToolTip content={area.archived ? "Activate" : "Archive"}>
          <Icon
            className={area.archived ? "tuf-unarchive" : "tuf-archive"}
            onClick={() => handleArchiveArea(area, !area.archived)}
          />
        </ToolTip>
      </Table.Cell>
    </Table.Row>
  );
};

AreaRow.propTypes = {
  archivedFarm: PropTypes.bool,
  area: PropTypes.object.isRequired,
  setActivePatch: PropTypes.func.isRequired,
  blockRow: PropTypes.bool,
  className: PropTypes.string,
  farm: PropTypes.object.isRequired,
  handleArchiveArea: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired
};

export default AreaRow;
