import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MultiSelectFilter from "./MultiSelectFilter/MultiSelectFilter";
import { fetchChemicalsList } from "../../../actions/Chemicals/index";

const ProductMultiSelectFilter = ({
  actions: { fetchList },
  list,
  isCreating,
  ...extraProps
}) => {
  useEffect(() => {
    fetchList();
  }, [fetchList]);
  list.splice(0, 1);
  const options = useMemo(
    () =>
      list
        .filter(item => !item.archived)
        .map(item => ({
          key: item.id,
          text: item.name,
          value: item.id
        }))
        .sort((item1, item2) => item1.text.localeCompare(item2.text)),
    [list]
  );
  return (
    <MultiSelectFilter
      options={options}
      numbers={true}
      loading={isCreating}
      {...extraProps}
    />
  );
};

const mapStateToProps = state => {
  const {
    chemical: {
      isCreating,
      list: { content }
    }
  } = state;

  return {
    list: content,
    isCreating
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchList: fetchChemicalsList
    },
    dispatch
  )
});

ProductMultiSelectFilter.propTypes = {
  isCreating: PropTypes.bool,
  list: PropTypes.array,
  actions: PropTypes.object
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductMultiSelectFilter);
