import React, { useEffect, useState } from "react";
import {
  Form,
  Grid,
  Segment,
  Button,
  Icon,
  Header,
  Container,
  Popup
} from "semantic-ui-react";
import { connect } from "react-redux";
import { map, isEmpty } from "lodash";
import { useFormikContext, Field } from "formik";
import styles from "./batchNumberField.module.css";

const BatchNumberDropdownAdd = ({ item, batchNumbers, onChange, suffix }) => {
  const { values, setFieldValue } = useFormikContext();
  const [newBatchNumberShow, setNewBatchNumberShow] = useState(true);
  const [addValue, setAddValue] = useState([]);

  const shownOptions = batchNumbers.data
    .find(({ data, id }) => id == item.id)
    ?.data.map(batchNumber => ({
      value: batchNumber,
      text: batchNumber,
      key: batchNumber
    }));

  useEffect(() => {
    if (isEmpty(addValue)) {
      setNewBatchNumberShow(true);
    }
  }, [values.batchNumbers]);
  return (
    <Form className={styles.root}>
      <Grid>
        <Grid.Column className={styles.chemicalPropertyColumn}>
          <Form.Field>
            <div>
              {map(addValue, (batchNumber, index) => (
                <Segment className={styles.ingredientSection}>
                  <Header
                    floated="left"
                    className={styles.ingredientHeader}
                    size="small"
                  >
                    {batchNumber}
                  </Header>
                  <Popup
                    content="Delete active batch number"
                    position="bottom center"
                    inverted
                    trigger={
                      <Icon
                        name="trash alternate"
                        className={styles.removeActiveIngredient}
                        onClick={() => {
                          const filteredValues = addValue.filter(
                            (item, indx) => indx !== index
                          );
                          setAddValue(filteredValues);
                          return onChange(
                            [...(values.batchNumbers || []), filteredValues],
                            "chemicalBatchNumbers",
                            suffix
                          );
                        }}
                      />
                    }
                  />
                </Segment>
              ))}
              {newBatchNumberShow && (
                <Field
                  fluid
                  as={Form.Select}
                  allowAdditions
                  search
                  name="batchNumbers"
                  onChange={(_, { value }) => {
                    return (
                      setNewBatchNumberShow(false),
                      setAddValue([...addValue, value]),
                      setFieldValue(
                        "batchNumbers",
                        values.batchNumbers
                          ? [...values.batchNumbers, value]
                          : [value]
                      ),
                      onChange(
                        values.batchNumbers
                          ? [...values.batchNumbers, value]
                          : [value],
                        "chemicalBatchNumbers",
                        suffix
                      )
                    );
                  }}
                  options={shownOptions}
                />
              )}

              <Container fluid className={styles.textButtonContainer}>
                <Button
                  type="button"
                  onClick={() => setNewBatchNumberShow(true)}
                  className={styles.textButton}
                  disabled={newBatchNumberShow}
                >
                  <Icon name="plus" />
                  Add batch number
                </Button>
              </Container>
            </div>
          </Form.Field>
        </Grid.Column>
      </Grid>
    </Form>
  );
};
const mapStateToProps = state => {
  return {
    batchNumbers: state.spray.batchNumbers
  };
};
export default connect(mapStateToProps)(BatchNumberDropdownAdd);
