export const actionTypes = {
  FETCH_CONTRACTOR_REPORT_TABLE_DATA_START:
    "REPORTS/FETCH_CONTRACTOR_REPORT_TABLE_DATA_START",
  FETCH_CONTRACTOR_REPORT_TABLE_DATA:
    "REPORTS/SET_CONTRACTOR_REPORT_TABLE_DATA",
  FETCH_CONTRACTOR_REPORT_TABLE_DATA_FAILED:
    "REPORTS/FETCH_CONTRACTOR_REPORT_TABLE_DATA_FAILED",

  FETCH_CONTRACTOR_REPORT_TABLE_LIST_START:
    "REPORTS/FETCH_CONTRACTOR_REPORT_TABLE_LIST_START",
  SET_CONTRACTOR_REPORT_TABLE_LIST: "REPORTS/SET_CONTRACTOR_REPORT_TABLE_LIST",
  FETCH_CONTRACTOR_REPORT_TABLE_LIST_FAILED:
    "REPORTS/FETCH_CONTRACTOR_REPORT_TABLE_LIST_FAILED"
};
