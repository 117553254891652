import PropTypes from "prop-types";
import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { fetchEmployeeGroups } from "../../actions/EmployeeGroups/employeeGroups";
import SelectWithSelectAll from "../SelectWithSelectAll";

const SelectEmployeeGroup = props => {
  const {
    actions: { fetchEmployeeGroups },
    employeeGroups,
    isFetching,
    validation,
    showArchived,
    ...extraProps
  } = props;
  useEffect(() => {
    fetchEmployeeGroups({ unpaged: true });
  }, [fetchEmployeeGroups]);

  const filterArchived = data =>
    showArchived || !data.hasOwnProperty("archived")
      ? true
      : data.archived === false;

  const options = useMemo(
    () =>
      employeeGroups.filter(filterArchived).map(content => ({
        key: content.id,
        text: content.name,
        value: content.id
      })),
    [employeeGroups]
  );

  return (
    <SelectWithSelectAll
      placeholder="Select employee group"
      noResultsMessage="Nothing was found"
      fluid
      {...extraProps}
      error={!validation}
      closeOnBlur
      search
      loading={isFetching}
      selection
      options={options}
    />
  );
};

SelectEmployeeGroup.propTypes = {
  actions: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  employeeGroups: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  showArchived: PropTypes.bool,
  validation: PropTypes.bool.isRequired,
  value: PropTypes.array.isRequired
};

SelectEmployeeGroup.defaultProps = {
  disabled: false,
  employeeGroups: [],
  isFetching: false,
  onChange: () => {},
  showArchived: false,
  validation: true,
  value: []
};

const mapStateToProps = ({
  employeeGroups: {
    data: { content },
    isFetching
  }
}) => {
  return {
    employeeGroups: content,
    isFetching
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchEmployeeGroups
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectEmployeeGroup);
