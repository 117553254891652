import React from "react";

const Logo = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 178 75"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.5896 42.4097L13.8659 22.6335L15.0614 21.4348L34.7857 41.211L33.5896 42.4097Z"
      fill="#D2DB2F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.8081 45.1991L11.0845 25.4222L12.28 24.2228L32.0043 43.9997L30.8081 45.1991Z"
      fill="#D2DB2F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.0273 47.987L8.30298 28.2108L9.49915 27.0121L29.2235 46.7883L28.0273 47.987Z"
      fill="#D2DB2F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.2458 50.7765L5.52148 31.0003L6.71696 29.8009L26.4413 49.5778L25.2458 50.7765Z"
      fill="#D2DB2F"
    />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="32"
      width="24"
      height="25"
    >
      <path d="M0 32.5904H23.6593V56.3547H0V32.5904Z" fill="white" />
    </mask>
    <g mask="url(#mask0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.4635 53.566L2.73984 33.7891L3.93601 32.5904L23.6596 52.3666L22.4635 53.566Z"
        fill="#D2DB2F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.6815 56.3547L-0.0421143 36.5785L1.15336 35.3792L20.8777 55.156L19.6815 56.3547Z"
        fill="#D2DB2F"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.7496 58.2662L18.5534 57.0669L38.2778 37.2914L39.4732 38.49L19.7496 58.2662Z"
      fill="#2BAB6E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.5311 61.0549L21.3349 59.857L41.0593 40.0801L42.2554 41.2787L22.5311 61.0549Z"
      fill="#2BAB6E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.3121 63.8442L24.1166 62.6448L43.8402 42.8687L45.0364 44.068L25.3121 63.8442Z"
      fill="#2BAB6E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.0935 66.6332L26.898 65.4345L46.621 45.6576L47.8172 46.8563L28.0935 66.6332Z"
      fill="#2BAB6E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.8757 69.4218L29.6795 68.2225L49.4039 48.4463L50.5993 49.6456L30.8757 69.4218Z"
      fill="#2BAB6E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.6572 72.2113L32.461 71.0126L52.1854 51.2357L53.3808 52.4344L33.6572 72.2113Z"
      fill="#2BAB6E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.4389 75L35.2427 73.8013L54.967 54.0244L56.1625 55.2238L36.4389 75Z"
      fill="#2BAB6E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.8749 56.3547L37.1513 36.5785L38.3461 35.3792L58.0704 55.1561L56.8749 56.3547Z"
      fill="#0B8B42"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59.6566 53.566L39.933 33.7891L41.1284 32.5904L60.8528 52.3666L59.6566 53.566Z"
      fill="#0B8B42"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M62.4388 50.7765L42.7144 31.0003L43.9106 29.8009L63.6343 49.5778L62.4388 50.7765Z"
      fill="#0B8B42"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M65.2175 47.987L45.4932 28.2108L46.6893 27.0121L66.413 46.7883L65.2175 47.987Z"
      fill="#0B8B42"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M68 45.1991L48.277 25.4222L49.4718 24.2228L69.1955 43.9997L68 45.1991Z"
      fill="#0B8B42"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M70.7822 42.4097L51.0585 22.6335L52.254 21.4348L71.9783 41.211L70.7822 42.4097Z"
      fill="#0B8B42"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M73.5637 39.6216L53.84 19.8447L55.0355 18.6453L74.7598 38.4222L73.5637 39.6216Z"
      fill="#0B8B42"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.4389 37.7094L35.2427 36.51L54.967 16.7331L56.1625 17.9325L36.4389 37.7094Z"
      fill="#84BF41"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.6572 34.9206L32.461 33.7213L52.1854 13.9451L53.3808 15.1437L33.6572 34.9206Z"
      fill="#84BF41"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.8757 32.1311L29.6795 30.9318L49.4039 11.1556L50.5993 12.3549L30.8757 32.1311Z"
      fill="#84BF41"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.0935 29.3424L26.898 28.1437L46.621 8.36682L47.8172 9.56548L28.0935 29.3424Z"
      fill="#84BF41"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.3121 26.5531L24.1166 25.3537L43.8402 5.57751L45.0364 6.77688L25.3121 26.5531Z"
      fill="#84BF41"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.5311 23.7643L21.3349 22.5657L41.0593 2.78876L42.2554 3.98742L22.5311 23.7643Z"
      fill="#84BF41"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.7496 20.9756L18.5534 19.7762L38.2778 0L39.4732 1.19936L19.7496 20.9756Z"
      fill="#84BF41"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M89.175 15.4981H82.8769V31.5232H78.8667V15.4981H72.5986V11.8644H89.175V15.4981Z"
      fill="#84BF41"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M91.5536 31.5231H95.5645V16.7785H91.5536V31.5231ZM95.5638 13.3314C95.5638 14.463 94.6597 15.331 93.5472 15.331C92.434 15.331 91.5536 14.463 91.5536 13.3314C91.5536 12.1761 92.434 11.3094 93.5472 11.3094C94.6597 11.3094 95.5638 12.1761 95.5638 13.3314Z"
      fill="#84BF41"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M101.951 22.676H108.664C108.634 20.8001 107.18 19.6978 105.486 19.6978C103.793 19.6978 102.426 20.8294 101.951 22.676ZM112.289 25.5052H101.951C102.455 27.3826 104 28.4247 106.109 28.4247C107.714 28.4247 109.02 27.8585 110.209 26.9353L111.872 29.6758C110.209 31.0757 108.248 31.8795 105.99 31.8795C101.326 31.8795 97.9401 28.5141 97.9401 24.1654C97.9401 19.8167 101.297 16.4506 105.634 16.4506C109.763 16.4506 112.556 19.4889 112.556 23.3015C112.556 24.136 112.407 24.9999 112.289 25.5052Z"
      fill="#84BF41"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M131.63 23.3918C131.63 28.6638 128.065 31.8796 123.312 31.8796C118.53 31.8796 114.994 28.6638 114.994 23.3918V11.8644H119.004V23.6889C119.004 26.6376 120.876 28.1564 123.312 28.1564C125.778 28.1564 127.619 26.6376 127.619 23.6889V11.8644H131.63V23.3918Z"
      fill="#0B8B42"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M145.478 24.1654C145.478 21.6632 143.785 20.0844 141.675 20.0844C139.834 20.0844 137.962 21.3054 137.962 24.136C137.962 26.9353 139.804 28.2157 141.675 28.2157C143.755 28.2157 145.478 26.6669 145.478 24.1654ZM149.548 24.136C149.548 28.5736 146.577 31.8795 142.418 31.8795C140.606 31.8795 139.061 31.2246 138.022 30.093V36.8838H134.012V16.7784H137.695V18.625C138.704 17.2257 140.369 16.4506 142.418 16.4506C146.577 16.4506 149.548 19.6978 149.548 24.136Z"
      fill="#0B8B42"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M88.5756 47.3901H79.6935V52.3049H86.9709V55.938H79.6935V63.4151H75.6832V43.7563H88.5756V47.3901Z"
      fill="#231E1E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M98.7638 57.9048V57.7259H95.6151C94.3081 57.7259 93.565 58.2312 93.565 59.2153C93.565 60.2281 94.4266 60.8229 95.675 60.8229C97.3675 60.8229 98.7638 59.6312 98.7638 57.9048ZM102.774 54.6576V63.4152H99.0907V61.9852C98.0507 63.0867 96.506 63.7717 94.6043 63.7717C91.3964 63.7717 89.5255 61.8657 89.5255 59.3635C89.5255 56.6831 91.6941 55.0749 95.0798 55.0749H98.7638V54.4186C98.7638 52.811 97.8429 51.8283 96.0306 51.8283C94.4573 51.8283 93.3573 52.5734 92.1388 53.8538L90.0601 51.381C91.7826 49.3262 93.8919 48.3428 96.4767 48.3428C100.219 48.3428 102.774 50.4577 102.774 54.6576Z"
      fill="#231E1E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M114.126 48.6707V52.2156H113.057C110.741 52.2156 109.107 53.4066 109.107 56.0576V63.4153H105.096V48.6707H108.78V50.4578C109.76 49.1774 111.216 48.4924 112.82 48.4924C113.355 48.4924 113.741 48.5518 114.126 48.6707Z"
      fill="#231E1E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M138.553 54.2704V63.4152H134.543V54.8358C134.543 53.0787 133.503 51.9765 131.929 51.9765C130.325 51.9765 129.225 53.1088 129.225 54.7471V63.4152H125.216V54.8358C125.216 53.0787 124.175 51.9765 122.602 51.9765C120.997 51.9765 119.899 53.0787 119.899 54.8358V63.4152H115.888V48.6706H119.571V49.9818C120.552 48.9683 121.977 48.3428 123.73 48.3428C125.72 48.3428 127.205 49.1179 128.097 50.4577C129.196 49.1773 130.948 48.3428 132.999 48.3428C136.504 48.3428 138.553 50.7261 138.553 54.2704Z"
      fill="#231E1E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M140.95 63.4152H144.961V48.6706H140.95V63.4152ZM144.96 44.3275C144.96 45.459 144.056 46.3257 142.943 46.3257C141.831 46.3257 140.95 45.459 140.95 44.3275C140.95 43.1721 141.831 42.3041 142.943 42.3041C144.056 42.3041 144.96 43.1721 144.96 44.3275Z"
      fill="#231E1E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M160.914 54.2704V63.4152H156.935V54.8065C156.935 53.1088 155.835 51.9471 154.202 51.9471C152.568 51.9471 151.409 53.1088 151.409 54.8065V63.4152H147.398V48.6706H151.082V50.0111C152.092 48.9984 153.578 48.3428 155.389 48.3428C158.836 48.3428 160.914 50.7261 160.914 54.2704Z"
      fill="#231E1E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M174.049 55.5802C174.049 52.8704 172.178 51.7682 170.336 51.7682C168.257 51.7682 166.534 53.1682 166.534 55.6109C166.534 58.0537 168.286 59.5718 170.336 59.5718C172.208 59.5718 174.049 58.2913 174.049 55.5802ZM178 48.6706V61.7769C178 66.7505 174.821 69.1331 170.395 69.1331C167.187 69.1331 164.84 67.8827 163.177 66.3933L165.286 63.5927C166.741 64.7243 168.168 65.4994 170.068 65.4994C172.326 65.4994 173.989 64.3979 173.989 62.0145V61.2101C172.95 62.3416 171.345 62.9679 169.504 62.9679C165.464 62.9679 162.463 60.0191 162.463 55.6403C162.463 51.2028 165.494 48.3428 169.504 48.3428C171.642 48.3428 173.306 49.1179 174.316 50.5171V48.6706H178Z"
      fill="#231E1E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.3583 38.2132L17.8431 18.6453L16.6476 19.8447L36.1635 39.4119L37.3583 38.2132Z"
      fill="#D2DB2F"
    />
  </svg>
);

Logo.defaultProps = {
  width: 256,
  height: 108
};

export default Logo;
