import { actionType } from '../../constants/EmployeeBarcodes';
import { OPTIONS } from '../../routes/Barcodes/GenerateEmployeesBarcodes/EmployeesBarcodesGeneratorForm/SelectPrintLayout';

const initialState = {
  series: [],
  isFetching: false,
  error: null,
  printLayout: OPTIONS[0].value,
};

export default (state = initialState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case actionType.EMPLOYEE_BARCODES_CLEAR_SERIES:
      return {
        ...initialState,
      };
    case actionType.GENERATE_EMPLOYEE_BARCODES_START:
      return {
        ...initialState,
        isFetching: true,
      };
    case actionType.GENERATE_EMPLOYEE_BARCODES:
      return {
        ...state,
        series: action.payload,
        isFetching: false,
      };
    case actionType.GENERATE_EMPLOYEE_BARCODES_FAIL:
      return {
        ...initialState,
        error: action.payload,
        isFetching: false,
      };
    case actionType.EMPLOYEE_BARCODES_MARK_PRINTED:
      return {
        ...state,
        series: state.series.map(series => {
          if (series.employee.id === action.payload) {
            return {
              ...series,
              printed: true,
            };
          }

          return series;
        }),
      };
    case actionType.EMPLOYEE_BARCODES_SET_PRINT_LAYOUT:
      return {
        ...state,
        printLayout: action.payload,
      };
    case actionType.EMPLOYEE_BARCODES_CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
