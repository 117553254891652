import React from 'react';
import PropTypes from 'prop-types';
import { utils } from './utils/utils';
import WeatherIcon from './WeatherIcon';

const propTypes = {
  forecast: PropTypes.string.isRequired,
  numOfDays: PropTypes.number.isRequired,
  unit: PropTypes.string.isRequired,
  daysData: PropTypes.array.isRequired,
};

const DaysForecast = props => {
  const { forecast, unit, daysData, numOfDays = 5 } = props;
  if (forecast === '5days') {
    const units = utils.getUnits(unit);
    return (
      <div className="rw-box-days">
        {daysData.map((day, i) => {
          if (i > 0 && i <= numOfDays) {
            const iconCls = utils.getIcon(day.icon);
            return (
              <div key={`day-${i}`} className="rw-day">
                <div className="rw-date">{day.date}</div>
                <WeatherIcon name={iconCls}/>
                <div className="rw-desc">{day.description}</div>
                <div className="rw-range">
                  {day.temperature.max}&deg; / {day.temperature.min}&deg; {units.temp}
                </div>
              </div>
            );
          }
          return '';
        })}
      </div>
    );
  }
  return <div/>;
};

DaysForecast.propTypes = propTypes;

export default DaysForecast;
