import { actionType } from "../../constants/Dashboard";

const initialState = {
  binsPerAreaPerEmployee: {},
  binsPerArea: {},
  isReportInProgress: false,
  error: null
};

export default (state = initialState, action) => {
  if (!action) {
    return state;
  }
  switch (action.type) {
    case actionType.DASHBOARD_BINS_PER_AREA_PER_EMPLOYEE:
      return {
        ...state,
        binsPerAreaPerEmployee: action.payload,
        isReportInProgress: false
      };
    case actionType.DASHBOARD_BINS_PER_AREA:
      return {
        ...state,
        binsPerArea: action.payload
      };
    default:
      return state;
  }
};
