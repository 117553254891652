import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Checkbox, Confirm, Grid, TextArea } from "semantic-ui-react";
import { Form } from "formsy-semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { validationErrors } from "../../../../utils/validationRules";
import SegmentClosable from "../../../../components/SegmentClosable";
import styles from "./TagBarcodesGeneratorForm.module.css";
import ErrorLabel from "../../../../components/ErrorLabel";
import ReactDOMServer from "react-dom/server";
import { copyStyles } from "../../../../utils/styles";
import DatePicker from "../../../../components/DatePicker";
import SelectPrintLayout, { PrintLayouts } from "./SelectPrintLayout";
import moment from "moment";
import {
  clearSeries,
  generateTagBarcodes
} from "../../../../actions/TagBarcodes/tagBarcodes";

const initialValues = {
  date: new Date(),
  comment: null,
  quantity: null,
  addDateToLabel: false,
  showConfirm: false,
  confirmAction: null,
  printLayout: "A4 8 Labels"
};

const columnMiddle = {
  mobile: 16,
  tablet: 16,
  computer: 8,
  largeScreen: 8,
  widescreen: 8
};

class TagBarcodesGeneratorForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...initialValues
    };
    this.formRef = React.createRef();

    this.handleChange = ({ target: { name, value } }) => {
      this.setState({
        [name]: value
      });
    };
  }

  clear = () => {
    const { actions, formRef } = this.props;
    actions.clearSeries();
    this.isSubmitted = false;
    this.setState({ ...initialValues }, () => {
      const targetRef = formRef || this.formRef;
      targetRef.current.reset();
    });
  };

  print = (labels, series) => {
    const { printLayout } = this.state;
    const newWindow = window.open();
    newWindow.document.title = ``;
    copyStyles(window.document, newWindow.document);
    newWindow.document.body.innerHTML = ReactDOMServer.renderToString(
      PrintLayouts[printLayout].html.render({ labels, series })
    );
    newWindow.focus();
    setTimeout(function() {
      newWindow.print();
    }, 2000);
  };

  showReprintConfirm = confirmAction => {
    this.setState({
      showConfirm: true,
      confirmAction: confirmAction
    });
  };

  hideConfirm = () => {
    this.setState({
      showConfirm: false,
      confirmAction: null
    });
  };

  onConfirm = () => {
    const { confirmAction } = this.state;
    confirmAction();
    this.hideConfirm();
  };

  onValidSubmit = async () => {
    const {
      actions: { generateTagBarcodes }
    } = this.props;

    const dataToSend = {
      quantity: this.state.quantity,
      comment: this.state.comment,
      farmLabel: this.state.farm ? this.state.farm.name : null
    };

    const series = await generateTagBarcodes(dataToSend);

    this.print(
      {
        ...dataToSend,
        dateLabel: this.state.addDateToLabel
          ? moment(this.state.date).format("DD/MM/YYYY")
          : ""
      },
      series
    );
  };

  onSubmit = () => {
    const { formRef } = this.props;
    const targetRef = formRef || this.formRef;
    targetRef.current.submit();
  };

  render() {
    const { formRef } = this.props;
    const { quantity, showConfirm } = this.state;
    return (
      <Form
        onValidSubmit={this.onValidSubmit}
        onInvalidSubmit={this.handleErrors}
        onReset={() => this.clear()}
        loading={false}
        ref={formRef || this.formRef}
      >
        <div className={styles.sprayGrid}>
          <div className={styles.sprayFormContainer} id="sprayGrid">
            <SegmentClosable title="Barcode setup">
              <Grid>
                <Grid.Row>
                  <Grid.Column {...columnMiddle}>
                    <Form.Field className="sprayField">
                      <label>Date</label>
                      <DatePicker
                        value={this.state.date}
                        onChange={date =>
                          this.handleChange({
                            target: { name: "date", value: date }
                          })
                        }
                      />
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column {...columnMiddle}>
                    <Form.Field className="sprayField">
                      <Checkbox
                        style={{ marginTop: 35 }}
                        value={this.state.addDateToLabel}
                        onClick={() =>
                          this.handleChange({
                            target: {
                              name: "addDateToLabel",
                              value: !this.state.addDateToLabel
                            }
                          })
                        }
                        label="Add print date to label"
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column {...columnMiddle}>
                    <Form.Field required className="sprayField">
                      <Form.Input
                        fluid
                        required
                        label="Barcode quantity"
                        placeholder="Insert barcodes quantity"
                        name="quantity"
                        type="number"
                        format="integer"
                        value={quantity}
                        onChange={event =>
                          this.setState({ quantity: event.target.value })
                        }
                        validations={{
                          isPositiveInteger: true,
                          upTo10000: true
                        }}
                        validationErrors={validationErrors({
                          isDefaultRequiredValue: "Quantity"
                        })}
                        errorLabel={ErrorLabel}
                      />
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column {...columnMiddle}>
                    <Form.Field required className="sprayField">
                      <SelectPrintLayout
                        onChange={this.handleChange}
                        name={"printLayout"}
                        value={this.state.printLayout}
                        style={{ marginTop: 24 }}
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Form.Field className="sprayField">
                      <label>Add comments</label>
                      <TextArea
                        maxLength={50}
                        placeholder="Only 50 characters"
                        value={this.state.comment}
                        onChange={(_, data) =>
                          this.handleChange({
                            target: { name: "comment", value: data.value }
                          })
                        }
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column {...columnMiddle}>
                    <Button
                      primary
                      size="large"
                      type="button"
                      onClick={this.onSubmit}
                    >
                      Print
                    </Button>
                    <Button primary size="large" type="reset">
                      Clear
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </SegmentClosable>
          </div>
        </div>
        <Confirm
          content={"Are you sure you want to print it again?"}
          open={showConfirm}
          onConfirm={this.onConfirm}
          onCancel={this.hideConfirm}
        />
      </Form>
    );
  }
}

TagBarcodesGeneratorForm.propTypes = {
  formRef: PropTypes.func,
  actions: PropTypes.object
};

const mapStateToProps = state => {
  const {
    tagBarcodes: { series, isGenerating }
  } = state;
  return {
    series,
    isGenerating
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      generateTagBarcodes,
      clearSeries
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TagBarcodesGeneratorForm);
