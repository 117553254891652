import React, { useState } from "react";
import { useFormikContext } from "formik";
import { useDispatch } from "react-redux";
import { Icon, Segment, Grid, Modal, Button, Popup } from "semantic-ui-react";
import { get } from "lodash";
import styles from "./Farm.module.css";

const FarmRow = ({ farmData, shed }) => {
  const { values, setFieldValue } = useFormikContext();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const dispatch = useDispatch();
  const onDeleteClick = () => {
    setFieldValue(
      "farms",
      values.farms.filter(i => i !== farmData)
    );
    setShowDeleteModal(false);
  };

  return (
    <Grid.Row className={styles.rowContainer} as={Segment}>
      <Modal
        className={styles.deleteModal}
        size="mini"
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
      >
        <Modal.Content>
          <p>
            Delete of {get(farmData.farm, "name")}{" "}
            {shed && `from ${shed.name}'s farm`}?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            className={styles.cancelDltBtn}
            onClick={() => setShowDeleteModal(false)}
          >
            Cancel
          </Button>
          <Button
            className={styles.deleteBtn}
            positive
            onClick={() => onDeleteClick()}
          >
            Delete
          </Button>
        </Modal.Actions>
      </Modal>
      <Grid.Column>{get(farmData.farm, "name")}</Grid.Column>
      <Grid.Column textAlign="right">
        <Popup
          inverted
          position="bottom center"
          content={"Delete"}
          size="tiny"
          trigger={
            <Button
              onClick={() => setShowDeleteModal(true)}
              className={styles.deleteButton}
              floated="right"
              icon={<Icon name="trash alternate" />}
              size="small"
            />
          }
        />
      </Grid.Column>
    </Grid.Row>
  );
};

export default FarmRow;
