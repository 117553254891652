import React from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import {
  Table,
  TextArea,
  Icon,
  Header,
  Grid,
  Button,
  Form,
  Input
} from "semantic-ui-react";
import moment from "moment";
import styles from "./BlockDetails.module.css";

const Details = ({ block, changeValue }) => {
  const openedTasks = block.tasks
    ? block.tasks.filter(task => task.status === "open")
    : null;
  const today = moment();

  const currentCrop = block.isRotating
    ? block.varieties.find(variety =>
        today.isBetween(variety.plantingAt, variety.pickingAt)
      )
    : block.varieties[0];

  return (
    <div>
      <Grid className={styles.detailsItem}>
        <Grid.Row>
          <Grid.Column width={3}>
            <div className={styles.detailsTitle}>SIZE</div>
            <div className={styles.detailsText}>{block.size} Ha</div>
          </Grid.Column>
          <Grid.Column width={3}>
            <div className={styles.detailsTitle}>Crop</div>
            <div className={styles.detailsText}>
              {currentCrop.variety && currentCrop.variety.crop
                ? currentCrop.variety.crop.name
                : "—"}
            </div>
          </Grid.Column>
          <Grid.Column width={3}>
            <div className={styles.detailsTitle}>Variety</div>
            <div className={styles.detailsText}>
              {currentCrop.variety ? currentCrop.variety.name : "—"}
            </div>
          </Grid.Column>
          <Grid.Column width={3}>
            <div className={styles.detailsTitle}>No. of Trees</div>
            <div className={styles.detailsText}>
              {currentCrop.numberOfPlants || "—"}
            </div>
          </Grid.Column>
          <Grid.Column width={4}>
            <div className={styles.detailsTitle}>Rootstock</div>
            <div className={styles.detailsText}>
              {currentCrop.rootstock || "—"}
            </div>
          </Grid.Column>
        </Grid.Row>
        {block.type === "PATCH" && (
          <Grid.Row>
            <Grid.Column>
              <div className={styles.detailsTitle}>Registration Number</div>
              <div className={styles.detailsText}>
                {block.registrationNumber || "—"}
              </div>
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row>
          <Grid.Column width={3}>
            <div className={styles.detailsTitle}>Age</div>
            <div className={styles.detailsText}>
              {block.plantingAt
                ? moment().diff(block.plantingAt, "years")
                : "—"}
            </div>
          </Grid.Column>
          <Grid.Column width={9}>
            <div className={styles.detailsTitle}>Planted On</div>
            <div className={styles.detailsText}>
              {block.plantingAt
                ? moment(block.plantingAt).format("DD/MM/YYYY")
                : "—"}
            </div>
          </Grid.Column>
          <Grid.Column width={4} textAlign="right">
            <Button className="button-text">Edit Details</Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <hr className={styles.lineHorizontal} />
      <Grid className={styles.detailsItem}>
        <Grid.Row>
          <Grid.Column width={8}>
            <div className={styles.detailsTitle}>Planning</div>
          </Grid.Column>
          <Grid.Column textAlign="right" width={8}>
            <Button className="button-text">View Timeline</Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {openedTasks && (
        <>
          <div className={styles.tasksTotal}>
            {openedTasks.length} Tasks are planned for{" "}
            {moment().format("MMMM, YYYY")}
          </div>
          <Table className={styles.tableTasks} basic="very">
            <Table.Body>
              {openedTasks.map(task => (
                <Table.Row key={`task_${task.id}`}>
                  <Table.Cell>
                    Spray task due on {moment(task.due).format("DD.MM.YYYY")}
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <Button className="button-text">View Task</Button>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </>
      )}
      <Grid className={styles.detailsItem}>
        <Grid.Row>
          <Grid.Column width={8}>
            <div className={styles.detailsTitle}>
              Withholding <Icon name="info circle" />
            </div>
            {block.withholding ? (
              <div className={`${styles.detailsText} ${styles.timer}`}>
                <Icon name="stopwatch" /> {block.withholding} days left out of X
                days
              </div>
            ) : (
              <div className={`${styles.detailsText} ${styles.clock}`}>
                <Icon name="clock" /> Safe
              </div>
            )}
          </Grid.Column>
          <Grid.Column width={8}>
            <div className={styles.detailsTitle}>
              Re-entry <Icon name="info circle" />
            </div>
            {block.reEnter ? (
              <div className={`${styles.detailsText} ${styles.timer}`}>
                <Icon name="stopwatch" /> {block.reEnter} days left out of X
                days
              </div>
            ) : (
              <div className={`${styles.detailsText} ${styles.clock}`}>
                <Icon name="clock" /> Safe
              </div>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <hr className={styles.lineHorizontal} />
      <Button className="button-text">
        <Icon name="linkify" /> Add attachment
      </Button>
      <div className={styles.formHolder}>
        <Form.Field>
          <label>
            <div>Barcode comments</div>
            <Input
              size="large"
              value={block.barcodeComments}
              placeholder=""
              onChange={event =>
                changeValue(block, "barcodeComments", event.target.value)
              }
            />
          </label>
        </Form.Field>
        <Form.Field>
          <label>
            <div>Comments & Notes</div>
            <TextArea
              size="large"
              value={block.comments || ""}
              placeholder=""
              onChange={event =>
                changeValue(block, "comments", event.target.value)
              }
            />
          </label>
        </Form.Field>
      </div>
    </div>
  );
};

Details.propTypes = {
  block: PropTypes.object,
  changeValue: PropTypes.func
};

const Activity = () => {
  return <div>No Activity</div>;
};

function BlockDetails({ block, changeValue, history }) {
  return (
    <div className={styles.detailsHolder}>
      <Header as="h2">
        {block.farm.name} - {block.name}
      </Header>
      {/*<Button*/}
      {/*  onClick={() => {*/}
      {/*    const blockId = _.get(block, 'parent.id') || block.id;*/}
      {/*    history.push(`/farms/edit_farm/${block.farm.id}/${blockId}`);*/}
      {/*  }}*/}
      {/*>*/}
      {/*  Edit block*/}
      {/*</Button>*/}
      {/*<Tab menu={{ secondary: true, pointing: true }} panes={getPanes(block, changeValue)} />*/}
      <Details block={block} changeValue={changeValue} />
      <hr />
      <Activity />
    </div>
  );
}

BlockDetails.propTypes = {
  block: PropTypes.object.isRequired,
  changeValue: PropTypes.func,
  history: PropTypes.object
};

BlockDetails.defaultProps = {
  block: {}
};

export default withRouter(BlockDetails);
