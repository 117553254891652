export const actionType = {
  GET_URL_START: "MACHINES/GET_URL_START",
  GET_URL: "MACHINES/GET_URL",
  GET_URL_FAIL: "MACHINES/GET_URL_FAIL",

  FETCH_TOKEN_START: "MACHINES/FETCH_TOKEN_START",
  FETCH_TOKEN: "MACHINES/FETCH_TOKEN",
  FETCH_TOKEN_FAIL: "MACHINES/FETCH_TOKEN_FAIL",

  CHECK_TOKEN_START: "MACHINES/CHECK_TOKEN_START",
  CHECK_TOKEN: "MACHINES/CHECK_TOKEN",
  CHECK_TOKEN_FAIL: "MACHINES/CHECK_TOKEN_FAIL",

  GET_LAST_LOCATION_START: "MACHINES/GET_LAST_LOCATION_START",
  GET_LAST_LOCATION: "MACHINES/GET_LAST_LOCATION",
  GET_LAST_LOCATION_FAIL: "MACHINES/GET_LAST_LOCATION_FAIL",

  GET_ORGANIZATIONS_START: "MACHINES/GET_ORGANIZATIONS_START",
  GET_ORGANIZATIONS: "MACHINES/GET_ORGANIZATIONS",
  GET_ORGANIZATIONS_FAIL: "MACHINES/GET_ORGANIZATIONS_FAIL",

  GET_MACHINES_START: "MACHINES/GET_MACHINES_START",
  GET_MACHINES: "MACHINES/GET_MACHINES",
  GET_MACHINES_FAIL: "MACHINES/GET_MACHINES_FAIL",

  CLEAR_TOKEN_START: "MACHINES/CLEAR_TOKEN_START",
  CLEAR_TOKEN: "MACHINES/CLEAR_TOKEN",
  CLEAR_TOKEN_FAIL: "MACHINES/CLEAR_TOKEN_FAIL",
  CHECK_REDIRECT: "MACHINES/CHECK_REDIRECT",

  COLLECTED_LAST_LOCATION_START: "MACHINES/COLLECTED_LAST_LOCATION_START",
  COLLECTED_LAST_LOCATION: "MACHINES/COLLECTED_LAST_LOCATION"
};
