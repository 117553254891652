import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow
} from "semantic-ui-react";
import moment from "moment";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchScannedBarcodesByEmployeeId } from "../../../../../../actions/Reports/reports";
import {
  selectEmployeeBarcodes,
  selectIsEmployeeReportLoading
} from "../../../../../../selectors/reports";
import Loader from "../../../../../../components/Loader";

const PickerBreakdownTable = ({ employeeId, crops, isFetching, actions }) => {
  useEffect(() => {
    if (!isFetching && !crops) {
      actions.fetchScannedBarcodesByEmployeeId(employeeId);
    }
  }, [crops, isFetching]);

  return (
    <Table unstackable>
      <TableHeader>
        <TableRow>
          <TableHeaderCell>Crop</TableHeaderCell>
          <TableHeaderCell>Variety</TableHeaderCell>
          <TableHeaderCell>HarvestUnit, weight</TableHeaderCell>
          <TableHeaderCell>Quantity</TableHeaderCell>
          <TableHeaderCell>Scanned At</TableHeaderCell>
          <TableHeaderCell>Price</TableHeaderCell>
          <TableHeaderCell>Batch ID</TableHeaderCell>
          <TableHeaderCell>Farm</TableHeaderCell>
          <TableHeaderCell>Area</TableHeaderCell>
          <TableHeaderCell>Row</TableHeaderCell>
        </TableRow>
      </TableHeader>
      <TableBody>
        {isFetching && !crops && <Loader />}
        {Array.isArray(crops) &&
          crops.map((crop, i) => (
            <TableRow key={`crop_${i}`}>
              <TableCell>{crop.cropName}</TableCell>
              <TableCell>{crop.varietyName}</TableCell>
              <TableCell>
                {`${crop.harvestUnitName} ${crop.harvestUnitNameNetWeight} KG`}
              </TableCell>
              <TableCell>{_.round(crop.binPart, 2)}</TableCell>
              <TableCell>
                {moment(crop.scannedAt).format("DD/MM/YYYY")}
              </TableCell>
              <TableCell>
                {_.round(crop.pricePartPerBarcode, 2) || "-"}
              </TableCell>
              <TableCell>{crop.batchId || "-"}</TableCell>
              <TableCell>{crop.farmName}</TableCell>
              <TableCell>{crop.areaName}</TableCell>
              <TableCell>
                {`${crop.rowsPrefix || ""}${crop.row || ""}` || "-"}
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

PickerBreakdownTable.propTypes = {
  crops: PropTypes.array,
  employeeId: PropTypes.string.isRequired,
  isFetching: PropTypes.bool,
  actions: PropTypes.shape({
    fetchScannedBarcodesByEmployeeId: PropTypes.func
  })
};

const mapStateToProps = (state, props) => {
  const loadingSelector = selectIsEmployeeReportLoading(state);
  const cropsBarcodesSelector = selectEmployeeBarcodes(state);

  return {
    isFetching: loadingSelector(props.employeeId),
    crops: cropsBarcodesSelector(props.employeeId)
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchScannedBarcodesByEmployeeId
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PickerBreakdownTable);
