import moment from "moment";
import ObjectId from "bson-objectid";

export const deleteTempIdFromWorkingTimes = workingTimes => {
  return workingTimes
    ? workingTimes.map(workingTime => {
        delete workingTime._id;
        return workingTime;
      })
    : [];
};

export const prepareTaskData = ({ forTask, sprayDiary, newTask }) => {
  //withEmptyRows need to compare arrays to send only modified fields
  const {
    assignees,
    supervisors,
    interventionType,
    shedId,
    applicationType,
    selectedAreas,
    selectedChemicals,
    plannedDate,
    expirationDate,
    literPerHectare,
    rowsToSpray,
    selectedMachinery,
    widthPerRow,
    status,
    windDirection,
    windStrength,
    machinerySpeed,
    conc,
    nozzle,
    nozzlesPerSide,
    gear,
    rpm,
    pressure,
    pto,
    actualRowsToSpray,
    temperature,
    deltaT,
    growthStage,
    workingTimes,
    comment,
    ppeWorn,
    taskCreatedAt,
    taskName,
    seen,
    seenAt,
    completedAt,
    inProgressAt,
    attachments,
    actualTotalTanks,
    employee,
    id,
    _id
  } = sprayDiary;
  const parseId = arr => {
    return arr
      ? arr.map(area => {
          const { id, size, plannedLiters, tanks, actualTanks, done } = area;

          return {
            area: forTask ? area : { id },
            plannedLiters,
            plannedTanks: tanks,
            hectares: size,
            done,
            actualTanks: actualTanks ? parseFloat(actualTanks) : null,
            ...(newTask ? { action: "CREATE" } : {})
          };
        })
      : [];
  };
  if (Object.keys(sprayDiary).length === 0) {
    return {};
  }

  let body = {
    supervisors: supervisors
      ? supervisors.map(supervisor => ({
          ...supervisor,
          employee: forTask
            ? employee.content.find(row => row.id === supervisor.employee.id)
            : { id: supervisor.employee.id }
        }))
      : [],
    interventionType,
    shedId,
    applicationType,
    areas: parseId(selectedAreas),
    chemicals: selectedChemicals
      ? selectedChemicals.map(chemical => ({
          position: chemical.position,
          chemical: forTask ? chemical : { id: chemical.id },
          dilutionRate: Number(chemical.rate.rate || chemical.rate.minRate), //minRate for old records
          batchRequired: chemical.batchRequired,
          reentry: chemical.reentry,
          withholdingPeriod: chemical.rate.withholdingPeriod,
          dilutionRateType: chemical.rate.rateType,
          qtyPerFullVat: chemical.fullTanks,
          qtyPerPartTank: chemical.partTanks,
          // batchNumber: chemical.batchNumber,
          batchNumbers: chemical.batchNumbers,
          dateOfManufacture: chemical.dateOfManufacture,
          chemicalRate: forTask
            ? chemical.rate
            : chemical.rate.id
            ? { id: chemical.rate.id }
            : null,
          ...(newTask ? { action: "CREATE" } : {})
        }))
      : [],
    plannedDate: moment(plannedDate).format("YYYY-MM-DD"),
    expirationDate: expirationDate
      ? moment(expirationDate).format("YYYY-MM-DD")
      : null,
    literPerHectare,
    rowsToSpray,
    status,
    growthStage,
    machinery:
      selectedMachinery && selectedMachinery.id
        ? forTask
          ? selectedMachinery
          : { id: selectedMachinery.id }
        : null,
    machinerySize:
      selectedMachinery && selectedMachinery.id ? selectedMachinery.size : null,
    machinerySizeUnit:
      selectedMachinery && selectedMachinery.id
        ? selectedMachinery.sizeUnit
        : null,
    machinerySpeed:
      selectedMachinery && selectedMachinery.id ? machinerySpeed : null,
    conc: selectedMachinery && selectedMachinery.id ? conc : null,
    nozzle: selectedMachinery && selectedMachinery.id ? nozzle : null,
    nozzlesPerSide:
      selectedMachinery && selectedMachinery.id ? nozzlesPerSide : null,
    gear: selectedMachinery && selectedMachinery.id ? gear : null,
    rpm: selectedMachinery && selectedMachinery.id ? rpm : null,
    pressure: selectedMachinery && selectedMachinery.id ? pressure : null,
    pto: selectedMachinery && selectedMachinery.id ? pto : null,
    rowWidth: widthPerRow,
    comment,
    ppeWorn,
    taskCreatedAt,
    taskName,
    seen,
    seenAt,
    completedAt,
    inProgressAt,
    workingTimes,
    attachments: attachments
      ? attachments
          .filter(file => (forTask ? file.id || file.idReplace : file.id))
          .map(file => ({ ...file, ...(newTask ? { action: "CREATE" } : {}) }))
      : [],
    actualTotalTanks: parseFloat(actualTotalTanks) || null
  };

  if (assignees) {
    body.assignees = assignees.map(assignee => ({
      employee: forTask
        ? employee.content.find(row => row.id === assignee.employee.id)
        : { id: assignee.employee.id }
    }));
  }

  const completedTaskBody = {
    windDirection,
    windStrength,
    temperature,
    deltaT,
    actualRowsToSpray,
    workingTimes: deleteTempIdFromWorkingTimes(workingTimes)
  };

  if (status === "COMPLETED") {
    body = { ...body, ...completedTaskBody };
  }
  const attachmentsToWatch = body.attachments.find(file => file.idReplace);
  if (forTask && _id && !id) {
    body._id = _id;
  } else if (forTask && attachmentsToWatch) {
    body._id = ObjectId().toHexString();
  }

  return body;
};
