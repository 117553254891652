import React from "react";
import moment from "moment";

import { Button, Grid, Icon } from "semantic-ui-react";
import { Form } from "formsy-semantic-ui-react";
import SelectArea from "../../../../../components/SelectArea";
import { validationErrors } from "../../../../../utils/validationRules";
import ErrorLabel from "../../../../../components/ErrorLabel";
import styles from "./AllocatePallets.module.css";
import PropTypes from "prop-types";

const AllocatePalletsTableItem = ({
  showDeleteButton,
  stock: { variety },
  item: { _id, area, count },
  changeItemValue,
  onDelete
}) => {
  return (
    <Grid.Row className={styles.tableItem}>
      <Grid.Column width={5}>
        <Form.Field>
          <Form.Input
            fluid
            name="count"
            type="number"
            validations="isPositiveInteger"
            validationErrors={validationErrors({
              isDefaultRequiredValue: "count"
            })}
            errorLabel={ErrorLabel}
            value={count}
            required
            onChange={event =>
              changeItemValue("count", _id, event.target.value)
            }
          />
        </Form.Field>
      </Grid.Column>
      <Grid.Column width={8}>
        <Form.Field>
          <SelectArea
            name={`area${_id}`}
            validation={"isRequired"}
            validationErrors={validationErrors({
              isDefaultRequiredValue: "area"
            })}
            date={moment().format("YYYY-MM-DD")}
            filter={area => {
              const areaVariety = area.areaVarietyByDate(
                moment().format("YYYY-MM-DD")
              );
              return (
                areaVariety &&
                areaVariety.variety &&
                areaVariety.variety.id === variety.id
              );
            }}
            required
            value={area && area.id}
            onChange={(_, data) => {
              const { area } = data.options.find(
                item => item.key === data.value
              ) || { area: null };
              if (area) changeItemValue("area", _id, area);
            }}
          />
        </Form.Field>
      </Grid.Column>
      <Grid.Column width={1}>
        {showDeleteButton && (
          <div className={styles.deleteButton}>
            <Button
              type="button"
              className="transparent"
              icon
              onClick={event => onDelete(event, _id)}
            >
              <Icon name="minus circle" color="red" size="large" />
            </Button>
          </div>
        )}
      </Grid.Column>
    </Grid.Row>
  );
};

AllocatePalletsTableItem.propTypes = {
  showDeleteButton: PropTypes.bool,
  stock: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  changeItemValue: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

AllocatePalletsTableItem.defaultProps = {
  showDeleteButton: false
};

export default AllocatePalletsTableItem;
