import React, { PureComponent } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { StandaloneSearchBox } from "@react-google-maps/api";
import ObjectId from "bson-objectid";
import {
  Grid,
  Header,
  Button,
  Icon,
  Form,
  Input,
  Dropdown
} from "semantic-ui-react";
import { getCropsList } from "../../../../actions/Farms/crops";

import { validateFarm } from "../validation";
import {
  setFarmField,
  addNewFarm,
  updateFarm,
  initBlock,
  setCurrentBlock
} from "../../../../actions/Farms/addNewFarm";
import { getFarmsSummary } from "../../../../actions/Farms/farms";

import styles from "./StepOne.module.css";
import { Area } from "../../../../models/block.model";
import { AreaVariety } from "../../../../models/area_variety.model";
import { fetchWeatherStationsList } from "../../../../actions/WeatherStation";

class StepOne extends PureComponent {
  componentDidMount() {
    const { actions } = this.props;
    actions.getCropsList();
    actions.fetchWeatherStationsList();
  }

  changeValue = (fieldName, fieldValue) => {
    const { actions } = this.props;
    actions.setFarmField({ fieldName, fieldValue });
  };

  onPlaceChanged = () => {
    const { changeLocation } = this.props;
    const places = this.searchBox.getPlaces();
    if (places && places[0]) {
      this.changeValue("location", {
        name: places[0].formatted_address,
        point: places[0].geometry.location
      });
      changeLocation(places[0].geometry.location);
    }
  };

  createNewFarm = async () => {
    const { setStep, newFarm, actions } = this.props;

    const errors = validateFarm(newFarm);
    if (errors) {
      await actions.setFarmField({ fieldName: "error", fieldValue: true });
    }

    const farmObj = {
      farmStructure: newFarm.constructed,
      location: newFarm.location.point
        ? [newFarm.location.point.lat(), newFarm.location.point.lng()]
        : [],
      name: newFarm.name,
      weatherStation: newFarm.weatherStation
    };

    if (newFarm.id) {
      farmObj.id = newFarm.id;
      await actions.updateFarm(farmObj);
      actions.getFarmsSummary();
    } else {
      await actions.addNewFarm(farmObj);
    }

    if (_.isEmpty(newFarm.blocks)) {
      const blockId = ObjectId().toHexString();
      await actions.initBlock(
        new Area({
          id: blockId,
          name: "",
          type: "block",
          size: 0,
          polygons: [],
          patchMode: "BLOCKS_AND_PATCHES",
          patches: [],
          attributes: {
            varieties: [new AreaVariety()]
          }
        })
      );
      await actions.setCurrentBlock(blockId);
    }

    if (!newFarm.error) {
      setStep(newFarm.id ? 3 : 2);
    }
  };

  panToLocation = () => {
    const { currentLatLng, refForMap } = this.props;

    refForMap.panTo(currentLatLng);
  };

  render() {
    const {
      newFarm,
      showPlaceInput,
      weatherStationsList,
      weatherStationsIsFetching
    } = this.props;
    const buttonDisabled =
      !newFarm.name || !newFarm.constructed || newFarm.isFetching;
    const weatherStationsOptions = weatherStationsList.map(ws => ({
      key: ws.id,
      text: ws.displayName,
      value: ws.id
    }));
    return (
      <Form className={styles.stepHolder}>
        <div className={styles.stepContent}>
          <div className={styles.stepCount}>STEP 1 / 2</div>
          <Header as="h2" className={styles.stepTitle}>
            Tell us about your farm
          </Header>
          <Grid>
            <Grid.Row>
              <Grid.Column width={16}>
                <Form.Field required>
                  <label>What&apos;s the name of the farm</label>
                  <Input
                    {...(!newFarm || !newFarm.id ? { autoFocus: true } : {})}
                    size="large"
                    placeholder="Give your farm a name"
                    value={newFarm.name || ""}
                    onChange={event =>
                      this.changeValue("name", event.target.value)
                    }
                  />
                  <div className={styles.helperText}>
                    Don’t worry adding more farms is available one farm at a
                    time
                  </div>
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <Form.Field>
                  <label className={styles.labelLocation}>
                    <div>Where is the farm located?</div>
                    {showPlaceInput ? (
                      <StandaloneSearchBox
                        onLoad={ref => (this.searchBox = ref)}
                        onPlacesChanged={this.onPlaceChanged}
                      >
                        <Input
                          size="large"
                          icon={{
                            name: "location arrow",
                            link: true,
                            onClick: this.panToLocation
                          }}
                          placeholder="Address"
                          value={newFarm.location.name}
                          onChange={event =>
                            this.changeValue("location", {
                              name: event.target.value
                            })
                          }
                        />
                      </StandaloneSearchBox>
                    ) : (
                      <Input
                        size="large"
                        icon="location arrow"
                        placeholder="Address"
                        value={newFarm.location.name}
                        disabled
                      />
                    )}
                  </label>
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <Form.Field>
                  <label
                    htmlFor="weatherStationDropdown"
                    className={styles.labelLocation}
                  >
                    <div>Select weather station</div>
                  </label>
                  <Dropdown
                    id="weatherStationDropdown"
                    clearable
                    closeOnBlur
                    closeOnChange
                    search
                    loading={weatherStationsIsFetching}
                    placeholder="Select weather station"
                    selection
                    fluid
                    noResultsMessage="Nothing was found"
                    value={newFarm.weatherStation && newFarm.weatherStation.id}
                    options={weatherStationsOptions}
                    onChange={(event, data) => {
                      event.preventDefault();
                      this.changeValue(
                        "weatherStation",
                        data.value ? { id: data.value } : null
                      );
                    }}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <div className={styles.stepActions}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={16}>
                <div className={`${styles.verticalMiddle} right`}>
                  <Button
                    type="submit"
                    disabled={buttonDisabled}
                    onClick={this.createNewFarm}
                    loading={newFarm.isFetching}
                  >
                    Draw Farm
                    <Icon name="right arrow" />
                  </Button>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </Form>
    );
  }
}

StepOne.propTypes = {
  showPlaceInput: PropTypes.bool,
  weatherStationsIsFetching: PropTypes.bool,
  changeLocation: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  refForMap: PropTypes.object,
  newFarm: PropTypes.object,
  weatherStationsList: PropTypes.array,
  actions: PropTypes.object,
  currentLatLng: PropTypes.object
};

StepOne.defaultProps = {
  route: { name: "{%name%}" },
  changeLocation: () => {},
  setStep: () => {}
};

function mapStateToProps({
  farms: { newFarm, constructed },
  weatherStation: {
    list: { data, isFetching }
  }
}) {
  return {
    newFarm,
    constructed,
    weatherStationsList: data,
    weatherStationsIsFetching: isFetching
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setFarmField,
        addNewFarm,
        updateFarm,
        getFarmsSummary,
        initBlock,
        setCurrentBlock,
        getCropsList,
        fetchWeatherStationsList
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StepOne);
