import React, { useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { truncate } from "lodash";
import { Button } from "semantic-ui-react";
import styles from "./ShowMore.module.css";

const ShowMore = ({
  children,
  labelMore,
  labelLess,
  length,
  separator,
  omission
}) => {
  const [showMore, setShowMore] = useState(true);
  const text = children ? children.toString() : "";
  const stringToShow = useMemo(() => {
    return !showMore || text.length <= length
      ? text
      : truncate(text, {
          length,
          separator,
          omission
        });
  }, [showMore, text, length, separator, omission]);

  const toggleMore = useCallback(
    event => {
      event.stopPropagation();
      setShowMore(!showMore);
    },
    [showMore]
  );

  return (
    <>
      {stringToShow}
      {text.length > length && (
        <Button
          className={`button-text color-green ${styles.buttonMore}`}
          onClick={toggleMore}
        >
          {showMore ? labelMore : labelLess}
        </Button>
      )}
    </>
  );
};

ShowMore.propTypes = {
  text: PropTypes.string,
  labelMore: PropTypes.string,
  labelLess: PropTypes.string,
  length: PropTypes.number,
  separator: PropTypes.string,
  omission: PropTypes.string,
  children: PropTypes.node
};

ShowMore.defaultProps = {
  labelMore: "more",
  labelLess: "less",
  length: 30,
  separator: "",
  omission: ""
};

export default ShowMore;
