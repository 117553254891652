import React, { useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";
import FarmItem from "./FarmItem";
import SegmentClosable from "../../../components/SegmentClosable";
import styles from "../Dashboard.module.css";

const FarmsList = ({ farm, constructed, redirectToFarm }) => {
  const sortedAreasToShow = useMemo(
    () =>
      farm.areas
        .filter(area => !area.hasSubAreas)
        .sort((area1, area2) => {
          return area1.name?.localeCompare(area2.name);
        }),
    [farm]
  );

  const handleRedirectToFarm = useCallback(() => {
    redirectToFarm(farm.farm.id);
  }, [farm, redirectToFarm]);

  return (
    <SegmentClosable
      title={farm.farm.name}
      onClickHeader={handleRedirectToFarm}
      headerClassName={styles.farmName}
      headerMore={
        <div className={styles.blocksCount}>
          <span>{farm.areas.filter(area => area.type === "BLOCK").length}</span>{" "}
          Blocks | {farm.areas.filter(area => area.type === "PATCH").length}{" "}
          Patches
        </div>
      }
    >
      <div className={styles.tableHolder}>
        <Table unstackable striped selectable className={styles.tableSummary}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Area</Table.HeaderCell>
              <Table.HeaderCell>Size</Table.HeaderCell>
              <Table.HeaderCell>Planting At</Table.HeaderCell>
              <Table.HeaderCell>Crop</Table.HeaderCell>
              <Table.HeaderCell>Variety</Table.HeaderCell>
              <Table.HeaderCell>Plants No.</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                Opened Tasks
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                In progress Tasks
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                Completed Tasks
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {sortedAreasToShow &&
              sortedAreasToShow.map((block, index) => {
                const count = {};
                (block.taskStatistics || []).forEach(statusRow => {
                  count[statusRow.status] = statusRow.count;
                });

                return (
                  <FarmItem key={`block${index}`} block={block} count={count} />
                );
              })}
          </Table.Body>
        </Table>
      </div>
    </SegmentClosable>
  );
};

FarmsList.propTypes = {
  farm: PropTypes.object,
  constructed: PropTypes.array,
  redirectToFarm: PropTypes.func.isRequired
};

export default FarmsList;
