import React, { memo, useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHeader as Header,
  TableHeaderCell,
  TableRow
} from "semantic-ui-react";
import PropTypes from "prop-types";

const getRowName = tag =>
  tag.rowsPrefix || Number.isInteger(tag.row)
    ? `${tag.rowsPrefix ? tag.rowsPrefix + " " : ""}${tag.row}`
    : null;

const TagsTable = ({ data, hasAreaColumn }) => {
  return (
    <Table unstackable>
      <Header>
        <TableRow>
          {hasAreaColumn && <TableHeaderCell>Area</TableHeaderCell>}
          <TableHeaderCell>Row Number</TableHeaderCell>
          <TableHeaderCell>Barcode ID</TableHeaderCell>
        </TableRow>
      </Header>
      <TableBody>
        {data.map((item, i) => (
          <TableRow key={`expanded_table_${i}`}>
            {hasAreaColumn && <TableCell>{item.area.name}</TableCell>}
            <TableCell>{getRowName(item)}</TableCell>
            <TableCell>{item.rfidTagId}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

TagsTable.propTypes = {
  data: PropTypes.array,
  hasAreaColumn: PropTypes.bool
};

const TagDate = ({ date, tags, hasAreaColumn }) => {
  const [isExpanded, setExpanded] = useState(false);
  return (
    <>
      <TableRow>
        <TableCell>
          <Button
            active={isExpanded}
            onClick={() => setExpanded(!isExpanded)}
            compact
            icon="dropdown"
          />
        </TableCell>
        <TableCell>{date}</TableCell>
        <TableCell>{tags.length}</TableCell>
      </TableRow>
      {isExpanded && (
        <TableRow>
          <TableCell colSpan={9}>
            <TagsTable data={tags} hasAreaColumn={hasAreaColumn} />
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

TagDate.propTypes = {
  date: PropTypes.string,
  tags: PropTypes.array,
  hasAreaColumn: PropTypes.bool
};

export default memo(TagDate);
