import React from "react";
import { Segment, Form, Table } from "semantic-ui-react";
import CollapsibleBlock from "components/CollapsibleBlock";
import { chunk, get } from "lodash";
import { ELEMENTS_LIST, FERTILISER } from "constants/Chemicals/types";
import { useFormikContext, Field } from "formik";
const ElementWeightEditSection = ({ editSectionIndex }) => {
  const { values } = useFormikContext();
  const isFertiliser = get(values.type, "type") === FERTILISER;
  const elementsChunks = chunk(ELEMENTS_LIST, ELEMENTS_LIST.length / 2);

  if (!isFertiliser) {
    return null;
  }

  return (
    <Segment>
      <CollapsibleBlock
        defaultActive={!editSectionIndex || editSectionIndex === 4}
        title={
          values.matter == "LIQUID"
            ? "Element weight (W/V, %)"
            : "Element weight (W/W, %)"
        }
      >
        <Form>
          {elementsChunks.map((chunk, i) => (
            <Form.Group key={i}>
              {chunk.map((element, index) => (
                <React.Fragment key={index}>
                  <Field
                    as={Form.Input}
                    width={2}
                    label={element}
                    onKeyPress={event => {
                      if (!/^[0-9\b.,]+$/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    name={`elements.${element}`}
                  />
                  {index !== chunk.length - 1 && <Form.Field width={1} />}
                </React.Fragment>
              ))}
            </Form.Group>
          ))}
        </Form>
      </CollapsibleBlock>
    </Segment>
  );
};

export default ElementWeightEditSection;
