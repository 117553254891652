import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";
import { bindActionCreators } from "redux";

import { abilitiesSelector } from "../../../../../selectors/user";
import ScanPackedBoxesForm from "./ScanPackedBoxesForm";
import LayoutOverlay from "../../../../../components/LayoutOverlay";
import styles from "./ScanPackedBoxes.module.css";
import { history } from "../../../../../store";
import Loader from "../../../../../components/Loader";
import { getPackedBoxById } from "../../../../../actions/PackedBoxes/packedBoxes";

class ScanPackedBoxes extends Component {
  state = {
    isFetching: false,
    packedBoxes: null,
    saveButtonActive: true
  };

  constructor(props) {
    super(props);
    this.packedBoxForm = React.createRef();
  }

  async componentWillMount() {
    const { match } = this.props;
    const { id } = match.params;
    if (id) {
      this.getPackedBoxById(id);
    } else {
      this.setState({
        isFetching: false
      });
    }
  }

  getPackedBoxById = id => {
    const {
      actions: { getPackedBoxById }
    } = this.props;
    this.setState({
      isFetching: true
    });

    getPackedBoxById(id)
      .then(packedBoxes => {
        this.setState({
          isFetching: false,
          packedBoxes
        });
      })
      .catch(error => {
        if (error.response.status === 404) {
          history.push("/404");
        }
        this.setState({
          isFetching: false
        });
      });
  };

  doSubmitForm = () => {
    if (this.packedBoxForm.current) {
      this.packedBoxForm.current.submit();
    }
  };

  setSaveButtonActivity = active => {
    this.setState({ saveButtonActive: active });
  };

  render() {
    const { route, Can } = this.props;
    const { isFetching, saveButtonActive, packedBoxes } = this.state;

    return (
      <LayoutOverlay
        closeLink={"/harvest"}
        route={route}
        childrenOnly
        footer={
          <div className={styles.buttonsFooter}>
            <Can I="add" a="packed_boxes">
              <Button
                primary
                size="large"
                disabled={!saveButtonActive}
                onClick={this.doSubmitForm}
              >
                Save
              </Button>
            </Can>
          </div>
        }
      >
        {isFetching ? (
          <Loader />
        ) : (
          <ScanPackedBoxesForm
            route={route}
            initialValues={packedBoxes}
            formRef={this.packedBoxForm}
            setSaveButtonActivity={this.setSaveButtonActivity}
          />
        )}
      </LayoutOverlay>
    );
  }
}

ScanPackedBoxes.propTypes = {
  route: PropTypes.object.isRequired,
  packedBox: PropTypes.object,
  actions: PropTypes.object,
  match: PropTypes.object,
  Can: PropTypes.func
};

ScanPackedBoxes.defaultProps = {
  route: { name: "{%name%}" }
};

const mapStateToProps = state => {
  return {
    Can: abilitiesSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getPackedBoxById
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(ScanPackedBoxes);
