import React, { useState } from "react";
import { Button, Popup, Dropdown, Form, Icon } from "semantic-ui-react";
import styles from "./Table.module.css";

export const pageSizeOptions = [10, 20, 50, 100, 200, 300].map(value => ({
  value,
  key: value,
  text: String(value)
}));

const Pagination = ({
  canPreviousPage,
  canNextPage,
  currentPage,
  gotoPage,
  pageCount,
  pageSize,
  previousPage,
  nextPage,
  setPageSize,
  totalElements
}) => {
  const [arrowPosition, setArrowPosition] = useState(true);
  const [secondArrowPosition, setSecondArrowPosition] = useState(true);
  const itemFrom = currentPage * pageSize + 1;
  const itemTo = Math.min(itemFrom + pageSize - 1, totalElements);
  const goToPageOptions = Array.from(Array(pageCount)).map((_, value) => {
    return { value, key: value, text: String(value + 1) };
  });

  return (
    <div className={styles.pagination}>
      <Popup
        hoverable
        trigger={
          <span className={styles.paginationTitle}>
            {itemFrom} - {itemTo} from {totalElements}
          </span>
        }
        content={
          <Form className={styles.paginationPopup}>
            <Form.Field inline className={styles.paginationField}>
              <label className={styles.pageCountLabel}>Show</label>
              <Dropdown
                fluid
                selection
                onClose={() => setArrowPosition(true)}
                onClick={() => {
                  setArrowPosition(!arrowPosition);
                }}
                value={pageSize}
                options={pageSizeOptions}
                className={styles.filterDropdown}
                onChange={(_, { value }) => setPageSize(value)}
                icon={
                  <div className={styles.dateFilterDropDownIconsWrapper}>
                    <Icon
                      className={styles.dateFilterDropDownIcons}
                      name={arrowPosition ? "angle down" : "angle up"}
                    />
                  </div>
                }
              />
            </Form.Field>
            <Form.Field inline className={styles.paginationField}>
              <label className={styles.pageCountLabel}>
                Go to page (1-{pageCount}){" "}
              </label>
              <Dropdown
                fluid
                selection
                onClose={() => setSecondArrowPosition(true)}
                onClick={() => {
                  setSecondArrowPosition(!arrowPosition);
                }}
                value={currentPage}
                options={goToPageOptions}
                className={styles.filterDropdown}
                onChange={(_, { value }) => gotoPage(value)}
                icon={
                  <div className={styles.dateFilterDropDownIconsWrapper}>
                    <Icon
                      className={styles.dateFilterDropDownIcons}
                      name={secondArrowPosition ? "angle down" : "angle up"}
                    />
                  </div>
                }
              />
            </Form.Field>
          </Form>
        }
        position="bottom center"
        basic
      />
      <Button
        icon="angle left"
        className={styles.paginationButton}
        disabled={!canPreviousPage}
        basic
        onClick={previousPage}
      />
      <Button
        icon="angle right"
        className={styles.paginationRightButton}
        disabled={!canNextPage}
        basic
        onClick={nextPage}
      />
    </div>
  );
};

export default Pagination;
