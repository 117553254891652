export const actionTypes = {
  FETCH_REPORT_TYPES_START: "REPORTS/FETCH_REPORT_TYPES_START",
  FETCH_REPORT_TYPES: "REPORTS/FETCH_REPORT_TYPES",
  FETCH_REPORT_TYPES_FAIL: "REPORTS/FETCH_REPORT_TYPES_FAIL",

  FETCH_REPORT_PER_AREA_LIST_START: "REPORTS/FETCH_REPORT_PER_AREA_LIST_START",
  FETCH_REPORT_PER_AREA_LIST: "REPORTS/FETCH_REPORT_PER_AREA_LIST",
  FETCH_REPORT_PER_AREA_LIST_FAIL: "REPORTS/FETCH_REPORT_PER_AREA_FAIL",

  FETCH_REPORT_PER_AREA_START: "REPORTS/FETCH_REPORT_PER_AREA_START",
  FETCH_REPORT_PER_AREA: "REPORTS/FETCH_REPORT_PER_AREA",
  FETCH_REPORT_PER_AREA_FAIL: "REPORTS/FETCH_REPORT_PER_AREA_FAIL",

  SUBMIT_REPORT_START: "REPORTS/SUBMIT_REPORT_START",
  SUBMIT_REPORT: "REPORTS/SUBMIT_REPORT",
  SUBMIT_REPORT_FAIL: "REPORTS/SUBMIT_REPORT_FAIL",
  FETCH_PACKED_BOXES_START: "PACKED_BOXES/FETCH_PACKED_BOXES_START",
  FETCH_PACKED_BOXES: "PACKED_BOXES/FETCH_PACKED_BOXES",
  FETCH_PACKED_BOXES_FAIL: "PACKED_BOXES/FETCH_PACKED_BOXES_FAIL",

  FETCH_PACKED_BOXES_START: "PACKED_BOXES/FETCH_PACKED_BOXES_START",
  FETCH_PACKED_BOXES: "PACKED_BOXES/FETCH_PACKED_BOXES",
  FETCH_PACKED_BOXES_FAIL: "PACKED_BOXES/FETCH_PACKED_BOXES_FAIL",

  FETCH_PACKED_BOXES_START: "PACKED_BOXES/FETCH_PACKED_BOXES_START",
  FETCH_PACKED_BOXES: "PACKED_BOXES/FETCH_PACKED_BOXES",
  FETCH_PACKED_BOXES_FAIL: "PACKED_BOXES/FETCH_PACKED_BOXES_FAIL",

  FETCH_SCANNED_BARCODES_REPORT_START:
    "GENERAL_BARCODES/FETCH_SCANNED_BARCODES_REPORT_START",
  FETCH_SCANNED_BARCODES_REPORT:
    "GENERAL_BARCODES/FETCH_SCANNED_BARCODES_REPORT",
  FETCH_SCANNED_BARCODES_REPORT_FAIL:
    "GENERAL_BARCODES/FETCH_SCANNED_BARCODES_REPORT_FAIL",
  CLEAR_SCANNED_BARCODES_REPORT_ERROR:
    "GENERAL_BARCODES/CLEAR_SCANNED_BARCODES_REPORT_ERROR",

  FETCH_PICKED_CROP_PER_EMPLOYEE_REPORT_START:
    "GENERAL_BARCODES/FETCH_PICKED_CROP_PER_EMPLOYEE_REPORT_START",
  FETCH_PICKED_CROP_PER_EMPLOYEE_REPORT:
    "GENERAL_BARCODES/FETCH_PICKED_CROP_PER_EMPLOYEE_REPORT",
  FETCH_PICKED_CROP_PER_EMPLOYEE_REPORT_FAIL:
    "GENERAL_BARCODES/FETCH_PICKED_CROP_PER_EMPLOYEE_REPORT_FAIL",
  CLEAR_PICKED_CROP_PER_EMPLOYEE_REPORT_ERROR:
    "GENERAL_BARCODES/CLEAR_PICKED_CROP_PER_EMPLOYEE_REPORT_ERROR",

  SET_EMPLOYEE_PICKED_CROPS_FILTERS:
    "REPORTS/SET_EMPLOYEE_PICKED_CROPS_FILTERS",
  TOGGLE_LOADING_BARCODES_BY_EMPLOYEE:
    "REPORTS/SET_LOADING_EMPLOYEE_PICKED_CROPS",
  SET_EMPLOYEE_BARCODES_LIST: "REPORTS/SET_EMPLOYEE_BARCODES_LIST",

  CLEAR_ERROR: "REPORTS/CLEAR_ERROR",
  SET_FILTERS: "REPORTS/SET_FILTERS"
};
