import { area } from "@turf/turf";
import { actionType } from "../../constants/QualityControl";
import { axiosProxy } from "../../utils/axiosProxy";

const url = "/qc_forms";

export const fetchQCFroms = ({
  page,
  size,
  search,
  sort,
  unpaged = false,
  showArchived = false
}) => async (dispatch, getState) => {
  const { areas, employee } = getState();
  try {
    dispatch({
      type: actionType.FETCH_QC_FORMS_START
    });

    const params = new URLSearchParams();
    if (unpaged) {
      params.append("unpaged", "true");
    } else {
      params.append("page", page);
      params.append("size", size);
    }

    search && params.append("search", search);
    showArchived && params.append("showArchived", "true");

    (sort || ["id,asc"]).forEach(field => {
      params.append("sort", field);
    });

    const res = await axiosProxy({
      method: "GET",
      params,
      url
    });
    const newData = {
      ...res.data,
      content: res.data.content.map(item => {
        return {
          ...item,
          completedForms: item.completedForms.map(form => {
            return {
              ...form,
              barcode: {
                ...form.barcode,
                area: areas.list.content.find(
                  area => area.id === form.barcode.areaId
                ),
                employeeBinParts: form.barcode.employeeBinParts.map(
                  employeeBinPart => {
                    return {
                      id: employeeBinPart.id,
                      part: employeeBinPart.part,
                      employee: employee.list.content.find(
                        emp => emp.id === employeeBinPart.employeeId
                      )
                    };
                  }
                ),
                supervisors: form.barcode.supervisors.map(supervisor => {
                  return {
                    id: supervisor.id,
                    employee: employee.list.content.find(
                      emp => emp.id === supervisor.employeeId
                    )
                  };
                })
              }
            };
          })
        };
      })
    };
    await dispatch({
      type: actionType.FETCH_QC_FORMS,
      payload: newData
    });
    return newData;
  } catch (error) {
    dispatch({
      type: actionType.FETCH_QC_FORMS_FAIL,
      payload: error
    });
  }
};

export const addNewQCForm = ({
  name,
  employeeIds,
  fields
}) => async dispatch => {
  try {
    dispatch({
      type: actionType.QC_FORM_CREATE_START
    });

    const res = await axiosProxy({
      method: "POST",
      data: {
        name,
        allowedFor: employeeIds,
        formTemplate: fields
      },
      url
    });

    dispatch({
      type: actionType.QC_FORM_CREATE,
      payload: res
    });

    return res;
  } catch (error) {
    dispatch({
      type: actionType.QC_FORM_CREATE_FAIL,
      payload: error
    });
  }
};

export const updateQCForm = (
  id,
  { name, employeeIds, fields }
) => async dispatch => {
  try {
    dispatch({
      type: actionType.QC_FORM_UPDATE_START
    });

    const res = await axiosProxy({
      method: "PUT",
      data: {
        name,
        allowedFor: employeeIds,
        formTemplate: fields
      },
      url: url + `/${id}`
    });

    dispatch({
      type: actionType.QC_FORM_UPDATE,
      payload: res.data
    });

    return res;
  } catch (error) {
    dispatch({
      type: actionType.QC_FORM_UPDATE_FAIL,
      payload: error
    });
  }
};

export const setFormData = payload => dispatch =>
  dispatch({
    type: actionType.QC_FORM_SET_FORM_SET,
    payload
  });

export const clearFormData = () => dispatch =>
  dispatch({
    type: actionType.QC_FORM_CLEAR
  });

export const handleChange = (name, value) => dispatch => {
  dispatch({
    type: actionType.QC_FORM_CHANGE,
    payload: {
      name,
      value
    }
  });
};

export const addFormInput = () => dispatch =>
  dispatch({
    type: actionType.ADD_FORM_INPUT
  });

export const handleFormInputChange = (id, name, value) => dispatch =>
  dispatch({
    type: actionType.EDIT_FORM_INPUT,
    payload: {
      id,
      name,
      value
    }
  });

export const removeFormInput = id => dispatch =>
  dispatch({
    type: actionType.REMOVE_FORM_INPUT,
    payload: id
  });

export const archiveQCForm = (id, archive = true) => async dispatch => {
  dispatch({
    type: actionType.QC_FORM_ARCHIVE_START
  });

  const params = new URLSearchParams();
  params.append("archive", archive);

  try {
    await axiosProxy({
      method: "PUT",
      url: url + `/${id}/archive`,
      params
    });

    dispatch({
      type: actionType.QC_FORM_ARCHIVE,
      payload: {
        id,
        archive
      }
    });
  } catch (error) {
    dispatch({
      type: actionType.QC_FORM_ARCHIVE_FAIL,
      payload: error
    });
  }
};

export const clearError = () => dispatch =>
  dispatch({ type: actionType.QC_FORM_CLEAR_ERROR });
