export const DEFAULT_LOCATION = [-33.8688, 151.2093];

export const actionType = {
  DASHBOARD_BINS_PER_AREA_PER_EMPLOYEE:
    "DASHBOARD/DASHBOARD_BINS_PER_AREA_EMPLOYEE",
  DASHBOARD_BINS_PER_AREA_PER_EMPLOYEE_START:
    "DASHBOARD/DASHBOARD_BINS_PER_AREA_EMPLOYEE_START",
  DASHBOARD_BINS_PER_AREA_PER_EMPLOYEE_FAIL:
    "DASHBOARD/DASHBOARD_BINS_PER_AREA_EMPLOYEE_FAIL",

  DASHBOARD_BINS_PER_AREA: "DASHBOARD/DASHBOARD_BINS_PER_AREA"
};
