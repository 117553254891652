import PropTypes from "prop-types";
import React, { useEffect } from "react";
import SegmentClosableControlled from "../../../../../components/SegmentClosableControlled";
import { Dropdown, Form, Grid, Input, TextArea } from "semantic-ui-react";
import TaskStepFooter from "../TaskStepFooter";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTaskPickingTypes,
  setField
} from "../../../../../actions/GeneralTaskCreator/taskCreator";
import { range } from "lodash";
import SelectHarvestUnit from "../../../../../components/SelectHarvestUnit";
import Review from "./Review";

const roundOptions = range(1, 21).map(value => ({
  id: value,
  text: value,
  value
}));

export const ShowField = ({ allowedFields, fieldName, children }) =>
  allowedFields.includes(fieldName) ? <>{children}</> : <></>;

const SwitchableStep = ({
  isOpen,
  taskTypeID,
  allowedFields,
  mandatoryFields,
  title,
  onArrowClick
}) => {
  const taskCreator = useSelector(state => state.taskCreator);
  const {
    isFetching: isPickingTypesFetching,
    content: pickingTypes
  } = taskCreator.taskPickingTypes;
  const data = taskCreator.taskStages.switchable;
  const subTypes = taskCreator.taskTypes.content.find(
    type => +type.id === +taskTypeID
  ).taskSubTypes;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTaskPickingTypes());
  }, []);

  const pickingTypesOptions = pickingTypes.map(type => ({
    id: type.id,
    text: type.name,
    value: type.id
  }));
  const subTypesOptions = subTypes.map(type => ({
    id: type.id,
    text: type.name,
    value: type.id
  }));

  const setFieldValue = (fieldName, fieldValue) =>
    dispatch(setField("switchable", fieldName, fieldValue, mandatoryFields));

  const flowRate =
    data.flowRate && (data.runningTimeHours || data.runningTimeMinutes)
      ? (
          data.flowRate *
          (data.runningTimeHours + data.runningTimeMinutes / 60)
        ).toFixed(2)
      : 0;

  return (
    <SegmentClosableControlled
      onArrowClick={onArrowClick}
      title={title}
      open={isOpen}
    >
      {isOpen && (
        <>
          <Grid>
            <Grid.Row>
              <Grid.Column largeScreen={2}>
                <Form.Field
                  required={mandatoryFields.includes("ROUND")}
                  disabled={!allowedFields.includes("ROUND")}
                >
                  <label>Round</label>
                  <Dropdown
                    disabled={!allowedFields.includes("ROUND")}
                    name="round"
                    closeOnChange
                    allowAdditions
                    fluid
                    selection
                    onChange={(_, data) => setFieldValue("round", data.value)}
                    value={data.round}
                    options={roundOptions}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column largeScreen={4}>
                <Form.Field
                  required={mandatoryFields.includes("SUBTYPE")}
                  disabled={!allowedFields.includes("SUBTYPE")}
                >
                  <label>Type</label>
                  <Dropdown
                    disabled={!allowedFields.includes("SUBTYPE")}
                    name="type"
                    closeOnChange
                    fluid
                    clearable
                    selection
                    onChange={(_, data) => setFieldValue("subType", data.value)}
                    value={data.subType}
                    options={subTypesOptions}
                  />
                </Form.Field>
              </Grid.Column>
              <ShowField
                allowedFields={allowedFields}
                fieldName={"RUNNING_TIME_MIN"}
              >
                <Grid.Column largeScreen={4}>
                  <Form.Field
                    required={mandatoryFields.includes("RUNNING_TIME_MIN")}
                    disabled={!allowedFields.includes("RUNNING_TIME_MIN")}
                  >
                    <label>Running Time</label>
                    <Input
                      style={{ width: "45%", float: "left", marginRight: 10 }}
                      fluid
                      disabled={!allowedFields.includes("RUNNING_TIME_MIN")}
                      name="runningTimeMin"
                      size="large"
                      type="number"
                      maxLength={5}
                      value={data.runningTimeHours}
                      onChange={(_, data) => {
                        setFieldValue("runningTimeHours", +data.value);
                      }}
                      label="hrs"
                      labelPosition="right"
                    />
                    <Input
                      style={{ width: "45%", float: "left" }}
                      fluid
                      disabled={!allowedFields.includes("RUNNING_TIME_MIN")}
                      name="runningTimeMin"
                      size="large"
                      type="number"
                      maxLength={5}
                      value={data.runningTimeMinutes}
                      onChange={(_, data) => {
                        setFieldValue("runningTimeMinutes", +data.value);
                      }}
                      label="mins"
                      labelPosition="right"
                    />
                  </Form.Field>
                </Grid.Column>
              </ShowField>
              <ShowField allowedFields={allowedFields} fieldName={"FLOW_RATE"}>
                <Grid.Column largeScreen={4}>
                  <Form.Field
                    required={mandatoryFields.includes("FLOW_RATE")}
                    disabled={!allowedFields.includes("FLOW_RATE")}
                  >
                    <label>Flow rate (L/Hr)</label>
                    <Input
                      fluid
                      disabled={!allowedFields.includes("FLOW_RATE")}
                      name="flowRate"
                      size="large"
                      type="number"
                      maxLength={5}
                      value={data.flowRate}
                      onChange={(_, data) =>
                        setFieldValue("flowRate", data.value)
                      }
                    />
                  </Form.Field>
                </Grid.Column>
              </ShowField>
              <ShowField
                allowedFields={allowedFields}
                fieldName={"TOTAL_IRRIGATION_WATER"}
              >
                <Grid.Column largeScreen={4}>
                  <Form.Field
                    required={mandatoryFields.includes(
                      "TOTAL_IRRIGATION_WATER"
                    )}
                    disabled={!allowedFields.includes("TOTAL_IRRIGATION_WATER")}
                  >
                    <label>Total Irrigation Water (L)</label>
                    <Input
                      fluid
                      disabled
                      name="totalIrrigationWater"
                      size="large"
                      type="number"
                      maxLength={5}
                      value={flowRate}
                      onChange={(_, data) =>
                        setFieldValue("totalIrrigationWater", data.value)
                      }
                    />
                  </Form.Field>
                </Grid.Column>
              </ShowField>
              <Grid.Column largeScreen={4}>
                <ShowField
                  allowedFields={allowedFields}
                  fieldName={"HARVEST_UNIT_PICKING_TYPE"}
                >
                  <Form.Field
                    required={mandatoryFields.includes(
                      "HARVEST_UNIT_PICKING_TYPE"
                    )}
                    disabled={
                      !allowedFields.includes("HARVEST_UNIT_PICKING_TYPE")
                    }
                  >
                    <label>Picking Type</label>
                    <Dropdown
                      disabled={
                        !allowedFields.includes("HARVEST_UNIT_PICKING_TYPE")
                      }
                      name="pickingType"
                      closeOnChange
                      fluid
                      selection
                      loading={isPickingTypesFetching}
                      onChange={(_, data) => {
                        setFieldValue("pickingType", data.value);
                      }}
                      value={data.pickingType}
                      options={pickingTypesOptions}
                    />
                  </Form.Field>
                </ShowField>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column largeScreen={4}>
                <ShowField
                  allowedFields={allowedFields}
                  fieldName={"HARVEST_UNIT"}
                >
                  <Form.Field
                    required={mandatoryFields.includes("HARVEST_UNIT")}
                    disabled={!allowedFields.includes("HARVEST_UNIT")}
                  >
                    <label>Harvest unit</label>
                    <SelectHarvestUnit
                      name="harvestUnit"
                      closeOnChange
                      disabled={!allowedFields.includes("HARVEST_UNIT")}
                      fluid
                      native
                      selection
                      onChange={(_, data) =>
                        setFieldValue("harvestUnitId", data.value)
                      }
                      value={data.harvestUnitId}
                    />
                  </Form.Field>
                </ShowField>
              </Grid.Column>
              <Grid.Column largeScreen={4}>
                <ShowField
                  allowedFields={allowedFields}
                  fieldName={"UNIT_PRICE"}
                >
                  <Form.Field
                    required={mandatoryFields.includes("UNIT_PRICE")}
                    disabled={!allowedFields.includes("UNIT_PRICE")}
                  >
                    <label>Unit price</label>
                    <Input
                      fluid
                      disabled={!allowedFields.includes("UNIT_PRICE")}
                      name="unitPrice"
                      size="large"
                      type="number"
                      maxLength={5}
                      value={data.unitPrice}
                      onChange={(_, data) =>
                        setFieldValue("unitPrice", data.value)
                      }
                    />
                  </Form.Field>
                </ShowField>
              </Grid.Column>
              <Grid.Column largeScreen={3}>
                <ShowField allowedFields={allowedFields} fieldName={"SIZE"}>
                  <Form.Field
                    required={mandatoryFields.includes("SIZE")}
                    disabled={!allowedFields.includes("SIZE")}
                  >
                    <label>Size</label>
                    <Input
                      disabled={!allowedFields.includes("SIZE")}
                      value={data.size}
                      onChange={(_, data) => setFieldValue("size", data.value)}
                      maxLength={5}
                      fluid
                      type={"text"}
                    />
                  </Form.Field>
                </ShowField>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column widescreen={5} largeScreen={5} computer={5}>
                <Form.Field
                  required={mandatoryFields.includes("COMMENTS")}
                  disabled={!allowedFields.includes("COMMENTS")}
                >
                  <label>Comments</label>
                  <TextArea
                    disabled={!allowedFields.includes("COMMENTS")}
                    value={data.comments}
                    name={"comments"}
                    maxLength={100}
                    style={{ width: "100%" }}
                    rows={5}
                    onChange={(_, data) =>
                      setFieldValue("comments", data.value)
                    }
                  />
                </Form.Field>
              </Grid.Column>
              <ShowField
                allowedFields={allowedFields}
                fieldName={"TIME_BUDGETING"}
              >
                <Grid.Column widescreen={3} largeScreen={3} computer={3}>
                  <Form.Field
                    required={mandatoryFields.includes("TIME_BUDGETING")}
                    disabled={!allowedFields.includes("TIME_BUDGETING")}
                  >
                    <label>Time Budgeting</label>
                    <Input
                      fluid
                      disabled={!allowedFields.includes("TIME_BUDGETING")}
                      name="timeBudgeting"
                      size="large"
                      type="number"
                      value={data.timeBudgeting}
                      onChange={(_, data) =>
                        setFieldValue("timeBudgeting", data.value)
                      }
                      label="mins"
                      labelPosition="right"
                    />
                  </Form.Field>
                </Grid.Column>
              </ShowField>
              <ShowField
                allowedFields={allowedFields}
                fieldName={"TIME_BUDGETING_PER_ROW"}
              >
                <Grid.Column widescreen={3} largeScreen={3} computer={3}>
                  <Form.Field
                    required={mandatoryFields.includes(
                      "TIME_BUDGETING_PER_ROW"
                    )}
                    disabled={!allowedFields.includes("TIME_BUDGETING_PER_ROW")}
                  >
                    <label>Per Row</label>
                    <Input
                      fluid
                      disabled={
                        !allowedFields.includes("TIME_BUDGETING_PER_ROW")
                      }
                      name="perRow"
                      size="large"
                      type="number"
                      value={data.perRow}
                      onChange={(_, data) =>
                        setFieldValue("perRow", data.value)
                      }
                      label="mins"
                      labelPosition="right"
                    />
                  </Form.Field>
                </Grid.Column>
              </ShowField>
              <ShowField
                allowedFields={allowedFields}
                fieldName={"TIME_BUDGETING_PER_PLANT"}
              >
                <Grid.Column widescreen={3} largeScreen={3} computer={3}>
                  <Form.Field
                    required={mandatoryFields.includes(
                      "TIME_BUDGETING_PER_PLANT"
                    )}
                    disabled={
                      !allowedFields.includes("TIME_BUDGETING_PER_PLANT")
                    }
                  >
                    <label>Per Plant</label>
                    <Input
                      fluid
                      disabled={
                        !allowedFields.includes("TIME_BUDGETING_PER_PLANT")
                      }
                      name="perPlant"
                      size="large"
                      type="number"
                      value={data.perPlant}
                      onChange={(_, data) =>
                        setFieldValue("perPlant", data.value)
                      }
                      label="mins"
                      labelPosition="right"
                    />
                  </Form.Field>
                </Grid.Column>
              </ShowField>
            </Grid.Row>
          </Grid>
          <TaskStepFooter isValid={true} />
        </>
      )}
      {isOpen || (
        <Review
          switchStep={data}
          allowedFields={allowedFields}
          taskTypeID={taskTypeID}
          pickingTypes={pickingTypes}
        />
      )}
    </SegmentClosableControlled>
  );
};

SwitchableStep.propTypes = {
  allowedFields: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  mandatoryFields: PropTypes.array.isRequired,
  onArrowClick: PropTypes.func.isRequired,
  taskTypeID: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired
};

ShowField.propTypes = {
  allowedFields: PropTypes.array.isRequired,
  children: PropTypes.element.isRequired,
  fieldName: PropTypes.string.isRequired
};

export default SwitchableStep;
