import React from "react";
import moment from "moment";
import BasicCell from "components/Table/components/BasicCell";
import { get } from "lodash";
import { matterToUnits } from "../utils/constToUnits";
import Numeric from "components/Numeric/Numeric";

export const getPurchaseOrderColumns = (
  chemicalsList,
  shedList,
  activeItem,
  typeSelector
) => {
  const columnsConfig = {
    chemicalIds: {
      accessor: ({ chemicalId }) => {
        return get(
          chemicalsList.find(e => e.key === chemicalId),
          "text"
        );
      },
      title: "Products",
      withSort: true,
      cellWidth: 2
    },
    manufacturer: {
      title: "Manufacturer",
      accessor: ({ manufacturer }) => {
        return manufacturer ? manufacturer : "-";
      },
      withSort: true,
      cellWidth: 2
    },
    batchNumber: {
      title: "Batch number",
      withSort: false,
      accessor: ({ quantities }) => {
        return get(
          quantities.find(q => q),
          "batchNumber"
        );
      },
      cellWidth: 2
    },
    type: {
      title: "Type",
      accessor: ({ chemicalId }) => {
        return get(
          typeSelector.find(t => t.id === chemicalId),
          "type"
        );
      },
      withSort: false,
      cellWidth: 2
    },
    unitsNumber: {
      title: "No. of drums",
      accessor: ({ quantities }) => {
        return get(
          quantities.find(q => q),
          "unitsNumber"
        );
      },
      withSort: false,
      cellWidth: 2
    },
    unitSize: {
      title: "Drums",
      accessor: ({ quantities, chemicalId }) => {
        return `${get(
          quantities.find(q => q),
          "unitSize"
        )} ${matterToUnits(
          get(
            typeSelector.find(item => item.id == chemicalId && item),
            "matter"
          )
        )} Drum`;
      },
      withSort: false,
      cellWidth: 2
    },

    totalQuantity: {
      title: "Total QTY",
      accessor: ({ quantities, chemicalId }) => {
        return (
          <Numeric
            fractionDigits={
              quantities.reduce(
                (prev, curr) => prev + +curr.unitsNumber * curr.unitSize,
                0
              ) > 1000
                ? 0
                : 2
            }
            value={quantities.reduce(
              (prev, curr) => prev + +curr.unitsNumber * curr.unitSize,
              0
            )}
            commaSeparatorOnThousands
            units={matterToUnits(
              get(
                typeSelector.find(item => item.id == chemicalId && item),
                "matter"
              )
            )}
            unitsPosition="right"
            defaultValue="-"
          />
        );
      },
      withSort: false,
      cellWidth: 2
    },
    price: {
      title: "Price ($/unit)",

      accessor: ({ chemicalId, totalCost, quantitiesList, products }) => {
        const quantitiesTotalPrice = quantitiesList.reduce(
          (prev, curr) => prev + +curr.unitsNumber * curr.unitSize,
          0
        );
        return (
          <Numeric
            fractionDigits={totalCost / quantitiesTotalPrice > 1000 ? 0 : 2}
            value={totalCost / quantitiesTotalPrice}
            commaSeparatorOnThousands
            units={`/ ${matterToUnits(
              get(
                typeSelector.find(item => item.id == chemicalId && item),
                "matter"
              )
            )} `}
            unitsPosition="right"
            defaultValue="-"
          />
        );
      },
      withSort: false,
      cellWidth: 2
    },
    manufacturerDate: {
      title: "Manuf. date",
      accessor: ({ quantities }) => {
        return get(
          quantities.find(q => q),
          "manufacturerDate"
        )
          ? moment(
              get(
                quantities.find(q => q),
                "manufacturerDate"
              )
            ).format("DD/MM/YYYY")
          : "-";
      },
      withSort: false,
      cellWidth: 2
    },
    expirationDate: {
      title: "Exp. date",
      accessor: ({ quantities }) => {
        return get(
          quantities.find(q => q),
          "expirationDate"
        )
          ? moment(
              get(
                quantities.find(q => q),
                "expirationDate"
              )
            ).format("DD/MM/YYYY")
          : "-";
      },
      withSort: false,
      cellWidth: 2
    },
    shedId: {
      title: "Shed",
      accessor: ({ quantities }) => {
        return get(
          shedList.options.find(
            s =>
              s.key ===
              get(
                quantities.find(item => item),
                "shedId"
              )
          ),
          "label"
        );
      },
      withSort: false,
      cellWidth: 2
    },
    totalCost: {
      title: "Total price ($)",
      accessor: ({ totalCost }) => {
        return (
          <Numeric
            fractionDigits={totalCost > 1000 ? 0 : 2}
            value={totalCost}
            commaSeparatorOnThousands
            units="$ "
            unitsPosition="left"
            defaultValue="-"
          />
        );
      },
      withSort: false,
      cellWidth: 2
    }
  };

  return Object.entries(columnsConfig).map(([id, targetColumn]) => ({
    id,
    ...targetColumn,
    accessor: targetColumn.accessor || id,
    Header: targetColumn.title,
    Cell: targetColumn.Cell || BasicCell,
    ...(targetColumn.sortFields ? { sortFields: targetColumn.sortFields } : {}),
    ...(targetColumn.filter ? { filter: targetColumn.filter } : {})
  }));
};
