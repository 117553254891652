import { combineReducers } from "redux";
import { harvestRootReducer } from "./harvest";
import reportsReducerNeedToRefactor from "./reportsReducerNeedToRefactor";
import nutrient from "./sheds/nutrient";
import productType from "./sheds/productType";
import productUsage from "./sheds/productUsage";

export const reportsRootReducer = combineReducers({
  harvest: harvestRootReducer,
  reportsReducerNeedToRefactor,
  shedReportNutrient: nutrient,
  shedReportProductType: productType,
  shedReportProductUsage: productUsage
});
