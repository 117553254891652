import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import {
  useBlockLayout,
  useExpanded,
  useFilters,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable
} from "react-table";
import PropTypes from "prop-types";

import styles from "./ExpandedTable.module.css";
import { DefaultColumnFilter, EditableCell, filterTypes } from "./filters";
import { Checkbox, Icon } from "semantic-ui-react";
import Loader from "../Loader";

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef(null);
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <Checkbox type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

IndeterminateCheckbox.propTypes = {
  indeterminate: PropTypes.any
};

const ExpandedTable = ({
  columns,
  className,
  data: originalData,
  pagination,
  defaultPageSize,
  sortBy,
  dynamicGrouping,
  showFilters,
  withRowSelect,
  groupBy: initialGroupBy,
  defaultExpandedLevel,
  filters,
  loading,
  noDataText,
  onSelectedRowsChanged,
  onRowsChanged
}) => {
  const skipPageResetRef = useRef(false);
  const [data, setData] = useState(originalData);

  useEffect(() => setData(originalData), [originalData]);

  useEffect(() => {
    skipPageResetRef.current = false;
  }, [data]);

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnID and new value to update the
  // original data
  const updateMyData = useCallback((rowIndex, columnID, value) => {
    // We also turn on the flag to not reset the page
    skipPageResetRef.current = true;
    setData(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            [columnID]: value
          };
        }
        return row;
      })
    );
  }, []);

  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
      Cell: EditableCell
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageCount,
    gotoPage,
    setPageSize,
    toggleSortBy,
    rowsById,
    toggleRowExpanded,
    setAllFilters,
    state: {
      pageIndex,
      pageSize,
      //groupBy, expanded, sortBy: sortBy1, filters: filter1
      selectedRowIds
    }
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      // nestExpandedRows: true,
      initialState: {
        pageIndex: 0,
        pageSize: defaultPageSize,
        sortBy,
        groupBy: initialGroupBy,
        filters
      },
      // updateMyData isn't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer!
      updateMyData,
      // We also need to pass this so the page doesn't change
      // when we edit the data
      disablePageResetOnDataChange: skipPageResetRef.current
    },
    useFilters,
    // useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useBlockLayout,
    useResizeColumns,
    useRowSelect,
    hooks => {
      withRowSelect &&
        hooks.visibleColumns.push(columns => [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
            width: "fit-content",
            disableResizing: true
          },
          ...columns
        ]);
    }
  );

  useEffect(() => {
    sortBy &&
      sortBy.forEach(sort =>
        toggleSortBy(sort.id, sort.desc, sortBy.length > 1)
      );
  }, [sortBy, toggleSortBy]);

  useEffect(() => setAllFilters(filters), [filters, setAllFilters]);

  useEffect(() => {
    return onSelectedRowsChanged(
      Object.keys(selectedRowIds).map(id => rowsById[id].original)
    );
    //eslint-disable-next-line
  }, [onSelectedRowsChanged, selectedRowIds]);

  useEffect(() => {
    defaultExpandedLevel &&
      rowsById &&
      Object.values(rowsById).forEach(row => {
        return (
          row.depth < defaultExpandedLevel && toggleRowExpanded(row.id, true)
        );
      });
    //eslint-disable-next-line
  }, [defaultExpandedLevel, rowsById, originalData, pageIndex]);

  useEffect(() => {
    onRowsChanged({ headerGroups, rows, prepareRow });
  }, [headerGroups, rows, prepareRow, onRowsChanged]);

  const showMessage = loading || originalData.length === 0;
  return (
    <>
      <div
        className={`${className} ExpandedTable ${
          showMessage ? "messageShow" : ""
        }`}
      >
        <div {...getTableProps()} className="rt-table-holder">
          <div {...getTableProps()} className="rt-table" role="grid">
            <div className="rt-thead -header">
              {headerGroups.map(headerGroup => (
                <div
                  key={headerGroup.headers.reduce(
                    (s, header) => `${s}_${header.id}`,
                    ""
                  )}
                  {...headerGroup.getHeaderGroupProps()}
                  className="rt-tr -headerGroups"
                >
                  {headerGroup.headers.map(column => (
                    <div
                      key={column.id}
                      {...column.getHeaderProps()}
                      className={`rt-th`}
                    >
                      <div>
                        {dynamicGrouping && column.canGroupBy ? (
                          <span {...column.getGroupByToggleProps()}>
                            <Icon
                              className={`object outline ${
                                column.isGrouped ? "ungroup" : "group"
                              }`}
                            />
                          </span>
                        ) : null}
                        <span>{column.render("Header")}</span>
                      </div>
                      <div>
                        {showFilters && column.canFilter
                          ? column.render("Filter")
                          : null}
                      </div>
                      {column.getResizerProps && (
                        <div
                          {...column.getResizerProps()}
                          className={`resizer ${styles.resizer} ${
                            column.isResizing ? "isResizing" : ""
                          }`}
                        />
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>
            <div {...getTableBodyProps()} className="rt-tbody">
              {showMessage ? (
                <div className={styles.messageHolder}>
                  {loading ? (
                    <span>
                      <Loader /> Loading...
                    </span>
                  ) : (
                    <span>{noDataText}</span>
                  )}
                </div>
              ) : (
                page.map(
                  (row, index) =>
                    prepareRow(row) || (
                      <div className="rt-tr-group">
                        <div
                          {...row.getRowProps()}
                          className={`rt-tr ${
                            index % 2 === 0 ? "-even" : "-odd"
                          } ${
                            row.original && row.original.error ? "error" : ""
                          }`}
                          role="row"
                        >
                          {row.cells.map(cell => (
                            <div
                              key={`cell_${cell.row.id}_${cell.column.id}`}
                              {...cell.getCellProps()}
                              className={`rt-td ${
                                cell.column.girdColumnsWidth
                                  ? `cell_${cell.column.girdColumnsWidth}`
                                  : ""
                              }`}
                            >
                              {cell.isGrouped ? (
                                <div className={styles.groupedCell}>
                                  <span {...row.getToggleRowExpandedProps()}>
                                    <Icon
                                      className={`square outline ${
                                        row.isExpanded ? "minus" : "plus"
                                      }`}
                                    />
                                  </span>
                                  {cell.render("Cell", { editable: false })}
                                  {row.subRows.length > 1 && (
                                    <span className={styles.groupNumberOfItems}>
                                      {" "}
                                      ({row.subRows.length})
                                    </span>
                                  )}
                                </div>
                              ) : cell.isAggregated ? (
                                cell.render("Aggregated")
                              ) : cell.isPlaceholder ? null : (
                                cell.render("Cell", { editable: true })
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    )
                )
              )}
            </div>
          </div>
        </div>
        {pagination && (
          <div className="pagination">
            {loading
              ? pagination({}, setPageSize, gotoPage)
              : pagination(
                  {
                    pageSize: pageSize,
                    pages: pageCount,
                    currentPage: pageIndex,
                    itemsCount: {
                      itemFrom:
                        rows.length === 0 ? 0 : pageSize * pageIndex + 1,
                      itemTo: Math.min(rows.length, pageSize * (pageIndex + 1))
                    },
                    totalElements: rows.length
                  },
                  setPageSize,
                  gotoPage
                )}
          </div>
        )}
        {/*<pre>*/}
        {/*  <code>*/}
        {/*    {JSON.stringify(*/}
        {/*      {*/}
        {/*        pageIndex,*/}
        {/*        pageSize,*/}
        {/*        pageCount,*/}
        {/*        groupBy,*/}
        {/*        expanded,*/}
        {/*        filter1,*/}
        {/*        sortBy1,*/}
        {/*        // selectedRowPaths,*/}
        {/*      },*/}
        {/*      null,*/}
        {/*      2,*/}
        {/*    )}*/}
        {/*  </code>*/}
        {/*</pre>*/}
      </div>
    </>
  );
};

ExpandedTable.propTypes = {
  columns: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  getData: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  searchColumn: PropTypes.string,
  pagination: PropTypes.object,
  row: PropTypes.object,
  withDateRange: PropTypes.bool,
  withRowSelect: PropTypes.bool,
  exportableToExcel: PropTypes.bool,
  sortBy: PropTypes.array.isRequired,
  dynamicGrouping: PropTypes.bool,
  showFilters: PropTypes.bool,
  className: PropTypes.any,
  groupBy: PropTypes.any,
  defaultExpandedLevel: PropTypes.number,
  filters: PropTypes.array,
  loading: PropTypes.bool,
  noDataText: PropTypes.string,
  onSelectedRowsChanged: PropTypes.func.isRequired,
  onRowsChanged: PropTypes.func,
  getToggleAllRowsSelectedProps: PropTypes.func,
  defaultPageSize: PropTypes.number
};

ExpandedTable.defaultProps = {
  withDateRange: true,
  exportableToExcel: false,
  isFetching: false,
  getData: () => {},
  onSelectedRowsChanged: () => {},
  sortBy: [],
  groupBy: [],
  defaultExpandedLevel: 0,
  filters: [],
  dynamicGrouping: false,
  loading: false,
  withRowSelect: false,
  defaultPageSize: 10,
  onRowsChanged: () => {}
};

export default ExpandedTable;
