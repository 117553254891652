import React, { useState } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import { useFormikContext } from "formik";
import CollapsibleBlock from "components/CollapsibleBlock";
import { Button } from "semantic-ui-react";
import { Container, Grid, Segment, Confirm } from "semantic-ui-react";
import { productTypeSelector } from "../../../../../../../selectors/chemicals";
import { isEmpty } from "lodash";
import { matterToUnits } from "utils/constToUnits";
import InventoryForm from "./InventoryForm";
import InventoryRow from "./InventoryRow";
import { changeOperation } from "actions/Sheds";
import styles from "./Inventory.module.css";

const Inventory = ({ shed, sheds, productList }) => {
  const dispatch = useDispatch();
  const { values, setFieldValue, validateForm } = useFormikContext();
  const [addButtonCheck, setAddButtonCheck] = useState(true);
  const [deleteButtonCheck, setDeleteButtonCheck] = useState(false);
  const inventoryListCheck = values.buckets?.length;
  const [secondOpen, setSecondOpen] = useState(false);
  const productType = useSelector(productTypeSelector);

  const onNewInventoryAdd = bucket => {
    let buckets = [];
    if (values.buckets) {
      buckets = [...values.buckets];
      const bucketIndex = buckets.findIndex(
        b => b.price == bucket.price && b.chemicalId === bucket.chemicalId
      );
      if (bucketIndex > -1) {
        buckets[bucketIndex] = {
          ...buckets[bucketIndex],
          quantity: +buckets[bucketIndex].quantity + +bucket.quantity
        };
      } else {
        buckets = [...buckets, bucket];
      }
    } else {
      buckets.push(bucket);
    }
    setFieldValue("buckets", buckets);
    validateForm();
    setAddButtonCheck(true);

    if (shed) {
      const { name, totalPrice, createdAt, ...data } = bucket;
      dispatch(changeOperation({ ...data, shedId: shed.id }));
    }
  };

  const addProduct = () => {
    setAddButtonCheck(false);
    setDeleteButtonCheck(true);
  };

  const deleteAddingForm = () => {
    setAddButtonCheck(true);
  };
  const groupedProducts = Object.values(
    values.buckets.reduce(
      (
        groupedProducts,
        { price, quantity, chemicalId, name, matter, createdAt },
        _,
        initialBuckets
      ) => {
        const currentGroup = groupedProducts[chemicalId];

        return {
          ...groupedProducts,
          [chemicalId]: {
            chemicalId,
            productName: name,
            initialPrice: initialBuckets[initialBuckets.length - 1].price,
            createdAt,
            unit: matterToUnits(matter),
            ...(currentGroup
              ? {
                  expectedInventory:
                    Number(quantity) + currentGroup.expectedInventory,
                  price: (
                    (Number(price) * Number(quantity) +
                      currentGroup.price * currentGroup.expectedInventory) /
                    (currentGroup.expectedInventory + Number(quantity))
                  ).toFixed(2),
                  buckets: [
                    ...groupedProducts[chemicalId].buckets,
                    {
                      price,
                      quantity,
                      createdAt
                    }
                  ]
                }
              : {
                  expectedInventory: Number(quantity),
                  price: Number(price),
                  buckets: [
                    {
                      price,
                      quantity,
                      createdAt
                    }
                  ]
                })
          }
        };
      },
      {}
    )
  );

  return (
    <Segment>
      <Confirm
        className={styles.confirmModal}
        content="Are you sure you want to ..."
        open={secondOpen}
        onCancel={() => setSecondOpen(false)}
        onConfirm={() => deleteAddingForm()}
      />
      <CollapsibleBlock title="Inventory" className={styles.root}>
        {!isEmpty(groupedProducts) && (
          <Grid as={Container}>
            <Grid.Column className={styles.rowLabel}>Product</Grid.Column>
            <Grid.Column className={styles.rowLabel}></Grid.Column>
            <Grid.Column className={styles.rowLabel}>
              Price ($/unit)
            </Grid.Column>
            <Grid.Column className={styles.rowLabel}></Grid.Column>
            <Grid.Column className={styles.rowLabel}>
              Expected inventory
            </Grid.Column>
            <Grid.Column className={styles.rowLabel}></Grid.Column>
            {groupedProducts
              .sort(function(a, b) {
                var nameA = a.productName?.toLowerCase(),
                  nameB = b.productName?.toLowerCase();
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
              })
              .map(product => (
                <Grid.Row className={styles.rowContainer}>
                  <InventoryRow
                    productType={productType}
                    productList={productList}
                    sheds={sheds}
                    shed={shed}
                    key={product.id}
                    inventoryData={product}
                  />
                </Grid.Row>
              ))}
          </Grid>
        )}
        {inventoryListCheck && addButtonCheck ? (
          <div className={styles.buttonContainer}>
            <Button
              type="submit"
              className="button-text color-green"
              onClick={addProduct}
            >
              + Add a Product
            </Button>
          </div>
        ) : null}
        {(!addButtonCheck || !inventoryListCheck) && (
          <Segment>
            <InventoryForm
              inventoryListCheck={inventoryListCheck}
              shed={shed}
              deleteAddingForm={deleteAddingForm}
              deleteButtonCheck={deleteButtonCheck}
              onSubmit={onNewInventoryAdd}
            />
          </Segment>
        )}
      </CollapsibleBlock>
    </Segment>
  );
};
export default connect((state, props) => {
  return {
    productList: state.chemical.list.content
  };
})(Inventory);
