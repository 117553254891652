import { actionType } from "constants/Sheds";
import { axiosProxy } from "utils/axiosProxy";
import { omitBy, isNil } from "lodash";
import uuid from "uuid/v4";
const url = "/sheds";
let uniqId;

export const fetchUnits = () => async dispatch => {
  try {
    dispatch({ type: actionType.FETCH_UNITS_START });

    const res = await axiosProxy({
      method: "GET",
      url: `${url}/units`
    });
    dispatch({ type: actionType.SET_UNITS, payload: res.data });
  } catch (e) {
    dispatch({ type: actionType.SET_UNITS, payload: [] });
  }
};

export const fetchListSheds = (
  withoutArchivedSheds,
  filtersList
) => async dispatch => {
  try {
    dispatch({ type: actionType.FETCH_SHEDS_LIST_START });
    const paramsObject = withoutArchivedSheds
      ? {
          unpaged: true,
          archived: false
        }
      : {
          unpaged: true
        };
    const params = new URLSearchParams(paramsObject);
    if (filtersList && Array.isArray(filtersList.filters)) {
      filtersList.filters.forEach(([filter, value]) =>
        params.append(filter, value)
      );
    }
    const res = await axiosProxy({
      method: "GET",
      params,
      url
    });
    await dispatch({
      type: actionType.FETCH_SHEDS_LIST,
      payload: res.data
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.FETCH_SHEDS_LIST_ERROR,
      payload: error
    });
  }
};
export const fetchSheds = ({
  archived,
  page,
  size,
  search,
  sort,
  unpaged = false,
  filters
}) => async dispatch => {
  try {
    dispatch({ type: actionType.FETCH_SHEDS_START });

    const paramsObject = omitBy(
      {
        archived,
        page,
        size,
        search,
        sort,
        unpaged
      },
      isNil
    );
    const params = new URLSearchParams(paramsObject);
    !sort.length && params.append("sort", ["name,asc"]);

    if (Array.isArray(filters)) {
      filters.forEach(([filter, value]) => params.append(filter, value));
    }
    uniqId = uuid();
    const res = await axiosProxy({
      method: "GET",
      params,
      uniqId,
      url
    });
    if (uniqId === res.config.headers["X-REQUEST-ID"]) {
      await dispatch({
        type: actionType.FETCH_SHEDS,
        payload: res.data
      });
    }
    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.FETCH_SHEDS_ERROR,
      payload: error
    });
  }
};

export const updateShed = (id, data) => async dispatch => {
  dispatch({
    type: actionType.UPDATE_SHED_START
  });
  try {
    const res = await axiosProxy({
      method: "PUT",
      url: `${url}/${id}`,
      data
    });

    await dispatch({
      type: actionType.UPDATE_SHED,
      payload: res.data
    });
    dispatch(getOperationsList(id));
    return res.data;
  } catch (error) {
    await dispatch({
      type: actionType.UPDATE_SHED_FAIL,
      payload: error
    });
  }
};
export const getOperations = shedIds => async dispatch => {
  await dispatch({
    type: actionType.PENDING_OPERATIONS_START,
    payload: shedIds
  });
  try {
    const params = new URLSearchParams({
      status: "PENDING",
      shedIds
    });

    const res = await axiosProxy({
      method: "GET",
      params,
      url: `${url}/operations`
    });

    await dispatch({
      type: actionType.PENDING_OPERATIONS,
      payload: {
        shedId: shedIds,
        pendingOperations: res.data.content
      }
    });
  } catch (error) {}
};
export const getOperationsList = shedIds => async dispatch => {
  await dispatch({
    type: actionType.PENDING_OPERATIONS_START,
    payload: shedIds
  });
  try {
    const params = new URLSearchParams({
      status: "PENDING",
      shedIds,
      unpaged: true
    });

    const res = await axiosProxy({
      method: "GET",
      params,
      url: `${url}/operations`
    });

    await dispatch({
      type: actionType.PENDING_OPERATIONS,
      payload: {
        shedId: shedIds,
        pendingOperations: res.data.content
      }
    });
  } catch (error) {}
};

export const partialUpdate = (id, data, path) => async dispatch => {
  try {
    const res = await axiosProxy({
      method: "PATCH",
      url: `${url}/${id}`,
      data: [
        {
          op: "replace",
          path: `/${path}`,
          value: data
        }
      ]
    });
    await dispatch({
      type: actionType.PARTIAL_UPDATE,
      payload: {
        shedId: id,
        updatedShed: res.data
      }
    });
    return res.data;
  } catch (error) {
    await dispatch({
      type: actionType.ADD_OPERATION_FAIL,
      payload: error
    });
  }
};

export const addOperation = data => async dispatch => {
  dispatch({
    type: actionType.ADD_OPERATION_START
  });

  try {
    const res = await axiosProxy({
      method: "POST",
      url: `${url}/operation/batch`,
      data
    });
    await dispatch({
      type: actionType.ADD_OPERATION,
      payload: res.data
    });
    return res.data;
  } catch (error) {
    await dispatch({
      type: actionType.ADD_OPERATION_FAIL,
      payload: error
    });
  }
};

export const changeOperation = data => async dispatch => {
  try {
    const res = await axiosProxy({
      method: "POST",
      url: `${url}/operation`,
      data
    });
    await dispatch({
      type: actionType.UPDATE_SHED_INVENTORY,
      payload: {
        shedId: data.shedId,
        buckets: res.data
      }
    });
    return res.data;
  } catch (error) {
    await dispatch({
      type: actionType.OPERATION_FAIL,
      payload: error
    });
  }
};

export const createShed = ({ buckets, ...restData }) => async dispatch => {
  dispatch({ type: actionType.ADD_SHED_START });
  try {
    const res = await axiosProxy({
      method: "POST",
      url,
      data: restData
    });

    await dispatch({
      type: actionType.ADD_SHED,
      payload: res.data
    });

    const bucketsList = buckets.map(item => ({ ...item, shedId: res.data.id }));
    dispatch(addOperation(bucketsList));

    return res.data;
  } catch (error) {
    await dispatch({
      type: actionType.ADD_SHED_FAIL,
      payload: error
    });
  }
};

export const changeArchivedStatus = (tasksIds, archive) => async dispatch => {
  try {
    dispatch({
      type: actionType.ARCHIVE_SHEDS_START
    });

    await axiosProxy({
      method: "PUT",
      url: `${url}/archive/batch?ids=${tasksIds.join(",")}&archive=${archive}`
    });

    dispatch({
      type: actionType.ARCHIVE_SHEDS,
      payload: tasksIds
    });
  } catch (error) {
    dispatch({
      type: actionType.ARCHIVE_SHEDS_FAIL,
      payload: error
    });
  }
};

export const setActiveItemId = id => dispatch => {
  dispatch({ type: actionType.SET_ACTIVE_ITEM_ID, payload: id });
};
export const deleteShedError = () => dispatch => {
  dispatch({ type: actionType.DELETE_SHED_ERROR, payload: null });
};

export const searchProductsByName = async name => {
  const res = await axiosProxy({
    method: "GET",
    params: new URLSearchParams({ search: name }),
    url
  });

  return res.data;
};

export const getAllOperationsList = shedIds => async dispatch => {
  await dispatch({
    type: actionType.PENDING_OPERATIONS_LIST_START,
    payload: shedIds
  });
  try {
    const params = new URLSearchParams({
      status: "PENDING",
      shedIds,
      unpaged: true
    });

    const res = await axiosProxy({
      method: "GET",
      params,
      url: `${url}/operations`
    });

    await dispatch({
      type: actionType.PENDING_OPERATIONS_LIST,
      payload: {
        shedId: shedIds,
        pendingOperations: res.data.content
      }
    });
  } catch (error) {}
};
