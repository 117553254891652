import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Form, Input } from "semantic-ui-react";
import { Field, Formik } from "formik";
import { get, isEmpty } from "lodash";
import * as Yup from "yup";
import SelectProductInput from "./SelectProductInput";
import styles from "./Inventory.module.css";
import { matterToUnits } from "utils/constToUnits";

const InventoryForm = ({
  inventoryData,
  onSubmit,
  deleteButtonCheck,
  inventoryListCheck,
  newNegativeItemsData,
  buckets,
  deleteAddingForm
}) => {
  const [lastChange, setLastChange] = useState();
  const [firstChange, setFirstChange] = useState();
  const validationSchema = () => {
    return Yup.object().shape({
      quantity: Yup.number().required("quantity is required"),
      price: Yup.number().required("price is required"),
      totalPrice: Yup.number().required("price is required")
    });
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      initialValues={inventoryData || {}}
    >
      {({ values, dirty, isValid, setValues, handleSubmit }) => {
        const valuesValidation =
          values.quantity > 0 && values.price > 0 && values.totalPrice > 0;
        const operationType = !buckets
          .map(item => item.chemicalId)
          .includes(values.chemicalId)
          ? "STOCK_ADDITION"
          : "STOCK_CORRECTION";
        return (
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Field width={7} required>
                <label>Product</label>
                <Field
                  placeholder="Select a product"
                  as={SelectProductInput}
                  name="product"
                  required
                  value={get(values, "chemicalId")}
                  onChange={({ key, text, matter }) => {
                    setValues({
                      ...values,
                      chemicalId: key,
                      name: text,
                      matter,
                      date: new Date().toISOString()
                    });
                  }}
                />
              </Form.Field>
              <Form.Field width={2} />
              <Form.Field width={7} required>
                <label>Quantity</label>
                <Field
                  as={Input}
                  name="quantity"
                  onKeyPress={event => {
                    if (!/^[\.0-9]*$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(e, { value }) => {
                    setLastChange("quantity");
                    setFirstChange("price");
                    setValues({
                      ...values,
                      quantity: value,
                      operationType,
                      totalPrice:
                        values.price * value
                          ? (values.price * value).toFixed(2)
                          : ""
                    });
                  }}
                  label={`${matterToUnits(values.matter) || "unit"}`}
                  labelPosition="right"
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field width={7} required>
                <label>{`Price $/${matterToUnits(values.matter) ||
                  "unit"}`}</label>
                <Field
                  as={Input}
                  name="price"
                  onKeyPress={event => {
                    if (!/^[\.0-9]*$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(e, { value }) => {
                    setLastChange("price");
                    setFirstChange("quantity");
                    setValues({
                      ...values,
                      price: value,
                      totalPrice:
                        values.quantity * value
                          ? (values.quantity * value).toFixed(2)
                          : ""
                    });
                  }}
                  label={`($/${matterToUnits(values.matter) || "unit"})`}
                  labelPosition="right"
                />
              </Form.Field>
              <Form.Field width={2} />
              <Form.Field width={7}>
                <label>Total Price ($) </label>
                <Field
                  as={Input}
                  name="totalPrice"
                  label="$"
                  onKeyPress={event => {
                    if (!/^[0-9]*\.?[0-9]*$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(e, { value }) => {
                    setValues({
                      ...values,
                      totalPrice: value,
                      [firstChange]: isFinite(value / values[lastChange])
                        ? value / values[lastChange]
                        : ""
                    });
                  }}
                  labelPosition="right"
                />
              </Form.Field>
            </Form.Group>
            <div className={styles.buttonContainer}>
              {deleteButtonCheck &&
              (inventoryListCheck || !isEmpty(newNegativeItemsData)) ? (
                <Button
                  type="submit"
                  className={styles.cancelBtn}
                  onClick={deleteAddingForm}
                >
                  Cancel
                </Button>
              ) : null}
              <Button
                type="submit"
                className={
                  !dirty || !isValid
                    ? styles.disabledBtn
                    : "button-text color-green"
                }
                disabled={
                  !dirty || !isValid || !values.chemicalId || !valuesValidation
                }
              >
                Save
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

InventoryForm.propTypes = {
  inventoryData: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func
};

export default InventoryForm;
