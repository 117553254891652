import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Button, Grid, Icon } from "semantic-ui-react";
import styles from "./BlockBox.module.css";
import Numeric from "../Numeric";
import { Area } from "../../models/block.model";
import moment from "moment";
import ToolTip from "../ToolTip";

const BlockBox = ({
  block,
  handleEditBlock,
  handleActivateBlock,
  handleDeleteBlock
}) => {
  let totalArea = 0;
  if (_.isEmpty(block.patches)) {
    totalArea = block.patchMode === "PATCHES" ? 0 : _.toNumber(block.size || 0);
  } else {
    (block.patches || [])
      .filter(item => !item.archived)
      .forEach(patch => {
        totalArea += _.toNumber(patch.size || 0);
      });
  }
  const blockModel = new Area(block);
  const areaVariety = blockModel.areaVarietyByDate(moment());

  return (
    <div className={styles.blockBox}>
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <div className={styles.blockName}>{block.name}</div>
          </Grid.Column>
          <Grid.Column width={4} className={styles.blockCol}>
            <div className={styles.colHead}>Size</div>
            <div className={styles.colBody}>
              <Numeric value={totalArea} defaultValue="-" units="Ha" />
            </div>
          </Grid.Column>
          <Grid.Column width={4} className={styles.blockCol}>
            <div className={styles.colHead}>Crop</div>
            <div className={styles.colBody}>
              {areaVariety.crop ? areaVariety.crop.name : "-"}
            </div>
          </Grid.Column>
          <Grid.Column width={4} className={styles.blockCol}>
            <div className={styles.colHead}>Variety</div>
            <div className={styles.colBody}>{areaVariety.name || "-"}</div>
          </Grid.Column>
          <Grid.Column width={4} className={styles.buttonsCol}>
            {!block.archived ? (
              <ToolTip content="Edit">
                <Button icon="edit" onClick={() => handleEditBlock(block.id)} />
              </ToolTip>
            ) : (
              <Icon className="blank" />
            )}
            <ToolTip content={block.archived ? "Activate" : "Archive"}>
              {block.archived ? (
                <Button
                  icon={<Icon className="tuf-unarchive" />}
                  onClick={() => handleActivateBlock(block)}
                />
              ) : (
                <Button
                  icon={<Icon className="tuf-archive" />}
                  onClick={() => handleDeleteBlock(block)}
                />
              )}
            </ToolTip>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

BlockBox.propTypes = {
  block: PropTypes.object.isRequired,
  handleEditBlock: PropTypes.func.isRequired,
  handleActivateBlock: PropTypes.func.isRequired,
  handleDeleteBlock: PropTypes.func.isRequired
};

BlockBox.defaultProps = {
  block: { name: "—", size: "—", crop: "—", variety: "—" }
};

export default BlockBox;
