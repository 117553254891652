import React from "react";
import PropTypes from "prop-types";

export function addCommas(nStr) {
  nStr += "";
  const x = nStr.split(".");
  let x1 = x[0];
  const x2 = x.length > 1 ? "." + x[1] : "";
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1" + "," + "$2");
  }
  return x1 + x2;
}

export const removeCommas = nStr => +nStr.replaceAll(",", "");

const Numeric = ({
  value,
  defaultValue,
  fractionDigits,
  units,
  displayBlock,
  unitsPosition,
  needSpace,
  commaSeparatorOnThousands
}) => {
  if (!value && value !== 0) return <span>{defaultValue}</span>;
  if (isNaN(value)) return <span>{value}</span>;
  const temp = parseFloat(value).toFixed(fractionDigits);
  const valueToShow =
    commaSeparatorOnThousands && temp > 1000 ? addCommas(temp) : temp;
  const unitsToShow = displayBlock ? (
    <div>{units}</div>
  ) : (
    `${needSpace ? ` ${units}` : units}`
  );
  return unitsPosition === "left" ? (
    <span>
      {unitsToShow}
      {valueToShow}
    </span>
  ) : (
    <span>
      {valueToShow}
      {unitsToShow}
    </span>
  );
};

Numeric.propTypes = {
  commaSeparatorOnThousands: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  displayBlock: PropTypes.bool,
  fractionDigits: PropTypes.number,
  needSpace: PropTypes.bool,
  units: PropTypes.string,
  unitsPosition: PropTypes.string,
  value: PropTypes.number
};

Numeric.defaultProps = {
  commaSeparatorOnThousands: false,
  defaultValue: "—",
  fractionDigits: 3,
  needSpace: true,
  units: "",
  unitsPosition: "right"
};

export default Numeric;
