import React from "react";
import { Container, Grid, Header } from "semantic-ui-react";
import Logo from "../../../Scouting/ScoutingList/components/ScoutingReport/Logo";
import { getRecommendationTaskReadableId } from "utils/recommendations";
import GeneralInfoSection from "./GeneralInfoSection";
import ApplyToAreasSection from "./ApplyToAreasSection";
import OperationsSection from "./OperationsSection";
import styles from "./RecommendationsPrintout.module.css";

const RecommendationsPrintout = ({ tasksToPrint }) => (
  <Container fluid className={styles.root}>
    <Container>
      {tasksToPrint.map(task => (
        <Grid className={styles.page}>
          <Grid.Row
            columns="equal"
            className="print-page-header-row"
            textAlign="right"
          >
            <Grid.Column floated="left" textAlign="left">
              <Header as="h1" className="bold">
                Recommendation Task
              </Header>
              <Header as="h4" className="bold">
                Task ID: {getRecommendationTaskReadableId(task.id)}
              </Header>
            </Grid.Column>
            <Grid.Column floated="right">
              <Logo />
            </Grid.Column>
          </Grid.Row>
          <GeneralInfoSection taskData={task} />
          <ApplyToAreasSection taskData={task} />
          <OperationsSection taskData={task} />
        </Grid>
      ))}
    </Container>
  </Container>
);

export default RecommendationsPrintout;
