import moment from "moment";
import { get, map, reduce } from "lodash";
import AreasCell from "routes/Scouting/ScoutingList/components/AreasCell/AreasCell";
import { uppercaseFirstLetter } from "utils/string";
import { RECOMMENDATION_STATUSES } from "./types";
import { calcBlockTanksValue } from "reducers/SprayDiary";
import { getRecommendationTaskReadableId } from "utils/recommendations";
import { getUserDisplayedName } from "utils/user";

export const RECOMMENDATION_TABLE_COLUMNS = {
  id: {
    title: "Task ID",
    accessor: ({ id }) => getRecommendationTaskReadableId(id)
  },
  name: {
    title: "Task Name",
    accessor: "name"
  },
  plannedDate: {
    title: "Date",
    accessor: ({ plannedDate }) =>
      plannedDate && moment(plannedDate).format("DD/MM/YYYY"),
    filter: {
      type: "DateRangeFilter"
    }
  },
  applicationType: {
    title: "Type",
    accessor: ({ applicationType }) => uppercaseFirstLetter(applicationType)
  },
  urgencyLevel: {
    title: "Urgency",
    accessor: ({ urgencyLevel }) => uppercaseFirstLetter(urgencyLevel)
  },
  status: {
    title: "Status",
    accessor: ({ status }) => RECOMMENDATION_STATUSES[status]
  },
  areaIds: {
    title: "Block/Patch",
    accessor: ({ areas }) => map(areas, ({ area }) => get(area, "name")),
    Cell: AreasCell,
    filter: {
      type: "AreaMultiSelectFilter"
    },
    disableSort: true
  },
  chemicals: {
    title: "Products",
    accessor: ({ chemicals }) =>
      chemicals.map(({ chemical }) => chemical.name).join(", "),
    disableSort: true
  },
  creator: {
    title: "Creator",
    accessor: ({ creator }) => getUserDisplayedName(creator)
  },
  assignees: {
    title: "Assignees",
    accessor: ({ assignees }) =>
      map(assignees, ({ employee }) => getUserDisplayedName(employee))
  },
  total: {
    title: "Total Vat",
    accessor: ({
      areas,
      literPerHectare,
      rowsToSpray,
      rowWidth,
      machinery
    }) => {
      const totalAreasSize = reduce(
        areas,
        (sum, { area }) => sum + area.size,
        0
      );
      const totalTanksValue = calcBlockTanksValue(
        totalAreasSize,
        literPerHectare,
        get(machinery, "size"),
        rowsToSpray,
        rowWidth
      );

      if (totalTanksValue) {
        return totalTanksValue.toFixed(2);
      }

      return null;
    },
    disableSort: true
  },
  empty: {
    title: "",
    accessor: () => {},
    disableSort: true,
    filter: {
      type: "SeasonSingleSelectFilter"
    }
  }
};
