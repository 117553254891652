import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableHeaderCell,
  TableCell,
  Container,
  Header
} from "semantic-ui-react";
import classNames from "classnames";
import Numeric from "components/Numeric";
import {
  calcBlockPlannedLitersValue,
  calcBlockTanksValue
} from "reducers/SprayDiary";
import { map, sumBy, get } from "lodash";
import { matterToUnits } from "utils/constToUnits";
import { calculateChemicalQuantity } from "utils/chemicalBreakdownUtils";
import {
  APPLICATION_TYPES,
  recommendationTaskShape
} from "constants/Recommendations/types";
import styles from "./styles.module.css";

const SelectedAreasTable = ({
  basic,
  className,
  recommendationTask,
  striped,
  showParentBlocksOnly,
  stickyFirstColumn,
  parentBlocks,
  withTanksExplanation
}) => {
  const {
    areas,
    applicationType,
    chemicals,
    literPerHectare,
    machinery,
    rowWidth,
    rowsToSpray
  } = recommendationTask;
  const isSprayTask =
    APPLICATION_TYPES[applicationType] === APPLICATION_TYPES.SPRAY;

  const { tableRows, totalRowValue } = useMemo(() => {
    const tableRows = map(areas, ({ area = {} }) => {
      const chemicalsSummary = map(chemicals, chemicalData => {
        return calculateChemicalQuantity(
          chemicalData.chemical,
          area.size,
          rowsToSpray,
          rowWidth,
          literPerHectare
        );
      });
      const parentBlock = get(parentBlocks, area.parentId);
      const name = parentBlock
        ? `${parentBlock.name} - ${area.name}`
        : area.name;

      return {
        ...area,
        name,
        totalTanks: calcBlockTanksValue(
          area.size,
          literPerHectare,
          get(machinery, "size"),
          rowsToSpray,
          rowWidth
        ),
        totalSolution: calcBlockPlannedLitersValue(
          area.size,
          literPerHectare,
          rowsToSpray,
          rowWidth
        ),
        chemicals: chemicalsSummary
      };
    });

    const totalRowValue = {
      fieldsNumber: tableRows.length,
      size: sumBy(tableRows, "size"),
      totalSolution: sumBy(tableRows, "totalSolution"),
      totalTanks: sumBy(tableRows, "totalTanks"),
      chemicals: map(chemicals, (chemical, index) => ({
        units: matterToUnits(chemical.matter),
        quantity: sumBy(tableRows, row => {
          return row.chemicals[index].quantity;
        })
      }))
    };

    return { tableRows, totalRowValue };
  }, [
    areas,
    literPerHectare,
    rowsToSpray,
    rowWidth,
    chemicals,
    machinery,
    parentBlocks
  ]);

  const shownTableRows = useMemo(() => {
    if (!showParentBlocksOnly) {
      return tableRows;
    }

    const parentBlocksMap = tableRows.reduce((acc, area) => {
      const parentBlock = parentBlocks[area.parentId];

      if (parentBlock) {
        const currentArea = acc[parentBlock.id];
        acc[parentBlock.id] = currentArea
          ? {
              ...area,
              size: currentArea.size + area.size,
              totalTanks: currentArea.totalTanks + area.totalTanks,
              totalSolution: currentArea.totalSolution + area.totalSolution,
              chemicals: map(currentArea.chemicals, (chemical, index) => ({
                units: matterToUnits(chemical.matter),
                quantity: chemical.quantity + area.chemicals[index].quantity
              }))
            }
          : { ...area, name: parentBlock.name };
      } else {
        acc[area.id] = area;
      }

      return acc;
    }, {});

    return Object.values(parentBlocksMap);
  }, [tableRows, parentBlocks, showParentBlocksOnly]);

  return (
    <Container fluid className={classNames(className, styles.tableWrapper)}>
      <Table
        basic={basic}
        singleLine
        unstackable
        className={classNames({ ["freeze-head-and-col"]: stickyFirstColumn })}
        striped={striped}
      >
        <TableHeader>
          <TableRow>
            <TableHeaderCell>Area</TableHeaderCell>
            <TableHeaderCell>Size</TableHeaderCell>
            {isSprayTask && (
              <>
                <TableHeaderCell>Total solution (L)</TableHeaderCell>
                <TableHeaderCell>
                  Tanks
                  {withTanksExplanation && (
                    <span className={styles.explanationStar}>*</span>
                  )}
                </TableHeaderCell>
              </>
            )}
            {map(chemicals, item => (
              <TableHeaderCell key={item.chemicalId}>
                {item.chemical.name}
              </TableHeaderCell>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {shownTableRows.map(area => (
            <TableRow key={area.id}>
              <TableCell>{area.name}</TableCell>
              <TableCell>
                <Numeric
                  fractionDigits={2}
                  value={area.size}
                  commaSeparatorOnThousands
                  units="Ha"
                />
              </TableCell>
              {isSprayTask && (
                <>
                  <TableCell>
                    <Numeric
                      fractionDigits={2}
                      value={area.totalSolution}
                      commaSeparatorOnThousands
                      units="L"
                    />
                  </TableCell>
                  <TableCell>
                    <Numeric
                      fractionDigits={2}
                      value={area.totalTanks}
                      commaSeparatorOnThousands
                    />
                  </TableCell>
                </>
              )}
              {area.chemicals.map(({ units, quantity }, index) => {
                return (
                  <TableCell key={index}>
                    <Numeric
                      fractionDigits={2}
                      value={quantity}
                      commaSeparatorOnThousands
                      units={units}
                    />
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
          <TableRow className={styles.tableTotalsRow}>
            <TableCell>
              {totalRowValue.fieldsNumber} Field
              {totalRowValue.fieldsNumber > 1 && "s"}
            </TableCell>
            <TableCell>
              <Numeric
                value={totalRowValue.size}
                fractionDigits={2}
                commaSeparatorOnThousands
                units="Ha"
              />
            </TableCell>
            {isSprayTask && (
              <>
                <TableCell>
                  <Numeric
                    value={totalRowValue.totalSolution}
                    fractionDigits={2}
                    commaSeparatorOnThousands
                    units="L"
                  />
                </TableCell>
                <TableCell>
                  <Numeric
                    value={totalRowValue.totalTanks}
                    fractionDigits={2}
                    commaSeparatorOnThousands
                  />
                </TableCell>
              </>
            )}
            {map(totalRowValue.chemicals, (item, index) => (
              <TableCell key={index}>
                <Numeric
                  fractionDigits={2}
                  value={item.quantity}
                  commaSeparatorOnThousands
                  units={item.units}
                />
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
      {!!(withTanksExplanation && machinery) && (
        <Container className={styles.explanationContainer} fluid>
          <Header className={styles.explanation} size="small">
            {"* 1 Tank = "}
            <Numeric
              value={machinery.size}
              fractionDigits={2}
              commaSeparatorOnThousands
              units="L"
            />
          </Header>
          <Header className={styles.explanation} size="small">
            <Numeric
              value={totalRowValue.totalTanks}
              fractionDigits={2}
              commaSeparatorOnThousands
              units="Tanks"
            />
            {" = "}
            <Numeric
              value={totalRowValue.totalTanks * machinery.size}
              fractionDigits={2}
              commaSeparatorOnThousands
              units="L"
            />
          </Header>
        </Container>
      )}
    </Container>
  );
};

SelectedAreasTable.propTypes = {
  basic: PropTypes.oneOfType([PropTypes.oneOf(["very"]), PropTypes.bool]),
  className: PropTypes.string,
  recommendationTask: recommendationTaskShape.isRequired,
  parentBlocks: PropTypes.object,
  showParentBlocksOnly: PropTypes.bool,
  striped: PropTypes.bool,
  stickyFirstColumn: PropTypes.bool,
  withTanksExplanation: PropTypes.bool
};

SelectedAreasTable.defaultProps = {
  showParentBlocksOnly: false,
  parentBlocks: {},
  striped: false,
  stickyFirstColumn: true,
  withTanksExplanation: false
};

export default SelectedAreasTable;
