import React, { useState } from "react";
import { Button, Form, Input } from "semantic-ui-react";
import PropTypes from "prop-types";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import DatePicker from "components/DatePicker/DatePicker";
import styles from "../Setting.module.css";
const SeasonsForm = ({
  item,
  deleteAddingForm,
  isEditing,
  onCancel,
  onSubmit
}) => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const validationSchema = () => {
    return Yup.object().shape({
      seasonName: Yup.string().required("seasonName is required")
    });
  };

  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={values => {
        onSubmit(values);
      }}
      initialValues={item || {}}
    >
      {({ values, dirty, isValid, setValues, handleSubmit }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <>
              <Form.Group>
                <Form.Field required width={10}>
                  <label>Name</label>
                  <Field
                    as={Input}
                    name="seasonName"
                    value={values.seasonName}
                  />
                </Form.Field>
                <Form.Field width={10}>
                  <label>From date</label>
                  <DatePicker
                    name="startAt"
                    trigger={false}
                    focused={false}
                    value={values.startAt}
                    onChange={startAt => {
                      return (
                        setStartDate(startAt),
                        setValues({
                          ...values,
                          startAt
                        })
                      );
                    }}
                  />
                </Form.Field>
                <Form.Field width={10}>
                  <label>To date</label>
                  <DatePicker
                    name="endAt"
                    trigger={false}
                    focused={false}
                    value={values.endAt}
                    onChange={endAt => {
                      return (
                        setEndDate(endAt),
                        setValues({
                          ...values,
                          endAt
                        })
                      );
                    }}
                    disableClear
                  />
                </Form.Field>
              </Form.Group>

              <div className={styles.buttonContainer}>
                <Button
                  type="reset"
                  className={styles.cancelButton}
                  onClick={() => (isEditing ? onCancel() : deleteAddingForm())}
                >
                  Cancel
                </Button>

                <Button
                  type="submit"
                  className={styles.saveBtn}
                  disabled={
                    !dirty || !isValid || !values.endAt || !values.startAt
                  }
                >
                  Save
                </Button>
              </div>
            </>
          </Form>
        );
      }}
    </Formik>
  );
};

SeasonsForm.propTypes = {
  quantity: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func
};

export default SeasonsForm;
