import React, { useState } from "react";
import { useFormikContext } from "formik";
import {
  Button,
  Checkbox,
  Grid,
  Icon,
  Container,
  Modal
} from "semantic-ui-react";
import NewCostForm from "./NewCostForm";
import styles from "./NewCost.module.css";

const NewCost = ({ errors }) => {
  const { values, setFieldValue, validateForm } = useFormikContext();
  const [allChecked, setAllChecked] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const costsListCheck = values.costs?.length;
  const addProduct = () => {
    setFieldValue(
      "costs",
      values.costs && [
        ...values.costs,
        {
          costType: "",
          areaData: {
            expandedFarmsIds: [],
            expandedAreasIds: [],
            selectedAreas: [],
            treeSelectSearchFilter: ""
          },
          generalTaskId: "",
          costSubType: "",
          units: null,
          rate: null,
          notes: "",
          startAt: "",
          endAt: ""
        }
      ]
    );
  };

  const handleSelectAll = event => {
    setAllChecked(!allChecked);
    setSelectedItem(values.costs && values.costs.map((item, index) => index));
    allChecked && setSelectedItem([]);
  };

  const checkHandler = index => {
    selectedItem.includes(index)
      ? setSelectedItem(selectedItem.filter(i => i != index))
      : setSelectedItem([...selectedItem, index]);
  };
  const onDeleteByIndex = () => {
    var costListInObject = values.costs.reduce((accumulator, value, index) => {
      return { ...accumulator, [index]: value };
    }, {});
    selectedItem.forEach(function(k) {
      delete costListInObject[k];
    });
    const newData = Object.values(costListInObject);
    setFieldValue("costs", newData);
    setSelectedItem([]);
    if (values.costs.length === selectedItem.length) {
      setFieldValue(
        "costs",
        values.costs && [
          {
            costType: "",
            areaData: {
              expandedFarmsIds: [],
              expandedAreasIds: [],
              selectedAreas: [],
              treeSelectSearchFilter: ""
            },
            generalTaskId: "",
            costSubType: "",
            units: null,
            rate: null,
            notes: "",
            startAt: "",
            endAt: ""
          }
        ]
      );
    }
    setShowDeleteModal(false);
  };
  return (
    <Container className={styles.root}>
      <Modal
        className={styles.deleteModal}
        size="mini"
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
      >
        <Modal.Content>
          <p>Delete {selectedItem.length} cost type?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            className={styles.cancelDltBtn}
            onClick={() => setShowDeleteModal(false)}
          >
            Cancel
          </Button>
          <Button
            className={styles.deleteBtn}
            positive
            onClick={() => onDeleteByIndex()}
          >
            Delete
          </Button>
        </Modal.Actions>
      </Modal>
      <div className={styles.deleteButtonContainer}>
        <Button
          className={styles.delete}
          disabled={!selectedItem.length || costsListCheck <= 1}
          onClick={() => setShowDeleteModal(true)}
        >
          <div className={styles.deleteButtonIconWrapper}>
            <Icon className={styles.deleteButtonIcon} name="trash alternate" />
            Delete
          </div>
        </Button>
      </div>
      <Grid className={styles.wrapperRow}>
        <Grid.Row className={styles.labelRow}>
          <Grid.Column width={2} className={styles.costtypeLabel}>
            <div className={styles.checkboxWrapper}>
              <Checkbox
                disabled={!costsListCheck}
                indeterminate={
                  selectedItem.length != values.costs?.length &&
                  selectedItem.length >= 1
                }
                checked={selectedItem.length === values.costs?.length}
                onChange={event => handleSelectAll(event)}
              />
            </div>
            Cost type
          </Grid.Column>
          <Grid.Column width={2} className={styles.area}>
            Area
          </Grid.Column>
          <Grid.Column width={2} className={styles.activity}>
            General task id
          </Grid.Column>
          <Grid.Column width={2} className={styles.type}>
            Type
          </Grid.Column>
          <Grid.Column width={1} className={styles.units}>
            Units
          </Grid.Column>
          <Grid.Column width={1} className={styles.rate}>
            Rate ($)
          </Grid.Column>
          <Grid.Column width={1} className={styles.total}>
            Total ($)
          </Grid.Column>
          <Grid.Column width={2} className={styles.notes}>
            Notes
          </Grid.Column>
          <Grid.Column className={styles.datePeriod}>Date/Period</Grid.Column>
          <Grid.Column width={1}></Grid.Column>
        </Grid.Row>
      </Grid>
      <div className={values.costs && styles.formContainer}>
        {values.costs &&
          values.costs.map((cost, index) => (
            <NewCostForm
              addProduct={addProduct}
              errors={errors}
              selectedItem={selectedItem}
              checkHandler={checkHandler}
              costData={cost}
              index={index}
              costsListCheck={costsListCheck}
            />
          ))}
      </div>
      <div className={styles.newRowbuttonContainer}>
        <Button
          type="submit"
          className="button-text color-green"
          onClick={addProduct}
        >
          + New row
        </Button>
      </div>
    </Container>
  );
};

export default NewCost;
