export const defaultFiltersOptions = [
  {
    name: "Date",
    type: "DateRangeFilter",
    id: "task_costs_date"
  },
  {
    name: "Type",
    type: "GeneralTasksTypesMultiSelectFilter",
    id: "task_costs_taskType"
  },
  {
    name: "Sub Task Name",
    type: "GeneralSubTaskNameMultiSelectFilter",
    id: "task_costs_subTaskName"
  }
];

export const columnsConfig = [
  {
    id: "taskId",
    accessor: "taskId",
    Header: "General Task ID"
  },
  {
    id: "generalTaskType",
    accessor: "generalTaskType",
    Header: "Task Type"
  },
  {
    id: "generalTaskSubtaskName",
    accessor: "generalTaskSubtaskName",
    Header: "Sub Task"
  },
  {
    id: "totalWorkingTimeString",
    accessor: "totalWorkingTimeString",
    format: "time",
    Header: "Total Working Time",
    aggregate: true
  },
  {
    id: "labourCost",
    accessor: "labourCost",
    Header: "Labour Costs",
    aggregate: true
  },
  {
    id: "machineryCost",
    accessor: "machineryCost",
    Header: "Machinery Costs",
    aggregate: true
  },
  {
    id: "calculatedCostPerHectare",
    accessor: "calculatedCostPerHectare",
    Header: "Cost ($)/Ha/Task",
    aggregate: true
  },
  {
    id: "totalCosts",
    accessor: "totalCosts",
    Header: "Total Costs ($)",
    aggregate: true
  }
];

export const sortOptions = [
  {
    key: "taskType",
    text: `Sort by Type`,
    value: "taskType",
    content: "Task Type",
    sortfields: ["taskType"]
  }
];
