import React from "react";
import ReactDOM from "react-dom";
import { UserManager, WebStorageStateStore } from "oidc-client";
import configureStore from "./store";
import "./index.css";
import App from "./App";

import appConfig from "./config";
import * as serviceWorker from "./serviceWorker";
import { getParsableItemsFromStorage } from "utils/localStorage";
// import * as Sentry from '@sentry/browser';

const createUserManager = config => {
  const userStore = new WebStorageStateStore({ store: window.localStorage });
  const userManagerSettings = Object.assign({ userStore }, config);
  const userManager = new UserManager(userManagerSettings);

  userManager.events.addAccessTokenExpired(() => {
    userManager.signinSilent();
  });

  userManager.events.addUserSignedOut(() => {
    userManager.removeUser();
  });

  return userManager;
};

const renderApp = store => {
  ReactDOM.render(<App store={store} />, document.getElementById("root"));

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.register();

  // Fix for scroll inputs into view on Android devices
  if (/Android/.test(navigator.appVersion) && window) {
    window.addEventListener("resize", () => {
      if (document.activeElement.tagName === "INPUT") {
        window.setTimeout(() => {
          document.activeElement.scrollIntoViewIfNeeded();
        }, 0);
      }
    });
  }
};

export let userManager = {};
export let userManagerOffline = {};
export let store;
export const requestsCancels = {};

const waitForRegister = async () => {
  while (!window.hasOwnProperty("myServiceWorkerReg"))
    await new Promise(resolve => setTimeout(resolve, 50));
};

const run = async () => {
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.register();
  if ("serviceWorker" in navigator) {
    await waitForRegister();
  }
  userManager = createUserManager(appConfig.oauth2);
  userManagerOffline = new UserManager(appConfig.oauth2Offline);
  store = configureStore({
    initialState: {
      offline: { outbox: [], lastTransaction: 0 },
      appConfig,
      ...(await getParsableItemsFromStorage(["areas", "chemical", "employee"]))
    }
  });

  // if you wish to re-active it you should nmp install "@sentry/browser": "^5.9.1", aging
  // if (process.env.REACT_APP_ENVIRONMENT !== 'local_dev') {
  //   Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN, environment: process.env.REACT_APP_ENVIRONMENT });
  // }

  renderApp(store);
};
//eslint-disable-next-line
run().catch(e => console.error(e));
