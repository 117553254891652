import * as React from "react";
import { Icon } from "semantic-ui-react";

export const LogoIcon = props => {
  return (
    <Icon {...props}>
      <svg
        width="130"
        height="131"
        viewBox="0 0 130 131"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M58.4125 74.0098L24.1338 39.731L26.2115 37.6533L60.4914 71.9321L58.4125 74.0098Z"
          fill="#D2DB2F"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M53.5786 78.8452L19.2998 44.5652L21.3775 42.4863L55.6575 76.7663L53.5786 78.8452Z"
          fill="#D2DB2F"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M48.7458 83.6777L14.4658 49.399L16.5447 47.3213L50.8247 81.6L48.7458 83.6777Z"
          fill="#D2DB2F"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M43.9108 88.5119L9.63086 54.2332L11.7085 52.1543L45.9885 86.4343L43.9108 88.5119Z"
          fill="#D2DB2F"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M39.0747 93.3469L4.7959 59.0669L6.87479 56.9893L41.1535 91.268L39.0747 93.3469Z"
          fill="#D2DB2F"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M34.2407 98.1809L-0.0380859 63.9021L2.0396 61.8232L36.3196 96.1032L34.2407 98.1809Z"
          fill="#D2DB2F"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M34.3601 101.495L32.2812 99.4162L66.5612 65.1387L68.6389 67.2164L34.3601 101.495Z"
          fill="#2BAB6E"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M39.1941 106.328L37.1152 104.252L71.3952 69.9717L73.4741 72.0494L39.1941 106.328Z"
          fill="#2BAB6E"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M44.0269 111.163L41.9492 109.084L76.228 74.8057L78.3069 76.8846L44.0269 111.163Z"
          fill="#2BAB6E"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M48.8628 115.997L46.7852 113.92L81.0627 79.6396L83.1416 81.7173L48.8628 115.997Z"
          fill="#2BAB6E"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M53.698 120.831L51.6191 118.752L85.8991 84.4736L87.9768 86.5525L53.698 120.831Z"
          fill="#2BAB6E"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M58.532 125.666L56.4531 123.589L90.7331 89.3086L92.8108 91.3863L58.532 125.666Z"
          fill="#2BAB6E"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M63.365 130.5L61.2861 128.423L95.5661 94.1426L97.6438 96.2215L63.365 130.5Z"
          fill="#2BAB6E"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M98.8793 98.1809L64.6006 63.9021L66.6771 61.8232L100.957 96.1032L98.8793 98.1809Z"
          fill="#0B8B42"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M103.713 93.3469L69.4346 59.0669L71.5123 56.9893L105.792 91.268L103.713 93.3469Z"
          fill="#0B8B42"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M108.549 88.5119L74.2686 54.2332L76.3474 52.1543L110.626 86.4343L108.549 88.5119Z"
          fill="#0B8B42"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M113.382 83.6777L79.1016 49.399L81.1805 47.3213L115.459 81.6L113.382 83.6777Z"
          fill="#0B8B42"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M118.216 78.8452L83.9385 44.5652L86.0149 42.4863L120.294 76.7663L118.216 78.8452Z"
          fill="#0B8B42"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M123.051 74.0098L88.7725 39.731L90.8501 37.6533L125.13 71.9321L123.051 74.0098Z"
          fill="#0B8B42"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M127.885 69.1772L93.6064 34.8973L95.6841 32.8184L129.964 67.0983L127.885 69.1772Z"
          fill="#0B8B42"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M63.365 65.8628L61.2861 63.7839L95.5661 29.5039L97.6438 31.5828L63.365 65.8628Z"
          fill="#84BF41"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M58.532 61.0295L56.4531 58.9506L90.7331 24.6719L92.8108 26.7496L58.532 61.0295Z"
          fill="#84BF41"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M53.698 56.1936L51.6191 54.1147L85.8991 19.8359L87.9768 21.9148L53.698 56.1936Z"
          fill="#84BF41"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M48.8628 51.3606L46.7852 49.2829L81.0627 15.0029L83.1416 17.0806L48.8628 51.3606Z"
          fill="#84BF41"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M44.0269 46.5256L41.9492 44.4467L76.228 10.168L78.3069 12.2469L44.0269 46.5256Z"
          fill="#84BF41"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M39.1941 41.6916L37.1152 39.614L71.3952 5.33398L73.4741 7.41167L39.1941 41.6916Z"
          fill="#84BF41"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M34.3601 36.8577L32.2812 34.7788L66.5612 0.5L68.6389 2.5789L34.3601 36.8577Z"
          fill="#84BF41"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M64.962 66.7361L31.0455 32.8184L28.9678 34.8973L62.8855 68.8138L64.962 66.7361Z"
          fill="#D2DB2F"
        />
      </svg>
    </Icon>
  );
};
