import React, { Fragment } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import Logo from "../../../../../components/Logo";
import { Header, Tab, Table, TableBody, TableRow } from "semantic-ui-react";
import Numeric from "../../../../../components/Numeric/Numeric";
import { get, pickBy } from "lodash";
import { minutesToHM, dateRangeToString } from "../../../../../utils/timeUtils";
import styles from "./PrintReports.module.css";
import { matterToUnits } from "utils/constToUnits";
import { uppercaseFirstLetter } from "utils/string";

class PrintReports extends React.Component {
  render() {
    const {
      productsList,
      farmsList,
      chemicals,
      farms,
      from,
      data,
      to
    } = this.props;
    return (
      <div className={styles.printTableWrapper}>
        <div className={styles.printTableRow}>
          <div className={styles.printTableHeader}>Product type usage</div>
          <div className={styles.printTableLogo}>
            <Logo width={140} height={60} />
          </div>
        </div>
        <Table columns="3" basic="very" className={styles.totalRowWrapper}>
          <Table.Header className={styles.totalRowHeaderWrapper}>
            <Table.HeaderCell className={styles.totalRowDateHeaderContainer}>
              Date range
            </Table.HeaderCell>
            <Table.HeaderCell className={styles.totalRowFarmHeaderContainer}>
              Farm
            </Table.HeaderCell>
            <Table.HeaderCell className={styles.totalRowProductHeaderContainer}>
              Product
            </Table.HeaderCell>
          </Table.Header>
          <Table.Body className={styles.totalRowValueWrapper}>
            <Table.Cell className={styles.totalRowDateValueContainer}>
              {from && to
                ? `${moment(from).format("DD/MM/YYYY")} - ${moment(to).format(
                    "DD/MM/YYYY"
                  )}`
                : "-"}
            </Table.Cell>
            <Table.Cell className={styles.totalRowFarmValueContainer}>
              {farms
                ? farmsList.options
                    .filter(({ key }) => farms.some(item => item === key))
                    .map(item => item.label)
                    .join(",")
                : "-"}
            </Table.Cell>
            <Table.Cell className={styles.totalRowProductValueContainer}>
              {chemicals
                ? productsList
                    .filter(({ id }) => chemicals.some(item => item === id))
                    .map(item => item.name)
                    .join(",")
                : "-"}
            </Table.Cell>
          </Table.Body>
        </Table>
        {data.content &&
          data.content.map((item, index) => {
            return (
              <div key={index} className={styles.container}>
                <Table basic="very" fixed={true} className={styles.reportTable}>
                  <Table.Header>
                    <Table.Row>
                      <Table.Cell as="td" className={styles.reportHeader}>
                        <div>Product type</div>
                      </Table.Cell>
                      <Table.Cell as="td" className={styles.reportHeader}>
                        <div>Matter</div>
                      </Table.Cell>
                      <Table.Cell as="td" className={styles.reportHeader}>
                        <div>Planned Qty (L/Kg)</div>
                      </Table.Cell>
                      <Table.Cell as="td" className={styles.reportHeader}>
                        <div>Actual Qty (L/Kg)</div>
                      </Table.Cell>
                      <Table.Cell as="td" className={styles.reportHeader}>
                        <div>Difference (L/Kg)</div>
                      </Table.Cell>
                      <Table.Cell as="td" className={styles.reportHeader}>
                        <div>Total cost ($)</div>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell className={styles.reportBody} as="td">
                        {item.name || "-"}
                      </Table.Cell>
                      <Table.Cell className={styles.reportBody} as="td">
                        {item.matter
                          .toLowerCase()
                          .charAt(0)
                          .toUpperCase() + item.matter.toLowerCase().slice(1) ||
                          "-"}
                      </Table.Cell>
                      <Table.Cell className={styles.reportBody} as="td">
                        {item.plannedQuantity || "-"}
                      </Table.Cell>
                      <Table.Cell className={styles.reportBody} as="td">
                        {item.actualQuantity || "-"}
                      </Table.Cell>
                      <Table.Cell className={styles.reportBody} as="td">
                        {item.difference || "-"}
                      </Table.Cell>
                      <Table.Cell className={styles.reportBody} as="td">
                        {item.totalCost || "-"}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </div>
            );
          })}
      </div>
    );
  }
}

PrintReports.propTypes = {
  title: PropTypes.string,
  data: PropTypes.object.isRequired,
  showExpandedData: PropTypes.bool,
  startDate: PropTypes.any,
  endDate: PropTypes.any
};

export default PrintReports;
