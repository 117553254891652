import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Grid } from "semantic-ui-react";
import { Form } from "formsy-semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";

import { history } from "../../../../store";
import {
  getErrorFields,
  validationErrors
} from "../../../../utils/validationRules";
import ErrorLabel from "../../../../components/ErrorLabel";
import { abilitiesSelector } from "../../../../selectors/user";
import SegmentClosable from "../../../../components/SegmentClosable";
import { scrollIntoError } from "../../../../utils/scrollIntoError";
import styles from "./PackingShedsForm.module.css";
import {
  addPackingShed,
  updatePackingShed
} from "../../../../actions/PackingSheds/packingSheds";
import Address from "./Address";

const initialValues = {
  id: null,
  name: "",
  registrationNumber: "",
  country: "",
  state: "",
  city: "",
  street: "",
  houseNumber: "",
  zip: ""
};

class PackingShedsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      packingShed: props.initialValues ? props.initialValues : initialValues,
      errorsInSegments: {}
    };
    this.formRef = React.createRef();
    this.isSubmitted = false;
  }

  changeValue = (fieldName, value) => {
    const { packingShed } = this.state;
    this.setState({
      packingShed: {
        ...packingShed,
        [fieldName]: value
      }
    });
  };

  handleErrors = () => {
    const { formRef } = this.props;
    const formsyForm = (formRef || this.formRef).current.formsyForm;
    const errorFields = getErrorFields(formsyForm);
    this.setState({
      errorsInSegments: {
        information: {
          error: errorFields.name
        }
      }
    });
    setTimeout(scrollIntoError, 300);
  };

  onValidSubmit = async () => {
    if (this.isSubmitted) {
      return null;
    }
    this.isSubmitted = true;
    const {
      actions: { addPackingShed, updatePackingShed },
      onSubmitForm
    } = this.props;
    const { packingShed } = this.state;

    const packingShedData = _.omit(packingShed, ["id", "isFetching"]);

    this.setState({
      errorsInSegments: {}
    });

    let res;

    if (packingShed.id) {
      res = await updatePackingShed(packingShed.id, packingShedData, true);
    } else {
      res = await addPackingShed(packingShedData, true);
    }
    this.isSubmitted = false;

    if (!res.error) {
      if (_.isFunction(onSubmitForm)) {
        onSubmitForm(res);
      } else {
        history.push("/harvest/packing_sheds");
      }
    }
  };

  onSubmit = () => {
    const { formRef } = this.props;
    const targetRef = formRef || this.formRef;
    targetRef.current.submit();
  };

  render() {
    const { modal, Can, showMobileHeader, formRef } = this.props;
    const {
      packingShed: {
        id,
        name,
        registrationNumber,
        street,
        city,
        state,
        zip,
        country,
        houseNumber
      },
      errorsInSegments
    } = this.state;

    const columnMiddle = modal
      ? {
          width: 16
        }
      : {
          mobile: 16,
          tablet: 16,
          computer: 10,
          largeScreen: 10,
          widescreen: 10
        };
    const columnMiddleRight = {
      mobile: 16,
      tablet: 16,
      computer: 6,
      largeScreen: 6,
      widescreen: 6
    };
    return (
      <Form
        onValidSubmit={this.onValidSubmit}
        onInvalidSubmit={this.handleErrors}
        loading={false}
        ref={formRef || this.formRef}
      >
        <div className={`${styles.sprayGrid} ${modal ? styles.forModal : ""}`}>
          <div className={styles.sprayFormContainer} id="sprayGrid">
            <div>
              <SegmentClosable
                title="Packing shed information"
                errors={errorsInSegments.information}
              >
                <Grid>
                  <Grid.Row>
                    <Grid.Column {...columnMiddle}>
                      <Form.Input
                        fluid
                        {...(!id ? { autoFocus: true } : {})}
                        label="Name"
                        placeholder="Name"
                        name="name"
                        size="large"
                        required
                        validationErrors={validationErrors({
                          isDefaultRequiredValue: "Name"
                        })}
                        errorLabel={ErrorLabel}
                        value={name}
                        onChange={event =>
                          this.changeValue("name", event.target.value)
                        }
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column {...columnMiddle}>
                      <Form.Input
                        fluid
                        label="Registration number"
                        placeholder="Registration number"
                        name="registrationNumber"
                        size="large"
                        validationErrors={validationErrors({
                          isDefaultRequiredValue: "Registration number"
                        })}
                        errorLabel={ErrorLabel}
                        value={registrationNumber}
                        onChange={event =>
                          this.changeValue(
                            "registrationNumber",
                            event.target.value
                          )
                        }
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </SegmentClosable>
              <SegmentClosable title="Address">
                <Address
                  country={country}
                  city={city}
                  state={state}
                  zip={zip}
                  street={street}
                  houseNumber={houseNumber}
                  changeValue={this.changeValue}
                  columnMiddle={columnMiddle}
                  columnMiddleRight={columnMiddleRight}
                />
              </SegmentClosable>
            </div>
          </div>
          {!showMobileHeader && (
            <div className={`${styles.sprayFormFooter} show-sm`}>
              <div className="text-right">
                {id ? (
                  <Can I="update" a="packing_sheds">
                    <Button
                      primary
                      size="large"
                      type="button"
                      onClick={this.onSubmit}
                    >
                      Save
                    </Button>
                  </Can>
                ) : (
                  <Can I="add" a="packing_sheds">
                    <Button
                      primary
                      size="large"
                      type="button"
                      onClick={this.onSubmit}
                    >
                      Save
                    </Button>
                  </Can>
                )}
              </div>
            </div>
          )}
        </div>
      </Form>
    );
  }
}

PackingShedsForm.propTypes = {
  modal: PropTypes.bool,
  showMobileHeader: PropTypes.bool,
  Can: PropTypes.func,
  onSubmitForm: PropTypes.func,
  formRef: PropTypes.func,
  initialValues: PropTypes.object,
  actions: PropTypes.object
};

const mapStateToProps = state => {
  return {
    Can: abilitiesSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      addPackingShed,
      updatePackingShed
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(PackingShedsForm);
