import { actionType } from '../../constants/SprayDiary';
import { actionType as actionTypeApi } from '../../constants/ApiResults';
const initialState = {
  errors: {},
  success: {},
};

export default (state = initialState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case actionTypeApi.CLEAR_API_ALL:
      return { ...initialState };
    case actionType.CREATE_TASK_START:
      delete state.success.CREATE_TASK;
      delete state.errors.CREATE_TASK;
      return {
        ...state,
        errors: { ...state.errors },
        success: { ...state.success },
      };
    case actionType.UPLOAD_TASK_ATTACHMENT_START:
      delete state.success.UPLOAD_TASK_ATTACHMENT;
      delete state.errors.UPLOAD_TASK_ATTACHMENT;
      return {
        ...state,
        errors: { ...state.errors },
        success: { ...state.success },
      };
    case actionType.CREATE_TASK_ERROR:
      return {
        ...state,
        errors: { ...state.errors, CREATE_TASK: action.meta },
      };
    case actionType.CREATE_TASK_SUCCESS:
      return {
        ...state,
        success: { ...state.success, CREATE_TASK: action.meta },
      };
    case actionType.UPLOAD_TASK_ATTACHMENT_ERROR:
      return {
        ...state,
        errors: { ...state.errors, UPLOAD_TASK_ATTACHMENT: action.meta },
      };
    case actionType.UPLOAD_TASK_ATTACHMENT:
      return {
        ...state,
        success: { ...state.success, UPLOAD_TASK_ATTACHMENT: action.meta },
      };
    default:
      return state;
  }
};
