import { actionType } from "../../constants/Filters";

const initialState = {
  resetFilters: false
};

export default (state = initialState, action) => {
  if (!action) return state;

  switch (action.type) {
    case actionType.RESET_FILTERS_START:
      return {
        ...state,
        resetFilters: true
      };
    case actionType.RESET_FILTERS:
      return {
        ...state,
        resetFilters: false
      };

    default:
      return state;
  }
};
