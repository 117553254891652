import { createSelector } from 'reselect';

const userChecked = state => state.common.userChecked;
const online = state => state.offline.online;
const onlineFetch = state => state.common.online;

const onlineSelector = createSelector([onlineFetch, online], (onlineFetchSelect, onlineSelect) => {
  return onlineFetchSelect && onlineSelect;
});

const canFetchSelector = createSelector([userChecked, onlineSelector], (userCheckedSelect, onlineSelect) => {
  return userCheckedSelect && onlineSelect;
});

export { canFetchSelector, onlineSelector };
