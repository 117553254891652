import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { Icon } from 'semantic-ui-react';
import styles from './CircularProgressbar.module.css';

const CircularProgressbar = ({ value, text, className }) => (
  <div className={`${styles.barHolder} ${className}`}>
    <CircularProgressbarWithChildren
      value={value}
      strokeWidth={7}
      styles={{
        trail: {
          stroke: '#98acbb',
        },
        path: {
          // Path color
          stroke: '#0e9354',

          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
        },
        text: {
          // Text color
          fill: '#98acbb',
          // Text size
          fontSize: '18px',
        },
      }}
    >
      {value && Number(value) === 100 ? (
        <div className={styles.check}>
          <Icon className="tuf-check-thin" />
        </div>
      ) : (
        <div className={`${styles.text} ${Number(value) === 0 ? styles.zero : ''}`}>{text || `${value}%`}</div>
      )}
    </CircularProgressbarWithChildren>
  </div>
);

CircularProgressbar.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  text: PropTypes.string,
  className: PropTypes.string,
};

CircularProgressbar.defaultProps = {
  className: '',
  value: 0,
  text: '',
};
export default CircularProgressbar;
