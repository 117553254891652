import PropTypes from "prop-types";
import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Form } from "formsy-semantic-ui-react";
import { fetchContractorsList } from "../../actions/Contractors/contractors";
import { Dropdown } from "semantic-ui-react";

const SelectContractor = ({
  actions: { fetchContractors },
  contractorsList,
  isFetching,
  required,
  error,
  minItemForSearch,
  onChange,
  native,
  additionalOptions,
  ...extraProps
}) => {
  useEffect(() => {
    fetchContractors();
  }, [fetchContractors]);

  const options = useMemo(
    () => [
      ...additionalOptions,
      ...contractorsList
        .sort(content => content.name)
        .map(content => ({
          key: content.id,
          text: content.name,
          value: content.id
        }))
    ],
    [contractorsList]
  );

  useEffect(() => {
    if (required && options && options.length === 1) {
      onChange(null, { options, value: options[0].value });
    }
  }, [options]); // eslint-disable-line

  const props = {
    placeholder: "Select contractor",
    fluid: true,
    ...extraProps,
    error,
    closeOnBlur: true,
    onChange,
    loading: isFetching,
    selection: true,
    required,
    search: options.length >= minItemForSearch,
    noResultsMessage: "Nothing was found",
    options
  };
  return native ? <Dropdown {...props} /> : <Form.Dropdown {...props} />;
};

SelectContractor.propTypes = {
  actions: PropTypes.object.isRequired,
  additionalOptions: PropTypes.array,
  contractorsList: PropTypes.array.isRequired,
  disabled: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool,
  minItemForSearch: PropTypes.number,
  native: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.array.isRequired,
    PropTypes.number.isRequired
  ])
};

SelectContractor.defaultProps = {
  additionalOptions: [],
  contractorsList: [],
  disabled: false,
  error: false,
  isFetching: false,
  minItemForSearch: 10,
  native: false,
  onChange: () => {},
  required: false,
  value: []
};

const mapStateToProps = ({
  contractors: {
    list: { content, isFetching }
  }
}) => {
  return {
    contractorsList: content,
    isFetching
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchContractors: fetchContractorsList
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectContractor);
