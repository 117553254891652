import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { Header, Icon, Segment, Table } from "semantic-ui-react";
import styles from "../QCFarm.module.css";
import xls from "../assets/xls.svg";
import ToolTip from "../../../components/ToolTip";
import { bindActionCreators } from "redux";
import { archiveQCForm } from "../../../actions/QualityControl/forms";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import QcFormSubmission from "./QCFormSubmission";
import { copyStyles } from "../../../utils/styles";
import QCPDFReport from "./QCPDFReport";
import ReactDOMServer from "react-dom/server";

function QualityCheck({ form, actions: { archiveQCForm } }) {
  const [openTable, toggleOpenTable] = useState(false);

  const print = useCallback(() => {
    const newWindow = window.open();
    newWindow.document.title = ``;
    copyStyles(window.document, newWindow.document);
    newWindow.document.body.innerHTML = ReactDOMServer.renderToString(
      <QCPDFReport
        formTemplate={form.formTemplate}
        submissions={form.completedForms}
      />
    );
    newWindow.focus();
    setTimeout(function() {
      newWindow.print();
    }, 2000);
  }, [form.completedForms, form.formTemplate]);

  const isAllowedToEdit = !form.completedForms.length;

  return (
    <Segment className={styles.tableFarmHolder}>
      <Table
        selectable
        className={`${styles.tableFarm} ${openTable ? styles.tableOpen : ""}`}
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={1}>
              <div
                className={`${styles.toggleIcon} ${
                  openTable ? styles.openTable : ""
                }`}
              >
                <Icon
                  className={
                    openTable ? "tuf-chevron-down" : "tuf-chevron-right"
                  }
                  onClick={() => toggleOpenTable(!openTable)}
                />
              </div>
            </Table.HeaderCell>
            <Table.HeaderCell width={7}>
              <Header
                data-id={"farm-header"}
                size={"large"}
                className={
                  openTable ? styles.farmNameSelected : styles.farmName
                }
                onClick={e => {
                  e.preventDefault();
                  toggleOpenTable(true);
                }}
              >
                {form.name}
              </Header>
            </Table.HeaderCell>
            <Table.HeaderCell width={4}>
              <div>Assigned employees</div>
              <br />
              <b>{form.permissions.length}</b>
            </Table.HeaderCell>
            <Table.HeaderCell width={5}>
              {form.completedForms.length > 0 && (
                <>
                  <div>Report</div>
                  <br />
                  <div>
                    <a onClick={print} href="javascript:void(0)">
                      <img alt="" src={xls} style={{ marginRight: 10 }} />
                      Download XLS
                    </a>
                  </div>
                </>
              )}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" width={2}>
              {!form.archived && isAllowedToEdit ? (
                <ToolTip content="Edit">
                  <Link to={"/harvest/qc/form/" + form.id}>
                    <Icon className={`tuf-pen ${styles.tasksEditIcon}`} />
                  </Link>
                </ToolTip>
              ) : (
                <Icon className="blank" />
              )}
              <ToolTip content={form.archived ? "Activate" : "Archive"}>
                <Icon
                  className={form.archived ? "tuf-unarchive" : "tuf-archive"}
                  onClick={() => archiveQCForm(form.id, !form.archived)}
                />
              </ToolTip>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        {openTable && (
          <Table.Body>
            <Table.Row>
              {form.completedForms.length > 0 ? (
                <Table.Cell
                  textAlign="center"
                  colSpan={10}
                  style={{ padding: 0 }}
                >
                  <QcFormSubmission
                    submissions={form.completedForms}
                    formTemplate={form.formTemplate}
                  />
                </Table.Cell>
              ) : (
                <Table.Cell textAlign="center" colSpan={10}>
                  <div>No form submissions</div>
                </Table.Cell>
              )}
            </Table.Row>
          </Table.Body>
        )}
      </Table>
    </Segment>
  );
}

QualityCheck.propTypes = {
  form: PropTypes.object,
  actions: PropTypes.object
};

QualityCheck.defaultProps = {};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ archiveQCForm }, dispatch)
});

export default connect(() => ({}), mapDispatchToProps)(QualityCheck);
