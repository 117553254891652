import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import moment from "moment";
import { connect } from "react-redux";

import {
  infoMessageType,
  messagesIds,
  removeMessage
} from "../../actions/InfoMessages";
import InfoMessage from "../InfoMessage/InfoMessage";
import styles from "./InfoMessages.module.css";

const messageTypeConfig = {
  [infoMessageType.INFO]: {
    color: "blue"
  },
  [infoMessageType.WARN]: {
    color: "yellow"
  },
  [infoMessageType.ERROR]: {
    color: "red"
  }
};

const messagesIdToMessage = {
  [messagesIds.GENERIC]: infoMessage => infoMessage.extraData.message,
  [messagesIds.GENERIC_CODE]: infoMessage => infoMessage.extraData.message
};

const parseMessage = (infoMessage, showOffline) => {
  return {
    autoHide: true,
    showOffline: showOffline || true,
    ...infoMessage,
    ...messageTypeConfig[infoMessage.type],
    message:
      messagesIdToMessage[infoMessage.messageId] &&
      messagesIdToMessage[infoMessage.messageId](infoMessage),
    duration: moment
      .duration(
        moment(infoMessage.ts)
          .add(
            (infoMessage.duration && infoMessage.duration.amount) || "5",
            (infoMessage.duration && infoMessage.duration.unit) || "second"
          )
          .diff(moment())
      )
      .asMilliseconds()
  };
};

const InfoMessages = ({
  online,
  infoMessages,
  actions: { removeMessage },
  showOffline = false
}) => {
  const parsedMessages = useMemo(
    () => infoMessages.map(message => parseMessage(message, showOffline)),
    [infoMessages]
  );

  return (
    parsedMessages.length > 0 && (
      <div className={styles.infoMessages}>
        <div className={styles.infoMessagesIn}>
          {parsedMessages.map(conf => {
            if (!conf.showOffline && !online) {
              removeMessage(conf.id);
              return null;
            }

            return (
              <InfoMessage
                key={conf.id}
                showOffline={showOffline}
                message={conf.message}
                extraData={conf.extraData}
                color={conf.color}
                duration={conf.duration}
                autoHide={conf.autoHide}
                onClose={() => removeMessage(conf.id)}
              />
            );
          })}
        </div>
      </div>
    )
  );
};

InfoMessages.propTypes = {
  infoMessages: PropTypes.array,
  removeMessage: PropTypes.func,
  online: PropTypes.bool,
  actions: PropTypes.object
};

InfoMessages.defaultProps = {};

function mapStateToProps({
  generalTasksWorkingTimes: { showOffline },
  offline: { online },
  infoMessages: { infoMessages }
}) {
  return {
    online,
    infoMessages,
    showOffline
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        removeMessage
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InfoMessages);
