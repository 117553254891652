import PropTypes from "prop-types";
import React, { memo, useState } from "react";
import { Container, Grid, Header, Menu } from "semantic-ui-react";
import moment from "moment";
import styles from "./GeneralTasksSidebar.module.css";
import GeneralTasksCostsBreakdown from "../../../../components/GeneralTasksCostsBreakdown";
import Attachments from "../../../../components/Attachments";
import { minutesToHM } from "../../../../utils/timeUtils";

const TaskFieldRow = ({ name, value, units = "", show = true }) => {
  if (!show) return <React.Fragment />;

  return (
    <Grid.Row className={styles.taskField}>
      <Grid.Column width={4}>
        <b>{name}:</b>
      </Grid.Column>
      <Grid.Column width={12}>{value ? value + units : "-"}</Grid.Column>
    </Grid.Row>
  );
};

const TABS = {
  INSTRUCTIONS: "INSTRUCTIONS",
  TASK_TYPE: "TASK_TYPE",
  COSTINGS: "COSTINGS"
};

const formatDate = date => (date ? moment(date).format("DD/MM/YYYY") : "-");

const GeneralTasksSidebar = ({ task }) => {
  const [currentTab, setCurrentTab] = useState(TABS.INSTRUCTIONS);
  const totalSize = task?.areas
    ?.map(item => item.area.size)
    ?.reduce((a, b) => a + b, 0)
    .toFixed(2);
  const currentTask = task || { type: { allowedFields: [] }, areas: [] };

  const switchTab = tab => () => setCurrentTab(tab);

  const areas = currentTask.areas || [];

  return (
    <Container>
      <Grid style={{ maxWidth: "100%" }}>
        <Grid.Row>
          <Grid.Column className={styles.header}>
            <Header as={"h1"}>GT-{currentTask.id}</Header>
          </Grid.Column>
        </Grid.Row>
        <TaskFieldRow name={"Task Name"} value={currentTask.name} />
        <TaskFieldRow
          name={"Task Type"}
          value={currentTask.type && currentTask.type.name}
        />
        <TaskFieldRow name={"Sub Task"} value={currentTask.subTaskName} />
        <TaskFieldRow
          name={"Status"}
          value={(currentTask.status || "").replace(/_/, " ")}
        />
        <TaskFieldRow
          name={"Date Range"}
          value={`${formatDate(currentTask.startAt)} - ${formatDate(
            currentTask.endAt
          )}`}
        />
        <TaskFieldRow name={"Priority"} value={currentTask.priority} />
        <TaskFieldRow
          name={"Size of Area"}
          value={(currentTask.totalAreaSize || 0).toFixed(2)}
          units={"Ha"}
        />
        <Grid.Row>
          <Grid.Column>
            <Menu pointing secondary className={styles.tabsMenu}>
              <Menu.Item
                name="Instructions"
                active={currentTab === TABS.INSTRUCTIONS}
                onClick={switchTab(TABS.INSTRUCTIONS)}
              />
              <Menu.Item
                name="Task Type"
                active={currentTab === TABS.TASK_TYPE}
                onClick={switchTab(TABS.TASK_TYPE)}
              />
              <Menu.Item
                name="Costings"
                active={currentTab === TABS.COSTINGS}
                onClick={switchTab(TABS.COSTINGS)}
              />
            </Menu>
          </Grid.Column>
        </Grid.Row>
        {currentTab === TABS.INSTRUCTIONS && (
          <>
            <TaskFieldRow
              name={"Farm"}
              value={currentTask.farm ? currentTask.farm.name : "-"}
            />
            <TaskFieldRow
              name={"Areas"}
              value={areas.map(({ area }) => area.name).join(", ")}
            />
            <TaskFieldRow
              name={"Crop"}
              value={currentTask.crop ? currentTask.crop.name : "-"}
            />
            <TaskFieldRow
              name={"Variety"}
              value={currentTask.variety ? currentTask.variety.name : "-"}
            />
            <TaskFieldRow
              name={"Required Equipment"}
              value={(currentTask.equipments || [])
                .map(({ machinery }) => machinery.name)
                .join(", ")}
            />
            <TaskFieldRow
              name={"Number of Employees "}
              value={currentTask?.assignedTo?.length}
            />
            <TaskFieldRow
              name={"Payment Method"}
              value={(currentTask.paymentMethod || "").replace(/_/, " ")}
            />
            <Grid.Row className={styles.taskField}>
              <Grid.Column width={4}>
                <b>Attachments:</b>
              </Grid.Column>
              <Grid.Column width={12}>
                {(currentTask.attachments || []).length > 0 ? (
                  <Attachments
                    canEdit={false}
                    isUploading={false}
                    attachments={currentTask.attachments}
                  />
                ) : (
                  "-"
                )}
              </Grid.Column>
            </Grid.Row>
          </>
        )}
        {currentTab === TABS.TASK_TYPE && (
          <>
            <TaskFieldRow name={"Round"} value={currentTask.round} />
            <TaskFieldRow
              name={"Picking Type"}
              value={
                currentTask.harvestUnitPickingType
                  ? currentTask.harvestUnitPickingType.name
                  : null
              }
              show={currentTask.type.allowedFields.includes(
                "HARVEST_UNIT_PICKING_TYPE"
              )}
            />
            <TaskFieldRow
              name={"Harvest Unit"}
              value={
                currentTask.harvestUnit ? currentTask.harvestUnit.name : null
              }
              show={currentTask.type.allowedFields.includes("HARVEST_UNIT")}
            />
            <TaskFieldRow
              show={currentTask.type.allowedFields.includes("TIME_BUDGETING")}
              name={"Time Budgeting"}
              value={currentTask.timeBudgeting}
            />
            <TaskFieldRow
              show={currentTask.type.allowedFields.includes(
                "TIME_BUDGETING_PER_ROW"
              )}
              name={"Per Row"}
              value={currentTask.timeBudgetingPerRow}
            />
            <TaskFieldRow
              show={currentTask.type.allowedFields.includes(
                "TIME_BUDGETING_PER_PLANT"
              )}
              name={"Per Plant"}
              value={currentTask.timeBudgetingPerPlant}
            />
            <TaskFieldRow
              name={"Running Time"}
              value={minutesToHM(currentTask.runningTimeMin)}
              show={currentTask.type.allowedFields.includes("RUNNING_TIME_MIN")}
            />
            <TaskFieldRow
              name={"Flow Rate (L/Hr)"}
              value={currentTask.flowRate + " L/Hr"}
              show={currentTask.type.allowedFields.includes("FLOW_RATE")}
            />
            <TaskFieldRow
              name={"Total Irrigation Water (L)"}
              value={currentTask.totalIrrigationWater + " L"}
              show={currentTask.type.allowedFields.includes(
                "TOTAL_IRRIGATION_WATER"
              )}
            />
            <TaskFieldRow name={"Comments"} value={currentTask.comments} />
          </>
        )}
        {currentTab === TABS.COSTINGS && (
          <Grid.Row>
            <Grid.Column>
              <GeneralTasksCostsBreakdown
                breakdownTitle={currentTask.name}
                tasks={[currentTask]}
                totalSize={totalSize}
                isFarm
                manual
                showDetailedInfo={false}
              />
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </Container>
  );
};

GeneralTasksSidebar.propTypes = {
  task: PropTypes.object
};

GeneralTasksSidebar.defaultProps = {
  task: {}
};

TaskFieldRow.propTypes = {
  name: PropTypes.string.isRequired,
  show: PropTypes.bool,
  units: PropTypes.string,
  value: PropTypes.any
};

TaskFieldRow.defaultProps = {
  show: true,
  units: ""
};

export default memo(GeneralTasksSidebar);
