import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Dropdown, Header, Input } from "semantic-ui-react";
import Checkbox from "../../components/Table/components/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, map, keyBy, debounce } from "lodash";
import styles from "./MultiSelect.module.css";

const MultiSelect = ({
  name,
  text,
  selector,
  loaderAction,
  onOptionChange,
  upward,
  value,
  width,
  withAsyncSearch,
  search,
  containerElementId
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [shownOptions, setShownOptions] = useState([]);
  const { isFetching, options } = useSelector(selector);
  const dispatch = useDispatch();
  const [dropDownOpen, setDropDownOpen] = useState(false);

  useEffect(() => {
    if (
      isFetching === false &&
      isEmpty(options) &&
      typeof loaderAction === "function"
    ) {
      dispatch(loaderAction());
    }
  }, [isFetching, options, loaderAction]);

  const onDropdownClose = () => {
    const valuesMap = keyBy(value);

    const selectedFirstArray = options.sort((x, y) => {
      const isXSelected = valuesMap[x.value];
      const isYSelected = valuesMap[y.value];

      return isXSelected === isYSelected ? 0 : isXSelected ? -1 : 1;
    });
    setDropDownOpen(false);
    setShownOptions(selectedFirstArray);
  };

  useEffect(() => {
    if (containerElementId && dropDownOpen) {
      const container = document.querySelector(containerElementId);
      container.scrollTop = container.scrollHeight;
    }
  }, [dropDownOpen]);

  useEffect(() => {
    setShownOptions(
      (options || []).filter(item =>
        item.label.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [options, searchQuery]);

  const performSearch = useCallback(searchQuery => {
    dispatch(loaderAction({ search: searchQuery }));
  }, []);

  const debouncedSearchHandler = useMemo(
    () => debounce(performSearch, 500),
    []
  );

  useEffect(() => {
    if (withAsyncSearch) {
      debouncedSearchHandler(searchQuery);
    }
  }, [searchQuery, withAsyncSearch]);

  return (
    <Dropdown
      text={text}
      multiple
      upward={upward ? upward : false}
      className={styles.multiSelectDropdown}
      loading={isFetching}
      closeOnChange={false}
      value={value}
      width={width}
      open={dropDownOpen}
      onClick={() => {
        setDropDownOpen(!dropDownOpen);
      }}
      onClose={onDropdownClose}
      selectOnBlur={false}
    >
      <Dropdown.Menu>
        <Dropdown.Menu scrolling>
          {search && (
            <Input
              placeholder="Type the name…"
              className={styles.search}
              onClick={e => e.stopPropagation()}
              onChange={(_, { value }) => setSearchQuery(value)}
            />
          )}
          {map(shownOptions, option => (
            <Dropdown.Item
              key={option.value}
              value={option.value}
              text={option.label}
              content={
                <>
                  <Checkbox
                    label={option.label}
                    checked={
                      Array.isArray(value) && value.includes(option.value)
                    }
                  />
                  <div className={styles.dropdownItemSuffix}>
                    {option.group}
                  </div>
                </>
              }
              onClick={(e, item) => {
                e.stopPropagation();
                if (value.includes(item.value)) {
                  const valuesToSet = value.filter(val => val !== item.value);
                  onOptionChange(`${name}`, valuesToSet);
                } else {
                  onOptionChange(`${name}`, [...value, item.value]);
                }
              }}
            />
          )).sort((a, b) => a.props.text.localeCompare(b.props.text))}
        </Dropdown.Menu>
        {!!value?.length && (
          <>
            <Dropdown.Divider />
            <Header
              className={styles.clearButton}
              onClick={() => onOptionChange(`${name}`, [])}
            >
              Clear selection
            </Header>
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MultiSelect;
