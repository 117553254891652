export const nodify = (node, parent) => {
  const temp = {
    ...node,
    parent
  };

  temp.children = node.children.map(node => nodify(node, temp));

  return temp;
};

export const makeTree = structure => {
  const temp = { children: [] };

  temp.children = structure.map(node => nodify(node, temp));
  temp.parent = temp;

  return temp;
};
