import { SET_VIEW_MODE } from "../../constants/Application";

const initialState = {
  showParentBlocks: true
};

export default (state = initialState, action) => {
  if (!action) return state;

  switch (action.type) {
    case SET_VIEW_MODE: {
      return {
        ...state,
        showParentBlocks: !state.showParentBlocks
      };
    }
    default:
      return state;
  }
};
