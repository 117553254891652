import mixpanel from 'mixpanel-browser';
import { userSelector } from '../../selectors/user';
import { store } from '../../index';
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);

const properties = {
  EMPLOYEE_ID: 'Employee id',
  CLIENT_ID: 'Client id',
  ENV: 'Environment',
};

let actions = {
  identify: () => {
    const user = userSelector(store.getState());
    mixpanel.identify(user && user.profile ? user.profile.user_id : null);
  },
  alias: id => {
    mixpanel.alias(id);
  },
  track: (name, props) => {
    const user = userSelector(store.getState());
    mixpanel.track(name, {
      ...props,
      [properties.CLIENT_ID]: user && user.profile ? user.profile.client_id : null,
      [properties.ENV]: process.env.REACT_APP_ENVIRONMENT,
    });
  },
  people: {
    set: props => {
      mixpanel.people.set(props);
    },
  },
  updateUserProperties: () => {
    const user = userSelector(store.getState());
    mixpanel.people.set({
      $email: user && user.profile ? user.profile.email : null,
      $last_login: new Date(),
      $first_name: user && user.profile ? user.profile.given_name : null,
      $last_name: user && user.profile ? user.profile.family_name : null,
      [properties.EMPLOYEE_ID]: user && user.profile ? user.profile.employee_id : null,
      [properties.CLIENT_ID]: user && user.profile ? user.profile.client_id : null,
      [properties.ENV]: process.env.REACT_APP_ENVIRONMENT,
    });
  },
};

export let Mixpanel = actions;
