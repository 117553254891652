export const actionType = {
  FETCH_CONTRACTORS_START: "CONTRACTORS/FETCH_START",
  FETCH_CONTRACTORS: "CONTRACTORS/FETCH",
  FETCH_CONTRACTORS_FAIL: "CONTRACTORS/FETCH_FAIL",

  FETCH_CONTRACTORS_LIST_START: "CONTRACTORS/FETCH_LIST_START",
  FETCH_CONTRACTORS_LIST: "CONTRACTORS/FETCH_LIST",
  FETCH_CONTRACTORS_LIST_FAIL: "CONTRACTORS/FETCH_LIST_FAIL",

  GET_CONTRACTORS_BY_ID_START: "CONTRACTORS/GET_BY_ID_START",
  GET_CONTRACTORS_BY_ID: "CONTRACTORS/GET_BY_ID",
  GET_CONTRACTORS_BY_ID_FAIL: "CONTRACTORS/GET_BY_ID_FAIL",

  ADD_CONTRACTORS_START: "CONTRACTORS/ADD_START",
  ADD_CONTRACTORS: "CONTRACTORS/ADD",
  ADD_CONTRACTORS_FAIL: "CONTRACTORS/ADD_FAIL",

  UPDATE_CONTRACTORS_START: "CONTRACTORS/UPDATE_START",
  UPDATE_CONTRACTORS: "CONTRACTORS/UPDATE",
  UPDATE_CONTRACTORS_FAIL: "CONTRACTORS/UPDATE_FAIL",

  DELETE_CONTRACTOR_START: "CONTRACTORS/DELETE_START",
  DELETE_CONTRACTOR: "CONTRACTORS/DELETE",
  DELETE_CONTRACTOR_FAIL: "CONTRACTORS/DELETE_FAIL",

  DELETE_CONTRACTORS_START: "CONTRACTORS/DELETE_CONTRACTORS_START",
  DELETE_CONTRACTORS: "CONTRACTORS/DELETE_CONTRACTORS",
  DELETE_CONTRACTORS_FAIL: "CONTRACTORS/DELETE_CONTRACTORS_FAIL",

  CLEAR_CONTRACTORS_ERROR: "CONTRACTORS/CLEAR_CONTRACTORS_ERROR",

  GET_EMPLOYEE_LIST_START: "CONTRACTORS/GET_EMPLOYEE_LIST_START",
  GET_EMPLOYEE_LIST_SUCCESS: "CONTRACTORS/GET_EMPLOYEE_LIST_SUCCESS",
  GET_EMPLOYEE_LIST_ERROR: "CONTRACTORS/GET_EMPLOYEE_LIST_ERROR",
  SELECT_EMPLOYEES: "CONTRACTORS/SELECT_EMPLOYEES", //check used or not
  SELECT_SINGLE_EMPLOYEE: "CONTRACTORS/SELECT_SINGLE_EMPLOYEE", // check used or not

  ARCHIVE_CONTRACTORS_START: "CONTRACTORS/ARCHIVE_CONTRACTORS_START",
  ARCHIVE_CONTRACTORS: "CONTRACTORS/ARCHIVE_CONTRACTORS",
  ARCHIVE_CONTRACTORS_FAIL: "CONTRACTORS/ARCHIVE_CONTRACTORS_FAIL"
};
