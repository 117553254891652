import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Grid, Icon, Input } from "semantic-ui-react";
import PropTypes from "prop-types";
import moment from "moment";

import styles from "./ExpandedLocalDataTable.module.css";
import Pagination from "../ListTable/Pagination";
import SortDropdown from "../SortDropdown";
import DatePicker from "../DatePicker";
import ExportToExcel from "../ExportToExcel";
import ExpandedTable from "../ExpandedTable";
import ExpandedTablePrintButton from "./ExpandedTablePrintButton";

const renderCell = props => {
  const {
    value,
    column: { Header }
  } = props;

  return (
    <div>
      <div className="cellTitle hide-md hide-print">{Header}</div>
      <div className="cellValue">{value}</div>
    </div>
  );
};

renderCell.propTypes = {
  value: PropTypes.any,
  column: PropTypes.object
};

const generatePagination = (pagination, setPageSize, gotoPage) => (
  <Pagination
    {...pagination}
    pageSizeOptions={[10, 20, 50, 100]}
    updatePageSize={(_, { value: pageSize }) => setPageSize(pageSize)}
    onPageChangeOwn={(currentPage, loadMore) => {
      loadMore
        ? setPageSize(currentPage * pagination.pageSize + pagination.pageSize)
        : gotoPage(currentPage);
    }}
  />
);

const ExpandedLocalDataTable = ({
  name,
  columns,
  data,
  getData,
  loading,
  searchColumn,
  showFilters,
  defaultSort,
  defaultGroupBy,
  dynamicGrouping,
  withDateRange,
  withRowSelect,
  exportableToExcel,
  printable,
  printTitle
}) => {
  const [filters, setFilters] = useState({});
  const [sortBy, setSortBy] = useState(
    (defaultSort && {
      id: defaultSort.split(",")[0],
      desc: defaultSort.split(",")[1] === "desc"
    }) ||
      null
  );
  const [printProps, setPrintProps] = useState({
    rows: [],
    headerGroups: [],
    prepareRow: () => true
  });

  const [dateFrom, setDateFrom] = useState(
    moment(moment.now())
      .subtract(0, "days")
      .format("YYYY-MM-DD")
  );
  const [dateTo, setDateTo] = useState(
    moment(moment.now()).format("YYYY-MM-DD")
  );

  const refresh = useCallback(
    () => getData({ from: dateFrom, to: dateTo, unpaged: true }),
    [getData, dateFrom, dateTo]
  );

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  const tableColumns = useMemo(
    () =>
      columns.map(column => {
        return {
          accessor: column.id,
          Cell: renderCell,
          ...column,
          className: `cell_${column.width} ${column.className || ""}`
        };
      }),
    [columns]
  );

  const sortOptions = useMemo(() => {
    return tableColumns
      .filter(
        column =>
          column.accessor !== "actions" && column.Header && !column.disableSort
      )
      .map(column => ({
        key: column.id,
        text: `Sort by ${column.Header}`,
        value: column.id,
        content: column.Header,
        sortfields: column.sortFields || [column.id]
      }));
  }, [tableColumns]);

  const onSortChange = useCallback(({ sortValue, sortOrder, sort }) => {
    setSortBy(
      sort.length > 0 ? { id: sortValue, desc: sortOrder === "desc" } : null
    );
  }, []);

  const sortByArray = useMemo(() => sortBy && [sortBy], [sortBy]);

  const setFilter = useCallback(
    (name, value) => setFilters({ ...filters, [name]: value }),
    [filters, setFilters]
  );

  const filtersArray = useMemo(
    () => Object.keys(filters).map(key => ({ id: key, value: filters[key] })),
    [filters]
  );

  const onRowsChanged = useCallback(props => {
    setPrintProps(props);
  }, []);

  exportableToExcel = exportableToExcel ? exportableToExcel : printable;

  const dataToExport = useMemo(() => {
    const columnsToExport = columns.filter(c => c.accessor !== "actions");
    const mappedData = data.map(dataObject => {
      const temp = {};

      columnsToExport.forEach(column => {
        column.accessor = column.accessor || column.id;
        if (typeof column.accessor === "string") {
          temp[column.id] = dataObject[column.accessor];
        }
        if (typeof column.accessor === "function") {
          temp[column.id] = column.accessor(dataObject);
        }
      });

      return temp;
    });
    return {
      columnsToExport: columnsToExport.map(c => ({ Header: c.id, id: c.id })),
      mappedData
    };
  }, [columns, data]);

  return (
    <Grid verticalAlign="middle">
      {withDateRange && (
        <Grid.Row verticalAlign="bottom">
          <Grid.Column mobile={16} tablet={10} computer={3}>
            <DatePicker
              value={dateFrom}
              onChange={date => setDateFrom(date)}
              labelText={"From"}
            />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={10} computer={3}>
            <DatePicker
              value={dateTo}
              onChange={date => setDateTo(date)}
              labelText={"To"}
            />
          </Grid.Column>
          <Grid.Column mobile={8} tablet={10} computer={3}>
            <Button onClick={refresh} size={"large"}>
              <Icon name="repeat" />
              <span>Refresh</span>
            </Button>
          </Grid.Column>
        </Grid.Row>
      )}
      <Grid.Row>
        <Grid.Column mobile={8} tablet={10} computer={13}>
          <div className={styles.filtersHolder}>
            {searchColumn && (
              <Input
                icon="search"
                placeholder="Search"
                onChange={e => setFilter(searchColumn, e.target.value)}
                className={styles.filterItem}
                size={"large"}
              />
            )}
            {columns
              .filter(column => column.ExternalFilter)
              .map(column => (
                <column.ExternalFilter
                  key={`filter_${column.id}`}
                  value={filters[column.id]}
                  className={styles.filterItem}
                  setFilter={value => setFilter(column.id, value)}
                  fulid={false}
                />
              ))}
          </div>
        </Grid.Column>
        <Grid.Column
          verticalAlign={"bottom"}
          mobile={8}
          tablet={6}
          computer={3}
          textAlign="right"
          className={styles.sortColumn}
        >
          {exportableToExcel && (
            <ExportToExcel
              columns={dataToExport.columnsToExport}
              rawData={dataToExport.mappedData}
              name={name}
              className={"hide-on-mobile"}
            />
          )}
          {printable && (
            <ExpandedTablePrintButton title={printTitle} {...printProps} />
          )}
          {sortOptions && sortOptions.length > 0 && (
            <SortDropdown
              sortOptions={sortOptions}
              onChange={onSortChange}
              sortValue={sortBy ? sortBy.id : "none"}
              sortOrder={sortBy && sortBy.desc ? "desc" : "asc"}
              defaultSort={defaultSort}
            />
          )}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column
          mobile={16}
          tablet={16}
          computer={16}
          largeScreen={16}
          widescreen={16}
        >
          <ExpandedTable
            data={data}
            columns={tableColumns}
            className={`-striped ${styles.listTable}`}
            pagination={generatePagination}
            showFilters={showFilters}
            dynamicGrouping={dynamicGrouping}
            loading={loading}
            sortBy={sortByArray}
            groupBy={defaultGroupBy}
            filters={filtersArray}
            withRowSelect={withRowSelect}
            defaultExpandedLevel={defaultGroupBy.length - 1}
            noDataText="No data available"
            onRowsChanged={onRowsChanged}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

ExpandedLocalDataTable.propTypes = {
  columns: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  getData: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  showFilters: PropTypes.bool,
  defaultGroupBy: PropTypes.array.isRequired,
  searchColumn: PropTypes.string,
  name: PropTypes.string,
  printTitle: PropTypes.string,
  defaultSort: PropTypes.string.isRequired,
  withDateRange: PropTypes.bool,
  loading: PropTypes.bool,
  withRowSelect: PropTypes.bool,
  exportableToExcel: PropTypes.bool,
  dynamicGrouping: PropTypes.bool,
  printable: PropTypes.bool
};

ExpandedLocalDataTable.defaultProps = {
  withDateRange: true,
  exportableToExcel: false,
  isFetching: false,
  withRowSelect: false,
  getData: () => {},
  defaultSort: "",
  defaultGroupBy: [],
  showFilters: false,
  dynamicGrouping: false,
  printable: false
};

export default ExpandedLocalDataTable;
