import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Header, Table } from "semantic-ui-react";
import moment from "moment";
import classNames from "classnames";
import styles from "./PrintReport.module.css";
import Logo from "../../../../../components/Logo";
import Numeric from "../../../../../components/Numeric";
import { minutesToHM, dateRangeToString } from "../../../../../utils/timeUtils";

class PrintReport extends React.Component {
  render() {
    const {
      payrolls,
      payrollTotals,
      title,
      showExpandedData,
      startDate,
      endDate
    } = this.props;

    return (
      <div className={styles.reportWrapper}>
        {title && <title>{title}</title>}
        <style>{`html,body {margin: 0px; background: #F5F7F7}`}</style>
        <div className={styles.reportHeaderRow}>
          <div>
            {title && <div className={styles.reportHeader}>{title}</div>}
            {startDate && endDate && (
              <div className={styles.reportSubHeader}>
                {dateRangeToString(startDate, endDate)}
              </div>
            )}
          </div>
          <Logo width={178} height={75} />
        </div>
        {payrolls.length > 0 ? (
          <Table basic="very" className={styles.reportTable}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Employee name</Table.HeaderCell>
                <Table.HeaderCell>Contractor</Table.HeaderCell>
                <Table.HeaderCell>Working time</Table.HeaderCell>
                <Table.HeaderCell>NUM. of pieces</Table.HeaderCell>
                <Table.HeaderCell>Total Pay</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {payrolls.map(
                (
                  {
                    employeeName,
                    contractor,
                    workingSeconds,
                    numberOfPieces,
                    totalPay,
                    expandedData
                  },
                  index
                ) => (
                  <Fragment key={`payroll_${index}`}>
                    <Table.Row
                      className={classNames({
                        [styles.stripedRow]: index % 2 === 0
                      })}
                    >
                      <Table.Cell>{employeeName}</Table.Cell>
                      <Table.Cell>{contractor || "—"}</Table.Cell>
                      <Table.Cell>
                        {minutesToHM(workingSeconds / 60)}
                      </Table.Cell>
                      <Table.Cell>
                        <Numeric
                          value={numberOfPieces}
                          fractionDigits={2}
                          commaSeparatorOnThousands
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Numeric
                          value={totalPay}
                          fractionDigits={2}
                          commaSeparatorOnThousands
                          units="$ "
                          unitsPosition="left"
                        />
                      </Table.Cell>
                    </Table.Row>
                    {showExpandedData && expandedData && (
                      <Table.Row
                        className={classNames({
                          [styles.stripedRow]: index % 2 === 0
                        })}
                      >
                        <Table.Cell
                          colSpan={7}
                          className={styles.additionalInfoContainer}
                        >
                          <Table
                            basic="very"
                            unstackable
                            className={styles.additionalInfoTable}
                          >
                            <Table.Header>
                              <Table.Row>
                                <Table.HeaderCell>Date</Table.HeaderCell>
                                <Table.HeaderCell>Task ID</Table.HeaderCell>
                                <Table.HeaderCell>Task type</Table.HeaderCell>
                                <Table.HeaderCell>
                                  Working time
                                </Table.HeaderCell>
                                <Table.HeaderCell>Unit type</Table.HeaderCell>
                                <Table.HeaderCell>
                                  NUm. of pieces
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                  Rate($/piece)
                                </Table.HeaderCell>
                                <Table.HeaderCell>Total Pay</Table.HeaderCell>
                              </Table.Row>
                            </Table.Header>
                            <Table.Body>
                              {expandedData.length > 0 ? (
                                expandedData.map(
                                  (
                                    {
                                      date,
                                      taskId,
                                      taskType,
                                      workingSeconds,
                                      unitType,
                                      numberOfPieces,
                                      rate
                                    },
                                    index
                                  ) => (
                                    <Table.Row key={`expanded_row_${index}`}>
                                      <Table.Cell>
                                        {moment(date).format("DD/MM/YYYY")}
                                      </Table.Cell>
                                      <Table.Cell>{taskId || "—"}</Table.Cell>
                                      <Table.Cell>{taskType || "—"}</Table.Cell>
                                      <Table.Cell>
                                        {minutesToHM(workingSeconds / 60)}
                                      </Table.Cell>
                                      <Table.Cell>{unitType || "—"}</Table.Cell>
                                      <Table.Cell>
                                        <Numeric
                                          value={numberOfPieces}
                                          fractionDigits={2}
                                          commaSeparatorOnThousands
                                        />
                                      </Table.Cell>
                                      <Table.Cell>
                                        <Numeric
                                          value={rate}
                                          fractionDigits={2}
                                          commaSeparatorOnThousands
                                          units="$ "
                                          unitsPosition="left"
                                        />
                                      </Table.Cell>
                                      <Table.Cell>
                                        <Numeric
                                          value={numberOfPieces * rate}
                                          fractionDigits={2}
                                          commaSeparatorOnThousands
                                          units="$ "
                                          unitsPosition="left"
                                        />
                                      </Table.Cell>
                                    </Table.Row>
                                  )
                                )
                              ) : (
                                <Header as="h4">Nothing found</Header>
                              )}
                            </Table.Body>
                          </Table>
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Fragment>
                )
              )}
            </Table.Body>
            <Table.Footer>
              <Table.Row
                className={classNames({
                  [styles.stripedRow]: payrolls.length % 2 === 0
                })}
              >
                <Table.HeaderCell colSpan={2}>Total</Table.HeaderCell>
                <Table.HeaderCell>
                  {minutesToHM(payrollTotals.workingSeconds / 60)}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Numeric
                    value={payrollTotals.numberOfPieces}
                    fractionDigits={2}
                    commaSeparatorOnThousands
                  />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Numeric
                    value={payrollTotals.totalPay}
                    fractionDigits={2}
                    commaSeparatorOnThousands
                    units="$ "
                    unitsPosition="left"
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        ) : (
          <Header as="h4">Nothing found</Header>
        )}
        <div className={styles.reportCreatedRow}>
          Report created {moment().format("DD/MM/YYYY")} using Tie Up Farming
        </div>
      </div>
    );
  }
}

PrintReport.propTypes = {
  payrolls: PropTypes.array.isRequired,
  payrollTotals: PropTypes.object.isRequired,
  title: PropTypes.string,
  showExpandedData: PropTypes.bool,
  endDate: PropTypes.any,
  startDate: PropTypes.any
};

export default PrintReport;
