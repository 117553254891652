import { axiosProxy } from '../../utils/axiosProxy';
import { actionType } from '../../constants/BoxUnits';

const url = '/box_units';

export const fetchBoxUnitsList = () => async dispatch => {
  try {
    dispatch({
      type: actionType.FETCH_BOX_UNITS_LIST_START,
    });

    const res = await axiosProxy({
      method: 'GET',
      url: `${url}`,
      params: {
        unpaged: true,
      },
    });

    dispatch({
      type: actionType.FETCH_BOX_UNITS_LIST,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: actionType.FETCH_BOX_UNITS_LIST_FAIL,
      payload: error,
    });
  }
};

export const fetchBoxUnits = ({ page, size, search, sort, unpaged = false }) => async dispatch => {
  try {
    dispatch({
      type: actionType.FETCH_BOX_UNITS_START,
    });

    const params = new URLSearchParams();
    if (unpaged) {
      params.append('unpaged', true);
    } else {
      params.append('page', page);
      params.append('size', size);
    }

    if (search) {
      params.append('search', search);
    }

    (sort || ['id,asc']).forEach(field => {
      params.append('sort', field);
    });

    const res = await axiosProxy({
      method: 'GET',
      params,
      url,
    });

    await dispatch({
      type: actionType.FETCH_BOX_UNITS,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.FETCH_BOX_UNITS_FAIL,
      payload: error,
    });
  }
};

export const getBoxUnitById = id => async dispatch => {
  try {
    dispatch({
      type: actionType.GET_BOX_UNITS_BY_ID_START,
    });
    const res = await axiosProxy({
      method: 'GET',
      url: `${url}/${id}`,
    });

    dispatch({
      type: actionType.GET_BOX_UNITS_BY_ID,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.GET_BOX_UNITS_BY_ID_FAIL,
      payload: error,
    });
    return Promise.reject(error);
  }
};

export const addBoxUnit = data => async dispatch => {
  try {
    dispatch({
      type: actionType.ADD_BOX_UNITS_START,
    });
    const res = await axiosProxy({
      method: 'POST',
      url,
      data,
    });

    await dispatch({
      type: actionType.ADD_BOX_UNITS,
      payload: res.data,
    });

    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.ADD_BOX_UNITS_FAIL,
      payload: error,
    });
    return { error };
  }
};

export const updateBoxUnit = (id, data) => async dispatch => {
  try {
    dispatch({
      type: actionType.UPDATE_BOX_UNITS_START,
    });
    const res = await axiosProxy({
      method: 'PUT',
      data,
      url: `${url}/${id}`,
    });

    await dispatch({
      type: actionType.UPDATE_BOX_UNITS,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.UPDATE_BOX_UNITS_FAIL,
      payload: error,
    });
    return { error };
  }
};

export const deleteBoxUnit = id => async dispatch => {
  try {
    dispatch({
      type: actionType.DELETE_BOX_UNITS_START,
    });
    await axiosProxy({
      method: 'DELETE',
      url: `${url}/${id}`,
    });

    await dispatch({
      type: actionType.DELETE_BOX_UNITS,
      payload: id,
    });
  } catch (error) {
    dispatch({
      type: actionType.DELETE_BOX_UNITS_FAIL,
      payload: error,
    });
  }
};

export const clearError = () => dispatch => {
  dispatch({
    type: actionType.CLEAR_BOX_UNITS_ERROR,
  });
};
