import React from "react";
import PropTypes from "prop-types";
import { Segment, Grid } from "semantic-ui-react";
import RowEmployee from "./RowEmployee";
import RowAdmin from "./RowAdmin";
import styles from "./TableSprayTask.module.css";

const collectTaskData = task => {
  let data = {
    blocksNames: [],
    chemicals: [],
    hectares: 0,
    plannedTanks: 0,
    actualTotalTanks: 0,
    doneCount: 0,
    donePercent: 0,
    supervisors: task.supervisors,
    assignees: task.assignees
  };

  if (task.areas && task.areas.length) {
    data = task.areas.reduce((data, block) => {
      if (block.area) {
        data.blocksNames.push({
          name: block.area.name,
          id: block.area.id,
          done: block.done,
          parent: block.area.parent
        });
      }
      data.hectares += block.hectares;
      data.plannedTanks += block.plannedTanks;
      data.actualTotalTanks +=
        block.actualTanks !== null && block.actualTanks >= 0
          ? block.actualTanks
          : block.plannedTanks;
      data.doneCount = block.done ? data.doneCount + 1 : data.doneCount;
      return data;
    }, data);

    if (task.chemicals.length) {
      data.chemicals = task.chemicals.map(chemical => ({
        id: chemical.chemical?.id,
        name: chemical.chemical?.name
      }));
    }

    data.hectares = data.hectares.toFixed(2);
    data.donePercent = ((data.doneCount / task.areas.length) * 100).toFixed(0);
  }

  return data;
};

function TableSprayTask({
  task,
  statuses,
  dilutionRateTypes,
  setActiveTask,
  handleDeleteTask,
  Can,
  handlePrintTask
}) {
  const taskData = collectTaskData(task);
  return (
    <Segment basic className={styles.tasksListRow}>
      <Grid className={styles.tasksListRowGrid}>
        <Can I="add" a="spray_tasks">
          <RowAdmin
            task={task}
            setActiveTask={setActiveTask}
            taskData={taskData}
            statuses={statuses}
            handleDeleteTask={handleDeleteTask}
            handlePrintTask={handlePrintTask}
          />
        </Can>
        <Can not I="add" a="spray_tasks">
          <RowEmployee
            task={task}
            setActiveTask={setActiveTask}
            taskData={taskData}
            statuses={statuses}
            dilutionRateTypes={dilutionRateTypes}
            handlePrintTask={handlePrintTask}
          />
        </Can>
      </Grid>
    </Segment>
  );
}

TableSprayTask.propTypes = {
  task: PropTypes.object.isRequired,
  statuses: PropTypes.object,
  setActiveTask: PropTypes.func,
  dilutionRateTypes: PropTypes.object,
  handleDeleteTask: PropTypes.func,
  handlePrintTask: PropTypes.func,
  Can: PropTypes.func
};

TableSprayTask.defaultProps = {
  task: {}
};

export default TableSprayTask;
