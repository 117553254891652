import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Grid } from "semantic-ui-react";
import { Form } from "formsy-semantic-ui-react";
import SelectWithSelectAll from "../../../../components/SelectWithSelectAll";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getVarietiesList } from "../../../../actions/Varieties";

const columnMiddle = {
  mobile: 16,
  tablet: 16,
  computer: 8,
  largeScreen: 8,
  widescreen: 8
};

const FarmRelatedFields = ({
  area,
  varietiesList,
  actions: { getVarietiesList },
  crop,
  variety,
  onChange
}) => {
  const cropID = crop ? crop.id : null;
  const varietyID = variety ? variety.id : null;

  useEffect(() => {
    if (!area) getVarietiesList();
  }, [getVarietiesList, area]);

  const crops = useMemo(() => {
    const crops = {};
    const list = area ? area.varieties : varietiesList;

    list.forEach(variety => {
      const tempVariety = !area ? variety : variety.variety;
      const crop = tempVariety.crop;

      if (crops[crop.id]) {
        if (
          crops[crop.id].varieties.filter(v => v.id === tempVariety.id)
            .length === 0
        ) {
          crops[crop.id].varieties = [...crops[crop.id].varieties, tempVariety];
        }
      } else {
        crops[crop.id] = crop;
        crops[crop.id].varieties = [tempVariety];
      }
    });

    return crops;
  }, [varietiesList, area]);

  const cropsOptions = useMemo(
    () =>
      Object.values(crops).map(content => ({
        key: content.id,
        text: `${content.name}`,
        value: content.id
      })),
    [crops]
  );

  const varietyOptions = useMemo(
    () =>
      crop
        ? crop.varieties.map(content => ({
            key: content.id,
            text: `${content.name}`,
            value: content.id
          }))
        : [],
    [crop]
  );

  return (
    <>
      <Grid.Row>
        <Grid.Column {...columnMiddle}>
          <Form.Field className="sprayField">
            <label>Crop</label>
            <SelectWithSelectAll
              placeholder="Select Crop"
              showSelectAll={false}
              name={"crop"}
              noResultsMessage="Nothing was found"
              fluid
              closeOnBlur
              selection
              value={cropID}
              onChange={(_, data) => {
                onChange({
                  target: { name: "crop", value: crops[data.value] }
                });
                onChange({ target: { name: "variety", value: null } });
              }}
              options={cropsOptions}
            />
          </Form.Field>
        </Grid.Column>
        <Grid.Column {...columnMiddle}>
          <Form.Field className="sprayField">
            <label>Variety</label>
            <SelectWithSelectAll
              placeholder="Select Farm"
              showSelectAll={false}
              name={"farm"}
              noResultsMessage="Nothing was found"
              fluid
              closeOnBlur
              selection
              disabled={!crop || !varietyOptions.length}
              value={varietyID}
              onChange={(_, data) => {
                onChange({
                  target: {
                    name: "variety",
                    value: crop.varieties.find(v => v.id === data.value)
                  }
                });
              }}
              options={varietyOptions}
            />
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
    </>
  );
};

FarmRelatedFields.propTypes = {
  onChange: PropTypes.func,
  varietiesList: PropTypes.array,
  area: PropTypes.object,
  variety: PropTypes.object,
  crop: PropTypes.object,
  actions: PropTypes.object
};

const mapStateToProps = state => ({
  varietiesList: state.varieties.data
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getVarietiesList
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(FarmRelatedFields);
