import { actionType } from '../../constants/Varieties';
import { axiosProxy } from '../../utils/axiosProxy';

const url = '/varieties';

export const getVarietiesList = () => async dispatch => {
  try {
    dispatch({
      type: actionType.GET_VARIETIES_LIST_START,
    });

    const res = await axiosProxy({
      method: 'GET',
      url,
    });

    await dispatch({
      type: actionType.GET_VARIETIES_LIST,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: actionType.GET_VARIETIES_LIST_FAIL,
      payload: error,
    });
  }
};

export const getUsedVarietiesList = () => async dispatch => {
  try {
    dispatch({
      type: actionType.GET_USED_VARIETIES_LIST_START,
    });

    const res = await axiosProxy({
      method: 'GET',
      url: '/areas/varieties',
      params: {
        unpaged: true,
      },
    });

    dispatch({
      type: actionType.GET_USED_VARIETIES_LIST,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: actionType.GET_USED_VARIETIES_LIST_FAIL,
      payload: error,
    });
  }
};
