import { actionTypes } from "../../constants/Crops";
const initialState = {
  isFetching: false,
  error: null,
  data: []
};

const cropsActions = (state, action) => {
  switch (action.type) {
    case actionTypes.GET_CROPS_START:
      return {
        ...state,
        isFetching: true
      };
    case actionTypes.GET_CROPS:
      return {
        ...state,
        error: null,
        isFetching: false,
        data: action.payload
      };
    case actionTypes.GET_CROPS_FAIL:
      return {
        ...state,
        error: action.payload,
        isFetching: false
      };
    case actionTypes.GET_VARIETIES_START:
      return {
        ...state,
        isFetching: true
      };
    case actionTypes.GET_VARIETIES:
      const targetCrop = state.data.find(
        crop => crop.id === action.payload.cropId
      );
      targetCrop.varieties = action.payload.varieties;
      return {
        ...state,
        data: [...state.data],
        isFetching: false
      };
    case actionTypes.GET_VARIETIES_FAIL:
      return {
        ...state,
        error: action.payload,
        isFetching: false
      };
    default:
      return state;
  }
};

export default (state = initialState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case actionTypes.GET_CROPS_START:
    case actionTypes.GET_CROPS:
    case actionTypes.GET_CROPS_FAIL:
    case actionTypes.GET_VARIETIES_START:
    case actionTypes.GET_VARIETIES:
    case actionTypes.GET_VARIETIES_FAIL:
      return cropsActions(state, action);

    default:
      return state;
  }
};
