import React, { useState } from "react";
import { Form, Container, Image, Button } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { useFormikContext } from "formik";
import { uploadAttachment } from "actions/Settings";
import ObjectId from "bson-objectid";
import FileChooser from "components/Attachments/FileChooser";
import styles from "../Setting.module.css";

const AdditionalInformationEditSection = () => {
  const { values, setFieldValue, setValues } = useFormikContext();
  const [loadingAttachments, setLoadingAttachments] = useState([]);
  const dispatch = useDispatch();
  const onAttachmentSelected = fieldName => files => {
    setLoadingAttachments(attachments => [...attachments, fieldName]);
    files.forEach(file => {
      const dataToSend = {
        file,
        idReplace: ObjectId().toHexString(),
        callBack: loadedFile => {
          const newData = {
            id: loadedFile.id,
            name: loadedFile.name,
            presignedUrl: loadedFile.presignedUrl,
            subPath: loadedFile.subPath
          };
          setFieldValue(fieldName, newData);
          setLoadingAttachments(attachments =>
            attachments.filter(i => i !== fieldName)
          );
        }
      };

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        dispatch(uploadAttachment(dataToSend));
      };
    });
  };

  return (
    <Form>
      <Container fluid className={styles.generalAttachmentsContainer}>
        {values.picture?.presignedUrl ? (
          <Image
            avatar
            className={styles.companyLogo}
            src={values.picture?.presignedUrl}
          />
        ) : (
          <div className={styles.companyDisabledLogo} />
        )}
        <div className={styles.fileChooserWrapper}>
          <div className={styles.buttonWrapper}>
            <FileChooser
              className={styles.generalAttachmentsButton}
              forImage={true}
              onChange={onAttachmentSelected("picture", true)}
              label="Upload company logo"
              disabled={loadingAttachments.includes("picture")}
            />

            {values.picture?.presignedUrl && (
              <>
                <span className={styles.dot}> </span>
                <Button
                  type="reset"
                  className={styles.removeBtn}
                  onClick={() => {
                    setFieldValue("picture", null);
                  }}
                >
                  Remove logo
                </Button>
              </>
            )}
          </div>
          Your company's logo will appear in the various printouts throughout
          TieUp Farming's software.
        </div>
      </Container>
    </Form>
  );
};

export default AdditionalInformationEditSection;
