import { actionType } from "../../constants/HourlyPayroll";

const initialState = {
  fromReportPage: true,
  data: {
    content: [],
    number: 0,
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
    size: 10
  },
  payrollsAllInformation: [],
  isFetching: false,
  error: null
};

export default (state = initialState, action) => {
  if (!action) return state;

  switch (action.type) {
    case actionType.FETCH_HOURLY_PAYROLLS_EXPANDED_DATA_START: {
      const newData = [...state.data.content];
      const elementIndex = newData.findIndex(
        item => item.employeeId === action.payload
      );
      newData[elementIndex] = {
        ...newData[elementIndex],
        expandedData: {
          isFetching: true,
          items: []
        }
      };

      return {
        ...state,
        data: {
          ...state.data,
          content: newData
        }
      };
    }
    case actionType.FETCH_HOURLY_PAYROLLS_EXPANDED_DATA: {
      const newData = [...state.data.content];
      const elementIndex = newData.findIndex(
        item => item.employeeId === action.payload.employeeId
      );
      newData[elementIndex] = {
        ...newData[elementIndex],
        expandedData: {
          isFetching: false,
          items: action.payload.data
        }
      };
      return {
        ...state,
        data: {
          ...state.data,
          content: newData
        }
      };
    }
    case actionType.CHECK_REPORT_PAGE: {
      return {
        ...state,
        fromReportPage: action.payload
      };
    }
    case actionType.FETCH_HOURLY_PAYROLLS_EXPANDED_DATA_FAIL: {
      return {
        ...state,
        allData: {
          ...state.allData,
          isFetching: false,
          error: action.payload
        }
      };
    }
    case actionType.FETCH_HOURLY_PAYROLLS_SUMMARY_START: {
      return {
        ...state,
        isFetching: true
      };
    }
    case actionType.SET_HOURLY_PAYROLLS_SUMMARY: {
      return {
        ...state,
        data: {
          ...action.payload
        },
        isFetching: false
      };
    }
    case actionType.FETCH_HOURLY_PAYROLLS_SUMMARY_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFetching: false
      };
    }
    case actionType.FETCH_HOURLY_PAYROLLS_ALL_INFORMATION_START:
    case actionType.FETCH_HOURLY_PAYROLLS_ALL_INFORMATION_FAIL: {
      return {
        ...state,
        payrollsAllInformation: []
      };
    }
    case actionType.SET_HOURLY_PAYROLLS_ALL_INFORMATION: {
      return {
        ...state,
        payrollsAllInformation: action.payload
      };
    }
    default:
      return state;
  }
};
