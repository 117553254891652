import { unique } from 'react-table/src/aggregations';

export function singleOrUniqueCount(values) {
  const res = unique(values);
  return {
    isCount: res.length !== 1,
    value: res.length === 1 ? res[0] : res.length,
  };
}

export function singleOrUniqueCountAggregatedValue(values) {
  return singleOrUniqueCount(values.map(v => v.value));
}

export const singleOrUniqueCountAggregated = (
  {
    cell: {
      value: { isCount, value },
    },
  },
  postfix,
) => value && `${value}${isCount ? ` ${postfix}` : ''}`;
