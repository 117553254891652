import { saveSubscription } from './notificationsApi';

const urlB64ToUint8Array = (base64String = '') => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/\-/g, '+') /* eslint-disable-line */.replace(/_/g, '/');
  const rawData = atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

const applicationServerKey = urlB64ToUint8Array(process.env.REACT_APP_PUSH_SERVICE_PUBLIC_KEY);

const options = {
  userVisibleOnly: true,
  applicationServerKey: applicationServerKey,
};

const check = () => {
  if (window.safari) {
    return false;
  }

  if (!('serviceWorker' in navigator)) {
    return false;
  }

  if (!('PushManager' in window || (window.safari && 'pushNotification' in window.safari))) {
    return false;
  }

  return true;
};

let swRegistration;

export const subscribe = async () => {
  if ('serviceWorker' in navigator) {
    let permission = await window.Notification.requestPermission();

    if (permission !== 'granted') {
      return;
    }

    navigator.serviceWorker.ready
      .then(function(registration) {
        if (!registration.pushManager) {
          //eslint-disable-next-line
          console.log('Push manager unavailable.');
          return;
        }

        registration.pushManager.getSubscription().then(function(existedSubscription) {
          if (existedSubscription === null) {
            //eslint-disable-next-line
            console.log('No subscription detected, make a request.');
            registration.pushManager
              .subscribe(options)
              .then(function(newSubscription) {
                //eslint-disable-next-line
                console.log('New subscription added.');
                return saveSubscription(newSubscription);
              })
              .catch(function(e) {
                if (Notification.permission !== 'granted') {
                  //eslint-disable-next-line
                  console.log('Permission was not granted.');
                } else {
                  //eslint-disable-next-line
                  console.error('An error ocurred during the subscription process.', e);
                }
              });
          } else {
            //eslint-disable-next-line
            console.log('Existed subscription detected.');
            return saveSubscription(existedSubscription);
          }
        });
      })
      .catch(function(e) {
        //eslint-disable-next-line
        console.error('An error ocurred during Service Worker registration.', e);
      });
  }
};

export const unsubscribe = async () => {
  check();
  swRegistration.pushManager.getSubscription().then(function(subscription) {
    subscription
      .unsubscribe()
      .then(function(successful) {
        //eslint-disable-next-line
        console.log('unsubscribe success');
      })
      .catch(function(e) {
        //eslint-disable-next-line
        console.log('unsubscribe failed');
      });
  });
};
