import React, { Fragment } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import Logo from "../../../../components/Logo";
import classNames from "classnames";
import { Header, Tab, Table, TableBody, TableRow } from "semantic-ui-react";
import Numeric from "../../../../components/Numeric/Numeric";
import { get, pickBy } from "lodash";
import { minutesToHM, dateRangeToString } from "../../../../utils/timeUtils";
import styles from "./PrintInventoryMovements.module.css";
import { matterToUnits } from "utils/constToUnits";
import { uppercaseFirstLetter } from "utils/string";

class PrintReports extends React.Component {
  render() {
    const {
      chemicalIds,
      chemicalsList,
      shedIds,
      chemicalsTypeList,
      chemicals,
      employeesList,
      shedList,
      areasList,
      purchaseList,
      activeIngredientList,
      selectedItem,
      data,
      from,
      to,
      ref
    } = this.props;
    return (
      <div className={styles.printTableWrapper}>
        <div className={styles.printTableRow}>
          <div className={styles.printTableHeader}>Inventory Movements</div>
          <div className={styles.printTableLogo}>
            <Logo width={140} height={60} />
          </div>
        </div>
        <Table columns="3" basic="very" className={styles.totalRowWrapper}>
          <Table.Header className={styles.totalRowHeaderWrapper}>
            <Table.HeaderCell className={styles.totalRowDateHeaderContainer}>
              Date range
            </Table.HeaderCell>
            <Table.HeaderCell className={styles.totalRowProductHeaderContainer}>
              Product
            </Table.HeaderCell>
            <Table.HeaderCell className={styles.totalRowShedHeaderContainer}>
              Shed
            </Table.HeaderCell>
          </Table.Header>
          <Table.Body className={styles.totalRowValueWrapper}>
            <Table.Cell className={styles.totalRowDateValueContainer}>
              {from && to
                ? `${moment(from).format("DD/MM/YYYY")} - ${moment(to).format(
                    "DD/MM/YYYY"
                  )}`
                : "-"}
            </Table.Cell>
            <Table.Cell className={styles.totalRowProductValueContainer}>
              {chemicalIds
                ? chemicalsList
                    .filter(({ key }) => chemicalIds.some(item => item === key))
                    .map(item => item.text)
                    .join(",")
                : "-"}
            </Table.Cell>
            <Table.Cell className={styles.totalRowShedsValueContainer}>
              {" "}
              {shedIds
                ? shedList.options
                    .filter(({ key }) => shedIds.some(item => item === key))
                    .map(item => item.label)
                    .join(",")
                : "-"}
            </Table.Cell>
          </Table.Body>
        </Table>

        {data.content.content &&
          data.content.content.map(item => {
            const area = areasList.content.find(
              area => area.id === item.areaId
            );
            const activeIngredients = chemicals
              .find(p => p.id === item.chemicalId)
              ?.activeIngredients.map(
                ({ activeIngredient }) => activeIngredient
              );
            return (
              <div className={styles.container}>
                <Table basic="very" fixed={true} className={styles.reportTable}>
                  <Table.Header className={styles.reportHeaderWrapper}>
                    <Table.Row className={styles.reportHeaderRow}>
                      <Table.Cell as="td" className={styles.reportHeader}>
                        <div className={styles.dateCell}>Date</div>
                      </Table.Cell>
                      <Table.Cell as="td" className={styles.reportHeader}>
                        <div className={styles.productCell}>Products</div>
                      </Table.Cell>
                      <Table.Cell as="td" className={styles.reportHeader}>
                        <div className={styles.userCell}>User</div>
                      </Table.Cell>
                      <Table.Cell as="td" className={styles.reportHeader}>
                        <div className={styles.typeCell}>Product type</div>
                      </Table.Cell>
                      <Table.Cell as="td" className={styles.reportHeader}>
                        <div className={styles.matterCell}>Matter</div>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell className={styles.reportBody} as="td">
                        {moment(item.createdAt).format("DD/MM/YYYY")}
                      </Table.Cell>
                      <Table.Cell className={styles.reportBody} as="td">
                        {
                          chemicalsList.find(
                            products => products.key === item.chemicalId
                          )?.text
                        }
                      </Table.Cell>
                      <Table.Cell className={styles.reportBody} as="td">
                        {
                          employeesList.find(
                            employee => employee.key === item.employeeId
                          )?.firstName
                        }
                        {
                          employeesList.find(
                            employee => employee.key === item.employeeId
                          )?.lastName
                        }
                      </Table.Cell>
                      <Table.Cell className={styles.reportBody} as="td">
                        {
                          chemicalsTypeList.find(
                            products => products.id === item.chemicalId
                          )?.type
                        }
                      </Table.Cell>
                      <Table.Cell className={styles.reportBody} as="td">
                        {uppercaseFirstLetter(
                          chemicalsList.find(
                            products => products.key === item.chemicalId
                          )?.matter
                        )}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>

                <Table
                  basic="very"
                  fixed={true}
                  className={styles.secondreportTable}
                >
                  <Table.Header className={styles.secondreportHeaderWrapper}>
                    <Table.Row className={styles.secondreportHeaderRow}>
                      <Table.Cell as="td" className={styles.secondreportHeader}>
                        <div className={styles.seconddateCell}>
                          Active Ingridient
                        </div>
                      </Table.Cell>
                      <Table.Cell as="td" className={styles.secondreportHeader}>
                        <div className={styles.secondproductCell}>
                          Activity type
                        </div>
                      </Table.Cell>
                      <Table.Cell as="td" className={styles.secondreportHeader}>
                        <div className={styles.seconduserCell}>Block/Patch</div>
                      </Table.Cell>
                      <Table.Cell as="td" className={styles.secondreportHeader}>
                        <div className={styles.secondtypeCell}>
                          Price ($/unit)
                        </div>
                      </Table.Cell>
                      <Table.Cell as="td" className={styles.secondreportHeader}>
                        <div className={styles.secondmatterCell}>Shed</div>
                      </Table.Cell>
                      <Table.Cell as="td" className={styles.secondreportHeader}>
                        <div className={styles.secondmatterCell}>
                          Difference
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row className={styles.secondreportRow}>
                      <Table.Cell className={styles.secondreportBody} as="td">
                        {activeIngredients?.join(", ") || "-"}
                      </Table.Cell>
                      <Table.Cell className={styles.secondreportBody} as="td">
                        {item.operationType === "TASK_CONSUMPTION" ||
                        item.operationType === "TASK_CORRECTION" ? (
                          <div className={styles.linkWrapper}>
                            {(
                              item.operationType
                                .toLowerCase()
                                .charAt(0)
                                .toUpperCase() +
                              item.operationType.toLowerCase().slice(1)
                            ).replace(/_/g, " ")}
                            <p> ST-{item.entityId}</p>
                          </div>
                        ) : item.operationType === "PURCHASE_ORDER_ARRIVAL" ||
                          item.operationType === "PURCHASE_ORDER_CORRECTION" ? (
                          <div className={styles.linkWrapper}>
                            {(
                              item.operationType
                                .toLowerCase()
                                .charAt(0)
                                .toUpperCase() +
                              item.operationType.toLowerCase().slice(1)
                            ).replace(/_/g, " ")}
                            {
                              purchaseList.find(({ id }) => id == item.entityId)
                                ?.orderNumber
                            }
                          </div>
                        ) : (
                          (
                            item.operationType
                              .toLowerCase()
                              .charAt(0)
                              .toUpperCase() +
                            item.operationType.toLowerCase().slice(1)
                          ).replace(/_/g, " ")
                        )}
                      </Table.Cell>
                      <Table.Cell className={styles.secondreportBody} as="td">
                        {area && area.parent
                          ? `${area.parent.name}/${area.name}`
                          : area
                          ? area.name
                          : "-"}
                      </Table.Cell>
                      <Table.Cell className={styles.secondreportBody} as="td">
                        {
                          <>
                            <Numeric
                              fractionDigits={item.price > 1000 ? 0 : 2}
                              value={item.price}
                              commaSeparatorOnThousands
                              units="$ "
                              unitsPosition="left"
                            />
                            /
                            {matterToUnits(
                              get(
                                chemicalsList.find(
                                  p => p.key === item.chemicalId
                                ),
                                "matter"
                              )
                            )}
                          </>
                        }
                      </Table.Cell>
                      <Table.Cell className={styles.secondreportBody} as="td">
                        {shedList.options.find(shed => shed.key === item.shedId)
                          ?.label || "-"}
                      </Table.Cell>
                      <Table.Cell className={styles.secondreportBody} as="td">
                        {item.quantity > 0 ? "+ " : "- "}
                        <Numeric
                          value={Math.abs(item.quantity)}
                          fractionDigits={0}
                          commaSeparatorOnThousands
                          units={matterToUnits(
                            get(
                              chemicalsList.find(
                                p => p.key === item.chemicalId
                              ),
                              "matter"
                            )
                          )}
                          unitsPosition="right"
                        />
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </div>
            );
          })}
      </div>
    );
  }
}

PrintReports.propTypes = {
  title: PropTypes.string,
  data: PropTypes.object.isRequired,
  showExpandedData: PropTypes.bool,
  startDate: PropTypes.any,
  endDate: PropTypes.any
};

export default PrintReports;
