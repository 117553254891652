import moment from 'moment-timezone';

/**
 * Return convert the time to the given timezone, and then ignore the timezone and set it to UTC
 * For example: datetime=1583326140261, timezone="Australia/Melbourne" is actually 2020-03-04 23:49:00+11:00
 * So it ignore the time zone and return 2020-03-04 23:49:00+00:00 in epoch time which is 1583365740
 *
 * Do you ask why?!?!?!?!
 * Field Climate API does not accept time zone, it ask for the time in unix `Etc/UTC` while the station has local
 * time zone. so if you want to get the station data at `2020-01-01 12:00:00` local time, you need to send this
 * in unix time but assuming you are in `Etc/UTC` Timezone, means 1577836800
 */
export const toUnixInTimezone = (datetime, timezone) => {
  return moment(
    moment(datetime)
      .tz(timezone)
      .format(`YYYY-MM-DD HH:mm:ss+00:00`),
  ).unix();
};

export const toUnixInUTC = datetime => {
  let extra = ' 00:00:00+00:00';
  const match = datetime.match(/^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/);
  if (match && datetime === match[0]) {
    extra = '+00:00';
  }
  return moment(`${datetime}${extra}`).unix();
};

export const formatInUTC = (datetime, format = 'DD/MM/YYYY HH:mm:ss') => {
  return moment.tz(datetime, 'Etc/UTC').format(format);
};
