import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Grid,
  GridColumn,
  Header,
  Icon,
  Input,
  TextArea
} from "semantic-ui-react";
import { Form } from "formsy-semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import moment from "moment";

import { history } from "../../../../../store";
import {
  getErrorFields,
  validationErrors
} from "../../../../../utils/validationRules";
import ErrorLabel from "../../../../../components/ErrorLabel";
import { abilitiesSelector } from "../../../../../selectors/user";
import SegmentClosable from "../../../../../components/SegmentClosable";
import { scrollIntoError } from "../../../../../utils/scrollIntoError";
import styles from "./DispatchForm.module.css";
import tableStyles from "./SelectPalletsTable/SelectPalletsTable.module.css";
import {
  addDispatch,
  updateDispatch
} from "../../../../../actions/Dispatches/dispatches";
import { getParentPageHref } from "../../../../../utils/routeHelpers";
import DatePicker from "../../../../../components/DatePicker";
import SelectVendor from "../../../../../components/SelectVendor";
import {
  fetchPalletsNotDispatched as fetchPallets,
  getPalletById
} from "../../../../../actions/Pallets/pallets";
import SelectPalletsTable, {
  renderCell,
  renderCellMobile
} from "./SelectPalletsTable";
import ExpandedTable from "../../../../../components/ExpandedTable";
import {
  genericErrors,
  infoMessageType,
  messagesIds,
  putMessage
} from "../../../../../actions/InfoMessages";
import { columns } from "../../columns";
import QrScanner from "../../../../../components/QrScanner/QrScanner";

const scanModes = {
  INPUT: "INPUT",
  CAMERA: "CAMERA"
};

const initialValues = {
  id: null,
  dispatchedAt: moment().format("YYYY-MM-DD"),
  vendor: { id: null },
  orderNumber: "",
  notes: "",
  pallets: []
};

class DispatchForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dispatch: props.initialValues ? props.initialValues : initialValues,
      palletId: "",
      showSelectPalletsTable: false,
      scanMode: scanModes.INPUT,
      errorsInSegments: {}
    };
    this.isSubmitted = false;
    this.formRef = React.createRef();
    this.gunBarcode = React.createRef();
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    window.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown(e) {
    if (e.key === "Unidentified" && e.keyCode === 0) {
      this.gunBarcode.current.focus();
    }
  }

  changeValue = (fieldName, value) => {
    const { dispatch } = this.state;
    this.setState({
      dispatch: {
        ...dispatch,
        [fieldName]: value
      }
    });
  };

  changeShowSelectPalletsTable = () => {
    this.setState(prevState => ({
      showSelectPalletsTable: !prevState.showSelectPalletsTable
    }));
  };

  addPalletFromInput = () => {
    const { palletId } = this.state;
    this.addPallet(palletId);
  };

  addPallet = palletId => {
    const {
      actions: { putMessage, getPalletById }
    } = this.props;
    const { dispatch } = this.state;
    const intPalletId = parseInt(palletId);

    if (!palletId || palletId === "") {
      return;
    }

    if (
      dispatch.pallets.findIndex(pallet => pallet.id === intPalletId) !== -1
    ) {
      putMessage({
        type: infoMessageType.WARN,
        messageId: messagesIds.GENERIC,
        duration: {
          amount: 1,
          unit: "second"
        },
        extraData: {
          message: `Pallet ${palletId} already selected`
        }
      });
      this.setState({
        dispatch: {
          ...dispatch
        },
        palletId: ""
      });
      return;
    }

    getPalletById(palletId)
      .then(res => {
        this.setState(prevState => ({
          dispatch: {
            ...prevState.dispatch,
            pallets: prevState.dispatch.pallets.map(pallet => {
              if (pallet.id === res.id) {
                return res;
              }

              return pallet;
            })
          }
        }));
      })
      .catch(() => {
        putMessage({
          type: infoMessageType.ERROR,
          messageId: messagesIds.GENERIC,
          duration: {
            amount: 5,
            unit: "second"
          },
          extraData: {
            message: `Pallet ${palletId} does not exist`
          }
        });

        this.setState(prevState => ({
          dispatch: {
            ...prevState.dispatch,
            pallets: prevState.dispatch.pallets.filter(
              pallet => pallet.id !== intPalletId
            )
          }
        }));
      });

    this.setState({
      dispatch: {
        ...dispatch,
        pallets: [...dispatch.pallets, { id: intPalletId, loading: true }]
      },
      palletId: ""
    });
  };

  removePallet = id => {
    const { dispatch } = this.state;

    this.setState({
      dispatch: {
        ...dispatch,
        pallets: dispatch.pallets.filter(pallet => pallet.id !== id)
      }
    });
  };

  addPallets = pallets => {
    const { dispatch } = this.state;

    if (!pallets || pallets.length === 0) {
      return;
    }

    this.setState({
      dispatch: {
        ...dispatch,
        pallets: [
          ...dispatch.pallets,
          ...pallets.filter(
            pallet => dispatch.pallets.findIndex(p => p.id === pallet.id) === -1
          )
        ]
      }
    });
  };

  switchScanMode = () => {
    const { scanMode } = this.state;
    const newScanMode =
      scanMode === scanModes.INPUT ? scanModes.CAMERA : scanModes.INPUT;
    this.setState({ scanMode: newScanMode });
  };

  renderActionsCell = props => {
    const { value } = props;

    return (
      <div style={{ textAlign: "right" }}>
        <Button
          type={"button"}
          icon={"trash"}
          className={"transparent"}
          onClick={() => this.removePallet(value)}
        />
      </div>
    );
  };

  tableColumns = [
    ...columns.map(column => {
      return {
        accessor: column.id,
        Cell: renderCell,
        ...column,
        className: `cell_${column.width} ${column.className || ""}`
      };
    }),
    {
      id: "actions",
      accessor: d => d.id,
      Header: "",
      Cell: this.renderActionsCell,
      className: `actions`,
      width: 25
    }
  ];

  tableColumnsMobile = [
    {
      id: "id",
      Header: "Id",
      accessor: d => d.id,
      Cell: renderCellMobile,
      className: ``
    },
    {
      id: "actions",
      accessor: d => d.id,
      Header: "",
      Cell: this.renderActionsCell,
      className: `actions`,
      width: 25
    }
  ];

  handleErrors = () => {
    const { formRef } = this.props;
    const formsyForm = (formRef || this.formRef).current.formsyForm;
    const errorFields = getErrorFields(formsyForm);
    this.setState({
      errorsInSegments: {
        information: {
          error: errorFields.name
        }
      }
    });
    setTimeout(scrollIntoError, 300);
  };

  onValidSubmit = async () => {
    const { dispatch } = this.state;
    const {
      actions: { addDispatch, updateDispatch, putMessage },
      onSubmitForm,
      route
    } = this.props;

    if (dispatch.pallets.filter(pallet => pallet.loading).length > 0) {
      putMessage({
        type: infoMessageType.WARN,
        messageId: messagesIds.GENERIC,
        duration: {
          amount: 5,
          unit: "second"
        },
        extraData: {
          message: `Please wait until all the selected pallets will be loaded`
        }
      });
      return null;
    }

    if (this.isSubmitted) {
      return null;
    }
    this.isSubmitted = true;

    const dispatchData = {
      ...dispatch,
      pallets: dispatch.pallets.map(pallet => ({
        id: pallet.id
      }))
    };

    this.setState({
      errorsInSegments: {}
    });

    let res;

    if (dispatch.id) {
      res = await updateDispatch(dispatch.id, dispatchData, true);
    } else {
      res = await addDispatch(dispatchData, true);
    }
    this.isSubmitted = false;

    if (res.error) {
      if (
        res.error.response &&
        res.error.response.data &&
        res.error.response.data.code ===
          genericErrors.ALREADY_USED_PALLETS_OR_NOT_EXISTS &&
        res.error.response.data.body
      ) {
        this.setState(prevState => ({
          dispatch: {
            ...prevState.dispatch,
            pallets: prevState.dispatch.pallets.map(pallet => {
              if (
                res.error.response.data.body.findIndex(
                  res => pallet.id === res.id
                ) !== -1
              ) {
                return { ...pallet, error: true };
              }

              return pallet;
            })
          }
        }));
      }
    } else {
      if (_.isFunction(onSubmitForm)) {
        onSubmitForm(res);
      } else {
        history.push(getParentPageHref(route));
      }
    }
  };

  onSubmit = () => {
    const { formRef } = this.props;
    const targetRef = formRef || this.formRef;
    targetRef.current.submit();
  };

  handlePalletIdInput = event => {
    this.setState({ palletId: event.target.value });
  };

  handlePalletIdHiddenInput = event => {
    this.addPallet(event.target.value);
  };

  render() {
    const {
      modal,
      Can,
      showMobileHeader,
      formRef,
      content,
      isFetching,
      actions: { fetchPallets }
    } = this.props;
    const {
      dispatch: { id, dispatchedAt, vendor, orderNumber, notes, pallets },
      palletId,
      scanMode,
      showSelectPalletsTable,
      errorsInSegments
    } = this.state;

    const columnMiddle = modal
      ? {
          width: 16
        }
      : {
          mobile: 16,
          tablet: 16,
          computer: 10,
          largeScreen: 10,
          widescreen: 10
        };

    const columnFull = {
      mobile: 16,
      tablet: 16,
      computer: 16,
      largeScreen: 16,
      widescreen: 16
    };
    return (
      <Form
        onValidSubmit={this.onValidSubmit}
        onInvalidSubmit={this.handleErrors}
        loading={false}
        ref={formRef || this.formRef}
      >
        <div className={`${styles.sprayGrid} ${modal ? styles.forModal : ""}`}>
          <div className={styles.sprayFormContainer} id="sprayGrid">
            <div className={scanMode === scanModes.CAMERA ? styles.hide : ""}>
              <SegmentClosable
                title="Dispatch information"
                errors={errorsInSegments.information}
              >
                <Grid>
                  <Grid.Row>
                    <Grid.Column {...columnMiddle}>
                      <Form.Field required className="sprayField">
                        <DatePicker
                          required
                          validationErrors={validationErrors()}
                          errorLabel={ErrorLabel}
                          value={dispatchedAt}
                          onChange={createDate =>
                            this.changeValue("dispatchedAt", createDate)
                          }
                          labelText="Date"
                          {...(!id ? { focus: true } : {})}
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column {...columnMiddle}>
                      <Form.Field required className="sprayField">
                        <label>Select client/buyer</label>
                        <SelectVendor
                          name="vendor"
                          validation={"isRequired"}
                          validationErrors={validationErrors({
                            isDefaultRequiredValue: "vendor"
                          })}
                          errorLabel={ErrorLabel}
                          required
                          value={vendor && vendor.id}
                          onChange={(_, data) =>
                            this.changeValue("vendor", { id: data.value })
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column {...columnMiddle}>
                      <Form.Input
                        fluid
                        label="Order number"
                        placeholder="Order number"
                        name="orderNumber"
                        size="large"
                        value={orderNumber}
                        onChange={event =>
                          this.changeValue("orderNumber", event.target.value)
                        }
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column {...columnMiddle}>
                      <Form.Field className="sprayField">
                        <label>Notes</label>
                        <TextArea
                          name="notes"
                          size="large"
                          value={notes}
                          onChange={event =>
                            this.changeValue("notes", event.target.value)
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </SegmentClosable>
              <SegmentClosable
                title="Pallets"
                errors={errorsInSegments.pallets}
              >
                <Grid>
                  <Grid.Row>
                    <Grid.Column
                      mobile={11}
                      tablet={12}
                      computer={6}
                      largeScreen={6}
                      widescreen={6}
                    >
                      <Input
                        fluid
                        placeholder="Enter pallet id or scan"
                        name="palletId"
                        value={palletId}
                        type="number"
                        onChange={this.handlePalletIdInput}
                        icon={
                          <Icon
                            name="delete"
                            link
                            onClick={() => {
                              this.setState({ palletId: "" });
                            }}
                          />
                        }
                        onKeyPress={e => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            this.addPalletFromInput();
                          }
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column
                      mobile={5}
                      tablet={4}
                      computer={2}
                      largeScreen={2}
                      widescreen={2}
                    >
                      <Button
                        onClick={this.addPalletFromInput}
                        type="button"
                        primary
                        fluid
                      >
                        Add
                      </Button>
                    </Grid.Column>
                    <Grid.Column
                      mobile={4}
                      tablet={4}
                      computer={3}
                      largeScreen={3}
                      widescreen={3}
                    >
                      <Button
                        onClick={this.changeShowSelectPalletsTable}
                        type="button"
                        className={"hide-on-mobile"}
                        fluid
                      >
                        {showSelectPalletsTable ? "Hide" : "Show"} pallets table
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row className="hide-lg">
                    <Grid.Column>
                      <Button
                        onClick={this.switchScanMode}
                        type="button"
                        className="button-text"
                      >
                        <Icon className={`camera ${styles.scanButton}`} />
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                  <Input
                    ref={this.gunBarcode}
                    onKeyDown={e => {
                      if (e.key === "Unidentified") {
                        e.target.blur();
                      }
                    }}
                    name="gunBarcode"
                    style={{ opacity: 0, height: 0 }}
                    inputMode="none"
                    value={""}
                    onChange={this.handlePalletIdHiddenInput}
                  />
                  {showSelectPalletsTable && (
                    <Grid.Row>
                      <Grid.Column {...columnFull}>
                        <SelectPalletsTable
                          columns={columns}
                          data={content.filter(
                            row =>
                              pallets.findIndex(
                                pallet => pallet.id === row.id
                              ) === -1
                          )}
                          getData={fetchPallets}
                          loading={isFetching}
                          showFilters={false}
                          searchColumn={"id"}
                          defaultSort="id,desc"
                          withDateRange={true}
                          withRowSelect={true}
                          exportableToExcel={false}
                          defaultGroupBy={[]}
                          onSelectPallets={this.addPallets}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  )}
                  <Grid.Row>
                    <GridColumn {...columnFull}>
                      <Header type={"h1"}>
                        Selected pallets ({pallets.length})
                      </Header>
                    </GridColumn>
                  </Grid.Row>
                  <Grid.Row>
                    <GridColumn {...columnFull}>
                      <ExpandedTable
                        data={pallets}
                        columns={this.tableColumns}
                        className={`-striped ${tableStyles.listTable} hide-on-mobile`}
                        defaultPageSize={999999999}
                        sortBy={[]}
                        groupBy={[]}
                        noDataText="No pallets selected yet"
                      />
                      <ExpandedTable
                        data={pallets}
                        columns={this.tableColumnsMobile}
                        className={`-striped ${tableStyles.listTable} hide-lg`}
                        defaultPageSize={999999999}
                        sortBy={[]}
                        groupBy={[]}
                        noDataText="No pallets selected yet"
                      />
                    </GridColumn>
                  </Grid.Row>
                </Grid>
              </SegmentClosable>
            </div>
            <div className={scanMode === scanModes.INPUT ? styles.hide : ""}>
              <QrScanner
                items={pallets}
                onDone={this.switchScanMode}
                addAction={this.addPallet}
                validateBarcode={barcode => !isNaN(barcode)}
              />
            </div>
          </div>
          {!showMobileHeader && (
            <div className={`${styles.sprayFormFooter} show-sm`}>
              <div className="text-right">
                {id ? (
                  <Can I="update" a="dispatches">
                    <Button
                      primary
                      size="large"
                      type="button"
                      onClick={this.onSubmit}
                    >
                      Save
                    </Button>
                  </Can>
                ) : (
                  <Can I="add" a="dispatches">
                    <Button
                      primary
                      size="large"
                      type="button"
                      onClick={this.onSubmit}
                    >
                      Save
                    </Button>
                  </Can>
                )}
              </div>
            </div>
          )}
        </div>
      </Form>
    );
  }
}

DispatchForm.propTypes = {
  initialValues: PropTypes.object,
  actions: PropTypes.object,
  route: PropTypes.object,
  formRef: PropTypes.func,
  Can: PropTypes.func,
  modal: PropTypes.bool,
  isFetching: PropTypes.bool,
  content: PropTypes.any,
  showMobileHeader: PropTypes.bool,
  onSubmitForm: PropTypes.func
};

const mapStateToProps = state => {
  const {
    pallets: {
      isFetching,
      data: { content }
    }
  } = state;
  return {
    content,
    isFetching,
    Can: abilitiesSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      addDispatch,
      updateDispatch,
      fetchPallets,
      getPalletById,
      putMessage
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(DispatchForm);
