import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";
import Loader from "../../../components/Loader";
import { abilitiesSelector } from "../../../selectors/user";
import WeatherStationForm from "./WeatherStationForm";
import { getWeatherStationById } from "../../../actions/WeatherStation/";
import { getParentPageHref } from "../../../utils/routeHelpers";
import { history } from "../../../store";
import LayoutOverlay from "../../../components/LayoutOverlay";
// eslint-disable-next-line
import styles from "./AddWeatherStation.module.css";

class AddWeatherStation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      weatherStation: null
    };
    this.weatherStationForm = React.createRef();
  }
  async componentWillMount() {
    const { match } = this.props;
    const { id } = match.params;
    if (id) {
      this.getWeatherStation(id);
    } else {
      this.setState({
        isFetching: false
      });
    }
  }

  getWeatherStation = weatherStationId => {
    const {
      actions: { getWeatherStationById }
    } = this.props;
    this.setState({
      isFetching: true
    });

    getWeatherStationById(weatherStationId)
      .then(weatherStation => {
        this.setState({
          isFetching: false,
          weatherStation
        });
      })
      .catch(error => {
        if (error.response.status === 404) {
          history.push("/404");
        }
        this.setState({
          isFetching: false
        });
      });
  };

  async componentDidUpdate(prevProps, prevState) {
    const { match } = this.props;
    const { id } = match.params;

    if (id && id !== prevProps.match.params.id) {
      this.getWeatherStation(id);
    }
  }

  doSubmitForm = () => {
    if (this.weatherStationForm.current) {
      this.weatherStationForm.current.submit();
    }
  };

  render() {
    const { weatherStation, isFetching } = this.state;
    const { route, match, Can } = this.props;
    const { id } = match.params;
    return (
      <LayoutOverlay
        closeLink={getParentPageHref(route)}
        route={{
          ...route,
          name: id ? route.name.replace("Add New", "Edit") : route.name
        }}
        childrenOnly
        footer={
          <div className={styles.buttonsFooter}>
            {weatherStation && weatherStation.id ? (
              <Can I="update" a="weather_stations">
                <Button primary size="large" onClick={this.doSubmitForm}>
                  Save
                </Button>
              </Can>
            ) : (
              <Can I="add" a="weather_stations">
                <Button primary size="large" onClick={this.doSubmitForm}>
                  Save
                </Button>
              </Can>
            )}
          </div>
        }
      >
        {isFetching ? (
          <Loader />
        ) : (
          <WeatherStationForm
            initialValues={weatherStation}
            formRef={this.weatherStationForm}
          />
        )}
      </LayoutOverlay>
    );
  }
}

AddWeatherStation.propTypes = {
  route: PropTypes.object.isRequired,
  match: PropTypes.object,
  actions: PropTypes.object,
  weatherStation: PropTypes.object,
  Can: PropTypes.func
};

AddWeatherStation.defaultProps = {
  route: { name: "{%name%}" }
};

const mapStateToProps = state => {
  return {
    Can: abilitiesSelector(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ getWeatherStationById }, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddWeatherStation);
