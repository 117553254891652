import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MultiSelectFilter from "./MultiSelectFilter/MultiSelectFilter";
import { fetchEmployeesBasicInformation } from "../../../actions/Employee/employees";

const EmployeeMultiSelectFilter = ({
  actions: { fetchList },
  list,
  isFetching,
  ...extraProps
}) => {
  useEffect(() => {
    fetchList();
  }, [fetchList]);

  const options = useMemo(
    () =>
      list
        .map(item => ({
          key: item.id,
          text: `${item.firstName} ${item.lastName}`,
          value: item.id
        }))
        .sort((o1, o2) => o1.text.localeCompare(o2.text)),
    [list]
  );

  return <MultiSelectFilter options={options} numbers={true} {...extraProps} />;
};

EmployeeMultiSelectFilter.propTypes = {
  isFetching: PropTypes.bool,
  list: PropTypes.array,
  actions: PropTypes.object
};

const mapStateToProps = ({
  employee: {
    data: { content },
    isFetching
  }
}) => {
  return {
    list: content,
    isFetching
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchList: fetchEmployeesBasicInformation
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeMultiSelectFilter);
