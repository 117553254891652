import { actionType } from '../../constants/Machineries';

const initialState = {
  herbicideOptions: [
    {
      key: 'true',
      text: 'Yes',
      value: true,
    },
    {
      key: 'false',
      text: 'No',
      value: false,
    },
  ],
  sizeUnitOptions: [
    {
      key: 'LITER',
      text: 'Litre',
      value: 'LITER',
    },
    {
      key: 'KILOGRAM',
      text: 'Kilogram',
      value: 'KILOGRAM',
    },
  ],
  data: {
    content: [],
    number: 0,
    totalPages: 0,
    totalElements: 0,
    size: 10,
    isFetching: false,
  },
  route: { isFetching: false, machineryId: null, route: [] },
  error: null,
};

export default (state = initialState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case actionType.ADD_MACHINERY_START:
      return state;

    case actionType.FETCH_MACHINERY_START: {
      return {
        ...state,
        data: {
          ...state.data,
          isFetching: true,
        },
      };
    }

    case actionType.FETCH_MACHINERY: {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
          isFetching: false,
        },
      };
    }

    case actionType.FETCH_MACHINERY_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          isFetching: false,
        },
        error: action.payload,
      };

    case actionType.CHANGE_MACHINERY_PAGE:
      return {
        ...state,
        data: {
          ...state.data,
          number: action.payload,
        },
      };

    case actionType.CHANGE_MACHINERY_PAGE_SIZE:
      return {
        ...state,
        data: {
          ...state.data,
          size: action.payload,
          totalPages: Math.ceil(state.data.totalElements / action.payload),
          number: 0,
        },
      };

    case actionType.ADD_MACHINERY: {
      const newTotalElements = state.data.totalElements + 1;
      const dataContent = [...state.data.content];
      if (state.data.last) {
        dataContent.push(action.payload);
      }

      return {
        ...state,
        data: {
          ...state.data,
          content: dataContent,
          totalElements: newTotalElements,
          totalPages: Math.ceil(newTotalElements / state.data.size),
        },
      };
    }

    case actionType.UPDATE_MACHINERY: {
      return {
        ...state,
        data: {
          ...initialState.data,
          content: state.data.content.map(machinery => {
            if (machinery.id === action.payload.id) {
              return action.payload;
            }
            return machinery;
          }),
        },
      };
    }

    case actionType.DELETE_MACHINERY: {
      const newTotalElements = state.data.totalElements - 1;

      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.filter(e => e.id !== action.payload),
          totalElements: newTotalElements,
          totalPages: Math.ceil(newTotalElements / state.data.size),
        },
      };
    }
    case actionType.GET_TRACKER_ROUTE_START: {
      return {
        ...state,
        route: {
          ...state.route,
          isFetching: true,
        },
      };
    }
    case actionType.GET_TRACKER_ROUTE: {
      return {
        ...state,
        route: {
          ...state.route,
          machineryId: action.payload.machineryId,
          route: action.payload.route,
          isFetching: false,
        },
      };
    }
    case actionType.CLEAR_TRACKER_ROUTE: {
      return {
        ...state,
        route: {
          ...state.route,
          machineryId: null,
          route: null,
          isFetching: false,
        },
      };
    }

    case actionType.ADD_MACHINERY_FAIL:
    case actionType.DELETE_MACHINERY_FAIL:
    case actionType.GET_MACHINERY_BY_ID_ERROR:
    case actionType.UPDATE_MACHINERY_FAIL:
    case actionType.CLEAR_MACHINERY_ERROR:
    case actionType.GET_TRACKER_ROUTE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};
