import { axiosProxy } from "../../utils/axiosProxy";
import { actionTypes } from "../../constants/Crops";
const url = "/crops";
export const getCropsList = () => async dispatch => {
  let url = "/crops";
  try {
    dispatch({
      type: actionTypes.GET_CROPS_START
    });

    const res = await axiosProxy({
      method: "GET",
      params: {
        sort: "name"
      },
      url
    });

    dispatch({
      type: actionTypes.GET_CROPS,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: actionTypes.GET_CROPS_FAIL,
      payload: error
    });
  }
};
export const fetchCropsList = () => async dispatch => {
  try {
    dispatch({ type: actionTypes.GET_CROPS_START });

    const params = new URLSearchParams({ unpaged: true });

    const res = await axiosProxy({ method: "GET", params, url });

    await dispatch({
      type: actionTypes.GET_CROPS,
      payload: res.data
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: actionTypes.GET_CROPS_FAIL,
      payload: error
    });
  }
};

export const clearError = () => dispatch =>
  dispatch({ type: actionTypes.CLEAR_ERROR });

export const getVarieties = cropId => async dispatch => {
  let url = `/crops/${cropId}/varieties`;
  try {
    dispatch({
      type: actionTypes.GET_VARIETIES_START
    });
    const res = await axiosProxy({
      method: "GET",
      params: {
        sort: "name"
      },
      url
    });

    dispatch({
      type: actionTypes.GET_VARIETIES,
      payload: { varieties: res.data, cropId }
    });
  } catch (error) {
    dispatch({
      type: actionTypes.GET_VARIETIES_FAIL,
      payload: error
    });
  }
};
