import React, { useState } from "react";
import moment from "moment";
import { useFormikContext } from "formik";
import {
  Icon,
  Segment,
  Grid,
  Container,
  Modal,
  Button,
  Popup
} from "semantic-ui-react";
import styles from "../Setting.module.css";
import SeasonsForm from "./SeasonsForm";

const SeasonsRow = ({ item, index, onSubmit, deleteAddingForm }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const onDeleteClick = async () => {
    onSubmit(null, index, null, true);
    setShowDeleteModal(false);
  };

  if (isEditing) {
    return (
      <Grid.Row className={styles.root}>
        <Segment className={styles.seasonsSegment} as={Container}>
          <SeasonsForm
            item={item}
            onSubmit={newValue => (
              onSubmit(newValue, index, isEditing), setIsEditing(false)
            )}
            deleteAddingForm={deleteAddingForm}
            isEditing={isEditing}
            onCancel={() => setIsEditing(false)}
          />
        </Segment>
      </Grid.Row>
    );
  }
  return (
    <Grid.Row className={styles.newSeasonsRow} as={Segment}>
      <Modal
        className={styles.deleteModal}
        closeOnDimmerClick={false}
        size="mini"
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
      >
        <Modal.Content className={styles.deleteMessageWrapper}>
          Delete season?
        </Modal.Content>
        <Modal.Actions>
          <Button
            className={styles.cancelDltBtn}
            onClick={() => setShowDeleteModal(false)}
          >
            Cancel
          </Button>
          <Button
            className={styles.deleteBtn}
            positive
            onClick={() => onDeleteClick()}
          >
            Delete
          </Button>
        </Modal.Actions>
      </Modal>
      <Grid.Column className={styles.valueColumn}>
        {item.seasonName}
      </Grid.Column>

      <Grid.Column className={styles.valueColumn}>
        {item.startAt ? moment(item.startAt).format("DD/MM/YYYY") : "-"}
      </Grid.Column>
      <Grid.Column className={styles.valueColumn}>
        {item.endAt ? moment(item.endAt).format("DD/MM/YYYY") : "-"}
      </Grid.Column>

      <Grid.Column
        className={styles.newSeasonssButtonCantainer}
        textAlign="right"
      >
        <Popup
          inverted
          position="bottom center"
          content={"Edit"}
          size="tiny"
          trigger={
            <div className={styles.editButtonWrapper}>
              <Button
                onClick={() => {
                  return setIsEditing(true);
                }}
                className={styles.editButton}
                floated="right"
                icon={<Icon name="edit" />}
                size="small"
              />
            </div>
          }
        />
        <Popup
          inverted
          position="bottom center"
          content={"Delete"}
          size="tiny"
          trigger={
            <div className={styles.deleteButton}>
              <Icon
                name="trash alternate"
                onClick={() => setShowDeleteModal(true)}
              />
            </div>
          }
        />
      </Grid.Column>
    </Grid.Row>
  );
};

export default SeasonsRow;
