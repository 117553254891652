import React, { useEffect } from "react";
import { Message, Grid, Icon } from "semantic-ui-react";
import styles from "./ActionMessage.module.css";

const DURATION = 5000;

export const DeletedMessage = ({ onClose, items, locationName, error }) => {
  useEffect(() => {
    const id = setTimeout(() => {
      onClose();
    }, DURATION);

    return () => clearTimeout(id);
  }, [items]);

  return (
    <div className={styles.deletedMessage}>
      <div className={styles.deletedMessageIn}>
        <Message
          hidden={!items.length || error}
          size="small"
          style={{
            paddingBlock: 0,
            background: "#EBF6E9",
            color: "#59B655",
            boxShadow: "none"
          }}
        >
          <Grid style={{ padding: 0 }} verticalAlign="middle">
            <Grid.Column floated="left" width={10}>
              {`${
                items.length === 1 ? locationName : `${locationName}s`
              } successfully deleted`}
            </Grid.Column>
            <Grid.Column floated="right" width={6} textAlign={"right"}>
              <Icon
                className={styles.closeButton}
                onClick={() => onClose()}
                name={"close"}
              />
            </Grid.Column>
          </Grid>
        </Message>
      </div>
    </div>
  );
};
