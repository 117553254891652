export const VACCINATION_STATUS = [
  { text: "Not vaccinated", key: 'NOT_VACCINATED"', value: "NOT_VACCINATED" },
  {
    text: "Partially vaccinated",
    key: "PARTIALLY_VACCINATED",
    value: "PARTIALLY_VACCINATED"
  },
  {
    text: "Fully vaccinated",
    key: "FULLY_VACCINATED",
    value: "FULLY_VACCINATED"
  },
  { text: "Exempt for medical reasons", key: "EXEMPTED", value: "EXEMPTED" }
];
