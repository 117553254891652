export const actionType = {
  ADD_MACHINERY_START: 'MACHINERIES/ADD_MACHINERY_START',
  ADD_MACHINERY: 'MACHINERIES/ADD_MACHINERY',
  ADD_MACHINERY_FAIL: 'MACHINERIES/ADD_MACHINERY_FAIL',

  CHANGE_VALUE: 'MACHINERIES/CHANGE_MACHINERY_VALUE',

  FETCH_MACHINERY_START: 'MACHINERIES/FETCH_MACHINERY_START',
  FETCH_MACHINERY: 'MACHINERIES/FETCH_MACHINERY',
  FETCH_MACHINERY_ERROR: 'MACHINERIES/FETCH_MACHINERY_ERROR',

  CHANGE_MACHINERY_PAGE: 'MACHINERIES/CHANGE_MACHINERY_PAGE',
  CHANGE_MACHINERY_PAGE_SIZE: 'MACHINERIES/CHANGE_MACHINERY_PAGE_SIZE',

  DELETE_MACHINERY_START: 'MACHINERIES/DELETE_MACHINERY_START',
  DELETE_MACHINERY: 'MACHINERIES/DELETE_MACHINERY',
  DELETE_MACHINERY_FAIL: 'MACHINERIES/DELETE_MACHINERY_FAIL',

  GET_MACHINERY_BY_ID: 'MACHINERIES/GET_MACHINERY_BY_ID',
  GET_MACHINERY_BY_ID_SUCCESS: 'MACHINERIES/GET_MACHINERY_BY_ID_SUCCESS',
  GET_MACHINERY_BY_ID_ERROR: 'MACHINERIES/GET_MACHINERY_BY_ID_ERROR',

  CLEAR_MACHINERY_CREATOR: 'MACHINERIES/CLEAR_MACHINERY_CREATOR',

  UPDATE_MACHINERY_START: 'MACHINERIES/UPDATE_MACHINERY_START',
  UPDATE_MACHINERY: 'MACHINERIES/UPDATE_MACHINERY',
  UPDATE_MACHINERY_FAIL: 'MACHINERIES/UPDATE_MACHINERY_FAIL',

  GET_TRACKER_ROUTE_START: 'MACHINERIES/GET_TRACKER_ROUTE_START',
  GET_TRACKER_ROUTE: 'MACHINERIES/GET_TRACKER_ROUTE',
  GET_TRACKER_ROUTE_FAIL: 'MACHINERIES/GET_TRACKER_ROUTE_FAIL',
  CLEAR_TRACKER_ROUTE: 'MACHINERIES/CLEAR_TRACKER_ROUTE',

  CLEAR_MACHINERY_ERROR: 'MACHINERIES/CLEAR_MACHINERY_ERROR',
};
