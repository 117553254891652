import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  archiveTasks,
  fetchRecommendations,
  setCurrentTaskById,
  unArchiveTasks
} from "actions/Recommendations/recommendations";
import { Button, Icon } from "semantic-ui-react";
import { recommendationsListPropsShape } from "constants/Recommendations/types";
import RecommendationSidebar from "./Sidebar/RecommendationSidebar";
import PageWithTableHolder from "components/PageWithTableHolder";
import { getRecommendationsTableColumns } from "../../utils";
import { Link } from "react-router-dom";
import { ADD_RECOMMENDATION } from "constants/shared/routes";
import { keyBy } from "lodash";
import {
  getRecommendationTaskReadableId,
  printRecommendationsList
} from "utils/recommendations";
import TasksArchivedMessage from "../../../Scouting/ScoutingList/components/TasksArchivedMessage";
import styles from "./RecommendationsList.module.css";
import { setHashParameter } from "utils/hashToObject";

const RecommendationsList = ({
  isFetching,
  location,
  route,
  recommendationsData
}) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [archivedItemsIds, setArchivedItems] = useState(null);
  const tableColumns = useMemo(getRecommendationsTableColumns, [tableData]);
  const isArchivedView = location.pathname.includes("archived");
  const dispatch = useDispatch();
  const getRecommendationsData = useCallback(
    requestData => {
      const { filters } = requestData;
      const filtersMap = Object.fromEntries(filters);
      const { plannedDateFrom, plannedDateTo } = filtersMap;

      dispatch(
        fetchRecommendations({
          ...requestData,
          archived: isArchivedView,
          dateFrom: plannedDateFrom,
          dateTo: plannedDateTo
        })
      );
      setSelectedItems([]);
    },
    [location]
  );
  useEffect(() => {
    setHashParameter(location, "empty", "*", null);
  }, []);

  useEffect(() => {
    if (!recommendationsData || !recommendationsData.content) {
      return;
    }

    setTableData(recommendationsData);
  }, [recommendationsData]);

  const onSelectedItemsPrintRequest = () => {
    const idsMap = keyBy(
      selectedItems.map(i => Number(i.replace(/^\D+/g, "")))
    );
    const selectedScoutingTasks = recommendationsData.content.filter(
      ({ id }) => !!idsMap[id]
    );

    printRecommendationsList(selectedScoutingTasks);
  };

  const archiveSelectedTasks = () => {
    const idsList = selectedItems.map(i => Number(i.replace(/^\D+/g, "")));

    dispatch(archiveTasks(idsList)).then(a => {
      setArchivedItems(idsList);
    });
    setSelectedItems([]);
  };

  const unArchiveSelectedTasks = () => {
    const idsList = selectedItems.map(i => Number(i.replace(/^\D+/g, "")));

    dispatch(unArchiveTasks(idsList));
  };

  const undoTasksArchiving = useCallback(() => {
    dispatch(unArchiveTasks(archivedItemsIds));
    setArchivedItems(null);
  }, [archivedItemsIds]);

  return (
    <>
      <PageWithTableHolder
        filterName={"empty"}
        actionsButtons={
          <>
            <Button
              basic
              color="green"
              disabled={!selectedItems.length}
              onClick={
                isArchivedView ? unArchiveSelectedTasks : archiveSelectedTasks
              }
            >
              <Icon name="archive" /> {isArchivedView ? "Unarchive" : "Archive"}
            </Button>
            <Button
              basic
              color="green"
              disabled={!selectedItems.length}
              onClick={onSelectedItemsPrintRequest}
            >
              <Icon name="print" /> Print
            </Button>
          </>
        }
        defaultSort={"id,desc"}
        className={styles.holder}
        getData={getRecommendationsData}
        isFetching={isFetching}
        location={location}
        mainButton={
          <Button as={Link} color="green" to={ADD_RECOMMENDATION}>
            Add recommendation
          </Button>
        }
        onRowClick={rowData => dispatch(setCurrentTaskById(rowData.id))}
        pageTitle={route.name}
        route={route}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        sidebarComponent={<RecommendationSidebar />}
        tableColumns={tableColumns}
        tableData={tableData}
      />
      <TasksArchivedMessage
        archivedItemsIds={archivedItemsIds}
        onClose={() => setArchivedItems(null)}
        onUndo={undoTasksArchiving}
        getPrefixFunction={getRecommendationTaskReadableId}
      />
    </>
  );
};

const mapStateToProps = state => {
  const { isFetching, data } = state.recommendations;

  return {
    recommendationsData: data,
    isFetching
  };
};

RecommendationsList.propTypes = recommendationsListPropsShape;

export default connect(mapStateToProps)(RecommendationsList);
