import React from "react";
import { Table, Popup, Icon } from "semantic-ui-react";
import CollapsibleBlock from "components/CollapsibleBlock";
import { chemicalShape, CHEMICAL_MATTERS } from "constants/Chemicals/types";
import { uppercaseFirstLetter } from "utils/string";
import { get } from "lodash";
import { matterToUnits } from "utils/constToUnits";
import styles from "../Sidebar.module.css";
import Numeric from "components/Numeric/Numeric";

const GeneralInformationSection = ({
  chemical,
  editButton,
  bucketsList,
  pendingOperation
}) => (
  <CollapsibleBlock
    title="General Information"
    className={styles.blockContainer}
    headerOptionalComponent={editButton}
  >
    <Table className={styles.sectionTable} definition fixed>
      <Table.Body>
        <Table.Row>
          <Table.Cell className={styles.headerSection}>Product type</Table.Cell>
          <Table.Cell>
            {uppercaseFirstLetter(get(chemical.type, "type"))}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell className={styles.headerSection}>Matter</Table.Cell>
          <Table.Cell>{CHEMICAL_MATTERS[chemical.matter]}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell className={styles.headerSection}>
            {chemical.trackInventory ? "Average price" : "Price"}
            {chemical.trackInventory && (
              <Popup
                position="right center"
                className={styles.popup}
                trigger={
                  <Icon
                    className={styles.averagePriceIcon}
                    name="info circle"
                  />
                }
                content={
                  <div className={styles.averagePriceIconContent}>
                    This price is an average of your currently available prices
                    in your sheds.
                  </div>
                }
                size="mini"
                inverted
              />
            )}
          </Table.Cell>
          <Table.Cell>
            {
              <>
                {!!chemical.inventoryAvgCost ? "$" : ""}
                <Numeric
                  fractionDigits={
                    (chemical.trackInventory
                      ? chemical.inventoryAvgCost
                      : chemical.price) > 1000
                      ? 0
                      : 2
                  }
                  value={
                    chemical.trackInventory
                      ? chemical.inventoryAvgCost
                      : chemical.price
                  }
                  commaSeparatorOnThousands
                  units={`/${matterToUnits(chemical.matter)} `}
                  unitsPosition="right"
                />
              </>
            }
          </Table.Cell>
        </Table.Row>
        {chemical.trackInventory && (
          <Table.Row>
            <Table.Cell className={styles.headerSection}>
              Expected inventory
              <Popup
                position="right center"
                className={styles.popup}
                trigger={
                  <Icon
                    className={styles.expectedInventoryIcon}
                    name="info circle"
                  />
                }
                content={
                  <div className={styles.expectedInventoryContent}>
                    Calculated available {chemical.name} based on your inventory
                    movements
                  </div>
                }
                size="mini"
                inverted
              />
            </Table.Cell>

            <Table.Cell>
              <>
                <Numeric
                  fractionDigits={chemical.expectedInventory > 1000 ? 0 : 2}
                  value={
                    chemical.expectedInventory
                      ? chemical.expectedInventory
                      : "-"
                  }
                  units={` ${matterToUnits(chemical.matter)} `}
                  commaSeparatorOnThousands
                />
              </>
            </Table.Cell>
          </Table.Row>
        )}
        {chemical.trackInventory && (
          <Table.Row>
            <Table.Cell className={styles.shedsNameHeaderWrapper}>
              Available in
            </Table.Cell>
            <Table.Cell className={styles.shedsNameWrapper}>
              {bucketsList &&
                bucketsList.map(item => {
                  const shedWithNegativeItem = pendingOperation.filter(
                    operation =>
                      operation.shedId === item.id &&
                      operation.chemicalId === chemical.id
                  );
                  const currenQty = !!shedWithNegativeItem.length
                    ? item.buckets
                        .filter(item => item.chemicalId == chemical.id)
                        ?.reduce(
                          (prev, curr) => prev + +curr.currentQuantity,
                          0
                        ) +
                      shedWithNegativeItem.reduce(
                        (prev, curr) => prev + curr.quantity,
                        0
                      )
                    : item.buckets
                        .filter(item => item.chemicalId == chemical.id)
                        ?.reduce(
                          (prev, curr) => prev + +curr.currentQuantity,
                          0
                        );
                  return (
                    <div>
                      {`${item.name} `}
                      (
                      <Numeric
                        fractionDigits={currenQty > 1000 ? 0 : 2}
                        value={`${currenQty}`}
                        commaSeparatorOnThousands
                        units={` ${matterToUnits(chemical.matter)}`}
                        unitsPosition="right"
                      />
                      )
                    </div>
                  );
                })}
            </Table.Cell>
          </Table.Row>
        )}

        <Table.Row>
          <Table.Cell className={styles.headerSection}>
            Track inventory
          </Table.Cell>
          <Table.Cell>{chemical.trackInventory ? "Yes" : "No"}</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  </CollapsibleBlock>
);

GeneralInformationSection.propTypes = {
  chemical: chemicalShape
};

export default GeneralInformationSection;
