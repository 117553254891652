import PropTypes from "prop-types";
import React from "react";
import A4_18_Labels from "../../../CustomGeneralBarcodesPrintLayout/layouts/A4_18_Labels.module.css";
import Page78x16 from "../../../CustomGeneralBarcodesPrintLayout/layouts/page_78mm_16mm_3_labels_23mm_13mm.module.css";
import Page100x40 from "../../../CustomGeneralBarcodesPrintLayout/layouts/page_100mm_40mm_4_Labels_37mm_22_3mm.module.css";
import Page80x85 from "../../../CustomGeneralBarcodesPrintLayout/layouts/page_80mm_85mm_1_label.module.css";
import Page80x40 from "../../../CustomGeneralBarcodesPrintLayout/layouts/page_80mm_40mm_1_label.module.css";
import Page70x60 from "../../../CustomGeneralBarcodesPrintLayout/layouts/page_70mm_60mm_1_label.module.css";
import { Dropdown } from "semantic-ui-react";
import CustomTagBarcodesPrintLayout from "../../../CustomTagBarcodesPrintLayout";

export const PrintLayouts = {
  "A4 8 Labels": {
    html: {
      render: data => (
        <CustomTagBarcodesPrintLayout
          labels={data.labels}
          series={data.series}
          itemsInPage={8}
          className={A4_18_Labels.layout}
          pageStyle={"@page { size: A4;}"}
        />
      )
    }
  },
  "78mm x 16mm 3 Labels": {
    html: {
      render: data => (
        <CustomTagBarcodesPrintLayout
          labels={data.labels}
          series={data.series}
          itemsInPage={3}
          className={Page78x16.layout}
          smallBarcode
          pageStyle={"@page { size: landscape;}"}
        />
      )
    }
  },
  "100mm x 40mm 3 Labels": {
    html: {
      render: data => (
        <CustomTagBarcodesPrintLayout
          labels={data.labels}
          series={data.series}
          itemsInPage={4}
          className={Page100x40.layout}
          smallBarcode
          pageStyle={"@page { size: landscape;}"}
        />
      )
    }
  },
  "80mm x 85mm 1 Label": {
    html: {
      render: data => (
        <CustomTagBarcodesPrintLayout
          labels={data.labels}
          series={data.series}
          itemsInPage={1}
          minimized
          fullHeight
          className={Page80x85.layout}
          pageStyle={"@page { size: landscape;}"}
        />
      )
    }
  },
  "80mm x 40mm 1 Label": {
    html: {
      render: data => (
        <CustomTagBarcodesPrintLayout
          labels={data.labels}
          series={data.series}
          itemsInPage={1}
          smallBarcode
          className={Page80x40.layout}
          pageStyle={"@page { size: landscape;}"}
        />
      )
    }
  },
  "70mm x 60mm 1 Label": {
    html: {
      render: data => (
        <CustomTagBarcodesPrintLayout
          labels={data.labels}
          series={data.series}
          itemsInPage={1}
          smallBarcode
          className={Page70x60.layout}
          pageStyle={"@page { size: landscape;}"}
        />
      )
    }
  }
};

export const OPTIONS = Object.keys(PrintLayouts).map(layout => ({
  key: layout,
  text: layout,
  value: layout
}));

const SelectPrintLayout = ({ value, name, onChange, ...rest }) => {
  return (
    <Dropdown
      {...rest}
      value={value}
      placeholder="Select print layout"
      selection
      fluid
      options={OPTIONS}
      onChange={(_, data) => onChange({ target: { name, value: data.value } })}
    />
  );
};

SelectPrintLayout.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string
};

SelectPrintLayout.defaultProps = {};

export default SelectPrintLayout;
