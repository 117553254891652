import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import PropTypes from "prop-types";
import filterTypes from "components/Table/filterTypes";
import { connect, useDispatch, useSelector } from "react-redux";
import { Icon, Button, Popup } from "semantic-ui-react";
import TablePageHolder from "components/TablePageHolder/TablePageHolder";
import {
  selectTableStructuredData,
  selectExpotStructuredData,
  formaterData
} from "selectors/reports/harvest/fieldScanning/contractor";
import {
  matterOptionsSelector,
  chemicalsOptionsFilterSelector,
  chemicalTypeOptionsSelector,
  typeOptionsSelector,
  activeIngredientsSelector
} from "selectors/chemicals";
import { farmsOptionsFilterSelector } from "selectors/farms";
import {
  blockSelector,
  patchSelector,
  blocListSelector,
  patchListSelector,
  varietiesOptionSelector,
  areaFilterOptions,
  cropsOptionSelector
} from "selectors/areas";
import {
  fetchContractorReportTableData,
  fetchContractorReportExportData
} from "actions/Reports/harvest/contractorReport";
import { fetchCropsList, getCropsList } from "actions/Farms/crops";
import { getVarietiesList } from "actions/Varieties/index";
import { harvestUnitsFetch } from "actions/HarvestUnits/harvestUnits";
import DateRangeSelect from "components/Table/components/Filters/DateRangeSelect";
import { history } from "../../../../../store";
import styles from "./ContractorReport.module.css";
import Numeric from "components/Numeric/Numeric";
import { excelExport } from "utils/excelExport";
import { useReactToPrint } from "react-to-print";
import { fetchActiveIngredients } from "actions/Chemicals";
import moment from "moment-timezone";
import _, { get, pickBy, pick } from "lodash";
import {
  allWorkersFilterOptions,
  workersFilterOptions
} from "selectors/harvestUnits";
import { contractorsListSelector } from "selectors/employee";
import PrintReports from "./ContractorReportPrintout/PrintReports";
import { harvestUnitOptionsSelector } from "selectors/boxUnits";
import { getNameInitials } from "routes/Labour/utils";
import { seasonsOptionsSelector } from "selectors/seasonsSelector";

const headerColumn = {
  contractor: "Contractors",
  totalQty: "No. units",
  totalPrice: "Total price ($)",
  date: "Date",
  farmId: "Farm",
  employeeIds: "Workers",
  areaId: "Block",
  patchId: "Patch",
  row: "Row",
  qty: "Quantity",
  cropId: "Crop",
  varietyId: "Variety",
  barcodeId: "Barcode Id",
  unitName: "Bin unit",
  price: "Price ($)"
};

const ROLE_COLORS = {
  OWNER: "#DED500",
  EXTERNAL_AGRONOMIST: "#37B963",
  OPERATOR: "#E87432",
  INTERNAL_AGRONOMIST: "#B4CA2D",
  WORKER: "#B25E21",
  SUPERVISOR: "#1DC5CF",
  FARM_MANAGER: "#3A67BF",
  ADMIN: "#AD3ABF",
  HEAD_OFFICE: "#DE4267"
};

const ContractorReport = ({ location, route, employees, areaList }) => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({
    type: [],
    matter: "",
    activeIngredient: [],
    blockIds: [],
    patchIds: [],
    chemicalIds: [],
    farmIds: [],
    seasons: [],
    from: "",
    to: ""
  });
  const workersList = useSelector(workersFilterOptions);
  const contractorsList = useSelector(contractorsListSelector);
  const farmsList = useSelector(farmsOptionsFilterSelector);
  const typeList = useSelector(chemicalTypeOptionsSelector);
  const blockList = useSelector(blocListSelector);
  const patchList = useSelector(patchListSelector);
  const varietiesList = useSelector(state =>
    varietiesOptionSelector(filters)(state)
  );
  const cropsList = useSelector(cropsOptionSelector);
  const binUnitsList = useSelector(harvestUnitOptionsSelector);
  const { content } = useSelector(selectExpotStructuredData);
  const [open, setOpen] = useState(false);
  const [filtersList, setFiltersList] = useState([]);
  const [printExpandedData, setPrintExpandedData] = useState(false);
  const [expandedRow, setExpandedRow] = useState(false);
  const [selectItem, setSelectItem] = useState();
  const [excel, setExcel] = useState();
  const componentRef = useRef();
  const chemicalsList = useSelector(state => state.chemical.list.content);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });
  const getData = useCallback(
    params => {
      setFiltersList(params);
      dispatch(fetchContractorReportTableData(params));
      dispatch(getCropsList());
      dispatch(getVarietiesList());
      dispatch(harvestUnitsFetch());
    },
    [fetchContractorReportTableData]
  );
  const columns = [
    {
      name: "Contractors",
      id: 1
    },
    {
      name: "No. units",
      id: 2
    },
    {
      name: "Total price ($)",
      id: 3
    },
    {
      name: "Date",
      id: 4
    },
    {
      name: "Farm",
      id: 5
    },
    {
      name: "Workers",
      id: 6
    },
    {
      name: "Block",
      id: 7
    },
    {
      name: "Patch",
      id: 8
    },
    {
      name: "Row",
      id: 9
    },
    {
      name: "Quantity",
      id: 10
    },
    {
      name: "Crop",
      id: 11
    },
    {
      name: "Variety",
      id: 12
    },
    {
      name: "Barcode Id",
      id: 13
    },
    {
      name: "Bin unit",
      id: 14
    },
    {
      name: "Price ($)",
      id: 15
    }
  ];

  const fetchPrintoutAllInformation = () => {
    return dispatch(fetchContractorReportExportData(filtersList));
  };

  const tableData = useSelector(selectTableStructuredData);
  const printData = useSelector(selectExpotStructuredData);
  useEffect(() => {
    return setExpandedRow(false);
  }, [tableData.number]);

  const handleExcelExport = useCallback(
    async selectedItem => {
      const headerNewData = pickBy(headerColumn, function(value, key) {
        return selectedItem && selectedItem.find(item => value === item);
      });
      const data = await dispatch(fetchContractorReportExportData(filtersList));

      if (data.content.length) {
        const newData = data.content
          ?.map(parent =>
            parent.contractorData.map(child => ({
              ...child,
              totalQty: parent.totalQty,
              contractorId: parent.contractorId,
              contractor: parent.contractorName,
              totalPrice: parent.totalPrice,
              patchId: child.areaId
            }))
          )
          .flat();
        const filtredDate = newData.map(item =>
          pick(item, Object.keys(headerNewData))
        );
        const expanded = filtredDate.map(item => {
          const areaName = areaList.find(area => area.id === item?.areaId);
          const patchName = areaList.find(area => area.id === item?.patchId);
          const farms = farmsList.options.find(
            farm => farm.key === item.farmId
          );
          return {
            ...(item.hasOwnProperty("contractor") && {
              contractor: item.contractor || "-"
            }),
            ...(item.hasOwnProperty("totalQty") && {
              totalQty: item.totalQty.toFixed(2) || "-"
            }),
            ...(item.hasOwnProperty("totalPrice") && {
              totalPrice: item.totalPrice.toFixed(2) || "-"
            }),
            ...(item.hasOwnProperty("date") && {
              date: moment(item.date).format("DD/MM/YYYY") || "-"
            }),
            ...(item.hasOwnProperty("farmId") && {
              farmId:
                farmsList.options.find(farm => farm.key == item.farmId)
                  ?.label || "-"
            }),
            ...(item.hasOwnProperty("employeeIds") && {
              employeeIds:
                employees
                  .filter(employee =>
                    item.employeeIds.some(i => i === employee.id)
                  )
                  .map(e => `${e.firstName}${e.lastName}`)
                  .join(",") || "-"
            }),
            ...(item.hasOwnProperty("areaId") && {
              block: areaName
                ? areaName.parent
                  ? areaName.parent.name
                  : areaName.name
                : " - "
            }),
            ...(item.hasOwnProperty("patchId") && {
              patch: patchName?.parent ? patchName.name : " - "
            }),
            ...(item.hasOwnProperty("row") && { row: item.row || "-" }),
            ...(item.hasOwnProperty("qty") && {
              qty: item.qty.toFixed(2) || "-"
            }),
            ...(item.hasOwnProperty("cropId") && {
              crop:
                cropsList.options.find(crop => crop.key === item.cropId)
                  ?.label || "-"
            }),
            ...(item.hasOwnProperty("varietyId") && {
              variety:
                varietiesList.options.find(
                  variety => variety.key === item.varietyId
                )?.label || "-"
            }),
            ...(item.hasOwnProperty("barcodeId") && {
              barcodeId: item.barcodeId || "-"
            }),
            ...(item.hasOwnProperty("unitName") && {
              unitName: item.unitName || "-"
            }),
            ...(item.hasOwnProperty("price") && {
              price: !isNaN(item.price) ? item.price.toFixed(2) : "-"
            })
          };
        });
        const filename = "contractor_report";
        excelExport(expanded, selectedItem, filename);
      }
    },
    [filtersList]
  );

  const columnsConfig = useMemo(() => {
    return [
      {
        accessor: "contractors",
        id: "contractor",
        Header: "Contractors",
        filterId: "date",
        withSort: true,
        Footer: <div className={styles.totalRow}>Total</div>,
        cellWidth: 2,
        Cell: ({ value, row }) => {
          return row.canExpand ? (
            <span {...row.getToggleRowExpandedProps({})}>
              <Icon
                className={styles.chevronIcon}
                onClick={() => setExpandedRow(row.id)}
                name={`angle ${row.isExpanded ? "up" : "down"}`}
              />
              {row.canExpand ? (
                <span
                  style={{
                    paddingRight: "10px"
                  }}
                >
                  {" "}
                  {value || "-"}
                </span>
              ) : (
                <div
                  className={styles.valueContainer}
                  style={{
                    borderLeft: "1px solid #DEDEDF",
                    borderBottom: "1px solid #DEDEDF"
                  }}
                >
                  {value || " - "}
                </div>
              )}
            </span>
          ) : row.depth == 1 ? (
            <span>
              {row.canExpand ? (
                value || "-"
              ) : (
                <div
                  className={styles.valueContainer}
                  style={{
                    borderLeft: "1px solid #DEDEDF",
                    borderBottom: "1px solid #DEDEDF"
                  }}
                >
                  {value || "-"}
                </div>
              )}
            </span>
          ) : (
            <span>
              {row.canExpand ? (
                value || "-"
              ) : (
                <div
                  className={styles.valueContainer}
                  style={{
                    borderLeft: "1px solid #DEDEDF",
                    borderBottom: "1px solid #DEDEDF"
                  }}
                >
                  {value || "-"}
                </div>
              )}
            </span>
          );
        },
        filter: {
          title: "Date",
          type: filterTypes.DateRangeSelect,
          selector: <DateRangeSelect />
        },
        minWidth: 100
      },
      {
        Header: "",
        onlyTable: true,
        id: "empty",
        withSort: false,
        cellWidth: 2,
        accessor: "empty",
        minWidth: 70,
        Cell: ({ value, row }) =>
          row.canExpand ? (
            value || "-"
          ) : (
            <div
              className={styles.valueContainer}
              style={{
                borderBottom: "1px solid #DEDEDF",
                whiteSpace: "normal"
              }}
            >
              {value || "-"}
            </div>
          ),
        filterId: "contractorIds",
        filter: {
          title: "Contractor",
          type: filterTypes.MultiSelect,
          selector: contractorsListSelector
        }
      },
      {
        Header: " ",
        onlyTable: true,
        id: "empty1",
        withSort: false,
        accessor: "empty1",
        minWidth: 70,
        cellWidth: 1,
        filterId: "employeeIds",
        filter: {
          title: "Worker",
          type: filterTypes.MultiSelect,
          selector: allWorkersFilterOptions
        },
        Cell: ({ value, row }) => {
          const numberOfEmployees = value.length;
          const employeeIds = value;
          const morePopupContent = members => {
            return (
              <div className={styles.morePopupContent}>
                {members.length &&
                  members &&
                  members.slice(3, members.length).map(emp => {
                    const employee =
                      (employees &&
                        emp &&
                        employees.find(
                          employeeObj => employeeObj.id === emp
                        )) ||
                      null;
                    const employeeImg =
                      employee && employee.picture
                        ? employee.picture.presignedUrl
                        : null;

                    return (
                      employee &&
                      !employee.archived && (
                        <div key={employee.id} className={styles.popupItem}>
                          <div
                            className={styles.avatarHolder}
                            style={
                              employeeImg
                                ? {
                                    backgroundImage:
                                      employeeImg && `url(${employeeImg})`
                                  }
                                : {
                                    background: ROLE_COLORS[employee.type]
                                      ? ROLE_COLORS[employee.type]
                                      : "gray"
                                  }
                            }
                          >
                            {!employeeImg && getNameInitials(employee)}
                          </div>
                          <span>{`${employee.firstName} ${employee.lastName}`}</span>
                        </div>
                      )
                    );
                  })}
              </div>
            );
          };
          return row.canExpand ? (
            value || "-"
          ) : (
            <div
              className={styles.valueContainer}
              style={{ borderBottom: "1px solid #DEDEDF" }}
            >
              {!Array.isArray(value) ? (
                value
              ) : numberOfEmployees ? (
                <ul className={styles.avatarsContainer}>
                  {employeeIds.slice(0, 3).map(employeeId => {
                    const employee =
                      (employees &&
                        employees.find(
                          employeeObj => employeeObj.id === employeeId
                        )) ||
                      null;
                    const employeeImg =
                      employee && employee.picture
                        ? employee.picture.presignedUrl
                        : null;

                    return employeeImg ? (
                      <Popup
                        position="bottom center"
                        inverted
                        key={employee.id}
                        trigger={
                          <li
                            key={employee.id}
                            className={styles.avatarHolder}
                            style={{ backgroundImage: `url(${employeeImg})` }}
                          />
                        }
                        content={`${employee.firstName} ${employee.lastName}`}
                      />
                    ) : (
                      <Popup
                        key={employee && employee.id}
                        inverted
                        position="bottom center"
                        trigger={
                          <li
                            key={employee && employee.id}
                            className={styles.avatarHolder}
                            style={{
                              background: ROLE_COLORS[employee && employee.type]
                                ? ROLE_COLORS[employee && employee.type]
                                : "gray"
                            }}
                          >
                            {getNameInitials(employee && employee)}
                          </li>
                        }
                        content={`${employee &&
                          employee.firstName} ${employee && employee.lastName}`}
                      />
                    );
                  })}
                  {numberOfEmployees > 3 && (
                    <Popup
                      inverted
                      position="right center"
                      popperModifiers={{
                        preventOverflow: {
                          boundariesElement: "offsetParent"
                        }
                      }}
                      content={morePopupContent(employeeIds)}
                      trigger={<span>+{numberOfEmployees - 3}</span>}
                    />
                  )}
                </ul>
              ) : (
                "—"
              )}
            </div>
          );
        }
      },
      {
        Header: " ",
        onlyTable: true,
        id: "empty2",
        withSort: false,
        accessor: "empty2",
        minWidth: 70,
        cellWidth: 1,
        Cell: ({ value, row }) =>
          row.canExpand ? (
            value || "-"
          ) : (
            <div
              className={styles.valueContainer}
              style={{ borderBottom: "1px solid #DEDEDF" }}
            >
              {value || "-"}
            </div>
          ),
        filterId: "areaIds",
        filter: {
          title: "Area",
          type: filterTypes.MultiSelect,
          selector: areaFilterOptions
        }
      },
      {
        Header: "No. units",
        className: "test",
        id: "totalQty",
        withSort: true,
        accessor: "numberOfUnits",
        minWidth: 70,
        Cell: ({ value, row }) =>
          row.canExpand ? (
            value || "-"
          ) : (
            <div
              className={styles.valueContainer}
              style={{
                borderBottom: "1px solid #DEDEDF"
              }}
            >
              {value || "-"}
            </div>
          ),
        filterId: "farmIds",
        filter: {
          title: "Farm",
          type: filterTypes.MultiSelect,
          selector: farmsOptionsFilterSelector
        },
        Footer: info => {
          const total = useMemo(
            () =>
              info.sortedRows.reduce(
                (sum, row) => +row.original.numberOfUnits + sum,
                0
              ),
            [info.rows]
          );
          return <div className={styles.totalRow}>{total.toFixed(2)}</div>;
        }
      },
      {
        Header: "",
        onlyTable: true,
        id: "empty3",
        withSort: false,
        accessor: "empty3",
        minWidth: 70,
        Cell: ({ value, row }) =>
          row.canExpand ? (
            value || "-"
          ) : (
            <div
              className={styles.valueContainer}
              style={{
                borderBottom: "1px solid #DEDEDF"
              }}
            >
              {value || "-"}
            </div>
          ),
        filterId: "cropIds",
        filter: {
          title: "Crop",
          type: filterTypes.MultiSelect,
          selector: cropsOptionSelector,
          loaderAction: fetchCropsList
        }
      },
      {
        Header: "",
        onlyTable: true,
        id: "empty4",
        withSort: false,
        accessor: "empty4",
        minWidth: 70,
        Cell: ({ value, row }) =>
          row.canExpand ? (
            " "
          ) : (
            <div
              className={styles.valueContainer}
              style={{
                borderBottom: "1px solid #DEDEDF"
              }}
            >
              {value || "-"}
            </div>
          ),
        filterId: "varietyIds",
        filter: {
          title: "Variety",
          type: filterTypes.MultiSelect,
          selector: state => varietiesOptionSelector(filters)(state)
        }
      },
      {
        Header: "",
        onlyTable: true,
        id: "empty5",
        withSort: false,
        accessor: "empty5",
        minWidth: 70,
        Cell: ({ value, row }) =>
          row.canExpand ? (
            " "
          ) : (
            <div
              className={styles.valueContainer}
              style={{
                borderBottom: "1px solid #DEDEDF"
              }}
            >
              {value || "-"}
            </div>
          )
      },
      {
        Header: "Total price ($)",
        id: "totalPrice",
        withSort: true,
        accessor: "totalPrice",
        minWidth: 80,
        Cell: ({ value, row }) =>
          row.canExpand ? (
            value || "-"
          ) : (
            <div
              className={styles.valueContainer}
              style={{
                whiteSpace: "normal",
                borderBottom: "1px solid #DEDEDF"
              }}
            >
              {value || "-"}
            </div>
          ),
        Footer: info => {
          const total = useMemo(
            () =>
              info.sortedRows.reduce(
                (sum, row) => +row.original.totalPrice + sum,
                0
              ),
            [info.rows]
          );
          return <div className={styles.totalRow}>{total.toFixed(2)}</div>;
        }
      },
      {
        Header: "",
        onlyTable: true,
        id: "empty6",
        withSort: false,
        accessor: "empty6",
        minWidth: 70,
        // cellWidth: 1,
        Cell: ({ value, row }) =>
          row.canExpand ? (
            " "
          ) : (
            <div
              className={styles.valueContainer}
              style={{
                borderBottom: "1px solid #DEDEDF",
                marginRight: "15px"
              }}
            >
              {value || "-"}
            </div>
          ),
        filterId: "seasons",
        filter: {
          title: "Season",
          type: filterTypes.SingleSelect,
          selector: seasonsOptionsSelector
        }
      },
      {
        Header: "",
        onlyTable: true,
        id: "empty7",
        withSort: false,
        accessor: "empty7",
        minWidth: 70,
        cellWidth: 2,
        Cell: ({ value, row }) =>
          row.canExpand ? (
            " "
          ) : (
            <div
              className={styles.valueContainer}
              style={{
                borderBottom: "1px solid #DEDEDF",
                marginRight: "15px"
              }}
            >
              {value || "-"}
            </div>
          )
      },
      {
        Header: "",
        onlyTable: true,
        id: "empty8",
        withSort: false,
        accessor: "empty8",
        minWidth: 70,
        // cellWidth: 1,
        Cell: ({ value, row }) =>
          row.canExpand ? (
            " "
          ) : (
            <div
              className={styles.valueContainer}
              style={{
                borderBottom: "1px solid #DEDEDF",
                borderRight: "1px solid #DEDEDF",
                marginRight: "15px"
              }}
            >
              {value || "-"}
            </div>
          )
      }
    ];
  }, [filters]);

  const handleBackClick = () => {
    history.push({
      pathname: "/reports",
      state: {
        activeNode: location?.state?.reportNode
      }
    });
  };
  const printButton = selectedItem => {
    setSelectItem(selectedItem);
    fetchPrintoutAllInformation().then(() => {
      setPrintExpandedData(true);
      handlePrint();
    });
  };
  return (
    <>
      {
        <div style={{ display: "none" }}>
          <PrintReports
            title="Contractor report"
            showExpandedData={printExpandedData}
            employees={employees}
            workersList={workersList}
            contractorsList={contractorsList}
            farmsList={farmsList}
            blockList={blockList}
            patchList={patchList}
            cropsList={cropsList}
            varietiesList={varietiesList}
            binUnitsList={binUnitsList}
            cropIds={filters.cropIds}
            farmIds={filters.farmIds}
            varietyIds={filters.varietyIds}
            blockIds={filters.blockIds}
            patchIds={filters.patchIds}
            employeeIds={filters.employeeIds}
            contractorIds={filters.contractorIds}
            binUnitsIds={filters.harvestUnitIds}
            tableData={content}
            selectedItem={selectItem}
            data={printData}
            from={filters.from}
            to={filters.to}
            ref={componentRef}
          />
        </div>
      }
      <TablePageHolder
        backButton={
          <Button
            floated="right"
            className={styles.backIconWrapper}
            onClick={handleBackClick}
          >
            <Icon className={styles.backIcon} name="angle left" />
          </Button>
        }
        actionsButtons={
          <>
            <Button
              size="small"
              floated="right"
              className={styles.printButton}
              onClick={() => {
                return setExcel(true), setOpen(true);
              }}
            >
              <div className={styles.iconWrapper}>
                <Icon name="download" />
                Export
              </div>
            </Button>
            <Button
              size="small"
              floated="right"
              className={styles.printButton}
              onClick={() => {
                return setExcel(false), setOpen(true);
              }}
            >
              <div className={styles.iconWrapper}>
                <Icon name="print" />
                Print
              </div>
            </Button>
          </>
        }
        rowId={expandedRow}
        // withMoreButton={true}
        numberofParent={3}
        excel={excel}
        getData={getData}
        isFetching={tableData.isFetching}
        location={location}
        onRowClick={() => {}}
        open={open}
        setOpen={setOpen}
        handleExcelExport={handleExcelExport}
        setFilters={setFilters}
        printButton={printButton}
        pageTitle={route.name}
        tableType="report"
        route={route}
        printColumns={columns}
        sectionModal={true}
        tableColumns={columnsConfig}
        withSelection={false}
        tableData={tableData}
        withoutSort={false}
        firstColumnFixed={false}
        singleLine
        fixed={true}
        withTable={true}
        withHeader={true}
      />
    </>
  );
};

ContractorReport.propTypes = {
  Can: PropTypes.func,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  totalElements: PropTypes.number.isRequired,
  route: PropTypes.object,
  filterValues: PropTypes.object,
  location: PropTypes.object
};

export default connect((state, props) => {
  return {
    areaList: state.areas.list.content,
    filterValues: state.packedBoxes.filters,
    productsList: state.chemical.list.content,
    isFetching: state.packedBoxes.data.isFetching,
    error: state.packedBoxes.error,
    employees: state.employee.list.content
  };
})(ContractorReport);
