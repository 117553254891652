import React, { useEffect } from "react";
import { Dropdown, Form, Grid } from "semantic-ui-react";
import { useFormikContext } from "formik";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchTemplates,
  setCurrentTask
} from "actions/Recommendations/recommendations";
import { map, find } from "lodash";

const TaskTemplateDropdown = ({ isFetching, templatesList, actions }) => {
  const { values } = useFormikContext();
  const templatesOptions = map(templatesList, item => ({
    key: item.id,
    value: item.id,
    text: item.name
  }));

  const onChange = (_, { value }) => {
    if (value) {
      const currentValue = templatesList.find(({ id }) => id === value);
      const { id, name, ...selectedValue } = currentValue;
      const newValue = {
        ...currentValue,
        ...selectedValue,
        templateId: id
      };

      actions.setCurrentTask(newValue);
    } else {
      actions.setCurrentTask({ ...values, templateId: null });
    }
  };

  useEffect(() => {
    actions.fetchTemplates();
  }, []);

  return (
    <Grid.Row>
      <Grid.Column>
        <Form.Field>
          <label>Select from task template</label>
          <Dropdown
            clearable
            search
            loading={isFetching}
            placeholder="Select template"
            selection
            fluid
            noResultsMessage="Nothing was found"
            options={templatesOptions}
            disabled={isFetching || values.id}
            value={values.templateId}
            onChange={onChange}
          />
        </Form.Field>
      </Grid.Column>
    </Grid.Row>
  );
};

const mapStateToProps = state => {
  const { templatesList, isTemplatesListFetching } = state.recommendations;

  return {
    templatesList,
    isFetching: isTemplatesListFetching
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchTemplates, setCurrentTask }, dispatch),
  dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskTemplateDropdown);
