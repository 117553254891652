import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import ChemicalSidebar from "../Sidebar";
import {
  fetchChemicals,
  changeArchivedStatus,
  setActiveItemId
} from "actions/Chemicals";
import { getCropsList } from "actions/Farms/crops";
import { fetchListSheds } from "actions/Sheds";
import { abilitiesSelector } from "selectors/user";
import { activeItemSelector } from "selectors/chemicals";
import { getChemicalsTableColumns } from "utils/chemicals";
import { Button } from "semantic-ui-react";
import InventoryActionButtons from "./components/InventoryActionButtons";
import { getHashParameter } from "utils/hashToObject";
import TablePageHolder from "components/TablePageHolder/TablePageHolder";
import { keyBy } from "lodash";
import InventoryModal from "../Modal";
import { chemicalShape } from "constants/Chemicals/types";
import styles from "./Inventory.module.css";
import { ActionMessage } from "../components/ActionMessage/ActionMessage";

const tableColumns = getChemicalsTableColumns();
const Inventory = ({
  isArchivedView,
  Can,
  isFetching,
  location,
  route,
  tableData,
  shedList,
  activeItem,
  fetchChemicalToggle,
  pendingOperation
}) => {
  const [selectedItemsIds, setSelectedItems] = useState([]);
  const [archivedStatus, setArchivedStatus] = useState(false);
  const [addEditModalOpen, setAddEditModalOpen] = useState(false);
  const [itemId, setItemId] = useState(null);
  const [archivedItems, setArchivedItems] = useState([]);
  const [editSectionIndex, setEditSectionIndex] = useState(null);
  const bucketsList = shedList.filter(({ buckets }) =>
    buckets.some(item => item.chemicalId === activeItem?.id)
  );
  const dispatch = useDispatch();
  const getData = useCallback(
    params => {
      setSelectedItems([]);
      dispatch(fetchChemicals({ ...params, archived: isArchivedView }));
      dispatch(getCropsList());
      dispatch(fetchListSheds(true));
      dispatch(setActiveItemId(null));
      setArchivedStatus(false);
    },
    [fetchChemicals, fetchChemicalToggle, archivedStatus]
  );
  const filtersOptions = [{}];
  const selectedProducts = useMemo(() => {
    const idsMap = keyBy(selectedItemsIds);

    return tableData.content.filter((_, index) => !!idsMap[index]);
  }, [selectedItemsIds]);
  useEffect(() => {
    activeItem === null && setEditSectionIndex(null);
  }, [activeItem]);

  const onArchiveClick = useCallback(() => {
    const idsMap = keyBy(selectedItemsIds);
    const itemsIds = tableData.content
      .filter((_, index) => !!idsMap[index])
      .map(({ id }) => id);
    setSelectedItems([]);
    setArchivedItems(itemsIds);
    dispatch(changeArchivedStatus(itemsIds, !isArchivedView));
    setArchivedStatus(true);
  }, [selectedItemsIds, tableData.content]);

  const breadcrumbSections = [
    { content: "Chemical and fertiliser", active: true },
    { content: route.name, active: false }
  ];
  const onRowClick = useCallback(
    (event, row) => {
      if (!activeItem && row) {
        dispatch(setActiveItemId(row.original.id));
      }
    },
    [activeItem]
  );
  const setCreateUpdateModalShown = (e, value, editSectionIndex) => {
    setAddEditModalOpen(true);
    setItemId(!!value && value);
    setEditSectionIndex(editSectionIndex);
  };
  return (
    <>
      <InventoryModal
        open={addEditModalOpen}
        onClose={() => {
          setAddEditModalOpen(false);
        }}
        chemical={activeItem}
        editSectionIndex={editSectionIndex}
      />
      <TablePageHolder
        actionsButtons={
          selectedItemsIds && selectedItemsIds.length ? (
            <InventoryActionButtons
              archiveButtonTitle={isArchivedView ? "Restore" : "Archive"}
              archiveButtonIcon="archive"
              onArchiveButtonClick={onArchiveClick}
              selectedProducts={selectedProducts}
              withModal={!isArchivedView}
            />
          ) : null
        }
        breadcrumbSections={breadcrumbSections}
        getData={getData}
        isFetching={isFetching}
        location={location}
        mainButton={
          !isArchivedView && (
            <Can I="add" a="chemicals">
              <Button
                primary
                className={styles.addButton}
                onClick={() => setAddEditModalOpen(true)}
              >
                Add
              </Button>
            </Can>
          )
        }
        onRowClick={onRowClick}
        onSidebarHidden={() => dispatch(setActiveItemId(null))}
        sidebarShown={!!activeItem}
        pageTitle={route.name}
        route={route}
        selectedItems={selectedItemsIds}
        setSelectedItems={setSelectedItems}
        sidebarComponent={
          <ChemicalSidebar
            chemical={activeItem}
            bucketsList={bucketsList}
            pendingOperation={pendingOperation}
            onEditClick={(e, value, editSectionIndex) =>
              setCreateUpdateModalShown(e, value, editSectionIndex)
            }
          />
        }
        tableColumns={tableColumns}
        tableData={tableData}
      />
      {!!archivedItems.length && (
        <ActionMessage
          items={archivedItems}
          onClose={() => setArchivedItems([])}
          onUndo={() => setArchivedItems([])}
          locationName="Inventory"
          isArchivedView={isArchivedView}
        />
      )}
    </>
  );
};

Inventory.propTypes = {
  Can: PropTypes.func,
  isArchivedView: PropTypes.bool,
  tableData: PropTypes.shape({
    content: PropTypes.arrayOf(chemicalShape)
  }).isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  totalElements: PropTypes.number.isRequired,
  fetchChemicals: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  route: PropTypes.object,
  filterValues: PropTypes.object,
  location: PropTypes.object
};

export default connect((state, props) => {
  const isArchivedView = props.location.pathname.includes("archived");

  return {
    isArchivedView,
    tableData: state.chemical.data,
    shedList: state.shed.list.content,
    filterValues: state.chemical.filters,
    pendingOperation: state.pendingMovements.list.content,
    isFetching: state.chemical.data.isFetching,
    Can: abilitiesSelector(state),
    error: state.chemical.error,
    activeItem: activeItemSelector(state)
  };
})(Inventory);
