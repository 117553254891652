import React from "react";
import PropTypes from "prop-types";
import { Button, Grid } from "semantic-ui-react";
import ObjectId from "bson-objectid";
import styles from "./TableSprayTask.module.css";

export const MIN_ITEMS_COUNT_TO_SHOW = 3;

function ShowMoreColumn({
  mobile,
  tablet,
  computer,
  largeScreen,
  className,
  items,
  renderItem,
  showAll,
  onChange
}) {
  const itemsToRender = items.filter(item => item);
  const itemsToShow = showAll
    ? itemsToRender
    : itemsToRender.slice(0, MIN_ITEMS_COUNT_TO_SHOW);
  const moreCount = itemsToRender.length - MIN_ITEMS_COUNT_TO_SHOW;
  const showActionButton = moreCount > 0;

  const handleShowMore = event => {
    event.stopPropagation();
    onChange && onChange(!showAll);
  };

  return (
    <Grid.Column
      mobile={mobile}
      tablet={tablet}
      computer={computer}
      largeScreen={largeScreen}
      className={className}
    >
      {itemsToShow.map(item =>
        renderItem ? (
          <span key={`item${item.id || ObjectId().toHexString()}`}>
            {renderItem(item)}
          </span>
        ) : (
          item
        )
      )}
      {showActionButton && (
        <Button
          className={`button-text ${styles.readFull}`}
          onClick={event => handleShowMore(event)}
        >
          {!showAll && moreCount > 0 ? (
            <span>+ {moreCount} More</span>
          ) : (
            "Show less"
          )}
        </Button>
      )}
    </Grid.Column>
  );
}

ShowMoreColumn.propTypes = {
  mobile: PropTypes.number,
  tablet: PropTypes.number,
  computer: PropTypes.number,
  largeScreen: PropTypes.number,
  className: PropTypes.string,
  renderItem: PropTypes.func,
  onChange: PropTypes.func,
  showAll: PropTypes.bool,
  items: PropTypes.array
};

export default ShowMoreColumn;
