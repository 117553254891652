import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";

import {
  fetchHarvestUnitsControl,
  fetchHarvestExportData,
  deleteHarvestUnits,
  harvestUnitsFetch
} from "actions/HarvestUnits/harvestUnits";
import { fetchGeneralTaskList } from "actions/GeneralTasks/generalTasks";
import { excelExport } from "utils/excelExport";
import { abilitiesSelector } from "selectors/user";
import { activeItemSelector } from "selectors/chemicals";
import { getHarvestUnitsTableolumns } from "utils/harvestUnitsControl";
import HarvestUnitsControlActionButtons from "./components/HarvestUnitsControlActionButtonsActionButtons";
import TablePageHolder from "components/TablePageHolder/TablePageHolder";
import { isEmpty, keyBy, pick, pickBy } from "lodash";
import { chemicalShape } from "constants/Chemicals/types";
import styles from "./HarvestUnitsControl.module.css";
import HarvestUnitsModal from "./components/Modal/HarvestUnitsControlModal";
import HarvestUnitsImageModal from "./components/Slider";
import HarvestUnitsAddModal from "./components/AddModal";
import { Icon, Button } from "semantic-ui-react";
import moment from "moment";
const headerColumn = {
  id: "ID",
  scannedAt: "Date",
  supervisors: "Supervisor",
  employeeBinParts: "Workers",
  farmLabel: "Farm",
  area: "Block/Patch",
  row: "Row",
  harvestUnit: "Harvest unit",
  price: "Price",
  /* batch: "Batch ID", */
  generalTaskId: "Task ID",
  internalBinId: "Internal bin ID"
};

const columns = [
  {
    name: "ID",
    id: 1
  },
  {
    name: "Date",
    id: 2
  },
  {
    name: "Supervisor",
    id: 3
  },
  {
    name: "Workers",
    id: 4
  },
  {
    name: "Farm",
    id: 5
  },
  {
    name: "Block/Patch",
    id: 6
  },
  {
    name: "Row",
    id: 7
  },
  {
    name: "Harvest unit",
    id: 8
  },
  {
    name: "Price",
    id: 9
  },

  /*   {
    name: "Batch ID",
    id: 10
  }, */
  {
    name: "Task ID",
    id: 10
  },
  {
    name: "Internal bin ID",
    id: 11
  }
];

const HarvestUnitsControl = ({
  isArchivedView,
  Can,
  isFetching,
  location,
  route,
  tableData,
  employees,
  areas,
  isUploadingAttachment
}) => {
  const [selectedItemsIds, setSelectedItems] = useState([]);
  const [isCreateUpdateModalShown, setCreateUpdateModalShown] = useState(false);
  const [harvestId, setHarvestId] = useState(null);
  const [addEditModalOpen, setAddEditModalOpen] = useState(false);
  const [editSectionIndex, setEditSectionIndex] = useState(null);
  const [imageModalOpen, setimageModalOpen] = useState(false);
  const [blockName, setblockName] = useState(false);
  const [dataRefetch, setDataRefetch] = useState(1);
  const [urlList, setUrlList] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [excel, setExcel] = useState();
  const [filtersList, setFiltersList] = useState([]);
  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState(false);

  const dispatch = useDispatch();
  const editCheck = id => {
    const activeHarvest = tableData.content.find(item => item.id === id);
    return (
      setActiveItem(activeHarvest),
      setCreateUpdateModalShown(true),
      setHarvestId(id)
    );
  };
  const tableColumns = getHarvestUnitsTableolumns(
    editCheck,
    employees,
    setblockName,
    setimageModalOpen,
    setUrlList,
    areas,
    isFetching
  );

  const getData = useCallback(
    params => {
      setFiltersList(params);
      dispatch(fetchHarvestUnitsControl({ ...params }));
      dispatch(harvestUnitsFetch());
      dispatch(fetchGeneralTaskList());
      setSelectedItems([]);
    },
    [fetchHarvestUnitsControl]
  );
  const selectedProducts = useMemo(() => {
    const idsMap = keyBy(selectedItemsIds);

    return tableData.content.filter((_, index) => !!idsMap[index]);
  }, [selectedItemsIds]);
  useEffect(() => {
    activeItem === null && setEditSectionIndex(null);
  }, [activeItem]);

  const updateTable = () => {
    setDataRefetch(dataRefetch + 1);
  };
  const onDeleteClick = useCallback(() => {
    const idsMap = keyBy(selectedItemsIds);
    const itemsIds = tableData.content
      .filter((_, index) => !!idsMap[index])
      .map(({ id }) => id);
    setSelectedItems([]);
    dispatch(deleteHarvestUnits(itemsIds)).then(updateTable());
  }, [selectedItemsIds, tableData.content]);

  const handleExcelExport = useCallback(
    async selectedItem => {
      const data = await dispatch(fetchHarvestExportData(filtersList));
      const { content } = data;
      const newContent = content.map(item => {
        return {
          ...item,
          area: areas.content.find(area => area.id === item.areaId),
          employeeBinParts: item.employeeBinParts.map(e => {
            return {
              ...e,
              employee: employees.find(emp => emp.id === e.employeeId)
            };
          }),
          supervisors: item.supervisors.map(s => {
            return {
              ...s,
              employee: employees.find(e => e.id === s.employeeId)
            };
          })
        };
      });
      if (newContent.length) {
        const headerNewData = pickBy(headerColumn, function(value, key) {
          return selectedItem && selectedItem.find(item => value === item);
        });
        const expanded = (selectedItem.includes("Workers")
          ? newContent
              .map(item => {
                return item.employeeBinParts.map(employee => {
                  return {
                    id: item.id,
                    scannedAt: item.scannedAt,
                    supervisors: item.supervisors,
                    employeeBinParts: employee,
                    farmLabel: item.area?.farm.name,
                    area: item.area,
                    row: item.row,
                    harvestUnit: item.harvestUnit,
                    price: item.price,
                    /*  batch: item.batch, */
                    generalTaskId: item.generalTaskId,
                    internalBinId: item.internalBinId
                  };
                });
              })
              .flat()
          : newContent
        )
          .reduce((prev, block) => {
            return [
              ...prev,
              pick(block, Object.keys(headerNewData)),
              ...(block.subRows
                ? block.subRows.reduce(
                    (prev, { subRows, isSubRows, ...rest }) => {
                      const temp = [...prev];
                      if (isSubRows) {
                        temp.push({
                          ...pick(rest, Object.keys(headerNewData))
                        });
                      }
                      return temp;
                    },
                    []
                  )
                : [])
            ];
          }, [])
          .map(item => {
            return {
              ...(item.id && { id: item.id }),
              ...(item.scannedAt && {
                scannedAt: item.scannedAt
                  ? moment(item.scannedAt).format("DD/MM/YYYY ")
                  : "-"
              }),
              ...(item.supervisors && {
                supervisors:
                  item.supervisors &&
                  !!item.supervisors.length &&
                  item.supervisors.length >= 1
                    ? item.supervisors
                        .map(
                          item =>
                            `${item.employee.firstName} ${item.employee.lastName}`
                        )
                        .join(",  ")
                    : "-"
              }),
              ...(item.employeeBinParts && {
                employee:
                  item.employeeBinParts &&
                  item.employeeBinParts.employee?.firstName &&
                  item.employeeBinParts.employee?.lastName
                    ? `${item.employeeBinParts.employee?.firstName} ${item.employeeBinParts.employee?.lastName}`
                    : "-"
              }),
              ...(item.hasOwnProperty("farmLabel") && {
                farmLabel: item.farmLabel || "-"
              }),
              ...(item.area && {
                area:
                  item.area.type === "PATCH"
                    ? `${item.area.parent.name} - ${item.area?.name}`
                    : `${item.area?.name}`
              }),
              ...(item.hasOwnProperty("row") && { row: item.row }),
              ...(item.hasOwnProperty("harvestUnit") && {
                harvestUnit: `${item.harvestUnit?.name} ${item.harvestUnit?.netWeight} Kg`
              }),
              ...(item.hasOwnProperty("price") && {
                price: item.price ? item.price.toFixed(2) : "-"
              }),
              ...(item.hasOwnProperty("generalTaskId") && {
                generalTaskId: item.generalTaskId || "-"
              }),
              ...(item.hasOwnProperty("internalBinId") && {
                internalBinId: item.internalBinId || "-"
              })
            };
          });
        const dataeFromFilter = filters.from
          ? filters.from && moment(filters.from).format("DD/MM/YYYY")
          : "";
        const dataeTOFilter = `${
          filters.to
            ? filters.to && moment(filters.to).format("DD/MM/YYYY")
            : ""
        } `;
        const dateCheker = dataeFromFilter && dataeTOFilter ? "-" : "";
        const ids = filters.ids?.length ? `ID-${filters.ids.join(", ")} ` : "";
        const fileName = `harvest_units ${`${dataeFromFilter}${dateCheker}`}${dataeTOFilter} ${ids}`;
        excelExport(expanded, selectedItem, fileName);
      }
    },
    [filtersList]
  );

  return (
    <>
      <HarvestUnitsModal
        areas={areas}
        employees={employees}
        open={isCreateUpdateModalShown}
        units={tableData.content}
        harvestId={harvestId}
        activeItem={activeItem}
        updateTable={updateTable}
        urlList={urlList}
        isUploadingAttachment={isUploadingAttachment}
        onClose={() => {
          return setCreateUpdateModalShown(false), setActiveItem(null);
        }}
        unit={activeItem}
      />
      <HarvestUnitsAddModal
        open={addEditModalOpen}
        areas={areas}
        updateTable={updateTable}
        onClose={() => {
          return setAddEditModalOpen(false);
        }}
        unit={activeItem}
      />

      <HarvestUnitsImageModal
        open={imageModalOpen}
        units={tableData.content}
        blockName={blockName}
        urlList={urlList}
        onClose={() => {
          return setimageModalOpen(false);
        }}
        unit={activeItem}
      />
      <TablePageHolder
        actionsButtons={
          <>
            <Button
              size="small"
              floated="right"
              className={styles.exportButton}
              onClick={() => {
                return setExcel(true), setOpen(true);
              }}
            >
              <div className={styles.iconWrapper}>
                <Icon name="download" />
                Export
              </div>
            </Button>

            {selectedItemsIds && selectedItemsIds.length ? (
              <HarvestUnitsControlActionButtons
                onDeleteClick={onDeleteClick}
                selectedProducts={selectedProducts}
                withModal={true}
              />
            ) : null}
          </>
        }
        getData={getData}
        handleExcelExport={handleExcelExport}
        excel={excel}
        setFilters={setFilters}
        open={open}
        setOpen={setOpen}
        printColumns={columns}
        isFetching={isFetching}
        location={location}
        mainButton={
          !isArchivedView && (
            <Can I="add" a="chemicals">
              <Button
                primary
                className={styles.addButton}
                onClick={() => setAddEditModalOpen(true)}
              >
                Add
              </Button>
            </Can>
          )
        }
        withMoreButton={true}
        onRowClick={() => {}}
        pageTitle={route.name}
        route={route}
        selectedItems={selectedItemsIds}
        setSelectedItems={setSelectedItems}
        fixed={false}
        singleLine
        tableColumns={tableColumns}
        tableData={tableData}
        dataRefetch={dataRefetch}
      />
    </>
  );
};

HarvestUnitsControl.propTypes = {
  Can: PropTypes.func,
  isArchivedView: PropTypes.bool,
  tableData: PropTypes.shape({
    content: PropTypes.arrayOf(chemicalShape)
  }).isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  totalElements: PropTypes.number.isRequired,
  fetchChemicals: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  route: PropTypes.object,
  location: PropTypes.object
};

export default connect((state, props) => {
  const isArchivedView = props.location.pathname.includes("archived");

  return {
    isArchivedView,
    tableData: state.harvestUnitsControl.data,
    areas: state.areas.list,
    isUploadingAttachment: state.chemical.isUploadingAttachment,
    isFetching: state.harvestUnitsControl.isFetching,
    employees: state.employee.list.content,
    Can: abilitiesSelector(state),
    error: state.chemical.error,
    activeItem: activeItemSelector(state)
  };
})(HarvestUnitsControl);
