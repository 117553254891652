import React, { Component } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import overlaps from "turf-overlaps";

import { bindActionCreators } from "redux";
import { Button, Header, Icon, Sidebar } from "semantic-ui-react";
import ObjectId from "bson-objectid";
import {
  polygon as turfPolygon,
  centerOfMass,
  booleanContains
} from "@turf/turf";
import HeaderOverlay from "../../../components/HeaderOverlay";
import GoogleMaps from "../../../components/GoogleMaps";
import MapButtons from "./MapButtons";
import asyncForEach from "../../../utils/asyncForEach";
import { getParentPageHref } from "../../../utils/routeHelpers";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";

// eslint-disable-next-line no-unused-vars
import {
  setFarmField,
  setPolygon,
  clearPolygons,
  initBlock,
  removeBlock,
  removePatchesFromBlock,
  addPatchesToBlock,
  setBlockField,
  setBlockFieldsMultiple,
  setCurrentBlock,
  setCurrentPatch,
  removeAllBlocks,
  setPatchField,
  archiveBlockById
} from "../../../actions/Farms/addNewFarm";
import { archiveArea } from "../../../actions/Farms/areas";
import { setNewFarm } from "../../../actions/Farms/farms";
import { initialState } from "../../../reducers/Farms";

import styles from "./AddNewFarm.module.css";
import { Area } from "../../../models/block.model";
import { AreaVariety } from "../../../models/area_variety.model";
import { InfoWindow } from "@react-google-maps/api";

const drawingOptions = {
  drawingMode: "polygon",
  polygonOptions: { editable: true },
  drawingControlOptions: {
    position: 2,
    drawingModes: ["polygon"]
  }
};

const mapOptions = {
  streetViewControl: false,
  mapTypeControlOptions: {
    style: 1,
    position: 11
  },
  mapTypeId: "satellite",
  maxZoom: 21
};

const MODE_VIEW = "view";
const MODE_EDIT = "edit";

const DrawPolygonWarning = {
  PATCH_OUTSIDE_BLOCK: "Patch is out of the block area",
  OVERLAPS: "Polygon overlaps with others"
};

const getLatLong = props => {
  let currentLatLng = {
    lat: _.get(props, "newFarm.location.0", 0),
    lng: _.get(props, "newFarm.location.1", 0)
  };
  const { currentPatch, currentBlock } = props.newFarm;
  if (currentBlock) {
    const block = (props.newFarm.blocks || []).find(
      block => block.id === currentBlock
    );
    let patch;
    if (currentPatch) {
      patch = (block.patches || []).find(patch => patch.id === currentPatch);
    }
    const polygons = _.get(patch, "polygons") || _.get(block, "polygons");
    if (polygons) {
      const coordinates = (props.newFarm.polygonsToAdd || []).find(polygon =>
        polygons.includes(polygon.id)
      );
      const [lat, lng] = _.get(coordinates, "coordinates.0", [0, 0]);
      currentLatLng = { lat, lng };
    }
  }

  return currentLatLng;
};

class AddNewFarm extends Component {
  constructor(props) {
    super(props);

    const currentLatLng = getLatLong(props);
    this.state = {
      showMap: false,
      currentLatLng: currentLatLng,
      zoom: 16,
      showBox: false,
      showDrawing: false,
      drawingOptions,
      mapOptions,
      polygonsToAdd: [], // should store polygons in state because redux tools is crashing
      isPatchInside: true,
      currentBlock: null,
      stageMode: null,
      markers: [],
      mapInstance: null,
      mapMode: MODE_VIEW,
      drawStack: [],
      saveStack: [],
      editingEntity: null
    };
  }

  componentDidMount() {
    this.checkPage();
    const { currentLatLng } = this.state;
    if (currentLatLng.lat === 0 || currentLatLng.lng === 0) {
      this.getCurrentLocation();
    } else {
      this.setState({ showMap: true });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { newFarm } = this.props;
    if (prevProps.newFarm !== newFarm) {
      this.checkPage();
    }
    if (prevProps.newFarm !== newFarm) {
      this.setState({
        currentBlock: newFarm.currentBlock
          ? newFarm.blocks.find(block => block.id === newFarm.currentBlock)
          : null
      });
      this.getMarkers();
    }
  }

  componentWillUnmount() {
    this.props.actions.setNewFarm({ newFarm: initialState.newFarm });
  }

  // Redirects if newFarm object have not required params for page
  checkPage = () => {
    const { history, newFarm, route, match } = this.props;
    const step = Number(_.get(match, "params.step", 1));
    if (!newFarm.name) {
      history.replace(route.href);
    } else if (newFarm.blocks.length === 0 && step === 4) {
      this.setStep(1);
    }
  };

  getCurrentLocation = () => {
    if (!navigator.geolocation) {
      return this.setState({ showMap: true });
    }

    return navigator.geolocation.getCurrentPosition(
      position => {
        this.setState({
          currentLatLng: {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          },
          showMap: true,
          zoom: 18
        });
      },
      e => {
        this.setState({
          showMap: true,
          currentLatLng: {
            lat: -33.87,
            lng: 151.21
          }
        });
      },
      { timeout: 3000 }
    );
  };

  setMode = (mapMode, extendData = {}) => {
    return new Promise(resolve => {
      this.setState(
        {
          mapMode,
          ...extendData
        },
        resolve
      );
    });
  };

  getMapDrawingOptions = () => {
    const { mapMode } = this.state;
    switch (mapMode) {
      case MODE_EDIT:
        return {
          drawingDisabled: false,
          showDrawing: true,
          drawingOptions: drawingOptions
        };
      default:
        return {
          drawingDisabled: true,
          showDrawing: false,
          drawingOptions: {
            ...drawingOptions,
            drawingMode: ""
          }
        };
    }
  };

  setPolygonsVisibilityForFarm = () => {
    const {
      newFarm: { blocks }
    } = this.props;
    const { polygonsToAdd } = this.state;

    blocks.forEach(block => {
      polygonsToAdd
        .filter(polygon =>
          block.polygons.some(polygonId => polygon.id === polygonId)
        )
        .forEach(polygon =>
          polygon.setMap(!block.archived ? this.mapInstance : null)
        );

      block.patches.forEach(patch => {
        polygonsToAdd
          .filter(polygon =>
            patch.polygons.some(polygonId => polygon.id === polygonId)
          )
          .forEach(polygon =>
            polygon.setMap(
              !patch.archived && !block.archived ? this.mapInstance : null
            )
          );
      });
    });
    this.setState({ polygonsToAdd });
  };

  onLoadMap = mapInstance => {
    const { polygonsToAdd } = this.props.newFarm;
    this.mapInstance = mapInstance;
    this.setState({ showBox: true });
    const maps = window.google.maps;

    const polygonsToAddState = [];
    if (!_.isEmpty(polygonsToAdd)) {
      polygonsToAdd.forEach(({ id, coordinates }) => {
        if (!_.isEmpty(coordinates)) {
          const polygon = new maps.Polygon({
            path: coordinates.map(point => new maps.LatLng(...point))
          });

          polygon.id = id;
          polygonsToAddState.push(polygon);
        }
      });

      this.setState({ polygonsToAdd: polygonsToAddState }, () => {
        this.setPolygonsVisibilityForFarm();
        this.getMarkers();
      });
    }
  };

  changeLocation = currentLatLng => {
    this.setState({ currentLatLng });
  };

  // Changing location for flow
  setStep = (step, replace = false) => {
    const { history } = this.props;

    const url = `/farms/add_new_farm/${step}`;
    replace ? history.replace(url) : history.push(url);
  };

  isPatchPolygonOutsideParentBlock = polygon => {
    const {
      newFarm: { currentBlock, currentPatch: isPolygonIsPatch }
    } = this.props;
    const { polygonsToAdd } = this.state;
    const block = this.getBlockById(currentBlock);
    if (
      !isPolygonIsPatch ||
      block.patchMode !== "PATCHES" ||
      block.polygons.length === 0
    ) {
      return true;
    }

    const polygonPatchGEO = turfPolygon([this.getGEOJSONPolygon(polygon)]);
    return (
      block.polygons
        .map(polygonBlockId =>
          polygonsToAdd.find(polygon => polygon.id === polygonBlockId)
        )
        .filter(p => p)
        .map(p => turfPolygon([this.getGEOJSONPolygon(p)]))
        .filter(pGeo => booleanContains(pGeo, polygonPatchGEO)).length !== 0
    );
  };

  isOverlapping = polygon => {
    const { polygonsToAdd, currentBlock } = this.state;
    const polygonsToCheck = polygonsToAdd.filter(
      currPoly =>
        currPoly.id !== polygon.id &&
        currentBlock.polygons.findIndex(item => item === currPoly.id) === -1
    );
    const polygonGeoJson = turfPolygon([this.getGEOJSONPolygon(polygon)]);
    return (
      polygonsToCheck
        .filter(p => p.getPath().getLength() > 2 && p.getMap())
        .map(p => turfPolygon([this.getGEOJSONPolygon(p)]))
        .filter(
          p => overlaps(p, polygonGeoJson) || booleanContains(p, polygonGeoJson)
        ).length !== 0
    );
  };

  // Adding listeners for created polygon and storing into state
  setPolygonReady = polygon => {
    if (polygon.getPath().getLength() > 2) {
      const { actions } = this.props;
      const { polygonsToAdd, drawStack, saveStack } = this.state;
      const polygonId = ObjectId().toHexString();
      polygon.id = polygonId;
      actions.setPolygon(polygonId);
      this.setState({
        polygonsToAdd: [...polygonsToAdd, polygon],
        drawStack: [...drawStack, polygon.id],
        saveStack: [...saveStack, polygon.id]
      });
      if (!this.isPatchPolygonOutsideParentBlock(polygon)) {
        this.setState({
          drawPolygonWarning: {
            polygon,
            warning: DrawPolygonWarning.PATCH_OUTSIDE_BLOCK
          }
        });
      } else {
        if (this.isOverlapping(polygon)) {
          this.setState({
            drawPolygonWarning: {
              polygon,
              warning: DrawPolygonWarning.OVERLAPS
            }
          });
        }
      }
    } else {
      polygon.setMap(null);
    }
  };

  // Converting google maps polygon into GeoJSON format
  getGEOJSONPolygon = polygon => {
    const vertices = polygon.getPath();
    const path = [];

    for (let corner = 0; corner < vertices.getLength(); corner++) {
      const xy = vertices.getAt(corner);
      path.push([xy.lat(), xy.lng()]);
    }
    path.push(path[0]);

    return path;
  };

  getMarkers = () => {
    const { newFarm } = this.props;
    const { polygonsToAdd } = this.state;

    const markers = [];
    const collectLevel = blocksOrPatches => {
      blocksOrPatches
        .filter(block => !block.archived)
        .forEach((block, index) => {
          block.polygons.forEach(polygonId => {
            const polygonTarget = polygonsToAdd.find(
              polygon => polygon.id === polygonId
            );

            if (polygonTarget && polygonTarget.getPath().getLength() >= 3) {
              const center = centerOfMass(
                turfPolygon([this.getGEOJSONPolygon(polygonTarget)])
              );
              markers.push({
                id: `${block.id}_${polygonId}`,
                label: {
                  text:
                    block.name ||
                    `${block.type === "block" ? "Block" : "Patch"} ${index +
                      1}`,
                  color: "white"
                },
                icon: {
                  path: window.google.maps.SymbolPath.CIRCLE,
                  scale: 0
                },
                position: {
                  lat: center.geometry.coordinates[0],
                  lng: center.geometry.coordinates[1]
                }
              });
            }
          });
          if (block.patches) {
            collectLevel(block.patches);
          }
        });
    };
    collectLevel(newFarm.blocks);
    this.setState({ markers });
  };

  updateCurrentEntityPolygons = cb => {
    const { newFarm, actions } = this.props;
    const { currentBlock, currentPatch } = newFarm;
    const editingEntity = currentPatch || currentBlock;

    // find current entity and update polygons according to cb
    const farmBlocks = newFarm.blocks || [];
    farmBlocks.forEach(block => {
      if (block.id === editingEntity) {
        return actions.setBlockField({
          blockId: block.id,
          fieldName: "polygons",
          fieldValue: cb(block.polygons)
        });
      }
      const patches = block.patches || [];
      patches.forEach(patch => {
        if (patch.id === editingEntity) {
          return actions.setPatchField({
            blockId: block.id,
            patchId: patch.id,
            fieldName: "polygons",
            fieldValue: cb(patch.polygons)
          });
        }
      });
    });
  };

  handleMapSave = () => {
    const { polygonsToAdd, drawStack } = this.state;
    const { newFarm, actions } = this.props;
    let polygonArea = 0;
    polygonsToAdd.forEach(polygon => {
      if (drawStack.includes(polygon.id)) {
        polygon.setEditable(false);
        polygonArea += window.google.maps.geometry.spherical.computeArea(
          polygon.getPath()
        );
      }
    });

    if (newFarm.currentBlock) {
      let data = {
        blockId: newFarm.currentBlock,
        fieldName: "size",
        fieldValue: (polygonArea / 10000).toFixed(2)
      };

      if (newFarm.currentPatch) {
        data = { ...data, patchId: newFarm.currentPatch };
        actions.setPatchField(data);
      } else {
        actions.setBlockField(data);
      }
    }

    if (!_.isEmpty(drawStack)) {
      this.updateCurrentEntityPolygons(existedPolygons => {
        return _.uniq([...existedPolygons, ...drawStack]);
      });
    }

    this.setState({ drawPolygonWarning: null });
    return this.setMode(MODE_VIEW, { drawStack: [], saveStack: [] });
  };

  handleMapDelete = () => {
    const { polygonsToAdd, drawStack } = this.state;
    if (_.isEmpty(drawStack)) {
      return Promise.resolve(false);
    }
    // remove from map before set to state
    polygonsToAdd
      .filter(polygon => drawStack.includes(polygon.id))
      .map(polygon => {
        polygon.setMap(null);
        return true;
      });

    this.updateCurrentEntityPolygons(existedPolygons => {
      return _.without(existedPolygons, ...drawStack);
    });

    return new Promise(resolve => {
      this.setState(
        {
          polygonsToAdd: polygonsToAdd.filter(
            polygon => !drawStack.includes(polygon.id)
          ),
          drawStack: [],
          saveStack: [],
          drawPolygonWarning: null
        },
        resolve
      );
    });
  };

  handleMapCancel = () => {
    const { saveStack, polygonsToAdd } = this.state;
    const savedPolygons = polygonsToAdd.reduce((next, polygon) => {
      if (saveStack.includes(polygon.id)) {
        polygon.setMap(null);
        return next;
      }
      polygon.setEditable(false);
      return [...next, polygon];
    }, []);
    this.setState({ drawPolygonWarning: null });
    return this.setMode(MODE_VIEW, {
      polygonsToAdd: savedPolygons,
      drawStack: [],
      saveStack: []
    });
  };

  handleMapEdit = () => {
    const { newFarm } = this.props;
    const { polygonsToAdd } = this.state;
    const { currentBlock, currentPatch } = newFarm;

    const entity = this.getCurrentEntity(currentBlock, currentPatch);
    const polygons = entity.polygons || [];

    polygonsToAdd.forEach(polygon => {
      if (polygons.includes(polygon.id)) {
        polygon.setEditable(true);
      }
    });

    return this.setMode(MODE_EDIT, {
      drawStack: polygons
    });
  };

  getCurrentEntity = (blockId, patchId) => {
    const block = this.getBlockById(blockId);
    if (block) {
      if (patchId) {
        return (block.patches || []).find(patch => patch.id === patchId);
      }
      return block;
    }
    return null;
  };

  getBlockById = blockId => {
    return this.props.newFarm.blocks.find(block => block.id === blockId);
  };

  handlePatchMode = mode => {
    const { actions, newFarm } = this.props;
    const currentBlock = this.getBlockById(newFarm.currentBlock);
    const patchesCount = _.size(currentBlock.patches);
    if (!currentBlock) {
      return false;
    }
    if (!["NO_PATCHES", "PATCHES"].includes(currentBlock.patchMode)) {
      this.setMode(MODE_EDIT);
    }

    if (mode === "NO_PATCHES") {
      actions.setCurrentPatch(null);
      if (patchesCount === 1) {
        this.mergePatchToBlock();
      }
      if (patchesCount > 1) {
        return false;
      }
    } else {
      if (patchesCount === 0) {
        this.mergeBlockToPatch();
      }
    }

    return true;
  };

  addNewPatch = () => {
    const { actions, newFarm } = this.props;
    const patchId = ObjectId().toHexString();
    const newPatch = new Area({
      id: patchId,
      polygons: [],
      type: "patch",
      name: "",
      size: 0,
      attributes: {}
    });

    actions.addPatchesToBlock({
      blockId: newFarm.currentBlock,
      patches: [newPatch]
    });
    actions.setCurrentPatch(patchId);
    this.setMode(MODE_EDIT);
  };

  handleAddAnotherBlock = async (saveAndList = false) => {
    this.setMode(MODE_VIEW);
    if (saveAndList) {
      this.setStep(3);
    } else {
      const blockId = ObjectId().toHexString();
      this.props.actions.initBlock(
        new Area({
          id: blockId,
          name: "",
          type: "block",
          size: 0,
          polygons: [],
          patchMode: "BLOCKS_AND_PATCHES",
          patches: [],
          attributes: {
            isRotating: false,
            varieties: [new AreaVariety()]
          }
        })
      );
      await this.props.actions.setCurrentBlock(blockId);
    }
  };

  handleCancel = async event => {
    const { newFarm } = this.props;
    // EDGE is ignoring button type for some reason and doing form submit on button click.
    // So, it's going to reload page. To avoid it,prevent default action from button clicks added.
    if (event) {
      event.preventDefault();
    }

    const savedBlocks = newFarm.blocks.filter(block => block._id);

    for (const block of savedBlocks) {
      await asyncForEach(
        block.patches.filter(patch => !patch._id),
        async patch => {
          await this.handleArchivePatch(block.id, patch.id);
        }
      );
    }

    await asyncForEach(
      newFarm.blocks.filter(block => !block._id),
      async block => {
        await this.handleArchiveBlock(block.id);
      }
    );

    if (savedBlocks.length) {
      this.setStep(3);
    } else {
      this.setStep(1);
    }
  };

  handleEditBlock = blockId => {
    const { actions } = this.props;
    const block = this.getBlockById(blockId);
    const patchId = _.get(block, "patches.0.id", null);
    actions.setCurrentBlock(_.get(block, "id", null));
    actions.setCurrentPatch(patchId);
    this.setMode(MODE_VIEW);
    this.setStep(2);
  };

  removePolygons = polygonsToRemove => {
    const { polygonsToAdd, drawStack } = this.state;
    const newPolygons = polygonsToAdd.reduce((next, polygon) => {
      if (!polygonsToRemove.includes(polygon.id)) {
        return [...next, polygon];
      }

      polygon.setMap(null);
      return next;
    }, []);

    this.setState({
      polygonsToAdd: newPolygons,
      drawStack: _.without(drawStack, ...polygonsToRemove)
    });
  };

  handleUndoLastDraw = () => {
    const { actions } = this.props;
    const { drawPolygonWarning } = this.state;
    actions.clearPolygons([drawPolygonWarning.polygon.id]);
    this.removePolygons([drawPolygonWarning.polygon.id]);
    this.setState({ drawPolygonWarning: null });
  };

  goBack = () => {
    const { newFarm, actions } = this.props;
    const { currentBlock } = newFarm;
    if (!currentBlock) {
      const block = _.get(newFarm, "blocks.0");
      if (_.isEmpty(block)) {
        return this.setStep(1);
      } else {
        const patch = _.get(block, "patches.0");
        if (_.isEmpty(patch)) {
          actions.setFarmField({
            fieldName: "currentPatch",
            fieldValue: null
          });
        } else {
          actions.setFarmField({
            fieldName: "currentPatch",
            fieldValue: patch.id
          });
        }
        actions.setFarmField({
          fieldName: "currentBlock",
          fieldValue: block.id
        });
      }
    }
    this.setStep(2);
  };

  handleActivatePatch = (blockId, patchId) => {
    this.handleArchivePatch(blockId, patchId, false);
  };

  handleArchivePatch = (blockId, patchId, archive = true) => {
    const { actions } = this.props;
    const block = this.getBlockById(blockId);
    let patches;
    const patchToRemove = block.patches.find(patch => patch.id === patchId);
    this.setPolygonsVisibilityForFarm(patchToRemove.polygons, !archive);

    if (patchToRemove._id) {
      actions.archiveArea(patchToRemove._id, archive);
      patches = block.patches.map(patch => {
        if (patch.id === patchId) {
          return {
            ...patch,
            archived: archive
          };
        }

        return patch;
      });
    } else {
      patches = block.patches.filter(patch => patch.id !== patchId);
    }

    actions.setBlockFieldsMultiple({
      blockId: blockId,
      fields: [
        {
          fieldName: "patches",
          fieldValue: patches
        }
      ]
    });

    actions.setCurrentPatch(null);
  };

  handleActivateBlock = blockId => {
    return this.handleArchiveBlock(blockId, false);
  };

  handleArchiveBlock = async (blockId, archive = true) => {
    const { actions } = this.props;
    const currentBlock = this.getBlockById(blockId);

    if (!currentBlock) {
      return false;
    }

    currentBlock.patches.forEach(patch =>
      this.setPolygonsVisibilityForFarm(patch.polygons, !archive)
    );
    this.setPolygonsVisibilityForFarm(currentBlock.polygons, !archive);

    actions.setBlockFieldsMultiple({
      blockId: blockId,
      fields: [
        {
          fieldName: "archived",
          fieldValue: archive
        },
        {
          fieldName: "patches",
          fieldValue: currentBlock.patches.map(patch => {
            return {
              ...patch,
              archived: archive
            };
          })
        }
      ]
    });

    if (currentBlock.patches) {
      await asyncForEach(currentBlock.patches, async patch => {
        if (patch._id) {
          await actions.archiveArea(patch._id, archive);
        }
      });
    }

    if (currentBlock._id) {
      await actions.archiveArea(currentBlock._id, archive);
    } else {
      await actions.removeBlock(currentBlock.id);
    }

    if (!this.props.newFarm.error) {
      actions.setCurrentPatch(null);
      actions.setCurrentBlock(null);
      actions.archiveBlockById(blockId, archive);
    }
  };

  handleSwitchPatch = () => {
    this.handleMapCancel();
    this.setMode(MODE_VIEW);
  };

  mergeBlockToPatch = () => {
    const { actions } = this.props;
    const currentBlock = this.getBlockById(this.props.newFarm.currentBlock);
    actions.setBlockFieldsMultiple({
      blockId: currentBlock.id,
      fields: [
        {
          fieldName: "polygons",
          fieldValue: currentBlock.polygons
        }
      ]
    });
    actions.setCurrentPatch(null);
  };

  mergePatchToBlock = () => {
    const { actions } = this.props;
    const currentBlock = this.getBlockById(this.props.newFarm.currentBlock);
    const patch = _.get(currentBlock, "patches.0");

    const fields = _.reduce(
      patch,
      (next, value, key) => {
        return ["id", "type", "name", "_id"].includes(key)
          ? next
          : [...next, { fieldName: key, fieldValue: value }];
      },
      []
    );

    this.props.actions.setBlockFieldsMultiple({
      blockId: currentBlock.id,
      fields: [
        ...fields,
        {
          fieldName: "patches",
          fieldValue: []
        }
      ]
    });

    if (patch._id) {
      actions.archiveArea(patch._id);
    }
  };

  render() {
    const { route, newFarm, match } = this.props;
    const {
      showMap,
      currentLatLng,
      zoom,
      mapOptions,
      markers,
      polygonsToAdd,
      drawPolygonWarning
    } = this.state;
    const step = Number(_.get(match, "params.step", 1));
    const currentBlock = this.getBlockById(newFarm.currentBlock);
    const showButtons =
      step === 2 &&
      ["NO_PATCHES", "PATCHES"].includes(_.get(currentBlock, "patchMode"));
    const position =
      drawPolygonWarning &&
      this.getGEOJSONPolygon(drawPolygonWarning.polygon)[0];
    return (
      <div className={styles.headerOverlayHolder}>
        <div className={styles.headerOverlayHeader}>
          <HeaderOverlay closeLink={getParentPageHref(route)} route={route} />
        </div>
        <div className={styles.headerOverlayBody}>
          <Sidebar.Pushable>
            <Sidebar
              animation={"overlay"}
              direction="left"
              vertical="vertical"
              visible={true}
            >
              {step === 1 && (
                <StepOne
                  showPlaceInput={this.state.showBox}
                  changeLocation={this.changeLocation}
                  setStep={this.setStep}
                  refForMap={this.mapInstance}
                  currentLatLng={currentLatLng}
                />
              )}
              {step === 2 && (
                <StepTwo
                  polygonsToAdd={polygonsToAdd}
                  handleCancel={this.handleCancel}
                  handlePatchMode={this.handlePatchMode}
                  handleUndoLastDraw={this.handleUndoLastDraw}
                  handleAddAnotherBlock={this.handleAddAnotherBlock}
                  currentBlock={currentBlock}
                  toggleDrawing={this.toggleDrawing}
                  setStep={this.setStep}
                  mapMode={this.state.mapMode}
                  addNewPatch={this.addNewPatch}
                  handleActivatePatch={this.handleActivatePatch}
                  handleDeletePatch={this.handleArchivePatch}
                  handleSwitchPatch={this.handleSwitchPatch}
                />
              )}
              {step === 3 && (
                <StepThree
                  goBack={this.goBack}
                  handleAddAnotherBlock={this.handleAddAnotherBlock}
                  setStep={this.setStep}
                  handleActivateBlock={this.handleActivateBlock}
                  handleDeleteBlock={this.handleArchiveBlock}
                  handleEditBlock={this.handleEditBlock}
                />
              )}
            </Sidebar>

            <Sidebar.Pusher className={styles.columnMain}>
              <div className={styles.mapHolder}>
                <GoogleMaps
                  onLoadMap={this.onLoadMap}
                  zoom={zoom}
                  currentLatLng={currentLatLng}
                  mapOptions={mapOptions}
                  {...this.getMapDrawingOptions()}
                  setPolygonReady={this.setPolygonReady}
                  markers={markers}
                  showMap={showMap}
                  step={step}
                  withClustering
                >
                  {drawPolygonWarning && (
                    <InfoWindow
                      position={{ lat: position[0], lng: position[1] }}
                    >
                      <div className={styles.mapWarningMessage}>
                        <Header as="h4">
                          <Icon
                            name="warning sign"
                            className={styles.warningIcon}
                          />
                          {drawPolygonWarning.warning}
                        </Header>
                        <div className={styles.warningButtons}>
                          <Button onClick={this.handleUndoLastDraw}>
                            Draw again
                          </Button>
                          <Button
                            onClick={() =>
                              this.setState({ drawPolygonWarning: null })
                            }
                          >
                            Continue
                          </Button>
                        </div>
                      </div>
                    </InfoWindow>
                  )}
                </GoogleMaps>
                {showButtons && (
                  <MapButtons
                    onSave={this.handleMapSave}
                    onEdit={this.handleMapEdit}
                    onDelete={this.handleMapDelete}
                    onCancel={this.handleMapCancel}
                    drawStack={this.state.drawStack}
                    mapMode={this.state.mapMode}
                  />
                )}
              </div>
            </Sidebar.Pusher>
          </Sidebar.Pushable>
        </div>
      </div>
    );
  }
}

AddNewFarm.propTypes = {
  route: PropTypes.object.isRequired,
  newFarm: PropTypes.object,
  actions: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  setStep: PropTypes.func
};

AddNewFarm.defaultProps = {
  route: { name: "{%name%}" }
};

function mapStateToProps({ farms: { newFarm } }) {
  return {
    newFarm
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setFarmField,
        setPolygon,
        clearPolygons,
        initBlock,
        removeBlock,
        archiveBlockById,
        removePatchesFromBlock,
        addPatchesToBlock,
        setBlockField,
        setBlockFieldsMultiple,
        setCurrentBlock,
        setCurrentPatch,
        removeAllBlocks,
        archiveArea,
        setPatchField,
        setNewFarm
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNewFarm);
