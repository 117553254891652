//TODO: get rid of this
import { parseFilters } from "../components/Filters";

export const getParentPageHref = route =>
  route.href.substring(0, route.href.lastIndexOf("/"));

/**
 * @param {{}} finalFiltersOptions
 * @param {{}} location
 * @returns {{}}
 */
export const getFiltersFromLocations = (finalFiltersOptions, location) => {
  const temp = {};

  const filterEntries = parseFilters(finalFiltersOptions, location);
  const filtersKeys = Object.keys(Object.fromEntries(filterEntries));

  filtersKeys.forEach(key => (temp[key] = []));
  filterEntries.forEach(([name, value]) => temp[name].push(value));

  return temp;
};
