import React from "react";
import { MATTER_TYPE_LIQUID } from "../Farms";
import { Icon, Popup } from "semantic-ui-react";
import styles from "../../routes/Chemicals/Modal/InventoryModal.module.css";

export const actionType = {
  ADD_CHEMICAL_START: "CHEMICALS/ADD_CHEMICAL_START",
  ADD_CHEMICAL: "CHEMICALS/ADD_CHEMICAL",
  ADD_CHEMICAL_FAIL: "CHEMICALS/ADD_CHEMICAL_FAIL",

  UPDATE_CHEMICAL_START: "CHEMICALS/UPDATE_CHEMICAL_START",
  UPDATE_CHEMICAL: "CHEMICALS/UPDATE_CHEMICAL",
  UPDATE_CHEMICAL_FAIL: "CHEMICALS/UPDATE_CHEMICAL_FAIL",

  CHANGE_VALUE: "CHEMICALS/CHANGE_VALUE",

  CHANGE_CHEMICALS_PAGE: "CHEMICALS/CHANGE_CHEMICALS_PAGE",
  CHANGE_CHEMICALS_PAGE_SIZE: "CHEMICALS/CHANGE_CHEMICALS_PAGE_SIZE",

  FETCH_CHEMICALS_START: "CHEMICALS/FETCH_CHEMICALS_START",
  FETCH_CHEMICALS: "CHEMICALS/FETCH_CHEMICALS",
  FETCH_CHEMICALS_ERROR: "CHEMICALS/FETCH_CHEMICALS_ERROR",

  FETCH_CHEMICALS_LIST_START: "CHEMICALS/FETCH_CHEMICALS_LIST_START",
  FETCH_CHEMICALS_LIST: "CHEMICALS/FETCH_CHEMICALS_LIST",
  FETCH_CHEMICALS_LIST_FAIL: "CHEMICALS/FETCH_CHEMICALS_LIST_FAIL",

  DELETE_CHEMICAL_START: "CHEMICALS/DELETE_CHEMICAL_START",
  DELETE_CHEMICAL: "CHEMICALS/DELETE_CHEMICAL",
  DELETE_CHEMICAL_FAIL: "CHEMICALS/DELETE_CHEMICAL_FAIL",

  ARCHIVE_CHEMICALS_START: "CHEMICALS/ARCHIVE_CHEMICALS_START",
  ARCHIVE_CHEMICALS: "CHEMICALS/ARCHIVE_CHEMICALS",
  ARCHIVE_CHEMICALS_FAIL: "CHEMICALS/ARCHIVE_CHEMICALS_FAIL",

  UPLOAD_CHEMICAL_ATTACHMENT_START:
    "CHEMICALS/UPLOAD_CHEMICAL_ATTACHMENT_START",
  UPLOAD_CHEMICAL_ATTACHMENT: "CHEMICALS/UPLOAD_CHEMICAL_ATTACHMENT",
  UPLOAD_CHEMICAL_ATTACHMENT_ERROR:
    "CHEMICALS/UPLOAD_CHEMICAL_ATTACHMENT_ERROR",

  FETCH_CHEMICALS_FILTERS_START: "CHEMICALS/FETCH_CHEMICALS_FILTERS_START",
  FETCH_CHEMICALS_FILTERS_ERROR: "CHEMICALS/FETCH_CHEMICALS_FILTERS_ERROR",
  FETCH_CHEMICALS_FILTERS_SUCCESS: "CHEMICALS/FETCH_CHEMICALS_FILTERS_SUCCESS",

  FETCH_ACTIVE_INGREDIENTS_START: "CHEMICALS/FETCH_ACTIVE_INGREDIENTS_START",
  FETCH_ACTIVE_INGREDIENTS_ERROR: "CHEMICALS/FETCH_ACTIVE_INGREDIENTS_ERROR",
  FETCH_ACTIVE_INGREDIENTS_SUCCESS:
    "CHEMICALS/FETCH_ACTIVE_INGREDIENTS_SUCCESS",

  CHEMICALS_CLEAR_ERROR: "CHEMICALS/CLEAR_ERROR",

  FETCH_UNITS_START: "CHEMICALS/FETCH_UNITS_START",
  SET_UNITS: "CHEMICALS/SET_UNITS",
  SET_ACTIVE_ITEM_ID: "CHEMICALS/SET_ACTIVE_ITEM_ID",

  CHANGE_LAST_UPDATE_DATE: "CHEMICALS/CHANGE_LAST_UPDATE_DATE",

  PENDING_OPERATIONS_START: "INVENTORY/PENDING_OPERATIONS_START",
  PENDING_OPERATIONS: "INVENTORY/PENDING_OPERATIONS"
};

const MEASUREMENT_UNITS = {
  GRAM: "gram",
  KILOGRAM: "kilogram",
  LITER: "liter",
  MILLILITER: "milliliter"
};

export const PRODUCT_INITIAL_VALUES = {
  price: 0,
  batchRequired: true,
  matter: MATTER_TYPE_LIQUID,
  unit: MEASUREMENT_UNITS.LITER,
  dangerousProduct: true,
  organic: false,
  rates: []
};

export const YES_NO_OPTIONS = [
  { key: 1, label: "Yes", value: true },
  { key: 2, label: "No", value: false }
];

export const TRACK_INVENTORY_OPTIONS = [
  {
    key: 1,
    label: (
      <>
        Yes{" "}
        <Popup
          position="right center"
          className={styles.popup}
          trigger={<Icon className={styles.yesButtonIcon} name="info circle" />}
          content={
            <div className={styles.trackInventoryButtonYes}>
              Selecting "Yes" will allow you to track the inventory for this
              product through the addition of products in purchase orders or in
              your sheds
            </div>
          }
          size="mini"
          inverted
        />
      </>
    ),
    value: true
  },
  {
    key: 2,
    label: (
      <>
        No{" "}
        <Popup
          position="right center"
          className={styles.popup}
          trigger={<Icon className={styles.noButtonIcon} name="info circle" />}
          content={
            <div className={styles.trackInventoryButtonNo}>
              The inventory for this product will not be tracked, please add a
              rate below to capture this product's application costs.
            </div>
          }
          size="mini"
          inverted
        />
      </>
    ),
    value: false
  }
];

export const INVENTORY_MODULE = "inventory";
