import React, { memo } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableHeader as Header,
  TableHeaderCell,
  TableRow
} from "semantic-ui-react";
import Numeric from "../../../Numeric";

const ChemicalsTable = ({
  sprayTaskChemicalCosts: { totalAreasSize, chemicals }
}) => {
  return (
    <Table unstackable>
      <Header>
        <TableRow>
          <TableHeaderCell>Chemical name</TableHeaderCell>
          <TableHeaderCell>Area treated (Ha)</TableHeaderCell>
          <TableHeaderCell>Quantity applied</TableHeaderCell>
          <TableHeaderCell>Cost ($)/Ha</TableHeaderCell>
          <TableHeaderCell>Total cost</TableHeaderCell>
        </TableRow>
      </Header>
      <TableBody>
        {chemicals.map(
          (
            {
              chemicalName,
              quantityPerHectare,
              chemicalMatter,
              chemicalCostsPerHectare,
              totalCost
            },
            i
          ) => (
            <TableRow key={`expanded_table_${i}`}>
              <TableCell>{chemicalName}</TableCell>
              <TableCell>
                <Numeric
                  fractionDigits={2}
                  value={totalAreasSize}
                  units=" Ha"
                />
              </TableCell>
              <TableCell>
                <Numeric
                  fractionDigits={2}
                  value={quantityPerHectare}
                  units={chemicalMatter === "LIQUID" ? "L" : "Kg"}
                />
              </TableCell>
              <TableCell>
                <Numeric
                  fractionDigits={2}
                  value={chemicalCostsPerHectare}
                  units="$ "
                  unitsPosition="left"
                />
                /Ha
              </TableCell>
              <TableCell>
                <Numeric
                  fractionDigits={2}
                  value={totalAreasSize * chemicalCostsPerHectare}
                  units="$ "
                  unitsPosition="left"
                />
              </TableCell>
            </TableRow>
          )
        )}
      </TableBody>
    </Table>
  );
};

ChemicalsTable.propTypes = {
  sprayTaskChemicalCosts: PropTypes.object
};

export default memo(ChemicalsTable);
