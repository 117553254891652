import React, { PureComponent, useEffect, useMemo, useState } from "react";
import { Grid, Header, Table, Button } from "semantic-ui-react";
import DatePicker from "../DatePicker";
import SegmentClosable from "../SegmentClosable";
import { useDispatch, useSelector } from "react-redux";
import { fetchBinsPerAreaEmployee } from "../../actions/Dashboard";
import styles from "./PickedBinsGroupsReport.module.css";
import _ from "lodash";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip
} from "recharts";
import Loader from "../Loader";
import moment from "moment";
import ExportToExcel from "../ExportToExcel";
import PickedBinsGroupsRow from "./PickedBinsGroupsRow";

const columnsConfig = [
  {
    id: "date",
    accessor: "date",
    Header: "Date"
  },
  {
    id: "id",
    accessor: "id",
    Header: "Group ID"
  },
  {
    id: "name",
    accessor: "name",
    Header: "Group Name"
  },
  {
    id: "location",
    accessor: "location",
    Header: "Location"
  },
  {
    id: "bins",
    accessor: "bins",
    Header: "Bins Picked"
  },
  {
    id: "binsPerWorker",
    accessor: "binsPerWorker",
    Header: "Bins Per Worker"
  }
];

const renderCustomBarLabel = ({ x, y, width, value }) => {
  return (
    <text
      x={x + width / 2}
      y={y}
      fill="#666"
      textAnchor="middle"
      dy={-6}
    >{`Bins: ${value}`}</text>
  );
};

const PickedBinsGroupsReport = () => {
  const dispatch = useDispatch();
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const { binsPerAreaPerEmployee, isReportInProgress } = useSelector(
    state => state.dashboard
  );
  const {
    list: { content: employeeList }
  } = useSelector(state => state.employee);
  const {
    list: { content: areasList }
  } = useSelector(state => state.areas);

  const pickedBinsDataGraph = Object.values(binsPerAreaPerEmployee).map(
    group => {
      return {
        name:
          group.groupName === "Workers without group"
            ? "Workers without group"
            : `${group.groupName.substring(0, 4)}...`,
        bins: _.round(group.bins, 2),
        id: group.groupId,
        location: _.uniq(group.location).toString(),
        employees: group.employees,
        binsPerWorker: _.round(
          group.bins / Object.values(group.employees).length,
          2
        )
      };
    }
  );

  const getExcelReport = (binsPerAreaPerEmployee, date) =>
    binsPerAreaPerEmployee
      .map(groupReport => {
        return {
          date: moment(date).format("DD-MM-YYYY"),
          id: groupReport.id,
          name: groupReport.name,
          location: groupReport.location,
          bins: _.round(groupReport.bins, 2),
          binsPerWorker: _.round(groupReport.binsPerWorker, 2)
        };
      })
      .flat();

  const excelReport = useMemo(
    () => getExcelReport(Object.values(pickedBinsDataGraph), date),
    [pickedBinsDataGraph, date]
  );

  const pickedBinsData = Object.values(binsPerAreaPerEmployee).map(group => {
    return {
      name: group.groupName,
      bins: parseFloat(group.bins),
      id: group.groupId,
      location: _.uniq(group.location).toString(),
      employees: group.employees
    };
  });

  const CustomTooltip = ({ payload }) => {
    if (payload && payload.length) {
      const data = payload[0].payload;
      const numOfWorkers = Object.values(data.employees);
      return (
        <div className={styles.customTooltip}>
          <div>{`Group: ${
            binsPerAreaPerEmployee[data.id] === undefined
              ? "Workers without group"
              : binsPerAreaPerEmployee[data.id].groupName
          }`}</div>
          <div>{`Bins: ${data.bins}`}</div>
          <div>{`No. of workers: ${numOfWorkers.length}`}</div>
          <div>{`No. of bins per worker: ${_.round(
            data.bins / numOfWorkers.length,
            2
          )}`}</div>
          <div>{`Locations: ${data.location}`}</div>
        </div>
      );
    }
    return null;
  };

  const refresh = () => dispatch(fetchBinsPerAreaEmployee(date));

  useEffect(() => {
    refresh();
  }, [date, employeeList, areasList]);

  const [graphView, setGraphView] = useState(true);

  return (
    <SegmentClosable
      title={`Daily Bins Picked per Group`}
      headerLineClassName={styles.segmentHeader}
    >
      <Grid>
        <Grid.Row width={14}>
          <Grid.Column width={6}>
            <DatePicker
              value={date}
              onChange={date => setDate(date)}
              disableClear
            />
          </Grid.Column>
          <Grid.Column width={1} style={{ paddingTop: 13 }}>
            <ExportToExcel columns={columnsConfig} rawData={[...excelReport]} />
          </Grid.Column>
          {/* {pickedBinsData && Object.keys(pickedBinsData) && (
            <Grid.Column width={5} style={{ paddingTop: 4 }}>
              <Button.Group>
                <Button onClick={() => setGraphView(true)} positive={graphView}>
                  No. of bins
                </Button>
                <Button
                  onClick={() => setGraphView(false)}
                  positive={!graphView}
                >
                  No. of bins per worker
                </Button>
              </Button.Group>
            </Grid.Column>
          )} */}
          <Grid.Column width={2}>
            <div className={styles.loader}>
              {isReportInProgress ? <Loader /> : ""}
            </div>
          </Grid.Column>
        </Grid.Row>
        {pickedBinsData && !Object.keys(pickedBinsData).length ? (
          <Grid.Column width={6}>
            <Header as={"h3"} style={{ textAlign: "center" }}>
              No picked bins for selected date range
            </Header>
          </Grid.Column>
        ) : (
          <>
            <Grid.Row style={{ width: "820px", height: "450px" }}>
              <ResponsiveContainer width="89.7%" height="100%">
                <BarChart
                  margin={{ top: 25, right: 15, bottom: 41, left: 0 }}
                  width={820}
                  maxBarSize={40}
                  height={450}
                  data={
                    pickedBinsDataGraph
                    // .sort((a, b) =>
                    // a.name > b.name ? 1 : -1)
                  }
                >
                  <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                  <XAxis interval={1} dataKey="name" />
                  <YAxis />
                  <Legend
                    verticalAlign="top"
                    wrapperStyle={{ lineHeight: "40px" }}
                  />
                  <Tooltip content={<CustomTooltip />} />
                  <Bar
                    dataKey={graphView ? "bins" : "binsPerWorker"}
                    fill="#2cb673"
                  />
                </BarChart>
              </ResponsiveContainer>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column mobile={14} tablet={14} computer={14}>
                <Table style={{ marginLeft: "1.5rem" }} striped celled>
                  <Table.Header>
                    <Table.HeaderCell> </Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">ID</Table.HeaderCell>
                    <Table.HeaderCell>Group</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">
                      No. of workers
                    </Table.HeaderCell>
                    <Table.HeaderCell>Location</Table.HeaderCell>
                    <Table.HeaderCell>No. of Bins</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">
                      No. of Bins per worker
                    </Table.HeaderCell>
                  </Table.Header>
                  <Table.Body>
                    {pickedBinsData.map((group, i) => (
                      <PickedBinsGroupsRow
                        groupStat={group}
                        key={`group-stat-${i}`}
                      />
                    ))}
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
          </>
        )}
      </Grid>
    </SegmentClosable>
  );
};

export default PickedBinsGroupsReport;
