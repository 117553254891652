import { axiosProxy } from "utils/axiosProxy";
import { actionTypes } from "constants/Reports/sheds";
import { omitBy, isNil } from "lodash";
import uuid from "uuid/v4";

let uniqId;

const url = "/reporting_module/inventory";

export const fetchProductUsageData = ({
  page,
  size,
  search,
  unpaged = false,
  filters
}) => async (dispatch, getState) => {
  const { areas } = getState();

  const filtersList = Object.fromEntries(filters);
  try {
    const blockIds = areas.list.content.filter(item =>
      filtersList?.blockIds?.split(",", 999999)?.some(id => item.id == id)
    );
    const patchIds = areas.list.content.filter(item =>
      filtersList?.patchIds?.split(",", 999999)?.some(id => item.id == id)
    );
    const filtredBloCk = blockIds.filter(block =>
      patchIds.every(patch => patch.parent?.id != block.id)
    );
    const areaIds = [...patchIds, ...filtredBloCk].map(item => item.id);

    dispatch({ type: actionTypes.PRODUCT_USAGE_TABLE_DATA_START });
    const paramsObject = omitBy(
      {
        search,
        page,
        unpaged,
        size
      },
      isNil
    );
    const params = new URLSearchParams(paramsObject);

    if (Array.isArray(filters)) {
      filters.forEach(([filter, value]) => {
        const from = value.slice(0, 10);
        const to = value.slice(-10);
        return filter !== "seasons"
          ? filter == "blockIds" || filter == "patchIds"
            ? !params.has("areaIds") && params.append("areaIds", areaIds)
            : params.append(filter, value)
          : (params.append("from", `${from}T00:00:00`),
            params.append("to", `${to}T23:59:59`));
      });
    }
    uniqId = uuid();
    const res = await axiosProxy({
      method: "GET",
      params,
      uniqId,
      url: `${url}/productUsage`
    });
    if (uniqId === res.config.headers["X-REQUEST-ID"]) {
      dispatch({
        type: actionTypes.PRODUCT_USAGE_TABLE_DATA,
        payload: res.data
      });
    }
  } catch (error) {
    dispatch({
      type: actionTypes.PRODUCT_USAGE_TABLE_DATA_FAILED,
      error
    });
  }
};

export const fetchProductUsageDataList = ({
  unpaged = true,
  filters
}) => async (dispatch, getState) => {
  const { areas } = getState();
  const filtersList = filters && Object.fromEntries(filters);
  try {
    const blockIds = areas.list.content.filter(item =>
      filtersList?.blockIds?.split(",", 999999)?.some(id => item.id == id)
    );
    const patchIds = areas.list.content.filter(item =>
      filtersList?.patchIds?.split(",", 999999)?.some(id => item.id == id)
    );
    const filtredBloCk = blockIds.filter(block =>
      patchIds.every(patch => patch.parent?.id != block.id)
    );
    const areaIds = [...patchIds, ...filtredBloCk].map(item => item.id);

    dispatch({
      type: actionTypes.PRODUCT_USAGE_TABLE_DATA_LIST_START
    });
    const paramsObject = omitBy(
      {
        unpaged: true
      },
      isNil
    );
    const params = new URLSearchParams(paramsObject);
    if (Array.isArray(filters)) {
      filters.forEach(([filter, value]) => {
        const from = value.slice(0, 10);
        const to = value.slice(-10);
        return filter !== "seasons"
          ? filter == "blockIds" || filter == "patchIds"
            ? !params.has("areaIds") && params.append("areaIds", areaIds)
            : params.append(filter, value)
          : (params.append("from", `${from}T00:00:00`),
            params.append("to", `${to}T23:59:59`));
      });
    }
    const res = await axiosProxy({
      url: `${url}/productUsage`,
      params,
      method: "GET"
    });
    dispatch({
      type: actionTypes.PRODUCT_USAGE_TABLE_DATA_LIST,
      payload: res.data
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: actionTypes.PRODUCT_USAGE_TABLE_DATA_LIST_FAILED,
      payload: error
    });
  }
};
export const setActiveItemId = id => dispatch => {
  dispatch({ type: actionTypes.SET_ACTIVE_ITEM_ID, payload: id });
};
