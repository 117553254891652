import { actionType } from "../../constants/Filters";
import { axiosProxy } from "../../utils/axiosProxy";

export const resetAllFilters = () => async dispatch => {
  await dispatch({
    type: actionType.RESET_FILTERS_START
  });
  dispatch({
    type: actionType.RESET_FILTERS
  });
};
