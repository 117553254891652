import React from "react";
import { Form, Input } from "formsy-semantic-ui-react";
import { Grid } from "semantic-ui-react";
import PropTypes from "prop-types";
import { validationErrors } from "../../../utils/validationRules";
import HorizontalSelect from "../../../components/HorizontalSelect";
import ErrorLabel from "../../../components/ErrorLabel";
import { genders } from "./constants";
import styles from "./AddNewClient.module.css";
import TimezoneSelect from "./TimezoneSelect";

const ClientDetails = ({
  id,
  timezone,
  firstName,
  lastName,
  gender,
  changeValue,
  email,
  columnMiddle
}) => {
  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column {...columnMiddle}>
            <Form.Field required>
              <label htmlFor="id">Client Name</label>
              <Input
                value={id}
                autoFocus
                required
                validations={{ smallLettersOnly: true, maxLength: 15 }}
                validationErrors={validationErrors({
                  isDefaultRequiredValue: "Client Name",
                  maxLength: "15"
                })}
                errorLabel={ErrorLabel}
                id="id"
                name="id"
                onChange={changeValue}
              />
            </Form.Field>
          </Grid.Column>
          {/*
              <Grid.Column {...columnMiddleRight}>
              <Form.Field required disabled>
                <label>User Name</label>
                <Input value={userName} name="userName" />
              </Form.Field>
            </Grid.Column>
              */}
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <div className={styles.warning}>
              Don’t change the following details unless you really know what you
              are doing!
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column {...columnMiddle}>
            <Form.Field required>
              <label htmlFor="timezone">
                Timezone{" "}
                <span className={styles.warning}>
                  (Prefer selecting timezone with city name)
                </span>
              </label>
              <TimezoneSelect timezone={timezone} changeValue={changeValue} />
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column {...columnMiddle}>
            <Form.Field required>
              <label htmlFor="email">Email</label>
              <Input
                placeholder="joe@tieupfarming.com"
                value={email}
                required
                validations={"isEmail"}
                validationErrors={validationErrors({
                  isDefaultRequiredValue: "Email"
                })}
                errorLabel={ErrorLabel}
                id="email"
                name="email"
                onChange={changeValue}
              />
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column {...columnMiddle}>
            <Form.Group widths="equal">
              <Form.Field required>
                <label>Gender</label>
                <HorizontalSelect
                  options={genders}
                  selected={gender}
                  onSelect={option =>
                    changeValue({
                      target: { name: "gender", value: option.value }
                    })
                  }
                />
                <Input
                  name="gender"
                  value={gender}
                  type="hidden"
                  required
                  className={styles.inputHidden}
                  validationErrors={validationErrors({
                    isDefaultRequiredValue: "Gender"
                  })}
                  errorLabel={ErrorLabel}
                />
              </Form.Field>
            </Form.Group>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column {...columnMiddle}>
            <Form.Field required>
              <label htmlFor="firstName">First Name</label>
              <Input
                value={firstName}
                required
                validationErrors={validationErrors({
                  isDefaultRequiredValue: "First Name"
                })}
                errorLabel={ErrorLabel}
                id="firstName"
                name="firstName"
                onChange={changeValue}
              />
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column {...columnMiddle}>
            <Form.Field required>
              <label htmlFor="lastName">Last Name</label>
              <Input
                required
                value={lastName}
                validationErrors={validationErrors({
                  isDefaultRequiredValue: "Last Name"
                })}
                errorLabel={ErrorLabel}
                id="lastName"
                name="lastName"
                onChange={changeValue}
              />
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

ClientDetails.propTypes = {
  id: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  gender: PropTypes.string,
  changeValue: PropTypes.func.isRequired,
  columnMiddle: PropTypes.object,
  columnMiddleRight: PropTypes.object,
  timezone: PropTypes.string
};

ClientDetails.defaultProps = {
  changeValue: () => {},
  firstName: "",
  lastName: "",
  email: "",
  gender: null,
  columnMiddle: {},
  columnMiddleRight: {}
};

export default ClientDetails;
