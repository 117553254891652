import React, { useEffect, useCallback, useState } from "react";
import ReactDOM from "react-dom";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
  Dropdown,
  Input,
  Header,
  Menu,
  TextArea,
  Icon,
  Button,
  Container
} from "semantic-ui-react";
import moment from "moment";
import DatePicker from "../DatePicker";
import styles from "./ToggleField.module.css";
import { formatInputFloat } from "../../utils/validationRules";
import { isEmptyValue, valueToString } from "../../utils/tasksUtils";
import { Formik } from "formik";
import BatchNumberField from "routes/SprayDiary/TaskDetailsMobile/BatchNumberField";
import BatchNumberInputField from "routes/SprayDiary/TaskDetailsMobile/BatchNumberInput";
import BatchNumberDropdownAdd from "routes/SprayDiary/TaskDetailsMobile/Chemicals/ChemicalItem/BatchNumberAddForm/BatchNumberDropdownAdd";
import BatchNumberInputAdd from "routes/SprayDiary/TaskDetailsMobile/Chemicals/ChemicalItem/BatchNumberAddForm/BatchNumberInputAdd";
import SideBarBatchNumberInput from "routes/SprayDiary/TaskDetailsMobile/SideBarBatchNumberInput";
import BatchNumberFieldInSideBar from "routes/SprayDiary/TaskDetailsMobile/SideBarBatchNumber";
import { getBatchNumbersLastYear } from "actions/Spray/tasks";

const FieldHolder = props => {
  const { heavy, label, children } = props;
  return (
    <div className={`${heavy ? styles.heavyHolder : ""}`}>
      {heavy && <Header as="h4">{label}</Header>}
      {children}
    </div>
  );
};

FieldHolder.propTypes = {
  heavy: PropTypes.bool,
  label: PropTypes.string,
  children: PropTypes.node
};

const validationInput = (value, rules) => {
  let valueForUpdate = value;
  if (rules) {
    Object.keys(rules).forEach(rule => {
      switch (rule) {
        case "numeric":
          valueForUpdate = formatInputFloat(value);
          break;
        case "array":
          valueForUpdate = !!value?.length ? value : [];
          break;
        case "valueMax":
          valueForUpdate =
            !isNaN(valueForUpdate) &&
            Number(valueForUpdate) >= 0 &&
            valueForUpdate !== "" &&
            valueForUpdate > rules[rule]
              ? rules[rule]
              : valueForUpdate;
          break;
        default:
          break;
      }
    });
  }
  return valueForUpdate;
};

const ToggleField = ({
  name,
  value,
  chemicalsList,
  showAddNewChemical,
  batchNumbers,
  spray,
  sprayTask,
  shedId,
  list,
  fieldToEdit,
  toggleEditField,
  showValue,
  inputType,
  options,
  onChange,
  onBatchNumberChange,
  onFiledChange,
  minRate,
  label,
  heavy,
  emptyLabel,
  hardLabel,
  inputLabel,
  suffix,
  component,
  valueToSet,
  unit,
  newBatchNumberShow,
  editAllMode,
  rules,
  disabled,
  multiplierValue,
  item,
  index,
  ...rest
}) => {
  let textInput = React.createRef();
  const [inputRate, setInputRate] = useState();

  const [addFieldShow, setAddFieldShow] = useState(false);
  const dispatch = useDispatch();
  const PRODUCT_INITIAL_VALUES = {
    batchNumbers: "",
    id: null
  };
  const ADD_PRODUCT_INITIAL_VALUES = {
    batchNumbers: [],
    id: null
  };
  const editMode =
    (fieldToEdit &&
      fieldToEdit.name === name &&
      (!suffix || (suffix && fieldToEdit.suffix === suffix))) ||
    editAllMode;

  useEffect(
    () => {
      if (heavy && !editAllMode) {
        // eslint-disable-next-line react/no-find-dom-node
        const targetInput = textInput.current
          ? ReactDOM.findDOMNode(textInput.current).querySelector("input")
          : null;

        if (targetInput) {
          targetInput.focus();
        }
      }
    },
    [editMode] // eslint-disable-line
  );

  const toggleField = useCallback(() => {
    if (disabled) {
      return false;
    }
    toggleEditField({
      name,
      value: valueToSet || value,
      suffix,
      heavy,
      rules,
      component
    });
  }, [
    toggleEditField,
    name,
    valueToSet,
    value,
    suffix,
    heavy,
    rules,
    component,
    disabled
  ]);

  const valueToRender =
    fieldToEdit &&
    fieldToEdit.name === name &&
    (!suffix || (suffix && fieldToEdit.suffix === suffix)) &&
    fieldToEdit.valueForUpdate !== undefined
      ? fieldToEdit.valueForUpdate
      : value;

  let valueToShow = Array.isArray(valueToRender) ? (
    <ul className={styles.listView}>
      {valueToRender.map((item, index) => (
        <li key={`${item}_${index}`}>{item}</li>
      ))}
    </ul>
  ) : valueToRender && typeof valueToRender === "object" ? (
    valueToRender.text
  ) : (
    valueToString(valueToRender) ||
    emptyLabel ||
    (inputType == "addForm" && (
      <Container fluid className={styles.textButtonContainer}>
        <Button
          onClick={() => setAddFieldShow(true)}
          type="button"
          className={styles.textButton}
        >
          <Icon name="plus" />
          Add batch number
        </Button>
      </Container>
    ))
  );

  if (valueToRender) {
    switch (inputType) {
      case "datePicker":
        valueToShow = moment(valueToRender).format("DD/MM/YYYY");
        break;
      case "radioMenu":
        const targetMenu = options.find(
          option => option.value === valueToRender
        );
        valueToShow = targetMenu ? targetMenu.text : "";
        break;
      case "dropdown":
        const targetOption = options.filter(option =>
          Array.isArray(valueToRender)
            ? valueToRender.includes(option.value)
            : option.value === valueToRender
        );
        valueToShow = targetOption
          ? targetOption.reduce((value, item) => {
              return value ? `${value}, ${item.text}` : item.text;
            }, "")
          : valueToRender;
        break;
      default:
        break;
    }
  }

  if (editMode) {
    switch (inputType) {
      case "component":
        return (
          <FieldHolder heavy={heavy} label={label}>
            {component}
          </FieldHolder>
        );
      case "textArea":
        return (
          <FieldHolder heavy={heavy} label={label}>
            <TextArea
              value={valueToRender}
              onChange={event =>
                onChange(
                  validationInput(event.target.value, rules),
                  name,
                  suffix
                )
              }
              ref={textInput}
              fluid
              className={styles.textArea}
              error={fieldToEdit && fieldToEdit.error}
              disabled={disabled}
              {...rest}
            />
          </FieldHolder>
        );

      case "datePicker":
        return (
          <FieldHolder heavy={heavy} label={label}>
            <DatePicker
              value={valueToRender}
              labelClass={styles.dateLabel}
              onChange={value =>
                onChange(validationInput(value, rules), name, suffix)
              }
              position={"right"}
              disableClear
              ref={textInput}
              error={fieldToEdit && fieldToEdit.error}
              disabled={disabled}
              {...rest}
            />
          </FieldHolder>
        );

      case "dropdown":
        return (
          <FieldHolder heavy={heavy} label={label}>
            <Dropdown
              closeOnChange
              selection
              fluid
              value={valueToRender}
              options={options}
              onChange={(_, data) => {
                return (
                  onChange(validationInput(data.value, rules), name, suffix),
                  dispatch(
                    getBatchNumbersLastYear(
                      chemicalsList.content.filter(
                        item => item.id == data.value
                      )
                    )
                  )
                );
              }}
              ref={textInput}
              error={fieldToEdit && fieldToEdit.error}
              disabled={disabled}
              {...rest}
            />
          </FieldHolder>
        );
      case "radioMenu":
        return (
          <Menu
            compact
            error={fieldToEdit && fieldToEdit.error}
            disabled={disabled}
            {...rest}
          >
            {options.map(option => (
              <Menu.Item
                key={option.key}
                active={valueToRender === option.value}
                onClick={() =>
                  onChange(validationInput(option.value, rules), name, suffix)
                }
              >
                {option.icon && (
                  <Icon
                    name={option.icon.name}
                    style={option.icon.style || null}
                  />
                )}
                {option.text}
              </Menu.Item>
            ))}
          </Menu>
        );
      case "rateInput":
        return (
          <FieldHolder heavy={heavy} label={label}>
            <Input
              value={
                inputRate ||
                fieldToEdit?.valueForUpdate * multiplierValue ||
                valueToRender ||
                undefined
              }
              ref={textInput}
              onChange={event => {
                return (
                  setInputRate(event.target.value),
                  onChange(
                    validationInput(
                      event.target.value / multiplierValue,
                      rules
                    ),
                    name,
                    suffix
                  )
                );
              }}
              label={inputLabel}
              error={fieldToEdit && fieldToEdit.error}
              disabled={disabled}
              {...rest}
            />
          </FieldHolder>
        );
      case "batchNumbersInSideBar":
        const activeTasks = spray.tasks.data.content
          .find(({ id }) => id == sprayTask?.id)
          ?.chemicals.map(i => ({
            batchNumbers: i.batchNumbers,
            id: i.chemical?.id
          }))
          .find(task => task?.id == item?.id);

        const activeBatch = batchNumbers.data.find(
          batch => batch?.id === item?.id
        );
        return (
          <Formik
            enableReinitialize
            initialValues={activeTasks || PRODUCT_INITIAL_VALUES}
          >
            {props =>
              activeBatch?.data.length > 0 ? (
                <BatchNumberFieldInSideBar
                  item={item}
                  index={index}
                  activeBatchNumber={activeBatch}
                  spray={spray}
                  value={value}
                  sprayTask={sprayTask}
                  validationInput={validationInput}
                  onChange={onBatchNumberChange}
                  addFieldShow={addFieldShow}
                  setAddFieldShow={setAddFieldShow}
                  name={name}
                  suffix={suffix}
                  props={props}
                  rules={rules}
                />
              ) : (
                <SideBarBatchNumberInput
                  item={item}
                  index={index}
                  spray={spray}
                  value={value}
                  sprayTask={sprayTask}
                  validationInput={validationInput}
                  onChange={onBatchNumberChange}
                  addFieldShow={addFieldShow}
                  setAddFieldShow={setAddFieldShow}
                  name={name}
                  suffix={suffix}
                  props={props}
                  rules={rules}
                />
              )
            }
          </Formik>
        );
      case "addForm":
        const activeTasksInAddForm = spray.tasks.data.content
          .find(({ id }) => id == sprayTask?.id)
          ?.chemicals.map(i => ({
            batchNumbers: i.batchNumbers,
            id: i.chemical?.id
          }))
          .find(task => task?.id == item?.id);

        const activeBatchInAddForm = batchNumbers.data.find(
          batch => batch?.id === item?.id
        );
        return (
          <Formik
            enableReinitialize
            initialValues={activeTasksInAddForm || ADD_PRODUCT_INITIAL_VALUES}
          >
            {props =>
              activeBatchInAddForm?.data.length > 0 ? (
                <BatchNumberDropdownAdd
                  item={item}
                  index={index}
                  activeBatchNumber={activeBatchInAddForm}
                  spray={spray}
                  value={value}
                  sprayTask={sprayTask}
                  validationInput={validationInput}
                  onChange={onBatchNumberChange}
                  addFieldShow={addFieldShow}
                  setAddFieldShow={setAddFieldShow}
                  name={name}
                  suffix={suffix}
                  props={props}
                  rules={rules}
                />
              ) : (
                <BatchNumberInputAdd
                  item={item}
                  index={index}
                  spray={spray}
                  value={value}
                  sprayTask={sprayTask}
                  validationInput={validationInput}
                  onChange={onBatchNumberChange}
                  addFieldShow={addFieldShow}
                  setAddFieldShow={setAddFieldShow}
                  name={name}
                  suffix={suffix}
                  props={props}
                  rules={rules}
                />
              )
            }
          </Formik>
        );

      case "batchNumber":
        const activeTask = spray.tasks.data.content
          .find(({ id }) => id == sprayTask.id)
          ?.chemicals.map(i => ({
            batchNumbers: i.batchNumbers,
            id: i.chemical.id
          }))
          .find(task => task.id == item.id);

        const activeBatchNumber = batchNumbers.data.find(
          batch => batch.id === item.id
        );

        return (
          <Formik
            enableReinitialize
            initialValues={activeTask || PRODUCT_INITIAL_VALUES}
          >
            {props =>
              activeBatchNumber?.data.length > 0 ? (
                <BatchNumberField
                  item={item}
                  activeBatchNumber={activeBatchNumber}
                  spray={spray}
                  index={index}
                  sprayTask={sprayTask}
                  validationInput={validationInput}
                  onChange={onBatchNumberChange}
                  name={name}
                  suffix={suffix}
                  props={props}
                  rules={rules}
                />
              ) : (
                <BatchNumberInputField
                  item={item}
                  spray={spray}
                  index={index}
                  sprayTask={sprayTask}
                  validationInput={validationInput}
                  onChange={onBatchNumberChange}
                  name={name}
                  suffix={suffix}
                  props={props}
                  rules={rules}
                />
              )
            }
          </Formik>
        );

      default:
        return (
          <FieldHolder heavy={heavy} label={label}>
            <Input
              value={valueToRender || ""}
              ref={textInput}
              onChange={event => {
                return onChange(
                  validationInput(event.target.value, rules),
                  name,
                  suffix
                );
              }}
              label={inputLabel}
              error={fieldToEdit && fieldToEdit.error}
              disabled={disabled}
              {...rest}
            />
          </FieldHolder>
        );
    }
  }

  return (
    <div
      className={`${styles.valueToShow} ${disabled ? styles.disabled : ""}`}
      onClick={toggleField}
    >
      {isEmptyValue(hardLabel || valueToShow || emptyLabel)}{" "}
      {inputLabel && valueToShow ? inputLabel.content : ""}
    </div>
  );
};

const mapStateToProps = state => {
  const {
    sprayDiary: { chemicalsList },
    spray,
    shed: { list }
  } = state;

  return {
    batchNumbers: state.spray.batchNumbers,
    spray,
    chemicalsList,
    list
  };
};

ToggleField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  hardLabel: PropTypes.string,
  value: PropTypes.any,
  fieldToEdit: PropTypes.object,
  toggleEditField: PropTypes.func,
  inputType: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  heavy: PropTypes.bool,
  editAllMode: PropTypes.bool,
  emptyLabel: PropTypes.string,
  inputLabel: PropTypes.object,
  suffix: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  component: PropTypes.object,
  valueToSet: PropTypes.any,
  rules: PropTypes.object,
  disabled: PropTypes.bool
};

ToggleField.defaultProps = {
  name: "",
  onChange: () => {},
  toggleEditField: () => {},
  heavy: false,
  emptyLabel: "",
  inputLabel: null,
  suffix: null
};

export default connect(mapStateToProps)(ToggleField);
