import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import { chemicalsOptionsSelector } from "selectors/chemicals";
import { Container, Grid, Button, Segment } from "semantic-ui-react";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import styles from "./Product.module.css";
import ProductForm from "./ProductForm";
import ProductRow from "./ProductRow";
import CollapsibleBlock from "components/CollapsibleBlock";

const Product = ({ purchaseHisory }) => {
  const { values, setFieldValue, setValues, validateForm } = useFormikContext();
  const productsList = useSelector(chemicalsOptionsSelector);
  const [addButtonCheck, setAddButtonCheck] = useState(true);
  const [deleteButtonCheck, setDeleteButtonCheck] = useState(false);
  const [productId, setProductId] = useState(null);
  const productsListCheck = values.products?.length;
  const [validation, setValidation] = useState(false);
  const [checkForm, setCheckForm] = useState(false);
  const [check, setCheck] = useState();
  const [dirtyRow, setDirty] = useState();
  const onNewNewProductAdd = async products => {
    const newData = products;
    setFieldValue(
      "products",
      values.products ? [...values.products, newData] : [newData]
    );
    validateForm();
    setAddButtonCheck(true);
  };
  const addProduct = () => {
    setAddButtonCheck(false);
    setDeleteButtonCheck(true);
  };
  const deleteAddingForm = () => {
    setAddButtonCheck(true);
  };
  return (
    <>
      {!isEmpty(values.products) && (
        <Grid columns="equal" as={Container}>
          {values.products.map((item, productIndex) => (
            <div className={styles.rowContainer}>
              <ProductRow
                productIndex={productIndex}
                deleteButtonCheck={deleteButtonCheck}
                productsListCheck={productsListCheck}
                setProductId={setProductId}
                validation={validation}
                setValidation={setValidation}
                checkForm={checkForm}
                setCheckForm={setCheckForm}
                dirtyRow={dirtyRow}
                setDirty={setDirty}
                productsList={productsList}
                productData={item}
                key={item.id}
                checmicalId={item.chemicalId}
                products={values.products}
                quantities={item.quantities}
                chemicalId={item.chemicalId}
                manufacturer={item.manufacturer}
                totalCost={item.totalCost}
                purchaseHisory={purchaseHisory}
              />
            </div>
          ))}
        </Grid>
      )}

      {productsListCheck && addButtonCheck ? (
        <div className={styles.addButtonContainer}>
          <Button
            type="submit"
            className="button-text color-green"
            onClick={addProduct}
          >
            + Add product
          </Button>
        </div>
      ) : null}
      {(!addButtonCheck || !productsListCheck) && (
        <Segment>
          <CollapsibleBlock title="New Product" className={styles.root}>
            <ProductForm
              validation={validation}
              setValidation={setValidation}
              setCheckForm={setCheckForm}
              checkForm={checkForm}
              dirtyRow={dirtyRow}
              setProductId={setProductId}
              setDirty={setDirty}
              addButtonCheck={addButtonCheck}
              deleteButtonCheck={deleteButtonCheck}
              productsListCheck={productsListCheck}
              productsList={productsList}
              deleteAddingForm={deleteAddingForm}
              onSubmit={onNewNewProductAdd}
            />
          </CollapsibleBlock>
        </Segment>
      )}
    </>
  );
};
export default Product;
