import React from "react";
import PropTypes from "prop-types";
import {
  APPLICATION_TYPES,
  recommendationTaskShape
} from "constants/Recommendations/types";
import MachineryInformationForm from "./MachineryInformationForm";
import ProductsInformationForm from "./ProductsInformationForm";

const OperationsTab = ({ currentTask, onEditTask }) => {
  return (
    <>
      {APPLICATION_TYPES[currentTask.applicationType] !==
        APPLICATION_TYPES.FERTIGATION && (
        <MachineryInformationForm
          currentTask={currentTask}
          onEditTask={onEditTask}
        />
      )}
      <ProductsInformationForm
        currentTask={currentTask}
        onEditTask={onEditTask}
      />
    </>
  );
};

OperationsTab.propTypes = {
  currentTask: recommendationTaskShape,
  onEditTask: PropTypes.func
};

export default OperationsTab;
