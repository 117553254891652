import { actionType } from '../../constants/EmployeeBarcodes';
import { axiosProxy } from '../../utils/axiosProxy';

const url = '/employees/barcodes';

export const generateEmployeesBarcodes = ({ employeesIds, quantity }) => async dispatch => {
  try {
    dispatch({
      type: actionType.GENERATE_EMPLOYEE_BARCODES_START,
    });

    const res = await axiosProxy({
      method: 'POST',
      url: `${url}/generate`,
      data: {
        employeesIds,
        quantity,
      },
    });

    await dispatch({
      type: actionType.GENERATE_EMPLOYEE_BARCODES,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: actionType.GENERATE_EMPLOYEE_BARCODES_FAIL,
      payload: error,
    });
  }
};

export const markPrinted = employeeId => dispatch => {
  dispatch({
    type: actionType.EMPLOYEE_BARCODES_MARK_PRINTED,
    payload: employeeId,
  });
};

export const setPrintLayout = printLayout => dispatch => {
  dispatch({
    type: actionType.EMPLOYEE_BARCODES_SET_PRINT_LAYOUT,
    payload: printLayout,
  });
};

export const clearSeries = () => dispatch => {
  dispatch({
    type: actionType.EMPLOYEE_BARCODES_CLEAR_SERIES,
  });
};

export const clearError = () => dispatch => {
  dispatch({
    type: actionType.EMPLOYEE_BARCODES_CLEAR_ERROR,
  });
};
