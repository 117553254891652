import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHeader as Header,
  TableHeaderCell,
  TableRow
} from "semantic-ui-react";
import Numeric from "../../../../../../components/Numeric/Numeric";
import { connect } from "react-redux";

const OperatingCostsTable = ({ task }) => {
  return (
    <Table unstackable>
      <Header>
        <TableRow>
          <TableHeaderCell style={{ width: "280px" }}>Type</TableHeaderCell>
          <TableHeaderCell>Units</TableHeaderCell>
          <TableHeaderCell>Rate</TableHeaderCell>
          <TableHeaderCell style={{ width: "180px" }}>Total</TableHeaderCell>
        </TableRow>
      </Header>
      <TableBody>
        {task.map(({ cost, costSubType, rate }, i) => (
          <TableRow key={`expanded_table_${i}`}>
            <TableCell>{costSubType}</TableCell>
            <TableCell>
              <Numeric
                fractionDigits={cost / rate > 1000 ? 0 : 2}
                value={cost / rate}
                defaultValue={"-"}
              />
            </TableCell>
            <TableCell>
              <Numeric
                fractionDigits={rate > 1000 ? 0 : 2}
                value={rate}
                defaultValue={"-"}
              />
            </TableCell>
            <TableCell>
              <Numeric fractionDigits={2} value={cost} defaultValue={"-"} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default OperatingCostsTable;
