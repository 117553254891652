import { actionType } from "../../constants/Farms";

const initialState = {
  data: [],
  isReportInProgress: false,
  error: null
};

export default (state = initialState, action) => {
  if (!action) return state;

  switch (action.type) {
    case actionType.FETCH_OPERATING_COST_BREAKDOWN_START:
      return {
        ...state,
        isReportInProgress: true
      };
    case actionType.FETCH_OPERATING_COST_BREAKDOWN:
      return {
        ...state,
        data: action.payload,
        isReportInProgress: false
      };
    case actionType.FETCH_OPERATING_COST_BREAKDOWN_FAIL:
      return {
        ...state,
        isReportInProgress: false,
        error: action.payload
      };

    default:
      return state;
  }
};
