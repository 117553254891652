import React from "react";

export const BasicTableCell = props => {
  const {
    value,
    column: { Header }
  } = props;

  return (
    <>
      <div className="cellTitle hide-md">{Header}</div>
      <div className="cellValue">{value}</div>
    </>
  );
};
