import { orderBy } from "lodash";
import { createSelector, createStructuredSelector } from "reselect";
import { farmsOptionsSelector } from "./farms";
import {
  areasOptionsSelector,
  chemicalsOptionsSelector,
  employeesSelector,
  growthStageSelector,
  taskNamesSelector
} from "./sprayDiary";

const online = state => state.offline.online;
const tasks = state => state.spray.tasks;
const tasksData = state => state.spray.tasks.data;
const tasksRoutes = state => state.spray.tasks.routes;
const tasksDataOffline = state => state.spray.tasks.dataOffline;
const urlString = state => state.spray.urlString;
const taskId = (state, props) =>
  props && props.activeTask ? props.activeTask : null;
const ids = state => state.spray.ids.data;
const crops = state => state.spray.crops.data;
const seasons = state => state.settings.data.seasons;
const varieties = state => state.spray.varieties.data;
const sort = (state, props) =>
  props && props.sortOptions ? props.sortOptions : null;

const sortChemicalsByPosition = (tasks, sortOptions) => {
  const sort = sortOptions ? sortOptions.split(",") : null;
  return {
    ...tasks,
    content: tasks.content.map(task => ({
      ...task,
      chemicals: task.chemicals
        ? orderBy(
            task.chemicals,
            [
              sort && sort[0] === "chemical"
                ? chemical => chemical.chemical.name.toLowerCase()
                : "position"
            ],
            sort ? sort[1] : "asc"
          )
        : []
    }))
  };
};

const sortOptionsSelector = createSelector([tasks], selectTask => {
  const { sortFields } = selectTask;

  return sortFields
    ? Object.keys(sortFields).map(field => ({
        key: field,
        text: `Sort by ${sortFields[field].name}`,
        value: field,
        content: sortFields[field].name,
        sortfields: [field]
      }))
    : [];
});

const sortSelectors = {};
const taskSelector = (state, props = {}) => {
  const { sortOptions } = props;
  const sortToStore = sortOptions ? sortOptions.split(",")[0] : "default";
  if (!sortSelectors[sortToStore]) {
    sortSelectors[sortToStore] = createSelector(
      [urlString, tasksData, tasksDataOffline, online, sort],
      (
        selectUrlString,
        selectTaskData,
        selectTaskDataOffline,
        selectOnline,
        selectSort
      ) => {
        const tasks = selectOnline ? selectTaskData : selectTaskDataOffline;
        return sortChemicalsByPosition(tasks, selectSort);
      }
    );
  }
  return sortSelectors[sortToStore](state, props);
};

const activeTaskRouteSelector = createSelector(
  [tasksRoutes, taskId],
  (routes, taskId) => {
    if (!taskId || !routes) {
      return null;
    }

    return routes[taskId];
  }
);

const idsOptionsSelector = createSelector([ids], selectIds => {
  return selectIds
    ? selectIds.content.map(task => ({
        key: task.id,
        text: `ST-${task.id}`,
        value: task.id
      }))
    : null;
});

const cropsOptionsSelector = createSelector([crops], selectCrops => {
  return selectCrops
    ? selectCrops.content.map(crop => ({
        key: crop.id,
        text: crop.name,
        value: crop.id
      }))
    : null;
});
const seasonsOptionsSelector = createSelector([seasons], seasons => {
  return seasons
    ? seasons.map(season => ({
        key: season.seasonName,
        text: season.seasonName,
        value: [season.startAt, season.endAt]
      }))
    : null;
});

const varietyOptionsSelector = createSelector([varieties], selectVarieties => {
  return selectVarieties
    ? selectVarieties.content.map(variety => ({
        key: variety.id,
        text: variety.name,
        value: variety.id
      }))
    : null;
});

const rangeOptions = createSelector([], () => [
  { key: "from", text: "From", value: "" },
  { key: "to", text: "To", value: "" }
]);

const switchOptions = createSelector([], () => [
  { key: "yes", text: "Yes", value: "true" },
  { key: "no", text: "No", value: "false" },
  { key: "any", text: "All", value: "" }
]);

const statusOptions = createSelector([], () => [
  { key: "open", text: "Open", value: "OPEN" },
  { key: "inProgress", text: "In Progress", value: "IN_PROGRESS" },
  { key: "completed", text: "Completed", value: "COMPLETED" }
]);
const typeOptionsSelector = createSelector([], () => [
  { key: 1, text: "Spray", value: "SPRAY" },
  { key: 2, text: "Spread", value: "SPREAD" },
  { key: 3, text: "Fertigation", value: "FERTIGATION" }
]);

const filtersOptionsSelector = createStructuredSelector({
  farmsOptions: farmsOptionsSelector,
  areasOptions: areasOptionsSelector,
  chemicalsOptions: chemicalsOptionsSelector,
  typeOptions: typeOptionsSelector,
  employeesOptions: employeesSelector,
  varietyOptions: varietyOptionsSelector,
  cropsOptions: cropsOptionsSelector,
  idsOptions: idsOptionsSelector,
  namesOptions: taskNamesSelector,
  growthStageOptions: growthStageSelector,
  statusOptions,
  rangeOptions,
  switchOptions,
  seasonsOptionsSelector
});

const paginationSelector = createSelector([taskSelector], selectTasks => {
  const { size, number, totalPages, totalElements } = selectTasks;

  return {
    size,
    page: number,
    totalPages,
    totalElements,
    itemsCount: {
      itemFrom: number * size + 1,
      itemTo: Math.min(number * size + size, totalElements)
    }
  };
});

export {
  filtersOptionsSelector,
  taskSelector,
  sortOptionsSelector,
  paginationSelector,
  activeTaskRouteSelector
};
