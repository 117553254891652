import React from "react";
import { Form } from "semantic-ui-react";
import { FastField } from "formik";

const GrowthStage = () => (
  <Form.Field>
    <FastField label="Growth stage" fluid name="growthStage" as={Form.Input} />
  </Form.Field>
);

export default GrowthStage;
