export const actionType = {
  GENERATE_EMPLOYEE_BARCODES_START: 'EMPLOYEE_BARCODES/GENERATE_EMPLOYEE_BARCODES_START',
  GENERATE_EMPLOYEE_BARCODES: 'EMPLOYEE_BARCODES/GENERATE_EMPLOYEE_BARCODES',
  GENERATE_EMPLOYEE_BARCODES_FAIL: 'EMPLOYEE_BARCODES/GENERATE_EMPLOYEE_BARCODES_FAIL',

  EMPLOYEE_BARCODES_MARK_PRINTED: 'EMPLOYEE_BARCODES/MARK_PRINTED',

  EMPLOYEE_BARCODES_SET_PRINT_LAYOUT: 'EMPLOYEE_BARCODES/SET_PRINT_LAYOUT',

  EMPLOYEE_BARCODES_CLEAR_SERIES: 'EMPLOYEE_BARCODES/CLEAR_SERIES',
  EMPLOYEE_BARCODES_CLEAR_ERROR: 'EMPLOYEE_BARCODES/CLEAR_ERROR',
};
