import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Grid, Segment, Image, Header, Button } from "semantic-ui-react";
import plant from "../../../assets/img/plant.svg";
import { history } from "../../../store";
import styles from "./CreateSprayTaskPrompt.module.css";

const CreateSprayTaskPrompt = ({ route, online = true, Can }) => {
  const handleCreateTask = useCallback(() => {
    history.push(`${route.href}/create_spray_task`);
  }, [route]);

  return (
    <Grid centered className={styles.taskPrompt}>
      <Grid.Row>
        <Grid.Column computer={6} tablet={14} mobile={14}>
          <Segment basic textAlign="center">
            <Image src={plant} size="medium" centered />
            <Header as="h1">Spray Diary</Header>
            <Can I="add" a="spray_tasks">
              <Header>
                No spraying was done so far
                {!online && " or it not available since you are offline"}.<br />
                Create your first spray task and you are good to go
              </Header>
              <Button primary size="huge" onClick={handleCreateTask}>
                Create spray task
              </Button>
            </Can>
            <Can not I="add" a="spray_tasks">
              <Header>
                No spraying was done so far
                {!online && " or it not available since you are offline"}.
              </Header>
            </Can>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

CreateSprayTaskPrompt.propTypes = {
  online: PropTypes.bool,
  setCanActiveTask: PropTypes.func,
  Can: PropTypes.func,
  route: PropTypes.object
};

export default CreateSprayTaskPrompt;
