import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Form, Input } from "formsy-semantic-ui-react";
import { Button, Grid, Icon, Modal, TextArea } from "semantic-ui-react";
import { Dropdown } from "formsy-semantic-ui-react";
import { validationErrors } from "../../../../../utils/validationRules";
import ErrorLabel from "../../../../../components/ErrorLabel";
import styles from "./PackedBoxesDetails.module.css";
import DatePicker from "../../../../../components/DatePicker";
import SelectArea from "../../../../../components/SelectArea";
import { COUNTRY_OPTIONS } from "../../../../../js/common/countries";
import SwitchButton from "../../../../../components/SwitchButton";
import SelectBoxUnit from "../../../../../components/SelectBoxUnit";
import { Area } from "../../../../../models/block.model";
import SelectPackingShed from "../../../../../components/SelectPackingShed";
import CheckedInputRow from "../../../../../components/CheckedInputRow/CheckedInputRow";
import SegmentClosable from "components/SegmentClosable/SegmentClosable";
import _ from "lodash";
import { addBoxUnit } from "actions/BoxUnits/boxUnits";
import { useDispatch } from "react-redux";

const packLocationOptions = [
  { label: "Field", value: "Field" },
  { label: "Shed", value: "Shed" }
];

const PackedBoxesDetails = ({
  scannedAt,
  area,
  boxUnit,
  packLocation,
  packingShed,
  intendedDestination,
  notes,
  changeValue,
  showCheckbox,
  isActive,
  setActive
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [boxUnitState, setBoxUnitState] = useState({});
  const [formValid, setFormValid] = useState(false);
  const dispatch = useDispatch();

  const columnMiddle = {
    mobile: 16,
    tablet: 16,
    computer: 10,
    largeScreen: 10,
    widescreen: 10
  };

  const columnLabel = {
    mobile: 7,
    tablet: 4,
    computer: 3,
    largeScreen: 3,
    widescreen: 3
  };

  const columnValue = {
    mobile: 9,
    tablet: 12,
    computer: 13,
    largeScreen: 13,
    widescreen: 13
  };

  const variety = area && new Area(area).areaVarietyByDate(scannedAt);
  const changeModalValue = (fieldName, value) => {
    setBoxUnitState({
      ...boxUnitState,
      [fieldName]: value
    });
  };

  useEffect(() => {
    setBoxUnitState({
      ...boxUnitState,
      ["displayName"]: `${
        boxUnitState.netWeight ? `${boxUnitState.netWeight} KG ` : ""
      }${
        boxUnitState.packType ? `${boxUnitState.packType} ` : ""
      }${boxUnitState.boxType || ""}`
    });
  }, [boxUnitState.netWeight, boxUnitState.packType, boxUnitState.boxType]);

  const onSubmit = () => {
    dispatch(addBoxUnit(boxUnitState, true)).then(response => {
      changeValue("boxUnit", { id: response.id });
      setOpenModal(false);
      setBoxUnitState({});
    });
  };
  const validateForm = () => {
    const requiredFields = [
      "pickingPrice",
      "paddingPrice",
      "cartingPrice",
      "unitsPerPallet",
      "displayName"
    ];
    const isFormValid = requiredFields.every(field => !!boxUnitState[field]);
    setFormValid(isFormValid);
  };
  useEffect(() => {
    validateForm();
  }, [boxUnitState]);

  return (
    <>
      <Modal
        size={"large"}
        open={openModal}
        onClose={() => setOpenModal(false)}
        className={styles.root}
      >
        <Modal.Header className={styles.modalHeader}>
          Add New box unit{" "}
          <Icon
            className={styles.closeIcon}
            onClick={() => setOpenModal(false)}
            name="close"
          />
        </Modal.Header>
        <Modal.Content scrolling className={styles.modalContentContainer}>
          <Form>
            <SegmentClosable
              title="Box unit information"
              // errors={errorsInSegments.information}
            >
              <Grid>
                <Grid.Row>
                  <Grid.Column {...columnMiddle}>
                    <Form.Field>
                      <label htmlFor="netWeight">Net weight</label>
                      <Input
                        fluid
                        name="netWeight"
                        size="small"
                        type="number"
                        validations="isNotNegativeFloat"
                        validationErrors={validationErrors()}
                        errorLabel={ErrorLabel}
                        value={boxUnitState.netWeight}
                        onChange={event =>
                          changeModalValue("netWeight", event.target.value)
                        }
                        label="Kg"
                        labelPosition="right"
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column {...columnMiddle}>
                    <Form.Input
                      fluid
                      label="Pack type"
                      placeholder="Pack type"
                      name="packType"
                      size="small"
                      value={boxUnitState.packType}
                      onChange={event =>
                        changeModalValue("packType", event.target.value)
                      }
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column {...columnMiddle}>
                    <Form.Input
                      fluid
                      label="Box type"
                      placeholder="Box type"
                      name="boxType"
                      size="small"
                      value={boxUnitState.boxType}
                      onChange={event =>
                        changeModalValue("boxType", event.target.value)
                      }
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column {...columnMiddle}>
                    <Form.Input
                      fluid
                      label="Display name"
                      placeholder="Display name"
                      name="displayName"
                      size="small"
                      required
                      validationErrors={validationErrors({
                        isDefaultRequiredValue: "Display name"
                      })}
                      errorLabel={ErrorLabel}
                      value={`${
                        boxUnitState.netWeight
                          ? `${boxUnitState.netWeight} KG `
                          : ""
                      }${
                        boxUnitState.packType ? `${boxUnitState.packType} ` : ""
                      }${boxUnitState.boxType || ""}`}
                      onChange={event =>
                        changeModalValue("displayName", event.target.value)
                      }
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </SegmentClosable>
            <SegmentClosable
              title="Pricing"
              // errors={errorsInSegments.information}
            >
              <Grid>
                <Grid.Row>
                  <Grid.Column {...columnMiddle}>
                    <Form.Field required>
                      <label htmlFor="pickingPrice">Picking price</label>
                      <Input
                        fluid
                        name="pickingPrice"
                        size="small"
                        type="number"
                        validations="isNotNegativeFloat"
                        validationErrors={validationErrors({
                          isDefaultRequiredValue: "Picking price"
                        })}
                        errorLabel={ErrorLabel}
                        value={boxUnitState.pickingPrice}
                        required
                        onChange={event =>
                          changeModalValue("pickingPrice", event.target.value)
                        }
                        label="$"
                        labelPosition="left"
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column {...columnMiddle}>
                    <Form.Field required>
                      <label htmlFor="paddingPrice">Padding price</label>
                      <Input
                        fluid
                        name="paddingPrice"
                        size="small"
                        type="number"
                        validations="isNotNegativeFloat"
                        validationErrors={validationErrors({
                          isDefaultRequiredValue: "Padding price"
                        })}
                        errorLabel={ErrorLabel}
                        value={boxUnitState.paddingPrice}
                        required
                        onChange={event =>
                          changeModalValue("paddingPrice", event.target.value)
                        }
                        label="$"
                        labelPosition="left"
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column {...columnMiddle}>
                    <Form.Field required>
                      <label htmlFor="cartingPrice">Crating price</label>
                      <Input
                        fluid
                        name="cartingPrice"
                        size="small"
                        type="number"
                        validations="isNotNegativeFloat"
                        validationErrors={validationErrors({
                          isDefaultRequiredValue: "Crating price"
                        })}
                        errorLabel={ErrorLabel}
                        value={boxUnitState.cartingPrice}
                        required
                        onChange={event =>
                          changeModalValue("cartingPrice", event.target.value)
                        }
                        label="$"
                        labelPosition="left"
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </SegmentClosable>
            <SegmentClosable title="Packing">
              <Grid>
                <Grid.Row>
                  <Grid.Column {...columnMiddle}>
                    <Form.Field required>
                      <label htmlFor="unitsPerPallet">Units per pallet</label>
                      <Input
                        fluid
                        name="unitsPerPallet"
                        size="small"
                        type="number"
                        validations="isNotNegativeInteger"
                        validationErrors={validationErrors({
                          isDefaultRequiredValue: "Units per pallet"
                        })}
                        errorLabel={ErrorLabel}
                        value={boxUnitState.unitsPerPallet}
                        required
                        onChange={event =>
                          changeModalValue("unitsPerPallet", event.target.value)
                        }
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column {...columnMiddle}>
                    <Form.Field>
                      <label htmlFor="liner">Liner</label>
                      <Input
                        fluid
                        name="liner"
                        size="small"
                        value={boxUnitState.liner}
                        onChange={event =>
                          changeModalValue("liner", event.target.value)
                        }
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </SegmentClosable>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={!formValid}
            onClick={() => onSubmit()}
            primary
            content="Save"
          />
        </Modal.Actions>
      </Modal>
      <CheckedInputRow
        name={"scannedAt"}
        showCheckbox={showCheckbox}
        required={true}
        label={"Date"}
        columnWidth={columnMiddle}
        isActive={isActive}
        setActive={setActive}
      >
        <DatePicker
          required={!showCheckbox || isActive("scannedAt")}
          validationErrors={validationErrors()}
          errorLabel={ErrorLabel}
          value={scannedAt}
          onChange={scannedAt => changeValue("scannedAt", scannedAt)}
          focused={true}
        />
      </CheckedInputRow>
      <CheckedInputRow
        name={"area"}
        showCheckbox={showCheckbox}
        required={true}
        label={"Select area"}
        columnWidth={columnMiddle}
        isActive={isActive}
        setActive={setActive}
      >
        <SelectArea
          name="area"
          validation={"isRequired"}
          validationErrors={validationErrors({
            isDefaultRequiredValue: "area"
          })}
          errorLabel={ErrorLabel}
          date={scannedAt}
          required={!showCheckbox || isActive("area")}
          value={area && area.id}
          onChange={(_, data) => {
            const { area } = data.options.find(
              item => item.key === data.value
            ) || { area: null };
            if (area) changeValue("area", area);
          }}
        />
      </CheckedInputRow>
      {area && area.id && (
        <>
          <Grid.Row>
            <Grid.Column {...columnLabel} className={styles.label}>
              Farm
            </Grid.Column>
            <Grid.Column {...columnValue}>
              {area && area.farm && area.farm.name}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column {...columnLabel} className={styles.label}>
              Registration number
            </Grid.Column>
            <Grid.Column {...columnValue}>
              {area && area.registrationNumber}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column {...columnLabel} className={styles.label}>
              Crop
            </Grid.Column>
            <Grid.Column {...columnValue}>
              {variety && variety.crop && variety.crop.name}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column {...columnLabel} className={styles.label}>
              Variety
            </Grid.Column>
            <Grid.Column {...columnValue}>
              {variety && variety.name}
            </Grid.Column>
          </Grid.Row>
        </>
      )}
      <CheckedInputRow
        name={"boxUnit"}
        showCheckbox={showCheckbox}
        required={!showCheckbox || isActive("boxUnit")}
        label={"Select box unit"}
        columnWidth={columnMiddle}
        isActive={isActive}
        setActive={setActive}
      >
        <SelectBoxUnit
          setOpenModal={setOpenModal}
          name="boxUnit"
          validation={"isRequired"}
          validationErrors={validationErrors({
            isDefaultRequiredValue: "Box unit"
          })}
          errorLabel={ErrorLabel}
          required={!showCheckbox || isActive("boxUnit")}
          value={boxUnit && boxUnit.id}
          onChange={(_, data) => changeValue("boxUnit", { id: data.value })}
        />
      </CheckedInputRow>
      <CheckedInputRow
        name={"packLocation"}
        showCheckbox={showCheckbox}
        required={!showCheckbox || isActive("packLocation")}
        label={"Select pack location"}
        columnWidth={columnMiddle}
        isActive={isActive}
        setActive={setActive}
      >
        <SwitchButton
          name="packLocation"
          onChange={(_, packLocation) =>
            changeValue("packLocation", packLocation)
          }
          value={packLocation}
          items={packLocationOptions}
          validation={"isRequired"}
          validationErrors={validationErrors({
            isDefaultRequiredValue: "Pack location"
          })}
          errorLabel={ErrorLabel}
          title={null}
          required={!showCheckbox || isActive("packLocation")}
        />
      </CheckedInputRow>
      <CheckedInputRow
        name={"packingShed"}
        showCheckbox={showCheckbox}
        required={!showCheckbox || isActive("packingShed")}
        label={"Select packing shed"}
        columnWidth={columnMiddle}
        isActive={isActive}
        setActive={setActive}
      >
        <SelectPackingShed
          name="packingShed"
          validation={"isRequired"}
          validationErrors={validationErrors({
            isDefaultRequiredValue: "Packing shed"
          })}
          errorLabel={ErrorLabel}
          required={!showCheckbox || isActive("packingShed")}
          value={packingShed && packingShed.id}
          onChange={(_, data) => changeValue("packingShed", { id: data.value })}
        />
      </CheckedInputRow>
      <CheckedInputRow
        name={"intendedDestination"}
        showCheckbox={showCheckbox}
        label={"Select intended destination"}
        columnWidth={columnMiddle}
        isActive={isActive}
        setActive={setActive}
      >
        <Dropdown
          name="intendedDestination"
          onChange={(_, data) => changeValue("intendedDestination", data.value)}
          options={COUNTRY_OPTIONS}
          search
          selection
          selectOnBlur={false}
          value={intendedDestination}
          clearable
        />
      </CheckedInputRow>
      <CheckedInputRow
        name={"notes"}
        showCheckbox={showCheckbox}
        label={"Notes"}
        columnWidth={columnMiddle}
        isActive={isActive}
        setActive={setActive}
      >
        <TextArea
          name="notes"
          size="large"
          value={notes}
          onChange={event => changeValue("notes", event.target.value)}
        />
      </CheckedInputRow>
    </>
  );
};

PackedBoxesDetails.propTypes = {
  isActive: PropTypes.bool,
  setActive: PropTypes.func,
  showCheckbox: PropTypes.func,
  changeValue: PropTypes.func,
  notes: PropTypes.any,
  intendedDestination: PropTypes.string,
  packingShed: PropTypes.any,
  packLocation: PropTypes.any,
  boxUnit: PropTypes.any,
  area: PropTypes.any,
  scannedAt: PropTypes.any
};

export default PackedBoxesDetails;
