import { createSelector } from 'reselect';

const newFarm = state => state.farms.newFarm;
const blocks = state => state.farms.newFarm.blocks;

const blocksToShowSelector = createSelector([newFarm, blocks], (newFarm, blocks) => {
  return blocks.filter(block => newFarm.showArchived || !block.archived);
});

const patchesToShowSelector = createSelector([newFarm, blocks], (newFarm, blocks) => {
  const block = blocks.find(block => block.id === newFarm.currentBlock);

  if (!block || !block.patches) {
    return [];
  }

  return block.patches.filter(patch => newFarm.showArchived || !patch.archived);
});

const currentBlockSelector = createSelector([newFarm, blocks], (newFarm, blocks) => {
  return blocks.find(block => block.id === newFarm.currentBlock);
});

export { blocksToShowSelector, patchesToShowSelector, currentBlockSelector };
