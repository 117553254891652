import { axiosProxy } from "../../utils/axiosProxy";
import { actionType } from "../../constants/Farms";
import { toEndDateString, toStartDateString } from "../../utils/dateUtils";

export const toggleOpenFarmTable = ({ farmId, open }) => {
  return {
    type: actionType.TOGGLE_OPEN_FARM_TABLE,
    payload: { farmId, open }
  };
};

export const toggleFarmListShowArchive = show => {
  return {
    type: actionType.TOGGLE_FARM_LIST_SHOW_ARCHIVED,
    payload: show
  };
};

export const setFarmsSearch = show => {
  return {
    type: actionType.SET_FARMS_SEARCH,
    payload: show
  };
};

export const setFarmField = ({ farmId, fieldName, fieldValue }) => {
  return {
    type: actionType.SET_FARM_FIELD,
    payload: { farmId, fieldName, fieldValue }
  };
};

export const setSummaryField = ({ farmId, fieldName, fieldValue }) => {
  return {
    type: actionType.SET_SUMMARY_FIELD,
    payload: { farmId, fieldName, fieldValue }
  };
};

export const setNewFarm = ({ newFarm }) => {
  return {
    type: actionType.SET_NEW_FARM,
    payload: { newFarm: newFarm }
  };
};

export const setActiveBlock = block => {
  return {
    type: actionType.SET_ACTIVE_BLOCK,
    payload: block
  };
};

export const handleDeleteArea = area => {
  return {
    type: actionType.DELETE_SUMMARY_FARM_AREA,
    payload: area
  };
};

export const getFarmsList = () => async dispatch => {
  let url = "/farms?unpaged=true";
  try {
    dispatch({
      type: actionType.GET_FARMS_LIST_START
    });
    const res = await axiosProxy({ url, method: "GET" });

    dispatch({
      type: actionType.GET_FARMS_LIST,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: actionType.GET_FARMS_LIST_FAIL,
      payload: error
    });
  }
};

export const getFarm = farmId => async dispatch => {
  let url = `/farms/${farmId}?unpaged=true`;
  try {
    dispatch({
      type: actionType.GET_FARM_START
    });
    const res = await axiosProxy({
      url,
      method: "GET"
    });

    dispatch({
      type: actionType.GET_FARM,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: actionType.GET_FARM_FAIL,
      payload: error
    });
  }
};

export const archiveFarm = (farmId, archive = true) => async dispatch => {
  let url = `/farms/${farmId}/archive`;
  const params = new URLSearchParams();
  params.append("archive", archive.toString());
  try {
    dispatch({
      type: actionType.ARCHIVE_FARM_START
    });
    await axiosProxy({
      method: "PUT",
      url,
      params
    });

    await dispatch({
      type: actionType.ARCHIVE_FARM,
      payload: { farmId, archive }
    });
  } catch (error) {
    dispatch({
      type: actionType.ARCHIVE_FARM_FAIL,
      payload: error
    });
  }
};

export const deleteFarm = farmId => async dispatch => {
  let url = `/farms/${farmId}`;
  try {
    dispatch({
      type: actionType.DELETE_FARM_START
    });
    await axiosProxy({
      url,
      method: "DELETE"
    });

    dispatch({
      type: actionType.DELETE_FARM,
      payload: farmId
    });
  } catch (error) {
    dispatch({
      type: actionType.DELETE_FARM_FAIL,
      payload: error
    });
  }
};

export const getFarmsSummary = () => async (dispatch, getState) => {
  const { farms, areas } = getState();
  let url = "/farms/summary";
  try {
    dispatch({
      type: actionType.GET_FARMS_SUMMARY_START
    });
    const res = await axiosProxy({
      url,
      method: "GET"
    });
    const newData = res.data.map(item => {
      return {
        farm: {
          archived: item.archived,
          farmStructure: item.farmStructure,
          id: item.id,
          location: item.location,
          name: item.name,
          weatherStation: item.weatherStation
        },
        areas: item.areas.map(item => {
          return {
            ...item,
            ...areas.list?.content.find(area => area.id === item.areaId)
          };
        })
      };
    });
    dispatch({
      type: actionType.GET_FARMS_SUMMARY,
      payload: newData
    });
  } catch (error) {
    dispatch({
      type: actionType.GET_FARMS_SUMMARY_FAIL,
      payload: error
    });
  }
};

export const getFarmSummary = farmId => async (dispatch, getState) => {
  const { farms, areas } = getState();
  let url = `/farms/summary/${farmId}`;

  try {
    dispatch({
      type: actionType.GET_FARM_SUMMARY_START
    });
    const res = await axiosProxy({
      url,
      method: "GET"
    });

    const farm = res.data;
    const newData = {
      farm: {
        archived: farm.archived,
        farmStructure: farm.farmStructure,
        id: farm.id,
        location: farm.location,
        name: farm.name,
        weatherStation:
          farms.list?.content.find(f => f.id == farm.id)?.weatherStation || null
      },
      areas: farm.areas.map(item => {
        return {
          ...item,
          ...areas.list?.content.find(area => area.id === item.areaId)
        };
      })
    };
    dispatch({
      type: actionType.GET_FARM_SUMMARY,
      payload: newData
    });
  } catch (error) {
    dispatch({
      type: actionType.GET_FARM_SUMMARY_FAIL,
      payload: error
    });
  }
};

export const getSprayTasksForArea = (
  areaID,
  startDate,
  endDate
) => async dispatch => {
  try {
    const url = "/spray_tasks_v2";

    dispatch({
      type: actionType.FETCH_SPRAY_TASKS_FOR_AREA_START
    });

    const params = new URLSearchParams();

    params.append("unpaged", "true");
    params.append("area", areaID);

    if (startDate) params.append("dateAfter", startDate);
    if (endDate) params.append("dateBefore", endDate);

    const res = await axiosProxy({ method: "GET", params, url });

    await dispatch({
      type: actionType.FETCH_SPRAY_TASKS_FOR_AREA_SUCCESS,
      payload: res.data.content
    });
  } catch (error) {
    dispatch({
      type: actionType.FETCH_SPRAY_TASKS_FOR_AREA_FAIL,
      payload: error
    });
  }
};

const getChemicalsBreakdown = (
  filterKey,
  filterValue,
  startDate,
  endDate
) => async dispatch => {
  try {
    const url = "/reporting_module/areaCostByTask";

    dispatch({ type: actionType.FETCH_CHEMICALS_BREAKDOWN_START });
    dispatch({ type: actionType.FETCH_TASK_COST_BREAKDOWN_START });
    dispatch({ type: actionType.FETCH_OPERATING_COST_BREAKDOWN_START });

    const params = new URLSearchParams();

    params.append(filterKey, filterValue);

    if (startDate) params.append("from", toStartDateString(startDate));
    if (endDate) params.append("to", toEndDateString(endDate));

    const res = await axiosProxy({ method: "GET", params, url });

    await dispatch({
      type: actionType.FETCH_CHEMICALS_BREAKDOWN,
      payload: res.data.sprayTaskCostGrouped
    });
    await dispatch({
      type: actionType.FETCH_OPERATING_COST_BREAKDOWN,
      payload: res.data.operatingCostGrouped
    });

    await dispatch({
      type: actionType.FETCH_TASK_COST_BREAKDOWN,
      payload: res.data.generalTaskCostGrouped
    });
  } catch (error) {
    dispatch({
      type: actionType.FETCH_CHEMICALS_BREAKDOWN_FAIL,
      payload: error
    });
    dispatch({
      type: actionType.FETCH_OPERATING_COST_BREAKDOWN_FAIL,
      payload: error
    });

    dispatch({
      type: actionType.FETCH_TASK_COST_BREAKDOWN_FAIL,
      payload: error
    });
  }
};

export const getChemicalsBreakdownForFarm = (farmID, startDate, endDate) =>
  getChemicalsBreakdown("farmId", farmID, startDate, endDate);

export const getChemicalsBreakdownForPatch = (patchID, startDate, endDate) =>
  getChemicalsBreakdown("areaId", patchID, startDate, endDate);

export const clearError = () => {
  return {
    type: actionType.FARM_CLEAR_ERROR
  };
};
