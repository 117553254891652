import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Button, Grid, Header, Icon } from "semantic-ui-react";
import moment from "moment";

import { isEmptyValue } from "../../../../utils/tasksUtils";
import TaskRow from "../../../SprayDiary/TaskDetailsMobile/TaskRow";
import styles from "./Sidebar.module.css";
import tableStyles from "../AddDispatch/DispatchForm/SelectPalletsTable/SelectPalletsTable.module.css";
import ExpandedTable from "../../../../components/ExpandedTable";
import { columns, exelColumns } from "../columns";
import { renderCell } from "../AddDispatch/DispatchForm/SelectPalletsTable";
import ExportToExcel from "../../../../components/ExportToExcel";
import { copyStyles } from "../../../../utils/styles";
import ReactDOMServer from "react-dom/server";
import PrintReport from "../PrintLayout";

const generateColumns = columns =>
  columns.map(column => ({
    accessor: column.id,
    Cell: renderCell,
    ...column,
    className: `cell_${column.width} ${column.className || ""}`
  }));

const tableColumns = generateColumns(columns);

const xslsColumns = generateColumns(exelColumns);

const Sidebar = ({ dispatch }) => {
  const dispatchDate =
    dispatch &&
    isEmptyValue(moment(dispatch.dispatchedAt).format("DD/MM/YYYY"));
  const vendorName =
    dispatch && isEmptyValue(dispatch.vendor && dispatch.vendor.name);
  const orderNumber = dispatch && isEmptyValue(dispatch.orderNumber);
  const notes = dispatch && isEmptyValue(dispatch.notes);

  const sortedPalletsByIds =
    dispatch &&
    dispatch.pallets.sort((a, b) => {
      if (a.id > b.id) return 1;
      if (b.id > a.id) return -1;
      return 0;
    });

  const print = () => {
    const newWindow = window.open();
    newWindow.document.title = `Dispatch Report`;
    copyStyles(window.document, newWindow.document);

    newWindow.document.body.innerHTML = ReactDOMServer.renderToString(
      <PrintReport
        date={dispatch.dispatchedAt}
        client={dispatch.vendor.name}
        orderNumber={dispatch.orderNumber}
        pallets={dispatch.pallets}
        notes={dispatch.notes}
      />
    );
    newWindow.print();
  };

  return dispatch ? (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column width={11}>
            <Header as="h1" className={styles.name}>
              # {dispatch.id}
            </Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Header>Information</Header>
      <Grid>
        <TaskRow label="Date" name="date" value={<span>{dispatchDate}</span>} />
        <TaskRow
          label="Client/Buyer"
          name="vendor"
          value={<span>{vendorName}</span>}
        />
        <TaskRow
          label="Order number"
          name="orderNumber"
          value={<span>{orderNumber}</span>}
        />
        <TaskRow label="Notes" name="notes" value={<span>{notes}</span>} wide />
      </Grid>
      <Grid>
        <Grid.Row>
          <Grid.Column floated={"left"} width={5}>
            <Header>
              Pallets ({dispatch.pallets && dispatch.pallets.length})
            </Header>
          </Grid.Column>
          <Grid.Column floated={"right"} textAlign={"right"} width={3}>
            <ExportToExcel
              columns={xslsColumns}
              rawData={sortedPalletsByIds.map(pallet => {
                return {
                  id: pallet.id,
                  dispatchDate,
                  createDate: pallet.createDate,
                  variety: pallet.variety.name,
                  boxUnit: pallet.boxUnit.displayName,
                  area: pallet.areas
                    .map(
                      ({ area }) =>
                        `${area.parent ? `${area.parent.name} - ` : ""}${
                          area.name
                        }`
                    )
                    .join(", "),
                  registrationNumber: pallet.packingShed.registrationNumber,
                  unitsOnPallet: pallet.unitsOnPallet,
                  vendorName,
                  orderNumber,
                  notes
                };
              })}
              name={"Dispatches"}
              className={"mr-1"}
            />
            <Button onClick={print}>
              <Icon name="print" />
              <span>Print</span>
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <ExpandedTable
        data={dispatch.pallets}
        columns={tableColumns}
        className={`-striped ${tableStyles.listTable} ${styles.listTable}`}
        defaultPageSize={999999999}
        sortBy={[{ id: "id", desc: false }]}
        groupBy={[]}
        noDataText="No pallets"
      />
    </>
  ) : (
    <div>Nothing to show</div>
  );
};

Sidebar.propTypes = {
  dispatch: PropTypes.object.isRequired
};

Sidebar.defaultProps = {
  dispatch: {}
};

const mapStateToProps = state => {
  const {
    notifications: { isSending, success, error, showMessage }
  } = state;
  return { notifications: { isSending, success, error, showMessage } };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({}, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
