import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Button, Icon } from "semantic-ui-react";

import Alert from "../../components/Alert";

export const allowedTypesImages = ["image/jpeg", "image/gif", "image/png"];
const allowedTypes = [
  ...allowedTypesImages,
  "image/x-eps",
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/doc",
  ".doc",
  ".docx",
  "text/plain"
];

const OVER_SIZE = "OVER_SIZE";
const WRONG_TYPE = "WRONG_TYPE";

class FileChooser extends Component {
  fileInputRef = React.createRef();
  state = {
    showError: false,
    errorMessages: []
  };

  closeAlert = () => {
    this.setState({
      showError: false,
      errorMessages: []
    });
  };

  fileChange = event => {
    const { attachmentMaxSize, onChange } = this.props;
    const files = Array.from(event.target.files);
    const overSize = [];
    const wrongType = [];
    const errorMessages = [];
    const filesToSend = [];
    files.forEach(file => {
      let error = false;
      if (file.size > attachmentMaxSize) {
        overSize.push(file.name);
        error = true;
      }
      if (!allowedTypes.includes(file.type)) {
        wrongType.push(file.name);
        error = true;
      }
      if (!error) {
        filesToSend.push(file);
      }
    });

    if (overSize.length > 0) {
      errorMessages.push({
        type: OVER_SIZE,
        message: `The file size should be up to ${Math.ceil(
          attachmentMaxSize / (1024 * 1024)
        )} MB: ${overSize.join(", ")}`
      });
    }
    if (wrongType.length > 0) {
      errorMessages.push({
        type: WRONG_TYPE,
        message: `The file type is not allowed: ${wrongType.join(", ")}`
      });
    }

    if (errorMessages.length > 0)
      this.setState({
        showError: true,
        errorMessages
      });

    if (filesToSend.length > 0) onChange(filesToSend);
  };

  openFileSelect = event => {
    event.preventDefault();
    this.fileInputRef.current.value = "";
    this.fileInputRef.current.click();
  };

  render() {
    const { showError, errorMessages } = this.state;
    const {
      multiply,
      label,
      disabled,
      className,
      forImage = false
    } = this.props;

    return (
      <>
        <Alert
          open={showError}
          size="mini"
          onClose={this.closeAlert}
          mainContent={
            <>
              {errorMessages.map(message => (
                <p key={message.type}>{message.message}</p>
              ))}
            </>
          }
        />
        <Button
          className={classNames("button-text color-green", className)}
          onClick={this.openFileSelect}
          disabled={disabled}
        >
          {!forImage && <Icon name="linkify" />}
          {label || "Add Attachments"}
        </Button>
        <input
          ref={this.fileInputRef}
          type="file"
          hidden
          onChange={this.fileChange}
          accept={allowedTypes.join(",")}
          multiple={multiply ? "multiply" : ""}
        />
      </>
    );
  }
}

FileChooser.propTypes = {
  onChange: PropTypes.func.isRequired,
  multiply: PropTypes.bool,
  attachmentMaxSize: PropTypes.any
};

export default FileChooser;
