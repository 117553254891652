import { axiosProxy } from '../../utils/axiosProxy';
import { actionType } from '../../constants/Dispatches';

const url = '/dispatches';

export const fetchDispatchesList = () => async dispatch => {
  try {
    dispatch({
      type: actionType.FETCH_DISPATCHES_LIST_START,
    });

    const res = await axiosProxy({
      method: 'GET',
      url: `${url}`,
      params: {
        unpaged: true,
      },
    });

    dispatch({
      type: actionType.FETCH_DISPATCHES_LIST,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: actionType.FETCH_DISPATCHES_LIST_FAIL,
      payload: error,
    });
  }
};

export const fetchDispatches = ({ page, size, search, sort, unpaged = false }) => async dispatch => {
  try {
    dispatch({
      type: actionType.FETCH_DISPATCHES_START,
    });

    const params = new URLSearchParams();
    if (unpaged) {
      params.append('unpaged', true);
    } else {
      params.append('page', page);
      params.append('size', size);
    }

    if (search) {
      params.append('search', search);
    }

    (sort || ['id,asc']).forEach(field => {
      params.append('sort', field);
    });

    const res = await axiosProxy({
      method: 'GET',
      params,
      url,
    });

    await dispatch({
      type: actionType.FETCH_DISPATCHES,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.FETCH_DISPATCHES_FAIL,
      payload: error,
    });
  }
};

export const getDispatchById = id => async dispatch => {
  try {
    dispatch({
      type: actionType.GET_DISPATCH_BY_ID_START,
    });
    const res = await axiosProxy({
      method: 'GET',
      url: `${url}/${id}`,
    });

    dispatch({
      type: actionType.GET_DISPATCH_BY_ID,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.GET_DISPATCH_BY_ID_FAIL,
      payload: error,
    });
    return Promise.reject(error);
  }
};

export const addDispatch = data => async dispatch => {
  try {
    dispatch({
      type: actionType.ADD_DISPATCH_START,
    });
    const res = await axiosProxy({
      method: 'POST',
      url,
      data,
    });

    await dispatch({
      type: actionType.ADD_DISPATCH,
      payload: res.data,
    });

    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.ADD_DISPATCH_FAIL,
      payload: error,
    });
    return { error };
  }
};

export const updateDispatch = (id, data) => async dispatch => {
  try {
    dispatch({
      type: actionType.UPDATE_DISPATCH_START,
    });
    const res = await axiosProxy({
      method: 'PUT',
      data,
      url: `${url}/${id}`,
    });

    await dispatch({
      type: actionType.UPDATE_DISPATCH,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.UPDATE_DISPATCH_FAIL,
      payload: error,
    });
    return { error };
  }
};

export const deleteDispatch = id => async dispatch => {
  try {
    dispatch({
      type: actionType.DELETE_DISPATCH_START,
    });
    await axiosProxy({
      method: 'DELETE',
      url: `${url}/${id}`,
    });

    await dispatch({
      type: actionType.DELETE_DISPATCH,
      payload: id,
    });
  } catch (error) {
    dispatch({
      type: actionType.DELETE_DISPATCH_FAIL,
      payload: error,
    });
  }
};

export const clearError = () => dispatch => {
  dispatch({
    type: actionType.CLEAR_DISPATCHES_ERROR,
  });
};
