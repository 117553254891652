import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Dropdown } from "semantic-ui-react";
import { setPrintLayout } from "../../../../../../actions/Pallets/pallets";
import PalletsLabelPrintLayout from "../../PalletsLabelPrintLayout";
import A4_2_Labels_210mm_148mm_2_labels_per_pallet from "../../PalletsLabelPrintLayout/layouts/A4_2_Labels_210mm_148mm_2_labels_per_pallet.module.css";
import page_80mm_48mm_1_label_per_page from "../../PalletsLabelPrintLayout/layouts/page_80mm_48mm_1_label_per_page.module.css";
import page_102mm_99mm_1_label_per_page from "../../PalletsLabelPrintLayout/layouts/page_102mm_99mm_1_label_per_page.module.css";

export const PrintLayouts = {
  "A4 2 Labels (210mm x 148mm) 2 labels per pallet": {
    html: {
      render: pallets => (
        <PalletsLabelPrintLayout
          pallets={pallets}
          itemsInPage={2}
          duplicatePalletLabel={2}
          className={A4_2_Labels_210mm_148mm_2_labels_per_pallet.layout}
          pageStyle={"@page {size: A4 portrait;}"}
        />
      )
    }
  },
  "1 Labels (80mm x 48mm)": {
    html: {
      render: pallets => (
        <PalletsLabelPrintLayout
          pallets={pallets}
          itemsInPage={1}
          duplicatePalletLabel={1}
          className={page_80mm_48mm_1_label_per_page.layout}
          pageStyle={""}
        />
      )
    }
  },
  "2 Labels (102mm x 99mm)": {
    html: {
      render: pallets => (
        <PalletsLabelPrintLayout
          withoutNotes={true}
          pallets={pallets}
          itemsInPage={2}
          duplicatePalletLabel={2}
          className={page_102mm_99mm_1_label_per_page.layout}
          pageStyle={"@page {size: A4 portrait;}"}
        />
      )
    }
  }
};

export const OPTIONS = Object.keys(PrintLayouts).map(layout => ({
  key: layout,
  text: layout,
  value: layout
}));

const SelectPrintLayout = ({
  printLayout,
  actions: { setPrintLayout },
  ...extraProps
}) => {
  return (
    <Dropdown
      {...extraProps}
      value={printLayout}
      placeholder="Select print layout"
      selection
      fluid
      options={OPTIONS}
      onChange={(_, data) => setPrintLayout(data.value)}
    />
  );
};

SelectPrintLayout.propTypes = {
  actions: PropTypes.object,
  printLayout: PropTypes.any
};

SelectPrintLayout.defaultProps = {};

const mapStateToProps = state => {
  const {
    pallets: { printLayout }
  } = state;
  return {
    printLayout
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ setPrintLayout }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectPrintLayout);
