import { actionType } from '../../constants/WeatherStation';

export const dataGroups = {
  RAW: 'raw',
  DAILY: 'daily',
  HOURLY: 'hourly',
  MONTHLY: 'monthly',
};

const initialState = {
  data: [],
  list: {
    data: [],
    isFetching: false,
  },
  isFetching: false,
  error: null,
  stationData: {
    chartsOptions: [],
    grid: {
      data: [],
      headers: [],
    },
    isFetching: false,
    error: null,
    dataGroup: null,
    showCharts: true,
    showStationDataTable: true,
  },
};

export default (state = initialState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case actionType.WEATHER_STATION_CLEAR_STATE:
      return {
        ...state,
        stationData: {
          ...initialState.stationData,
        },
      };
    case actionType.WEATHER_STATION_GET_DATA_BETWEEN_START:
      return {
        ...state,
        stationData: { ...state.stationData, isFetching: true },
      };
    case actionType.WEATHER_STATION_GET_DATA_BETWEEN:
      return {
        ...state,
        stationData: { ...state.stationData, ...action.payload, isFetching: false },
      };
    case actionType.WEATHER_STATION_GET_DATA_BETWEEN_FAIL:
      return {
        ...state,
        stationData: {
          ...state.stationData,
          isFetching: false,
          error: action.payload,
        },
      };
    case actionType.WEATHER_STATION_SET_STATION_DATA_FIELD:
      return {
        ...state,
        stationData: {
          ...state.stationData,
          ...action.payload,
        },
      };

    case actionType.FETCH_WEATHER_STATIONS_LIST_START: {
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: true,
        },
      };
    }
    case actionType.FETCH_WEATHER_STATIONS_START: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case actionType.FETCH_WEATHER_STATIONS: {
      return {
        ...state,
        data: {
          ...action.payload,
        },
        isFetching: false,
      };
    }
    case actionType.FETCH_WEATHER_STATIONS_LIST: {
      return {
        ...state,
        list: {
          data: [...action.payload],
          isFetching: false,
        },
        isFetching: false,
      };
    }
    case actionType.ADD_WEATHER_STATION: {
      const newTotalElements = state.data.totalElements + 1;
      const dataContent = [action.payload, ...state.data.content];

      return {
        ...state,
        data: {
          ...state.data,
          content: dataContent,
          totalElements: newTotalElements,
          totalPages: Math.ceil(newTotalElements / state.data.size),
        },
        list: {
          ...state.list,
          data: [action.payload, ...state.list.data],
        },
      };
    }
    case actionType.UPDATE_WEATHER_STATION: {
      return {
        ...state,
        data: {
          ...initialState.data,
          content: state.data.content.map(weatherStation => {
            if (weatherStation.id === action.payload.id) {
              return action.payload;
            }
            return weatherStation;
          }),
        },
        list: {
          ...state.list,
          data: state.list.data.map(weatherStation => {
            if (weatherStation.id === action.payload.id) {
              return action.payload;
            }
            return weatherStation;
          }),
        },
      };
    }
    case actionType.DELETE_WEATHER_STATION: {
      const newTotalElements = state.data.totalElements - 1;

      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.filter(e => e.id !== action.payload),
          totalElements: newTotalElements,
          totalPages: Math.ceil(newTotalElements / state.data.size),
        },
        list: {
          ...state.list,
          data: state.list.data.filter(e => e.id !== action.payload),
        },
      };
    }

    case actionType.FETCH_WEATHER_STATIONS_FAIL:
    case actionType.ADD_WEATHER_STATION_FAIL:
    case actionType.UPDATE_WEATHER_STATION_FAIL:
    case actionType.DELETE_WEATHER_STATION_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case actionType.FETCH_WEATHER_STATIONS_LIST_FAIL: {
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: false,
        },
        error: action.payload,
      };
    }
    case actionType.WEATHER_STATION_CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
