import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "react-table-6/react-table.css";
import ListHolder from "../../../components/ListHolder";
import { abilitiesSelector } from "../../../selectors/user";
import {
  deletePackedBoxes,
  fetchPackedBoxes
} from "../../../actions/PackedBoxes/packedBoxes";
import { Area } from "../../../models/block.model";
import moment from "moment";
import Sidebar from "./Sidebar";
import { pageSize } from "../../../components/ListTable/Pagination";
import { setHashParameter } from "utils/hashToObject";

const PAGE_SIZE = 10;

class PackedBoxes extends Component {
  state = {
    tableData: [],
    tableColumns: [],
    sideBarShown: false,
    activeItemId: null
  };

  componentDidMount() {
    const defaultSeason = this.props.settings.data.seasons?.reduce(function(
      prev,
      current
    ) {
      return prev.startAt > current.startAt ? prev : current;
    },
    {});
    const defaultDate = [defaultSeason?.startAt, defaultSeason?.endAt];

    this.prepareData();
    defaultSeason?.startAt &&
      defaultSeason?.endAt &&
      setHashParameter(this.props.location, "actions", defaultDate, null);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { data } = this.props;
    if (prevProps.data !== data) this.prepareData();
  }

  prepareData = () => {
    const { data } = this.props;
    const columnsNames = {
      barcodeId: {
        title: "id",
        width: 12
      },
      scannedAt: {
        title: "Date",
        accessor: d => d.scannedAt && moment(d.scannedAt).format("DD/MM/YYYY"),
        width: 8,
        filter: {
          type: "DateRangeFilter"
        }
      },
      employee: {
        title: "Employee",
        accessor: d =>
          d.employee && `${d.employee.firstName} ${d.employee.lastName}`,
        width: 8,
        sortFields: ["employee.firstName", "employee.lastName"],
        filter: {
          type: "EmployeeMultiSelectFilter"
        }
      },
      contractor: {
        title: "Contractor",
        accessor: d =>
          d.employee &&
          d.employee.contractor &&
          `${d.employee.contractor.name}`,
        width: 8,
        sortFields: ["employee.contractor.name"],
        filter: {
          type: "ContractorMultiSelectFilter"
        }
      },
      area: {
        title: "Area",
        accessor: d =>
          d.area &&
          `${d.area.parent ? `${d.area.parent.name} - ` : ""}${d.area.name}`,
        width: 8,
        sortFields: ["area.parent.name", "area.name"],
        filter: {
          type: "AreaMultiSelectFilter"
        }
      },
      variety: {
        title: "Variety",
        accessor: d => {
          const variety = new Area(d.area).areaVarietyByDate(d.scannedAt);
          return variety && variety.crop && variety.name;
        },
        width: 8,
        sortFields: ["variety.name"],
        filter: {
          type: "UsedVarietyMultiSelectFilter"
        }
      },
      boxUnit: {
        title: "Box unit",
        accessor: d => d.boxUnit && d.boxUnit.displayName,
        width: 8,
        sortFields: ["boxUnit.displayName"],
        filter: {
          type: "BoxUnitMultiSelectFilter"
        }
      },
      pickingPrice: {
        title: "Picking price",
        accessor: d => d.pickingPrice && `$${d.pickingPrice}`,
        width: 8
      },
      paddingPrice: {
        title: "Padding price",
        accessor: d => d.paddingPrice && `$${d.paddingPrice}`,
        width: 8
      },
      cartingPrice: {
        title: "Crating  price",
        accessor: d => d.cartingPrice && `$${d.cartingPrice}`,
        width: 8
      },
      actions: {
        title: "",
        width: "auto",
        accessor: d => d.id,
        className: "textRight actions",
        filter: {
          type: "SeasonSingleSelectFilter"
        }
      }
    };
    const tableColumns = [];
    const tableData = [];

    Object.keys(columnsNames).forEach(column => {
      const targetColumn = columnsNames[column];
      const columnToAdd = {
        id: column,
        accessor: targetColumn.accessor || column,
        Header: targetColumn.title,
        Cell: this.renderCell,
        className: `cell_${targetColumn.width} ${targetColumn.className || ""}`,
        ...(targetColumn.sortFields
          ? { sortFields: targetColumn.sortFields }
          : {}),
        disableSort: targetColumn.disableSort,
        filter: targetColumn.filter
      };

      tableColumns.push(columnToAdd);
    });

    if (data && data.content) {
      data.content.forEach(row => {
        const rowData = {
          rights: {
            update: "update",
            delete: "delete"
          }
        };
        Object.keys(row).forEach(key => {
          switch (key) {
            default:
              rowData[key] = row[key] || "";
              break;
          }
        });
        tableData.push(rowData);
      });
    }

    this.setState({
      tableData,
      tableColumns
    });
  };

  renderCell = props => {
    const {
      value,
      column: { Header }
    } = props;

    return (
      <div>
        <div className="cellTitle hide-md">{Header}</div>
        <div className="cellValue">{value}</div>
      </div>
    );
  };

  setActiveItem = activeItemId => {
    this.setState({
      activeItemId
    });
  };

  render() {
    const {
      settings,
      route,
      location,
      currentPage,
      totalPages,
      isFetching,
      Can,
      fetchAction,
      size,
      totalElements,
      deleteAction,
      error,
      online,
      data: { content }
    } = this.props;
    const { tableData, tableColumns, activeItemId } = this.state;
    const expandedContent =
      activeItemId && content.find(e => e.id === activeItemId);
    const editLink = expandedContent
      ? `/harvest/packed_boxes/edit/${expandedContent.id}`
      : null;
    return (
      <ListHolder
        filterName="actions"
        accessName="packed_boxes"
        Can={Can}
        addNewPath={"/harvest/packed_boxes/scan"}
        editPath={"/harvest/packed_boxes/edit"}
        updateAction="update"
        editLink={editLink}
        addButtonLabel={"Scan"}
        route={route}
        location={location}
        currentPage={currentPage}
        size={pageSize(size, PAGE_SIZE)}
        totalPages={totalPages}
        rawData={content}
        data={tableData}
        columns={tableColumns}
        getData={fetchAction}
        delData={deleteAction}
        isFetching={isFetching && !totalPages}
        totalElements={totalElements}
        withDateRange={false}
        setActiveItem={this.setActiveItem}
        error={error}
        online={online}
        defaultSort="scannedAt,desc"
        withSelection={true}
        batchActionPath={"/harvest/packed_boxes/batch_edit"}
        batchAction={"update"}
        batchActionButtonLabel={"Edit"}
        keyField={"id"}
      >
        <Sidebar
          id={expandedContent && expandedContent.id}
          packedBox={expandedContent}
          Can={Can}
        />
      </ListHolder>
    );
  }
}

PackedBoxes.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  totalElements: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  fetchAction: PropTypes.func.isRequired,
  deleteAction: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  lastLoaded: PropTypes.bool,
  data: PropTypes.object,
  error: PropTypes.object,
  route: PropTypes.object,
  location: PropTypes.object,
  Can: PropTypes.func,
  online: PropTypes.bool
};

const mapStateToProps = state => {
  const {
    packedBoxes: {
      isFetching,
      data,
      data: { content, number, totalPages, totalElements, size, last },
      error
    },
    settings,
    offline: { online }
  } = state;
  return {
    data,
    settings,
    content,
    isFetching,
    currentPage: number,
    totalPages,
    totalElements,
    size,
    lastLoaded: last,
    Can: abilitiesSelector(state),
    error,
    online
  };
};

const mapDispatchToProps = {
  fetchAction: fetchPackedBoxes,
  deleteAction: deletePackedBoxes
};

export default connect(mapStateToProps, mapDispatchToProps)(PackedBoxes);
