import React from "react";
import { Form, Grid, Header, Dropdown, Input } from "semantic-ui-react";
import DatePicker from "components/DatePicker";
import AssigneesDropdown from "./components/AssigneesDropdown";
import AdditionalInformation from "./components/AdditionalInformation";
import { useFormikContext, FastField } from "formik";
import ErrorLabel from "../../../../components/ErrorLabel";
import { toStartDateString } from "../../../../utils/dateUtils";
import {
  APPLICATION_TYPES,
  URGENCY_LEVELS
} from "constants/Recommendations/types";
import { objectToSelectOptions } from "utils/objectUtils";
import ScoutingTaskDropdown from "./components/ScoutingTaskDropdown";
import TaskTemplateDropdown from "./components/TaskTemplateDropdown";
import "./Sidebar.module.css";

const applicationTypeOptions = objectToSelectOptions(APPLICATION_TYPES);
const urgencyLevelOptions = objectToSelectOptions(URGENCY_LEVELS);

const Sidebar = () => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column className="spraySection">
          <Header as="h2" className="title">
            Task Information
          </Header>
        </Grid.Column>
      </Grid.Row>
      <ScoutingTaskDropdown />
      <Grid.Row>
        <Grid.Column>
          <Form.Field required>
            <FastField name="plannedDate">
              {({ field, meta }) => (
                <DatePicker
                  {...field}
                  required
                  position="right"
                  disableClear
                  error={meta.touched && meta.error && !field.value}
                  errorLabel={ErrorLabel}
                  value={values.plannedDate}
                  name="plannedDate"
                  onChange={newValue => {
                    setFieldValue("plannedDate", toStartDateString(newValue));
                  }}
                  labelText="Planned date"
                  onBlur={() => setFieldTouched(field.name, true)}
                />
              )}
            </FastField>
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <DatePicker
            position="right"
            disableClear
            errorLabel={ErrorLabel}
            value={values.expiryDate}
            onChange={newValue => {
              setFieldValue("expiryDate", toStartDateString(newValue));
            }}
            labelText="Expiry date"
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Form.Field required>
            <FastField
              as={Form.Dropdown}
              selection
              label="Application type"
              fluid
              name="applicationType"
              onChange={(_, { value }) => {
                setFieldValue("applicationType", value);
              }}
              options={applicationTypeOptions}
            />
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Form.Field required>
            <FastField
              as={Form.Dropdown}
              selection
              label="Urgency level"
              fluid
              name="urgencyLevel"
              onChange={(_, { value }) => {
                setFieldValue("urgencyLevel", value);
              }}
              options={urgencyLevelOptions}
            />
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Form.Field required>
            <label>Assignees</label>
            <AssigneesDropdown
              type="Assignees"
              fieldName="assignees"
              multiple
              showArchived={false}
            />
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <TaskTemplateDropdown />
      <Grid.Row>
        <Grid.Column>
          <Form.Field>
            <FastField
              label="Task name"
              placeholder="Task name"
              fluid
              name="name"
              as={Form.Input}
            />
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <AdditionalInformation />
    </Grid>
  );
};

export default Sidebar;
