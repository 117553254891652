import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "react-table-6/react-table.css";
import { Grid, Header, Segment, Sidebar } from "semantic-ui-react";

import { abilitiesSelector } from "../../../../../selectors/user";
import { fetchPackedBoxes } from "../../../../../actions/PackedBoxes/packedBoxes";
import { Area } from "../../../../../models/block.model";
import moment from "moment";
import Layout from "../../../../../components/Layout";
import styles from "../../../../../components/ListHolder/ListHolder.module.css";
import ExpandedLocalDataTable from "../../../../../components/ExpandedLocalDataTable";
import Blank from "../../../../Blank";
import { bindActionCreators } from "redux";
import Numeric from "../../../../../components/Numeric";
import {
  singleOrUniqueCount,
  singleOrUniqueCountAggregated,
  singleOrUniqueCountAggregatedValue
} from "../../../../../components/ExpandedTable/aggregations";
import {
  includes,
  includesVariety
} from "../../../../../components/ExpandedTable/filters";
import { ExtContractorFilter } from "../../../../../components/ExpandedLocalDataTable/externalFilters";

const renderMoneyCell = props => {
  const {
    value,
    column: { Header }
  } = props;

  return (
    <div>
      <div className="cellTitle hide-md hide-print">{Header}</div>
      <div className="cellValue">
        <Numeric value={value} units={"$"} fractionDigits={2} />
      </div>
    </div>
  );
};

renderMoneyCell.propTypes = {
  column: PropTypes.object,
  value: PropTypes.any
};

const columns = [
  {
    id: "id",
    Header: "Id",
    girdColumnsWidth: 12,
    aggregate: "uniqueCount",
    aggregateValue: "sum",
    Aggregated: ({ cell: { value } }) => `${value} boxes`
  },
  {
    id: "scannedAt",
    Header: "Date",
    accessor: d => d.scannedAt && moment(d.scannedAt).format("DD/MM/YYYY"),
    girdColumnsWidth: 8,
    aggregate: singleOrUniqueCount,
    aggregateValue: singleOrUniqueCountAggregatedValue,
    Aggregated: s => singleOrUniqueCountAggregated(s, "dates")
  },
  {
    id: "employee",
    Header: "Employee",
    accessor: d =>
      d.employee && `${d.employee.firstName} ${d.employee.lastName}`,
    girdColumnsWidth: 8,
    sortFields: ["employee.firstName", "employee.lastName"],
    aggregate: singleOrUniqueCount,
    aggregateValue: singleOrUniqueCountAggregatedValue,
    Aggregated: s => singleOrUniqueCountAggregated(s, "employees"),
    filter: includes("employee.id")
  },
  {
    id: "contractor",
    Header: "Contractor",
    accessor: d =>
      d.employee && d.employee.contractor
        ? `${d.employee.contractor.name}`
        : "-",
    girdColumnsWidth: 8,
    sortFields: ["employee.contractor.name"],
    aggregate: singleOrUniqueCount,
    aggregateValue: singleOrUniqueCountAggregatedValue,
    Aggregated: s => singleOrUniqueCountAggregated(s, "contractors"),
    ExternalFilter: ExtContractorFilter,
    filter: includes("employee.contractor.id")
  },
  {
    id: "area",
    Header: "Area",
    accessor: d =>
      d.area &&
      `${d.area.parent ? `${d.area.parent.name} - ` : ""}${d.area.name}`,
    girdColumnsWidth: 8,
    sortFields: ["area.parent.name", "area.name"],
    aggregate: singleOrUniqueCount,
    aggregateValue: singleOrUniqueCountAggregatedValue,
    Aggregated: s => singleOrUniqueCountAggregated(s, "areas"),
    filter: includes("area.id")
  },
  {
    id: "variety",
    Header: "Variety",
    accessor: d => {
      const variety = new Area(d.area).areaVarietyByDate(d.scannedAt);
      return variety && variety.name;
    },
    girdColumnsWidth: 8,
    aggregate: singleOrUniqueCount,
    aggregateValue: singleOrUniqueCountAggregatedValue,
    Aggregated: s => singleOrUniqueCountAggregated(s, "varieties"),
    filter: includesVariety("area", "scannedAt")
  },
  {
    id: "boxUnit",
    Header: "Box unit",
    accessor: d => d.boxUnit && d.boxUnit.displayName,
    girdColumnsWidth: 8,
    sortFields: ["boxUnit.displayName"],
    aggregate: singleOrUniqueCount,
    aggregateValue: singleOrUniqueCountAggregatedValue,
    Aggregated: s => singleOrUniqueCountAggregated(s, "box units"),
    filter: includes("boxUnit.id")
  },
  {
    id: "price",
    Header: "Price",
    accessor: d =>
      (d.pickingPrice || 0) + (d.paddingPrice || 0) + (d.cartingPrice || 0),
    Cell: renderMoneyCell,
    girdColumnsWidth: 8,
    aggregate: "sum",
    // eslint-disable-next-line react/prop-types
    Aggregated: ({ cell: { value } }) => (
      <Numeric value={value} units={"$"} fractionDigits={2} />
    )
  }
];

const defaultGroupBy = ["contractor", "employee", "boxUnit"];

const PickerAndVarietyReport = ({
  route,
  location,
  content,
  isFetching,
  Can,
  actions: { fetchAction }
}) => {
  return (
    <>
      <Can not I={"access_module"} a={"harvest"}>
        <Blank route={route} location={location} />
      </Can>
      <Can I={"access_module"} a={"harvest"}>
        <Can I={"view"} a={"packed_boxes"}>
          <Layout
            route={route}
            location={location}
            classForMain={styles.mainHolder}
          >
            <Sidebar.Pushable className={`${styles.farmsHolder}`}>
              <Sidebar.Pusher>
                <Segment basic className={styles.headerTasks}>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={8}>
                        <Header as="h2" className={styles.taskName}>
                          {route.name}
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
                <Segment>
                  <ExpandedLocalDataTable
                    name={"PickerAndVarietyReport"}
                    printTitle={"Picker and variety report"}
                    columns={columns}
                    data={content}
                    getData={fetchAction}
                    loading={isFetching}
                    showFilters={false}
                    defaultSort="scannedAt,desc"
                    withDateRange={true}
                    exportableToExcel={true}
                    defaultGroupBy={defaultGroupBy}
                    printable={true}
                  />
                </Segment>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
          </Layout>
        </Can>
      </Can>
    </>
  );
};

PickerAndVarietyReport.propTypes = {
  location: PropTypes.object,
  content: PropTypes.array,
  route: PropTypes.object,
  actions: PropTypes.object,
  totalPages: PropTypes.number.isRequired,
  isFetching: PropTypes.bool.isRequired,
  Can: PropTypes.func
};

const mapStateToProps = state => {
  const {
    packedBoxes: {
      isFetching,
      data: { content, totalPages }
    }
  } = state;
  return {
    content,
    isFetching,
    totalPages,
    Can: abilitiesSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchAction: fetchPackedBoxes
    },
    dispatch
  )
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PickerAndVarietyReport);
