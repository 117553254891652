export const actionType = {
  FETCH_SETTINGS_START: "SETTINGS/FETCH_SETTINGS_START",
  FETCH_SETTINGS: "SETTINGS/FETCH_SETTINGS",
  FETCH_SETTINGS_FAIL: "SETTINGS/FETCH_SETTINGS_FAIL",

  UPLOAD_SETTING_ATTACHMENT_START: "SETTINGS/UPLOAD_SETTING_ATTACHMENT_START",
  UPLOAD_SETTING_ATTACHMENT: "SETTINGS/UPLOAD_SETTING_ATTACHMENT",
  UPLOAD_SETTING_ATTACHMENT_ERROR: "SETTINGS/UPLOAD_SETTING_ATTACHMENT_ERROR",

  ADD_SETTINGS_START: "SETTINGS/ADD_SETTINGS_START",
  ADD_SETTINGS: "SETTINGS/ADD_SETTINGS",
  ADD_SETTINGS_FAIL: "SETTINGS/ADD_SETTINGS_FAIL"
};
