import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "../Farms/Farms.module.css";
import Layout from "../../components/Layout";
import { Confirm, Grid, Header, Input, Radio } from "semantic-ui-react";
import QualityCheck from "./components/QualityCheck";
import style from "./QCFarm.module.css";
import { bindActionCreators } from "redux";
import { fetchQCFroms } from "../../actions/QualityControl/forms";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Pagination from "../../components/ListTable/Pagination";
import { onlineSelector } from "../../selectors/common";

const QualityControl = props => {
  const {
    actions: { fetchQCFroms },
    qcForms = [],
    route,
    location,
    online,
    pagination
  } = props;

  const [showConfirm, toggleConfirm] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("lol");
  const [showArchived, toggleArchived] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);

  useEffect(() => {
    fetchQCFroms({
      page: pageNumber,
      size: pageSize,
      search: searchQuery.length ? searchQuery : false,
      showArchived: showArchived
    });
  }, [fetchQCFroms, pageNumber, pageSize, searchQuery, showArchived]);

  const hideConfirm = () => {
    setConfirmMessage("");
    toggleConfirm(false);
  };

  const onConfirmApproved = () => hideConfirm();

  const itemsCount = {
    itemFrom: pagination.number * pageSize + 1,
    itemTo: Math.min(
      pagination.number * pageSize + 1 + pageSize - 1,
      pagination.totalElements
    )
  };

  return (
    <Layout route={route} location={location} classForMain={styles.mainHolder}>
      <Confirm
        open={showConfirm}
        content={confirmMessage}
        onCancel={hideConfirm}
        onConfirm={onConfirmApproved}
        confirmButton="Yes"
      />
      <div className={style.p1em}>
        <Header as="h2">{props.route.name}</Header>
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} computer={5} largeScreen={5}>
              <Input
                icon="search"
                placeholder="Search"
                fluid
                value={searchQuery}
                onChange={e => setSearchQuery(e.target.value)}
                size={"large"}
              />
            </Grid.Column>
            <Grid.Column mobile={16} computer={5} largeScreen={5}>
              <span className={styles.archiveToggle}>
                <span>Show archived</span>
                <Radio
                  toggle
                  checked={showArchived}
                  onChange={() => toggleArchived(!showArchived)}
                />
              </span>
            </Grid.Column>
            <Grid.Column
              floated={"right"}
              mobile={16}
              computer={3}
              largeScreen={3}
              textAlign={"right"}
            >
              <Link to={"/harvest/qc/form"} className={"ui primary button"}>
                Create new checklist
              </Link>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {qcForms.map((form, i) => (
          <QualityCheck form={form} key={`qc_form_${i}`} />
        ))}
        <Pagination
          currentPage={pageNumber}
          totalElements={pagination.totalElements}
          pageSize={10}
          online={online}
          itemsCount={itemsCount}
          onPageChangeOwn={pageNumber => setPageNumber(pageNumber)}
          pages={pagination.totalPages}
          updatePageSize={(_, data) => {
            setPageSize(data.value);
            setPageNumber(0);
          }}
          pageSizeOptions={[10, 20, 50, 100]}
        />
      </div>
    </Layout>
  );
};

QualityControl.propTypes = {
  actions: PropTypes.object,
  route: PropTypes.object,
  location: PropTypes.object,
  pagination: PropTypes.object,
  online: PropTypes.bool,
  qcForms: PropTypes.array
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchQCFroms }, dispatch)
});

const mapStateToProps = ({ qualityControl }) => ({
  isFetching: qualityControl.isFetching,
  qcForms: qualityControl.data.content,
  online: onlineSelector,
  pagination: {
    ...qualityControl.data
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(QualityControl);
