export const actionType = {
  FETCH_EMPLOYEE_GROUPS_START: "EMPLOYEE_GROUPS/FETCH_EMPLOYEE_GROUPS_START",
  FETCH_EMPLOYEE_GROUPS: "EMPLOYEE_GROUPS/FETCH_EMPLOYEE_GROUPS",
  FETCH_EMPLOYEE_GROUPS_ERROR: "EMPLOYEE_GROUPS/FETCH_EMPLOYEE_GROUPS_ERROR",

  FETCH_EMPLOYEE_GROUPS_LIST_START:
    "EMPLOYEE_GROUPS_LIST/FETCH_EMPLOYEE_GROUPS_LIST_START",
  FETCH_EMPLOYEE_GROUPS_LIST: "EMPLOYEE_GROUPS_LIST/FETCH_EMPLOYEE_GROUPS_LIST",
  FETCH_EMPLOYEE_GROUPS_LIST_ERROR:
    "EMPLOYEE_GROUPS_LIST/FETCH_EMPLOYEE_GROUPS_LIST_ERROR",

  CLEAR_EMPLOYEE_GROUPS_ERROR: "EMPLOYEE_GROUPS/CLEAR_EMPLOYEE_GROUPS_ERROR"
};
