import { axiosProxy } from '../../utils/axiosProxy';
import { actionType } from '../../constants/Notifications';

const url = '/notifications';

export const sendNotification = (employeeId, webPushNotification) => async dispatch => {
  const currUrl = `${url}/notify/${employeeId}`;
  try {
    dispatch({
      type: actionType.SEND_NOTIFICATION_START,
    });
    const res = await axiosProxy({
      method: 'POST',
      data: webPushNotification,
      url: currUrl,
    });
    await dispatch({
      type: actionType.SEND_NOTIFICATION,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.SEND_NOTIFICATION_FAIL,
      payload: error,
    });
  }
};

export const closeMessage = () => async dispatch => {
  dispatch({
    type: actionType.SEND_NOTIFICATION_CLOSE_MESSAGE,
  });
};
