import React, { useEffect, useState } from "react";
import { Icon } from "semantic-ui-react";

const slideStyles = {
  width: "100%",
  height: "85%",
  borderRadius: "10px",
  backgroundSize: "cover",
  backgroundPosition: "center"
};

const rightArrowStyles = {
  position: "absolute",
  top: "50%",
  transform: "translate(0, -50%)",
  right: "10px",
  fontSize: "45px",
  color: "#fff",
  zIndex: 1,
  cursor: "pointer",
  color: "#666666",
  width: "2%",
  fontSize: "15px"
};

const leftArrowStyles = {
  position: "absolute",
  top: "50%",
  transform: "translate(0, -50%)",
  left: "8px",
  fontSize: "45px",
  color: "#fff",
  zIndex: 1,
  cursor: "pointer",
  color: "#666666",
  width: "2%",
  fontSize: "15px"
};

const sliderStyles = {
  position: "relative",
  height: "50vh",
  overflow: "hidden",
  width: "96%"
};

const dotsContainerStyles = {
  display: "flex",
  justifyContent: "center"
};

const dotStyle = {
  margin: "0 3px",
  cursor: "pointer",
  fontSize: "20px",
  marginTop: "15px"
};
const wrapperStyle = {
  display: "flex",
  justifyContent: "center"
};

const image = {
  width: "30px",
  height: "30px"
};

const ImageSlider = ({ slides, setActiveUrl }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };
  const goToNext = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };
  const goToSlide = slideIndex => {
    setCurrentIndex(slideIndex);
  };
  const slideStylesWidthBackground = {
    ...slideStyles,
    backgroundImage: `url(${slides[currentIndex].url})`
  };
  useEffect(() => {
    setActiveUrl(slides[currentIndex]);
  });
  return (
    <div style={wrapperStyle}>
      <div onClick={goToPrevious} style={leftArrowStyles}>
        <Icon name="angle left  " />
      </div>
      <div style={sliderStyles}>
        <div></div>
        <div style={slideStylesWidthBackground}></div>
        <div style={dotsContainerStyles}>
          {slides.map((slide, slideIndex) => (
            <div
              style={dotStyle}
              key={slideIndex}
              onClick={() => goToSlide(slideIndex)}
            >
              <img style={image} src={slide.url} />
            </div>
          ))}
        </div>
      </div>
      <div onClick={goToNext} style={rightArrowStyles}>
        <Icon name="angle right  " />
      </div>
    </div>
  );
};

export default ImageSlider;
