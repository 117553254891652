import { createSelector } from "reselect";
import get from "lodash/get";
import { chemicalsList } from "selectors/chemicals";

const task = state => state.sprayDiary;
const sheds = state => state.shed;
export const shedsList = state => state.shed.data;
export const farmsList = state => state.farms;
export const typeList = state => state.inventoryMovements.type;
export const shedList = state => state.shed.list;

const shedsTypesSelector = createSelector([task], sprayDiary => {
  const {
    shedsTypes: { data }
  } = sprayDiary;
  return data
    ? data.map(type => ({ key: type.id, text: type.type, value: type.id }))
    : [];
});

const dilutionRateTypesSelector = createSelector([task], sprayDiary => {
  const { dilutionRateTypes } = sprayDiary;
  return dilutionRateTypes
    ? Object.keys(dilutionRateTypes).map(type => ({
        key: type,
        text: `/${dilutionRateTypes[type]}`,
        value: type
      }))
    : [];
});

const periodUnitsSelector = createSelector([task], sprayDiary => {
  const { periodUnits } = sprayDiary;
  return periodUnits
    ? Object.keys(periodUnits).map(type => ({
        key: type,
        text: periodUnits[type],
        value: type
      }))
    : [];
});

const selectedShedsSelector = createSelector([task], sprayDiary => {
  const { selectedSheds } = sprayDiary;
  return selectedSheds.filter(shed => shed);
});

export const shedsListOptionsSelector = createSelector([sheds], ({ list }) =>
  list.content
    ? list.content.map(({ id, name }) => ({
        key: id,
        text: name,
        value: id
      }))
    : []
);
export const matterOptionsSelector = createSelector([sheds], shedsData => {
  const { filters } = shedsData;
  return { options: filters.matterOptions };
});

export const trackShedOptionsSelector = createSelector([sheds], shedsData => {
  const { filters } = shedsData;

  return { options: filters.trackShedOptions };
});

export const shedTypeOptionsSelector = createSelector([sheds], shedsData => {
  const { filters } = shedsData;
  return {
    isFetching: filters.isFetching,
    options: filters.shedTypeOptions
  };
});

export const unitsSelector = createSelector([sheds], shedsData => {
  const { units } = shedsData;

  return units;
});

export const activeItemSelector = createSelector(
  [sheds, chemicalsList, farmsList],
  (shedsData, chemicals, farmsData) => {
    const { activeItem, data } = shedsData;
    const { summary } = farmsData;
    if (activeItem.id) {
      const activeShed = data.content.find(({ id }) => activeItem.id == id);
      return {
        ...activeShed,
        buckets: activeShed.buckets.map(b => {
          const currentChemical = chemicals.content.find(
            ({ id }) => id === b.chemicalId
          );
          return {
            ...b,
            name: get(currentChemical, "name"),
            quantity: b.currentQuantity,
            chemicalTypeId: get(currentChemical, "type.id"),
            matter: get(currentChemical, "matter"),
            unit: get(currentChemical, "unit")
          };
        }),
        thresholds: activeShed.thresholds.map(t => {
          const currentChemical = chemicals.content.find(
            ({ id }) => id === t.chemicalId
          );
          return {
            ...t,
            name: get(currentChemical, "name")
          };
        }),
        farms: activeShed.farms.map(f => {
          const currentFarms = summary?.find(
            item => item.farm.id === f.farm.id
          );
          return {
            ...f,
            name: get(currentFarms, "name")
          };
        })
      };
    }
    return null;
  }
);

export const shedListSelector = createSelector([shedList], ({ content }) => ({
  options: content
    ? content.map(({ id, name, matter }) => ({
        key: id,
        label: name,
        value: id
      }))
    : []
}));
export const shedsListSelector = createSelector([shedList], ({ content }) =>
  content
    ? content.map(({ id, name }) => ({
        key: id,
        text: name,
        value: id
      }))
    : []
);

export const shedsOptionsSelector = createSelector([shedsList], ({ content }) =>
  content
    ? content.map(({ id, name }) => ({
        key: id,
        text: name,
        value: id
      }))
    : []
);

export const activityTypeSelector = createSelector(
  [typeList],
  ({ content }) => ({
    options: content
      ? content
          .filter(item => item != "")
          .map(item => ({
            key: item,
            label: (
              item
                .toLowerCase()
                .charAt(0)
                .toUpperCase() + item.toLowerCase().slice(1)
            ).replace(/_/g, " "),
            value: item
          }))
      : []
  })
);

export {
  shedsTypesSelector,
  dilutionRateTypesSelector,
  periodUnitsSelector,
  selectedShedsSelector
};
