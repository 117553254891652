import React from "react";
import PropTypes from "prop-types";
import { Menu, Grid, Form } from "semantic-ui-react";
import { connect } from "react-redux";

import { useFormikContext } from "formik";
import { APPLICATION_TYPES } from "constants/Recommendations/types";

const actionOnRows = {
  [APPLICATION_TYPES.SPRAY]: "spray",
  [APPLICATION_TYPES.SPREAD]: "spread",
  [APPLICATION_TYPES.FERTIGATION]: "fertigate"
};

const RowsToSpray = ({ rowsToSprayOptions }) => {
  const { values, setFieldValue } = useFormikContext();
  const onClick = option => {
    setFieldValue("rowsToSpray", option.value);
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={16}>
          <Form.Field>
            <label className="title">
              Rows to {actionOnRows[APPLICATION_TYPES[values.applicationType]]}
            </label>
            <Menu fluid compact className="sprayMenu">
              {rowsToSprayOptions.map(option => (
                <Menu.Item
                  key={option.key}
                  active={values.rowsToSpray === option.value}
                  onClick={() => onClick(option)}
                >
                  {option.text}
                </Menu.Item>
              ))}
            </Menu>
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

RowsToSpray.propTypes = {
  rowsToSprayOptions: PropTypes.array
};

const mapStateToProps = ({ sprayDiary: { rowsToSprayOptions } }) => ({
  rowsToSprayOptions
});

export default connect(mapStateToProps)(RowsToSpray);
