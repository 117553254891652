import React from "react";
import { Popup, Label } from "semantic-ui-react";

export const Chip = ({ items, label }) => {
  return (
    <Popup
      position="right center"
      style={{ textAlign: "left" }}
      trigger={
        <Label
          as="a"
          style={{
            display: "inline-flex",
            alignItems: "center",
            backgroundColor: "#F5F7F7",
            color: "#F0AC47",
            borderRadius: 20,
            padding: "4px 6px"
          }}
        >
          <Label
            size="small"
            circular
            style={{
              backgroundColor: "#F0AC47",
              color: "#FFF",
              marginRight: 8
            }}
          >
            {items.length}
          </Label>
          <span>{label}</span>
        </Label>
      }
      content={items.map((item, index) => (
        <div key={`${label}-${index}`}>{item}</div>
      ))}
      inverted
    />
  );
};
