import { combineReducers } from "redux";
import harvestByArea from "./harvestByArea";
import harvestByVariety from "./harvestByVariet";
import pickerReport from "./pickerReport";
import contractorReport from "./contractorReport";

export const fieldScanningRootReducer = combineReducers({
  harvestByArea,
  harvestByVariety,
  pickerReport,
  contractorReport
});
