import React, { useEffect, useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Form,
  Container,
  Button,
  Icon,
  Segment,
  Popup,
  Input
} from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";
import ToggleField from "../../../../components/ToggleField";
import { connect } from "react-redux";
import styles from "./TaskRow.module.css";
import { Field, Formik } from "formik";
const TaskRow = ({
  label,
  value,
  extraColumn,
  inputType,
  wide,
  name,
  multiplierValue,
  unit,
  showAddNewChemical,
  item,
  index,
  fieldToEdit,
  sprayTask,
  spray,
  minRate,
  rules,
  contentBeforeInput,
  onBatchNumberChange,
  editAllMode,
  newBatchNumberShow,
  batchNumbers,
  ...rest
}) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
  const contentSize = useMemo(
    () =>
      extraColumn
        ? (wide ? 16 : isTabletOrMobile ? 9 : 11) - extraColumn.size
        : wide
        ? 16
        : isTabletOrMobile
        ? 9
        : 11,
    [extraColumn, wide, isTabletOrMobile]
  );
  const showRow = useMemo(
    () =>
      editAllMode ||
      !fieldToEdit ||
      (fieldToEdit &&
        (!fieldToEdit.heavy ||
          (fieldToEdit.name === name && fieldToEdit.heavy))),
    [fieldToEdit, name, editAllMode]
  );

  const InputField = useMemo(
    () =>
      inputType === "hidden" ? null : value &&
        typeof value === "object" &&
        value.$$typeof ? (
        value
      ) : (
        <ToggleField
          value={value}
          showAddNewChemical={showAddNewChemical}
          item={item}
          index={index}
          multiplierValue={multiplierValue}
          unit={unit}
          label={label}
          inputType={inputType}
          onBatchNumberChange={onBatchNumberChange}
          sprayTask={sprayTask}
          fieldToEdit={fieldToEdit}
          minRate={minRate}
          name={name}
          rules={rules}
          editAllMode={editAllMode}
          newBatchNumberShow={newBatchNumberShow}
          {...rest}
        />
      ),
    [value, label, inputType, fieldToEdit, name, rules, rest, editAllMode]
  );

  return (
    showRow && (
      <Grid.Row className={`${styles.taskRow} ${wide ? styles.wideRow : ""}`}>
        {label && (
          <Grid.Column
            width={wide ? 16 : isTabletOrMobile ? 7 : 5}
            className={
              name === "batchNumber" ||
              inputType == "batchNumbersInSideBar" ||
              inputType == "addForm"
                ? styles.batchNumberLabel
                : styles.label
            }
          >
            {label}{" "}
            {rules && rules.required && (
              <span className={styles.requiredField}>*</span>
            )}
          </Grid.Column>
        )}
        <Grid.Column width={contentSize} className={styles.componentHolder}>
          {contentBeforeInput ? (
            <Grid className={styles.fieldGrid}>
              <Grid.Row>
                <Grid.Column width={contentBeforeInput.width || 1}>
                  {contentBeforeInput.component}
                </Grid.Column>
                <Grid.Column
                  width={
                    contentBeforeInput.width
                      ? 16 - contentBeforeInput.width
                      : 15
                  }
                >
                  {InputField}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          ) : (
            InputField
          )}
        </Grid.Column>
        {extraColumn && (
          <Grid.Column
            width={extraColumn.size}
            className={extraColumn.className || null}
          >
            {extraColumn.content}
          </Grid.Column>
        )}
      </Grid.Row>
    )
  );
};
TaskRow.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  value: PropTypes.any,
  extraColumn: PropTypes.object,
  wide: PropTypes.bool,
  name: PropTypes.string,
  fieldToEdit: PropTypes.object,
  rules: PropTypes.object,
  contentBeforeInput: PropTypes.object,
  editAllMode: PropTypes.bool,
  inputType: PropTypes.string
};

TaskRow.defaultProps = {};

const mapStateToProps = state => {
  const {
    sprayDiary: { chemicalsList },
    shed: { list }
  } = state;

  return {
    batchNumbers: state.spray.batchNumbers,
    spray: state.spray,
    chemicalsList,
    list
  };
};

export default connect(mapStateToProps)(TaskRow);
