import React from "react";
import { Form, Segment } from "semantic-ui-react";
import { Field } from "formik";
import CollapsibleBlock from "components/CollapsibleBlock";
const GeneralInformation = () => {
  return (
    <Segment>
      <CollapsibleBlock title="General Information">
        <Form>
          <Form.Group>
            <Field name="name" required>
              {({ field, meta }) => (
                <Form.Input
                  placeholder="Type in the name of the shed"
                  width={8}
                  label="Name"
                  error={meta.touched && meta.error}
                  required
                  {...field}
                />
              )}
            </Field>
            <Field name="address">
              {({ field, meta }) => (
                <Form.Input
                  placeholder="Type in the address of the shed"
                  width={8}
                  label="Address"
                  error={meta.touched && meta.error}
                  {...field}
                />
              )}
            </Field>
          </Form.Group>
          <Field
            label="Comments"
            placeholder="You can leave some comments here"
            as={Form.TextArea}
            width={16}
            name="comments"
          />
        </Form>
      </CollapsibleBlock>
    </Segment>
  );
};

export default GeneralInformation;
