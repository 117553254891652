import PropTypes from "prop-types";
import React, { memo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHeader as Header,
  TableHeaderCell,
  TableRow
} from "semantic-ui-react";

import TagDate from "./TagDate";

import styles from "./index.module.css";

const BreakdownTable = ({ data, hasAreaColumn }) => {
  return (
    <div className={styles.breakdownTableContainer}>
      <Table unstackable>
        <Header>
          <TableRow>
            <TableHeaderCell style={{ width: 30 }} />
            <TableHeaderCell>Date</TableHeaderCell>
            <TableHeaderCell>Number of tags</TableHeaderCell>
          </TableRow>
        </Header>
        <TableBody>
          {Object.entries(data).map(item => (
            <TagDate
              key={`tag_date_${item[0]}`}
              date={item[0]}
              tags={item[1]}
              hasAreaColumn={hasAreaColumn}
            />
          ))}
          <TableRow>
            <TableCell>
              <b>Total</b>
            </TableCell>
            <TableCell>{Object.keys(data).length}</TableCell>
            <TableCell>
              {Object.values(data).reduce((a, b) => a + b.length, 0)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

BreakdownTable.propTypes = {
  data: PropTypes.object.isRequired,
  hasAreaColumn: PropTypes.bool
};

BreakdownTable.defaultProps = {
  data: {},
  hasAreaColumn: false
};

export default memo(BreakdownTable);
