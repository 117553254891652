import PropTypes from "prop-types";
import React, { useEffect, useRef, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  Grid,
  Dropdown,
  Input,
  Icon,
  Checkbox,
  Form,
  Button
} from "semantic-ui-react";
import { farmsWithAreas } from "selectors/farms";
import styles from "./AreasDropDownTree.module.css";
import { Field, Formik, useFormikContext } from "formik";
const DropdownTree = ({ data, handleAreaSelectTreeChange, costIndex }) => {
  const { values, setValues, setFieldValue } = useFormikContext();
  const [openArea, setOpenArea] = useState(true);
  const [openSubArea, setOpenSubArea] = useState(true);
  const [selectedItem, setSelectedItem] = useState(data.selectedAreas || []);
  const [expanded, setExpanded] = useState([]);
  const farmsWithAreasList = useSelector(farmsWithAreas);

  const [searchQuery, setSearchQuery] = useState("");

  const searchData = farmsWithAreasList
    .map(item => {
      return {
        ...item,
        areas: item.areas.filter(area =>
          area.name
            .toUpperCase()
            .replace(/\s/g, "")
            .includes(searchQuery?.toUpperCase().replace(/\s/g, ""))
        )
      };
    })
    .filter(i => i.areas?.length > 0)
    .sort((a, b) => a.farm.name.localeCompare(b.farm.name));

  const dataList = useMemo(() => {
    return searchData
      .map(({ farm, areas }) => {
        const filteredAreas = areas.filter(a => !a.archived);
        const groupedAreas = filteredAreas.reduce((prev, current) => {
          if (current.parent) {
            const prevParentIndex = prev.findIndex(
              block => block.id === current.parent.id
            );
            if (prevParentIndex > -1) {
              const prevNew = [...prev];
              prevNew[prevParentIndex] = {
                ...prevNew[prevParentIndex],
                label: current.parent.name,
                value: [...prevNew[prevParentIndex].value, current],
                type: "block",
                children: [
                  ...prevNew[prevParentIndex].children,
                  {
                    id: current.id,
                    farmId: farm.id,
                    blockId: current.parent.id,
                    label: current.name,
                    value: [current],
                    checked: !!selectedItem.find(
                      item => item.type === "patch" && item.id === current.id
                    ),
                    className: data.treeSelectSearchFilter
                      ? !current.name
                          .toLowerCase()
                          .includes(data.treeSelectSearchFilter.toLowerCase())
                        ? "notFitSearch"
                        : "fitSearch"
                      : "",
                    type: "patch"
                  }
                ]
              };
              return prevNew;
            }
            return [
              ...prev,
              {
                id: current.parent.id,
                farmId: farm.id,
                label: current.parent.name,
                value: [current],
                expanded: !!expanded.find(
                  item => item.type === "block" && item.id === current.parent.id
                ),
                className: data.treeSelectSearchFilter ? "notFitSearch" : "",
                checked: !!selectedItem.find(
                  item => item.type === "block" && item.id === current.parent.id
                ),
                type: "block",
                children: [
                  {
                    id: current.id,
                    farmId: farm.id,
                    blockId: current.parent.id,
                    label: current.name,
                    value: [current],
                    checked: !!selectedItem.find(
                      item => item.type === "patch" && item.id === current.id
                    ),
                    className: data.treeSelectSearchFilter
                      ? !current.name
                          .toLowerCase()
                          .includes(data.treeSelectSearchFilter.toLowerCase())
                        ? "notFitSearch"
                        : "fitSearch"
                      : "",
                    type: "patch"
                  }
                ]
              }
            ];
          }
          if (prev.findIndex(block => block.id === current.id) === -1) {
            return [
              ...prev,
              {
                id: current.id,
                farmId: farm.id,
                label: current.name,
                value: current.hasSubAreas ? [] : [current],
                expanded: !!expanded.find(
                  item => item.type === "block" && item.id === current.id
                ),
                type: "block",
                children: []
              }
            ];
          }
          return prev;
        }, []);
        const checkedAreas = groupedAreas
          .map(area => ({
            ...area,
            children: area.children.sort((p1, p2) =>
              p1.label.localeCompare(p2.label)
            ),
            checked: !!selectedItem.find(
              item => item.type === "block" && item.id === area.id
            )
          }))
          .sort((b1, b2) => b1.label.localeCompare(b2.label));
        return {
          farmId: farm.id,
          label: farm.name,
          value: checkedAreas.map(area => area.value).flat(),
          checked: !!selectedItem.find(
            item => item.type === "farm" && item.farmId === farm.id
          ),
          expanded: !!expanded.find(
            item => item.type === "farm" && item.farmId === farm.id
          ),
          type: "farm",
          children: checkedAreas
        };
      })
      .sort((farm1, farm2) => farm1.label.localeCompare(farm2.label));
  }, [searchQuery, data, expanded, selectedItem]);

  const dataWithoutSearch = useMemo(() => {
    return farmsWithAreasList
      .map(({ farm, areas }) => {
        const filteredAreas = areas.filter(a => !a.archived);
        const groupedAreas = filteredAreas.reduce((prev, current) => {
          if (current.parent) {
            const prevParentIndex = prev.findIndex(
              block => block.id === current.parent.id
            );
            if (prevParentIndex > -1) {
              const prevNew = [...prev];
              prevNew[prevParentIndex] = {
                ...prevNew[prevParentIndex],
                label: current.parent.name,
                value: [...prevNew[prevParentIndex].value, current],
                type: "block",
                children: [
                  ...prevNew[prevParentIndex].children,
                  {
                    id: current.id,
                    farmId: farm.id,
                    blockId: current.parent.id,
                    label: current.name,
                    value: [current],
                    checked: !!selectedItem.find(
                      item => item.type === "patch" && item.id === current.id
                    ),
                    className: data.treeSelectSearchFilter
                      ? !current.name
                          .toLowerCase()
                          .includes(data.treeSelectSearchFilter.toLowerCase())
                        ? "notFitSearch"
                        : "fitSearch"
                      : "",
                    type: "patch"
                  }
                ]
              };
              return prevNew;
            }
            return [
              ...prev,
              {
                id: current.parent.id,
                farmId: farm.id,
                label: current.parent.name,
                value: [current],
                expanded: !!expanded.find(
                  item => item.type === "block" && item.id === current.parent.id
                ),
                className: data.treeSelectSearchFilter ? "notFitSearch" : "",
                checked: !!selectedItem.find(
                  item => item.type === "block" && item.id === current.parent.id
                ),
                type: "block",
                children: [
                  {
                    id: current.id,
                    farmId: farm.id,
                    blockId: current.parent.id,
                    label: current.name,
                    value: [current],
                    checked: !!selectedItem.find(
                      item => item.type === "patch" && item.id === current.id
                    ),
                    className: data.treeSelectSearchFilter
                      ? !current.name
                          .toLowerCase()
                          .includes(data.treeSelectSearchFilter.toLowerCase())
                        ? "notFitSearch"
                        : "fitSearch"
                      : "",
                    type: "patch"
                  }
                ]
              }
            ];
          }
          if (prev.findIndex(block => block.id === current.id) === -1) {
            return [
              ...prev,
              {
                id: current.id,
                farmId: farm.id,
                label: current.name,
                value: current.hasSubAreas ? [] : [current],
                expanded: !!expanded.find(
                  item => item.type === "block" && item.id === current.id
                ),
                type: "block",
                children: []
              }
            ];
          }
          return prev;
        }, []);
        const checkedAreas = groupedAreas
          .map(area => ({
            ...area,
            children: area.children.sort((p1, p2) =>
              p1.label.localeCompare(p2.label)
            ),
            checked: !!selectedItem.find(
              item => item.type === "block" && item.id === area.id
            )
          }))
          .sort((b1, b2) => b1.label.localeCompare(b2.label));
        return {
          farmId: farm.id,
          label: farm.name,
          value: checkedAreas.map(area => area.value).flat(),
          checked: !!selectedItem.find(
            item => item.type === "farm" && item.farmId === farm.id
          ),
          expanded: !!expanded.find(
            item => item.type === "farm" && item.farmId === farm.id
          ),
          type: "farm",
          children: checkedAreas
        };
      })
      .sort((farm1, farm2) => farm1.label.localeCompare(farm2.label));
  }, [searchQuery, data, expanded, selectedItem]);

  const changeDataValue = () => {
    setFieldValue("data", dataList);
  };

  const selectedAreaList =
    dataWithoutSearch
      ?.map(item => {
        return item.children
          ?.map(ch => {
            return ch.children.length > 0 ? ch.children : ch;
          })
          .flat();
      })
      .flat()
      .filter(i => i.checked) || [];

  useEffect(() => {
    changeDataValue();
  }, []);

  useEffect(() => {
    changeDataValue();
  }, [searchQuery, dataList, selectedItem, expanded]);

  return (
    <Formik initialValues={dataList}>
      {props => {
        return (
          <Form>
            <Dropdown
              text={
                selectedAreaList?.length > 0 && (
                  <span className={styles.areasLabelDropdownTree}>
                    {selectedAreaList?.length} Areas
                  </span>
                )
              }
              placeholder="Select"
              multiple
              floating
              search
              className={styles.areaSelector}
              onClose={() => {
                return setSearchQuery("");
              }}
            >
              <Dropdown.Menu className={styles.areaSelectorMenu}>
                <Dropdown.Item className={styles.dropDownMenuSelectAllItem}>
                  Select
                  <Button
                    onClick={() => {
                      const newData = values.data
                        .map(item => {
                          return [
                            {
                              farmId: item.farmId,
                              type: item.type
                            },
                            ...item.children
                              .map(ch => [
                                {
                                  type: ch.type,
                                  id: ch.id,
                                  farmId: item.farmId,
                                  areaData: ch
                                },
                                ...ch.children.map(patch => ({
                                  type: patch.type,
                                  id: patch.id,
                                  farmId: item.farmId,
                                  areaData: patch
                                }))
                              ])
                              .flat()
                          ];
                        })
                        .flat();
                      const selectedAreas = [];
                      newData.forEach(i => {
                        if (i.type === "block" && !i.areaData.children.length) {
                          selectedAreas.push({
                            ...i,
                            areas: i.areaData.value
                          });
                        }
                      });

                      handleAreaSelectTreeChange(
                        `costs[${costIndex}].areaData.selectedAreas`,
                        newData
                      );
                      return setSelectedItem(newData);
                    }}
                    className={styles.allNoneButton}
                  >
                    All
                  </Button>
                  /
                  <Button
                    className={styles.allNoneButton}
                    onClick={() => {
                      return setSelectedItem([]);
                    }}
                  >
                    None
                  </Button>
                </Dropdown.Item>

                <Input
                  value={searchQuery}
                  placeholder="Search Block and Patch..."
                  iconPosition="left"
                  className={styles.searchInput}
                  onClick={e => e.stopPropagation()}
                  onChange={(_, { value }) => setSearchQuery(value)}
                />

                <Dropdown.Menu
                  className={styles.areaDropDownScrollingMenu}
                  scrolling
                >
                  {(searchQuery.length > 0
                    ? values.data.map(i => {
                        return {
                          ...i,
                          expanded: true,
                          children: i.children.map(block => {
                            return {
                              ...block,
                              expanded: true,
                              children: block.children.map(patch => {
                                return {
                                  ...patch
                                };
                              })
                            };
                          })
                        };
                      })
                    : values.data
                  )?.map((item, index) => {
                    return (
                      <>
                        <div className={styles.itemWrapper}>
                          {
                            <Icon
                              size="small"
                              onClick={() => {
                                let expandedItemInFarm = [...expanded];
                                const farmIndex = expandedItemInFarm.findIndex(
                                  i =>
                                    i.type === "farm" &&
                                    i.farmId === item.farmId
                                );
                                if (farmIndex > -1) {
                                  expandedItemInFarm = expandedItemInFarm.filter(
                                    i =>
                                      i.farmId !== item.farmId &&
                                      i.type === "farm"
                                  );
                                } else {
                                  expandedItemInFarm.push({
                                    type: "farm",
                                    farmId: item.farmId
                                  });
                                }

                                return (
                                  setExpanded(expandedItemInFarm),
                                  setOpenArea(!openArea),
                                  setFieldValue(
                                    `data[${index}].expanded`,
                                    openArea
                                  )
                                );
                              }}
                              name={
                                item.expanded === true
                                  ? "chevron up"
                                  : "chevron down"
                              }
                            />
                          }
                          <Dropdown.Item
                            className={styles.farmItem}
                            key={item.farmId}
                            value={item.farmId}
                            text={item.label}
                            content={
                              <Checkbox
                                indeterminate={
                                  !!item.children.some(c => c.checked) &&
                                  !item.children.every(c => c.checked)
                                }
                                onClick={() => {
                                  let selectedItemInFarm = [...selectedItem];
                                  const farmIndex = selectedItemInFarm.findIndex(
                                    i =>
                                      i.type === "farm" &&
                                      i.farmId === item.farmId
                                  );
                                  if (farmIndex > -1) {
                                    selectedItemInFarm = selectedItemInFarm.filter(
                                      i => i.farmId !== item.farmId
                                    );
                                  } else {
                                    selectedItemInFarm.push({
                                      type: "farm",
                                      farmId: item.farmId
                                    });
                                  }
                                  item.children.map((ch, chIndex) => {
                                    if (!item.checked) {
                                      selectedItemInFarm.push({
                                        farmId: item.farmId,
                                        type: "block",
                                        id: ch.id,
                                        areaData: ch
                                      });
                                    }
                                    return ch.children.map(
                                      (patch, patchIndex) => {
                                        if (!ch.checked) {
                                          selectedItemInFarm.push({
                                            farmId: item.farmId,
                                            type: "patch",
                                            id: patch.id,
                                            areaData: patch
                                          });
                                        }
                                      }
                                    );
                                  });

                                  // let uniqueObjArray = [...new Map(selectedItemInFarm.map((item) => [item["id"], item])).values()]
                                  const selectedAreas = [];
                                  selectedItemInFarm.forEach(i => {
                                    if (
                                      i.type === "block" &&
                                      !i.areaData.children.length
                                    ) {
                                      selectedAreas.push({
                                        ...i,
                                        areas: i.areaData.value
                                      });
                                    }
                                  });
                                  setSelectedItem(selectedItemInFarm);
                                  handleAreaSelectTreeChange(
                                    `costs[${costIndex}].areaData.selectedAreas`,
                                    selectedItemInFarm
                                  );
                                }}
                                label={item.label}
                                checked={item.checked}
                              />
                            }
                          />
                        </div>

                        {!!item.children.length &&
                          item.expanded &&
                          item.children.map((ch, blcokIndex) => {
                            return (
                              <>
                                <div
                                  className={
                                    !!ch.children.length
                                      ? styles.childrenWrapperWithArrow
                                      : styles.childrenWrapper
                                  }
                                >
                                  {!!ch.children.length && (
                                    <Icon
                                      size="small"
                                      onClick={() => {
                                        let expandedItemInBlock = [...expanded];
                                        const blockIndex = expandedItemInBlock.findIndex(
                                          i =>
                                            i.type === "block" && i.id === ch.id
                                        );
                                        if (blockIndex > -1) {
                                          expandedItemInBlock = expandedItemInBlock.filter(
                                            i =>
                                              i.type === "block"
                                                ? i.id !== ch.id
                                                : i /* || i.farmId !== item.farmId */
                                          );
                                        } else {
                                          expandedItemInBlock.push({
                                            type: "block",
                                            farmId: item.farmId,
                                            id: ch.id
                                          });
                                        }
                                        return (
                                          setExpanded(expandedItemInBlock),
                                          setOpenSubArea(!expandedItemInBlock),
                                          setFieldValue(
                                            `data[${index}].children[${blcokIndex}].expanded`,
                                            openSubArea
                                          )
                                        );
                                      }}
                                      name={
                                        ch.expanded
                                          ? "chevron up"
                                          : "chevron down"
                                      }
                                    />
                                  )}
                                  <Dropdown.Item
                                    className={styles.blockItem}
                                    key={ch.id}
                                    value={ch.id}
                                    text={ch.label}
                                    content={
                                      <Checkbox
                                        indeterminate={
                                          !!ch.children.some(
                                            block => block.checked
                                          ) &&
                                          !ch.children.every(c => c.checked)
                                        }
                                        onClick={() => {
                                          let selectedItemInBlock = [
                                            ...selectedItem
                                          ];
                                          const blockIndex = selectedItemInBlock.findIndex(
                                            i =>
                                              i.type === "block" &&
                                              i.id === ch.id
                                          );
                                          if (blockIndex > -1) {
                                            selectedItemInBlock = selectedItemInBlock.filter(
                                              i =>
                                                i.type === "farm"
                                                  ? i.farmId !== item.farmId
                                                  : i.type === "block"
                                                  ? i.id !== ch.id
                                                  : ch.children.every(
                                                      patch => patch.id !== i.id
                                                    )
                                            );
                                          } else {
                                            selectedItemInBlock.push({
                                              id: ch.id,
                                              type: "block",
                                              farmId: item.farmId,
                                              areaData: ch
                                            });
                                          }

                                          ch.children.map(
                                            (patch, patchIndex) => {
                                              if (!ch.checked) {
                                                selectedItemInBlock.push({
                                                  farmId: item.farmId,
                                                  type: "patch",
                                                  id: patch.id,
                                                  areaData: patch
                                                });
                                              }
                                            }
                                          );
                                          if (
                                            item.children.every(item =>
                                              selectedItemInBlock.some(
                                                i => i.id === item.id
                                              )
                                            )
                                          ) {
                                            selectedItemInBlock.push({
                                              type: "farm",
                                              farmId: item.farmId
                                            });
                                          }
                                          // let uniqueObjArray = [...new Map(selectedItemInBlock.map((item) => [item["id"], item])).values()]
                                          const selectedAreas = [];
                                          selectedItemInBlock.forEach(i => {
                                            if (
                                              i.type === "block" &&
                                              !i.areaData.children.length
                                            ) {
                                              selectedAreas.push({
                                                ...i,
                                                areas: i.areaData.value
                                              });
                                            }
                                          });

                                          handleAreaSelectTreeChange(
                                            `costs[${costIndex}].areaData.selectedAreas`,
                                            selectedItemInBlock
                                          );
                                          setSelectedItem(selectedItemInBlock);
                                        }}
                                        label={ch.label}
                                        checked={ch.checked}
                                      />
                                    }
                                  />
                                </div>
                                <div className={styles.subChildrenWrapper}>
                                  {!!ch.children.length &&
                                    ch.expanded === true &&
                                    ch.children.map(
                                      (subChildren, subChildrenIndex) => {
                                        return (
                                          <Dropdown.Item
                                            className={styles.subChildrenItem}
                                            key={subChildren.id}
                                            value={subChildren.id}
                                            text={subChildren.label}
                                            content={
                                              <Checkbox
                                                onClick={() => {
                                                  let selectedItemInPatch = [
                                                    ...selectedItem
                                                  ];
                                                  const patchIndex = selectedItemInPatch.findIndex(
                                                    i =>
                                                      i.type === "patch" &&
                                                      i.id === subChildren.id
                                                  );
                                                  if (patchIndex > -1) {
                                                    selectedItemInPatch = selectedItemInPatch.filter(
                                                      i =>
                                                        i.type === "patch"
                                                          ? i.id !==
                                                            subChildren.id
                                                          : i.type === "block"
                                                          ? i.id !== ch.id
                                                          : i.type === "farm"
                                                          ? i.farmId !==
                                                            item.farmId
                                                          : i
                                                    );
                                                  } else {
                                                    selectedItemInPatch.push({
                                                      id: subChildren.id,
                                                      type: "patch",
                                                      farmId: item.farmId,
                                                      areaData: subChildren
                                                    });
                                                  }

                                                  if (
                                                    ch.children.every(item =>
                                                      selectedItemInPatch.some(
                                                        i => i.id === item.id
                                                      )
                                                    )
                                                  ) {
                                                    selectedItemInPatch.push({
                                                      id: ch.id,
                                                      type: "block",
                                                      farmId: item.farmId,
                                                      areaData: ch
                                                    });
                                                  }
                                                  if (
                                                    item.children.every(item =>
                                                      selectedItemInPatch.some(
                                                        i => i.id === item.id
                                                      )
                                                    )
                                                  ) {
                                                    selectedItemInPatch.push({
                                                      type: "farm",
                                                      farmId: item.farmId
                                                    });
                                                  }
                                                  const selectedAreas = [];
                                                  // let uniqueObjArray = [...new Map(selectedItemInPatch.map((item) => [item["id"], item])).values()]
                                                  selectedItemInPatch.forEach(
                                                    i => {
                                                      if (
                                                        i.type === "block" &&
                                                        !i.areaData.children
                                                          .length
                                                      ) {
                                                        selectedAreas.push({
                                                          ...i,
                                                          areas:
                                                            i.areaData.value
                                                        });
                                                      }
                                                    }
                                                  );

                                                  handleAreaSelectTreeChange(
                                                    `costs[${costIndex}].areaData.selectedAreas`,
                                                    selectedItemInPatch
                                                  );
                                                  setSelectedItem(
                                                    selectedItemInPatch
                                                  );
                                                }}
                                                label={subChildren.label}
                                                checked={subChildren.checked}
                                              />
                                            }
                                          />
                                        );
                                      }
                                    )}
                                </div>
                              </>
                            );
                          })}
                      </>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown.Menu>
            </Dropdown>
          </Form>
        );
      }}
    </Formik>
  );
};

DropdownTree.propTypes = {
  allowedFields: PropTypes.array.isRequired,
  mandatoryFields: PropTypes.array.isRequired,
  onArrowClick: PropTypes.func.isRequired
};

export default React.memo(DropdownTree);
