import { actionType } from "../../constants/GeneralBarcodes";

const initialState = {
  series: [],
  data: {
    content: [],
    number: 0,
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
    size: 10
  },
  batchIDs: {
    isFetching: false,
    content: []
  },
  barcodeIDs: {
    isFetching: false,
    content: []
  },
  pickedBinsReport: {},
  isFetching: false,
  isReportGenerating: false,
  error: null
};

export default (state = initialState, action) => {
  if (!action) return state;

  switch (action.type) {
    case actionType.GENERAL_BARCODES_CLEAR_SERIES:
      return {
        ...initialState
      };
    case actionType.GENERATE_GENERAL_BARCODES_START:
      return {
        ...initialState,
        isFetching: true
      };
    case actionType.GENERATE_GENERAL_BARCODES:
      return {
        ...state,
        series: action.payload,
        isFetching: false
      };

    case actionType.FETCH_SCANNED_BARCODES_START: {
      return {
        ...state,
        isFetching: true
      };
    }
    case actionType.FETCH_SCANNED_BARCODES: {
      return {
        ...state,
        data: {
          ...action.payload
        },
        isFetching: false
      };
    }

    case actionType.UPDATE_SUBMISSION: {
      return {
        ...state,
        data: {
          ...state.data
        }
      };
    }

    case actionType.FETCH_SCANNED_BARCODE_START: {
      return {
        ...state,
        isFetching: true,
        error: null
      };
    }
    case actionType.FETCH_SCANNED_BARCODE: {
      return {
        ...state,
        data: {
          ...state.data,
          content: [action.payload]
        },
        isFetching: false,
        error: null
      };
    }
    case actionType.DELETE_SUBMISSION: {
      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.filter(x => x.id !== action.payload)
        }
      };
    }

    case actionType.FETCH_GENERAL_BARCODE_IDS_START: {
      return {
        ...state,
        batchIDs: {
          isFetching: true,
          content: []
        },
        barcodeIDs: {
          isFetching: true,
          content: []
        },
        error: null
      };
    }
    case actionType.FETCH_GENERAL_BARCODE_IDS: {
      return {
        ...state,
        batchIDs: {
          isFetching: false,
          content: action.payload.res
        },
        barcodeIDs: {
          isFetching: true,
          content: action.payload.barcodeIDs
        }
      };
    }
    case actionType.FETCH_GENERAL_BARCODE_IDS_FAIL: {
      return {
        ...state,
        batchIDs: {
          isFetching: false,
          content: []
        },
        barcodeIDs: {
          isFetching: true,
          content: []
        },
        error: action.payload
      };
    }

    case actionType.GENERATE_PICKED_BINS_REPORT_START:
      return {
        ...state,
        isReportGenerating: true
      };
    case actionType.GENERATE_PICKED_BINS_REPORT:
      return {
        ...state,
        pickedBinsReport: action.payload,
        isReportGenerating: false
      };
    case actionType.GENERATE_PICKED_BINS_REPORT_ERROR:
      return {
        ...state,
        isReportGenerating: false,
        error: action.payload
      };

    case actionType.FETCH_SCANNED_BARCODES_FAIL:
    case actionType.FETCH_SCANNED_BARCODE_FAIL:
    case actionType.UPDATE_SUBMISSION_ERROR:
    case actionType.GENERATE_GENERAL_BARCODES_FAIL:
      return {
        ...initialState,
        error: action.payload,
        isFetching: false
      };

    case actionType.GENERAL_BARCODES_CLEAR_ERROR:
    case actionType.CLEAR_SCANNED_BARCODES_ERROR:
      return {
        ...state,
        error: null
      };

    default:
      return state;
  }
};
