import { actionType } from "../../constants/Settings";

const initialState = {
  data: {},
  isUploadingAttachment: false,
  isCreating: false
};

export default (state = initialState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case actionType.FETCH_SETTINGS:
      return {
        ...state,
        data: {
          ...action.payload
        }
      };
    case actionType.UPLOAD_SETTING_ATTACHMENT_START:
      return {
        ...state,
        isUploadingAttachment: true,
        isCreating: true
      };

    case actionType.UPLOAD_SETTING_ATTACHMENT:
    case actionType.UPLOAD_SETTING_ATTACHMENT_ERROR:
      return {
        ...state,
        isUploadingAttachment: false
      };
    case actionType.ADD_SETTINGS_START:
      return {
        ...state,
        isCreating: true
      };
    case actionType.ADD_SETTINGS:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
        isCreating: false
      };

    case actionType.ADD_SETTINGS_FAIL:
      return {
        ...state,
        isCreating: false,
        error: action.payload
      };
    default:
      return state;
  }
};
