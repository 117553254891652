export const actionType = {
  ADD_SHED_START: "SHEDS/ADD_SHED_START",
  ADD_SHED: "SHEDS/ADD_SHED",
  ADD_SHED_FAIL: "SHEDS/ADD_SHED_FAIL",

  UPDATE_SHED_START: "SHEDS/UPDATE_SHED_START",
  UPDATE_SHED: "SHEDS/UPDATE_SHED",
  UPDATE_SHED_FAIL: "SHEDS/UPDATE_SHED_FAIL",

  ADD_OPERATION_START: "SHEDS/ADD_OPERATION_START",
  ADD_OPERATION: "SHEDS/ADD_OPERATION",
  ADD_OPERATION_FAIL: "SHEDS/ADD_OPERATION_FAIL",

  THRESHOLD_ADD: "SHEDS/THRESHOLD_ADD",
  THRESHOLD_DELETE: "SHEDS/THRESHOLD_DELETE",

  FETCH_SHEDS_LIST: "SHEDS/FETCH_SHEDS_LIST",
  OPERATION_FAIL: "SHEDS/OPERATION_FAIL",
  CHANGE_VALUE: "SHEDS/CHANGE_VALUE",

  FETCH_SHEDS_START: "SHEDS/FETCH_SHEDS_START",
  FETCH_SHEDS: "SHEDS/FETCH_SHEDS",
  FETCH_SHEDS_ERROR: "SHEDS/FETCH_SHEDS_ERROR",

  FETCH_SHEDS_LIST_START: "SHEDS/FETCH_SHEDS_LIST_START",
  FETCH_SHEDS_LIST: "SHEDS/FETCH_SHEDS_LIST",
  FETCH_SHEDS_LIST_ERROR: "SHEDS/FETCH_SHEDS_LIST_ERROR",

  ARCHIVE_SHEDS_START: "SHEDS/ARCHIVE_SHEDS_START",
  ARCHIVE_SHEDS: "SHEDS/ARCHIVE_SHEDS",
  ARCHIVE_SHEDS_FAIL: "SHEDS/ARCHIVE_SHEDS_FAIL",

  SET_ACTIVE_ITEM_ID: "SHEDS/SET_ACTIVE_ITEM_ID",
  DELETE_SHED_ERROR: "SHEDS/DELETE_SHED_ERROR",

  UPDATE_SHED_INVENTORY: "SHEDS/UPDATE_SHED_INVENTORY",
  PARTIAL_UPDATE: "SHEDS/PARTIAL_UPDATE",
  PARTIAL_UPDATE_FAIL: "SHEDS/PARTIAL_UPDATE_FAIL",

  PENDING_OPERATIONS_START: "SHEDS/PENDING_OPERATIONS_START",
  PENDING_OPERATIONS: "SHEDS/PENDING_OPERATIONS",

  PENDING_OPERATIONS_LIST_START: "SHEDS/PENDING_OPERATIONS_LIST_START",
  PENDING_OPERATIONS_LIST: "SHEDS/PENDING_OPERATIONS_LIST"
};

export const SHED_INITIAL_VALUES = {
  buckets: [],
  thresholds: [],
  farms: []
};
