import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "react-table-6/react-table.css";
import ListHolder from "../../../components/ListHolder";
import { abilitiesSelector } from "../../../selectors/user";
import { deleteStock, fetchStock } from "../../../actions/Stock/stock";
import Sidebar from "./Sidebar";

const PAGE_SIZE = 10;

export const calcNotAllocatedPallets = stockItem => {
  return (
    stockItem.boxUnit.unitsPerPallet &&
    stockItem.boxUnit &&
    Math.floor(stockItem.packedBoxesCount / stockItem.boxUnit.unitsPerPallet, 0)
  );
};

class Stock extends Component {
  state = {
    tableData: [],
    tableColumns: [],
    sideBarShown: false,
    activeItemId: null
  };

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { data } = this.props;
    if (prevProps.data !== data) this.prepareData();
  }

  id;
  createDate;
  variety;
  boxUnit;
  area;
  unitsOnStock;
  notes;
  shipped;
  prepareData = () => {
    const { data } = this.props;
    const columnsNames = {
      variety: {
        title: "Variety",
        accessor: d => d.variety && d.variety.name,
        width: 8
      },
      boxUnit: {
        title: "Box units",
        accessor: d => d.boxUnit && d.boxUnit.displayName,
        width: 8
      },
      unitsPerPallet: {
        title: "Units on pallet",
        accessor: d => d.boxUnit && d.boxUnit.unitsPerPallet,
        width: 8
      },
      notAllocatedPallets: {
        title: "Not allocated pallets",
        accessor: d => calcNotAllocatedPallets(d),
        width: 8
      },
      packedBoxesCount: {
        title: "Units in stock",
        accessor: d => d.packedBoxesCount || 0,
        width: 8
      },
      palletsCount: {
        title: "Pallets in stock",
        accessor: d => d.palletsCount || 0,
        width: 8
      },
      actions: {
        title: "",
        width: "auto",
        accessor: d => d.id,
        className: "textRight actions"
      }
    };
    const tableColumns = [];
    const tableData = [];

    Object.keys(columnsNames).forEach(column => {
      const targetColumn = columnsNames[column];
      const columnToAdd = {
        id: column,
        accessor: targetColumn.accessor || column,
        Header: targetColumn.title,
        Cell: this.renderCell,
        className: `cell_${targetColumn.width} ${targetColumn.className || ""}`,
        ...(targetColumn.sortFields
          ? { sortFields: targetColumn.sortFields }
          : {}),
        disableSort: targetColumn.disableSort
      };

      tableColumns.push(columnToAdd);
    });

    if (data && data.content) {
      data.content.forEach(row => {
        const rowData = {
          rights: {
            update: "update",
            delete: "delete"
          }
        };
        Object.keys(row).forEach(key => {
          switch (key) {
            default:
              rowData[key] = row[key] || "";
              break;
          }
        });
        tableData.push(rowData);
      });
    }

    this.setState({
      tableData,
      tableColumns
    });
  };

  renderCell = props => {
    const {
      value,
      column: { Header }
    } = props;

    return (
      <div>
        <div className="cellTitle hide-md">{Header}</div>
        <div className="cellValue">{value}</div>
      </div>
    );
  };

  setActiveItem = activeItemId => {
    this.setState({
      activeItemId
    });
  };

  render() {
    const {
      route,
      location,
      currentPage,
      totalPages,
      isFetching,
      Can,
      fetchAction,
      size,
      totalElements,
      deleteAction,
      error,
      online,
      data: { content }
    } = this.props;

    const { tableData, tableColumns, activeItemId } = this.state;
    const expandedContent =
      activeItemId && content.find(e => e.id === activeItemId);
    const editLink = expandedContent
      ? `/harvest/stock/item/${expandedContent.id}`
      : null;
    return (
      <ListHolder
        accessName="stock"
        Can={Can}
        addNewPath={"/harvest/stock/item"}
        updateAction="update"
        editLink={editLink}
        route={route}
        location={location}
        currentPage={currentPage}
        size={size || PAGE_SIZE}
        totalPages={totalPages}
        rawData={content}
        data={tableData}
        columns={tableColumns}
        getData={fetchAction}
        delData={deleteAction}
        isFetching={isFetching && !totalPages}
        totalElements={totalElements}
        withDateRange={false}
        setActiveItem={this.setActiveItem}
        error={error}
        online={online}
        defaultSort="id,desc"
      >
        <Sidebar
          id={expandedContent && expandedContent.id}
          stock={expandedContent}
          Can={Can}
        />
      </ListHolder>
    );
  }
}

Stock.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  totalElements: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  fetchAction: PropTypes.func.isRequired,
  deleteAction: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  lastLoaded: PropTypes.bool,
  data: PropTypes.object,
  error: PropTypes.object,
  route: PropTypes.object,
  location: PropTypes.object,
  Can: PropTypes.func,
  online: PropTypes.bool
};

const mapStateToProps = state => {
  const {
    stock: {
      isFetching,
      data,
      data: { content, number, totalPages, totalElements, size, last },
      error
    },
    offline: { online }
  } = state;
  return {
    data,
    content,
    isFetching,
    currentPage: number,
    totalPages,
    totalElements,
    size,
    lastLoaded: last,
    Can: abilitiesSelector(state),
    error,
    online
  };
};

const mapDispatchToProps = {
  fetchAction: fetchStock,
  deleteAction: deleteStock
};

export default connect(mapStateToProps, mapDispatchToProps)(Stock);
