import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import styles from "./styles.module.css";

const QCPDFReport = ({ formTemplate, submissions }) => {
  const formHeaders = Object.keys(formTemplate);

  return (
    <div>
      <h1 className={styles.centered}>Harvest Record</h1>
      <h2>Site ________________________________</h2>
      <h2>
        To be completed by harvest Supervisor for each bin received to
        storage/collection
      </h2>
      <h3 className={"centered"}>ONLY ICA TRAINED STAFF MAY INSPECT BINS</h3>
      <table className={styles.qcReportTable}>
        <thead>
          <tr>
            <th>Date</th>
            <th>Picker</th>
            <th>Bin No.</th>
            <th>Block Row ID</th>
            <th>Variety</th>
            <th>Time Received</th>
            {formHeaders.map((header, i) => (
              <th key={`completed_form_header_${i}`}>{header}</th>
            ))}
            <th>
              Inspected by
              <br />
              (Name)
            </th>
            <th>
              Inspected by
              <br />
              (Signature)
            </th>
          </tr>
        </thead>
        <tbody>
          {submissions.map((submission, i) => {
            return (
              <tr key={`submission_row_${i}`}>
                <td>{moment(submission).format("DD/MM/YYYY")}</td>
                <td>
                  {submission.barcode && submission.employeeBinParts
                    ? submission.employeeBinParts
                        .map(
                          ebp =>
                            `${ebp.employee.firstName} ${ebp.employee.lastName}`
                        )
                        .join(", ")
                    : "-"}
                </td>
                <td>-</td>
                <td>
                  {submission.barcode
                    ? `${submission.barcode.rowsPrefix || ""}${submission
                        .barcode.row || ""}`
                    : "-"}
                </td>
                <td>
                  {submission.barcode && submission.barcode.variety
                    ? submission.barcode.variety.name
                    : "-"}
                </td>
                <td>{moment(submission).format("hh:mm")}</td>
                {formHeaders.map((header, i) => (
                  <td key={`completed_form_header_${i}`}>
                    {formTemplate[header].type === "CHECKBOX"
                      ? submission.completedForm[header]
                        ? "Yes"
                        : "No"
                      : submission.completedForm[header]}
                  </td>
                ))}
                <td>{`${submission.completedBy.firstName} ${submission.completedBy.lastName}`}</td>
                <td />
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

QCPDFReport.propTypes = {
  submissions: PropTypes.array,
  formTemplate: PropTypes.object
};

export default QCPDFReport;
