import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Divider, Icon, Menu } from "semantic-ui-react";
import { Link } from "react-router-dom";
import styles from "./MenuLeft.module.css";
import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";
import { selectMenuItem } from "../../actions/Menu";
import CollapseBlock from "../CollapseBlock";
import { pageChecker } from "actions/HourlyPayroll/hourlyPayroll";

//  inside
function selectFirstChildInCaseOfOne(menu) {
  if (menu.children && menu.children.filter(item => item.menu).length === 1) {
    return selectFirstChildInCaseOfOne(menu.children[0]);
  }

  return menu;
}

const MenuSection = ({
  main = false,
  section,
  location,
  pathname,
  selectedMenu,
  headerMenuChecker,
  actions: { selectMenuItem },
  withoutLabel = false
}) => {
  const dispatch = useDispatch();
  const currSection = useMemo(
    () =>
      (
        (main &&
          selectedMenu &&
          selectedMenu.children &&
          section.find(s => s.name === selectedMenu.name)?.children) ||
        section
      )
        .filter(menu => menu.menu)
        .map(menu => selectFirstChildInCaseOfOne(menu)),
    [main, selectedMenu, section]
  );

  const active = useMemo(
    () =>
      currSection
        .filter(
          menu =>
            pathname?.indexOf(menu.href) >= 0 ||
            (Array.isArray(menu.path) && menu.path.includes(pathname))
        )
        .sort((m1, m2) => m2.href.length - m1.href.length)[0],
    [currSection, pathname]
  );

  const getSubElements = dropdownMenu =>
    currSection.filter(item => item.dropDownID === dropdownMenu.id);

  const renderDropdown = dropdownMenu => {
    const elements = getSubElements(dropdownMenu);

    return elements.map((children, i) => {
      const isActive = active ? children.href === active.href : false;
      return (
        <Menu.Item
          className={`${styles.menuLink} ${children.className || ""}`}
          key={`main_${children.name}_${children.path}`}
          active={isActive}
        >
          <Link
            key={`el_${i}`}
            to={children.disabled ? "#" : children.href}
            className={children.disabled ? styles.menuDisabled : ""}
            active={isActive}
          >
            {children.icon && (
              <Icon
                {...(dropdownMenu.icon?.indexOf("tuf-") === 0
                  ? { className: children.icon }
                  : { name: children.icon })}
              />
            )}
            {children.name}
          </Link>
        </Menu.Item>
      );
    });
  };

  const renderMenu = menu =>
    !menu.children ? (
      <Link
        onClick={() => {
          (menu.component === "SprayTasks" ||
            menu.component === "GeneralTasksList") &&
            dispatch(pageChecker(false));
        }}
        to={menu.disabled ? "#" : menu.href}
        className={menu.disabled ? styles.menuDisabled : ""}
      >
        {menu.icon && (
          <Icon
            {...(menu.icon?.indexOf("tuf-") === 0
              ? { className: menu.icon }
              : { name: menu.icon })}
          />
        )}
        {!withoutLabel && menu.name}
        {menu.notificationsCount > 0 && (
          <span className={styles.pendingOperationCount}>
            {menu.notificationsCount}
          </span>
        )}
      </Link>
    ) : (
      <Link
        to={
          menu.disabled
            ? "#"
            : menu.href || (menu.children && menu.children[0].href)
        }
        onClick={() => {
          menu.name === "Tasks" && dispatch(pageChecker(false));
          selectMenuItem({ parent: selectedMenu, ...menu });
        }}
        className={menu.disabled ? styles.menuDisabled : ""}
      >
        {menu.icon && (
          <Icon
            {...(menu.icon?.indexOf("tuf-") === 0
              ? { className: menu.icon }
              : { name: menu.icon })}
          />
        )}
        {!withoutLabel && menu.name}
        {menu.notificationsCount > 0 && (
          <span className={styles.pendingOperationCount}>
            {menu.notificationsCount}
          </span>
        )}
      </Link>
    );

  const checkOpened = menu => {
    const hrefs = getSubElements(menu).map(menu => menu.href);
    return hrefs.includes(pathname);
  };

  return (
    <>
      {!headerMenuChecker ? (
        main &&
        selectedMenu && (
          <Menu.Item className={styles.menuLink} active={false}>
            <Link
              to={
                location.pathname === "/tasks"
                  ? location.hash
                  : selectedMenu.group === "headerMenu"
                  ? ""
                  : `#`
              }
              onClick={() => selectMenuItem(selectedMenu.parent)}
            >
              <Icon className={"arrow alternate circle left outline"} />
              Back to{" "}
              {selectedMenu.parent &&
              selectedMenu.parent.name &&
              !headerMenuChecker
                ? selectedMenu.parent.name
                : "main menu"}
            </Link>
            <Divider />
          </Menu.Item>
        )
      ) : (
        <Menu.Item className={styles.menuLink} active={false}>
          <Link
            to={""}
            /*  onClick={() => selectMenuItem(selectedMenu.parent)} */
          >
            <Icon className={"arrow alternate circle left outline"} />
            Back to main menu
          </Link>
          <Divider />
        </Menu.Item>
      )}

      {currSection
        .filter(menu => !menu.dropDownID)
        .map(menu => {
          return (
            <Menu.Item
              className={`${styles.menuLink} ${menu.className || ""}`}
              key={`main_${menu.name}_${menu.path}`}
              active={menu === active}
            >
              {menu.group === "dropdown"
                ? getSubElements(menu).length > 0 && (
                    <CollapseBlock
                      defaultClosed={!checkOpened(menu)}
                      fontSize={14}
                      paddingBottom={0}
                      className={styles.dropdownItem}
                      icon={menu.icon}
                      title={menu.name}
                    >
                      {renderDropdown(menu)}
                    </CollapseBlock>
                  )
                : renderMenu(menu)}
            </Menu.Item>
          );
        })}
    </>
  );
};

MenuSection.propTypes = {
  main: PropTypes.bool,
  selectedMenu: PropTypes.any,
  section: PropTypes.any,
  pathname: PropTypes.string,
  actions: PropTypes.object
};

const mapStateToProps = state => {
  const {
    menu: { selectedMenu },
    generalTasks: { location }
  } = state;
  return {
    selectedMenu,
    location
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ selectMenuItem }, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuSection);
