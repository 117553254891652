import React from "react";
import Checkbox from "../components/Checkbox";
import { getHashParameters } from "utils/hashToObject";
import filterTypes from "../filterTypes";
import styles from "../components/Table.module.css";

export const addSelectColumn = hooks =>
  hooks.visibleColumns.push(columns => [
    {
      id: "selection",
      Header: ({
        getToggleAllRowsSelectedProps,
        toggleAllRowsSelected,
        rows
      }) => (
        <div className={styles.checkboxContainer}>
          <Checkbox
            className={styles.tableCheckbox}
            {...getToggleAllRowsSelectedProps()}
            onChange={() => {
              const isAnySelected = rows.some(r => r.isSelected);
              toggleAllRowsSelected(!isAnySelected);
            }}
          />
        </div>
      ),
      Cell: ({ row }) => (
        <div className={styles.checkboxContainer}>
          <Checkbox
            className={styles.tableCheckbox}
            {...row.getToggleRowSelectedProps()}
          />
        </div>
      ),
      disableSortBy: true,
      className: styles.checkboxCell,
      headerClassName: styles.selectHeader,
      cellWidth: "auto"
    },
    ...columns
  ]);

export const getTableInitialState = ({
  pageIndex,
  pageSize,
  defaultSort,
  selectedItems
}) => {
  const initialStateObject = {
    pageIndex,
    pageSize
  };

  if (defaultSort) {
    const [id, order] = defaultSort.split(",");
    const desc = order === "desc";

    initialStateObject.sortBy = [{ id, desc }];
  }

  if (selectedItems) {
    initialStateObject.selectedRowIds = selectedItems.reduce((acc, curr) => {
      acc[curr] = true;
      return acc;
    }, {});
  }

  return initialStateObject;
};

export const changeHandler = ({
  search,
  size,
  filters,
  sortBy,
  page,
  getData,
  data
}) => {
  const sort = sortBy.map(({ id, desc }) =>
    [id, desc ? "asc" : "desc"].join(",")
  );

  getData &&
    getData({
      page,
      size,
      search,
      filters,
      sort
    });
};

export const parseFilters = (options = [], location) => {
  const params = getHashParameters(location);

  return options
    .filter(option => params[option.id])
    .reduce((acc, filter) => {
      if (filter.type === filterTypes.MultiSelect) {
        acc[filter.id] = params[filter.id].split(",");
      } else {
        acc[filter.id] = params[filter.id];
      }

      return acc;
    }, {});
};
