export const actionType = {
  CHECK_API_STATUS_START: "COMMON/CHECK_API_STATUS_START",
  CHECK_API_STATUS: "COMMON/CHECK_API_STATUS",
  CHECK_API_STATUS_FAIL: "COMMON/CHECK_API_STATUS_FAIL",
  FORCE_UPDATE_APP: "COMMON/FORCE_UPDATE_APP",
  SET_USER_CHECKED: "COMMON/SET_USER_CHECKED",
  PURGE_ALL_DATA: "COMMON/PURGE_ALL_DATA",
  SET_VERSION_CHECKED: "COMMON/SET_VERSION_CHECKED",
  GET_BACKEND_BUILD_NUMBER_START: "COMMON/GET_BACKEND_BUILD_NUMBER_START",
  GET_BACKEND_BUILD_NUMBER: "COMMON/GET_BACKEND_BUILD_NUMBER",
  GET_BACKEND_BUILD_NUMBER_FAIL: "COMMON/GET_BACKEND_BUILD_NUMBER_FAIL"
};
