export const validationRegex = /^E(?<employeeId>\d{7,})[A-Z](?<barcode>\d{10,})$/;

export const generateBarcodeSeries = (employee, firstBarcode, lastBarcode) => {
  let series = [];

  for (let number = firstBarcode; number <= lastBarcode; number++) {
    series.push({
      employee: employee,
      number: number,
      employeePart: toBarcodeEmployeePartFormat(employee),
      numberPart: toBarcodeNumberPartFormat(number),
      barcode: toBarcodeFormat(employee, number),
    });
  }

  return series;
};

export const toBarcodeFormat = (employee, barcode) => {
  return `${toBarcodeEmployeePartFormat(employee)}${toBarcodeNumberPartFormat(barcode)}`;
};

export const toBarcodeNumberPartFormat = barcode => {
  return `B${String(barcode).padStart(10, '0')}`;
};

export const toBarcodeEmployeePartFormat = employee => {
  return `E${String(employee.id).padStart(7, '0')}`;
};

export const barcodeToProps = barcode => {
  const props = validationRegex.exec(barcode);
  return {
    barcode,
    employee: { id: props.groups.employeeId },
  };
};

export const validateBarcode = barcode => {
  return validationRegex.exec(barcode) !== null;
};
