import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Dropdown, Icon } from "semantic-ui-react";
import { history } from "../../store";
import { hashToObject, objectToHash } from "../../utils/hashToObject";
import { Mixpanel } from "../../tools/mixpanel/Mixpanel";
import { MixpanelEvents } from "../../tools/mixpanel/MixpanelEvents";
import styles from "./SortDropdown.module.css";

const getSortOption = (sortOptions, sortValue, currentSortOrder) => {
  const targetOption = sortOptions.find(option => option.value === sortValue);
  const sort =
    targetOption && targetOption.sortfields
      ? targetOption.sortfields
          .filter(sortItem => sortItem !== "none")
          .map(sortItem => `${sortItem},${currentSortOrder}`)
      : null;
  return sort && sort.length > 0 ? sort : [];
};
const sortRef = React.createRef();

function SortDropdown({
  sortOptions,
  sortOrder,
  sortValue,
  onChange,
  sortName = "",
  location,
  dispatch,
  defaultSort,
  silentOnMount,
  ...rest
}) {
  const sortKey = sortName || "sort";
  const [currentSortValue, setSortValue] = useState(sortValue);

  useEffect(
    () => {
      if (currentSortValue !== sortValue) {
        setSortValue(sortValue);
      }
    },
    [sortValue] // eslint-disable-line
  );

  useEffect(() => {
    const hashObject = hashToObject(location.hash);
    if (defaultSort && !hashObject[sortKey]) {
      const sort = defaultSort.split(",");
      onSortClick(null, { value: sort[0], order: sort[1] });
    } else if (!hashObject[sortKey] && !defaultSort && !silentOnMount) {
      onChange({
        sortValue: "none",
        sortOrder: null,
        sort: []
      });
    }
  }, []); // eslint-disable-line

  // useEffect(() => {
  //   const hashObject = hashToObject(location.hash);

  //   if (hashObject[sortKey]) {
  //     const sortArray = hashObject[sortKey].split(",");

  //     if (
  //       sortOptions.length &&
  //       (sortArray[0] !== sortValue || sortArray[1] !== sortOrder)
  //     ) {
  //       onChange({
  //         sortValue: sortArray[0],
  //         sortOrder: sortArray[1],
  //         sort: getSortOption(sortOptions, sortArray[0], sortArray[1]),
  //       });
  //     }
  //   }
  // }, [sortOptions, onChange, location, sortOrder, sortValue]);

  const onSortClick = useCallback(
    (_, menuItem) => {
      const currentSortValue = menuItem.value;
      let currentSortOrder = menuItem.order || null;
      if (
        sortValue === currentSortValue &&
        currentSortValue !== "none" &&
        !menuItem.order
      ) {
        currentSortOrder = !sortOrder
          ? "asc"
          : sortOrder === "asc"
          ? "desc"
          : "asc";
      } else if (currentSortValue !== "none" && !menuItem.order) {
        currentSortOrder = "asc";
      }
      if (
        currentSortValue !== sortValue ||
        sortOrder !== currentSortOrder ||
        menuItem.order
      ) {
        const sortObject = {
          sortValue: currentSortValue,
          sortOrder: currentSortOrder
        };
        Mixpanel.track(MixpanelEvents.SORT, sortObject);
        onChange({
          ...sortObject,
          sort: getSortOption(sortOptions, currentSortValue, currentSortOrder)
        });
      }
      setSortValue(currentSortValue);
      const hashObject = hashToObject(location.hash);
      if (currentSortValue === "none") {
        delete hashObject[sortKey];
      } else {
        hashObject[sortKey] = `${currentSortValue},${currentSortOrder}`;
      }

      const hash = objectToHash(hashObject);
      history.replace({
        pathname: location.pathname,
        hash: `${hash ? `#${hash}` : ""}`,
        state: location.state
      });
    },
    [location, onChange, sortOptions, sortOrder, sortValue]
  );

  const changeSortOrder = useCallback(() => {
    onSortClick(null, { value: sortValue });
  }, [onSortClick, sortValue]);

  const extSortOptions = useMemo(() => {
    return [
      {
        key: "none",
        text: "Click to Sort",
        value: "none",
        content: "Reset sorting"
      },
      ...sortOptions
    ].map(item => ({ ...item, onClick: onSortClick }));
  }, [onSortClick, sortOptions]);

  return (
    <span className={styles.sortHolder}>
      {sortOrder && (
        <Icon
          name={`sort${
            sortOrder === "asc"
              ? " amount down"
              : sortOrder === "desc"
              ? " amount up"
              : ""
          }`}
          onClick={changeSortOrder}
          className={styles.sortOrder}
        />
      )}

      <Dropdown
        inline
        className={styles.dropdownSort}
        options={extSortOptions}
        value={currentSortValue}
        ref={sortRef}
        icon={<Icon className="tuf-chevron-down" />}
        {...rest}
      />
    </span>
  );
}

SortDropdown.propTypes = {
  defaultSort: PropTypes.string,
  dispatch: PropTypes.func,
  location: PropTypes.object,
  onChange: PropTypes.func,
  pageSize: PropTypes.number,
  silentOnMount: PropTypes.bool,
  sortName: PropTypes.string,
  sortOptions: PropTypes.array,
  sortOrder: PropTypes.string,
  sortValue: PropTypes.string
};

SortDropdown.defaultProps = {
  onChange: () => {},
  sortName: ""
};

function mapStateToProps(state) {
  const {
    router: { location }
  } = state;
  return {
    location
  };
}

export default memo(connect(mapStateToProps)(SortDropdown));
