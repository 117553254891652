import { Area } from "../../../models/block.model";
import _ from "lodash";

export const collectBlockData = (task, areasList, showParentBlocks) => {
  let data = {
    blocksNames: [],
    hectares: 0,
    plannedLiters: 0,
    plannedTanks: 0,
    actualTanks: 0,
    doneCount: 0,
    donePercent: 0,
    assignee: task.assignee
      ? `${task.assignee.firstName.charAt(0)}${task.assignee.lastName.charAt(
          0
        )}`
      : null
  };
  if (!(task.areas && task.areas.length)) return data;
  const parentBlocks = areasList.blocks
    .filter(a => !a.parent && !a.archived)
    .reduce((acc, area) => {
      acc[area.id] = {
        ...area,
        areaIDs: [],
        areaSize: [],
        varietiesList: [],
        tanks: 0,
        plannedLiters: 0
      };
      return acc;
    }, {});

  areasList.patches
    .filter(a => a.parent && !a.archived)
    .forEach(a => {
      parentBlocks[a.parent.id].areaIDs.push(a.id);
      parentBlocks[a.parent.id].areaSize.push(a.size);
    });

  const listOfBlocks = {};
  const arrayOfAllParentBlocks = Object.values(parentBlocks);
  const selectedAreasIndexed = Object.fromEntries(
    task.areas.map(areas => [areas.area.id, areas])
  );

  arrayOfAllParentBlocks.forEach(area => {
    const selectedPatchesIDs = _.intersection(
      area.areaIDs,
      task.areas.map(({ area }) => area.id)
    );
    if (
      !_.isEmpty(area.areaIDs) &&
      _.isEqual(area.areaIDs, selectedPatchesIDs)
    ) {
      listOfBlocks[area.id] = {
        id: area.id,
        name: area.name,
        hectares: area.areaSize.reduce((a, b) => a + b, 0),
        areaIDs: area.areaIDs,
        plannedLiters: 0,
        done: task.areas
          .filter(a => selectedPatchesIDs.includes(a.area.id))
          .every(a => a.done),
        parent: null,
        variety: "",
        blockType: "BLOCK",
        plannedTanks: 0,
        actualTanks: 0
      };
    }
  });

  const arrayOfBlocks = Object.values(listOfBlocks);
  arrayOfBlocks.map(b => {
    b.areaIDs.forEach(id => {
      listOfBlocks[b.id].plannedLiters +=
        selectedAreasIndexed[id].area.plannedLiters;
      listOfBlocks[b.id].plannedTanks += selectedAreasIndexed[id].area.tanks;
      listOfBlocks[b.id].variety += `${selectedAreasIndexed[
        id
      ].area.varieties.map(v => v.variety.name)} - ${selectedAreasIndexed[
        id
      ].area.varieties.map(v => v.variety.crop.name)}\n`;
    });
  });

  const areasToShow = (() => {
    const filteredSelectedAreas = { ...selectedAreasIndexed };
    const listOfFilteredPatches = {};
    if (arrayOfBlocks.length >= 1) {
      arrayOfBlocks.map(block => {
        block.areaIDs.map(id => {
          listOfFilteredPatches[id] = filteredSelectedAreas[id];
          delete filteredSelectedAreas[id];
        });
      });
    }

    const arrayOfBlocksWithData = arrayOfBlocks.map(block => {
      const temp = {
        hectares: 0,
        plannedLiters: 0,
        plannedTanks: 0,
        actualTanks: 0
      };
      block.areaIDs.forEach(id => {
        temp.hectares += parseFloat(listOfFilteredPatches[id].hectares);
        temp.plannedLiters += parseFloat(
          listOfFilteredPatches[id].plannedLiters
        );
        temp.plannedTanks += parseFloat(listOfFilteredPatches[id].plannedTanks);
        temp.actualTanks += parseFloat(listOfFilteredPatches[id].actualTanks);
      });
      return { ...block, ...temp };
    });

    return Object.values(filteredSelectedAreas)
      .map(b => ({ ...b, area: b, name: b.area.name }))
      .concat(arrayOfBlocksWithData.map(b => ({ ...b, area: b })));
  })();

  const areasToPrint = showParentBlocks ? task.areas : areasToShow;

  data = areasToPrint.reduce((data, block) => {
    const currentActualTanks =
      block.actualTanks !== "" &&
      block.actualTanks !== null &&
      !isNaN(block.actualTanks)
        ? block.actualTanks
        : block.plannedTanks;
    if (block.area) {
      const area = new Area(block.area);
      data.blocksNames.push({
        name: block.area.name || block.name,
        id: block.area.id,
        parent: block.area.parent,
        variety: area.areaVarietyByDate(task.plannedDate),
        hectares: block.hectares ? block.hectares : null,
        blockType: block.type === "BLOCK" ? "block" : "patch",
        plannedLiters: block.plannedLiters ? block.plannedLiters : null,
        plannedTanks: block.plannedTanks ? block.plannedTanks : null,
        actualTanks: currentActualTanks,
        done: block.done
      });
    }
    data.hectares += block.hectares;
    data.plannedTanks += block.plannedTanks;
    data.plannedLiters += block.plannedLiters;
    data.actualTanks += currentActualTanks;
    data.doneCount = block.done ? data.doneCount + 1 : data.doneCount;
    return data;
  }, data);
  data.donePercent = ((data.doneCount / task.areas.length) * 100).toFixed(0);

  return data;
};
