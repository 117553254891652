import moment from "moment";
import { get, sumBy } from "lodash";
import AreasCell from "routes/Scouting/ScoutingList/components/AreasCell/AreasCell";
import React from "react";
import { Popup } from "semantic-ui-react";

export const SCOUTING_TABLE_COLUMNS = {
  id: {
    title: "Task ID",
    accessor: ({ id }) => `SC-${id}`,
    filter: {
      type: "ScoutingElementFilter"
    }
  },
  setOn: {
    title: "Date",
    accessor: d => d.setOn && moment(d.setOn).format("DD/MM/YYYY"),
    filter: {
      type: "DateRangeFilter"
    },
    minWidth: 120
  },
  name: {
    title: "Task name",
    accessor: ({ name }) => name,
    Cell: ({ value, column: { Header } }) => (
      <>
        <div className="cellTitle hide-md">{Header}</div>
        <Popup
          content={value}
          position="top center"
          inverted
          trigger={<div className="cellValue">{value}</div>}
        />
      </>
    )
  },
  farm: {
    title: "Farm",
    accessor: task => {
      return get(task, "areas[0].area.farm.name");
    },
    filter: {
      type: "ScoutingElementFilter"
    }
  },
  area: {
    title: "Block/Patch",
    accessor: ({ areas }) =>
      Array.isArray(areas) && areas.map(({ area }) => area.name),
    Cell: AreasCell,
    filter: {
      type: "AreaMultiSelectFilter"
    }
  },
  issues: {
    title: "Issues reported",
    accessor: ({ areas }) => sumBy(areas, "areaIssues.length") || "",
    style: {
      textAlign: "right"
    },
    headerStyle: {
      textAlign: "right"
    }
  },
  creatorIds: {
    title: "Creator",
    sortFields: ["creator"],
    accessor: task => {
      if (task.creator) {
        return task.creator.firstName + task.creator.lastName;
      }
    },
    filter: {
      type: "ScoutingElementFilter"
    },
    style: {
      textAlign: "right"
    },
    headerStyle: {
      textAlign: "right"
    }
  },
  empty: {
    title: "",
    accessor: () => {},
    disableSort: true,
    filter: {
      type: "SeasonSingleSelectFilter"
    }
  }
};
