import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  Form,
  Grid,
  Segment,
  Button,
  Icon,
  Header,
  Container,
  Popup
} from "semantic-ui-react";
import { useDispatch, useSelector, connect } from "react-redux";
import { debounce, map, isEmpty } from "lodash";
import { FastField, useFormikContext, FieldArray, Field } from "formik";
import CollapsibleBlock from "components/CollapsibleBlock";
import styles from "./batchNumberField.module.css";
import { getBatchNumbersLastYear } from "actions/Spray/tasks";
import { createTask } from "actions/SprayDiary/common";

const BatchNumberField = ({ item, batchNumbers, onChange, suffix }) => {
  const { values, setFieldValue, setValues } = useFormikContext();
  const [newBatchNumberShow, setNewBatchNumberShow] = useState(false);

  const shownOptions = batchNumbers.data
    .find(({ data, id }) => id == item.id)
    ?.data.map(batchNumber => ({
      value: batchNumber,
      text: batchNumber,
      key: batchNumber
    }));

  useEffect(() => {
    if (isEmpty(values.batchNumbers)) {
      setNewBatchNumberShow(true);
    }
  }, [values.batchNumbers]);

  return (
    <Form className={styles.root}>
      <Grid>
        <Grid.Column className={styles.chemicalPropertyColumn}>
          <Form.Field>
            <FieldArray
              name="batchNumbers"
              render={arrayHelpers => (
                <div>
                  {map(values.batchNumbers, (batchNumber, index) => (
                    <Segment className={styles.ingredientSection}>
                      <Header
                        floated="left"
                        className={styles.ingredientHeader}
                        size="small"
                      >
                        {batchNumber}
                      </Header>
                      <Popup
                        content="Delete active batch number"
                        position="bottom center"
                        inverted
                        trigger={
                          <Icon
                            name="trash alternate"
                            className={styles.removeActiveIngredient}
                            onClick={() => {
                              const filteredValues = values.batchNumbers.filter(
                                (item, indx) => indx !== index
                              );
                              return (
                                arrayHelpers.remove(index),
                                onChange(filteredValues, "batchNumbers", suffix)
                              );
                            }}
                          />
                        }
                      />
                    </Segment>
                  ))}
                  {newBatchNumberShow && (
                    <Field
                      fluid
                      as={Form.Select}
                      allowAdditions
                      search
                      name="batchNumbers"
                      onChange={(_, { value }) => {
                        return (
                          arrayHelpers.push(value),
                          setNewBatchNumberShow(false),
                          setFieldValue(
                            "batchNumbers",
                            values.batchNumbers
                              ? [...values.batchNumbers, value]
                              : [value]
                          ),
                          onChange(
                            values.batchNumbers
                              ? [...values.batchNumbers, value]
                              : [value],
                            "batchNumbers",
                            suffix
                          )
                        );
                      }}
                      options={shownOptions}
                    />
                  )}

                  <Container fluid className={styles.textButtonContainer}>
                    <Button
                      type="button"
                      onClick={() => setNewBatchNumberShow(true)}
                      className={styles.textButton}
                      disabled={newBatchNumberShow}
                    >
                      <Icon name="plus" />
                      Add batch number
                    </Button>
                  </Container>
                </div>
              )}
            />
          </Form.Field>
        </Grid.Column>
      </Grid>
    </Form>
  );
};
const mapStateToProps = state => {
  return {
    batchNumbers: state.spray.batchNumbers
  };
};

export default connect(mapStateToProps)(BatchNumberField);
