import React, { useState } from "react";
import { get } from "lodash";
import { Button, Container, Grid } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import {
  createCostEntering,
  createCostEnteringBatch
} from "actions/GeneralTasks/costEntering";
import { history } from "../../../../store";
import styles from "./AddCost.module.css";
import HeaderMain from "components/HeaderMain/HeaderMain";
import NewCost from "./components";
import { ALT_COST_ENTERING_INITIAL_VALUES } from "constants/GeneralTasks/costEntering";

const AddCost = ({ route }) => {
  const [setMenuOpen, menuOpen] = useState(false);
  const dispatch = useDispatch();
  const REQUIRED_FIELDS = [
    "costType",
    "areaData",
    "costSubType",
    "total",
    "startAt",
    "generalTaskId"
  ];
  const validateValues = async values => {
    const errors = {};
    values.costs.map(item =>
      REQUIRED_FIELDS.forEach(filedName => {
        if (
          filedName !== "areaData" &&
          filedName !== "rate" &&
          filedName !== "total" &&
          !get(item, filedName)
        ) {
          errors[filedName] = "Required field";
        }
        if (
          filedName == "areaData" &&
          item["areaData"].selectedAreas.length === 0
        ) {
          errors["areaData"] = "Required field";
        }
        if (item["units"] && !item["rate"] && !item["total"]) {
          errors["rate"] = "Required field";
          errors["total"] = "Required field";
        }
      })
    );
    return errors;
  };
  const onSubmit = values => {
    if (values.costs.length > 1) {
      const dataNew = values.costs.map((item, index) => {
        const { areaData, units, rate, total, ...rest } = values.costs[index];
        return {
          ...rest,
          areaIds: areaData.selectedAreas
            .filter(
              item => item.type !== "farm" && !item.areaData.children?.length
            )
            .map(area => area.id),
          total: units && rate ? units * rate : total,
          units,
          rate
        };
      });

      dispatch(createCostEnteringBatch(dataNew)).then(() =>
        history.push("/alt_labour_cost_entering")
      );
    } else {
      const { areaData, units, rate, total, ...rest } = values.costs[0];
      const dataNew = {
        ...rest,
        areaIds: areaData.selectedAreas
          .filter(
            item => item.type !== "farm" && !item.areaData.children?.length
          )
          .map(area => area.id),
        total: units && rate ? units * rate : total,
        units,
        rate
      };

      dispatch(createCostEntering(dataNew)).then(() =>
        history.push("/alt_labour_cost_entering")
      );
    }
  };
  return (
    <Formik
      enableReinitialize
      validate={validateValues}
      validateOnChange={true}
      onSubmit={onSubmit}
      initialValues={ALT_COST_ENTERING_INITIAL_VALUES}
    >
      {props => {
        return (
          <Container id="el" fluid className={styles.root}>
            <HeaderMain route={route} menuOpen={menuOpen} online={true} />
            <div className={styles.contentWrapper}>
              <div className={styles.headerContainer}>
                <div className={styles.header}>New cost</div>
              </div>
              <div className={styles.formContainer}>
                <NewCost errors={props.errors} />
              </div>
              <div className={styles.buttonContainer}>
                <div className={styles.buttonWrapper}>
                  <div className={styles.cancelButton}>
                    <Button
                      content="Cancel"
                      color="green"
                      basic
                      onClick={() => history.push("/alt_labour_cost_entering")}
                    />
                  </div>
                  <div className={styles.saveButton}>
                    <Button
                      content={"Save"}
                      color="green"
                      onClick={props.submitForm}
                      disabled={Object.keys(props.errors).length}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Container>
        );
      }}
    </Formik>
  );
};

export default AddCost;
