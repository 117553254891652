import PropTypes from "prop-types";
import React, { useEffect, useState, useRef } from "react";
import {
  DrawingManager,
  GoogleMap,
  Marker,
  MarkerClusterer,
  Polygon,
  useLoadScript
} from "@react-google-maps/api";
import { fetchBinsWeightPerArea } from "../../actions/Dashboard";
import FarmDetailPopup from "./FarmDetailPopup";
import Loader from "../Loader";
import BinDetailPopup from "./BinDetailPopup";
import "./styles.css";
import TagDetailPopup from "./TagDetailPopup";
import generalMarker from "./assets/general_marker.svg";
import HarvestDetailPopup from "./HarvestDetailPopup";
import { connect, useDispatch } from "react-redux";

const mapSettings = {
  id: "script-loader",
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API,
  language: "en",
  libraries: ["drawing", "places"]
};

export const PopupTypes = {
  FarmDetailPopup: "FarmDetailPopup",
  MarkerDetailPopup: "MarkerDetailPopup"
};

const clustererOptions = {
  styles: [
    {
      url: generalMarker,
      height: 30,
      width: 25
    }
  ]
};

function GoogleMaps({
  zoom,
  currentLatLng,
  mapOptions,
  showDrawing,
  drawingDisabled,
  drawingOptions,
  yieldActive,
  markers,
  setPolygonReady,
  onLoadMap,
  yieldDateRange,
  onClustererClick,
  showMap,
  step,
  polygons,
  showInfoWindow,
  children,
  popUpType = PopupTypes.FarmDetailPopup,
  withClustering,
  locations,
  showMachineries,
  isFetching
}) {
  const [tooltipData, setTooltipData] = useState();
  const [activeBlock, toggleActiveBlock] = useState(null);
  const [activeBin, toggleActiveBin] = useState(null);
  const [activeTag, toggleActiveTag] = useState(null);
  const [activeBinCluster, toggleActiveBinCluster] = useState(null);
  const [activeTagCluster, toggleActiveTagCluster] = useState(null);
  const { isLoaded, loadError } = useLoadScript(mapSettings);
  const [timedOut, toggleMapLoad] = useState(false);
  const [isFetchingBinsWeight, setIsFetchingBinsWeight] = useState(false);
  const dispatch = useDispatch();
  const mapRef = useRef(null);

  const calculateBounds = polygons => {
    if (!polygons || polygons.length === 0) {
      return null;
    }

    const bounds = new window.google.maps.LatLngBounds();

    polygons.forEach(polygon => {
      if (polygon.path && Array.isArray(polygon.path)) {
        polygon.path.forEach(latLng => {
          if (
            latLng &&
            typeof latLng.lat === "number" &&
            typeof latLng.lng === "number"
          ) {
            bounds.extend(
              new window.google.maps.LatLng(latLng.lat, latLng.lng)
            );
          }
        });
      }
    });

    return bounds;
  };
  useEffect(() => {
    if (mapRef.current && polygons && polygons.length > 0) {
      const bounds = calculateBounds(polygons);

      if (bounds) {
        const zoomAdjustment = 50 / Math.pow(2, mapRef.current.getZoom());
        const padding = {
          top: zoomAdjustment,
          bottom: zoomAdjustment,
          left: zoomAdjustment,
          right: zoomAdjustment
        };

        mapRef.current.fitBounds(bounds, padding);
      }
    }
  }, [mapRef.current, polygons]);

  if (!timedOut && isLoaded) {
    setTimeout(() => {
      toggleMapLoad(true);
    }, 500);
  }
  const areaId = activeBlock && activeBlock.block.id;
  useEffect(() => {
    async function fetchRequest() {
      setIsFetchingBinsWeight(true);
      const result = await dispatch(
        fetchBinsWeightPerArea(areaId, yieldDateRange)
      );
      setTooltipData(result);
      setIsFetchingBinsWeight(false);
    }

    if (yieldActive && activeBlock) {
      fetchRequest();
    }
  }, [activeBlock]);

  const { binMarkers, tagMarkers, otherMarkers } = markers
    ? markers.reduce(
        (prev, curr) => {
          const isBinMarker = curr.$type === "bin";
          const isTagMarker = curr.$type === "tag";
          return {
            binMarkers: isBinMarker
              ? [...prev.binMarkers, curr]
              : prev.binMarkers,
            // if some of typed markers return the previous value
            otherMarkers:
              isBinMarker || isTagMarker
                ? prev.otherMarkers
                : [...prev.otherMarkers, curr],
            tagMarkers: isTagMarker
              ? [...prev.tagMarkers, curr]
              : prev.tagMarkers
          };
        },
        { binMarkers: [], tagMarkers: [], otherMarkers: [] }
      )
    : { binMarkers: [], tagMarkers: [], otherMarkers: [] };

  useEffect(() => {
    if (binMarkers.length === 0) toggleActiveBinCluster(null);
    if (tagMarkers.length === 0) toggleActiveTagCluster(null);
  }, [binMarkers, tagMarkers]);

  const popupBins = activeBin
    ? [activeBin]
    : activeBinCluster
    ? activeBinCluster
    : [];
  const popupTags = activeTag
    ? [activeTag]
    : activeTagCluster
    ? activeTagCluster
    : [];
  return isLoaded && showMap ? (
    <GoogleMap
      zoom={zoom}
      key={showMap}
      id="farm-map"
      center={currentLatLng}
      options={mapOptions}
      onLoad={map => {
        if (map) {
          mapRef.current = map;
          onLoadMap(map);
        }
      }}
      defaultZoom={3}
    >
      {showDrawing && !drawingDisabled && (
        <DrawingManager
          options={drawingOptions}
          onPolygonComplete={setPolygonReady}
        />
      )}
      {currentLatLng && step === 1 && <Marker position={currentLatLng} />}
      {!isFetching &&
        locations.length &&
        showMachineries &&
        locations.map((item, index) => (
          <Marker
            key={`vehicle_marker_${index}`}
            position={{ lat: item.point.lat, lng: item.point.lon }}
            icon={{
              url:
                "https://www.downloadclipart.net/medium/25858-tractor--images.png",
              scaledSize: new window.google.maps.Size(40, 40) // Adjust the size as needed
            }}
          />
        ))}
      {binMarkers.length && (
        <MarkerClusterer
          averageCenter
          enableRetinaIcons
          zoomOnClick={false}
          gridSize={5}
          onClick={({ markers }) => {
            const normalizedMarkers = markers
              .map(m =>
                binMarkers.filter(
                  bMarker =>
                    bMarker.position.lat === m.position.lat() &&
                    bMarker.position.lng === m.position.lng()
                )
              )
              .flat()
              .map(b => b.$bin);

            const containedUniqueMarkers = [...new Set(normalizedMarkers)];
            toggleActiveBinCluster(
              activeBinCluster ? null : containedUniqueMarkers
            );
          }}
          options={clustererOptions}
        >
          {clusterer =>
            binMarkers.map(marker => (
              <Marker
                key={`marker_${marker.id}`}
                clickable={true}
                onClick={() => toggleActiveBin(activeBin ? null : marker.$bin)}
                clusterer={clusterer}
                {...marker}
              />
            ))
          }
        </MarkerClusterer>
      )}
      {tagMarkers.length && (
        <MarkerClusterer
          averageCenter
          enableRetinaIcons
          zoomOnClick={false}
          gridSize={5}
          onClick={({ markers }) => {
            const normalizedMarkers = markers
              .map(m =>
                tagMarkers.filter(
                  bMarker =>
                    bMarker.position.lat === m.position.lat() &&
                    bMarker.position.lng === m.position.lng()
                )
              )
              .flat()
              .map(b => b.$tag);

            const containedUniqueMarkers = [...new Set(normalizedMarkers)];
            toggleActiveTagCluster(
              activeTagCluster ? null : containedUniqueMarkers
            );
          }}
          options={clustererOptions}
        >
          {clusterer =>
            tagMarkers.map(marker => (
              <Marker
                key={`marker_${marker.id}`}
                clickable={true}
                onClick={() => toggleActiveTag(activeTag ? null : marker.$tag)}
                clusterer={clusterer}
                {...marker}
              />
            ))
          }
        </MarkerClusterer>
      )}
      {otherMarkers.length && (
        <>
          {withClustering ? (
            <MarkerClusterer
              minimumClusterSize={4}
              onClick={onClustererClick}
              options={clustererOptions}
            >
              {clusterer =>
                otherMarkers.map(marker => (
                  <Marker
                    key={`marker_${marker.id}`}
                    clusterer={clusterer}
                    {...marker}
                  />
                ))
              }
            </MarkerClusterer>
          ) : (
            <>
              {otherMarkers.map(marker => (
                <Marker key={`marker_${marker.id}`} {...marker} />
              ))}
            </>
          )}
        </>
      )}
      {polygons &&
        polygons.map(polygon => (
          <Polygon
            key={`polygon_${polygon.id}`}
            {...polygon}
            onMouseOver={() => toggleActiveBlock(polygon)}
            onMouseOut={() => toggleActiveBlock(null)}
            options={{ zIndex: polygon.zIndex }}
          />
        ))}
      {showInfoWindow &&
        (popUpType === PopupTypes.FarmDetailPopup
          ? activeBlock
          : popupBins || popupTags) &&
        (popUpType === PopupTypes.FarmDetailPopup ? (
          <FarmDetailPopup block={activeBlock} />
        ) : (
          <>
            {popupBins.length && (
              <BinDetailPopup
                bins={popupBins}
                clearInfo={() =>
                  toggleActiveBinCluster(null) && toggleActiveBin(null)
                }
              />
            )}
            {popupTags.length && (
              <TagDetailPopup
                tags={popupTags}
                clearInfo={() =>
                  toggleActiveTagCluster(null) && toggleActiveTag(null)
                }
              />
            )}
          </>
        ))}
      {yieldActive && activeBlock && (
        <HarvestDetailPopup
          tooltipData={tooltipData}
          block={activeBlock}
          isFetching={isFetchingBinsWeight}
        />
      )}
      {children}
    </GoogleMap>
  ) : loadError ? (
    <div>Sorry. Error while Google Map loading</div>
  ) : (
    <Loader />
  );
}

GoogleMaps.propTypes = {
  children: PropTypes.any,
  currentLatLng: PropTypes.object,
  drawingDisabled: PropTypes.bool,
  drawingOptions: PropTypes.object.isRequired,
  mapOptions: PropTypes.object,
  markers: PropTypes.array,
  onLoadMap: PropTypes.func,
  onClustererClick: PropTypes.func,
  polygons: PropTypes.array,
  popUpType: PropTypes.string,
  route: PropTypes.object.isRequired,
  setPolygonReady: PropTypes.func,
  setSelectedImage: PropTypes.func,
  showDrawing: PropTypes.bool,
  showInfoWindow: PropTypes.bool,
  showMap: PropTypes.bool,
  step: PropTypes.number,
  withClustering: PropTypes.bool,
  zoom: PropTypes.number.isRequired
};

GoogleMaps.defaultProps = {
  drawingOptions: {},
  popUpType: PopupTypes.FarmDetailPopup,
  route: { name: "{%name%}" },
  setSelectedImage: () => {},
  zoom: 18,
  withClustering: false
};

function mapStateToProps(state) {
  const {
    johnDeere: { machines, checkTokenStatus, locations, isFetching }
  } = state;
  return {
    isFetching,
    machines,
    checkTokenStatus,
    locations
  };
}

export default connect(mapStateToProps)(GoogleMaps);
