import { addValidationRule } from "formsy-react";
import { validateBarcode } from "./employeesBarcodes";

export default (function() {
  addValidationRule("shouldBeEmpty", function(values, value) {
    return !value;
  });
  addValidationRule("isRequired", function(values, value) {
    return !!value;
  });
  addValidationRule("isNotNegativeFloat", function(values, value) {
    return !isNaN(value) && Number(value) >= 0;
  });
  addValidationRule("isNotNegativeFloatOrEmpty", function(values, value) {
    return !value || (!isNaN(value) && Number(value) >= 0);
  });

  addValidationRule("isNotNegativeInteger", function(values, value) {
    return Number.isInteger(Number(value)) && Number(value) >= 0;
  });
  addValidationRule("isPositiveInteger", function(values, value) {
    return Number.isInteger(Number(value)) && Number(value) >= 0;
  });
  addValidationRule("isPositive", function(values, value) {
    return value > 0;
  });
  addValidationRule("isURL", function(values, value) {
    return value === "" || isValidURL(value);
  });
  addValidationRule("isExtraParameterTrue", function(
    values,
    value,
    extraParameter
  ) {
    return !extraParameter;
  });
  addValidationRule("isMinBiggerThanMax", function(values, value) {
    return Number(values["crop.minRate"]) <= Number(values["crop.maxRate"]);
  });
  addValidationRule("smallLettersOnly", function(values, value) {
    const pattern = /^[a-z0-9_]*$/;
    return value ? value.match(pattern) : false;
  });
  addValidationRule("atLeaseOnSelected", function(values, value) {
    return value && Array.isArray(value) && value.length > 0;
  });
  addValidationRule("AtLeaseOneBarcode", function(values, value) {
    return value > 0;
  });
  addValidationRule("upTo1000", function(values, value) {
    return value <= 1000;
  });
  addValidationRule("upTo10000", function(values, value) {
    return value <= 10000;
  });
  addValidationRule("validBarcode", function(values, value) {
    return validateBarcode(value);
  });
  addValidationRule("maxPalletsCount", function(values, value) {
    return value >= 0;
  });
})();

function isValidURL(string) {
  let res = string.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g // eslint-disable-line
  );
  return res !== null;
}

const errorsMessages = {
  isDefaultRequiredValue: "Required field: {addValue}",
  shouldBeEmpty: "Field should be empty {addValue}",
  isRequired: "Required",
  isEmail: "Email not valid",
  isURL: "URL not valid",
  isNotNegativeFloat: "Value should be equal or bigger than 0",
  isNotNegativeFloatOrEmpty: "Value should be equal or bigger than 0",
  isNotNegativeInteger: "Value should be integer and equal or bigger than 0",
  isPositiveInteger: "Value should be integer and positive",
  isExtraParameterTrue: "{addValue}",
  isPositive: "Value should be positive",
  isMinBiggerThanMax: "Max rate should be bigger than min rate",
  smallLettersOnly: "Only small letters, digits and _",
  maxLength: "Field should have less or equal to {addValue} characters",
  atLeaseOnSelected: "Select at least one entity",
  upTo1000: "Value should be up to 1000",
  upTo10000: "Value should be up to 10000",
  validBarcode: "Invalid barcode format",
  AtLeaseOneBarcode: "At lease one barcode should be scanned",
  maxPalletsCount: "Only {addValue} pallets available for allocation"
};

export function validationErrors(fieldsToChange = {}) {
  const customErrors = { ...errorsMessages };
  Object.keys(fieldsToChange).forEach(field => {
    customErrors[field] = customErrors[field]
      .split("{addValue}")
      .join(fieldsToChange[field]);
  });
  return customErrors;
}

export function formatInputFloat(value) {
  if (!value) {
    return value;
  }

  return value
    .toString()
    .replace(/[^0-9.]/g, "")
    .split(".")
    .slice(0, 2)
    .join(".");
}
export function getErrorFields(formsyForm) {
  return formsyForm && formsyForm.inputs
    ? formsyForm.inputs
        .filter(input => !input.state.isValid)
        .reduce((errors, input) => {
          errors[input.props.name] = true;
          return errors;
        }, {})
    : {};
}
