import React from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';

import styles from './EmployeesBarcodesPrintLayout.module.css';
import { generateBarcodeSeries } from '../../../utils/employeesBarcodes';

const EmployeesBarcodesPrintLayout = ({ employeeBarcodes }) => {
  const barcodes = generateBarcodeSeries(
    employeeBarcodes.employee,
    employeeBarcodes.firstBarcode,
    employeeBarcodes.lastBarcode,
  );

  return (
    <div className={styles.block}>
      <div className={styles.page}>
        {Array.from(Array(barcodes.length).keys())
          .filter(i => i % 4 === 0)
          .map(pageOffset => (
            <div key={`page_${pageOffset}`} className={styles.pageBreak}>
              {[0, 1, 2, 3].map(itemOffset => {
                const i = pageOffset + itemOffset;
                const barcode = barcodes[i];
                return (
                  i < barcodes.length && (
                    <div key={barcode.barcode} className={styles.barcodeHolder}>
                      <div key={barcode.barcode} className={styles.barcodeDataHolder}>
                        <QRCode renderAs="svg" className={styles.barcode} value={barcode.barcode} />
                        <div className={styles.textHolder}>
                          <div className={styles.text}>{barcode.numberPart}</div>
                          <div className={styles.text}>{barcode.employeePart}</div>
                          <div className={styles.text}>
                            {barcode.employee.firstName} {barcode.employee.lastName && barcode.employee.lastName[0]}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                );
              })}
            </div>
          ))}
      </div>
    </div>
  );
};

EmployeesBarcodesPrintLayout.propTypes = { employeeBarcodes: PropTypes.array.isRequired };

EmployeesBarcodesPrintLayout.defaultProps = {};

export default EmployeesBarcodesPrintLayout;
