import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactTable from "react-table-6";
import styles from "./SprayRecords.module.css";
import { Area } from "../../../models/block.model";
import Pagination from "../../../components/ListTable/Pagination";

const columnsNames = {
  plannedDate: {
    title: "Planned Date"
  },
  id: {
    title: "Id"
  },
  status: {
    title: "Status"
  },
  areaId: {
    title: "Area id"
  },
  area: {
    title: "Area"
  },
  variety: {
    title: "Variety"
  },
  chemical: {
    title: "Chemical"
  },
  purpose: {
    title: "Purpose"
  },
  resistanceGroup1: {
    title: "Resistance group"
  },
  activeIngredient: {
    title: "Active ingredient"
  },
  ratePer100L: {
    title: "Rate/100L"
  },
  ratePerHectare: {
    title: "Rate/Ha"
  },
  literPerHectare: {
    title: "Liter/Hectare"
  },
  plannedTanks: {
    title: "Planned tanks"
  },
  actualTanks: {
    title: "Actual tanks"
  },
  conc: {
    title: "Conc (x)"
  },
  comment: {
    title: "Comment"
  },
  withholdingPeriod: {
    title: "Withholding period"
  },
  machinery: {
    title: "Machinery"
  },
  supervisors: {
    title: "Supervisors"
  },
  assignees: {
    title: "Assignees"
  },
  creator: {
    title: "Creator"
  },
  interventionType: {
    title: "Intervention type"
  },
  rowsToSpray: {
    title: "Rows to spray"
  },
  machinerySize: {
    title: "Machinery size"
  },
  machinerySpeed: {
    title: "Machinery speed"
  },
  nozzle: {
    title: "Nozzle"
  },
  nozzlesPerSide: {
    title: "Nozzles per side"
  },
  gear: {
    title: "Gear"
  },
  rpm: {
    title: "Rpm"
  },
  pressure: {
    title: "Pressure"
  },
  pto: {
    title: "Pto"
  },
  workingTimes: {
    title: "Working times"
  },
  windDirection: {
    title: "Wind direction"
  },
  windStrength: {
    title: "Wind strength"
  },
  temperature: {
    title: "Temperature"
  }
};

export const tableColumns = Object.keys(columnsNames).map(column => {
  const targetColumn = columnsNames[column];
  return {
    id: column,
    accessor: targetColumn.accessor || column,
    Header: targetColumn.title,
    className: `cell_${targetColumn.width} ${targetColumn.className || ""}`,
    ...(targetColumn.sortFields ? { sortFields: targetColumn.sortFields } : {}),
    disableSort: targetColumn.disableSort
  };
});

const SprayRecordsTable = ({
  ref1: tableRef,
  data,
  isFetching,
  filtered,
  setFiltered
}) => {
  const onFilteredChangeCustom = useMemo(
    () => (value, accessor) => {
      let innerFiltered = [...filtered];
      let insertNewFilter = 1;

      if (innerFiltered.length) {
        innerFiltered.forEach((filter, i) => {
          if (filter["id"] === accessor) {
            if (value === "" || !value.length) innerFiltered.splice(i, 1);
            else filter["value"] = value;

            insertNewFilter = 0;
          }
        });
      }

      if (insertNewFilter) {
        innerFiltered.push({ id: accessor, value: value });
      }

      setFiltered(innerFiltered);
    },
    [filtered, setFiltered]
  );

  const [pagination, setPagination] = useState({
    pageSize: 10,
    pages: 0,
    currentPage: 0,
    itemsCount: {
      itemFrom: 0,
      itemTo: 0
    },
    totalElements: 0
  });

  const onPageChange = useCallback(
    (currentPage, loadMore) => {
      const itemFrom = loadMore ? 0 : currentPage * pagination.pageSize + 1;
      const itemTo = Math.min(
        itemFrom + pagination.pageSize - 1,
        pagination.totalElements
      );
      const pageSize = loadMore
        ? currentPage * pagination.pageSize + pagination.pageSize
        : pagination.pageSize;
      const pages = loadMore
        ? Math.ceil(pagination.totalElements / pageSize)
        : pagination.pages;
      setPagination({
        ...pagination,
        pageSize,
        pages,
        currentPage: loadMore ? 0 : currentPage,
        itemsCount: {
          itemFrom,
          itemTo
        }
      });
    },
    [pagination]
  );

  const updatePageSize = useCallback(
    (_, { value: pageSize }) => {
      setPagination({
        ...pagination,
        pageSize,
        pages: Math.ceil(pagination.totalElements / pageSize),
        currentPage: 0,
        itemsCount: {
          itemFrom: 0,
          itemTo:
            pagination.totalElements === 0
              ? pagination.totalElements
              : pageSize - 1
        }
      });
    },
    [pagination]
  );

  useEffect(() => {
    onFilteredDataChange();
  }, [isFetching, filtered]); // eslint-disable-line

  const onFilteredDataChange = () => {
    const data = tableRef.current.getResolvedState().sortedData;
    data &&
      setPagination({
        ...pagination,
        pages: Math.ceil(data.length / pagination.pageSize),
        currentPage: 0,
        itemsCount: {
          itemFrom: 0,
          itemTo: data.length === 0 ? data.length : pagination.pageSize - 1
        },
        totalElements: data.length
      });
  };

  return (
    <>
      <ReactTable
        ref={tableRef}
        className={`-striped ${styles.dataTable}`}
        loading={isFetching}
        filterable
        filtered={filtered}
        onFilteredChange={(filtered, column, value) => {
          onFilteredChangeCustom(value, column.id || column.accessor);
          onFilteredDataChange();
        }}
        defaultFilterMethod={(filter, row) => {
          const id = filter.pivotId || filter.id;
          if (typeof filter.value === "object") {
            return row[id] !== undefined
              ? filter.value.indexOf(row[id]) > -1
              : true;
          } else {
            return row[id] !== undefined
              ? String(row[id]).indexOf(filter.value) > -1
              : true;
          }
        }}
        data={data}
        showPagination={false}
        pageSize={pagination.pageSize}
        page={pagination.currentPage}
        columns={tableColumns}
        noDataText=""
      />
      <Pagination
        pageSizeOptions={[10, 20, 50, 100]}
        {...pagination}
        updatePageSize={updatePageSize}
        onPageChangeOwn={onPageChange}
      />
    </>
  );
};

SprayRecordsTable.propTypes = {
  data: PropTypes.array,
  filtered: PropTypes.array,
  isFetching: PropTypes.bool,
  ref1: PropTypes.func,
  setFiltered: PropTypes.func
};

SprayRecordsTable.defaultProps = {};

export default SprayRecordsTable;
