import React, { useState, useCallback } from "react";
import {
  Container,
  Grid,
  Header,
  Menu,
  Segment,
  Sidebar
} from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  setCurrentTaskById,
  fetchEditTask
} from "actions/Recommendations/recommendations";
import { recommendationTaskShape } from "constants/Recommendations/types";
import { getGeneralInfoData } from "../../../utils";
import LocationTab from "./LocationTab";
import OperationsTab from "./OperationsTab";
import SidebarHeader from "./SidebarHeader";
import styles from "./RecommendationSidebar.module.css";
import SidebarGeneralInformation from "./SidebarGeneralInformation";
import AdditionalInfoTab from "./AdditionalInfoTab";
import { get } from "lodash";

const TABS = {
  LOCATION: "Location",
  OPERATIONS: "Operations",
  INFO: "Additional Info"
};

const RecommendationSidebar = ({ currentTask, actions }) => {
  const [currentTab, setCurrentTab] = useState(TABS.LOCATION);
  const switchTab = tab => () => setCurrentTab(tab);
  const onEditTask = useCallback(actions.fetchEditTask, []);

  const onDeleteAttachment = useCallback(
    attachmentId => {
      const editedTask = {
        ...currentTask,
        attachments: currentTask.attachments.filter(
          ({ id }) => id !== attachmentId
        )
      };

      actions.fetchEditTask(editedTask);
    },
    [currentTask]
  );

  const onSidebarHide = useCallback(
    event => {
      const hasClosest = get(event, "target.closest");

      if (hasClosest && event.target.closest(".modals")) {
        return;
      }

      actions.setCurrentTaskById(null);
    },
    [currentTask]
  );

  if (!currentTask) {
    return null;
  }

  return (
    <Sidebar
      animation="overlay"
      direction="right"
      visible={!!currentTask}
      onHide={onSidebarHide}
      className={styles.root}
    >
      <Container>
        <Grid>
          <SidebarHeader
            currentTask={currentTask}
            onClose={() => actions.setCurrentTaskById(null)}
            onEditTask={onEditTask}
          />
          <SidebarGeneralInformation
            currentTask={currentTask}
            onEditTask={onEditTask}
          />

          <Grid.Row>
            <Grid.Column style={{ padding: 0 }}>
              <Segment>
                <Menu pointing secondary className={styles.tabsMenu}>
                  {Object.values(TABS).map(value => (
                    <Menu.Item
                      name={value}
                      active={currentTab === value}
                      onClick={switchTab(value)}
                      className={styles.tab}
                    />
                  ))}
                </Menu>
                {currentTab === TABS.LOCATION && (
                  <LocationTab
                    currentTask={currentTask}
                    onEditTask={onEditTask}
                  />
                )}
                {currentTab === TABS.OPERATIONS && (
                  <OperationsTab
                    currentTask={currentTask}
                    onEditTask={onEditTask}
                  />
                )}
                {currentTab === TABS.INFO && (
                  <AdditionalInfoTab
                    currentTask={currentTask}
                    onEditTask={onEditTask}
                    onDeleteAttachment={onDeleteAttachment}
                  />
                )}
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Sidebar>
  );
};

const mapStateToProps = state => {
  const { currentTask } = state.recommendations;

  return {
    currentTask
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ setCurrentTaskById, fetchEditTask }, dispatch)
});

RecommendationSidebar.propTypes = {
  currentTask: recommendationTaskShape
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecommendationSidebar);
