import { actionType } from "../../constants/User";
import { USER_FOUND } from "redux-oidc";
const initialState = {
  userChecked: false,
  isFetching: false,
  error: null,
  redirected: false,
  user: null
};

const userActions = (state, action) => {
  switch (action.type) {
    case actionType.GET_REFRESH_TOKEN_START:
      return {
        ...state,
        error: null,
        isFetching: true
      };
    case actionType.GET_REFRESH_TOKEN:
      return {
        ...state,
        user: {
          ...state.user,
          refresh_token: action.payload
        },
        isFetching: false
      };
    case actionType.GET_REFRESH_TOKEN_FAIL:
      return {
        ...state,
        user: null,
        error: action.payload,
        isFetching: false
      };
    case actionType.SIGN_IN_SILENT_START:
      return {
        ...state,
        error: null,
        userChecked: false,
        isFetching: true
      };
    case actionType.SIGN_IN_SILENT:
    case USER_FOUND:
      return {
        ...state,
        user: {
          ...action.payload,
          refresh_token:
            state.user && state.user.refresh_token
              ? state.user.refresh_token
              : null,
          profile: {
            ...action.payload.profile,
            src:
              state.user && state.user.profile.src
                ? state.user.profile.src
                : null
          }
        },

        userChecked: true,
        isFetching: false
      };
    case actionType.SIGN_IN_SILENT_FAIL:
      return {
        ...state,
        user: action.payload.online ? null : state.user,
        error: action.payload.error,
        isFetching: false
      };
    case actionType.SIGN_IN_START:
      return {
        ...state,
        error: null,
        redirected: true,
        isFetching: true
      };
    case actionType.SIGN_IN:
      return {
        ...state,
        error: action.payload,
        redirected: true,
        isFetching: false
      };
    case actionType.SIGN_IN_FAIL:
      return {
        ...state,
        redirected: true,
        error: action.payload,
        isFetching: false
      };

    case actionType.SIGN_OUT_START:
      return {
        ...state,
        error: null
      };
    case actionType.SIGN_OUT:
      return {
        ...state,
        user: null,
        redirected: false
      };
    case actionType.SIGN_OUT_FAIL:
    case actionType.GET_PROFILE_PICTURE_FAIL:
      return {
        ...state,
        error: action.payload
      };
    case actionType.GET_PROFILE_PICTURE:
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        user: {
          ...state.user,
          profile: {
            ...state.user.profile,
            src: action.payload
          }
        }
      };
    case actionType.UPDATE_PROFILE_DATA:
      return {
        ...state,
        user: {
          ...state.user,
          profile: {
            ...state.user.profile,
            given_name: action.payload.firstName,
            family_name: action.payload.lastName,
            picture: action.payload.picture.id
          }
        }
      };
    default:
      return state;
  }
};

export default (state = initialState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case actionType.SIGN_IN_SILENT_START:
    case actionType.SIGN_IN_SILENT:
    case actionType.SIGN_IN_SILENT_FAIL:
    case actionType.SIGN_IN_START:
    case actionType.SIGN_IN:
    case actionType.SIGN_IN_FAIL:
    case actionType.SIGN_OUT_START:
    case actionType.SIGN_OUT:
    case actionType.SIGN_OUT_FAIL:
    case actionType.GET_REFRESH_TOKEN_START:
    case actionType.GET_REFRESH_TOKEN:
    case actionType.GET_REFRESH_TOKEN_FAIL:
    case actionType.GET_PROFILE_PICTURE_START:
    case actionType.GET_PROFILE_PICTURE:
    case actionType.GET_PROFILE_PICTURE_FAIL:
    case actionType.UPDATE_PROFILE_DATA:
    case USER_FOUND:
      return userActions(state, action);

    default:
      return state;
  }
};
