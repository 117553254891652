import React from "react";

export const actionType = {
  PURCHASE_HISTORY_START: "INVENTORY/PURCHASE_HISTORY_START",
  PURCHASE_HISTORY: "INVENTORY/PURCHASE_HISTORY",

  GET_MANUFACTURER_LIST_START: "INVENTORY/GET_MANUFACTURER_LIST_START",
  GET_MANUFACTURER_LIST: "INVENTORY/GET_MANUFACTURER_LIST",

  GET_SUPPLIERSS_LIST_START: "INVENTORY/GET_SUPPLIERSS_LIST_START",
  GET_SUPPLIERSS_LIST: "INVENTORY/GET_SUPPLIERSS_LIST",

  GET_BATCHNUMBER_LIST_START: "INVENTORY/GET_BATCHNUMBER_LIST_START",
  GET_BATCHNUMBER_LIST: "INVENTORY/GET_BATCHNUMBER_LIST",

  PURCHASE_HISTORY_LIST_START: "INVENTORY/PURCHASE_HISTORY_LIST_START",
  PURCHASE_HISTORY_LIST: "INVENTORY/PURCHASE_HISTORY_LIST",

  PURCHASE_HISTORY_FOR_PRINT_START:
    "INVENTORY/PURCHASE_HISTORY_FOR_PRINT_START",
  PURCHASE_HISTORY_FOR_PRINT: "INVENTORY/PURCHASE_HISTORY_FOR_PRINT",

  ADD_PURCHASE_START: "INVENTORY/ADD_PURCHASE_START",
  ADD_PURCHASE: "INVENTORY/ADD_PURCHASE",
  ADD_PURCHASE_FAIL: "INVENTORY/ADD_PURCHASE_FAIL",

  UPDATE_PURCHASE_START: "INVENTORY/UPDATE_PURCHASE_START",
  UPDATE_PURCHASE: "INVENTORY/UPDATE_PURCHASE",
  UPDATE_PURCHASE_FAIL: "INVENTORY/UPDATE_PURCHASE_FAIL",

  DELETE_PURCHASE_START: "INVENTORY/DELETE_PURCHASE_START",
  DELETE_PURCHASE: "INVENTORY/DELETE_PURCHASE",
  DELETE_PURCHASE_FAIL: "INVENTORY/DELETE_PURCHASE_FAIL",

  SET_ACTIVE_ITEM_ID: "INVENTORY/SET_ACTIVE_ITEM_ID",

  CLEAR_PURCHASE_ERROR: "INVENTORY/CLEAR_PURCHASE_ERROR"
};
export const PURCHASE_INITIAL_VALUES = {
  attachments: [],
  clientId: "",
  deliveryDate: null,
  id: null,
  orderDate: null,
  orderNumber: "",
  products: [],
  supplier: ""
};

export const PURCHASE_PRODUCT_INITIAL_VALUES = {
  quantities: []
};
