import React from "react";
import PropTypes from "prop-types";
import OpenWeatherMapApi from "./utils/OpenWeatherMapApi";
import { utils } from "./utils/utils";
import TodayForecast from "./TodayForecast";
import DaysForecast from "./DaysForecast";
import WeatherIcon from "./WeatherIcon";
import "./Weather.css";

const propTypes = {
  unit: PropTypes.oneOf(["metric", "imperial"]),
  type: PropTypes.oneOf(["geo", "city", "auto"]),
  lat: PropTypes.string,
  lon: PropTypes.string,
  city: PropTypes.string,
  forecast: PropTypes.oneOf(["today", "5days"]),
  apikey: PropTypes.string.isRequired,
  lang: PropTypes.string
};

const defaultProps = {
  unit: "metric",
  type: "auto",
  forecast: "today",
  lang: "en"
};

class Weather extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.api = new OpenWeatherMapApi(props.unit, props.apikey, props.lang);
    this.state = {
      data: null
    };
  }

  render() {
    const { unit, forecast, lang } = this.props;
    const data = this.state.data;
    if (data) {
      const days = data.days;
      const today = days[0];
      const todayIcon = utils.getIcon(today.icon);
      return (
        <div className="rw-box">
          <div className={`rw-main type-${forecast}`}>
            <div className="rw-box-left">
              <h4>{data.location.name}</h4>
              <TodayForecast todayData={today} unit={unit} lang={lang} />
            </div>
            <div className="rw-box-right">
              <WeatherIcon name={todayIcon} />
            </div>
          </div>
          <DaysForecast
            numOfDays={3}
            unit={unit}
            forecast={forecast}
            daysData={days}
            lang={lang}
          />
        </div>
      );
    }
    return <div>Loading...</div>;
  }

  componentDidMount() {
    this._isMounted = true;
    const params = this._getParams();
    this.api.getForecast(params).then(data => {
      if (this._isMounted) {
        this.setState({
          data
        });
      }
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _getParams() {
    const { type, lon, lat, city, lang, unit } = this.props;
    switch (type) {
      case "city":
        return { q: city, lang };
      case "geo":
        return {
          lat,
          lon,
          lang,
          units: unit
        };
      default:
        return {
          q: "auto:ip",
          lang
        };
    }
  }
}

Weather.propTypes = propTypes;
Weather.defaultProps = defaultProps;

export default Weather;
