import React from "react";
import ReactDOMServer from "react-dom/server";
import { cloneDeep } from "lodash";
import Compress from "react-image-file-resizer";
import { uppercaseFirstLetter } from "utils/string";

import { BasicTableCell } from "utils/tables";
import { copyStyles } from "utils/styles";
import { SCOUTING_TABLE_COLUMNS } from "constants/Scouting";
import ScoutingReport from "routes/Scouting/ScoutingList/components/ScoutingReport";

const TYPES_MAP = {
  PEST_AND_DISEASE: "Pest & Disease",
  NUTRIENT_DEFICIENCY: "Nutrient Deficiency",
  GENERAL: "General"
};

export const getReadableIssueType = (issueType = "") =>
  TYPES_MAP[issueType] ||
  uppercaseFirstLetter(issueType.toLowerCase().replaceAll("_", " "));

export const getIssuesReportedTitleText = issuesReported =>
  `${issuesReported ? issuesReported : "No"} issue${
    !issuesReported || issuesReported > 1 ? "s" : ""
  } reported`;

export const getColorByPressureRating = pressureRating => {
  switch (pressureRating) {
    case 0:
    case 1:
    case 2:
      return "#2BB673";
    case 3:
    case 4:
      return "#FF9900";
    default:
      return "#FD1313";
  }
};

export const getScoutingTableColumns = () => {
  return Object.keys(SCOUTING_TABLE_COLUMNS).map(columnName => {
    const targetColumn = SCOUTING_TABLE_COLUMNS[columnName];

    return {
      id: columnName,
      ...targetColumn,
      accessor: targetColumn.accessor || columnName,
      Header: targetColumn.title,
      // Cell: targetColumn.Cell || null,
      className: `${targetColumn.className || ""}`,
      ...(targetColumn.sortFields
        ? { sortFields: targetColumn.sortFields }
        : {})
    };
  });
};

const createResizedUrl = imgSrc =>
  new Promise(async resolve => {
    let img = document.createElement("img");
    let c = document.createElement("canvas");
    let ctx = c.getContext("2d");

    img.onload = function() {
      c.width = this.naturalWidth;
      c.height = this.naturalHeight;
      ctx.drawImage(this, 0, 0);
      c.toBlob(
        function(blob) {
          Compress.imageFileResizer(
            blob,
            400,
            500,
            "JPEG",
            70,
            0,
            uri => {
              resolve(uri);
            },
            "base64"
          );
        },
        "image/jpeg",
        1
      );
    };
    img.crossOrigin = "anonymous";
    img.src = imgSrc;
  });

export const resizeImgOfData = async data => {
  const newData = cloneDeep(data);

  const indexArray = [];
  const newImgUrlPromises = [];

  for (let i = 0; i < newData.length; i++) {
    for (let j = 0; j < newData[i].areas.length; j++) {
      if (newData[i].areas[j].areaIssues) {
        for (let k = 0; k < newData[i].areas[j].areaIssues.length; k++) {
          if (newData[i].areas[j].areaIssues[k].attachments?.length) {
            for (
              let l = 0;
              l < newData[i].areas[j].areaIssues[k].attachments.length && l < 4;
              l++
            ) {
              indexArray.push({ i, j, k, l });
              newImgUrlPromises.push(
                createResizedUrl(
                  newData[i].areas[j].areaIssues[k].attachments[l].attachment
                    .presignedUrl
                )
              );
            }
          }
        }
      }
    }
  }

  await Promise.all(newImgUrlPromises).then(urls => {
    for (let x = 0; x < indexArray.length; x++) {
      newData[indexArray[x].i].areas[indexArray[x].j].areaIssues[
        indexArray[x].k
      ].attachments[indexArray[x].l].attachment.presignedUrl = urls[x];
    }
  });

  return newData;
};

export const printScoutingList = (scoutingList, withImages) => {
  const newWindow = window.open();

  if (!newWindow) {
    return;
  }
  newWindow.document.title = `Scouting Report`;
  copyStyles(window.document, newWindow.document);

  newWindow.document.body.innerHTML = ReactDOMServer.renderToString(
    <ScoutingReport scoutingList={scoutingList} withImages={withImages} />
  );

  setTimeout(() => newWindow.print(), 1000);
};

export const getScoutingTaskReadableId = scoutingTaskId =>
  scoutingTaskId ? `ST-${String(scoutingTaskId).padStart(3, "0")}` : null;
