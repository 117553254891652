import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MultiSelectFilter from "./MultiSelectFilter/MultiSelectFilter";
import PropTypes from "prop-types";
import { fetchGeneralTasksNames } from "../../../actions/GeneralTasks/generalTasks";

const GeneralTaskNamesMultiSelectFilter = ({
  actions: { fetchList },
  list,
  isFetching,
  ...extraProps
}) => {
  useEffect(() => {
    fetchList({ unpaged: true });
  }, [fetchList]);

  const options = useMemo(
    () =>
      list.map(item => ({
        key: item.replace(/ /g, "+"),
        text: item,
        value: item.replace(/ /g, "+")
      })),
    [list]
  );

  return (
    <MultiSelectFilter
      options={options}
      numbers={false}
      loading={isFetching}
      {...extraProps}
    />
  );
};

const mapStateToProps = ({
  generalTasks: {
    taskNames: { content, isFetching }
  }
}) => {
  return {
    list: content,
    isFetching
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchList: fetchGeneralTasksNames }, dispatch)
});

GeneralTaskNamesMultiSelectFilter.propTypes = {
  isFetching: PropTypes.bool,
  list: PropTypes.array,
  actions: PropTypes.object
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralTaskNamesMultiSelectFilter);
