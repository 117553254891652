export const actionType = {
  COST_ENTERING_START: "GENERAL_TASKS/COST_ENTERING_START",
  COST_ENTERING: "GENERAL_TASKS/COST_ENTERING",

  COST_ENTERING_LIST_START: "GENERAL_TASKS/COST_ENTERING_LIST_START",
  COST_ENTERING_LIST: "GENERAL_TASKS/COST_ENTERING_LIST",

  COST_ENTERING_DELETE_START: "GENERAL_TASKS/COST_ENTERING_DELETE_START",
  COST_ENTERING_DELETE: "GENERAL_TASKS/COST_ENTERING_DELETE",
  COST_ENTERING_DELETE_FAIL: "GENERAL_TASKS/COST_ENTERING_DELETE_FAIL",

  ADD_COST_ENTERING_START: "GENERAL_TASKS/ADD_COST_ENTERING_START",
  ADD_COST_ENTERING: "GENERAL_TASKS/ADD_COST_ENTERING",
  ADD_COST_ENTERING_FAIL: "GENERAL_TASKS/ADD_COST_ENTERING_FAIL",

  ADD_COST_ENTERING_BATCH_START: "GENERAL_TASKS/ADD_COST_ENTERING_BATCH_START",
  ADD_COST_ENTERING_BATCH: "GENERAL_TASKS/ADD_COST_ENTERING_BATCH",
  ADD_COST_ENTERING_BATCH_FAIL: "GENERAL_TASKS/ADD_COST_ENTERING_BATCH_FAIL",

  COST_ENTERING_TYPE_START: "GENERAL_TASKS/COST_ENTERING_TYPE_START",
  COST_ENTERING_TYPE: "GENERAL_TASKS/COST_ENTERING_TYPE",
  COST_ENTERING_TYPE_FAIL: "GENERAL_TASKS/COST_ENTERING_TYPE_FAIL",

  UPDATE_COST_ENTERING_START: "GENERAL_TASKS/UPDATE_COST_ENTERING_START",
  UPDATE_COST_ENTERING: "GENERAL_TASKS/UPDATE_COST_ENTERING",
  UPDATE_COST_ENTERING_FAIL: "GENERAL_TASKS/UPDATE_COST_ENTERING_FAIL"
};
export const ALT_COST_ENTERING_INITIAL_VALUES = {
  costs: [
    {
      costType: "",
      areaData: {
        expandedFarmsIds: [],
        expandedAreasIds: [],
        selectedAreas: [],
        treeSelectSearchFilter: ""
      },
      generalTaskId: "",
      costSubType: "",
      units: null,
      rate: null,
      total: null,
      notes: "",
      startAt: "",
      endAt: ""
    }
  ]
};
export const COST_ENTERING_INITIAL_VALUES = {
  costs: [
    {
      costType: "",
      areaData: {
        expandedFarmsIds: [],
        expandedAreasIds: [],
        selectedAreas: [],
        treeSelectSearchFilter: ""
      },
      costSubType: "",
      units: null,
      rate: null,
      total: null,
      notes: "",
      startAt: "",
      endAt: ""
    }
  ]
};

export const ALT_LABOUR_CONTRACTOR_TYPE = [
  {
    key: 1,
    text: `Contractor`,
    value: "CONTRACTOR"
  },

  {
    key: 4,
    text: `Other wages`,
    value: "OTHER_WAGES"
  }
];
export const CONTRACTOR_TYPE = [
  {
    key: 2,
    text: `Overheads`,
    value: "OVERHEADS"
  },
  {
    key: 3,
    text: `One-off purchases`,
    value: "OTHER_EXPENSES"
  }
];
