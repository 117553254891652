import React, { useState } from "react";
import { Button, Table, TableCell, TableRow } from "semantic-ui-react";
import { sum, round } from "lodash";
import Numeric from "../../../../../components/Numeric";

const TotalCostRowForPrint = ({ area, disableSubRow }) => {
  const generalInfo = {
    totalCost:
      area.sprayTaskLabourCost +
      area.sprayTaskMachineryCost +
      area.chemicalCost +
      sum(
        area.generalTaskCosts.map(task =>
          task.machineryCost ? task.machineryCost : 0
        )
      ) +
      sum(
        area.generalTaskCosts.map(task =>
          task.labourCost ? task.labourCost : 0
        )
      ) +
      sum(
        area.generalTaskCosts.map(task =>
          task.altLabourCost ? task.altLabourCost : 0
        )
      ) +
      area.otherExpensesCost +
      area.overheadsCost,

    totalCostPerHa:
      area.areaSize && area.areaSize != 0
        ? (area.sprayTaskLabourCost +
            area.sprayTaskMachineryCost +
            area.chemicalCost +
            sum(
              area.generalTaskCosts.map(task =>
                task.machineryCost ? task.machineryCost : 0
              )
            ) +
            sum(
              area.generalTaskCosts.map(task =>
                task.labourCost ? task.labourCost : 0
              )
            ) +
            sum(
              area.generalTaskCosts.map(task =>
                task.altLabourCost ? task.altLabourCost : 0
              )
            ) +
            area.otherExpensesCost +
            area.overheadsCost) /
          area.areaSize
        : "No information"
  };

  const chemicalCost = {
    totalCosts: round(area.chemicalCost, 2),
    costPerHa: area.areaSize
      ? round(area.chemicalCost / area.areaSize, 2)
      : "No information",
    costPerHarvestUnit: area.binCount
      ? round(area.chemicalCost / area.binCount, 2)
      : "No information"
  };
  const operatingCost = {
    totalCosts: round(area.otherExpensesCost + area.overheadsCost, 2)
  };
  const labourCosts = {
    totalCosts: round(
      sum(
        area.generalTaskCosts.map(task =>
          task.labourCost ? task.labourCost : 0
        )
      ) +
        sum(
          area.generalTaskCosts.map(task =>
            task.altLabourCost ? task.altLabourCost : 0
          )
        ) +
        (area.sprayTaskLabourCost ? area.sprayTaskLabourCost : 0),
      2
    ),

    costPerHa: area.areaSize
      ? round(
          (sum(
            area.generalTaskCosts.map(task =>
              task.labourCost ? task.labourCost : 0
            )
          ) +
            (area.sprayTaskLabourCost ? area.sprayTaskLabourCost : 0)) /
            area.areaSize,
          2
        )
      : "No information",

    costPerHarvestUnit: area.binCount
      ? round(
          (sum(
            area.generalTaskCosts.map(task =>
              task.labourCost ? task.labourCost : 0
            )
          ) +
            (area.sprayTaskLabourCost ? area.sprayTaskLabourCost : 0)) /
            area.binCount,
          2
        )
      : "No information",

    tasks: area.generalTaskCosts,
    sprayTaskLabourCost: area.sprayTaskLabourCost,
    costSwitcher: false,
    areaSize: area.areaSize,
    binCount: area.binCount
  };

  const machineryCosts = {
    totalCosts: round(
      sum(
        area.generalTaskCosts.map(task =>
          task.labourCost ? task.machineryCost : 0
        )
      ) + (area.sprayTaskMachineryCost ? area.sprayTaskMachineryCost : 0),
      2
    ),

    costPerHa: area.areaSize
      ? round(
          (sum(
            area.generalTaskCosts.map(task =>
              task.machineryCost ? task.machineryCost : 0
            )
          ) +
            (area.sprayTaskMachineryCost ? area.sprayTaskMachineryCost : 0)) /
            area.areaSize,
          2
        )
      : "No information",

    costPerHarvestUnit: area.binCount
      ? round(
          (sum(
            area.generalTaskCosts.map(task =>
              task.machineryCost ? task.machineryCost : 0
            )
          ) +
            (area.sprayTaskMachineryCost ? area.sprayTaskMachineryCost : 0)) /
            area.binCount,
          2
        )
      : "No information",
    tasks: area.generalTaskCosts,
    sprayTaskMachineryCost: area.sprayTaskMachineryCost,
    costSwitcher: true,
    areaSize: area.areaSize,
    binCount: area.binCount
  };

  const [isExpanded, setExpanded] = useState(false);
  return (
    <>
      <TableRow style={isExpanded ? { backgroundColor: "#dff3ea" } : {}}>
        {!disableSubRow && (
          <TableCell width={1}>
            <Button
              active={isExpanded}
              onClick={() => setExpanded(!isExpanded)}
              compact
              icon="dropdown"
            />
          </TableCell>
        )}
        <Table.Cell width={2}>{area.areaName}</Table.Cell>
        <Table.Cell width={2}>
          {area.areaSize ? `${round(area.areaSize, 2)} Ha` : "No information"}
        </Table.Cell>
        <Table.Cell width={2}>
          <Numeric
            value={chemicalCost.totalCosts}
            fractionDigits={2}
            commaSeparatorOnThousands
            units="$ "
            unitsPosition="left"
          />
        </Table.Cell>
        <Table.Cell width={2}>
          <Numeric
            value={labourCosts.totalCosts}
            fractionDigits={2}
            commaSeparatorOnThousands
            units="$ "
            unitsPosition="left"
          />
        </Table.Cell>
        <Table.Cell width={2}>
          <Numeric
            value={machineryCosts.totalCosts}
            fractionDigits={2}
            commaSeparatorOnThousands
            units="$ "
            unitsPosition="left"
          />
        </Table.Cell>
        <Table.Cell width={2}>
          <Numeric
            value={operatingCost.totalCosts}
            fractionDigits={2}
            commaSeparatorOnThousands
            units="$ "
            unitsPosition="left"
          />
        </Table.Cell>
        <Table.Cell width={2}>
          <Numeric
            value={
              area.binCount
                ? round(generalInfo.totalCost / area.binCount, 2)
                : "No information"
            }
            fractionDigits={2}
            commaSeparatorOnThousands
            units="$ "
            unitsPosition="left"
          />
        </Table.Cell>
        <Table.Cell width={2}>
          <Numeric
            value={
              isNaN(generalInfo.totalCostPerHa)
                ? "No information"
                : round(generalInfo.totalCostPerHa, 2)
            }
            fractionDigits={2}
            commaSeparatorOnThousands
            units="$ "
            unitsPosition="left"
          />
          /Ha
        </Table.Cell>
        <Table.Cell width={2}>
          <Numeric
            value={round(generalInfo.totalCost, 2)}
            fractionDigits={2}
            commaSeparatorOnThousands
            units="$ "
            unitsPosition="left"
          />
        </Table.Cell>
      </TableRow>
    </>
  );
};

export default TotalCostRowForPrint;
