import React, { useEffect, useMemo } from "react";
import {
  Container,
  Grid,
  Message,
  Table,
  TableBody,
  TableCell,
  TableHeader as Header,
  TableHeaderCell,
  TableRow
} from "semantic-ui-react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchScannedBarcodes } from "../../../../actions/GeneralBarcodes/generalBarcodes";
import Loader from "../../../../components/Loader";
import ExportToExcel from "../../../../components/ExportToExcel";
import {
  toEndDateString,
  toStartDateString
} from "../../../../utils/dateUtils";
import "../index.css";

const columnsConfig = [
  {
    id: "rowName",
    accessor: "rowName",
    Header: "Row Name"
  },
  {
    id: "unitName",
    accessor: "unitName",
    Header: "Unit Name"
  },
  {
    id: "unitQty",
    accessor: "unitQty",
    Header: "Unit Quantity"
  },
  {
    id: "netWeight",
    accessor: "netWeight",
    Header: "Net Weight"
  }
];

const RowsReport = ({
  area,
  actions: { fetchScannedBarcodes },
  isFetching,
  content,
  dateTo,
  dateFrom
}) => {
  useEffect(() => {
    if (dateFrom && dateTo)
      fetchScannedBarcodes({
        filters: { farmId: area.farm.id },
        unpaged: true,
        from: toStartDateString(dateFrom),
        to: toEndDateString(dateTo)
      });
  }, [area, dateFrom, dateTo, fetchScannedBarcodes]);

  const pickedHarvest = useMemo(
    () => content.filter(barcode => barcode.area.id === area.id),
    [content, area]
  );

  const totals = useMemo(() => {
    return {
      totalHarvestUnitQty: pickedHarvest.length,
      totalWeight: pickedHarvest.reduce(
        (prev, harvest) => prev + harvest.harvestUnit.netWeight,
        0
      )
    };
  }, [pickedHarvest]);

  const rowsOrientedData = useMemo(() => {
    const rows = {};

    pickedHarvest.forEach(harvest => {
      if (!harvest.row) harvest.row = "Unspecified";

      const rowName = `${harvest.rowsPrefix || ""}${harvest.row}`;

      if (rows[rowName]) {
        rows[rowName] = [...rows[rowName], harvest];
      } else {
        rows[rowName] = [harvest];
      }
    });

    return Object.entries(rows)
      .map(([rowName, pickedHarvest]) => {
        const unitTypes = {};

        pickedHarvest.forEach(harvest => {
          const unit = harvest.harvestUnit;
          const unitName = `${unit.id}_${unit.name}_${unit.netWeight}`;

          if (unitTypes[unitName]) {
            unitTypes[unitName] = [...unitTypes[unitName], harvest];
          } else {
            unitTypes[unitName] = [harvest];
          }
        });

        return Object.entries(unitTypes).map(
          ([unitName, pickedHarvestForUnit]) => {
            const [, name, netWeight] = unitName.split("_");

            return {
              rowName,
              unitName: name,
              netWeight,
              unitQty: pickedHarvestForUnit.length
            };
          }
        );
      })
      .flat();
  }, [pickedHarvest]);

  if (isFetching) return <Loader />;

  return (
    <Container className={"rows-report"}>
      <Grid>
        <Grid.Row>
          <Grid.Column width={10} floated={"left"}>
            <Header style={{ fontWeight: 10 }} as="h2">
              Rows Report
            </Header>
          </Grid.Column>
          <Grid.Column width={6} floated={"right"} textAlign={"right"}>
            <ExportToExcel
              columns={columnsConfig}
              rawData={rowsOrientedData}
              name={"Rows Report"}
              className={"mr-1"}
            />
          </Grid.Column>
        </Grid.Row>
        {totals.totalHarvestUnitQty > 0 && rowsOrientedData.length === 0 && (
          <Grid.Row>
            <Grid.Column>
              <Message>
                The rows were not specified and no data can be shown in the
                excel.
              </Message>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
      <Table className="rowsReportTable" unstackable>
        <Header>
          <TableRow>
            <TableHeaderCell />
            <TableHeaderCell>Row</TableHeaderCell>
            <TableHeaderCell>Harvest unit type</TableHeaderCell>
            <TableHeaderCell>Harvest unit Qty</TableHeaderCell>
            <TableHeaderCell>Weight (Kg)</TableHeaderCell>
          </TableRow>
        </Header>
        <TableBody>
          {rowsOrientedData.map((row, i) => (
            <TableRow key={`row-reports-row-${i}`}>
              <TableCell />
              <TableCell>{row.rowName}</TableCell>
              <TableCell>{row.unitName}</TableCell>
              <TableCell>{row.unitQty}</TableCell>
              <TableCell>{row.netWeight}</TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell>
              <b>Total</b>
            </TableCell>
            <TableCell />
            <TableCell />
            <TableCell>
              <b>{totals.totalHarvestUnitQty} units</b>
            </TableCell>
            <TableCell>
              <b>{totals.totalWeight}</b>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Container>
  );
};

RowsReport.propTypes = {
  actions: PropTypes.object,
  area: PropTypes.any,
  content: PropTypes.any,
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
  isFetching: PropTypes.bool
};

const mapStateToProps = ({
  generalBarcodes: {
    isFetching,
    data: { content }
  },
  router: { location }
}) => ({ isFetching, content, location });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchScannedBarcodes
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(RowsReport);
