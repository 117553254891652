import axios from 'axios';
import { utils } from './utils';

export default class OpenWeatherMapApi {
  constructor(unit, apiKey) {
    this.unit = unit;
    this.apiKey = apiKey;
    this.baseApiUrl = 'https://api.openweathermap.org/data/2.5/forecast';
  }
  getForecast(args) {
    const self = this;
    const endpoint = self.baseApiUrl;
    const params = Object.assign(
      {
        appid: self.apiKey,
        cnt: 40,
        lang: self.lang,
      },
      args,
    );
    const promise = axios
      .get(endpoint, {
        params,
      })
      .then(response => {
        const data = response.data;
        if (data) {
          return self._map(data, params.lang);
        }
        return {};
      });
    return promise;
  }
  _map(data, lang) {
    const daysData = data.list;
    const mapped = {};
    mapped.location = data.city;
    mapped.days = daysData
      .filter(daysData => daysData.dt_txt.indexOf('12:00:00') !== -1)
      .map(item => ({
        date: utils.formatDate(item.dt_txt, lang),
        dt_txt: item.dt_txt,
        description: item.weather[0] ? item.weather[0].description : null,
        icon: item.weather[0] ? item.weather[0].icon : null,
        temperature: {
          min: item.main.temp_min.toFixed(0),
          max: item.main.temp_max.toFixed(0),
        },
        wind: item.wind.speed.toFixed(0),
        humidity: item.main.humidity,
      }));
    if (mapped.days.length > 0) {
      mapped.days[0].temperature.current = data.list[0].main.temp;
    }
    return mapped;
  }
}
