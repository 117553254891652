import { createSelector } from "reselect";
import { Area } from "models/block.model";
import moment from "moment";

const areasListSelector = state => state.areas.list.content;
const actualDateSelector = state =>
  state.reports.reportsReducerNeedToRefactor.filters;

export const formaterData = (areaList, filters) => {
  const newContent = (filters &&
  (filters.farmIds?.length || filters.cropIds?.length)
    ? areaList.filter(item =>
        filters.farmIds?.length && filters.cropIds?.length
          ? filters.farmIds.some(id => item.farm.id === id) &&
            item.varieties.some(v =>
              filters.cropIds.includes(v.variety.crop.id)
            )
          : filters.cropIds?.length
          ? item.varieties.some(v =>
              filters.cropIds.includes(v.variety.crop.id)
            )
          : filters.farmIds.some(id => item.farm.id === id)
      )
    : areaList
  )
    .filter(i => !i.hasSubAreas)
    .map((area, index, array) => {
      const block = new Area(area);
      const variety = block.areaVarietyByDate(
        filters?.from || moment(new Date()).format("YYYY-MM-DD")
      );
      return {
        variety: variety.variety ? variety : "Vacant",

        area: array.filter(a => a.id === block.id)
      };
    });
  const filtredNewContent = newContent.filter(i => i.variety !== "Vacant");
  const uniqFiltredNewContent = [
    ...new Map(
      newContent
        .filter(i => i.variety !== "Vacant")
        .map(item => [item.variety.variety.id, item])
    )
  ].map(i => i[1]);
  const data = uniqFiltredNewContent.map((item, index) => ({
    variety: item.variety,
    area: filtredNewContent
      .filter(v => v.variety.variety.id === item.variety.variety.id)
      .map(i => i.area)
      .flat()
  }));
  if (areaList.length) {
    return data
      ? data
          .map(item => {
            const vacantLength = newContent.filter(i => i.variety === "Vacant")
              .length;
            return {
              variety: item.variety.variety?.name,
              block: item.area.map(a =>
                a.parent ? `${a.parent.name}/${a.name}` : `${a.name}`
              ),
              plantingDate:
                item.area.length === 1
                  ? item.area[0].varieties.find(
                      v => v.variety.id === item.variety.variety.id
                    ).plantingAt
                  : null,
              pickingDate:
                item.area.length === 1
                  ? item.area[0].varieties.find(
                      v => v.variety.id === item.variety.variety.id
                    ).pickingAt
                  : null,
              areaSize: item.area.reduce((prev, curr) => prev + curr.size, 0),
              vacantLength:
                vacantLength > 1
                  ? `${vacantLength} areas`
                  : vacantLength === 1
                  ? `${vacantLength} area`
                  : "-",
              subRows:
                item.area.length > 1 &&
                item.area.map(a => ({
                  block: a.parent ? `${a.parent.name}/${a.name}` : a.name,
                  plantingDate: a.varieties.find(
                    v => v.variety.id === item.variety.variety.id
                  ).plantingAt,
                  pickingDate: a.varieties.find(
                    v => v.variety.id === item.variety.variety.id
                  ).pickingAt,
                  areaSize: a.size
                }))
            };
          }, {})
          .map(({ subRows, withPatches, ...rest }) => ({
            ...rest,
            ...(withPatches
              ? {
                  subRows: subRows.map(({ subRows, ...rest }) =>
                    subRows.length > 1 ? { ...rest, subRows } : rest
                  )
                }
              : subRows.length > 1 && { subRows })
          }))
      : [];
  }
};

export const selectTableStructuredData = createSelector(
  [areasListSelector, actualDateSelector],
  (areaList, filters) => {
    return formaterData(areaList, filters);
  }
);
