import React, { useEffect } from 'react';
import Splash from '../../components/Splash';
import { Link } from 'react-router-dom';
import { history } from '../../store';

function NotFound() {
  useEffect(() => {
    history.replace('/');
  }, []);
  return (
    <Splash>
      404. Page not found.
      <br />
      Please proceed to <Link to="/">Home page</Link>.
    </Splash>
  );
}

export default NotFound;
