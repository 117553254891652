import React from "react";
import PropTypes from "prop-types";
import { Grid, Dropdown, Form } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setInterventionType } from "../../../actions/SprayDiary/common";

const transformedInterventionTypes = interventionTypes => {
  return Object.keys(interventionTypes).map(interventionType => ({
    text: interventionTypes[interventionType],
    key: interventionType,
    value: interventionType
  }));
};

const InterventionTypeList = ({
  interventionTypes,
  interventionType,
  actions
}) => {
  return (
    <Grid.Row>
      <Grid.Column className="spraySection">
        <Form.Field className="sprayField">
          <label className="title">Intervention type</label>
          <Dropdown
            closeOnChange
            closeOnBlur
            placeholder="Select Intervention type"
            selection
            fluid
            clearable
            value={interventionType || ""}
            options={transformedInterventionTypes(interventionTypes)}
            onChange={(_, data) =>
              actions.setInterventionType(data.value !== "" ? data.value : null)
            }
          />
        </Form.Field>
      </Grid.Column>
    </Grid.Row>
  );
};

InterventionTypeList.propTypes = {
  interventionTypes: PropTypes.object,
  actions: PropTypes.object,
  interventionType: PropTypes.any
};

const mapStateToProps = ({
  spray: {
    tasks: { interventionTypes }
  },
  sprayDiary: { interventionType }
}) => ({
  interventionTypes,
  interventionType
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setInterventionType
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InterventionTypeList);
