import PropTypes from "prop-types";
import React from "react";
import { Table } from "semantic-ui-react";
import TotalCostRow from "./TotalCostRow";
import Loader from "../../../../components/Loader";
import { connect } from "react-redux";

const TotalCostPerAreaTable = ({ areaStats, areasList }) => {
  return (
    <>
      <Table>
        <Table.Header>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell>Area Name</Table.HeaderCell>
          <Table.HeaderCell>Area Size (Ha)</Table.HeaderCell>
          <Table.HeaderCell>Cost/Harvest Unit</Table.HeaderCell>
          <Table.HeaderCell>Cost/Ha ($/Ha)</Table.HeaderCell>
          <Table.HeaderCell>Total Costs ($)</Table.HeaderCell>
        </Table.Header>
        <Table.Body>
          {areaStats ? (
            areaStats.map(area => (
              <TotalCostRow area={area} areasList={areasList} />
            ))
          ) : (
            <Loader />
          )}
        </Table.Body>
      </Table>
    </>
  );
};

TotalCostPerAreaTable.propTypes = {
  areaStats: PropTypes.shape({
    areasStat: PropTypes.object.isRequired,
    blockName: PropTypes.string.isRequired
  })
};

export default connect((state, props) => {
  return {
    areasList: state.areas.list.content
  };
})(TotalCostPerAreaTable);
