import React from 'react';
import PropTypes from 'prop-types';
import styles from './Splash.module.css';
import Loader from '../Loader';

const Splash = ({ children, loading }) => {
  return (
    <div className={styles.splashHolder}>
      <div>
        <div className={styles.logo} />
        <div className={styles.message}>
          {loading && <Loader className={styles.loader} />}
          {children}
        </div>
      </div>
    </div>
  );
};

Splash.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.any,
};

export default Splash;
