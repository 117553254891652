import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Form } from "formsy-semantic-ui-react";
import { Button, Dropdown } from "semantic-ui-react";
import { fetchBoxUnitsList } from "../../actions/BoxUnits/boxUnits";
import styles from "./SelectBoxUnit.module.css";

const SelectBoxUnit = ({
  actions: { fetchBoxUnits },
  boxUnitsList,
  isFetching,
  required,
  error,
  minItemForSearch,
  onChange,
  native,
  setOpenModal,
  ...extraProps
}) => {
  useEffect(() => {
    fetchBoxUnits();
  }, [fetchBoxUnits]);

  const options = useMemo(
    () =>
      boxUnitsList
        .sort(content => content.name)
        .map(content => ({
          key: content.id,
          text: content.displayName,
          value: content.id
        })),
    [boxUnitsList]
  );

  useEffect(() => {
    if (required && options && options.length === 1) {
      onChange(null, { options, value: options[0].value });
    }
  }, [options]); // eslint-disable-line

  const props = {
    placeholder: "Select box unit",
    noResultsMessage: "Nothing was found",
    fluid: true,
    ...extraProps,
    error,
    closeOnBlur: true,
    onChange,
    loading: isFetching,
    selection: true,
    required,
    search: options.length >= minItemForSearch,
    options: [
      {
        key: "",
        text: "",
        value: "",
        content: (
          <Button
            className={styles.addButton}
            type="button"
            onClick={() => setOpenModal(true)}
          >
            {" "}
            + Add
          </Button>
        )
      },
      ...options
    ]
  };
  return native ? (
    <Dropdown {...props} />
  ) : (
    <Form.Dropdown className={styles.dropdown} {...props} />
  );
};

SelectBoxUnit.propTypes = {
  actions: PropTypes.object.isRequired,
  boxUnitsList: PropTypes.array.isRequired,
  disabled: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  minItemForSearch: PropTypes.number,
  native: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.array.isRequired,
    PropTypes.number.isRequired
  ])
};

SelectBoxUnit.defaultProps = {
  boxUnitsList: [],
  disabled: false,
  error: false,
  isFetching: false,
  minItemForSearch: 10,
  native: false,
  onChange: () => {},
  required: false,
  value: []
};

const mapStateToProps = ({
  boxUnits: {
    list: { content, isFetching }
  }
}) => {
  return {
    boxUnitsList: content,
    isFetching
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchBoxUnits: fetchBoxUnitsList
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectBoxUnit);
