import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, Card, Grid, Header, Icon } from "semantic-ui-react";
import { history } from "../../../../store";
import {
  clearError,
  setBlockField,
  setPatchField,
  toggleShowArchived
} from "../../../../actions/Farms/addNewFarm";
import { setNewFarm } from "../../../../actions/Farms/farms";
import { getVarieties } from "../../../../actions/Farms/crops";
import BlockBox from "../../../../components/BlockBox";

import styles from "./StepThree.module.css";
import { blocksToShowSelector } from "../../../../selectors/addFarm";
import ShowArchiveToggle from "../ShowArchiveToggle";

class StepThree extends PureComponent {
  state = { activeIndex: 0, blockToHandle: null };

  componentWillUnmount() {
    const { actions } = this.props;
    actions.clearError();
  }

  handleSaveFarm = event => {
    // EDGE is ignoring button type for some reason and doing form submit on button click.
    // So, it's going to reload page. To avoid it,prevent default action from button clicks added.
    if (event) {
      event.preventDefault();
    }
    history.push("/farms");
  };

  hideConfirm = () => {
    this.setState({
      blockToHandle: null
    });
  };

  proceedDelete = () => {
    const { blockToHandle } = this.state;
    this.props.handleDeleteBlock(blockToHandle.id);
    this.hideConfirm();
  };

  proceedActivate = () => {
    const { blockToHandle } = this.state;
    this.props.handleActivateBlock(blockToHandle.id);
    this.hideConfirm();
  };

  handleBlockAction = blockToHandle => {
    this.setState({
      blockToHandle
    });
  };

  handleAddBlock = () => {
    this.props.handleAddAnotherBlock();
    this.props.setStep(2);
  };

  render() {
    const {
      newFarm,
      blocksToShow,
      goBack,
      handleEditBlock,
      isFetching
    } = this.props;
    const { blockToHandle } = this.state;
    return (
      <div className={styles.stepHolder}>
        <div className={styles.stepContent}>
          <div className={styles.stepCount}>STEP 2 / 2</div>
          <Header as="h2" className={styles.stepTitle}>
            {newFarm.name}
            &lsquo;s{" "}
            {newFarm.constructed === "PATCHES_ONLY" ? "Patches" : "Blocks"}
          </Header>

          <div className={`${styles.archiveToggle} ${styles.wide}`}>
            <div>
              <Button onClick={this.handleAddBlock}>
                Add {newFarm.constructed === "PATCHES_ONLY" ? "Patch" : "Block"}
              </Button>
            </div>
            <span>Show archived</span>
            <ShowArchiveToggle />
          </div>
          <div className={styles.blocksHolder}>
            {blocksToShow &&
              blocksToShow
                ?.sort(function(a, b) {
                  var nameA = a.name?.toLowerCase(),
                    nameB = b.name?.toLowerCase();
                  if (nameA < nameB) return -1;
                  if (nameA > nameB) return 1;
                  return 0;
                })
                .map(block =>
                  blockToHandle && block.id === blockToHandle.id ? (
                    <Card fluid className={styles.deleteBlock}>
                      <Card.Content className="center aligned" relaxed>
                        <Grid centered>
                          <Grid.Row>
                            <h3>
                              Are you sure you want to{" "}
                              {blockToHandle.archived ? "activate" : "archive"}{" "}
                              {blockToHandle.name}?
                            </h3>
                          </Grid.Row>
                          <Grid.Row>
                            <Button
                              color="red"
                              className={styles.deleteActionButton}
                              onClick={
                                blockToHandle.archived
                                  ? this.proceedActivate
                                  : this.proceedDelete
                              }
                            >
                              {blockToHandle.archived ? (
                                <span>
                                  <Icon name="tuf-unarchive" />
                                  Activate{" "}
                                </span>
                              ) : (
                                <span>
                                  <Icon name="tuf-archive" />
                                  Archive
                                </span>
                              )}
                            </Button>
                            <Button
                              color="grey"
                              className={styles.deleteActionButton}
                              onClick={this.hideConfirm}
                            >
                              Cancel
                            </Button>
                          </Grid.Row>
                        </Grid>
                      </Card.Content>
                    </Card>
                  ) : (
                    <BlockBox
                      key={`block_${block.id}`}
                      block={block}
                      handleDeleteBlock={this.handleBlockAction}
                      handleEditBlock={handleEditBlock}
                      handleActivateBlock={this.handleBlockAction}
                    />
                  )
                )}
          </div>
        </div>
        <div className={styles.stepActions}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={4}>
                <div className={styles.verticalMiddle}>
                  <Button onClick={goBack} disabled={newFarm.isFetching}>
                    Back
                  </Button>
                </div>
              </Grid.Column>
              <Grid.Column width={12}>
                <div className={`${styles.verticalMiddle} right`}>
                  <Button
                    onClick={() => this.handleSaveFarm()}
                    disabled={newFarm.isFetching || isFetching}
                  >
                    Save Farm
                  </Button>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

StepThree.propTypes = {
  newFarm: PropTypes.object,
  actions: PropTypes.object,
  blocksToShow: PropTypes.object,
  goBack: PropTypes.func,
  handleAddAnotherBlock: PropTypes.func,
  handleEditBlock: PropTypes.func,
  handleRemoveBlock: PropTypes.func,
  setStep: PropTypes.func,
  handleDeleteBlock: PropTypes.func,
  handleActivateBlock: PropTypes.func
};

StepThree.defaultProps = {};

function mapStateToProps(state) {
  const {
    farms: { newFarm, constructed, isFetching }
  } = state;
  return {
    newFarm,
    constructed,
    isFetching,
    blocksToShow: blocksToShowSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setBlockField,
        setPatchField,
        getVarieties,
        setNewFarm,
        clearError: clearError,
        toggleShowArchived
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StepThree);
