import moment from "moment";

export const countMinutesBetweenDates = (startDate, endDate) => {
  const tempStartDate = moment(startDate);
  const temEndDate = moment(endDate);
  return +moment
    .duration(temEndDate.diff(tempStartDate))
    .asMinutes()
    .toFixed(0);
};

export const countTimeInHMBetweenDates = (startDate, endDate) => {
  const minutes = countMinutesBetweenDates(startDate, endDate);
  const fullHours = Math.floor(minutes / 60);
  const lastMinutes = minutes - fullHours * 60;
  return `${fullHours}h ${lastMinutes}m`;
};

export const minutesToHM = minutes => {
  const fullHours = Math.floor(Math.abs(minutes) / 60);
  const lastMinutes = Math.round(Math.abs(minutes) - fullHours * 60);
  return lastMinutes == 60
    ? minutes < 0
      ? `-${fullHours + 1}h ${0}m`
      : `${fullHours + 1}h ${0}m`
    : minutes < 0
    ? `-${fullHours}h ${lastMinutes}m`
    : `${fullHours}h ${lastMinutes}m`;
};

export const dateIsAfterDayStart = (dateFrom, dateToCompare) => {
  if (!dateFrom) return true;
  dateFrom = moment(dateFrom)
    .hour(0)
    .minute(0)
    .second(0);
  return moment(dateToCompare).isSameOrAfter(dateFrom);
};

export const dateIsBeforeDayEnd = (dateTo, dateToCompare) => {
  if (!dateTo) return true;
  dateTo = moment(dateTo)
    .hour(23)
    .minute(59)
    .second(59);
  return moment(dateToCompare).isSameOrBefore(dateTo);
};

export const dateRangeToString = (dateFrom, dateTo) =>
  `Date: ${moment(dateFrom).format("DD/MM/YY")} - ${moment(dateTo).format(
    "DD/MM/YY"
  )}`;
