import React from "react";
import { useSelector } from "react-redux";
import styles from "../TablePageHolder.module.css";
import { Button, Icon } from "semantic-ui-react";
import { RestoreIcon } from "../../../assets/icons/RestoreIcon";
import { currentUserIdSelector } from "../../../selectors/user";
import CustomTooltip from "../../CustomTooltip/CustomTooltip";

export const ActionsColumn = ({
  value,
  row,
  isArchivedView,
  accessName,
  onUpdateModalOpen,
  onArchiveItem,
  hasPermissions,
  Can
}) => {
  const currentUserId = useSelector(currentUserIdSelector);

  return (
    <div className={styles.textButtonContainer}>
      {isArchivedView ? (
        <>
          <Can I="delete" a={accessName}>
            <CustomTooltip
              toolTipClassName={
                hasPermissions && !hasPermissions(row)
                  ? styles.noPermissionTooltipPosition
                  : styles.restoreButtonTooltipPosition
              }
              content={
                hasPermissions && !hasPermissions(row)
                  ? "You dont have permissions to perform this action"
                  : "Restore"
              }
            >
              <Button
                disabled={hasPermissions ? !hasPermissions(row) : false}
                basic
                className={`${styles.textButton} ${styles.restoreButton}`}
                icon={<RestoreIcon />}
                onClick={e => onArchiveItem(e, value, "Restore")}
              />
            </CustomTooltip>
          </Can>
          <Can I="delete" a={accessName}>
            <CustomTooltip
              toolTipClassName={
                hasPermissions && !hasPermissions(row)
                  ? styles.archiveTooltipPosition
                  : ""
              }
              content={
                hasPermissions && !hasPermissions(row)
                  ? "You dont have permissions to perform this action"
                  : "Delete"
              }
            >
              <Button
                basic
                disabled={
                  currentUserId === value ||
                  (hasPermissions && !hasPermissions(row))
                }
                className={styles.deleteButton}
                icon={<Icon name="trash alternate" />}
                onClick={e => onArchiveItem(e, value, "Delete")}
              />
            </CustomTooltip>
          </Can>
        </>
      ) : (
        <>
          <Can I="update" a={accessName}>
            <CustomTooltip
              toolTipClassName={
                !(value === currentUserId) &&
                hasPermissions &&
                !hasPermissions(row)
                  ? styles.noPermissionTooltipPosition
                  : styles.editButtonTooltipPosition
              }
              content={
                !(value === currentUserId) &&
                hasPermissions &&
                !hasPermissions(row)
                  ? "You dont have permissions to perform this action"
                  : "Edit"
              }
            >
              <Button
                basic
                disabled={
                  !(value === currentUserId) && hasPermissions
                    ? !hasPermissions(row)
                    : false
                }
                className={`${styles.textButton} ${styles.editButton}`}
                icon={<Icon name="edit" />}
                onClick={e => onUpdateModalOpen(e, value)}
              />
            </CustomTooltip>
          </Can>
          <Can I="delete" a={accessName}>
            <CustomTooltip
              toolTipClassName={
                hasPermissions && !hasPermissions(row)
                  ? styles.archiveTooltipPosition
                  : ""
              }
              content={
                value === currentUserId
                  ? "Can't archive yourself"
                  : hasPermissions && !hasPermissions(row)
                  ? "You dont have permissions to perform this action"
                  : "Archive"
              }
            >
              <Button
                basic
                disabled={
                  value === currentUserId ||
                  (hasPermissions && !hasPermissions(row))
                }
                className={`${styles.textButton} ${styles.archiveButton}`}
                icon={<Icon name="archive" />}
                onClick={e => onArchiveItem(e, value, "Archive")}
              />
            </CustomTooltip>
          </Can>
        </>
      )}
    </div>
  );
};
