import React, { Component } from "react";
import PropTypes from "prop-types";

import "react-table-6/react-table.css";
import {
  Button,
  Confirm,
  Dropdown,
  Grid,
  Header,
  Segment,
  Sidebar
} from "semantic-ui-react";

import { history } from "../../store";
import Layout from "../Layout";
import ListTable from "../ListTable";
import ErrorBar from "../ErrorBar";
import styles from "./ListHolder.module.css";
import { Mixpanel } from "../../tools/mixpanel/Mixpanel";
import { MixpanelEvents } from "../../tools/mixpanel/MixpanelEvents";
import SidebarHolder from "../SidebarHolder";
import FlattenRecordsView from "../FlattenRecordsView";
import { pageSize } from "../ListTable/Pagination";

class ListHolder extends Component {
  state = {
    sideBarShown: false,
    sideBarId: null,
    confirmIsShown: false,
    idToDelete: null,
    rawMode: false,
    headerMenuOptions: [],
    selectedItems: []
  };

  componentDidMount() {
    document.body.addEventListener("click", this.handleBlur);
    this.generateHeaderMenuOptions();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.rawMode !== this.state.rawMode) {
      this.generateHeaderMenuOptions();
    }
  }

  componentWillUnmount() {
    document.body.removeEventListener("click", this.handleBlur);
  }

  handleBlur = event => {
    if (
      !(
        event.target.closest("#listTable") ||
        event.target.closest("#sidebarHolder") ||
        event.target.closest(".modal")
      )
    ) {
      this.closeSideBar();
    }
  };

  openSideBar = itemId => {
    const { setActiveItem } = this.props;
    this.setState({
      sideBarShown: !!itemId,
      sideBarId: itemId
    });
    setActiveItem(itemId);
  };

  closeSideBar = () => {
    Mixpanel.track(MixpanelEvents.BUTTON_CLICK, {
      action: "close_side_bar"
    });
    this.setState({
      sideBarShown: false
    });
  };

  showConfirm = idToDelete => {
    this.setState({
      confirmIsShown: true,
      idToDelete
    });
  };

  closeConfirm = () => {
    this.setState({
      confirmIsShown: false,
      idToDelete: null
    });
  };

  onDelete = () => {
    const { idToDelete } = this.state;
    const { delData } = this.props;

    delData(idToDelete);
    this.closeConfirm();
  };

  onRowClick = ({ original }) => {
    const { children } = this.props;
    const { sideBarShown, sideBarId } = this.state;
    if (!children) {
      return;
    }
    this.openSideBar(original.id);
    if (sideBarShown && sideBarId === original.id) {
      this.closeSideBar();
    }
  };

  hideError = () => {
    this.setState({
      showError: false
    });
  };

  generateHeaderMenuOptions = () => {
    const { rawMode } = this.state;
    this.setState({
      headerMenuOptions: [
        {
          key: "rawMode",
          text: !rawMode ? "Raw data view" : "List view",
          value: () => this.setState({ rawMode: !rawMode })
        }
      ]
    });
  };

  setSelectedItems = selectedItems => {
    this.setState({
      selectedItems
    });
  };

  render() {
    const {
      route,
      filterName,
      location,
      currentPage,
      totalPages,
      isFetching,
      size,
      totalElements,
      children,
      editPath,
      rawData,
      data,
      columns,
      getData,
      accessName,
      Can,
      error,
      online,
      updateAction,
      editLink,
      defaultSort,
      className,
      addNewPath,
      addAction,
      addButtonLabel,
      batchActionPath,
      batchAction,
      batchActionButtonLabel,
      extraActionButtons,
      rawDataOptions,
      ...extraProps
    } = this.props;

    const {
      sideBarShown,
      confirmIsShown,
      showError,
      headerMenuOptions,
      rawMode,
      selectedItems
    } = this.state;

    return (
      <Layout
        route={route}
        location={location}
        classForMain={styles.mainHolder}
      >
        <Confirm
          open={confirmIsShown}
          onCancel={this.closeConfirm}
          onConfirm={this.onDelete}
          confirmButton="Yes"
        />
        <ErrorBar showError={showError} onHide={this.hideError} error={error} />
        <Sidebar.Pushable className={`${styles.farmsHolder} ${className}`}>
          {children && (
            <Sidebar
              animation="overlay"
              direction="right"
              visible={sideBarShown}
              className={styles.sidebarHolder}
              onHidden={() => this.openSideBar(null)}
              id="sidebarHolder"
            >
              <SidebarHolder
                updateAction={updateAction}
                accessName={accessName}
                onClose={this.closeSideBar}
                Can={Can}
                editLink={editLink}
              >
                {children}
              </SidebarHolder>
            </Sidebar>
          )}
          <Sidebar.Pusher>
            <Segment basic className={styles.headerTasks}>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={11}>
                    <Header as="h2" className={styles.taskName}>
                      {route.name}
                      <Dropdown
                        text={" "}
                        floating
                        className={styles.dropdownHeaderMenu}
                        options={headerMenuOptions}
                      >
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => this.setState({ rawMode: !rawMode })}
                          >
                            {!this.state.rawMode
                              ? "Raw data view"
                              : "List view"}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Header>
                  </Grid.Column>
                  <Grid.Column width={5}>
                    {addNewPath && selectedItems.length === 0 && (
                      <Can I={addAction} a={accessName}>
                        <Button
                          content={addButtonLabel}
                          floated="right"
                          size="large"
                          primary
                          onClick={() => history.push(addNewPath)}
                        />
                      </Can>
                    )}
                    {batchActionPath && selectedItems.length > 0 && (
                      <Can I={batchAction} a={accessName}>
                        <Button
                          content={batchActionButtonLabel}
                          floated="right"
                          size="large"
                          primary
                          onClick={() =>
                            history.push(batchActionPath, {
                              ids: selectedItems.map(item =>
                                item.substring("select-".length, item.length)
                              )
                            })
                          }
                        />
                      </Can>
                    )}
                    {extraActionButtons}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
            <Segment>
              {rawMode ? (
                <FlattenRecordsView
                  name={route.name}
                  data={rawData}
                  options={rawDataOptions}
                  getData={getData}
                  isFetching={isFetching}
                />
              ) : (
                <ListTable
                  filterName={filterName}
                  currentPage={currentPage}
                  pageSize={pageSize(size)}
                  totalPages={totalPages}
                  data={data}
                  columns={columns}
                  getTrProps={(state, rowInfo) => ({
                    onClick: () => this.onRowClick(rowInfo)
                  })}
                  getData={getData}
                  isFetching={isFetching}
                  totalElements={totalElements}
                  withDateRange={false}
                  accessName={accessName}
                  addNewPath={addNewPath}
                  editPath={editPath}
                  Can={Can}
                  showConfirm={this.showConfirm}
                  online={online}
                  defaultSort={defaultSort}
                  keyField={"id"}
                  selectedItems={selectedItems}
                  setSelectedItems={this.setSelectedItems}
                  {...extraProps}
                />
              )}
            </Segment>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Layout>
    );
  }
}

ListHolder.propTypes = {
  rawData: PropTypes.array,
  content: PropTypes.array,
  addNewPath: PropTypes.string,
  addAction: PropTypes.string,
  addActionLabel: PropTypes.string,
  batchActionPath: PropTypes.string,
  batchAction: PropTypes.string,
  batchActionButtonLabel: PropTypes.string,
  editPath: PropTypes.string,
  getData: PropTypes.func,
  delData: PropTypes.func,
  setActiveItem: PropTypes.func,
  accessName: PropTypes.string,
  Can: PropTypes.func,
  route: PropTypes.object,
  location: PropTypes.object,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  isFetching: PropTypes.bool,
  size: PropTypes.number,
  totalElements: PropTypes.number,
  children: PropTypes.object,
  data: PropTypes.array,
  columns: PropTypes.array,
  error: PropTypes.object,
  online: PropTypes.bool,
  updateAction: PropTypes.string,
  editLink: PropTypes.string,
  defaultSort: PropTypes.string,
  className: PropTypes.string,
  addButtonLabel: PropTypes.string,
  extraActionButtons: PropTypes.object,
  rawDataOptions: PropTypes.object,
  withSelection: PropTypes.bool,
  exportToExcel: PropTypes.bool
};

ListHolder.defaultProps = {
  content: [],
  columns: [],
  data: [],
  addAction: "add",
  addButtonLabel: "Add",
  withSelection: false
};

export default ListHolder;
