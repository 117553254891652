import React from "react";
import { Button, Form } from "semantic-ui-react";
import { Field, Formik } from "formik";
import styles from "../Setting.module.css";
import PropTypes from "prop-types";
import AdditionalInformation from "./AdditionalInformation";

const BusinessInformationForm = ({
  settings,
  onSubmit,
  setEditCheck,
  editCheck
}) => {
  return (
    <Formik
      enableReinitialize
      initialValues={
        settings || {
          name: "",
          abn: "",
          picture: {}
        }
      }
      onSubmit={values => {
        onSubmit(values);
      }}
    >
      {props => (
        <Form className={styles.form} onSubmit={props.handleSubmit}>
          <Form.Group>
            <Form.Field className={styles.logoField} fluid width={16}>
              <AdditionalInformation
                settings={settings}
                editCheck={editCheck}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field className={styles.nameField} fluid width={16} required>
              <label className={styles.label}>Business name</label>
              <Field as={Form.Input} name="name" fluid width={16} />
            </Form.Field>
            <Form.Field className={styles.abnField} fluid width={16}>
              <label className={styles.label}>ABN</label>
              <Field as={Form.Input} name="abn" fluid width={16} />
            </Form.Field>
          </Form.Group>

          <div className={styles.buttonContainer}>
            {true && (
              <Button
                type="button"
                className={styles.cancelBtn}
                onClick={() => setEditCheck(false)}
              >
                Cancel
              </Button>
            )}
            <Button
              type="submit"
              disabled={!props.dirty || !props.values.name}
              className={styles.saveButton}
            >
              Save changes
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

BusinessInformationForm.propTypes = {
  farmData: PropTypes.object,
  onSubmit: PropTypes.func.isRequired
};

export default BusinessInformationForm;
