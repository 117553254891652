import React from "react";
import classNames from "classnames";
import styles from "./CustomTooltip.module.css";

const CustomTooltip = ({
  containerClassName,
  toolTipClassName,
  content,
  children
}) => {
  return (
    <div className={classNames(containerClassName, styles.elementContainer)}>
      {children}
      <div className={classNames(toolTipClassName, styles.tooltipBody)}>
        {content}
      </div>
    </div>
  );
};

export default CustomTooltip;
