import axios from "axios";
import { requestsCancels } from "../index";
import { store } from "../index";
import { userSelector } from "../selectors/user";

const API_ERRORS_TO_RETRY = [401];
const API_RETRY_COUNT = 3;
const CancelToken = axios.CancelToken;
const API_URL = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`;

export function axiosProxy(requestOptions) {
  return requestOptions.externalApi
    ? retryAxiosProxy(requestOptions)
    : apiAxiosProxy(requestOptions);
}

async function apiAxiosProxy({
  url,
  method = "GET",
  headers = {},
  callFrom,
  uniqId,
  shouldUseRetryConfig = true,
  ...rest
}) {
  const user = userSelector(store.getState());

  const axiosConfig = {
    method,
    headers: {
      "X-REQUEST-ID": uniqId,
      "Content-Type": "application/json-patch+json",
      "Cache-Control": "no-cache,no-store,must-revalidate,max-age=-1,private",
      "Access-Control-Max-Age": 600,
      Accept: "application/json",
      Expires: "-1",
      ...headers,
      authorization: `Bearer ${user && user.access_token}`
    },
    url: `${API_URL}${url}`,
    cancelToken: new CancelToken(function executor(cancel) {
      // An executor function receives a cancel function as a parameter
      if (callFrom) {
        requestsCancels[callFrom] = cancel;
      }
    }),
    ...rest
  };

  const retryConfig = {
    errorsToRetry: API_ERRORS_TO_RETRY,
    retryCount: API_RETRY_COUNT,
    retryStrategy: retryCount => Math.pow(2, retryCount) * 500,
    preRetryConfigUpdate: {
      401: ({ config }) => {
        const user = userSelector(store.getState());
        if (user) {
          config.headers.authorization = `Bearer ${user && user.access_token}`;
        }
        return config;
      }
    }
  };

  return retryAxiosProxy({
    ...axiosConfig,
    ...(shouldUseRetryConfig && { retryConfig })
  });
}

async function retryAxiosProxy({ retryConfig, ...rest }) {
  const axiosRequest = axios.create();

  if (retryConfig) {
    let retryCount = 0;
    axiosRequest.interceptors.response.use(null, async error => {
      if (
        error.config &&
        error.response &&
        retryConfig.errorsToRetry.includes(error.response.status) &&
        retryCount <= retryConfig.retryCount
      ) {
        await new Promise(resolve =>
          setTimeout(resolve, retryConfig.retryStrategy(retryCount))
        );
        retryCount++;
        return axiosRequest.request(
          retryConfig.preRetryConfigUpdate[error.response.status]
            ? retryConfig.preRetryConfigUpdate[error.response.status](error)
            : error.config
        );
      }
      return Promise.reject(error);
    });
  }

  return axiosRequest(rest);
}
