import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";
import { bindActionCreators } from "redux";

import { abilitiesSelector } from "../../../../selectors/user";
import { getParentPageHref } from "../../../../utils/routeHelpers";
import LayoutOverlay from "../../../../components/LayoutOverlay";
import styles from "./AddStockItem.module.css";
import { history } from "../../../../store";
import Loader from "../../../../components/Loader";
import { getStockById } from "../../../../actions/Stock/stock";
import AddStockItemForm from "./AddStockItemForm";

class AddStockItem extends Component {
  state = {
    isFetching: false,
    stock: null
  };

  constructor(props) {
    super(props);
    this.stockForm = React.createRef();
  }

  async componentWillMount() {
    const { match } = this.props;
    const { id } = match.params;
    if (id) {
      this.getStockById(id);
    } else {
      this.setState({
        isFetching: false
      });
    }
  }

  getStockById = id => {
    const {
      actions: { getStockById }
    } = this.props;
    this.setState({
      isFetching: true
    });

    getStockById(id)
      .then(stock => {
        this.setState({
          isFetching: false,
          stock
        });
      })
      .catch(error => {
        if (error.response.status === 404) {
          history.push("/404");
        }
        this.setState({
          isFetching: false
        });
      });
  };

  doSubmitForm = () => {
    if (this.stockForm.current) {
      this.stockForm.current.submit();
    }
  };

  render() {
    const {
      route,
      Can,
      match: {
        params: { id }
      }
    } = this.props;
    const { isFetching, stock } = this.state;

    return (
      <LayoutOverlay
        closeLink={getParentPageHref(route)}
        route={{
          ...route,
          name: id ? route.name.replace("Add", "Edit") : route.name
        }}
        childrenOnly
        footer={
          <div className={styles.buttonsFooter}>
            <Can I="add" a="stock">
              <Button primary size="large" onClick={this.doSubmitForm}>
                Save
              </Button>
            </Can>
          </div>
        }
      >
        {isFetching ? (
          <Loader />
        ) : (
          <AddStockItemForm
            route={route}
            initialValues={stock}
            formRef={this.stockForm}
          />
        )}
      </LayoutOverlay>
    );
  }
}

AddStockItem.propTypes = {
  route: PropTypes.object.isRequired,
  stock: PropTypes.object,
  match: PropTypes.object,
  actions: PropTypes.object,
  Can: PropTypes.func
};

AddStockItem.defaultProps = {
  route: { name: "{%name%}" }
};

const mapStateToProps = state => {
  return {
    Can: abilitiesSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getStockById
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(AddStockItem);
