import PropTypes from "prop-types";
import React, { useEffect, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { Form } from "semantic-ui-react";
import { getCropsList } from "actions/Farms/crops";
import { isEmpty } from "lodash";
import { cropsSelector } from "../../../../../selectors/crops";

const SelectCrop = ({
  actions: { getCropsList },
  cropsList,
  isFetching,
  onChange,
  checker,
  value,
  name
}) => {
  useEffect(() => {
    if (!isFetching && isEmpty(cropsList)) {
      getCropsList();
    }
  }, [isFetching, getCropsList]);

  const options = useMemo(() => cropsList, [cropsList]);
  return (
    <Form.Dropdown
      fluid
      error={!!checker}
      placeholder="Select crop"
      options={options}
      onChange={(_, { value }) =>
        onChange(cropsList.find(item => item.value === value))
      }
      search
      selection
      name={name}
      value={value}
    />
  );
};

SelectCrop.propTypes = {
  actions: PropTypes.object.isRequired,
  cropsList: PropTypes.array.isRequired,
  disabled: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  minItemForSearch: PropTypes.bool,
  native: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
  showArchived: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.array.isRequired,
    PropTypes.number.isRequired
  ])
};

SelectCrop.defaultProps = {
  cropsList: [],
  disabled: false,
  error: false,
  isFetching: false,
  minItemForSearch: 10,
  native: false,
  onChange: () => {},
  required: false,
  showArchived: false
};

const mapStateToProps = state => {
  return {
    cropsList: cropsSelector(state),
    isFetching: state.crops.isFetching
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getCropsList
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectCrop);
