import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { Button, TableCell, TableRow } from "semantic-ui-react";
import OperatingCostsTable from "./OperatingCostsTable";
import Numeric from "components/Numeric/Numeric";

const TaskRow = ({ task }) => {
  const [isExpanded, setExpanded] = useState(false);
  const costType = task[0].costType;
  const allCostSize = task.reduce((prev, curr) => {
    return prev + curr.cost;
  }, 0);
  return (
    <>
      <TableRow>
        <TableCell>
          <Button
            active={isExpanded}
            onClick={() => setExpanded(!isExpanded)}
            compact
            icon="dropdown"
          />
        </TableCell>
        <TableCell>
          {(
            costType
              .toLowerCase()
              .charAt(0)
              .toUpperCase() + costType.toLowerCase().slice(1)
          ).replace(/_/g, " ")}
        </TableCell>
        <TableCell>
          <Numeric fractionDigits={2} value={allCostSize} defaultValue={"-"} />
        </TableCell>
      </TableRow>
      {isExpanded && (
        <TableRow>
          <TableCell colSpan={9}>
            <OperatingCostsTable task={task} />
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

TaskRow.propTypes = {
  task: PropTypes.object
};

export default memo(TaskRow);
