import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MultiSelectFilter from "./MultiSelectFilter/MultiSelectFilter";
import PropTypes from "prop-types";
import { fetchScoutingTasks } from "../../../actions/Scouting/scouting";

const ScoutingNameMultiSelectFilter = ({
  actions: { fetchList },
  list,
  isFetching,
  ...extraProps
}) => {
  useEffect(() => {
    fetchList({ unpaged: true });
  }, [fetchList]);

  const options = useMemo(
    () =>
      list.map(item => ({
        key: item.name,
        text: `${item.name}`,
        value: item.name
      })),
    [list]
  );

  return (
    <MultiSelectFilter
      options={options}
      numbers={false}
      loading={isFetching}
      {...extraProps}
    />
  );
};

const mapStateToProps = ({
  scouting: {
    data: { content },
    isFetching
  }
}) => {
  return {
    list: content,
    isFetching
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchList: fetchScoutingTasks }, dispatch)
});

ScoutingNameMultiSelectFilter.propTypes = {
  isFetching: PropTypes.bool,
  list: PropTypes.array,
  actions: PropTypes.object
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScoutingNameMultiSelectFilter);
