import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Dropdown,
  Header,
  Input,
  Icon,
  Label,
  Button
} from "semantic-ui-react";
import Checkbox from "../../../../../components/Table/components/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, map, keyBy, debounce } from "lodash";
import styles from "./batchNumberDropdown.module.css";

const BatchNumberDropDown = ({
  value = [],
  id,
  setChemicalItemToAdd,
  batchNumbers,
  index,
  selectedChemicals,
  item
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [values, setValues] = useState([...(value || [])]);

  const chemicalBatchNumbers =
    batchNumbers.data.find(
      ({ id }) => id == (selectedChemicals[index]?.id || item.id)
    )?.data || [];

  const options = chemicalBatchNumbers
    ?.map(batchNumber => ({
      value: batchNumber,
      text: batchNumber,
      key: batchNumber
    }))
    .filter(item => values.every(batchNumber => batchNumber !== item.text));

  const selectedOptions = chemicalBatchNumbers
    ?.map(batchNumber => ({
      value: batchNumber,
      text: batchNumber,
      key: batchNumber
    }))
    .filter(item => values.some(batchNumber => batchNumber === item.text));

  const newOptions = [...selectedOptions, ...options];

  const filtred = newOptions?.filter(item =>
    item.text.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const onOptionClick = (batchNumber, index) => {
    if (!values.includes(batchNumber)) {
      setValues([...values, batchNumber]);
      setChemicalItemToAdd(id, {
        ...item,
        batchNumbers: [...values, batchNumber]
      });
    } else {
      const deletedValues = values.filter((item, indx) => item !== batchNumber);

      setValues(deletedValues);
      setChemicalItemToAdd(id, {
        ...item,
        batchNumbers: deletedValues
      });
    }
  };

  const onItemToAdd = () => {
    setSearchQuery("");
    !values.includes(searchQuery) && setValues([...values, searchQuery]);
    setChemicalItemToAdd(id, {
      ...item,
      batchNumbers: !values.includes(searchQuery)
        ? [...values, searchQuery]
        : [...values]
    });
  };
  return (
    <Dropdown
      search
      multiple
      selection
      fluid
      value
      allowAdditions={!chemicalBatchNumbers.includes(searchQuery)}
      className={styles.batchNumberDropDown}
      onClose={() => setSearchQuery("")}
      onSearchChange={(_, { searchQuery }) => {
        setSearchQuery(searchQuery);
      }}
      onAddItem={() => onItemToAdd()}
    >
      <Dropdown.Menu className={styles.batchNumberDropDownMenu}>
        <div className={styles.labelContainer}>
          {values.map((batch, batchIndex) => {
            return (
              <Label className={styles.addItem}>
                {batch}
                <Icon
                  name="close"
                  onClick={() => {
                    const filteredValues = values.filter(
                      (item, indx) => indx !== batchIndex
                    );
                    setValues(filteredValues);
                    setChemicalItemToAdd(id, {
                      ...item,
                      batchNumbers: filteredValues
                    });
                  }}
                />
              </Label>
            );
          })}
        </div>

        <Dropdown.Menu
          className={styles.batchNumberDropDownScrollingMenu}
          scrolling
        >
          {(filtred || []).map((option, index) => (
            <Dropdown.Item
              key={option.value}
              value={option.value}
              text={option.text}
              content={
                <Checkbox
                  label={option.text}
                  checked={values.includes(option.value)}
                />
              }
              onClick={(e, { value }) => onOptionClick(value, index)}
            />
          ))}
          {!newOptions?.length && !searchQuery && !values.length && (
            <Dropdown.Item>Nothing was found</Dropdown.Item>
          )}
          {!filtred.length && searchQuery && (
            <div className={styles.addButtonWrapper}>
              <Button
                onClick={() => onItemToAdd()}
                className={styles.addButton}
              >
                Add{" "}
                <span className={styles.addButtonBatchNumber}>
                  {searchQuery}
                </span>
              </Button>
            </div>
          )}
        </Dropdown.Menu>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default BatchNumberDropDown;
