import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Dropdown } from "semantic-ui-react";
import { valueToString } from "../../../../../utils/tasksUtils";
import styles from "./SelectDilusionRate.module.css";
import { unitsSelector } from "selectors/chemicals";
import { connect } from "react-redux";

const DilusionRate = ({ item, setItem, error, applicationType }) => {
  const [defaultOpen, setDefaultOpen] = useState(false);
  const [rates, setRates] = useState();
  const rateValue = item.rates
    ? item.rates.find(r => r.id === item.rate.id)
    : null;
  const id = item.new ? "new" : item.id;
  useEffect(() => {
    setRates(item.rates);
  }, [applicationType]);
  const { content, isFetching } = useSelector(unitsSelector);

  const multiplier = item => {
    const foundElement = content.find(({ id }) => id === item);

    if (foundElement) {
      return 1 / foundElement.multiplicationFactor;
    }

    return 1;
  };

  const selectItem = async data => {
    await setDefaultOpen(false);
    const rate = item.rates.find(r => r.id === data.value);
    if (rate.minRate >= 0 && (!rate.maxRate || rate.minRate === rate.maxRate)) {
      rate.rate = rate.minRate;
    }
    setItem(id, { ...item, rate });
  };

  const setText = rateInfo => {
    const { crop, purpose, minRate, maxRate, unit } = rateInfo;

    return [
      crop && crop.name ? crop.name : "Generic",
      purpose,
      minRate === maxRate || !valueToString(maxRate)
        ? minRate * multiplier(unit)
        : `${minRate * multiplier(unit)}-${maxRate * multiplier(unit)}`
    ]
      .filter(item => item)
      .join(" - ");
  };

  const setContent = rateInfo => {
    const { crop, purpose, minRate, maxRate, unit } = rateInfo;

    return (
      <div>
        <div>{crop && crop.name ? crop.name : "Generic"}</div>
        {purpose && <div>{purpose}</div>}
        <div>
          {minRate === maxRate || !valueToString(maxRate)
            ? minRate * multiplier(unit)
            : `${minRate * multiplier(unit)}-${maxRate * multiplier(unit)}`}
        </div>
      </div>
    );
  };

  const options = (applicationType === "SPRAY"
    ? item.rates
    : item.rates.filter(rate => rate.rateType === "PER_HECTARE")
  ).map(rate => ({
    key: rate.id,
    text: setText(rate),
    value: rate.id,
    content: setContent(rate)
  }));
  return (
    <Dropdown
      search
      fluid
      selection
      closeOnChange
      defaultOpen={defaultOpen}
      disabled={!item.rates || !item.rates.length}
      options={options}
      className={styles.dilutionDropdown}
      text={rateValue && setContent(rateValue)}
      value={rateValue && rateValue.id}
      onChange={(_, data) => selectItem(data)}
      error={error}
      menuTransition="slide up"
    />
  );
};

DilusionRate.propTypes = {
  error: PropTypes.bool,
  setItem: PropTypes.func,
  item: PropTypes.object
};

const mapStateToProps = ({ sprayDiary: { applicationType }, spray }) => ({
  applicationType
});

export default connect(mapStateToProps, {})(DilusionRate);
