import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "react-table-6/react-table.css";
import BasicCell from "components/Table/components/BasicCell";
import { bindActionCreators } from "redux";
import styles from "./LaborGroups.module.css";
import { abilitiesSelector } from "../../../selectors/user";
import {
  deleteLaborGroup,
  fetchLaborGroups,
  laborGroupStatusChange,
  deleteLaborGroups
} from "../../../actions/LaborGroups/laborGroups";
import { fetchEmployeesList } from "../../../actions/Employee/employees";
import Sidebar from "./Sidebar/LaborGroupSidebar";
import { Button, Popup } from "semantic-ui-react";
import TablePageHolder from "../../../components/TablePageHolder/TablePageHolder";
import { getHashParameter } from "../../../utils/hashToObject";
import _, { keyBy } from "lodash";
import AddUpdateGroupModal from "./Modals/AddUpdateGroupModal/AddUpdateGroupModal";
import AssignWorkersModal from "./Modals/AssignWorkersModal/AssignWorkersModal";
import TableActionButtons from "../components/TableActionButtons/TableActionButtons";
import ConfirmationModal from "../components/ConfirmationModal/ConfirmationModal";
import { ROLE_COLORS } from "../constants";
import { getNameInitials } from "../utils";
import { ActionMessage } from "../components/ActionMessage/ActionMessage";
import { DeletedMessage } from "../components/ActionMessage/DeletedMessage";

class LaborGroups extends Component {
  state = {
    tableData: [],
    tableColumns: [],
    sideBarShown: false,
    activeItem: {},
    activeItemId: null,
    addEditModalOpen: false,
    editItemId: null,
    selectedItems: [],
    tempArchivedItems: [],
    tempDeletedItems: [],
    assignWorkersModalOpen: false,
    archiveItemId: null,
    confirmationModalOpen: false,
    actionType: "",
    dataRefetch: 1
  };

  componentDidMount() {
    this.prepareData();
    this.props.actions.fetchEmployeesList();
  }

  getData = params => {
    const { isArchivedView, actions } = this.props;
    this.setSelectedItems([]);
    actions.fetchAction({
      ...params,
      archived: isArchivedView ? isArchivedView : false
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { data } = this.props;
    if (prevProps.data !== data) this.prepareData();
  }

  morePopupContent = members => {
    const { employees } = this.props;

    return (
      <div className={styles.morePopupContent}>
        {members.length &&
          members &&
          members.slice(3, members.length).map(emp => {
            const employee =
              (employees &&
                emp &&
                employees.find(employeeObj => employeeObj.id === emp)) ||
              null;
            const employeeImg =
              employee && employee.picture
                ? employee.picture.presignedUrl
                : null;

            return (
              employee &&
              !employee.archived && (
                <div key={employee.id} className={styles.popupItem}>
                  <div
                    className={styles.avatarHolder}
                    style={
                      employeeImg
                        ? {
                            backgroundImage:
                              employeeImg && `url(${employeeImg})`
                          }
                        : {
                            background: ROLE_COLORS[employee.type]
                              ? ROLE_COLORS[employee.type]
                              : "gray"
                          }
                    }
                  >
                    {!employeeImg && getNameInitials(employee)}
                  </div>
                  <span>{`${employee.firstName} ${employee.lastName}`}</span>
                </div>
              )
            );
          })}
      </div>
    );
  };

  prepareData = () => {
    const { data, employees, isArchivedView } = this.props;
    const columnsNames = {
      name: {
        title: "Name",
        withSort: true,
        type: "string",
        id: "name",
        cellWidth: isArchivedView ? 10 : 6,
        accessor: row => `${row.name}`
      },
      ...(!isArchivedView
        ? {
            numberOfEmployees: {
              title: "Number of workers",
              cellWidth: 6,
              // disableSortBy: true,
              accessor: row => `${row.numberOfEmployees}`
            },
            employees: {
              title: "Workers",
              cellWidth: 6,
              disableSortBy: true,
              accessor: row => {
                return row.numberOfEmployees ? (
                  <ul className={styles.avatarsContainer}>
                    {row.employeeIds.slice(0, 3).map(employeeId => {
                      const employee =
                        (employees &&
                          employees.find(
                            employeeObj => employeeObj.id === employeeId
                          )) ||
                        null;
                      const employeeImg =
                        employee && employee.picture
                          ? employee.picture.presignedUrl
                          : null;

                      return employeeImg ? (
                        <Popup
                          position="bottom center"
                          inverted
                          key={employee.id}
                          trigger={
                            <li
                              key={employee.id}
                              className={styles.avatarHolder}
                              style={{ backgroundImage: `url(${employeeImg})` }}
                            />
                          }
                          content={`${employee.firstName} ${employee.lastName}`}
                        />
                      ) : (
                        <Popup
                          key={employee && employee.id}
                          inverted
                          position="bottom center"
                          trigger={
                            <li
                              key={employee && employee.id}
                              className={styles.avatarHolder}
                              style={{
                                background: ROLE_COLORS[
                                  employee && employee.type
                                ]
                                  ? ROLE_COLORS[employee && employee.type]
                                  : "gray"
                              }}
                            >
                              {getNameInitials(employee && employee)}
                            </li>
                          }
                          content={`${employee &&
                            employee.firstName} ${employee &&
                            employee.lastName}`}
                        />
                      );
                    })}
                    {row.numberOfEmployees > 3 && (
                      <Popup
                        inverted
                        position="right center"
                        popperModifiers={{
                          preventOverflow: {
                            boundariesElement: "offsetParent"
                          }
                        }}
                        content={this.morePopupContent(row.employeeIds)}
                        trigger={<span>+{row.numberOfEmployees - 3}</span>}
                      />
                    )}
                  </ul>
                ) : (
                  "—"
                );
              }
            }
          }
        : {}),
      actions: {
        title: "",
        cellWidth: "auto",
        className: styles.actionCells,
        accessor: row => row.id,
        disableSortBy: true
      }
    };

    const tableColumns = [];
    const tableData = [];

    Object.entries(columnsNames).map(([id, targetColumn]) => {
      const columnToAdd = {
        id,
        ...targetColumn,
        accessor: targetColumn.accessor || id,
        Header: targetColumn.title,
        Cell: targetColumn.Cell || BasicCell,
        className: `cell_${targetColumn.cellWidth} ${targetColumn.className ||
          ""}`,
        ...(targetColumn.sortFields
          ? { sortFields: targetColumn.sortFields }
          : {}),
        disableSortBy: targetColumn.disableSortBy
      };

      tableColumns.push(columnToAdd);
    });

    if (data && data.content) {
      data.content.forEach(row => {
        let updateRole;
        let deleteRole;
        switch (row.type) {
          case "OWNER": {
            deleteRole = "delete_owner";
            updateRole = "update_owner";
            break;
          }
          case "SUPERVISOR": {
            deleteRole = "delete_supervisor";
            updateRole = "update_supervisor";
            break;
          }
          default:
            deleteRole = "delete_casual_worker";
            updateRole = "update_casual_worker";
        }
        const rowData = {
          rights: {
            update: updateRole,
            delete: deleteRole
          },
          numberOfEmployees: row.employeeIds?.length
        };

        Object.keys(row).forEach(key => {
          switch (key) {
            default:
              rowData[key] = row[key] || "";
              break;
          }
        });
        tableData.push(rowData);
      });
    }
    this.setState({
      tableData,
      tableColumns
    });
  };

  updateTable = () => {
    this.setState({ dataRefetch: this.state.dataRefetch + 1 });
  };

  setCreateUpdateModalShown = (e, value) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState(prevState => ({
      addEditModalOpen: true,
      editItemId: !!value && value
    }));
  };

  setCreateUpdateModalHidden = () => {
    this.setState({
      addEditModalOpen: false
    });
  };

  setSelectedItems = items => {
    this.setState({ selectedItems: [...items] });
  };

  selectedGroups = () => {
    const { selectedItems } = this.state;
    const idsMap = keyBy(selectedItems);

    return this.state.tableData.filter((_, index) => !!idsMap[index]);
  };

  onArchiveItems = () => {
    const { selectedItems } = this.state;
    const { isArchivedView, actions } = this.props;
    const idsMap = keyBy(selectedItems);

    const itemsIds = this.state.tableData
      .filter((_, index) => !!idsMap[index])
      .map(({ id }) => id);
    this.setSelectedItems([]);
    this.setState({
      tempArchivedItems: itemsIds
    });
    actions.archiveAction(!isArchivedView, itemsIds).then(this.updateTable);
  };

  onChangeItem = () => {
    const { archiveItemId } = this.state;
    const { isArchivedView, actions, error } = this.props;
    if (this.state.actionType === "Delete") {
      actions
        .deleteAction(archiveItemId)
        .then(() => {
          if (!error) {
            this.setState({
              tempDeletedItems: [archiveItemId]
            });
          }
          this.setState({
            confirmationModalOpen: false,
            activeItemId: null
          });
        })
        .then(this.updateTable);
    } else {
      actions
        .archiveAction(!isArchivedView, [archiveItemId])
        .then(() => {
          this.setState({
            tempArchivedItems: [archiveItemId]
          });
          this.setState({
            confirmationModalOpen: false
          });
        })
        .then(this.updateTable);
    }
  };

  onDeleteItems = () => {
    const { selectedItems } = this.state;
    const { actions, error } = this.props;
    const idsMap = keyBy(selectedItems);

    const itemsIds = this.state.tableData
      .filter((_, index) => !!idsMap[index])
      .map(({ id }) => id);
    this.setSelectedItems([]);
    actions
      .deleteLaborGroups(itemsIds)
      .then(() => {
        if (!error) {
          this.setState({
            tempDeletedItems: itemsIds
          });
        }
      })
      .then(this.updateTable);
  };

  actionMessageUndo = () => {
    const { tempArchivedItems } = this.state;
    const { isArchivedView, actions } = this.props;
    actions
      .archiveAction(isArchivedView, [tempArchivedItems])
      .then(this.updateTable);
    this.setState({
      tempArchivedItems: []
    });
  };

  onArchiveItemClick = (e, value, actionType) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      confirmationModalOpen: true,
      archiveItemId: value && value,
      actionType
    });
  };

  onConfirmationModalClose = () => {
    this.setState({
      confirmationModalOpen: false
    });
  };

  getContractorReadable = archiveContractorId =>
    archiveContractorId
      ? `Contractor-${String(archiveContractorId).padStart(3, "0")}`
      : null;

  renderCell = props => {
    const {
      value,
      column: { Header }
    } = props;

    return (
      <div>
        <div className="cellTitle hide-md">{Header}</div>
        <div className="cellValue">{value}</div>
      </div>
    );
  };

  onRowClick = (e, row) => {
    if (row) {
      this.setState(prevState => ({
        ...prevState,
        activeItem: row.original,
        activeItemId: row.original.id
      }));
    }
  };

  clearActiveItemId = () => {
    this.setState(prevState => ({
      ...prevState,
      activeItemId: null,
      activeItem: {}
    }));
  };

  render() {
    const {
      route,
      location,
      content,
      currentPage,
      totalPages,
      isFetching,
      Can,
      totalElements,
      isArchivedView
    } = this.props;
    const {
      activeItemId,
      confirmationModalOpen,
      tableData,
      archiveItemId,
      tableColumns,
      editItemId,
      selectedItems,
      addEditModalOpen,
      tempArchivedItems,
      tempDeletedItems,
      assignWorkersModalOpen,
      actionType
    } = this.state;

    const expandedContent =
      activeItemId && content.find(e => e.id === activeItemId);

    const selectedGroup = content.find(group => group.id === activeItemId);

    const itemToArchive =
      archiveItemId &&
      content.find(contractor => contractor.id === archiveItemId);
    const defaultSort = getHashParameter(location, "sort") || "";
    const itemToEdit =
      editItemId && content.find(group => group.id === editItemId);

    const breadcrumbSections = [
      { key: "1", content: "Main menu", active: true, href: "/" },
      { key: "2", content: "Labour", active: true, href: "/labor_management" },
      { key: "3", content: route.name, active: false }
    ];

    return (
      <>
        {assignWorkersModalOpen && (
          <AssignWorkersModal
            update={this.updateTable}
            open={assignWorkersModalOpen}
            onClose={() => this.setState({ assignWorkersModalOpen: false })}
          />
        )}
        {addEditModalOpen ? (
          <AddUpdateGroupModal
            update={this.updateTable}
            open={addEditModalOpen}
            onClose={this.setCreateUpdateModalHidden}
            item={itemToEdit || expandedContent}
          />
        ) : null}
        <ConfirmationModal
          open={confirmationModalOpen}
          selectedItem={itemToArchive}
          actionType={actionType}
          onChangeItem={this.onChangeItem}
          onClose={this.onConfirmationModalClose}
        />
        <TablePageHolder
          actionsButtons={
            selectedItems.length ? (
              <TableActionButtons
                isArchive={isArchivedView}
                onDeleteButtonClick={this.onDeleteItems}
                onArchiveButtonClick={this.onArchiveItems}
                selectedItems={this.selectedGroups}
                actionsLocation="groups"
              />
            ) : null
          }
          defaultSort={defaultSort}
          totalPages={totalPages}
          currentPage={currentPage}
          totalElements={totalElements}
          getData={this.getData}
          isFetching={isFetching}
          location={location}
          mainButton={
            !isArchivedView && (
              <Can I="add" a="employee_groups">
                <Button
                  color="green"
                  basic
                  className={styles.assignWorkersButton}
                  onClick={() => {
                    this.setState({
                      assignWorkersModalOpen: true
                    });
                  }}
                >
                  Assign workers
                </Button>
                <Button
                  primary
                  className={styles.addButton}
                  onClick={e => this.setCreateUpdateModalShown(e)}
                >
                  Add
                </Button>
              </Can>
            )
          }
          onRowClick={this.onRowClick}
          onSidebarHidden={() => this.clearActiveItemId()}
          sidebarShown={!!activeItemId}
          pageTitle={route.name}
          route={route}
          selectedItems={selectedItems}
          setSelectedItems={this.setSelectedItems}
          sidebarComponent={
            <Sidebar
              update={this.updateTable}
              isArchive={isArchivedView}
              group={selectedGroup}
              onClose={this.clearActiveItemId}
              Can={({ children }) => <>{children}</>}
              onEditClick={this.setCreateUpdateModalShown}
              onDeleteItem={this.onArchiveItemClick}
            />
          }
          Can={({ children }) => <>{children}</>}
          accessName="employee_groups"
          tableColumns={tableColumns}
          tableData={tableData}
          updateAction="update"
          onUpdateModalOpen={this.setCreateUpdateModalShown}
          onArchiveItem={this.onArchiveItemClick}
          isArchivedView={isArchivedView}
          dataRefetch={this.state.dataRefetch}
        />
        {!this.props.error && (
          <DeletedMessage
            items={tempDeletedItems}
            error={this.props.error}
            onClose={() => this.setState({ tempDeletedItems: [] })}
            locationName="Group"
          />
        )}
        {!!tempArchivedItems.length && (
          <ActionMessage
            items={tempArchivedItems}
            onClose={() => this.setState({ tempArchivedItems: [] })}
            onUndo={this.actionMessageUndo}
            locationName="Group"
            isArchivedView={isArchivedView}
          />
        )}
      </>
    );
  }
}

LaborGroups.propTypes = {
  content: PropTypes.array.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  totalElements: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  isFetching: PropTypes.bool.isRequired,
  lastLoaded: PropTypes.bool,
  data: PropTypes.object,
  actions: PropTypes.object,
  error: PropTypes.object,
  route: PropTypes.object,
  location: PropTypes.object,
  Can: PropTypes.func,
  online: PropTypes.bool
};

const mapStateToProps = (state, props) => {
  const isArchivedView = props.location.pathname.includes("archived");

  const {
    laborGroups: {
      isFetching,
      data,
      data: { content, number, totalPages, totalElements, size, last },
      error
    },
    employee: {
      list: { content: employees }
    },
    offline: { online }
  } = state;

  return {
    isArchivedView,
    data,
    content,
    isFetching,
    currentPage: number,
    totalPages,
    totalElements,
    size,
    lastLoaded: last,
    Can: abilitiesSelector(state),
    error,
    online,
    employees
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        fetchAction: fetchLaborGroups,
        deleteAction: deleteLaborGroup,
        archiveAction: laborGroupStatusChange,
        fetchEmployeesList,
        deleteLaborGroups
      },
      dispatch
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LaborGroups);
