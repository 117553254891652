import React from "react";
import PropTypes from "prop-types";
import { Popup, Icon } from "semantic-ui-react";
import styles from "./TaskSeenIcon.module.css";

const TaskSeenIcon = ({ employees, position, className }) => {
  const seen = employees.every(employee => employee.seen);
  return (
    <Popup
      className={styles.popup}
      on="hover"
      trigger={
        <Icon
          className={`tuf-eye ${styles.icon} ${
            seen ? styles.green : styles.grey
          } ${className}`}
        />
      }
      position={position}
    >
      {employees.map(employee => {
        return employee.employee ? (
          <div key={`employee${employee.employee.id}`}>
            {employee.employee.firstName} {employee.employee.lastName} :{" "}
            {employee.seen ? "Seen" : "Unseen"}
          </div>
        ) : null;
      })}
    </Popup>
  );
};

TaskSeenIcon.propTypes = {
  employees: PropTypes.array.isRequired,
  position: PropTypes.string,
  className: PropTypes.any
};

TaskSeenIcon.defaultProps = {
  position: "top center",
  className: ""
};

export default TaskSeenIcon;
