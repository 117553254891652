import React, { useCallback } from 'react';
import { Dropdown, Grid } from 'semantic-ui-react';
import { Form, Input } from 'formsy-semantic-ui-react';
import PropTypes from 'prop-types';
import { COUNTRY_OPTIONS } from '../../../../js/common/countries';

const Address = ({ country, zip, state, city, street, houseNumber, changeValue, columnMiddle }) => {
  const updateInState = useCallback(fieldName => event => changeValue(fieldName, event.target.value), [changeValue]);
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column {...columnMiddle}>
          <Form.Field>
            <label htmlFor="country">Country</label>
            <Dropdown
              name="country"
              onChange={(_, option) => {
                changeValue('country', option.value);
              }}
              options={COUNTRY_OPTIONS}
              search
              selection
              selectOnBlur={false}
              value={country}
            />
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column {...columnMiddle}>
          <Form.Field>
            <label htmlFor="zip">Zip code</label>
            <Input name="zip" value={zip} onChange={updateInState('zip')} />
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column {...columnMiddle}>
          <Form.Field>
            <label htmlFor="state">State</label>
            <Input name="state" value={state} onChange={updateInState('state')} />
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column {...columnMiddle}>
          <Form.Field>
            <label htmlFor="city">City </label>
            <Input name="city" value={city} onChange={updateInState('city')} />
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column {...columnMiddle}>
          <Form.Field>
            <label htmlFor="street">Street</label>
            <Input name="street" value={street} onChange={updateInState('street')} />
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column {...columnMiddle}>
          <Form.Field>
            <label htmlFor="houseNumber">Number </label>
            <Input name="houseNumber" value={houseNumber} onChange={updateInState('houseNumber')} />
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

Address.propTypes = {
  changeValue: PropTypes.func.isRequired,
  country: PropTypes.string,
  zip: PropTypes.string,
  state: PropTypes.string,
  city: PropTypes.string,
  street: PropTypes.string,
  houseNumber: PropTypes.string,
  columnMiddle: PropTypes.object,
};

Address.defaultProps = {
  changeValue: () => {},
  country: '',
  zip: '',
  state: '',
  city: '',
  street: '',
  houseNumber: '',
  phone: '',
  columnMiddle: {},
};

export default Address;
