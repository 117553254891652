import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MultiSelectFilter from "./MultiSelectFilter/MultiSelectFilter";
import { getCropsList } from "../../../actions/Spray/tasks";
import PropTypes from "prop-types";

const CropMultiSelectFilter = ({
  actions: { fetchList },
  list,
  isFetching,
  ...extraProps
}) => {
  useEffect(() => {
    fetchList();
  }, [fetchList]);

  const options = useMemo(
    () =>
      list
        .sort(item => item.name)
        .map(item => ({
          key: item.id,
          text: item.name,
          value: item.id
        })),
    [list]
  );

  return (
    <MultiSelectFilter
      options={options}
      numbers={true}
      loading={isFetching}
      {...extraProps}
    />
  );
};

const mapStateToProps = ({
  spray: {
    crops: { isFetching, data }
  }
}) => {
  return {
    list: data.content,
    isFetching
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchList: getCropsList
    },
    dispatch
  )
});

CropMultiSelectFilter.propTypes = {
  isFetching: PropTypes.bool,
  list: PropTypes.array,
  actions: PropTypes.object
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CropMultiSelectFilter);
