import { BaseModel } from "./base.model";
import { WorkingTime } from "./working_time.model";
import { PAYMENT_TYPES } from "../constants/GeneralTasks";
import { sum } from "lodash";

export class GeneralTask extends BaseModel {
  id;
  name;
  numberOfEmployees;
  paymentMethod;
  priority;
  round;
  size;
  startAt;
  status;
  areas;
  assignedTo;
  attachments;
  comments;
  createdAt;
  description;
  endAt;
  equipments;
  generalTaskSubTypeId;
  generalTaskTypeId;
  harvestUnit;
  harvestUnitPickingType;
  variety;
  subTaskName;
  subType;
  timeBudgeting;
  timeBudgetingPerPlant;
  timeBudgetingPerRow;
  type;
  unitPrice;
  updatedAt;
  workingTimes;
  generalBarcodes;
  _workingTimesCount;

  constructor(data = {}) {
    super(data);

    this.variety = data.variety || null;
    this.harvestUnitPickingType = data.harvestUnitPickingType || null;
    this.equipments = data.equipments || [];

    this.workingTimes = data.workingTimes
      ? data.workingTimes.map(wt => new WorkingTime(wt))
      : [];

    this._workingTimesCount = this.workingTimes.length;
    this.generalBarcodes = data.generalBarcodes || [];
  }

  get totalAreaSize() {
    return this.areas.reduce((sum, area) => sum + area.area.size, 0);
  }

  get farm() {
    return this.areas[0] ? this.areas[0].area.farm : null;
  }

  get crop() {
    return this.variety ? this.variety.crop : null;
  }

  _countTotalAreasSize() {
    return sum(this.areas.map(t => t.area.size));
  }

  /**
   * @param {Number} taskAreaSize
   * @returns {number}
   * @private
   */
  _countHourlyRate(taskAreaSize) {
    const workingTimes = this.workingTimes || [];
    const totalSum = sum(workingTimes.map(wt => wt.payout));

    if (totalSum === 0 || !taskAreaSize) return 0;
    if (this.areas.length === 1) return totalSum;

    const totalAreaSize = this._countTotalAreasSize();
    const ratePerHa = totalSum / totalAreaSize;
    return taskAreaSize * ratePerHa;
  }

  /**
   * @returns {number}
   * @private
   */
  _countPieceRate() {
    const { unitPrice, generalBarcodes } = this;
    return sum(generalBarcodes.map(({ price }) => unitPrice || price || 0));
  }

  /**
   * @param {Number} areaID
   * @returns {number}
   * @private
   */
  countCost(areaID) {
    let taskAreaSize;

    if (areaID) {
      const area = this.areas.find(a => a.area.id === areaID);
      taskAreaSize = area ? area.area.size : 0;
    } else {
      taskAreaSize = this._countTotalAreasSize();
    }

    if (this.paymentMethod === PAYMENT_TYPES.HOURLY_RATE)
      return this._countHourlyRate(taskAreaSize);
    if (this.paymentMethod === PAYMENT_TYPES.PIECE_RATE)
      return this._countPieceRate();

    return 0;
  }

  /**
   * @param {Number} areaID
   * @returns {number}
   */
  countTimePerTask(areaID) {
    let taskAreaSize;

    if (areaID) {
      const area = this.areas.find(a => a.area.id === areaID);
      taskAreaSize = area ? area.area.size : 0;
    } else {
      taskAreaSize = this._countTotalAreasSize();
    }

    const workingTimes = this.workingTimes || [];
    const totalTime = sum(
      workingTimes.map(wt => wt.workingTimeInMinutesWithoutLunch).flat()
    );

    if (totalTime === 0 || !taskAreaSize) return 0;
    if (this.areas.length === 1) return totalTime;

    const totalAreaSize = this._countTotalAreasSize();
    const ratePerHa = totalTime / totalAreaSize;
    return taskAreaSize * ratePerHa;
  }
}
