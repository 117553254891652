import React, { useState, useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { Button, Icon, Message } from "semantic-ui-react";
import moment from "moment";

import { getTaskWeatherCondition } from "../../../../../actions/WeatherStation";
import { onlineSelector } from "../../../../../selectors/common";
import { toUnixInUTC } from "../../../../../utils/timezoneUtils";

const getFirstWorkingFromDateTime = workingTimes => {
  const workingFroms = workingTimes
    .filter(wt => wt.workingFrom !== wt.workingTo)
    .map(wt => `${wt.date} ${wt.workingFrom}`)
    .sort();
  return workingFroms.length > 0 && workingFroms[0];
};

const getLastWorkingToDateTime = workingTimes => {
  const workingTos = workingTimes
    .filter(wt => wt.workingFrom !== wt.workingTo)
    .map(wt => `${wt.date} ${wt.workingTo}`)
    .sort();
  return workingTos.length > 0 && workingTos[workingTos.length - 1];
};

const getWeatherStations = selectedAreas => {
  return [
    ...new Set(
      selectedAreas
        .map(area => area.farm?.weatherStation && area.farm?.weatherStation.id)
        .filter(a => a)
    )
  ];
};

const UpdateInfoFromWeatherStationButton = ({
  actions: { getTaskWeatherCondition },
  workingTimes,
  selectedAreas,
  isFetchingWeatherCondition,
  weatherDataDateTime,
  online
}) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [
    weatherDataFetchedFromClicked,
    setWeatherDataFetchedFromClicked
  ] = useState(false);
  const firstWorkingFromDateTime = useMemo(
    () => getFirstWorkingFromDateTime(workingTimes),
    [workingTimes]
  ); //getFirstWorkingFromDateTime(workingTimes); //
  const lastWorkingToDateTime = useMemo(
    () => getLastWorkingToDateTime(workingTimes),
    [workingTimes]
  );
  const weatherStations = useMemo(() => getWeatherStations(selectedAreas), [
    selectedAreas
  ]);

  if (weatherStations.length === 0) {
    return null;
  }

  const onClick = () => {
    if (weatherStations.length > 1) {
      setErrorMessage(
        "Cannot update information since the task related to more than one weather station"
      );
      return;
    }

    if (!firstWorkingFromDateTime) {
      setErrorMessage("You should set working times first");
      return;
    }
    getTaskWeatherCondition(
      weatherStations[0],
      toUnixInUTC(firstWorkingFromDateTime),
      toUnixInUTC(lastWorkingToDateTime)
    );
    setErrorMessage(null);
    setWeatherDataFetchedFromClicked(true);
  };

  return (
    <>
      <Button
        onClick={onClick}
        loading={isFetchingWeatherCondition}
        disabled={!online || isFetchingWeatherCondition}
        className={"color-green"}
      >
        Update information from weather station
      </Button>
      {(errorMessage ||
        (weatherDataFetchedFromClicked && !isFetchingWeatherCondition)) && (
        <Message
          color={errorMessage || !weatherDataDateTime ? "yellow" : "blue"}
        >
          <Icon name="warning" />
          {errorMessage ? (
            <span>{errorMessage}</span>
          ) : weatherDataDateTime ? (
            <span>
              The information was updated as of the date:{" "}
              {moment(weatherDataDateTime).format("DD/MM/YYYY HH:mm:ss")}
            </span>
          ) : (
            <span>No available information for this dates</span>
          )}
        </Message>
      )}
    </>
  );
};

UpdateInfoFromWeatherStationButton.propTypes = {
  actions: PropTypes.object,
  workingTimes: PropTypes.array,
  selectedAreas: PropTypes.array,
  weatherDataDateTime: PropTypes.string,
  online: PropTypes.bool,
  isFetchingWeatherCondition: PropTypes.bool
};

const mapStateToProps = state => {
  const {
    sprayDiary: {
      status,
      workingTimes,
      selectedAreas,
      isFetchingWeatherCondition,
      weatherDataDateTime
    }
  } = state;
  return {
    status,
    workingTimes,
    selectedAreas,
    isFetchingWeatherCondition,
    weatherDataDateTime,
    online: onlineSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ getTaskWeatherCondition }, dispatch)
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateInfoFromWeatherStationButton);
