import PropTypes from "prop-types";
import React from "react";
import {
  Container,
  Header,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow
} from "semantic-ui-react";
import Numeric from "../../../../components/Numeric";
import "../index.css";

const TotalCostsBreakdown = ({
  sprayTasksSum,
  generalTasksSum,
  farm,
  itemSize
}) => {
  return (
    <Container className="totalCost">
      {!farm && <Header as="h2">Total Task Cost Breakdown:</Header>}
      <Table
        style={{ width: "98%", fontSize: "12px" }}
        unstackable
        textAlign="left"
      >
        <TableHeader>
          <TableRow>
            <TableHeaderCell></TableHeaderCell>
            <TableHeaderCell>Application Task Costs ($)</TableHeaderCell>
            <TableHeaderCell>General Tasks Costs ($)</TableHeaderCell>
            <TableHeaderCell>Cost ($)/Ha</TableHeaderCell>
            <TableHeaderCell>Total Running Costs ($)</TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow>
            <TableCell>
              <b>Total</b>
            </TableCell>
            <TableCell>
              <b>
                ${" "}
                <Numeric
                  fractionDigits={2}
                  value={sprayTasksSum}
                  commaSeparatorOnThousands
                />
              </b>
            </TableCell>
            <TableCell>
              <b>
                ${" "}
                <Numeric
                  fractionDigits={2}
                  value={generalTasksSum}
                  commaSeparatorOnThousands
                />
              </b>
            </TableCell>
            <TableCell>
              <b>
                <Numeric
                  fractionDigits={2}
                  value={(sprayTasksSum + generalTasksSum) / itemSize}
                  commaSeparatorOnThousands
                  units="$ "
                  unitsPosition="left"
                />
                /Ha
              </b>
            </TableCell>
            <TableCell>
              <b>
                ${" "}
                <Numeric
                  fractionDigits={2}
                  value={sprayTasksSum + generalTasksSum}
                  commaSeparatorOnThousands
                />
              </b>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Container>
  );
};

TotalCostsBreakdown.propTypes = {
  generalTasksSum: PropTypes.number.isRequired,
  sprayTasksSum: PropTypes.number.isRequired
};

TotalCostsBreakdown.defaultProps = {
  generalTasksSum: 0,
  sprayTasksSum: 0
};

export default TotalCostsBreakdown;
