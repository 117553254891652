export const mergeAreasNumericValues = data => {
  const result = {};

  if (Array.isArray(data)) {
    data.forEach(area => {
      for (let [key, value] of Object.entries(area)) {
        if (result[key] && typeof value === "number") {
          result[key] += value ? value : 0;
        } else if (!result[key]) {
          result[key] = value;
        }
      }
    });
  }

  return result;
};
