import { actionType } from "constants/InventoryMovements";
import { axiosProxy } from "utils/axiosProxy";
import { omitBy, isNil } from "lodash";
import uuid from "uuid/v4";

let uniqId;

export const getOperations = (
  { page, search, size, unpaged = false, filters },
  checkedChanged
) => async dispatch => {
  try {
    dispatch({ type: actionType.OPERATIONS_START });
    const paramsObject = omitBy(
      {
        page,
        search,
        size,
        unpaged,
        sort: "id,desc",
        status: "PROCESSED"
      },
      isNil
    );
    const params = new URLSearchParams(paramsObject);
    if (Array.isArray(filters)) {
      filters.forEach(([filter, value]) => {
        const from = value.slice(0, 10);
        const to = value.slice(-10);
        return filter !== "seasons"
          ? params.append(filter, value)
          : (params.append("from", `${from}T00:00:00`),
            params.append("to", `${to}T23:59:59`));
      });
    }
    uniqId = uuid();
    const res = await axiosProxy({
      method: "GET",
      params,
      uniqId,
      url: `/sheds/operations`
    });
    if (uniqId === res.config.headers["X-REQUEST-ID"]) {
      await dispatch({
        type: actionType.OPERATIONS,
        payload: res.data
      });
    }
    return res.data;
  } catch (error) {
    console.log("errror");
  }
};
export const getOperationsForPrint = ({
  unpaged = true,
  sort = ["id,desc"],
  filters
}) => async dispatch => {
  try {
    dispatch({ type: actionType.OPERATIONS_FOR_PRINT_START });
    const paramsObject = omitBy(
      {
        sort,
        unpaged: true
      },
      isNil
    );
    const params = new URLSearchParams(paramsObject);
    if (Array.isArray(filters)) {
      filters.forEach(([filter, value]) => {
        const from = value.slice(0, 10);
        const to = value.slice(-10);
        return filter !== "seasons"
          ? params.append(filter, value)
          : (params.append("from", `${from}T00:00:00`),
            params.append("to", `${to}T23:59:59`));
      });
    }
    const res = await axiosProxy({
      method: "GET",
      params,
      url: `/sheds/operations`
    });
    await dispatch({
      type: actionType.OPERATIONS_FOR_PRINT,
      payload: res.data
    });
    return res.data;
  } catch (error) {
    console.log("errror");
  }
};
export const getOperationsFilters = ({
  page,
  search,
  size,
  unpaged = false,
  filters
}) => async dispatch => {
  try {
    dispatch({ type: actionType.OPERATIONS_FILTERS_START });
    const paramsObject = omitBy(
      {
        page,
        search,
        size,
        unpaged,
        sort: "id,desc"
      },
      isNil
    );
    const params = new URLSearchParams(paramsObject);
    if (Array.isArray(filters)) {
      filters.forEach(([filter, value]) => {
        const from = value.slice(0, 10);
        const to = value.slice(-10);
        return filter !== "seasons"
          ? params.append(filter, value)
          : (params.append("from", `${from}T00:00:00`),
            params.append("to", `${to}T23:59:59`));
      });
    }

    const res = await axiosProxy({
      method: "GET",
      params,
      url: `/sheds/operations/filters`
    });
    await dispatch({
      type: actionType.OPERATIONS_FILTERS,
      payload: res.data
    });
    return res.data;
  } catch (error) {
    console.log("errror");
  }
};

export const getOperationsType = () => async dispatch => {
  try {
    dispatch({ type: actionType.OPERATIONS_TYPES_START });

    const res = await axiosProxy({
      method: "GET",
      url: `/sheds/operation_types`
    });
    await dispatch({
      type: actionType.OPERATIONS_TYPES,
      payload: res.data
    });
    return res.data;
  } catch (error) {
    console.log("errror");
  }
};
