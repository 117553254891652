import React, { useEffect, useRef } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { Form, Input } from "semantic-ui-react";

const PatchFormHeader = ({
  currentBlock,
  type,
  changeValue,
  sizeRequired,
  errors,
  patchId
}) => {
  const nameInput = useRef();
  useEffect(() => {
    if (nameInput.current && !currentBlock._id) {
      nameInput.current.focus();
    }
  }, [currentBlock._id]);

  let size = currentBlock.size || "";
  const hasPatches = currentBlock.patches && currentBlock.patches.length > 0;

  if (hasPatches) {
    size = currentBlock.patches
      .filter(item => !item.archived)
      .reduce((a, b) => a + (parseFloat(b.size) || 0), 0)
      .toFixed(2);
  }

  return (
    <Form.Group>
      <Form.Field
        width={sizeRequired || hasPatches ? 10 : 16}
        required
        error={_.has(errors, "name")}
      >
        <label htmlFor={`name${patchId}`}>{type} name</label>
        <Input
          name={`name${patchId}`}
          id={`name${patchId}`}
          ref={nameInput}
          {...(!currentBlock._id ? { autoFocus: true } : {})}
          size="large"
          placeholder={`Give your ${type.toLowerCase()} a name`}
          value={currentBlock.name || ""}
          onChange={event =>
            changeValue(currentBlock, "name", event.target.value)
          }
        />
      </Form.Field>
      {(sizeRequired || hasPatches) && (
        <Form.Field
          width={6}
          required={sizeRequired}
          error={sizeRequired && _.has(errors, "size")}
        >
          <label htmlFor={`size${patchId}`}>Size</label>
          <Input
            name={`size${patchId}`}
            id={`size${patchId}`}
            size="large"
            placeholder=""
            value={size}
            onChange={event =>
              changeValue(currentBlock, "size", event.target.value)
            }
            label={{ content: "Ha", inverted: "true" }}
            labelPosition="right"
            disabled={!sizeRequired}
          />
        </Form.Field>
      )}
    </Form.Group>
  );
};

PatchFormHeader.propTypes = {
  currentBlock: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  changeValue: PropTypes.func.isRequired,
  showErrors: PropTypes.bool,
  errors: PropTypes.object,
  sizeRequired: PropTypes.bool,
  patchId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

PatchFormHeader.defaultProps = {
  patch: { name: "", size: "" },
  type: "Block",
  changeValue: () => {},
  patchId: "",
  sizeRequired: true
};

export default PatchFormHeader;
