export const taskTypesList = ["Spray", "Spread", "Fertigation"];

export const sortConfig = {
  defaultSort: "sprayTaskId",
  sortOptions: [
    {
      key: "sprayTaskId",
      text: `Sort by Task ID`,
      value: "sprayTaskId",
      content: "Task ID",
      sortfields: ["sprayTaskId"]
    },
    {
      key: "totalCost",
      text: `Sort by Total Cost`,
      value: "totalCost",
      content: "Total Cost",
      sortfields: ["totalCost"]
    },
    {
      key: "costHectare",
      text: `Sort by Cost/Ha`,
      value: "costHectare",
      content: "Cost/Ha",
      sortfields: ["costHectare"]
    }
  ]
};

export const defaultFiltersOptions = [
  {
    name: "Date",
    type: "DateRangeFilter",
    id: "date"
  },
  {
    name: "Task Type",
    type: "MultiSelectFilter",
    id: "taskType",
    options: [
      {
        id: "taskType",
        value: "Spray",
        text: "Spray"
      },
      {
        id: "taskType",
        value: "Spread",
        text: "Spread"
      },
      {
        id: "taskType",
        value: "Fertigation",
        text: "Fertigation"
      }
    ]
  }
];

export const printDocColumns = [
  {
    id: "sprayTaskId",
    accessor: "sprayTaskId",
    Header: "Task ID"
  },
  {
    id: "taskType",
    accessor: "taskType",
    Header: "Task Type"
  },
  {
    id: "chemicalCost",
    accesor: "chemicalCost",
    aggregate: true,
    Header: "Chemical Cost"
  },
  {
    id: "machineryCost",
    accesor: "machineryCost",
    aggregate: true,
    Header: "Machinery Cost"
  },
  {
    id: "labourCost",
    accesor: "labourCost",
    aggregate: true,
    Header: "Labour Cost"
  },
  {
    id: "costHectare",
    accesor: "costHectare",
    aggregate: true,
    Header: "Cost ($)/Ha"
  },
  {
    id: "totalCost",
    accesor: "totalCost",
    aggregate: true,
    Header: "Total Cost($)"
  }
];
