import React from "react";
import { useSelector } from "react-redux";
import styles from "./ContractorRate.module.css";
import { Form, Icon, Segment } from "semantic-ui-react";
import CollapsibleBlock from "../../../../../components/CollapsibleBlock";
import { getEmployeeList } from "../../../../../actions/Contractors/contractors";
import { Field, useFormikContext } from "formik";
import MultiSelect from "../../../../../components/MultiSelect/MultiSelect";
import { employeeOptionsSelector } from "../../../../../selectors/employee";

const ContractorWorkers = () => {
  const { options } = useSelector(employeeOptionsSelector);
  const { values, setFieldValue } = useFormikContext();

  return (
    <Segment id={"worker_section_element"}>
      <CollapsibleBlock title="Workers" className={styles.root}>
        <Form>
          <Form.Group>
            <Form.Field width={16}>
              <label>Select workers</label>
              <Field
                as={MultiSelect}
                search={true}
                containerElementId={"#contractors_modal_scroll_container"}
                width={16}
                text={
                  values.employeeIds.length
                    ? `${values.employeeIds.length} workers`
                    : ""
                }
                selector={employeeOptionsSelector}
                value={values.employeeIds}
                name="employeeIds"
                loaderAction={getEmployeeList}
                onOptionChange={setFieldValue}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <div className={styles.workerElementsContainer}>
              {values.employeeIds.map(employee => (
                <div key={employee} className={styles.workerElement}>
                  <span>
                    {options.length &&
                      options.find(option => option.value === employee).label}
                  </span>
                  <Icon
                    name="close"
                    onClick={() => {
                      setFieldValue(
                        "employeeIds",
                        values.employeeIds.filter(id => id != employee)
                      );
                    }}
                  />
                </div>
              ))}
            </div>
          </Form.Group>
        </Form>
      </CollapsibleBlock>
    </Segment>
  );
};

export default ContractorWorkers;
