import React, { useEffect, useMemo } from "react";
import MultiSelectFilter from "./MultiSelectFilter";
import { bindActionCreators } from "redux";
import { fetchScoutingFilterData } from "actions/Scouting/scouting";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { get } from "lodash";

const ScoutingElementFilter = ({
  actions: { fetchList },
  filterData,
  isFetching,
  ...extraProps
}) => {
  useEffect(() => {
    if (!filterData && !isFetching) {
      fetchList();
    }
  }, [fetchList]);

  const options = useMemo(
    () => get(filterData, `${get(extraProps, "id")}`, []),
    [filterData]
  );

  return (
    <MultiSelectFilter
      options={options}
      numbers={true}
      loading={isFetching}
      {...extraProps}
    />
  );
};

const mapStateToProps = ({
  scouting: { filterData, isFilterDataFetching }
}) => {
  return {
    filterData,
    isFetching: isFilterDataFetching
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchList: fetchScoutingFilterData }, dispatch)
});

ScoutingElementFilter.propTypes = {
  isFetching: PropTypes.bool,
  filterData: PropTypes.object,
  actions: PropTypes.object
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScoutingElementFilter);
