import React, { useState } from "react";
import { valueToString } from "../../../../../utils/tasksUtils";
import styles from "./SelectChemicalsForm.module.css";
import SelectChemicalDropdown from "./SelectChemicalDropdown";
import SelectDilusionRate from "./SelectDilusionRate";
import { Button, Dropdown, Icon, Input, Message } from "semantic-ui-react";
import { formatInputFloat } from "../../../../../utils/validationRules";
import { matterToUnits } from "../../../../../utils/constToUnits";
import rateTypes from "./rateTypes";
import Numeric from "../../../../../components/Numeric";
import DatePicker from "../../../../../components/DatePicker";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setSprayDiaryField } from "../../../../../actions/SprayDiary/common";
import BatchNumberDropDown from "./batchNumberSelector";
import {
  addChemicalItem,
  setAddChemicalSelection,
  declineEditingChemicalItem,
  setChemicalItemToAdd
} from "../../../../../actions/SprayDiary/chemicals";
import PropTypes from "prop-types";

const AddTableItem = ({
  item,
  selectedChemicals,
  index,
  selectedMachinery,
  onSelectChemical,
  unitFormater,
  batchNumbers,
  multiplier,
  addChemicalSelection,
  addButtonAttentionAnimationActivation,
  totalTanks,
  validation,
  calculateChemicalTotal,
  actions: {
    addChemicalItem,
    setChemicalItemToAdd,
    declineEditingChemicalItem,
    setAddButtonAttentionAnimationActivation,
    setAddChemicalSelection
  }
}) => {
  const gotRate =
    item.rate.rate >= 0 ||
    (item.rate.minRate >= 0 && !(item.rate.maxRate >= 0)) ||
    (item.rate.minRate >= 0 &&
      item.rate.maxRate >= 0 &&
      item.rate.minRate === item.rate.maxRate);
  const disabledButton =
    !item.name || !gotRate || !valueToString(item.rate.rate);
  const id = item.new ? "new" : item.id;
  const first = selectedChemicals.length === 0;
  const chemicalTotal = calculateChemicalTotal(item);
  return (
    (addChemicalSelection || first || (item.toAdd && !item.new)) && (
      <>
        <tr className={item.new ? styles.newItem : styles.editItem}>
          <td className={styles.addTitle}>Add Chemical to the Task</td>
          <td className={styles.chemicalCell}>
            <div className={styles.title}>Name</div>
            <div>
              <SelectChemicalDropdown
                id={id}
                value={item.id}
                onChange={onSelectChemical}
              />
            </div>
          </td>
          <td className={styles.chemicalTypeCell}>
            <div className={styles.title}>Type</div>
            <div>{item.type ? item.type.type : "—"}</div>
          </td>
          <td className={styles.cropAndPurposeCell}>
            <div className={styles.title}>Target</div>
            <div>
              <SelectDilusionRate
                error={!validation && !item.rate.id}
                item={item}
                setItem={setChemicalItemToAdd}
              />
            </div>
          </td>
          <td className={styles.dilutionCell}>
            <div className={styles.title}>Rate</div>
            <div>
              <Input
                fluid
                disabled={!(item.rate && item.rate.id)}
                onChange={e => {
                  return setChemicalItemToAdd(id, {
                    ...item,
                    rate: {
                      ...item.rate,
                      inputRate: e.target.value,
                      rate: e.target.value / multiplier(item.rate.unit)
                    }
                  });
                }}
                value={
                  item.rate.inputRate ||
                  item.rate.rate * multiplier(item.rate.unit) ||
                  ""
                }
                label={{
                  content: !!Object.keys(item.rate).length
                    ? unitFormater(item.rate.unit)
                    : "",
                  inverted: "true"
                }}
                labelPosition="right"
                className={styles.inputRate}
                error={!validation && !valueToString(item.rate.rate)}
              />
            </div>
          </td>
          <td>
            <div className={styles.title}>Rate Type</div>
            {rateTypes[item.rate.rateType] || "—"}
          </td>
          <td>
            <div className={styles.title}>Total Qty</div>
            <Numeric
              value={
                !!Object.keys(item.rate).length
                  ? chemicalTotal.quantity * multiplier(item.rate.unit)
                  : "—"
              }
              fractionDigits={3}
              units={unitFormater(item.rate.unit)}
            />
          </td>
          <td>
            <div className={styles.title}>
              {selectedMachinery ? "Qty Per Full Tank" : "Full Qty"}
            </div>
            <Numeric
              value={
                !!Object.keys(item.rate).length
                  ? totalTanks < 1
                    ? 0
                    : item.fullTanks * multiplier(item.rate.unit)
                  : "—"
              }
              fractionDigits={totalTanks < 1 ? 0 : 3}
              units={unitFormater(item.rate.unit)}
            />
          </td>
          {selectedMachinery && (
            <td>
              <div className={styles.title}>Qty Per Part Tank</div>
              <Numeric
                value={
                  !!Object.keys(item.rate).length
                    ? item.partTanks * multiplier(item.rate.unit)
                    : "—"
                }
                units={unitFormater(item.rate.unit)}
              />
            </td>
          )}
          <td className={styles.batchNumberCell}>
            <div className={styles.title}>Batch No.</div>
            <div>
              <BatchNumberDropDown
                selectedChemicals={selectedChemicals}
                index={index}
                setChemicalItemToAdd={setChemicalItemToAdd}
                value={item.batchNumbers}
                batchNumbers={batchNumbers}
                item={item}
                id={id}
              />
            </div>
          </td>
          <td className={styles.dateOfManufactureCell}>
            <div className={styles.title}>Date of Manufacture</div>
            <DatePicker
              item={item}
              onChange={date =>
                setChemicalItemToAdd(id, {
                  ...item,
                  dateOfManufacture: date
                })
              }
              size="small"
            />
          </td>
          <td>
            {item.new ? (
              <div className={styles.buttonsNew}>
                <Button
                  disabled={disabledButton}
                  className={
                    !disabledButton && addButtonAttentionAnimationActivation
                      ? styles.addChemicalValidation
                      : null
                  }
                  onAnimationEnd={() => {
                    setAddButtonAttentionAnimationActivation(false);
                  }}
                  onClick={() => {
                    addChemicalItem(id);
                    setAddChemicalSelection(false);
                  }}
                  color={disabledButton ? "grey" : "green"}
                >
                  + Add
                </Button>
                <Button
                  onClick={() => (
                    setAddChemicalSelection(false),
                    declineEditingChemicalItem(id)
                  )}
                >
                  Cancel
                </Button>
              </div>
            ) : (
              <div className={styles.buttonsNew}>
                <Button
                  onClick={() => (disabledButton ? null : addChemicalItem(id))}
                  disabled={disabledButton}
                  color={disabledButton ? "grey" : "green"}
                >
                  Update
                </Button>
                <Button
                  onClick={() => declineEditingChemicalItem(id)}
                  className={styles.buttonCancel}
                >
                  Cancel
                </Button>
              </div>
            )}
          </td>
        </tr>
        {item && item.id && (
          <tr className={styles.message}>
            <td colSpan={9}>
              <Message color="blue">
                <Icon name="warning" /> The chemicals you have selected have{" "}
                <strong>{item.reentry} day(s)</strong> of Re-Entry{" "}
                {item.rate && item.rate.withholdingPeriod >= 0 && (
                  <span>
                    and {<strong>{item.rate.withholdingPeriod} day(s)</strong>}{" "}
                    of Withholding
                  </span>
                )}
                Period
              </Message>
            </td>
          </tr>
        )}
      </>
    )
  );
};

AddTableItem.propTypes = {
  selectedMachinery: PropTypes.number,
  addChemicalSelection: PropTypes.bool,
  selectedChemicals: PropTypes.array,
  validation: PropTypes.bool,
  addButtonAttentionAnimationActivation: PropTypes.bool,
  totalTanks: PropTypes.number,
  actions: PropTypes.object,
  item: PropTypes.object,
  onSelectChemical: PropTypes.func
};

const mapStateToProps = ({
  sprayDiary: {
    selectedMachinery: { id },
    addChemicalSelection,
    selectedChemicals,
    validations: {
      selectedChemicals: selectedChemicalsValidation,
      addChemicalSelection: addChemicalSelectionValidation
    },
    addButtonAttentionAnimationActivation,
    totalTanks
  },
  spray
}) => ({
  selectedMachinery: id,
  batchNumbers: spray.batchNumbers,
  addChemicalSelection,
  selectedChemicals,
  validation: selectedChemicalsValidation && addChemicalSelectionValidation,
  addButtonAttentionAnimationActivation,
  totalTanks
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      addChemicalItem,
      setChemicalItemToAdd,
      declineEditingChemicalItem,
      setAddChemicalSelection,
      setAddButtonAttentionAnimationActivation: active =>
        setSprayDiaryField({
          fieldName: "addButtonAttentionAnimationActivation",
          fieldValue: active
        })
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(AddTableItem);
