import { BaseModel } from "./base.model";
import {
  countMinutesBetweenDates,
  countTimeInHMBetweenDates,
  minutesToHM
} from "../utils/timeUtils";
import * as Yup from "yup";
import moment from "moment";

export class WorkingTime extends BaseModel {
  contractor;
  contractorId;
  employee;
  employeeId;
  employeesGroup;
  employeesGroupId;
  generalTaskId;
  id;
  lunchInterval;
  endAt;
  startAt;
  workingTimeInHM;
  workingTimeInMinutes;
  workingTimeInMinutesWithoutLunch;
  workingTimeInHMWithoutLunch;
  payout;

  constructor(data = {}) {
    super(data);

    this.lunchInterval = data.lunchInterval || 0;
  }

  get workingTimeInHM() {
    return countTimeInHMBetweenDates(this.startAt, this.endAt);
  }

  get workingTimeInMinutes() {
    return countMinutesBetweenDates(this.startAt, this.endAt);
  }

  get workingTimeInMinutesWithoutLunch() {
    const temp = this.workingTimeInMinutes - this.lunchInterval;
    return temp < 0 ? 0 : temp;
  }

  get workingTimeInHMWithoutLunch() {
    return minutesToHM(this.workingTimeInMinutesWithoutLunch);
  }

  get payout() {
    const ratePerHour = this.employeesRateHistory
      ? this.employeesRateHistory.rate
      : this.employee.ratePerHour;
    return this.workingTimeInMinutesWithoutLunch * (ratePerHour / 60);
  }
}

WorkingTime.validationSchema = Yup.object().shape({
  employeeId: Yup.number().required("Required"),
  employeesGroupId: Yup.number().nullable(),
  contractorId: Yup.number().nullable(),
  generalTaskId: Yup.number().required("Required"),
  areas: Yup.array()
    .min(1)
    .required("Required"),
  startAt: Yup.date().required("Required"),
  endAt: Yup.date()
    .when("startAt", (startAt, schema) => {
      return (
        startAt &&
        schema
          .min(startAt, "Should be later than Clocked In time")
          .max(
            moment(startAt).add(720, "minutes"),
            "Should be earlier than 12 hours after Clocked In time"
          )
      );
    })
    .required("Required"),
  lunchInterval: Yup.number()
    .min(0)
    .required("Required")
});
