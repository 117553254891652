import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Accordion, Grid, Header, Segment } from "semantic-ui-react";
import { scoutingAreaShape } from "constants/Scouting/types";
import { get } from "lodash";
import styles from "../ScoutingSidebar.module.css";
import { getIssuesReportedTitleText } from "utils/scouting";
import ScoutingSidebarIssuesAccordion from "./ScoutingSidebarIssuesAccordion";

const ScoutingSidebarAreaElement = ({
  scoutingArea,
  onIssueAttachmentClick
}) => {
  const [isActive, setIsOpen] = useState(false);
  const { area, comment, areaIssues } = scoutingArea;
  const issuesReported = get(areaIssues, "length", 0);
  const reportedIssuesTitleText = getIssuesReportedTitleText(issuesReported);
  const reportedIssuesTitleColor = issuesReported
    ? "#B00020"
    : "var(--color-green)";
  const selectIssueAttachment = (issue, attachment) => {
    onIssueAttachmentClick(area, issue, attachment);
  };

  const areaTitle = useMemo(() => {
    return area.parent ? `${area.parent.name} - ${area.name}` : area.name;
  }, [area]);

  return (
    <Grid.Row className={styles.taskField}>
      <Accordion as={Segment} fluid>
        <Accordion.Title
          active={isActive}
          content={areaTitle}
          as={Header}
          className={styles.areaTitle}
          onClick={() => setIsOpen(active => !active)}
        />
        <Header
          as="h4"
          className={styles.reportedTitle}
          style={{ color: reportedIssuesTitleColor }}
        >
          {reportedIssuesTitleText}
        </Header>
        <Accordion.Content
          active={isActive}
          content={
            <>
              {comment && (
                <>
                  <Header
                    as="p"
                    size="small"
                    color="grey"
                    className={styles.generalCommentsHeader}
                  >
                    General comments
                  </Header>
                  <Header as="p" size="small">
                    {comment}
                  </Header>
                </>
              )}
              {Array.isArray(areaIssues) && (
                <ScoutingSidebarIssuesAccordion
                  areaIssues={areaIssues}
                  onSelectIssue={selectIssueAttachment}
                />
              )}
            </>
          }
        />
      </Accordion>
    </Grid.Row>
  );
};

ScoutingSidebarAreaElement.propTypes = {
  scoutingArea: scoutingAreaShape,
  onIssueAttachmentClick: PropTypes.func
};

export default ScoutingSidebarAreaElement;
