import React from "react";
import { InfoWindow } from "@react-google-maps/api";
import _ from "lodash";
import Loader from "../Loader";
import styles from "./GoogleMaps.module.css";

const HarvestDetailPopup = ({ tooltipData, block, isFetching }) => (
  <InfoWindow position={(block.path && block.path[0]) || block.position}>
    {isFetching ? (
      <Loader />
    ) : (
      <div className={styles.binsWeightHolder}>
        <div className={styles.headerLabel}>
          {`${block.name} - Harvest information`}
        </div>
        <div className={styles.rowWrapper}>
          <span className={styles.label}>No.Bins:</span>
          <span className={styles.value}>
            {tooltipData && tooltipData.count}
          </span>
        </div>
        <div className={styles.rowWrapper}>
          <span className={styles.label}>Weight(T):</span>
          <span className={styles.value}>
            {tooltipData && tooltipData.weight ? `${tooltipData.weight}T` : "0"}
          </span>
        </div>
        <div className={styles.rowWrapper}>
          <span className={styles.label}>Tonnes/Ha (T/Ha):</span>
          <span className={styles.value}>
            {tooltipData && tooltipData.weight && tooltipData.size
              ? `T${_.round(tooltipData.weight / tooltipData.size, 2)}/Ha`
              : "0"}
          </span>
        </div>
      </div>
    )}
  </InfoWindow>
);

export default HarvestDetailPopup;
