import React from 'react';
import PropTypes from 'prop-types';
import { Label, Table } from 'semantic-ui-react';
import moment from 'moment';

import styles from '../Dashboard.module.css';
import { Area } from '../../../models/block.model';

const FarmItem = ({ block, count }) => {
  const blockModel = new Area(block);
  const areaVariety = blockModel.areaVarietyByDate(moment());
  return (
    <Table.Row>
      <Table.Cell>
        <strong>{block.name}</strong>
      </Table.Cell>
      <Table.Cell>{block.size || '—'} Ha</Table.Cell>
      <Table.Cell>{areaVariety.plantingAt ? moment(areaVariety.plantingAt).format('DD/MM/YYYY') : '—'}</Table.Cell>
      <Table.Cell>
        {areaVariety.crop ? areaVariety.crop.name : <span className={styles.vacant}>Vacant</span>}
      </Table.Cell>
      <Table.Cell>{areaVariety.name ? areaVariety.variety.name : '—'}</Table.Cell>
      <Table.Cell>{areaVariety.numberOfPlants || '—'}</Table.Cell>
      <Table.Cell textAlign="center">
        <Label circular className={styles.colorOPEN}>
          {count.OPEN || 0}
        </Label>
      </Table.Cell>
      <Table.Cell textAlign="center">
        <Label circular className={styles.colorIN_PROGRESS}>
          {count.IN_PROGRESS || 0}
        </Label>
      </Table.Cell>
      <Table.Cell textAlign="center">
        <Label circular className={styles.colorCOMPLETED}>
          {count.COMPLETED || 0}
        </Label>
      </Table.Cell>
    </Table.Row>
  );
};

FarmItem.propTypes = {
  block: PropTypes.object,
  count: PropTypes.object,
};

export default FarmItem;
