import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Form, Input } from "semantic-ui-react";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { get } from "lodash";
import { matterToUnits } from "utils/constToUnits";
import SelectProductInput from "./SelectProductInput";
import styles from "./Inventory.module.css";

const REQUIRED_FIELDS = ["chemicalId", "quantity", "price", "totalPrice"];

const InventoryForm = ({
  inventoryData,
  shed,
  onSubmit,
  inventoryListCheck,
  deleteButtonCheck,
  deleteAddingForm
}) => {
  const [resetRequired, setResetRequired] = useState(false);

  useEffect(() => {
    if (resetRequired) {
      setResetRequired(false);
    }
  }, [resetRequired]);

  if (resetRequired) {
    return null;
  }
  const validationSchema = () => {
    return Yup.object().shape({
      quantity: Yup.number().required("quantity is required"),
      price: Yup.number().required("price is required"),
      totalPrice: Yup.number()
    });
  };
  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={values => {
        onSubmit(values);
        setResetRequired(true);
      }}
      initialValues={inventoryData || {}}
    >
      {({ values, dirty, isValid, setValues, handleSubmit }) => {
        const operationType =
          shed &&
          !shed.buckets.map(item => item.chemicalId).includes(values.chemicalId)
            ? "STOCK_ADDITION"
            : "STOCK_CORRECTION";
        return (
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Field width={7} required>
                <label>Product</label>
                <Field
                  placeholder="Select a product"
                  as={SelectProductInput}
                  name="product"
                  required
                  value={get(values, "chemicalId")}
                  onChange={({ key, text, matter }) => {
                    setValues({
                      ...values,
                      chemicalId: key,
                      name: text,
                      matter,
                      date: new Date().toISOString()
                    });
                  }}
                />
              </Form.Field>
              <Form.Field width={2} />
              <Form.Field width={7} required>
                <label>Quantity</label>
                <Field
                  as={Input}
                  name="quantity"
                  onKeyPress={event => {
                    if (!/^[\.0-9]*$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(e, { value }) => {
                    setValues({
                      ...values,
                      quantity: value,
                      operationType,
                      totalPrice:
                        values.price * value ? values.price * value : ""
                    });
                  }}
                  label={`${matterToUnits(values.matter) || "unit"}`}
                  labelPosition="right"
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field width={7} required>
                <label>
                  {" "}
                  Price $/{matterToUnits(get(values, "matter")) || "unit"}{" "}
                </label>
                <Field
                  as={Input}
                  name="price"
                  onKeyPress={event => {
                    if (!/^[\.0-9]*$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(e, { value }) => {
                    setValues({
                      ...values,
                      price: value,
                      totalPrice:
                        values.quantity * value ? values.quantity * value : ""
                    });
                  }}
                  label={`$/${matterToUnits(values.matter) || "unit"}`}
                  labelPosition="right"
                />
              </Form.Field>
              <Form.Field width={2} />
              <Form.Field width={7}>
                <label>Total Price ($) </label>
                <Field
                  as={Input}
                  name="totalPrice"
                  label="$"
                  onKeyPress={event => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(e, { value }) => {
                    setValues({
                      ...values,
                      totalPrice: value,
                      quantity: values.price * value ? value / values.price : ""
                    });
                  }}
                  labelPosition="right"
                />
              </Form.Field>
            </Form.Group>
            <div className={styles.buttonContainer}>
              {deleteButtonCheck && inventoryListCheck ? (
                <Button
                  type="submit"
                  className={styles.formCancelBtn}
                  onClick={deleteAddingForm}
                >
                  Cancel
                </Button>
              ) : null}
              <Button
                type="submit"
                className={
                  !dirty || !isValid
                    ? styles.formDisabledBtn
                    : "button-text color-green"
                }
                disabled={!dirty || !isValid}
              >
                Save
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

InventoryForm.propTypes = {
  inventoryData: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func
};

export default InventoryForm;
