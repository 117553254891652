import React from "react";
import { Button, Table, Icon, Label, Popup } from "semantic-ui-react";
import PropTypes from "prop-types";
import { Form, Input, TextArea, Select } from "formsy-semantic-ui-react";
import _ from "lodash";
import styles from "../AddNewChemical.module.css";
import { validationErrors } from "../../../../utils/validationRules";
import { MATTER_TYPE_LIQUID, PER_HECTARE } from "../../../../constants/Farms";
import TableRow from "./TableRow";
import TableRowEdit from "./TableRowEdit";

const ErrorLabel = <Label color="red" pointing />;

const CropTable = ({
  formData,
  duplicatedCropAndPurpose,
  cropsOptions,
  cropsFetching,
  addCropRow,
  clearCropRow,
  removeCropRow,
  changeValue,
  modal,
  editCropRow,
  updateCropRow,
  updateValue,
  validRow,
  setAddCropUpdateValidation,
  setAddNewCropUpdateValidation,
  allFieldsEmpty
}) => {
  const { rateType, matter } = formData;
  const rateTitle = `Rate (${matter === MATTER_TYPE_LIQUID ? "L" : "Kg"}\\${
    rateType === PER_HECTARE ? "Ha" : "100L"
  })`;

  return (
    <div className={`${styles.cropTableHolder}`}>
      <Table
        unstackable
        compact
        basic="very"
        className={`${styles.tableCrop} ${modal ? styles.modalMode : ""}`}
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              Crop
              <span className={styles.signRequired} />
            </Table.HeaderCell>
            <Table.HeaderCell width={3}>Purpose</Table.HeaderCell>
            <Table.HeaderCell colSpan={2} width={2}>
              {rateTitle}
              <span className={styles.signRequired} />
            </Table.HeaderCell>
            <Table.HeaderCell width={1}>
              <Popup
                content="Withholding Period"
                trigger={
                  <span>
                    WHP <span className={styles.signRequired} />
                  </span>
                }
              />
            </Table.HeaderCell>
            <Table.HeaderCell colSpan={2}>Comments</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {formData.crops.map(
            (crop, index) =>
              !crop.deleted &&
              (!crop.edit ? (
                <TableRow
                  crop={crop}
                  index={index}
                  rateTitle={rateTitle}
                  removeCropRow={removeCropRow}
                  editCropRow={editCropRow}
                />
              ) : (
                <TableRowEdit
                  index={index}
                  cropsOptions={cropsOptions}
                  updateValue={updateValue}
                  duplicatedCropAndPurpose={duplicatedCropAndPurpose}
                  crop={crop}
                  rateTitle={rateTitle}
                  validRow={validRow}
                  updateCropRow={updateCropRow}
                  editCropRow={editCropRow}
                  setAddCropUpdateValidation={setAddCropUpdateValidation}
                  allFieldsEmpty={allFieldsEmpty}
                />
              ))
          )}
          {(cropsOptions.length > 0 || cropsFetching) && (
            <Table.Row key={formData.crop._id}>
              <Table.Cell width={3}>
                <div className={styles.title}>Crop</div>
                <Form.Field className={styles.inputRate}>
                  <Select
                    name="crop.id"
                    className={`small ${styles.selectCrop}`}
                    value={formData.crop.value}
                    fluid
                    options={cropsOptions}
                    search
                    placeholder="Select Crop"
                    clearable
                    validations={!allFieldsEmpty ? "isRequired" : null}
                    validationErrors={validationErrors()}
                    errorLabel={ErrorLabel}
                    onChange={(event, data) =>
                      changeValue(
                        "crop.id",
                        data.options.find(option => option.value === data.value)
                      )
                    }
                  />
                </Form.Field>
              </Table.Cell>
              <Table.Cell>
                <div className={styles.title}>Purpose</div>
                <Form.Field className={styles.inputRate}>
                  <Input
                    size="small"
                    fluid
                    name="crop.purpose"
                    validations={
                      !allFieldsEmpty
                        ? { isExtraParameterTrue: duplicatedCropAndPurpose }
                        : null
                    }
                    value={formData.crop.purpose}
                    validationErrors={validationErrors({
                      isExtraParameterTrue: `${
                        formData.crop.id
                          ? formData.crop.id.text +
                            " with " +
                            (formData.crop.purpose
                              ? "purpose " + formData.crop.purpose
                              : "no purpose") +
                            " already exists"
                          : null
                      }`
                    })}
                    errorLabel={ErrorLabel}
                    onChange={event =>
                      changeValue("crop.purpose", event.target.value)
                    }
                  />
                </Form.Field>
              </Table.Cell>
              <Table.Cell className={styles.dashField} width={1}>
                <div className={styles.title}>
                  {rateTitle} <span className={styles.signRequired} />
                </div>
                <Form.Field className={styles.inputRate}>
                  <Input
                    size="small"
                    name="crop.minRate"
                    fluid
                    value={formData.crop.minRate}
                    validations={
                      _.filter(formData.crops, function(o) {
                        return !o.deleted;
                      }).length === 0 && formData.crop.id
                        ? "isPositive"
                        : !allFieldsEmpty || formData.crop.id
                        ? "isPositive"
                        : null
                    }
                    validationErrors={validationErrors()}
                    errorLabel={ErrorLabel}
                    placeholder="min"
                    onChange={event =>
                      changeValue("crop.minRate", event.target.value)
                    }
                    className={styles.minWidth}
                  />
                </Form.Field>
              </Table.Cell>
              <Table.Cell className={styles.dashFieldAfter} width={1}>
                <div className={styles.title} />
                <Form.Field className={styles.inputRate}>
                  <Input
                    size="small"
                    name="crop.maxRate"
                    fluid
                    value={formData.crop.maxRate}
                    validations={
                      formData.crop.maxRate !== "" &&
                      formData.crop.minRate > formData.crop.maxRate
                        ? "isMinBiggerThanMax"
                        : !allFieldsEmpty || formData.crop.id
                        ? "isNotNegativeFloat"
                        : null
                    }
                    validationErrors={validationErrors()}
                    errorLabel={ErrorLabel}
                    placeholder="max"
                    onChange={event =>
                      changeValue("crop.maxRate", event.target.value)
                    }
                    className={styles.minWidth}
                  />
                </Form.Field>
              </Table.Cell>
              <Table.Cell>
                <div className={styles.title}>
                  <Popup
                    content="Withholding Period"
                    trigger={<span>WHP</span>}
                  />{" "}
                  <span className={styles.signRequired} />
                </div>
                <Form.Field className={styles.inputRate}>
                  <Input
                    size="small"
                    name="crop.withholdingPeriod"
                    fluid
                    value={formData.crop.withholdingPeriod}
                    validations={
                      (_.filter(formData.crops, function(o) {
                        return !o.deleted;
                      }).length === 0 &&
                        formData.crop.id) ||
                      !allFieldsEmpty ||
                      formData.crop.id
                        ? { isNotNegativeInteger: true, isRequired: true }
                        : null
                    }
                    validationErrors={validationErrors()}
                    errorLabel={ErrorLabel}
                    onChange={event =>
                      changeValue("crop.withholdingPeriod", event.target.value)
                    }
                    className={styles.minWidth}
                  />
                </Form.Field>
              </Table.Cell>
              <Table.Cell>
                <div className={styles.title}>Comments</div>
                <Form.Field className={styles.inputRate}>
                  <TextArea
                    className="small"
                    rows={1}
                    name="crop.comment"
                    value={formData.crop.comment}
                    onChange={event =>
                      changeValue("crop.comment", event.target.value)
                    }
                  />
                </Form.Field>
              </Table.Cell>
              <Table.Cell>
                <div className={styles.newButtonsWrapper}>
                  <Button
                    size="small"
                    positive={true}
                    onClick={addCropRow}
                    type="button"
                    className={[
                      styles.addNewChemicalButtonWrapper,
                      formData.crop.notValidAnimation
                        ? [styles.editCropValidation, styles.buttonAdd]
                        : styles.buttonAdd
                    ]}
                    onAnimationEnd={() => {
                      setAddNewCropUpdateValidation();
                    }}
                  >
                    <Icon name="plus" />
                    Add
                  </Button>
                  <Button
                    size="small"
                    className={[
                      styles.buttonAdd,
                      styles.addNewChemicalButtonWrapper
                    ]}
                    onClick={clearCropRow}
                    type="button"
                  >
                    Clear
                  </Button>
                </div>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </div>
  );
};

CropTable.propTypes = {
  formData: PropTypes.object,
  duplicatedCropAndPurpose: PropTypes.bool,
  cropsOptions: PropTypes.array,
  cropsFetching: PropTypes.bool,
  modal: PropTypes.bool,
  allFieldsEmpty: PropTypes.bool,
  addCropRow: PropTypes.func,
  clearCropRow: PropTypes.func,
  removeCropRow: PropTypes.func,
  updateCropRow: PropTypes.func,
  editCropRow: PropTypes.func,
  validRow: PropTypes.func,
  updateValue: PropTypes.func,
  setAddCropUpdateValidation: PropTypes.func,
  changeValue: PropTypes.func,
  setAddNewCropUpdateValidation: PropTypes.func
};

export default CropTable;
