import React from "react";
import { Popup } from "semantic-ui-react";
import filterTypes from "components/Table/filterTypes";
import BasicCell from "components/Table/components/BasicCell";
import { get, join, map } from "lodash";
// import { fetchFilterValues, fetchActiveIngredients } from "../actions/Sheds";
import { uppercaseFirstLetter } from "./string";
import { matterToUnits } from "./constToUnits";
import {
  activeIngredientsSelector,
  chemicalTypeOptionsSelector,
  matterOptionsSelector,
  trackInventoryOptionsSelector
} from "selectors/chemicals";
import {
  fetchActiveIngredients,
  fetchFilterValues
} from "../actions/Chemicals";
import { farmsOptionsFilterSelector } from "selectors/farms";

export const getShedsTableColumns = () => {
  const columnsConfig = {
    name: {
      withSort: true,
      type: "string",
      id: "name",
      title: "Name",
      cellWidth: 4 // of 16, see Semantic Table
    },
    farms: {
      withSort: true,
      id: "farms",
      type: "string",
      title: "Farms",
      accessor: ({ farms }) => farms.map(farm => get(farm.farm, "name")),
      Cell: ({ value }) =>
        value.length ? (
          <Popup
            position="right center"
            trigger={
              <span>
                {value.length} {value.length > 1 ? "farms" : "farm"}
              </span>
            }
            content={value.map(farm => (
              <div> {farm}</div>
            ))}
            size="small"
            inverted
          />
        ) : (
          `${value.length} farm`
        ),
      filterId: "farmIds",
      filter: {
        title: "Farm",
        type: filterTypes.MultiSelect,
        selector: farmsOptionsFilterSelector
      },
      cellWidth: 4
    },
    address: {
      withSort: true,
      type: "string",
      id: "address",
      title: "Address",
      cellWidth: 8 // of 16, see Semantic Table
    }
  };

  return Object.entries(columnsConfig).map(([id, targetColumn]) => ({
    id,
    ...targetColumn,
    accessor: targetColumn.accessor || id,
    Header: targetColumn.title,
    Cell: targetColumn.Cell || BasicCell,
    ...(targetColumn.sortFields ? { sortFields: targetColumn.sortFields } : {}),
    ...(targetColumn.filter ? { filter: targetColumn.filter } : {})
  }));
};

export const getChemicalsTableColumns = () => {
  const columnsConfig = {
    name: {
      title: "Product",
      cellWidth: 2 // of 16, see Semantic Table
    },
    type: {
      title: "Type",
      accessor: ({ type }) => get(type, "type", ""),
      filter: {
        type: filterTypes.MultiSelect,
        selector: chemicalTypeOptionsSelector,
        loaderAction: fetchFilterValues
      }
    },
    matter: {
      title: "Matter",
      accessor: ({ matter }) => uppercaseFirstLetter(matter),
      filter: {
        type: filterTypes.SingleSelect,
        selector: matterOptionsSelector
      }
    }
  };

  return Object.entries(columnsConfig).map(([id, targetColumn]) => ({
    id,
    ...targetColumn,
    accessor: targetColumn.accessor || id,
    Header: targetColumn.title,
    Cell: targetColumn.Cell || BasicCell,
    ...(targetColumn.sortFields ? { sortFields: targetColumn.sortFields } : {}),
    ...(targetColumn.filter ? { filter: targetColumn.filter } : {})
  }));
};
