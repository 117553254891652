import React from "react";
import PropTypes from "prop-types";
import { Grid, Header, Icon, Image, Modal } from "semantic-ui-react";
import { get } from "lodash";
import blank from "assets/img/blank.png";
import { uppercaseFirstLetter } from "utils/string";
import {
  scoutingAreaIssueShape,
  scoutingAreaShape,
  scoutingIssueAttachmentShape
} from "constants/Scouting/types";
import { getReadableIssueType } from "utils/scouting";

const ContentRow = ({ name, value }) => {
  return (
    <Grid.Row style={{ padding: "5px 0" }}>
      <Grid.Column width={6}>{name}:</Grid.Column>
      <Grid.Column width={10}>
        <b>{value}</b>
      </Grid.Column>
    </Grid.Row>
  );
};

const ScoutingSidebarIssueModal = ({ selectedIssueData, onClose }) => {
  if (!selectedIssueData) {
    return null;
  }

  const { area, issue, attachmentData } = selectedIssueData;
  const areaType = uppercaseFirstLetter(area.type);
  const url = get(attachmentData, "attachment.presignedUrl");

  return (
    <Modal closeIcon open onClose={onClose}>
      <Header content={`${areaType} ${area.name}`} />
      <Modal.Content image>
        <Image
          size="medium"
          style={{ height: 300, objectFit: "contain" }}
          src={url || blank}
        />
        <Modal.Description>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Header size="medium">
                  <b>{get(attachmentData, "attachment.name")}</b>
                </Header>
              </Grid.Column>
            </Grid.Row>
            <ContentRow
              name={"Type of issue"}
              value={getReadableIssueType(issue.type)}
            />
            <ContentRow
              name={"Pressure rating"}
              value={`${issue.pressureRating}/5`}
            />
            <ContentRow name={"Row"} value={issue.rowNumber} />
            <ContentRow name={"Comments"} value={attachmentData.comment} />
            {url && (
              <Grid.Row style={{ marginTop: "auto" }} stretched>
                <Grid.Column>
                  <Header
                    as="a"
                    color="green"
                    href={url}
                    download
                    target="_blank"
                  >
                    <Icon name="download" color="green" />
                    Download image
                  </Header>
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

ScoutingSidebarIssueModal.propTypes = {
  selectedIssueData: PropTypes.shape({
    attachmentData: scoutingIssueAttachmentShape,
    issue: scoutingAreaIssueShape,
    area: scoutingAreaShape
  })
};

export default ScoutingSidebarIssueModal;
