import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import Layout from "../../../../../components/Layout";
import styles from "../../../../../components/ListHolder/ListHolder.module.css";
import pageStyles from "./ScannedGeneralBarcodesReport.module.css";
import {
  Button,
  Grid,
  Header,
  Segment,
  Sidebar,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow
} from "semantic-ui-react";
import {
  infoMessageType,
  messagesIds,
  putMessage
} from "../../../../../actions/InfoMessages";
import PickerRow from "./components/PickerRow";
import Loader from "../../../../../components/Loader";
import Filters from "../../../../../components/Filters";
import { hashToObject } from "../../../../../utils/hashToObject";
import ExportToExcel from "../../../../../components/ExportToExcel";
import { getFiltersFromLocations } from "../../../../../utils/routeHelpers";
import {
  fetchPickedCropPerEmployeeReport,
  fetchScannedBarcodesReport,
  setEmployeePickedCropsFilters
} from "../../../../../actions/Reports/reports";
import { history } from "../../../../../store";
import NavigateBackButton from "../../../../../components/NavigateBackButton/NavigateBackButton";
import Pagination from "../../../../../components/ListTable/Pagination";
import { columnsConfig, filtersOptions } from "./constants/index";

const ScannedGeneralBarcodesReport = ({
  route,
  location,
  employeePickedCrops,
  content,
  isFetching,
  isBarcodesReportLoading,
  pagination,
  actions
}) => {
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortValue, setSortValue] = useState("totalPrice");
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [reportRequested, setReportRequested] = useState(false);
  const [reportReady, setReportReady] = useState(false);

  const itemsCount = {
    itemFrom: pagination.number * pageSize + 1,
    itemTo: Math.min(
      pagination.number * pageSize + 1 + pageSize - 1,
      pagination.totalElements
    )
  };

  const onExcelButtonClick = () => {
    const rawFilters = getFiltersFromLocations(filtersOptions, location);

    actions.fetchScannedBarcodesReport({
      rawFilters,
      unpaged: true
    });
    setTimeout(() => setReportRequested(true), 0);
  };

  useEffect(() => {
    const rawFilters = getFiltersFromLocations(filtersOptions, location);

    actions.setEmployeePickedCropsFilters(rawFilters);
  }, [location, setEmployeePickedCropsFilters]);

  useEffect(() => {
    const { sort } = hashToObject(location.hash);
    if (sort) {
      const [sortValue, sortOrder] = sort.split(",");
      setSortOrder(sortOrder);
      setSortValue(sortValue);
    }
  }, [location]);

  const exportRows = useMemo(
    () =>
      !isBarcodesReportLoading && content
        ? content.map(employee => ({
            picker: `${employee.employeeFirstName} ${employee.employeeLastName}`,
            varietyName: employee.varietyName,
            cropName: employee.cropName,
            pricePartPerBarcode: _.round(employee.pricePartPerBarcode, 2),
            areaName: employee.areaName,
            row: employee.row,
            farmName: employee.farmName,
            harvestUnitName: `${employee.harvestUnitName} ${employee.harvestUnitNameNetWeight} KG`,
            binPart: _.round(employee.binPart, 2),
            weightPartPerBarcode: _.round(employee.weightPartPerBarcode, 2),
            scannedDate: moment.utc(employee.scannedAt).format("DD-MM-YYYY"),
            scannedTime: moment.utc(employee.scannedAt).format("HH:mm")
          }))
        : [],
    [content, isBarcodesReportLoading]
  );

  useEffect(() => {
    if (reportRequested && exportRows.length) {
      setReportReady(true);
      setTimeout(() => {
        setReportReady(false);
        setReportRequested(false);
      }, 0);
    }
  }, [exportRows, reportRequested]);

  // TODO: Optimize the 'routing' part
  const handleBackClick = () => {
    history.push({
      pathname: "/reports",
      state: {
        activeNode: _.get(location, "state.reportNode")
      }
    });
  };

  useEffect(() => {
    if (isBarcodesReportLoading) {
      actions.putMessage({
        type: infoMessageType.INFO,
        messageId: messagesIds.GENERIC,
        extraData: {
          message: "Loading the report..."
        }
      });
    }
  }, [isBarcodesReportLoading]);

  const employeePickedCropsValues = Object.values(employeePickedCrops || {});

  return (
    <Layout route={route} location={location} classForMain={styles.mainHolder}>
      <Sidebar.Pushable className={`${styles.farmsHolder}`}>
        <Sidebar.Pusher>
          <Segment basic className={styles.headerTasks}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Header as="h2" className={styles.taskName}>
                    <NavigateBackButton onClick={handleBackClick} />
                    {route.name}
                  </Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          <Segment>
            <div className={pageStyles.filterBlock}>
              <Filters options={filtersOptions} location={location} />
              <div>
                {reportRequested && reportReady && (
                  <ExportToExcel
                    columns={columnsConfig}
                    rawData={exportRows}
                    hideElement={true}
                    name={"Picked Crops per employee report"}
                  />
                )}
                <Button
                  icon="file excel"
                  className={`${pageStyles.exportButton} transparent`}
                  onClick={onExcelButtonClick}
                />
              </div>
            </div>
            {isFetching ? (
              <Loader />
            ) : (
              <Table unstackable>
                <TableHeader>
                  <TableRow>
                    <TableHeaderCell style={{ width: 30 }} />
                    <TableHeaderCell>ID</TableHeaderCell>
                    <TableHeaderCell>Employee</TableHeaderCell>
                    <TableHeaderCell>Group</TableHeaderCell>
                    <TableHeaderCell>Total Quantity (Bins)</TableHeaderCell>
                    <TableHeaderCell>Total Price ($)</TableHeaderCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {employeePickedCropsValues.length ? (
                    employeePickedCropsValues.map((employee, i) => (
                      <PickerRow data={employee} key={`item_${i}`} />
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={10}>No scanned barcodes</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}

            <Pagination
              showChangePageSize={false}
              currentPage={pageNumber}
              totalElements={pagination.totalElements}
              pageSize={10}
              online={true}
              itemsCount={itemsCount}
              onPageChangeOwn={pageNumber => setPageNumber(pageNumber)}
              pages={pagination.totalPages}
              updatePageSize={(_, data) => {
                setPageSize(data.value);
                setPageNumber(0);
              }}
              pageSizeOptions={[10, 20, 50, 100]}
            />
          </Segment>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </Layout>
  );
};

ScannedGeneralBarcodesReport.propTypes = {
  isFetching: PropTypes.bool,
  isBarcodesReportLoading: PropTypes.bool,
  employeePickedCrops: PropTypes.shape({
    [PropTypes.number]: {
      employeeFirstName: PropTypes.string,
      employeeGroupName: PropTypes.string,
      employeeId: PropTypes.string,
      employeeLastName: PropTypes.string,
      totalPrice: PropTypes.number,
      totalQuantity: PropTypes.number
    }
  }),
  pagination: PropTypes.shape({
    number: PropTypes.number,
    totalPages: PropTypes.number,
    totalElements: PropTypes.number,
    numberOfElements: PropTypes.number,
    size: PropTypes.number
  }),
  location: PropTypes.object,
  route: PropTypes.object,
  actions: PropTypes.object
};

const mapStateToProps = ({
  reports: {
    isFetching,
    reportsReducerNeedToRefactor: {
      employeePickedCrops,
      barcodes,
      isBarcodesReportLoading
    }
  }
}) => {
  const { content, ...pagination } = employeePickedCrops;
  return {
    isFetching,
    isBarcodesReportLoading,
    employeePickedCrops: content,
    content: barcodes.content,
    pagination
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchScannedBarcodesReport,
      fetchPickedCropPerEmployeeReport,
      setEmployeePickedCropsFilters,
      putMessage
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScannedGeneralBarcodesReport);
