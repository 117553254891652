import React from "react";
import PropTypes from "prop-types";
import { Grid, Header } from "semantic-ui-react";
import { Form, Input } from "formsy-semantic-ui-react";
import { validationErrors } from "../../../../utils/validationRules";
import styles from "../AddNewChemical.module.css";

const elementsWeight = [
  ["N", "Fe", "S"],
  ["P", "Zn", "Cu"],
  ["K", "Mg", "B"],
  ["Ca", "Mn", "Mo"],
  ["Cl", null, null]
];

function ChemicalProperties({
  errorLabel,
  onChangeValue,
  properties,
  columnMiddle,
  columnMiddleRight
}) {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column {...columnMiddle}>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Header as="h1">&nbsp;</Header>
                <Header as="h3">&nbsp;</Header>
                <Form.Field>
                  <label>Poison schedule </label>
                  <Input
                    value={properties.poisonSchedule}
                    name="poisonSchedule"
                    onChange={event =>
                      onChangeValue(
                        "properties.poisonSchedule",
                        event.target.value
                      )
                    }
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Form.Field>
                  <label>Active ingredient</label>
                  <Input
                    value={properties.activeIngredient}
                    name="activeIngredient"
                    onChange={event =>
                      onChangeValue(
                        "properties.activeIngredient",
                        event.target.value
                      )
                    }
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Form.Field>
                  <label>Resistance Group 1</label>
                  <Input
                    value={properties.resistanceGroup1}
                    name="resistanceGroup1"
                    onChange={event =>
                      onChangeValue(
                        "properties.resistanceGroup1",
                        event.target.value
                      )
                    }
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Form.Field>
                  <label>Resistance Group 2</label>
                  <Input
                    value={properties.resistanceGroup2}
                    name="resistanceGroup2"
                    onChange={event =>
                      onChangeValue(
                        "properties.resistanceGroup2",
                        event.target.value
                      )
                    }
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
        <Grid.Column {...columnMiddleRight}>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Header as="h1">Fertiliser Properties</Header>
                <Header as="h3">Element % Weight</Header>
                {elementsWeight.map((row, rowIndex) => (
                  <Form.Group
                    widths="equal"
                    className={styles.columnsEqual}
                    key={`row_${row[0]}`}
                  >
                    {row.map((cell, cellIndex) => (
                      <Form.Field key={`cell_${cell}_${rowIndex}_${cellIndex}`}>
                        {!cell ? null : (
                          <>
                            <label>{cell}</label>
                            <Input
                              value={
                                properties.elements && properties.elements[cell]
                                  ? properties.elements[cell]
                                  : ""
                              }
                              name={`elements.${cell}`}
                              validations="isNotNegativeFloat"
                              validationErrors={validationErrors()}
                              errorLabel={errorLabel}
                              onChange={event =>
                                onChangeValue(
                                  `properties.elements.${cell}`,
                                  event.target.value
                                )
                              }
                            />
                          </>
                        )}
                      </Form.Field>
                    ))}
                  </Form.Group>
                ))}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

ChemicalProperties.propTypes = {
  errorLabel: PropTypes.object,
  onChangeValue: PropTypes.func,
  properties: PropTypes.object,
  columnMiddle: PropTypes.object,
  columnMiddleRight: PropTypes.object
};

export default ChemicalProperties;
