import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MultiSelectFilter from "./MultiSelectFilter/MultiSelectFilter";
import { fetchActiveIngredients } from "../../../actions/Chemicals/index";

const ProductTypeSingleMultiFilter = ({ list, isCreating, ...extraProps }) => {
  const options = [
    ...new Map(
      list
        .map(item => ({
          value: item.type.id,
          key: item.type.id,
          text: item.type.type
        }))
        .filter((item, i, ar) => item !== false)
        .map(item => [item["key"], item])
    ).values()
  ];
  return (
    <MultiSelectFilter
      options={options}
      numbers={true}
      loading={isCreating}
      {...extraProps}
    />
  );
};

const mapStateToProps = state => {
  const {
    chemical: {
      isCreating,
      list: { content }
    }
  } = state;

  return {
    list: content,
    isCreating
  };
};

const mapDispatchToProps = dispatch => ({});

ProductTypeSingleMultiFilter.propTypes = {
  isCreating: PropTypes.bool,
  list: PropTypes.array,
  actions: PropTypes.object
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductTypeSingleMultiFilter);
