import React from "react";
import PropTypes from "prop-types";

import MultiSelectFilter from "./MultiSelectFilter/MultiSelectFilter";

const ApplicationTypeMultiSelectFilter = ({ ...extraProps }) => {
  const options = [
    { key: 1, text: "Spray", value: "SPRAY" },
    { key: 2, text: "Spread", value: "SPREAD" },
    { key: 3, text: "Fertigation", value: "FERTIGATION" }
  ];
  return (
    <MultiSelectFilter options={options} numbers={false} {...extraProps} />
  );
};

ApplicationTypeMultiSelectFilter.propTypes = {
  isFetching: PropTypes.bool,
  list: PropTypes.array,
  actions: PropTypes.object
};

export default ApplicationTypeMultiSelectFilter;
