import { BaseModel } from './base.model';
import { AreaVariety } from './area_variety.model';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);

export class Area extends BaseModel {
  name;
  type;
  patchMode;
  size;
  polygons;
  patches;
  attributes;

  constructor(data = {}) {
    super(data);

    this.name = this.name || '';
    this.type = this.type || 'block';
    this.patchMode = this.patchMode || 'BLOCKS_AND_PATCHES';
    this.attributes = Object.assign(
      {
        isRotating: false,
        registrationNumber: '',
        varieties: [{}],
      },
      data.attributes,
    );
    this.attributes.varieties = data.attributes ? data.attributes.varieties : [new AreaVariety()];
    if (data.varieties) {
      this.varieties = data.varieties.map(variety => new AreaVariety(variety));
    }
  }

  dateOrNull(date, start) {
    return date ? (start ? moment(date).startOf('day') : moment(date).endOf('day')) : null;
  }

  areaVarietyByDate(plannedDate) {
    const varieties = this.varieties || this.attributes.varieties;
    const variety = new AreaVariety(
      varieties
        ? varieties.find(variety => {
            const range = moment.range(
              this.dateOrNull(variety.plantingAt, true),
              this.dateOrNull(variety.pickingAt, false),
            );
            return range.contains(moment(plannedDate).startOf('day'));
          }) || {}
        : {},
    );
    return variety;
  }
}
