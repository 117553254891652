import React, { memo } from "react";
import PropTypes from "prop-types";
import { Grid, Header, Divider } from "semantic-ui-react";
import TaskRow from "../../../SprayDiary/TaskDetailsMobile/TaskRow/TaskRow";
import { isEmptyValue } from "../../../../utils/tasksUtils";
import ShowMore from "../../../../components/ShowMore/ShowMore";

const Sidebar = ({
  task: {
    id,
    workingTimes,
    windSpeed,
    windDirection,
    temperature,
    deltaT,
    supervisors,
    assignees,
    machinery,
    comment
  }
}) => {
  return id ? (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <Header as="h1">Further informations: {`ST-${id}`}</Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider />
      <Grid>
        <TaskRow
          label="Working Times:"
          name="workingTimes"
          value={isEmptyValue(workingTimes)}
        />
        <TaskRow
          label="Wind Speed:"
          name="windSpeed"
          value={isEmptyValue(windSpeed)}
        />
        <TaskRow
          label="Wind Direction:"
          name="windDirection"
          value={isEmptyValue(windDirection)}
        />
        <TaskRow
          label="Temperature:"
          name="temperature"
          value={temperature ? `${temperature} °C` : "—"}
        />
        <TaskRow
          label="Delta T"
          name="deltaT"
          inputType="textInput"
          value={deltaT}
        />
        <TaskRow label="Supervisors:" name="supervisor" value={supervisors} />
        <TaskRow
          label="Operators:"
          name="assignee"
          value={isEmptyValue(assignees)}
        />
        <TaskRow
          label="Machinery:"
          name="machinery"
          value={isEmptyValue(machinery)}
        />
        <TaskRow
          label="Comments:"
          name="comments"
          value={<ShowMore length={120}>{isEmptyValue(comment)}</ShowMore>}
        />
      </Grid>
    </>
  ) : (
    <div>No Data</div>
  );
};

Sidebar.propTypes = {
  task: PropTypes.object
};

export default memo(Sidebar);
