import React from "react";
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Menu,
  Popup
} from "semantic-ui-react";
import { get } from "lodash";
import { useSelector } from "react-redux";
import { abilitiesSelector } from "selectors/user";
import { Link } from "react-router-dom";
import GeneralInformationSection from "./components/GeneralInformationSection";
import { chemicalShape, FERTILISER } from "constants/Chemicals/types";
import CropRatesSection from "./components/CropRatesSection";
import ChemicalProperties from "./components/ChemicalProperties";
import ElementWeightSection from "./components/ElementWeightSection";
import AdditionalInformationSection from "./components/AdditionalInformationSection";
import styles from "./Sidebar.module.css";

function Sidebar({
  chemical,
  onClose,
  onEditClick,
  bucketsList,
  pendingOperation
}) {
  const Can = useSelector(abilitiesSelector);
  const typeCheck = chemical && get(chemical.type, "type") === FERTILISER;
  if (!chemical) {
    return null;
  }

  return (
    <Container fluid className={styles.root}>
      <Grid className={styles.sideBarHeader}>
        <Grid.Row
          className={styles.headerRow}
          columns={"equal"}
          verticalAlign="middle"
        >
          <Grid.Column className={styles.headerColumn}>
            <Header className={styles.name}>{chemical.name}</Header>
          </Grid.Column>

          <Can I="update" a="chemicals">
            <Grid.Column floated="right">
              <Popup
                inverted
                content={"Edit"}
                size="tiny"
                position="bottom center"
                trigger={
                  <div className={styles.editButtonWrapper}>
                    <Button
                      onClick={onEditClick}
                      className={styles.editButton}
                      floated="right"
                      icon={<Icon name="edit" />}
                      size="small"
                    />
                  </div>
                }
              />
            </Grid.Column>
          </Can>
          <Grid.Column width={1} floated="right">
            <Popup
              inverted
              position="bottom center"
              content={"Close"}
              size="tiny"
              trigger={
                <Button
                  className={styles.closeButton}
                  onClick={onClose}
                  floated="right"
                  icon={<Icon className="tuf-times" />}
                  size="small"
                />
              }
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid className={styles.infoContainer}>
        <Grid.Row columns={1}>
          <Grid.Column className={styles.generalInfoContainer}>
            <GeneralInformationSection
              chemical={chemical}
              bucketsList={bucketsList}
              pendingOperation={pendingOperation}
              editButton={
                <Button
                  onClick={e => onEditClick(e, chemical.id, 1)}
                  className={styles.sectionEditButton}
                  floated="right"
                  icon={<Icon name="edit" />}
                  size="small"
                  position="bottom right"
                />
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column className={styles.cropRatesSectionContainer}>
            <CropRatesSection
              editButton={
                <Button
                  onClick={e => onEditClick(e, chemical.id, 2)}
                  className={styles.sectionEditButton}
                  floated="right"
                  icon={<Icon name="edit" />}
                  size="small"
                  position="bottom right"
                />
              }
              chemical={chemical}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column className={styles.chemicalPropertiesContainer}>
            <ChemicalProperties
              typeCheck={typeCheck}
              editButton={
                <Button
                  onClick={e => onEditClick(e, chemical.id, 3)}
                  className={styles.sectionEditButton}
                  floated="right"
                  icon={<Icon name="edit" />}
                  size="small"
                  position="bottom right"
                />
              }
              chemical={chemical}
            />
          </Grid.Column>
        </Grid.Row>

        {typeCheck && (
          <Grid.Row columns={1}>
            <Grid.Column className={styles.elementWeightSectionContainer}>
              <ElementWeightSection
                editButton={
                  <Button
                    onClick={e => onEditClick(e, chemical.id, 4)}
                    className={styles.sectionEditButton}
                    floated="right"
                    icon={<Icon name="edit" />}
                    size="small"
                    position="bottom right"
                  />
                }
                chemical={chemical}
              />
            </Grid.Column>
          </Grid.Row>
        )}

        <Grid.Row columns={1}>
          <Grid.Column className={styles.additionalInformationContainer}>
            <AdditionalInformationSection
              editButton={
                <Button
                  onClick={e => onEditClick(e, chemical.id, 5)}
                  className={styles.sectionEditButton}
                  floated="right"
                  icon={<Icon name="edit" />}
                  size="small"
                  position="bottom right"
                />
              }
              chemical={chemical}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
}

Sidebar.propTypes = {
  chemical: chemicalShape
};
export default Sidebar;
