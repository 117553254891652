import React, { Component } from "react";
import { createSelector } from "reselect";
import { get, join, map } from "lodash";
import { matterToUnits } from "utils/constToUnits";
import Numeric from "components/Numeric/Numeric";
import moment from "moment";
const tableDataSelector = state =>
  state.reports.harvest.fieldScanning.pickerReport.data;
const tableListDataSelector = state =>
  state.reports.harvest.fieldScanning.pickerReport.list;
const areasListSelector = state => state.areas.list.content;
const cropsListSelector = state => state.crops.data;
const varietiesListSelector = state => state.varieties.data;
const farmListSelector = state => state.farms.summary;
const harvestListSelector = state =>
  state.harvestUnitsControl.filtersOptions.content;

export const formaterData = (
  data,
  areasList,
  farmsList,
  cropsList,
  varietiesList,
  harvestList
) => {
  const { content, ...rest } = data;
  const restContent = content?.content && {
    number: content.number,
    numberOfElements: content.numberOfElements,
    size: content.size,
    totalElements: content.totalElements,
    totalPages: content.totalPages
  };
  const contentUpdated =
    content && content?.content && content.content.length
      ? content.content.map(block => {
          return {
            ...block?.employeeData.reduce(
              (prev, curr, _, items) => {
                const harvestUnit = harvestList.find(
                  item => item.id === curr.harvestUnitId
                );
                const areaName = areasList.find(
                  item => item.id === curr.areaId
                );
                return {
                  workers: block.employeeName,
                  empty: "emptyValue",
                  contractors: block.contractor,
                  empty1: "emptyValue",
                  empty5: "emptyValue",
                  empty6: "emptyValue",
                  numberOfUnits: block.totalQty.toFixed(2),
                  empty2: "emptyValue",
                  empty3: "emptyValue",
                  totalPrice: block.totalPrice,
                  empty4: "emptyValuev",
                  subRows: [
                    ...prev.subRows,
                    {
                      workers: (
                        <span style={{ paddingLeft: "5px" }}>
                          {moment(curr.date).format("DD/MM/YYYY")}
                        </span>
                      ),
                      empty: farmsList?.find(
                        item => item.farm.id === curr.farmId
                      )?.farm?.name,
                      contractors: areaName?.parent
                        ? areaName?.parent?.name
                        : areaName?.name,
                      empty1: areaName?.parent ? areaName?.name : "-",
                      empty5: curr.row,
                      empty6: curr.qty.toFixed(2),
                      numberOfUnits: cropsList?.find(
                        item => item.id === curr.cropId
                      )?.name,
                      empty2: varietiesList?.find(
                        item => item.id === curr.varietyId
                      )?.name,
                      empty3: `${harvestUnit?.name} ${harvestUnit?.netWeight} Kg`,
                      totalPrice: curr.barcodeId,
                      empty4: (
                        <span style={{ paddingRight: "5px" }}>
                          {curr.price}{" "}
                        </span>
                      )
                    }
                  ]
                };
              },
              {
                workers: "",
                empty: "emptyValue",
                contractors: "",
                empty1: "emptyValue",
                empty5: "emptyValue",
                numberOfUnits: null,
                empty2: "emptyValue",
                totalPrice: null,
                empty3: "emptyValue",
                empty4: "emptyValue",
                subRows: [
                  {
                    workers: (
                      <b
                        style={{
                          paddingLeft: "5px",
                          height: "48px",
                          borderTop: "1px solid #DEDEDF",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "#F6F7F8"
                        }}
                      >
                        {" "}
                        Date
                      </b>
                    ),
                    empty: (
                      <b
                        style={{
                          height: "48px",
                          borderTop: "1px solid #DEDEDF",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "#F6F7F8"
                        }}
                      >
                        Farm
                      </b>
                    ),
                    contractors: (
                      <b
                        style={{
                          height: "48px",
                          borderTop: "1px solid #DEDEDF",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "#F6F7F8"
                        }}
                      >
                        Block
                      </b>
                    ),
                    empty1: (
                      <b
                        style={{
                          height: "48px",
                          borderTop: "1px solid #DEDEDF",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "#F6F7F8"
                        }}
                      >
                        Patch
                      </b>
                    ),
                    empty5: (
                      <b
                        style={{
                          height: "48px",
                          borderTop: "1px solid #DEDEDF",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "#F6F7F8"
                        }}
                      >
                        Row
                      </b>
                    ),
                    empty6: (
                      <b
                        style={{
                          height: "48px",
                          borderTop: "1px solid #DEDEDF",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "#F6F7F8"
                        }}
                      >
                        Quantity
                      </b>
                    ),
                    numberOfUnits: (
                      <b
                        style={{
                          height: "48px",
                          borderTop: "1px solid #DEDEDF",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "#F6F7F8"
                        }}
                      >
                        Crop
                      </b>
                    ),
                    empty2: (
                      <b
                        style={{
                          height: "48px",
                          borderTop: "1px solid #DEDEDF",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "#F6F7F8"
                        }}
                      >
                        Variety
                      </b>
                    ),
                    totalPrice: (
                      <b
                        style={{
                          height: "48px",
                          borderTop: "1px solid #DEDEDF",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "#F6F7F8"
                        }}
                      >
                        Barcode Id
                      </b>
                    ),
                    empty3: (
                      <b
                        style={{
                          height: "48px",
                          borderTop: "1px solid #DEDEDF",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "#F6F7F8"
                        }}
                      >
                        Bin unit
                      </b>
                    ),
                    empty4: (
                      <b
                        style={{
                          height: "48px",
                          borderTop: "1px solid #DEDEDF",
                          paddingRight: "5px",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "#F6F7F8"
                        }}
                      >
                        Price
                      </b>
                    )
                  }
                ]
              }
            )
          };
        }, {})
      : [];
  const contentUpdatedNew = contentUpdated
    ? contentUpdated.map(item => {
        return {
          ...item,
          subRows: item.subRows.map((row, index) => {
            if (index === item.subRows.length - 1) {
              row.isSubRowsLastItem = true;
            }
            return row;
          })
        };
      })
    : [];

  return {
    ...rest,
    ...restContent,
    content: contentUpdatedNew
  };
};
export const selectTableStructuredData = createSelector(
  [
    tableDataSelector,
    areasListSelector,
    farmListSelector,
    cropsListSelector,
    varietiesListSelector,
    harvestListSelector
  ],
  (tableData, areasList, farmsList, cropsList, varietiesList, harvestList) => {
    return formaterData(
      tableData,
      areasList,
      farmsList,
      cropsList,
      varietiesList,
      harvestList
    );
  }
);

export const selectExpotStructuredData = createSelector(
  [
    tableListDataSelector,
    areasListSelector,
    farmListSelector,
    cropsListSelector,
    varietiesListSelector,
    harvestListSelector
  ],
  (listData, areasList, farmsList, cropsList, varietiesList, harvestList) => {
    return formaterData(
      listData,
      areasList,
      farmsList,
      cropsList,
      varietiesList,
      harvestList
    );
  }
);
