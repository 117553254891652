import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import { chemicalsOptionsSelector } from "selectors/chemicals";
import { Container, Grid, Button, Segment, Popup } from "semantic-ui-react";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import NewProductForm from "./NewProductForm";
import NewProductRow from "./NewProductRow";
import { matterToUnits } from "utils/constToUnits";
import { get } from "lodash";
import styles from "./NewProduct.module.css";
import Numeric from "components/Numeric/Numeric";

const NewProduct = ({
  purchaseHisory,
  chemicalId,
  quantities,
  setValidation,
  setCheckForm,
  setProductId,
  setDirty,
  handleProductDelete,
  productIndex
}) => {
  const { values, setFieldValue, validateForm, setValues } = useFormikContext();
  const productsList = useSelector(chemicalsOptionsSelector);
  const [addButtonCheck, setAddButtonCheck] = useState(true);
  const [deleteButtonCheck, setDeleteButtonCheck] = useState(false);
  const productList = useSelector(chemicalsOptionsSelector);
  const quantitiesListCheck = quantities.length;

  const onNewNewProductAdd = async products => {
    setFieldValue(
      "quantities",
      values.quantities ? [...values.quantities, products] : [products]
    );
    validateForm();
    setAddButtonCheck(true);
    setCheckForm(false);
  };

  if (!addButtonCheck || !quantitiesListCheck) {
    setCheckForm(true);
  }
  const addProduct = () => {
    setAddButtonCheck(false);
    setDeleteButtonCheck(true);
    setCheckForm(true);
  };
  const deleteAddingForm = () => {
    setAddButtonCheck(true);
    setCheckForm(false);
  };
  useEffect(() => {
    if (values.quantities.length == 0) {
      setProductId(values.chemicalId);
    }
  }, [!values.quantities.length]);
  return (
    <>
      <div className={styles.productHeader}>Quantities purchased</div>
      {!isEmpty(quantities) && (
        <Grid columns="equal" as={Container}>
          <Grid.Row className={styles.labelContaner}>
            <Grid.Column className={styles.rowFirstLabel}>
              Batch number
            </Grid.Column>
            <Grid.Column className={styles.rowLabel}>No. of drums</Grid.Column>
            <Grid.Column className={styles.rowLabel}>Drum size</Grid.Column>
            <Grid.Column className={styles.rowLabel}>Total qty</Grid.Column>
            <Grid.Column className={styles.rowLabel}>Manuf. date</Grid.Column>
            <Grid.Column className={styles.rowLabel}>Exp. date</Grid.Column>
            <Grid.Column className={styles.rowLabel}>Shed</Grid.Column>
            <Grid.Column className={styles.lastLabel} />
          </Grid.Row>
          {quantities &&
            quantities.map((quantity, quantityIndex) => (
              <NewProductRow
                productIndex={productIndex}
                quantities={values.quantities}
                setValidation={setValidation}
                productsList={productsList}
                setDirty={setDirty}
                setCheckForm={setCheckForm}
                productKey={values.chemicalId}
                productId={values.id}
                quantitiesList={quantitiesListCheck}
                quantity={quantity}
                key={quantity.id}
                purchaseHisory={purchaseHisory}
                quantityIndex={quantityIndex}
                handleProductDelete={handleProductDelete}
              />
            ))}
          <Grid.Row className={styles.totalRowButtonWrapper}>
            <div className={styles.totalRowWrapper}>
              <Grid.Column className={styles.totalRowLabel}>Total</Grid.Column>
              <Popup
                trigger={
                  <Grid.Column className={styles.totalRowUnitsNumber}>
                    {quantities.reduce(
                      (prev, curr) => prev + +curr.unitsNumber,
                      0
                    ) || "-"}
                  </Grid.Column>
                }
                position="top left "
                content={
                  <div className={styles.meesageContainer}>
                    {quantities.reduce(
                      (prev, curr) => prev + +curr.unitsNumber,
                      0
                    ) || "-"}
                  </div>
                }
                inverted
              />
              <Popup
                trigger={
                  <Grid.Column className={styles.totalRowTotalQty}>
                    <Numeric
                      fractionDigits={
                        quantities.reduce(
                          (prev, curr) =>
                            prev + +curr.unitsNumber * curr.unitSize,
                          0
                        ) > 1000
                          ? 0
                          : 2
                      }
                      value={quantities.reduce(
                        (prev, curr) =>
                          prev + +curr.unitsNumber * curr.unitSize,
                        0
                      )}
                      commaSeparatorOnThousands
                      units={
                        ` ${
                          values.chemicalId
                            ? matterToUnits(
                                get(
                                  productList.find(
                                    item =>
                                      item.key == values.chemicalId && item
                                  ),
                                  "matter"
                                )
                              )
                            : "Kg"
                        }` || "-"
                      }
                      unitsPosition="right"
                    />
                  </Grid.Column>
                }
                position="top left "
                content={
                  <div className={styles.meesageContainer}>
                    {`${quantities.reduce(
                      (prev, curr) => prev + +curr.unitsNumber * curr.unitSize,
                      0
                    )} ${
                      values.chemicalId
                        ? matterToUnits(
                            get(
                              productList.find(
                                item => item.key == values.chemicalId && item
                              ),
                              "matter"
                            )
                          )
                        : "Kg"
                    }` || "-"}
                  </div>
                }
                inverted
                // disabled={!unitsNumber }
              />
            </div>
            {quantitiesListCheck && addButtonCheck ? (
              <div className={styles.addButtonContainer}>
                <Button
                  type="submit"
                  className="button-text color-green"
                  onClick={addProduct}
                >
                  + Add batch number
                </Button>
              </div>
            ) : null}
          </Grid.Row>
        </Grid>
      )}

      {(!addButtonCheck || !quantitiesListCheck) && (
        <>
          <Segment>
            <NewProductForm
              quantitiy={quantities}
              setValidation={setValidation}
              setCheckForm={setCheckForm}
              addButtonCheck={addButtonCheck}
              quantitiesListCheck={quantitiesListCheck}
              setDirty={setDirty}
              productsListCheck={quantitiesListCheck}
              productsList={productsList}
              deleteAddingForm={deleteAddingForm}
              deleteButtonCheck={deleteButtonCheck}
              onSubmit={onNewNewProductAdd}
              chemicalId={chemicalId}
            />
          </Segment>
        </>
      )}
    </>
  );
};
export default NewProduct;
