import React from "react";
import { Table } from "semantic-ui-react";
import moment from "moment";

const RateHistoryTable = ({ rateHistory }) => {
  let allRates = 0;

  const tableData = rateHistory
    .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
    .map((rate, index) => {
      allRates += rate.rate;
      return {
        date: `${moment(rate.createdAt).format("DD/MM/YYYY")} - ${moment(
          index === rateHistory.length - 1
            ? moment.now()
            : rateHistory[index + 1].createdAt
        ).format("DD/MM/YYYY")}`,
        rate: rate.rate ? `$${rate.rate.toFixed(2)}/hr` : "-"
      };
    });

  tableData.push({
    date: `Average rate during the period: ${moment(
      rateHistory[0].createdAt
    ).format("DD/MM/YYYY")} - ${moment().format("DD/MM/YYYY")}`,
    rate: `$${(allRates / rateHistory.length).toFixed(2)}/hr`
  });

  const renderBodyRow = ({ date, rate }, i) => ({
    key: `row-${i}`,
    cells: [
      date || "No name specified",
      rate ? { key: "rate", content: rate } : "None"
    ]
  });

  const headerRows = ["Date", "Rate($/hr)"];

  return (
    <Table
      celled
      headerRow={headerRows}
      columns={2}
      renderBodyRow={renderBodyRow}
      tableData={tableData}
    />
  );
};

export default RateHistoryTable;
