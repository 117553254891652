export const actionType = {
  GENERATE_GENERAL_BARCODES_START:
    "GENERAL_BARCODES/GENERATE_GENERAL_BARCODES_START",
  GENERATE_GENERAL_BARCODES: "GENERAL_BARCODES/GENERATE_GENERAL_BARCODES",
  GENERATE_GENERAL_BARCODES_FAIL:
    "GENERAL_BARCODES/GENERATE_GENERAL_BARCODES_FAIL",
  GENERAL_BARCODES_CLEAR_SERIES: "GENERAL_BARCODES/CLEAR_SERIES",
  GENERAL_BARCODES_CLEAR_ERROR: "GENERAL_BARCODES/CLEAR_ERROR",

  FETCH_SCANNED_BARCODES_START: "GENERAL_BARCODES/FETCH_SCANNED_BARCODES_START",
  FETCH_SCANNED_BARCODES: "GENERAL_BARCODES/FETCH_SCANNED_BARCODES",
  FETCH_SCANNED_BARCODES_FAIL: "GENERAL_BARCODES/FETCH_SCANNED_BARCODES_FAIL",
  CLEAR_SCANNED_BARCODES_ERROR: "GENERAL_BARCODES/CLEAR_SCANNED_BARCODES_ERROR",

  UPLOAD_GENERAL_BARCODES_ATTACHMENTS_START:
    "GENERAL_BARCODES/UPLOAD_GENERAL_BARCODES_ATTACHMENTS_START",
  UPLOAD_GENERAL_BARCODES_ATTACHMENTS:
    "GENERAL_BARCODES/UPLOAD_GENERAL_BARCODES_ATTACHMENTS",
  UPLOAD_GENERAL_BARCODES_ATTACHMENTS_ERROR:
    "GENERAL_BARCODES/UPLOAD_GENERAL_BARCODES_ATTACHMENTS_ERROR",

  // SCANNED BARCODES

  FETCH_SCANNED_BARCODE: "GENERAL_BARCODES/FETCH_SCANNED_BARCODE",
  FETCH_SCANNED_BARCODE_START: "GENERAL_BARCODES/FETCH_SCANNED_BARCODE_START",
  FETCH_SCANNED_BARCODE_FAIL: "GENERAL_BARCODES/FETCH_SCANNED_BARCODE_FAIL",

  DELETE_SUBMISSION_START:
    "GENERAL_BARCODES/SUBMISSION/DELETE_SUBMISSION_START",
  DELETE_SUBMISSION: "GENERAL_BARCODES/SUBMISSION/DELETE_SUBMISSION",
  DELETE_SUBMISSION_FAIL: "GENERAL_BARCODES/SUBMISSION/DELETE_SUBMISSION_FAIL",

  UPDATE_SUBMISSION_START:
    "GENERAL_BARCODES/SUBMISSION/UPDATE_SUBMISSION_START",
  UPDATE_SUBMISSION: "GENERAL_BARCODES/SUBMISSION/UPDATE_SUBMISSION",
  UPDATE_SUBMISSION_ERROR:
    "GENERAL_BARCODES/SUBMISSION/UPDATE_SUBMISSION_ERROR",

  GENERATE_PICKED_BINS_PER_GROUP_REPORT_START:
    "GENERAL_BARCODES/GENERATE_PICKED_BINS_PER_GROUP_REPORT_START",
  GENERATE_PICKED_BINS_PER_GROUP_REPORT:
    "GENERAL_BARCODES/GENERATE_PICKED_BINS_PER_GROUP_REPORT",
  GENERATE_PICKED_BINS_PER_GROUP_REPORT_ERROR:
    "GENERAL_BARCODES/GENERATE_PICKED_BINS_PER_GROUP_REPORT_ERROR",

  GENERATE_PICKED_BINS_REPORT: "GENERAL_BARCODES/GENERATE_PICKED_BINS_REPORT",
  GENERATE_PICKED_BINS_REPORT_START:
    "GENERAL_BARCODES/GENERATE_PICKED_BINS_REPORT_START",
  GENERATE_PICKED_BINS_REPORT_ERROR:
    "GENERAL_BARCODES/GENERATE_PICKED_BINS_REPORT_ERROR",

  FETCH_GENERAL_BARCODE_IDS_START:
    "GENERAL_BARCODES/FETCH_GENERAL_BARCODE_IDS_START",
  FETCH_GENERAL_BARCODE_IDS: "GENERAL_BARCODES/FETCH_GENERAL_BARCODE_IDS",
  FETCH_GENERAL_BARCODE_IDS_FAIL:
    "GENERAL_BARCODES/FETCH_GENERAL_BARCODE_IDS_FAIL"
};
