import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Form } from "formsy-semantic-ui-react";
import { Dropdown } from "semantic-ui-react";
import { harvestUnitsFetch } from "../../actions/HarvestUnits/harvestUnits";

const SelectHarvestUnit = ({
  actions: { harvestUnitsFetch },
  harvestUnitsList,
  isFetching,
  required,
  // error,
  minItemForSearch,
  onChange,
  native,
  showArchived,
  ...extraProps
}) => {
  useEffect(() => {
    harvestUnitsFetch();
  }, [harvestUnitsFetch]);

  const filterArchived = data =>
    showArchived || !data.hasOwnProperty("archived")
      ? true
      : data.archived === false;

  const options = useMemo(
    () =>
      harvestUnitsList
        .filter(filterArchived)
        .sort(content => content.name)
        .map(content => ({
          key: content.id,
          text: `${content.name}, ${content.netWeight}kg`,
          value: content.id
        })),
    [harvestUnitsList]
  );

  useEffect(() => {
    if (required && options && options.length === 1) {
      onChange(null, { options, value: options[0].value });
    }
  }, [options]); // eslint-disable-line

  const props = {
    placeholder: "Select harvest unit",
    noResultsMessage: "Nothing was found",
    fluid: true,
    ...extraProps,
    // error,
    closeOnBlur: true,
    onChange,
    loading: isFetching,
    selection: true,
    required,
    search: options.length >= minItemForSearch,
    options
  };

  return native ? <Dropdown {...props} /> : <Form.Dropdown {...props} />;
};

SelectHarvestUnit.propTypes = {
  actions: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  harvestUnitsList: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  minItemForSearch: PropTypes.number,
  native: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
  showArchived: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.array.isRequired,
    PropTypes.number.isRequired
  ])
};

SelectHarvestUnit.defaultProps = {
  disabled: false,
  error: false,
  harvestUnitsList: [],
  isFetching: false,
  minItemForSearch: 10,
  native: false,
  onChange: () => {},
  required: false,
  showArchived: false,
  value: []
};
const mapStateToProps = ({
  harvestUnitsControl: {
    filtersOptions: { content },
    isFetching
  }
}) => {
  return {
    harvestUnitsList: content,
    isFetching
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      harvestUnitsFetch
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectHarvestUnit);
