import React from "react";
import SegmentClosable from "components/SegmentClosable";
import FormContainer from "./FormContainer";
import SelectedAreasContainer from "./SelectedAreasContainer/SelectedAreasContainer";

const Location = () => {
  return (
    <SegmentClosable title="Location">
      <FormContainer />
      <SelectedAreasContainer />
    </SegmentClosable>
  );
};

Location.propTypes = {};

export default Location;
