import { useMemo } from "react";
import { Grid } from "semantic-ui-react";
import styles from "../styles.module.css";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import { useSelector } from "react-redux";

function Review({ instructionsStep }) {
  const equipment = useSelector(state => state.machinery.data.content);

  const requiredEquipments = useMemo(() => {
    const items = [];

    if (instructionsStep.equipment.length) {
      instructionsStep.equipment.forEach(equipmentId => {
        const foundEquipment = equipment.find(e => e.id === equipmentId);

        if (foundEquipment) {
          items.push(foundEquipment);
        }
      });
    }
    return items;
  }, [instructionsStep.equipment]);

  return (
    <Grid style={{ paddingLeft: 40 }}>
      <Grid.Row>
        <Grid.Column computer={2} mobile={16}>
          <span className={styles.fieldTitle}>Farm</span>
          <br />
          <div className={styles.farmfield}>
            {instructionsStep.farms
              ? instructionsStep.farms.map(farm => farm.name).join(", ")
              : "-"}
          </div>
        </Grid.Column>
        <Grid.Column computer={2} mobile={16}>
          <span className={styles.fieldTitle}>Areas</span>
          <br />
          <span className={styles.blockfield}>
            {instructionsStep.areas
              ? instructionsStep.areas.map(area => area.name).join(", ")
              : "-"}
          </span>
        </Grid.Column>
        <Grid.Column computer={2} mobile={16}>
          <span className={styles.fieldTitle}>Crop</span>
          <br />
          <span>
            {instructionsStep.crop ? instructionsStep.crop.name : "-"}
          </span>
        </Grid.Column>
        <Grid.Column computer={2} mobile={16}>
          <span className={styles.fieldTitle}>Variety</span>
          <br />
          <span>
            {instructionsStep.variety ? instructionsStep.variety.name : "-"}
          </span>
        </Grid.Column>
        <Grid.Column computer={2} mobile={16}>
          <span className={styles.fieldTitle}>Required equipment</span>
          <br />
          <span>
            {requiredEquipments.length > 0
              ? requiredEquipments.map(e => e.name).join(", ")
              : "-"}
          </span>
        </Grid.Column>
        <Grid.Column computer={2} mobile={16}>
          <span className={styles.fieldTitle}>Number of employees</span>
          <br />
          <span className={styles.fieldValue}>
            {instructionsStep.numberOfEmployees || "-"}
          </span>
        </Grid.Column>
        <Grid.Column computer={2} mobile={16}>
          <span className={styles.fieldTitle}>Payment method</span>
          <br />
          <span>
            {instructionsStep.paymentMethod
              ? instructionsStep.paymentMethod.replace(/_/gi, " ")
              : "-"}
          </span>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

Review.propTypes = {
  instructionsStep: PropTypes.object
};

export default Review;
