export const COUNTRY_OPTIONS = [
  { key: 'af', text: 'Afghanistan', value: 'Afghanistan' },
  { key: 'ax', text: 'Åland Islands', value: 'Åland Islands' },
  { key: 'al', text: 'Albania', value: 'Albania' },
  { key: 'dz', text: 'Algeria', value: 'Algeria' },
  { key: 'as', text: 'American Samoa', value: 'American Samoa' },
  { key: 'ad', text: 'Andorra', value: 'Andorra' },
  { key: 'ao', text: 'Angola', value: 'Angola' },
  { key: 'ai', text: 'Anguilla', value: 'Anguilla' },
  { key: 'aq', text: 'Antarctica', value: 'Antarctica' },
  { key: 'ag', text: 'Antigua and Barbuda', value: 'Antigua and Barbuda' },
  { key: 'ar', text: 'Argentina', value: 'Argentina' },
  { key: 'am', text: 'Armenia', value: 'Armenia' },
  { key: 'aw', text: 'Aruba', value: 'Aruba' },
  { key: 'au', text: 'Australia', value: 'Australia' },
  { key: 'at', text: 'Austria', value: 'Austria' },
  { key: 'az', text: 'Azerbaijan', value: 'Azerbaijan' },
  { key: 'bs', text: 'Bahamas', value: 'Bahamas' },
  { key: 'bh', text: 'Bahrain', value: 'Bahrain' },
  { key: 'bd', text: 'Bangladesh', value: 'Bangladesh' },
  { key: 'bb', text: 'Barbados', value: 'Barbados' },
  { key: 'by', text: 'Belarus', value: 'Belarus' },
  { key: 'be', text: 'Belgium', value: 'Belgium' },
  { key: 'bz', text: 'Belize', value: 'Belize' },
  { key: 'bj', text: 'Benin', value: 'Benin' },
  { key: 'bm', text: 'Bermuda', value: 'Bermuda' },
  { key: 'bt', text: 'Bhutan', value: 'Bhutan' },
  { key: 'bo', text: 'Bolivia, Plurinational State of', value: 'Bolivia, Plurinational State of' },
  { key: 'bq', text: 'Bonaire, Sint Eustatius and Saba', value: 'Bonaire, Sint Eustatius and Saba' },
  { key: 'ba', text: 'Bosnia and Herzegovina', value: 'Bosnia and Herzegovina' },
  { key: 'bw', text: 'Botswana', value: 'Botswana' },
  { key: 'bv', text: 'Bouvet Island', value: 'Bouvet Island' },
  { key: 'br', text: 'Brazil', value: 'Brazil' },
  { key: 'io', text: 'British Indian Ocean Territory', value: 'British Indian Ocean Territory' },
  { key: 'bn', text: 'Brunei Darussalam', value: 'Brunei Darussalam' },
  { key: 'bg', text: 'Bulgaria', value: 'Bulgaria' },
  { key: 'bf', text: 'Burkina Faso', value: 'Burkina Faso' },
  { key: 'bi', text: 'Burundi', value: 'Burundi' },
  { key: 'kh', text: 'Cambodia', value: 'Cambodia' },
  { key: 'cm', text: 'Cameroon', value: 'Cameroon' },
  { key: 'ca', text: 'Canada', value: 'Canada' },
  { key: 'cv', text: 'Cape Verde', value: 'Cape Verde' },
  { key: 'ky', text: 'Cayman Islands', value: 'Cayman Islands' },
  { key: 'cf', text: 'Central African Republic', value: 'Central African Republic' },
  { key: 'td', text: 'Chad', value: 'Chad' },
  { key: 'cl', text: 'Chile', value: 'Chile' },
  { key: 'cn', text: 'China', value: 'China' },
  { key: 'cx', text: 'Christmas Island', value: 'Christmas Island' },
  { key: 'cc', text: 'Cocos (Keeling) Islands', value: 'Cocos (Keeling) Islands' },
  { key: 'co', text: 'Colombia', value: 'Colombia' },
  { key: 'km', text: 'Comoros', value: 'Comoros' },
  { key: 'cg', text: 'Congo', value: 'Congo' },
  { key: 'cd', text: 'Congo, the Democratic Republic of the', value: 'Congo, the Democratic Republic of the' },
  { key: 'ck', text: 'Cook Islands', value: 'Cook Islands' },
  { key: 'cr', text: 'Costa Rica', value: 'Costa Rica' },
  { key: 'ci', text: "Côte d'Ivoire", value: "Côte d'Ivoire" },
  { key: 'hr', text: 'Croatia', value: 'Croatia' },
  { key: 'cu', text: 'Cuba', value: 'Cuba' },
  { key: 'cw', text: 'Curaçao', value: 'Curaçao' },
  { key: 'cy', text: 'Cyprus', value: 'Cyprus' },
  { key: 'cz', text: 'Czech Republic', value: 'Czech Republic' },
  { key: 'dk', text: 'Denmark', value: 'Denmark' },
  { key: 'dj', text: 'Djibouti', value: 'Djibouti' },
  { key: 'dm', text: 'Dominica', value: 'Dominica' },
  { key: 'do', text: 'Dominican Republic', value: 'Dominican Republic' },
  { key: 'ec', text: 'Ecuador', value: 'Ecuador' },
  { key: 'eg', text: 'Egypt', value: 'Egypt' },
  { key: 'sv', text: 'El Salvador', value: 'El Salvador' },
  { key: 'gq', text: 'Equatorial Guinea', value: 'Equatorial Guinea' },
  { key: 'er', text: 'Eritrea', value: 'Eritrea' },
  { key: 'ee', text: 'Estonia', value: 'Estonia' },
  { key: 'et', text: 'Ethiopia', value: 'Ethiopia' },
  { key: 'fk', text: 'Falkland Islands (Malvinas)', value: 'Falkland Islands (Malvinas)' },
  { key: 'fo', text: 'Faroe Islands', value: 'Faroe Islands' },
  { key: 'fj', text: 'Fiji', value: 'Fiji' },
  { key: 'fi', text: 'Finland', value: 'Finland' },
  { key: 'fr', text: 'France', value: 'France' },
  { key: 'gf', text: 'French Guiana', value: 'French Guiana' },
  { key: 'pf', text: 'French Polynesia', value: 'French Polynesia' },
  { key: 'tf', text: 'French Southern Territories', value: 'French Southern Territories' },
  { key: 'ga', text: 'Gabon', value: 'Gabon' },
  { key: 'gm', text: 'Gambia', value: 'Gambia' },
  { key: 'ge', text: 'Georgia', value: 'Georgia' },
  { key: 'de', text: 'Germany', value: 'Germany' },
  { key: 'gh', text: 'Ghana', value: 'Ghana' },
  { key: 'gi', text: 'Gibraltar', value: 'Gibraltar' },
  { key: 'gr', text: 'Greece', value: 'Greece' },
  { key: 'gl', text: 'Greenland', value: 'Greenland' },
  { key: 'gd', text: 'Grenada', value: 'Grenada' },
  { key: 'gp', text: 'Guadeloupe', value: 'Guadeloupe' },
  { key: 'gu', text: 'Guam', value: 'Guam' },
  { key: 'gt', text: 'Guatemala', value: 'Guatemala' },
  { key: 'gg', text: 'Guernsey', value: 'Guernsey' },
  { key: 'gn', text: 'Guinea', value: 'Guinea' },
  { key: 'gw', text: 'Guinea-Bissau', value: 'Guinea-Bissau' },
  { key: 'gy', text: 'Guyana', value: 'Guyana' },
  { key: 'ht', text: 'Haiti', value: 'Haiti' },
  { key: 'hm', text: 'Heard Island and McDonald Islands', value: 'Heard Island and McDonald Islands' },
  { key: 'va', text: 'Holy See (Vatican City State)', value: 'Holy See (Vatican City State)' },
  { key: 'hn', text: 'Honduras', value: 'Honduras' },
  { key: 'hk', text: 'Hong Kong', value: 'Hong Kong' },
  { key: 'hu', text: 'Hungary', value: 'Hungary' },
  { key: 'is', text: 'Iceland', value: 'Iceland' },
  { key: 'in', text: 'India', value: 'India' },
  { key: 'id', text: 'Indonesia', value: 'Indonesia' },
  { key: 'ir', text: 'Iran, Islamic Republic of', value: 'Iran, Islamic Republic of' },
  { key: 'iq', text: 'Iraq', value: 'Iraq' },
  { key: 'ie', text: 'Ireland', value: 'Ireland' },
  { key: 'im', text: 'Isle of Man', value: 'Isle of Man' },
  { key: 'il', text: 'Israel', value: 'Israel' },
  { key: 'it', text: 'Italy', value: 'Italy' },
  { key: 'jm', text: 'Jamaica', value: 'Jamaica' },
  { key: 'jp', text: 'Japan', value: 'Japan' },
  { key: 'je', text: 'Jersey', value: 'Jersey' },
  { key: 'jo', text: 'Jordan', value: 'Jordan' },
  { key: 'kz', text: 'Kazakhstan', value: 'Kazakhstan' },
  { key: 'ke', text: 'Kenya', value: 'Kenya' },
  { key: 'ki', text: 'Kiribati', value: 'Kiribati' },
  { key: 'kp', text: "Korea, Democratic People's Republic of", value: "Korea, Democratic People's Republic of" },
  { key: 'kr', text: 'Korea, Republic of', value: 'Korea, Republic of' },
  { key: 'kw', text: 'Kuwait', value: 'Kuwait' },
  { key: 'kg', text: 'Kyrgyzstan', value: 'Kyrgyzstan' },
  { key: 'la', text: "Lao People's Democratic Republic", value: "Lao People's Democratic Republic" },
  { key: 'lv', text: 'Latvia', value: 'Latvia' },
  { key: 'lb', text: 'Lebanon', value: 'Lebanon' },
  { key: 'ls', text: 'Lesotho', value: 'Lesotho' },
  { key: 'lr', text: 'Liberia', value: 'Liberia' },
  { key: 'ly', text: 'Libya', value: 'Libya' },
  { key: 'li', text: 'Liechtenstein', value: 'Liechtenstein' },
  { key: 'lt', text: 'Lithuania', value: 'Lithuania' },
  { key: 'lu', text: 'Luxembourg', value: 'Luxembourg' },
  { key: 'mo', text: 'Macao', value: 'Macao' },
  {
    key: 'mk',
    text: 'Macedonia, the Former Yugoslav Republic of',
    value: 'Macedonia, the Former Yugoslav Republic of',
  },
  { key: 'mg', text: 'Madagascar', value: 'Madagascar' },
  { key: 'mw', text: 'Malawi', value: 'Malawi' },
  { key: 'my', text: 'Malaysia', value: 'Malaysia' },
  { key: 'mv', text: 'Maldives', value: 'Maldives' },
  { key: 'ml', text: 'Mali', value: 'Mali' },
  { key: 'mt', text: 'Malta', value: 'Malta' },
  { key: 'mh', text: 'Marshall Islands', value: 'Marshall Islands' },
  { key: 'mq', text: 'Martinique', value: 'Martinique' },
  { key: 'mr', text: 'Mauritania', value: 'Mauritania' },
  { key: 'mu', text: 'Mauritius', value: 'Mauritius' },
  { key: 'yt', text: 'Mayotte', value: 'Mayotte' },
  { key: 'mx', text: 'Mexico', value: 'Mexico' },
  { key: 'fm', text: 'Micronesia, Federated States of', value: 'Micronesia, Federated States of' },
  { key: 'md', text: 'Moldova, Republic of', value: 'Moldova, Republic of' },
  { key: 'mc', text: 'Monaco', value: 'Monaco' },
  { key: 'mn', text: 'Mongolia', value: 'Mongolia' },
  { key: 'me', text: 'Montenegro', value: 'Montenegro' },
  { key: 'ms', text: 'Montserrat', value: 'Montserrat' },
  { key: 'ma', text: 'Morocco', value: 'Morocco' },
  { key: 'mz', text: 'Mozambique', value: 'Mozambique' },
  { key: 'mm', text: 'Myanmar', value: 'Myanmar' },
  { key: 'na', text: 'Namibia', value: 'Namibia' },
  { key: 'nr', text: 'Nauru', value: 'Nauru' },
  { key: 'np', text: 'Nepal', value: 'Nepal' },
  { key: 'nl', text: 'Netherlands', value: 'Netherlands' },
  { key: 'nc', text: 'New Caledonia', value: 'New Caledonia' },
  { key: 'nz', text: 'New Zealand', value: 'New Zealand' },
  { key: 'ni', text: 'Nicaragua', value: 'Nicaragua' },
  { key: 'ne', text: 'Niger', value: 'Niger' },
  { key: 'ng', text: 'Nigeria', value: 'Nigeria' },
  { key: 'nu', text: 'Niue', value: 'Niue' },
  { key: 'nf', text: 'Norfolk Island', value: 'Norfolk Island' },
  { key: 'mp', text: 'Northern Mariana Islands', value: 'Northern Mariana Islands' },
  { key: 'no', text: 'Norway', value: 'Norway' },
  { key: 'om', text: 'Oman', value: 'Oman' },
  { key: 'pk', text: 'Pakistan', value: 'Pakistan' },
  { key: 'pw', text: 'Palau', value: 'Palau' },
  { key: 'ps', text: 'Palestine, State of', value: 'Palestine, State of' },
  { key: 'pa', text: 'Panama', value: 'Panama' },
  { key: 'pg', text: 'Papua New Guinea', value: 'Papua New Guinea' },
  { key: 'py', text: 'Paraguay', value: 'Paraguay' },
  { key: 'pe', text: 'Peru', value: 'Peru' },
  { key: 'ph', text: 'Philippines', value: 'Philippines' },
  { key: 'pn', text: 'Pitcairn', value: 'Pitcairn' },
  { key: 'pl', text: 'Poland', value: 'Poland' },
  { key: 'pt', text: 'Portugal', value: 'Portugal' },
  { key: 'pr', text: 'Puerto Rico', value: 'Puerto Rico' },
  { key: 'qa', text: 'Qatar', value: 'Qatar' },
  { key: 're', text: 'Réunion', value: 'Réunion' },
  { key: 'ro', text: 'Romania', value: 'Romania' },
  { key: 'ru', text: 'Russian Federation', value: 'Russian Federation' },
  { key: 'rw', text: 'Rwanda', value: 'Rwanda' },
  { key: 'bl', text: 'Saint Barthélemy', value: 'Saint Barthélemy' },
  {
    key: 'sh',
    text: 'Saint Helena, Ascension and Tristan da Cunha',
    value: 'Saint Helena, Ascension and Tristan da Cunha',
  },
  { key: 'kn', text: 'Saint Kitts and Nevis', value: 'Saint Kitts and Nevis' },
  { key: 'lc', text: 'Saint Lucia', value: 'Saint Lucia' },
  { key: 'mf', text: 'Saint Martin (French part)', value: 'Saint Martin (French part)' },
  { key: 'pm', text: 'Saint Pierre and Miquelon', value: 'Saint Pierre and Miquelon' },
  { key: 'vc', text: 'Saint Vincent and the Grenadines', value: 'Saint Vincent and the Grenadines' },
  { key: 'ws', text: 'Samoa', value: 'Samoa' },
  { key: 'sm', text: 'San Marino', value: 'San Marino' },
  { key: 'st', text: 'Sao Tome and Principe', value: 'Sao Tome and Principe' },
  { key: 'sa', text: 'Saudi Arabia', value: 'Saudi Arabia' },
  { key: 'sn', text: 'Senegal', value: 'Senegal' },
  { key: 'rs', text: 'Serbia', value: 'Serbia' },
  { key: 'sc', text: 'Seychelles', value: 'Seychelles' },
  { key: 'sl', text: 'Sierra Leone', value: 'Sierra Leone' },
  { key: 'sg', text: 'Singapore', value: 'Singapore' },
  { key: 'sx', text: 'Sint Maarten (Dutch part)', value: 'Sint Maarten (Dutch part)' },
  { key: 'sk', text: 'Slovakia', value: 'Slovakia' },
  { key: 'si', text: 'Slovenia', value: 'Slovenia' },
  { key: 'sb', text: 'Solomon Islands', value: 'Solomon Islands' },
  { key: 'so', text: 'Somalia', value: 'Somalia' },
  { key: 'za', text: 'South Africa', value: 'South Africa' },
  {
    key: 'gs',
    text: 'South Georgia and the South Sandwich Islands',
    value: 'South Georgia and the South Sandwich Islands',
  },
  { key: 'ss', text: 'South Sudan', value: 'South Sudan' },
  { key: 'es', text: 'Spain', value: 'Spain' },
  { key: 'lk', text: 'Sri Lanka', value: 'Sri Lanka' },
  { key: 'sd', text: 'Sudan', value: 'Sudan' },
  { key: 'sr', text: 'Suriname', value: 'Suriname' },
  { key: 'sj', text: 'Svalbard and Jan Mayen', value: 'Svalbard and Jan Mayen' },
  { key: 'sz', text: 'Swaziland', value: 'Swaziland' },
  { key: 'se', text: 'Sweden', value: 'Sweden' },
  { key: 'ch', text: 'Switzerland', value: 'Switzerland' },
  { key: 'sy', text: 'Syrian Arab Republic', value: 'Syrian Arab Republic' },
  { key: 'tw', text: 'Taiwan, Province of China', value: 'Taiwan, Province of China' },
  { key: 'tj', text: 'Tajikistan', value: 'Tajikistan' },
  { key: 'tz', text: 'Tanzania, United Republic of', value: 'Tanzania, United Republic of' },
  { key: 'th', text: 'Thailand', value: 'Thailand' },
  { key: 'tl', text: 'Timor-Leste', value: 'Timor-Leste' },
  { key: 'tg', text: 'Togo', value: 'Togo' },
  { key: 'tk', text: 'Tokelau', value: 'Tokelau' },
  { key: 'to', text: 'Tonga', value: 'Tonga' },
  { key: 'tt', text: 'Trinidad and Tobago', value: 'Trinidad and Tobago' },
  { key: 'tn', text: 'Tunisia', value: 'Tunisia' },
  { key: 'tr', text: 'Turkey', value: 'Turkey' },
  { key: 'tm', text: 'Turkmenistan', value: 'Turkmenistan' },
  { key: 'tc', text: 'Turks and Caicos Islands', value: 'Turks and Caicos Islands' },
  { key: 'tv', text: 'Tuvalu', value: 'Tuvalu' },
  { key: 'ug', text: 'Uganda', value: 'Uganda' },
  { key: 'ua', text: 'Ukraine', value: 'Ukraine' },
  { key: 'ae', text: 'United Arab Emirates', value: 'United Arab Emirates' },
  { key: 'gb', text: 'United Kingdom', value: 'United Kingdom' },
  { key: 'us', text: 'United States', value: 'United States' },
  { key: 'um', text: 'United States Minor Outlying Islands', value: 'United States Minor Outlying Islands' },
  { key: 'uy', text: 'Uruguay', value: 'Uruguay' },
  { key: 'uz', text: 'Uzbekistan', value: 'Uzbekistan' },
  { key: 'vu', text: 'Vanuatu', value: 'Vanuatu' },
  { key: 've', text: 'Venezuela, Bolivarian Republic of', value: 'Venezuela, Bolivarian Republic of' },
  { key: 'vn', text: 'Viet Nam', value: 'Viet Nam' },
  { key: 'vg', text: 'Virgin Islands, British', value: 'Virgin Islands, British' },
  { key: 'vi', text: 'Virgin Islands, U.S.', value: 'Virgin Islands, U.S.' },
  { key: 'wf', text: 'Wallis and Futuna', value: 'Wallis and Futuna' },
  { key: 'eh', text: 'Western Sahara', value: 'Western Sahara' },
  { key: 'ye', text: 'Yemen', value: 'Yemen' },
  { key: 'zm', text: 'Zambia', value: 'Zambia' },
  { key: 'zw', text: 'Zimbabwe', value: 'Zimbabwe' },
];
