import { axiosProxy } from '../../utils/axiosProxy';
import { actionType } from '../../constants/PackingSheds';

const url = '/packing_sheds';

export const fetchPackingShedsList = () => async dispatch => {
  try {
    dispatch({
      type: actionType.FETCH_PACKING_SHEDS_LIST_START,
    });

    const res = await axiosProxy({
      method: 'GET',
      url: `${url}`,
      params: {
        unpaged: true,
      },
    });

    dispatch({
      type: actionType.FETCH_PACKING_SHEDS_LIST,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: actionType.FETCH_PACKING_SHEDS_LIST_FAIL,
      payload: error,
    });
  }
};

export const fetchPackingSheds = ({ page, size, search, sort, unpaged = false }) => async dispatch => {
  try {
    dispatch({
      type: actionType.FETCH_PACKING_SHEDS_START,
    });

    const params = new URLSearchParams();
    if (unpaged) {
      params.append('unpaged', true);
    } else {
      params.append('page', page);
      params.append('size', size);
    }

    if (search) {
      params.append('search', search);
    }

    (sort || ['id,asc']).forEach(field => {
      params.append('sort', field);
    });

    const res = await axiosProxy({
      method: 'GET',
      params,
      url,
    });

    await dispatch({
      type: actionType.FETCH_PACKING_SHEDS,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.FETCH_PACKING_SHEDS_FAIL,
      payload: error,
    });
  }
};

export const getPackingShedById = id => async dispatch => {
  try {
    dispatch({
      type: actionType.GET_PACKING_SHEDS_BY_ID_START,
    });
    const res = await axiosProxy({
      method: 'GET',
      url: `${url}/${id}`,
    });

    dispatch({
      type: actionType.GET_PACKING_SHEDS_BY_ID,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.GET_PACKING_SHEDS_BY_ID_FAIL,
      payload: error,
    });
    return Promise.reject(error);
  }
};

export const addPackingShed = data => async dispatch => {
  try {
    dispatch({
      type: actionType.ADD_PACKING_SHEDS_START,
    });
    const res = await axiosProxy({
      method: 'POST',
      url,
      data,
    });

    await dispatch({
      type: actionType.ADD_PACKING_SHEDS,
      payload: res.data,
    });

    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.ADD_PACKING_SHEDS_FAIL,
      payload: error,
    });
    return { error };
  }
};

export const updatePackingShed = (id, data) => async dispatch => {
  try {
    dispatch({
      type: actionType.UPDATE_PACKING_SHEDS_START,
    });
    const res = await axiosProxy({
      method: 'PUT',
      data,
      url: `${url}/${id}`,
    });

    await dispatch({
      type: actionType.UPDATE_PACKING_SHEDS,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.UPDATE_PACKING_SHEDS_FAIL,
      payload: error,
    });
    return { error };
  }
};

export const deletePackingShed = id => async dispatch => {
  try {
    dispatch({
      type: actionType.DELETE_PACKING_SHEDS_START,
    });
    await axiosProxy({
      method: 'DELETE',
      url: `${url}/${id}`,
    });

    await dispatch({
      type: actionType.DELETE_PACKING_SHEDS,
      payload: id,
    });
  } catch (error) {
    dispatch({
      type: actionType.DELETE_PACKING_SHEDS_FAIL,
      payload: error,
    });
  }
};

export const clearError = () => dispatch => {
  dispatch({
    type: actionType.CLEAR_PACKING_SHEDS_ERROR,
  });
};
