import React from "react";
import PropTypes from "prop-types";
import styles from "./Filters.module.css";
import { Button } from "semantic-ui-react";

const ClearButton = ({ onClick, className }) => {
  return (
    <span className={`${className} ${styles.clearHolder}`}>
      <Button className="button-text" content="Clear All" onClick={onClick} />
    </span>
  );
};

ClearButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.any
};
export default ClearButton;
