import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Form } from "semantic-ui-react";
import { Input } from "formsy-semantic-ui-react";

import styles from "./SwitchButton.module.css";

const SwitchButton = ({
  onChange,
  matter,
  value,
  items,
  title,
  name,
  required,
  disabled,
  validation,
  validationErrors,
  width,
  errorLabel
}) => {
  useEffect(() => {
    matter === "SOLID" &&
      (value == "milliliter" || value == "liter") &&
      onChange(name, "gram");
    matter === "LIQUID" &&
      (value == "gram" || value == "kilogram") &&
      onChange(name, "milliliter");
  }, [matter]);
  return (
    <>
      <Form.Field required={required} width={width}>
        {title && <label>{title}</label>}
        <Button.Group basic className={styles.buttonsGroup} fluid>
          {validation && (
            <Input
              name={name}
              value={value}
              type={"hidden"}
              validation={validation}
              validationErrors={validationErrors}
              errorLabel={errorLabel}
              required={required}
            />
          )}
          {items.map(item => {
            return (
              <Button
                key={item.value}
                type="button"
                onClick={() => onChange(name, item.value)}
                className={value === item.value ? styles.buttonActive : null}
                disabled={disabled}
                basic
              >
                {item.label}
              </Button>
            );
          })}
        </Button.Group>
      </Form.Field>
    </>
  );
};

SwitchButton.propTypes = {
  name: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ),
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  validation: PropTypes.string,
  value: PropTypes.string,
  title: PropTypes.string,
  validationErrors: PropTypes.object,
  errorLabel: PropTypes.object
};

SwitchButton.defaultProps = {
  title: "\u00A0",
  required: false,
  disabled: false,
  validation: null,
  validationErrors: null,
  errorLabel: null
};

export default SwitchButton;
