import PropTypes from "prop-types";
import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHeader as Header,
  TableHeaderCell,
  TableRow
} from "semantic-ui-react";
import TaskSummaryRow from "./TaskSummaryRow";
import { sumBy } from "lodash";
import Numeric from "../../Numeric";
import { minutesToHM } from "../../../utils/timeUtils";
import styles from "../index.module.css";

const TasksTable = ({ showDetailedInfo, combinedTasks }) => {
  const totalMachineryCost = sumBy(combinedTasks, "totalMachineryCost");
  const totalLabourCost = sumBy(combinedTasks, "totalLabourCost");
  const totalAltLabourCost = sumBy(combinedTasks, "totalAltLabourCost");
  const totalWorkingTime = sumBy(combinedTasks, "totalWorkingTimeInMinutes");
  const costPerAreaPerTask = sumBy(combinedTasks, "costPerAreaPerTask");
  const totalWorkingTimesMins = sumBy(
    combinedTasks,
    "totalWorkingTimeInMinutes"
  );

  return (
    <div className={styles.breakdownTableContainer}>
      <Table unstackable>
        <Header>
          <TableRow>
            <TableHeaderCell style={{ width: 30 }} />
            <TableHeaderCell>Task Type</TableHeaderCell>
            <TableHeaderCell>Sub Task</TableHeaderCell>
            <TableHeaderCell>Tasks Completed</TableHeaderCell>
            <TableHeaderCell>Total Working Time</TableHeaderCell>
            <TableHeaderCell>TimeSpend /Task/Ha</TableHeaderCell>
            <TableHeaderCell>Machinery Cost</TableHeaderCell>
            <TableHeaderCell>Labour Cost</TableHeaderCell>
            <TableHeaderCell> Alt Labour Cost</TableHeaderCell>
            <TableHeaderCell>Cost ($)/Ha/Task</TableHeaderCell>
            <TableHeaderCell>Total Costs ($)</TableHeaderCell>
          </TableRow>
        </Header>
        <TableBody>
          {combinedTasks.map((taskSummary, i) => (
            <TaskSummaryRow
              taskSummary={taskSummary}
              key={`item_${i}`}
              showDetailedInfo={showDetailedInfo}
            />
          ))}
          <TableRow className={styles.breakdownTableContainerTotals}>
            <TableCell>
              <b>Total</b>
            </TableCell>
            <TableCell />
            <TableCell />
            <TableCell>{sumBy(combinedTasks, "tasksList.length")}</TableCell>
            <TableCell>{minutesToHM(totalWorkingTime)}</TableCell>
            <TableCell>
              <Numeric
                value={totalWorkingTimesMins || 0}
                fractionDigits={2}
                commaSeparatorOnThousands
              />
            </TableCell>
            <TableCell>
              <Numeric
                fractionDigits={2}
                value={totalMachineryCost}
                commaSeparatorOnThousands
                units="$ "
                unitsPosition="left"
              />
            </TableCell>
            <TableCell>
              <Numeric
                fractionDigits={2}
                value={totalLabourCost}
                commaSeparatorOnThousands
                units="$ "
                unitsPosition="left"
              />
            </TableCell>
            <TableCell>
              <Numeric
                fractionDigits={2}
                value={totalAltLabourCost}
                commaSeparatorOnThousands
                units="$ "
                unitsPosition="left"
              />
            </TableCell>
            <TableCell>
              <Numeric
                fractionDigits={2}
                value={costPerAreaPerTask}
                commaSeparatorOnThousands
                units="$ "
                unitsPosition="left"
              />
              /Ha
            </TableCell>
            <TableCell>
              <Numeric
                fractionDigits={2}
                value={
                  totalMachineryCost + totalLabourCost + totalAltLabourCost
                }
                commaSeparatorOnThousands
                units="$ "
                unitsPosition="left"
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

TasksTable.propTypes = {
  combinedTasks: PropTypes.array,
  showDetailedInfo: PropTypes.bool,
  sortBy: PropTypes.object
};

TasksTable.defaultProps = {
  showDetailedInfo: true,
  taskSummaries: []
};

export default TasksTable;
