import React, { useEffect, useMemo, useState } from "react";
import ReactDOMServer from "react-dom/server";
import {
  Button,
  Grid,
  Dropdown,
  Header,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableHeaderCell,
  TableRow
} from "semantic-ui-react";
import classNames from "classnames";
import _ from "lodash";
import DatePicker from "../DatePicker";
import SegmentClosable from "../SegmentClosable";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader";
import BlockStat from "./components/BlockStat";
import { generatePickedBinsReport } from "../../actions/GeneralBarcodes/generalBarcodes";
import { fetchBinsPerArea } from "../../actions/Dashboard";
import ExportToExcel from "../ExportToExcel";
import moment from "moment";
import styles from "./PickedBinsReport.module.css";
import { copyStyles } from "../../utils/styles";
import PrintReport from "./components/PrintReport";
import ToolTip from "../ToolTip";
import { generalTaskOptionsSelector } from "selectors/generalTasks";

const columnsConfig = [
  {
    id: "dateFrom",
    accessor: "dateFrom",
    Header: "Date From"
  },
  {
    id: "dateTo",
    accessor: "dateTo",
    Header: "Date To"
  },
  {
    id: "farmName",
    accessor: "farmName",
    Header: "Farm"
  },
  {
    id: "blockName",
    accessor: "blockName",
    Header: "Block"
  },
  {
    id: "patchName",
    accessor: "patchName",
    Header: "Patch"
  },
  {
    id: "bins",
    accessor: "bins",
    Header: "Bins Picked"
  },
  {
    id: "cost",
    accessor: "cost",
    Header: "Cost($)"
  },
  {
    id: "costRatioToBins",
    accessor: "costRatioToBins",
    Header: "Cost/Bin($/Bin)"
  }
];

const getExcelReport = (pickedBinsReport, dateFrom, dateTo) =>
  pickedBinsReport
    .map(farmStat => {
      const base = {
        dateFrom: moment(dateFrom).format("DD-MM-YYYY"),
        dateTo: moment(dateTo).format("DD-MM-YYYY"),
        farmName: farmStat.farmName,
        blockName: null,
        patchName: "-",
        bins: 0,
        cost: "-",
        costRatioToBins: "-"
      };

      const areas = [];

      Object.values(farmStat.blocksStat).forEach(block => {
        const blockAreas = Object.values(block.areasStat);
        if (blockAreas.length > 0)
          blockAreas.forEach(({ areaName, bins, cost }) => {
            areas.push({
              ...base,
              blockName: block.blockName,
              patchName: areaName,
              bins,
              cost: _.round(cost, 2),
              costRatioToBins: _.round(cost / bins, 2)
            });
          });
        if (blockAreas.length === 0)
          areas.push({ ...base, blockName: block.blockName, bins: block.bins });
      });

      return areas;
    })
    .flat();

const PickedBinsReport = () => {
  const dispatch = useDispatch();
  const [dateFrom, setDateFrom] = useState(moment().format("YYYY-MM-DD"));
  const [dateTo, setDateTo] = useState(moment().format("YYYY-MM-DD"));
  const [selectedTaskTypes, setSelectedTaskTypes] = useState(["Harvest"]);

  const { binsPerArea, isReportGenerating } = useSelector(
    state => state.dashboard
  );
  const taskTypes = useSelector(generalTaskOptionsSelector);

  const refresh = () =>
    dispatch(fetchBinsPerArea(selectedTaskTypes, dateFrom, dateTo));

  useEffect(() => {
    if (selectedTaskTypes.length) {
      refresh();
    }
  }, [selectedTaskTypes, dateTo, dateFrom]);

  const totalBins = Object.values(binsPerArea).reduce(
    (acc, farmStat) => acc + farmStat.bins,
    0
  );
  const totalCost = Object.values(binsPerArea).reduce(
    (acc, farmStat) => acc + farmStat.cost,
    0
  );
  const totalCostRatioToBins = totalCost / totalBins;
  const excelReport = useMemo(
    () => getExcelReport(Object.values(binsPerArea), dateFrom, dateTo),
    [binsPerArea, dateFrom, dateTo]
  );

  const print = () => {
    const newWindow = window.open();
    newWindow.document.title = `Bins Picked Report`;
    copyStyles(window.document, newWindow.document);

    newWindow.document.body.innerHTML = ReactDOMServer.renderToString(
      <PrintReport
        startDate={dateFrom}
        endDate={dateTo}
        totalBinsCount={totalBins}
        totalCostCount={_.round(totalCost, 2)}
        totalCostRatioToBinsCount={_.round(totalCostRatioToBins, 2)}
        pickedBins={binsPerArea}
      />
    );
    newWindow.print();
  };

  return (
    <SegmentClosable
      title={`Bins Picked (${totalBins} bins total)`}
      headerLineClassName={styles.segmentHeader}
    >
      <Grid>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={10} computer={3}>
            <DatePicker
              value={dateFrom}
              onChange={date => setDateFrom(date)}
              labelText={"From"}
              disableClear
            />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={10} computer={3}>
            <DatePicker
              value={dateTo}
              onChange={date => setDateTo(date)}
              labelText={"To"}
              disableClear
            />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={10} computer={5}>
            <label>Task Type</label>
            <Dropdown
              multiple
              fluid
              options={taskTypes}
              value={selectedTaskTypes}
              onChange={(e, data) => setSelectedTaskTypes(data.value)}
              scrolling
              className={classNames(styles.taskTypesFilter, {
                [styles.taskTypesFilterError]: !selectedTaskTypes.length
              })}
            />
            {!selectedTaskTypes.length && (
              <span style={{ color: "#b00020" }}>Field is required</span>
            )}
          </Grid.Column>
          <Grid.Column
            mobile={16}
            tablet={10}
            computer={2}
            style={{ paddingTop: 34 }}
          >
            <ExportToExcel
              columns={columnsConfig}
              rawData={[
                ...excelReport,
                {
                  patchName: "TOTAL",
                  bins: totalBins,
                  cost: _.round(totalCost, 2),
                  costRatioToBins: _.round(totalCostRatioToBins, 2)
                }
              ]}
              name={`Bins Picked (${totalBins} bins total)`}
            />
            <ToolTip content="Export to PDF/Print">
              <Icon
                style={{ marginLeft: 10, cursor: "pointer" }}
                name="print"
                onClick={print}
              />
            </ToolTip>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={6}>
            {isReportGenerating ? (
              <Loader />
            ) : (
              Object.values(binsPerArea).map((farmStat, i) => (
                <div key={`farm-stat-${i}`} style={{ marginBottom: 20 }}>
                  <Header as={"h3"}>{farmStat.farmName}</Header>
                  <Table unstackable>
                    <Table.Header>
                      <TableRow>
                        <TableHeaderCell />
                        <TableHeaderCell>Block Name</TableHeaderCell>
                        <TableHeaderCell>Bins Picked</TableHeaderCell>
                        <TableHeaderCell>Cost($)</TableHeaderCell>
                        <TableHeaderCell>Cost/Bin($/Bin)</TableHeaderCell>
                      </TableRow>
                    </Table.Header>
                    <TableBody>
                      {Object.values(farmStat.blocksStat).map(
                        (blockStat, i) => (
                          <BlockStat
                            blockStat={blockStat}
                            key={`block-stat-${i}`}
                          />
                        )
                      )}
                      <TableRow>
                        <TableCell>
                          <b>Total</b>
                        </TableCell>
                        <TableCell />
                        <TableCell>
                          <b>{farmStat.bins}</b>
                        </TableCell>
                        <TableCell>
                          <b>${_.round(farmStat.cost, 2)}</b>
                        </TableCell>
                        <TableCell>
                          <b>${_.round(farmStat.cost / farmStat.bins, 2)}</b>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              ))
            )}
            {!isReportGenerating && Object.values(binsPerArea).length === 0 && (
              <Grid.Column>
                <Header as={"h3"} style={{ textAlign: "center" }}>
                  No picked bins for selected date range
                </Header>
              </Grid.Column>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </SegmentClosable>
  );
};

export default PickedBinsReport;
