import PropTypes from "prop-types";
import React from "react";

import styles from "./CustomGeneralBarcodesPrintLayout.module.css";
import { generateBarcodeSeries } from "../../../utils/generalBarcodes";
import GeneralBarcode from "../components/GeneralBarcode";
import QRCode from "qrcode.react";
import GeneralBarcodeLargeArea from "../components/GeneralBarcodeLargeArea";

const CustomGeneralBarcodesPrintLayout = ({
  labels,
  series,
  itemsInPage,
  className,
  smallBarcode,
  barcodeStyle,
  pageBreakStyles,
  fullHeight,
  onlyQR,
  minimized,
  pageStyle,
  qrHeight,
  qrWidth,
  largeAreaLayout
}) => {
  const barcodes = generateBarcodeSeries(
    labels,
    series.firstBarcode,
    series.lastBarcode
  );
  const itemsInPageRange = [...Array(itemsInPage).keys()];

  return (
    <div className={`${styles.block} ${className} block`}>
      <style>{`${pageStyle} html,body {margin: 0px;}`}</style>
      <div className={`${styles.page} page`}>
        {Array.from(Array(barcodes.length).keys())
          .filter(i => i % itemsInPage === 0)
          .map(pageOffset => (
            <>
              <div
                key={`page_${pageOffset}`}
                className={`${pageBreakStyles || styles.pageBreak} pageBreak`}
              >
                {itemsInPageRange.map(itemOffset => {
                  const i = pageOffset + itemOffset;
                  const barcode = barcodes[i];
                  return (
                    i < barcodes.length &&
                    (smallBarcode || onlyQR ? (
                      <div
                        key={`bc_${i}`}
                        className={`${styles.barcodeHolder} barcodeHolder`}
                      >
                        <div
                          key={`bc_${i}`}
                          className={`${styles.barcodeDataHolder} barcodeDataHolder`}
                        >
                          <QRCode
                            renderAs="svg"
                            className={`${barcodeStyle ||
                              styles.barcode} barcode`}
                            value={barcode.numberPart}
                          />
                          {onlyQR || (
                            <div className={`${styles.textHolder} textHolder`}>
                              <div className={`${styles.text} text`}>
                                {barcode.numberPart}
                              </div>
                              <div className={`${styles.text} text`}>
                                {barcode.dateLabel}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : largeAreaLayout ? (
                      <GeneralBarcodeLargeArea barcode={barcode} />
                    ) : (
                      <GeneralBarcode
                        barcode={barcode}
                        minimized={minimized}
                        fullHeight={fullHeight}
                        qrHeight={qrHeight}
                        qrWidth={qrWidth}
                      />
                    ))
                  );
                })}
              </div>
              <p style={{ pageBreakBefore: "always" }} />
            </>
          ))}
      </div>
    </div>
  );
};

CustomGeneralBarcodesPrintLayout.propTypes = {
  barcodeStyle: PropTypes.any,
  className: PropTypes.string,
  fullHeight: PropTypes.bool,
  itemsInPage: PropTypes.number,
  labels: PropTypes.object,
  minimized: PropTypes.bool,
  onlyQR: PropTypes.bool,
  pageBreakStyles: PropTypes.any,
  pageStyle: PropTypes.any,
  qrHeight: PropTypes.number,
  qrWidth: PropTypes.number,
  series: PropTypes.object,
  smallBarcode: PropTypes.any,
  largeAreaLayout: PropTypes.bool
};

CustomGeneralBarcodesPrintLayout.defaultProps = {
  qrHeight: 130,
  qrWidth: 130,
  largeAreaLayout: false
};

export default CustomGeneralBarcodesPrintLayout;
