import React, { useCallback } from "react";
import PropTypes from "prop-types";
import GoogleMaps from "components/GoogleMaps";
import { get } from "lodash";
import { scoutingShape } from "constants/Scouting/types";
import { DEFAULT_LOCATION } from "constants/Scouting";
import markerIcon from "assets/img/issue_with_attachment_marker.svg";

const MAP_OPTIONS = {
  streetViewControl: false,
  mapTypeControlOptions: { style: 1, position: 11 },
  mapTypeId: "satellite",
  maxZoom: 21
};

const getCurrentLatLng = (attachmentMarkers = []) => {
  const firstAttachmentPosition = get(attachmentMarkers[0], "position");

  if (firstAttachmentPosition) {
    return firstAttachmentPosition;
  }

  return { lat: DEFAULT_LOCATION[0], lng: DEFAULT_LOCATION[1] };
};

const ScoutingSidebarMap = ({ scoutingData, onIssueAttachmentClick }) => {
  const attachmentMarkers = scoutingData.areas.reduce((acc, item) => {
    const { areaIssues, area } = item;

    if (areaIssues) {
      areaIssues.forEach(issue => {
        const { attachments } = issue;

        if (attachments) {
          attachments.forEach(attachment => {
            const { id, coordinates } = attachment;

            if (coordinates) {
              const [lat, lng] = coordinates;

              acc.push({
                id,
                icon: {
                  url: markerIcon
                },
                position: { lat, lng },
                onClick: () => onIssueAttachmentClick(area, issue, attachment)
              });
            }
          });
        }
      });
    }

    return acc;
  }, []);
  const currentLatLng = getCurrentLatLng(attachmentMarkers);
  const onClustererClick = useCallback(
    ({ map, markers }) => {
      const position = get(markers[0], "position");

      if (position && map.getZoom() === MAP_OPTIONS.maxZoom) {
        const anyAttachmentMarker = attachmentMarkers.find(attachment => {
          const { lat, lng } = attachment.position;

          return lat === position.lat() && lng === position.lng();
        });

        if (anyAttachmentMarker && anyAttachmentMarker.onClick) {
          anyAttachmentMarker.onClick();
        }
      }
    },
    [attachmentMarkers]
  );
  return (
    <GoogleMaps
      zoom={16}
      currentLatLng={currentLatLng}
      mapOptions={MAP_OPTIONS}
      drawingDisabled={true}
      showDrawing={false}
      markers={attachmentMarkers}
      showMap={true}
      showInfoWindow={true}
      popUpType={null}
      onClustererClick={onClustererClick}
      withClustering
    />
  );
};

ScoutingSidebarMap.propTypes = {
  scoutingData: scoutingShape,
  onIssueAttachmentClick: PropTypes.func
};

export default ScoutingSidebarMap;
