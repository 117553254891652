import PropTypes from "prop-types";
import React, { useRef, useEffect, useMemo } from "react";
import moment from "moment";
import { Button, Grid, Segment } from "semantic-ui-react";
import { Form, Input } from "formsy-semantic-ui-react";
import AddNewModal from "../../../../components/AddNewModal";
import SelectEmployee from "../../../../components/SelectEmployee";
import { updateWorkingTime } from "../../../../actions/GeneralTasksWorkingTimes/generalTasksWorkingTimes";
import { useDispatch, useSelector } from "react-redux";
import DateTimePicker from "../../../../components/DateTimePicker";
import SelectEmployeeGroup from "../../../../components/SelectEmployeeGroup";
import SelectContractor from "../../../../components/SelectContractor";
import { useFormik } from "formik";
import { WorkingTime } from "../../../../models/working_time.model";
import { farmsWithAreas } from "selectors/farms";
import { areaList } from "selectors/areas";
import "./dropdownTree.css";
import DropdownTreeSelect from "react-dropdown-tree-select";
import "react-dropdown-tree-select/dist/styles.css";

const WorkingTimeEditModal = ({
  open,
  onClose,
  workingTime,
  showPieceRateFields
}) => {
  const { isUpdating } = useSelector(state => state.generalTasksWorkingTimes);
  const dispatch = useDispatch();
  const areasData = useSelector(areaList);

  let result = areasData.options.filter(area =>
    workingTime.areas.some(({ areaId }) => area.id == areaId)
  );

  const form = useFormik({
    initialValues: {
      generalTaskId: workingTime.generalTaskId,
      contractorId: workingTime.contractorId,
      employeeId: workingTime.employeeId,
      employeesGroupId: workingTime.employeesGroupId,
      lunchInterval: workingTime.lunchInterval,
      endAt: workingTime.endAt,
      startAt: workingTime.startAt,
      pieceRate: workingTime.pieceRate,
      piecesPerEmployee: workingTime.piecesPerEmployee,
      areas: workingTime.areas.map(item => ({
        areaId: item.areaId
      })),
      areaData: {
        expandedFarmsIds: [],
        expandedAreasIds: [],
        selectedAreas: result,
        treeSelectSearchFilter: ""
      }
    },
    onSubmit: ({ areaData, ...formData }) => {
      const startAt =
        moment(formData.startAt).format("YYYY-MM-DDTHH:mm") + ":00";
      const endAt = moment(formData.endAt).format("YYYY-MM-DDTHH:mm") + ":00";
      const areas = areaData.selectedAreas.map(({ id, name }) => ({
        areaId: id
      }));
      dispatch(
        updateWorkingTime(workingTime.id, {
          ...formData,
          areas,
          startAt,
          endAt
        })
      )
        .then(() => {
          form.resetForm();
        })
        .finally(() => {
          onClose();
        });
    },
    validationSchema: WorkingTime.validationSchema
  });

  const data = form.values.areaData;
  const selectTreeRef = useRef();
  const selectTreeCustomBtnsContainerRef = useRef();
  const farmsWithAreasList = useSelector(farmsWithAreas);
  const setFieldValue = (field, value) => {
    form.setFieldValue(`areaData[${field}]`, value);
  };
  const areasList = useSelector(farmsWithAreas)
    .map(({ areas }) => areas)
    .flat();

  useEffect(() => {
    if (selectTreeRef.current) {
      const mutationCallback = mutationsList => {
        for (const mutation of mutationsList) {
          if (
            mutation.type === "attributes" &&
            mutation.attributeName === "aria-expanded"
          ) {
            if (mutation.target.getAttribute("aria-expanded") === "true") {
              const div = document.createElement("div");
              div.innerText = "Select ";

              const span1 = document.createElement("span");
              span1.innerText = "All";
              span1.className = "select-btn select-all";
              span1.onclick = handleSelectAll(true);

              const separator = document.createElement("span");
              separator.innerText = " / ";

              const span2 = document.createElement("span");
              span2.innerText = "None";
              span2.className = "select-btn select-none";
              span2.onclick = handleSelectAll(false);

              div.className = "custom-buttons-container";
              div.appendChild(span1);
              div.appendChild(separator);
              div.appendChild(span2);

              selectTreeRef.current.node
                .querySelector("div.dropdown-content")
                .prepend(div);
              selectTreeCustomBtnsContainerRef.current = div;

              const dropdownContent = selectTreeRef.current.node.querySelector(
                "div.dropdown-content"
              );
              const searchInput = dropdownContent.querySelector("input.search");
              const input = document.createElement("input");
              input.type = "text";
              input.placeholder = "Search Block and Patch...";
              input.className = "search custom";
              input.oninput = e => {
                const val = e.target.value.trim();

                if (!val) {
                  setFieldValue("expandedFarmsIds", []);
                  setFieldValue("expandedAreasIds", []);
                }

                setFieldValue("treeSelectSearchFilter", val);

                if (selectTreeCustomBtnsContainerRef.current) {
                  selectTreeCustomBtnsContainerRef.current.style.display = val
                    ? "none"
                    : "block";
                }
              };
              input.oncut = () => {
                if (selectTreeCustomBtnsContainerRef.current) {
                  selectTreeCustomBtnsContainerRef.current.style.display =
                    "block";
                }
                setFieldValue("expandedFarmsIds", []);
                setFieldValue("expandedAreasIds", []);
                setFieldValue("treeSelectSearchFilter", "");
              };

              searchInput.replaceWith(input);
              input.focus();
            } else {
              setFieldValue("expandedFarmsIds", []);
              setFieldValue("expandedAreasIds", []);
              setFieldValue("treeSelectSearchFilter", "");
            }
          }
        }
      };

      const observer = new MutationObserver(mutationCallback);
      const searchInputContainer = selectTreeRef.current.node.querySelector(
        ".dropdown-trigger"
      );
      observer.observe(searchInputContainer, {
        attributes: true,
        attributeOldValue: true
      });
    }
  }, [selectTreeRef]);

  const dataList = useMemo(() => {
    return farmsWithAreasList
      .map(({ farm, areas }) => {
        const filteredAreas = areas.filter(a => !a.archived);
        const groupedAreas = filteredAreas.reduce((prev, current) => {
          if (current.parent) {
            const prevParentIndex = prev.findIndex(
              block => block.id === current.parent.id
            );
            if (prevParentIndex > -1) {
              const prevNew = [...prev];
              prevNew[prevParentIndex] = {
                ...prevNew[prevParentIndex],
                label: current.parent.name,
                value: [...prevNew[prevParentIndex].value, current],
                type: "block",
                children: [
                  ...prevNew[prevParentIndex].children,
                  {
                    id: current.id,
                    farmId: farm.id,
                    blockId: current.parent.id,
                    label: current.name,
                    value: [current],
                    checked: !!data.selectedAreas?.find(
                      area => area.id === current.id
                    ),
                    className: data.treeSelectSearchFilter
                      ? !current.name.includes(data.treeSelectSearchFilter)
                        ? "notFitSearch"
                        : "fitSearch"
                      : "",
                    type: "patch"
                  }
                ]
              };
              return prevNew;
            }
            return [
              ...prev,
              {
                id: current.parent.id,
                farmId: farm.id,
                label: current.parent.name,
                value: [current],
                expanded:
                  data.expandedAreasIds?.includes(current.parent.id) ||
                  data.treeSelectSearchFilter,
                className: data.treeSelectSearchFilter ? "notFitSearch" : "",
                type: "block",
                children: [
                  {
                    id: current.id,
                    farmId: farm.id,
                    blockId: current.parent.id,
                    label: current.name,
                    value: [current],
                    checked: !!data.selectedAreas?.find(
                      area => area.id === current.id
                    ),
                    className: data.treeSelectSearchFilter
                      ? !current.name?.includes(data.treeSelectSearchFilter)
                        ? "notFitSearch"
                        : "fitSearch"
                      : "",
                    type: "patch"
                  }
                ]
              }
            ];
          }
          if (prev.findIndex(block => block.id === current.id) === -1) {
            return [
              ...prev,
              {
                id: current.id,
                farmId: farm.id,
                label: current.name,
                value: current.hasSubAreas ? [] : [current],
                expanded:
                  data.expandedAreasIds?.includes(current.id) ||
                  data.treeSelectSearchFilter,
                className: data.treeSelectSearchFilter
                  ? current.hasSubAreas ||
                    !current.name.includes(data.treeSelectSearchFilter)
                    ? "notFitSearch"
                    : "fitSearch"
                  : "",
                type: "block",
                children: []
              }
            ];
          }
          return prev;
        }, []);
        const checkedAreas = groupedAreas
          .map(area => ({
            ...area,
            children: area.children.sort((p1, p2) =>
              p1.label.localeCompare(p2.label)
            ),
            checked:
              area.children && area.children.length
                ? area.children.every(patch => patch.checked)
                : data.selectedAreas?.find(a => a.id === area.id)
          }))
          .sort((b1, b2) => b1.label.localeCompare(b2.label));
        return {
          farmId: farm.id,
          label: farm.name,
          value: checkedAreas.map(area => area.value).flat(),
          checked:
            checkedAreas.length && checkedAreas.every(block => block.checked),
          expanded:
            data.expandedFarmsIds?.includes(farm.id) ||
            data.treeSelectSearchFilter,
          ...(data.treeSelectSearchFilter && { className: "notFitSearch" }),
          type: "farm",
          children: checkedAreas
        };
      })
      .sort((farm1, farm2) => farm1.label.localeCompare(farm2.label));
  }, [data]);

  const handleSelectAll = isChecked => () => {
    const [
      selectAllBtn,
      selectNoneBtn
    ] = selectTreeCustomBtnsContainerRef.current.querySelectorAll(
      ".select-btn"
    );

    if (isChecked) {
      selectAllBtn.classList.add("active");
      selectNoneBtn.classList.remove("active");
    } else {
      selectAllBtn.classList.remove("active");
      selectNoneBtn.classList.add("active");
    }

    const selectedAreas = dataList.map(area => area.value).flat();
    setFieldValue(
      "farms",
      isChecked
        ? [
            ...new Map(
              selectedAreas.map(area => [area.farm.id, area.farm])
            ).values()
          ]
        : []
    );
    setFieldValue("selectedAreas", isChecked ? selectedAreas : []);
    setFieldValue("treeSelectSearchFilter", "");
  };

  const onChange = (_, selectedNodes) => {
    const selectedAreas = selectedNodes.map(area => area.value).flat();
    setFieldValue("selectedAreas", selectedAreas);
  };

  const onNodeToggle = currentNode => {
    if (currentNode.type === "farm") {
      const expandedFarmsIdsNew = data.expandedFarmsIds.includes(
        currentNode.farmId
      )
        ? data.expandedFarmsIds.filter(id => id !== currentNode.farmId)
        : [...data.expandedFarmsIds, currentNode.farmId];
      const expandedAreasIdsNew = data.expandedAreasIds.filter(areaId => {
        const foundArea = areasList.find(a => a.id === areaId);
        if (foundArea) {
          return expandedFarmsIdsNew.includes(foundArea.farm.id);
        }
        return false;
      });
      setFieldValue("expandedFarmsIds", expandedFarmsIdsNew);
      setFieldValue("expandedAreasIds", expandedAreasIdsNew);
    } else if (currentNode.type === "block") {
      const expandedAreasIdsNew = data.expandedAreasIds.includes(currentNode.id)
        ? data.expandedAreasIds.filter(id => id !== currentNode.id)
        : [...data.expandedAreasIds, currentNode.id];
      setFieldValue("expandedAreasIds", expandedAreasIdsNew);
    }
  };
  return (
    <AddNewModal
      open={open}
      title="Working Time edit"
      formToShow={
        <Form loading={isUpdating}>
          <Segment basic style={{ width: "100%" }}>
            <Grid>
              <Grid.Row columns={1}>
                <Grid.Column>
                  <Form.Field required className="sprayField">
                    <label>Employee:</label>
                    <SelectEmployee
                      name={"employeeId"}
                      onChange={(...args) =>
                        form.handleChange({ target: args[1] })
                      }
                      value={form.values.employeeId}
                    />
                  </Form.Field>
                  <br />
                  <Form.Field className="sprayField">
                    <label>Employee Group:</label>
                    <SelectEmployeeGroup
                      name={"employeesGroupId"}
                      clearable
                      onChange={(...args) =>
                        form.handleChange({ target: args[1] })
                      }
                      value={form.values.employeesGroupId}
                    />
                  </Form.Field>
                  <br />
                  <Form.Field className="sprayField">
                    <label>Contractor:</label>
                    <SelectContractor
                      clearable
                      name={"contractorId"}
                      onChange={(...args) =>
                        form.handleChange({ target: args[1] })
                      }
                      value={form.values.contractorId}
                    />
                  </Form.Field>
                  <br />
                  <Form.Field className="sprayField">
                    <label>
                      Blocks / Patches:
                      <span style={{ color: "#DB2828" }}>＊</span>
                    </label>
                    <Grid.Row>
                      <Grid.Column>
                        <DropdownTreeSelect
                          ref={selectTreeRef}
                          data={dataList}
                          className="dropdownTree"
                          texts={{ placeholder: " " }}
                          showPartiallySelected
                          keepOpenOnSelect
                          keepTreeOnSearch
                          keepChildrenOnSearch
                          clearSearchOnChange
                          inlineSearchInput
                          onChange={onChange}
                          onNodeToggle={onNodeToggle}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Form.Field>
                  <br />
                  <Form.Field required className="sprayField">
                    <label>Clocked In at:</label>
                    <DateTimePicker
                      value={form.values.startAt}
                      onChange={form.handleChange}
                      name={"startAt"}
                    />
                  </Form.Field>
                  <br />
                  <Form.Field required className="sprayField">
                    <label>Clocked Out at:</label>
                    <DateTimePicker
                      value={form.values.endAt}
                      onChange={form.handleChange}
                      name={"endAt"}
                    />
                    <small style={{ color: "#ea5151" }}>
                      {form.errors.endAt}
                    </small>
                  </Form.Field>
                  <br />
                  <Form.Field required className="sprayField">
                    <label>Lunch Interval:</label>
                    <Input
                      style={{ height: "100%" }}
                      name={"lunchInterval"}
                      value={form.values.lunchInterval}
                      onChange={(...args) =>
                        form.handleChange({ target: args[1] })
                      }
                      type={"number"}
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              {showPieceRateFields && (
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <Form.Field className="sprayField">
                      <label>Piece number:</label>
                      <Input
                        style={{ height: "100%" }}
                        name={"piecesPerEmployee"}
                        value={form.values.piecesPerEmployee}
                        onChange={(...args) =>
                          form.handleChange({ target: args[1] })
                        }
                        type={"number"}
                      />
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field className="sprayField">
                      <label>Piece Rate ($):</label>
                      <Input
                        style={{ height: "100%" }}
                        name={"pieceRate"}
                        value={form.values.pieceRate}
                        onChange={(...args) =>
                          form.handleChange({ target: args[1] })
                        }
                        type={"number"}
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
              )}
            </Grid>
          </Segment>
        </Form>
      }
      onClose={onClose}
      actions={
        <Button
          disabled={!form.values.areaData.selectedAreas.length || !form.isValid}
          onClick={form.submitForm}
          primary
          size="large"
        >
          Save
        </Button>
      }
    />
  );
};

WorkingTimeEditModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  workingTime: PropTypes.object,
  showPieceRateFields: PropTypes.bool
};

WorkingTimeEditModal.defaultProps = {
  workingTime: {},
  showPieceRateFields: false
};

export default WorkingTimeEditModal;
