export const ROWS_TO_SPRAY = Object.freeze({
  EVEN: "Even",
  ODD: "Odd",
  ALL: "All"
});

export const RATE_TYPES = Object.freeze({
  PER_100_LITERS: "100 L",
  PER_HECTARE: "Ha"
});
