import axios from 'axios';
import { userManager } from '../index';

const API_URL = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`;

const baseUrl = `${API_URL}/notifications`;

export const saveSubscription = async subscription => {
  const user = await userManager.getUser();
  let url = `${baseUrl}/save-subscription`;
  return await axios({
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${user && user.access_token}`,
    },
    url,
    data: subscription,
  });
};
