import React from "react";
import { createSelector } from "reselect";
import { orderBy, uniqBy } from "lodash";
import moment from "moment";
import { Area } from "../models/block.model";
import { farmsOptionsSelector } from "./farms";

/*TODO Apply selectors to desktop component*/
const sprayDiary = state => state.sprayDiary;

const sprayDiaryFilters = state => state.sprayDiary.filters;
const areasList = state => state.sprayDiary.areasList;
const chemicalsList = state => state.sprayDiary.chemicalsList;
const taskNames = state => state.sprayDiary.taskNames;
const employee = state => state.sprayDiary.employee;
const spray = state => state.spray;
const offline = state => state.offline;
const filterList = state => state.sprayDiary.filters;
const selectedAreas = state => state.sprayDiary.selectedAreas;
const plannedDate = state => state.sprayDiary.plannedDate;
const expirationDate = state => state.sprayDiary.expirationDate;
const selectedChemicals = state => state.sprayDiary.selectedChemicals;
const selectedFetchedTasks = state => state.sprayDiary.fetchedTasks;
const selectedTaskId = state => state.sprayDiary.id || state.sprayDiary._id;

const varietyOptionsSelector = createSelector(
  [areasList, filterList],
  (selectAreasList, filters) => {
    const { content } = selectAreasList;
    const selectedCrops = filters.crops || [];
    return content
      ? orderBy(
          uniqBy(
            content.reduce((varieties, area) => {
              return area.varieties
                ? [
                    ...varieties,
                    ...area.varieties
                      .filter(
                        ({
                          variety: {
                            crop: { id }
                          }
                        }) => {
                          if (!selectedCrops.length) return true;
                          return selectedCrops.includes(id);
                        }
                      )
                      .map(variety => ({
                        text: variety.variety.name,
                        key: variety.variety.id,
                        value: variety.variety.id,
                        content: (
                          <>
                            <span>{variety.variety.name}</span>
                            {variety.variety.crop && (
                              <i>{variety.variety.crop.name}</i>
                            )}
                          </>
                        )
                      }))
                  ]
                : varieties;
            }, []),
            "key"
          ),
          [variety => variety.text.toLowerCase()]
        )
      : [];
  }
);

const cropsOptionsSelector = createSelector(
  [areasList, filterList],
  (selectAreasList, filters) => {
    const { content } = selectAreasList;
    const selectedFarms = filters.farms || [];

    return content
      ? orderBy(
          uniqBy(
            content
              .filter(area =>
                selectedFarms.length
                  ? selectedFarms.includes(area.farm.id)
                  : true
              )
              .reduce((varieties, area) => {
                return area.varieties
                  ? [
                      ...varieties,
                      ...area.varieties.map(variety => ({
                        text: variety.variety.crop.name,
                        key: variety.variety.crop.id,
                        value: variety.variety.crop.id
                      }))
                    ]
                  : varieties;
              }, []),
            "key"
          ),
          [variety => variety.text.toLowerCase()]
        )
      : [];
  }
);

const interventionTypesSelector = createSelector([spray], selectSpray => {
  const {
    tasks: { interventionTypes }
  } = selectSpray;

  return interventionTypes
    ? Object.keys(interventionTypes).map(interventionType => ({
        text: interventionTypes[interventionType],
        key: interventionType,
        value: interventionType
      }))
    : [];
});

const employeesSelector = createSelector([employee], selectEmployee => {
  const { content } = selectEmployee;

  return content
    ? content.map(employee => ({
        text: `${employee.firstName} ${employee.lastName}`,
        key: employee.id,
        value: employee.id
      }))
    : [];
});

const machinerySelector = createSelector(
  [sprayDiary, offline],
  (selectSprayDiary, selectOffline) => {
    const {
      machineryList: { content }
    } = selectSprayDiary;
    const { online } = selectOffline;

    const machinery = content
      ? content.map(machinery => ({
          key: machinery.id,
          text: `${machinery.name} ${
            machinery.size ? `(size ${machinery.size} L)` : ""
          }`,
          value: machinery.id
        }))
      : [];

    if (online) {
      machinery.push({
        key: "new",
        text: "+ Add new Machinery",
        value: "new"
      });
    }
    return machinery;
  }
);

const chemicalsOptionsSelector = createSelector(
  [chemicalsList],
  selectChemicalsList => {
    const { content } = selectChemicalsList;
    return content
      ? content.map(chemical => ({
          key: chemical.id,
          text: chemical.name,
          value: chemical.id
        }))
      : [];
  }
);

const chemicalsSelector = createSelector(
  [sprayDiary, offline],
  (selectSprayDiary, selectOffline) => {
    const {
      chemicalsList: { content }
    } = selectSprayDiary;
    const { online } = selectOffline;
    const chemicals = content
      ? content.map(chemical => ({
          key: chemical.id,
          text: chemical.name,
          value: chemical.id,
          sortingName: chemical.name
        }))
      : [];

    /*     if (online) {
      chemicals.push({
        key: "new",
        text: "+ Add new Chemical",
        value: "new"
      });
    } */

    return chemicals;
  }
);

const areasSelector = createSelector([areasList], selectAreasList => {
  const { content } = selectAreasList;

  return content
    ? content.map(area => ({
        key: area.key,
        text: `${area.name} ${area.variety ? area.variety.name : ""}`,
        shortText: area.name,
        value: area.id
      }))
    : [];
});

const areasSelectorWithoutVariety = createSelector(
  [areasList],
  selectAreasList => {
    const { content } = selectAreasList;

    return content
      ? content.map(area => ({
          key: area.key,
          text: `${area.name}`,
          shortText: area.name,
          value: area.id
        }))
      : [];
  }
);

const areasOptionsSelector = createSelector([areasList], selectAreasList => {
  const { content } = selectAreasList;
  return content
    ? content.map(area => ({
        key: area.key,
        text: area.name,
        value: area.id
      }))
    : [];
});

const strengthsSelector = createSelector([sprayDiary], selectSprayDiary => {
  const { strenghts } = selectSprayDiary;

  return strenghts
    ? strenghts.map(strength => ({
        key: strength.value,
        text: `${strength.text} km/h`,
        value: strength.value
      }))
    : [];
});

const directionsIcons = {
  N: { name: "arrow circle down" },
  NE: { name: "arrow circle down", style: { transform: "rotate(45deg)" } },
  E: { name: "arrow circle left" },
  SE: { name: "arrow circle left", style: { transform: "rotate(45deg)" } },
  S: { name: "arrow circle up" },
  SW: { name: "arrow circle up", style: { transform: "rotate(45deg)" } },
  W: { name: "arrow circle right" },
  NW: { name: "arrow circle right", style: { transform: "rotate(45deg)" } }
};

const directionSelector = createSelector([sprayDiary], selectSprayDiary => {
  const { directions } = selectSprayDiary;

  return directions
    ? directions.map(directions => ({
        key: directions,
        text: directions,
        value: directions,
        icon: directionsIcons[directions]
      }))
    : [];
});

const taskSelector = createSelector([sprayDiary], selectSprayDiary => {
  const {
    selectedAreas,
    widthPerRow,
    literPerHectare,
    selectedMachinery,
    rowsToSprayOptions,
    actualRowsToSprayChecked,
    status,
    rowsToSpray,
    actualRowsToSpray,
    conc,
    machinerySpeed,
    nozzle,
    nozzlesPerSide,
    gear,
    rpm,
    pressure,
    pto,
    chemicalToAdd,
    addChemicalSelection,
    validations,
    strenghts,
    windStrength,
    temperature,
    id,
    saving,
    selectedChemicals,
    windDirection,
    deltaT,
    ppeWorn,
    growthStage,
    interventionType,
    shedId,
    applicationType,
    assignees,
    supervisors,
    creator,
    workingTimes,
    plannedDate,
    expirationDate,
    closingDate,
    originalTask,
    chemicalsList,
    comment,
    attachments,
    isUploadingAttachment,
    inProgressAt,
    _id
  } = selectSprayDiary;
  return {
    selectedAreas: orderBy(selectedAreas, "id"),
    selectedMachinery,
    widthPerRow,
    literPerHectare,
    rowsToSprayOptions,
    actualRowsToSprayChecked,
    status,
    rowsToSpray,
    actualRowsToSpray,
    conc,
    ppeWorn,
    machinerySpeed,
    nozzle,
    nozzlesPerSide,
    gear,
    rpm,
    pressure,
    pto,
    chemicalToAdd,
    addChemicalSelection,
    validations,
    strenghts,
    windStrength,
    temperature,
    id,
    saving,
    selectedChemicals,
    windDirection,
    interventionType,
    shedId,
    applicationType,
    assignees,
    supervisors,
    creator,
    workingTimes,
    plannedDate,
    expirationDate,
    closingDate,
    originalTask,
    deltaT,
    growthStage,
    chemicalsList,
    comment,
    attachments,
    isUploadingAttachment,
    inProgressAt,
    _id
  };
});

const parentBlocksFilterSelector = createSelector(
  [sprayDiary, filterList],
  (selectSprayDiary, filters) => {
    const {
      areasList: { content }
    } = selectSprayDiary;
    const selectedFarms = filters.farms || [];

    let longestNameLength = 0;

    return content
      ? orderBy(
          uniqBy(
            content
              .filter(area => filters.showArchived || !area.archived)
              .filter(area =>
                selectedFarms.length
                  ? selectedFarms.includes(area.farm.id)
                  : true
              )
              .map(area => {
                if (area.name.length > longestNameLength) {
                  longestNameLength = area.name.length;
                }
                return {
                  key: area.id,
                  value: area.parent ? area.parent.id : area.id,
                  text: area.parent
                    ? area.parent.archived
                      ? `${area.parent.name} (archived)`
                      : area.parent.name
                    : area.archived
                    ? `${area.name} (archived)`
                    : area.name
                };
              }),
            "value"
          ),
          [
            block =>
              block.text
                .toLowerCase()
                .replace(/\s/g, "")
                .replace(/\d+/g, n => n.padStart(longestNameLength, "0"))
          ]
        )
      : [];
  }
);

const parentBlocksSelector = createSelector([sprayDiary], selectSprayDiary => {
  const { areasList } = selectSprayDiary;
  const parentBlocks = areasList.blocks
    .filter(a => !a.parent && !a.archived)
    .reduce((acc, area) => {
      acc[area.id] = {
        ...area,
        areaIDs: [],
        areaSize: [],
        varietiesList: [],
        tanks: 0,
        plannedLiters: 0
      };
      return acc;
    }, {});

  areasList.patches
    .filter(a => a.parent && !a.archived)
    .forEach(a => {
      parentBlocks[a.parent.id].areaIDs.push(a.id);
      parentBlocks[a.parent.id].areaSize.push(a.size);
    });

  return parentBlocks;
});

const taskNamesSelector = createSelector([taskNames], selectSprayDiary => {
  const { content } = selectSprayDiary;

  return Array.isArray(content)
    ? content.map(name => ({
        text: name,
        key: name,
        value: name
      }))
    : [];
});

const growthStageSelector = createSelector(
  [selectedFetchedTasks],
  sprayTasks => {
    const tasksWithGrowthStage = Object.values(sprayTasks).filter(
      task => task.growthStage
    );

    return tasksWithGrowthStage
      ? [...new Set(tasksWithGrowthStage.map(task => task.growthStage))].map(
          growthStage => ({
            text: growthStage,
            key: growthStage,
            value: growthStage
          })
        )
      : [];
  }
);

const filterLabelsSelector = createSelector(
  [
    parentBlocksFilterSelector,
    farmsOptionsSelector,
    cropsOptionsSelector,
    varietyOptionsSelector,
    sprayDiaryFilters
  ],
  (
    selectParentBlocks,
    selectFarmsOptions,
    selectCropsOptions,
    selectVarietyOptions,
    selectSprayDiaryFilters
  ) => {
    const filtersOptions = {
      farms: selectFarmsOptions,
      crops: selectCropsOptions,
      varieties: selectVarietyOptions,
      parentBlock: selectParentBlocks
    };
    const labels = Object.keys(selectSprayDiaryFilters).reduce(
      (options, filter) => {
        if (Array.isArray(selectSprayDiaryFilters[filter])) {
          options = [
            ...options,
            ...filtersOptions[filter]
              .filter(item =>
                selectSprayDiaryFilters[filter].includes(item.value)
              )
              .map(item => ({ ...item, key: `${filter}_${item.key}`, filter }))
          ];
        }
        return options;
      },
      []
    );
    if (selectSprayDiaryFilters.ageFrom || selectSprayDiaryFilters.ageTo) {
      labels.push({
        key: "age",
        text:
          selectSprayDiaryFilters.ageFrom && selectSprayDiaryFilters.ageTo
            ? `${selectSprayDiaryFilters.ageFrom} < Age < ${selectSprayDiaryFilters.ageTo}`
            : selectSprayDiaryFilters.ageFrom
            ? `Age > ${selectSprayDiaryFilters.ageFrom}`
            : `Age < ${selectSprayDiaryFilters.ageTo}`,
        value: [selectSprayDiaryFilters.ageFrom, selectSprayDiaryFilters.ageTo],
        filter: "age"
      });
    }
    if (selectSprayDiaryFilters.showArchived) {
      labels.push({
        key: "showArchived",
        text: "Archived included",
        value: selectSprayDiaryFilters.showArchived,
        filter: "showArchived"
      });
    }

    return labels;
  }
);

const filteredAreasOptionsSelector = createSelector(
  [filterList, areasList, selectedAreas, plannedDate, filterList],
  (
    selectFilterList,
    selectAreasList,
    selectSelectedAreas,
    selectPlannedDate
  ) => {
    const content = selectAreasList.content.map(area => new Area(area));
    const selectedAreas = selectSelectedAreas
      ? selectSelectedAreas.map(area => area.id)
      : [];

    const filterFarms = (values, content) =>
      values.length > 0
        ? content.filter(
            area =>
              (area.farm && values.includes(area.farm.id)) ||
              selectedAreas.includes(area.id)
          )
        : content;
    const filterCrops = (values, content) =>
      values.length > 0
        ? content.filter(area => {
            const areaVariety = area.areaVarietyByDate(selectPlannedDate);
            return (
              (areaVariety &&
                areaVariety.variety &&
                areaVariety.variety.crop &&
                values.includes(areaVariety.variety.crop.id)) ||
              selectedAreas.includes(area.id)
            );
          })
        : content;
    const filterVarieties = (values, content) =>
      values.length > 0
        ? content.filter(area => {
            const areaVariety = area.areaVarietyByDate(selectPlannedDate);
            return (
              (areaVariety &&
                areaVariety.variety &&
                values.includes(areaVariety.variety.id)) ||
              selectedAreas.includes(area.id)
            );
          })
        : content;
    const filterBlocks = (values, content) =>
      values.length > 0
        ? content.filter(
            area =>
              (area.parent && values.includes(area.parent.id)) ||
              selectedAreas.includes(area.id)
          )
        : content;
    const filterAgeFrom = (value, content) =>
      value
        ? content.filter(
            area =>
              (area.plantingAt &&
                moment().diff(area.plantingAt, "years") >= Number(value)) ||
              selectedAreas.includes(area.id)
          )
        : content;
    const filterAgeTo = (value, content) =>
      value
        ? content.filter(
            area =>
              (area.plantingAt &&
                moment().diff(area.plantingAt, "years") <= Number(value)) ||
              selectedAreas.includes(area.id)
          )
        : content;
    const filterShowArchived = (value, content) =>
      content.filter(
        area => value || !area.archived || selectedAreas.includes(area.id)
      );
    const defaultType = (values, content) => {
      return content;
    };
    const filterTypes = {
      farms: filterFarms,
      crops: filterCrops,
      varieties: filterVarieties,
      parentBlock: filterBlocks,
      ageFrom: filterAgeFrom,
      ageTo: filterAgeTo,
      showArchived: filterShowArchived,
      defaultType
    };

    const contentToOptions = Object.keys(selectFilterList).reduce(
      (contentIteration, filter) =>
        (filterTypes[filter] || filterTypes.defaultType)(
          selectFilterList[filter],
          contentIteration
        ),
      content || []
    );

    return contentToOptions
      .sort((item1, item2) =>
        item1.name
          .replace(/\s/g, "")
          .localeCompare(item2.name.replace(/\s/g, ""), undefined, {
            numeric: true,
            sensitivity: "base"
          })
      )
      .map(item => {
        const areaVariety = item.areaVarietyByDate(selectPlannedDate);
        return {
          key: item.key,
          text: `${item.name} ${item.variety ? item.variety.name : ""}`,
          shortText: item.archived ? `${item.name} (archived)` : item.name,
          parentName: item.parent ? item.parent.name : null,
          parentId: item.parent ? item.parent.id : null,
          value: item.id,
          variety: item.variety && item.variety.name,
          type: item.type,
          archived: item.archived,
          content: (
            <>
              <span>
                {item.archived ? `${item.name} (archived)` : item.name}
              </span>
              {areaVariety && (
                <i className={!areaVariety.name ? "vacant" : ""}>
                  {areaVariety.name || "Vacant"}
                </i>
              )}
            </>
          )
        };
      });
  }
);

const gotMoreOptionsSelector = createSelector(
  [filteredAreasOptionsSelector, selectedAreas],
  (selectAreasList, selectSelectedAreas) =>
    selectAreasList
      .map(area => area.value)
      .filter(
        areaId => !selectSelectedAreas.map(area => area.id).includes(areaId)
      )
);

const selectedAreasSelector = createSelector(
  [selectedAreas],
  selectSelectedAreas => {
    return selectSelectedAreas ? selectSelectedAreas.map(area => area.id) : [];
  }
);

const selectedChemicalsSelector = createSelector(
  [selectedChemicals],
  selectSelectedChemicals => {
    return selectSelectedChemicals
      ? orderBy(selectSelectedChemicals, ["position"])
      : [];
  }
);
const originalFetchedTaskSelector = createSelector(
  [selectedFetchedTasks, selectedTaskId],
  (selectFetchedOriginalTasks, selectSelectedTaskId) => {
    return selectFetchedOriginalTasks[selectSelectedTaskId] || {};
  }
);

export {
  interventionTypesSelector,
  taskSelector,
  taskNamesSelector,
  growthStageSelector,
  employeesSelector,
  areasSelector,
  machinerySelector,
  chemicalsSelector,
  strengthsSelector,
  directionSelector,
  areasOptionsSelector,
  chemicalsOptionsSelector,
  parentBlocksSelector,
  parentBlocksFilterSelector,
  filterLabelsSelector,
  filteredAreasOptionsSelector,
  gotMoreOptionsSelector,
  selectedAreasSelector,
  areasSelectorWithoutVariety,
  cropsOptionsSelector,
  varietyOptionsSelector,
  selectedChemicalsSelector,
  originalFetchedTaskSelector
};
