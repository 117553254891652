import React from 'react';
import Machinery from '../Machinery';
import Chemicals from '../Chemicals';
import styles from './Tabs.module.css';

function Operations(props) {
  return (
    <div className={styles.tabHolder}>
      <Machinery {...props} />
      <Chemicals {...props} />
    </div>
  );
}

export default Operations;
