import { RECOMMENDATION_TABLE_COLUMNS } from "constants/Recommendations/tableColumnsConfig";
import { BasicTableCell } from "../../utils/tables";
import {
  APPLICATION_TYPES,
  PRESSURE_UNITS,
  RECOMMENDATION_STATUSES
} from "../../constants/Recommendations/types";
import { formatDate } from "../../utils/dateUtils";
import { uppercaseFirstLetter } from "../../utils/string";
import { getUserDisplayedName } from "../../utils/user";
import { ROWS_TO_SPRAY } from "constants/shared";
import { get, map } from "lodash";
import { objectToSelectOptions } from "../../utils/objectUtils";
import { Dropdown } from "semantic-ui-react";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import AssigneesDropdown from "../RecommendationForm/components/Sidebar/components/AssigneesDropdown";
import { calculateChemicalsValues } from "../../utils/recommendations";
import {
  calculateQuantityPer100L,
  calculateQuantityPerHectare
} from "../../utils/chemicalBreakdownUtils";
import { matterToUnits } from "../../utils/constToUnits";
import { ChemicalsDropdown } from "./components/FormElements";
import ChemicalTargetDropdown from "./components/FormElements/ChemicalTargetDropdown";
import {
  calcBlockTanksValue,
  setFullTank,
  setPartTank
} from "../../reducers/SprayDiary";
import styles from "../RecommendationForm/components/Operations/components/SelectMachinery/styles.module.css";
import { getScoutingTaskReadableId } from "../../utils/scouting";

const pressureUnitOptions = objectToSelectOptions(PRESSURE_UNITS);

export const getDropdownStylesByTaskStatus = status => {
  const readableName = RECOMMENDATION_STATUSES[status];

  switch (readableName) {
    case RECOMMENDATION_STATUSES.ACCEPTED:
      return { color: "#FFFFFF", background: "#0E9354" };
    case RECOMMENDATION_STATUSES.PENDING:
      return { color: "#000000", background: "#FFB340" };
    case RECOMMENDATION_STATUSES.REJECTED:
    default:
      return { color: "#FFFFFF", background: "#E94E4E" };
  }
};

export const getGeneralInfoData = currentTask => {
  const result = [
    {
      title: "Task name",
      value: currentTask.name,
      key: "name",
      editable: true
    },
    {
      title: "Planned date",
      value: formatDate(currentTask.plannedDate),
      key: "plannedDate",
      type: "date",
      editable: true
    },
    {
      title: "Expiry date",
      value: formatDate(currentTask.expiryDate),
      key: "expiryDate",
      type: "date",
      editable: true
    },
    {
      title: "Application type",
      value: uppercaseFirstLetter(currentTask.applicationType),
      editable: false
    },
    {
      title: "Creator",
      value: getUserDisplayedName(currentTask.creator),
      editable: false
    },
    {
      title: "Assignees",
      value: map(currentTask.assignees, ({ employee }) =>
        getUserDisplayedName(employee)
      )
        .filter(Boolean)
        .join(", "),
      key: "assignees",
      as: AssigneesDropdown,
      editable: true
    },
    {
      title: "Task Template",
      value: get(currentTask, "templateBasicInfo.name"),
      editable: false
    }
  ];

  if (currentTask.scoutingId) {
    result.unshift({
      title: "Linked to scouting task",
      value: getScoutingTaskReadableId(currentTask.scoutingId)
    });
  }

  return result;
};

export const getSprayInformationData = currentTask => {
  const { applicationType, rowWidth, rowsToSpray } = currentTask;

  return [
    {
      title: "Row width",
      value: `${rowWidth}%`,
      key: "rowWidth",
      type: "number",
      label: "%",
      max: 100,
      min: 1
    },
    ...(APPLICATION_TYPES[applicationType] === APPLICATION_TYPES.SPRAY
      ? [
          {
            title: "Solution rate (L/Ha)",
            value: currentTask.literPerHectare
              ? `${currentTask.literPerHectare} L/Ha`
              : null,
            key: "literPerHectare"
          }
        ]
      : []),
    {
      title: "Rows to spray",
      value: ROWS_TO_SPRAY[rowsToSpray],
      options: objectToSelectOptions(ROWS_TO_SPRAY),
      key: "rowsToSpray"
    },
    {
      title: "Growth stage",
      value: currentTask.growthStage,
      key: "growthStage"
    }
  ];
};

const CustomInputComponent = props => {
  const { machineryList } = useSelector(state => state.sprayDiary);
  const { setFieldValue } = useFormikContext();
  const options = machineryList.content
    .filter(m => !m.archived)
    .map(item => {
      return {
        key: item.id,
        text: `${item.name} ${item.size ? `(size ${item.size} L)` : ""}`,
        value: item.id
      };
    });

  return (
    <Dropdown
      {...props}
      search
      clearable
      closeOnChange
      noResultsMessage="Nothing was found"
      placeholder="Machinery"
      loading={machineryList.isFetching}
      options={options}
      selection
      onChange={(_, { value }) => {
        const machinery = machineryList.content.find(({ id }) => id === value);

        setFieldValue(props.name, value);
        setFieldValue("machinery", machinery);
      }}
    />
  );
};

const PressureUnitComponent = () => {
  const formikContext = useFormikContext();
  useEffect(() => {
    if (formikContext.values.pressure && !formikContext.values.pressureUnit) {
      formikContext.setFieldValue("pressureUnit", PRESSURE_UNITS.PSI);
    }
  }, [formikContext.values.pressure, formikContext.values.pressureUnit]);

  return (
    <Dropdown
      className={styles.pressureUnitDropdown}
      button
      floating
      options={pressureUnitOptions}
      value={formikContext.values.pressureUnit}
      color="green"
      onChange={(_, { value }) => {
        formikContext.setFieldValue("pressureUnit", value);
      }}
      text={formikContext.values.pressureUnit}
    />
  );
};

export const getMachineryInformationData = recommendationTask => {
  const {
    applicationType,
    conc,
    gear,
    machinery,
    machinerySpeed,
    nozzle,
    nozzlesPerSide,
    pto,
    pressure,
    pressureUnit,
    rpm
  } = recommendationTask;
  const size = get(machinery, "size");
  const sizeUnit = get(machinery, "sizeUnit") === "LITER" ? "L" : "Kg";
  const isSprayTask =
    APPLICATION_TYPES[applicationType] === APPLICATION_TYPES.SPRAY;

  return [
    {
      title: "Name",
      value: get(machinery, "name"),
      key: "machineryId",
      as: CustomInputComponent
    },
    ...(isSprayTask
      ? [
          {
            title: "Size",
            value: size ? `${size.toLocaleString()} ${sizeUnit}` : null,
            key: "machinery.size",
            disabled: true
          },
          {
            title: "Concentration/(x)",
            value: conc,
            key: "conc",
            type: "number"
          },
          { title: "Nozzle", value: nozzle, key: "nozzle" },
          {
            title: "Nozzle per side",
            value: nozzlesPerSide,
            key: "nozzlesPerSide",
            type: "number"
          }
        ]
      : []),
    { title: "Gear", value: gear, key: "gear" },
    ...(isSprayTask
      ? [
          {
            title: "Pressure",
            value: pressure ? `${pressure} ${pressureUnit}` : null,
            type: "number",
            action: <PressureUnitComponent />,
            key: "pressure"
          }
        ]
      : []),
    {
      title: "Speed",
      value: machinerySpeed,
      type: "number",
      key: "machinerySpeed"
    },
    { title: "PTO", value: pto, key: "pto" },
    { title: "RPM", value: rpm, key: "rpm" }
  ];
};

export const getRecommendationsTableColumns = () => {
  return Object.keys(RECOMMENDATION_TABLE_COLUMNS).map(columnName => {
    const targetColumn = RECOMMENDATION_TABLE_COLUMNS[columnName];

    return {
      ...targetColumn,
      id: columnName,
      accessor: targetColumn.accessor || columnName,
      Header: targetColumn.title,
      Cell: targetColumn.Cell || BasicTableCell,
      className: `cell_${targetColumn.width} ${targetColumn.className || ""}`,
      disableSort: targetColumn.disableSort,
      filter: targetColumn.filter
    };
  });
};

export const getDataToSend = (taskData, state) => {
  const machineryList = state.sprayDiary.machineryList;
  const areasList = get(state.sprayDiary.areasList, "content", []);
  const { chemicals } = calculateChemicalsValues(
    taskData,
    machineryList,
    areasList
  );

  return {
    ...taskData,
    chemicals: map(chemicals, chemical => ({
      chemicalId: chemical.chemicalId,
      chemicalRateId: chemical.chemicalRateId,
      dilutionRate: chemical.dilutionRate,
      dilutionRateType: chemical.dilutionRateType,
      qtyPerFullVat: chemical.qtyPerFullVat,
      qtyPerPartTank: chemical.qtyPerPartTank,
      batchNumber: chemical.batchNumber,
      dateOfManufacture: chemical.dateOfManufacture
    }))
  };
};

const ChemicalTargetWrapper = props => {
  const { setFieldValue } = useFormikContext();

  return (
    <ChemicalTargetDropdown
      chemical={props.value}
      onChange={rate => {
        setFieldValue(`${props.name}.rate`, rate);
      }}
    />
  );
};

const getQuantity = (
  rateType,
  areasSize,
  rowsToSpray,
  rowWidth,
  dilutionRate,
  literPerHectare
) => {
  const areaTreated =
    rateType === "PER_HECTARE"
      ? areasSize
      : areasSize * (rowsToSpray === "ALL" ? 1 : 0.5) * (rowWidth / 100);
  return rateType === "PER_HECTARE"
    ? calculateQuantityPerHectare(areaTreated, dilutionRate)
    : calculateQuantityPer100L(areaTreated, literPerHectare, dilutionRate, 0);
};

const getChemicalFullTanksValue = (taskData, chemical, totalAreasSize) => {
  const { machinery, conc, literPerHectare } = taskData;

  return setFullTank(
    !!machinery,
    get(chemical, "rate.rateType"),
    get(chemical, "rate.minRate"),
    get(chemical, "rate.maxRate"),
    get(chemical, "rate.rate"),
    get(machinery, "size"),
    conc,
    literPerHectare,
    totalAreasSize
  );
};

// TODO: Use redux selectors instead of this 'utils' accessors
export const getChemicalsInformationData = currentTask => {
  const {
    interventionType,
    areas,
    rowsToSpray,
    rowWidth,
    literPerHectare,
    machinery
  } = currentTask;
  const totalAreasSize = areas.reduce((acc, { area }) => acc + area.size, 0);
  const machinerySize = get(machinery, "size");
  const totalTanksValue = calcBlockTanksValue(
    totalAreasSize,
    literPerHectare,
    machinerySize,
    rowsToSpray,
    rowWidth
  );
  return map(currentTask.chemicals, (chemicalData, index) => {
    const prefix = `chemicals[${index}]`;
    const {
      chemical,
      dilutionRate,
      batchNumber,
      dateOfManufacture
    } = chemicalData;
    const cropName = get(chemical, "rate.crop.name", "Generic");
    const target = [cropName, get(chemical, "rate.purpose")]
      .filter(Boolean)
      .join(" - ");
    const rateType = get(chemical, "rate.rateType");
    const units = matterToUnits(get(chemical, "matter"));
    const quantity = getQuantity(rateType, totalAreasSize, rowsToSpray);

    return [
      {
        title: "Name",
        value: get(chemicalData, "chemical.name"),
        key: `${prefix}.chemicalId`,
        editable: true,
        as: ChemicalsDropdown
      },
      {
        title: "Type",
        value: get(chemicalData, "chemical.type.type"),
        key: `${prefix}.chemical.type.type`,
        editable: false
      },
      {
        title: "Target",
        value: target,
        key: `${prefix}.chemical`,
        as: ChemicalTargetWrapper,
        editable: true
      },
      {
        title: "Intervention type",
        value: interventionType,
        key: `interventionType`
      },
      {
        title: "Rate",
        value: `${dilutionRate} ${units}`,
        key: `${prefix}.dilutionRate`,
        editable: true,
        label: units,
        labelPosition: "right"
      },
      {
        title: "Total QTY",
        value: quantity ? `${quantity.toFixed(3)} ${units}` : null,
        key: `interventionType`,
        accessor: taskData => {
          const chemical = get(taskData, `${prefix}.chemical`);
          const rateType = get(chemical, `rate.rateType`);
          const dilutionRate = get(taskData, `${prefix}.dilutionRate`);
          const quantity = getQuantity(
            rateType,
            totalAreasSize,
            rowsToSpray,
            rowWidth,
            dilutionRate,
            literPerHectare
          );
          const shownQuantity = quantity.toFixed(2);

          return quantity
            ? `${shownQuantity} ${matterToUnits(get(chemical, "matter"))}`
            : null;
        }
      },
      {
        title: "QTY/Full Tank",
        accessor: taskData => {
          const chemical = get(taskData, `${prefix}.chemical`);
          const qtyPerFullVat = getChemicalFullTanksValue(
            taskData,
            chemical,
            totalAreasSize
          );
          return qtyPerFullVat ? `${qtyPerFullVat.toFixed(2)} ${units}` : null;
        }
      },
      {
        title: "QTY/Part Tank",
        accessor: taskData => {
          if (!machinery) {
            return null;
          }
          const chemical = get(taskData, `${prefix}.chemical`);
          const fullTanks = getChemicalFullTanksValue(
            taskData,
            chemical,
            totalAreasSize
          );
          const qtyPerPartTank = setPartTank(fullTanks, totalTanksValue);

          return qtyPerPartTank
            ? `${qtyPerPartTank.toFixed(2)} ${units}`
            : null;
        }
      },
      {
        title: "Batch Number",
        value: batchNumber,
        key: `${prefix}.batchNumber`,
        editable: true
      },
      {
        title: "Date of manufacture",
        value: formatDate(dateOfManufacture),
        key: `${prefix}.dateOfManufacture`,
        editable: true,
        type: "date"
      }
    ];
  });
};
