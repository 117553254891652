import { actionType } from '../../constants/Stock';

const initialState = {
  list: {
    content: [],
    isFetching: false,
  },
  data: { content: [] },
  isFetching: false,
  error: null,
};

export default (state = initialState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case actionType.FETCH_STOCK_LIST_START:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: true,
        },
      };
    case actionType.FETCH_STOCK_START: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case actionType.FETCH_STOCK: {
      return {
        ...state,
        data: {
          ...action.payload,
        },
        isFetching: false,
      };
    }
    case actionType.ADD_STOCK: {
      const newTotalElements = state.data.totalElements + 1;
      const dataContent = [action.payload, ...state.data.content];
      const newListTotalElements = state.list.totalElements + 1;
      const listContent = [action.payload, ...state.list.content];

      return {
        ...state,
        data: {
          ...state.data,
          content: dataContent,
          totalElements: newTotalElements,
          totalPages: Math.ceil(newTotalElements / state.data.size),
        },
        list: {
          ...state.list,
          content: listContent,
          totalElements: newListTotalElements,
        },
      };
    }
    case actionType.UPDATE_STOCK: {
      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.map(item => {
            if (item.id === action.payload.id) {
              return action.payload;
            }
            return item;
          }),
        },
        list: {
          ...state.list,
          content: state.list.content.map(item => {
            if (item.id === action.payload.id) {
              return action.payload;
            }
            return item;
          }),
        },
      };
    }
    case actionType.DELETE_STOCK: {
      const newTotalElements = state.data.totalElements - 1;

      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.filter(e => e.id !== action.payload),
          totalElements: newTotalElements,
          totalPages: Math.ceil(newTotalElements / state.data.size),
        },
        list: {
          ...state.list,
          content: state.list.content.filter(e => e.id !== action.payload),
        },
      };
    }

    case actionType.FETCH_STOCK_LIST:
      return {
        ...state,
        list: { ...action.payload, isFetching: false },
      };
    case actionType.FETCH_STOCK_LIST_FAIL:
      return {
        ...state,
        list: { ...state.list, isFetching: false },
        error: action.payload,
      };
    case actionType.FETCH_STOCK_FAIL:
    case actionType.GET_STOCK_ITEM_BY_VARIETY_ID_AND_BOX_UNIT_ID_FAIL:
    case actionType.ADD_STOCK_FAIL:
    case actionType.UPDATE_STOCK_FAIL:
    case actionType.DELETE_STOCK_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    }
    case actionType.CLEAR_STOCK_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
