import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "semantic-ui-react";

class Alert extends PureComponent {
  render() {
    const { open, size, onClose, headerContent, mainContent } = this.props;

    return (
      <Modal size={size} open={open} onClose={onClose}>
        {headerContent && <Modal.Header>{headerContent}</Modal.Header>}
        <Modal.Content>{mainContent}</Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose}>Ok</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

Alert.propTypes = {
  open: PropTypes.bool,
  size: PropTypes.any,
  onClose: PropTypes.func,
  headerContent: PropTypes.any,
  mainContent: PropTypes.any
};

export default Alert;
