export default {
  page: {
    maxWidth: "210mm",
    margin: 0,
    padding: "0mm 10mm"
  },
  block: {
    justifyContent: "space-around",
    display: "grid"
  },
  center: {
    alignSelf: "center",
    textAlign: "center"
  },
  width35mmAndTopAlign: {
    width: "37mm",
    verticalAlign: "top"
  },
  boldText: {
    fontWeight: "bold",
    verticalAlign: "top"
  },
  boldTextPreWrap: {
    whiteSpace: "pre-wrap",
    fontWeight: "bold",
    verticalAlign: "top"
  },
  normalTextLeft: {
    textAlign: "left",
    fontWeight: "normal"
  },
  normalText: {
    fontWeight: "normal"
  },
  filledRow: {
    background: "#edeced",
    WebkitPrintColorAdjust: "exact"
  },
  margin10: {
    margin: "10px 0"
  },
  fixedWidth: {
    display: "inline-block",
    width: "35mm",
    marginTop: "3mm"
  },
  footerBlock: {
    padding: "2mm",
    width: "100%",
    background: "#edeced",
    WebkitPrintColorAdjust: "exact"
  },
  textDecoration: {
    display: "inline-block",
    borderBottom: "1px solid black",
    width: "50mm"
  },

  workingTimes: {
    display: "inline-block"
  },

  workingTimesLabel: {
    display: "inline-block",
    verticalAlign: "top",
    width: "35mm"
  },

  underline: {
    borderBottom: "1px solid black"
    // width: '50mm',
  },
  batchNumerColumn: {
    display: "flex",
    flexDirection: "column"
  },
  dateColumn: {
    verticalAlign: "top"
  }
};
