export const actionType = {
  FETCH_HOURLY_PAYROLLS_EXPANDED_DATA_START:
    "HOURLY_PAYROLL/FETCH_HOURLY_PAYROLLS_EXPANDED_DATA_START",
  FETCH_HOURLY_PAYROLLS_EXPANDED_DATA:
    "HOURLY_PAYROLL/FETCH_HOURLY_PAYROLLS_EXPANDED_DATA",
  FETCH_HOURLY_PAYROLLS_EXPANDED_DATA_FAIL:
    "HOURLY_PAYROLL/FETCH_HOURLY_PAYROLLS_EXPANDED_DATA_FAIL",
  FETCH_HOURLY_PAYROLLS_SUMMARY_START:
    "HOURLY_PAYROLL/FETCH_HOURLY_PAYROLLS_SUMMARY_START",
  SET_HOURLY_PAYROLLS_SUMMARY: "HOURLY_PAYROLL/SET_HOURLY_PAYROLLS_SUMMARY",
  FETCH_HOURLY_PAYROLLS_SUMMARY_FAIL:
    "HOURLY_PAYROLL/FETCH_HOURLY_PAYROLLS_SUMMARY_FAIL",
  FETCH_HOURLY_PAYROLLS_ALL_INFORMATION_START:
    "HOURLY_PAYROLL/FETCH_HOURLY_PAYROLLS_ALL_INFORMATION_START",
  SET_HOURLY_PAYROLLS_ALL_INFORMATION:
    "HOURLY_PAYROLL/SET_HOURLY_PAYROLLS_ALL_INFORMATION",
  FETCH_HOURLY_PAYROLLS_ALL_INFORMATION_FAIL:
    "HOURLY_PAYROLL/FETCH_HOURLY_PAYROLLS_ALL_INFORMATION_FAIL",
  CHECK_REPORT_PAGE: "HOURLY_PAYROLL/CHECK_REPORT_PAGE"
};
