import { enumerateDaysBetweenDates } from "../../utils/dateUtils";

export const printReportColumnsConfig = [
  {
    id: "firstName",
    accessor: "firstName",
    Header: "First Name"
  },
  {
    id: "lastName",
    accessor: "lastName",
    Header: "Last Name"
  },
  {
    id: "contractor",
    accessor: "contractor",
    Header: "Contractor"
  },
  {
    id: "totalWorkingTime",
    accessor: "totalWorkingTimeInHM",
    Header: "Total Working Time"
  },
  {
    id: "ratePerHour",
    accessor: "ratePerHour",
    Header: "Rate ($/H)"
  },
  {
    id: "payPerHours",
    accessor: "payPerHours",
    Header: "Pay For Hours ($)"
  },
  {
    id: "payPerPiece",
    accessor: "payPerPiece",
    Header: "Pay For Pieces ($)"
  },
  {
    id: "totalPay",
    accessor: "totalPay",
    Header: "Total Pay ($)",
    aggregate: true,
    format: "commaSeparatedNumber"
  }
];

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];

export const getHourlyRateColumnsConfig = (dateFrom, dateTo) => [
  {
    id: "firstName",
    accessor: "firstName",
    Header: "First Name"
  },
  {
    id: "lastName",
    accessor: "lastName",
    Header: "Last Name"
  },
  {
    id: "contractor",
    accessor: "contractor",
    Header: "Contractor"
  },
  ...enumerateDaysBetweenDates(dateFrom, dateTo).map(date => {
    const isoDate = date.toISOString().split("T")[0];
    return {
      id: isoDate,
      accessor: isoDate,
      Header: `${days[date.getDay()]} ${isoDate
        .split("-")
        .reverse()
        .join("/")}`
    };
  }),
  {
    id: "totalHours",
    accessor: "totalHours",
    Header: "Total Hours"
  },
  {
    id: "ratePerHour",
    accessor: "ratePerHour",
    Header: "Rate ($/H)"
  },
  {
    id: "totalCost",
    accessor: "totalCost",
    Header: "Total Cost"
  }
];

export const getPieceRateColumnsConfig = (dateFrom, dateTo) => [
  {
    id: "firstName",
    accessor: "firstName",
    Header: "First Name"
  },
  {
    id: "lastName",
    accessor: "lastName",
    Header: "Last Name"
  },
  {
    id: "contractor",
    accessor: "contractor",
    Header: "Contractor"
  },
  ...enumerateDaysBetweenDates(dateFrom, dateTo).map(date => {
    const isoDate = date.toISOString().split("T")[0];
    return {
      id: isoDate,
      accessor: isoDate,
      Header: `${days[date.getDay()]} ${isoDate
        .split("-")
        .reverse()
        .join("/")}`
    };
  }),
  {
    id: "totalCost",
    accessor: "totalCost",
    Header: "Total Cost"
  }
];
