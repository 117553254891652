import React, { useState } from "react";
import { Accordion, Form, Header, Segment, TextArea } from "semantic-ui-react";
import TextareaAutosize from "react-textarea-autosize";
import { get, xor } from "lodash";
import EditScoutingIssuesAccordion from "./EditScoutingIssuesAccordion";
import styles from "./styles.module.css";
import { scoutingFormElementShape } from "../../../constants/Scouting/types";

const EditScoutingLocationForm = ({ formik }) => {
  const [activeIndexes, setActiveIndexes] = useState([0]);
  const handleLocationClick = (e, { index }) => {
    setActiveIndexes(activeIndexes => xor(activeIndexes, [index]));
  };
  const { values } = formik;

  return (
    <Segment piled>
      <Header as="h1" className={styles.locationTitle}>
        Location
      </Header>
      <Accordion fluid>
        {get(values, "areas", []).map(({ area, areaIssues }, index) => (
          <Segment.Group>
            <Segment key={index}>
              <Accordion.Title
                index={index}
                onClick={handleLocationClick}
                content={area.name}
                as={Header}
              />
              <Accordion.Content active={activeIndexes.includes(index)}>
                <Form.Field required className="sprayField">
                  <label>General Comments:</label>
                  <TextArea
                    as={TextareaAutosize}
                    minRows={2}
                    size="large"
                    placeholder="Comment"
                    name={`areas[${index}].comment`}
                    value={values.areas[index].comment}
                    onChange={formik.handleChange}
                  />
                </Form.Field>
                <EditScoutingIssuesAccordion
                  areaIssues={areaIssues}
                  areaIndex={index}
                  formik={formik}
                />
              </Accordion.Content>
            </Segment>
          </Segment.Group>
        ))}
      </Accordion>
    </Segment>
  );
};
EditScoutingLocationForm.propTypes = scoutingFormElementShape;

export default EditScoutingLocationForm;
