import { axiosProxy } from "../../utils/axiosProxy";
import { actionType } from "../../constants/Common";

export const checkAPIStatus = () => async dispatch => {
  let url = "/public/status";
  //  const user = await userManager.getUser();
  try {
    dispatch({
      type: actionType.CHECK_API_STATUS_START
    });
    const res = await axiosProxy({
      url,
      method: "GET"
      /*
      headers: {
        authorization: `Bearer ${user.access_token}`,
      },

       */
    });
    dispatch({
      type: actionType.CHECK_API_STATUS,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: actionType.CHECK_API_STATUS_FAIL,
      payload: error
    });
  }
};

export const getBackEndBuildNumber = () => async dispatch => {
  let url = "/public/build_number";

  try {
    dispatch({ type: actionType.GET_BACKEND_BUILD_NUMBER_START });

    const res = await axiosProxy({ url, method: "GET" });

    dispatch({
      type: actionType.GET_BACKEND_BUILD_NUMBER,
      payload: res.data
    });

    return res.data;
  } catch (e) {
    dispatch({
      type: actionType.GET_BACKEND_BUILD_NUMBER_FAIL,
      payload: e
    });
  }
};

export const forceUpdateApp = update => dispatch => {
  dispatch({
    type: actionType.FORCE_UPDATE_APP,
    payload: update
  });
};

export const purgeAllData = () => dispatch => {
  return dispatch({
    type: actionType.PURGE_ALL_DATA
  });
};
export const setUserChecked = () => dispatch => {
  return dispatch({
    type: actionType.SET_USER_CHECKED
  });
};
export const setVersionChecked = checked => dispatch => {
  return dispatch({
    type: actionType.SET_VERSION_CHECKED,
    payload: checked
  });
};
