import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import styles from "./Areas.module.css";

function showReadMoreButton(element) {
  return element.offsetHeight + 1 < element.scrollHeight;
}

const AreasCell = props => {
  const {
    value,
    column: { Header }
  } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const [isToggleShown, setIsTogglingNeeded] = useState(false);
  const cellRef = useRef();

  const onResize = useCallback(() => {
    setIsTogglingNeeded(showReadMoreButton(cellRef.current));
  }, [cellRef]);

  useEffect(() => {
    if (cellRef) {
      onResize();
      window.addEventListener("resize", onResize);

      return window.removeEventListener("resize", onResize);
    }
  }, [cellRef, value]);

  useEffect(() => {
    if (!isToggleShown) {
      setIsExpanded(false);
    }
  }, [isToggleShown]);
  return (
    <div>
      <div className="cellTitle hide-md">{Header}</div>
      <div
        className={cn("cellValue", styles.value, { ["expanded"]: isExpanded })}
        ref={cellRef}
      >
        {value.join(", ")}
      </div>
      {isToggleShown && (
        <button
          className={styles.expand}
          onClick={event => {
            event.stopPropagation();
            setIsExpanded(isExpanded => !isExpanded);
          }}
        >
          {isExpanded ? "Show less" : "Show more"}
        </button>
      )}
    </div>
  );
};

AreasCell.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  column: PropTypes.shape({ Header: PropTypes.string })
};

export default AreasCell;
