import PropTypes from "prop-types";
import React from "react";
import DatetimeRangePicker from "react-datetime-range-picker";
import styles from "./DateRangePicker.module.css";
import "./DateRangePicker.css";
import moment from "moment";

const DateRangePicker = ({ onChange, name, value, disabled }) => {
  const handleChange = ({ start, end }) =>
    onChange({
      start: moment(start).format("YYYY-MM-DD"),
      end: moment(end).format("YYYY-MM-DD")
    });

  return (
    <div className={styles.wrapper}>
      <span className={styles.startDate}>
        {value.start ? moment(value.start).format("DD-MM-YYYY") : "-"}
      </span>
      <span className={styles.endDate}>
        {value.end ? moment(value.end).format("DD-MM-YYYY") : "-"}
      </span>
      <DatetimeRangePicker
        disabled={disabled}
        name={name}
        value={value}
        initialValue={value}
        className={styles.dateRangePicker}
        viewMode={"days"}
        timeFormat={false}
        dateFormat={"DD-MM-YYYY"}
        onChange={handleChange}
      />
    </div>
  );
};

DateRangePicker.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired
};

DateRangePicker.defaultProps = {
  disabled: false
};

export default DateRangePicker;
