import { actionType } from "../../constants/InventoryMovements/index";

const initialState = {
  list: {
    content: [],
    isFetching: false
  },
  data: {
    content: [],
    isFetching: false
  },
  type: {
    content: [],
    isFetching: false,
    error: null
  },
  print: {
    content: [],
    isFetching: false,
    error: null
  },
  filters: {
    shedIds: [],
    checmicalIds: [],
    operationTypes: [],
    areaIds: [],
    status: [],
    date: {
      content: [],
      isFetching: false
    },
    product: {
      content: [],
      error: null,
      isFetching: false
    },
    shed: {
      id: null,
      isEditing: false
    }
  }
};
export default (state = initialState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case actionType.OPERATIONS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
          isFetching: false
        }
      };
    case actionType.OPERATIONS_TYPES:
      return {
        ...state,
        type: {
          content: action.payload,
          isFetching: false,
          error: null
        }
      };
    case actionType.OPERATIONS_FOR_PRINT:
      return {
        ...state,
        print: {
          content: action.payload,
          isFetching: false,
          error: null
        }
      };
    case actionType.OPERATIONS_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload
        }
      };

    default:
      return state;
  }
};
