import React, { useState } from "react";
import {
  Button,
  Table,
  TableCell,
  TableHeaderCell,
  TableRow
} from "semantic-ui-react";
import _ from "lodash";

const PickedBinsGroupRow = ({ groupStat }) => {
  const [isExpanded, setExpanded] = useState(false);
  const numOfWorkers = Object.values(groupStat.employees);
  return (
    <>
      <Table.Row>
        <TableCell width={1}>
          <Button
            active={isExpanded}
            onClick={() => setExpanded(!isExpanded)}
            compact
            icon="dropdown"
          />
        </TableCell>
        <Table.Cell width={1} textAlign="center">
          {groupStat.id}
        </Table.Cell>
        <Table.Cell width={4}>{groupStat.name}</Table.Cell>
        <Table.Cell width={1} textAlign="center">
          {numOfWorkers.length}
        </Table.Cell>
        <Table.Cell width={2}>{groupStat.location}</Table.Cell>
        <Table.Cell positive width={2}>
          {_.ceil(groupStat.bins, 2)}
        </Table.Cell>
        <Table.Cell textAlign="center" width={1}>
          {_.ceil(groupStat.bins / numOfWorkers.length, 2)}
        </Table.Cell>
      </Table.Row>
      {isExpanded && (
        <TableRow>
          <TableCell colSpan={12} style={{ paddingLeft: "8.3%" }}>
            <Table unstackable>
              <Table.Header>
                <TableRow>
                  <TableHeaderCell>ID</TableHeaderCell>
                  <TableHeaderCell>Worker Name</TableHeaderCell>
                  <TableHeaderCell>Location</TableHeaderCell>
                  <TableHeaderCell>No. of Bins</TableHeaderCell>
                </TableRow>
              </Table.Header>
              <Table.Body>
                {Object.values(groupStat.employees).map(employee => {
                  return (
                    <TableRow>
                      <TableCell width={1}>{employee.id}</TableCell>
                      <TableCell width={4}>{employee.name}</TableCell>
                      <TableCell width={4}>
                        {_.uniq(employee.location).toString()}
                      </TableCell>
                      <TableCell width={4}>
                        {parseFloat(employee.bins).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </Table.Body>
            </Table>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default PickedBinsGroupRow;
