import { actionType } from "../../constants/Contractors";
import { keyBy } from "lodash";

const initialState = {
  list: {
    content: [],
    isFetching: false
  },
  data: { content: [] },
  isFetching: false,
  error: null,
  employee: {
    content: [],
    error: null,
    isFetching: false
  }
};

export default (state = initialState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case actionType.FETCH_CONTRACTORS_LIST_START:
    case actionType.DELETE_CONTRACTORS_START:
    case actionType.ARCHIVE_CONTRACTORS_START:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: true
        }
      };
    case actionType.FETCH_CONTRACTORS_START: {
      return {
        ...state,
        isFetching: true
      };
    }
    case actionType.FETCH_CONTRACTORS: {
      return {
        ...state,
        data: {
          ...action.payload
        },
        isFetching: false
      };
    }
    case actionType.ADD_CONTRACTORS: {
      const newTotalElements = state.data.totalElements + 1;
      const dataContent = [action.payload, ...state.data.content];
      const newListTotalElements = state.list.totalElements + 1;
      const listContent = [action.payload, ...state.list.content];

      return {
        ...state,
        data: {
          ...state.data,
          content: dataContent,
          totalElements: newTotalElements,
          totalPages: Math.ceil(newTotalElements / state.data.size)
        },
        list: {
          ...state.list,
          content: listContent,
          totalElements: newListTotalElements
        }
      };
    }
    case actionType.UPDATE_CONTRACTORS: {
      return {
        ...state,
        data: {
          ...initialState.data,
          content: state.data.content.map(item => {
            if (item.id === action.payload.id) {
              return action.payload;
            }
            return item;
          })
        },
        list: {
          ...state.list,
          content: state.list.content.map(item => {
            if (item.id === action.payload.id) {
              return action.payload;
            }
            return item;
          })
        }
      };
    }

    case actionType.DELETE_CONTRACTORS:
    case actionType.ARCHIVE_CONTRACTORS: {
      const deletedElementsIdsMap = keyBy(action.payload);

      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.filter(e => !deletedElementsIdsMap[e.id]),
          isFetching: false
        },
        error: null
      };
    }
    case actionType.DELETE_CONTRACTOR: {
      const newTotalElements = state.data.totalElements - 1;

      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.filter(e => e.id !== action.payload),
          totalElements: newTotalElements,
          totalPages: Math.ceil(newTotalElements / state.data.size)
        },
        list: {
          ...state.list,
          content: state.list.content.filter(e => e.id !== action.payload)
        },
        error: null
      };
    }

    case actionType.FETCH_CONTRACTORS_LIST:
      return {
        ...state,
        list: { ...action.payload, isFetching: false }
      };
    case actionType.FETCH_CONTRACTORS_LIST_FAIL:
      return {
        ...state,
        list: { ...state.list, isFetching: false },
        error: action.payload
      };
    case actionType.FETCH_CONTRACTORS_FAIL:
    case actionType.ADD_CONTRACTORS_FAIL:
    case actionType.ARCHIVE_CONTRACTORS_FAIL:
    case actionType.UPDATE_CONTRACTORS_FAIL:
    case actionType.DELETE_CONTRACTOR_FAIL:
    case actionType.DELETE_CONTRACTORS_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    }
    case actionType.CLEAR_CONTRACTORS_ERROR:
      return {
        ...state,
        error: null
      };
    case actionType.GET_EMPLOYEE_LIST_START: {
      return {
        ...state,
        employee: { ...state.employee, error: null, isFetching: true }
      };
    }
    case actionType.GET_EMPLOYEE_LIST_SUCCESS: {
      return {
        ...state,
        employee: { ...action.payload, isFetching: false }
      };
    }
    case actionType.GET_EMPLOYEE_LIST_ERROR: {
      return {
        ...state,
        employee: {
          ...state.employee,
          error: action.payload,
          isFetching: false
        }
      };
    }

    case actionType.SELECT_EMPLOYEES: {
      const field = action.payload.fieldName;
      const gotEmployees = state[field];
      const newEmployees = [
        ...action.payload.employees.map(item => {
          const gotEmployee = gotEmployees.find(
            employee => employee.employee.id === item
          );
          return {
            ...(gotEmployee || {}),
            employee: {
              ...state.employee.content.find(employee => employee.id === item)
            }
          };
        })
      ];

      return {
        ...state,
        [field]: newEmployees,
        validations: { ...state.validations, [field]: true }
      };
    }
    case actionType.SELECT_SINGLE_EMPLOYEE: {
      const field = action.payload.fieldName;
      return {
        ...state,
        [field]: {
          ...state.employee.content.find(
            employee => employee.id === action.payload.employee
          )
        },
        validations: { ...state.validations, [field]: true }
      };
    }
    default:
      return state;
  }
};
