import React, { useEffect, useState } from "react";
import {
  Form,
  Segment,
  Button,
  Icon,
  Input,
  Container,
  Popup
} from "semantic-ui-react";
import { connect } from "react-redux";
import { useFormikContext, FieldArray } from "formik";
import styles from "./BatchNumberInput.module.css";
import { isEmpty } from "lodash";

const BatchNumberInputAdd = ({ onChange, suffix }) => {
  const { values, setFieldValue } = useFormikContext();
  const [addValue, setAddValue] = useState([]);
  useEffect(() => {
    if (isEmpty(addValue)) {
      setAddValue([""]);
    }
  }, [values.batchNumbers]);

  return (
    <Form className={styles.root}>
      <FieldArray
        name="batchNumbers"
        render={arrayHelpers => (
          <div>
            {addValue.map((formItem, index) => (
              <div className={styles.inputButtonWrapper}>
                <div className={styles.inputWrapper} key={index}>
                  <Segment className={styles.ingredientSection}>
                    <Input
                      value={formItem}
                      name={`batchNumbers.${index}`}
                      className={styles.batchNumberInput}
                      onChange={(_, { value }) => {
                        addValue[index] = value;
                        setAddValue([...addValue]);
                        setFieldValue(
                          "batchNumbers",
                          values.batchNumbers
                            ? [...values.batchNumbers]
                            : [value]
                        );
                        onChange(
                          [...(values.batchNumbers || []), ...addValue],
                          "chemicalBatchNumbers",
                          suffix
                        );
                      }}
                    />
                    <Popup
                      content="Delete active batch number"
                      position="bottom center"
                      inverted
                      trigger={
                        <Icon
                          name="trash alternate"
                          className={styles.removeActiveIngredient}
                          onClick={() => {
                            const filteredValues = addValue.filter(
                              (item, indx) => indx !== index
                            );
                            setAddValue(filteredValues);
                            return onChange(
                              [
                                ...(values.batchNumbers || []),
                                ...filteredValues
                              ],
                              "chemicalBatchNumbers",
                              suffix
                            );
                          }}
                        />
                      }
                    />
                  </Segment>
                </div>
              </div>
            ))}
            <Container fluid className={styles.textButtonContainer}>
              <Button
                type="button"
                onClick={() => {
                  return setAddValue([...addValue, ""]);
                }}
                className={styles.textButton}
              >
                <Icon name="plus" />
                Add batch number
              </Button>
            </Container>
          </div>
        )}
      />
    </Form>
  );
};
const mapStateToProps = state => {
  return {
    batchNumbers: state.spray.batchNumbers
  };
};

export default connect(mapStateToProps)(BatchNumberInputAdd);
