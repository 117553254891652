import React from "react";
import { Grid, Dropdown, Form } from "semantic-ui-react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { setSheds } from "../../../actions/SprayDiary/common";
import { shedsListSelector } from "../../../selectors/sheds";
import styles from "./createSprayTask.module.css";

const ShedsList = ({ actions, shedId }) => {
  const sheds = useSelector(shedsListSelector);
  return (
    <Grid.Row>
      <Grid.Column>
        <Form.Field>
          <label>Shed</label>
          <Dropdown
            closeOnChange
            closeOnBlur
            selection
            fluid
            placeholder="Select shed"
            name="shed"
            scrolling
            clearable
            className={styles.shedsDropdown}
            openOnFocus
            disabled={!sheds.length}
            loading={!sheds.length}
            options={sheds}
            value={shedId || null}
            onChange={(_, data) => {
              return actions.setSheds(data.value !== "" ? data.value : null);
            }}
          />
        </Form.Field>
      </Grid.Column>
    </Grid.Row>
  );
};

const mapStateToProps = ({ sprayDiary: { shedId } }) => ({
  shedId
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setSheds
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(ShedsList);
