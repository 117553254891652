import { getTaskPlannedOrEndDate, TaskDateType } from '../../utils/tasksUtils';
import { Icon, Popup } from 'semantic-ui-react';
import styles from './TableSprayTask.module.css';
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

const TaskPlannedOrEndDate = ({ task }) => {
  const taskPlannedOrEndDate = getTaskPlannedOrEndDate(task);
  return (
    <Popup
      className={styles.popup}
      trigger={
        <span>
          <span>
            {taskPlannedOrEndDate.type === TaskDateType.PLANNED ? (
              <Icon name="clock outline" color="brown" />
            ) : (
              <Icon name="calendar outline" />
            )}
          </span>
          {moment(taskPlannedOrEndDate.date).format('DD/MM/YYYY')}
        </span>
      }
      content={taskPlannedOrEndDate.type === TaskDateType.PLANNED ? 'Planned date' : 'End date'}
      size="small"
      basic
    />
  );
};

TaskPlannedOrEndDate.propTypes = {
  task: PropTypes.object.isRequired,
};

export default TaskPlannedOrEndDate;
