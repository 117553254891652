import React, { memo, useMemo } from "react";
import PropTypes from "prop-types";
import { InfoWindow } from "@react-google-maps/api";
import { capitalize } from "lodash";
import moment from "moment";

import styles from "./GoogleMaps.module.css";
import { toBarcodeNumberPartFormat } from "../../utils/generalBarcodes";

const TagDetailPopup = ({ tags, clearInfo }) => {
  const mappedTags = useMemo(
    () =>
      tags.map(tag => ({
        rfidTagId: tag.rfidTagId,
        id: tag.id,
        farmName: tag.area.farm.name,
        areaType: capitalize(tag.area.type.toLowerCase()),
        areaName: tag.area.name,
        scannedDate: moment(tag.createdAt).format("YYYY-MM-DD"),
        worker: `${tag.creator.firstName} ${tag.creator.lastName}`
      })),
    [tags]
  );

  return (
    <InfoWindow
      onCloseClick={clearInfo}
      position={tags[0].position}
      options={{ pixelOffset: { height: -30, width: 0 } }}
    >
      <div className={styles.infoHolder}>
        {mappedTags.map((tag, i) => (
          <>
            <div key={`tag_info_${i}`}>
              <div>
                <b>Barcode ID: </b>{" "}
                {tag.rfidTagId || toBarcodeNumberPartFormat(tag.id)}
              </div>
              <div>
                <b>Farm: </b> {tag.farmName}
              </div>
              <div>
                <b>{tag.areaType}: </b> {tag.areaName}
              </div>
              <div>
                <b>Scanned At: </b> {tag.scannedDate}
              </div>
              <div>
                <b>Worker: </b> {tag.worker}
              </div>
            </div>
          </>
        ))}
      </div>
    </InfoWindow>
  );
};

TagDetailPopup.propTypes = {
  tags: PropTypes.array,
  clearInfo: PropTypes.func
};

export default memo(TagDetailPopup);
