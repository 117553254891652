import { createSelector } from "reselect";
import get from "lodash/get";
import { chemicalsList } from "selectors/chemicals";

const harvestUnits = state => state.harvestUnitsControl.list.content;
const harvestUnitsOptions = state => state.harvestUnitsControl.filtersOptions;
const employeesList = state => state.employee.list.content;
const employeesArchivedList = state => state.employee.archivedList.content;
export const harvestUnitsIdFilterOptions = createSelector(
  [harvestUnits],
  harvestUnits => ({
    options: harvestUnits.map(({ id }) => ({
      key: id,
      label: `${id}`,
      value: id
    }))
  })
);

export const rowFilterOptions = createSelector(
  [harvestUnits],
  harvestUnits => ({
    options: [
      ...new Map(
        harvestUnits
          .map(({ row }) =>
            row
              ? {
                  key: row,
                  label: `${row}`,
                  value: row
                }
              : []
          )
          .map(item => [item["key"], item])
      ).values()
    ]
  })
);
export const supervisorsFilterOptions = createSelector(
  [employeesList],
  employees => ({
    options: employees
      .filter(item => item.type === "SUPERVISOR")
      .map(({ id, firstName, lastName }) => ({
        key: id,
        label: `${firstName} ${lastName}`,
        value: id
      }))
  })
);
export const workersFilterOptions = createSelector(
  [employeesList],
  employees => ({
    options: employees
      .filter(item => item.type === "WORKER")
      .map(({ id, firstName, lastName }) => ({
        key: id,
        label: `${firstName} ${lastName}`,
        value: id
      }))
  })
);
export const allWorkersFilterOptions = createSelector(
  [employeesList, employeesArchivedList],
  (employees, archivedEmployees) => {
    return {
      options: [...employees, ...archivedEmployees]
        .filter(item => item.type === "WORKER")
        .map(({ id, firstName, lastName }) => ({
          key: id,
          label: `${firstName} ${lastName}`,
          value: id
        }))
    };
  }
);

export const harvestUnitOptionsSelector = createSelector(
  [harvestUnitsOptions],
  units => {
    return {
      options: units.content.map(({ id, name, netWeight }) => ({
        key: id,
        label: `${name} ${netWeight}Kg`,
        value: id
      }))
    };
  }
);
export const harvestUnitSelector = createSelector(
  [harvestUnitsOptions],
  units => {
    return {
      options: units.content.map(({ id, name, netWeight }) => ({
        key: id,
        text: `${name} ${netWeight}Kg`,
        value: id
      }))
    };
  }
);

export const batchIdOptions = createSelector([harvestUnits], batchIds => {
  return {
    options: batchIds.map(
      ({ batch }) =>
        batch && {
          key: batch.id,
          label: `${batch.id}`,
          value: batch.id
        }
    )
  };
});
export const internalBinIdOptions = createSelector(
  [harvestUnits],
  harvestUnits => {
    return {
      options: harvestUnits
        .filter(item => item.internalBinId)
        .map(({ internalBinId }) => ({
          key: internalBinId,
          label: `${internalBinId}`,
          value: internalBinId
        }))
    };
  }
);
export const internalBinIdSelector = createSelector(
  [harvestUnits],
  harvestUnits => {
    return {
      options: harvestUnits
        .filter(item => item.internalBinId)
        .map(({ internalBinId }) => ({
          key: internalBinId,
          text: `${internalBinId}`,
          value: internalBinId
        }))
    };
  }
);
export const generalTaskIdSelector = createSelector(
  [harvestUnits],
  harvestUnits => {
    return {
      options: harvestUnits
        .filter(item => item.generalTaskId)
        .map(({ generalTaskId }) => ({
          key: generalTaskId,
          text: `${generalTaskId}`,
          value: generalTaskId
        }))
    };
  }
);
