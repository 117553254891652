import React from "react";
import { Table } from "semantic-ui-react";
import { get } from "lodash";
import moment from "moment";
import { isEmptyValue } from "../../../../../utils/tasksUtils";
import styles from "../Sidebar.module.css";

const GeneralInformationSection = ({ group, employeesList }) => {
  const employees = group.employeeIds.map(employeeId => {
    return (
      employeesList &&
      employeesList.options.find(
        employeeObj => employeeObj.value === employeeId
      )
    );
  });

  return (
    <>
      <h1 className={styles.sectionHeader} style={{ marginTop: "16px" }}>
        General Information
      </h1>
      <Table className={styles.sectionTable} definition fixed>
        <Table.Body>
          <Table.Row>
            <Table.Cell width={6}>Creation Date</Table.Cell>
            <Table.Cell width={10}>
              {moment(isEmptyValue(get(group, "createdAt"))).format(
                "DD/MM/YYYY"
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Created by</Table.Cell>
            <Table.Cell>
              {isEmptyValue(get(group.creator, "firstName"))}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Number of workers</Table.Cell>
            <Table.Cell>{get(group, "employeeIds.length")}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell verticalAlign="top">{`Worker's names`}</Table.Cell>
            <Table.Cell>
              {employees.map(employee => (
                <div
                  key={employee.value + employee.label}
                  className={styles.employeeListItem}
                >
                  {employee.label}
                </div>
              ))}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
};

export default GeneralInformationSection;
