import React, { useState } from "react";
import {
  Checkbox,
  Form,
  Icon,
  Input,
  Popup,
  Segment,
  Dropdown
} from "semantic-ui-react";
import {
  useFormikContext,
  Field,
  useField,
  ErrorMessage,
  FieldArray
} from "formik";
import { get } from "lodash";
import { COUNTRY_OPTIONS_WITH_CODE } from "../../../../../js/common/countries_with_code";
import { VACCINATION_STATUS } from "../../../../../js/common/vaccination_status";
import CollapsibleBlock from "../../../../../components/CollapsibleBlock";
import DatePicker from "../../../../../components/DatePicker";
import { useSelector } from "react-redux";
import { ShowRateHistory } from "../../../components/ShowRateHistory/ShowRateHistory";
import styles from "../AddUpdateEmployeeModal.module.css";

const EmploymentSection = ({ isEdit, employee, editSectionIndex, Can }) => {
  const {
    values,
    errors,
    touched,
    setValues,
    setFieldValue,
    setFieldTouched
  } = useFormikContext();
  const [field, meta] = useField("ratePerHour");

  const {
    list: { content }
  } = useSelector(state => state.contractors, undefined);
  const options = content.map(content => ({
    key: content.id,
    text: content.name,
    value: content.id,
    id: content.id
  }));

  return (
    <Segment>
      <CollapsibleBlock
        className={styles.collapseBlock}
        title="Employment"
        defaultActive={!editSectionIndex || editSectionIndex === 1}
      >
        <Form widths="equal">
          <Form.Group>
            <Form.Field
              className={styles.dataPickerField}
              disabled={isEdit}
              name="startDate"
              required
              width={8}
            >
              <label>Start date</label>
              <DatePicker
                value={get(values, "startDate")}
                onChange={newValue => {
                  setFieldValue("startDate", newValue);
                }}
              />
            </Form.Field>
            <Form.Field width={1}>
              {isEdit && (
                <span className={styles.infoIconContainer}>
                  <Popup
                    size="small"
                    basic
                    inverted
                    position="right center"
                    disabled={false}
                    trigger={
                      <Icon disabled={false} size="small" name="info circle" />
                    }
                    content={
                      "Please contact TieUp Farming's support if you need the start date to be changed for this employee"
                    }
                  />
                </span>
              )}
            </Form.Field>
            <Field name="visaType">
              {({ field, meta }) => (
                <Form.Input
                  width={8}
                  label="Visa type"
                  error={meta.touched && meta.error}
                  {...field}
                />
              )}
            </Field>
          </Form.Group>
          <Form.Group>
            <Form.Field
              className={styles.dataPickerField}
              name="finishDate"
              width={8}
            >
              <label>Finish date</label>
              <DatePicker
                value={get(values, "finishDate")}
                onChange={newValue => {
                  setFieldValue("finishDate", newValue);
                }}
              />
            </Form.Field>
            <Form.Field width={1} />
            <Field name="immicardNumber">
              {({ field, meta }) => (
                <Form.Input
                  width={8}
                  label="Visa grant/immicard number"
                  error={meta.touched && meta.error}
                  {...field}
                />
              )}
            </Field>
          </Form.Group>
          <Form.Group>
            <Field name="passportNumber">
              {({ field, meta }) => (
                <Form.Input
                  width={8}
                  label="Passport number"
                  error={meta.touched && meta.error}
                  {...field}
                />
              )}
            </Field>
            <Form.Field width={1} />
            <Form.Field
              className={styles.dataPickerField}
              name="vevoCheck"
              width={8}
            >
              <label>Date of last VEVO check</label>
              <DatePicker
                value={get(values, "vevoCheck")}
                onChange={newValue => {
                  setFieldValue("vevoCheck", newValue);
                }}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field width={8}>
              <label>Country of residence</label>
              <Dropdown
                className={styles.contractorField}
                clearable
                value={
                  COUNTRY_OPTIONS_WITH_CODE.find(
                    ({ value }) => values.countryOfResidence === value
                  )?.text || ""
                }
                text={
                  COUNTRY_OPTIONS_WITH_CODE.find(
                    ({ value }) => values.countryOfResidence === value
                  )?.text || ""
                }
                options={COUNTRY_OPTIONS_WITH_CODE}
                selection
                search
                onChange={({ target }, { value: key }) => {
                  const country = COUNTRY_OPTIONS_WITH_CODE.find(
                    ({ value }) => key === value
                  );
                  country
                    ? setFieldValue("countryOfResidence", country.value)
                    : setValues({
                        ...values,
                        countryOfResidence: ""
                      });
                }}
              />
            </Form.Field>
            <Form.Field width={1} />
            <Form.Field width={8}>
              <label>Vaccination status</label>
              <Dropdown
                className={styles.contractorField}
                clearable
                options={VACCINATION_STATUS.sort((a, b) =>
                  a.key.localeCompare(b.key)
                )}
                selection
                value={
                  VACCINATION_STATUS.find(
                    ({ value }) => values.vaccinationStatus == value
                  )?.text || ""
                }
                text={
                  VACCINATION_STATUS.find(
                    ({ value }) => values.vaccinationStatus == value
                  )?.text || ""
                }
                search
                onChange={({ target }, { value: key }) => {
                  const status = VACCINATION_STATUS.find(
                    ({ value }) => key === value
                  );
                  status
                    ? setFieldValue("vaccinationStatus", status.value, true)
                    : setValues({
                        ...values,
                        vaccinationStatus: null
                      });
                }}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field width={8}>
              <label>Select contractor</label>
              <Dropdown
                className={styles.contractorField}
                clearable
                options={options.sort((a, b) => a.text.localeCompare(b.text))}
                value={(values.contractor && values.contractor.name) || ""}
                text={get(values.contractor, "name") || ""}
                selection
                search
                onChange={({ target }, { value: id }) => {
                  const option = options.find(({ value }) => id === value);
                  return option
                    ? (setFieldValue("contractor", {
                        name: option.text,
                        id: option.id
                      }),
                      setFieldValue(
                        "ratePerHour",
                        content.find(el => el.id === option.value).rate
                      ),
                      setFieldValue("contractorFollowed", true))
                    : setValues({
                        ...values,
                        contractor: {
                          name: "",
                          id: ""
                        }
                      });
                }}
              />
            </Form.Field>
            <Form.Field width={1} />
            <Form.Field
              width={4}
              disabled={values.followContractorsRate}
              className={styles.customFieldLabel}
              required
            >
              {isEdit ? (
                <Can I={"rate_manage"} a="employees">
                  <label>Rate</label>
                  <Field
                    as={Input}
                    error={meta.touched && meta.error}
                    name="ratePerHour"
                    labelPosition="right"
                    label={`$/hr`}
                    value={get(values, "ratePerHour")}
                    onChange={(_, { value }) => {
                      if (!isNaN(value)) {
                        setFieldValue("ratePerHour", value);
                      }
                    }}
                  />
                </Can>
              ) : (
                <>
                  <label>Rate</label>
                  <Field
                    as={Input}
                    error={meta.touched && meta.error}
                    name="ratePerHour"
                    labelPosition="right"
                    label={`$/hr`}
                    value={get(values, "ratePerHour")}
                    onChange={(_, { value }) => {
                      if (!isNaN(value)) {
                        setFieldValue("ratePerHour", value);
                      }
                    }}
                  />
                </>
              )}
            </Form.Field>
            <Form.Field width={4}>
              {isEdit ? (
                <Can I={"rate_manage"} a="employees">
                  <div
                    style={{
                      paddingTop: "15px",
                      display: "flex",
                      alignItems: "center",
                      height: "100%"
                    }}
                  >
                    <Checkbox
                      name="followContractorsRate"
                      disabled={!values.contractor || !values.contractor.id}
                      checked={get(values, "followContractorsRate")}
                      label="Follow contractor’s rate"
                      onChange={() => {
                        !values.followContractorsRate &&
                          setFieldValue(
                            "ratePerHour",
                            content.find(el => el.id === values.contractor.id)
                              .rate
                          ); // contractor rate
                        setFieldValue(
                          "followContractorsRate",
                          !values.followContractorsRate
                        );
                      }}
                    />
                  </div>
                </Can>
              ) : (
                <div
                  style={{
                    paddingTop: "15px",
                    display: "flex",
                    alignItems: "center",
                    height: "100%"
                  }}
                >
                  <Checkbox
                    name="followContractorsRate"
                    disabled={!values.contractor || !values.contractor.id}
                    checked={get(values, "followContractorsRate")}
                    label="Follow contractor’s rate"
                    onChange={() => {
                      !values.followContractorsRate &&
                        setFieldValue(
                          "ratePerHour",
                          content.find(el => el.id === values.contractor.id)
                            .rate
                        ); // contractor rate
                      setFieldValue(
                        "followContractorsRate",
                        !values.followContractorsRate
                      );
                    }}
                  />
                </div>
              )}
            </Form.Field>
          </Form.Group>
        </Form>
        <Can I={"rate_manage"} a="employees">
          {isEdit && (
            <ShowRateHistory rateHistory={employee.employeeRateHistory} />
          )}
        </Can>
      </CollapsibleBlock>
    </Segment>
  );
};

export default EmploymentSection;
