import React, { Component } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Layout from "../../components/Layout";
import { Confirm, Icon, Sidebar } from "semantic-ui-react";
import HeaderFarms from "../../components/HeaderFarms";
import TableFarm from "../../components/TableFarm";
import BlockDetails from "../../components/BlockDetails";
import Loader from "../../components/Loader";
import AddFarmPrompt from "./AddFarmPrompt";
import {
  archiveFarm,
  deleteFarm,
  getFarm,
  getFarmSummary,
  handleDeleteArea,
  setActiveBlock,
  setFarmsSearch,
  setSummaryField,
  toggleFarmListShowArchive,
  toggleOpenFarmTable
} from "../../actions/Farms/farms";

import { clearError } from "../../actions/Farms/addNewFarm";

import { archiveArea, deleteArea } from "../../actions/Farms/areas";
import { checkAPIStatus } from "../../actions/Common";
import { farmsSelector } from "../../selectors/farms";
import asyncForEach from "../../utils/asyncForEach";
import styles from "./Farms.module.css";
import FarmSidebar from "./FarmSidebar";
import PatchSidebar from "./PatchSidebar";

// constants
const SIDEBAR_FARM = "SIDEBAR_FARM";
const SIDEBAR_PATCH = "SIDEBAR_PATCH";

class Farms extends Component {
  state = {
    activeBlock: null,
    farmToDelete: null,
    showSidebar: false,
    showConfirm: false,
    confirmAction: null,
    confirmMessage: "",
    sidebarType: null,
    sidebarData: null
  };

  componentDidMount() {
    this.getFarmsData();
    document.body.addEventListener("click", this.handleBlur);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { attributes, match, farmsAttributes } = this.props;

    if (
      farmsAttributes.activeBlock &&
      prevProps.farmsAttributes.activeBlock !== farmsAttributes.activeBlock
    ) {
      this.getActiveBlock();
    }

    if (prevProps.attributes !== attributes) {
      this.getActiveBlock();
    }

    if (prevProps.match.params.farmId !== match.params.farmId) {
      this.getFarmsData();
    }
  }

  componentWillUnmount() {
    const { actions } = this.props;
    actions.clearError();
    document.body.removeEventListener("click", this.handleBlur);
  }

  showConfirm = (message, action) => {
    this.setState({
      showConfirm: true,
      confirmAction: action,
      confirmMessage: message
    });
  };

  getFarmsData = () => {
    const { actions, match } = this.props;
    const selectedFarmId = match.params.farmId;
    if (selectedFarmId) {
      actions.getFarmSummary(selectedFarmId);
    }
  };

  getActiveBlock = () => {
    const { farmsAttributes: attributes, farms } = this.props;
    let activeBlock = null;

    const targetFarm =
      farms &&
      (attributes.activeBlock
        ? farms.find(farm => farm.id === attributes.activeBlock.farmId)
        : null);

    if (attributes.activeBlock) {
      const sameArea = area => area.id === attributes.activeBlock.id;
      const areaIsPatch = area => area.type === "PATCH";
      activeBlock = targetFarm
        ? targetFarm.areas.find(area => sameArea(area) && areaIsPatch(area))
        : null;
    }

    this.setState({
      activeBlock,
      showSidebar: !!activeBlock
    });
  };

  toggleOpen = (farmId, open) => {
    const { actions } = this.props;
    actions.toggleOpenFarmTable({ farmId, open });
  };

  toggleShowFarm = (farmId, fieldValue) => {
    const { actions, farms } = this.props;
    if (farmId) {
      actions.setSummaryField({ farmId, fieldName: "show", fieldValue });
    } else {
      farms.forEach(farm => {
        actions.setSummaryField({
          farmId: farm.id,
          fieldName: "show",
          fieldValue
        });
      });
    }
  };

  setActiveBlock = (activeBlock, farmId) => {
    const {
      actions,
      history,
      location,
      attributes: { activeBlock: activeBlockCurrent }
    } = this.props;
    if (
      activeBlock &&
      (!activeBlockCurrent || activeBlock.id !== activeBlockCurrent.id)
    ) {
      actions.setActiveBlock({
        parentId: activeBlock.parentId,
        id: activeBlock.id,
        farmId
      });
    } else if (!activeBlock) {
      history.replace(location.pathname);
      actions.setActiveBlock(null);
    } else {
      this.handleHideBlock();
    }
  };

  handleAddBlock = (event, farmId) => {
    const { history } = this.props;
    event.stopPropagation();
    history.push(`/farms/edit_farm/${farmId}/add_block`);
  };

  handleEdit = (event, farmId, block) => {
    const { history } = this.props;
    event.stopPropagation();
    if (!block) {
      history.push(`/farms/edit_farm/${farmId}`);
    } else {
      const blockId = _.get(block, "parent.id") || block.id;
      history.push(`/farms/edit_farm/${farmId}/${blockId}`);
    }
  };

  hideConfirm = () => {
    this.setState({
      showConfirm: false,
      confirmAction: null,
      confirmMessage: ""
    });
  };

  archiveArea = async (area, archive = true) => {
    const { actions } = this.props;

    if (area.hasSubAreas) {
      await asyncForEach(this.getPatchesForArea(area), async patch => {
        if (patch.archived !== archive) {
          actions.archiveArea(patch.id, archive);
        }
      });
    }

    if (!archive) {
      area.parent && actions.archiveArea(area.parent.id, archive);
      actions.archiveFarm(area.farm.id, archive);
    }

    actions.archiveArea(area.id, archive);
  };

  archiveFarm = async (farm, archive = true) => {
    const { actions } = this.props;

    await asyncForEach(this.getAreasForFarm(farm), async area => {
      if (area.archived !== archive) {
        actions.archiveArea(area.id, archive);
      }
    });

    actions.archiveFarm(farm.id, archive);
  };

  getPatchesForArea = area => {
    const { farms } = this.props;
    return farms
      .flatMap(farm => farm.areas)
      .filter(a => a.parent && a.parent.id === area.id);
  };

  getAreasForFarm = farm => {
    const { farms } = this.props;
    return farms.flatMap(farm => farm.areas).filter(a => a.farm.id === farm.id);
  };

  handleArchiveArea = (area, archive = true) => {
    this.showConfirm(
      `Are you sure you want to ${archive ? "archive" : "activate"} ${
        area.type === "BLOCK" ? "block" : "patch"
      } ${area.name}?`,
      async () => {
        await this.archiveArea(area, archive);
      }
    );
  };

  handleArchiveFarm = (farm, archive = true) => {
    this.showConfirm(
      `Are you sure you want to  ${archive ? "archive" : "activate"} farm ${
        farm.name
      }?`,
      async () => {
        await this.archiveFarm(farm, archive);
      }
    );
  };

  handleDeleteFarm = farm => {
    const { actions, history, route } = this.props;
    this.showConfirm(
      `Are you sure you want to delete farm ${farm.name}?`,
      async () => {
        await actions.deleteFarm(farm.id);
        history.replace(route.href);
      }
    );
  };

  handleFarmClick = farm => {
    this.setState({
      sidebarType: SIDEBAR_FARM,
      showSidebar: !this.state.showSidebar,
      sidebarData: farm
    });
  };

  confirmApproved = () => {
    const { confirmAction } = this.state;
    confirmAction && confirmAction();
    this.hideConfirm();
  };

  handleHideBlock = () => {
    this.setState({ showSidebar: false });
  };

  setActiveFarm = farmId => {
    const { route, history, actions } = this.props;
    actions.setSummaryField({
      farmId,
      fieldName: "show",
      fieldValue: true
    });
    this.setState({ showSidebar: false });
    history.push(`${route.href}/${farmId}`);
  };

  handleBlur = event => {
    if (
      !(
        event.target.closest("#sidebarHolder") || event.target.closest(".modal")
      )
    ) {
      this.setState({ showSidebar: false });
    }
  };

  setActivePatch = (patch, farm) => {
    if (_.isEqual(patch, this.state.sidebarData)) {
      this.setState({
        sidebarType: null,
        showSidebar: false,
        sidebarData: null
      });
    } else {
      const blockAreaSize = patch.hasSubAreas
        ? farm.areas.reduce((prev, area) => {
            if (
              area.type === "PATCH" &&
              !area.archived &&
              area.parent.id === patch.id
            ) {
              return prev + area.size;
            }

            return prev;
          }, 0)
        : patch.size;

      this.setState({
        sidebarType: SIDEBAR_PATCH,
        showSidebar: true,
        sidebarData: {
          ...patch,
          size: blockAreaSize
        }
      });
    }
  };

  render() {
    const {
      activeBlock,
      showSidebar,
      sidebarType,
      showConfirm,
      confirmMessage,
      sidebarData
    } = this.state;
    const {
      route,
      location,
      farms,
      match,
      showArchived,
      isFetching,
      summary,
      search,
      actions
    } = this.props;
    const selectedFarmId = match.params.farmId
      ? Number(match.params.farmId)
      : null;
    return (
      <Layout
        route={route}
        location={location}
        classForMain={styles.mainHolder}
      >
        <Confirm
          open={showConfirm}
          content={confirmMessage}
          onCancel={this.hideConfirm}
          onConfirm={this.confirmApproved}
          confirmButton="Yes"
        />
        <Sidebar.Pushable className={styles.farmsHolder}>
          <Sidebar
            id="sidebarHolder"
            animation="overlay"
            direction="right"
            visible={showSidebar}
            className={styles.sidebarHolder}
            onHidden={() => this.setActiveBlock()}
          >
            {activeBlock && (
              <BlockDetails
                block={activeBlock}
                changeValue={this.changeValue}
              />
            )}
            {showSidebar && sidebarType === SIDEBAR_FARM && (
              <FarmSidebar farm={sidebarData} data={location} />
            )}
            {showSidebar && sidebarType === SIDEBAR_PATCH && (
              <PatchSidebar patch={sidebarData} />
            )}
            <Icon
              name="close"
              className={styles.buttonClose}
              onClick={this.handleHideBlock}
            />
          </Sidebar>

          <Sidebar.Pusher id="farmsListHolder">
            {!summary ? (
              <Loader />
            ) : search || (summary && summary.length > 0) ? (
              <>
                <HeaderFarms
                  farms={farms}
                  toggleShowFarm={this.toggleShowFarm}
                  selectedFarmId={selectedFarmId}
                  showArchived={showArchived}
                  toggleShowArchived={actions.toggleFarmListShowArchive}
                  search={search}
                  setFarmsSearch={actions.setFarmsSearch}
                  route={route}
                />
                {farms.map(
                  farm =>
                    (!selectedFarmId ||
                      (selectedFarmId && selectedFarmId === farm.id)) &&
                    farm.show && (
                      <TableFarm
                        key={`farm_${farm.id}`}
                        farm={farm}
                        selectedFarmId={selectedFarmId}
                        isFetching={isFetching}
                        toggleOpen={this.toggleOpen}
                        setActiveBlock={this.setActiveBlock}
                        handleAddBlock={this.handleAddBlock}
                        handleArchiveArea={this.handleArchiveArea}
                        handleEdit={this.handleEdit}
                        handleFarmClick={this.handleFarmClick}
                        setActiveFarm={this.setActiveFarm}
                        setActivePatch={this.setActivePatch}
                        handleArchiveFarm={this.handleArchiveFarm}
                        handleDeleteFarm={this.handleDeleteFarm}
                        showArchived={showArchived}
                      />
                    )
                )}
              </>
            ) : (
              <AddFarmPrompt route={route} />
            )}
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Layout>
    );
  }
}

Farms.propTypes = {
  route: PropTypes.object.isRequired,
  farms: PropTypes.array,
  attributes: PropTypes.object,
  farmsAttributes: PropTypes.object,
  actions: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  isFetching: PropTypes.bool,
  showArchived: PropTypes.bool,
  search: PropTypes.any
};

Farms.defaultProps = {
  route: { name: "{%name%}" }
};

function mapStateToProps(state) {
  const {
    farms: {
      data: attributes,
      attributes: farmsAttributes,
      isFetching,
      showArchived,
      search,
      summary
    }
  } = state;
  return {
    farms: farmsSelector(state),
    attributes,
    farmsAttributes,
    isFetching,
    showArchived,
    summary,
    search
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setFarmsSearch,
        toggleFarmListShowArchive,
        toggleOpenFarmTable,
        checkAPIStatus,
        setSummaryField,
        setActiveBlock,
        handleDeleteArea,
        getFarmSummary,
        getFarm,
        deleteArea,
        archiveArea,
        archiveFarm,
        deleteFarm,
        clearError: clearError
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Farms);
