import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  Form,
  Grid,
  Segment,
  Button,
  Icon,
  Header,
  Container,
  Popup
} from "semantic-ui-react";
import { connect } from "react-redux";
import { map, isEmpty } from "lodash";
import { useFormikContext, FieldArray, Field } from "formik";
import styles from "./BatchNumberInput.module.css";

const BatchNumberFieldInSideBar = ({
  item,
  index,
  batchNumbers,
  onChange,
  suffix,
  addFieldShow,
  value,
  checker
}) => {
  const { values, setFieldValue, setValues } = useFormikContext();
  const [newBatchNumberShow, setNewBatchNumberShow] = useState(addFieldShow);
  const shownOptions = batchNumbers.data
    .find(({ data, id }) => id == item.id)
    ?.data.map(batchNumber => ({
      value: batchNumber,
      text: batchNumber,
      key: batchNumber
    }));
  useEffect(() => {
    if (isEmpty(values.batchNumbers)) {
      setNewBatchNumberShow(true);
    }
  }, [values.batchNumbers]);
  return (
    <Form className={styles.root}>
      <Grid>
        <Segment className={styles.ingredientSection}>
          <Header
            floated="left"
            className={styles.ingredientHeader}
            size="small"
          >
            {value}
          </Header>
          <Popup
            content="Delete active batch number"
            position="bottom center"
            inverted
            trigger={
              <Icon
                name="trash alternate"
                className={styles.removeActiveIngredient}
                onClick={() => {
                  const filteredValues = values.batchNumbers.filter(
                    (item, indx) => indx !== index
                  );
                  return onChange(
                    filteredValues,
                    `chemicalBatchNumbers`,
                    suffix
                  );
                }}
              />
            }
          />
        </Segment>
      </Grid>
    </Form>
  );
};
const mapStateToProps = state => {
  return {
    batchNumbers: state.spray.batchNumbers
  };
};

export default connect(mapStateToProps)(BatchNumberFieldInSideBar);
