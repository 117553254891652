export const calcTotalHectare = selectedAreas => {
  const allSizes = selectedAreas.map(a => parseFloat(a.size)).filter(a => a);
  return allSizes.length ? allSizes.reduce((curr, next) => curr + next) : null;
};

export const numericValue = ({ value, defaultValue, fractionDigits = 3 }) => {
  if (!value) return defaultValue;
  if (isNaN(value)) return value;
  return parseFloat(value).toFixed(fractionDigits);
};

export const isEmptyValue = value => {
  return (!value && value !== 0) || (value && value.length === 0) ? '—' : value;
};

export const valueToString = value => {
  return (value && !isNaN(value)) || value === 0 ? value.toString() : value || '';
};

export const TaskDateType = {
  END: 'END',
  PLANNED: 'PLANNED',
};

export const getTaskPlannedOrEndDate = task => {
  if (task.status === 'COMPLETED') {
    const date = task.workingTimes
      .map(wt => wt.date)
      .sort((d1, d2) => d2.localeCompare(d1))
      .shift();
    return {
      type: TaskDateType.END,
      date: date,
    };
  }

  return {
    type: TaskDateType.PLANNED,
    date: task.plannedDate,
  };
};
