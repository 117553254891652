import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Input,
  Segment
} from "semantic-ui-react";
import { Field, useFormikContext } from "formik";
import React from "react";
import styles from "./RecommendationSidebar.module.css";

const ProductsInformationSegment = ({
  chemicalData,
  chemicalIndex,
  isEditing,
  setEditingChemicalIndex,
  setChemicalToDeleteIndex
}) => {
  const formikProps = useFormikContext();

  return (
    <Segment vertical>
      <Grid>
        <Grid.Row>
          <Grid.Column width={14}>
            <Grid>
              <Grid.Row>
                {chemicalData.map(({ editable, ...item }, index) => (
                  <Grid.Column width={(index + 1) % 2 === 0 ? 10 : 6}>
                    <Container fluid>
                      <Header size="tiny" className={styles.chemicalHeader}>
                        {item.title}
                      </Header>
                      {isEditing && editable ? (
                        <Field
                          fluid
                          as={item.as || Input}
                          name={item.key}
                          type={item.type}
                          labelPosition={item.labelPosition}
                          label={item.label}
                        />
                      ) : (
                        <Header size="small" className={styles.chemicalValue}>
                          {item.accessor
                            ? item.accessor(formikProps.values)
                            : item.value}
                        </Header>
                      )}
                    </Container>
                  </Grid.Column>
                ))}
              </Grid.Row>
            </Grid>
          </Grid.Column>
          <Grid.Column width={2}>
            {!isEditing && (
              <>
                <Icon
                  name="edit"
                  className={styles.editIcon}
                  onClick={() => setEditingChemicalIndex(chemicalIndex)}
                />
                <Icon
                  name="trash alternate outline"
                  className={styles.trashIcon}
                  onClick={() => setChemicalToDeleteIndex(chemicalIndex)}
                />
              </>
            )}
          </Grid.Column>
          {isEditing && (
            <Grid.Column width={16}>
              <Divider />
              <Container fluid>
                <Button
                  onClick={() => {
                    setEditingChemicalIndex(null);
                  }}
                  disabled={formikProps.isSubmitting}
                >
                  Cancel
                </Button>
                <Button
                  disabled={!formikProps.dirty || formikProps.isSubmitting}
                  onClick={formikProps.handleSubmit}
                  color="green"
                >
                  Save
                </Button>
              </Container>
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default ProductsInformationSegment;
