import React, { Fragment } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import Logo from "../../../../../components/Logo";
import { Header, Tab, Table, TableBody, TableRow } from "semantic-ui-react";
import Numeric from "../../../../../components/Numeric/Numeric";
import { get, pickBy } from "lodash";
import { minutesToHM, dateRangeToString } from "../../../../../utils/timeUtils";
import styles from "./PrintPurchaseOrder.module.css";
import { matterToUnits } from "utils/constToUnits";
import { uppercaseFirstLetter } from "utils/string";

class PrintPurchaseOrder extends React.Component {
  render() {
    const {
      data,
      printData,
      productsList,
      activeItemsId,
      shedList,
      ref
    } = this.props;
    const activeItem = printData?.content?.content?.find(
      ({ id }) => id === activeItemsId
    );
    const newData = activeItem?.products
      .map(item => {
        const test = item.quantities.map(q => {
          return {
            chemicalId: item.chemicalId,
            manufacturer: item.manufacturer,
            totalCost: item.totalCost,
            quantities: [q],
            products: activeItem.products,
            quantitiesList: item.quantities
          };
        });
        return [...test];
      })
      ?.flat();
    const supplier = activeItem?.supplier;
    const totalCost = activeItem?.totalCost;
    return (
      <div className={styles.printTableWrapper}>
        <div className={styles.printTableRow}>
          <div className={styles.printTableHeader}>
            Purchase receipt - {activeItem?.orderNumber}{" "}
          </div>
          <div className={styles.printTableLogo}>
            <Logo width={140} height={60} />
          </div>
        </div>
        <Table columns="4" basic="very" className={styles.totalRowWrapper}>
          <Table.Header className={styles.totalRowHeaderWrapper}>
            <Table.HeaderCell className={styles.totalRowHeaderContainer}>
              Order Date
            </Table.HeaderCell>
            <Table.HeaderCell className={styles.totalRowHeaderContainer}>
              Delivery Date
            </Table.HeaderCell>
            <Table.HeaderCell className={styles.totalRowHeaderContainer}>
              Suppliers
            </Table.HeaderCell>
            <Table.HeaderCell className={styles.totalRowHeaderContainer}>
              Total price ($)
            </Table.HeaderCell>
          </Table.Header>
          <Table.Body columns="4" className={styles.totalRowValueWrapper}>
            <Table.Cell className={styles.totalRowValueContainer}>
              {moment(activeItem?.orderDate).format("DD/MM/YYYY")}
            </Table.Cell>
            <Table.Cell className={styles.totalRowValueContainer}>
              {moment(activeItem?.deliveryDate).format("DD/MM/YYYY")}
            </Table.Cell>
            <Table.Cell className={styles.totalRowValueContainer}>
              {supplier}
            </Table.Cell>
            <Table.Cell className={styles.totalRowValueContainer}>
              <Numeric
                fractionDigits={totalCost > 1000 ? 0 : 2}
                value={totalCost}
                commaSeparatorOnThousands
                units="$ "
                unitsPosition="left"
              />
            </Table.Cell>
          </Table.Body>
        </Table>
        {newData?.map(item => {
          const quantitiesTotalPrice = item.quantitiesList.reduce(
            (prev, curr) => prev + +curr.unitsNumber * curr.unitSize,
            0
          );
          return (
            <div className={styles.container}>
              <Table basic="very" fixed={true} className={styles.reportTable}>
                <Table.Header className={styles.reportHeaderWrapper}>
                  <Table.Row className={styles.reportHeaderRow}>
                    <Table.Cell as="td" className={styles.reportHeader}>
                      <div>Products</div>
                    </Table.Cell>
                    <Table.Cell as="td" className={styles.reportHeader}>
                      <div>Manufacturer</div>
                    </Table.Cell>
                    <Table.Cell as="td" className={styles.reportHeader}>
                      <div>Batch number</div>
                    </Table.Cell>
                    <Table.Cell as="td" className={styles.reportHeader}>
                      <div>Shed</div>
                    </Table.Cell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {
                    <Table.Row>
                      <Table.Cell className={styles.reportBody} as="td">
                        {
                          productsList.content.find(
                            product => product.id == item.chemicalId
                          )?.name
                        }
                      </Table.Cell>
                      <Table.Cell className={styles.reportBody} as="td">
                        {item.manufacturer}
                      </Table.Cell>
                      <Table.Cell className={styles.reportBody} as="td">
                        {item.quantities.find(q => q)?.batchNumber}
                      </Table.Cell>
                      <Table.Cell className={styles.reportBody} as="td">
                        {
                          shedList.content.find(
                            shed =>
                              shed.id == item.quantities.find(q => q)?.shedId
                          )?.name
                        }
                      </Table.Cell>
                    </Table.Row>
                  }
                </Table.Body>
              </Table>

              <Table
                basic="very"
                fixed={true}
                className={styles.secondreportTable}
              >
                <Table.Header className={styles.secondreportHeaderWrapper}>
                  <Table.Row className={styles.secondreportHeaderRow}>
                    <Table.Cell as="td" className={styles.secondreportHeader}>
                      <div>Type</div>
                    </Table.Cell>
                    <Table.Cell as="td" className={styles.secondreportHeader}>
                      <div>No. of drums</div>
                    </Table.Cell>
                    <Table.Cell as="td" className={styles.secondreportHeader}>
                      <div>Drums</div>
                    </Table.Cell>
                    <Table.Cell as="td" className={styles.secondreportHeader}>
                      <div>Total QTY</div>
                    </Table.Cell>
                    <Table.Cell as="td" className={styles.secondreportHeader}>
                      <div>Price ($/unit)</div>
                    </Table.Cell>
                    <Table.Cell as="td" className={styles.secondreportHeader}>
                      <div>Manuf. date</div>
                    </Table.Cell>
                    <Table.Cell as="td" className={styles.secondreportHeader}>
                      <div>Exp. date</div>
                    </Table.Cell>
                    <Table.Cell as="td" className={styles.secondreportHeader}>
                      <div>Total price ($)</div>
                    </Table.Cell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row className={styles.secondreportRow}>
                    <Table.Cell className={styles.secondreportBody} as="td">
                      {
                        productsList.content.find(
                          product => product.id == item.chemicalId
                        )?.type?.type
                      }
                    </Table.Cell>
                    <Table.Cell className={styles.secondreportBody} as="td">
                      {item.quantities.find(q => q)?.unitsNumber}
                    </Table.Cell>
                    <Table.Cell className={styles.secondreportBody} as="td">
                      {`${
                        item.quantities.find(q => q)?.unitSize
                      } ${matterToUnits(
                        productsList.content.find(
                          product => product.id == item.chemicalId
                        )?.matter
                      )} Drum`}
                    </Table.Cell>
                    <Table.Cell className={styles.secondreportBody} as="td">
                      {`${item.quantities.find(q => q)?.unitsNumber *
                        item.quantities.find(q => q)?.unitSize} ${matterToUnits(
                        productsList.content.find(
                          product => product.id == item.chemicalId
                        )?.matter
                      )}` || "-"}
                    </Table.Cell>
                    <Table.Cell className={styles.secondreportBody} as="td">
                      $
                      {
                        <Numeric
                          fractionDigits={
                            item.totalCost / quantitiesTotalPrice > 1000 ? 0 : 2
                          }
                          value={item.totalCost / quantitiesTotalPrice}
                          commaSeparatorOnThousands
                          units={`/ ${matterToUnits(
                            productsList.content.find(
                              product => product.id == item.chemicalId
                            )?.matter
                          )} `}
                          unitsPosition="right"
                          defaultValue="-"
                        />
                      }
                    </Table.Cell>
                    <Table.Cell className={styles.secondreportBody} as="td">
                      {moment(
                        item.quantities.find(q => q)?.manufacturerDate
                      ).format("DD/MM/YYYY")}
                    </Table.Cell>
                    <Table.Cell className={styles.secondreportBody} as="td">
                      {moment(
                        item.quantities.find(q => q)?.expirationDate
                      ).format("DD/MM/YYYY")}
                    </Table.Cell>
                    <Table.Cell className={styles.secondreportBody} as="td">
                      ${item.totalCost}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>
          );
        })}
      </div>
    );
  }
}

PrintPurchaseOrder.propTypes = {
  title: PropTypes.string,
  data: PropTypes.object.isRequired,
  showExpandedData: PropTypes.bool,
  startDate: PropTypes.any,
  endDate: PropTypes.any
};

export default PrintPurchaseOrder;
