import React from "react";
import PropTypes from "prop-types";
import { Button, Icon, Message } from "semantic-ui-react";
import styles from "./OfflineMessageInfo.module.css";

const OfflineMessageInfo = props => {
  const { message, onClose, color, children } = props;

  return (
    <Message
      className={`${styles.messageInfo} ${props.className}`}
      color={color}
      style={props.style}
    >
      {message}
      {onClose && (
        <Button className="button-text" onClick={onClose}>
          <Icon name="close" />
        </Button>
      )}
      {children}
    </Message>
  );
};
OfflineMessageInfo.defaultProps = {
  message: "",
  color: ""
};
OfflineMessageInfo.propTypes = {
  message: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.any,
  style: PropTypes.any,
  onClose: PropTypes.func,
  children: PropTypes.node
};

export default OfflineMessageInfo;
