import React, { useState } from "react";
import { Field, useFormikContext, FieldArray } from "formik";
import { Form, Input, Segment, Dropdown } from "semantic-ui-react";
import { get } from "lodash";
import { COUNTRY_OPTIONS_WITH_CODE } from "../../../../../js/common/countries_with_code";
import CollapsibleBlock from "../../../../../components/CollapsibleBlock";
import styles from "../AddUpdateEmployeeModal.module.css";
const AddressSection = props => {
  const { values, setFieldValue, setValues } = useFormikContext();
  return (
    <Segment>
      <CollapsibleBlock title="Address" defaultActive={!props.editSectionIndex}>
        <Form widths="equal">
          <Form.Group>
            <Field name="houseNumber">
              {({ field, meta }) => (
                <Form.Input
                  width={8}
                  label="Number"
                  error={meta.touched && meta.error}
                  {...field}
                />
              )}
            </Field>
            <Form.Field width={1} />
            <Field name="state">
              {({ field, meta }) => (
                <Form.Input
                  width={8}
                  label="State"
                  error={meta.touched && meta.error}
                  {...field}
                />
              )}
            </Field>
          </Form.Group>
          <Form.Group>
            <Field name="street">
              {({ field, meta }) => (
                <Form.Input
                  width={8}
                  label="Street"
                  error={meta.touched && meta.error}
                  {...field}
                />
              )}
            </Field>
            <Form.Field width={1} />
            <Form.Field width={8}>
              <label>Country</label>
              <Dropdown
                className={styles.contractorField}
                clearable
                value={
                  COUNTRY_OPTIONS_WITH_CODE.find(
                    ({ value }) => values.country === value
                  )?.text || ""
                }
                text={
                  COUNTRY_OPTIONS_WITH_CODE.find(
                    ({ value }) => values.country === value
                  )?.text || ""
                }
                options={COUNTRY_OPTIONS_WITH_CODE}
                selection
                search
                onChange={({ target }, { value: key }) => {
                  const country = COUNTRY_OPTIONS_WITH_CODE.find(
                    ({ value }) => key === value
                  );
                  country
                    ? setFieldValue("country", country.value)
                    : setValues({
                        ...values,
                        country: ""
                      });
                }}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field width={8}>
              <label>Town/Suburb</label>
              <Field as={Input} name="city" />
            </Form.Field>
            <Form.Field width={1} />
            <Form.Field width={8} />
          </Form.Group>
        </Form>
      </CollapsibleBlock>
    </Segment>
  );
};

export default AddressSection;
