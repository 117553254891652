import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Grid, Input, Icon, Form } from "semantic-ui-react";
import Calendar from "react-calendar";
import { bindActionCreators } from "redux";
import { setExpiryDate } from "../../../../actions/SprayDiary/common";
import PropTypes from "prop-types";

class SprayTaskExpiryDatePicker extends Component {
  state = {
    trigger: false
  };

  componentDidUpdate(prevProps) {
    const { id } = this.props;

    if (prevProps.id !== id) {
      document.activeElement.blur();
    }
  }

  onChange = expirationDate => {
    this.props.actions.setExpiryDate(expirationDate);
    this.setState({ trigger: false });
  };

  toggleShowCalendar = () => {
    this.setState({ trigger: !this.state.trigger });
  };

  render() {
    const { id, title, expirationDate, valid } = this.props;
    return (
      <React.Fragment>
        <Grid.Row className="sprayField">
          <Grid.Column className="section">
            <Form.Field className="sprayField">
              <label className="title">{title}</label>
              <Input
                fluid
                size="large"
                onClick={this.toggleShowCalendar}
                value={
                  expirationDate
                    ? moment(expirationDate).format("DD/MM/YYYY")
                    : ""
                }
                placeholder="DD/MM/YYYY"
                icon={<Icon className="tuf-calendar-alt" />}
              />

              {this.state.trigger && (
                <Calendar
                  value={expirationDate ? new Date(expirationDate) : new Date()}
                  onChange={this.onChange}
                />
              )}
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
      </React.Fragment>
    );
  }
}

SprayTaskExpiryDatePicker.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  Can: PropTypes.func,
  actions: PropTypes.object,
  valid: PropTypes.bool,
  expirationDate: PropTypes.object
};

const mapStateToProps = ({
  sprayDiary: {
    id,
    expirationDate,
    validations: { expirationDate: valid }
  }
}) => ({
  id,
  expirationDate,
  valid
});

const mapDispatchToProps = (dispatch, props) => ({
  actions: bindActionCreators(
    {
      setExpiryDate: date => setExpiryDate(`expirationDate`, date)
    },
    dispatch
  )
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SprayTaskExpiryDatePicker);
