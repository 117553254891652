export const validationRegex = /^[A-Z](?<barcode>\d{10,})$/;

export const generateBarcodeSeries = (labels, firstBarcode, lastBarcode) => {
  let series = [];

  for (let number = firstBarcode; number <= lastBarcode; number++) {
    series.push({
      ...labels,
      numberPart: toBarcodeNumberPartFormat(number),
    });
  }

  return series;
};


export const toBarcodeNumberPartFormat = barcode => {
  return `B${String(barcode).padStart(10, '0')}`;
};

export const validateBarcode = barcode => {
  return validationRegex.exec(barcode) !== null;
};
