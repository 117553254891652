import React, { Component } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import _, { difference } from "lodash";
import {
  Grid,
  Dropdown,
  Input,
  Form,
  Header,
  Icon,
  Button,
  Label
} from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import style from "./SelectArea.module.css";
import {
  setSprayDiaryField,
  setSprayNestedField
} from "../../../../actions/SprayDiary/common";
import {
  changeAreas,
  setWidthPerRow,
  setLPerHa,
  getAreasList
} from "../../../../actions/SprayDiary/areas";
import AreasFilters from "./AreasFilters";

import {
  filterLabelsSelector,
  filteredAreasOptionsSelector,
  gotMoreOptionsSelector,
  selectedAreasSelector
} from "../../../../selectors/sprayDiary";

import styles from "./sprayForm.module.css";
import { switchViewMode } from "../../../../actions/Application/application";

class SelectArea extends Component {
  state = {
    showFilters: false,
    showBars: false,
    filtersPrev: null
  };

  filtersRef = React.createRef();
  filtersButtonRef = React.createRef();
  filtersBarRef = React.createRef();
  filtersFooterRef = React.createRef();
  dropDownRef = React.createRef();

  componentWillMount() {
    this.props.actions.getAreasList({ leaveBlocksWithPatchesInList: true });
  }

  componentDidMount() {
    this.resetFilters(true);
    document.body.addEventListener("click", this.handleBlur);
  }

  componentWillUnmount() {
    document.body.removeEventListener("click", this.handleBlur);
  }

  handleBlur = event => {
    const { showFilters, filtersPrev, showBars } = this.state;
    const { actions } = this.props;

    if (
      this.filtersRef.current &&
      // eslint-disable-next-line
      !ReactDOM.findDOMNode(this.filtersRef.current).contains(event.target) &&
      // eslint-disable-next-line
      !ReactDOM.findDOMNode(this.filtersButtonRef.current).contains(
        event.target
      ) &&
      showFilters
    ) {
      this.toggleFilters();

      actions.setSprayDiaryField({
        fieldName: "filters",
        fieldValue: filtersPrev
      });
    }

    if (
      this.dropDownRef.current &&
      // eslint-disable-next-line
      !ReactDOM.findDOMNode(this.dropDownRef.current).contains(event.target) &&
      (!this.filtersBarRef.current ||
        // eslint-disable-next-line
        (this.filtersBarRef.current &&
          !ReactDOM.findDOMNode(this.filtersBarRef.current).contains(
            event.target
          ))) &&
      (!this.filtersFooterRef.current ||
        (this.filtersFooterRef.current &&
          // eslint-disable-next-line
          !ReactDOM.findDOMNode(this.filtersFooterRef.current).contains(
            event.target
          ))) &&
      showBars
    ) {
      this.toggleDropDown();
    }
  };

  changeAreas = async (_, data) => {
    const { actions } = this.props;
    await actions.changeAreas(data.value);
    this.arrangeItems();
  };

  toggleFilters = showBars => {
    const { showFilters } = this.state;
    const { filters } = this.props;
    this.setState({
      showFilters: !showFilters,
      filtersPrev: !showFilters ? filters : null
    });

    this.toggleDropDown(showBars);
  };

  arrangeItems = () => {
    if (this.dropDownRef.current) {
      const filtersBarHeight = this.filtersBarRef.current
        ? this.filtersBarRef.current.offsetHeight
        : 0;
      const optionsHolder = this.dropDownRef.current.ref.current.getElementsByClassName(
        "menu"
      )[0];
      const optionsHolderHeight = optionsHolder.offsetHeight;
      optionsHolder.style.marginTop = `${filtersBarHeight}px`;
      if (this.filtersFooterRef.current) {
        this.filtersFooterRef.current.style.paddingTop = `${filtersBarHeight +
          optionsHolderHeight}px`;
      }
    }
  };

  toggleDropDown = (showBars = false) => {
    this.setState({ showBars }, () => {
      this.arrangeItems();
    });
  };

  resetFilters = async noToggle => {
    const { actions } = this.props;
    await actions.setSprayDiaryField({
      fieldName: "filters",
      fieldValue: {
        farms: [],
        varieties: [],
        parentBlock: [],
        ageFrom: "",
        ageTo: "",
        showArchived: false
      }
    });
    if (!noToggle) {
      this.toggleFilters(true);
    } else {
      this.arrangeItems();
    }
  };

  changeFilter = (_, data) => {
    const { actions } = this.props;
    actions.setSprayNestedField({
      parent: "filters",
      fieldName: data.name,
      fieldValue:
        data.type === "radio"
          ? data.checked
          : data.format === "integer"
          ? parseInt(data.value)
          : data.value
    });
  };

  applyFilters = () => {
    this.toggleFilters(true);
  };

  deleteLabel = async (event, value, filter) => {
    event.preventDefault();
    const { filters, actions } = this.props;
    const resetShowArchived = () => {
      filters.showArchived = false;
      return filters;
    };
    const removeAge = () => {
      filters.ageFrom = "";
      filters.ageTo = "";
      return filters;
    };
    const defaultAction = () => {
      const targetFilter = filters[filter];
      const targetIndex = targetFilter.indexOf(value);
      if (targetIndex >= 0) {
        targetFilter.splice(targetIndex, 1);
      }
      return filters;
    };

    const switchActions = {
      age: removeAge,
      showArchived: resetShowArchived,
      defaultAction
    };

    await actions.setSprayDiaryField({
      fieldName: "filters",
      fieldValue: {
        ...(switchActions[filter] || switchActions.defaultAction)()
      }
    });
    this.arrangeItems();
  };

  selectAll = () => {
    const { areasOptions, actions } = this.props;
    actions.changeAreas(areasOptions.map(area => area.value));
    this.setState({ showBars: false });
  };

  clearSelection = () => {
    this.changeAreas(null, { value: [] });
  };

  render() {
    const {
      selectedAreas,
      widthPerRow,
      literPerHectare,
      applicationType,
      actions,
      areasList,
      areasList: { loaded },
      areaValidation,
      widthPerRowValidation,
      literPerHectareValidation,
      filters,
      filterLabels,
      areasOptions,
      gotMoreOptions
    } = this.props;

    const { showFilters, showBars } = this.state;
    //TODO: function
    const parentBlocks = areasList.blocks
      .filter(a => !a.parent && !a.archived)
      .reduce((acc, area) => {
        acc[area.id] = area;
        acc[area.id].areaIDs = [];
        return acc;
      }, {});

    areasList.patches
      .filter(a => a.parent && !a.archived)
      .forEach(a => parentBlocks[a.parent.id].areaIDs.push(a.id));

    const listOfBlocks = [];

    Object.values(parentBlocks).forEach(area => {
      const comparedList = _.intersection(area.areaIDs, selectedAreas);
      if (!_.isEmpty(area.areaIDs) && _.isEqual(area.areaIDs, comparedList)) {
        listOfBlocks.push({
          id: area.id,
          name: area.name,
          areaIDs: comparedList
        });
      }
    });

    const areaIDsToRemove = listOfBlocks.map(b => b.areaIDs).flat();
    const selectedAreasToShow = [
      ...selectedAreas.filter(a => a && !areaIDsToRemove.includes(a)),
      ...listOfBlocks.map(b => b.id)
    ];

    const areasOptionsToShow = [
      ...areasOptions.filter(a => !areaIDsToRemove.includes(a.value)),
      ...listOfBlocks.map(b => ({ key: b.id, shortText: b.name, value: b.id }))
    ];

    const displayedAreasOptionsToShow = areasOptionsToShow.filter(
      area => filters.showArchived || !area.archived
    );

    if (this.dropDownRef.current) {
      this.dropDownRef.current.handleLabelRemove = (_, data) => {
        const blockIndex = listOfBlocks
          .map(b => b.id)
          .findIndex(id => id === data.value);
        this.changeAreas(null, {
          value:
            blockIndex !== -1
              ? difference(selectedAreas, listOfBlocks[blockIndex].areaIDs)
              : difference(selectedAreas, [data.value])
        });
      };

      this.dropDownRef.current.handleChange = (_, data) => {
        const blockIDs = listOfBlocks.map(b => b.id);
        const newValues = data
          .map(b => {
            const blockIndex = blockIDs.findIndex(id => id === b);
            return blockIndex !== -1 ? listOfBlocks[blockIndex].areaIDs : b;
          })
          .flat();
        this.changeAreas(null, { value: newValues });
      };
    }

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column widescreen={8} largeScreen={10} mobile={16} tablet={16}>
            <Form.Field required className="sprayField">
              <div className={style.viewHolder}>
                <div className={style.viewLabelHolder}>
                  <label className="title">Select the areas</label>
                </div>
                <div className={style.viewButtonHolder}>
                  {listOfBlocks.length >= 1 ? (
                    <span
                      className={style.viewSelector}
                      onClick={this.props.actions.switchViewMode}
                    >
                      {this.props.showParentBlocks
                        ? "Show parent block only"
                        : "Show all patches"}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div
                className={`${styles.areaDropDownHolder} ${
                  showFilters ? styles.showFilters : ""
                }`}
              >
                <Dropdown
                  error={!areaValidation}
                  search
                  closeOnChange
                  closeOnBlur={false}
                  noResultsMessage="Nothing was found"
                  fluid
                  placeholder="Select all fields to be sprayed"
                  selection
                  multiple
                  value={
                    this.props.showParentBlocks
                      ? selectedAreas
                      : selectedAreasToShow
                  }
                  loading={!loaded}
                  renderLabel={item => {
                    return item.shortText;
                  }}
                  options={
                    this.props.showParentBlocks
                      ? areasOptions
                      : displayedAreasOptionsToShow
                  }
                  onChange={this.changeAreas}
                  icon={null}
                  onFocus={() => this.toggleDropDown(true)}
                  ref={this.dropDownRef}
                  open={showBars}
                  className={`${styles.areasDropdown} ${
                    filterLabels.length > 0 ? styles.gotFilters : ""
                  }`}
                />

                {selectedAreas.length > 0 && (
                  <Icon
                    name="delete"
                    className={styles.clearAll}
                    onClick={this.clearSelection}
                  />
                )}
                <Icon
                  name="sliders horizontal"
                  onClick={() => this.toggleFilters()}
                  ref={this.filtersButtonRef}
                />
                {showBars && filterLabels.length > 0 && (
                  <div className={styles.filtersBar} ref={this.filtersBarRef}>
                    <Grid className={styles.labelsGrid}>
                      <Grid.Row>
                        <Grid.Column
                          mobile={16}
                          tablet={16}
                          widescreen={2}
                          largeScreen={2}
                        >
                          <Header as="h4">Filters</Header>
                        </Grid.Column>
                        <Grid.Column
                          mobile={16}
                          tablet={16}
                          widescreen={9}
                          largeScreen={10}
                        >
                          {filterLabels.map(label => (
                            <Label key={label.key}>
                              {label.text}
                              <Icon
                                name="delete"
                                label={label}
                                onClick={event =>
                                  this.deleteLabel(
                                    event,
                                    label.value,
                                    label.filter
                                  )
                                }
                              />
                            </Label>
                          ))}
                        </Grid.Column>
                        <Grid.Column
                          mobile={16}
                          tablet={16}
                          widescreen={5}
                          largeScreen={4}
                          textAlign="right"
                        >
                          <Button
                            className="button-text"
                            onClick={() => this.toggleFilters()}
                          >
                            Edit
                          </Button>
                          <Button
                            className="button-text"
                            onClick={() => this.resetFilters(true)}
                          >
                            Reset
                          </Button>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </div>
                )}
                {showBars && filterLabels.length > 0 && (
                  <div
                    className={styles.filtersFooter}
                    ref={this.filtersFooterRef}
                  >
                    {gotMoreOptions.length > 0 && (
                      <div className={styles.allHolder}>
                        <Button onClick={this.selectAll} fluid>
                          Select All
                        </Button>
                      </div>
                    )}
                  </div>
                )}

                {showFilters && (
                  <div className={styles.filtersHolder} ref={this.filtersRef}>
                    <AreasFilters
                      filters={filters}
                      resetFilters={this.resetFilters}
                      applyFilters={this.applyFilters}
                      changeFilter={this.changeFilter}
                    />
                  </div>
                )}
              </div>
            </Form.Field>
          </Grid.Column>
          <Grid.Column largeScreen={3} widescreen={2} tablet={8} mobile={8}>
            <Form.Field required className="sprayField">
              <label htmlFor="widthPerRow" className="title">
                Row width
              </label>
              <Input
                error={!widthPerRowValidation}
                id="widthPerRow"
                type="number"
                fluid
                max={100}
                min={0}
                value={widthPerRow}
                onChange={e => actions.setWidthPerRow(e.target.value)}
                label={{ content: "%", inverted: "true" }}
                labelPosition="right"
              />
            </Form.Field>
          </Grid.Column>
          {applicationType === "SPRAY" && (
            <Grid.Column largeScreen={3} widescreen={2} tablet={8} mobile={8}>
              <Form.Field required className="sprayField">
                <label htmlFor="literPerHectare" className="title">
                  L per Ha
                </label>
                <Input
                  error={!literPerHectareValidation}
                  fluid
                  id="literPerHectare"
                  type="number"
                  min={0}
                  value={literPerHectare}
                  onChange={e => actions.setLPerHa(e.target.value)}
                  label={{ content: "L", inverted: "true" }}
                  labelPosition="right"
                />
              </Form.Field>
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
    );
  }
}

SelectArea.propTypes = {
  selectedAreas: PropTypes.array,
  widthPerRow: PropTypes.number,
  literPerHectare: PropTypes.number,
  actions: PropTypes.object,
  areasList: PropTypes.object,
  areaValidation: PropTypes.bool,
  widthPerRowValidation: PropTypes.bool,
  literPerHectareValidation: PropTypes.bool,
  filters: PropTypes.object,
  filterLabels: PropTypes.array,
  areasOptions: PropTypes.array,
  gotMoreOptions: PropTypes.array
};
const mapStateToProps = state => {
  const {
    applicationSettings: { showParentBlocks },
    sprayDiary: {
      areasList,
      widthPerRow,
      literPerHectare,
      selectedMachinery: { id },
      applicationType,
      validations: {
        selectedAreas: areaValidation,
        literPerHectare: literPerHectareValidation,
        widthPerRow: widthPerRowValidation
      },
      filters
    }
  } = state;

  return {
    areasList,
    selectedAreas: selectedAreasSelector(state),
    selectedMachinery: id,
    widthPerRow,
    literPerHectare,
    areaValidation,
    literPerHectareValidation,
    applicationType,
    widthPerRowValidation,
    showParentBlocks,
    filterLabels: filterLabelsSelector(state),
    areasOptions: filteredAreasOptionsSelector(state),
    gotMoreOptions: gotMoreOptionsSelector(state),
    filters
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      switchViewMode,
      getAreasList,
      changeAreas,
      setWidthPerRow,
      setLPerHa,
      setSprayDiaryField,
      setSprayNestedField
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectArea);
