import { actionType } from "../../constants/Common";
import { PERSIST_REHYDRATE } from "@redux-offline/redux-offline/lib/constants";

const initialState = {
  isFetching: false,
  apiStatus: null,
  error: null,
  forceUpdate: false,
  userChecked: false,
  online: true,
  rehydrate: false,
  backEndBuildNumber: null
};

const commonActions = (state, action) => {
  switch (action.type) {
    case PERSIST_REHYDRATE:
      return {
        ...state,
        rehydrate: true
      };
    case actionType.SET_VERSION_CHECKED:
      return {
        ...state,
        online: action.payload
      };
    case actionType.SET_USER_CHECKED:
      return {
        ...state,
        userChecked: true
      };
    case actionType.CHECK_API_STATUS_START:
      return {
        ...state,
        isFetching: true
      };
    case actionType.CHECK_API_STATUS:
      return {
        ...state,
        apiStatus: action.payload,
        isFetching: false
      };
    case actionType.CHECK_API_STATUS_FAIL:
      return {
        ...state,
        error: action.payload,
        isFetching: false
      };
    case actionType.GET_BACKEND_BUILD_NUMBER_START:
      return {
        ...state,
        isFetching: true
      };
    case actionType.GET_BACKEND_BUILD_NUMBER:
      return {
        ...state,
        backEndBuildNumber: action.payload,
        isFetching: false
      };
    case actionType.GET_BACKEND_BUILD_NUMBER_FAIL:
      return {
        ...state,
        error: action.payload,
        isFetching: false
      };
    case actionType.FORCE_UPDATE_APP:
      return {
        ...state,
        forceUpdate: action.payload
      };
    default:
      return state;
  }
};

export default (state = initialState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case actionType.CHECK_API_STATUS_START:
    case actionType.CHECK_API_STATUS:
    case actionType.CHECK_API_STATUS_FAIL:
    case actionType.FORCE_UPDATE_APP:
    case actionType.SET_USER_CHECKED:
    case actionType.SET_VERSION_CHECKED:
    case PERSIST_REHYDRATE:
      return commonActions(state, action);

    default:
      return state;
  }
};
