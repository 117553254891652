import React, { useCallback, useEffect, useState } from "react";
import { get } from "lodash";
import { Modal, Button, Form, Radio } from "semantic-ui-react";
import styles from "./printoutTypeModal.module.css";

const PrintModal = ({
  open,
  printButton,
  setOpen,
  printoutType,
  setPrintOutType,
  excel,
  handleExcelExport
}) => {
  const [selectedItem, setSelectedItem] = useState([]);

  const handleChange = value => setPrintOutType(value);
  return (
    <Modal
      className={styles.modalPrintAndExport}
      open={open}
      closeOnDimmerClick={false}
      closeOnEscape={true}
      size="tiny"
    >
      <Modal.Header className={styles.modalHeader}>
        Choose the type of the printout
      </Modal.Header>
      <>
        <Modal.Content>
          <Form className={styles.checkboxWrapper}>
            <Form.Field className={styles.inventoryCheckboxContainer}>
              <Radio
                className={styles.inventoryBox}
                label="Inventory"
                name="radioGroup"
                value="inventory"
                checked={printoutType === "inventory"}
                onChange={e => handleChange("inventory")}
              />
            </Form.Field>
            <Form.Field className={styles.stocktakeCheckboxContainer}>
              <Radio
                className={styles.stocktake}
                label="Physical stocktake"
                name="radioGroup"
                value="physicalStocktake"
                checked={printoutType === "physicalStocktake"}
                onChange={() => handleChange("physicalStocktake")}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions className={styles.modalAction}>
          <Button
            className={styles.reportsModalCancelButton}
            content="Cancel"
            onClick={() => {
              setSelectedItem([]);
              setOpen(false);
            }}
          />
          <Button
            className={styles.reportsModalButton}
            content="Print"
            onClick={() => {
              setOpen(false);
              printButton(selectedItem);
            }}
          />
        </Modal.Actions>
      </>
    </Modal>
  );
};

export default PrintModal;
