import { actionType, YES_NO_OPTIONS } from "../../constants/Chemicals";
import { MATTER_TYPE_LIQUID, MATTER_TYPE_SOLID } from "../../constants/Farms";
import { keyBy } from "lodash";

const initialState = {
  list: {
    content: [],
    isFetching: false
  },
  data: {
    content: [],
    isFetching: false
  },
  filters: {
    matterOptions: [
      {
        key: MATTER_TYPE_LIQUID,
        label: "Liquid",
        value: MATTER_TYPE_LIQUID
      },
      {
        key: MATTER_TYPE_SOLID,
        label: "Solid",
        value: MATTER_TYPE_SOLID
      }
    ],
    trackInventoryOptions: YES_NO_OPTIONS,
    isFetching: false
  },
  units: {
    content: [],
    isFetching: false
  },
  activeIngredients: {
    content: [],
    error: null,
    isFetching: false
  },
  isUploadingAttachment: false,
  activeItem: {
    id: null,
    isEditing: false
  },
  lastUpdateDate: null,
  error: null,
  isCreating: false,
  fetchChemicalToggle: false
};

export default (state = initialState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case actionType.UPLOAD_CHEMICAL_ATTACHMENT_START:
      return {
        ...state,
        isUploadingAttachment: true
      };

    case actionType.UPLOAD_CHEMICAL_ATTACHMENT:
    case actionType.UPLOAD_CHEMICAL_ATTACHMENT_ERROR:
      return {
        ...state,
        isUploadingAttachment: false
      };
    case actionType.FETCH_CHEMICALS_LIST_START:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: true
        }
      };
    case actionType.FETCH_CHEMICALS_LIST:
      return {
        ...state,
        list: {
          ...action.payload.data,
          isFetching: false
        },
        lastUpdateDate: action.payload.lastUpdateDate
      };
    case actionType.FETCH_CHEMICALS_LIST_FAIL:
      return {
        ...state,
        list: { ...state.list, isFetching: false },
        error: action.payload
      };
    case actionType.CHANGE_LAST_UPDATE_DATE:
      return {
        ...state,
        lastUpdateDate: action.payload
      };
    case actionType.FETCH_CHEMICALS_START:
    case actionType.UPDATE_CHEMICAL_START:
    case actionType.ARCHIVE_CHEMICALS_START:
      return {
        ...state,
        data: {
          ...state.data,
          isFetching: true
        }
      };

    case actionType.FETCH_CHEMICALS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
          isFetching: false
        }
      };

    case actionType.UPDATE_CHEMICAL:
      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.map(item =>
            item.id === action.payload.id ? action.payload : item
          ),
          isFetching: false
        }
      };
    case actionType.FETCH_CHEMICALS_ERROR:
    case actionType.UPDATE_CHEMICAL_FAIL:
    case actionType.ARCHIVE_CHEMICALS_FAIL:
      return {
        ...state,
        data: {
          ...state.data,
          isFetching: false
        },
        error: action.payload
      };

    case actionType.ARCHIVE_CHEMICALS:
      const deletedElementsIdsMap = keyBy(action.payload);
      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.filter(e => !deletedElementsIdsMap[e.id]),
          isFetching: false
        }
      };

    case actionType.CHEMICALS_CLEAR_ERROR:
      return {
        ...state,
        error: null
      };

    case actionType.FETCH_CHEMICALS_FILTERS_START:
      return {
        ...state,
        data: {
          ...state.data
        },
        filters: {
          ...state.filters,
          isFetching: true
        }
      };

    case actionType.FETCH_CHEMICALS_FILTERS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data
        },
        filters: {
          ...state.filters,
          ...action.payload,
          isFetching: false
        }
      };

    case actionType.FETCH_CHEMICALS_FILTERS_ERROR:
      return {
        ...state,
        data: {
          ...state.data
        },
        filters: {
          ...state.filters,
          isFetching: false
        },
        error: action.payload
      };

    case actionType.SET_UNITS:
      return {
        ...state,
        units: {
          content: action.payload,
          isFetching: false
        }
      };

    case actionType.FETCH_UNITS_START:
      return {
        ...state,
        units: {
          ...state.units,
          isFetching: true
        }
      };

    case actionType.FETCH_ACTIVE_INGREDIENTS_START:
      return {
        ...state,
        activeIngredients: {
          ...state.activeIngredients,
          isFetching: true
        }
      };

    case actionType.FETCH_ACTIVE_INGREDIENTS_SUCCESS:
      return {
        ...state,
        activeIngredients: {
          ...state.activeIngredients,
          error: null,
          content: action.payload,
          isFetching: false
        }
      };

    case actionType.FETCH_ACTIVE_INGREDIENTS_ERROR:
      return {
        ...state,
        activeIngredients: {
          ...state.activeIngredients,
          error: action.payload,
          isFetching: false
        }
      };

    case actionType.ADD_CHEMICAL_START:
      return {
        ...state,
        isCreating: true,
        activeItem: {
          ...state.activeItem,
          isEditing: true
        }
      };

    case actionType.ADD_CHEMICAL:
      return {
        ...state,
        data: {
          content: [...state.data.content, action.payload]
        },
        isCreating: false,
        fetchChemicalToggle: !state.fetchChemicalToggle
      };

    case actionType.ADD_CHEMICAL_FAIL:
      return {
        ...state,
        isCreating: false,
        activeItem: {
          ...state.activeItem,
          isEditing: false,
          error: action.payload
        }
      };

    case actionType.SET_ACTIVE_ITEM_ID:
      return {
        ...state,
        activeItem: {
          id: action.payload,
          isEditing: false,
          error: null
        }
      };
    case actionType.PENDING_OPERATIONS_START:
    case actionType.PENDING_OPERATIONS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
          isFetching: false
        }
      };

    default:
      return state;
  }
};
