import { Popup } from "semantic-ui-react";
import styles from "./ToolTip.module.css";
import PropTypes from "prop-types";
import React from "react";

const ToolTip = ({ content, children }) => {
  return (
    <Popup
      className={styles.popup}
      trigger={children}
      content={content}
      size="small"
      basic
    />
  );
};

ToolTip.propTypes = {
  content: PropTypes.string.isRequired,
  children: PropTypes.node
};

export default ToolTip;
