import React, { useState } from "react";
import PropTypes from "prop-types";
import ObjectId from "bson-objectid";
import styles from "./ShowMore.module.css";
import { Button } from "semantic-ui-react";

function ShowMore({ items, minItemsToShow }) {
  const [opened, setOpened] = useState(false);
  const itemsToRender = items.filter(item => item);
  const itemsToShow = opened
    ? itemsToRender
    : itemsToRender.slice(0, minItemsToShow);
  const moreCount = itemsToRender.length - minItemsToShow;
  const showActionButton = moreCount > 0;

  const handleShowMore = event => {
    event.stopPropagation();
    setOpened(!opened);
  };

  return (
    <div className={styles.itemsHolder}>
      {itemsToShow.map(item => (
        <span key={`item${ObjectId().toHexString()}`}>{item}</span>
      ))}
      {showActionButton && (
        <Button
          className={`button-text ${styles.readFull}`}
          onClick={event => handleShowMore(event)}
        >
          {!opened && moreCount > 0 ? (
            <span>+ {moreCount} More</span>
          ) : (
            "Show less"
          )}
        </Button>
      )}
    </div>
  );
}

ShowMore.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
  minItemsToShow: PropTypes.number
};

ShowMore.defaultProps = {
  minItemsToShow: 1
};

export default ShowMore;
