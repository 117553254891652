import React from "react";
import { Table } from "semantic-ui-react";
import CollapsibleBlock from "components/CollapsibleBlock";
import { map } from "lodash";
import { chemicalShape, FERTILISER } from "constants/Chemicals/types";
import { isEmptyValue } from "utils/tasksUtils";
import styles from "../Sidebar.module.css";

const ChemicalProperties = ({ chemical, editButton, typeCheck }) => {
  return (
    <CollapsibleBlock
      className={styles.blockContainer}
      headerOptionalComponent={editButton}
      title={`${typeCheck ? "Fertiliser" : "Chemical"} properties`}
    >
      <Table className={styles.sectionTable} definition fixed>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Poison schedule</Table.Cell>
            <Table.Cell>{isEmptyValue(chemical.poisonSchedule)}</Table.Cell>
          </Table.Row>
          {map(chemical.activeIngredients, ({ activeIngredient }) => (
            <Table.Row>
              <Table.Cell>Active ingredient</Table.Cell>
              <Table.Cell>{activeIngredient}</Table.Cell>
            </Table.Row>
          ))}
          <Table.Row>
            <Table.Cell>Dangerous product</Table.Cell>
            <Table.Cell>{chemical.dangerousProduct ? "Yes" : "No"}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Organic</Table.Cell>
            <Table.Cell>{chemical.organic ? "Yes" : "No"}</Table.Cell>
          </Table.Row>
          {!chemical.dangerousProduct && (
            <Table.Row>
              <Table.Cell>AOC reg. No</Table.Cell>
              <Table.Cell>
                {isEmptyValue(chemical.registrationNumber)}
              </Table.Cell>
            </Table.Row>
          )}
          {chemical.type !== FERTILISER && (
            <>
              <Table.Row>
                <Table.Cell>Resistant group 1</Table.Cell>
                <Table.Cell>
                  {isEmptyValue(chemical.resistanceGroup1)}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Resistant group 2</Table.Cell>
                <Table.Cell>
                  {isEmptyValue(chemical.resistanceGroup2)}
                </Table.Cell>
              </Table.Row>
            </>
          )}
        </Table.Body>
      </Table>
    </CollapsibleBlock>
  );
};

ChemicalProperties.propTypes = {
  chemical: chemicalShape
};

export default ChemicalProperties;
