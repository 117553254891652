import PropTypes from "prop-types";

export const userShape = PropTypes.shape({
  id: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.shape
});

export const attachmentShape = PropTypes.shape({
  subPath: PropTypes.string,
  id: PropTypes.string,
  presignedUrl: PropTypes.string,
  name: PropTypes.string
});

export const machineryShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  make: PropTypes.string,
  model: PropTypes.string,
  code: PropTypes.string,
  size: PropTypes.number,
  ratePerHour: PropTypes.number,
  sizeUnit: PropTypes.string,
  notes: PropTypes.string,
  trackerId: PropTypes.string,
  archived: PropTypes.bool,
  herbicide: PropTypes.bool
});

export const chemicalShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string
});

export const getPageableDataShape = contentElementShape => ({
  content: PropTypes.arrayOf(contentElementShape),
  totalPages: PropTypes.number,
  totalElements: PropTypes.number,
  number: PropTypes.number,
  size: PropTypes.number
});
