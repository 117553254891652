import React from 'react';
import { Header, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from 'semantic-ui-react';
import styles from './ExpandedTablePrintLayout.module.css';
import PropTypes from 'prop-types';

const ExpandedTablePrintLayout = ({ title, headerGroups, prepareRow, rows, className }) => {
  rows.forEach(row => prepareRow(row));
  return (
    <>
      <Header className={styles.title}>{title}</Header>
      <div className={`${className} ExpandedTable`}>
        <div className="rt-table-holder">
          <Table className="rt-table" role="grid">
            <TableHeader className="rt-thead -header">
              {headerGroups.map(headerGroup => (
                <TableRow
                  key={headerGroup.headers.reduce((s, header) => `${s}_${header.id}`, '')}
                  className="rt-tr -headerGroups"
                >
                  {headerGroup.headers.map(column => (
                    <TableHeaderCell key={column.id} className={`rt-th`}>
                      <div>
                        <span>{column.render('Header')}</span>
                      </div>
                    </TableHeaderCell>
                  ))}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody className="rt-tbody">
              {rows.map((row, index) => (
                <TableRow
                  className={`rt-tr ${index % 2 === 0 ? '-even' : '-odd'} ${
                    row.original && row.original.error ? 'error' : ''
                  }`}
                  role="row"
                  key={row.id}
                >
                  {row.cells.map(cell => (
                    <TableCell
                      key={`cell_${cell.row.id}_${cell.column.id}`}
                      className={`rt-td ${cell.column.girdColumnsWidth ? `cell_${cell.column.girdColumnsWidth}` : ''}`}
                    >
                      {cell.isGrouped ? (
                        <div>{cell.render('Cell', { editable: false })}</div>
                      ) : cell.isAggregated ? (
                        cell.render('Aggregated')
                      ) : cell.isPlaceholder ? (
                        <span />
                      ) : (
                        cell.render('Cell', { editable: false })
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </>
  );
};

ExpandedTablePrintLayout.propTypes = {
  title: PropTypes.string,
  headerGroups: PropTypes.array.isRequired,
  prepareRow: PropTypes.func.isRequired,
  rows: PropTypes.array.isRequired,
  className: PropTypes.string,
};

ExpandedTablePrintLayout.defaultProps = {
  title: '',
};

export default ExpandedTablePrintLayout;
