import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Grid, Header } from "semantic-ui-react";
import { isEmptyValue } from "../../../../utils/tasksUtils";
import TaskRow from "../../../SprayDiary/TaskDetailsMobile/TaskRow";
import styles from "./Sidebar.module.css";

const Sidebar = ({ vendor }) => {
  return vendor ? (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column width={11}>
            <Header as="h1" className={styles.name}>
              {vendor.name}
            </Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Header>Information</Header>
      <Grid>
        <TaskRow
          label="Email"
          name="email"
          value={<span>{isEmptyValue(vendor.email)}</span>}
        />
      </Grid>
    </>
  ) : (
    <div>Nothing to show</div>
  );
};

Sidebar.propTypes = {
  vendor: PropTypes.object
};
Sidebar.defaultProps = {
  vendor: {}
};

const mapStateToProps = state => {
  const {
    notifications: { isSending, success, error, showMessage }
  } = state;
  return { notifications: { isSending, success, error, showMessage } };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({}, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
