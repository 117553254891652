import React, { useState } from "react";
import { get } from "lodash";
import { Modal, Button, Confirm } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { Formik, useFormikContext } from "formik";
import GeneralInformation from "./components/GeneralInformation";
import Inventory from "./components/Inventory";
import Thresholds from "./components/Thresholds";
import Farms from "./components/Farms";
import {
  createShed,
  updateShed,
  searchProductsByName,
  deleteThresholds,
  getOperations
} from "actions/Sheds";
import { SHED_INITIAL_VALUES } from "constants/Sheds";
import styles from "./ShedModal.module.css";

const REQUIRED_FIELDS = ["name"];

const ShedModal = ({ open, onClose, shed, sheds }) => {
  const [secondOpen, setSecondOpen] = useState(false);
  const [farmChecker, setFarmChecker] = useState(false);
  const [thresholdChekcer, setThresholdChekcer] = useState(false);

  const dispatch = useDispatch();

  const validateValues = async values => {
    const errors = {};
    const { name, buckets, thresholds } = values;

    REQUIRED_FIELDS.forEach(filedName => {
      if (!get(values, filedName)) {
        errors[filedName] = "Required field";
      }
    });

    if (name && name !== get(shed, "name")) {
      const { content } = await searchProductsByName(name);

      if (content.some(product => product.name === name)) {
        errors.name = "Shed with the same name already exists";
      }
    }
    return errors;
  };

  const onSubmit = values => {
    if (shed) {
      dispatch(updateShed(values.id, values)).then(onClose);
    } else {
      dispatch(createShed(values)).then(onClose);
    }
  };
  return (
    <Modal
      open={open}
      closeOnDimmerClick={false}
      closeOnEscape={false}
      size="large"
      onClick={e => e.stopPropagation()}
      className={styles.root}
    >
      <Modal.Header>
        {!!shed ? `Edit the ${shed.name}` : " Add a"} shed
      </Modal.Header>
      <Formik
        validate={validateValues}
        enableReinitialize
        validateOnChange={false}
        onSubmit={onSubmit}
        initialValues={shed || SHED_INITIAL_VALUES}
      >
        {props => (
          <>
            <Modal.Content scrolling className={styles.modalContentContainer}>
              <GeneralInformation />
              {/*    {shed && <Inventory shed={shed} sheds={sheds} />} */}
              {shed && !!shed.buckets.length && (
                <Thresholds
                  setThresholdChekcer={setThresholdChekcer}
                  shed={shed}
                />
              )}
              <Farms setFarmChecker={setFarmChecker} shed={shed} />
            </Modal.Content>
            <Modal.Actions>
              <Button
                content="Cancel"
                color="green"
                basic
                onClick={() => {
                  props.dirty ? setSecondOpen(true) : onClose();
                }}
              />
              <Button
                content={shed ? "Update" : "Save"}
                color="green"
                onClick={props.submitForm}
                disabled={
                  !props.isValid ||
                  !props.dirty ||
                  thresholdChekcer ||
                  farmChecker
                }
              />
            </Modal.Actions>
          </>
        )}
      </Formik>
      <Confirm
        className={styles.confirmModal}
        content="Are you sure you want to close modal window"
        open={secondOpen}
        onCancel={() => setSecondOpen(false)}
        onConfirm={() => {
          onClose();
          setSecondOpen(false);
        }}
      />
    </Modal>
  );
};

export default ShedModal;
