import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Dropdown, Header, Input, Icon } from "semantic-ui-react";
import Checkbox from "../Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, map, keyBy, debounce } from "lodash";
import styles from "../Filters.module.css";

const MultiSelect = ({
  selectedFilter,
  showOptions,
  text,
  selector,
  loaderAction,
  className,
  key,
  onChange,
  value = [],
  addedFilter,
  setSelectedFilter,
  filterId,
  withAsyncSearch,
  loading,
  filter
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [shownOptions, setShownOptions] = useState([]);
  const { isFetching, options } = useSelector(selector);
  const dispatch = useDispatch();
  const [count, setCount] = useState(0);

  const onOptionClick = useCallback((e, { value }) => {
    e.stopPropagation();
    onChange(filterId, value);
  }, []);

  useEffect(() => {
    if (!isFetching && !options?.length && typeof loaderAction === "function") {
      if (count < 1) {
        setCount(count + 1);
        loaderAction();
      }
    }
  }, [isFetching, options, loaderAction]);

  const onDropdownClose = () => {
    const valuesMap = keyBy(value);
    const objectsCopy = options?.filter(
      item =>
        item &&
        item.label &&
        item.label.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const selectedFirstArray = objectsCopy.sort((x, y) => {
      const isXSelected = valuesMap[x.value];
      const isYSelected = valuesMap[y.value];

      return isXSelected === isYSelected ? 0 : isXSelected ? -1 : 1;
    });

    setShownOptions(selectedFirstArray);
  };

  useEffect(() => {
    setShownOptions(
      (options || []).filter(
        item =>
          item &&
          item.label &&
          item.label.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [options, searchQuery]);

  const performSearch = useCallback(searchQuery => {
    dispatch(loaderAction({ search: searchQuery }));
  }, []);

  const debouncedSearchHandler = useMemo(
    () => debounce(performSearch, 500),
    []
  );

  useEffect(() => {
    if (withAsyncSearch) {
      debouncedSearchHandler(searchQuery);
    }
  }, [searchQuery, withAsyncSearch]);

  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function(a, b) {
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }
  return (
    <Dropdown
      // disabled={loading}
      text={text}
      multiple
      icon={
        addedFilter ? (
          <div className={styles.filterDropDownIcons}>
            {value.length ? <span>{value.length}</span> : null}
            <Icon
              size="small"
              name="close"
              onClick={e => {
                return (
                  onChange(filterId, null),
                  setSelectedFilter(
                    selectedFilter.filter(
                      item =>
                        showOptions.some(options => item != options.filterId) &&
                        item != filter.filterId
                    )
                  )
                );
              }}
            />
          </div>
        ) : (
          <div className={styles.filterDropDownIcons}>
            {value.length ? <span>{value.length}</span> : null}
            <Icon name={"angle down"} />
          </div>
        )
      }
      loading={isFetching}
      className={className}
      searchQuery={searchQuery}
      closeOnChange={false}
      value={value}
      onClose={onDropdownClose}
      // selectOnBlur={false}
    >
      <Dropdown.Menu>
        <Input
          placeholder="Search"
          icon="search"
          iconPosition="left"
          className="search"
          onClick={e => e.stopPropagation()}
          onChange={(_, { value }) => setSearchQuery(value)}
        />
        <Dropdown.Menu scrolling>
          {map(
            shownOptions.sort(function(a, b) {
              var nameA = a.label?.trim().toLowerCase(),
                nameB = b.label?.trim().toLowerCase();
              if (nameA < nameB) return -1;
              if (nameA > nameB) return 1;
              return 0;
            }),
            option => (
              <Dropdown.Item
                key={option.value}
                value={option.value}
                text={option.label}
                content={
                  <Checkbox
                    label={option.label}
                    checked={value.includes(option.value)}
                  />
                }
                onClick={onOptionClick}
              />
            )
          )}
        </Dropdown.Menu>
        {!!value.length && (
          <>
            <Dropdown.Divider />

            <Header
              className={styles.clearButton}
              onClick={() => onChange(filterId, null)}
            >
              Clear selection
            </Header>
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MultiSelect;
