import React, { useEffect, useState } from "react";
import DatePicker from "../../../DatePicker/DatePicker";
import { Dropdown, Icon } from "semantic-ui-react";
import styles from "../Filters.module.css";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getHashParameters } from "utils/hashToObject";

const DateRangeSelect = ({
  onChange,
  location,
  resetFilters,
  loading,
  dateType,
  defaultDate,
  disableChecker
}) => {
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const params = getHashParameters(location);
  useEffect(() => {
    if (resetFilters) {
      setDateFrom();
      setDateTo();
    }
  }, [resetFilters]);
  const dateToChecker = dateTo ? "-" : "";
  return (
    <Dropdown
      disabled={/* loading || */ disableChecker === "seasons"}
      className={
        (dateType !== "date" ? dateFrom : defaultDate) || dateTo
          ? "activeDateRangePickerDropdown"
          : "dateRangePickerDropdown"
      }
      name={"hours"}
      text={
        (dateType !== "date" ? dateFrom : defaultDate) || dateTo
          ? `Date ${((dateType !== "date" ? dateFrom : defaultDate) &&
              moment(dateType !== "date" ? dateFrom : defaultDate)?.format(
                dateTo ? "DD/MM" : "DD/MM/YYYY"
              )) ||
              ""}
        ${dateToChecker}
          ${(dateTo &&
            moment(dateTo)?.format(dateFrom ? "DD/MM" : "DD/MM/YYY")) ||
            ""}`
          : "Date"
      }
      icon={
        <div className={styles.dateFilterDropDownIconsWrapper}>
          <Icon
            className={styles.dateFilterDropDownIcons}
            name={"angle down"}
          />
        </div>
      }
      labeled
      multiple
      search
      floating
    >
      <Dropdown.Menu className={styles.dateRangeSelectDropDown}>
        <div className={styles.filterHolder}>
          <div className={styles.datepickerWrapper}>
            <DatePicker
              trigger={true}
              focused={false}
              value={dateType !== "date" ? dateFrom : defaultDate}
              onChange={date => {
                return (
                  setDateFrom(date), date !== dateFrom && onChange("from", date)
                );
              }}
              labelText={dateType !== "date" ? "From" : ""}
              disableClear
            />
          </div>
          {dateType !== "date" && (
            <div className={styles.secondDatepickerWrapper}>
              <DatePicker
                value={dateTo}
                onChange={date => {
                  return (
                    setDateTo(date), date !== dateTo && onChange("to", date)
                  );
                }}
                labelText={"To"}
                disableClear
              />
            </div>
          )}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

function mapStateToProps(state) {
  const {
    filters: { resetFilters },
    router: { location }
  } = state;
  return {
    resetFilters,
    location
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({}, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DateRangeSelect);
