import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ObjectId from "bson-objectid";
import {
  Button,
  Grid,
  Icon,
  Image as SemanticImage,
  Segment
} from "semantic-ui-react";
import Loader from "../../components/Loader";
import blank from "../../assets/img/blank.png";
import FileChooser, { allowedTypesImages } from "./FileChooser";
import styles from "./Attachments.module.css";
import asyncForEach from "../../utils/asyncForEach";

class Attachments extends PureComponent {
  onFilesSelected = files => {
    const { callBack, onFileSelected, serviceWorkerAction } = this.props;
    asyncForEach(files, async file => {
      const dataToSend = {
        file,
        idReplace: ObjectId().toHexString(),
        callBack,
        serviceWorkerAction
      };
      if (allowedTypesImages.includes(file.type)) {
        const reader = new FileReader();
        await reader.readAsDataURL(file);
        reader.onloadend = () => {
          onFileSelected(dataToSend);
        };
      } else {
        onFileSelected(dataToSend);
      }
    });
  };

  onDelete = (id, idReplace) => {
    const { onDelete } = this.props;
    window.localforage.removeItem(`req_${idReplace}`);
    onDelete(id || idReplace);
  };

  render() {
    const {
      attachments,
      attachmentMaxSize,
      canEdit,
      online,
      multiply
    } = this.props;

    return (
      <Segment basic className={styles.attachments}>
        <ul className={styles.listHolder}>
          {attachments &&
            attachments.map(file => (
              <li key={file.id || file.idReplace}>
                {file.idReplace && online ? (
                  <div className={styles.loaderHolder}>
                    <Loader />
                  </div>
                ) : (
                  <div>
                    {file.name && file.name.includes(".pdf") ? (
                      <Icon name="file pdf outline" />
                    ) : (
                      <SemanticImage
                        src={file.presignedUrl || file.data || blank}
                        rounded
                      />
                    )}
                  </div>
                )}
                <div>
                  <p className={styles.blockName}>
                    {/* eslint-disable-next-line */}
                    <a href={file.presignedUrl} target="_blank">
                      {file.name}
                    </a>
                  </p>
                </div>
                {canEdit && (
                  <div>
                    <Button
                      onClick={() => this.onDelete(file.id, file.idReplace)}
                      className="transparent"
                      icon="delete"
                      floated="right"
                    />
                  </div>
                )}
              </li>
            ))}

          {canEdit && (
            <Grid.Row>
              <Grid.Column>
                <FileChooser
                  onChange={this.onFilesSelected}
                  attachmentMaxSize={attachmentMaxSize}
                  multiply={multiply}
                />
              </Grid.Column>
            </Grid.Row>
          )}
        </ul>
      </Segment>
    );
  }
}

Attachments.defaultProps = {
  attachments: [],
  canEdit: true,
  showMetadata: false,
  online: true,
  multiply: false
};

Attachments.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onDelete: PropTypes.func,
  callBack: PropTypes.func,
  onFileSelected: PropTypes.func,
  attachmentMaxSize: PropTypes.number,
  canEdit: PropTypes.bool.isRequired,
  online: PropTypes.bool,
  serviceWorkerAction: PropTypes.object,
  multiply: PropTypes.bool,
  showMetadata: PropTypes.bool
};

function mapStateToProps(state) {
  const {
    offline: { online }
  } = state;
  return {
    online
  };
}

export default connect(mapStateToProps)(Attachments);
