import React from "react";
import PropTypes from "prop-types";
import QRCode from "qrcode.react";
import moment from "moment";

import styles from "./PalletsLabelPrintLayout.module.css";

const PalletsLabelPrintLayout = ({
  pallets,
  itemsInPage,
  duplicatePalletLabel,
  className,
  pageStyle,
  withoutNotes = false
}) => {
  const duplicateRange = [...Array(duplicatePalletLabel).keys()];
  const updatedPallets = pallets.flatMap(item =>
    duplicateRange.map(() => item)
  );
  const palletsRange = [...Array(updatedPallets.length).keys()];
  const itemsInPageRange = [...Array(itemsInPage).keys()];
  return (
    <div className={`${styles.block} ${className} block`}>
      <style>{`${pageStyle} html,body {margin: 0px;}`}</style>
      <div className={`${styles.page} page`}>
        {palletsRange
          .filter((_, i) => i % itemsInPage === 0)
          .map(pageOffset => (
            <div
              key={`page_${pageOffset}`}
              className={`${styles.pageBreak} pageBreak`}
            >
              {itemsInPageRange.map(itemOffset => {
                const i = pageOffset + itemOffset;
                const pallet = updatedPallets[i];
                return (
                  i < updatedPallets.length && (
                    <div
                      key={i}
                      className={`${styles.labelHolder} labelHolder`}
                    >
                      <div
                        className={`${styles.barcodeDataHolder} barcodeDataHolder`}
                      >
                        <div className={`${styles.dateText} text id`}>
                          <b>{String(pallet.id).padStart(12, "0")}</b>
                        </div>
                        <QRCode
                          renderAs="svg"
                          className={`${styles.barcode} barcode`}
                          value={String(pallet.id)}
                        />
                      </div>
                      <div className={`${styles.textHolder} textHolder`}>
                        {pallet.variety && (
                          <div className={`${styles.text} text variety`}>
                            {pallet.variety.name}
                          </div>
                        )}
                        {pallet.areas.length && (
                          <div className={`${styles.text} text area`}>
                            {pallet.areas
                              .map(({ area }) => area.registrationNumber)
                              .join(", ")}
                          </div>
                        )}
                        {pallet.boxUnit && (
                          <div className={`${styles.text} text boxUnit`}>
                            {pallet.boxUnit.displayName}
                          </div>
                        )}
                        {pallet.notes && !withoutNotes && (
                          <div className={`${styles.text} text notes`}>
                            {pallet.notes}
                          </div>
                        )}
                        {pallet.unitsOnPallet && (
                          <div className={`${styles.text} text unitsOnPallet`}>
                            {pallet.unitsOnPallet} units
                          </div>
                        )}
                        {pallet.createDate && (
                          <div className={`${styles.dateText} text createDate`}>
                            <b>
                              {moment(pallet.createDate).format("DD/MM/YYYY")}
                            </b>
                          </div>
                        )}
                        {pallet.packingShed && (
                          <div className={`${styles.packingShed} packingShed`}>
                            <div>
                              {pallet.packingShed.name}
                              {pallet.packingShed.registrationNumber && (
                                <span>
                                  {" "}
                                  - {pallet.packingShed.registrationNumber}
                                </span>
                              )}
                            </div>
                            <div>
                              <span>
                                {pallet.packingShed.street &&
                                  `${pallet.packingShed.street},`}{" "}
                              </span>
                              <span>
                                {pallet.packingShed.houseNumber &&
                                  `${pallet.packingShed.houseNumber},`}{" "}
                              </span>
                              <span>
                                {pallet.packingShed.city &&
                                  `${pallet.packingShed.city},`}{" "}
                              </span>
                              <span>
                                {pallet.packingShed.state &&
                                  `${pallet.packingShed.state},`}
                              </span>
                              <span>
                                {pallet.packingShed.country &&
                                  `${pallet.packingShed.country},`}{" "}
                              </span>
                              <span>
                                {pallet.packingShed.zip &&
                                  `${pallet.packingShed.zip},`}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )
                );
              })}
            </div>
          ))}
      </div>
    </div>
  );
};

PalletsLabelPrintLayout.propTypes = {
  pallets: PropTypes.array.isRequired,
  itemsInPage: PropTypes.number,
  duplicatePalletLabel: PropTypes.number,
  className: PropTypes.string,
  pageStyle: PropTypes.any
};

PalletsLabelPrintLayout.defaultProps = {
  itemsInPage: 1,
  duplicatePalletLabel: 1
};

export default PalletsLabelPrintLayout;
