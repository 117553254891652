import { Grid } from "semantic-ui-react";
import styles from "../styles.module.css";
import PropTypes from "prop-types";
import React from "react";
import { ShowField } from "./index";
import { useSelector } from "react-redux";
import { cropText } from "../../../../../utils/string";

function Review({ switchStep, allowedFields, taskTypeID, pickingTypes }) {
  const subTypes = useSelector(state =>
    state.taskCreator.taskTypes.content.find(type => +type.id === +taskTypeID)
  ).taskSubTypes;
  const subType = subTypes.find(a => a.id === switchStep.subType) || {};
  const harvestUnit =
    useSelector(state => state.harvestUnits.data.content).find(
      a => a.id === switchStep.harvestUnitId
    ) || {};
  const pickingType =
    pickingTypes.find(p => p.id === switchStep.pickingType) || {};

  const flowRate =
    switchStep.flowRate &&
    (switchStep.runningTimeHours || switchStep.runningTimeMinutes)
      ? (
          switchStep.flowRate *
          (switchStep.runningTimeHours + switchStep.runningTimeMinutes / 60)
        ).toFixed(2)
      : 0;

  return (
    <Grid style={{ paddingLeft: 40 }}>
      <Grid.Row>
        <Grid.Column computer={2} mobile={16}>
          <span className={styles.fieldTitle}>Round:</span>
          <br />
          <span>{switchStep.round || "-"}</span>
        </Grid.Column>
        <Grid.Column computer={2} mobile={16}>
          <span className={styles.fieldTitle}>Type:</span>
          <br />
          <span>{subType.name || "-"}</span>
        </Grid.Column>
        <ShowField
          allowedFields={allowedFields}
          fieldName={"HARVEST_UNIT_PICKING_TYPE"}
        >
          <Grid.Column computer={2} mobile={16}>
            <span className={styles.fieldTitle}>Picking Type:</span>
            <br />
            <span>{pickingType.name || "-"}</span>
          </Grid.Column>
        </ShowField>
        <ShowField allowedFields={allowedFields} fieldName={"HARVEST_UNIT"}>
          <Grid.Column computer={2} mobile={16}>
            <span className={styles.fieldTitle}>Harvest unit:</span>
            <br />
            <span>{harvestUnit.name || "-"}</span>
          </Grid.Column>
        </ShowField>
        <ShowField allowedFields={allowedFields} fieldName={"UNIT_PRICE"}>
          <Grid.Column computer={2} mobile={16}>
            <span className={styles.fieldTitle}>Unit price:</span>
            <br />
            <span>{switchStep.unitPrice || "-"}$</span>
          </Grid.Column>
        </ShowField>
        <ShowField allowedFields={allowedFields} fieldName={"UNIT_PRICE"}>
          <Grid.Column computer={2} mobile={16}>
            <span className={styles.fieldTitle}>Size:</span>
            <br />
            <span>{switchStep.size || "-"}</span>
          </Grid.Column>
        </ShowField>
        <Grid.Column computer={2} mobile={16}>
          <span className={styles.fieldTitle}>Comments:</span>
          <br />
          <span className={styles.fieldValueLong}>
            {cropText(switchStep.comments) || "-"}
          </span>
        </Grid.Column>
        <ShowField allowedFields={allowedFields} fieldName={"TIME_BUDGETING"}>
          <Grid.Column computer={2} mobile={16}>
            <span className={styles.fieldTitle}>Time Budgeting:</span>
            <br />
            <span>{switchStep.timeBudgeting || "-"}</span>
          </Grid.Column>
        </ShowField>
        <ShowField
          allowedFields={allowedFields}
          fieldName={"TIME_BUDGETING_PER_ROW"}
        >
          <Grid.Column computer={2} mobile={16}>
            <span className={styles.fieldTitle}>Per Row:</span>
            <br />
            <span>{switchStep.perRow || "-"}</span>
          </Grid.Column>
        </ShowField>
        <ShowField
          allowedFields={allowedFields}
          fieldName={"TIME_BUDGETING_PER_PLANT"}
        >
          <Grid.Column computer={2} mobile={16}>
            <span className={styles.fieldTitle}>Per Plant:</span>
            <br />
            <span>{switchStep.perPlant || "-"}</span>
          </Grid.Column>
        </ShowField>
        <ShowField allowedFields={allowedFields} fieldName={"RUNNING_TIME_MIN"}>
          <Grid.Column computer={2} mobile={16}>
            <span className={styles.fieldTitle}>Running Time:</span>
            <br />
            <span>{`${switchStep.runningTimeHours ||
              0}h ${switchStep.runningTimeMinutes || 0}m`}</span>
          </Grid.Column>
        </ShowField>
        <ShowField allowedFields={allowedFields} fieldName={"FLOW_RATE"}>
          <Grid.Column computer={2} mobile={16}>
            <span className={styles.fieldTitle}>Flow Rate:</span>
            <br />
            <span>{flowRate || "-"}</span>
          </Grid.Column>
        </ShowField>
        <ShowField
          allowedFields={allowedFields}
          fieldName={"TOTAL_IRRIGATION_WATER"}
        >
          <Grid.Column computer={2} mobile={16}>
            <span className={styles.fieldTitle}>Total Irrigation Water:</span>
            <br />
            <span>{switchStep.totalIrrigationWater || "-"}</span>
          </Grid.Column>
        </ShowField>
      </Grid.Row>
    </Grid>
  );
}

export default Review;

Review.propTypes = {
  allowedFields: PropTypes.array.isRequired,
  pickingTypes: PropTypes.array.isRequired,
  switchStep: PropTypes.object.isRequired,
  taskTypeID: PropTypes.number.isRequired
};
