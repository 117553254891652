import React, { memo } from "react";
import PropTypes from "prop-types";
import ReactTable, { ReactTableDefaults } from "react-table-6";
import selectTableHOC from "react-table-6/lib/hoc/selectTable";
import classNames from "classnames";
import _, { isEqual } from "lodash";
import {
  Button,
  Dropdown,
  Form,
  Grid,
  Header,
  Icon,
  Input,
  Segment
} from "semantic-ui-react";
import Loader from "../Loader";
import styles from "./ListTable.module.css";
import Pagination from "./Pagination";
import DatePicker from "../DatePicker";
import SortDropdown from "../SortDropdown";
import { history } from "../../store";
import { getHashParameter, setHashParameter } from "../../utils/hashToObject";
import { connect } from "react-redux";
import Filters, { parseFilters } from "../Filters";
import ExportToExcel from "../ExportToExcel";

const LOAD_MORE_PAGES = 10;
export const LoaderComponent = props => {
  return (
    <div
      className={`${styles.loadingHolder} ${
        props.loading ? styles.showLoading : ""
      }`}
    >
      <Loader />
    </div>
  );
};

const SelectReactTable = selectTableHOC(ReactTable);

class ListTable extends React.Component {
  state = {
    columns: [],
    options: {
      keyField: "id",
      showPaginationTop: false,
      showPaginationBottom: true,
      minRows: 0,
      sortable: false,
      resizable: true,
      filterable: false
    },

    defaultPageSize: 10,
    totalPages: 1,
    itemsCount: {
      itemFrom: 0,
      itemTo: 0
    },
    search: "",
    sortOptions: null,
    sortOrder: null,
    sortValue: "none",
    dateFrom: null,
    dateTo: null,
    filtersOptions: [],
    filters: []
  };

  constructor(props) {
    super(props);

    this.tableRef = React.createRef();
  }

  componentDidMount() {
    const { location } = this.props;
    this.setState({
      search: getHashParameter(location, "search") || "",
      itemsCount: this.getItemsCount(),
      sortOptions: this.getSortOptions(),
      columns: this.extendColumns()
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      currentPage,
      totalElements,
      pageSize,
      columns,
      location
    } = this.props;
    const { filtersOptions, filters } = this.state;

    if (
      prevProps.currentPage !== currentPage ||
      prevProps.totalElements !== totalElements ||
      prevProps.pageSize !== pageSize
    ) {
      this.setState({
        itemsCount: this.getItemsCount()
      });
    }

    if (prevProps.columns !== columns) {
      const filtersOptions = this.generateFiltersOptions();
      this.setState({
        sortOptions: this.getSortOptions(),
        columns: this.extendColumns(),
        filtersOptions: filtersOptions,
        filters: parseFilters(filtersOptions, location)
      });
    }

    if (prevProps.location !== location) {
      this.setState({
        search: getHashParameter(location, "search") || "",
        filters: parseFilters(filtersOptions, location)
      });
    }

    if (!isEqual(prevState.filters, filters)) {
      this.onFiltersChange();
    }
  }

  renderActionsColumn = ({
    value,
    row: {
      _original: { rights }
    }
  }) => {
    const { Can, accessName, addNewPath, editPath, showConfirm } = this.props;

    return (
      <div className="actionBlock">
        <Can I={rights.update} a={accessName}>
          <Button
            className="button-text"
            icon
            onClick={e => {
              e.stopPropagation();
              history.push(`${editPath || addNewPath}/${value}`);
            }}
          >
            <Icon name="pencil alternate" />
          </Button>
        </Can>
        <Can I={rights.delete} a={accessName}>
          <Dropdown
            icon="ellipsis vertical"
            iconposition="left"
            floating
            button
            className="button-text"
          >
            <Dropdown.Menu direction="left">
              <Dropdown.Item
                onClick={() => {
                  showConfirm(value);
                }}
              >
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Can>
      </div>
    );
  };

  extendColumns = () => {
    const { columns } = this.props;
    const actionsIndex = columns.findIndex(column => column.id === "actions");
    if (actionsIndex >= 0) {
      columns[actionsIndex].Cell = this.renderActionsColumn;
    }
    return columns;
  };

  generateFiltersOptions = () => {
    const { columns } = this.props;
    return columns
      .filter(column => column.filter)
      .map(column => ({
        id: column.id,
        name: column.Header,
        ...column.filter
      }));
  };

  getSortOptions = () => {
    const { columns } = this.props;
    const sortOptions = [];
    columns.forEach(column => {
      if (
        column.accessor !== "actions" &&
        column.Header &&
        !column.disableSort
      ) {
        sortOptions.push({
          key: column.id,
          text: `Sort by ${column.Header}`,
          value: column.id,
          content: column.Header,
          sortfields: column.sortFields || [column.id]
        });
      }
    });

    return sortOptions;
  };

  getSortOption = (sortOptions, sortValue, currentSortOrder) => {
    const targetOption = sortOptions.find(option => option.value === sortValue);
    const sort =
      targetOption && targetOption.sortfields
        ? targetOption.sortfields
            .filter(sortItem => sortItem !== "none")
            .map(sortItem => `${sortItem},${currentSortOrder}`)
        : null;
    return sort && sort.length > 0 ? sort : null;
  };

  onFiltersChange = () => {
    const { getData, pageSize } = this.props;
    const { sortOrder, sortOptions, sortValue, search, filters } = this.state;
    this.setState({ sortValue, sortOrder });

    setTimeout(() => {
      getData({
        page: 0,
        size: pageSize,
        search,
        sort: this.getSortOption(sortOptions, sortValue, sortOrder),
        filters
      });
    }, 900);
  };

  onSortChange = ({ sortValue, sortOrder, sort }) => {
    const { getData, pageSize } = this.props;
    const { search, filters } = this.state;
    this.setState({ sortValue, sortOrder });
    getData({
      page: 0,
      size: pageSize,
      search,
      sort,
      filters
    });
  };

  updatePageSize = (_, pageSize) => {
    const { getData } = this.props;
    const { sortOrder, sortOptions, sortValue, search, filters } = this.state;
    getData({
      page: 0,
      size: pageSize.value,
      search,
      sort: this.getSortOption(sortOptions, sortValue, sortOrder),
      filters
    });
  };

  onPageChange = (page, loadMore) => {
    const { getData, pageSize } = this.props;
    const { sortOrder, sortOptions, sortValue, search, filters } = this.state;
    if (loadMore) {
      getData({
        page: 0,
        size: pageSize + LOAD_MORE_PAGES,
        search,
        sort: this.getSortOption(sortOptions, sortValue, sortOrder),
        filters
      });
    } else {
      getData({
        page,
        size: pageSize,
        search,
        sort: this.getSortOption(sortOptions, sortValue, sortOrder),
        filters
      });
    }
  };

  toggleSelection = key => {
    const { setSelectedItems } = this.props;
    let selectedItems = [...this.props.selectedItems];
    const keyIndex = selectedItems.indexOf(key);
    if (keyIndex >= 0) {
      selectedItems = [
        ...selectedItems.slice(0, keyIndex),
        ...selectedItems.slice(keyIndex + 1)
      ];
    } else {
      selectedItems.push(key);
    }

    setSelectedItems(selectedItems);
  };

  toggleAll = () => {
    const { data, selectedItems, keyField, setSelectedItems } = this.props;
    let selection;

    if (!this.isSelectAll()) {
      selection = [
        ...selectedItems,
        ...data.map(item => `select-${item[keyField]}`)
      ];
    } else {
      selection = selectedItems.filter(
        selectedItem =>
          data.findIndex(
            item => selectedItem === `select-${item[keyField]}`
          ) === -1
      );
    }

    setSelectedItems(selection);
  };

  isSelected = key => {
    const { selectedItems } = this.props;
    return selectedItems.includes(`select-${key}`);
  };

  isSelectAll = () => {
    const { data, selectedItems, keyField } = this.props;
    return (
      data.filter(
        item =>
          selectedItems.findIndex(
            selectedItem => selectedItem === `select-${item[keyField]}`
          ) === -1
      ).length === 0
    );
  };

  handleSearch = _.debounce(() => {
    const { sortOrder, sortOptions, sortValue, search } = this.state;
    const { getData, pageSize } = this.props;
    /*TODO update action for search*/
    getData({
      page: 0,
      size: pageSize,
      search,
      sort: this.getSortOption(sortOptions, sortValue, sortOrder)
    });
  }, 900);

  onSearchChange = event => {
    const { location } = this.props;
    setHashParameter(location, "search", event.target.value);
    this.handleSearch();
  };

  getItemsCount = () => {
    const { currentPage, totalElements, pageSize } = this.props;
    const itemFrom = currentPage * pageSize + 1;
    const itemTo = Math.min(itemFrom + pageSize - 1, totalElements);
    return { itemFrom, itemTo };
  };

  changeDate = (fieldName, fieldValue) => {
    this.setState({
      [fieldName]: fieldValue
    });
  };

  render() {
    const {
      location,
      filterName,
      column,
      pageSize,
      currentPage,
      totalPages,
      data,
      getTrProps,
      optionsMore,
      isFetching,
      totalElements,
      withDateRange,
      online,
      defaultSort,
      withSelection,
      selectedItems,
      tableClassName,
      exportToExcel,
      withItemsCount,
      withSearch
    } = this.props;
    const {
      options,
      defaultPageSize,
      itemsCount,
      search, // eslint-disable-line
      sortOptions,
      sortValue,
      sortOrder,
      dateFrom,
      dateTo,
      columns,
      filtersOptions
    } = this.state;
    const CustomReactTable = withSelection ? SelectReactTable : ReactTable;
    const newFiltersOptions = filtersOptions.map(item => {
      return item.name
        ? { ...item }
        : {
            ...item,
            name: "Season"
          };
    });
    const convertedFilters = Object.fromEntries(this.state.filters);
    const disableChecker =
      !!convertedFilters.actions || !!convertedFilters.empty
        ? "seasons"
        : !!convertedFilters.scannedAtFrom ||
          !!convertedFilters.plannedDateFrom ||
          !!convertedFilters.setOnFrom ||
          !!convertedFilters.setOnTo ||
          !!convertedFilters.scannedAtTo ||
          !!convertedFilters.plannedDateTo
        ? "date"
        : null;

    return (
      <div className={styles.tableHolder}>
        <div className={styles.tableTools}>
          <Grid verticalAlign="middle" className={styles.filtersGrid}>
            {withSearch && filtersOptions && filtersOptions.length > 0 && (
              <Grid.Row>
                <Grid.Column width={16}>
                  <Filters
                    disableChecker={disableChecker}
                    options={newFiltersOptions}
                    location={location}
                    filterName={filterName}
                  />
                </Grid.Column>
              </Grid.Row>
            )}
            <Grid.Row>
              {withSearch && (
                <Grid.Column
                  mobile={8}
                  tablet={10}
                  computer={6}
                  largeScreen={4}
                  widescreen={4}
                >
                  <Input
                    icon="search"
                    placeholder="Search"
                    value={search}
                    fluid
                    onChange={this.onSearchChange}
                  />
                </Grid.Column>
              )}

              {!withSearch && filtersOptions && filtersOptions.length > 0 && (
                <Grid.Column width={10}>
                  <Filters
                    disableChecker={disableChecker}
                    options={newFiltersOptions}
                    location={location}
                    filterName={filterName}
                  />
                </Grid.Column>
              )}

              <Grid.Column
                width={6}
                textAlign="right"
                floated="right"
                className={styles.sortColumn}
              >
                {exportToExcel && this.tableRef.current && (
                  <ExportToExcel
                    tableRef={this.tableRef}
                    name={"TUF Exported Data"}
                    className={"mr-1"}
                  />
                )}
                {sortOptions && sortOptions.length > 0 && (
                  <SortDropdown
                    sortOptions={sortOptions}
                    onChange={this.onSortChange}
                    sortValue={sortValue}
                    sortOrder={sortOrder}
                    defaultSort={defaultSort}
                  />
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Segment basic className={styles.countItems}>
            <div className={styles.verticalMiddle}>
              <Form.Group inline>
                {withItemsCount && (
                  <>
                    {itemsCount.itemTo ? (
                      <Header className={styles.totalItems}>
                        {itemsCount.itemFrom}-{itemsCount.itemTo} /{" "}
                        {totalElements || "..."} items{" "}
                        {selectedItems.length > 0 && (
                          <span>({selectedItems.length} selected)</span>
                        )}
                      </Header>
                    ) : (
                      <Header className={styles.totalItems}>No items</Header>
                    )}
                  </>
                )}
                {withDateRange && (
                  <span className={styles.datePicker}>
                    <DatePicker
                      value={dateFrom}
                      onChange={date => this.changeDate("dateFrom", date)}
                      labelText="From"
                    />
                    <DatePicker
                      value={dateTo}
                      onChange={date => this.changeDate("dateTo", date)}
                      labelText="To"
                    />
                  </span>
                )}
              </Form.Group>
            </div>
          </Segment>
        </div>
        <CustomReactTable
          {...{ ...options, ...optionsMore }}
          className={`-striped ${styles.listTable}`}
          loading={isFetching}
          page={0}
          currentPage={currentPage}
          pages={totalPages}
          onPageChangeOwn={this.onPageChange}
          getTrProps={getTrProps}
          data={data}
          itemsCount={itemsCount}
          totalElements={totalElements}
          updatePageSize={this.updatePageSize}
          PaginationComponent={Pagination}
          LoadingComponent={LoaderComponent}
          ref={this.tableRef}
          column={{
            ...ReactTableDefaults.column,
            ...column
          }}
          columns={columns}
          pageSize={pageSize || defaultPageSize}
          online={online}
          noDataText="No data available"
          getProps={() => ({ id: "listTable" })}
          getTableProps={() => ({ className: tableClassName })}
          toggleSelection={this.toggleSelection}
          getTrGroupProps={(state, rowInfo) => ({
            id: `row-group-${rowInfo.original.id}`,
            className: classNames({
              [styles.selectedRow]: this.isSelected(rowInfo.original.id)
            })
          })}
          {...(withSelection
            ? {
                isSelected: this.isSelected,
                toggleAll: this.toggleAll,
                selectAll: this.isSelectAll()
              }
            : {})}
        />
      </div>
    );
  }
}

LoaderComponent.propTypes = {
  loading: PropTypes.bool
};

ListTable.propTypes = {
  Can: PropTypes.func,
  accessName: PropTypes.string,
  addNewPath: PropTypes.string,
  column: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  currentPage: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
  defaultSort: PropTypes.string,
  editPath: PropTypes.string,
  exportToExcel: PropTypes.bool,
  getData: PropTypes.func.isRequired,
  getTrProps: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  keyField: PropTypes.string,
  location: PropTypes.object,
  online: PropTypes.bool,
  optionsMore: PropTypes.object.isRequired,
  pageSize: PropTypes.number.isRequired,
  selectedItems: PropTypes.array,
  setSelectedItems: PropTypes.func,
  showConfirm: PropTypes.func,
  totalElements: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  tableClassName: PropTypes.string,
  withDateRange: PropTypes.bool,
  withItemsCount: PropTypes.bool,
  withSearch: PropTypes.bool,
  withSelection: PropTypes.bool
};

ListTable.defaultProps = {
  accessName: "anonymous",
  column: {},
  columns: [],
  currentPage: 1,
  data: [],
  exportToExcel: false,
  getData: () => ({}),
  getTrProps: () => ({}),
  optionsMore: {},
  pageSize: 10,
  tableClassName: null,
  totalElements: 0,
  totalPages: 1,
  withDateRange: false,
  withItemsCount: true,
  withSearch: true,
  withSelection: false
};

function mapStateToProps(state) {
  const {
    router: { location }
  } = state;
  return {
    location
  };
}

export default memo(connect(mapStateToProps)(ListTable));
