export const actionType = {
  FETCH_BOX_UNITS_START: 'BOX_UNITS/FETCH_START',
  FETCH_BOX_UNITS: 'BOX_UNITS/FETCH',
  FETCH_BOX_UNITS_FAIL: 'BOX_UNITS/FETCH_FAIL',

  FETCH_BOX_UNITS_LIST_START: 'BOX_UNITS/FETCH_LIST_START',
  FETCH_BOX_UNITS_LIST: 'BOX_UNITS/FETCH_LIST',
  FETCH_BOX_UNITS_LIST_FAIL: 'BOX_UNITS/FETCH_LIST_FAIL',

  GET_BOX_UNITS_BY_ID_START: 'BOX_UNITS/GET_BY_ID_START',
  GET_BOX_UNITS_BY_ID: 'BOX_UNITS/GET_BY_ID',
  GET_BOX_UNITS_BY_ID_FAIL: 'BOX_UNITS/GET_BY_ID_FAIL',

  ADD_BOX_UNITS_START: 'BOX_UNITS/ADD_START',
  ADD_BOX_UNITS: 'BOX_UNITS/ADD',
  ADD_BOX_UNITS_FAIL: 'BOX_UNITS/ADD_FAIL',

  UPDATE_BOX_UNITS_START: 'BOX_UNITS/UPDATE_START',
  UPDATE_BOX_UNITS: 'BOX_UNITS/UPDATE',
  UPDATE_BOX_UNITS_FAIL: 'BOX_UNITS/UPDATE_FAIL',

  DELETE_BOX_UNITS_START: 'BOX_UNITS/DELETE_START',
  DELETE_BOX_UNITS: 'BOX_UNITS/DELETE',
  DELETE_BOX_UNITS_FAIL: 'BOX_UNITS/DELETE_FAIL',

  CLEAR_BOX_UNITS_ERROR: 'BOX_UNITS/CLEAR_BOX_UNITS_ERROR',
};
