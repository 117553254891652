import { APPLICATION_TYPES } from "../constants/Recommendations/types";

/**
 * @param {{}} obj
 * @returns {{}}
 */
export const sanitizeObject = obj => {
  const temp = {};

  Object.entries(obj).forEach(([name, value]) => {
    if (!!value) temp[name] = value;
  });

  return temp;
};

export const objectToSelectOptions = object =>
  Object.entries(object).map(([key, text]) => ({
    key,
    text,
    value: key
  }));
