import PropTypes from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";
import { Button, Grid } from "semantic-ui-react";
import { moveToTheNextStage } from "../../../../actions/GeneralTaskCreator/taskCreator";
import styles from "./styles.module.css";
import check from "./asserts/check.svg";

const TaskStepFooter = ({
  isValid = false,
  submitAction,
  submitButtonText = "Next"
}) => {
  const dispatch = useDispatch();

  const moveToNextStage = () =>
    submitAction ? submitAction() : dispatch(moveToTheNextStage());

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Button
            disabled={!isValid}
            onClick={moveToNextStage}
            className={styles.nextButton}
            color={"primary"}
            floated={"right"}
          >
            <img src={check} alt="" /> <span>{submitButtonText}</span>
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

TaskStepFooter.propTypes = {
  isValid: PropTypes.bool.isRequired,
  submitAction: PropTypes.func,
  submitButtonText: PropTypes.string.isRequired
};

TaskStepFooter.defaultProps = {
  isValid: false,
  submitButtonText: "Next"
};

export default TaskStepFooter;
