import React, { useState } from "react";
import { Button, Icon } from "semantic-ui-react";
import styles from "../../Employees/Employees.module.css";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import { RestoreIcon } from "../../../../assets/icons/RestoreIcon";

const TableActionButtons = ({
  isArchive,
  onArchiveButtonClick,
  selectedItems,
  onDeleteButtonClick,
  actionsLocation
}) => {
  const [isArchiveConfirmShown, setArchiveConfirmShown] = useState(false);
  const [isDeleteConfirmShown, setDeleteConfirmShown] = useState(false);

  return (
    <>
      <div className={styles.buttonContainer}>
        <Button
          basic
          size="small"
          className={styles.actionButton}
          onClick={() => setArchiveConfirmShown(true)}
          color="green"
        >
          {isArchive ? (
            <span style={{ display: "flex" }}>
              <RestoreIcon /> Restore
            </span>
          ) : (
            <span>
              {" "}
              <Icon name="archive" /> Archive
            </span>
          )}
        </Button>
        {isArchive && (
          <Button
            color="red"
            basic
            size="small"
            className={styles.actionButton}
            onClick={() => setDeleteConfirmShown(true)}
          >
            <Icon name="trash alternate" />
            Delete
          </Button>
        )}
      </div>
      <ConfirmationModal
        open={isArchiveConfirmShown}
        selectedItems={selectedItems}
        actionType={isArchive ? "Restore" : "Archive"}
        modalLocation={actionsLocation}
        onChangeItem={onArchiveButtonClick}
        onClose={() => setArchiveConfirmShown(false)}
      />
      <ConfirmationModal
        open={isDeleteConfirmShown}
        selectedItems={selectedItems}
        actionType="Delete"
        modalLocation={actionsLocation}
        onChangeItem={onDeleteButtonClick}
        onClose={() => setDeleteConfirmShown(false)}
      />
    </>
  );
};

export default TableActionButtons;
