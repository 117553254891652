export const sortConfig = {
  defaultSort: "farm,asc",
  sortOptions: [
    {
      key: "farm",
      text: "Sort by Farm",
      value: "farm",
      content: "Farm",
      sortfields: ["farm"]
    },
    {
      key: "date",
      text: "Sort by Completed date",
      value: "date",
      content: "Completed date",
      sortfields: ["date"]
    },
    {
      key: "plannedDate",
      text: "Sort by Planned date",
      value: "plannedDate",
      content: "Planned date",
      sortfields: ["plannedDate"]
    },
    {
      key: "safeHarvestDate",
      text: "Sort by  Safe harvest date",
      value: "safeHarvestDate",
      content: "Safe harvest date",
      sortfields: ["harvestDate"]
    }
  ]
};

export const filtersOptions = [
  {
    id: "farm",
    name: "Farm",
    type: "FarmMultiSelectFilter"
  },
  {
    id: "area",
    name: "Area",
    type: "AreaMultiSelectFilter"
  },
  /* {
    id: "crop",
    name: "Crop",
    type: "CropMultiSelectFilter"
  },
  {
    id: "variety",
    name: "Variety",
    type: "VarietyMultiSelectFilter"
  }, */
  {
    name: "Planned Date",
    type: "DateRangeFilter",
    id: "plannedDate",
    fromKey: "After",
    toKey: "Before"
  },
  {
    name: "Completed Date",
    type: "DateRangeFilter",
    id: "endDate",
    fromKey: "After",
    toKey: "Before"
  },
  {
    name: "Expiry Date",
    type: "DateRangeFilter",
    id: "expirationDate",
    fromKey: "After",
    toKey: "Before"
  },
  {
    name: "Product",
    type: "ProductMultiSelectFilter",
    id: "chemical"
  },
  {
    name: "Active Ingredient",
    type: "ActiveIngredientsMultiSelectFilter",
    id: "activeIngredient"
  },
  {
    name: "Application type",
    type: "ApplicationTypeMultiSelectFilter",
    id: "applicationType"
  },
  {
    name: "Product type",
    type: "ProductTypeSingleMultiFilter",
    id: "chemicalTypeIds"
  },
  {
    id: "seasons",
    name: "Season",
    type: "SeasonSingleSelectFilter"
  }
];
