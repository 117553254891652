import { useDispatch, useSelector } from "react-redux";
import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import { fetchChemicals } from "../../../../actions/Chemicals";
import { Dropdown } from "semantic-ui-react";
import {
  LOADING_MESSAGE,
  NO_RESULTS_MESSAGE
} from "../../../../constants/shared/messages";

const ChemicalsDropdown = ({ value, name }) => {
  const { chemicalsList } = useSelector(state => state.sprayDiary);
  const { isFetching: loading, content } = chemicalsList;
  const dispatch = useDispatch();
  const { setFieldValue } = useFormikContext();
  const options = content.map(item => ({
    key: item.id,
    text: item.name,
    value: item.id
  }));
  useEffect(() => {
    if (!content.length && !loading) {
      dispatch(fetchChemicals({ unpaged: true }));
    }
  }, [loading, content]);

  return (
    <Dropdown
      search
      fluid
      noResultsMessage={loading ? LOADING_MESSAGE : NO_RESULTS_MESSAGE}
      selection
      placeholder="Select chemical"
      onChange={(_, { value }) => {
        const chemical = chemicalsList.content.find(({ id }) => id === value);

        setFieldValue(`${name}`, value);
        setFieldValue(name.replace("chemicalId", "chemical"), {
          ...chemical,
          rate: {}
        });
      }}
      value={value}
      loading={chemicalsList.isFetching}
      options={options}
    />
  );
};

export default ChemicalsDropdown;
