import React from "react";
import classNames from "classnames";
import { Icon, Label } from "semantic-ui-react";
import styles from "./Attachments.module.css";
import { get } from "lodash";

const AttachmentLabel = ({
  attachment,
  className,
  onDeleteClick,
  withDownloadfromEdit,
  withPercent,
  percent,
  withDownloadIcon
}) => {
  const fileNameExtensions = name => {
    const m = name?.match(/\.([^.]+)$/);
    return m && m[1];
  };
  const fileName = name => {
    return name?.replace(/\..*/, "");
  };

  const restProps = withDownloadIcon
    ? {
        as: "a",
        href: attachment && attachment.presignedUrl && attachment.presignedUrl,
        target: "_blank"
      }
    : withDownloadfromEdit
    ? {
        as: "a",
        href: attachment && attachment.presignedUrl && attachment.presignedUrl,
        target: "_blank"
      }
    : {};
  return (
    <span style={{ display: "flex" }}>
      <Label
        onClick={event => event.stopPropagation()}
        basic
        className={classNames(
          withDownloadIcon ? styles.withoutCloseIcon : styles.attachmentLabel,
          className
        )}
        {...restProps}
      >
        {withPercent ? (
          <span className={styles.percentNumber}>{percent}%</span>
        ) : (
          <Icon
            size="small"
            name={
              withDownloadIcon || withDownloadfromEdit ? "download" : "file"
            }
            className={
              withDownloadIcon || withDownloadfromEdit
                ? styles.downloadButton
                : styles.attachmentIcon
            }
          />
        )}
        <span
          className={
            percent ? styles.uploadingAttachmentTitle : styles.attachmentTitle
          }
        >
          {get(attachment, "name")?.length > 17
            ? fileName(get(attachment, "name")).slice(0, 5) +
              "..." +
              fileName(get(attachment, "name")).slice(-4)
            : fileName(get(attachment, "name"))}
          .{fileNameExtensions(get(attachment, "name"))}
        </span>
      </Label>
      {onDeleteClick && (
        <div className={styles.deleteButtonContainer}>
          <Icon
            name="close"
            className={styles.deleteButton}
            onClick={onDeleteClick}
          />
        </div>
      )}
    </span>
  );
};

export default AttachmentLabel;
