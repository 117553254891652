import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Icon, Message, Transition } from "semantic-ui-react";
import styles from "./InfoMessage.module.css";

const InfoMessage = props => {
  const {
    message,
    onClose,
    color,
    duration,
    autoHide,
    children,
    extraData
  } = props;
  const errorStatus = message.slice(-3);
  const [visible, setVisible] = useState(true);
  useEffect(() => {
    if (visible && autoHide) {
      setTimeout(() => setVisible(false), duration);
    }
  }, [visible, autoHide, duration]);
  return (
    errorStatus != 401 && (
      <Transition
        visible={visible}
        animation="fade"
        duration={1000}
        onHide={onClose}
      >
        <Message
          className={`${styles.messageInfo} ${props.className}`}
          color={color}
          style={props.style}
        >
          {<div>{extraData?.body?.message || extraData?.body || message}</div>}
          {onClose && (
            <div className={styles.closeButton}>
              <Button className="button-text" onClick={onClose}>
                <Icon name="close" />
              </Button>
            </div>
          )}
          {children}
        </Message>
      </Transition>
    )
  );
};
InfoMessage.defaultProps = {
  message: "",
  color: "",
  className: ""
};
InfoMessage.propTypes = {
  message: PropTypes.string,
  color: PropTypes.string,
  onClose: PropTypes.func,
  duration: PropTypes.any,
  className: PropTypes.any,
  style: PropTypes.any,
  autoHide: PropTypes.bool,
  children: PropTypes.node
};

export default InfoMessage;
