import React from "react";
import { Field, useFormikContext } from "formik";
import { map, get } from "lodash";
import { Form, Input, Segment } from "semantic-ui-react";
import CollapsibleBlock from "../../../../../components/CollapsibleBlock";

const ContractorInformation = () => {
  const { values, setFieldValue } = useFormikContext();

  const typeOptions = map(
    [
      {
        id: 1,
        value: "CHEMICAL_AND_FERTILISING_OPERATIONS",
        label: "Chemical and Fertilising Operations"
      },
      {
        id: 0,
        value: "LAND_AND_FIELDWORK",
        label: "Land and Fieldwork"
      }
    ],
    ({ id, value, label }) => ({
      id,
      key: id,
      value,
      text: label
    })
  );

  return (
    <Segment>
      <CollapsibleBlock title="Contractor Information">
        <Form>
          <Form.Group>
            <Field name="name" required>
              {({ field, meta }) => (
                <Form.Input
                  value={get(values, "name")}
                  width={7}
                  label="Contractor name"
                  error={meta.touched && meta.error}
                  required
                  {...field}
                />
              )}
            </Field>
            <Form.Field width={1} />
            <Field name="phoneNumber">
              {({ field, meta }) => (
                <Form.Input
                  value={get(values, "phoneNumber")}
                  width={7}
                  label="Phone number"
                  error={meta.touched && meta.error}
                  {...field}
                />
              )}
            </Field>
            <Form.Field width={1} />
          </Form.Group>
          <Form.Group>
            <Field name="address">
              {({ field, meta }) => (
                <Form.Input
                  value={get(values, "address")}
                  width={7}
                  label="Address"
                  error={meta.touched && meta.error}
                  {...field}
                />
              )}
            </Field>
            <Form.Field width={1} />
            <Field name="email">
              {({ field, meta }) => (
                <Form.Input
                  width={7}
                  label="Email"
                  value={get(values, "email")}
                  error={meta.touched && meta.error}
                  validations={{ isEmail: true }}
                  {...field}
                />
              )}
            </Field>
            <Form.Field width={1} />
          </Form.Group>
          <Form.Group>
            <Form.Field width={7} />
            <Form.Field width={1} />
            <Field name="abn">
              {({ field, meta }) => (
                <Form.Input
                  value={get(values, "abn")}
                  width={7}
                  label="ABN"
                  error={meta.touched && meta.error}
                  {...field}
                />
              )}
            </Field>
            <Form.Field width={1} />
          </Form.Group>
        </Form>
      </CollapsibleBlock>
    </Segment>
  );
};

export default ContractorInformation;
