export const actionType = {
  GENERAL_TASK_WORKING_TIMES_FETCH_START:
    "GENERAL_TASKS_WORKING_TIMES/FETCH_START",
  GENERAL_TASK_WORKING_TIMES_FETCH: "GENERAL_TASKS_WORKING_TIMES/FETCH",
  GENERAL_TASK_WORKING_TIMES_FETCH_ERROR:
    "GENERAL_TASKS_WORKING_TIMES/FETCH_ERROR",

  GENERAL_TASK_WORKING_TIME_CREATE_START:
    "GENERAL_TASKS_WORKING_TIMES/CREATE_START",
  GENERAL_TASK_WORKING_TIME_CREATE: "GENERAL_TASKS_WORKING_TIMES/CREATE",
  GENERAL_TASK_WORKING_TIME_CREATE_FAIL:
    "GENERAL_TASKS_WORKING_TIMES/CREATE_FAIL",

  GENERAL_TASK_WORKING_TIME_UPDATE_START:
    "GENERAL_TASKS_WORKING_TIMES/UPDATE_START",
  GENERAL_TASK_WORKING_TIME_UPDATE: "GENERAL_TASKS_WORKING_TIMES/UPDATE",
  GENERAL_TASK_WORKING_TIME_UPDATE_FAIL:
    "GENERAL_TASKS_WORKING_TIMES/UPDATE_FAIL",

  GENERAL_TASK_WORKING_TIME_DELETE_START:
    "GENERAL_TASKS_WORKING_TIMES/DELETE_START",
  GENERAL_TASK_WORKING_TIME_DELETE: "GENERAL_TASKS_WORKING_TIMES/DELETE",
  GENERAL_TASK_WORKING_TIME_DELETE_FAIL:
    "GENERAL_TASKS_WORKING_TIMES/DELETE_FAIL",

  GENERAL_TASK_WORKING_TIME_ARCHIVE_START:
    "GENERAL_TASKS_WORKING_TIMES/ARCHIVE_START",
  GENERAL_TASK_WORKING_TIME_ARCHIVE: "GENERAL_TASKS_WORKING_TIMES/ARCHIVE",
  GENERAL_TASK_WORKING_TIME_ARCHIVE_FAIL:
    "GENERAL_TASKS_WORKING_TIMES/ARCHIVE_FAIL",

  CLEAR_ERROR: "GENERAL_TASKS_WORKING_TIMES/CLEAR_ERROR"
};
