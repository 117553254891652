export const actionTypes = {
  CLEAR_FORM_STATE: "CREATE_NEW_TASK/CLEAR_FORM_STATE",
  SET_FORM_STATE: "CREATE_NEW_TASK/SET_FORM_STATE",
  SET_TASK_TYPE: "CREATE_NEW_TASK/SET_TASK_TYPE",
  SET_FIELD_VALUE: "CREATE_NEW_TASK/SET_FIELD_VALUE",
  MOVE_TO_STEP: "CREATE_NEW_TASK/MOVE_TO_STEP",
  MOVE_TO_THE_NEXT_STEP: "CREATE_NEW_TASK/MOVE_TO_THE_NEXT_STEP",

  //TASK_TYPES
  FETCH_TASK_TYPES_START: "CREATE_NEW_TASK/FETCH_TASK_TYPES_START",
  FETCH_TASK_TYPES: "CREATE_NEW_TASK/FETCH_TASK_TYPES",
  FETCH_TASK_TYPES_ERROR: "CREATE_NEW_TASK/FETCH_TASK_TYPES_ERROR",

  //TASK_PRIORITIES
  FETCH_TASK_PRIORITIES_START: "CREATE_NEW_TASK/FETCH_TASK_PRIORITIES_START",
  FETCH_TASK_PRIORITIES: "CREATE_NEW_TASK/FETCH_TASK_PRIORITIES",
  FETCH_TASK_PRIORITIES_ERROR: "CREATE_NEW_TASK/FETCH_TASK_PRIORITIES_ERROR",

  //TASK_PAYMENT_METHODS
  FETCH_TASK_PAYMENT_METHODS_START:
    "CREATE_NEW_TASK/FETCH_TASK_PAYMENT_METHODS_START",
  FETCH_TASK_PAYMENT_METHODS: "CREATE_NEW_TASK/FETCH_TASK_PAYMENT_METHODS",
  FETCH_TASK_PAYMENT_METHODS_ERROR:
    "CREATE_NEW_TASK/FETCH_TASK_PAYMENT_METHODS_ERROR",

  //TASK_STATUSES
  FETCH_TASK_STATUSES_START: "CREATE_NEW_TASK/FETCH_TASK_STATUSES_START",
  FETCH_TASK_STATUSES: "CREATE_NEW_TASK/FETCH_TASK_STATUSES",
  FETCH_TASK_STATUSES_ERROR: "CREATE_NEW_TASK/FETCH_TASK_STATUSES_ERROR",

  //TASK_STATUSES
  FETCH_TASK_PICKING_TYPES_START:
    "CREATE_NEW_TASK/FETCH_TASK_PICKING_TYPES_START",
  FETCH_TASK_PICKING_TYPES: "CREATE_NEW_TASK/FETCH_TASK_PICKING_TYPES",
  FETCH_TASK_PICKING_TYPES_ERROR:
    "CREATE_NEW_TASK/FETCH_TASK_PICKING_TYPES_ERROR",

  //TASK_SUB_NAMES
  FETCH_SUB_TASK_NAMES_START: "CREATE_NEW_TASK/FETCH_SUB_TASK_NAMES_START",
  FETCH_SUB_TASK_NAMES: "CREATE_NEW_TASK/FETCH_SUB_TASK_NAMES",
  FETCH_SUB_TASK_NAMES_ERROR: "CREATE_NEW_TASK/FETCH_SUB_TASK_NAMES_ERROR",

  //ATTACHMENTS
  UPLOAD_GENERAL_TASKS_ATTACHMENTS_START:
    "CREATE_NEW_TASK/UPLOAD_GENERAL_TASKS_ATTACHMENTS_START",
  UPLOAD_GENERAL_TASKS_ATTACHMENTS:
    "CREATE_NEW_TASK/UPLOAD_GENERAL_TASKS_ATTACHMENTS",
  UPLOAD_GENERAL_TASKS_ATTACHMENTS_ERROR:
    "CREATE_NEW_TASK/UPLOAD_GENERAL_TASKS_ATTACHMENTS_ERROR",
  DELETE_ATTACHMENT: "CREATE_NEW_TASK/DELETE_ATTACHMENT",

  CLEAR_ERROR: "CREATE_NEW_TASK/CLEAR_ERROR"
};
