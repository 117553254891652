export const genders = [
  {
    key: 'MALE',
    text: 'Male',
    value: 'MALE',
  },
  {
    key: 'FEMALE',
    text: 'Female',
    value: 'FEMALE',
  },
];

export const clientTypes = [
  {
    key: 'OWNER',
    text: 'Owner',
    value: 'OWNER',
  },
];
