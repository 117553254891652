import React from "react";
import {
  Container,
  Form,
  Grid,
  Input,
  Segment,
  TextArea
} from "semantic-ui-react";
import TextareaAutosize from "react-textarea-autosize";
import DatePicker from "components/DatePicker";
import ErrorLabel from "components/ErrorLabel";
import { scoutingFormElementShape } from "constants/Scouting/types";
import { validationErrors } from "utils/validationRules";
import { toStartDateString } from "utils/dateUtils";
import EditScoutingLocationForm from "./EditScoutingLocationForm";
import styles from "./styles.module.css";

const EditScoutingForm = ({ formik }) => {
  const { values } = formik;

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Container fluid>
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} computer={4}>
              <Segment fluid>
                <Form.Field required className={styles.field}>
                  <label>Name:</label>
                  <Input
                    size="large"
                    placeholder="Name"
                    name={"name"}
                    value={values.name}
                    onChange={formik.handleChange}
                  />
                </Form.Field>
                <Form.Field required className={styles.field}>
                  <DatePicker
                    required
                    validationErrors={validationErrors()}
                    errorLabel={ErrorLabel}
                    value={values.setOn}
                    onChange={newValue => {
                      formik.setFieldValue(
                        "setOn",
                        toStartDateString(newValue)
                      );
                    }}
                    labelText="Created On"
                    name={"setOn"}
                  />
                </Form.Field>
                <Form.Field className={styles.field}>
                  <DatePicker
                    required
                    validationErrors={validationErrors()}
                    errorLabel={ErrorLabel}
                    value={values.doneOn}
                    onChange={newValue => {
                      formik.setFieldValue(
                        "doneOn",
                        toStartDateString(newValue)
                      );
                    }}
                    labelText="Done On"
                    name={"doneOn"}
                  />
                </Form.Field>
                <Form.Field required className="sprayField">
                  <label>General Comments:</label>
                  <TextArea
                    as={TextareaAutosize}
                    minRows={2}
                    size="large"
                    placeholder="Comment"
                    name={"comment"}
                    value={values.comment}
                    onChange={formik.handleChange}
                  />
                </Form.Field>
              </Segment>
            </Grid.Column>
            <Grid.Column mobile={16} computer={12}>
              <EditScoutingLocationForm formik={formik} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Form>
  );
};

EditScoutingForm.propTypes = scoutingFormElementShape;

export default EditScoutingForm;
