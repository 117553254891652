import { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import ObjectId from "bson-objectid";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getFarmSummary, setNewFarm } from "../../../actions/Farms/farms";
import { setCurrentBlock } from "../../../actions/Farms/addNewFarm";
import { initialState } from "../../../reducers/Farms";
import { getCropsList } from "../../../actions/Farms/crops";
import { Area } from "../../../models/block.model";
import { AreaVariety } from "../../../models/area_variety.model";

class EditFarm extends Component {
  constructor(props) {
    super(props);
    const { match } = props;

    this.state = {
      farmId: parseInt(match.params.id),
      blockId: parseInt(match.params.blockId) || null,
      addBlock: !!match.path.match(/\/add_block$/),
      loaded: false
    };
  }

  componentDidMount() {
    const { actions, summary, crops } = this.props;
    const { farmId } = this.state;
    const farm = (summary || []).find(farm => farm.farm.id === farmId);
    if (crops.data.length <= 0) {
      actions.getCropsList();
    }
    if (!farm) {
      actions.getFarmSummary(farmId);
    } else {
      this.loadNewFarm(farm);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { summary } = this.props;
    const { farmId, loaded } = this.state;
    const farm = (summary || []).find(farm => farm.farm.id === farmId);

    if (!loaded && farm) {
      this.loadNewFarm(farm);
    }
  }

  addNewBlock = () => {
    const blockId = ObjectId().toHexString();
    return new Area({
      id: blockId,
      name: "",
      type: "block",
      size: 0,
      polygons: [],
      patchMode: "BLOCKS_AND_PATCHES",
      patches: [],
      attributes: {
        isRotating: false,
        varieties: [new AreaVariety()]
      }
    });
  };

  loadNewFarm = farm => {
    const { actions } = this.props;
    const { blockId, addBlock } = this.state;
    this.setState({ loaded: true });

    let composedFarm = _.pick(
      { ...initialState.newFarm, ...farm.farm },
      Object.keys(initialState.newFarm)
    );
    if (_.isEmpty(farm.areas)) {
      return actions.setNewFarm({ newFarm: composedFarm });
    }
    const polygons = [];
    const mapBlock = data => {
      const id = _.get(data, "id");
      const blockPolygons = [];
      _.get(data, "geojson.features", []).forEach(geo => {
        const polygonId = ObjectId().toHexString();
        blockPolygons.push(polygonId);
        polygons.push({
          id: polygonId,
          coordinates: _.get(geo, "geometry.coordinates", null)
        });
      });

      return {
        id,
        _id: id,
        name: _.get(data, "name", ""),
        size: _.get(data, "size", 0),
        polygons: blockPolygons,
        patchMode: "NO_PATCHES",
        attributes: {
          isRotating: _.get(data, "isRotating", false),
          registrationNumber: _.get(data, "registrationNumber", ""),
          notes: _.get(data, "comments", ""),
          varieties: _.get(data, "varieties", [{}]).map(
            varietyData => new AreaVariety(varietyData)
          )
        },
        type: "block",
        patches: [],
        archived: _.get(data, "archived", false),
        hasSubAreas: _.get(data, "hasSubAreas", false)
      };
    };

    const mapPatch = (data = null) => {
      const { patches, patchMode, ...result } = mapBlock(data);
      return { ...result, type: "patch" };
    };

    const mapDeep = parentId => {
      const areas = farm.areas.reduce((next, area) => {
        if (_.get(area, "parent.id", null) === parentId) {
          return [
            ...next,
            area.type === "BLOCK" ? mapBlock(area) : mapPatch(area)
          ];
        } else {
          return next;
        }
      }, []);

      if (_.isEmpty(areas)) {
        return [];
      }

      return areas.map(area => {
        const subBlocks = mapDeep(area.id);
        if (area.type === "patch") {
          return { ...area, patches: subBlocks };
        }

        return {
          ...area,
          patchMode:
            _.isEmpty(subBlocks) && !area.hasSubAreas
              ? "NO_PATCHES"
              : "PATCHES",
          patches: subBlocks
        };
      });
    };

    const blocks = mapDeep(null);
    const newFarm = { ...composedFarm, blocks, polygonsToAdd: polygons };
    let currentBlock = _.get(newFarm, "blocks.0.id", null);
    let currentPatch = _.get(newFarm, "blocks.0.patches.0.id", null);

    if (addBlock) {
      const newBlock = this.addNewBlock();
      currentBlock = newBlock.id;
      currentPatch = null;
      newFarm.blocks = [...newFarm.blocks, newBlock];
    } else if (blockId) {
      const currentBlockIndex =
        blocks.findIndex(
          block => _.toString(block.id) === _.toString(blockId)
        ) || 0;
      currentBlock = _.get(newFarm, `blocks.${currentBlockIndex}.id`, null);
      currentPatch = _.get(
        newFarm,
        `blocks.${currentBlockIndex}.patches.0.id`,
        null
      );
    }
    actions.setNewFarm({
      newFarm: {
        ...newFarm,
        currentBlock,
        currentPatch
      }
    });
  };

  render() {
    const { history, newFarm, crops } = this.props;
    const { addBlock } = this.state;
    if (!newFarm.id || !crops.data.length) {
      return null;
    }

    let step = 1;
    if (this.state.blockId && newFarm.currentBlock) {
      step = 2;
    } else if (addBlock && newFarm.currentBlock) {
      step = 2;
    }

    const url = `/farms/add_new_farm/${step}`;
    history.push(url);

    return null;
  }
}

EditFarm.propTypes = {
  match: PropTypes.object,
  actions: PropTypes.object,
  history: PropTypes.object,
  crops: PropTypes.object,
  newFarm: PropTypes.object,
  summary: PropTypes.array
};

EditFarm.defaultProps = {};

function mapStateToProps({ farms: { summary, newFarm }, crops }) {
  return {
    summary,
    newFarm,
    crops
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setNewFarm,
        getFarmSummary,
        setCurrentBlock,
        getCropsList
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditFarm);
