export const actionType = {
  SIGN_IN_SILENT_START: "USER/SIGN_IN_SILENT_START",
  SIGN_IN_SILENT: "USER/SIGN_IN_SILENT",
  SIGN_IN_SILENT_FAIL: "USER/SIGN_IN_SILENT_FAIL",

  SIGN_IN_START: "USER/SIGN_IN_START",
  SIGN_IN: "USER/SIGN_IN",
  SIGN_IN_FAIL: "USER/SIGN_IN_FAIL",

  SIGN_OUT_START: "USER/SIGN_OUT_START",
  SIGN_OUT: "USER/SIGN_OUT",
  SIGN_OUT_FAIL: "USER/SIGN_OUT_FAIL",

  GET_REFRESH_TOKEN_START: "USER/GET_REFRESH_TOKEN_START",
  GET_REFRESH_TOKEN: "USER/GET_REFRESH_TOKEN",
  GET_REFRESH_TOKEN_FAIL: "USER/GET_REFRESH_TOKEN_FAIL",

  GET_PROFILE_PICTURE_START: "USER/GET_PROFILE_PICTURE_START",
  GET_PROFILE_PICTURE: "USER/GET_PROFILE_PICTURE",
  GET_PROFILE_PICTURE_FAIL: "USER/GET_PROFILE_PICTURE_FAIL",

  UPDATE_PROFILE_DATA: "USER/UPDATE_PROFILE_DATA"
};
