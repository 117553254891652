import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef
} from "react";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import TablePageHolder from "../../../components/TablePageHolder/TablePageHolder";
import {
  getOperations,
  getOperationsType,
  getOperationsFilters,
  getOperationsForPrint
} from "actions/InventoryMovements/index";
import { getPurchaseHistoryList } from "actions/PurchaseHistory";
import { fetchListSheds } from "actions/Sheds/index";
import { getInventoryMovementsTableColumns } from "utils/inventoryMovements";
import { keyBy } from "lodash";
import { Button, Icon } from "semantic-ui-react";
import PrintReports from "./PrintInventoryMovementsTable/PrintInventoryMovements";
import {
  chemicalsOptionsSelector,
  areasOptionsSelector,
  productTypeSelector,
  chemicalTypeOptionsSelector,
  activeIngredientsSelector
} from "selectors/chemicals";
import { useReactToPrint } from "react-to-print";
import { employeesOptionsSelector } from "selectors/employee";
import { shedListSelector } from "selectors/sheds";
import { shedShape } from "../../../constants/Sheds/types";
import styles from "./InventoryMovements.module.css";
import _ from "lodash";

const InventoryMovements = ({
  isFetching,
  location,
  route,
  tableData,
  purchaseList,
  chemicals,
  areas,
  printData
}) => {
  const [filters, setFilters] = useState({
    activeIngredient: [],
    from: "",
    to: "",
    chemicalIds: [],
    matter: "",
    activityType: [],
    blockIds: [],
    patchIds: [],
    shedIds: [],
    seasons: [],
    chemicalTypeIds: []
  });
  const componentRef = useRef();
  const [excel, setExcel] = useState();
  const [open, setOpen] = useState(false);
  const [selectItem, setSelectItem] = useState();
  const [filtersList, setFiltersList] = useState([]);
  const [printExpandedData, setPrintExpandedData] = useState(false);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  const chemicalsList = useSelector(chemicalsOptionsSelector);
  const chemicalsTypeList = useSelector(chemicalTypeOptionsSelector);
  const activeIngredientList = useSelector(activeIngredientsSelector);
  const areasList = useSelector(areasOptionsSelector);
  const productList = useSelector(productTypeSelector);
  const employeesList = useSelector(employeesOptionsSelector);
  const shedList = useSelector(shedListSelector);
  const [selectedItemsIds, setSelectedItems] = useState([]);
  const dispatch = useDispatch();
  const fetchPrintoutAllInformation = () => {
    return dispatch(getOperationsForPrint(filtersList));
  };

  const tableColumns = getInventoryMovementsTableColumns(
    chemicalsList,
    shedList,
    employeesList,
    areas,
    productList,
    purchaseList,
    filters,
    isFetching
  );

  const printButton = selectedItem => {
    setSelectItem(selectedItem);
    fetchPrintoutAllInformation().then(() => {
      setPrintExpandedData(true);
      handlePrint();
    });
  };

  const getData = useCallback(params => {
    setFiltersList(params);
    const checkedChanged = _.fromPairs(params.filters);
    dispatch(getOperations({ ...params }, checkedChanged));
    dispatch(getOperationsFilters({ ...params }));
    dispatch(getOperationsType());
    dispatch(getPurchaseHistoryList());
  }, []);
  const filtersOptions = [{}];
  const selectedProducts = useMemo(() => {
    const idsMap = keyBy(selectedItemsIds);

    return tableData.content.filter((_, index) => !!idsMap[index]);
  }, [selectedItemsIds]);

  const breadcrumbSections = [
    { content: "Shed and fertiliser", active: true },
    { content: route.name, active: false }
  ];
  useEffect(() => {
    dispatch(fetchListSheds());
  }, []);
  return (
    <>
      <div style={{ display: "none" }}>
        <PrintReports
          title="Harvest by area"
          showExpandedData={printExpandedData}
          chemicalsList={chemicalsList}
          shedList={shedList}
          employeesList={employeesList}
          areasList={areas}
          purchaseList={purchaseList}
          chemicalsTypeList={productList}
          chemicals={chemicals}
          activeIngredientList={activeIngredientList}
          chemicalIds={filters.chemicalIds}
          matter={filters.matter}
          activityType={filters.activityType}
          blockIds={filters.blockIds}
          patchIds={filters.patchIds}
          shedIds={filters.shedIds}
          chemicalTypeIds={filters.chemicalTypeIds}
          selectedItem={selectItem}
          data={printData}
          from={filters.from}
          to={filters.to}
          ref={componentRef}
        />
      </div>
      <TablePageHolder
        breadcrumbSections={breadcrumbSections}
        open={open}
        setOpen={setOpen}
        withoutSort={true}
        onRowClick={() => {}}
        getData={getData}
        isFetching={isFetching}
        printButton={printButton}
        location={location}
        setFilters={setFilters}
        pageTitle={route.name}
        route={route}
        selectedItems={selectedItemsIds}
        setSelectedItems={setSelectedItems}
        tableColumns={tableColumns}
        tableData={tableData}
        withSelection={false}
        actionsButtons={
          <div className={styles.buttonWrapper}>
            {/* <Button
              size="small"
              floated="right"
              className={styles.printButton}
              onClick={() => {
                // return setExcel(true), setOpen(true);
              }}
            >
              <div className={styles.iconWrapper}>
                <Icon name="download" />
                Export
              </div>
            </Button> */}
            <Button
              size="small"
              floated="right"
              className={styles.printButton}
              onClick={() => {
                printButton();
              }}
            >
              <div className={styles.iconWrapper}>
                <Icon name="print" />
                Print
              </div>
            </Button>
          </div>
        }
      />
    </>
  );
};

InventoryMovements.propTypes = {
  tableData: PropTypes.shape({
    content: PropTypes.arrayOf(shedShape)
  }).isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  totalElements: PropTypes.number.isRequired,
  getOperations: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  route: PropTypes.object,
  filterValues: PropTypes.object,
  location: PropTypes.object
};
export default connect(state => {
  return {
    chemicals: state.chemical.list.content,
    purchaseList: state.purchaseHistory.list.content,
    tableData: state.inventoryMovements.data,
    areas: state.areas.list,
    printData: state.inventoryMovements.print,
    filterValues: state.inventoryMovements.filters,
    isFetching: state.inventoryMovements.data.isFetching,
    error: state.inventoryMovements.error
  };
})(InventoryMovements);
