import React from "react";

const Logo = () => (
  <svg
    width="100"
    height="42"
    viewBox="0 0 58 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.945 13.7822L4.51819 7.35536L4.90773 6.96582L11.3348 13.3926L10.945 13.7822Z"
      fill="#101010"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0387 14.6887L3.61194 8.26162L4.00148 7.87186L10.4285 14.2989L10.0387 14.6887Z"
      fill="#101010"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.13244 15.5947L2.70541 9.16789L3.09518 8.77835L9.52221 15.2052L9.13244 15.5947Z"
      fill="#101010"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.22616 16.5012L1.79913 10.0744L2.18867 9.68463L8.6157 16.1117L8.22616 16.5012Z"
      fill="#101010"
    />
    <mask
      id="mask0"
      maskUnits="userSpaceOnUse"
      x="0"
      y="10"
      width="8"
      height="9"
    >
      <path d="M0 10.5911H7.70921V18.314H0V10.5911Z" fill="#101010" />
    </mask>
    <g mask="url(#mask0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.31947 17.4077L0.89267 10.9807L1.28243 10.5911L7.70924 17.0179L7.31947 17.4077Z"
        fill="#101010"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.41319 18.314L-0.0136108 11.8872L0.375927 11.4974L6.80296 17.9244L6.41319 18.314Z"
        fill="#101010"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.43545 18.9352L6.04568 18.5454L12.4727 12.1188L12.8623 12.5084L6.43545 18.9352Z"
      fill="#101010"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.34173 19.8415L6.95197 19.4521L13.379 13.0251L13.7688 13.4147L7.34173 19.8415Z"
      fill="#101010"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.24797 20.748L7.85843 20.3582L14.2852 13.9314L14.675 14.3212L8.24797 20.748Z"
      fill="#101010"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.15446 21.6542L8.76492 21.2647L15.1915 14.8376L15.5813 15.2272L9.15446 21.6542Z"
      fill="#101010"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.061 22.5605L9.6712 22.1707L16.0982 15.7439L16.4878 16.1337L10.061 22.5605Z"
      fill="#101010"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9672 23.467L10.5775 23.0774L17.0045 16.6504L17.3941 17.0399L10.9672 23.467Z"
      fill="#101010"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8735 24.3732L11.4837 23.9837L17.9108 17.5567L18.3003 17.9464L11.8735 24.3732Z"
      fill="#101010"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.532 18.314L12.1052 11.8872L12.4945 11.4974L18.9215 17.9244L18.532 18.314Z"
      fill="#101010"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.4383 17.4077L13.0115 10.9807L13.401 10.5911L19.828 17.0179L19.4383 17.4077Z"
      fill="#101010"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.3448 16.5012L13.9177 10.0744L14.3075 9.68463L20.7343 16.1117L20.3448 16.5012Z"
      fill="#101010"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.2511 15.5947L14.824 9.16789L15.2138 8.77835L21.6406 15.2052L21.2511 15.5947Z"
      fill="#101010"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.1573 14.6887L15.7307 8.26162L16.1201 7.87186L22.5469 14.2989L22.1573 14.6887Z"
      fill="#101010"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.0638 13.7822L16.637 7.35536L17.0266 6.96582L23.4536 13.3926L23.0638 13.7822Z"
      fill="#101010"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.9701 12.8761L17.5433 6.44909L17.9328 6.05933L24.3598 12.4864L23.9701 12.8761Z"
      fill="#101010"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8735 12.2547L11.4837 11.8649L17.9108 5.43787L18.3003 5.82763L11.8735 12.2547Z"
      fill="#101010"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9672 11.3484L10.5775 10.9586L17.0045 4.53183L17.3941 4.92137L10.9672 11.3484Z"
      fill="#101010"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.061 10.4419L9.6712 10.0521L16.0982 3.62534L16.4878 4.0151L10.061 10.4419Z"
      fill="#101010"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.15446 9.53562L8.76492 9.14609L15.1915 2.71906L15.5813 3.10859L9.15446 9.53562Z"
      fill="#101010"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.24797 8.62913L7.85843 8.23937L14.2852 1.81256L14.675 2.20233L8.24797 8.62913Z"
      fill="#101010"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.34173 7.72285L6.95197 7.33331L13.379 0.906281L13.7688 1.29582L7.34173 7.72285Z"
      fill="#101010"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.43545 6.81657L6.04568 6.4268L12.4727 0L12.8623 0.389765L6.43545 6.81657Z"
      fill="#101010"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.0572 5.03653H27.0051V10.2443H25.6983V5.03653H23.6559V3.85565H29.0572V5.03653Z"
      fill="#101010"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.8318 10.2443H31.1387V5.45263H29.8318V10.2443ZM31.1385 4.3324C31.1385 4.70013 30.8439 4.98223 30.4814 4.98223C30.1186 4.98223 29.8318 4.70013 29.8318 4.3324C29.8318 3.95694 30.1186 3.67529 30.4814 3.67529C30.8439 3.67529 31.1385 3.95694 31.1385 4.3324Z"
      fill="#101010"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.2204 7.3692H35.4077C35.398 6.75956 34.9242 6.40137 34.3722 6.40137C33.8205 6.40137 33.3751 6.7691 33.2204 7.3692ZM36.5888 8.28864H33.2204C33.3846 8.89873 33.8882 9.23739 34.5753 9.23739C35.0982 9.23739 35.5238 9.05341 35.9113 8.75336L36.4532 9.64396C35.9113 10.0989 35.2724 10.3601 34.5364 10.3601C33.0169 10.3601 31.9135 9.26646 31.9135 7.85322C31.9135 6.43999 33.0074 5.3461 34.4206 5.3461C35.7659 5.3461 36.6758 6.33346 36.6758 7.57248C36.6758 7.84368 36.6275 8.12442 36.5888 8.28864Z"
      fill="#101010"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.8907 7.6018C42.8907 9.31508 41.7291 10.3601 40.1803 10.3601C38.6222 10.3601 37.4701 9.31508 37.4701 7.6018V3.85565H38.7768V7.69833C38.7768 8.65662 39.3867 9.15018 40.1803 9.15018C40.9839 9.15018 41.584 8.65662 41.584 7.69833V3.85565H42.8907V7.6018Z"
      fill="#101010"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.4028 7.85322C47.4028 7.04008 46.8511 6.52698 46.1638 6.52698C45.5637 6.52698 44.9538 6.92378 44.9538 7.84368C44.9538 8.75336 45.5539 9.16947 46.1638 9.16947C46.8415 9.16947 47.4028 8.66614 47.4028 7.85322ZM48.729 7.84368C48.729 9.28577 47.761 10.3601 46.4057 10.3601C45.8153 10.3601 45.312 10.1473 44.9733 9.77956V11.9864H43.6666V5.45263H44.8668V6.05272C45.1957 5.59799 45.7381 5.3461 46.4057 5.3461C47.761 5.3461 48.729 6.40137 48.729 7.84368Z"
      fill="#101010"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.8615 15.4007H25.9673V16.9979H28.3386V18.1786H25.9673V20.6085H24.6606V14.2198H28.8615V15.4007Z"
      fill="#101010"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.1817 18.8177V18.7596H31.1557C30.7299 18.7596 30.4877 18.9238 30.4877 19.2436C30.4877 19.5727 30.7685 19.766 31.1753 19.766C31.7268 19.766 32.1817 19.3788 32.1817 18.8177ZM33.4884 17.7625V20.6085H32.2882V20.1438C31.9493 20.5017 31.446 20.7243 30.8264 20.7243C29.7811 20.7243 29.1715 20.1049 29.1715 19.2918C29.1715 18.4207 29.8781 17.8981 30.9813 17.8981H32.1817V17.6848C32.1817 17.1624 31.8817 16.843 31.2911 16.843C30.7785 16.843 30.42 17.0851 30.023 17.5013L29.3457 16.6977C29.9069 16.0299 30.5943 15.7103 31.4365 15.7103C32.656 15.7103 33.4884 16.3976 33.4884 17.7625Z"
      fill="#101010"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.1871 15.8168V16.9689H36.8387C36.0839 16.9689 35.5515 17.3559 35.5515 18.2174V20.6085H34.2446V15.8168H35.445V16.3976C35.7646 15.9815 36.2388 15.7589 36.7615 15.7589C36.9357 15.7589 37.0615 15.7782 37.1871 15.8168Z"
      fill="#101010"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.1469 17.6366V20.6085H43.8404V17.8204C43.8404 17.2494 43.5015 16.8912 42.9884 16.8912C42.4657 16.8912 42.1076 17.2591 42.1076 17.7915V20.6085H40.8011V17.8204C40.8011 17.2494 40.4619 16.8912 39.9493 16.8912C39.4264 16.8912 39.0685 17.2494 39.0685 17.8204V20.6085H37.7615V15.8168H38.9617V16.2429C39.2813 15.9136 39.7458 15.7103 40.317 15.7103C40.9653 15.7103 41.4493 15.9622 41.7398 16.3976C42.098 15.9815 42.669 15.7103 43.337 15.7103C44.4793 15.7103 45.1469 16.4848 45.1469 17.6366Z"
      fill="#101010"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.9273 20.6085H47.2342V15.8168H45.9273V20.6085ZM47.234 14.4054C47.234 14.7732 46.9394 15.0548 46.5767 15.0548C46.2144 15.0548 45.9273 14.7732 45.9273 14.4054C45.9273 14.03 46.2144 13.7479 46.5767 13.7479C46.9394 13.7479 47.234 14.03 47.234 14.4054Z"
      fill="#101010"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52.4329 17.6366V20.6085H51.1362V17.8108C51.1362 17.2591 50.778 16.8816 50.2456 16.8816C49.7132 16.8816 49.3357 17.2591 49.3357 17.8108V20.6085H48.0287V15.8168H49.2292V16.2525C49.5583 15.9234 50.0423 15.7103 50.6326 15.7103C51.7556 15.7103 52.4329 16.4848 52.4329 17.6366Z"
      fill="#101010"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.7126 18.0623C56.7126 17.1817 56.103 16.8235 55.5029 16.8235C54.8253 16.8235 54.2639 17.2784 54.2639 18.0723C54.2639 18.8661 54.8349 19.3595 55.5029 19.3595C56.1127 19.3595 56.7126 18.9433 56.7126 18.0623ZM58 15.8168V20.0761C58 21.6924 56.9643 22.4667 55.5222 22.4667C54.4767 22.4667 53.7119 22.0603 53.1702 21.5763L53.8573 20.6662C54.3316 21.0339 54.7963 21.2858 55.4157 21.2858C56.1514 21.2858 56.6933 20.9278 56.6933 20.1533V19.8919C56.3546 20.2596 55.8318 20.4631 55.2317 20.4631C53.9154 20.4631 52.9376 19.5048 52.9376 18.0818C52.9376 16.6397 53.925 15.7103 55.2317 15.7103C55.9285 15.7103 56.4707 15.9622 56.7998 16.4169V15.8168H58Z"
      fill="#101010"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1729 12.4184L5.81398 6.05933L5.42444 6.44909L11.7836 12.808L12.1729 12.4184Z"
      fill="#101010"
    />
  </svg>
);

export default Logo;
