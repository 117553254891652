import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Dropdown } from "semantic-ui-react";
import styles from "../Filters.module.css";
import {
  getHashParameter,
  setHashParameter
} from "../../../utils/hashToObject";
import { connect } from "react-redux";
import DatePicker from "../../DatePicker";
import moment from "moment";

const DateRangeFilter = ({
  id,
  name,
  disabled,
  location,
  isFetching,
  disableChecker,
  onFilterClick = () => {}
}) => {
  const values = useMemo(() => {
    const param = getHashParameter(location, id);
    if (!param || param === "*") {
      return [];
    } else {
      return param.split(",");
    }
  }, [id, location]);

  const from = useMemo(() => values[0], [values]);
  const to = useMemo(() => values[1], [values]);

  const text = useMemo(
    () =>
      `${name}: ${from ? moment(from).format("DD/MM/YYYY") : "Any time"}${
        from || to
          ? ` - ${to ? moment(to).format("DD/MM/YYYY") : "Any time"}`
          : ""
      }`,
    [name, from, to]
  );
  const nameCheck = text.slice(0, 4);
  return (
    <Dropdown
      filter={id}
      name={name}
      labeled
      button
      floating
      text={text}
      closeOnChange={false}
      icon={null}
      disabled={
        disabled ||
        (disableChecker === "seasons" &&
          (nameCheck === "Date" || text.includes("Expiry Date")))
      }
    >
      <Dropdown.Menu
        onClick={event => event.stopPropagation()}
        className={`dropdownMenu ${styles.dropdownMenu} ${styles.dateRangeHolder}`}
      >
        <div className={styles.filterHolder}>
          <DatePicker
            size="small"
            value={from}
            onChange={date => {
              return (
                onFilterClick(true), setHashParameter(location, id, [date, to])
              );
            }}
            labelText={"From"}
            maxDate={to}
            className={styles.datePicker}
            disabled={isFetching}
            loading={isFetching}
          />
          <DatePicker
            size="small"
            value={to}
            minDate={from}
            onChange={date => {
              return (
                onFilterClick(true),
                setHashParameter(location, id, [from, date])
              );
            }}
            labelText={"To"}
            className={styles.datePicker}
            disabled={isFetching}
            loading={isFetching}
          />
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

DateRangeFilter.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  disabled: PropTypes.bool,
  location: PropTypes.object
};

function mapStateToProps(state) {
  const {
    applicationsRecordByBlock: { isFetching },
    router: { location }
  } = state;
  return {
    location,
    isFetching
  };
}

export default connect(mapStateToProps)(DateRangeFilter);
