import React, { useState } from "react";
import { useFormikContext } from "formik";
import { useDispatch } from "react-redux";
import { Button } from "semantic-ui-react";
import { Container, Grid, Segment } from "semantic-ui-react";
import FarmForm from "./FarmForm";
import FarmRow from "./FarmRow";
import { partialUpdate } from "actions/Sheds";
import styles from "./Farms.module.css";

const Farms = ({ shed }) => {
  const dispatch = useDispatch();
  const { values, setFieldValue, validateForm } = useFormikContext();
  const [addButtonCheck, setAddButtonCheck] = useState(true);
  const [deleteButtonCheck, setDeleteButtonCheck] = useState(true);
  const farmsListCheck = shed.farms?.length;

  const addProduct = () => {
    setAddButtonCheck(false);
    setDeleteButtonCheck(true);
  };
  const deleteAddingForm = () => {
    setAddButtonCheck(true);
  };

  const handleFarmAdd = farmValues => {
    const path = "farms";
    const farms = [...values.farms, farmValues];
    setFieldValue("farms", farms);
    dispatch(partialUpdate(shed.id, farms, path));
    validateForm();
    setAddButtonCheck(true);
  };
  return (
    <Segment className={styles.sideBarFarm}>
      <Grid className={styles.root} columns="equal" as={Container}>
        <Grid.Row className={styles.labelContainer}>
          <Grid.Column className={styles.farmHeader}>Farm</Grid.Column>
          <Grid.Column />
        </Grid.Row>
        {shed.farms.map(farm => (
          <FarmRow
            shed={shed}
            shedId={shed.id}
            farm={farm}
            farmData={farm}
            key={farm.id}
          />
        ))}
      </Grid>
      {addButtonCheck && farmsListCheck ? (
        <div className={styles.buttonContainer}>
          <Button
            type="submit"
            className="button-text color-green"
            onClick={addProduct}
          >
            + Add farm
          </Button>
        </div>
      ) : null}
      {(!addButtonCheck || !farmsListCheck) && (
        <Segment className={styles.SideBarFarmform}>
          <FarmForm
            farmsListCheck={farmsListCheck}
            deleteAddingForm={deleteAddingForm}
            deleteButtonCheck={deleteButtonCheck}
            onSubmit={handleFarmAdd}
          />
        </Segment>
      )}
    </Segment>
  );
};

export default Farms;
