import SingleSelect from "./components/Filters/SingleSelect";
import MultiSelect from "./components/Filters/MultiSelect";
import DateRangeSelect from "./components/Filters/DateRangeSelect";

const filterTypes = {
  SingleSelect,
  MultiSelect,
  DateRangeSelect
};

export default filterTypes;
