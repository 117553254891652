export const actionType = {
  GET_SPRAY_TASKS_START: "SPRAY/GET_SPRAY_TASKS_START",
  GET_SPRAY_TASKS: "SPRAY/GET_SPRAY_TASKS",
  GET_SPRAY_TASKS_FAIL: "SPRAY/GET_SPRAY_TASKS_FAIL",
  SET_ACTIVE_TASK: "SPRAY/SET_ACTIVE_TASK",

  SET_TASK_BLOCK_DONE_START: "SPRAY/SET_TASK_BLOCK_DONE_START",
  SET_TASK_BLOCK_DONE: "SPRAY/SET_TASK_BLOCK_DONE",
  SET_TASK_BLOCK_DONE_FAIL: "SPRAY/SET_TASK_BLOCK_DONE_FAIL",

  DELETE_SPRAY_TASK_START: "SPRAY/DELETE_SPRAY_TASK_START",
  DELETE_SPRAY_TASK: "SPRAY/DELETE_SPRAY_TASK",
  DELETE_SPRAY_TASK_FAIL: "SPRAY/DELETE_SPRAY_TASK_FAIL",

  UPDATE_TASK_ITEM: "SPRAY/UPDATE_TASK_ITEM",

  GET_TASKS_IDS_START: "SPRAY/GET_TASKS_IDS_START",
  GET_TASKS_IDS: "SPRAY/GET_TASKS_IDS",
  GET_TASKS_IDS_FAIL: "SPRAY/GET_TASKS_IDS_FAIL",

  GET_CROPS_START: "SPRAY/GET_CROPS_START",
  GET_CROPS: "SPRAY/GET_CROPS",
  GET_CROPS_FAIL: "SPRAY/GET_CROPS_FAIL",

  GET_VARIETIES_START: "SPRAY/GET_VARIETIES_START",
  GET_VARIETIES: "SPRAY/GET_VARIETIES",
  GET_VARIETIES_FAIL: "SPRAY/GET_VARIETIES_FAIL",

  GET_BATCH_NUMBERS_START: "SPRAY/GET_BATCH_NUMBERS_START",
  GET_BATCH_NUMBERS: "SPRAY/GET_BATCH_NUMBERS",
  GET_BATCH_NUMBERS_FAIL: "SPRAY/GET_BATCH_NUMBERS_FAIL",

  SET_PROPERTY_VALUE: "SPRAY/SET_PROPERTY_VALUE",

  SET_ACTIVE_FILTERS: "SPRAY/SET_ACTIVE_FILTERS",

  SET_URL_STRING: "SPRAY/SET_URL_STRING",

  GET_SPRAY_TASKS_LIST_FOR_RECORDS_START:
    "SPRAY/GET_SPRAY_TASKS_LIST_FOR_RECORDS_START",
  GET_SPRAY_TASKS_LIST_FOR_RECORDS: "SPRAY/GET_SPRAY_TASKS_LIST_FOR_RECORDS",
  GET_SPRAY_TASKS_LIST_FOR_RECORDS_FAIL:
    "SPRAY/GET_SPRAY_TASKS_LIST_FOR_RECORDS_FAIL",
  CLEAR_SPRAY_TASKS_LIST_FOR_RECORDS:
    "SPRAY/GET_SPRAY_TASKS_LIST_FOR_RECORDS_FAIL",

  CLEAR_TASKS_ERROR: "SPRAY/CLEAR_TASKS_ERROR",
  CLEAR_ERROR: "SPRAY/CLEAR_ERROR"
};
