import React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Header,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow
} from "semantic-ui-react";
import moment from "moment";
import { sum } from "lodash";
import "../SprayTasksCostsBreakdown/index.css";
import Logo from "../Logo";
import { minutesToHM } from "../../utils/timeUtils";
import Numeric from "../Numeric";
import { cropText } from "../../utils/string";

const PrintReport = ({
  title,
  data,
  areaName,
  startDate,
  endDate,
  showTimePeriod,
  size
}) => {
  return (
    <Grid>
      <Grid.Column>
        <Grid.Row className={"print-page-header-row"}>
          <Header as="h1">Tie Up Farming</Header>
          <Logo />
        </Grid.Row>
        <Grid.Column>
          {areaName && <div>Location: {areaName}</div>}
          {showTimePeriod && (
            <div>
              Date: {startDate ? moment(startDate).format("DD/MM/YY") : "..."}-
              {endDate ? moment(endDate).format("DD/MM/YY") : "..."}
              <br />
              <br />
              <br />
            </div>
          )}
        </Grid.Column>
        <Grid.Row>
          <Header as={"h2"}>{title}</Header>
          <Table style={{ fontSize: "12px" }} unstackable>
            <TableHeader>
              <TableRow>
                <TableHeaderCell>Task ID</TableHeaderCell>
                <TableHeaderCell>Task Name</TableHeaderCell>
                <TableHeaderCell>Task Type</TableHeaderCell>
                <TableHeaderCell>SubTask</TableHeaderCell>
                <TableHeaderCell>Working Time</TableHeaderCell>
                <TableHeaderCell>Machinery Cost</TableHeaderCell>
                <TableHeaderCell>Labour Cost</TableHeaderCell>
                <TableHeaderCell>Cost ($)/Ha/Task</TableHeaderCell>
                <TableHeaderCell>Total Costs ($)</TableHeaderCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {data.map((task, i) => (
                <TableRow key={`expanded_table_${i}`}>
                  <TableCell>GT-{task.generalTaskId}</TableCell>
                  <TableCell>{task.generalTaskName}</TableCell>
                  <TableCell>{task.generalTaskType}</TableCell>
                  <TableCell>
                    {cropText(task.generalTaskSubtaskName, 50) || "-"}
                  </TableCell>
                  <TableCell>
                    {minutesToHM(task.totalWorkingTimesMins)}
                  </TableCell>
                  <TableCell>
                    <Numeric
                      value={task.machineryCost ? task.machineryCost : 0}
                      fractionDigits={2}
                      commaSeparatorOnThousands
                      units="$ "
                      unitsPosition="left"
                    />
                  </TableCell>
                  <TableCell>
                    <Numeric
                      value={task.labourCost ? task.labourCost : 0}
                      fractionDigits={2}
                      commaSeparatorOnThousands
                      units="$ "
                      unitsPosition="left"
                    />
                  </TableCell>
                  <TableCell>
                    <Numeric
                      value={
                        (task.machineryCost ? task.machineryCost : 0) +
                        (task.labourCost ? task.labourCost : 0) /
                          task.totalAreasSize
                      }
                      fractionDigits={2}
                      commaSeparatorOnThousands
                      units="$ "
                      unitsPosition="left"
                    />
                    /Ha
                  </TableCell>
                  <TableCell>
                    <Numeric
                      value={
                        (task.machineryCost ? task.machineryCost : 0) +
                        (task.labourCost ? task.labourCost : 0)
                      }
                      fractionDigits={2}
                      commaSeparatorOnThousands
                      units="$ "
                      unitsPosition="left"
                    />
                  </TableCell>
                </TableRow>
              ))}

              {/*Total Cost row*/}

              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell style={{ fontWeight: "bold" }}> Total</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  {minutesToHM(
                    sum(data.map(task => task.totalWorkingTimesMins))
                  )}
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  <Numeric
                    value={sum(
                      data.map(task =>
                        task.machineryCost ? task.machineryCost : 0
                      )
                    )}
                    fractionDigits={2}
                    commaSeparatorOnThousands
                    units="$ "
                    unitsPosition="left"
                  />
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  <Numeric
                    value={sum(
                      data.map(task => (task.labourCost ? task.labourCost : 0))
                    )}
                    fractionDigits={2}
                    commaSeparatorOnThousands
                    units="$ "
                    unitsPosition="left"
                  />
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  <Numeric
                    value={
                      sum(
                        data.map(
                          task =>
                            (task.machineryCost ? task.machineryCost : 0) +
                            (task.labourCost ? task.labourCost : 0)
                        )
                      ) / size
                    }
                    fractionDigits={2}
                    commaSeparatorOnThousands
                    units="$ "
                    unitsPosition="left"
                  />
                  /Ha
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  <Numeric
                    value={sum(
                      data.map(
                        task =>
                          (task.machineryCost ? task.machineryCost : 0) +
                          (task.labourCost ? task.labourCost : 0)
                      )
                    )}
                    fractionDigits={2}
                    commaSeparatorOnThousands
                    units="$ "
                    unitsPosition="left"
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid.Row>
      </Grid.Column>
    </Grid>
  );
};

PrintReport.propTypes = {
  areaName: PropTypes.string,
  columnsConfig: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  endDate: PropTypes.any,
  showTimePeriod: PropTypes.bool,
  startDate: PropTypes.any,
  title: PropTypes.string.isRequired,
  size: PropTypes.number
};

PrintReport.defaultProps = {
  showTimePeriod: true
};

export default PrintReport;
