import { PER_100_LITERS, PER_HECTARE } from "../constants/Farms";
import { get, groupBy, sortBy } from "lodash";

export const matterToUnits = matter =>
  matter && matter === "SOLID" ? "Kg" : matter ? "L" : "";
export const rateToUnits = rateType =>
  rateType && rateType === "PER_100_LITERS"
    ? "Per 100 L"
    : rateType
    ? "Per Ha"
    : "";

export const getRateWithUnits = (rate, rateType, unit, multiplier) => {
  const perUnit =
    rateType === PER_HECTARE
      ? "Ha"
      : rateType === PER_100_LITERS
      ? "100 L"
      : "";
  const formatter = new Intl.NumberFormat("en", {
    style: "unit",
    unit
  });

  return `${formatter.format(rate * multiplier)}/${perUnit}`;
};

export const unitsToOptions = unitsList => {
  const sortedList = sortBy(unitsList, ["multiplicationFactor"]);
  const formattedList = sortedList.map(({ id: unit, ...rest }) => {
    const formatter = new Intl.NumberFormat("en", {
      style: "unit",
      unit
    });
    const formattedParts = formatter.formatToParts(0);
    const unitLabel = formattedParts.find(({ type }) => type === "unit");

    return { value: unit, label: get(unitLabel, "value"), ...rest };
  });

  return groupBy(formattedList, ({ matter }) => matter);
};
