import { createSelector } from "reselect";
import moment from "moment";
import { calcTotalHectare, getTaskPlannedOrEndDate } from "../utils/tasksUtils";
import { memoize, get, keyBy, uniq } from "lodash";
import { Area } from "models/block.model";
import { calculateChemicalQuantity } from "utils/chemicalBreakdownUtils";

const windStrengthList = {
  WS_LESS_THEN_2: "Less then 2 km/h",
  WS_2_TO_5: "2-5 km/h",
  WS_5_TO_12: "5-12 km/h",
  WS_12_TO_20: "12-20 km/h",
  WS_20_TO_30: "20-30 km/h",
  WS_39_TO_49: "30-49 km/h"
};

const applicationsRecordByBlock = state =>
  state.applicationsRecordByBlock.data.content;
const applicationsRecordListByBlock = state =>
  state.applicationsRecordByBlock.list.content;

const prepareApplicationRecordData = (data, filters) => {
  const printReportData = {};

  const multiplier = item => {
    const foundElement = [
      { id: "gram", matter: "SOLID", multiplicationFactor: 0.001 },
      { id: "kilogram", matter: "SOLID", multiplicationFactor: 1 },
      { id: "liter", matter: "LIQUID", multiplicationFactor: 1 },
      { id: "milliliter", matter: "LIQUID", multiplicationFactor: 0.001 }
    ].find(({ id }) => id === item);

    if (foundElement) {
      return 1 / foundElement.multiplicationFactor;
    }
    return 1;
  };
  const tasksList = data.map(
    ({
      id,
      areas,
      chemicals,
      applicationType,
      conc,
      workingTimes,
      status,
      supervisors,
      assignees,
      machinery,
      machinerySize,
      temperature,
      deltaT,
      windStrength,
      windDirection,
      completedAt,
      expirationDate,
      plannedDate,
      literPerHectare,
      shedId,
      comment,
      rowsToSpray,
      rowWidth
    }) => {
      const farmsList = [];
      const farmSize = [];
      const cropsList = [];
      const areasList = [];
      const productTypeList = [];
      const varietiesList = [];
      const chemicalsFertilisers = [];
      const activeIngredients = [];
      const purposes = [];
      let batchNumbers = [];
      const actualRateApplied = [];
      const withholdingPeriods = [];
      const workingTimesList = [];
      const supervisorsList = [];
      const assigneesList = [];
      let mostWithholdingPeriod = 0;
      const selectedAreas = areas.map(area => ({
        ...area.area,
        ...area
      }));
      const calculateChemicalTotal = (
        item,
        selectedAreas,
        rowsToSpray,
        widthPerRow,
        literPerHectare,
        conc
      ) => {
        const totalHectares = calcTotalHectare(selectedAreas);
        return calculateChemicalQuantity(
          item,
          totalHectares,
          rowsToSpray,
          widthPerRow,
          literPerHectare,
          conc
        );
      };
      const selectedChemicals = chemicals.map(chemical => ({
        ...chemical,
        ...chemical.chemical,
        rate: {
          ...chemical.chemicalRate,
          rate: chemical.dilutionRate
        }
      }));
      const totalChemicals = selectedChemicals.map(chemical => {
        return {
          units: chemical.rate.unit,
          quantity: selectedAreas.reduce(
            (prev, { size, actualTanks, tanks, plannedTanks }) => {
              const { quantity: predictedQuantity } = calculateChemicalQuantity(
                chemical,
                size,
                rowsToSpray,
                rowWidth,
                literPerHectare,
                conc
              );
              let quantity = predictedQuantity;
              if (
                actualTanks !== null &&
                actualTanks !== undefined &&
                actualTanks !== (tanks || plannedTanks)
              ) {
                quantity =
                  (predictedQuantity * actualTanks) / (tanks || plannedTanks);
              }
              return prev + quantity;
            },
            0
          )
        };
      });
      const calculateActualRate = (
        rate,
        totalChemical,
        selectedChemical,
        selectedAreas,
        rowsToSpray,
        widthPerRow,
        literPerHectare,
        conc
      ) => {
        return (
          (rate * (totalChemical.quantity * multiplier(totalChemical.units))) /
          (calculateChemicalTotal(
            selectedChemical,
            selectedAreas,
            rowsToSpray,
            widthPerRow,
            literPerHectare,
            conc
          ).quantity *
            multiplier(totalChemical.units))
        );
      };

      if (chemicals.length) {
        chemicals.forEach((chemical, index) => {
          /* TODO refactor after inventory release */

          const chemicalActiveIngredients =
            chemical.chemical && chemical.chemical.properties
              ? [
                  {
                    activeIngredient:
                      chemical.chemical.properties.activeIngredient
                  }
                ]
              : chemical.chemical
              ? chemical.chemical.activeIngredients
              : {};

          const { name, matter } = chemical.chemical || {};
          const { chemicalRate } = chemical || {};
          const { dilutionRate, dilutionRateType, batchNumber } =
            chemical || {};
          const chemicalMatter = matter === "SOLID" ? "Kg" : "L";
          const rateType = dilutionRateType === "PER_HECTARE" ? "Ha" : "100L";

          withholdingPeriods.push(`${chemicalRate?.withholdingPeriod} day`);

          if (chemicalRate?.withholdingPeriod > mostWithholdingPeriod) {
            mostWithholdingPeriod = chemicalRate?.withholdingPeriod;
          }
          chemical.chemical &&
            productTypeList.push(chemical.chemical.type.type);
          if (chemicalActiveIngredients.length > 0) {
            chemicalActiveIngredients.forEach(ingredient => {
              if (!activeIngredients.includes(ingredient.activeIngredient)) {
                activeIngredients.push(ingredient.activeIngredient);
              }
            });
          }

          if (chemicalRate && chemicalRate.purpose) {
            purposes.push(chemicalRate.purpose);
          }
          chemicalsFertilisers.push(`${name} ${dilutionRate + chemicalMatter}`);
          actualRateApplied.push(
            totalChemicals[index].quantity && conc
              ? `${
                  dilutionRateType === "PER_HECTARE"
                    ? calculateActualRate(
                        dilutionRate,
                        totalChemicals[index],
                        selectedChemicals[index],
                        selectedAreas,
                        rowsToSpray,
                        rowWidth,
                        literPerHectare,
                        conc
                      ).toFixed(2)
                    : dilutionRate
                } ${chemicalMatter}/${rateType}`
              : "-"
          );

          batchNumbers.push(chemical.batchNumbers);
        });
      }

      if (supervisors.length) {
        supervisors.forEach(({ employee }) => {
          employee &&
            supervisorsList.push(`${employee.firstName} ${employee.lastName}`);
        });
      }

      if (assignees.length) {
        assignees.forEach(({ employee }) => {
          employee &&
            assigneesList.push(`${employee.firstName} ${employee.lastName}`);
        });
      }

      if (workingTimes.length) {
        workingTimes.forEach(({ workingFrom, workingTo }) => {
          workingTimesList.push(
            `${moment(workingFrom, "HH:mm:ss").format("hh:mm a")} 
                - ${moment(workingTo, "HH:mm:ss").format("hh:mm a")}`
          );
        });
      }

      const { date: actualDate } = getTaskPlannedOrEndDate({
        workingTimes,
        status
      });
      const taskData = {
        id,
        date: moment(actualDate).format("DD/MM/YYYY"),
        taskId: `ST-${id}`,
        type: applicationType,
        farms: farmsList,
        farmSize: farmSize,
        productTypeList,
        areas: areasList,
        crops: cropsList,
        varieties: varietiesList,
        chemicalsFertilisers,
        batchNumbers,
        activeIngredients,
        purposes,
        withholdingPeriods,
        safeHarvestDate: moment(actualDate)
          .add(mostWithholdingPeriod, "days")
          .format("DD/MM/YYYY"),
        concentrationFactor: conc,
        actualRateApplied,
        completedAt,
        plannedDate,
        deltaT,
        shedId,
        expirationDate,
        literPerHectare:
          (literPerHectare *
            areas.reduce(
              (prev, curr) =>
                prev +
                (curr.actualTanks ? curr.actualTanks : curr.plannedTanks),
              0
            ) *
            machinerySize) /
            areas.reduce((prev, curr) => prev + curr.plannedLiters, 0) ||
          literPerHectare,
        taskAdditionalInfo: {
          id,
          workingTimes: workingTimesList,
          windSpeed: windStrengthList[windStrength],
          windDirection,
          deltaT,
          temperature,
          supervisors: supervisorsList,
          assignees: assigneesList,
          machinery: machinery && machinery.name,
          comment
        }
      };

      const filtredCrops = areas.map(a => {
        const block = new Area(a.area);
        const variety = block.areaVarietyByDate(actualDate);
        const varietyList = a.area?.varietiesList;

        return varietyList
          ? uniq(varietyList).map((vary, index) => {
              return vary;
            })
          : variety.name
          ? `${variety.crop ? `${variety.crop.name}  ` : ""}`
          : "Vacant";
      });
      const filtredVariety = areas.map(a => {
        const block = new Area(a.area);
        const variety = block.areaVarietyByDate(actualDate);
        const varietyList = a.area ? a.area.varietiesList : [];

        return varietyList
          ? uniq(varietyList).map((vary, index) => {
              return vary;
            })
          : variety.name
          ? variety.name
          : "Vacant";
      });

      if (filtredVariety.length) {
        cropsList.push(...filtredCrops);
        varietiesList.push(...filtredVariety);
      } else {
        cropsList.push(null);
        varietiesList.push(null);
      }

      areas.forEach(area => {
        /* TODO */
        /* const variatiesIds = area.area.varieties.length
            ? area.area.varieties.map(({ variety }) => "" + variety.id)
            : [];
          const cropsIds = area.area.varieties.length
            ? area.area.varieties.map(({ variety }) => "" + variety.crop.id)
            : []; */

        const farmFilterCondition =
          !filters.farm ||
          (filters.farm && filters.farm.includes("" + area.area.farm.id));
        const areaFilterCondition =
          !filters.area ||
          (filters.area &&
            filters.area.includes(
              "" + area.area.id
            )); /*  &&
            (!filters.variety || (filters.variety && variatiesIds.some((vId) => filters.variety.includes(vId)))) &&
            (!filters.crop || (filters.crop && cropsIds.some((cId) => filters.crop.includes(cId)))) */

        areasList.push(
          area.area
            ? area.area.parent
              ? `${area.area.parent.name} - ${area.area.name}`
              : area.area.name
            : ""
        );

        if (area.area && !farmSize.includes(area.area.size)) {
          farmSize.push(area.area.size);
        }

        area.area && farmsList.push(area.area.farm.name);
        if (area.area && !printReportData[area.area.farm.id]) {
          printReportData[area.area.farm.id] = {
            farmName: area.area.farm.name,
            tasks: [taskData]
          };
        } else {
          if (
            area.area &&
            !printReportData[area.area.farm.id].tasks
              .map(task => task.id)
              .includes(id)
          ) {
            printReportData[area.area.farm.id].tasks.push(taskData);
          }
        }
      });

      return taskData;
    }
  );

  return {
    tasksList,
    printReportData
  };
};

export const applicationsRecordByBlockSelector = filters =>
  createSelector([applicationsRecordByBlock], data => {
    const { tasksList } = prepareApplicationRecordData(data, filters);

    return tasksList;
  });

export const applicationsRecordPrintoutDataSelector = filters =>
  createSelector([applicationsRecordListByBlock], data => {
    const { printReportData } = prepareApplicationRecordData(data, filters);

    return printReportData;
  });

export const employeePickedCropsFiltersSelector = state =>
  state.reports.reportsReducerNeedToRefactor.employeePickedCropsFilters;

const employeeBarcodesLoadingByEmployeeSelector = state =>
  state.reports.reportsReducerNeedToRefactor.loadingBarcodesEmployeeIds;

export const employeeBarcodesListSelector = state =>
  state.reports.reportsReducerNeedToRefactor.barcodesByEmployeeMap;

export const selectIsEmployeeReportLoading = createSelector(
  employeeBarcodesLoadingByEmployeeSelector,
  items => memoize(employeeId => items.includes(employeeId))
);

export const selectEmployeeBarcodes = createSelector(
  employeeBarcodesListSelector,
  barcodesMap => employeeId => barcodesMap[employeeId]
);
