import React from "react";
import {
  createSelector,
  createSelectorCreator,
  defaultMemoize
} from "reselect";
import { AbilityBuilder } from "@casl/ability";
import { createCanBoundTo } from "@casl/react";
import _, { isEqual } from "lodash";
import { Icon } from "semantic-ui-react";
import { routesConfig as routes } from "../routes/Routes/routesConfig";

export const DEFAULT_TIMEZONE = "Australia/Melbourne";

const getConditionalRoutesProperties = state => {
  const { pendingMovements } = state;

  return {
    pendingMovements
  };
};

const getUserOffline = state => {
  const {
    user: { user: offlineUser }
  } = state;

  return offlineUser;
};

const getOIDC = state => {
  const {
    oidc: { user: onlineUser }
  } = state;

  return onlineUser;
};

const userFERolesSelector = createSelector([getUserOffline], user => {
  return _.get(user, "profile.resource_accessy.app-web-react.roles", []);
});

const userSelector = createSelector([getUserOffline], user => {
  return user;
});

const oidcSelector = createSelector([getOIDC], user => user);

const getUserRoles = user => {
  return _.get(user, 'profile.resource_accessy["backend-api"].roles', []);
};

const userTypeSelector = createSelector([getUserOffline], user => {
  const userRoles = _.get(
    user,
    'profile.resource_accessy["backend-api"].roles',
    []
  );
  return userRoles.indexOf("employee") < 0 ? "admin" : "employee";
});

const abilitiesPairsSelector = createSelector([getUserOffline], user => {
  const beRoles = _.get(user, "profile.resource_accessy.backend-api.roles", []);
  const feRoles = _.get(
    user,
    "profile.resource_accessy.app-web-react.roles",
    []
  );
  return AbilityBuilder.define(can => {
    [...beRoles, ...feRoles].forEach(role => {
      const roleAction = role.split(":");
      if (roleAction.length === 1) {
        roleAction.unshift("type");
      }
      can(roleAction[1], roleAction[0]);
    });
  });
});
const currentUserIdSelector = createSelector([getUserOffline], user => {
  return _.get(user, "profile.employee_id");
});

const userBERolesSelector = createSelector([getUserOffline], user => {
  return getUserRoles(user);
});
const isSameAbilities = (a, b) => {
  return a && b && isEqual(a.originalRules, b.originalRules);
};

const abilitiesSelectorCreator = createSelectorCreator(
  defaultMemoize,
  isSameAbilities
);

const abilitiesSelector = abilitiesSelectorCreator(
  [abilitiesPairsSelector],
  abilities => {
    return createCanBoundTo(abilities);
  }
);

const routesConfig = createSelector(
  [getUserOffline, getConditionalRoutesProperties],
  (user, conditionalRoutesProperties) => {
    const userRoles = _.get(
      user,
      "profile.resource_accessy.app-web-react.roles",
      []
    );

    // collecting UI roles from Array to Object
    const rolesToObject = userRoles.reduce((roles, role) => {
      const roleKey = role.split(":");
      const [left, right] =
        roleKey.length > 2
          ? [`${roleKey[0]}:${roleKey[1]}`, roleKey[2]]
          : [roleKey[0], roleKey[1]];

      if (!roles[left]) {
        roles[left] = [];
      }
      roles[left].push(roleKey[right]);
      return roles;
    }, {});

    // filtering routesConfig for enabled UI components

    let gotDashboard = false;
    const filterNotPermit = route => {
      if (route.children) {
        return true;
      }

      if (!route.private) {
        if (!user && route.href === "/") {
          route.href = null;
          route.path = null;
        }
        return true;
      } else if (
        rolesToObject[route.href] &&
        (!route.module || rolesToObject[route.module])
      ) {
        if (rolesToObject[route.href] === "/") {
          gotDashboard = true;
        }
        route.abilities = rolesToObject[route.href];
        return true;
      }

      return false;
    };

    function filterNotPermitRoute(routes) {
      return routes
        .filter(filterNotPermit)
        .map(route => {
          if (route.children) {
            const filteredChilds = route.children.filter(r => {
              if (typeof r.isAvailable === "function") {
                return r.isAvailable(
                  conditionalRoutesProperties[r.storeAccesser]
                );
              }
              return true;
            });
            route = {
              ...route,
              children: filterNotPermitRoute(filteredChilds)
            };
          }

          return !route.children || route.children.length > 0 ? route : null;
        })
        .filter(a => a);
    }

    const treeToList = tree => {
      const treeToList = (tree, arr = []) => {
        tree.forEach(item => {
          item.children && treeToList(item.children, arr);
          arr.push(item);
        });
        return arr;
      };

      return treeToList(tree);
    };

    const filteredRoutes = treeToList(filterNotPermitRoute(routes));

    if (!gotDashboard) {
      const firstPrivateIndex = filteredRoutes.findIndex(
        route => route.private && route.menu
      );

      if (firstPrivateIndex >= 0) {
        filteredRoutes[firstPrivateIndex].exact = true;
        if (Array.isArray(filteredRoutes[firstPrivateIndex].path)) {
          if (filteredRoutes[firstPrivateIndex].path[0] !== "/") {
            filteredRoutes[firstPrivateIndex].path.unshift("/");
          }
        } else if (filteredRoutes[firstPrivateIndex].path !== "/") {
          filteredRoutes[firstPrivateIndex].path = [
            "/",
            filteredRoutes[firstPrivateIndex].path
          ];
        }
      }
    }

    return filteredRoutes;
  }
);

const menuTypesSelector = createSelector(
  [routesConfig, getConditionalRoutesProperties],
  (routes, conditionalRoutesProperties) => {
    const mainMenu = routes
      .filter(menu => menu.group === "main" && menu.menu)
      .map(menu =>
        menu.children
          ? {
              ...menu,
              children: menu.children.map(child => ({
                ...child,
                notificationsCount:
                  typeof child.getNotificationsCount === "function"
                    ? child.getNotificationsCount(
                        conditionalRoutesProperties[child.storeAccesser]
                      )
                    : 0
              }))
            }
          : menu
      );
    const headerMenu = routes
      .filter(menu => menu.group === "headerMenu")
      .map(menu =>
        menu.children
          ? {
              ...menu,
              children: menu.children.map(child => ({
                ...child,
                notificationsCount:
                  typeof child.getNotificationsCount === "function"
                    ? child.getNotificationsCount(
                        conditionalRoutesProperties[child.storeAccesser]
                      )
                    : 0
              }))
            }
          : menu
      );
    const accountMenu = routes.filter(
      menu => menu.group === "account" && menu.menu
    );
    const supportMenu = routes.filter(
      menu => menu.group === "support" && menu.menu
    );
    const userMenu = routes
      .filter(menu => menu.group === "account")
      .map(menu => ({
        key: menu.href,
        value: menu.href,
        text: menu.name,
        icon: menu.icon ? (
          <Icon
            {...(menu.icon.indexOf("tuf-") === 0
              ? { className: menu.icon }
              : { name: menu.icon })}
          />
        ) : null
      }));
    return { mainMenu, accountMenu, headerMenu, supportMenu, userMenu };
  }
);

const createEmployeePermissionsSelector = createSelector(
  [getUserOffline],
  user => {
    const userRoles = getUserRoles(user);

    let availableRoles = [
      "OPERATOR",
      "INTERNAL_AGRONOMIST",
      "EXTERNAL_AGRONOMIST",
      "WORKER"
    ];

    if (userRoles.includes("employees:manage_owner")) {
      availableRoles.push("OWNER");
    }
    if (userRoles.includes("employees:manage_head_office")) {
      availableRoles.push("HEAD_OFFICE");
    }
    if (userRoles.includes("employees:manage_admin")) {
      availableRoles.push("ADMIN");
    }
    if (userRoles.includes("employees:manage_farm_manager")) {
      availableRoles.push("FARM_MANAGER");
    }
    if (userRoles.includes("employees:manage_supervisor")) {
      availableRoles.push("SUPERVISOR");
    }

    return availableRoles;
  }
);

const createTimezoneSelector = createSelector([getUserOffline], user => {
  return _.get(user, "profile.timezone", DEFAULT_TIMEZONE);
});

export {
  userSelector,
  userTypeSelector,
  routesConfig,
  menuTypesSelector,
  currentUserIdSelector,
  userBERolesSelector,
  abilitiesSelector,
  createEmployeePermissionsSelector,
  oidcSelector,
  abilitiesPairsSelector,
  createTimezoneSelector,
  userFERolesSelector
};
