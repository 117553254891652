import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Table,
  Popup
} from "semantic-ui-react";
import {
  closeMessage,
  sendNotification
} from "../../../../actions/Notifications/notifications";
import { isEmptyValue } from "../../../../utils/tasksUtils";
import styles from "./Sidebar.module.css";
import { camelCase, get, isEmpty, startCase } from "lodash";
import { RestoreIcon } from "../../../../assets/icons/RestoreIcon";

const Sidebar = ({
  contractor,
  isArchive,
  Can,
  onClose,
  onEditClick,
  onDeleteItem
}) => {
  if (isEmpty(contractor)) {
    onClose();
  }

  return (
    !!contractor && (
      <Container fluid className={styles.root}>
        <Grid className={styles.sidebarHeader}>
          <Grid.Row
            columns={"equal"}
            style={{ paddingBottom: "10px" }}
            verticalAlign="middle"
          >
            <Grid.Column className={styles.headerColumn}>
              <Header className={styles.name} as="h1">
                {contractor.name}
              </Header>
            </Grid.Column>
            <Can I="update" a="contractors">
              <Grid.Column floated="right">
                {isArchive ? (
                  <Popup
                    inverted
                    content={"Restore"}
                    position="bottom center"
                    size="tiny"
                    trigger={
                      <Button
                        onClick={e => onDeleteItem(e, contractor.id, "Restore")}
                        className={styles.deleteButton}
                        floated="right"
                        icon={<RestoreIcon />}
                        size="small"
                      />
                    }
                  />
                ) : (
                  <Popup
                    inverted
                    content={"Edit"}
                    position="bottom center"
                    size="tiny"
                    trigger={
                      <Button
                        onClick={e => onEditClick(e, contractor.id)}
                        className={styles.editButton}
                        floated="right"
                        icon={<Icon name="edit" />}
                        size="medium"
                      />
                    }
                  />
                )}
              </Grid.Column>
            </Can>
            <Grid.Column width={1} floated="right">
              <Popup
                inverted
                content={"Close"}
                position="bottom center"
                size="tiny"
                trigger={
                  <Button
                    onClick={onClose}
                    className={styles.closeButton}
                    floated="right"
                    icon={<Icon className="tuf-times" />}
                    size="medium"
                  />
                }
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid className={styles.infoContainer}>
          <h1 className={styles.sectionHeader}>General Information</h1>
          <Table className={styles.sectionTable} definition fixed>
            <Table.Body>
              <Table.Row>
                <Table.Cell width={6}>Type</Table.Cell>
                <Table.Cell width={10}>
                  {contractor.type === "LAND_AND_FIELDWORK"
                    ? "Land and Fieldwork"
                    : "Chemical and Fertilising Operations"}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Address</Table.Cell>
                <Table.Cell>{isEmptyValue(contractor.address)}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Phone Number</Table.Cell>
                <Table.Cell>{isEmptyValue(contractor.phoneNumber)}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Email</Table.Cell>
                <Table.Cell>{isEmptyValue(contractor.email)}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>ABN</Table.Cell>
                <Table.Cell>{isEmptyValue(contractor.abn)}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Rate</Table.Cell>
                <Table.Cell>
                  {contractor.rate && contractor.rate !== 0
                    ? `$ ${contractor.rate.toFixed(2)}/hr`
                    : "—"}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Grid>
        <div className={styles.sidebarFooter}>
          <Button
            basic
            size="small"
            className={styles.actionButton}
            onClick={e =>
              onDeleteItem(e, contractor.id, !isArchive ? "Archive" : "Delete")
            }
            color={isArchive ? "red" : "green"}
          >
            {isArchive ? (
              <span style={{ display: "flex" }}>
                <Icon name="trash alternate" /> Delete
              </span>
            ) : (
              <span>
                {" "}
                <Icon name="archive" /> Archive
              </span>
            )}
          </Button>
        </div>
      </Container>
    )
  );
};

Sidebar.propTypes = {
  Contractor: PropTypes.object
};
Sidebar.defaultProps = {
  Contractor: {}
};

const mapStateToProps = state => {
  const {
    notifications: { isSending, success, error, showMessage }
  } = state;
  return { notifications: { isSending, success, error, showMessage } };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        sendNotification,
        closeMessage
      },
      dispatch
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
