import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Button, Icon } from "semantic-ui-react";
import { history } from "../../store";
import styles from "./SidebarHolder.module.css";
import classNames from "classnames";

function SidebarHolder({
  updateAction,
  accessName,
  onClose,
  Can,
  editLink,
  children,
  className
}) {
  const onEdit = useCallback(() => {
    history.push(editLink);
  }, [editLink]);
  return (
    <div className={classNames(styles.sidebar, className)}>
      <div className={styles.buttonsHolder}>
        {editLink && (
          <Can I={updateAction} a={accessName}>
            <Button
              onClick={onEdit}
              content="Edit"
              className="color-green transparent"
            />
          </Can>
        )}
        <Button
          onClick={onClose}
          className={"transparent"}
          floated="right"
          icon={<Icon className="tuf-times" size="large" />}
          size="medium"
        />
      </div>
      {children}
    </div>
  );
}

SidebarHolder.propTypes = {
  Can: PropTypes.func,
  accessName: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
  editLink: PropTypes.string,
  onClose: PropTypes.func,
  updateAction: PropTypes.string
};
SidebarHolder.defaultProps = {
  onClose: () => {}
};

export default SidebarHolder;
