import React, { useEffect, useRef } from "react";
import { Field, useFormik, useFormikContext } from "formik";
import { Form, Segment, Icon } from "semantic-ui-react";
import SwitchButton from "components/SwitchButton";
import { genders } from "../constants";
import { get } from "lodash";
import { employeesTypesSelector } from "selectors/employee";
import { currentUserIdSelector } from "selectors/user";
import { connect, useSelector } from "react-redux";
import CollapsibleBlock from "../../../../../components/CollapsibleBlock";
import DatePicker from "../../../../../components/DatePicker";
import { ROLE_COLORS } from "../../../constants";
import styles from "../AddUpdateEmployeeModal.module.css";

import { uploadProfilePicture as uploadProfilePictureAction } from "../../../../../actions/Employee/employees";
import ImageInput from "../../../../../components/ImageInput";
import { getNameInitials } from "../../../utils";

const PersonalDetailsSection = props => {
  const { values, touched, errors, setFieldValue } = useFormikContext();
  const { options } = useSelector(employeesTypesSelector);
  const currentUser = useSelector(currentUserIdSelector);

  const imgInputRef = useRef(null);

  const typeOptions = options
    .map(option => {
      return {
        key: option.value,
        text: (
          <span style={{ display: "flex", alignItems: "center" }}>
            <Icon
              name="circle"
              size="tiny"
              style={{ color: ROLE_COLORS[option.value], marginRight: "15px" }}
            />
            {option.label}
          </span>
        ),
        value: option.value,
        id: option.id
      };
    })
    .sort((a, b) => a.key.localeCompare(b.key));

  const uploadProfilePicture = file => {
    const { uploadPicture } = props;
    uploadPicture(file)
      .then(({ id, name, subPath, presignedUrl }) => {
        setFieldValue("picture", {
          id,
          name,
          subPath,
          presignedUrl: presignedUrl && presignedUrl
        });
      })
      .catch(err => console.log(err));
  };

  const removeProfilePicture = () => {
    setFieldValue("picture", null);
    imgInputRef.current.clearImage();
  };

  const uploadPicture = e => {
    imgInputRef.current.onImageClick(e);
  };

  return (
    <Segment>
      <CollapsibleBlock
        className={styles.collapseBlock}
        title="Personal details"
        defaultActive={
          !props.editSectionIndex ||
          props.editSectionIndex === 2 ||
          props.editSectionIndex === 3
        }
      >
        <Form>
          <Form.Group>
            <Field name="firstName" required>
              {({ field, meta }) => (
                <Form.Input
                  width={8}
                  label="First name"
                  error={meta.touched && meta.error}
                  required
                  {...field}
                />
              )}
            </Field>
            <Form.Field width={1} />
            <Field name="email" required={get(values, "type") !== "WORKER"}>
              {({ field, meta }) => (
                <Form.Input
                  width={8}
                  label="Email"
                  error={meta.touched && meta.error}
                  required={get(values, "type") !== "WORKER"}
                  {...field}
                />
              )}
            </Field>
          </Form.Group>
          <Form.Group>
            <Field name="lastName" required>
              {({ field, meta }) => (
                <Form.Input
                  width={8}
                  label="Last name"
                  error={meta.touched && meta.error}
                  required
                  {...field}
                />
              )}
            </Field>
            <Form.Field width={1} />
            <Field name="phone">
              {({ field, meta }) => (
                <Form.Input
                  width={8}
                  label="Phone"
                  error={meta.touched && meta.error}
                  {...field}
                />
              )}
            </Field>
          </Form.Group>
          <Form.Group>
            <Field
              as={SwitchButton}
              items={genders}
              name="gender"
              onChange={setFieldValue}
              required
              title="Gender"
              error={touched.gender && errors.gender}
              value={values.gender}
              width={8}
            />
            <Form.Field width={1} />
            <Field
              as={Form.Select}
              label="Role"
              options={typeOptions}
              disabled={props.isEdit && currentUser === values.id}
              name="type"
              required
              error={touched.type && errors.type}
              value={get(values, "type")}
              width={8}
              onChange={({ target }, { value }) => {
                setFieldValue("type", value);
              }}
            />
          </Form.Group>
          {/*<Form.Group style={{ marginBottom: 0 }}>*/} {/*Todo*/}
          {/*  <Form.Field width={8} />*/}
          {/*  <Form.Field width={1} />*/}
          {/*  <Form.Field width={8}>*/}
          {/*    <a style={{ color: "#F0AC47" }}>*/}
          {/*      More info about roles and permission in settings.{" "}*/}
          {/*    </a>*/}
          {/*  </Form.Field>*/}
          {/*</Form.Group>*/}
          <Form.Group>
            <Form.Field
              className={styles.dataPickerField}
              name="dateOfBirth"
              width={8}
            >
              <label>Date of birth</label>
              <DatePicker
                value={get(values, "dateOfBirth")}
                onChange={newValue => {
                  setFieldValue("dateOfBirth", newValue);
                }}
              />
            </Form.Field>
            <Form.Field width={1} />
            <Form.Field
              width={8}
              style={{
                display: "flex",
                marginTop: "16px",
                alignItems: "center"
              }}
            >
              <ImageInput
                // key={values.picture ? values.picture.presignedUrl : ''} //Todo
                nameInitials={getNameInitials(values)}
                roleColor={ROLE_COLORS[values.type]}
                ref={imgInputRef}
                imagePath={values.picture ? values.picture.presignedUrl : ""}
                onChange={uploadProfilePicture}
                isLoading={props.isUploadingPicture}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "12px"
                }}
              >
                <div style={{ display: "flex" }}>
                  <button
                    onClick={e => uploadPicture(e)}
                    className={styles.actionText}
                    style={{ marginRight: "12px" }}
                  >
                    Upload photo
                  </button>
                  {!!values.picture?.presignedUrl && (
                    <>
                      <div className={styles.dividerLine}>|</div>
                      <button
                        onClick={removeProfilePicture}
                        className={styles.actionText}
                        style={{ marginLeft: "12px" }}
                      >
                        Remove photo
                      </button>
                    </>
                  )}
                </div>
                <span className={styles.pictureDescText}>
                  Photo helps to recognise users on TieUp Farming.
                </span>
              </div>
            </Form.Field>
          </Form.Group>
        </Form>
      </CollapsibleBlock>
    </Segment>
  );
};

const mapStateToProp = state => {
  const {
    employee: { isUploadingPicture }
  } = state;
  return {
    isUploadingPicture
  };
};
const mapDispatchToProps = {
  uploadPicture: uploadProfilePictureAction
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(PersonalDetailsSection);
