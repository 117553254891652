import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { Button, Grid, Icon, Input } from "semantic-ui-react";
import PropTypes from "prop-types";
import ReactTable from "react-table-6";
import moment from "moment";

import styles from "./LocalDataTable.module.css";
import Pagination from "../ListTable/Pagination";
import SortDropdown from "../SortDropdown";
import DatePicker from "../DatePicker";
import ExportToExcel from "../ExportToExcel";

const renderCell = props => {
  const {
    value,
    column: { Header }
  } = props;

  return (
    <div>
      <div className="cellTitle hide-md">{Header}</div>
      <div className="cellValue">{value}</div>
    </div>
  );
};

renderCell.propTypes = {
  value: PropTypes.any,
  column: PropTypes.object
};

const LocalDataTable = ({
  name,
  columns,
  data,
  getData,
  isFetching,
  searchColumn,
  defaultSort,
  withDateRange,
  exportableToExcel
}) => {
  const tableRef = useRef(null);
  const [filtered, setFiltered] = useState([]);
  const [sortValue, setSortValue] = useState(
    (defaultSort && defaultSort.split(",")[0]) || null
  );
  const [sortOrder, setSortOrder] = useState(
    (defaultSort && defaultSort.split(",")[1]) || "asc"
  );
  const [dateFrom, setDateFrom] = useState(
    moment(moment.now())
      .subtract(0, "days")
      .format("YYYY-MM-DD")
  );
  const [dateTo, setDateTo] = useState(
    moment(moment.now()).format("YYYY-MM-DD")
  );
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pages: 0,
    currentPage: 0,
    itemsCount: {
      itemFrom: 0,
      itemTo: 0
    },
    totalElements: 0
  });

  const refresh = useCallback(
    () => getData({ from: dateFrom, to: dateTo, unpaged: true }),
    [getData, dateFrom, dateTo]
  );

  useEffect(() => {
    refresh();
  }, [refresh]); // eslint-disable-line;

  const tableColumns = useMemo(
    () =>
      columns.map(column => {
        return {
          id: column.id,
          accessor: column.accessor || column,
          Header: column.Header,
          Cell: renderCell,
          className: `cell_${column.width} ${column.className || ""}`,
          ...(column.sortFields ? { sortFields: column.sortFields } : {}),
          disableSort: column.disableSort,
          ...(column.columns ? { columns: column.columns } : {})
        };
      }),
    [columns]
  );

  const sortOptions = useMemo(() => {
    return tableColumns
      .filter(
        column =>
          column.accessor !== "actions" && column.Header && !column.disableSort
      )
      .map(column => ({
        key: column.id,
        text: `Sort by ${column.Header}`,
        value: column.id,
        content: column.Header,
        sortfields: column.sortFields || [column.id]
      }));
  }, [tableColumns]);

  const onFilteredChangeCustom = useCallback(
    (value, accessor) => {
      let innerFiltered = [...filtered];
      let insertNewFilter = 1;

      if (innerFiltered.length) {
        innerFiltered.forEach((filter, i) => {
          if (filter["id"] === accessor) {
            if (value === "" || !value.length) innerFiltered.splice(i, 1);
            else filter["value"] = value;

            insertNewFilter = 0;
          }
        });
      }

      if (insertNewFilter) {
        innerFiltered.push({ id: accessor, value: value });
      }

      setFiltered(innerFiltered);
    },
    [filtered, setFiltered]
  );

  const onPageChange = useCallback(
    (currentPage, loadMore) => {
      const itemFrom = loadMore ? 0 : currentPage * pagination.pageSize + 1;
      const itemTo = Math.min(
        itemFrom + pagination.pageSize - 1,
        pagination.totalElements
      );
      const pageSize = loadMore
        ? currentPage * pagination.pageSize + pagination.pageSize
        : pagination.pageSize;
      const pages = loadMore
        ? Math.ceil(pagination.totalElements / pageSize)
        : pagination.pages;
      setPagination({
        ...pagination,
        pageSize,
        pages,
        currentPage: loadMore ? 0 : currentPage,
        itemsCount: {
          itemFrom,
          itemTo
        }
      });
    },
    [pagination]
  );

  const updatePageSize = useCallback(
    (_, { value: pageSize }) => {
      setPagination({
        ...pagination,
        pageSize,
        pages: Math.ceil(pagination.totalElements / pageSize),
        currentPage: 0,
        itemsCount: {
          itemFrom: 0,
          itemTo:
            pagination.totalElements === 0
              ? pagination.totalElements
              : pageSize - 1
        }
      });
    },
    [pagination]
  );

  const onFilteredDataChange = useCallback(() => {
    const data =
      tableRef.current && tableRef.current.getResolvedState().sortedData;
    data &&
      setPagination({
        ...pagination,
        pages: Math.ceil(data.length / pagination.pageSize),
        currentPage: 0,
        itemsCount: {
          itemFrom: 0,
          itemTo: data.length === 0 ? data.length : pagination.pageSize - 1
        },
        totalElements: data.length
      });
  }, [pagination, tableRef]);

  const onSortChange = useCallback(({ sortValue, sortOrder, sort }) => {
    setSortValue(sortValue);
    setSortOrder(sortOrder);
  }, []);

  useEffect(() => {
    onFilteredDataChange();
  }, [isFetching, data, filtered]); // eslint-disable-line

  return (
    <Grid verticalAlign="middle">
      {withDateRange && (
        <Grid.Row verticalAlign="bottom">
          <Grid.Column mobile={16} tablet={10} computer={3}>
            <DatePicker
              value={dateFrom}
              onChange={date => setDateFrom(date)}
              labelText={"From"}
            />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={10} computer={3}>
            <DatePicker
              value={dateTo}
              onChange={date => setDateTo(date)}
              labelText={"To"}
            />
          </Grid.Column>
          <Grid.Column mobile={8} tablet={10} computer={3}>
            <Button onClick={refresh} size={"large"}>
              <Icon name="repeat" />
              <span>Refresh</span>
            </Button>
          </Grid.Column>
        </Grid.Row>
      )}
      <Grid.Row>
        <Grid.Column mobile={8} tablet={10} computer={4}>
          {searchColumn && (
            <Input
              icon="search"
              placeholder="Search"
              fluid
              onChange={e => {
                onFilteredChangeCustom(e.target.value, searchColumn);
              }}
              size={"large"}
            />
          )}
        </Grid.Column>
        <Grid.Column
          mobile={8}
          tablet={6}
          computer={12}
          textAlign="right"
          className={styles.sortColumn}
        >
          {exportableToExcel && (
            <ExportToExcel
              tableRef={tableRef}
              name={name}
              className={"hide-on-mobile"}
            />
          )}
          {sortOptions && sortOptions.length > 0 && (
            <SortDropdown
              sortOptions={sortOptions}
              onChange={onSortChange}
              sortValue={sortValue}
              sortOrder={sortOrder}
              defaultSort={defaultSort}
            />
          )}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column
          mobile={16}
          tablet={16}
          computer={16}
          largeScreen={16}
          widescreen={16}
        >
          <ReactTable
            ref={tableRef}
            className={`-striped ${styles.listTable}`}
            loading={isFetching}
            //filterable
            filtered={filtered}
            onFilteredChange={(filtered, column, value) => {
              onFilteredChangeCustom(value, column.id || column.accessor);
              onFilteredDataChange();
            }}
            defaultFilterMethod={(filter, row, column) => {
              const id = filter.pivotId || filter.id;
              if (typeof filter.value === "object") {
                return row[id] !== undefined
                  ? filter.value.indexOf(row[id]) > -1
                  : true;
              } else {
                return row[id] !== undefined
                  ? String(row[id])
                      .toLocaleLowerCase()
                      .indexOf(filter.value.toLocaleLowerCase()) > -1
                  : true;
              }
            }}
            data={data}
            showPagination={false}
            pageSize={pagination.pageSize}
            page={pagination.currentPage}
            columns={tableColumns}
            sortable={false}
            sorted={[
              {
                id: sortValue,
                desc: sortOrder === "desc"
              }
            ]}
            noDataText="No data available"
          />
          <Pagination
            pageSizeOptions={[10, 20, 50, 100]}
            {...pagination}
            updatePageSize={updatePageSize}
            onPageChangeOwn={onPageChange}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

LocalDataTable.propTypes = {
  columns: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  getData: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  searchColumn: PropTypes.string,
  name: PropTypes.string,
  defaultSort: PropTypes.string.isRequired,
  withDateRange: PropTypes.bool,
  exportableToExcel: PropTypes.bool
};

LocalDataTable.defaultProps = {
  withDateRange: true,
  exportableToExcel: false,
  isFetching: false,
  getData: () => {},
  defaultSort: ""
};

export default LocalDataTable;
