import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import styles from "./BasicCell.module.css";

const BasicCell = ({ value }) => {
  const [expanded, setExpanded] = useState(false);
  const [expandable, setExpandable] = useState(false);
  const cellRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      const element = cellRef.current;

      setExpandable(!(element && element.offsetHeight >= element.scrollHeight));
    });
  }, [cellRef.current, value]);

  return (
    <>
      <div
        className={classNames("basicCell", { [styles.expanded]: expanded })}
        ref={cellRef}
      >
        {value}
      </div>
      {expandable && (
        <button
          className={styles.expandButton}
          onClick={e => {
            e.stopPropagation();
            setExpanded(expanded => !expanded);
          }}
        >
          {expanded ? "Show Less" : "Show More"}
        </button>
      )}
    </>
  );
};

export default BasicCell;
