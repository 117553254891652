// Set same min height to all cells in one row to align title with content.
// It is unable to do it with css
//
// APPLICATION        ROW WIDTH
// PURPOSE
//
// Spreading          100
// Fertiliser/Seed
//
//

export default function setSameHeight(where = 'row', what = 'sameContentHeight') {
  const rows = [...document.getElementsByClassName(where)];

  rows.forEach(row => {
    const heights = [];
    const cells = [...row.getElementsByClassName(what)];
    cells.forEach(cell => {
      cell.style.minHeight = 'auto';
      heights.push(cell.offsetHeight);
    });
    const tallest = Math.max.apply(null, heights);
    cells.forEach(cell => {
      cell.style.minHeight = `${tallest}px`;
    });
  });
}
