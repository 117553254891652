import React from "react";

export const actionType = {
  OPERATIONS_START: "INVENTORY/OPERATIONS_START",
  OPERATIONS: "INVENTORY/OPERATIONS",

  OPERATIONS_FOR_PRINT_START: "INVENTORY/OPERATIONS_FOR_PRINT_START",
  OPERATIONS_FOR_PRINT: "INVENTORY/OPERATIONS_FOR_PRINT",

  OPERATIONS_FILTERS_START: "INVENTORY/OPERATIONS_FILTERS_START",
  OPERATIONS_FILTERS: "INVENTORY/OPERATIONS_FILTERS",

  OPERATIONS_TYPES_START: "INVENTORY/OPERATIONS_TYPES_START",
  OPERATIONS_TYPES: "INVENTORY/OPERATIONS_TYPES"
};

export const ROLE_COLORS = {
  OWNER: "#DED500",
  EXTERNAL_AGRONOMIST: "#37B963",
  OPERATOR: "#E87432",
  INTERNAL_AGRONOMIST: "#B4CA2D",
  WORKER: "#B25E21",
  SUPERVISOR: "#1DC5CF",
  FARM_MANAGER: "#3A67BF",
  ADMIN: "#AD3ABF",
  HEAD_OFFICE: "#DE4267"
};
