import { axiosProxy } from "../../utils/axiosProxy";
import { actionType } from "../../constants/Contractors";
import { history } from "../../store";
import { isNil, omitBy } from "lodash";
import { fetchEmployeesLog } from "actions/Employee/employees";

const url = "/contractors";

export const fetchContractorsList = () => async dispatch => {
  try {
    dispatch({
      type: actionType.FETCH_CONTRACTORS_LIST_START
    });

    const res = await axiosProxy({
      method: "GET",
      url: `${url}`,
      params: {
        unpaged: true,
        archived: false
      }
    });

    dispatch({
      type: actionType.FETCH_CONTRACTORS_LIST,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: actionType.FETCH_CONTRACTORS_LIST_FAIL,
      payload: error
    });
  }
};

export const fetchContractors = ({
  archived,
  page,
  size,
  search,
  sort,
  unpaged = false
}) => async dispatch => {
  try {
    dispatch({
      type: actionType.FETCH_CONTRACTORS_START
    });

    const paramsObject = omitBy(
      {
        archived,
        page,
        size,
        search,
        sort,
        unpaged
      },
      isNil
    );
    const params = new URLSearchParams(paramsObject);

    const res = await axiosProxy({
      method: "GET",
      params,
      url
    });

    await dispatch({
      type: actionType.FETCH_CONTRACTORS,
      payload: res.data
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.FETCH_CONTRACTORS_FAIL,
      payload: error
    });
  }
};

export const getContractorById = id => async dispatch => {
  try {
    dispatch({
      type: actionType.GET_CONTRACTORS_BY_ID_START
    });
    const res = await axiosProxy({
      method: "GET",
      url: `${url}/${id}`
    });

    dispatch({
      type: actionType.GET_CONTRACTORS_BY_ID,
      payload: res.data
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.GET_CONTRACTORS_BY_ID_FAIL,
      payload: error
    });
    return Promise.reject(error);
  }
};

export const addContractor = data => async dispatch => {
  try {
    dispatch({
      type: actionType.ADD_CONTRACTORS_START
    });
    const res = await axiosProxy({
      method: "POST",
      url,
      data
    });

    await dispatch({
      type: actionType.ADD_CONTRACTORS,
      payload: res.data
    });

    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.ADD_CONTRACTORS_FAIL,
      payload: error
    });
    return { error };
  }
};

export const updateContractor = (id, data) => async dispatch => {
  try {
    dispatch({
      type: actionType.UPDATE_CONTRACTORS_START
    });
    const res = await axiosProxy({
      method: "PUT",
      data,
      url: `${url}/${id}`
    });

    await dispatch({
      type: actionType.UPDATE_CONTRACTORS,
      payload: res.data
    });
    dispatch(fetchEmployeesLog());
    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.UPDATE_CONTRACTORS_FAIL,
      payload: error
    });
    return { error };
  }
};

export const deleteContractor = id => async dispatch => {
  try {
    dispatch({
      type: actionType.DELETE_CONTRACTOR_START
    });
    await axiosProxy({
      method: "DELETE",
      url: `${url}/${id}`
    });

    await dispatch({
      type: actionType.DELETE_CONTRACTOR,
      payload: id
    });
  } catch (error) {
    dispatch({
      type: actionType.DELETE_CONTRACTOR_FAIL,
      payload: error
    });
  }
};

export const clearError = () => dispatch => {
  dispatch({
    type: actionType.CLEAR_CONTRACTORS_ERROR
  });
};

export const contractorsStatusChange = (
  archive,
  contractorIds
) => async dispatch => {
  try {
    dispatch({
      type: actionType.ARCHIVE_CONTRACTORS_START
    });

    await axiosProxy({
      method: "PUT",
      url: `${url}/archive/batch?archive=${archive}&ids=${contractorIds.join(
        "&ids="
      )}`
    });

    dispatch({
      type: actionType.ARCHIVE_CONTRACTORS,
      payload: contractorIds
    });
    dispatch(fetchEmployeesLog());
  } catch (error) {
    dispatch({
      type: actionType.ARCHIVE_CONTRACTORS_FAIL,
      payload: error
    });
  }
};

export const deleteContractors = contractorIds => async dispatch => {
  try {
    dispatch({
      type: actionType.DELETE_CONTRACTORS_START
    });

    await axiosProxy({
      method: "DELETE",
      url: `${url}/batch?ids=${contractorIds.join("&ids=")}`
    });

    dispatch({
      type: actionType.DELETE_CONTRACTORS,
      payload: contractorIds
    });
  } catch (error) {
    dispatch({
      type: actionType.DELETE_CONTRACTORS_FAIL,
      payload: error
    });
  }
};

export const archiveContractor = (contractorId, archive) => async dispatch => {
  try {
    dispatch({
      type: actionType.ARCHIVE_CONTRACTORS_START
    });

    await axiosProxy({
      method: "PUT",
      url: `${url}/archive/batch?ids=${contractorId}&archive=${archive}`
    });

    dispatch({
      type: actionType.ARCHIVE_CONTRACTORS,
      payload: contractorId
    });
  } catch (error) {
    dispatch({
      type: actionType.ARCHIVE_CONTRACTORS_FAIL,
      payload: error
    });
  }
};

export const getEmployeeList = () => async (dispatch, getState) => {
  const url = "/employees";
  const {
    user: { user }
  } = getState();
  dispatch({
    type: actionType.GET_EMPLOYEE_LIST_START,
    meta: {
      callFrom: history.location.pathname,
      user,
      offline: {
        // the network action to execute:
        effect: {
          url,
          method: "GET",
          params: {
            unpaged: true
          }
        },
        commit: {
          type: actionType.GET_EMPLOYEE_LIST_SUCCESS,
          meta: {}
        },
        rollback: {
          type: actionType.GET_EMPLOYEE_LIST_ERROR,
          meta: {}
        }
      }
    }
  });
};
