import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import GenerateBarcodesForm from './EmployeesBarcodesGeneratorForm';
import styles from '../../Chemicals/AddNewChemical/AddNewChemical.module.css';
import LayoutOverlay from '../../../components/LayoutOverlay';
import { getParentPageHref } from '../../../utils/routeHelpers';

function EmployeesBarcodesGenerator(props) {
  const { route } = props;
  return (
    <LayoutOverlay
      closeLink={getParentPageHref(route)}
      route={{ ...route, name: 'Employees barcodes generator' }}
      childrenOnly
      footer={
        <div className={styles.buttonsFooter}>
          <Button primary size="large">
            Save
          </Button>
        </div>
      }
    >
      <GenerateBarcodesForm />
    </LayoutOverlay>
  );
}

EmployeesBarcodesGenerator.propTypes = {
  route: PropTypes.object.isRequired,
};

EmployeesBarcodesGenerator.defaultProps = {
  route: { name: '{%name%}' },
};

export default EmployeesBarcodesGenerator;
