import React, { useState } from "react";
import {
  Button,
  Icon,
  Modal,
  Header,
  List,
  Container
} from "semantic-ui-react";
import styles from "../Inventory.module.css";

const InventoryActionButtons = ({
  archiveButtonTitle,
  archiveButtonIcon,
  onArchiveButtonClick,
  withModal,
  selectedProducts
}) => {
  const [isArchiveConfirmShown, setArchiveConfirmShown] = useState(false);

  return (
    <>
      <Modal
        className={styles.messageModal}
        onClose={() => setArchiveConfirmShown(false)}
        open={isArchiveConfirmShown}
        size="tiny"
      >
        <Modal.Content>
          <Header className={styles.modalMessageHeader}>
            Archive the following products?
          </Header>
          <List as="ol">
            {selectedProducts.map(product => (
              <List.Item as="li">{product.name}</List.Item>
            ))}
          </List>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            content="Cancel"
            basic
            className={styles.cancelButton}
            onClick={() => setArchiveConfirmShown(false)}
          />
          <Button
            content="Archive"
            className={styles.addButton}
            onClick={onArchiveButtonClick}
            positive
          />
        </Modal.Actions>
      </Modal>

      <Button
        basic
        size="small"
        className={styles.actionButton}
        onClick={
          withModal ? () => setArchiveConfirmShown(true) : onArchiveButtonClick
        }
        color="green"
      >
        <Icon className={styles.archiveIcon} name={archiveButtonIcon} />
        {archiveButtonTitle}
      </Button>
    </>
  );
};

export default InventoryActionButtons;
