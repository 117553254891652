import React from "react";
import PropTypes from "prop-types";
import Logo from "../../../../../../../../../components/Logo";
import { Header, Table, TableBody, TableRow } from "semantic-ui-react";
import styles from "./PrintoutInventory.module.css";
import Numeric from "components/Numeric/Numeric";

class InventoryPrintout extends React.Component {
  render() {
    const { negativeData, positiveData, title, checmicals, shed } = this.props;
    const productsNameUniqe = Array.from(
      new Set(shed.buckets.map(item => item.chemicalId))
    ).map(id => {
      return shed.buckets.find(i => i.chemicalId === id)?.name;
    });
    return (
      <div className={styles.reportWrapper}>
        <style type="text/css" media="print">
          {"\
  @page { size: landscape; }\
"}
        </style>
        <div className={styles.reportHeaderRow}>
          <div className={styles.reportHeader}>{title}</div>
          <div className={styles.logoContainet}>
            <Logo width={140} height={50} />
          </div>
        </div>
        <Table columns="3" basic="very">
          <Table.Header>
            <Table.Row>
              <Table.Cell className={styles.totalHeader}>Adress</Table.Cell>
              <Table.Cell className={styles.totalHeader}>Product</Table.Cell>
              <Table.Cell className={styles.totalHeader}>Comments</Table.Cell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell className={styles.totalValue}>
                {shed.address || "-"}
              </Table.Cell>
              <Table.Cell className={styles.totalValue}>
                {productsNameUniqe.join(", ")}
              </Table.Cell>
              <Table.Cell className={styles.totalValue}>
                {shed.comments || "-"}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        {negativeData.length > 0 && (
          <>
            <div className={styles.headerContainer}>
              Negative inventory products
            </div>
            <Table columns="7" celled={true}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell className={styles.tableHeaderCell}>
                    Product
                  </Table.HeaderCell>
                  <Table.HeaderCell className={styles.tableHeaderCell}>
                    Active Ingredients
                  </Table.HeaderCell>
                  <Table.HeaderCell className={styles.tableHeaderCell}>
                    Matter
                  </Table.HeaderCell>
                  <Table.HeaderCell className={styles.tableHeaderCell}>
                    Type
                  </Table.HeaderCell>
                  <Table.HeaderCell className={styles.tableHeaderCell}>
                    Price ($/unit)
                  </Table.HeaderCell>
                  <Table.HeaderCell className={styles.tableHeaderCell}>
                    Expected inventory
                  </Table.HeaderCell>
                  <Table.HeaderCell className={styles.tableHeaderCell}>
                    Total Cost
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <TableBody>
                {negativeData.map(item => {
                  const activeIngredients = checmicals
                    .find(p => p.id === item.chemicalId)
                    ?.activeIngredients.map(
                      ({ activeIngredient }) => activeIngredient
                    );
                  const matter = checmicals.find(p => p.id === item.chemicalId)
                    ?.matter;
                  const type = checmicals.find(p => p.id === item.chemicalId)
                    ?.type?.type;
                  return (
                    <Table.Row>
                      <Table.Cell className={styles.tableBodyCell}>
                        {item.productName || "-"}
                      </Table.Cell>
                      <Table.Cell className={styles.tableBodyCell}>
                        {activeIngredients.join(", ") || "-"}
                      </Table.Cell>
                      <Table.Cell className={styles.tableBodyCell}>
                        {matter
                          .toLowerCase()
                          .charAt(0)
                          .toUpperCase() + matter.toLowerCase().slice(1) || "-"}
                      </Table.Cell>
                      <Table.Cell className={styles.tableBodyCell}>
                        {type || "-"}
                      </Table.Cell>
                      <Table.Cell className={styles.tableBodyCell}>
                        -
                      </Table.Cell>
                      <Table.Cell
                        className={styles.tableBodyExpectedInventoryCell}
                      >
                        <Numeric
                          value={
                            item.expectedInventory +
                            item.pendingOperations.reduce(
                              (sum, o) => sum + o.quantity,
                              0
                            )
                          }
                          fractionDigits={
                            item.expectedInventory +
                              item.pendingOperations.reduce(
                                (sum, o) => sum + o.quantity,
                                0
                              ) <
                            -1000
                              ? 0
                              : 2
                          }
                          commaSeparatorOnThousands
                          units={` ${item.unit}`}
                          unitsPosition="right"
                          defaultValue="-"
                        />
                      </Table.Cell>
                      <Table.Cell>
                        {item.buckets
                          .reduce((prev, curr) => {
                            return prev + curr.price * curr.quantity;
                          }, 0)
                          .toFixed(2)}
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </TableBody>
              <Table.Footer fullWidth>
                <Table.Row>
                  <Table.HeaderCell colSpan="6">Total</Table.HeaderCell>
                  <Table.HeaderCell colSpan="6">
                    {negativeData
                      .reduce((prev, curr) => {
                        return (
                          prev +
                          curr.buckets.reduce((prev, curr) => {
                            return prev + curr.price * curr.quantity;
                          }, 0)
                        );
                      }, 0)
                      .toFixed(2)}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </>
        )}
        {positiveData.length > 0 && (
          <>
            <div className={styles.headerContainer}>
              Positive inventory products
            </div>
            <Table columns="7" celled={true}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell className={styles.tableHeaderCell}>
                    Product
                  </Table.HeaderCell>
                  <Table.HeaderCell className={styles.tableHeaderCell}>
                    Active Ingredients
                  </Table.HeaderCell>
                  <Table.HeaderCell className={styles.tableHeaderCell}>
                    Matter
                  </Table.HeaderCell>
                  <Table.HeaderCell className={styles.tableHeaderCell}>
                    Type
                  </Table.HeaderCell>
                  <Table.HeaderCell className={styles.tableHeaderCell}>
                    Price ($/unit)
                  </Table.HeaderCell>
                  <Table.HeaderCell className={styles.tableHeaderCell}>
                    Expected inventory
                  </Table.HeaderCell>
                  <Table.HeaderCell className={styles.tableHeaderCell}>
                    Total Cost
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <TableBody>
                {positiveData.map(item => {
                  const activeIngredients = checmicals
                    .find(p => p.id === item.chemicalId)
                    ?.activeIngredients.map(
                      ({ activeIngredient }) => activeIngredient
                    );
                  const matter = checmicals.find(p => p.id === item.chemicalId)
                    ?.matter;
                  const type = checmicals.find(p => p.id === item.chemicalId)
                    ?.type?.type;

                  return (
                    <Table.Row>
                      <Table.Cell className={styles.tableBodyCell}>
                        {item.productName || "-"}
                      </Table.Cell>
                      <Table.Cell className={styles.tableBodyCell}>
                        {activeIngredients.join(", ") || "-"}
                      </Table.Cell>
                      <Table.Cell className={styles.tableBodyCell}>
                        {matter
                          .toLowerCase()
                          .charAt(0)
                          .toUpperCase() + matter.toLowerCase().slice(1) || "-"}
                      </Table.Cell>
                      <Table.Cell className={styles.tableBodyCell}>
                        {type || "-"}
                      </Table.Cell>
                      <Table.Cell className={styles.tableBodyCell}>
                        <>
                          <Numeric
                            value={item.price}
                            fractionDigits={item.price > 1000 ? 0 : 2}
                            commaSeparatorOnThousands
                            units="$"
                            unitsPosition="left"
                            defaultValue="-"
                          />
                          /{item.unit}
                        </>
                      </Table.Cell>
                      <Table.Cell className={styles.tableBodyCell}>
                        <Numeric
                          value={item.expectedInventory}
                          fractionDigits={item.expectedInventory > 1000 ? 0 : 2}
                          commaSeparatorOnThousands
                          units={` ${item.unit}`}
                          unitsPosition="right"
                          defaultValue="-"
                        />
                      </Table.Cell>
                      <Table.Cell>
                        {item.buckets
                          .reduce((prev, curr) => {
                            return prev + curr.price * curr.quantity;
                          }, 0)
                          .toFixed(2)}
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </TableBody>
              <Table.Footer fullWidth>
                <Table.Row>
                  <Table.HeaderCell colSpan="6">Total</Table.HeaderCell>
                  <Table.HeaderCell colSpan="6">
                    {positiveData
                      .reduce((prev, curr) => {
                        return (
                          prev +
                          curr.buckets.reduce((prev, curr) => {
                            return prev + curr.price * curr.quantity;
                          }, 0)
                        );
                      }, 0)
                      .toFixed(2)}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </>
        )}
        {positiveData.length < 0 && negativeData.length < 0 && (
          <Header as="h4">Nothing found</Header>
        )}
      </div>
    );
  }
}

InventoryPrintout.propTypes = {
  title: PropTypes.string,
  negativeData: PropTypes.object.isRequired,
  showExpandedData: PropTypes.bool,
  startDate: PropTypes.any,
  endDate: PropTypes.any
};

export default InventoryPrintout;
