import { actionType } from "../../constants/SprayDiary";

export const getAreasList = () => (dispatch, getState) => {
  const { areas } = getState();
  dispatch({
    type: actionType.GET_AREAS_LIST,
    payload: {
      areas: areas.list.content
    }
  });
};

export const changeAreas = areas => dispatch => {
  dispatch({
    type: actionType.CHANGE_AREAS,
    payload: areas
  });
};

export const setWidthPerRow = width => dispatch => {
  dispatch({
    type: actionType.SET_WIDTH_PER_ROW,
    payload: width
  });
};

export const setLPerHa = value => dispatch => {
  dispatch({
    type: actionType.SET_L_PER_HA,
    payload: value
  });
};

export const setAreaDoneState = id => dispatch => {
  dispatch({
    type: actionType.SET_AREA_DONE_STATE,
    payload: id
  });
};

export const setAreasDoneState = (areas, isDone) => dispatch => {
  dispatch({
    type: actionType.SET_AREAS_DONE_STATE,
    payload: { areas, isDone }
  });
};

export const setAllAreasDoneState = isAllDone => dispatch => {
  dispatch({
    type: actionType.SET_ALL_AREAS_DONE_STATE,
    isAllDone
  });
};

export const setAreaValue = (id, value) => dispatch => {
  dispatch({
    type: actionType.SET_AREA_VALUE,
    payload: { id, value }
  });
};

export const updateAreasList = areas => dispatch => {
  dispatch({
    type: actionType.UPDATE_AREAS_LIST,
    payload: areas
  });
};
