import React from "react";
import { useDispatch } from "react-redux";
import BusinessInformationForm from "./BusinessInformationForm";
import { createSettings } from "actions/Settings";

const BusinessInformation = ({ settings, setEditCheck, editCheck }) => {
  const dispatch = useDispatch();

  const submitForm = formValues => {
    const dataToSend = {
      ...settings,
      abn: formValues.abn,
      name: formValues.name,
      picture: formValues.picture
        ? {
            id: formValues.picture.id,
            name: formValues.picture.name,
            presignedUrl: formValues.picture.presignedUrl,
            subPath: formValues.picture.subPath
          }
        : null
    };

    return dispatch(createSettings(dataToSend)).then(setEditCheck(false));
  };

  return (
    <BusinessInformationForm
      settings={settings}
      onSubmit={submitForm}
      setEditCheck={setEditCheck}
      editCheck={editCheck}
    />
  );
};

export default BusinessInformation;
