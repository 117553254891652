import React from "react";
import _ from "lodash";

import SelectContractor from "../SelectContractor";
import SelectEmployee from "../SelectEmployee";
import SelectBoxUnit from "../SelectBoxUnit";
import SelectArea from "../SelectArea";
import SelectVariety from "../SelectVariety";
import { Area } from "../../models/block.model";
import SelectUsedVariety from "../SelectUsedVariety/SelectUsedVariety";
import PropTypes from "prop-types";

// Define a default UI for filtering
export const DefaultColumnFilter = ({
  column: { filterValue, preFilteredRows, setFilter }
}) => {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ""}
      onChange={e => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
};

DefaultColumnFilter.propTypes = {
  column: PropTypes.object
};

// This is a custom filter UI for selecting
// a unique option from a list
// Create an editable cell renderer
export const EditableCell = ({
  cell: { value: initialValue },
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
  editable
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);

  const onChange = e => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value);
  };

  // If the initialValue is changed externall, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (!editable) {
    return `${initialValue}`;
  }

  return <input value={value} onChange={onChange} onBlur={onBlur} />;
};

EditableCell.propTypes = {
  cell: PropTypes.object,
  row: PropTypes.object,
  column: PropTypes.object,
  updateMyData: PropTypes.func,
  editable: PropTypes.bool
};

// function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
//   // Calculate the options for filtering
//   // using the preFilteredRows
//   const options = React.useMemo(() => {
//     const options = new Set();
//     preFilteredRows.forEach(row => {
//       options.add(row.values[id]);
//     });
//     return [...options.values()];
//   }, [id, preFilteredRows]);
//
//   // Render a multi-select box
//   return (
//     <select
//       value={filterValue}
//       onChange={e => {
//         setFilter(e.target.value || undefined);
//       }}
//     >
//       <option value="">All</option>
//       {options.map((option, i) => (
//         <option key={i} value={option}>
//           {option}
//         </option>
//       ))}
//     </select>
//   );
// }
//
// // This is a custom filter UI that uses a
// // slider to set the filter value between a column's
// // min and max values
// function SliderColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
//   // Calculate the min and max
//   // using the preFilteredRows
//
//   const [min, max] = React.useMemo(() => {
//     let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
//     let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
//     preFilteredRows.forEach(row => {
//       min = Math.min(row.values[id], min);
//       max = Math.max(row.values[id], max);
//     });
//     return [min, max];
//   }, [id, preFilteredRows]);
//
//   return (
//     <>
//       <input
//         type="range"
//         min={min}
//         max={max}
//         value={filterValue || min}
//         onChange={e => {
//           setFilter(parseInt(e.target.value, 10));
//         }}
//       />
//       <button onClick={() => setFilter(undefined)}>Off</button>
//     </>
//   );
// }
//
// // This is a custom UI for our 'between' or number range
// // filter. It uses two number boxes and filters rows to
// // ones that have values between the two
// function NumberRangeColumnFilter({ column: { filterValue = [], preFilteredRows, setFilter, id } }) {
//   const [min, max] = React.useMemo(() => {
//     let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
//     let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
//     preFilteredRows.forEach(row => {
//       min = Math.min(row.values[id], min);
//       max = Math.max(row.values[id], max);
//     });
//     return [min, max];
//   }, [id, preFilteredRows]);
//
//   return (
//     <div
//       style={{
//         display: 'flex',
//       }}
//     >
//       <input
//         value={filterValue[0] || ''}
//         type="number"
//         onChange={e => {
//           const val = e.target.value;
//           setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]]);
//         }}
//         placeholder={`Min (${min})`}
//         style={{
//           width: '70px',
//           marginRight: '0.5rem',
//         }}
//       />
//       to
//       <input
//         value={filterValue[1] || ''}
//         type="number"
//         onChange={e => {
//           const val = e.target.value;
//           setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined]);
//         }}
//         placeholder={`Max (${max})`}
//         style={{
//           width: '70px',
//           marginLeft: '0.5rem',
//         }}
//       />
//     </div>
//   );
// }

export const ContractorFilter = ({
  column: { filterValue = [], setFilter },
  className,
  fluid
}) => {
  return (
    <SelectContractor
      native
      placeholder="All contractors"
      multiple
      value={filterValue}
      clearable
      onChange={(_, data) => setFilter(data.value)}
      className={className}
      fluid={fluid}
    />
  );
};

ContractorFilter.propTypes = {
  column: PropTypes.object,
  className: PropTypes.any,
  fluid: PropTypes.any
};

export const EmployeeFilter = ({
  column: { filterValue = [], setFilter },
  className,
  fluid
}) => {
  return (
    <SelectEmployee
      native
      placeholder="All employees"
      multiple
      value={filterValue}
      clearable
      onChange={(_, data) => setFilter(data.value)}
      className={className}
      fluid={fluid}
    />
  );
};

EmployeeFilter.propTypes = {
  column: PropTypes.object,
  className: PropTypes.any,
  fluid: PropTypes.any
};

export const BoxUnitFilter = ({
  column: { filterValue = [], setFilter },
  className,
  fluid
}) => {
  return (
    <SelectBoxUnit
      native
      placeholder="All box units"
      multiple
      value={filterValue}
      clearable
      onChange={(_, data) => setFilter(data.value)}
      className={className}
      fluid={fluid}
    />
  );
};

BoxUnitFilter.propTypes = {
  column: PropTypes.object,
  className: PropTypes.any,
  fluid: PropTypes.any
};

export const AreaFilter = ({
  column: { filterValue = [], setFilter },
  className,
  fluid
}) => {
  return (
    <SelectArea
      native
      placeholder="All areas"
      multiple
      value={filterValue}
      clearable
      onChange={(_, data) => setFilter(data.value)}
      className={className}
      fluid={fluid}
    />
  );
};

AreaFilter.propTypes = {
  column: PropTypes.object,
  className: PropTypes.any,
  fluid: PropTypes.any
};

export const VarietyFilter = ({
  column: { filterValue = [], setFilter },
  className,
  fluid
}) => {
  return (
    <SelectVariety
      native
      placeholder="All varieties"
      multiple
      value={filterValue}
      clearable
      onChange={(_, data) => setFilter(data.value)}
      className={className}
      fluid={fluid}
    />
  );
};

VarietyFilter.propTypes = {
  column: PropTypes.object,
  className: PropTypes.any,
  fluid: PropTypes.any
};

export const UsedVarietyFilter = ({
  column: { filterValue = [], setFilter },
  className,
  fluid
}) => {
  return (
    <SelectUsedVariety
      native
      placeholder="All varieties"
      multiple
      value={filterValue}
      clearable
      onChange={(_, data) => setFilter(data.value)}
      className={className}
      fluid={fluid}
    />
  );
};

UsedVarietyFilter.propTypes = {
  column: PropTypes.object,
  className: PropTypes.any,
  fluid: PropTypes.any
};

export const includesVariety = (areaAccessor, dateAccessor) => (
  rows,
  ids,
  filterValue
) => {
  if (!filterValue || filterValue.length === 0) {
    return rows;
  }

  return rows.filter(row => {
    const area = _.get(row.original, areaAccessor);
    const date = _.get(row.original, dateAccessor);
    const areaVariety = date && area && new Area(area).areaVarietyByDate(date);
    return areaVariety && filterValue.includes(areaVariety.variety.id);
  });
};

export const includes = accessor => (rows, ids, filterValue) => {
  if (!filterValue || filterValue.length === 0) {
    return rows;
  }

  return rows.filter(row => {
    const rowValue = _.get(row.original, accessor);
    return rowValue && filterValue.includes(rowValue);
  });
};

export const fuzzyTextFilterFn = (rows, id, filterValue) => {
  //return matchSorter(rows, filterValue, { keys: [row => row.values[id]] });
};

export const filterTypes = {
  // Add a new fuzzyTextFilterFn filter type.
  fuzzyText: fuzzyTextFilterFn,
  // Or, override the default text filter to use
  // "startWith"
  text: (rows, id, filterValue) => {
    return rows.filter(row => {
      const rowValue = row.values[id];
      return rowValue !== undefined
        ? String(rowValue)
            .toLowerCase()
            .startsWith(String(filterValue).toLowerCase())
        : true;
    });
  }
};
