import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MultiSelectFilter from "./MultiSelectFilter/MultiSelectFilter";
import { fetchActiveIngredients } from "../../../actions/Chemicals/index";

const ActiveIngredientsMultiSelectFilter = ({
  actions: { fetchList },
  list,
  isCreating,
  ...extraProps
}) => {
  useEffect(() => {
    fetchList();
  }, [fetchList]);

  const options = useMemo(
    () =>
      list
        .filter(item => !item.archived)
        .map(item => ({
          key: item,
          text: item,
          value: item
        }))
        .sort((item1, item2) => item1.text.localeCompare(item2.text)),
    [list]
  );
  return (
    <MultiSelectFilter
      options={options}
      numbers={false}
      loading={isCreating}
      {...extraProps}
    />
  );
};

const mapStateToProps = state => {
  const {
    chemical: {
      isCreating,
      activeIngredients: { content }
    }
  } = state;

  return {
    list: content,
    isCreating
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchList: fetchActiveIngredients
    },
    dispatch
  )
});

ActiveIngredientsMultiSelectFilter.propTypes = {
  isCreating: PropTypes.bool,
  list: PropTypes.array,
  actions: PropTypes.object
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActiveIngredientsMultiSelectFilter);
