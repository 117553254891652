import React from 'react';
import { Button, Table, Popup, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { isEmptyValue } from '../../../../utils/tasksUtils';

import styles from '../AddNewChemical.module.css';

const TableRow = ({ crop, index, rateTitle, removeCropRow, editCropRow }) => {
  return (
    <Table.Row key={`crop${crop.id.key}_${index}`} className={styles.rowCrop}>
      <Table.Cell>
        <div className={styles.title}>Crop</div>
        {crop.id.text}
      </Table.Cell>
      <Table.Cell>
        <div className={styles.title}>Purpose</div>
        {isEmptyValue(crop.purpose)}
      </Table.Cell>
      <Table.Cell className={styles.dashField} textAlign="center">
        <div className={styles.title}>{rateTitle}, Min</div>
        {isEmptyValue(crop.minRate)}
      </Table.Cell>
      <Table.Cell textAlign="center">
        <div className={styles.title}>{rateTitle}, Max</div>
        {isEmptyValue(crop.maxRate)}
      </Table.Cell>
      <Table.Cell textAlign="center">
        <div className={styles.title}>
          <Popup content="Withholding Period" trigger={<span>WHP</span>} />
        </div>
        {isEmptyValue(crop.withholdingPeriod)}
      </Table.Cell>
      <Table.Cell>
        <div className={styles.title}>Comments</div>
        {isEmptyValue(crop.comment)}
      </Table.Cell>
      <Table.Cell className={styles.newButtonsWrapper}>
        <Button
          icon={<Icon className="tuf-pen" />}
          className="button-text"
          onClick={() => editCropRow(index)}
          type="button"
        />

        <Button
          icon={<Icon className="tuf-trash-alt" />}
          className="button-text"
          onClick={() => removeCropRow(crop.id, crop.purpose)}
          type="button"
        />
      </Table.Cell>
    </Table.Row>
  );
};

TableRow.propTypes = {
  crop: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  rateTitle: PropTypes.string.isRequired,
  removeCropRow: PropTypes.func.isRequired,
  editCropRow: PropTypes.func.isRequired,
};

export default TableRow;
