import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Button, Form, TextArea, Input, Message } from "semantic-ui-react";
import { Field, Formik, useFormikContext, getIn } from "formik";
import { isEmpty, get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";
import { unitsSelector } from "selectors/chemicals";
import SwitchButton from "components/SwitchButton";
import SelectCrop from "./SelectCropInput";
import { PER_100_LITERS, PER_HECTARE } from "constants/Farms";
import MinMaxRateInput from "./MinMaxRateInput";
import { GENERIC_VALUE } from "selectors/crops";
import { matterToUnits, unitsToOptions } from "utils/constToUnits";
import styles from "./CropRatesSection.module.css";

const RATE_TYPE_ITEMS = [
  { label: "Per 100L", value: PER_100_LITERS },
  { label: "Per Ha", value: PER_HECTARE }
];

const REQUIRED_FIELDS = ["crop", "rateType"];

const CropRateForm = ({
  chemicalRate,
  cropIndex,
  cropListCheck,
  deleteAddingForm,
  deleteButtonCheck,
  onSubmit,
  onCancel
}) => {
  const { values, setFieldValue } = useFormikContext();
  const [resetRequired, setResetRequired] = useState(false);
  const { isFetching: unitsFetching, content: unitsContent } = useSelector(
    unitsSelector
  );
  const unitsItems = useMemo(() => {
    return isEmpty(unitsContent) ? {} : unitsToOptions(unitsContent);
  }, [unitsContent]);

  useEffect(() => {
    if (resetRequired) {
      setResetRequired(false);
    }
  }, [resetRequired]);
  useEffect(() => {
    const unitsList = unitsItems[values.matter];

    if (unitsList && !unitsList.find(({ value }) => value === values.unit)) {
      setFieldValue("unit", unitsList[1].value);
    }
  }, [unitsItems, values.matter, values.unit]);

  if (resetRequired) {
    return null;
  }
  return (
    <Formik
      enableReinitialize
      validate={values => {
        const errors = {};

        REQUIRED_FIELDS.forEach(fieldName => {
          if (!get(values, fieldName) && get(values, fieldName) !== null) {
            errors[fieldName] = true;
          }
        });

        ["withholdingPeriod", "reentry"].forEach(fieldName => {
          if (!errors[fieldName] && !/^\+?\d+$/.test(values[fieldName])) {
            errors[fieldName] = "Enter whole numbers";
          }
        });

        if (
          (values.maxRate || values.maxRate == 0) &&
          values.minRate >= values.maxRate
        ) {
          errors.minRate = true;
          errors.maxRate = true;
        }

        if (!get(values, "minRate") && get(values, "minRate") !== 0) {
          errors["minRate"] = true;
        }

        if (!get(values, "reentry") && get(values, "reentry") !== 0) {
          errors["reentry"] = true;
        }

        if (
          !get(values, "withholdingPeriod") &&
          get(values, "withholdingPeriod") !== 0
        ) {
          errors["withholdingPeriod"] = true;
        }

        return errors;
      }}
      onSubmit={values => {
        onSubmit(values);
        setResetRequired(true);
      }}
      initialValues={
        chemicalRate || {
          rateType: RATE_TYPE_ITEMS[0].value
          // unit: values.matter === "LIQUID" ? "milliliter" : "gram"
        }
      }
    >
      {props => {
        const defaultValue = values.matter === "LIQUID" ? "milliliter" : "gram";
        !props.values.unit && props.setFieldValue("unit", defaultValue);
        let checker = true;
        const cropId = props.values?.crop?.id;
        const purposeValue = props.values?.purpose;
        if (cropIndex !== undefined) {
          const rateIndex = values.rates.findIndex(
            rates =>
              rates?.crop?.id === chemicalRate?.crop?.id &&
              rates.purpose === chemicalRate.purpose
          );
          const doubleRate = values.rates.find(
            (rate, index) =>
              rate?.crop?.id === cropId &&
              rate.purpose === purposeValue &&
              index !== cropIndex
          );

          if (rateIndex > -1 && rateIndex === cropIndex && !doubleRate) {
            checker = false;
          }
        } else {
          checker = !!values.rates.find(rate => {
            return rate?.crop?.id !== undefined && cropId !== undefined
              ? rate?.crop?.id === cropId &&
                  (rate.purpose || "") === (purposeValue || "")
              : rate?.crop === null &&
                  cropId === -10000000 &&
                  (rate.purpose || "") === (purposeValue || "");
          });
        }

        return (
          <Form onSubmit={props.handleSubmit}>
            <Form.Group className={styles.cropFieldRow}>
              <Form.Field className={styles.cropField} fluid required width={4}>
                <label>Crop</label>
                <Field
                  as={SelectCrop}
                  error={checker}
                  checker={checker}
                  className="crop"
                  name="crop"
                  value={get(
                    props.values.crop,
                    "id",
                    chemicalRate ? GENERIC_VALUE : undefined
                  )}
                  onChange={({ key, text }) => {
                    props.setFieldValue("crop", { id: key, name: text });
                  }}
                />
              </Form.Field>
              <Field
                as={Form.Input}
                error={checker}
                name="purpose"
                className={styles.purpose}
                fluid
                label="Purpose"
                width={4}
              />
              {unitsItems[values.matter] && (
                <Field
                  as={SwitchButton}
                  items={unitsItems[values.matter]}
                  name="unit"
                  onChange={props.setFieldValue}
                  matter={values.matter}
                  title="Units"
                  value={props.values.unit || defaultValue}
                  width={4}
                  required
                />
              )}
              <Field
                as={SwitchButton}
                required
                error
                fluid
                title="Rate type"
                items={RATE_TYPE_ITEMS}
                name="rateType"
                onChange={props.setFieldValue}
                value={props.values.rateType}
                width={4}
              />
            </Form.Group>
            <Form.Group className={styles.ratesContainer}>
              <MinMaxRateInput
                width={8}
                label="Rate"
                unit={props.values.unit}
              />
              <Form.Field width={4} required className={styles.reentry}>
                <label>Re-entry</label>
                <Field
                  className={
                    props.errors.reentry === "Enter whole numbers"
                      ? "reentryWarningStyle"
                      : styles.reentry
                  }
                  onKeyPress={event => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  as={Input}
                  name="reentry"
                  label="Days"
                  labelPosition="right"
                />
                {props.errors.reentry && (
                  <div className={styles.errorMessage}>
                    {props.errors.reentry}
                  </div>
                )}
              </Form.Field>
              <Form.Field width={4} required>
                <label>Withholding period</label>
                <Field
                  className={
                    props.errors.withholdingPeriod === "Enter whole numbers"
                      ? "withholdingPeriodWarningStyle"
                      : styles.withholdingPeriod
                  }
                  onKeyPress={event => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  as={Input}
                  name="withholdingPeriod"
                  label="Days"
                  labelPosition="right"
                />
                {props.errors.withholdingPeriod && (
                  <div className={styles.errorMessage}>
                    {props.errors.withholdingPeriod}
                  </div>
                )}
              </Form.Field>
            </Form.Group>
            <Form.Group width={"equal"}>
              <Form.Field width={16}>
                <label>Comments</label>
                <TextArea
                  as={TextareaAutosize}
                  minRows={2}
                  size="large"
                  placeholder="Comment"
                  name="comment"
                  value={props.values.comment}
                  onChange={props.handleChange}
                  className={styles.comment}
                />
              </Form.Field>
            </Form.Group>

            <div className={styles.formButtonContainer}>
              <div className={styles.messageContainer}>
                {checker && (
                  <Message className={styles.purposeErrorMessage} color="red">
                    Crop and purpose combination must be unique.
                  </Message>
                )}
              </div>
              <div>
                {(deleteButtonCheck && cropListCheck) || onCancel ? (
                  <Button
                    className={styles.cancelBtn}
                    type="button-text button"
                    onClick={deleteAddingForm || onCancel}
                  >
                    Cancel
                  </Button>
                ) : null}
                <Button
                  type="submit"
                  className="button-text color-green"
                  disabled={!props.dirty || !props.isValid || checker}
                >
                  Save
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

CropRateForm.propTypes = {
  chemicalRate: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func
};

export default CropRateForm;
