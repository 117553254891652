import { actionType } from "../../constants/TagBarcodes";
import { axiosProxy } from "../../utils/axiosProxy";

const url = "/tag_barcodes";

export const generateTagBarcodes = ({
  blockLabel,
  comment,
  cropLabel,
  dateLabel,
  farmLabel,
  patchLabel,
  quantity,
  varietyLabel
}) => async dispatch => {
  try {
    dispatch({
      type: actionType.GENERATE_TAG_BARCODES_START
    });

    const res = await axiosProxy({
      method: "POST",
      url: `${url}/generate`,
      data: {
        blockLabel,
        comment,
        cropLabel,
        dateLabel,
        farmLabel,
        patchLabel,
        varietyLabel,
        quantity
      }
    });

    await dispatch({
      type: actionType.GENERATE_TAG_BARCODES,
      payload: res.data
    });

    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.GENERATE_TAG_BARCODES_FAIL,
      payload: error
    });
  }
};

export const fetchScannedTagBarcodes = ({
  unpaged,
  size,
  filters = {},
  to,
  from,
  page
}) => async dispatch => {
  try {
    dispatch({ type: actionType.FETCH_SCANNED_TAG_BARCODES_START });

    const params = new URLSearchParams();

    if (unpaged) {
      params.append("unpaged", "true");
    } else {
      params.append("page", page);
      params.append("size", size);
    }

    if (filters)
      Object.entries(filters).forEach(([filter, value]) =>
        params.append(filter, value)
      );

    if (from) {
      const dateFrom = `${new Date(from).toISOString().substr(0, 10)}T00:00:00`;
      params.append("scannedAtFrom", dateFrom);
    }

    if (to) {
      const dateTo = `${new Date(to).toISOString().substr(0, 10)}T23:59:59`;
      params.append("scannedAtTo", dateTo);
    }

    const res = await axiosProxy({
      method: "GET",
      url: url,
      params
    });

    dispatch({
      type: actionType.FETCH_SCANNED_TAG_BARCODES,
      payload: res.data
    });

    return res.data;
  } catch (e) {
    dispatch({ type: actionType.FETCH_SCANNED_TAG_BARCODES_FAIL });
  }
};

export const clearSeries = () => dispatch =>
  dispatch({ type: actionType.TAG_BARCODES_CLEAR_SERIES });

export const clearError = () => dispatch =>
  dispatch({ type: actionType.TAG_BARCODES_CLEAR_ERROR });
