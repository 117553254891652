import React, { useState } from "react";
import { Field, useFormikContext } from "formik";
import { Container, Form, Segment } from "semantic-ui-react";
import { map } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import ObjectId from "bson-objectid";
import { uploadAttachment } from "../../../../../actions/Employee/employees";
import FileChooser from "components/Attachments/FileChooser";
import AttachmentLabel from "components/Attachments/AttachmentLabel";
import CollapsibleBlock from "../../../../../components/CollapsibleBlock";
import styles from "../AddUpdateEmployeeModal.module.css";

const AdditionalInformationSection = props => {
  const { values, setFieldValue } = useFormikContext();
  const dispatch = useDispatch();

  const {
    isUploadingAttachment,
    uploadProgressPercentage,
    uploadAttachmentCancelToken
  } = useSelector(state => state.employee);

  const reader = new FileReader();

  const onAttachmentSelected = (fieldName, multi = false) => files => {
    files.forEach(file => {
      const dataToSend = {
        file,
        idReplace: ObjectId().toHexString(),
        callBack: loadedFile => {
          setFieldValue(
            fieldName,
            !multi
              ? loadedFile
              : values[fieldName]
              ? [...values[fieldName], loadedFile]
              : [loadedFile]
          );
        }
      };
      reader.addEventListener("error", () => {
        console.error(`Error occurred reading file`);
      });
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        dispatch(uploadAttachment(dataToSend)).catch(() => {
          console.log("Upload Stopped");
        });
      };
    });
  };

  return (
    <Segment>
      <CollapsibleBlock
        title="Additional information"
        defaultActive={!props.editSectionIndex || props.editSectionIndex === 4}
      >
        <Form>
          <Form.Group>
            <Field
              placeholder="You can leave some notes here"
              as={Form.TextArea}
              width={16}
              name="medicalConditions"
              label="Medical condition / allergies"
            />
          </Form.Group>
          <Container fluid className={styles.generalAttachmentsContainer}>
            <div className={styles.attachmentItemsContainer}>
              {map(values.attachments, (attachment, index) => (
                <AttachmentLabel
                  attachment={attachment}
                  onDeleteClick={() => {
                    setFieldValue(
                      "attachments",
                      values.attachments.filter((_, i) => i !== index)
                    );
                  }}
                />
              ))}
              {isUploadingAttachment && (
                <AttachmentLabel
                  withPercent={true}
                  onDeleteClick={() => uploadAttachmentCancelToken?.cancel()}
                  percent={uploadProgressPercentage}
                  attachment={{ name: "Uploading..." }}
                />
              )}
            </div>
            <div className={styles.fileChooserContainer}>
              <FileChooser
                className={styles.generalAttachmentsButton}
                onChange={onAttachmentSelected("attachments", true)}
                label="Attach files for additional information"
                disabled={isUploadingAttachment}
              />
            </div>
          </Container>
          <Form.Group>
            <Field
              placeholder="You can leave some notes here"
              as={Form.TextArea}
              width={16}
              name="notes"
              label="Additional Notes"
            />
          </Form.Group>
        </Form>
      </CollapsibleBlock>
    </Segment>
  );
};

export default AdditionalInformationSection;
