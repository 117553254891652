import React, { useEffect, useState } from "react";
import {
  Form,
  Segment,
  Button,
  Icon,
  Input,
  Container,
  Popup
} from "semantic-ui-react";
import { connect } from "react-redux";
import { useFormikContext, FieldArray, Field } from "formik";
import styles from "./BatchNumberInput.module.css";
import { isEmpty } from "lodash";

const SideBarBatchNumberInput = ({
  onChange,
  suffix,
  name,
  index,
  addFieldShow,
  setAddFieldShow,
  value
}) => {
  const { values, setFieldValue } = useFormikContext();
  useEffect(() => {
    if (isEmpty(values.batchNumbers) && !!suffix) {
      setFieldValue("batchNumbers", [""]);
    }
  }, [values.batchNumbers]);
  useEffect(() => {
    if (addFieldShow) {
      setFieldValue("batchNumbers", ["", ...values.batchNumbers]);
      setAddFieldShow(false);
    }
  }, [addFieldShow]);
  return (
    <Form className={styles.root}>
      <div className={styles.inputButtonWrapper}>
        <div className={styles.inputWrapper} key={index}>
          <Segment className={styles.ingredientSection}>
            <Field
              as={Input}
              disabled
              name={`batchNumbers.${index}`}
              className={styles.batchNumberInput}
              onChange={(_, { value }) => {
                values.batchNumbers[index] = value;
                onChange(values.batchNumbers, `chemicalBatchNumbers`, suffix);
              }}
            />
            <Popup
              content="Delete active batch number"
              position="bottom center"
              inverted
              trigger={
                <Icon
                  name="trash alternate"
                  className={styles.removeActiveIngredient}
                  onClick={() => {
                    const filteredValues = values.batchNumbers.filter(
                      (item, indx) => indx !== index
                    );
                    return onChange(
                      filteredValues,
                      `chemicalBatchNumbers`,
                      suffix
                    );
                  }}
                />
              }
            />
          </Segment>
        </div>
      </div>
    </Form>
  );
};
const mapStateToProps = state => {
  return {
    batchNumbers: state.spray.batchNumbers
  };
};

export default connect(mapStateToProps)(SideBarBatchNumberInput);
