import React from "react";
import PropTypes from "prop-types";
import { Grid, Header, Table, TableRow } from "semantic-ui-react";
import styles from "./PrintReport.module.css";
import Logo from "../../../../../components/Logo";
import TotalCostRowForPrint from "./TotalCostRowForPrint";
import Numeric from "../../../../../components/Numeric";
import { sum, round } from "lodash";

const PrintReport = ({ data }) => {
  return (
    <div className={styles.reportWrapper}>
      <div className={styles.reportHeaderRow}>
        <div>
          <Header as="h1">Total Cost Per Area</Header>
        </div>
        <div>
          <Logo width={140} height={60} />
        </div>
      </div>
      <Grid.Row>
        <Table striped>
          <Table.Header>
            <Table.Row className={styles.farmTable}>
              <Table.HeaderCell className={styles.boldFont}>
                Area Name
              </Table.HeaderCell>
              <Table.HeaderCell className={styles.boldFont}>
                Area Size (Ha)
              </Table.HeaderCell>
              <Table.HeaderCell className={styles.boldFont}>
                Chemical/Fertiliser Costs ($)
              </Table.HeaderCell>
              <Table.HeaderCell className={styles.boldFont}>
                Labour Costs ($)
              </Table.HeaderCell>
              <Table.HeaderCell className={styles.boldFont}>
                Machinery Costs ($)
              </Table.HeaderCell>
              <Table.HeaderCell className={styles.boldFont}>
                Operating Costs ($)
              </Table.HeaderCell>
              <Table.HeaderCell className={styles.boldFont}>
                Cost/Harvest Unit
              </Table.HeaderCell>
              <Table.HeaderCell className={styles.boldFont}>
                Cost/Ha ($/Ha)
              </Table.HeaderCell>
              <Table.HeaderCell className={styles.boldFont}>
                Total Costs ($)
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.map(area => (
              <TotalCostRowForPrint disableSubRow area={area} />
            ))}
            <TableRow>
              <Table.Cell style={{ fontWeight: "bold" }} width={2}></Table.Cell>
              <Table.Cell style={{ fontWeight: "bold" }} width={2}>
                {`${sum(data.map(area => area.areaSize))} Ha`}
              </Table.Cell>
              <Table.Cell style={{ fontWeight: "bold" }} width={2}>
                <Numeric
                  value={sum(data.map(area => area.chemicalCost))}
                  fractionDigits={2}
                  commaSeparatorOnThousands
                  units="$ "
                  unitsPosition="left"
                />
              </Table.Cell>
              <Table.Cell style={{ fontWeight: "bold" }} width={2}>
                <Numeric
                  value={sum(
                    data.map(area =>
                      round(
                        sum(
                          area.generalTaskCosts.map(task =>
                            task.labourCost ? task.labourCost : 0
                          )
                        ) +
                          sum(
                            area.generalTaskCosts.map(task =>
                              task.altLabourCost ? task.altLabourCost : 0
                            )
                          ) +
                          (area.sprayTaskLabourCost
                            ? area.sprayTaskLabourCost
                            : 0)
                      )
                    )
                  )}
                  fractionDigits={2}
                  commaSeparatorOnThousands
                  units="$ "
                  unitsPosition="left"
                />
              </Table.Cell>
              <Table.Cell style={{ fontWeight: "bold" }} width={2}>
                <Numeric
                  value={sum(
                    data.map(area =>
                      round(
                        sum(
                          area.generalTaskCosts.map(task =>
                            task.machineryCost ? task.machineryCost : 0
                          )
                        ) +
                          (area.sprayTaskMachineryCost
                            ? area.sprayTaskMachineryCost
                            : 0),
                        2
                      )
                    )
                  )}
                  fractionDigits={2}
                  commaSeparatorOnThousands
                  units="$ "
                  unitsPosition="left"
                />
              </Table.Cell>
              <Table.Cell style={{ fontWeight: "bold" }} width={2}>
                <Numeric
                  value={sum(
                    data.map(area =>
                      round(area.otherExpensesCost + area.overheadsCost)
                    )
                  )}
                  fractionDigits={2}
                  commaSeparatorOnThousands
                  units="$ "
                  unitsPosition="left"
                />
              </Table.Cell>
              <Table.Cell style={{ fontWeight: "bold" }} width={2}>
                <Numeric
                  value={sum(
                    data.map(area =>
                      area.generalTaskCosts.length > 0 && area.binCount
                        ? (area.sprayTaskLabourCost +
                            area.sprayTaskMachineryCost +
                            area.chemicalCost +
                            area.otherExpensesCost +
                            area.overheadsCost +
                            sum(
                              area.generalTaskCosts.map(task =>
                                task.altLabourCost ? task.altLabourCost : 0
                              )
                            ) +
                            sum(
                              area.generalTaskCosts.map(task =>
                                task.machineryCost ? task.machineryCost : 0
                              )
                            ) +
                            sum(
                              area.generalTaskCosts.map(task =>
                                task.labourCost ? task.labourCost : 0
                              )
                            )) /
                          area.binCount
                        : 0
                    )
                  )}
                  fractionDigits={2}
                  commaSeparatorOnThousands
                  units="$ "
                  unitsPosition="left"
                />
              </Table.Cell>
              <Table.Cell style={{ fontWeight: "bold" }} width={2}>
                <Numeric
                  value={sum(
                    data.map(
                      area =>
                        (area.sprayTaskLabourCost +
                          area.sprayTaskMachineryCost +
                          area.chemicalCost +
                          area.otherExpensesCost +
                          area.overheadsCost +
                          sum(
                            area.generalTaskCosts.map(task =>
                              task.altLabourCost ? task.altLabourCost : 0
                            )
                          ) +
                          sum(
                            area.generalTaskCosts.map(task =>
                              task.machineryCost ? task.machineryCost : 0
                            )
                          ) +
                          sum(
                            area.generalTaskCosts.map(task =>
                              task.labourCost ? task.labourCost : 0
                            )
                          )) /
                        area.areaSize
                    )
                  )}
                  fractionDigits={2}
                  commaSeparatorOnThousands
                  units="$ "
                  unitsPosition="left"
                />
                /Ha
              </Table.Cell>
              <Table.Cell style={{ fontWeight: "bold" }} width={2}>
                <Numeric
                  value={sum(
                    data.map(
                      area =>
                        area.sprayTaskLabourCost +
                        area.sprayTaskMachineryCost +
                        area.chemicalCost +
                        area.otherExpensesCost +
                        area.overheadsCost +
                        sum(
                          area.generalTaskCosts.map(task =>
                            task.altLabourCost ? task.altLabourCost : 0
                          )
                        ) +
                        sum(
                          area.generalTaskCosts.map(task =>
                            task.machineryCost ? task.machineryCost : 0
                          )
                        ) +
                        sum(
                          area.generalTaskCosts.map(task =>
                            task.labourCost ? task.labourCost : 0
                          )
                        )
                    )
                  )}
                  fractionDigits={2}
                  commaSeparatorOnThousands
                  units="$ "
                  unitsPosition="left"
                />
              </Table.Cell>
            </TableRow>
          </Table.Body>
        </Table>
      </Grid.Row>
    </div>
  );
};

PrintReport.propTypes = {
  data: PropTypes.object.isRequired,
  endDate: PropTypes.any,
  startDate: PropTypes.any,
  drawMultipleItems: PropTypes.func
};

export default PrintReport;
