import { Grid } from "semantic-ui-react";
import styles from "../styles.module.css";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { cropText } from "../../../../../utils/string";

const getDateRangeStr = dateRange =>
  dateRange.start && dateRange.end
    ? `${moment(dateRange.start).format("DD/MM")} - ${moment(
        dateRange.end
      ).format("DD/MM/YYYY")}`
    : "-";

function Review({ generalStep }) {
  const employees = useSelector(state => state.employee.data.content);

  const employeesNames = generalStep.assignTo
    .map(eID => employees.find(e => e.id === eID))
    .filter(e => !!e)
    .map(e => `${e.firstName} ${e.lastName}`)
    .join(", ");

  return (
    <Grid style={{ paddingLeft: 40 }}>
      <Grid.Row>
        <Grid.Column computer={2} mobile={16}>
          <span className={styles.fieldTitle}>Task Name</span>
          <br />
          <span className={styles.fieldValue}>
            {generalStep.taskName || "-"}
          </span>
        </Grid.Column>
        <Grid.Column computer={4} mobile={16}>
          <span className={styles.fieldTitle}>Description</span>
          <br />
          <span className={styles.fieldValueLong}>
            {cropText(generalStep.description, 60) || "-"}
          </span>
        </Grid.Column>
        <Grid.Column computer={2} mobile={16}>
          <span className={styles.fieldTitle}>Dates</span>
          <br />
          <span>{getDateRangeStr(generalStep.dateRange)}</span>
        </Grid.Column>
        <Grid.Column computer={2} mobile={16}>
          <span className={styles.fieldTitle}>Assign To</span>
          <br />
          <span>{employeesNames || "-"}</span>
        </Grid.Column>
        <Grid.Column computer={2} mobile={16}>
          <span className={styles.fieldTitle}>Priority</span>
          <br />
          <span>{generalStep.priority || "-"}</span>
        </Grid.Column>
        <Grid.Column computer={2} mobile={16}>
          <span className={styles.fieldTitle}>Status</span>
          <br />
          <span>{generalStep.status || "-"}</span>
        </Grid.Column>
        <Grid.Column computer={2} mobile={16}>
          <span className={styles.fieldTitle}>Sub-task name</span>
          <br />
          <span className={styles.fieldValue}>
            {generalStep.subTaskName || "-"}
          </span>
        </Grid.Column>
        <Grid.Column computer={2} mobile={16}>
          <span className={styles.fieldTitle}>Attachments</span>
          <br />
          <span>{generalStep.attachments.length || "-"}</span>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

Review.propTypes = {
  generalStep: PropTypes.object
};

export default Review;
