import { actionType } from "../../constants/Sheds";
import { keyBy } from "lodash";

const initialState = {
  list: {
    content: [],
    isFetching: false
  },
  data: {
    content: [],
    isFetching: false
  },
  pendingOperations: {
    content: []
  },
  activeItem: {
    id: null,
    isEditing: false
  },
  isCreating: false,
  fetchShedsToggle: false,
  error: null
};

export default (state = initialState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case actionType.FETCH_SHEDS_START:
    case actionType.FETCH_SHEDS_LIST_START:
    case actionType.UPDATE_SHED_START:
    case actionType.ARCHIVE_SHEDS_START:
    case actionType.PENDING_OPERATIONS_LIST_START:
      return {
        ...state,
        data: {
          ...state.data,
          isFetching: true
        }
      };

    case actionType.FETCH_SHEDS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
          isFetching: false
        }
      };
    case actionType.PENDING_OPERATIONS_LIST:
      return {
        ...state,
        data: {
          ...state.data,
          isFetching: false
        },
        pendingOperations: {
          content: action.payload.pendingOperations
        }
      };
    case actionType.FETCH_SHEDS_LIST:
      return {
        ...state,
        data: {
          ...state.data,
          isFetching: false
        },
        list: {
          ...state.list,
          ...action.payload,
          isFetching: false
        }
      };

    case actionType.UPDATE_SHED:
      const contentToUpdate = JSON.parse(JSON.stringify(state.data.content));
      const shedIndex = contentToUpdate.findIndex(
        shed => shed.id === action.payload.id
      );

      contentToUpdate.splice(shedIndex, 1, action.payload);
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
          content: contentToUpdate,
          isFetching: false
        }
      };
    case actionType.FETCH_SHEDS_ERROR:
    case actionType.UPDATE_SHED_FAIL:
    case actionType.ARCHIVE_SHEDS_FAIL:
      return {
        ...state,
        data: {
          ...state.data,
          isFetching: false
        },
        error: action.payload
      };

    case actionType.ARCHIVE_SHEDS:
      const deletedElementsIdsMap = keyBy(action.payload);

      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.filter(e => !deletedElementsIdsMap[e.id]),
          isFetching: false
        },
        activeItem: {
          id: null,
          isEditing: false
        }
      };
    case actionType.ADD_SHED_START:
      return {
        ...state,
        isCreating: true
      };
    case actionType.ADD_SHED:
      return {
        ...state,
        data: {
          content: [...state.data.content, action.payload]
        },
        isCreating: false,
        fetchShedsToggle: !state.fetchShedsToggle
      };

    case actionType.ADD_SHED_FAIL:
      return {
        ...state,
        isCreating: false,
        error: action.payload
      };
    case actionType.FETCH_SHEDS_LIST:
      return {
        ...state,
        ...state.list,
        list: {
          ...action.payload,
          isFetching: false
        }
      };
    case actionType.SET_ACTIVE_ITEM_ID:
      return {
        ...state,
        activeItem: {
          id: action.payload,
          error: null
        }
      };
    case actionType.UPDATE_SHED_INVENTORY: {
      const { shedId, buckets } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.map(shed => ({
            ...shed,
            buckets: shed.id === shedId ? buckets : shed.buckets
          }))
        }
      };
    }

    case actionType.OPERATION_FAIL:
      return {
        ...state,
        isCreating: false,
        error: action.payload
      };

    case actionType.DELETE_SHED_ERROR:
      return {
        ...state,
        isCreating: false,
        error: action.payload
      };

    case actionType.PENDING_OPERATIONS_START:
      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.map(shed =>
            shed.id === action.payload
              ? {
                  ...shed,
                  pendingOperations: {
                    content: [],
                    isFetching: true
                  }
                }
              : shed
          )
        }
      };
    case actionType.PARTIAL_UPDATE: {
      const { id, thresholds, farms } = action.payload.updatedShed;
      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.map(shed => ({
            ...shed,
            thresholds: shed.id === id ? thresholds : shed.thresholds,
            farms: shed.id === id ? farms : shed.farms
          }))
        }
      };
    }
    case actionType.PENDING_OPERATIONS: {
      const { pendingOperations, shedId } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.map(shed =>
            shed.id === shedId
              ? {
                  ...shed,
                  pendingOperations: {
                    content: pendingOperations,
                    isFetching: false
                  }
                }
              : shed
          )
        }
      };
    }
    case actionType.PENDING_OPERATIONS: {
      const { pendingOperations, shedId } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.map(shed =>
            shed.id === shedId
              ? {
                  ...shed,
                  pendingOperations: {
                    content: pendingOperations,
                    isFetching: false
                  }
                }
              : shed
          )
        }
      };
    }
    default:
      return state;
  }
};
