import React from 'react';
import styles from './index.module.css';
import PropTypes from 'prop-types';

function Loader({ absolute, className = '' }) {
  const absoluteClassName = absolute ? styles.positionAbsolute : '';
  return <div className={`${styles.loaderSpinner} ${absoluteClassName} ${className}`} />;
}

Loader.propTypes = {
  className: PropTypes.string,
  absolute: PropTypes.bool,
};

export default Loader;
