import PropTypes from "prop-types";
import React, { memo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHeader as Header,
  TableHeaderCell,
  TableRow
} from "semantic-ui-react";
import TaskRow from "../TaskRow";
import Numeric from "../../../Numeric";
import styles from "./index.module.css";
import "../../index.css";

const FarmsTable = ({ breakdownData, totalBreakdown }) => {
  return (
    <div className={styles.breakdownTableContainer}>
      <Table unstackable>
        <Header>
          <TableRow>
            <TableHeaderCell style={{ width: 30 }} />
            <TableHeaderCell>Task ID</TableHeaderCell>
            <TableHeaderCell>Task Type</TableHeaderCell>
            <TableHeaderCell>Chemical Cost</TableHeaderCell>
            <TableHeaderCell>Machinery Cost</TableHeaderCell>
            <TableHeaderCell>Labour Cost</TableHeaderCell>
            <TableHeaderCell>Cost ($)/Ha/Task</TableHeaderCell>
            <TableHeaderCell>Total Cost($)</TableHeaderCell>
          </TableRow>
        </Header>
        <TableBody>
          {breakdownData.map((item, i) => (
            <TaskRow key={`item_${i}`} task={item} />
          ))}
          <TableRow className={styles.breakdownTableContainerTotals}>
            <TableCell colSpan={3}>{`${breakdownData.length} Tasks`}</TableCell>
            <TableCell>
              <Numeric
                value={totalBreakdown.chemicalCost}
                fractionDigits={2}
                commaSeparatorOnThousands
                units="$ "
                unitsPosition="left"
              />
            </TableCell>
            <TableCell>
              <Numeric
                value={totalBreakdown.machineryCost}
                fractionDigits={2}
                commaSeparatorOnThousands
                units="$ "
                unitsPosition="left"
              />
            </TableCell>
            <TableCell>
              <Numeric
                value={totalBreakdown.laborCost}
                fractionDigits={2}
                commaSeparatorOnThousands
                units="$ "
                unitsPosition="left"
              />
            </TableCell>
            <TableCell>
              <Numeric
                value={totalBreakdown.cost / totalBreakdown.size}
                fractionDigits={2}
                commaSeparatorOnThousands
                units="$ "
                unitsPosition="left"
              />
              /Ha
            </TableCell>
            <TableCell>
              <Numeric
                value={totalBreakdown.cost}
                fractionDigits={2}
                commaSeparatorOnThousands
                units="$ "
                unitsPosition="left"
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

FarmsTable.propTypes = {
  breakdownData: PropTypes.any,
  totalBreakdown: PropTypes.any
};

export default memo(FarmsTable);
