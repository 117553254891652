import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Form } from "formsy-semantic-ui-react";
import { fetchPackingShedsList } from "../../actions/PackingSheds/packingSheds";

const SelectPackingShed = props => {
  const {
    actions: { fetchPackingSheds },
    packingShedsList,
    isFetching,
    required,
    error,
    minItemForSearch,
    onChange,
    showArchived,
    ...extraProps
  } = props;
  useEffect(() => {
    fetchPackingSheds();
  }, [fetchPackingSheds]);

  const options = useMemo(
    () =>
      packingShedsList
        .sort(content => content.name)
        .map(content => ({
          key: content.id,
          text: content.name,
          value: content.id
        })),
    [packingShedsList]
  );

  useEffect(() => {
    if (required && options && options.length === 1) {
      onChange(null, { options, value: options[0].value });
    }
  }, [options]); // eslint-disable-line

  return (
    <Form.Dropdown
      {...extraProps}
      error={error}
      closeOnBlur
      onChange={onChange}
      loading={isFetching}
      placeholder="Select packing shed"
      selection
      fluid
      required={required}
      search={options.length >= minItemForSearch}
      noResultsMessage="Nothing was found"
      options={options}
    />
  );
};

SelectPackingShed.propTypes = {
  actions: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  minItemForSearch: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  packingShedsList: PropTypes.array.isRequired,
  required: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.array.isRequired,
    PropTypes.number.isRequired
  ])
};

SelectPackingShed.defaultProps = {
  disabled: false,
  error: false,
  isFetching: false,
  minItemForSearch: 10,
  onChange: () => {},
  packingShedsList: [],
  required: false,
  value: []
};

const mapStateToProps = ({
  packingSheds: {
    list: { content, isFetching }
  }
}) => {
  return {
    packingShedsList: content,
    isFetching
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchPackingSheds: fetchPackingShedsList
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectPackingShed);
