import { actionType } from '../../constants/InfoMessages';
import ObjectId from 'bson-objectid';
import moment from 'moment';

export const infoMessageType = {
  INFO: 'INFO',
  WARN: 'WARN',
  ERROR: 'ERROR',
};

export const messagesIds = {
  GENERIC: 1,
  GENERIC_CODE: 2,
};

export const genericErrors = {
  NOT_EXISTS: 1000,
  MISSING_ATTRIBUTES: 1001,

  ALREADY_USED_BARCOD: 4000,
  INVALIDATE_BARCODES: 4001,

  NO_UNITS_IN_STOCK: 5000,

  ALREADY_USED_PALLETS_OR_NOT_EXISTS: 6000,
};
export const putMessage = message => dispatch => {
  dispatch({
    type: actionType.PUT_MESSAGE,
    payload: {
      id: ObjectId().toHexString(),
      ts: moment(),
      ...message,
    },
  });
};

export const removeMessage = id => dispatch => {
  dispatch({
    type: actionType.REMOVE_MESSAGE,
    payload: id,
  });
};
