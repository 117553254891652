import { actionType } from "../../constants/Varieties";
const initialState = {
  used_varieties: {
    content: [],
    isFetching: false
  },
  isFetching: false,
  error: null,
  data: []
};

const varietiesActions = (state, action) => {
  switch (action.type) {
    case actionType.GET_VARIETIES_LIST_START:
      return {
        ...state,
        isFetching: true
      };
    case actionType.GET_VARIETIES_LIST:
      return {
        ...state,
        isFetching: false,
        error: null,
        data: action.payload
      };
    case actionType.GET_VARIETIES_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        isFetching: false
      };
    case actionType.GET_USED_VARIETIES_LIST_START:
      return {
        ...state,
        used_varieties: {
          ...state.used_varieties,
          isFetching: true
        }
      };
    case actionType.GET_USED_VARIETIES_LIST:
      return {
        ...state,
        used_varieties: { ...action.payload, isFetching: false }
      };
    case actionType.GET_USED_VARIETIES_LIST_FAIL:
      return {
        ...state,
        used_varieties: { ...state.used_varieties, isFetching: false },
        error: action.payload
      };
    default:
      return state;
  }
};

export default (state = initialState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case actionType.GET_VARIETIES_LIST_START:
    case actionType.GET_VARIETIES_LIST:
    case actionType.GET_VARIETIES_LIST_FAIL:
    case actionType.GET_USED_VARIETIES_LIST_START:
    case actionType.GET_USED_VARIETIES_LIST:
    case actionType.GET_USED_VARIETIES_LIST_FAIL:
      return varietiesActions(state, action);
    default:
      return state;
  }
};
