import React, { useEffect, useMemo } from "react";
import { get, isEmpty, map } from "lodash";
import { Form, Input, Segment } from "semantic-ui-react";
import { FastField, useFormikContext, Field, Formik } from "formik";
import SwitchButton from "components/SwitchButton";
import CollapsibleBlock from "components/CollapsibleBlock";
import {
  chemicalTypeOptionsSelector,
  matterOptionsSelector,
  unitsSelector
} from "selectors/chemicals";
import { useDispatch, useSelector } from "react-redux";
import { fetchFilterValues, fetchUnits } from "actions/Chemicals";
import { matterToUnits, unitsToOptions } from "utils/constToUnits";
import { abilitiesSelector } from "selectors/user";
import {
  INVENTORY_MODULE,
  TRACK_INVENTORY_OPTIONS,
  YES_NO_OPTIONS
} from "constants/Chemicals";

const GeneralInformation = ({ editSectionIndex, chemical }) => {
  const { values, setFieldValue } = useFormikContext();
  const chemicalTypes = useSelector(chemicalTypeOptionsSelector);
  const { options: matterOptions } = useSelector(matterOptionsSelector);
  const Can = useSelector(abilitiesSelector);
  const { isFetching: unitsFetching, content: unitsContent } = useSelector(
    unitsSelector
  );
  const typeOptions = map(chemicalTypes.options, ({ value, label }) => ({
    key: value,
    value,
    text: label
  }));
  const dispatch = useDispatch();
  const unitsItems = useMemo(() => {
    return isEmpty(unitsContent) ? {} : unitsToOptions(unitsContent);
  }, [unitsContent]);

  const setDefaultTrackInventory = () => {
    if (values.trackInventory === undefined) {
      setFieldValue("trackInventory", false);
    }
  };

  const onMaterChange = (name, value) => {
    setFieldValue("matter", value);

    const unitsList = unitsItems[value];
    setFieldValue(
      "rates",
      values.rates.map(item => ({
        ...item,
        unit: unitsList[1].value
      }))
    );
  };

  useEffect(() => {
    if (!chemicalTypes.isFetching && isEmpty(chemicalTypes.options)) {
      dispatch(fetchFilterValues());
    }
  }, [chemicalTypes]);

  useEffect(() => {
    if (!unitsFetching && isEmpty(unitsContent)) {
      dispatch(fetchUnits());
    }
  }, [unitsFetching, unitsContent]);

  useEffect(() => {
    const unitsList = unitsItems[values.matter];

    if (unitsList && !unitsList.find(({ value }) => value === values.unit)) {
      setFieldValue("unit", unitsList[1].value);
    }
  }, [unitsItems, values.matter, values.unit]);
  useEffect(() => {}, [values.matter]);

  return (
    <Segment>
      <CollapsibleBlock
        title="General information"
        defaultActive={!editSectionIndex || editSectionIndex === 1}
      >
        <Form>
          <Form.Group>
            <Field name="name" required>
              {({ field, meta }) => (
                <Form.Input
                  width={7}
                  label="Product name"
                  error={meta.touched && meta.error}
                  required
                  {...field}
                />
              )}
            </Field>
            <Form.Field width={2} />
            <Field
              as={SwitchButton}
              items={matterOptions}
              name="matter"
              onChange={onMaterChange}
              required
              title="Matter"
              value={values.matter}
              width={3}
            />
            <Form.Field width={1} />
            <FastField
              as={SwitchButton}
              items={YES_NO_OPTIONS}
              name="batchRequired"
              onChange={setFieldValue}
              title="Batch required"
              value={values.batchRequired}
              width={3}
              required
            />
          </Form.Group>
          <Form.Group>
            <Field
              as={Form.Select}
              label="Product Type"
              options={typeOptions}
              name="type"
              required
              search
              value={get(values.type, "id")}
              width={7}
              onChange={({ target }, { value: id }) => {
                const type = typeOptions.find(({ value }) => id === value);
                setFieldValue("type", { type: type.text, id });
              }}
            />
            <Form.Field width={2} />
            <Can I={"access_module"} a={INVENTORY_MODULE}>
              {setDefaultTrackInventory()}
              <FastField
                as={SwitchButton}
                items={TRACK_INVENTORY_OPTIONS}
                name="trackInventory"
                onChange={setFieldValue}
                title="Track inventory"
                value={values.trackInventory}
                width={3}
                required
              />
              <Form.Field width={1} />
            </Can>
            <Can not I={"access_module"} a={INVENTORY_MODULE}>
              <Form.Field width={7} required>
                <label>Price ($/unit)</label>
                <Field
                  as={Input}
                  name="price"
                  labelPosition="right"
                  label={`$/${matterToUnits(values.matter)}`}
                />
              </Form.Field>
            </Can>
          </Form.Group>
          <Can I={"access_module"} a={INVENTORY_MODULE}>
            {values.trackInventory === false && (
              <Form.Group>
                <Form.Field width={9} />
                <Form.Field width={7} required>
                  <label>Price ($/unit)</label>
                  <Field
                    as={Input}
                    name="price"
                    labelPosition="right"
                    label={`$/${matterToUnits(values.matter)}`}
                    required
                  />
                </Form.Field>
              </Form.Group>
            )}
          </Can>
        </Form>
      </CollapsibleBlock>
    </Segment>
  );
};

export default GeneralInformation;
