import React, { useState } from "react";
import PropTypes from "prop-types";
import { Dropdown } from "semantic-ui-react";
import { valueToString } from "utils/tasksUtils";
import styles from "./ChemicalTargetDropdown.css";

/**
 * The 'Target' is described as current 'chemical.rate'
 */
const ChemicalTargetDropdown = ({ chemical, onChange, error }) => {
  const [defaultOpen, setDefaultOpen] = useState(false);
  const rateValue = chemical.rates
    ? chemical.rates.find(r => r.id === chemical.rate.id)
    : null;
  const selectItem = async data => {
    await setDefaultOpen(false);
    const rate = chemical.rates.find(r => r.id === data.value);
    if (rate.minRate >= 0 && (!rate.maxRate || rate.minRate === rate.maxRate)) {
      rate.rate = rate.minRate;
    }

    onChange(rate);
  };

  const setText = rateInfo => {
    const { crop, purpose, minRate, maxRate } = rateInfo;
    return [
      crop && crop.name ? crop.name : "Generic",
      purpose,
      minRate === maxRate || !valueToString(maxRate)
        ? minRate
        : `${minRate}-${maxRate}`
    ]
      .filter(item => item)
      .join(" - ");
  };

  const setContent = rateInfo => {
    const { crop, purpose, minRate, maxRate } = rateInfo;
    return (
      <div>
        <div>{crop && crop.name ? crop.name : "Generic"}</div>
        {purpose && <div>{purpose}</div>}
        <div>
          {minRate === maxRate || !valueToString(maxRate)
            ? minRate
            : `${minRate}-${maxRate}`}
        </div>
      </div>
    );
  };

  return (
    <Dropdown
      search
      fluid
      selection
      closeOnChange
      defaultOpen={defaultOpen}
      disabled={!chemical.rates || !chemical.rates.length}
      options={
        chemical.rates &&
        chemical.rates.map(rate => ({
          key: rate.id,
          text: setText(rate),
          value: rate.id,
          content: setContent(rate)
        }))
      }
      className={styles.dilutionDropdown}
      text={rateValue && setContent(rateValue)}
      value={rateValue && rateValue.id}
      onChange={(_, data) => selectItem(data)}
      error={error}
      menuTransition="slide up"
    />
  );
};

ChemicalTargetDropdown.propTypes = {
  error: PropTypes.bool,
  onChange: PropTypes.func,
  chemical: PropTypes.object
};

export default ChemicalTargetDropdown;
