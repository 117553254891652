import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { Button, Table } from "semantic-ui-react";
import { addCommas } from "../../../../components/Numeric";

const TotalRow = ({
  workingTimes,
  addWorkingTime,
  showPieceRateFields,
  totalPrice
}) => {
  const uniqueEmployeesCount = useMemo(
    () => [...new Set(workingTimes.map(wt => wt.employee))].length,
    workingTimes
  );

  const countWorkingHours = useMemo(() => {
    const workingMinutes = workingTimes.reduce(
      (sum, wt) => sum + wt.workingTimeInMinutesWithoutLunch,
      0
    );
    const workingHours = Math.floor(workingMinutes / 60);
    const restMinutes = workingMinutes - workingHours * 60;

    return `${workingHours}h ${restMinutes}m`;
  }, workingTimes);

  const countPieces = useMemo(
    () =>
      workingTimes.reduce((sum, wt) => sum + (wt.piecesPerEmployee || 0), 0),
    workingTimes
  );
  const countTotal = useMemo(
    () =>
      workingTimes.reduce(
        (sum, wt) => sum + ((wt.piecesPerEmployee || 0) * wt.pieceRate || 0),
        0
      ),
    workingTimes
  );

  return (
    <Table.Row>
      <Table.Cell>
        <b>Total</b>
      </Table.Cell>
      <Table.Cell>
        <b>{uniqueEmployeesCount}</b>
      </Table.Cell>
      <Table.Cell>
        <b>{countWorkingHours}</b>
      </Table.Cell>
      <Table.Cell />
      <Table.Cell />
      <Table.Cell />
      <Table.Cell />
      <Table.Cell />
      <Table.Cell />
      {showPieceRateFields && (
        <>
          <Table.Cell />
          <Table.Cell>{addCommas(countPieces)}</Table.Cell>
          <Table.Cell>{totalPrice.toFixed(2)}</Table.Cell>
        </>
      )}
      <Table.Cell>
        <Button primary size="small" type="button" onClick={addWorkingTime}>
          Add
        </Button>
      </Table.Cell>
    </Table.Row>
  );
};

TotalRow.propTypes = {
  addWorkingTime: PropTypes.func.isRequired,
  showPieceRateFields: PropTypes.bool,
  workingTimes: PropTypes.array.isRequired
};

TotalRow.defaultProps = {
  showPieceRateFields: false
};

export default TotalRow;
