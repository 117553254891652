import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button, TableCell, TableRow } from "semantic-ui-react";
import ChemicalsTable from "./ChemicalsTable";
import Numeric from "../../../Numeric";

const TaskRow = ({ task }) => {
  const [isExpanded, setExpanded] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell>
          <Button
            active={isExpanded}
            onClick={() => setExpanded(!isExpanded)}
            compact
            icon="dropdown"
          />
        </TableCell>
        <TableCell>
          <Link
            to={`/spray_diary/id=${task.sprayTaskId}/${task.sprayTaskId}#sort=date,desc`}
            target="_blank"
          >
            {`ST-${task.sprayTaskId}`}
          </Link>
        </TableCell>
        <TableCell>{task.taskType}</TableCell>
        <TableCell>
          <Numeric
            fractionDigits={2}
            value={task.chemicalCost}
            commaSeparatorOnThousands
            units="$ "
            unitsPosition="left"
          />
        </TableCell>
        <TableCell>
          <Numeric
            fractionDigits={2}
            value={task.machineryCost}
            commaSeparatorOnThousands
            units="$ "
            unitsPosition="left"
          />
        </TableCell>
        <TableCell>
          <Numeric
            fractionDigits={2}
            value={task.labourCost}
            commaSeparatorOnThousands
            units="$ "
            unitsPosition="left"
          />
        </TableCell>
        <TableCell>
          <Numeric
            fractionDigits={2}
            value={task.costHectare}
            commaSeparatorOnThousands
            units="$ "
            unitsPosition="left"
          />
          /Ha
        </TableCell>
        <TableCell>
          <Numeric
            fractionDigits={2}
            value={task.totalCost}
            commaSeparatorOnThousands
            units="$ "
            unitsPosition="left"
          />
        </TableCell>
      </TableRow>
      {isExpanded && (
        <TableRow>
          <TableCell colSpan={9}>
            <ChemicalsTable
              sprayTaskChemicalCosts={task.sprayTaskChemicalCosts}
            />
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

TaskRow.propTypes = {
  task: PropTypes.object
};

export default memo(TaskRow);
