import React, { useMemo, useState } from "react";
import { Table, Popup, Menu } from "semantic-ui-react";
import { isEmptyValue } from "utils/tasksUtils";
import CollapsibleBlock from "components/CollapsibleBlock";
import { getRateWithUnits } from "utils/constToUnits";
import { fetchUnits } from "actions/Chemicals";
import { useDispatch, useSelector } from "react-redux";
import { get, isEmpty } from "lodash";
import { unitsSelector } from "selectors/chemicals";
import styles from "../Sidebar.module.css";

const CropRatesSection = ({ chemical, editButton }) => {
  const dispatch = useDispatch();
  const { content, isFetching } = useSelector(unitsSelector);

  if (isEmpty(content) && !isFetching) {
    dispatch(fetchUnits());
  }

  return (
    <>
      <Menu pointing secondary className={styles.tabsMenu} fluid>
        <Menu.Item name="Information" active={true} />
      </Menu>
      <CollapsibleBlock
        className={styles.blockContainer}
        headerOptionalComponent={editButton}
        title="Crop rates"
      >
        <Table className={styles.sectionTable} celled fixed singleLine>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className={styles.cropLabel}>
                Crop
              </Table.HeaderCell>
              <Table.HeaderCell className={styles.purposeLabel}>
                Purpose
              </Table.HeaderCell>
              <Table.HeaderCell className={styles.rateLabel}>
                Rate
              </Table.HeaderCell>
              <Table.HeaderCell className={styles.reEntryLabel}>
                Re-entry
              </Table.HeaderCell>
              <Table.HeaderCell className={styles.whpLabel}>
                WHP
              </Table.HeaderCell>
              <Table.HeaderCell className={styles.commentsLabel}>
                Comments
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {chemical?.rates.map(
              ({
                maxRate,
                minRate,
                crop,
                rateType,
                reentry,
                unit,
                ...rate
              }) => {
                const foundElement = content.find(({ id }) => id === unit);
                const multiplier = foundElement
                  ? 1 / foundElement.multiplicationFactor
                  : 1;

                const rateValue = maxRate
                  ? `${minRate * multiplier} - ${getRateWithUnits(
                      maxRate,
                      rateType,
                      unit,
                      multiplier
                    )}`
                  : getRateWithUnits(minRate, rateType, unit, multiplier);
                return (
                  <Table.Row className={styles.itemGroupGrid} key={rate.id}>
                    <Table.Cell className={styles.cropContainer}>
                      {get(crop, "name", "Generic")}
                    </Table.Cell>
                    <Popup
                      trigger={
                        <Table.Cell className={styles.rowPurpose}>
                          {isEmptyValue(rate.purpose)}
                        </Table.Cell>
                      }
                      content={
                        <div className={styles.meesageContainer}>
                          {" "}
                          {isEmptyValue(rate.purpose)}
                        </div>
                      }
                      inverted
                      position="left center"
                      disabled={!rate.purpose}
                    />
                    <Popup
                      trigger={
                        <Table.Cell className={styles.rowRate}>
                          {rateValue}
                        </Table.Cell>
                      }
                      content={
                        <div className={styles.meesageContainer}>
                          {" "}
                          {rateValue}
                        </div>
                      }
                      inverted
                      position="left center"
                      disabled={!rateValue}
                    />
                    <Popup
                      trigger={
                        <Table.Cell className={styles.rowReentr}>
                          {isEmptyValue(reentry)} days
                        </Table.Cell>
                      }
                      content={
                        <div className={styles.meesageContainer}>
                          {" "}
                          {isEmptyValue(reentry)} days
                        </div>
                      }
                      inverted
                      position="left center"
                      disabled={!reentry}
                    />
                    <Popup
                      trigger={
                        <Table.Cell className={styles.rowWHP}>
                          {isEmptyValue(rate.withholdingPeriod)} days
                        </Table.Cell>
                      }
                      content={
                        <div className={styles.meesageContainer}>
                          {isEmptyValue(rate.withholdingPeriod)} days
                        </div>
                      }
                      inverted
                      position="left center"
                      disabled={!rate.withholdingPeriod}
                    />

                    <Popup
                      trigger={
                        <Table.Cell className={styles.rowComments}>
                          {isEmptyValue(rate.comment)}
                        </Table.Cell>
                      }
                      content={
                        <div className={styles.meesageContainer}>
                          {rate.comment}
                        </div>
                      }
                      inverted
                      position="left center"
                      disabled={!rate.comment}
                    />

                    {rate.comment ? (
                      <Popup
                        trigger={
                          <Table.Cell className={styles.commentsContainer}>
                            {rate.comment}
                          </Table.Cell>
                        }
                        content={
                          <div className={styles.commentContainer}>
                            {rate.comment}
                          </div>
                        }
                        inverted
                        position="left center"
                      />
                    ) : (
                      <Table.Cell>-</Table.Cell>
                    )}
                  </Table.Row>
                );
              }
            )}
          </Table.Body>
        </Table>
      </CollapsibleBlock>
    </>
  );
};

export default CropRatesSection;
