import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Container, Header, Segment } from "semantic-ui-react";
import classNames from "classnames";
import LayoutOverlay from "../../../components/LayoutOverlay";
import styles from "./styles.module.css";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  clearFormState,
  fetchTaskTypes
} from "../../../actions/GeneralTaskCreator/taskCreator";
import Blank from "../../Blank";
import { abilitiesSelector } from "../../../selectors/user";

const CreateTaskOptions = ({ route, location, Can }) => {
  const [taskCreationMethod] = useState("CREATE_NEW");
  const taskTypes = useSelector(state => state.taskCreator.taskTypes.content);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearFormState());
    dispatch(fetchTaskTypes());
  }, []);

  const taskTypesOptions = taskTypes
    .map(type => ({
      id: type.id,
      name: type.name,
      image: type.logo.presignedUrl
    }))
    .sort((a, b) => (a.id > b.id ? 1 : -1));

  return (
    <>
      <Can not I={"access_module"} a={"general_tasks"}>
        <Blank route={route} location={location} />
      </Can>
      <Can I={"access_module"} a={"general_tasks"}>
        <LayoutOverlay closeLink={"/tasks"} route={route} childrenOnly>
          <Container className={styles.taskContainer}>
            <Header as={"h2"} className={styles.heading}>
              Choose task type
            </Header>

            <div className={styles.creationOptions}>
              <button
                className={classNames(styles.creationOption, {
                  [styles.active]: taskCreationMethod === "CREATE_NEW"
                })}
              >
                Create new Task
              </button>
            </div>
            <Segment className={styles.taskTypesHolder}>
              {taskTypesOptions.map(type => (
                <Link
                  to={`/tasks/create/${type.id}`}
                  key={`spray_task_type_${type.name}`}
                  className={styles.taskCard}
                >
                  <img src={type.image} alt="" />
                  <span className={styles.caption}>{type.name}</span>
                </Link>
              ))}
            </Segment>
          </Container>
        </LayoutOverlay>
      </Can>
    </>
  );
};

CreateTaskOptions.propTypes = {
  Can: PropTypes.func,
  location: PropTypes.object,
  route: PropTypes.object
};

const mapStateToProps = state => {
  return {
    Can: abilitiesSelector(state)
  };
};

export default connect(mapStateToProps)(CreateTaskOptions);
