import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useFormikContext } from "formik";
import {
  Icon,
  Grid,
  Table,
  Segment,
  Popup,
  Modal,
  Button
} from "semantic-ui-react";
import { get } from "lodash";
import moment from "moment";
import styles from "./Inventory.module.css";
import InventoryEditForm from "./InventoryEditForm";
import { changeOperation } from "actions/Sheds";
import { fetchChemicalsLog } from "actions/Chemicals";
import { getPendingOperationsList } from "actions/PendingMovements";
import Numeric from "components/Numeric/Numeric";

const InventoryRow = ({
  productType,
  sheds,
  productList,
  inventoryData: {
    chemicalId,
    productName,
    price,
    initialPrice,
    expectedInventory,
    unit,
    buckets
  },
  shed
}) => {
  const dispatch = useDispatch();
  const { values, setFieldValue } = useFormikContext();
  const [isEditing, setIsEditing] = useState(false);
  const [openTable, toggleOpenTable] = useState(false);
  const [secondOpen, setSecondOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const lastPrice = buckets[buckets.length - 1].price;
  const firstPrice = buckets[0].price;

  const handleEditInventory = async ({
    actionOption,
    price,
    quantity,
    reason,
    shedTo,
    operationType
  }) => {
    await dispatch(
      changeOperation({
        chemicalId,
        shedId: shed.id,
        quantity: quantity * (actionOption === "REMOVE" ? -1 : 1),
        price: actionOption === "REMOVE" ? null : price,
        reason,
        shedTo,
        operationType
      })
    );
    dispatch(getPendingOperationsList());
    dispatch(fetchChemicalsLog());
    setIsEditing(false);
  };
  const handleDeleteInventory = () => {
    if (shed) {
      dispatch(
        changeOperation({
          chemicalId,
          shedId: shed.id,
          quantity: -expectedInventory,
          operationType: "STOCK_DELETION"
        })
      );
    } else {
      setFieldValue(
        "buckets",
        values.buckets.filter(bucket => bucket.chemicalId !== chemicalId)
      );
    }
    setShowDeleteModal(false);
  };

  return (
    <>
      <Modal
        className={styles.deleteModal}
        size="mini"
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
      >
        <Modal.Content>
          <p>
            Delete {expectedInventory} of {productName}{" "}
            {shed && `from ${shed.name}'s inventory`}?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            className={styles.cancelDltBtn}
            onClick={() => setShowDeleteModal(false)}
          >
            Cancel
          </Button>
          <Button
            className={styles.deleteBtn}
            positive
            onClick={() => handleDeleteInventory()}
          >
            Delete
          </Button>
        </Modal.Actions>
      </Modal>

      <Grid container as={Segment}>
        <Grid.Column className={styles.chevronContainer}>
          <div
            className={`${styles.toggleIcon} ${
              openTable ? styles.openTable : ""
            }`}
          >
            <Icon
              className={openTable ? "tuf-chevron-down" : "tuf-chevron-right"}
              onClick={() => toggleOpenTable(!openTable)}
            />
          </div>
        </Grid.Column>

        <Grid.Column className={styles.productNameItem}>
          {productName}
          <Popup
            position="right center"
            className={styles.popup}
            trigger={<Icon className={styles.infoButton} name="info circle" />}
            content={
              productType &&
              productType
                .filter(item => item.id === chemicalId)
                .map(item => (
                  <div className={styles.infoTooltipWrapper}>
                    <div> Type: {item.type ? item.type : "-"}</div>
                    <div>
                      {" "}
                      Matter:{" "}
                      {item.matter
                        ? item.matter == "LIQUID"
                          ? "Liquid"
                          : "Solid"
                        : "-"}
                    </div>
                    <div>
                      {" "}
                      Active ingredients:{" "}
                      {item.activeIngredient ? item.activeIngredient : "-"}
                    </div>
                  </div>
                ))
            }
            size="small"
            inverted
          />
        </Grid.Column>
        <Grid.Column className={styles.productTotalCostItem}>
          <>
            <Numeric
              fractionDigits={price > 1000 ? 0 : 2}
              value={price ? price : 0}
              commaSeparatorOnThousands
              units="$ "
              unitsPosition="left"
              defaultValue="-"
            />
            /{unit}
          </>
        </Grid.Column>
        <Grid.Column className={styles.productExpectedInventoryItem}>
          <>
            <Numeric
              fractionDigits={expectedInventory > 1000 ? 0 : 2}
              value={expectedInventory}
              commaSeparatorOnThousands
              defaultValue="-"
            />
            {unit}
          </>
        </Grid.Column>
        <Grid.Column className={styles.productButonContainer}>
          {!!shed && (
            <Popup
              inverted
              position="bottom center"
              content={"Edit"}
              size="tiny"
              trigger={
                <div className={styles.editButtonWrapper}>
                  <Button
                    onClick={() => setIsEditing(true)}
                    className={styles.editButton}
                    floated="right"
                    icon={<Icon name="edit" />}
                    size="small"
                  />
                </div>
              }
            />
          )}
          <Popup
            inverted
            position="bottom center"
            content={"Delete"}
            size="tiny"
            trigger={
              <Button
                onClick={() => setShowDeleteModal(true)}
                className={styles.deleteButton}
                floated="right"
                icon={<Icon name="trash alternate" />}
                size="small"
              />
            }
          />
        </Grid.Column>
        {openTable && (
          <Table
            compact
            basic="very"
            className={`${styles.tableFarm} ${
              openTable ? styles.tableOpen : ""
            }`}
          >
            <>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={2}>Date added</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Price ($/unit)</Table.HeaderCell>
                  <Table.HeaderCell width={2}></Table.HeaderCell>
                  <Table.HeaderCell width={2}>
                    Expected inventory
                  </Table.HeaderCell>
                  <Table.HeaderCell width={2}></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              {buckets.map(item => (
                <Table.Body>
                  <Table.Row>
                    <Table.Cell width={2}>
                      {item.createdAt
                        ? moment
                            .utc(item.createdAt.toString())
                            .format("DD/MM/YYYY")
                        : moment.utc(new Date()).format("DD/MM/YYYY")}
                    </Table.Cell>
                    <Table.Cell width={2}>
                      <>
                        <Numeric
                          fractionDigits={get(item, "price") > 1000 ? 0 : 2}
                          value={get(item, "price") ? get(item, "price") : 0}
                          commaSeparatorOnThousands
                          units="$ "
                          unitsPosition="left"
                          defaultValue="-"
                        />
                        /{unit}
                      </>
                    </Table.Cell>
                    <Table.Cell width={2}></Table.Cell>
                    <Table.Cell width={2}>
                      <>
                        <Numeric
                          fractionDigits={get(item, "quantity") > 1000 ? 0 : 2}
                          value={get(item, "quantity")}
                          commaSeparatorOnThousands
                          defaultValue="-"
                        />
                        {unit}
                      </>
                    </Table.Cell>
                    <Table.Cell width={2}></Table.Cell>
                  </Table.Row>
                </Table.Body>
              ))}
            </>
          </Table>
        )}
      </Grid>
      {isEditing && (
        <InventoryEditForm
          sheds
          inventory={{
            productName,
            initialPrice,
            lastPrice,
            firstPrice,
            currentInventory: expectedInventory,
            unit
          }}
          shedName={shed.name}
          shedId={shed.id}
          onSubmit={handleEditInventory}
          onCancel={() => setIsEditing(false)}
        />
      )}
    </>
  );
};

export default InventoryRow;
