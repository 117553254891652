import { GeneralTask } from "../../models/general_task.model";
import { PAYMENT_TYPES } from "../../constants/GeneralTasks";
import { minutesToHM } from "../../utils/timeUtils";
import { addCommas, removeCommas } from "../../components/Numeric";
import { enumerateDaysBetweenDates } from "../../utils/dateUtils";
import { flow as pipe, groupBy, identity } from "lodash";

const TYPES = {
  HOURLY_RATE: "HOURLY_RATE",
  PIECE_RATE: "PIECE_RATE"
};

const getEmployeeRows = (rawData, contractorID) => {
  const employees = {};

  rawData
    .filter(gt => gt.paymentMethod)
    .forEach(gt => {
      const generalTask = new GeneralTask(gt);

      if (generalTask.paymentMethod === PAYMENT_TYPES.HOURLY_RATE) {
        generalTask.workingTimes.forEach(workingTime => {
          const employeeID = workingTime.employee.id;

          const isSelectedContractor = !!contractorID;
          const isContractorPresent = !!workingTime.contractor;
          const isSameContractor =
            isContractorPresent && contractorID === workingTime.contractor.id;

          if (isSelectedContractor && !isSameContractor) return;

          const employeeRow = {
            $type: TYPES.HOURLY_RATE,
            id: employeeID,
            date: workingTime.startAt.split("T")[0],
            firstName: workingTime.employee.firstName,
            lastName: workingTime.employee.lastName,
            totalWorkingTime: workingTime.workingTimeInMinutesWithoutLunch,
            contractor: workingTime.contractor
              ? workingTime.contractor.name
              : null,
            ratePerHour: workingTime.employee.ratePerHour,
            payPerHours: workingTime.payout,
            payPerPiece: 0
          };

          employees.hasOwnProperty(employeeID)
            ? employees[employeeID].push(employeeRow)
            : (employees[employeeID] = [employeeRow]);
        });
      }

      if (generalTask.paymentMethod === PAYMENT_TYPES.PIECE_RATE) {
        const generalTaskUnitPrice = generalTask.unitPrice;

        generalTask.generalBarcodes.forEach(generalBarcode => {
          const price = generalTaskUnitPrice || generalBarcode.price;

          generalBarcode.employeeBinParts.forEach(({ part, employee }) => {
            const employeeID = employee.id;

            const isSelectedContractor = !!contractorID;
            const isContractorPresent = !!employee.contractor;
            const isSameContractor =
              isContractorPresent && contractorID === employee.contractor.id;

            if (isSelectedContractor && !isSameContractor) return;

            const employeeRow = {
              $type: TYPES.PIECE_RATE,
              date: generalBarcode.scannedAt.split("T")[0],
              id: employeeID,
              firstName: employee.firstName,
              lastName: employee.lastName,
              contractor: employee.contractor ? employee.contractor.name : null,
              ratePerHour: employee.ratePerHour,
              totalWorkingTime: 0,
              payPerHours: 0,
              payPerPiece: price * part
            };

            employees.hasOwnProperty(employeeID)
              ? employees[employeeID].push(employeeRow)
              : (employees[employeeID] = [employeeRow]);
          });
        });
      }
    });

  return employees;
};

export const generateHourlyReports = (
  rawData,
  contractorID,
  dateFrom,
  dateTo
) => {
  const employeeRows = getEmployeeRows(rawData, contractorID);
  const dates = enumerateDaysBetweenDates(dateFrom, dateTo).map(
    date => date.toISOString().split("T")[0]
  );

  const rows = Object.values(employeeRows)
    .map(employeeRows => {
      const validEmployeeRows = employeeRows.filter(
        row => row.$type === TYPES.HOURLY_RATE
      );
      if (validEmployeeRows.length === 0) return;

      const firstEmployeeRow = employeeRows[0];

      const totalCost = pipe(
        x => x.reduce((a, e) => a + e.payPerHours, 0),
        x => x.toFixed(2)
      )(validEmployeeRows);

      const base = {
        firstName: firstEmployeeRow.firstName,
        lastName: firstEmployeeRow.lastName,
        contractor: firstEmployeeRow.contractor,
        ratePerHour: firstEmployeeRow.ratePerHour,
        totalHours: pipe(x =>
          Number(x / 60)
            .toFixed(2)
            .replace(".", ",")
        )(validEmployeeRows.reduce((a, e) => a + e.totalWorkingTime, 0)),
        totalWorkingTime: validEmployeeRows.reduce(
          (a, e) => a + e.totalWorkingTime,
          0
        ),
        totalCostRaw: +totalCost,
        totalCost: totalCost.replaceAll(".", ",")
      };

      dates.forEach(date => {
        base[date] = pipe(x =>
          x > 0
            ? Number(x / 60)
                .toFixed(2)
                .replace(".", ",")
            : null
        )(
          validEmployeeRows
            .filter(row => row.date === date)
            .map(row => row.totalWorkingTime)
            .reduce((a, b) => a + b, 0)
        );
      });

      return base;
    })
    .filter(identity);

  return [
    ...Object.values(groupBy(rows, r => r.contractor))
      .flat()
      .reverse(),
    {
      isTotalCostRow: true,
      totalCost: pipe(
        data => data.reduce((a, b) => a + b.totalCostRaw, 0),
        n => n.toFixed(2),
        n => n.replaceAll(".", ",")
      )(rows)
    }
  ];
};

export const generatePieceRateReports = (
  rawData,
  contractorID,
  dateFrom,
  dateTo
) => {
  const employeeRows = getEmployeeRows(rawData, contractorID);
  const dates = enumerateDaysBetweenDates(dateFrom, dateTo).map(
    date => date.toISOString().split("T")[0]
  );

  const rows = Object.values(employeeRows)
    .map(employeeRows => {
      const validEmployeeRows = employeeRows.filter(
        row => row.$type === TYPES.PIECE_RATE
      );
      if (validEmployeeRows.length === 0) return;

      const firstEmployeeRow = employeeRows[0];

      const totalCost = pipe(x => x.toFixed(2))(
        validEmployeeRows.reduce((a, e) => a + e.payPerPiece, 0)
      );

      const base = {
        firstName: firstEmployeeRow.firstName,
        lastName: firstEmployeeRow.lastName,
        contractor: firstEmployeeRow.contractor,
        totalCostRaw: +totalCost,
        totalCost: totalCost.replaceAll(".", ",")
      };

      dates.forEach(date => {
        base[date] = pipe(
          x => x.toFixed(2),
          n => n.replaceAll(".", ",")
        )(
          validEmployeeRows
            .filter(row => row.date === date)
            .map(row => row.payPerPiece)
            .reduce((a, b) => a + b, 0)
        );
      });

      return base;
    })
    .filter(identity);

  return [
    ...rows,
    {
      isTotalCostRow: true,
      totalCost: pipe(
        data => data.reduce((a, b) => a + removeCommas(b.totalCost), 0),
        n => n.toFixed(2),
        n => n.replaceAll(".", ",")
      )(rows)
    }
  ];
};

export const generatePrintReport = (rawData, contractorId) => {
  const employeeRows = getEmployeeRows(rawData, contractorId);

  return Object.values(employeeRows).map(employeeRows => {
    const employee = { ...employeeRows[0] };

    const contractor = (employeeRows.find(er => er.contractor) || {})
      .contractor;
    employee.contractor = contractor || null;
    employee.totalWorkingTime = employeeRows.reduce(
      (a, e) => a + e.totalWorkingTime,
      0
    );
    employee.totalWorkingTimeInHM = minutesToHM(employee.totalWorkingTime);

    const payPerHours = employeeRows.reduce((a, e) => a + e.payPerHours, 0);
    const payPerPiece = employeeRows.reduce((a, e) => a + e.payPerPiece, 0);
    const totalPay = payPerHours + payPerPiece;

    employee.payPerHours = addCommas(payPerHours.toFixed(2));
    employee.payPerPiece = addCommas(payPerPiece.toFixed(2));
    employee.totalPay = addCommas(totalPay.toFixed(2));

    return employee;
  });
};
