import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import {
  Icon,
  Segment,
  Grid,
  Container,
  Modal,
  Popup,
  Button
} from "semantic-ui-react";
import { get } from "lodash";
import { isEmptyValue } from "utils/tasksUtils";
import ThresholdForm from "./ThresholdForm";
import styles from "./Threshold.module.css";
import { matterToUnits } from "utils/constToUnits";
import Numeric from "components/Numeric/Numeric";

const ThresholdRow = ({ thresholdData, thresholdsList, shed }) => {
  const { values, setFieldValue } = useFormikContext();
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { threshold } = thresholdData;

  const onDeleteClick = () => {
    setFieldValue(
      "thresholds",
      values.thresholds.filter(i => i !== thresholdData)
    );
    setShowDeleteModal(false);
  };

  const unit = thresholdsList
    .filter(threshold => threshold.key == thresholdData.chemicalId)
    .map(i => matterToUnits(i.matter));

  if (isEditing) {
    return (
      <Grid.Row className={styles.root}>
        <Segment as={Container}>
          <ThresholdForm
            unit={unit}
            thresholdData={thresholdData}
            onSubmit={newValue => {
              setFieldValue(
                "thresholds",
                values.thresholds.map(i => (i === thresholdData ? newValue : i))
              );
              setIsEditing(false);
            }}
            onCancel={() => setIsEditing(false)}
          />
        </Segment>
      </Grid.Row>
    );
  }

  return (
    <Grid.Row className={styles.rowContainer} as={Segment}>
      <Modal
        className={styles.deleteModal}
        size="mini"
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
      >
        <Modal.Content>
          <p>
            Delete {thresholdData.threshold} of{" "}
            {get(
              thresholdsList.find(t => t.value == thresholdData.chemicalId),
              "text"
            )}{" "}
            {shed && `from ${shed.name}'s inventory`}?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            className={styles.cancelDltBtn}
            onClick={() => setShowDeleteModal(false)}
          >
            Cancel
          </Button>
          <Button
            className={styles.deleteBtn}
            positive
            onClick={() => onDeleteClick()}
          >
            Delete
          </Button>
        </Modal.Actions>
      </Modal>
      <Grid.Column>
        {get(
          thresholdsList.find(t => t.value == thresholdData.chemicalId),
          "text"
        )}
      </Grid.Column>
      <Grid.Column>
        {isEmptyValue(threshold)}/{unit}
      </Grid.Column>
      <Grid.Column
        className={styles.thresholdsButtonCantainer}
        textAlign="right"
      >
        <Popup
          inverted
          position="bottom center"
          content={"Edit"}
          size="tiny"
          trigger={
            <div className={styles.editButtonWrapper}>
              <Button
                onClick={() => setIsEditing(true)}
                className={styles.editButton}
                floated="right"
                icon={<Icon name="edit" />}
                size="small"
              />
            </div>
          }
        />
        <Popup
          inverted
          position="bottom center"
          content={"Delete"}
          size="tiny"
          trigger={
            <Button
              onClick={() => setShowDeleteModal(true)}
              className={styles.deleteButton}
              floated="right"
              icon={<Icon name="trash alternate" />}
              size="small"
            />
          }
        />
      </Grid.Column>
    </Grid.Row>
  );
};

export default ThresholdRow;
