import React, { useState } from "react";
import { Button, Table, TableCell } from "semantic-ui-react";
import { round } from "lodash";
import Numeric from "../../../../components/Numeric";

const TotalCostRow = ({ data, name }) => {
  const [isExpanded, setExpanded] = useState(false);
  const dataSelector = data.costSwitcher
    ? data.sprayTaskMachineryCost
    : data.sprayTaskLabourCost;

  const applicationInfo = {
    totalCost: round(dataSelector, 2),
    costPerHa: data.areaSize
      ? round(dataSelector / data.areaSize, 2)
      : "No information",
    costPerHarvestUnit: data.binCount
      ? round(dataSelector / data.binCount, 2)
      : "No information"
  };

  return (
    <>
      <Table.Row style={{ fontWeight: "bold" }}>
        <TableCell width={1}>
          <Button
            active={isExpanded}
            onClick={() => setExpanded(!isExpanded)}
            compact
            icon="dropdown"
          />
        </TableCell>
        <Table.Cell width={3}>{name}</Table.Cell>
        <Table.Cell width={1}></Table.Cell>
        <Table.Cell width={2}>
          <Numeric
            value={data.costPerHarvestUnit}
            fractionDigits={2}
            commaSeparatorOnThousands
            units="$ "
            unitsPosition="left"
          />
        </Table.Cell>
        <Table.Cell width={2}>
          <Numeric
            value={data.costPerHa}
            fractionDigits={2}
            commaSeparatorOnThousands
            units="$ "
            unitsPosition="left"
          />
        </Table.Cell>
        <Table.Cell width={2}>
          <Numeric
            value={data.totalCosts}
            fractionDigits={2}
            commaSeparatorOnThousands
            units="$ "
            unitsPosition="left"
          />
        </Table.Cell>
      </Table.Row>
      {isExpanded && (
        <TableCell colSpan={16}>
          <Table colSpan={16} unstackable>
            <Table.Body>
              <Table.Row>
                <Table.Cell width={1}></Table.Cell>
                <TableCell width={3}>{`Application Task - ${
                  data.costSwitcher ? "Machinery Costs ($)" : "Labour Costs ($)"
                }`}</TableCell>
                <Table.Cell width={1}></Table.Cell>
                <Table.Cell width={2}>
                  <Numeric
                    value={applicationInfo.costPerHarvestUnit}
                    fractionDigits={2}
                    commaSeparatorOnThousands
                    units="$ "
                    unitsPosition="left"
                  />
                </Table.Cell>
                <Table.Cell width={2}>
                  <Numeric
                    value={applicationInfo.costPerHa}
                    fractionDigits={2}
                    commaSeparatorOnThousands
                    units="$ "
                    unitsPosition="left"
                  />
                </Table.Cell>
                <Table.Cell width={2}>
                  <Numeric
                    value={applicationInfo.totalCost}
                    fractionDigits={2}
                    commaSeparatorOnThousands
                    units="$ "
                    unitsPosition="left"
                  />
                </Table.Cell>
              </Table.Row>
              {data.tasks.map(task => (
                <>
                  <Table.Row>
                    <Table.Cell width={1}></Table.Cell>
                    <TableCell width={3}>
                      {`${task.generalTaskType} -
                    ${
                      data.costSwitcher
                        ? "Machinery Costs ($)"
                        : "Labour Costs ($)"
                    }`}
                    </TableCell>
                    <Table.Cell width={1}></Table.Cell>
                    <Table.Cell width={2}>
                      <Numeric
                        value={
                          data.binCount
                            ? data.costSwitcher
                              ? round(task.machineryCost / data.binCount, 2)
                              : round(task.labourCost / data.binCount, 2)
                            : "No information"
                        }
                        fractionDigits={2}
                        commaSeparatorOnThousands
                        units="$ "
                        unitsPosition="left"
                      />
                    </Table.Cell>
                    <Table.Cell width={2}>
                      <Numeric
                        value={
                          data.areaSize
                            ? data.costSwitcher
                              ? round(task.machineryCost / data.areaSize, 2)
                              : round(task.labourCost / data.areaSize, 2)
                            : "No information"
                        }
                        fractionDigits={2}
                        commaSeparatorOnThousands
                        units="$ "
                        unitsPosition="left"
                      />
                    </Table.Cell>
                    <Table.Cell width={2}>
                      <Numeric
                        value={
                          data.costSwitcher
                            ? round(task.machineryCost, 2)
                            : round(task.labourCost, 2)
                        }
                        fractionDigits={2}
                        commaSeparatorOnThousands
                        units="$ "
                        unitsPosition="left"
                      />
                    </Table.Cell>
                  </Table.Row>
                  {!data.costSwitcher && (
                    <Table.Row>
                      <Table.Cell width={1}></Table.Cell>
                      <TableCell width={3}>
                        {`${task.generalTaskType} - Alt Labour Costs($)`}
                      </TableCell>
                      <Table.Cell width={1}></Table.Cell>
                      <Table.Cell width={2}>
                        <Numeric
                          value={
                            data.binCount
                              ? round(task.altLabourCost / data.binCount, 2)
                              : "No information"
                          }
                          fractionDigits={2}
                          commaSeparatorOnThousands
                          units="$ "
                          unitsPosition="left"
                        />
                      </Table.Cell>
                      <Table.Cell width={2}>
                        <Numeric
                          value={
                            data.areaSize
                              ? round(task.altLabourCost / data.areaSize, 2)
                              : "No information"
                          }
                          fractionDigits={2}
                          commaSeparatorOnThousands
                          units="$ "
                          unitsPosition="left"
                        />
                      </Table.Cell>
                      <Table.Cell width={2}>
                        <Numeric
                          value={round(task.altLabourCost, 2)}
                          fractionDigits={2}
                          commaSeparatorOnThousands
                          units="$ "
                          unitsPosition="left"
                        />
                      </Table.Cell>
                    </Table.Row>
                  )}
                </>
              ))}
            </Table.Body>
          </Table>
        </TableCell>
      )}
    </>
  );
};

export default TotalCostRow;
