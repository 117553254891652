import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { Header, Segment, Sidebar } from "semantic-ui-react";
import { onlineSelector } from "../../selectors/common";
import MenuLeft from "../MenuLeft";
import HeaderMain from "../HeaderMain";
import ErrorBoundary from "../ErrorBoundary";
import styles from "./Layout.module.css";
import { menuTypesSelector } from "selectors/user";

const animation = "slide along";
const direction = "left";

function Layout({
  children,
  route,
  location,
  classForMain,
  online,
  showRouteName
}) {
  const [menuOpen, setMenuOpen] = useState(false);
  const pathname = location.pathname;
  const menuCollections = useSelector(menuTypesSelector);

  const closeMenu = useCallback(() => {
    setMenuOpen(false);
  }, [setMenuOpen]);
  return (
    <div className={styles.layoutHolder}>
      <HeaderMain
        menuCollections={menuCollections}
        route={route}
        pathname={pathname}
        setMenuOpen={setMenuOpen}
        menuOpen={menuOpen}
        online={online}
      />

      <Sidebar.Pushable>
        <MenuLeft
          animation={animation}
          direction={direction}
          className={`column-left${menuOpen ? " visible" : ""}`}
          setMenuOpen={setMenuOpen}
          location={location}
        />
        <Sidebar.Pusher className={styles.columnMain} onClick={closeMenu}>
          <Segment
            basic
            className={`${styles.mainHolder}${
              classForMain ? ` ${classForMain}` : ""
            }`}
          >
            {showRouteName && <Header as="h1">{route.name}</Header>}
            {route && route.name === "Dashboard" ? (
              children
            ) : (
              <ErrorBoundary>{children}</ErrorBoundary>
            )}
          </Segment>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  route: PropTypes.object,
  location: PropTypes.object,
  classForMain: PropTypes.string,
  online: PropTypes.bool,
  showRouteName: PropTypes.bool
};

Layout.defaultProps = {
  showRouteName: false
};

function mapStateToProps(state) {
  return {
    online: onlineSelector(state)
  };
}

export default connect(mapStateToProps)(Layout);
