import React from 'react';
import StationGraph from './StationGraph/StationGraph';
import ObjectId from 'bson-objectid';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const StationGraphs = props => {
  const { dataGroup, chartsOptions } = props;
  const groupsOptions = {};
  chartsOptions.forEach(
    options => (groupsOptions[options.groupId] = [...(groupsOptions[options.groupId] || []), options]),
  );
  return chartsOptions.map(chartOptions => (
    <StationGraph key={ObjectId().toHexString()} dataGroup={dataGroup} options={chartOptions} />
  ));
};

StationGraphs.propTypes = {
  chartsOptions: PropTypes.array.isRequired,
  dataGroup: PropTypes.string.isRequired,
};

StationGraphs.defaultProps = {};

const mapStateToProps = ({
  weatherStation: {
    stationData: { dataGroup, chartsOptions },
  },
}) => {
  return { dataGroup, chartsOptions };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(StationGraphs);
