import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  Button,
  Table,
  TableCell,
  TableHeaderCell,
  TableRow
} from "semantic-ui-react";
import _ from "lodash";

const BlockStat = ({ blockStat }) => {
  const [isExpanded, setExpanded] = useState(false);
  const hasChildren = Object.keys(blockStat.areasStat).length > 0;
  const areasStat = hasChildren ? Object.values(blockStat.areasStat) : [];
  const name = blockStat.blockName;
  const bins = hasChildren
    ? areasStat.reduce((acc, area) => acc + area.bins, 0)
    : blockStat.bins;
  const cost = hasChildren
    ? areasStat.reduce((acc, area) => acc + area.cost, 0)
    : blockStat.cost;
  const costRatioToBins = cost / bins;
  return (
    <>
      <TableRow>
        {hasChildren ? (
          <TableCell>
            <Button
              active={isExpanded}
              onClick={() => setExpanded(!isExpanded)}
              compact
              icon="dropdown"
            />
          </TableCell>
        ) : (
          <TableCell />
        )}
        <TableCell>{name}</TableCell>
        <TableCell>{bins}</TableCell>
        <TableCell>${_.round(cost, 2)}</TableCell>
        <TableCell>${_.round(costRatioToBins, 2)}</TableCell>
      </TableRow>
      {isExpanded && (
        <TableRow>
          <TableCell colSpan={5}>
            <Table unstackable>
              <Table.Header>
                <TableRow>
                  <TableHeaderCell>Patch Name</TableHeaderCell>
                  <TableHeaderCell>Bins Picked</TableHeaderCell>
                  <TableHeaderCell>Cost($)</TableHeaderCell>
                  <TableHeaderCell>Cost/Bin($/Bin)</TableHeaderCell>
                </TableRow>
              </Table.Header>
              <Table.Body>
                {areasStat.map(areaStat => (
                  <TableRow>
                    <TableCell>{areaStat.areaName}</TableCell>
                    <TableCell>{areaStat.bins}</TableCell>
                    <TableCell>${_.round(areaStat.cost, 2)}</TableCell>
                    <TableCell>${_.round(costRatioToBins, 2)}</TableCell>
                  </TableRow>
                ))}
              </Table.Body>
            </Table>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

BlockStat.propTypes = {
  blockStat: PropTypes.shape({
    areasStat: PropTypes.object.isRequired,
    blockName: PropTypes.string.isRequired,
    bins: PropTypes.number
  })
};

BlockStat.defaultProps = {
  bins: 0
};

export default BlockStat;
