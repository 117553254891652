import React from "react";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";
import GenerateBarcodesForm from "./GeneralBarcodesGeneratorForm";
import styles from "../../Chemicals/AddNewChemical/AddNewChemical.module.css";
import LayoutOverlay from "../../../components/LayoutOverlay";
import { getParentPageHref } from "../../../utils/routeHelpers";

function GeneralBarcodesGenerator(props) {
  const { route } = props;
  return (
    <LayoutOverlay
      closeLink={getParentPageHref(route)}
      route={{ ...route, name: "General barcodes generator" }}
      childrenOnly
      footer={
        <div className={styles.buttonsFooter}>
          <Button primary size="large">
            Save
          </Button>
        </div>
      }
    >
      <GenerateBarcodesForm />
    </LayoutOverlay>
  );
}

GeneralBarcodesGenerator.propTypes = {
  route: PropTypes.object.isRequired
};

GeneralBarcodesGenerator.defaultProps = {
  route: { name: "{%name%}" }
};

export default GeneralBarcodesGenerator;
