import { actionType } from "../../constants/TagBarcodes";

const initialState = {
  data: {
    content: [],
    number: 0,
    totalPages: 0,
    totalElements: 0,
    size: 10
  },
  series: [],
  isFetching: false,
  isGenerating: false,
  error: null
};

export default (state = initialState, action) => {
  if (!action) return state;

  switch (action.type) {
    case actionType.TAG_BARCODES_CLEAR_SERIES:
      return {
        ...initialState
      };
    case actionType.GENERATE_TAG_BARCODES_START:
      return {
        ...state,
        isGenerating: true
      };
    case actionType.GENERATE_TAG_BARCODES:
      return {
        ...state,
        series: action.payload,
        isGenerating: false
      };

    case actionType.GENERATE_TAG_BARCODES_FAIL:
      return {
        ...initialState,
        error: action.payload
      };
    case actionType.FETCH_SCANNED_TAG_BARCODES_START:
      return {
        ...state,
        isFetching: true
      };

    case actionType.FETCH_SCANNED_TAG_BARCODES:
      return {
        ...state,
        data: action.payload,
        isFetching: false
      };

    case actionType.FETCH_SCANNED_TAG_BARCODES_FAIL:
    case actionType.TAG_BARCODES_CLEAR_ERROR:
    case actionType.CLEAR_SCANNED_BARCODES_ERROR:
      return {
        ...state,
        error: null
      };

    default:
      return state;
  }
};
