import React from "react";
import classNames from "classnames";
import { Checkbox } from "semantic-ui-react";
import styles from "./Table.module.css";

const CustomCheckbox = React.forwardRef(
  ({ indeterminate, onChange, className, ...rest }, ref) => {
    return (
      <Checkbox
        {...rest}
        indeterminate={indeterminate}
        ref={ref}
        className={classNames(className, styles.checkbox)}
        onChange={e => {
          if (onChange) {
            e.stopPropagation();
            onChange(e);
          }
        }}
      />
    );
  }
);

export default CustomCheckbox;
