import { createSelector } from "reselect";

const harvestUnitsList = state => state.harvestUnits;
const boxUnitList = state => state.boxUnits;

export const harvestUnitOptionsSelector = createSelector(
  [harvestUnitsList],
  ({ data, isFetching }) => ({
    options: data.content.map(({ id, name, netWeight }) => ({
      key: id,
      label: `${name}/${netWeight}Kg`,
      value: id
    })),
    isFetching
  })
);
export const harvestUnitSelector = createSelector(
  [harvestUnitsList],
  ({ data, isFetching }) => ({
    options: data.content.map(({ id, name, netWeight }) => ({
      key: id,
      text: `${name}/${netWeight}Kg`,
      value: id
    })),
    isFetching
  })
);
export const boxUnitOptionsSelector = createSelector(
  [boxUnitList],
  ({ list, isFetching }) => ({
    options: list.content.map(({ id, displayName }) => ({
      key: id,
      label: displayName,
      value: id
    })),
    isFetching
  })
);
