import React, { useState, useEffect, useMemo } from "react";
import { Message, Transition, Grid, Button, Icon } from "semantic-ui-react";
import styles from "../ScoutingList.module.css";

const DURATION = 5000;

const TasksArchivedMessage = ({
  archivedItemsIds,
  onClose,
  onUndo,
  getPrefixFunction
}) => {
  const [visible, setVisible] = useState(false);

  const messageContent = useMemo(() => {
    if (!Array.isArray(archivedItemsIds)) {
      return null;
    }

    const text = archivedItemsIds.map(getPrefixFunction).join(", ");

    return `${text} ${archivedItemsIds.length === 1 ? "was" : "were"} archived`;
  }, [archivedItemsIds]);

  useEffect(() => {
    if (Array.isArray(archivedItemsIds)) {
      const id = setTimeout(() => {
        setVisible(false);
      }, DURATION);

      setVisible(true);
      return () => clearTimeout(id);
    } else {
      setVisible(false);
    }
  }, [archivedItemsIds]);

  useEffect(() => {
    if (!visible) {
      onClose();
    }
  }, [visible]);

  return (
    <div className={styles.deletedMessage}>
      <Message hidden={!visible} size="small" style={{ paddingBlock: 0 }}>
        <Grid style={{ padding: 0 }} verticalAlign="middle">
          <Grid.Column floated="left" width={10}>
            {messageContent}
          </Grid.Column>
          <Grid.Column floated="right" width={6} textAlign={"right"}>
            <button className={styles.undoButton} onClick={onUndo}>
              Undo
            </button>
            <Icon
              className={styles.closeButton}
              onClick={() => setVisible(false)}
              name={"close"}
              size={"big"}
            />
          </Grid.Column>
        </Grid>
      </Message>
    </div>
  );
};

export default TasksArchivedMessage;
