import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import A4_65_Labels_Legacy from "../../../CustomEmployeesBarcodesPrintLayout/layouts/A4_65_Labels_38mm_x_21mm.module.css";
import A4_65_Labels_EU30019 from "../../../CustomEmployeesBarcodesPrintLayout/layouts/A4_65_Labels_EU30019.module.css";
import page_100mm_40mm_4_Labels_37mm_22_3mm from "../../../CustomEmployeesBarcodesPrintLayout/layouts/page_100mm_40mm_4_Labels_37mm_22_3mm.module.css";
import page_78mm_16mm_3_labels_23mm_13mm from "../../../CustomEmployeesBarcodesPrintLayout/layouts/page_78mm_16mm_3_labels_23mm_13mm.module.css";
import page_80mm_40mm_1_label from "../../../CustomEmployeesBarcodesPrintLayout/layouts/page_80mm_40mm_1_label.module.css";
import page_80mm_80mm_1_label from "../../../CustomEmployeesBarcodesPrintLayout/layouts/page_80mm_80mm_1_label.module.css";
import page_70mm_60mm_1_label from "../../../CustomEmployeesBarcodesPrintLayout/layouts/page_70mm_60mm_1_label.module.css";
import page_40mm_28mm_1_label from "../../../CustomEmployeesBarcodesPrintLayout/layouts/page_40mm_28mm_1_label.module.css";
import CustomEmployeesBarcodesPrintLayout from "../../../CustomEmployeesBarcodesPrintLayout";
import EmployeesBarcodesPrintLayout from "../../../EmployeesBarcodesPrintLayout";
import { setPrintLayout } from "../../../../../actions/EmployeeBarcodes/employeeBarcodes";
import { Dropdown } from "semantic-ui-react";

export const PrintLayouts = {
  "A4 65 Labels - EU30019": {
    html: {
      render: employeeBarcodes => (
        <CustomEmployeesBarcodesPrintLayout
          employeeBarcodes={employeeBarcodes}
          itemsInPage={65}
          className={A4_65_Labels_EU30019.layout}
          pageStyle={"@page { size: A4 landscape;}"}
        />
      )
    }
  },
  "A4 65 Labels - Legacy": {
    html: {
      render: employeeBarcodes => (
        <CustomEmployeesBarcodesPrintLayout
          employeeBarcodes={employeeBarcodes}
          itemsInPage={65}
          className={A4_65_Labels_Legacy.layout}
          pageStyle={"@page { size: A4 landscape;}"}
        />
      )
    }
  },
  "78mm x 16mm 3 Labels (23mm x 13mm)": {
    html: {
      render: employeeBarcodes => (
        <CustomEmployeesBarcodesPrintLayout
          employeeBarcodes={employeeBarcodes}
          itemsInPage={3}
          className={page_78mm_16mm_3_labels_23mm_13mm.layout}
          pageStyle={"@page { size: landscape;}"}
        />
      )
    }
  },
  "100mm x 40mm 4 Labels (37mm x 22.3mm)": {
    html: {
      render: employeeBarcodes => (
        <CustomEmployeesBarcodesPrintLayout
          employeeBarcodes={employeeBarcodes}
          itemsInPage={4}
          className={page_100mm_40mm_4_Labels_37mm_22_3mm.layout}
          pageStyle={"@page { size: landscape;}"}
        />
      )
    }
  },
  "80mm x 40mm 1 Label": {
    html: {
      render: employeeBarcodes => (
        <CustomEmployeesBarcodesPrintLayout
          employeeBarcodes={employeeBarcodes}
          itemsInPage={1}
          className={page_80mm_40mm_1_label.layout}
          pageStyle={"@page { size: landscape;}"}
        />
      )
    }
  },
  "80mm x 80mm 1 Label": {
    html: {
      render: employeeBarcodes => (
        <CustomEmployeesBarcodesPrintLayout
          employeeBarcodes={employeeBarcodes}
          itemsInPage={1}
          className={page_80mm_80mm_1_label.layout}
          pageStyle={"@page { size: landscape;}"}
        />
      )
    }
  },
  "70mm x 60mm 1 Label": {
    html: {
      render: employeeBarcodes => (
        <CustomEmployeesBarcodesPrintLayout
          employeeBarcodes={employeeBarcodes}
          itemsInPage={1}
          className={page_70mm_60mm_1_label.layout}
          pageStyle={"@page { size: landscape;}"}
        />
      )
    }
  },
  "Legacy 100mm x 40mm 4 Labels (37mm x 22.3mm)": {
    html: {
      render: employeeBarcodes => (
        <EmployeesBarcodesPrintLayout employeeBarcodes={employeeBarcodes} />
      )
    },
    pdf: {
      // TODO: TBD, currently it not in use since we support only thine one, but it need to be change
      render: () => {}
    }
  },
  "40mm x 28mm 1 Labels": {
    html: {
      render: employeeBarcodes => (
        <CustomEmployeesBarcodesPrintLayout
          employeeBarcodes={employeeBarcodes}
          itemsInPage={1}
          className={page_40mm_28mm_1_label.layout}
          pageStyle={"@page { size: landscape;}"}
        />
      )
    }
  }
};

export const OPTIONS = Object.keys(PrintLayouts).map(layout => ({
  key: layout,
  text: layout,
  value: layout
}));

const SelectPrintLayout = ({
  printLayout,
  actions: { setPrintLayout },
  ...extraProps
}) => {
  return (
    <Dropdown
      {...extraProps}
      value={printLayout}
      placeholder="Select print layout"
      selection
      fluid
      options={OPTIONS}
      onChange={(_, data) => setPrintLayout(data.value)}
    />
  );
};

SelectPrintLayout.propTypes = {
  actions: PropTypes.object,
  printLayout: PropTypes.any
};
SelectPrintLayout.propTypes = {
  actions: PropTypes.object.isRequired,
  printLayout: PropTypes.string.isRequired
};

SelectPrintLayout.defaultProps = {};

const mapStateToProps = state => {
  const {
    employeesBarcodes: { printLayout }
  } = state;
  return {
    printLayout
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ setPrintLayout }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectPrintLayout);
