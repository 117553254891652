import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Dropdown, Form, Grid, Input } from "semantic-ui-react";
import { onlineSelector } from "../../../../selectors/common";
import {
  addAndSelectMachinery,
  clearMachinery,
  selectMachinery,
  setGear,
  setMachineryConc,
  setMachinerySpeed,
  setNozzle,
  setNozzlesPerSide,
  setPressure,
  setPto,
  setRpm
} from "../../../../actions/SprayDiary/machinery";
import AddMachineryModal from "./AddMachineryModal/AddMachineryModal";
import styles from "./sprayForm.module.css";

class SelectMachinery extends Component {
  state = {
    searchQuery: "",
    showMachineryModal: false
  };

  componentDidMount() {}

  get options() {
    const { machineryList, online, selectedMachinery } = this.props;
    const list = machineryList.content
      .filter(m => (m.archived ? m.id === selectedMachinery : true))
      .map(item => {
        return {
          key: item.id,
          text: `${item.name} ${item.size ? `(size ${item.size} L)` : ""}`,
          value: item.id
        };
      });
    if (online) {
      list.push({
        key: "new",
        text: "+ Add"
      });
    }

    return list;
  }

  onChangeMachinery = (event, data) => {
    event.preventDefault();
    event.stopPropagation();
    const { selectMachinery, clearMachinery } = this.props.actions;
    if (data.value === "") {
      return clearMachinery();
    }
    if (data.value !== undefined) {
      return selectMachinery(data.value);
    }
    this.setState({
      showMachineryModal: true
    });
  };

  handleCloseMachineryModal = () => {
    this.setState({
      showMachineryModal: false
    });
  };

  createMachinerySubmit = data => {
    const {
      actions: { andAndSelectMachinery }
    } = this.props;
    this.setState({
      showMachineryModal: false
    });
    andAndSelectMachinery(data);
  };

  changeSearchQuery = searchQuery => {
    this.setState({
      searchQuery
    });
  };

  render() {
    const { options } = this;
    const {
      actions: {
        setMachineryConc,
        setMachinerySpeed,
        setNozzle,
        setNozzlesPerSide,
        setGear,
        setRpm,
        setPressure,
        setPto
      },
      selectedMachinery,
      concValidation,
      machinerySpeedValidation,
      conc,
      machinerySpeed,
      nozzle,
      nozzleValidation,
      nozzlesPerSide,
      nozzlesPerSideValidation,
      gear,
      gearValidation,
      applicationType,
      rpm,
      rpmValidation,
      pressure,
      pressureValidation,
      pto,
      ptoValidation,
      machineryList,
      online
    } = this.props;
    const { showMachineryModal, searchQuery } = this.state;
    return (
      <>
        <AddMachineryModal
          open={showMachineryModal}
          onClose={this.handleCloseMachineryModal}
          onSubmit={this.createMachinerySubmit}
        />
        <Grid className={styles.machineryHolder}>
          <Grid.Row>
            <Grid.Column widescreen={5} largeScreen={5} mobile={16} tablet={16}>
              <Form.Field className="sprayField">
                <label className="title">Machinery</label>
                <Dropdown
                  key={showMachineryModal}
                  search
                  searchQuery={searchQuery}
                  clearable
                  onClose={() => this.changeSearchQuery("")}
                  onSearchChange={(_, { searchQuery }) =>
                    this.changeSearchQuery(searchQuery)
                  }
                  closeOnChange
                  noResultsMessage="Nothing was found"
                  fluid
                  value={selectedMachinery}
                  placeholder="Do you need to use a machine?"
                  loading={machineryList.isFetching && online}
                  options={options}
                  onChange={this.onChangeMachinery}
                  selection
                />
              </Form.Field>
            </Grid.Column>
            {selectedMachinery && applicationType === "SPRAY" && (
              <>
                <Grid.Column
                  widescreen={3}
                  largeScreen={3}
                  mobile={8}
                  tablet={8}
                >
                  <Form.Field className="sprayField">
                    <label className="title">Nozzle</label>
                    <Input
                      fluid
                      error={!nozzleValidation}
                      value={nozzle || ""}
                      onChange={e => setNozzle(e.target.value)}
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column
                  widescreen={3}
                  largeScreen={3}
                  mobile={8}
                  tablet={8}
                >
                  <Form.Field className="sprayField">
                    <label className="title">Nozzles per side</label>
                    <Input
                      fluid
                      error={!nozzlesPerSideValidation}
                      type="number"
                      placeholder="All"
                      value={nozzlesPerSide || ""}
                      onChange={e => setNozzlesPerSide(e.target.value)}
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column
                  widescreen={2}
                  largeScreen={2}
                  mobile={8}
                  tablet={8}
                >
                  <Form.Field className="sprayField">
                    <label className="title">Gear</label>
                    <Input
                      fluid
                      error={!gearValidation}
                      value={gear || ""}
                      onChange={e => setGear(e.target.value)}
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column
                  widescreen={2}
                  largeScreen={2}
                  mobile={8}
                  tablet={8}
                >
                  <Form.Field className="sprayField">
                    <label className="title">RPM</label>
                    <Input
                      fluid
                      error={!rpmValidation}
                      value={rpm || ""}
                      onChange={e => setRpm(e.target.value)}
                    />
                  </Form.Field>
                </Grid.Column>
              </>
            )}
          </Grid.Row>
          {selectedMachinery && applicationType === "SPRAY" && (
            <Grid.Row>
              <Grid.Column widescreen={3} largeScreen={3} mobile={8} tablet={8}>
                <Form.Field className="sprayField">
                  <label className="title">Pressure</label>
                  <Input
                    fluid
                    error={!pressureValidation}
                    value={pressure || ""}
                    type="number"
                    onChange={e => setPressure(e.target.value)}
                    label={{ content: "psi", inverted: "true" }}
                    labelPosition="right"
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column
                widescreen={3}
                largeScreen={3}
                mobile={8}
                tablet={8}
                className={styles.speedColumn}
              >
                <Form.Field className="sprayField">
                  <label className="title">Speed</label>
                  <Input
                    fluid
                    error={!machinerySpeedValidation}
                    value={machinerySpeed || ""}
                    type="number"
                    onChange={e => setMachinerySpeed(e.target.value)}
                    label={{ content: "km/h", inverted: "true" }}
                    labelPosition="right"
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column widescreen={2} largeScreen={2} mobile={8} tablet={8}>
                <Form.Field className="sprayField">
                  <label className="title">PTO</label>
                  <Input
                    error={!ptoValidation}
                    fluid
                    value={pto || ""}
                    type="number"
                    onChange={e => setPto(e.target.value)}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column widescreen={2} largeScreen={2} mobile={8} tablet={8}>
                <Form.Field required className="sprayField">
                  <label className="title">Concentration/(x)</label>
                  <Input
                    error={!concValidation}
                    fluid
                    value={conc}
                    type="number"
                    onChange={e => setMachineryConc(e.target.value)}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </>
    );
  }
}

const mapStateToProps = state => {
  const {
    sprayDiary: {
      machineryList,
      status,
      conc,
      machinerySpeed,
      nozzle,
      nozzlesPerSide,
      gear,
      rpm,
      pressure,
      pto,
      applicationType,
      selectedMachinery: { id },
      validations: {
        selectedMachinery: machineryValidation,
        conc: concValidation,
        machinerySpeed: machinerySpeedValidation,
        nozzle: nozzleValidation,
        nozzlesPerSide: nozzlesPerSideValidation,
        gear: gearValidation,
        rpm: rpmValidation,
        pressure: pressureValidation,
        pto: ptoValidation
      }
    }
  } = state;

  return {
    machineryList,
    conc,
    machinerySpeed,
    nozzle,
    nozzlesPerSide,
    gear,
    rpm,
    pressure,
    pto,
    applicationType,
    status,
    selectedMachinery: id || "",
    machineryValidation,
    concValidation,
    machinerySpeedValidation,
    online: onlineSelector(state),
    nozzleValidation,
    nozzlesPerSideValidation,
    gearValidation,
    rpmValidation,
    pressureValidation,
    ptoValidation
  };
};

SelectMachinery.propTypes = {
  machineryList: PropTypes.array,
  online: PropTypes.bool,
  machinerySpeedValidation: PropTypes.bool,
  concValidation: PropTypes.bool,
  nozzleValidation: PropTypes.bool,
  gearValidation: PropTypes.bool,
  conc: PropTypes.bool,
  rpmValidation: PropTypes.bool,
  ptoValidation: PropTypes.bool,
  pressureValidation: PropTypes.bool,
  nozzlesPerSideValidation: PropTypes.bool,
  actions: PropTypes.object,
  clearMachinery: PropTypes.func,
  selectedMachinery: PropTypes.object,
  machinerySpeed: PropTypes.number,
  nozzle: PropTypes.string,
  nozzlesPerSide: PropTypes.string,
  gear: PropTypes.string,
  rpm: PropTypes.string,
  pto: PropTypes.string,
  pressure: PropTypes.string,
  isPreviouslyCreatedTask: PropTypes.bool
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      selectMachinery,
      clearMachinery,
      setMachineryConc,
      setMachinerySpeed,
      setNozzle,
      setNozzlesPerSide,
      setGear,
      setRpm,
      setPressure,
      setPto,
      andAndSelectMachinery: addAndSelectMachinery
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectMachinery);
