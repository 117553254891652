import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MultiSelectFilter from "./MultiSelectFilter/MultiSelectFilter";
import PropTypes from "prop-types";
import { fetchSubTaskNames } from "../../../actions/GeneralTaskCreator/taskCreator";

const GeneralSubTaskNameMultiSelectFilter = ({
  actions: { fetchList },
  list,
  isFetching,
  ...extraProps
}) => {
  useEffect(() => {
    fetchList();
  }, [fetchList]);

  const options = useMemo(
    () => list.map((item, i) => ({ key: item, text: item, value: i })),
    [list]
  );

  return (
    <MultiSelectFilter
      options={options}
      numbers={true}
      loading={isFetching}
      {...extraProps}
    />
  );
};

const mapStateToProps = ({
  taskCreator: {
    subTaskNames: { content, isFetching }
  }
}) => {
  return {
    list: content,
    isFetching
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchList: fetchSubTaskNames }, dispatch)
});

GeneralSubTaskNameMultiSelectFilter.propTypes = {
  isFetching: PropTypes.bool,
  list: PropTypes.array,
  actions: PropTypes.object
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralSubTaskNameMultiSelectFilter);
