import retry from "@redux-offline/redux-offline/lib/defaults/retry";
import { createSelector } from "reselect";
export const GENERIC_VALUE = -10000000;

const crops = state => state.crops;

const cropsSelector = createSelector([crops], selectCrop => {
  const { data } = selectCrop;
  return (
    data &&
    [{ id: GENERIC_VALUE, name: "Generic" }, ...data]
      .map(crop => ({
        key: crop.id,
        text: crop.name,
        value: crop.id
      }))
      .sort(function(a, b) {
        return a.text == b.text ? 0 : a.text < b.text ? -1 : 1;
      })
  );
});

export const cropsOptionsSelector = createSelector(
  [crops],
  ({ data, isFetching }) => ({
    options: data.map(({ id, name }) => ({
      key: id,
      label: name,
      value: id
    })),
    isFetching
  })
);

export { cropsSelector };
