import { createSelector } from "reselect";
import _ from "lodash";
import {
  ALT_LABOUR_CONTRACTOR_TYPE,
  CONTRACTOR_TYPE
} from "constants/GeneralTasks/costEntering";

const activeTask = (state, props) =>
  props && props.activeTask ? props.activeTask : null;

const tasksSelector = (state, props) =>
  props && props.generalTasks ? props.generalTasks.data.content : null;

export const costTypes = state => state.costEntering.data;
export const subTypes = state => state.costEntering.types;
export const taskNames = state => state.generalTasks.taskNames;
export const taskTypesList = state => state.taskCreator.taskTypes.content;
export const taskList = state => state.generalTasks.list;

export const activeTaskSelector = createSelector(
  [tasksSelector, activeTask],
  (selectTasks, selectActiveTask) => {
    return selectActiveTask && selectTasks
      ? selectTasks.find(
          task =>
            task.id === Number(selectActiveTask) ||
            task._id === selectActiveTask
        )
      : null;
  }
);

export const generalTaskOptionsSelector = createSelector(
  [taskTypesList],
  types =>
    types
      ? types.map(({ id, name }) => ({
          key: id,
          text: name,
          value: name
        }))
      : []
);
export const generalTasksOptionsSelector = createSelector([taskList], types => {
  return types.content.length
    ? types.content.map(({ id, name }) => ({
        key: id,
        text: `GT-${id}`,
        value: id
      }))
    : [];
});
export const generalTasksFilterOptions = createSelector([taskList], types => {
  return {
    options: types.content.length
      ? types.content.map(({ id, name }) => ({
          key: id,
          label: `GT-${id}`,
          value: id
        }))
      : []
  };
});
export const costTypeFilterOptions = createSelector(
  [costTypes],
  ({ content }) => ({
    options: CONTRACTOR_TYPE
      ? CONTRACTOR_TYPE.map(({ key, text, value }) => ({
          key,
          label: text,
          value
        }))
      : []
  })
);

export const generalTasksOptions = createSelector([taskList], types => {
  return {
    options: types.content.length
      ? types.content.map(({ id, name }) => ({
          key: id,
          text: `GT-${id} ${name}`,
          value: id
        }))
      : []
  };
});

export const altLabourCostTypeFilterOptions = createSelector(
  [costTypes],
  ({ content }) => ({
    options: ALT_LABOUR_CONTRACTOR_TYPE
      ? ALT_LABOUR_CONTRACTOR_TYPE.map(({ key, text, value }) => ({
          key,
          label: text,
          value
        }))
      : []
  })
);
export const activityFilterOptions = createSelector([taskTypesList], types => ({
  options: types
    ? types.map(({ id, name }) => ({
        key: id,
        label: name,
        value: id
      }))
    : []
}));
export const activityOptions = createSelector([taskList], ({ content }) =>
  content
    ? Array.from(
        new Set(
          content
            .map(({ type }) => ({
              key: type.id,
              text: type.name,
              value: type.id
            }))
            .map(s => s.key)
        )
      ).map(key => ({
        key: key,
        value: content.find(({ type }) => type.id === key).type.name,
        text: content.find(({ type }) => type.id === key).type.name
      }))
    : []
);

export const subTypeSelector = createSelector([subTypes], ({ content }) =>
  content
    ? content
        .filter(item => item != "")
        .map(item => ({
          key: item,
          text: (
            item
              .toLowerCase()
              .charAt(0)
              .toUpperCase() + item.toLowerCase().slice(1)
          ).replace(/\s/g, "_"),
          value: item
        }))
    : []
);
