import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Dropdown, Form, Grid } from "semantic-ui-react";
import { map } from "lodash";

import { useFormikContext } from "formik";

const AssigneesDropdown = ({ employeesData, fieldName }) => {
  const { content: employees, isFetching } = employeesData;
  const {
    values,
    setFieldValue,
    errors,
    touched,
    setFieldTouched
  } = useFormikContext();
  const value = map(values.assignees, ({ employeeId }) => employeeId);
  const transformedEmployee = map(employees, content => ({
    key: content.id,
    text: `${content.firstName}  ${content.lastName}${
      content.archived ? " (archived)" : ""
    }`,
    value: content.id
  }));

  const onChange = (_, { value }) => {
    setFieldValue(
      "assignees",
      value.map(employeeId => ({
        employeeId
      }))
    );
    setFieldTouched(
      "assignees",
      value.map(employeeId => ({
        employeeId
      }))
    );
  };

  return (
    <Dropdown
      closeOnBlur
      closeOnChange
      search
      multiple
      loading={isFetching}
      placeholder="Select employee"
      selection
      fluid
      noResultsMessage="Nothing was found"
      value={value}
      options={transformedEmployee}
      onChange={onChange}
      disabled={isFetching}
      name={fieldName}
      error={touched[fieldName] && errors[fieldName]}
      onBlur={() => setFieldTouched(fieldName, true)}
    />
  );
};

AssigneesDropdown.defaultProps = {
  disabled: false
};

AssigneesDropdown.propTypes = {
  employee: PropTypes.object,
  fieldName: PropTypes.string,
  type: PropTypes.string,
  actions: PropTypes.object,
  validation: PropTypes.bool,
  multiple: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  showArchived: PropTypes.bool,
  online: PropTypes.bool,
  selectedEmployees: PropTypes.array
};

const mapStateToProps = (state, props) => {
  const { sprayDiary } = state;
  return {
    employeesData: sprayDiary.employee,
    selectedEmployees: sprayDiary[props.fieldName],
    validation:
      typeof sprayDiary.validations[props.fieldName] !== "undefined"
        ? sprayDiary.validations[props.fieldName]
        : true
  };
};

export default connect(mapStateToProps)(AssigneesDropdown);
