import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import styles from "./SprayAreaReport.module.css";
import Numeric from "../../../../components/Numeric";
import moment from "moment";

const getCompletedDate = record => {
  if (!record.workingTimes || record.workingTimes.length === 0) {
    return null;
  }

  return record.workingTimes
    .map(wt => wt.substring(0, 10))
    .sort((wt1, wt2) => wt2.localeCompare(wt1))[0];
};

const getEarliestPickDate = taskRecords => {
  const completeDate = getCompletedDate(taskRecords[0]);
  const whp = Math.max(
    ...taskRecords.map(record => record.withholdingPeriod || 0)
  );
  return !completeDate
    ? null
    : moment(completeDate)
        .add(whp, "days")
        .format("YYYY-MM-DD");
};

const SprayAreaReport = ({ data }) => {
  const byTaskRecords = _.groupBy(data, item => `${item.id}_${item.area}`);
  const orderedByTaskRecords = Object.keys(byTaskRecords)
    .map(key => byTaskRecords[key])
    .sort((t1, t2) => t1[0].plannedDate.localeCompare(t2[0].plannedDate));
  return (
    <div className={styles.block}>
      <style>{`@page { size: landscape;}`}</style>
      <div className={styles.page}>
        <table
          width="100%"
          rules="groups"
          cellSpacing="5"
          cellPadding="5"
          className={styles.table}
        >
          <thead>
            <tr>
              <th className={styles.header}>Completed date</th>
              <th className={styles.header}>Area</th>
              <th className={styles.header}>Variety</th>
              <th className={styles.header}>Earliest pick date</th>
              <th className={styles.header}>Chemical</th>
              <th className={styles.header}>Purpose</th>
              <th className={styles.header}>Active ingredient</th>
              <th className={styles.header}>WHP</th>
              <th className={styles.header}>Conc(X)</th>
              <th className={styles.header}>Rate/100L</th>
              <th className={styles.header}>Rate/hectares</th>
              <th className={styles.header}>Litre pre hectares</th>
            </tr>
          </thead>
          {orderedByTaskRecords.map(taskRecords => (
            <tbody key={`task_${taskRecords[0].id}`} className={styles.tbody}>
              <>
                {taskRecords.map((record, index) => (
                  <>
                    <tr>
                      {index === 0 && (
                        <td rowSpan={taskRecords.length}>
                          <div>{index === 0 && getCompletedDate(record)}</div>
                          <div>Id: {index === 0 && record.id}</div>
                          <div>{index === 0 && record.status}</div>
                        </td>
                      )}
                      <td>{index === 0 && record.area}</td>
                      <td>{index === 0 && record.variety}</td>
                      <td>{index === 0 && getEarliestPickDate(taskRecords)}</td>
                      <td>{record.chemical}</td>
                      <td>{record.purpose}</td>
                      <td>{record.activeIngredient}</td>
                      <td>{record.withholdingPeriod}</td>
                      <td>{record.conc}</td>
                      <td>
                        <Numeric value={record.ratePer100L} />
                      </td>
                      <td>
                        <Numeric value={record.ratePerHectare} />
                      </td>
                      <td>
                        <Numeric
                          value={record.literPerHectare}
                          fractionDigits={0}
                        />
                      </td>
                    </tr>
                  </>
                ))}
                <tr>
                  <td colSpan={11}>
                    <div className={styles.moreInfoContainer}>
                      <div className={styles.moreInfoItem}>
                        Working times:{" "}
                        <div className={styles.multiItemsContainer}>
                          {taskRecords[0].workingTimes.map(workingTime => (
                            <div
                              key={workingTime}
                              className={styles.multiItemsItem}
                            >
                              {workingTime}
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className={styles.moreInfoItem}>
                        <div>Wind speed: {taskRecords[0].windStrength}</div>
                        <div>
                          Wind Direction: {taskRecords[0].windDirection}
                        </div>
                        <div></div>
                      </div>
                      <div className={styles.moreInfoItem}>
                        Temperature: {taskRecords[0].temperature}
                      </div>
                      <div className={styles.moreInfoItem}>
                        Supervisors:{" "}
                        <div className={styles.multiItemsContainer}>
                          {taskRecords[0].supervisors.map(supervisors => (
                            <div
                              key={supervisors}
                              className={styles.multiItemsItem}
                            >
                              {supervisors}
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className={styles.moreInfoItem}>
                        Operators:{" "}
                        <div className={styles.multiItemsContainer}>
                          {taskRecords[0].assignees.map(assignees => (
                            <div
                              key={assignees}
                              className={styles.multiItemsItem}
                            >
                              {assignees}
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className={styles.moreInfoItem}>
                        Machinery: {taskRecords[0].machinery}
                      </div>
                      <div className={styles.moreInfoItem}>
                        Comment: {taskRecords[0].comment}
                      </div>
                    </div>
                  </td>
                </tr>
              </>
            </tbody>
          ))}
        </table>
      </div>
    </div>
  );
};

SprayAreaReport.propTypes = { data: PropTypes.array.isRequired };

SprayAreaReport.defaultProps = {};

export default SprayAreaReport;
