import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

export const excelExport = (rawData, headings, filename = "excel") => {
  var wb = XLSX.utils.book_new();
  var ws = XLSX.utils.json_to_sheet(rawData, {
    origin: "A2",
    skipHeader: true
  });
  XLSX.utils.sheet_add_aoa(ws, [headings]);
  XLSX.utils.book_append_sheet(wb, ws);
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, filename + fileExtension);
};
