import React, { useState } from "react";
import { Form, Segment, Input, Container } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import moment from "moment-timezone";
import { Field, useFormikContext } from "formik";
import { get, map } from "lodash";
import { uploadAttachment } from "actions/Chemicals";
import CollapsibleBlock from "components/CollapsibleBlock";
import ObjectId from "bson-objectid";
import FileChooser from "components/Attachments/FileChooser";
import AttachmentLabel from "components/Attachments/AttachmentLabel";
import { FERTILISER } from "constants/Chemicals/types";
import styles from "../InventoryModal.module.css";
import DatePicker from "components/DatePicker/DatePicker";

const AdditionalInformationEditSection = ({ editSectionIndex }) => {
  const { values, setFieldValue, setValues } = useFormikContext();
  const [loadingAttachments, setLoadingAttachments] = useState([]);
  const isFertiliser = get(values.type, "type") === FERTILISER;
  const [permitExpDate, setPermitExpDate] = useState();
  const dispatch = useDispatch();
  const onAttachmentSelected = (fieldName, multi = false) => files => {
    setLoadingAttachments(attachments => [...attachments, fieldName]);
    files.forEach(file => {
      const dataToSend = {
        file,
        idReplace: ObjectId().toHexString(),
        callBack: loadedFile => {
          setFieldValue(
            fieldName,
            !multi
              ? loadedFile
              : values[fieldName]
              ? [...values[fieldName], loadedFile]
              : [loadedFile]
          );
          setLoadingAttachments(attachments =>
            attachments.filter(i => i !== fieldName)
          );
        }
      };
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onloadend = () => {
        dispatch(uploadAttachment(dataToSend));
      };
    });
  };

  const onDeleteAttachment = fieldName => _ => {
    setFieldValue(fieldName, null);
  };

  return (
    <Segment>
      <CollapsibleBlock
        className={styles.root}
        defaultActive={!editSectionIndex || editSectionIndex === 5}
        title="Additional Information"
      >
        <Form>
          <Form.Group>
            <Form.Field width={7}>
              <label>Label link</label>
              <Field
                as={Input}
                name="label"
                placeholder="https://Label.com"
                disabled={!!values.labelAttachment}
              />
              <Container fluid className={styles.textButtonContainer}>
                {values.labelAttachment && (
                  <AttachmentLabel
                    attachment={values.labelAttachment}
                    onDeleteClick={onDeleteAttachment("labelAttachment")}
                    className={styles.attachmentLabel}
                    withDownloadfromEdit={
                      values.labelAttachment.online ? false : true
                    }
                  />
                )}

                {!values.labelAttachment && (
                  <FileChooser
                    onChange={onAttachmentSelected("labelAttachment")}
                    label="Attach Label"
                    disabled={
                      !!values.label ||
                      loadingAttachments.includes("labelAttachment")
                    }
                  />
                )}
              </Container>
            </Form.Field>

            <Form.Field width={2} />
            <Form.Field width={7}>
              <label>SDS link</label>
              <Field
                as={Input}
                width={7}
                name="sds"
                disabled={!!values.sdsAttachment}
                placeholder="https://SDS.com"
              />
              <Container fluid className={styles.textButtonContainer}>
                {values.sdsAttachment && (
                  <AttachmentLabel
                    attachment={values.sdsAttachment}
                    onDeleteClick={onDeleteAttachment("sdsAttachment")}
                    className={styles.attachmentLabel}
                    withDownloadfromEdit={
                      values.sdsAttachment.online ? false : true
                    }
                  />
                )}

                {!values.sdsAttachment && (
                  <FileChooser
                    onChange={onAttachmentSelected("sdsAttachment")}
                    label="SDS Label"
                    disabled={
                      !!values.sds ||
                      loadingAttachments.includes("sdsAttachment")
                    }
                  />
                )}
              </Container>
            </Form.Field>
          </Form.Group>
          {!isFertiliser && (
            <Form.Group>
              <Field
                as={Form.Input}
                width={7}
                name="chemicalUserPermitNumber"
                label="Chemical user permit No"
              />
              <Form.Field width={2} />
              <Form.Field width={7}>
                <label>Chemical user permit exp.date</label>
                <DatePicker
                  name="permitExpDate"
                  trigger={false}
                  focused={false}
                  value={values.permitExpDate}
                  onChange={permitExpDate => {
                    return (
                      setPermitExpDate(permitExpDate),
                      setValues({
                        ...values,
                        permitExpDate: moment(permitExpDate)
                          .add(1, "days")
                          .utc()
                          .format("YYYY-MM-DDTHH:mm:ss")
                      })
                    );
                  }}
                  disableClear
                />
              </Form.Field>
            </Form.Group>
          )}
          <Form.Group>
            <Field
              placeholder="You can leave some notes here"
              as={Form.TextArea}
              width={16}
              name="notes"
              label="Notes"
            />
          </Form.Group>
          <Container fluid className={styles.generalAttachmentsContainer}>
            {map(values.attachments, (attachment, index) => (
              <AttachmentLabel
                attachment={attachment}
                withDownloadfromEdit={attachment.online ? false : true}
                onDeleteClick={() => {
                  setFieldValue(
                    "attachments",
                    values.attachments.filter((_, i) => i !== index)
                  );
                }}
              />
            ))}

            <FileChooser
              className={styles.generalAttachmentsButton}
              onChange={onAttachmentSelected("attachments", true)}
              label="Attach files for additional information"
              disabled={loadingAttachments.includes("attachments")}
            />
          </Container>
        </Form>
      </CollapsibleBlock>
    </Segment>
  );
};

export default AdditionalInformationEditSection;
