import moment from "moment";
import { axiosProxy } from "utils/axiosProxy";
import { actionTypes } from "constants/Reports/harvest/contractorReport";
import { omitBy, isNil } from "lodash";
import uuid from "uuid/v4";

let uniqId;
const url = "/reporting_module/generalBarcodes/contractorReport";
const url1 = "/reporting_module/packedBoxes/contractorReport";

export const fetchContractorReportTableData = ({
  page,
  size,
  search,
  sort = ["id,desc"],
  unpaged = false,
  filters
}) => async dispatch => {
  try {
    dispatch({ type: actionTypes.FETCH_CONTRACTOR_REPORT_TABLE_DATA_START });
    const paramsObject = omitBy(
      {
        search,
        sort,
        page,
        size
      },
      isNil
    );
    const params = new URLSearchParams(paramsObject);

    if (Array.isArray(filters)) {
      filters.forEach(([filter, value]) => {
        const from = value.slice(0, 10);
        const to = value.slice(-10);
        return filter !== "seasons"
          ? params.append(filter, value)
          : (params.append("from", `${from}T00:00:00`),
            params.append("to", `${to}T23:59:59`));
      });
    }
    uniqId = uuid();
    const res = await axiosProxy({
      method: "GET",
      params,
      uniqId,
      url: `${url}`
    });
    if (uniqId === res.config.headers["X-REQUEST-ID"]) {
      dispatch({
        type: actionTypes.FETCH_CONTRACTOR_REPORT_TABLE_DATA,
        payload: res.data
      });
    }
  } catch (error) {
    dispatch({
      type: actionTypes.FETCH_CONTRACTOR_REPORT_TABLE_DATA_FAILED,
      payload: error
    });
  }
};

export const fetchContractorReportExportData = ({
  unpaged = true,
  filters
}) => async dispatch => {
  try {
    dispatch({
      type: actionTypes.FETCH_CONTRACTOR_REPORT_TABLE_LIST_START
    });
    const paramsObject = omitBy(
      {
        unpaged: true
      },
      isNil
    );
    const params = new URLSearchParams(paramsObject);
    if (Array.isArray(filters)) {
      filters.forEach(([filter, value]) => {
        const from = value.slice(0, 10);
        const to = value.slice(-10);
        return filter !== "seasons"
          ? params.append(filter, value)
          : (params.append("from", `${from}T00:00:00`),
            params.append("to", `${to}T23:59:59`));
      });
    }
    const res = await axiosProxy({
      url: `${url}`,
      params,
      method: "GET"
    });
    dispatch({
      type: actionTypes.SET_CONTRACTOR_REPORT_TABLE_LIST,
      payload: res.data
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: actionTypes.FETCH_CONTRACTOR_REPORT_TABLE_LIST_FAILED,
      payload: error
    });
  }
};

export const fetchPickToPackPickerReportTableData = ({
  page,
  size,
  search,
  sort = ["id,desc"],
  unpaged = false,
  filters
}) => async dispatch => {
  try {
    dispatch({ type: actionTypes.FETCH_CONTRACTOR_REPORT_TABLE_DATA_START });
    const paramsObject = omitBy(
      {
        search,
        sort,
        page,
        size
      },
      isNil
    );
    const params = new URLSearchParams(paramsObject);

    if (Array.isArray(filters)) {
      filters.forEach(([filter, value]) => {
        const from = value.slice(0, 10);
        const to = value.slice(-10);
        return filter !== "seasons"
          ? params.append(filter, value)
          : (params.append("from", `${from}T00:00:00`),
            params.append("to", `${to}T23:59:59`));
      });
    }
    uniqId = uuid();
    const res = await axiosProxy({
      method: "GET",
      params,
      uniqId,
      url: `${url1}`
    });
    if (uniqId === res.config.headers["X-REQUEST-ID"]) {
      dispatch({
        type: actionTypes.FETCH_CONTRACTOR_REPORT_TABLE_DATA,
        payload: res.data
      });
    }
  } catch (error) {
    dispatch({
      type: actionTypes.FETCH_CONTRACTOR_REPORT_TABLE_DATA_FAILED,
      payload: error
    });
  }
};

export const fetchPickToPackPickerReportExportData = ({
  unpaged = true,
  filters
}) => async dispatch => {
  try {
    dispatch({
      type: actionTypes.FETCH_CONTRACTOR_REPORT_TABLE_LIST_START
    });
    const paramsObject = omitBy(
      {
        unpaged: true
      },
      isNil
    );
    const params = new URLSearchParams(paramsObject);
    if (Array.isArray(filters)) {
      filters.forEach(([filter, value]) => {
        const from = value.slice(0, 10);
        const to = value.slice(-10);
        return filter !== "seasons"
          ? params.append(filter, value)
          : (params.append("from", `${from}T00:00:00`),
            params.append("to", `${to}T23:59:59`));
      });
    }
    const res = await axiosProxy({
      url: `${url1}`,
      params,
      method: "GET"
    });
    dispatch({
      type: actionTypes.SET_CONTRACTOR_REPORT_TABLE_LIST,
      payload: res.data
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: actionTypes.FETCH_CONTRACTOR_REPORT_TABLE_LIST_FAILED,
      payload: error
    });
  }
};
