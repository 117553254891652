import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Grid, Header, Form } from "semantic-ui-react";
import { uploadAttachment } from "actions/Recommendations/recommendations";
import Attachments from "components/Attachments";
import { FastField, useFormikContext } from "formik";
import styles from "../Sidebar.module.css";

const AdditionalInformation = props => {
  const { values, setFieldValue } = useFormikContext();
  const { attachments } = values;
  const { id, actions, _id } = props;

  const onUploadAttachment = useCallback(
    async ({ file, data, idReplace, callBack }) => {
      const result = await actions.uploadAttachment({
        file,
        callBack,
        idReplace,
        data,
        parentId: id || _id
      });

      if (result && result.id) {
        const newAttachment = {
          id: result.id,
          name: result.name,
          subPath: result.subPath,
          data
        };

        setFieldValue(
          "attachments",
          Array.isArray(attachments)
            ? [...attachments, newAttachment]
            : [newAttachment]
        );
      }
    },
    [attachments, id, _id]
  );

  const onDeleteAttachment = useCallback(
    attachmentId => {
      setFieldValue(
        "attachments",
        attachments.filter(
          a => a.id !== attachmentId && a.idReplace !== attachmentId
        )
      );
    },
    [attachments]
  );

  return (
    <React.Fragment>
      <Header>Additional Information</Header>
      <Grid.Row>
        <Grid.Column className="spraySection">
          <Attachments
            attachments={attachments}
            onDelete={onDeleteAttachment}
            onFileSelected={onUploadAttachment}
            multiply
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Form.Field>
            <FastField
              title="Comments"
              rows={5}
              as={Form.TextArea}
              name="comment"
              className={styles.textarea}
            />
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
    </React.Fragment>
  );
};

AdditionalInformation.propTypes = {
  id: PropTypes.number,
  _id: PropTypes.number,
  attachments: PropTypes.array,
  online: PropTypes.bool,
  comment: PropTypes.string,
  actions: PropTypes.object
};

const mapStateToProps = state => {
  const {
    sprayDiary: { id, _id }
  } = state;
  return {
    id,
    _id
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      uploadAttachment
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdditionalInformation);
