import React, { useState, useMemo, memo } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Table, Checkbox, Input } from "semantic-ui-react";
import _, { debounce } from "lodash";
import uuid from "uuid/v4";
import {
  setAreaDoneState,
  setAreasDoneState,
  setAllAreasDoneState,
  setAreaValue,
  updateAreasList
} from "../../../../actions/SprayDiary/areas";
import { setSprayDiaryField } from "../../../../actions/SprayDiary/common";
import Numeric from "../../../../components/Numeric";
import { Area } from "../../../../models/block.model";
import { calcTotalHectare } from "../../../../utils/tasksUtils";
import { matterToUnits } from "utils/constToUnits";
import { calculateChemicalQuantity } from "utils/chemicalBreakdownUtils";
import { abilitiesPairsSelector } from "selectors/user";
import styles from "./AreasTable.module.css";
import { parentBlocksSelector } from "../../../../selectors/sprayDiary";
import { unitsSelector } from "selectors/chemicals";

const debounceUpdate = debounce(updateTask => {
  updateTask();
}, 1000);

const AreasTable = ({
  sprayTask: {
    selectedAreas,
    selectedMachinery: machinery,
    selectedChemicals,
    rowsToSpray,
    widthPerRow,
    literPerHectare,
    conc
  },
  areasList: { blocks, patches },
  showParentBlocks,
  hideCheckBoxes,
  updateTask
}) => {
  const dispatch = useDispatch();
  const userAbilities = useSelector(abilitiesPairsSelector);
  const { totalTanks, plannedDate } = useSelector(state => state.sprayDiary);
  const [usedVolumeKey, setUsedVolumeKey] = useState();
  const [totalUsedVolumeKey, setTotalUsedVolumeKey] = useState();
  const [usedTanksKey, setUsedTanksKey] = useState();
  const [totalUsedTanksKey, setTotalUsedTanksKey] = useState();
  const shouldDisableDoneCheckbox = userAbilities.cannot(
    "update_done",
    "spray_tasks"
  );
  const allDone = selectedAreas.every(area => area.done);
  const someDone = selectedAreas.some(area => area.done);
  const totalHectares = calcTotalHectare(selectedAreas);
  const selectedMachinery = machinery && machinery.id ? machinery : null;
  const machineryLitersSize = selectedMachinery ? selectedMachinery.size : 0;
  const parentBlocks = useSelector(parentBlocksSelector);
  const listOfBlocks = {};
  const arrayOfAllParentBlocks = Object.values(parentBlocks);
  const selectedAreasIndexed = Object.fromEntries(
    selectedAreas.map(area => [area.id, area])
  );

  const { content, isFetching } = useSelector(unitsSelector);
  const multiplier = item => {
    const foundElement = content.find(({ id }) => id === item);

    if (foundElement) {
      return 1 / foundElement.multiplicationFactor;
    }

    return 1;
  };

  arrayOfAllParentBlocks.forEach(area => {
    const selectedPatchesIDs = _.intersection(
      area.areaIDs,
      selectedAreas.map(area => area.id)
    );
    if (
      !_.isEmpty(area.areaIDs) &&
      _.isEqual(area.areaIDs, selectedPatchesIDs)
    ) {
      listOfBlocks[area.id] = {
        id: area.id,
        actualTanks: area.areaIDs
          .map(
            id =>
              selectedAreasIndexed[id].actualTanks ||
              selectedAreasIndexed[id].tanks
          )
          .reduce((a, b) => (a && b ? parseFloat(a) + parseFloat(b) : null), 0),
        name: area.name,
        size: area.areaSize.reduce((a, b) => a + b, 0),
        areaIDs: area.areaIDs,
        varietiesList: [],
        plannedLiters: 0,
        done: selectedAreas
          .filter(a => selectedPatchesIDs.includes(a.id))
          .every(a => a.done),
        tanks: 0
      };
    }
  });

  const arrayOfBlocks = Object.values(listOfBlocks);

  arrayOfBlocks.map(b => {
    b.areaIDs.forEach(id => {
      listOfBlocks[b.id].plannedLiters +=
        selectedAreasIndexed[id].plannedLiters;
      listOfBlocks[b.id].tanks += selectedAreasIndexed[id].tanks;
      listOfBlocks[b.id].varietiesList.push(
        `${selectedAreasIndexed[id].varieties.map(
          v => v.variety.name
        )} - ${selectedAreasIndexed[id].varieties.map(
          v => v.variety.crop.name
        )}`
      );
    });
  });

  const areasToShow = (() => {
    const filteredSelectedAreas = { ...selectedAreasIndexed };
    if (arrayOfBlocks.length >= 1) {
      arrayOfBlocks.map(block => {
        block.areaIDs.map(id => {
          delete filteredSelectedAreas[id];
        });
      });
    }
    return Object.values(filteredSelectedAreas).concat(arrayOfBlocks);
  })();

  const indexedAreasToShow = Object.fromEntries(
    areasToShow.map(area => [area.id, area])
  );

  const handleActions = async (actionType, actionValue) => {
    switch (actionType) {
      case "doneState":
        if (showParentBlocks) {
          dispatch(setAreaDoneState(actionValue));
        } else {
          const { areaIDs, done } = indexedAreasToShow[actionValue] || {};

          areaIDs && areaIDs.length
            ? dispatch(setAreasDoneState(areaIDs, done))
            : dispatch(setAreaDoneState(actionValue));
        }
        break;
      case "doneAllState":
        dispatch(setAllAreasDoneState(!allDone));
        break;
      case "actualTanks":
        if (
          indexedAreasToShow[actionValue.id] &&
          indexedAreasToShow[actionValue.id].areaIDs &&
          indexedAreasToShow[actionValue.id].areaIDs.length
        ) {
          indexedAreasToShow[actionValue.id].areaIDs.forEach(id => {
            dispatch(
              setAreaValue(id, {
                actualTanks:
                  actionValue.value.actualTanks /
                  indexedAreasToShow[actionValue.id].areaIDs.length
              })
            );
          });
        } else {
          dispatch(setAreaValue(actionValue.id, actionValue.value));
        }

        await dispatch(
          setSprayDiaryField({
            fieldName: "actualTotalTanks",
            fieldValue: null
          })
        );
        break;
      case "diaryField":
        await dispatch(setSprayDiaryField(actionValue));
        break;
      default:
        break;
    }
    if (updateTask) {
      debounceUpdate(updateTask);
    }
  };

  const determineShouldSetActualValue = actualValue =>
    actualValue !== "" &&
    actualValue !== null &&
    actualValue !== undefined &&
    !isNaN(actualValue);

  const {
    totalVolume,
    totalUsedVolume,
    totalUsedTanks,
    totalChemicals
  } = useMemo(() => {
    const initialValues = {
      totalVolume: 0,
      totalUsedVolume: 0,
      totalUsedTanks: 0
    };

    if (!selectedAreas) {
      return initialValues;
    }
    const result = selectedAreas.reduce((prev, curr) => {
      const areaTank = determineShouldSetActualValue(curr.actualTanks)
        ? parseFloat(curr.actualTanks)
        : curr.tanks;

      return {
        totalVolume: prev.totalVolume + curr.plannedLiters,
        totalUsedVolume: prev.totalUsedVolume + areaTank * machineryLitersSize,
        totalUsedTanks: prev.totalUsedTanks + areaTank
      };
    }, initialValues);

    return {
      totalVolume: parseFloat(result.totalVolume).toFixed(2),
      totalUsedVolume: parseFloat(result.totalUsedVolume).toFixed(2),
      totalUsedTanks: parseFloat(result.totalUsedTanks).toFixed(2),
      totalChemicals: selectedChemicals.map(chemical => ({
        units: chemical.rate.unit,
        quantity: selectedAreas.reduce((prev, { size, actualTanks, tanks }) => {
          const { quantity: predictedQuantity } = calculateChemicalQuantity(
            chemical,
            size,
            rowsToSpray,
            widthPerRow,
            literPerHectare,
            conc
          );

          let quantity = predictedQuantity;

          if (
            actualTanks !== null &&
            actualTanks !== undefined &&
            actualTanks !== tanks
          ) {
            quantity = (predictedQuantity * actualTanks) / tanks;
          }

          return prev + quantity;
        }, 0)
      }))
    };
  }, [selectedAreas]);

  if (!selectedAreas.length) return null;

  const areasToMap = showParentBlocks ? selectedAreas : areasToShow;
  const sortedAreasToMap = areasToMap.sort(
    (item1, item2) =>
      item1.name &&
      item1.name
        .replace(/\s/g, "")
        .localeCompare(item2.name && item2.name.replace(/\s/g, ""), undefined, {
          numeric: true,
          sensitivity: "base"
        })
  );

  const totalUsedTanksDecimal = totalUsedTanks ? totalUsedTanks % 1 : 0;

  const handleUsedVolumeChange = (area, actualVolumeValue) => {
    setUsedTanksKey(uuid());
    setTotalUsedVolumeKey(uuid());
    setTotalUsedTanksKey(uuid());
    handleActions("actualTanks", {
      id: area.id,
      value: {
        actualTanks: determineShouldSetActualValue(actualVolumeValue)
          ? actualVolumeValue / machineryLitersSize
          : area.tanks
      }
    });
  };

  const handleUsedTanksChange = (area, actualTanksValue) => {
    setUsedVolumeKey(uuid());
    setTotalUsedVolumeKey(uuid());
    setTotalUsedTanksKey(uuid());
    handleActions("actualTanks", {
      id: area.id,
      value: {
        actualTanks: determineShouldSetActualValue(actualTanksValue)
          ? actualTanksValue
          : +area.tanks
      }
    });
  };

  const handleTotalUsedVolumeChange = actualTotalVolumeValue => {
    setTotalUsedTanksKey(uuid());
    setUsedTanksKey(uuid());
    setUsedVolumeKey(uuid());
    const shouldSetActualValue = determineShouldSetActualValue(
      actualTotalVolumeValue
    );
    const currentTotalVolumeValue = shouldSetActualValue
      ? actualTotalVolumeValue
      : totalVolume;

    const volumeFactor = currentTotalVolumeValue / totalVolume;

    const areasWithUpdatedActualTanks = sortedAreasToMap.map(area => {
      return {
        ...area,
        actualTanks: shouldSetActualValue
          ? (area.plannedLiters * volumeFactor) / machineryLitersSize
          : area.tanks
      };
    });

    dispatch(updateAreasList(areasWithUpdatedActualTanks));
    handleActions("diaryField", {
      fieldName: "actualTotalTanks",
      fieldValue: currentTotalVolumeValue / machineryLitersSize
    });
  };

  const handleTotalUsedTanksChange = actualTotalTanksValue => {
    setTotalUsedVolumeKey(uuid());
    setUsedTanksKey(uuid());
    setUsedVolumeKey(uuid());
    const shouldSetActualValue = determineShouldSetActualValue(
      actualTotalTanksValue
    );
    const currentTotalTanksValue = shouldSetActualValue
      ? actualTotalTanksValue
      : totalTanks;

    const tanksFactor = currentTotalTanksValue / totalTanks;

    const areasWithUpdatedActualTanks = sortedAreasToMap.map(area => {
      return {
        ...area,
        actualTanks: shouldSetActualValue
          ? area.tanks * tanksFactor
          : area.tanks
      };
    });

    dispatch(updateAreasList(areasWithUpdatedActualTanks));
    handleActions("diaryField", {
      fieldName: "actualTotalTanks",
      fieldValue: currentTotalTanksValue
    });
  };

  const unitFormater = item => {
    let unit;
    if (item === "liter") {
      unit = "L";
    } else if (item === "kilogram") {
      unit = "kg";
    } else if (item === "milliliter") {
      unit = "mL";
    } else {
      unit = "g";
    }
    return unit;
  };
  return (
    <div className={styles.areasTableWrapper}>
      <Table basic="very" singleLine unstackable className={styles.areasTable}>
        <Table.Header>
          <Table.Row>
            {!hideCheckBoxes && <Table.HeaderCell />}
            <Table.HeaderCell>Area</Table.HeaderCell>
            <Table.HeaderCell>Size</Table.HeaderCell>
            <Table.HeaderCell>Volume (L)</Table.HeaderCell>
            {selectedMachinery && <Table.HeaderCell>Tanks</Table.HeaderCell>}
            {selectedMachinery && someDone && (
              <Table.HeaderCell>Used Volume (L)</Table.HeaderCell>
            )}
            {selectedMachinery && someDone && (
              <Table.HeaderCell>Used Tanks</Table.HeaderCell>
            )}
            {selectedChemicals.length > 0 &&
              selectedChemicals.map(chemical => (
                <Table.HeaderCell key={`chemical_${chemical.id}`}>
                  {chemical.name}
                </Table.HeaderCell>
              ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sortedAreasToMap.map(area => {
            const block = new Area(area);
            const variety = block.areaVarietyByDate(plannedDate);
            const varietyList = area.varietiesList;
            return (
              <Table.Row key={area.id}>
                {!hideCheckBoxes && (
                  <Table.Cell>
                    <Checkbox
                      checked={area.done}
                      className="check-box"
                      onChange={() => handleActions("doneState", area.id)}
                      disabled={shouldDisableDoneCheckbox}
                    />
                  </Table.Cell>
                )}
                <Table.Cell className={styles.areaNameHolder}>
                  <div className={styles.areaName}>
                    {area.parent ? `${area.parent.name} - ` : ""}
                    {area.name}
                  </div>
                  {variety.name && (
                    <div className={styles.variety}>{variety.crop.name}</div>
                  )}
                  <div
                    className={`${styles.variety} ${
                      variety.name || varietyList ? "" : styles.vacant
                    }`}
                  >
                    {varietyList
                      ? _.uniq(varietyList).map(vary => {
                          return (
                            <>
                              {vary}
                              <br />
                            </>
                          );
                        })
                      : variety.name
                      ? `${variety.crop ? `${variety.crop.name} - ` : ""}${
                          variety.name
                        }`
                      : "Vacant"}
                  </div>
                </Table.Cell>
                <Table.Cell>
                  <Numeric
                    value={area.size}
                    fractionDigits={2}
                    commaSeparatorOnThousands
                    units="Ha"
                  />
                </Table.Cell>
                <Table.Cell>
                  <Numeric
                    value={area.plannedLiters}
                    fractionDigits={2}
                    commaSeparatorOnThousands
                    units="L"
                  />
                </Table.Cell>
                {selectedMachinery && (
                  <Table.Cell>
                    <Numeric
                      value={area.tanks}
                      fractionDigits={2}
                      commaSeparatorOnThousands
                    />
                  </Table.Cell>
                )}
                {selectedMachinery && someDone && (
                  <Table.Cell className={styles.withInput}>
                    <Input
                      fluid
                      key={usedVolumeKey}
                      placeholder={
                        area.tanks &&
                        (area.tanks * machineryLitersSize).toFixed(2)
                      }
                      defaultValue={
                        area.actualTanks !== null &&
                        area.actualTanks !== undefined &&
                        area.actualTanks !== area.tanks
                          ? (area.actualTanks * machineryLitersSize).toFixed(2)
                          : ""
                      }
                      disabled={!area.done}
                      onChange={e =>
                        handleUsedVolumeChange(area, e.target.value)
                      }
                      onBlur={() => setUsedVolumeKey(uuid())}
                    />
                  </Table.Cell>
                )}
                {selectedMachinery && someDone && (
                  <Table.Cell className={styles.withInput}>
                    <Input
                      fluid
                      key={usedTanksKey}
                      placeholder={area.tanks && area.tanks.toFixed(2)}
                      defaultValue={
                        area.actualTanks !== null &&
                        area.actualTanks !== undefined &&
                        area.actualTanks !== area.tanks
                          ? parseFloat(area.actualTanks).toFixed(2)
                          : ""
                      }
                      disabled={!area.done}
                      onBlur={() => setUsedTanksKey(uuid())}
                      onChange={e =>
                        handleUsedTanksChange(area, e.target.value)
                      }
                    />
                  </Table.Cell>
                )}
                {selectedChemicals.length > 0 &&
                  selectedChemicals.map(chemical => {
                    const {
                      quantity: predictedQuantity,
                      units
                    } = calculateChemicalQuantity(
                      chemical,
                      area.size,
                      rowsToSpray,
                      widthPerRow,
                      literPerHectare,
                      conc
                    );

                    let quantity = predictedQuantity;

                    if (
                      area.actualTanks !== null &&
                      area.actualTanks !== undefined &&
                      area.actualTanks !== area.tanks
                    ) {
                      quantity =
                        (predictedQuantity * area.actualTanks) / area.tanks;
                    }

                    return (
                      <Table.Cell>
                        <Numeric
                          fractionDigits={2}
                          value={
                            isFinite(quantity)
                              ? quantity * multiplier(chemical.rate.unit)
                              : "-"
                          }
                          commaSeparatorOnThousands
                          units={unitFormater(chemical.rate.unit)}
                        />
                      </Table.Cell>
                    );
                  })}
              </Table.Row>
            );
          })}
          <Table.Row className={styles.lastRow}>
            {!hideCheckBoxes && (
              <Table.Cell>
                <Checkbox
                  checked={allDone}
                  onChange={() => handleActions("doneAllState")}
                  className="check-box"
                  disabled={shouldDisableDoneCheckbox}
                />
              </Table.Cell>
            )}
            <Table.Cell>{`${sortedAreasToMap.length} Fields`}</Table.Cell>
            <Table.Cell>
              <Numeric
                value={totalHectares}
                fractionDigits={2}
                commaSeparatorOnThousands
                units="Ha"
              />
            </Table.Cell>
            <Table.Cell>
              <Numeric
                value={totalVolume}
                fractionDigits={2}
                commaSeparatorOnThousands
                units="L"
              />
            </Table.Cell>
            {selectedMachinery && (
              <Table.Cell>
                <Numeric
                  value={totalTanks}
                  fractionDigits={2}
                  commaSeparatorOnThousands
                />
              </Table.Cell>
            )}
            {selectedMachinery && someDone && (
              <Table.Cell className={styles.withInput}>
                <Input
                  fluid
                  key={totalUsedVolumeKey}
                  placeholder={totalUsedVolume || totalVolume}
                  defaultValue={
                    totalUsedVolume !== totalVolume ? totalUsedVolume : ""
                  }
                  onBlur={() => setTotalUsedVolumeKey(uuid())}
                  disabled={!allDone}
                  onChange={e => handleTotalUsedVolumeChange(e.target.value)}
                />
              </Table.Cell>
            )}
            {selectedMachinery && someDone && (
              <Table.Cell className={styles.withInput}>
                <Input
                  fluid
                  key={totalUsedTanksKey}
                  placeholder={totalUsedTanks || totalTanks.toFixed(2)}
                  defaultValue={
                    totalUsedTanks !== (totalTanks && totalTanks.toFixed(2))
                      ? totalUsedTanks
                      : ""
                  }
                  onBlur={() => setTotalUsedTanksKey(uuid())}
                  disabled={!allDone}
                  onChange={e => handleTotalUsedTanksChange(e.target.value)}
                />
              </Table.Cell>
            )}
            {selectedChemicals.length > 0 &&
              totalChemicals.map(chemical => (
                <Table.Cell>
                  <Numeric
                    fractionDigits={2}
                    value={
                      isFinite(chemical.quantity)
                        ? chemical.quantity * multiplier(chemical.units)
                        : "-"
                    }
                    commaSeparatorOnThousands
                    units={unitFormater(chemical.units)}
                  />
                </Table.Cell>
              ))}
          </Table.Row>
        </Table.Body>
      </Table>
      {selectedMachinery && (
        <>
          <div className={styles.tanksAdditionalInfo}>
            {`1 Tank = `}
            <Numeric
              value={machineryLitersSize}
              fractionDigits={2}
              commaSeparatorOnThousands
              units="L"
            />
          </div>
          {totalUsedTanksDecimal !== 0 && (
            <div className={styles.tanksAdditionalInfo}>
              <Numeric
                value={totalUsedTanksDecimal}
                fractionDigits={2}
                commaSeparatorOnThousands
                units="Tank"
              />
              {` = `}
              <Numeric
                value={totalUsedVolume % machineryLitersSize}
                fractionDigits={2}
                commaSeparatorOnThousands
                units="L"
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

AreasTable.propTypes = {
  actions: PropTypes.object,
  selectedAreas: PropTypes.array,
  areasList: PropTypes.object,
  selectedMachinery: PropTypes.number,
  totalTanks: PropTypes.number,
  updateTask: PropTypes.func,
  hideCheckBoxes: PropTypes.bool,
  showParentBlocks: PropTypes.bool,
  sprayTask: PropTypes.object,
  plannedDate: PropTypes.object
};

AreasTable.defaultProps = {
  hideCheckBoxes: false
};

export default memo(AreasTable);
