import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import _ from "lodash";
import { Header, Container } from "semantic-ui-react";
import Loader from "../../../../../components/Loader";
import OperatingCostTable from "./index";
import "../../index.css";

const OperatingCostsBreakdown = ({
  data,
  isReportInProgress,
  expandedTable = false
}) => {
  const dispatch = useDispatch();
  const groups = data.reduce((groups, item) => {
    const group = groups[item.costType] || [];
    group.push(item);
    groups[item.costType] = group;
    return groups;
  }, {});

  const gruppedArray = Object.keys(groups).map(function(k) {
    return groups[k];
  });
  return (
    <Container className="operating">
      {expandedTable && <Header as="h2">Operating Costs breakdown:</Header>}
      <div className="table-wrapper">
        {isReportInProgress ? (
          <Loader />
        ) : (
          <OperatingCostTable gruppedArray={gruppedArray} />
        )}
      </div>
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    data: state.farmOperatingCostBreakdow.data,
    isReportInProgress: state.farmOperatingCostBreakdow.isReportInProgress
  };
};

const mapDispatchToProps = () => ({ actions: {} });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OperatingCostsBreakdown);
