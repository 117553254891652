import React, { PureComponent } from "react";
import { Button, Confirm } from "semantic-ui-react";
import { Form } from "formsy-semantic-ui-react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { addNewClient } from "../../../actions/Clients";
import assignValue from "../../../utils/assignValue";
import ClientDetails from "./ClientDetails";
import { history } from "../../../store";

import LayoutOverlay from "../../../components/LayoutOverlay";
import SegmentClosable from "../../../components/SegmentClosable";
import { scrollIntoError } from "../../../utils/scrollIntoError";
import { getErrorFields } from "../../../utils/validationRules";
import styles from "./AddNewClient.module.css";
import { bindActionCreators } from "redux";
import { DEFAULT_TIMEZONE } from "../../../selectors/user";

class AddNewClient extends PureComponent {
  state = {
    errorsInSegments: {},
    formData: {
      type: "OWNER",
      gender: "MALE",
      firstName: "TieUp Farming",
      lastName: "Support",
      timezone: DEFAULT_TIMEZONE
    },
    userChangedEmail: false,
    showConfirm: false
  };
  formRef = React.createRef();

  componentDidMount = async () => {};

  handleErrors = () => {
    const formsyForm = this.formRef.current.formsyForm;
    const errorFields = getErrorFields(formsyForm);

    this.setState({
      errorsInSegments: {
        client: {
          error:
            errorFields.firstName ||
            errorFields.lastName ||
            errorFields.id ||
            errorFields.gender ||
            errorFields.email
        }
      }
    });

    setTimeout(scrollIntoError, 300);
  };

  onValidSubmit = () => {
    this.setState({ showConfirm: true });
  };

  handleCancel = () => {
    this.setState({ showConfirm: false });
  };

  handleConfirm = async () => {
    const { actions } = this.props;
    const { formData } = this.state;

    this.setState({
      errorsInSegments: {},
      showConfirm: false
    });

    const res = await actions.addNewClient(
      {
        id: formData.id,
        timezone: formData.timezone,
        userName: formData.userName,
        employee: {
          email: formData.email,
          firstName: formData.firstName,
          lastName: formData.lastName,
          type: formData.type,
          gender: formData.gender,
          ratePerHour: formData.ratePerHour
        }
      },
      true
    );
    if (!res.error) {
      history.push("/clients");
    }
  };

  changeValue = event => {
    const { userChangedEmail } = this.state;
    const formData = { ...this.state.formData };
    const name = event.target.name;
    const value = event.target.value;
    const extraKeys = {};
    assignValue(formData, name, value);

    if (name === "email") {
      extraKeys.userChangedEmail = !!value;
    }
    if (!userChangedEmail || !formData.email) {
      assignValue(
        formData,
        "email",
        formData.id ? `support+${formData.id}@tieupfarming.com` : ""
      );
    }
    assignValue(formData, "userName", formData.email);
    this.setState({ formData, ...extraKeys });
  };

  render() {
    const {
      formData: {
        id,
        timezone,
        firstName,
        lastName,
        gender,
        email,
        type,
        userName
      },
      errorsInSegments,
      showConfirm
    } = this.state;
    const { saving, route } = this.props;

    const columnMiddle = {
      mobile: 16,
      tablet: 16,
      computer: 8,
      largeScreen: 8,
      widescreen: 8
    };

    const columnMiddleRight = {
      mobile: 16,
      tablet: 16,
      computer: 8,
      largeScreen: 8,
      widescreen: 8
    };

    return (
      <Form
        onValidSubmit={this.onValidSubmit}
        onInvalidSubmit={this.handleErrors}
        className={styles.overForm}
        ref={this.formRef}
      >
        <Confirm
          open={showConfirm}
          confirmButton="I am sure"
          content={
            <div className={styles.confirmText}>
              <div>
                Client name: <span className={styles.name}>{id}</span>
              </div>
              <div>
                Make sure you wrote the client name correctly, it cannot modify
                later!
              </div>
            </div>
          }
          onCancel={this.handleCancel}
          onConfirm={this.handleConfirm}
        />
        <LayoutOverlay
          closeLink="/clients"
          route={{ ...route, name: route.name }}
          footer={
            <div className={styles.buttonsFooter}>
              <Button
                type="submit"
                primary
                size="large"
                loading={saving}
                disabled={saving}
              >
                Save
              </Button>
            </div>
          }
        >
          <div className={styles.accordionHolder}>
            <SegmentClosable
              title="Client Details"
              errors={errorsInSegments.client}
            >
              <ClientDetails
                id={id}
                type={type}
                userName={userName}
                firstName={firstName}
                lastName={lastName}
                email={email}
                gender={gender}
                timezone={timezone}
                changeValue={this.changeValue}
                columnMiddle={columnMiddle}
                columnMiddleRight={columnMiddleRight}
              />
            </SegmentClosable>
          </div>
        </LayoutOverlay>
      </Form>
    );
  }
}

AddNewClient.propTypes = {
  addNewClient: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  actions: PropTypes.object,
  route: PropTypes.object,
  saving: PropTypes.bool
};

AddNewClient.defaultProps = {};

const mapStateToProps = state => {
  const {
    clients: { saving }
  } = state;

  return {
    saving
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        addNewClient
      },
      dispatch
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewClient);
