import React from "react";
import PropTypes from "prop-types";
import { Button, Grid, Icon, Popup, Select } from "semantic-ui-react";
import styles from "./Pagination.module.css";

const defaultButton = props => {
  const { online, disabled, ...rest } = props;
  const ButtonToRender = (
    <span>
      <Button type="button" disabled={disabled} {...rest}>
        {props.children}
      </Button>
    </span>
  );
  return !online && disabled ? (
    <Popup
      position="top right"
      on="hover"
      content="Content is not available since you are offline"
      trigger={ButtonToRender}
    />
  ) : (
    ButtonToRender
  );
};

export const pageSizeOptions = [
  { value: 10, key: 10, text: "10" },
  { value: 20, key: 20, text: "20" },
  { value: 50, key: 50, text: "50" },
  { value: 100, key: 100, text: "100" },
  { value: 200, key: 200, text: "200" },
  { value: 300, key: 300, text: "300" }
];

export const pageSize = (size, defaultSize = 10) => {
  return pageSizeOptions.findIndex(item => item.value === size) !== -1
    ? size
    : defaultSize;
};

export default class Pagination extends React.Component {
  state = {
    visiblePages: []
  };

  static propTypes = {
    pages: PropTypes.number,
    page: PropTypes.number,
    PageButtonComponent: PropTypes.any,
    onPageChangeOwn: PropTypes.func,
    previousText: PropTypes.string,
    nextText: PropTypes.string,
    totalElements: PropTypes.number,
    itemsCount: PropTypes.object
  };

  componentDidMount() {
    const { pages, currentPage } = this.props;
    this.setState({
      visiblePages: this.getVisiblePages(currentPage + 1, pages)
    });
  }

  componentDidUpdate(prevProps) {
    const { pages, currentPage } = this.props;
    if (pages !== prevProps.pages) {
      this.setState({
        visiblePages: this.getVisiblePages(currentPage + 1, pages)
      });
    }

    this.changePage(currentPage + 1);
  }

  filterPages = (visiblePages, totalPages) => {
    return visiblePages.filter(page => page <= totalPages);
  };

  getVisiblePages = (page, total) => {
    if (total < 7) {
      return this.filterPages([1, 2, 3, 4, 5, 6], total);
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [1, page - 1, page, page + 1, total];
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [1, total - 3, total - 2, total - 1, total];
      } else {
        return [1, 2, 3, 4, 5, total];
      }
    }
  };

  changePage = (page, loadMore = false) => {
    const activePage = this.props.currentPage + 1;
    if (page === activePage) {
      return;
    }
    const visiblePages = this.getVisiblePages(page, this.props.pages);
    this.setState({
      visiblePages: this.filterPages(visiblePages, this.props.pages)
    });
    this.props.onPageChangeOwn(page - 1, loadMore);
  };

  render() {
    const { PageButtonComponent = defaultButton } = this.props;
    const { visiblePages } = this.state;
    const {
      currentPage,
      itemsCount,
      totalElements,
      updatePageSize,
      pageSize,
      online,
      accessibleOfflinePages,
      pages,
      showTotal,
      className,
      showChangePageSize
    } = this.props;
    const activePage = currentPage + 1;
    return (
      <Grid className={`${styles.paginationHolder} ${className}`}>
        <Grid.Row className="show-sm">
          <Grid.Column textAlign="right">
            <div className={styles.selectorsHolder}>
              {showTotal && (
                <span className={styles.totalItems}>
                  {itemsCount.itemTo ? (
                    <>
                      <span className="show-sm-inline">
                        {itemsCount.itemFrom}
                      </span>
                      <span className="hide-sm-inline">1</span>-
                      {itemsCount.itemTo} / {totalElements || "..."} items
                    </>
                  ) : (
                    <span className="show-sm-inline">No items</span>
                  )}
                </span>
              )}
              {showChangePageSize && (
                <>
                  <span className="show-sm-inline-block">
                    <span>Show</span>
                    <Select
                      className={styles.selectShort}
                      defaultValue={pageSize}
                      onChange={updatePageSize}
                      options={pageSizeOptions}
                      disabled={
                        !online ||
                        itemsCount.itemTo === 0 ||
                        itemsCount.totalElements === 0
                      }
                      icon={<Icon className="tuf-chevron-down" />}
                    />
                  </span>
                  <span className={`show-sm-inline-block ${styles.separator}`}>
                    |
                  </span>
                </>
              )}
              <div className={styles.pagination}>
                <div className={styles.prevPageHolder}>
                  <PageButtonComponent
                    className="button-text"
                    onClick={() => {
                      if (activePage === 1) return;
                      this.changePage(activePage - 1);
                    }}
                    disabled={
                      activePage === 1 ||
                      (!online && !accessibleOfflinePages[activePage - 1]) ||
                      itemsCount.itemTo === 0
                    }
                    online={online}
                  >
                    <Icon name="angle left" />
                  </PageButtonComponent>
                </div>
                <div className={styles.pagesHolder}>
                  {visiblePages.map((page, index, array) => (
                    <PageButtonComponent
                      key={page}
                      className={`button-text ${
                        activePage === page ? styles.active : ""
                      }`}
                      onClick={() => this.changePage(page)}
                      disabled={
                        (!online && !accessibleOfflinePages[page - 1]) ||
                        itemsCount.itemTo === 0
                      }
                      online={online}
                    >
                      {array[index - 1] + 2 <= page ? `...${page}` : page}
                    </PageButtonComponent>
                  ))}
                </div>
                <div className={styles.nextPageHolder}>
                  <PageButtonComponent
                    className="button-text"
                    onClick={() => {
                      if (activePage === pages) return;
                      this.changePage(activePage + 1);
                    }}
                    disabled={
                      activePage === pages ||
                      (!online && !accessibleOfflinePages[activePage]) ||
                      itemsCount.itemTo === 0
                    }
                    online={online}
                  >
                    <Icon name="angle right" />
                  </PageButtonComponent>
                </div>
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="hide-sm">
          <Grid.Column width={16} textAlign="right">
            <PageButtonComponent
              onClick={() => {
                if (activePage === pages) return;
                this.changePage(activePage + 1, true);
              }}
              disabled={
                activePage === pages ||
                (!online && !accessibleOfflinePages[activePage]) ||
                itemsCount.itemTo === 0
              }
              online={online}
            >
              Show more
            </PageButtonComponent>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

defaultButton.propTypes = {
  children: PropTypes.any,
  online: PropTypes.bool,
  disabled: PropTypes.bool
};

Pagination.propTypes = {
  pages: PropTypes.number,
  currentPage: PropTypes.number,
  itemsCount: PropTypes.object,
  totalElements: PropTypes.number,
  updatePageSize: PropTypes.func,
  pageSize: PropTypes.number,
  online: PropTypes.bool,
  accessibleOfflinePages: PropTypes.object,
  showTotal: PropTypes.bool,
  className: PropTypes.string,
  showChangePageSize: PropTypes.bool
};

Pagination.defaultProps = {
  currentPage: 0,
  itemsCount: {
    itemFrom: 0,
    itemTo: 0
  },
  onPageChangeOwn: () => {},
  online: true,
  accessibleOfflinePages: {},
  showTotal: false,
  className: "",
  pageSize: 10,
  pages: 0,
  showChangePageSize: true
};
