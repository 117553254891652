export const actionType = {
  FETCH_VENDORS_START: 'VENDORS/FETCH_START',
  FETCH_VENDORS: 'VENDORS/FETCH',
  FETCH_VENDORS_FAIL: 'VENDORS/FETCH_FAIL',

  FETCH_VENDORS_LIST_START: 'VENDORS/FETCH_LIST_START',
  FETCH_VENDORS_LIST: 'VENDORS/FETCH_LIST',
  FETCH_VENDORS_LIST_FAIL: 'VENDORS/FETCH_LIST_FAIL',

  GET_VENDORS_BY_ID_START: 'VENDORS/GET_BY_ID_START',
  GET_VENDORS_BY_ID: 'VENDORS/GET_BY_ID',
  GET_VENDORS_BY_ID_FAIL: 'VENDORS/GET_BY_ID_FAIL',

  ADD_VENDORS_START: 'VENDORS/ADD_START',
  ADD_VENDORS: 'VENDORS/ADD',
  ADD_VENDORS_FAIL: 'VENDORS/ADD_FAIL',

  UPDATE_VENDORS_START: 'VENDORS/UPDATE_START',
  UPDATE_VENDORS: 'VENDORS/UPDATE',
  UPDATE_VENDORS_FAIL: 'VENDORS/UPDATE_FAIL',

  DELETE_VENDORS_START: 'VENDORS/DELETE_START',
  DELETE_VENDORS: 'VENDORS/DELETE',
  DELETE_VENDORS_FAIL: 'VENDORS/DELETE_FAIL',

  CLEAR_VENDORS_ERROR: 'VENDORS/CLEAR_VENDORS_ERROR',
};
