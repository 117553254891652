import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Accordion, Header, Icon } from "semantic-ui-react";
import styles from "./CollapsibleBlock.module.css";

const CollapsibleBlock = ({
  title,
  children,
  className,
  headerOptionalComponent = null,
  defaultActive = true
}) => {
  const [active, setActive] = useState(!!defaultActive);

  const handleClick = () => {
    setActive(active => !active);
  };

  return (
    <Accordion className={classNames(className)} fluid>
      <Accordion.Title
        active={active}
        onClick={handleClick}
        className={styles.titleContainer}
      >
        <Header className={styles.title}>{title}</Header>
        {!!headerOptionalComponent && (
          <div className={styles.editButtonContainer}>
            {headerOptionalComponent}
          </div>
        )}
        <Icon name={`chevron ${active ? "up" : "down"}`} />
      </Accordion.Title>
      <Accordion.Content active={active}>{children}</Accordion.Content>
    </Accordion>
  );
};

CollapsibleBlock.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.any,
  defaultActive: PropTypes.bool,
  className: PropTypes.string
};

export default CollapsibleBlock;
