import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { reducer as oidc } from "redux-oidc";
import farms from "./Farms";
import johnDeere from "./JhonDeere";
import common from "./Common";
import user from "./User";
import filters from "./Filters";
import menu from "./Menu";
import crops from "./Crops";
import sprayDiary from "./SprayDiary";
import spray from "./Spray";
import machinery from "./Machinery";
import employee from "./Employee";
import chemical from "./Chemical";
import shed from "./Sheds";
import inventoryMovements from "./InventoryMovements";
import pendingMovements from "./PendingMovements";
import purchaseHistory from "./PurchaseHistory";
import costEntering from "./GeneralTasks/costEntering";
import varieties from "./Varieties";
import apiResults from "./apiResults";
import notifications from "./Notifications";
import weatherStation from "./WeatherStation";
import clients from "./Clients";
import infoMessages from "./InfoMessages";
import employeesBarcodes from "./EmployeesBarcodes";
import generalBarcodes from "./GeneralBarcodes";
import binsPicked from "./BinsPicked";
import dashboard from "./Dashboard";
import tagBarcodes from "./TagBarcodes";
import areas from "./Areas";
import boxUnits from "./BoxUnits";
import packedBoxes from "./PackedBoxes";
import packingSheds from "./PackingSheds";
import contractors from "./Contractors";
import pallets from "./Pallets";
import stock from "./Stock";
import vendors from "./Vendors";
import dispatches from "./Dispatches";
import laborGroups from "./LaborGroups";
import qualityControl from "./QualityControl";
import harvestUnits from "./HarvestUnits";
import harvestUnitsControl from "./HarvestUnits/harvestUnitsControl";
import taskCreator from "./GeneralTaskCreator";
import generalTasks from "./GeneralTasks";
import generalTasksWorkingTimes from "./GeneralTaskWorkingTimes";
import employeeGroups from "./EmployeeGroups";
import scouting from "./Scouting";
import imageViewer from "./ImageViewer";
import { reportsRootReducer } from "./Reports";
import applicationSettings from "./Application";
import applicationsRecordByBlock from "./ApplicationsRecordByBlock";
import farmGeneralTaskReport from "./FarmSidePanelGeneralTaskReport";
import farmOperatingCostBreakdow from "./FarmSidePanelOperatingCostReport";
import hourlyPayroll from "./HourlyPayroll";
import pieceRatePayroll from "./PieceRatePayroll";
import recommendations from "./Recommendations";
import settings from "./Settings";

export default (history, other = {}) =>
  combineReducers({
    appConfig: state => state || {},
    applicationSettings,
    oidc,
    user,
    menu,
    router: connectRouter(history),
    common,
    farms,
    filters,
    crops,
    sprayDiary,
    spray,
    machinery,
    employee,
    chemical,
    shed,
    johnDeere,
    settings,
    pendingMovements,
    inventoryMovements,
    purchaseHistory,
    costEntering,
    apiResults,
    notifications,
    varieties,
    weatherStation,
    clients,
    infoMessages,
    employeesBarcodes,
    generalBarcodes,
    dashboard,
    binsPicked,
    tagBarcodes,
    areas,
    boxUnits,
    packedBoxes,
    packingSheds,
    contractors,
    pallets,
    stock,
    vendors,
    dispatches,
    laborGroups,
    qualityControl,
    harvestUnits,
    harvestUnitsControl,
    taskCreator,
    generalTasks,
    generalTasksWorkingTimes,
    employeeGroups,
    scouting,
    imageViewer,
    reports: reportsRootReducer,
    applicationsRecordByBlock,
    farmGeneralTaskReport,
    farmOperatingCostBreakdow,
    hourlyPayroll,
    pieceRatePayroll,
    recommendations,
    ...other
  });
