import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Grid, Header } from "semantic-ui-react";
import { diff } from "deep-object-diff";
import TaskRow from "../TaskRow";
import Attachments from "../../../../components/Attachments";
import {
  createTask,
  setAttachments,
  uploadAttachment
} from "../../../../actions/SprayDiary/common";
import styles from "./Tabs.module.css";
import {
  directionSelector,
  strengthsSelector
} from "../../../../selectors/sprayDiary";

const API_URL = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`;

function GeneralInfo({
  sprayTask,
  fieldToEdit,
  onChange,
  toggleEditField,
  online,
  actions,
  updateTask,
  strengthsOptions,
  directionOptions,
  shouldDisableFieldsEdit
}) {
  const [attachments, setAttachments] = useState(sprayTask.attachments);
  useEffect(() => {
    if (Object.keys(diff(attachments, sprayTask.attachments)).length > 0) {
      if (
        !online ||
        (sprayTask.attachments.findIndex(file => file.idReplace) < 0 && online)
      ) {
        setAttachments(sprayTask.attachments);
        updateTask();
      }
    }
  }, [sprayTask, setAttachments, attachments, updateTask, online]);

  const callBack = useCallback(
    async data => {
      if (!data.online && data.id) {
        await actions.createTask({
          id: sprayTask.id,
          data: {
            attachments: [
              {
                id: data.id,
                name: data.name,
                subPath: data.subPath,
                action: "CREATE"
              }
            ]
          },
          offlineMode: true
        });
      }
    },
    [sprayTask, actions]
  );

  const serviceWorkerAction = useMemo(
    () => ({
      url: `${API_URL}/spray_tasks/partial/`,
      id: sprayTask.id
    }),
    [sprayTask]
  );

  const onUploadAttachment = useCallback(
    ({ file, data, idReplace, callBack, serviceWorkerAction }) => {
      actions.uploadAttachment({
        file,
        callBack,
        idReplace,
        serviceWorkerAction,
        data,
        parentId: sprayTask.id || sprayTask._id
      });
    },
    [actions, sprayTask]
  );

  const onDeleteAttachment = useCallback(
    attachmentId => {
      actions.setAttachments({
        attachments: attachments.filter(
          a => a.id !== attachmentId && a.idReplace !== attachmentId
        ),
        parentId: sprayTask.id || sprayTask._id
      });
    },
    [attachments, actions, sprayTask]
  );

  return (
    <div className={styles.tabHolder}>
      <Grid verticalAlign="middle" className="no-margin">
        <TaskRow
          label="Attachments"
          name="attachments"
          value={
            <Attachments
              attachments={sprayTask.attachments}
              isUploading={sprayTask.isUploadingAttachment}
              onDelete={onDeleteAttachment}
              onFileSelected={onUploadAttachment}
              serviceWorkerAction={serviceWorkerAction}
              callBack={callBack}
              multiply
              canEdit={!shouldDisableFieldsEdit}
            />
          }
          wide
        />
        <TaskRow
          label="Comment"
          name="comment"
          inputType="textArea"
          value={sprayTask.comment}
          toggleEditField={toggleEditField}
          fieldToEdit={fieldToEdit}
          onChange={onChange}
          fluid
          wide
          disabled={shouldDisableFieldsEdit}
        />
      </Grid>
      {sprayTask.status === "COMPLETED" && (
        <>
          <Header>Spray Conditions</Header>
          <Grid verticalAlign="middle" className="no-margin">
            <TaskRow
              label="Temperature"
              name="temperature"
              inputType="inputText"
              value={sprayTask.temperature}
              fieldToEdit={fieldToEdit}
              onChange={onChange}
              toggleEditField={toggleEditField}
              inputLabel={{ content: "°C", inverted: "true" }}
              labelPosition="right"
              fluid
              rules={{ requiredOnComplete: true, numeric: true }}
              disabled={shouldDisableFieldsEdit}
            />
            <TaskRow
              label="Wind direction"
              name="windDirection"
              inputType="radioMenu"
              value={sprayTask.windDirection}
              fieldToEdit={fieldToEdit}
              options={directionOptions}
              onChange={onChange}
              toggleEditField={toggleEditField}
              borderless
              className={styles.groupMenu}
              disabled={shouldDisableFieldsEdit}
            />
            <TaskRow
              label="Wind strength"
              name="windStrength"
              inputType="radioMenu"
              value={sprayTask.windStrength}
              fieldToEdit={fieldToEdit}
              options={strengthsOptions}
              onChange={onChange}
              toggleEditField={toggleEditField}
              borderless
              className={styles.groupMenu}
              disabled={shouldDisableFieldsEdit}
            />
            <TaskRow
              label="Delta T"
              name="deltaT"
              inputType="textInput"
              value={sprayTask.deltaT}
              toggleEditField={toggleEditField}
              fieldToEdit={fieldToEdit}
              onChange={onChange}
              disabled={shouldDisableFieldsEdit}
            />
          </Grid>

          <Header>Personal Protection Equipment</Header>
          <Grid verticalAlign="middle" className="no-margin">
            <TaskRow
              label="PPE was worn while undertaking this task"
              name="ppeWorn"
              inputType={"radioMenu"}
              value={sprayTask.ppeWorn}
              toggleEditField={toggleEditField}
              fieldToEdit={fieldToEdit}
              options={[
                { text: "Yes", value: true },
                { text: "No", value: false }
              ]}
              onChange={onChange}
              disabled={shouldDisableFieldsEdit}
            />
          </Grid>
        </>
      )}
    </div>
  );
}

GeneralInfo.propTypes = {
  strengthsOptions: PropTypes.array,
  sprayTask: PropTypes.object,
  fieldToEdit: PropTypes.object,
  onChange: PropTypes.func,
  toggleEditField: PropTypes.func,
  online: PropTypes.bool,
  actions: PropTypes.object,
  updateTask: PropTypes.func,
  directionOptions: PropTypes.array,
  shouldDisableFieldsEdit: PropTypes.bool
};

GeneralInfo.defaultProps = {};

const mapStateToProps = state => ({
  strengthsOptions: strengthsSelector(state),
  directionOptions: directionSelector(state)
});
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setAttachments,
      uploadAttachment,
      createTask
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfo);
