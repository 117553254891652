import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';
import { OidcProvider } from 'redux-oidc';
import CacheBuster from './utils/CacheBuster';
import { history } from './store';
import 'semantic-ui-css/semantic.css';
import AuthCallBack from './routes/AuthCallBack';
import Routes from './routes/Routes/Routes';
import { userManager } from './index';
import './App.css';

function App({ store }) {
  return (
    <Provider store={store}>
      <CacheBuster>
        {({ loading }) => {
          if (loading) return null;
          return (
            <OidcProvider store={store} userManager={userManager}>
              <ConnectedRouter history={history}>
                <Switch>
                  <Route
                    exact
                    path="/auth-callback"
                    render={() => <AuthCallBack userManager={userManager} history={history} />}
                  />
                  <Route path="*" component={Routes} />
                </Switch>
              </ConnectedRouter>
            </OidcProvider>
          );
        }}
      </CacheBuster>
    </Provider>
  );
}

App.propTypes = {
  store: PropTypes.object,
  history: PropTypes.object,
  userManager: PropTypes.object,
};

export default App;
