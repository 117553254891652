import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Icon, Segment, Grid, Modal, Popup } from "semantic-ui-react";
import { isEmptyValue } from "utils/tasksUtils";
import CollapsibleBlock from "components/CollapsibleBlock";
import { useFormikContext } from "formik";
import { getRateWithUnits } from "utils/constToUnits";
import { fetchUnits } from "actions/Chemicals";
import { get, isEmpty } from "lodash";
import { unitsSelector } from "selectors/chemicals";
import { partialUpdate } from "actions/Sheds";
import styles from "./Farms.module.css";
import { shedList, shedListSelector } from "selectors/sheds";

const FarmRow = ({ farmData, farm, shedId, shed }) => {
  const dispatch = useDispatch();
  const { content, isFetching } = useSelector(unitsSelector);
  const { values, setFieldValue } = useFormikContext();
  const [isEditing, setIsEditing] = useState(false);
  const [chemicalIdToDelete, setChemicalIdToDelete] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const shedsList = useSelector(shedListSelector);

  const handleFarmdDelete = () => {
    const path = "farms";
    const updatedFarms = Object.values(
      values.farms.filter(f => f.farm.id !== farm.farm.id)
    );
    setFieldValue("farm", updatedFarms);
    dispatch(partialUpdate(shedId, updatedFarms, path));
    setShowDeleteModal(false);
  };

  return (
    <Grid.Row className={styles.rowContainer} as={Segment}>
      <Modal
        size="mini"
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
      >
        <Modal.Content>
          <p>
            Delete {get(farmData.farm, "name")} from {shed.name}'s inventory?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            className={styles.cancelDltBtn}
            onClick={() => setShowDeleteModal(false)}
          >
            Cancel
          </Button>
          <Button
            className={styles.deleteBtn}
            positive
            onClick={() => handleFarmdDelete()}
          >
            Delete
          </Button>
        </Modal.Actions>
      </Modal>
      <Grid.Column>{get(farmData.farm, "name")}</Grid.Column>
      <Grid.Column textAlign="right">
        <Popup
          inverted
          position="bottom center"
          content={"Delete"}
          size="tiny"
          trigger={
            <Button
              onClick={() => setShowDeleteModal(true)}
              className={styles.deleteButton}
              floated="right"
              icon={<Icon name="trash alternate" />}
              size="small"
            />
          }
        />
      </Grid.Column>
    </Grid.Row>
  );
};

export default FarmRow;
