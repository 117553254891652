import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  infoMessageType,
  messagesIds,
  putMessage
} from "../../actions/InfoMessages";
import { clearError as newFarmClearError } from "../../actions/Farms/addNewFarm";
import { clearError as employeeClearError } from "../../actions/Employee/employees";
import { clearError as contractorsClearError } from "../../actions/Contractors/contractors";
import { clearError as chemicalClearError } from "../../actions/Chemicals/index";
import { clearError as farmsClearError } from "../../actions/Farms/farms";
import { clearError as areasClearError } from "../../actions/Areas/areas";
import { clearMachineryError as machineryClearError } from "../../actions/Machinery/machineries";
import {
  clearError as sprayClearError,
  clearTasksError as sprayTasksClearError
} from "../../actions/Spray/tasks";
import { clearError as purchaseHistoryClearError } from "../../actions/PurchaseHistory";
import { clearError as sprayDiaryClearError } from "../../actions/SprayDiary/common";
import { clearError as clientsClearError } from "../../actions/Clients/index";
import { clearError as weatherStationClearError } from "../../actions/WeatherStation/index";
import { clearError as packedBoxesClearError } from "../../actions/PackedBoxes/packedBoxes";
import { clearError as boxUnitsClearError } from "../../actions/BoxUnits/boxUnits";
import { clearError as packingShedsClearError } from "../../actions/PackingSheds/packingSheds";
import { clearError as employeesBarcodesClearError } from "../../actions/EmployeeBarcodes/employeeBarcodes";
import { clearError as palletsClearError } from "../../actions/Pallets/pallets";
import { clearError as stockClearError } from "../../actions/Stock/stock";
import { clearError as vendorsClearError } from "../../actions/Vendors/vendors";
import { clearError as dispatchesClearError } from "../../actions/Dispatches/dispatches";
import { clearError as laborGroupsClearError } from "../../actions/LaborGroups/laborGroups";
import { clearError as qcFormsClearError } from "../../actions/QualityControl/forms";
import { clearError as harvestUnitsClearError } from "../../actions/HarvestUnits/harvestUnits";
import { clearError as generalBarcodesClearError } from "../../actions/GeneralBarcodes/generalBarcodes";
import { clearError as tagBarcodesClearError } from "../../actions/TagBarcodes/tagBarcodes";
import { clearError as taskCreatorClearError } from "../../actions/GeneralTaskCreator/taskCreator";
import { clearError as generalTasksClearError } from "../../actions/GeneralTasks/generalTasks";
import { clearError as generalTasksWorkingTimesClearError } from "../../actions/GeneralTasksWorkingTimes/generalTasksWorkingTimes";
import { clearError as employeeGroupsClearError } from "../../actions/EmployeeGroups/employeeGroups";
import { clearError as scoutingClearError } from "../../actions/Scouting/scouting";
import { clearError as reportsClearError } from "../../actions/Reports/reports";
import { usePrevious } from "utils/hooks";

const ErrorsListener = ({ errors, actions }) => {
  const prevErrors = usePrevious(errors);
  useEffect(() => {
    Object.keys(errors).forEach(key => {
      if (errors[key] && (!prevErrors || errors[key] !== prevErrors[key])) {
        if (
          errors[key].response &&
          errors[key].response.data &&
          errors[key].response.data.code
        ) {
          actions.putMessage({
            type: infoMessageType.ERROR,
            messageId: messagesIds.GENERIC_CODE,
            extraData: {
              ...errors[key].response.data
            }
          });
        } else {
          actions.putMessage({
            type: infoMessageType.ERROR,
            messageId: messagesIds.GENERIC,
            extraData: {
              message: errors[key].message
            }
          });
        }
        actions[`${key}ClearError`]();
      }
    });
  }, [actions, errors, prevErrors]);
  return null;
};

ErrorsListener.propTypes = {
  putMessage: PropTypes.array,
  removeMessage: PropTypes.func
};

ErrorsListener.defaultProps = {};

function mapStateToProps({
  farms,
  sprayDiary,
  spray,
  machinery,
  employee,
  contractors,
  chemical,
  clients,
  weatherStation,
  employeesBarcodes,
  areas,
  boxUnits,
  packedBoxes,
  packingSheds,
  pallets,
  stock,
  vendors,
  dispatches,
  laborGroups,
  qualityControl,
  harvestUnits,
  generalBarcodes,
  tagBarcodes,
  taskCreator,
  generalTasks,
  generalTasksWorkingTimes,
  employeeGroups,
  scouting,
  reports,
  purchaseHistory
}) {
  return {
    errors: {
      newFarm: farms.newFarm.error,
      farms: farms.error,
      sprayDiary: sprayDiary.error,
      spray: spray.error,
      sprayTasks: spray.tasks && spray.tasks.error,
      machinery: machinery.error,
      employee: employee.error,
      contractors: contractors.error,
      chemical: chemical.error,
      clients: clients.error,
      weatherStation: weatherStation.error,
      employeesBarcodes: employeesBarcodes.error,
      areas: areas.error,
      boxUnits: boxUnits.error,
      packedBoxes: packedBoxes.error,
      packingSheds: packingSheds.error,
      pallets: pallets.error,
      stock: stock.error,
      vendors: vendors.error,
      dispatches: dispatches.error,
      laborGroups: laborGroups.error,
      qualityControl: qualityControl.error,
      harvestUnits: harvestUnits.error,
      generalBarcodes: generalBarcodes.error,
      tagBarcodes: tagBarcodes.error,
      taskCreator: taskCreator.error,
      generalTasks: generalTasks.error,
      generalTasksWorkingTimes: generalTasksWorkingTimes.error,
      employeeGroups: employeeGroups.error,
      scouting: scouting.error,
      reports: reports.error,
      purchaseHistory: purchaseHistory.error
    }
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        newFarmClearError,
        farmsClearError,
        sprayDiaryClearError,
        sprayClearError,
        sprayTasksClearError,
        machineryClearError,
        employeeClearError,
        contractorsClearError,
        chemicalClearError,
        clientsClearError,
        weatherStationClearError,
        areasClearError,
        packedBoxesClearError,
        boxUnitsClearError,
        packingShedsClearError,
        employeesBarcodesClearError,
        palletsClearError,
        stockClearError,
        vendorsClearError,
        dispatchesClearError,
        laborGroupsClearError,
        qcFormsClearError,
        harvestUnitsClearError,
        generalBarcodesClearError,
        tagBarcodesClearError,
        taskCreatorClearError,
        generalTasksClearError,
        generalTasksWorkingTimesClearError,
        employeeGroupsClearError,
        scoutingClearError,
        reportsClearError,
        putMessage,
        purchaseHistoryClearError
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorsListener);
