export const actionType = {
  QC_FORM_CLEAR_ERROR: 'QC/QC_FORM_CLEAR_ERROR',

  FETCH_QC_FORMS_START: 'QC/FETCH_QC_FORMS_START',
  FETCH_QC_FORMS: 'QC/FETCH_QC_FORMS',
  FETCH_QC_FORMS_FAIL: 'QC/FETCH_QC_FORMS_FAIL',

  QC_FORM_CREATE_START: 'QC/QC_FORM_CREATE_START',
  QC_FORM_CREATE: 'QC/QC_FORM_CREATE',
  QC_FORM_CREATE_FAIL: 'QC/QC_FORM_CREATE_FAIL',

  QC_FORM_ARCHIVE_START: 'QC/QC_FORM_ARCHIVE_START',
  QC_FORM_ARCHIVE: 'QC/QC_FORM_ARCHIVE',
  QC_FORM_ARCHIVE_FAIL: 'QC/QC_FORM_ARCHIVE_FAIL',

  QC_FORM_UPDATE_START: 'QC/QC_FORM_UPDATE_START',
  QC_FORM_UPDATE: 'QC/QC_FORM_UPDATE',
  QC_FORM_UPDATE_FAIL: 'QC/QC_FORM_UPDATE_FAIL',

  QC_FORM_CLEAR: 'QC/QC_FORM_CLEAR',
  QC_FORM_SET_FORM_SET: 'QC/QC_FORM_SET',
  QC_FORM_CHANGE: 'QC_FORM/QC_FORM_CHANGE',
  ADD_FORM_INPUT: 'QC_FORM/ADD_FORM_INPUT',
  EDIT_FORM_INPUT: 'QC_FORM/EDIT_FORM_INPUT',
  REMOVE_FORM_INPUT: 'QC_FORM/REMOVE_FORM_INPUT',
};