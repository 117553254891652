import { Icon } from "semantic-ui-react";
import PropTypes from "prop-types";
import React from "react";
import styles from "./NavigateBackButton.module.css";

const NavigateBackButton = ({ onClick }) => (
  <span className={styles.backIconWrapper} onClick={onClick}>
    <Icon name="angle left" className={styles.backIcon} />
  </span>
);

NavigateBackButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default NavigateBackButton;
