export const actionType = {
  FETCH_PACKED_BOXES_START: 'PACKED_BOXES/FETCH_PACKED_BOXES_START',
  FETCH_PACKED_BOXES: 'PACKED_BOXES/FETCH_PACKED_BOXES',
  FETCH_PACKED_BOXES_FAIL: 'PACKED_BOXES/FETCH_PACKED_BOXES_FAIL',

  FETCH_PACKED_BOXES_COUNT_PER_EMPLOYEE_START: 'PACKED_BOXES/FETCH_PACKED_BOXES_COUNT_PER_EMPLOYEE_START',
  FETCH_PACKED_BOXES_COUNT_PER_EMPLOYEE: 'PACKED_BOXES/FETCH_PACKED_BOXES_COUNT_PER_EMPLOYEE',
  FETCH_PACKED_BOXES_COUNT_PER_EMPLOYEE_FAIL: 'PACKED_BOXES/FETCH_PACKED_BOXES_COUNT_PER_EMPLOYEE_FAIL',

  GET_PACKED_BOXES_BY_ID_START: 'PACKED_BOXES/GET_PACKED_BOXES_BY_ID_START',
  GET_PACKED_BOXES_BY_ID: 'PACKED_BOXES/GET_PACKED_BOXES_BY_ID',
  GET_PACKED_BOXES_BY_ID_FAIL: 'PACKED_BOXES/GET_PACKED_BOXES_BY_ID_FAIL',

  ADD_PACKED_BOXES_START: 'PACKED_BOXES/ADD_PACKED_BOXES_START',
  ADD_PACKED_BOXES: 'PACKED_BOXES/ADD_PACKED_BOXES',
  ADD_PACKED_BOXES_FAIL: 'PACKED_BOXES/ADD_PACKED_BOXES_FAIL',

  UPDATE_PACKED_BOXES_START: 'PACKED_BOXES/UPDATE_START',
  UPDATE_PACKED_BOXES: 'PACKED_BOXES/UPDATE',
  UPDATE_PACKED_BOXES_FAIL: 'PACKED_BOXES/UPDATE_FAIL',

  BATCH_UPDATE_PACKED_BOXES_START: 'PACKED_BOXES/BATCH_UPDATE_START',
  BATCH_UPDATE_PACKED_BOXES: 'PACKED_BOXES/BATCH_UPDATE',
  BATCH_UPDATE_PACKED_BOXES_FAIL: 'PACKED_BOXES/BATCH_UPDATE_FAIL',

  DELETE_PACKED_BOXES_START: 'PACKED_BOXES/DELETE_PACKED_BOXES_START',
  DELETE_PACKED_BOXES: 'PACKED_BOXES/DELETE_PACKED_BOXES',
  DELETE_PACKED_BOXES_FAIL: 'PACKED_BOXES/DELETE_PACKED_BOXES_FAIL',

  CLEAR_PACKED_BOXES_ERROR: 'PACKED_BOXES/CLEAR_PACKED_BOXES_ERROR',
};
