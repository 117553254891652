import MultiSelectFilter from "./MultiSelectFilter/MultiSelectFilter";
import SingleSelectFilter from "./SingleSelectFilter/SingleSelectFilter";
import DateRangeFilter from "./DateRangeFilter";
import AreaMultiSelectFilter from "./AreaMultiSelectFilter";
import ContractorMultiSelectFilter from "./ContractorMultiSelectFilter";
import EmployeeMultiSelectFilter from "./EmployeeMultiSelectFilter";
import BoxUnitMultiSelectFilter from "./BoxUnitMultiSelectFilter";
import VarietyMultiSelectFilter from "./VarietyMultiSelectFilter";
import UsedVarietyMultiSelectFilter from "./UsedVarietyMultiSelectFilter";
import GeneralTasksTypesMultiSelectFilter from "./GeneralTasksTypesMultiSelectFilter";
import GeneralSubTaskNameMultiSelectFilter from "./GeneralSubTaskNameMultiSelectFilter";
import EmployeeGroupsMultiSelectFilter from "./EmployeeGroupsMultiSelectFilter";
import GeneralTaskIDMultiSelectFilter from "./GeneralTaskIDMultiSelectFilter";
import GeneralTaskNamesMultiSelectFilter from "./GeneralTaskNamesMultiSelectFilter";
import BatchIDMultiSelectFilter from "./BatchIDMultiSelectFilter";
import BarcodeIDMultiSelectFilter from "./BarcodeIDMultiSelectFilter";
import FarmMultiSelectFilter from "./FarmMultiSelectFilter";
import ApplicationTypeMultiSelectFilter from "./ApplicationTypeMultiSelectFilter";
import ProductMultiSelectFilter from "./ProductMultiSelectFilter";
import ActiveIngredientsMultiSelectFilter from "./ActiveIngredientsMultiSelectFilter";
import ProductTypeSingleMultiFilter from "./ProductTypeSingleMultiFilter";
import SeasonSingleSelectFilter from "./SeasonsSingleSelect";
import FarmMultiSelectFilterName from "./FarmSingleSelectFilterName";
import ScoutingNameMultiSelectFilter from "./ScoutingNameMultiSelectFilter";
import CropMultiSelectFilter from "./CropMultiSelectFilter";
import ScoutingElementFilter from "./ScoutingElementFilter";

export const filterTypes = {
  DateRangeFilter,
  MultiSelectFilter,
  SingleSelectFilter,
  AreaMultiSelectFilter,
  ContractorMultiSelectFilter,
  BoxUnitMultiSelectFilter,
  EmployeeMultiSelectFilter,
  SeasonSingleSelectFilter,
  VarietyMultiSelectFilter,
  UsedVarietyMultiSelectFilter,
  GeneralTasksTypesMultiSelectFilter,
  GeneralSubTaskNameMultiSelectFilter,
  EmployeeGroupsMultiSelectFilter,
  GeneralTaskIDMultiSelectFilter,
  GeneralTaskNamesMultiSelectFilter,
  ProductMultiSelectFilter,
  ActiveIngredientsMultiSelectFilter,
  ProductTypeSingleMultiFilter,
  FarmMultiSelectFilter,
  ApplicationTypeMultiSelectFilter,
  FarmMultiSelectFilterName,
  ScoutingElementFilter,
  ScoutingNameMultiSelectFilter,
  BatchIDMultiSelectFilter,
  BarcodeIDMultiSelectFilter,
  CropMultiSelectFilter
};

const multiSelectParser = (option, value) => {
  if (!value || value === "*") return [];
  return value
    .split(",")
    .filter(value => value)
    .reduce((arr, value) => {
      arr.push([option.id, value]);
      return arr;
    }, []);
};

const singleSelectParser = (option, value) => {
  if (!value || value === "*") return [];
  return [[option.id, value]];
};

export const filterTypesParser = {
  DateRangeFilter: ({ fromKey = "From", toKey = "To", ...option }, value) => {
    if (!value || value === "*") return [];
    const arr = [];
    const values = value.split(",");
    values[0] && arr.push([`${option.id}${fromKey}`, values[0]]);
    values[1] && arr.push([`${option.id}${toKey}`, values[1]]);
    return arr;
  },
  SingleSelectFilter: singleSelectParser,
  MultiSelectFilter: multiSelectParser,
  ProductMultiSelectFilter: multiSelectParser,
  ActiveIngredientsMultiSelectFilter: multiSelectParser,
  ProductTypeSingleMultiFilter: multiSelectParser,
  FarmMultiSelectFilter: singleSelectParser,
  SeasonSingleSelectFilter: singleSelectParser,
  ApplicationTypeMultiSelectFilter: multiSelectParser,
  FarmMultiSelectFilterName: multiSelectParser,
  AreaMultiSelectFilter: multiSelectParser,
  ContractorMultiSelectFilter: multiSelectParser,
  BoxUnitMultiSelectFilter: multiSelectParser,
  EmployeeMultiSelectFilter: multiSelectParser,
  VarietyMultiSelectFilter: multiSelectParser,
  UsedVarietyMultiSelectFilter: multiSelectParser,
  GeneralTasksTypesMultiSelectFilter: multiSelectParser,
  GeneralSubTaskNameMultiSelectFilter: multiSelectParser,
  EmployeeGroupsMultiSelectFilter: multiSelectParser,
  GeneralSubTaskIDMultiSelectFilter: multiSelectParser,
  GeneralTaskIDMultiSelectFilter: multiSelectParser,
  GeneralTaskNamesMultiSelectFilter: multiSelectParser,
  ScoutingElementFilter: multiSelectParser,
  ScoutingNameMultiSelectFilter: multiSelectParser,
  BatchIDMultiSelectFilter: multiSelectParser,
  BarcodeIDMultiSelectFilter: multiSelectParser,
  CropMultiSelectFilter: multiSelectParser
};
