import React from "react";
import PropTypes from "prop-types";
import { Input } from "semantic-ui-react";
import { validationErrors } from "../../../../../utils/validationRules";
import ErrorLabel from "../../../../../components/ErrorLabel";
import CheckedInputRow from "../../../../../components/CheckedInputRow/CheckedInputRow";

const PricingDetails = ({
  pickingPrice,
  paddingPrice,
  cartingPrice,
  showCheckbox,
  changeValue,
  isActive,
  setActive
}) => {
  return (
    <>
      <CheckedInputRow
        name={"pickingPrice"}
        showCheckbox={showCheckbox}
        required={!showCheckbox || isActive("pickingPrice")}
        label={"Picking price"}
        isActive={isActive}
        setActive={setActive}
      >
        <Input
          fluid
          name="pickingPrice"
          size="large"
          type="number"
          validations="isNotNegativeFloat"
          validationErrors={validationErrors({
            isDefaultRequiredValue: "Picking price"
          })}
          errorLabel={ErrorLabel}
          value={pickingPrice}
          required
          onChange={event => changeValue("pickingPrice", event.target.value)}
          label="$"
          labelPosition="left"
        />
      </CheckedInputRow>
      <CheckedInputRow
        name={"paddingPrice"}
        showCheckbox={showCheckbox}
        required={!showCheckbox || isActive("paddingPrice")}
        label={"Padding price"}
        isActive={isActive}
        setActive={setActive}
      >
        <Input
          fluid
          name="paddingPrice"
          size="large"
          type="number"
          validations="isNotNegativeFloat"
          validationErrors={validationErrors({
            isDefaultRequiredValue: "Padding price"
          })}
          errorLabel={ErrorLabel}
          value={paddingPrice}
          required
          onChange={event => changeValue("paddingPrice", event.target.value)}
          label="$"
          labelPosition="left"
        />
      </CheckedInputRow>
      <CheckedInputRow
        name={"cartingPrice"}
        showCheckbox={showCheckbox}
        required={!showCheckbox || isActive("cartingPrice")}
        label={"Carting price"}
        isActive={isActive}
        setActive={setActive}
      >
        <Input
          fluid
          name="cartingPrice"
          size="large"
          type="number"
          validations="isNotNegativeFloat"
          validationErrors={validationErrors({
            isDefaultRequiredValue: "Carting price"
          })}
          errorLabel={ErrorLabel}
          value={cartingPrice}
          required
          onChange={event => changeValue("cartingPrice", event.target.value)}
          label="$"
          labelPosition="left"
        />
      </CheckedInputRow>
    </>
  );
};

PricingDetails.propTypes = {
  pickingPrice: PropTypes.string,
  paddingPrice: PropTypes.string,
  cartingPrice: PropTypes.string,
  setActive: PropTypes.func,
  changeValue: PropTypes.func,
  isActive: PropTypes.bool,
  showCheckbox: PropTypes.bool,
  route: PropTypes.object
};

export default PricingDetails;
