import React, { useState } from "react";
import moment from "moment";
import { useFormikContext } from "formik";
import {
  Icon,
  Segment,
  Grid,
  Container,
  Modal,
  Button,
  Popup
} from "semantic-ui-react";
import { get } from "lodash";
import NewProductForm from "./NewProductForm";
import styles from "./NewProduct.module.css";
import { matterToUnits } from "utils/constToUnits";
import { useSelector } from "react-redux";
import { shedsListSelector } from "selectors/sheds";
import { chemicalsOptionsSelector } from "selectors/chemicals";
import Numeric from "components/Numeric/Numeric";

const NewProductRow = ({
  quantity,
  setValidation,
  productKey,
  productId,
  setCheckForm,
  setDirty,
  productsList,
  quantityIndex,
  productIndex,
  handleProductDelete
}) => {
  const { values, setFieldValue } = useFormikContext();
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const shedList = useSelector(shedsListSelector);
  const productList = useSelector(chemicalsOptionsSelector);
  const {
    batchNumber,
    expirationDate,
    shedId,
    manufacturerDate,
    unitSize,
    unitsNumber
  } = quantity;

  const onDeleteClick = async () => {
    if (values.quantities.length === 1) {
      handleProductDelete(productId, productIndex);
    } else {
      setFieldValue(
        "quantities",
        values.quantities.filter(i => i !== quantity)
      );
    }
    setShowDeleteModal(false);
  };

  if (isEditing) {
    return (
      <Grid.Row className={styles.root}>
        <Segment as={Container}>
          <NewProductForm
            setValidation={setValidation}
            setDirty={setDirty}
            setCheckForm={setCheckForm}
            quantity={quantity}
            onSubmit={newValue => {
              setCheckForm(false);
              setFieldValue(`quantities.${quantityIndex}`, newValue);
              setIsEditing(false);
            }}
            onCancel={() => setIsEditing(false)}
          />
        </Segment>
      </Grid.Row>
    );
  }
  return (
    <Grid.Row className={styles.newProductRow} as={Segment}>
      <Modal
        className={styles.deleteModal}
        closeOnDimmerClick={false}
        size="mini"
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
      >
        <Modal.Content className={styles.deleteMessageWrapper}>
          {values.quantities.length > 1
            ? ` Delete  batch number ${get(quantity, "batchNumber")}?`
            : `Delete ${get(
                productsList.find(item => item.key == productKey),
                "text"
              )} from purchase receipt?`}
        </Modal.Content>
        <Modal.Actions>
          <Button
            className={styles.cancelDltBtn}
            onClick={() => setShowDeleteModal(false)}
          >
            Cancel
          </Button>
          <Button
            className={styles.deleteBtn}
            positive
            onClick={() => onDeleteClick()}
          >
            Delete
          </Button>
        </Modal.Actions>
      </Modal>

      <Popup
        trigger={
          <Grid.Column className={styles.firstValueColumn}>
            {batchNumber}
          </Grid.Column>
        }
        position="top left "
        content={<div className={styles.meesageContainer}>{batchNumber}</div>}
        inverted
        disabled={!unitsNumber}
      />
      <Popup
        trigger={
          <Grid.Column className={styles.valueColumn}>
            {unitsNumber}
          </Grid.Column>
        }
        position="top left "
        content={<div className={styles.meesageContainer}>{unitsNumber}</div>}
        inverted
        disabled={!unitsNumber}
      />
      <Popup
        trigger={
          <Grid.Column className={styles.valueColumn}>
            <Numeric
              fractionDigits={unitSize > 1000 ? 0 : 2}
              value={unitSize}
              commaSeparatorOnThousands
              units={` ${
                values.chemicalId
                  ? matterToUnits(
                      get(
                        productList.find(
                          item => item.key == values.chemicalId && item
                        ),
                        "matter"
                      )
                    )
                  : " Kg"
              }
                `}
              unitsPosition="right"
            />
          </Grid.Column>
        }
        position="top left "
        content={
          <div className={styles.meesageContainer}>
            {unitSize}
            {values.chemicalId
              ? matterToUnits(
                  get(
                    productList.find(
                      item => item.key == values.chemicalId && item
                    ),
                    "matter"
                  )
                )
              : "Kg"}
          </div>
        }
        inverted
        disabled={!unitsNumber}
      />
      <Popup
        trigger={
          <Grid.Column className={styles.valueColumn}>
            <Numeric
              fractionDigits={unitsNumber * unitSize > 1000 ? 0 : 2}
              value={unitsNumber * unitSize}
              commaSeparatorOnThousands
              units={` ${
                values.chemicalId
                  ? matterToUnits(
                      get(
                        productList.find(
                          item => item.key == values.chemicalId && item
                        ),
                        "matter"
                      )
                    )
                  : " Kg"
              }
                `}
              unitsPosition="right"
            />
          </Grid.Column>
        }
        position="top left "
        content={
          <div className={styles.meesageContainer}>
            {unitsNumber * unitSize}{" "}
            {values.chemicalId
              ? matterToUnits(
                  get(
                    productList.find(
                      item => item.key == values.chemicalId && item
                    ),
                    "matter"
                  )
                )
              : "Kg"}
          </div>
        }
        inverted
        disabled={!unitsNumber}
      />

      <Grid.Column>
        {manufacturerDate ? moment(manufacturerDate).format("DD/MM/YYYY") : "-"}
      </Grid.Column>
      <Grid.Column>
        {expirationDate ? moment(expirationDate).format("DD/MM/YYYY") : "-"}
      </Grid.Column>
      <Grid.Column className={styles.valueColumn}>
        {get(
          shedList.find(item => item.key == shedId && item),
          "text"
        )}
      </Grid.Column>
      <Grid.Column
        className={styles.newProductsButtonCantainer}
        textAlign="right"
      >
        <Popup
          inverted
          position="bottom center"
          content={"Edit"}
          size="tiny"
          trigger={
            <div className={styles.editButtonWrapper}>
              <Button
                onClick={() => {
                  return setCheckForm(true), setIsEditing(true);
                }}
                className={styles.editButton}
                floated="right"
                icon={<Icon name="edit" />}
                size="small"
              />
            </div>
          }
        />
        <Popup
          inverted
          position="bottom center"
          content={"Delete"}
          size="tiny"
          trigger={
            <div className={styles.deleteButton}>
              <Icon
                name="trash alternate"
                onClick={() => setShowDeleteModal(true)}
              />
            </div>
          }
        />
      </Grid.Column>
    </Grid.Row>
  );
};

export default NewProductRow;
