import { axiosProxy } from '../../utils/axiosProxy';
import { actionType } from '../../constants/Machineries';

const url = '/machineries';

export const addMachinery = (data, withError = false) => async dispatch => {
  try {
    dispatch({
      type: actionType.ADD_MACHINERY_START,
    });
    const res = await axiosProxy({
      method: 'POST',
      data,
      url,
    });

    await dispatch({
      type: actionType.ADD_MACHINERY,
      payload: res.data,
    });

    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.ADD_MACHINERY_FAIL,
      payload: error,
    });
    if (withError) {
      return {
        error,
      };
    }
  }
};

export const updateMachinery = (id, data, withError = false) => async dispatch => {
  try {
    dispatch({
      type: actionType.UPDATE_MACHINERY_START,
    });
    const res = await axiosProxy({
      method: 'PUT',
      data,
      url: `${url}/${id}`,
    });

    await dispatch({
      type: actionType.UPDATE_MACHINERY,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.UPDATE_MACHINERY_FAIL,
      payload: error,
    });
    if (withError) {
      return {
        error,
      };
    }
  }
};

export const getTrackerRoute = (machineryId, from, to) => async dispatch => {
  const url = `/machineries/${machineryId}/tracker`;

  try {
    dispatch({
      type: actionType.GET_TRACKER_ROUTE_START,
    });

    const params = new URLSearchParams();
    params.append('from', from);
    params.append('to', to);
    const res = await axiosProxy({
      method: 'GET',
      params,
      url,
    });

    await dispatch({
      type: actionType.GET_TRACKER_ROUTE,
      payload: { machineryId, route: res.data },
    });
  } catch (error) {
    dispatch({
      type: actionType.GET_TRACKER_ROUTE_FAIL,
      payload: error,
    });
  }
};

export const clearTrackerRoute = () => dispatch => {
  dispatch({
    type: actionType.CLEAR_TRACKER_ROUTE,
  });
};

export const changeValue = (field, value) => dispatch => {
  dispatch({
    type: actionType.CHANGE_VALUE,
    payload: { [field]: value },
  });
};

export const fetchMachineryAction = ({ page, size, search, sort, unpaged = false }) => async dispatch => {
  try {
    dispatch({
      type: actionType.FETCH_MACHINERY_START,
    });

    const params = new URLSearchParams();
    if (unpaged) {
      params.append('unpaged', true);
    } else {
      params.append('page', page);
      params.append('size', size);
    }

    if (search) {
      params.append('search', search);
    }

    (sort || ['id,asc']).forEach(field => {
      params.append('sort', field);
    });

    const res = await axiosProxy({
      method: 'GET',
      params,
      url,
    });

    await dispatch({
      type: actionType.FETCH_MACHINERY,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.FETCH_MACHINERY_ERROR,
      payload: error,
    });
  }
};

export const changeMachineryPageAction = page => dispatch => {
  dispatch({
    type: actionType.CHANGE_MACHINERY_PAGE,
    payload: page,
  });
};

export const changePageSizeAction = pageSize => dispatch => {
  dispatch({
    type: actionType.CHANGE_MACHINERY_PAGE_SIZE,
    payload: pageSize,
  });
};

export const deleteMachineryAction = id => async dispatch => {
  try {
    dispatch({
      type: actionType.DELETE_MACHINERY_START,
    });
    await axiosProxy({
      method: 'DELETE',
      url: `${url}/${id}`,
    });

    await dispatch({
      type: actionType.DELETE_MACHINERY,
      payload: id,
    });
  } catch (error) {
    dispatch({
      type: actionType.DELETE_MACHINERY_FAIL,
      payload: error,
    });
  }
};

export const getMachineryById = id => async dispatch => {
  try {
    dispatch({
      type: actionType.GET_MACHINERY_BY_ID,
    });
    const res = await axiosProxy({
      method: 'GET',
      url: `${url}/${id}`,
    });

    dispatch({
      type: actionType.GET_MACHINERY_BY_ID_SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.GET_MACHINERY_BY_ID_ERROR,
      payload: error,
    });
    return Promise.reject(error);
  }
};

export const clearCreator = () => dispatch => {
  dispatch({
    type: actionType.CLEAR_MACHINERY_CREATOR,
  });
};
export const clearMachineryError = () => dispatch => {
  dispatch({
    type: actionType.CLEAR_MACHINERY_ERROR,
    payload: null,
  });
};
