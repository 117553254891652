import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Form, Input } from "semantic-ui-react";
import { Field, Formik, useFormikContext } from "formik";
import { get, map } from "lodash";
import SelectProductInput from "./SelectProductInput";
import styles from "./Threshold.module.css";
import { matterToUnits } from "utils/constToUnits";
import * as Yup from "yup";

const REQUIRED_FIELDS = ["chemicalId", "threshold"];

const ThresholdForm = ({
  shed,
  thresholdData,
  onSubmit,
  unit,
  onCancel,
  thresholdsListCheck,
  deleteButtonCheck,
  deleteAddingForm
}) => {
  const { values: parentValues } = useFormikContext();
  const [resetRequired, setResetRequired] = useState(false);

  const validationSchema = () => {
    return Yup.object().shape({
      threshold: Yup.number().required("threshold is required")
    });
  };
  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      validate={values => {
        const errors = {};

        REQUIRED_FIELDS.forEach(fieldName => {
          if (!get(values, fieldName)) {
            errors[fieldName] = true;
          }
        });

        return errors;
      }}
      onSubmit={values => {
        onSubmit(values);
        setResetRequired(true);
      }}
      initialValues={thresholdData || {}}
    >
      {({ values, dirty, isValid, setValues, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Field width={7} required>
              <label>Product</label>
              <Field
                placeholder="Select a product"
                as={SelectProductInput}
                name="product"
                required
                value={get(values, "chemicalId")}
                usedProductsIds={map(parentValues.thresholds, "chemicalId")}
                usedBucketsIds={map(parentValues.buckets, "chemicalId")}
                onChange={({ key, text, matter }) => {
                  setValues({
                    ...values,
                    chemicalId: key,
                    name: text,
                    threshold: values.threshold,
                    matter
                  });
                }}
              />
            </Form.Field>
            <Form.Field width={2} />
            <Form.Field width={7} required>
              <label>{thresholdData ? "Threshold" : "Quantity"}</label>
              <Field
                as={Input}
                name="threshold"
                label={
                  thresholdData
                    ? unit
                    : `$/${matterToUnits(values.matter) || "unit"}`
                }
                labelPosition="right"
                onKeyPress={event => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Field>
          </Form.Group>
          <div className={styles.buttonContainer}>
            {onCancel && (
              <Button className={styles.cancelBtn} onClick={onCancel}>
                Cancel
              </Button>
            )}
            {deleteButtonCheck && thresholdsListCheck ? (
              <Button
                type="submit"
                className={styles.cancelBtn}
                onClick={deleteAddingForm}
              >
                Cancel
              </Button>
            ) : null}
            <Button
              type="submit"
              className={
                !dirty || !isValid
                  ? styles.disabledBtn
                  : "button-text color-green"
              }
              disabled={!dirty || !isValid}
            >
              {thresholdData ? "Edit" : "Save"}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

ThresholdForm.propTypes = {
  thresholdData: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func
};

export default ThresholdForm;
