import { createSelector } from "reselect";

const purchaseHistory = state => state.purchaseHistory;
const suppliers = state => state.purchaseHistory.suppliers;
const batchNumber = state => state.purchaseHistory.batchNumber;
const manufacturer = state => state.purchaseHistory.manufacturer;

export const activeItemSelector = createSelector(
  [purchaseHistory],
  purchaseData => {
    const { activeItem, data } = purchaseData;
    if (activeItem && activeItem.id) {
      return data.content.find(({ id }) => activeItem.id === id);
    }
    return null;
  }
);

export const suppliersOptionsSelector = createSelector(
  [suppliers],
  ({ content }) =>
    content
      ? content.map(item => ({
          text: item,
          value: item
        }))
      : []
);

export const supplierSelector = createSelector([suppliers], ({ content }) => ({
  options: content
    ? content.map(item => ({
        key: item,
        label: item,
        value: item
      }))
    : []
}));
export const batchNumberSelector = createSelector(
  [batchNumber],
  ({ content }) => ({
    options: content
      ? content.map(item => ({
          key: item,
          label: item,
          value: item
        }))
      : []
  })
);
export const manufacturerSelector = createSelector(
  [manufacturer],
  ({ content }) => ({
    options: content
      ? content.map(item => ({
          key: item,
          label: item,
          value: item
        }))
      : []
  })
);
export const manufacturerOptionsSelector = createSelector(
  [manufacturer],
  ({ content }) =>
    content
      ? content.map(item => ({
          text: item,
          value: item
        }))
      : []
);
