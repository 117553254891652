import React from "react";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Label,
  Legend,
  Line,
  Bar,
  ReferenceArea,
  Tooltip,
  XAxis,
  YAxis,
  Dot,
  ResponsiveContainer
} from "recharts";
import moment from "moment";
import _ from "lodash";
import PropTypes from "prop-types";
import ObjectID from "bson-objectid";
import { dataGroups } from "../../../../reducers/WeatherStation";
import styles from "./StationGraph.module.css";
import { Button } from "semantic-ui-react";
import { formatInUTC } from "../../../../utils/timezoneUtils";

function getSeriesId(series) {
  return `${series.name}_${series.type}`;
}

const prepareData = options => {
  const dataMap = {};
  options.series.forEach(series => {
    series.data.forEach(dataPoint => {
      if (Array.isArray(dataPoint)) {
        dataMap[dataPoint[0]] = {
          ...dataMap[dataPoint[0]],
          x: dataPoint[0],
          [getSeriesId(series)]:
            dataPoint.length > 2 ? dataPoint.slice(1) : dataPoint[1]
        };
      } else {
        if (dataPoint.marker) {
          return;
        }

        dataMap[dataPoint.x] = {
          ...dataMap[dataPoint.x],
          x: dataPoint.x,
          [getSeriesId(series)]: dataPoint.y
        };
      }
    });
  });

  return Object.keys(dataMap).map(key => dataMap[key]);
};

const generateYAxises = options => {
  const visibleYAxis = options.yAxis.filter(y => y.visible !== false).length;
  return (
    options.yAxis
      // TODO: support other yAxis types
      .map((yAxis, index) => {
        return (
          <YAxis
            key={(yAxis.title && yAxis.title.text) || ObjectID().toHexString()}
            yAxisId={index}
            type="number"
            allowDataOverflow={true}
            domain={[
              dataMin =>
                Math.floor(dataMin < 0 ? dataMin : Math.max(dataMin - 2, 0)),
              dataMax => Math.ceil(dataMax + Math.max(dataMax * 0.1, 1))
            ]}
            orientation={index === 0 ? "left" : "right"}
            hide={
              yAxis.visible === false ||
              (visibleYAxis > 1 && window && window.innerWidth < 768)
            }
            ticks={yAxis.tickPositions}
          >
            <Label
              className={
                index === 0 ? styles.labelAxisYLeft : styles.labelAxisYRight
              }
              value={yAxis.title && yAxis.title.text}
              angle={-35}
              position={index === 0 ? "insideLeft" : "insideRight"}
            />
          </YAxis>
        );
      })
  );
};

const initialState = {
  disabled: [],
  refAreaLeft: "",
  refAreaRight: "",
  left: "dataMin",
  right: "dataMax",
  animation: true
};

const chartsOrder = {
  areaspline: 10,
  area: 20,
  column: 40,
  arearange: 49,
  line: 50,
  scatter: 100,
  default: 50
};

class StationGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      data: prepareData(props.options),
      yAxises: generateYAxises(props.options)
    };
  }

  componentDidUpdate(prevProps) {
    const { options } = this.props;
    if (prevProps.options !== options) {
      this.setState({
        data: prepareData(options),
        yAxises: generateYAxises(options)
      });
    }
  }

  zoom() {
    let { refAreaLeft, refAreaRight, data } = this.state;
    if (refAreaLeft === refAreaRight || refAreaRight === "") {
      this.setState(() => ({
        refAreaLeft: "",
        refAreaRight: ""
      }));
      return;
    }

    // xAxis domain
    if (refAreaLeft > refAreaRight)
      [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];

    this.setState(() => ({
      data: data.slice(),
      refAreaLeft: "",
      refAreaRight: "",
      left: refAreaLeft,
      right: refAreaRight
    }));
  }

  zoomOut = () => {
    const { data } = this.state;
    this.setState(() => ({
      data: data.slice(),
      refAreaLeft: "",
      refAreaRight: "",
      left: "dataMin",
      right: "dataMax"
    }));
  };

  renderLegendSeriesText = (_, entry) => {
    const { disabled } = this.state;
    const { value } = entry;
    const active = !disabled.includes(value);
    const style = {
      marginRight: 10,
      color: active ? "#000" : "#AAA"
    };
    return (
      <span style={style} onClick={() => this.handleLegendClick(value)}>
        {value}
      </span>
    );
  };

  handleLegendClick = ({ value }) => {
    const { disabled } = this.state;
    if (disabled.includes(value)) {
      this.setState({
        disabled: disabled.filter(obj => obj !== value)
      });
    } else {
      this.setState({ disabled: [...disabled, value] });
    }
  };

  getActiveSeries = () => {
    const { disabled } = this.state;
    const { options } = this.props;
    return options.series.filter(
      series => series.visible && !_.includes(disabled, series.name)
    );
  };

  render() {
    const { dataGroup, options } = this.props;
    const {
      yAxises,
      left,
      right,
      refAreaLeft,
      refAreaRight,
      data
    } = this.state;

    return (
      <div className={styles.chartHolder}>
        <Button
          className={`button-text ${styles.zoomOut}`}
          onClick={this.zoomOut}
          disabled={left === "dataMin" && right === "dataMax"}
        >
          Zoom Out
        </Button>
        <ResponsiveContainer width="100%" height={300}>
          <ComposedChart
            data={data}
            width={1100}
            height={300}
            onMouseDown={e =>
              e && this.setState({ refAreaLeft: e.activeLabel })
            }
            onMouseMove={e =>
              this.state.refAreaLeft &&
              this.setState({ refAreaRight: e.activeLabel })
            }
            onMouseUp={this.zoom.bind(this)}
            className={styles.chart}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            {options.xAxis && (
              <XAxis
                allowDataOverflow={true}
                dataKey={"x"}
                domain={[left, right]}
                type={"number"}
                ticks={data.map(row => row.x)}
                tickFormatter={value => {
                  if (options.xAxis.type !== "datetime") {
                    return value;
                  }

                  const date = moment(value);
                  switch (dataGroup) {
                    case dataGroups.RAW:
                    case dataGroups.HOURLY:
                      // Note: the space in ` HH:mm ` is order to make sure that it in the same of `MMM DD`, otherwise it might
                      // not show when there is no space
                      return date.hours() === 0
                        ? formatInUTC(value, "MMM DD")
                        : formatInUTC(value, " HH:mm ");
                    default:
                      return formatInUTC(value, "MMM DD");
                  }
                }}
              />
            )}
            {yAxises}
            <CartesianGrid />
            <Tooltip
              labelFormatter={value => {
                if (options.xAxis.type !== "datetime") {
                  return value;
                }

                return formatInUTC(value);
              }}
              formatter={(value, name, props) => {
                return value
                  ? `${Array.isArray(value) ? value[0] : value} ${
                      value[1] ? ` -  ${value[1]}` : ""
                    }`
                  : "";
              }}
            />
            <Legend
              onClick={this.handleLegendClick}
              payload={options.series
                .filter(series => !series.linkedTo && series.visible)
                .map(series => ({
                  value: series.name,
                  color: series.color,
                  type: "line",
                  formatter: this.renderLegendSeriesText
                }))}
            />
            {this.getActiveSeries()
              .sort((s1, s2) => {
                return (
                  (chartsOrder[s1.type] || chartsOrder.default) -
                  (chartsOrder[s2.type] || chartsOrder.default)
                );
              })
              .map(series => {
                let chart;
                switch (series.type) {
                  case "arearange":
                  case "areaspline":
                  case "area":
                    chart = (
                      <Area
                        key={`area_${series.name}`}
                        name={series.name}
                        yAxisId={series.yAxis}
                        dataKey={getSeriesId(series)}
                        stroke={series.color}
                        fill={series.color}
                        connectNulls
                        animationDuration={300}
                        className={"aaa"}
                      />
                    );
                    break;
                  case "column":
                    chart = (
                      <Bar
                        key={`area_${series.name}`}
                        name={series.name}
                        yAxisId={series.yAxis}
                        dataKey={getSeriesId(series)}
                        fill={series.color}
                        animationDuration={300}
                      />
                    );
                    break;
                  case "scatter":
                    chart = (
                      <Line
                        key={`line_${series.name}`}
                        name={series.name}
                        yAxisId={series.yAxis}
                        dataKey={getSeriesId(series)}
                        type="natural"
                        stroke={"none"}
                        animationDuration={300}
                        dot={props => {
                          return props.points.length < 100 ? (
                            <Dot style={{ stroke: series.color }} {...props} />
                          ) : null;
                        }}
                      />
                    );
                    break;
                  default:
                    chart = (
                      <Line
                        key={`line_${series.name}`}
                        name={series.name}
                        yAxisId={series.yAxis}
                        dataKey={getSeriesId(series)}
                        type="natural"
                        stroke={series.color}
                        connectNulls
                        strokeWidth={2}
                        animationDuration={300}
                        dot={props => {
                          return props.points.length < 50 ? (
                            <Dot {...props} />
                          ) : null;
                        }}
                      />
                    );
                }
                return chart;
              })}
            {refAreaLeft && refAreaRight ? (
              <ReferenceArea
                yAxisId={yAxises[0].props.yAxisId}
                x1={refAreaLeft}
                x2={refAreaRight}
                strokeOpacity={0.3}
              />
            ) : null}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

StationGraph.propTypes = {
  options: PropTypes.object.isRequired,
  dataGroup: PropTypes.object
};

StationGraph.defaultProps = {};

export default StationGraph;
