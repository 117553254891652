import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Grid, Header } from "semantic-ui-react";
import TaskRow from "./../TaskRow";
import {
  selectMachinery,
  clearMachinery,
  setMachineryConc,
  setMachinerySpeed,
  setNozzle,
  setNozzlesPerSide,
  setGear,
  setRpm,
  setPressure,
  setPto,
  addAndSelectMachinery
} from "../../../../actions/SprayDiary/machinery";
import TaskHeader from "../TaskHeader";
import { machinerySelector } from "../../../../selectors/sprayDiary";
import MachineryForm from "../../../Machinery/AddNewMachinery/MachineryForm";
import LayoutOverlay from "../../../../components/LayoutOverlay";
import styles from "./Machinery.module.css";
import App from "../../../../App";

class Machinery extends Component {
  state = {
    showMachineryModal: false
  };
  newMachineryForm = React.createRef();

  handleCloseMachineryModal = () => {
    const { setExternalField } = this.props;
    this.setState({
      showMachineryModal: false
    });
    setExternalField(false);
  };

  createMachinerySubmit = data => {
    const {
      actions: { andAndSelectMachinery }
    } = this.props;
    this.setState({
      showMachineryModal: false
    });
    andAndSelectMachinery(data);
  };

  onChangeMachinery = value => {
    const { onChange, setExternalField } = this.props;

    if (value === "new") {
      this.setState({
        showMachineryModal: true
      });
      setExternalField(true);
    } else {
      onChange(value);
    }
  };

  doSubmitForm = () => {
    if (this.newMachineryForm.current) {
      this.newMachineryForm.current.submit();
    }
  };

  render() {
    const {
      sprayTask,
      machineryOptions,
      fieldToEdit,
      toggleEditField,
      onChange,
      applicationType,
      shouldDisableFieldsEdit
    } = this.props;
    const { showMachineryModal } = this.state;

    return (
      <>
        <Header>Machinery</Header>
        {showMachineryModal && (
          <div className={styles.formHolder}>
            <div className={styles.formHolderIn}>
              <TaskHeader
                fieldToEdit={{}}
                sprayTaskId={sprayTask.id}
                onSave={this.doSubmitForm}
                onCancel={this.handleCloseMachineryModal}
              />
              <div className={styles.formHolderBody}>
                <Header>Add New Machinery</Header>
                <LayoutOverlay childrenOnly noOverflow>
                  <MachineryForm
                    modal={true}
                    onSubmitForm={this.createMachinerySubmit}
                    showMobileHeader
                    sprayTaskId={sprayTask.id}
                    onCancel={this.handleCloseMachineryModal}
                    formRef={this.newMachineryForm}
                  />
                </LayoutOverlay>
              </div>
            </div>
          </div>
        )}

        <Grid verticalAlign="middle" className="no-margin">
          <TaskRow
            label="Machinery"
            name="selectedMachinery"
            inputType="dropdown"
            placeholder="Do you need to use a machine?"
            emptyLabel="Do you need to use a machine?"
            value={sprayTask.selectedMachinery.id}
            options={machineryOptions}
            toggleEditField={toggleEditField}
            fieldToEdit={fieldToEdit}
            onChange={this.onChangeMachinery}
            clearable
            search
            disabled={shouldDisableFieldsEdit}
          />
          {sprayTask.selectedMachinery &&
            sprayTask.selectedMachinery.id &&
            applicationType === "SPRAY" && (
              <>
                <TaskRow
                  label="Nozzle"
                  name="nozzle"
                  inputType="inputText"
                  fluid
                  value={sprayTask.nozzle}
                  toggleEditField={toggleEditField}
                  fieldToEdit={fieldToEdit}
                  onChange={onChange}
                  disabled={shouldDisableFieldsEdit}
                />
                <TaskRow
                  label="Nozzles per side"
                  name="nozzlesPerSide"
                  inputType="inputText"
                  fluid
                  value={sprayTask.nozzlesPerSide}
                  toggleEditField={toggleEditField}
                  fieldToEdit={fieldToEdit}
                  onChange={onChange}
                  rules={{ numeric: true }}
                  disabled={shouldDisableFieldsEdit}
                />
                <TaskRow
                  label="Gear"
                  name="gear"
                  inputType="inputText"
                  fluid
                  value={sprayTask.gear}
                  toggleEditField={toggleEditField}
                  fieldToEdit={fieldToEdit}
                  onChange={onChange}
                  disabled={shouldDisableFieldsEdit}
                />
                <TaskRow
                  label="RPM"
                  name="rpm"
                  inputType="inputText"
                  fluid
                  value={sprayTask.rpm}
                  toggleEditField={toggleEditField}
                  fieldToEdit={fieldToEdit}
                  onChange={onChange}
                  rules={{ numeric: true }}
                  disabled={shouldDisableFieldsEdit}
                />
                <TaskRow
                  label="Pressure"
                  name="pressure"
                  inputType="inputText"
                  fluid
                  value={sprayTask.pressure}
                  toggleEditField={toggleEditField}
                  fieldToEdit={fieldToEdit}
                  onChange={onChange}
                  inputLabel={{ content: "psi", inverted: "true" }}
                  labelPosition="right"
                  rules={{ numeric: true }}
                  disabled={shouldDisableFieldsEdit}
                />
                <TaskRow
                  label="Speed"
                  name="machinerySpeed"
                  inputType="inputText"
                  fluid
                  value={sprayTask.machinerySpeed}
                  toggleEditField={toggleEditField}
                  fieldToEdit={fieldToEdit}
                  onChange={onChange}
                  inputLabel={{ content: "km/h", inverted: "true" }}
                  labelPosition="right"
                  rules={{ numeric: true }}
                  disabled={shouldDisableFieldsEdit}
                />
                <TaskRow
                  label="PTO"
                  name="pto"
                  inputType="inputText"
                  fluid
                  value={sprayTask.pto}
                  toggleEditField={toggleEditField}
                  fieldToEdit={fieldToEdit}
                  onChange={onChange}
                  rules={{ numeric: true }}
                  disabled={shouldDisableFieldsEdit}
                />
                <TaskRow
                  label="Concentration/(x)"
                  name="conc"
                  inputType="inputText"
                  fluid
                  value={sprayTask.conc}
                  toggleEditField={toggleEditField}
                  fieldToEdit={fieldToEdit}
                  onChange={onChange}
                  rules={{ required: true, numeric: true }}
                  disabled={shouldDisableFieldsEdit}
                />
              </>
            )}
        </Grid>
      </>
    );
  }
}

Machinery.propTypes = {
  setExternalField: PropTypes.func,
  onChange: PropTypes.func,
  toggleEditField: PropTypes.func,
  actions: PropTypes.object,
  machineryOptions: PropTypes.object,
  fieldToEdit: PropTypes.object,
  sprayTask: PropTypes.object,
  shouldDisableFieldsEdit: PropTypes.bool
};

const mapStateToProps = state => {
  const {
    sprayDiary: {
      applicationType,
      validations: {
        selectedMachinery: machineryValidation,
        conc: concValidation,
        machinerySpeed: machinerySpeedValidation,
        nozzle: nozzleValidation,
        nozzlesPerSide: nozzlesPerSideValidation,
        gear: gearValidation,
        rpm: rpmValidation,
        pressure: pressureValidation,
        pto: ptoValidation
      }
    }
  } = state;

  return {
    applicationType,
    validations: {
      machineryValidation,
      concValidation,
      machinerySpeedValidation,
      nozzleValidation,
      nozzlesPerSideValidation,
      gearValidation,
      rpmValidation,
      pressureValidation,
      ptoValidation
    },
    machineryOptions: machinerySelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      selectMachinery,
      clearMachinery,
      setMachineryConc,
      setMachinerySpeed,
      setNozzle,
      setNozzlesPerSide,
      setGear,
      setRpm,
      setPressure,
      setPto,
      andAndSelectMachinery: addAndSelectMachinery
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(Machinery);
