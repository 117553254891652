import React, {
  useEffect,
  useMemo,
  useRef,
  useCallback,
  useState
} from "react";
import ReactDOMServer from "react-dom/server";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Layout from "../../../components/Layout";
import { Button, Grid, Header, Icon, Segment } from "semantic-ui-react";
import style from "./TotalCostPerFarm.module.css";
import { onlineSelector } from "../../../selectors/common";
import Filters from "../../../components/Filters";
import { getFiltersFromLocations } from "../../../utils/routeHelpers";
import {
  fetchCostsPerArea,
  fetchCostsPerAreaList
} from "../../../actions/Reports/reports";
import TotalCostPerAreaTable from "./components/TotalCostPerAreaTable";
import Loader from "../../../components/Loader";
import { history } from "../../../store";
import styles from "../../Farms/Farms.module.css";
import PrintReport from "./components/PrintReport";
import { copyStyles } from "../../../utils/styles";
import { mergeAreasNumericValues } from "../../../utils/mergeAreasNumericValues";
import { usePrevious } from "utils/hooks";
import { sum, round, debounce, filter } from "lodash";
import { setHashParameter } from "utils/hashToObject";
import { excelExport } from "utils/excelExport";
import moment from "moment";

const defaultFiltersOptions = [
  {
    name: "Farm Name",
    type: "FarmMultiSelectFilterName",
    id: "farmName"
  },
  {
    name: "Date",
    type: "DateRangeFilter",
    id: "date"
  },
  {
    id: "seasons",
    name: "Season",
    type: "SeasonSingleSelectFilter"
  }
];

const TotalCostPerFarm = ({
  areas,
  route,
  location,
  actions: { fetchCostsPerArea, fetchCostsPerAreaList },
  isFetching,
  content,
  data
}) => {
  const rawFilters = getFiltersFromLocations(defaultFiltersOptions, location);
  const { filters, from, to } = useMemo(() => {
    const { dateFrom, dateTo, ...filters } = rawFilters;
    const from = rawFilters.dateFrom ? rawFilters.dateFrom[0] : null;
    const to = rawFilters.dateTo ? rawFilters.dateTo[0] : null;

    return {
      filters,
      from,
      to
    };
  }, [location.hash]);
  const disableChecker = !!filters.seasons
    ? "seasons"
    : !!from || !!to
    ? "date"
    : null;

  const prevLocation = usePrevious(location);

  const fetchCosts = useRef(
    debounce((...params) => {
      return !isFetching && fetchCostsPerArea(...params);
    }, 300)
  );

  useEffect(() => {
    if (prevLocation || prevLocation?.hash !== location.hash) {
      fetchCosts.current({
        filters,
        unpaged: true,
        from,
        to
      });
    }
  }, [location]);
  const defaultSeason = data.seasons?.reduce(function(prev, current) {
    return prev.startAt > current.startAt ? prev : current;
  }, {});
  const defaultDate = [defaultSeason?.startAt, defaultSeason?.endAt];
  useEffect(() => {
    defaultSeason?.startAt &&
      defaultSeason?.endAt &&
      setHashParameter(location, "seasons", defaultDate, null);
  }, []);

  const listOfCombinedAreas = {};
  content &&
    content.forEach(area => {
      listOfCombinedAreas[area.farmId] = filter(
        content,
        item => item.farmId === area.farmId
      );
    });

  const listOfFarms = {};
  Object.values(listOfCombinedAreas).forEach(collectionOfAreas => {
    const mergedAreasData = mergeAreasNumericValues(collectionOfAreas);

    const collectedGeneralTaskCosts = [];
    collectionOfAreas.forEach(area =>
      area.generalTaskCosts.length !== 0
        ? collectedGeneralTaskCosts.push(area.generalTaskCosts)
        : ""
    );
    const collectedOperatingCosts = [];
    collectionOfAreas.forEach(area =>
      area.operatingCosts.length !== 0
        ? collectedOperatingCosts.push(area.operatingCosts)
        : ""
    );

    const listOfCombinedTasksCosts = {};
    collectedGeneralTaskCosts.flat() &&
      collectedGeneralTaskCosts.flat().forEach(task => {
        listOfCombinedTasksCosts[task.generalTaskType] = filter(
          collectedGeneralTaskCosts.flat(),
          item => item.generalTaskType === task.generalTaskType
        );
      });

    const listOfCombinedOperatingCosts = {};
    collectedOperatingCosts.flat() &&
      collectedOperatingCosts.flat().forEach(cost => {
        listOfCombinedOperatingCosts[cost.type] = filter(
          collectedOperatingCosts.flat(),
          item => item.type === cost.type
        );
      });
    const calculatedTaskCosts = [];

    Object.values(listOfCombinedTasksCosts).forEach(taskData => {
      calculatedTaskCosts.push(mergeAreasNumericValues(taskData));
    });

    const calculatedOperatingCosts = [];

    Object.values(listOfCombinedOperatingCosts).forEach(taskData => {
      calculatedOperatingCosts.push(mergeAreasNumericValues(taskData));
    });

    const initialdata = {
      farmId: collectionOfAreas[0].farmId,
      id: collectionOfAreas[0].areaId,
      clientId: collectionOfAreas[0].clientId,
      farmName: collectionOfAreas[0].farmName
    };
    listOfFarms[initialdata.farmId] = {
      areaId: initialdata.id,
      areaName: initialdata.farmName,
      areaSize: mergedAreasData.areaSize,
      binCount: mergedAreasData.binCount,
      chemicalCost: mergedAreasData.chemicalCost,
      otherExpensesCost: mergedAreasData.otherExpensesCost,
      overheadsCost: mergedAreasData.overheadsCost,
      clientId: initialdata.clientId,
      farmId: initialdata.farmId,
      farmName: initialdata.farmName,
      generalTaskCosts: calculatedTaskCosts.flat(),
      operatingCosts: calculatedOperatingCosts.flat(),
      sprayTaskLabourCost: mergedAreasData.sprayTaskLabourCost,
      sprayTaskMachineryCost: mergedAreasData.sprayTaskMachineryCost
    };
  });

  const print = () => {
    const newWindow = window.open();
    newWindow.document.title = `Total Cost Per Farm`;
    copyStyles(window.document, newWindow.document);

    newWindow.document.body.innerHTML = ReactDOMServer.renderToString(
      <PrintReport data={Object.values(listOfFarms)} />
    );
    newWindow.print();
  };

  const handleExcelExport = useCallback(
    async filters => {
      const data = await fetchCostsPerAreaList(filters);
      if (data.content.length) {
        const costsHeader = [
          ...[
            ...new Set(
              data.content
                ?.map(item => {
                  return item.generalTaskCosts?.map(
                    type => type.generalTaskType
                  );
                })
                .flat()
            )
          ],
          ...[
            ...new Set(
              data.content
                ?.map(item => {
                  return item.generalTaskCosts?.map(
                    type => type.generalTaskType
                  );
                })
                .flat()
            )
          ]
        ].sort();
        const headerColumn = [
          "Farm Name",
          "Area Size(Ha)	",
          "No of plants",
          "Chemical / Fertiliser Costs($)",
          "Operating Costs ($)",
          "Labour Costs ($)",
          "Application Task - Labour Costs($)",
          ...costsHeader.map((item, index) =>
            index % 2 == 0
              ? `${item} - Labour Costs($)`
              : `${item} - Alt Labour Costs($)`
          ),
          "Machinery Costs($)",
          "Application Task - Machinery Costs($)",
          ...costsHeader
            .map(
              (item, index) => index % 2 == 0 && `${item} - Machinery Costs($)`
            )
            .filter(item => item),
          "Cost / Harvest Unit",
          "Cost / Ha($ / Ha)",
          "Cost / plant	",
          "Total Costs($)"
        ];
        const aggregatedData = {};
        data.content &&
          data.content.forEach(item => {
            if (aggregatedData[item.farmId]) {
              aggregatedData[item.farmId].areaSize += item.areaSize;
              aggregatedData[item.farmId].binCount += item.binCount;
              aggregatedData[item.farmId].chemicalCost += item.chemicalCost;
              aggregatedData[item.farmId].otherExpensesCost +=
                item.otherExpensesCost;
              aggregatedData[item.farmId].generalTaskCosts = [
                ...aggregatedData[item.farmId].generalTaskCosts,
                item.generalTaskCosts
              ].flat();
              aggregatedData[item.farmId].operatingCosts = [
                ...aggregatedData[item.farmId].operatingCosts,
                item.operatingCosts
              ].flat();
              aggregatedData[item.farmId].overheadsCost += item.overheadsCost;
              aggregatedData[item.farmId].sprayTaskLabourCost +=
                item.sprayTaskLabourCost;
              aggregatedData[item.farmId].sprayTaskMachineryCost +=
                item.sprayTaskMachineryCost;
            } else {
              aggregatedData[item.farmId] = { ...item };
            }
          });

        const result = Object.values(aggregatedData);

        const excelData =
          result &&
          result.map(
            (
              {
                farmName,
                areaSize,
                areaId,
                sprayTaskLabourCost,
                sprayTaskMachineryCost,
                generalTaskCosts,
                chemicalCost,
                binCount,
                otherExpensesCost,
                overheadsCost
              },
              index
            ) => {
              const totalCost =
                sprayTaskLabourCost +
                sprayTaskMachineryCost +
                chemicalCost +
                otherExpensesCost +
                overheadsCost +
                sum(
                  generalTaskCosts.map(task =>
                    task.machineryCost ? task.machineryCost : 0
                  )
                ) +
                sum(
                  generalTaskCosts.map(task =>
                    task.labourCost ? task.labourCost : 0
                  )
                ) +
                sum(
                  generalTaskCosts.map(task =>
                    task.altLabourCost ? task.altLabourCost : 0
                  )
                );
              const totalCostPerHa =
                areaSize && areaSize != 0
                  ? (sprayTaskLabourCost +
                      sprayTaskMachineryCost +
                      chemicalCost +
                      otherExpensesCost +
                      overheadsCost +
                      sum(
                        generalTaskCosts.map(task =>
                          task.machineryCost ? task.machineryCost : 0
                        )
                      ) +
                      sum(
                        generalTaskCosts.map(task =>
                          task.labourCost ? task.labourCost : 0
                        )
                      ) +
                      sum(
                        generalTaskCosts.map(task =>
                          task.altLabourCost ? task.altLabourCost : 0
                        )
                      )) /
                    areaSize
                  : "No information";

              const totalPlants = areas.list.content
                .find(item => item.id === areaId)
                ?.varieties.reduce(
                  (prev, curr) => prev + curr.numberOfPlants,
                  0
                );

              const labourCostValue = {};
              const labourAltCostValue = {};
              const machineryCostValue = {};
              costsHeader.forEach((item, index) => {
                const foundTask = generalTaskCosts.find(
                  ({ generalTaskType }) => item === generalTaskType
                );

                labourCostValue[
                  `labourCost${item}`.replace(/ /g, "_")
                ] = foundTask?.labourCost
                  ? foundTask?.labourCost?.toFixed(2)
                  : "0.00";
                labourAltCostValue[
                  `labourCost${item}Alt`.replace(/ /g, "_")
                ] = foundTask?.altLabourCost
                  ? foundTask?.altLabourCost?.toFixed(2)
                  : "0.00";

                machineryCostValue[
                  `machineryCost${item}`.replace(/ /g, "_")
                ] = foundTask ? foundTask.machineryCost?.toFixed(2) : "0.00";
              });

              const sorted = Object.keys(labourCostValue)
                .sort()
                .reduce(function(acc, key) {
                  acc[key] = round(labourCostValue[key], 2)?.toFixed(2);
                  return acc;
                }, {});
              return {
                ...{ farmName: farmName || "-" },
                ...{ areaSize: +areaSize?.toFixed(2) || 0 },
                ...{ noOfPlants: +totalPlants?.toFixed(2) || 0 },
                ...{ chemicalCost: +chemicalCost?.toFixed(2) || 0 },
                ...{
                  operatingCosts:
                    +round(otherExpensesCost + overheadsCost, 2)?.toFixed(2) ||
                    0
                },
                ...{
                  labourCosts:
                    +round(
                      sprayTaskLabourCost +
                        generalTaskCosts.reduce(
                          (prev, curr) => prev + curr.labourCost,
                          0
                        ) +
                        generalTaskCosts.reduce(
                          (prev, curr) => prev + curr.altLabourCost,
                          0
                        ),
                      2
                    )?.toFixed(2) || 0
                },
                ...{
                  sprayTaskLabourCost:
                    +round(sprayTaskLabourCost, 2)?.toFixed(2) || 0
                },
                ...sorted,
                ...labourAltCostValue,
                ...{
                  machineryCosts:
                    +round(
                      sprayTaskMachineryCost +
                        generalTaskCosts.reduce(
                          (prev, curr) => prev + curr.machineryCost,
                          0
                        ),
                      2
                    )?.toFixed(2) || 0
                },
                ...{
                  sprayTaskMachineryCost:
                    +round(sprayTaskMachineryCost, 2)?.toFixed(2) || 0
                },
                ...machineryCostValue,
                ...{
                  costPerUnits: binCount
                    ? +round(totalCost / binCount, 2)?.toFixed(2)
                    : "No information"
                },
                ...{
                  costPerHa: isNaN(totalCostPerHa)
                    ? "No information"
                    : +round(totalCostPerHa, 2)?.toFixed(2)
                },
                ...{
                  costPerPlant:
                    totalPlants !== 0 &&
                    isFinite(totalPlants) &&
                    isFinite(totalCost)
                      ? +round(totalCost / totalPlants, 3)?.toFixed(2)
                      : "No information"
                },
                ...{
                  totalCost: +round(totalCost, 2)?.toFixed(2)
                }
              };
            }
          );
        const seasonsFilter = filters.seasons
          ? `${moment(filters.seasons[0].slice(0, 10)).format(
              "DD/MM/YYYY"
            )}-${moment(filters.seasons[0].slice(-10)).format("DD/MM/YYYY")}`
          : "";

        const dataeFromFilter = filters.dateFrom
          ? filters.dateFrom && moment(filters.dateFrom).format("DD/MM/YYYY")
          : "";
        const dataeTOFilter = `${
          filters.dateTo
            ? filters.dateTo && moment(filters.dateTo).format("DD/MM/YYYY")
            : ""
        } `;
        const dateCheker = dataeFromFilter && dataeTOFilter ? "-" : "";

        const fileName = `total_cost_per_farm ${`${seasonsFilter}/${dataeFromFilter}${dateCheker}`}${dataeTOFilter}`;
        excelExport(excelData, headerColumn, fileName);
      }
    },
    [data.content]
  );

  const handleBackClick = () => {
    history.push({
      pathname: "/reports",
      state: {
        activeNode: location.state.reportNode
      }
    });
  };

  return (
    <Layout route={route} location={location} classForMain={styles.mainHolder}>
      <div className={style.p1em}>
        <Header as="h2">
          <span className={style.backIconWrapper} onClick={handleBackClick}>
            <Icon name="angle left" className={style.backIcon} />
          </span>
          {route.name}
        </Header>
        <Segment>
          <Grid className={styles.filtersGrid} verticalAlign="middle">
            <Grid.Row verticalAlign="middle">
              <Grid.Column
                mobile={16}
                tablet={10}
                computer={10}
                largeScreen={10}
              >
                <Filters
                  disabled={isFetching}
                  disableChecker={disableChecker}
                  options={defaultFiltersOptions}
                  location={location}
                />
              </Grid.Column>
              <Grid.Column
                mobile={16}
                tablet={6}
                computer={6}
                largeScreen={6}
                floated="right"
                textAlign="right"
              >
                <Button
                  disabled={isFetching}
                  style={{ marginLeft: 20 }}
                  onClick={() => handleExcelExport(rawFilters)}
                >
                  <Icon name="download" />
                  <span>Export</span>
                </Button>
                <Button
                  disabled={isFetching}
                  style={{ marginLeft: 10 }}
                  onClick={print}
                >
                  <Icon name="print" />
                  <span>Print</span>
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          {isFetching ? (
            <Loader />
          ) : (
            <TotalCostPerAreaTable areaStats={Object.values(listOfFarms)} />
          )}
        </Segment>
      </div>
    </Layout>
  );
};

TotalCostPerFarm.propTypes = {
  isFetching: PropTypes.bool,
  online: PropTypes.bool,
  location: PropTypes.object,
  actions: PropTypes.object,
  pagination: PropTypes.object,
  route: PropTypes.object,
  content: PropTypes.any
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchCostsPerArea,
      fetchCostsPerAreaList
    },
    dispatch
  )
});

const mapStateToProps = state => {
  const {
    settings: { data },
    areas,
    reports: {
      reportsReducerNeedToRefactor: {
        isFetching,
        reportPerAreaCosts: {
          content,
          number,
          totalPages,
          totalElements,
          numberOfElements,
          size
        },
        reportPerAreaCostsList
      }
    }
  } = state;

  return {
    data,
    areas,
    reportPerAreaCostsList,
    isFetching,
    content,
    online: onlineSelector(state),
    pagination: { number, totalPages, totalElements, numberOfElements, size }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TotalCostPerFarm);
