import { actionType } from "constants/JhonDeere";
import { axiosProxy } from "utils/axiosProxy";

export const getUrl = (data, callback) => async dispatch => {
  try {
    dispatch({ type: actionType.GET_URL_START });

    const res = await axiosProxy({
      method: "POST",
      url: `/john_deere/get_url`,
      data
    });
    await dispatch({ type: actionType.GET_URL, payload: res.data.url });
    if (callback) {
      callback(res.data.url);
    }
    return res.data.url;
  } catch (e) {
    dispatch({ type: actionType.GET_URL_FAIL, payload: [] });
  }
};

export const fetchToken = data => async dispatch => {
  try {
    dispatch({ type: actionType.FETCH_TOKEN_START });
    const res = await axiosProxy({
      method: "POST",
      url: `/john_deere/fetch_token`,
      data
    });
    dispatch({ type: actionType.FETCH_TOKEN, payload: res.data });
  } catch (e) {
    dispatch({ type: actionType.FETCH_TOKEN_FAIL, payload: [] });
  }
};

export const checkToken = () => async dispatch => {
  try {
    dispatch({ type: actionType.CHECK_TOKEN_START });
    const res = await axiosProxy({
      method: "GET",
      url: `/john_deere/check_token`
    });
    dispatch({ type: actionType.CHECK_TOKEN });
    return 1;
  } catch (e) {
    dispatch({ type: actionType.CHECK_TOKEN_FAIL, payload: [] });
  }
};

export const getLastLocation = machineId => async dispatch => {
  try {
    dispatch({ type: actionType.GET_LAST_LOCATION_START });

    const res = await axiosProxy({
      method: "GET",
      url: `/john_deere/machines/${machineId}/last_location`
    });
    dispatch({ type: actionType.GET_LAST_LOCATION, payload: res.data });
    return res.data;
  } catch (e) {
    dispatch({ type: actionType.GET_LAST_LOCATION_FAIL, payload: [] });
  }
};

export const getOrganisations = () => async dispatch => {
  try {
    dispatch({ type: actionType.GET_ORGANIZATIONS_START });

    const res = await axiosProxy({
      method: "GET",
      url: `/john_deere/organizations`
    });
    dispatch({ type: actionType.GET_ORGANIZATIONS, payload: res.data });
    res.data && res.data.values.map(i => dispatch(getMachines(i.id)));
  } catch (e) {
    dispatch({ type: actionType.GET_ORGANIZATIONS_FAIL, payload: [] });
  }
};

export const getMachines = orgId => async dispatch => {
  try {
    dispatch({ type: actionType.GET_MACHINES_START });

    const res = await axiosProxy({
      method: "GET",
      url: `/john_deere/organizations/${orgId}/machines`
    });
    if (res.data.values.length) {
      res.data.values.map(item => dispatch(getLastLocation(item.id)));
      dispatch({ type: actionType.GET_MACHINES, payload: res.data.values });
    }
  } catch (e) {
    dispatch({ type: actionType.GET_MACHINES_FAIL, payload: [] });
  }
};

export const clearToken = () => async dispatch => {
  try {
    dispatch({ type: actionType.CLEAR_TOKEN_START });

    const res = await axiosProxy({
      method: "GET",
      url: `/john_deere/clear_token`
    });
    dispatch({ type: actionType.CLEAR_TOKEN, payload: res.data });
  } catch (e) {
    dispatch({ type: actionType.CLEAR_TOKEN_FAIL, payload: [] });
  }
};
export const fetchCheckRedirect = bool => async dispatch => {
  dispatch({ type: actionType.CHECK_REDIRECT, payload: bool });
};

export const collectedLastLocation = locations => async dispatch => {
  dispatch({ type: actionType.COLLECTED_LAST_LOCATION_START });
  dispatch({ type: actionType.COLLECTED_LAST_LOCATION, payload: locations });
};
