import { actionType } from '../../constants/Clients';
import { axiosProxy } from '../../utils/axiosProxy';

const url = '/clients';

export const getClientsList = ({ page, size, search, sort }) => async dispatch => {
  try {
    dispatch({
      type: actionType.GET_CLIENTS_START,
    });

    const params = new URLSearchParams();
    params.append('page', page);
    params.append('size', size);

    if (search) {
      params.append('search', search);
    }

    (sort || ['id,asc']).forEach(field => {
      params.append('sort', field);
    });

    const res = await axiosProxy({
      method: 'GET',
      params,
      url,
    });

    await dispatch({
      type: actionType.GET_CLIENTS,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.GET_CLIENTS_FAIL,
      payload: error,
    });
  }
};

export const addNewClient = (data, withError = false) => async dispatch => {
  try {
    dispatch({
      type: actionType.ADD_CLIENT_START,
    });
    const res = await axiosProxy({
      method: 'POST',
      data,
      url,
    });

    await dispatch({
      type: actionType.ADD_CLIENT,
      payload: res.data,
    });

    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.ADD_CLIENT_FAIL,
      payload: error,
    });
    if (withError) {
      return {
        error,
      };
    }
  }
};

export const clearError = () => dispatch => {
  dispatch({
    type: actionType.CLIENTS_CLEAR_ERROR,
  });
};
