import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ObjectId from "bson-objectid";
import { Grid, Header, Form, TextArea } from "semantic-ui-react";
import { onlineSelector } from "../../../selectors/common";
import {
  setComments,
  setAttachments,
  uploadAttachment,
  createTask
} from "../../../actions/SprayDiary/common";

import Attachments from "../../../components/Attachments";

const API_URL = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`;

const AdditionalInformation = props => {
  const {
    id,
    attachments,
    isUploadingAttachment,
    comment,
    actions,
    online,
    _id
  } = props;
  // we need it for offline mode
  const callBack = useCallback(
    data => {
      debugger;
      if (online && data.id && id) {
        actions.createTask({
          id,
          reqId: ObjectId().toHexString(),
          data: {
            attachments: [
              {
                id: data.id,
                name: data.name,
                subPath: data.subPath,
                action: "CREATE"
              }
            ]
          },
          offlineMode: !online
        });
      }
    },
    [actions, id, online]
  );

  const serviceWorkerAction = {
    url: `${API_URL}/spray_tasks/partial/`,
    id
  };

  const onUploadAttachment = useCallback(
    ({ file, data, idReplace, callBack, serviceWorkerAction }) => {
      actions.uploadAttachment({
        file,
        callBack,
        idReplace,
        serviceWorkerAction,
        data,
        parentId: id || _id
      });
    },
    [actions, id, _id]
  );

  const onDeleteAttachment = useCallback(
    attachmentId => {
      actions.setAttachments({
        attachments: attachments.filter(
          a => a.id !== attachmentId && a.idReplace !== attachmentId
        ),
        parentId: id || _id
      });
    },
    [attachments, actions, id, _id]
  );

  return (
    <React.Fragment>
      <Header>Additional Information</Header>

      <Grid.Row>
        <Grid.Column className="spraySection">
          <Attachments
            attachments={attachments}
            isUploading={isUploadingAttachment}
            onDelete={onDeleteAttachment}
            onFileSelected={onUploadAttachment}
            serviceWorkerAction={serviceWorkerAction}
            callBack={callBack}
            multiply
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column className="spraySection">
          <Form.Field className="sprayField">
            <label className="title">Comments</label>
            <TextArea
              className="comments"
              rows={5}
              value={comment}
              onChange={e => actions.setComments(e.target.value)}
            />
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
    </React.Fragment>
  );
};

AdditionalInformation.propTypes = {
  id: PropTypes.number,
  _id: PropTypes.number,
  attachments: PropTypes.array,
  isUploadingAttachment: PropTypes.bool,
  online: PropTypes.bool,
  comment: PropTypes.string,
  actions: PropTypes.object
};

const mapStateToProps = state => {
  const {
    sprayDiary: { comment, attachments, isUploadingAttachment, id, _id }
  } = state;
  return {
    id,
    _id,
    comment,
    attachments,
    isUploadingAttachment,
    online: onlineSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setComments,
      setAttachments,
      uploadAttachment,
      createTask
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdditionalInformation);
