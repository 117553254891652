import React, { useState, useEffect } from "react";
import {
  Icon,
  Grid,
  Confirm,
  Table,
  Segment,
  Modal,
  Button,
  Popup
} from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { getOperations } from "actions/Sheds";
import { getPendingOperationsList } from "actions/PendingMovements";
import { useFormikContext } from "formik";
import { get } from "lodash";
import styles from "./Inventory.module.css";
import moment from "moment";
import classNames from "classnames";
import { matterToUnits } from "utils/constToUnits";
import { changeOperation } from "actions/Sheds";
import { fetchChemicalsLog } from "actions/Chemicals";
import InventoryEditForm from "../../../Modal/components/Inventory/InventoryEditForm";
import Numeric from "components/Numeric/Numeric";

const InventoryRow = ({
  inventoryList,
  productType,
  productList,
  sheds,
  inventoryData: {
    chemicalId,
    productName,
    price,
    initialPrice,
    expectedInventory,
    unit,
    buckets,
    pendingOperations
  },
  shed,
  isNegative
}) => {
  const dispatch = useDispatch();
  const { values, setFieldValue } = useFormikContext();
  const [isEditing, setIsEditing] = useState(false);
  const [openTable, toggleOpenTable] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const sortedActivities = buckets.sort((a, b) => {
    const date = new Date(a.createdAt);
    const date1 = new Date(b.createdAt);
    return date - date1;
  });
  const lastPrice = sortedActivities[sortedActivities.length - 1]?.price;
  const firstPrice = sortedActivities[0]?.price;
  const handleEditInventory = async ({
    actionOption,
    price,
    quantity,
    reason,
    shedTo,
    operationType
  }) => {
    await dispatch(
      changeOperation({
        chemicalId,
        shedId: shed.id,
        quantity: quantity * (actionOption === "REMOVE" ? -1 : 1),
        price: actionOption === "REMOVE" ? null : price,
        reason,
        shedTo,
        operationType
      })
    );
    dispatch(getPendingOperationsList());
    dispatch(fetchChemicalsLog());
    dispatch(getOperations(shed.id));
    setIsEditing(false);
  };
  const handleDeleteInventory = () => {
    if (shed) {
      dispatch(
        changeOperation({
          chemicalId,
          shedId: shed.id,
          quantity: -expectedInventory,
          operationType: "STOCK_DELETION"
        })
      );
    } else {
      setFieldValue(
        "buckets",
        values.buckets.filter(bucket => bucket.chemicalId !== chemicalId)
      );
    }
    setShowDeleteModal(false);
  };
  return (
    <>
      <Modal size="tiny " open={isEditing} onClose={!isEditing}>
        <Modal.Content className={styles.productEditModal}>
          <Grid.Row className={styles.productEditModalRow} as={Segment}>
            <Grid.Column>
              <InventoryEditForm
                sheds={sheds}
                inventory={{
                  productName,
                  initialPrice,
                  lastPrice,
                  firstPrice,
                  currentInventory:
                    expectedInventory +
                    (isNegative
                      ? pendingOperations.reduce(
                          (sum, o) => sum + o.quantity,
                          0
                        )
                      : 0),
                  unit
                }}
                shedName={shed.name}
                onSubmit={handleEditInventory}
                onCancel={() => setIsEditing(false)}
              />
            </Grid.Column>
          </Grid.Row>
        </Modal.Content>
      </Modal>

      <Modal
        size="mini"
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
      >
        <Modal.Content>
          <p>
            Delete {expectedInventory} of {productName} from {shed.name}'s
            inventory?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            className={styles.cancelDltBtn}
            onClick={() => setShowDeleteModal(false)}
          >
            Cancel
          </Button>
          <Button
            className={styles.deleteBtn}
            positive
            onClick={() => handleDeleteInventory()}
          >
            Delete
          </Button>
        </Modal.Actions>
      </Modal>

      <Grid className={styles.rowContainer} container as={Segment}>
        <div className={styles.icomNameContainer}>
          <Grid.Column className={styles.chevronContainer}>
            <div
              className={`${styles.toggleIcon} ${
                openTable ? styles.openTable : ""
              }`}
            >
              <Icon
                className={openTable ? "tuf-chevron-down" : "tuf-chevron-right"}
                onClick={() => toggleOpenTable(!openTable)}
              />
            </div>
          </Grid.Column>
          <Grid.Column className={styles.productNameItem}>
            <Popup
              inverted
              position="bottom center"
              content={productName}
              size="tiny"
              trigger={
                <span
                  className={classNames({
                    [styles.negativeElement]: isNegative
                  })}
                >
                  {productName}
                </span>
              }
            />
            <Popup
              position="right center"
              className={styles.popup}
              trigger={
                <Icon className={styles.infoButton} name="info circle" />
              }
              content={productType
                .filter(item => item.id === chemicalId)
                .map(item => (
                  <div className={styles.infoTooltipWrapper}>
                    <div> Type: {item.type ? item.type : "-"}</div>
                    <div>
                      {" "}
                      Matter:{" "}
                      {item.matter
                        ? item.matter == "LIQUID"
                          ? "Liquid"
                          : "Solid"
                        : "-"}
                    </div>
                    <div>
                      {" "}
                      Active ingredients:{" "}
                      {item.activeIngredient ? item.activeIngredient : "-"}
                    </div>
                  </div>
                ))}
              size="mini"
              inverted
            />
          </Grid.Column>
        </div>
        <Grid.Column className={styles.productTotalCostItem}>
          {isNegative ? (
            "-"
          ) : (
            <>
              <Numeric
                fractionDigits={price > 1000 ? 0 : 2}
                value={price ? price : 0}
                commaSeparatorOnThousands
                units="$ "
                unitsPosition="left"
                defaultValue="-"
              />
              /{unit}
            </>
          )}
        </Grid.Column>
        <Grid.Column
          className={classNames(styles.productExpectedInventoryItem, {
            [styles.negativeElement]: isNegative
          })}
        >
          <>
            <Numeric
              fractionDigits={
                expectedInventory +
                  (isNegative
                    ? pendingOperations.reduce((sum, o) => sum + o.quantity, 0)
                    : 0) >
                1000
                  ? 0
                  : 2
              }
              value={
                expectedInventory +
                (isNegative
                  ? pendingOperations.reduce((sum, o) => sum + o.quantity, 0)
                  : 0)
              }
              commaSeparatorOnThousands
              defaultValue="-"
            />
            {unit}
          </>
        </Grid.Column>
        <Grid.Column
          className={styles.sideBarProductButonContainer}
          textAlign="right"
        >
          {!!shed && (
            <Popup
              inverted
              position="bottom center"
              content={"Edit"}
              size="tiny"
              trigger={
                <div className={styles.editButtonWrapper}>
                  <Button
                    onClick={() => setIsEditing(true)}
                    className={styles.editButton}
                    floated="right"
                    icon={<Icon name="edit" />}
                    size="small"
                  />
                </div>
              }
            />
          )}
          {!isNegative && (
            <Popup
              inverted
              position="bottom center"
              content={"Delete"}
              size="tiny"
              trigger={
                <Button
                  onClick={() => setShowDeleteModal(true)}
                  className={styles.deleteButton}
                  floated="right"
                  icon={<Icon name="trash alternate" />}
                  size="small"
                />
              }
            />
          )}
        </Grid.Column>
        {openTable && (
          <Table
            size="large"
            basic="very"
            className={`${styles.tableFarm} ${
              openTable ? styles.tableOpen : ""
            }`}
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell className={styles.daterow}>
                  Date added
                </Table.HeaderCell>
                <Table.HeaderCell className={styles.pricerow}>
                  Price ($/unit)
                </Table.HeaderCell>
                <Table.HeaderCell className={styles.expInventoryrow}>
                  {" "}
                  Expected inventory
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {sortedActivities.map(item => {
                const userTimeZone = Intl.DateTimeFormat().resolvedOptions()
                  .timeZone;
                const moment = require("moment-timezone");
                const userTimeZoneDate = moment.utc(item?.createdAt);
                const convertedDate =
                  userTimeZoneDate &&
                  userTimeZoneDate
                    .tz(userTimeZone)
                    .format("YYYY-MM-DDTHH:mm:ss");
                return (
                  <Table.Row>
                    <Table.Cell className={styles.daterow}>
                      {item.createdAt
                        ? moment(convertedDate).format("DD/MM/YYYY")
                        : "-/-/- "}
                    </Table.Cell>
                    <Table.Cell className={styles.pricerow}>
                      <>
                        <Numeric
                          fractionDigits={get(item, "price") > 1000 ? 0 : 2}
                          value={get(item, "price") ? get(item, "price") : 0}
                          commaSeparatorOnThousands
                          units="$ "
                          unitsPosition="left"
                          defaultValue="-"
                        />
                        /{unit}
                      </>
                    </Table.Cell>
                    <Table.Cell lassName={styles.expInventoryrow}>
                      <>
                        <Numeric
                          fractionDigits={get(item, "quantity") > 1000 ? 0 : 2}
                          value={get(item, "quantity")}
                          commaSeparatorOnThousands
                          defaultValue="-"
                        />
                        {unit}
                      </>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
              {pendingOperations &&
                pendingOperations.map(operation => {
                  const userTimeZone = Intl.DateTimeFormat().resolvedOptions()
                    .timeZone;
                  const moment = require("moment-timezone");
                  const userTimeZoneDate = moment.utc(operation?.createdAt);
                  const convertedDate =
                    userTimeZoneDate &&
                    userTimeZoneDate
                      .tz(userTimeZone)
                      .format("YYYY-MM-DDTHH:mm:ss");
                  return (
                    <Table.Row>
                      <Table.Cell className={styles.negativeElement}>
                        {operation.createdAt
                          ? moment(convertedDate).format("DD/MM/YYYY")
                          : "-/-/- "}
                      </Table.Cell>
                      <Table.Cell>-</Table.Cell>
                      <Table.Cell className={styles.negativeElement}>
                        <>
                          <Numeric
                            fractionDigits={
                              get(operation, "quantity") > 1000 ? 0 : 2
                            }
                            value={get(operation, "quantity")}
                            commaSeparatorOnThousands
                            defaultValue="-"
                          />
                          {unit}
                        </>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
            </Table.Body>
          </Table>
        )}
      </Grid>
    </>
  );
};

export default InventoryRow;
