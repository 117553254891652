import React from "react";
import CollapsibleBlock from "../../../../../components/CollapsibleBlock";
import { Table } from "semantic-ui-react";
import { uppercaseFirstLetter } from "../../../../../utils/string";
import { camelCase, get, startCase } from "lodash";
import { isEmptyValue } from "../../../../../utils/tasksUtils";
import moment from "moment";
import styles from "../Sidebar.module.css";

const PersonalDetailsSection = ({ employee, editButton }) => {
  return (
    <CollapsibleBlock
      title="Personal Details"
      headerOptionalComponent={editButton}
      className={styles.blockContainer}
    >
      <Table className={styles.sectionTable} definition fixed>
        <Table.Body>
          <Table.Row>
            <Table.Cell width={6}>Role</Table.Cell>
            <Table.Cell width={10}>
              {startCase(camelCase(get(employee, "type")))}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={6}>Gender</Table.Cell>
            <Table.Cell width={10}>
              {isEmptyValue(uppercaseFirstLetter(get(employee, "gender")))}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Date of birth</Table.Cell>
            <Table.Cell>
              {employee.dateOfBirth
                ? moment(employee.dateOfBirth).format("DD/MM/YYYY")
                : isEmptyValue(employee.dateOfBirth)}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </CollapsibleBlock>
  );
};

export default PersonalDetailsSection;
