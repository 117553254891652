import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SingleSelectFilter from "./SingleSelectFilter/SingleSelectFilter";

const SeasonSingleSelectFilter = ({ list, ...extraProps }) => {
  const options = list?.map(item => ({
    value: [item.startAt, item.endAt],
    key: item.seasonName,
    text: item.seasonName
  }));
  return (
    <SingleSelectFilter options={options} numbers={false} {...extraProps} />
  );
};

const mapStateToProps = state => {
  const {
    settings: {
      data: { seasons }
    }
  } = state;

  return {
    list: seasons
  };
};

const mapDispatchToProps = dispatch => ({});

SeasonSingleSelectFilter.propTypes = {
  isCreating: PropTypes.bool,
  list: PropTypes.array,
  actions: PropTypes.object
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SeasonSingleSelectFilter);
