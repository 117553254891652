import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";
import styles from "./ImageSlider.module.css";
import arrowLeft from "./img/arrow-left.svg";
import arrowRight from "./img/arrow-right.svg";
import { setImage } from "../../actions/ImageViewer";
import { useDispatch } from "react-redux";

const ImageSlider = ({ images, width, height }) => {
  const dispatch = useDispatch();
  const [currentPhotoID, setCurrentPhotoID] = useState(0);
  const isThereImages = images.length > 0;

  if (!isThereImages) return <div />;

  const isArrowRightShown = isThereImages && currentPhotoID < images.length - 1;
  const isArrowLeftShown = isThereImages && currentPhotoID > 0;

  return (
    <div>
      <div className={styles.holder} style={{ width, height }}>
        {images.map((image, i) => (
          <div
            key={`image_${i}`}
            className={classNames(styles.photo, {
              [styles.active]: i === currentPhotoID
            })}
            style={{ width, height }}
          >
            <img
              src={image}
              alt=""
              onClick={() => dispatch(setImage(images[currentPhotoID]))}
            />
          </div>
        ))}
        <div
          className={styles.buttonsHolder}
          onClick={() => dispatch(setImage(images[currentPhotoID]))}
        >
          {isArrowLeftShown && (
            <button
              className={styles.arrowLeft}
              onClick={e => {
                e.stopPropagation();
                setCurrentPhotoID(currentPhotoID - 1);
              }}
            >
              <img src={arrowLeft} alt="" />
            </button>
          )}
          {isArrowRightShown && (
            <button
              className={styles.arrowRight}
              onClick={e => {
                e.stopPropagation();
                setCurrentPhotoID(currentPhotoID + 1);
              }}
            >
              <img src={arrowRight} alt="" />
            </button>
          )}
        </div>
      </div>
      <span className={styles.caption}>
        {currentPhotoID + 1} of {images.length}
      </span>
    </div>
  );
};

ImageSlider.propTypes = {
  height: PropTypes.number.isRequired,
  images: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  width: PropTypes.number.isRequired
};

ImageSlider.defaultProps = {
  images: []
};

export default ImageSlider;
