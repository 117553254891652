export const actionType = {
  FETCH_PACKING_SHEDS_START: 'PACKING_SHEDS/FETCH_START',
  FETCH_PACKING_SHEDS: 'PACKING_SHEDS/FETCH',
  FETCH_PACKING_SHEDS_FAIL: 'PACKING_SHEDS/FETCH_FAIL',

  FETCH_PACKING_SHEDS_LIST_START: 'PACKING_SHEDS/FETCH_LIST_START',
  FETCH_PACKING_SHEDS_LIST: 'PACKING_SHEDS/FETCH_LIST',
  FETCH_PACKING_SHEDS_LIST_FAIL: 'PACKING_SHEDS/FETCH_LIST_FAIL',

  GET_PACKING_SHEDS_BY_ID_START: 'PACKING_SHEDS/GET_BY_ID_START',
  GET_PACKING_SHEDS_BY_ID: 'PACKING_SHEDS/GET_BY_ID',
  GET_PACKING_SHEDS_BY_ID_FAIL: 'PACKING_SHEDS/GET_BY_ID_FAIL',

  ADD_PACKING_SHEDS_START: 'PACKING_SHEDS/ADD_START',
  ADD_PACKING_SHEDS: 'PACKING_SHEDS/ADD',
  ADD_PACKING_SHEDS_FAIL: 'PACKING_SHEDS/ADD_FAIL',

  UPDATE_PACKING_SHEDS_START: 'PACKING_SHEDS/UPDATE_START',
  UPDATE_PACKING_SHEDS: 'PACKING_SHEDS/UPDATE',
  UPDATE_PACKING_SHEDS_FAIL: 'PACKING_SHEDS/UPDATE_FAIL',

  DELETE_PACKING_SHEDS_START: 'PACKING_SHEDS/DELETE_START',
  DELETE_PACKING_SHEDS: 'PACKING_SHEDS/DELETE',
  DELETE_PACKING_SHEDS_FAIL: 'PACKING_SHEDS/DELETE_FAIL',

  CLEAR_PACKING_SHEDS_ERROR: 'PACKING_SHEDS/CLEAR_PACKING_SHEDS_ERROR',
};
