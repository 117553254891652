import { actionType } from "../../constants/PurchaseHistory";

const initialState = {
  list: {
    content: [],
    isFetching: false
  },
  data: {
    content: [],
    isFetching: false
  },
  print: {
    content: [],
    isFetching: false,
    error: null
  },
  suppliers: {
    content: [],
    isFetching: false
  },
  manufacturer: {
    content: [],
    isFetching: false
  },
  batchNumber: {
    content: [],
    isFetching: false
  },
  filters: {
    date: {
      content: [],
      isFetching: false
    }
  },
  isCreating: false,
  fetchPurchaseToggle: false,
  error: null
};
export default (state = initialState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case actionType.PURCHASE_HISTORY_START: {
      return {
        ...state,
        data: {
          ...state.data,
          isFetching: true
        }
      };
    }
    case actionType.PURCHASE_HISTORY: {
      return {
        ...state,
        data: {
          ...action.payload,
          isFetching: false
        }
      };
    }
    case actionType.PURCHASE_HISTORY_LIST: {
      return {
        ...state,
        list: {
          ...action.payload,
          isFetching: false
        }
      };
    }
    case actionType.PURCHASE_HISTORY_FOR_PRINT: {
      return {
        ...state,
        print: {
          content: action.payload,
          isFetching: false,
          error: null
        }
      };
    }
    case actionType.ADD_PURCHASE_START: {
      return {
        ...state,
        data: {
          isCreating: true,
          ...state.data,
          isFetching: true
        }
      };
    }
    case actionType.ADD_PURCHASE:
      return {
        ...state,
        data: {
          content: [...state.data.content, action.payload]
        },
        isCreating: false,
        fetchPurchaseToggle: !state.fetchPurchaseToggle
      };

    case actionType.ADD_PURCHASE_FAIL:
      return {
        ...state,
        isCreating: false,
        error: action.payload
      };
    case actionType.UPDATE_PURCHASE_FAIL:
    case actionType.DELETE_PURCHASE_FAIL:
      return {
        ...state,
        isCreating: false,
        error: action.payload
      };
    case actionType.UPDATE_PURCHASE_START: {
      return {
        ...state,
        data: {
          ...state.data,
          isFetching: true
        }
      };
    }
    case actionType.UPDATE_PURCHASE:
      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.map(item =>
            item.id === action.payload.id ? action.payload : item
          ),
          isFetching: false
        }
      };

    case actionType.DELETE_PURCHASE: {
      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.filter(g => g.id !== action.payload.id)
        },
        error: null
      };
    }
    case actionType.GET_SUPPLIERSS_LIST: {
      return {
        ...state,
        suppliers: {
          content: [...action.payload],
          isFetching: false
        }
      };
    }
    case actionType.GET_BATCHNUMBER_LIST: {
      return {
        ...state,
        batchNumber: {
          content: [...action.payload],
          isFetching: false
        }
      };
    }
    case actionType.GET_MANUFACTURER_LIST: {
      return {
        ...state,
        manufacturer: {
          content: [...action.payload],
          isFetching: false
        }
      };
    }
    case actionType.SET_ACTIVE_ITEM_ID:
      return {
        ...state,
        activeItem: {
          id: action.payload
        }
      };
    case actionType.CLEAR_PURCHASE_ERROR:
      return {
        ...state,
        error: null
      };

    default:
      return state;
  }
};
