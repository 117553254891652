import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { Dropdown } from 'semantic-ui-react';
import styles from './StatusDropdown.module.css';

const selectStatusTypes = createSelector(
  state => state.spray,
  spray =>
    Object.keys(spray.tasks.statuses).map(status => ({
      key: status,
      value: status,
      text: spray.tasks.statuses[status],
      content: <div className={styles[`color${status}`]}>{spray.tasks.statuses[status]}</div>,
    })),
);

function StatusDropdown({ onChange, value, className, ...rest }) {
  const options = useSelector(selectStatusTypes);

  return (
    <Dropdown
      onChange={onChange}
      options={options}
      value={value}
      className={`select-rounded ${styles.selectStatus} ${className} ${styles[`select${value}`]}`}
      {...rest}
    />
  );
}

StatusDropdown.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  className: PropTypes.string,
};

StatusDropdown.defaultProps = {
  onChange: () => {},
  value: '',
  className: '',
};

export default StatusDropdown;
