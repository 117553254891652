import React, { useEffect, useState } from "react";
import { Icon, Segment, Grid, Modal, Button, Popup } from "semantic-ui-react";
import { useFormikContext } from "formik";
import { useDispatch } from "react-redux";
import { get } from "lodash";
import { matterToUnits } from "utils/constToUnits";
import { partialUpdate } from "actions/Sheds";
import styles from "./Threshold.module.css";
import ThresholdForm from "./ThresholdForm";
import Numeric from "components/Numeric/Numeric";

const ThresholdRow = ({
  threshold,
  thresholdsList,
  shedId,
  shedName,
  buckets,
  setCheckNegativeThreshold,
  setNegativeThresholds,
  shed
}) => {
  const dispatch = useDispatch();
  const path = "thresholds";
  const { values, setFieldValue } = useFormikContext();
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const filtredBuckets = buckets.filter(
    item => item.chemicalId === threshold.chemicalId
  );
  const totalQuantity = filtredBuckets.reduce(
    (prev, curr) => prev + curr.quantity,
    0
  );
  const unit = thresholdsList
    .filter(t => t.key == threshold.chemicalId)
    .map(i => matterToUnits(i.matter));
  const handleThresholdDelete = () => {
    const updatedThresholds = values.thresholds.filter(
      t => t.chemicalId !== threshold.chemicalId
    );
    !!values.thresholds && setCheckNegativeThreshold(true);
    setFieldValue("thresholds", updatedThresholds);
    dispatch(partialUpdate(shedId, updatedThresholds, path));
    setShowDeleteModal(false);
  };

  const handleThresholdEdit = newValue => {
    const updatedThresholds = values.thresholds.map(i =>
      i === threshold ? newValue : i
    );
    setFieldValue("thresholds", updatedThresholds);
    dispatch(partialUpdate(shedId, updatedThresholds, path));
    setIsEditing(false);
  };
  if (isEditing) {
    return (
      <Grid.Row as={Segment}>
        <Grid.Column>
          <ThresholdForm
            thresholdData={threshold}
            thresholdsList={thresholdsList}
            onSubmit={newValue => handleThresholdEdit(newValue)}
            onCancel={() => setIsEditing(false)}
          />
        </Grid.Column>
      </Grid.Row>
    );
  }

  return (
    <Grid.Row
      className={
        totalQuantity < threshold.threshold
          ? styles.negativeThreshold
          : styles.rowContainer
      }
      as={Segment}
    >
      <Modal
        size="mini"
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
      >
        <Modal.Content>
          <p>
            Delete{" "}
            {get(
              thresholdsList.find(t => t.value == threshold.chemicalId),
              "text"
            )}{" "}
            threshold for this shed?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            className={styles.cancelDltBtn}
            onClick={() => setShowDeleteModal(false)}
          >
            Cancel
          </Button>
          <Button
            className={styles.deleteBtn}
            positive
            onClick={() => handleThresholdDelete()}
          >
            Delete
          </Button>
        </Modal.Actions>
      </Modal>
      <Grid.Column className={styles.thresholdProduct}>
        {get(
          thresholdsList.find(t => t.value == threshold.chemicalId),
          "text"
        )}
      </Grid.Column>
      <Grid.Column
        className={
          totalQuantity < threshold.threshold && styles.negativeCellThreshold
        }
      >
        <Numeric
          fractionDigits={threshold.threshold > 1000 ? 0 : 2}
          value={threshold.threshold}
          commaSeparatorOnThousands
          units={` ${unit}`}
          unitsPosition="right"
          defaultValue="-"
        />

        {totalQuantity < threshold.threshold && (
          <Popup
            position="right center"
            className={styles.popup}
            trigger={
              <Icon className={styles.infoNegativeButton} name="info circle" />
            }
            content={
              <div className={styles.negativeThresholdMessageContainer}>
                The expected inventory for this product in {shedName} has fallen
                below this threshold.
              </div>
            }
            size="mini"
            inverted
          />
        )}
      </Grid.Column>
      <Grid.Column
        className={styles.thresholdsbuttonContainer}
        textAlign="right"
      >
        <Popup
          inverted
          position="bottom center"
          content={"Edit"}
          size="tiny"
          trigger={
            <div className={styles.editButtonWrapper}>
              <Button
                onClick={() => setIsEditing(true)}
                className={styles.editButton}
                floated="right"
                icon={<Icon name="edit" />}
                size="small"
              />
            </div>
          }
        />
        <Popup
          inverted
          position="bottom center"
          content={"Delete"}
          size="tiny"
          trigger={
            <Button
              onClick={() => setShowDeleteModal(true)}
              className={styles.deleteButton}
              floated="right"
              icon={<Icon name="trash alternate" />}
              size="small"
            />
          }
        />
      </Grid.Column>
    </Grid.Row>
  );
};

export default ThresholdRow;
