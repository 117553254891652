import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { Header, Icon, Segment } from "semantic-ui-react";
import classNames from "classnames";
import styles from "./SegmentClosable.module.css";

const SegmentClosable = ({
  title,
  onClickHeader,
  headerClassName,
  headerLineClassName,
  headerMore,
  children,
  errors,
  alter,
  defaultOpened
}) => {
  const [segmentOpen, setSegmentOpen] = useState(defaultOpened);
  const toggleSegmentOpen = useCallback(() => {
    setSegmentOpen(!segmentOpen);
  }, [setSegmentOpen, segmentOpen]);

  useEffect(() => {
    if (errors && errors.error) {
      setSegmentOpen(true);
    }
  }, [errors]);

  return (
    <Segment
      className={classNames(styles.segmentHolder, { [styles.alter]: alter })}
    >
      <div className={styles.headerHolder}>
        <div className={styles.chevron}>
          <Icon
            className={segmentOpen ? "tuf-chevron-down" : "tuf-chevron-right"}
            onClick={toggleSegmentOpen}
          />
        </div>
        <div className={headerLineClassName}>
          <Header
            as={alter ? "h5" : "h2"}
            className={`${styles.headerTitle} ${headerClassName}`}
            onClick={onClickHeader}
          >
            {title}
          </Header>
          {headerMore}
        </div>
      </div>
      <div
        className={`${styles.childrenHolder} ${segmentOpen ? styles.open : ""}`}
      >
        {children}
      </div>
    </Segment>
  );
};

SegmentClosable.propTypes = {
  alter: PropTypes.bool,
  children: PropTypes.node,
  defaultOpened: PropTypes.bool,
  errors: PropTypes.object,
  headerClassName: PropTypes.string,
  headerLineClassName: PropTypes.string,
  headerMore: PropTypes.any,
  onClickHeader: PropTypes.func,
  title: PropTypes.string
};

SegmentClosable.defaultProps = {
  children: null,
  defaultOpened: true,
  headerClassName: "",
  headerLineClassName: "",
  headerMore: null,
  onClickHeader: null,
  title: ""
};

export default SegmentClosable;
