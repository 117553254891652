import { isNil, omitBy } from "lodash";
import { actionTypes } from "../../constants/HarvestUnits";
import { axiosProxy } from "../../utils/axiosProxy";
import uuid from "uuid/v4";
let uniqId;

const url = "/barcodes";

export const fetchHarvestUnits = () => async dispatch => {
  try {
    dispatch({ type: actionTypes.FETCH_HARVEST_UNITS_START });

    const params = new URLSearchParams({ unpaged: true });

    const res = await axiosProxy({ method: "GET", params, url });

    await dispatch({
      type: actionTypes.FETCH_HARVEST_UNITS,
      payload: res.data
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: actionTypes.FETCH_HARVEST_UNITS_ERROR,
      payload: error
    });
  }
};

export const updateHarvestUnits = (id, data) => async dispatch => {
  dispatch({
    type: actionTypes.UPDATE_HARVEST_UNITS_START
  });
  try {
    const res = await axiosProxy({
      method: "PUT",
      url: `${url}/${id}`,
      data
    });

    await dispatch({
      type: actionTypes.UPDATE_HARVEST_UNITS,
      payload: res.data
    });
    return res.data;
  } catch (error) {
    await dispatch({
      type: actionTypes.UPDATE_HARVEST_UNITS_ERROR,
      payload: error
    });
  }
};

export const createHarvestUnits = newData => async dispatch => {
  dispatch({ type: actionTypes.ADD_HARVEST_UNITS_START });
  try {
    const res = await axiosProxy({
      method: "POST",
      url: `${url}/harvestUnit/batch`,
      data: newData
    });

    await dispatch({
      type: actionTypes.ADD_HARVEST_UNITS,
      payload: res.data
    });

    return res.data;
  } catch (error) {
    await dispatch({
      type: actionTypes.ADD_HARVEST_UNITS_ERROR,
      payload: error
    });
  }
};
export const clearError = () => dispatch =>
  dispatch({ type: actionTypes.CLEAR_ERROR });

export const fetchHarvestUnitsControl = (
  { page, search, size, to, from, unpaged = false, filters, sort = ["id,asc"] },
  shedIds
) => async (dispatch, getState) => {
  await dispatch({
    type: actionTypes.FETCH_HARVEST_UNITS_CONTROL_START,
    payload: shedIds
  });
  const { areas } = getState();
  const filtersList = Object.fromEntries(filters);
  try {
    const blockIds = areas.list.content.filter(item =>
      filtersList?.blockIds?.split(",", 999999)?.some(id => item.id == id)
    );
    const patchIds = areas.list.content.filter(item =>
      filtersList?.patchIds?.split(",", 999999)?.some(id => item.id == id)
    );
    const filtredBloCk = blockIds.filter(block =>
      patchIds.every(patch => patch.parent?.id != block.id)
    );
    const areaIds = [...patchIds, ...filtredBloCk].map(item => item.id);

    const paramsObject = omitBy(
      {
        page,
        search,
        size,
        unpaged,
        to,
        sort,
        from
      },
      isNil
    );
    const params = new URLSearchParams(paramsObject);

    if (Array.isArray(filters)) {
      filters.forEach(([filter, value]) => {
        const from = value.slice(0, 10);
        const to = value.slice(-10);
        return filter !== "seasons"
          ? filter == "blockIds" || filter == "patchIds"
            ? !params.has("areaIds") && params.append("areaIds", areaIds)
            : params.append(filter, value)
          : (params.append("from", `${from}T00:00:00`),
            params.append("to", `${to}T23:59:59`));
      });
    }
    uniqId = uuid();
    const res = await axiosProxy({
      method: "GET",
      params,
      uniqId,
      url
    });
    if (uniqId === res.config.headers["X-REQUEST-ID"]) {
      await dispatch({
        type: actionTypes.FETCH_HARVEST_UNITS_CONTROL,
        payload: res.data
      });
    }
    return res.data;
  } catch (error) {}
};

export const fetchHarvestUnitsControlList = () => async dispatch => {
  await dispatch({
    type: actionTypes.FETCH_HARVEST_UNITS_CONTROL_LIST_START
  });
  try {
    const res = await axiosProxy({
      method: "GET",
      params: {
        unpaged: true
      },
      url
    });
    await dispatch({
      type: actionTypes.FETCH_HARVEST_UNITS_CONTROL_LIST,
      payload: res.data
    });

    return res.data;
  } catch (error) {}
};

export const fetchHarvestExportData = ({
  unpaged = true,
  sort = ["id,desc"],
  filters
}) => async dispatch => {
  try {
    dispatch({
      type: actionTypes.EXPORT_DATA_HARVEST_UNITS_START
    });
    const paramsObject = omitBy(
      {
        sort,
        unpaged: true
      },
      isNil
    );
    const params = new URLSearchParams(paramsObject);
    if (Array.isArray(filters)) {
      filters.forEach(([filter, value]) => {
        const from = value.slice(0, 10);
        const to = value.slice(-10);
        return filter !== "seasons"
          ? params.append(filter, value)
          : (params.append("from", `${from}T00:00:00`),
            params.append("to", `${to}T23:59:59`));
      });
    }
    const res = await axiosProxy({
      method: "GET",
      params,
      url
    });
    dispatch({
      type: actionTypes.EXPORT_DATA_HARVEST_UNITS,
      payload: res.data
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: actionTypes.EXPORT_DATA_HARVEST_UNITS_FAILED,
      payload: error
    });
  }
};

export const deleteHarvestUnits = selectedIds => async dispatch => {
  try {
    dispatch({
      type: actionTypes.DELETE_HARVEST_UNITS_START
    });
    const res = await axiosProxy({
      method: "DELETE",
      url: `${url}/batch?ids=${selectedIds.join("&ids=")}`
    });

    await dispatch({
      type: actionTypes.DELETE_HARVEST_UNITS,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: actionTypes.DELETE_HARVEST_UNITS_ERROR,
      payload: error
    });
  }
};
export const harvestUnitsFetch = selectedIds => async dispatch => {
  try {
    dispatch({
      type: actionTypes.FETCH_HARVEST_UNITS_OPTIONS_START
    });
    const res = await axiosProxy({
      method: "GET",
      url: "/harvest_units"
    });

    await dispatch({
      type: actionTypes.FETCH_HARVEST_UNITS_OPTIONS,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: actionTypes.FETCH_HARVEST_UNITS_OPTIONS_ERROR,
      payload: error
    });
  }
};
