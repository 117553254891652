import React, { useState } from "react";
import {
  Container,
  Dimmer,
  Icon,
  Input,
  Loader,
  Segment,
  Grid,
  Button,
  Message,
  Table as SemanticTable,
  Popup
} from "semantic-ui-react";
import Filters from "./Filters";
import Pagination from "./Pagination";
import classNames from "classnames";
import styles from "./Table.module.css";
import { LogoIcon } from "../../../assets/icons/LogoIcon";
import PrindAndExportModal from "../../../routes/Reports/Harvest/components/PrindAndExportModal";

const Table = ({
  defaultDate,
  dateType,
  withoutSearch = false,
  withPagination = false,
  withMoreButton,
  actionButtons,
  firstColumnFixed,
  cellProps,
  basic,
  withoutSort,
  withHeader,
  className,
  clearFilters,
  pendingMovementsLength,
  containerClassName,
  handleExcelExport,
  filtersOptions,
  fixed,
  loading,
  inTable,
  onFilterChange,
  printColumns,
  onRowClick,
  open,
  printData,
  excel,
  setOpen,
  printButton,
  onSearchChange,
  pageCount,
  rowProps,
  search,
  searchPlaceholder,
  selectedFilters,
  striped,
  tableInstance,
  totalElements,
  withSearch,
  withBorder,
  rowId,
  wideTableClass,
  singleLine,
  pendingMovements,
  withTable,
  tableType,
  sectionModal,
  numberofParent
}) => {
  const {
    canNextPage,
    canPreviousPage,
    getTableBodyProps,
    getTableProps,
    gotoPage,
    headerGroups,
    nextPage,
    pageOptions,
    prepareRow,
    previousPage,
    rows,
    setPageSize,
    state,
    footerGroups
  } = tableInstance;
  const [closeMessages, setCloseMessages] = useState(false);
  const pendingList = rows;

  var el = document.getElementsByTagName("BODY")[0];
  el.setAttribute("class", `${styles.prevBodyState}`);

  window.onload = (function(timer) {
    var el = document.getElementsByTagName("BODY")[0];
    var innerEl = document.getElementById("inner");
    innerEl &&
      innerEl.addEventListener("scroll", function(e) {
        (function(el) {
          el.setAttribute("class", `${styles.bodyState}`);
          clearTimeout(timer);
          timer = setTimeout(function() {
            el.setAttribute("class", `${styles.prevBodyState}`);
          }, 1000);
        })(el);
      });
  })();

  return (
    <>
      {!inTable && (
        <Container fluid className={styles.actionsContainerWrapper}>
          <div className={styles.actionsContainerWrapper}>
            <div className={styles.searchAndFiltersContainer}>
              {withSearch && !withoutSearch && (
                <Input
                  value={search}
                  className={styles.searchInput}
                  placeholder={searchPlaceholder}
                  onChange={onSearchChange}
                />
              )}
              <Filters
                withMoreButton={withMoreButton}
                options={filtersOptions}
                selectedFilters={selectedFilters}
                onFilterChange={onFilterChange}
                clearFilters={clearFilters}
                loading={loading}
                dateType={dateType}
                defaultDate={defaultDate}
              />
            </div>
            {!withPagination && (
              <Pagination
                canNextPage={canNextPage}
                canPreviousPage={canPreviousPage}
                gotoPage={gotoPage}
                currentPage={state.pageIndex}
                pageOptions={pageOptions}
                pageSize={state.pageSize}
                pageCount={pageCount}
                totalElements={totalElements}
                setPageSize={setPageSize}
                nextPage={nextPage}
                previousPage={previousPage}
              />
            )}
            {actionButtons && (
              <div className={styles.actionsContainer}>{actionButtons}</div>
            )}
          </div>
          {pendingMovements && !closeMessages && (
            <Message className={styles.warrningMessages}>
              <div className={styles.warrningMessagesWrapper}>
                <div className={styles.warrningMessagesRow}>
                  <div className={styles.warrningMessagesIconWrapper}>
                    <Icon
                      className={styles.warrningMessagesIcon}
                      name="info circle"
                    />
                  </div>
                  <div className={styles.warrningMessage}>
                    You currently have {pendingMovementsLength} pending
                    movements that need to be resolved. To resolve these, either
                    go to your sheds and update the price on the negative
                    inventory or add products to your shed via the purchase
                    order or via the sheds. Also, please check for over
                    consumption or edits in your applications tasks.
                  </div>
                  <div className={styles.messagesCloseButtonContainer}>
                    {" "}
                    <Button
                      onClick={() => setCloseMessages(true)}
                      className={styles.messagesCloseButton}
                    >
                      Got it
                    </Button>
                  </div>
                </div>
              </div>
            </Message>
          )}
        </Container>
      )}
      <Container
        as={Segment}
        vertical
        fluid
        style={{ overflow: loading ? "hidden" : "auto" }}
        className={
          !inTable
            ? classNames(styles.tableContainer, containerClassName)
            : styles.tableInModal
        }
      >
        <Dimmer
          active={loading}
          className={styles.dimmerCustomStyle}
          inverted
        />
        <div className={styles.loaderContainer}>
          <Loader className={styles.loader} active={loading} />
        </div>
        {totalElements ? (
          <>
            <PrindAndExportModal
              open={open}
              sectionModal={sectionModal}
              printData={printData}
              excel={excel}
              setOpen={setOpen}
              printColumns={printColumns}
              printButton={printButton}
              handleExcelExport={handleExcelExport}
              numberofParent={numberofParent}
            />
            <div id="inner" className={styles.inner}>
              <SemanticTable
                basic={basic}
                fixed={fixed}
                singleLine={singleLine}
                className={classNames(
                  className,
                  styles.table,
                  tableType == "report" && styles.reportType,
                  withBorder && styles.tableWithBorder,
                  firstColumnFixed && styles.firstColumnFixed,
                  wideTableClass
                )}
                striped={striped}
                {...getTableProps()}
              >
                <SemanticTable.Header>
                  {headerGroups.map(headerGroup => (
                    <SemanticTable.Row
                      verticalAlign="middle"
                      {...headerGroup.getHeaderGroupProps()}
                    >
                      {headerGroup.headers.map(column => {
                        return !withoutSort && column.withSort ? (
                          <Popup
                            inverted
                            trigger={
                              <SemanticTable.HeaderCell
                                className={
                                  withTable
                                    ? styles.reportHeaderColumn
                                    : column.headerClassName
                                }
                                width={column.cellWidth}
                                {...(column.disableSortBy
                                  ? column.getHeaderProps()
                                  : column.getHeaderProps(
                                      column.getSortByToggleProps()
                                    ))}
                                title={""}
                              >
                                <div
                                  className={classNames({
                                    [styles.sorted]: column.isSorted,
                                    [styles.sortableHeader]: !column.disableSortBy
                                  })}
                                >
                                  {column.onlyTable
                                    ? ""
                                    : column.render("Header")}
                                  {!column.disableSortBy && (
                                    <span className={styles.sortingIcon}>
                                      {!column.disableSortBy &&
                                        (!column.isSorted ? (
                                          <Icon name="arrow up" />
                                        ) : column.isSortedDesc ? (
                                          <Icon name="arrow up" />
                                        ) : (
                                          <Icon name="arrow down" />
                                        ))}
                                    </span>
                                  )}
                                </div>
                              </SemanticTable.HeaderCell>
                            }
                            content={
                              !withoutSort &&
                              column.withSort &&
                              (column.type === "number"
                                ? !column.disableSortBy &&
                                  (!column.isSorted
                                    ? "Sort  small - big"
                                    : column.isSortedDesc
                                    ? "Sorted   small - big"
                                    : "Sorted  big - small")
                                : !column.disableSortBy &&
                                  (!column.isSorted
                                    ? "Sort  A - Z"
                                    : column.isSortedDesc
                                    ? "Sorted Z - A "
                                    : "Sorted A - Z "))
                            }
                            position="top left"
                          />
                        ) : (
                          <SemanticTable.HeaderCell
                            className={
                              withTable
                                ? styles.reportHeaderColumn
                                : column.headerClassName
                            }
                            width={column.cellWidth}
                          >
                            <div
                              className={classNames(
                                withoutSort && styles.withoutSort,
                                {
                                  [styles.sorted]: column.isSorted,
                                  [styles.sortableHeader]: !column.disableSortBy
                                }
                              )}
                            >
                              {column.onlyTable
                                ? null
                                : column.render("Header")}
                            </div>
                          </SemanticTable.HeaderCell>
                        );
                      })}
                    </SemanticTable.Row>
                  ))}
                </SemanticTable.Header>
                <SemanticTable.Body {...getTableBodyProps()}>
                  {rows.map(row => {
                    prepareRow(row);
                    return (
                      <SemanticTable.Row
                        verticalAlign="middle"
                        onClick={event => {
                          onRowClick(event, row);
                        }}
                        className={classNames(styles.row, {
                          [styles.activeRow]:
                            parseInt(row.id) === parseInt(rowId),
                          [styles.selectedRow]: row.isSelected,
                          [styles.subRowsLastItem]:
                            row.original.isSubRowsLastItem
                        })}
                        {...row.getRowProps()}
                        {...rowProps}
                      >
                        {row.cells.map(cell => {
                          return cell.column.withAttachFiles ? (
                            <SemanticTable.Cell
                              width={
                                withTable
                                  ? styles.reportColumn
                                  : cell.column.cellWidth
                              }
                              className={cell.column.className}
                              {...cell.getCellProps({
                                style: {
                                  minWidth: cell.column.minWidth
                                }
                              })}
                              {...cellProps}
                            >
                              {cell.column.onlyTable &&
                              cell.render("Cell").props.value == "emptyValue"
                                ? null
                                : cell.render("Cell")}
                            </SemanticTable.Cell>
                          ) : (
                            <SemanticTable.Cell
                              width={cell.column.cellWidth}
                              className={
                                withTable
                                  ? styles.reportColumn
                                  : cell.column.className
                              }
                              {...cell.getCellProps({
                                style: {
                                  minWidth: cell.column.minWidth,
                                  verticalAlign: "middle"
                                }
                              })}
                              {...cellProps}
                            >
                              {cell.column.onlyTable &&
                              cell.render("Cell").props.value == "emptyValue"
                                ? null
                                : cell.render("Cell")}
                            </SemanticTable.Cell>
                          );
                        })}
                      </SemanticTable.Row>
                    );
                  })}
                </SemanticTable.Body>
                {!inTable && (
                  <SemanticTable.Footer>
                    {footerGroups.map(group => (
                      <SemanticTable.Row
                        className={styles.tableFooter}
                        {...group.getFooterGroupProps()}
                      >
                        {group.headers.map(column => (
                          <SemanticTable.Cell {...column.getFooterProps()}>
                            {column.render("Footer")}
                          </SemanticTable.Cell>
                        ))}
                      </SemanticTable.Row>
                    ))}
                  </SemanticTable.Footer>
                )}
              </SemanticTable>
            </div>
          </>
        ) : (
          <div className={styles.noDataContainer}>
            <div className={styles.noDataText}>
              {!!search
                ? "Nothing was found. \n" +
                  "Please change your search options. "
                : "There is no information yet."}
            </div>
            <div>
              <LogoIcon style={{ height: "130px", width: "130px" }} />
            </div>
          </div>
        )}
      </Container>
    </>
  );
};

export default Table;
