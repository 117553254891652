import * as React from "react";
import { Icon } from "semantic-ui-react";

export const RestoreIcon = props => {
  return (
    <Icon {...props}>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.75 12.6875C1.75 13.0356 1.88828 13.3694 2.13442 13.6156C2.38056 13.8617 2.7144 14 3.0625 14H10.9375C11.2856 14 11.6194 13.8617 11.8656 13.6156C12.1117 13.3694 12.25 13.0356 12.25 12.6875V3.5H1.75V12.6875ZM4.24676 7.9625L6.69074 5.38508C6.73019 5.34245 6.77803 5.30844 6.83124 5.28518C6.88446 5.26192 6.94192 5.24991 7 5.24991C7.05808 5.24991 7.11553 5.26192 7.16875 5.28518C7.22197 5.30844 7.26981 5.34245 7.30926 5.38508L9.75324 7.9625C10.0289 8.25289 9.83363 8.75 9.44371 8.75H7.875V11.8125C7.875 11.9285 7.8289 12.0398 7.74686 12.1219C7.66481 12.2039 7.55353 12.25 7.4375 12.25H6.5625C6.44647 12.25 6.33519 12.2039 6.25314 12.1219C6.17109 12.0398 6.125 11.9285 6.125 11.8125V8.75H4.55629C4.16637 8.75 3.97113 8.25289 4.24676 7.9625ZM12.6875 0.875004H9.40625L9.14922 0.363677C9.09477 0.25436 9.0109 0.162405 8.90704 0.0981575C8.80318 0.0339095 8.68345 -8.27959e-05 8.56133 4.7941e-06H5.43594C5.31409 -0.000463619 5.19457 0.0334019 5.09108 0.0977214C4.98759 0.162041 4.90431 0.254213 4.85078 0.363677L4.59375 0.875004H1.3125C1.19647 0.875004 1.08519 0.921098 1.00314 1.00315C0.921094 1.08519 0.875 1.19647 0.875 1.3125L0.875 2.1875C0.875 2.30354 0.921094 2.41482 1.00314 2.49686C1.08519 2.57891 1.19647 2.625 1.3125 2.625H12.6875C12.8035 2.625 12.9148 2.57891 12.9969 2.49686C13.0789 2.41482 13.125 2.30354 13.125 2.1875V1.3125C13.125 1.19647 13.0789 1.08519 12.9969 1.00315C12.9148 0.921098 12.8035 0.875004 12.6875 0.875004Z"
          fill="#0E9354"
        />
      </svg>
    </Icon>
  );
};
