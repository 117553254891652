import React from "react";

import PropTypes from "prop-types";
import StationGraphs from "./StationGraphs";
import StationsControlBar from "./StationsControlBar";
import { Grid, Header } from "semantic-ui-react";
import Loader from "../../../components/Loader";
import { connect } from "react-redux";
import styles from "./Sidebar.module.css";
import moment from "moment";
import StationDataTable from "./StationDataTable/StationDataTable";

const Sidebar = ({
  id,
  stationData,
  showCharts,
  showStationDataTable,
  isFetching
}) => {
  return !id || !stationData ? null : (
    <Grid>
      <Grid.Row>
        <Grid.Column width={16}>
          <Header as="h1" className={styles.name}>
            {stationData.displayName}
          </Header>
          {stationData.stationId}
          {" • "}
          {stationData.extraData.info.deviceName}
          <span className={styles.lastComm}>
            <span> • </span>Last communication:{" "}
            {moment(stationData.extraData.dates.lastCommunication).format(
              "DD/MM/YYYY HH:mm:ss"
            )}
          </span>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <StationsControlBar id={id} />
        </Grid.Column>
      </Grid.Row>
      {isFetching ? (
        <Grid.Row>
          <Grid.Column width={16}>
            <Loader />
          </Grid.Column>
        </Grid.Row>
      ) : (
        <>
          {showCharts && (
            <Grid.Row>
              <Grid.Column width={16}>
                <StationGraphs id={id} />
              </Grid.Column>
            </Grid.Row>
          )}
          {showStationDataTable && (
            <Grid.Row>
              <Grid.Column width={16}>
                <StationDataTable />
              </Grid.Column>
            </Grid.Row>
          )}
        </>
      )}
    </Grid>
  );
};

Sidebar.propTypes = {
  id: PropTypes.number,
  stationData: PropTypes.object,
  isFetching: PropTypes.bool.isRequired,
  showCharts: PropTypes.bool,
  showStationDataTable: PropTypes.bool
};

Sidebar.defaultProps = {};

const mapStateToProps = ({
  weatherStation: {
    stationData: { isFetching, showCharts, showStationDataTable }
  }
}) => {
  return { isFetching, showCharts, showStationDataTable };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
