export const actionType = {
  FETCH_PALLETS_START: 'PALLETS/FETCH_START',
  FETCH_PALLETS: 'PALLETS/FETCH',
  FETCH_PALLETS_FAIL: 'PALLETS/FETCH_FAIL',

  FETCH_PALLETS_LIST_START: 'PALLETS/FETCH_LIST_START',
  FETCH_PALLETS_LIST: 'PALLETS/FETCH_LIST',
  FETCH_PALLETS_LIST_FAIL: 'PALLETS/FETCH_LIST_FAIL',

  GET_PALLETS_BY_ID_START: 'PALLETS/GET_BY_ID_START',
  GET_PALLETS_BY_ID: 'PALLETS/GET_BY_ID',
  GET_PALLETS_BY_ID_FAIL: 'PALLETS/GET_BY_ID_FAIL',

  ADD_PALLETS_START: 'PALLETS/ADD_START',
  ADD_PALLETS: 'PALLETS/ADD',
  ADD_PALLETS_FAIL: 'PALLETS/ADD_FAIL',

  ADD_BATCH_PALLETS_START: 'PALLETS/ADD_BATCH_START',
  ADD_BATCH_PALLETS: 'PALLETS/ADD_BATCH',
  ADD_BATCH_PALLETS_FAIL: 'PALLETS/ADD_BATCH_FAIL',

  UPDATE_PALLETS_START: 'PALLETS/UPDATE_START',
  UPDATE_PALLETS: 'PALLETS/UPDATE',
  UPDATE_PALLETS_FAIL: 'PALLETS/UPDATE_FAIL',

  DELETE_PALLETS_START: 'PALLETS/DELETE_START',
  DELETE_PALLETS: 'PALLETS/DELETE',
  DELETE_PALLETS_FAIL: 'PALLETS/DELETE_FAIL',

  PALLETS_SET_PRINT_LAYOUT: 'PALLETS/SET_PRINT_LAYOUT',

  CLEAR_PALLETS_ERROR: 'PALLETS/CLEAR_PALLETS_ERROR',
};
