import React from "react";
import { Radio } from "semantic-ui-react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toggleShowArchived } from "../../../actions/Farms/addNewFarm";

const ShowArchiveToggle = ({ showArchived, actions }) => (
  <Radio
    toggle
    checked={showArchived}
    onChange={() => {
      actions.toggleShowArchived(!showArchived);
    }}
  />
);

ShowArchiveToggle.propTypes = {
  showArchived: PropTypes.object.isRequired,
  actions: PropTypes.object
};

function mapStateToProps({
  farms: {
    newFarm: { showArchived }
  }
}) {
  return { showArchived };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ toggleShowArchived }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowArchiveToggle);
