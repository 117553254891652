import PropTypes from "prop-types";
import React, { useEffect } from "react";
import CollapseBlock from "../../../../components/CollapseBlock";
import SprayTasksTable from "../../../Farms/PatchSidebar/components/SprayTasksTable";
import { bindActionCreators } from "redux";
import { getSprayTasksForArea } from "../../../../actions/Farms/farms";
import { connect } from "react-redux";

const sprayTaskColumns = [
  {
    id: "id",
    accessor: "id",
    Header: "Task ID"
  },
  {
    id: "status",
    accessor: "status",
    Header: "Status"
  },
  {
    id: "chemicals",
    accessor: "chemicals",
    Header: "Chemicals"
  },
  {
    id: "supervisors",
    accessor: "supervisors",
    Header: "Supervisors"
  },
  {
    id: "assignees",
    accessor: "assignees",
    Header: "Assignees"
  }
];

const mapSprayTaskToFormat = sprayTasks =>
  sprayTasks.map(task => ({
    id: `ST-${task.id}`,
    status: task.status,
    chemicals: task.chemicals
      .map(chemical => chemical.chemical.name)
      .join(", "),
    supervisors: task.supervisors.map(
      ({ employee: { firstName, lastName } }) => `${firstName} ${lastName}`
    ),
    assignees: task.assignees.map(
      ({ employee: { firstName, lastName } }) => `${firstName} ${lastName}`
    )
  }));

const SprayTasks = ({
  patch,
  actions: { getSprayTasksForArea },
  sprayTasksForArea,
  dateFrom,
  dateTo
}) => {
  useEffect(() => {
    if (dateFrom && dateTo) getSprayTasksForArea(patch.id, dateFrom, dateTo);
  }, [getSprayTasksForArea, patch, dateFrom, dateTo]);

  const { completedTasks, uncompletedTasks } = sprayTasksForArea.reduce(
    (prev, curr) => {
      const temp = { ...prev };

      curr.status === "COMPLETED"
        ? (temp.completedTasks = [...temp.completedTasks, curr])
        : (temp.uncompletedTasks = [...temp.uncompletedTasks, curr]);

      return temp;
    },
    { completedTasks: [], uncompletedTasks: [] }
  );

  return (
    <div>
      <CollapseBlock title={"Spray Tasks completed"} defaultClosed>
        <SprayTasksTable
          className={"border"}
          columns={sprayTaskColumns}
          data={mapSprayTaskToFormat(completedTasks)}
        />
      </CollapseBlock>
      <CollapseBlock title={"Spray Tasks outstanding"}>
        <SprayTasksTable
          className={"border"}
          columns={sprayTaskColumns}
          data={mapSprayTaskToFormat(uncompletedTasks)}
        />
      </CollapseBlock>
    </div>
  );
};

SprayTasks.propTypes = {
  actions: PropTypes.object,
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
  patch: PropTypes.object,
  sprayTasksForArea: PropTypes.array
};

const mapStateToProps = ({ farms: { sprayTasksForArea } }) => ({
  sprayTasksForArea
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getSprayTasksForArea
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(SprayTasks);
