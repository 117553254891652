import { actionTypes } from "../../constants/ImageViewer";

const initialState = {
  href: null
};

export default (state = initialState, action) => {
  if (!action) return state;

  switch (action.type) {
    case actionTypes.SET_IMAGE_HREF:
      return {
        ...state,
        href: action.payload
      };

    case actionTypes.CLEAR_IMAGE_HREF:
      return {
        ...state,
        href: null
      };

    default:
      return state;
  }
};
