import React from "react";
import { INTERVENTION_TYPES } from "constants/Recommendations/types";
import { Dropdown } from "semantic-ui-react";
import { objectToSelectOptions } from "utils/objectUtils";

const interventionTypesOptions = objectToSelectOptions(INTERVENTION_TYPES);

const InterventionTypeDropdown = ({ disabled, value, onChange }) => {
  return (
    <Dropdown
      clearable
      fluid
      selection
      disabled={disabled}
      options={interventionTypesOptions}
      value={value}
      onChange={(_, { value }) => onChange(value)}
    />
  );
};

export default InterventionTypeDropdown;
