import { actionType } from "../../constants/LaborGroups";
import { keyBy } from "lodash";

const laborFormInitialState = {
  name: null,
  employeeIds: []
};

const laborAssignFormInitialState = {
  groupID: null,
  employeeIds: []
};

const initialState = {
  data: {
    content: [],
    number: 0,
    totalPages: 0,
    totalElements: 0,
    size: 10,
    isFetching: false
  },
  list: {
    content: [],
    isFetching: false
  },
  laborForm: {
    ...laborFormInitialState
  },
  laborAssignForm: {
    ...laborAssignFormInitialState
  },
  isFetching: false,
  error: null
};

export default (state = initialState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case actionType.ARCHIVE_LABOR_GROUP_START:
    case actionType.LABOR_GROUPS_DELETE_START:
    case actionType.FETCH_LABOR_GROUPS_START: {
      return {
        ...state,
        isFetching: true
      };
    }

    case actionType.FETCH_LABOR_GROUPS_LIST_START:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: true
        }
      };

    case actionType.FETCH_LABOR_GROUPS_LIST:
      return {
        ...state,
        list: { ...action.payload, isFetching: false }
      };

    case actionType.FETCH_LABOR_GROUPS: {
      return {
        ...state,
        data: action.payload,
        isFetching: false
      };
    }

    case actionType.FETCH_LABOR_GROUPS_LIST_FAIL:
      return {
        ...state,
        list: { ...state.list, isFetching: false },
        error: action.payload
      };

    case actionType.FETCH_LABOR_GROUPS_FAIL:
    case actionType.LABOR_GROUPS_DELETE_FAIL:
    case actionType.ARCHIVE_LABOR_GROUP_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    }

    case actionType.LABOR_GROUP_FORM_SET: {
      return {
        ...state,
        laborForm: action.payload
      };
    }

    case actionType.LABOR_GROUP_FORM_CHANGE: {
      return {
        ...state,
        laborForm: {
          ...state.laborForm,
          [action.payload.name]: action.payload.value
        }
      };
    }

    case actionType.LABOR_GROUPS_DELETE:
    case actionType.ARCHIVE_LABOR_GROUP: {
      const deletedElementsIdsMap = keyBy(action.payload);

      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.filter(e => !deletedElementsIdsMap[e.id])
        },
        isFetching: false,
        error: null
      };
    }

    case actionType.LABOR_GROUP_FORM_CLEAR: {
      return {
        ...state,
        laborForm: laborFormInitialState
      };
    }

    case actionType.LABOR_GROUP_ASSIGN_FORM_CHANGE: {
      return {
        ...state,
        laborAssignForm: {
          ...state.laborAssignForm,
          [action.payload.name]: action.payload.value
        }
      };
    }

    case actionType.LABOR_GROUP_ASSIGN_FORM_CLEAR: {
      return {
        ...state,
        laborAssignForm: laborAssignFormInitialState
      };
    }

    case actionType.LABOR_GROUP_DELETE: {
      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.filter(g => g.id !== action.payload.id)
        },
        error: null
      };
    }
    case actionType.CLEAR_LABOR_GROUP_ERROR:
      return {
        ...state,
        error: null
      };

    case actionType.LABOR_GROUP_UPDATE_FAIL:
    case actionType.LABOR_GROUP_DELETE_FAIL:
    case actionType.LABOR_GROUP_CREATE_FAIL: {
      return {
        ...state,
        laborForm: laborFormInitialState,
        error: action.payload
      };
    }

    default:
      return state;
  }
};
