import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Grid, Input, TextArea } from "semantic-ui-react";
import { Form } from "formsy-semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { abilitiesSelector } from "../../../../../../selectors/user";
import styles from "./EditPackedBoxesFormContent.module.css";
import PackedBoxesDetails from "../../PackedBoxesDetails";
import SegmentClosable from "../../../../../../components/SegmentClosable";
import { getErrorFields } from "../../../../../../utils/validationRules";
import { scrollIntoError } from "../../../../../../utils/scrollIntoError";
import PricingDetails from "../../PricingDetails";

class EditPackedBoxesFormContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorsInSegments: {}
    };
    this.formRef = React.createRef();
  }

  handleErrors = () => {
    const { formRef } = this.props;
    const formsyForm = (formRef || this.formRef).current.formsyForm;
    const errorFields = getErrorFields(formsyForm);
    this.setState({
      errorsInSegments: {
        information: {
          error: errorFields.name
        }
      }
    });
    setTimeout(scrollIntoError, 300);
  };

  onSubmit = () => {
    const { formRef } = this.props;
    const targetRef = formRef || this.formRef;
    targetRef.current.submit();
  };

  render() {
    const {
      Can,
      showMobileHeader,
      formRef,
      changeValue,
      showCheckbox,
      onValidSubmit,
      isActive,
      setActive,
      doSubmitForm,
      values: {
        id,
        barcodeId,
        ids,
        scannedAt,
        area,
        boxUnit,
        packLocation,
        packingShed,
        intendedDestination,
        notes,
        pickingPrice,
        paddingPrice,
        cartingPrice
      }
    } = this.props;
    const { errorsInSegments } = this.state;
    const columnMiddle = {
      mobile: 16,
      tablet: 16,
      computer: 10,
      largeScreen: 10,
      widescreen: 10
    };

    const columnMiddleWithSelect = {
      mobile: 16,
      tablet: 16,
      computer: 11,
      largeScreen: 11,
      widescreen: 11
    };

    return (
      <Form
        onValidSubmit={onValidSubmit}
        onInvalidSubmit={this.handleErrors}
        loading={false}
        autoComplete="off"
        ref={formRef || this.formRef}
      >
        <div className={`${styles.sprayGrid}`}>
          <div className={styles.sprayFormContainer} id="sprayGrid">
            <div>
              <SegmentClosable title="Box information">
                <Grid>
                  {barcodeId && (
                    <Grid.Row>
                      <Grid.Column {...columnMiddle}>
                        <Form.Field className="sprayField">
                          <label>Id</label>
                          <Input
                            name="Id"
                            disabled={true}
                            size="large"
                            value={barcodeId}
                          />
                        </Form.Field>
                      </Grid.Column>
                    </Grid.Row>
                  )}
                  {ids && (
                    <Grid.Row>
                      <Grid.Column
                        {...(showCheckbox
                          ? columnMiddleWithSelect
                          : columnMiddle)}
                      >
                        <Form.Field className="sprayField">
                          <label>Ids</label>
                          <TextArea
                            name="Id"
                            readonly={true}
                            size="large"
                            value={ids.reduce((s, id) => `${s}${id}\n`, "")}
                          />
                        </Form.Field>
                      </Grid.Column>
                    </Grid.Row>
                  )}
                  <PackedBoxesDetails
                    scannedAt={scannedAt}
                    area={area}
                    boxUnit={boxUnit}
                    packLocation={packLocation}
                    packingShed={packingShed}
                    intendedDestination={intendedDestination}
                    notes={notes}
                    changeValue={changeValue}
                    showCheckbox={showCheckbox}
                    isActive={isActive}
                    setActive={setActive}
                  />
                </Grid>
              </SegmentClosable>
              <SegmentClosable
                title="Pricing"
                errors={errorsInSegments.information}
              >
                <Grid>
                  <PricingDetails
                    pickingPrice={pickingPrice}
                    paddingPrice={paddingPrice}
                    cartingPrice={cartingPrice}
                    changeValue={changeValue}
                    showCheckbox={showCheckbox}
                    isActive={isActive}
                    setActive={setActive}
                  />
                </Grid>
              </SegmentClosable>
            </div>
          </div>
          {!showMobileHeader && (
            <div className={`${styles.sprayFormFooter} show-sm`}>
              <div className="text-right">
                <Can I="update" a="packed_boxes">
                  <Button
                    primary
                    size="large"
                    type="button"
                    onClick={doSubmitForm || this.onSubmit}
                  >
                    Save
                  </Button>
                </Can>
              </div>
            </div>
          )}
        </div>
      </Form>
    );
  }
}

EditPackedBoxesFormContent.propTypes = {
  online: PropTypes.bool,
  showCheckbox: PropTypes.bool,
  isActive: PropTypes.bool,
  showMobileHeader: PropTypes.bool,
  formRef: PropTypes.func,
  setActive: PropTypes.func,
  doSubmitForm: PropTypes.func,
  onValidSubmit: PropTypes.func,
  Can: PropTypes.func,
  changeValue: PropTypes.func,
  values: PropTypes.object
};

const mapStateToProps = state => {
  return {
    Can: abilitiesSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({}, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPackedBoxesFormContent);
