import React, { useState } from "react";
import { Button, Container, Icon } from "semantic-ui-react";
import styles from "./ShowRateHistory.module.css";
import RateHistoryTable from "./RateHistoryTable";

export const ShowRateHistory = ({ rateHistory }) => {
  const [showHistory, setShowHistory] = useState(false);

  return (
    <div>
      <Container fluid className={styles.textButtonContainer}>
        <Button
          basic
          type="button"
          onClick={() => setShowHistory(!showHistory)}
          className={styles.textButton}
        >
          <span>{`${showHistory ? "Hide" : "Show"} rate history`}</span>{" "}
          <Icon
            name={showHistory ? "chevron up" : "chevron down"}
            size="small"
          />
        </Button>
      </Container>
      {showHistory && (
        <div>
          {rateHistory?.length ? (
            <RateHistoryTable rateHistory={rateHistory} />
          ) : (
            <h1>No history</h1>
          )}
        </div>
      )}
    </div>
  );
};
