import React from "react";
import { Icon } from "semantic-ui-react";
import styles from "./ImageViewer.module.css";
import { useDispatch, useSelector } from "react-redux";
import { clearImage } from "../../actions/ImageViewer";

const ImageViewer = () => {
  const dispatch = useDispatch();
  const { href } = useSelector(state => state.imageViewer);

  if (!href) return <div />;

  return (
    <div className={styles.modalBackdrop}>
      <div
        className={styles.iconWrapper}
        onClick={() => dispatch(clearImage())}
      >
        <Icon name={"close"} className={styles.icon} />
      </div>
      <img className={styles.image} src={href} alt="" />
    </div>
  );
};

export default ImageViewer;
