import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MultiSelectFilter from "./MultiSelectFilter/MultiSelectFilter";
import { fetchEmployeeGroups } from "../../../actions/EmployeeGroups/employeeGroups";

const EmployeeGroupsMultiSelectFilter = ({
  actions: { fetchList },
  list,
  isFetching,
  ...extraProps
}) => {
  useEffect(() => {
    fetchList({ unpaged: true });
  }, [fetchList]);

  const options = useMemo(
    () =>
      list.map(item => ({
        key: item.id,
        text: item.name,
        value: item.id
      })),
    [list]
  );

  return <MultiSelectFilter options={options} numbers={true} {...extraProps} />;
};

EmployeeGroupsMultiSelectFilter.propTypes = {
  isFetching: PropTypes.bool,
  list: PropTypes.array,
  actions: PropTypes.object
};

const mapStateToProps = ({
  employeeGroups: {
    data: { content },
    isFetching
  }
}) => {
  return {
    list: content,
    isFetching
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchList: fetchEmployeeGroups
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeGroupsMultiSelectFilter);
