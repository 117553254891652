import PropTypes from "prop-types";
import {
  getPageableDataShape,
  userShape,
  machineryShape,
  attachmentShape,
  chemicalShape
} from "constants/shared/types";
import { ROWS_TO_SPRAY } from "../shared";

const assigneeShape = PropTypes.shape({
  employeeId: PropTypes.number
});

export const RECOMMENDATION_STATUSES = {
  DRAFT: "Draft",
  PENDING: "Pending",
  ACCEPTED: "Accepted",
  REJECTED: "Rejected"
};

export const APPLICATION_TYPES = {
  SPRAY: "Spray",
  SPREAD: "Spread",
  FERTIGATION: "Fertigation"
};

export const INTERVENTION_TYPES = {
  CORRECTIVE: "Corrective",
  PREVENTIVE: "Preventive"
};

export const URGENCY_LEVELS = {
  LOW: "Low",
  MEDIUM: "Medium",
  HIGH: "High"
};

export const PRESSURE_UNITS = {
  PSI: "PSI",
  BAR: "BAR"
};

export const recommendationAreaShape = PropTypes.shape({
  areaId: PropTypes.number,
  area: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    size: PropTypes.number
  })
});

export const recommendationTaskShape = PropTypes.shape({
  attachments: PropTypes.arrayOf(attachmentShape),
  assignees: PropTypes.arrayOf(assigneeShape),
  applicationType: PropTypes.oneOf(Object.keys(APPLICATION_TYPES)),
  areas: PropTypes.arrayOf(recommendationAreaShape),
  chemicals: PropTypes.arrayOf(
    PropTypes.shape({
      chemical: chemicalShape,
      chemicalId: PropTypes.number
    })
  ),
  creator: userShape,
  creatorId: PropTypes.number,
  gear: PropTypes.string,
  conc: PropTypes.number,
  comment: PropTypes.string,
  expiryDate: PropTypes.string,
  id: PropTypes.number,
  interventionType: PropTypes.arrayOf(Object.keys(INTERVENTION_TYPES)),
  literPerHectare: PropTypes.number,
  growthStage: PropTypes.string,
  machinery: machineryShape,
  machinerySize: PropTypes.number,
  machinerySpeed: PropTypes.number,
  nozzle: PropTypes.number,
  nozzlesPerSide: PropTypes.number,
  plannedDate: PropTypes.string,
  pressure: PropTypes.number,
  pressureUnit: PropTypes.oneOf(Object.keys(PRESSURE_UNITS)),
  scoutingId: PropTypes.number,
  status: PropTypes.oneOf(Object.keys(RECOMMENDATION_STATUSES)),
  templateName: PropTypes.string,
  templateId: PropTypes.string,
  name: PropTypes.string,
  rejectReason: PropTypes.string,
  rowsToSpray: PropTypes.oneOf(Object.keys(ROWS_TO_SPRAY)),
  rowWidth: PropTypes.number,
  rpm: PropTypes.string,
  pto: PropTypes.number,
  urgencyLevel: PropTypes.oneOf(Object.keys(URGENCY_LEVELS))
});

export const recommendationsListPropsShape = PropTypes.shape({
  ...getPageableDataShape(recommendationTaskShape)
});
