import React, { Component } from 'react';
import moment from 'moment';
import { Button, Grid, Icon, Input } from 'semantic-ui-react';
import styles from './WorkingTimes.module.css';
import DatePicker from '../../../../components/DatePicker';
import PropTypes from 'prop-types';
import ObjectId from 'bson-objectid';

export const createDefaultWorkingTime = () => ({
  _id: ObjectId().toHexString(),
  date: moment().format('YYYY-MM-DD'),
  workingFrom: '00:00:00',
  workingTo: '00:00:00',
});

export const computeWorkingTime = workingTime => {
  const { workingFrom, workingTo } = workingTime;
  let totalTime = '--:--';
  if (workingTo && workingFrom) {
    const fromMoment = moment(workingFrom, 'HH:mm');
    const toMoment = moment(workingTo, 'HH:mm');

    if (!toMoment.isBefore(fromMoment)) {
      const duration = moment.duration(toMoment.diff(fromMoment));
      totalTime = moment.utc(duration.as('millisecond')).format('HH:mm');
    }
  }

  return totalTime;
};

class WorkingTime extends Component {
  state = {
    trigger: false,
  };

  setDate = date => {
    this.setState({
      trigger: false,
    });

    return { ...this.props.workingTime, date: date };
  };

  toggleShowCalendar = () => {
    this.setState({ trigger: !this.state.trigger });
  };

  setWorkingTime = (field, time) => {
    return { ...this.props.workingTime, [field]: `${time}:00` };
  };

  render() {
    const {
      showDeleteButton,
      onChange,
      onDelete,
      workingTime: { date, workingFrom, workingTo },
    } = this.props;
    return (
      <React.Fragment>
        <Grid.Row
          className={`${styles.sprayField} ${styles.dateRow} ${showDeleteButton ? styles.showDeleteButton : ''}`}
        >
          <Grid.Column className={`${styles.sprayField} ${styles.required}`}>
            <DatePicker
              value={date}
              onChange={date => {
                onChange(this.setDate(date));
              }}
              disableClear={true}
            />
            {showDeleteButton && (
              <div className={styles.deleteButton}>
                <Button
                  className={`${styles.minusIcon} transparent`}
                  icon
                  onClick={event => onDelete(event, this.state.workingTime)}
                >
                  <Icon name="minus circle" color="red" size="large" />
                </Button>
              </div>
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className={`${styles.sprayField} ${showDeleteButton ? styles.showDeleteButton : ''}`}>
          <Grid.Column
            className={`${styles.spraySection} ${styles.timeRange}`}
            mobile={10}
            tablet={10}
            computer={10}
            largeScreen={10}
          >
            <div className={`${styles.sprayField} ${styles.required}`}>
              <div className={styles.rangePicker}>
                <div>
                  <Input
                    size="small"
                    fluid
                    format="HH:mm"
                    type="time"
                    value={workingFrom.substring(0, 5)}
                    onChange={e => {
                      onChange(this.setWorkingTime('workingFrom', e.target.value));
                    }}
                    className={`${styles.timePicker} ${styles.from}`}
                  />
                </div>
                <div className={styles.range}>-</div>
                <div>
                  <Input
                    size="small"
                    fluid
                    format="HH:mm"
                    type="time"
                    className={`${styles.timePicker} ${styles.to}`}
                    value={workingTo.substring(0, 5)}
                    onChange={e => {
                      onChange(this.setWorkingTime('workingTo', e.target.value));
                    }}
                  />
                </div>
              </div>
            </div>
          </Grid.Column>
          <Grid.Column mobile={6} tablet={6} computer={6} largeScreen={6}>
            <div>
              <label>
                <Input
                  size="small"
                  fluid
                  readOnly
                  className={styles.total}
                  value={computeWorkingTime(this.props.workingTime)}
                  icon="time"
                />
              </label>
            </div>
          </Grid.Column>
        </Grid.Row>
      </React.Fragment>
    );
  }
}

WorkingTime.propTypes = {
  workingTime: PropTypes.object,
  showDeleteButton: PropTypes.bool,
  validation: PropTypes.bool,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
};

WorkingTime.defaultProps = {
  workingTime: createDefaultWorkingTime(),
  showDeleteButton: false,
  validation: true,
  onChange: () => {},
  onDelete: () => {},
};

export default WorkingTime;
