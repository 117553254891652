import React from "react";
import {
  AreaFilter,
  BoxUnitFilter,
  ContractorFilter,
  EmployeeFilter,
  UsedVarietyFilter,
  VarietyFilter
} from "../ExpandedTable/filters";
import PropTypes from "prop-types";

export const ExtVarietyFilter = ({ value = [], setFilter, ...extraProps }) => {
  return (
    <VarietyFilter column={{ filterValue: value, setFilter }} {...extraProps} />
  );
};

ExtVarietyFilter.propTypes = {
  value: PropTypes.array,
  setFilter: PropTypes.func
};

export const ExtUsedVarietyFilter = ({
  value = [],
  setFilter,
  ...extraProps
}) => {
  return (
    <UsedVarietyFilter
      column={{ filterValue: value, setFilter }}
      {...extraProps}
    />
  );
};

ExtUsedVarietyFilter.propTypes = {
  value: PropTypes.array,
  setFilter: PropTypes.func
};

export const ExtContractorFilter = ({
  value = [],
  setFilter,
  ...extraProps
}) => {
  return (
    <ContractorFilter
      column={{ filterValue: value, setFilter }}
      {...extraProps}
    />
  );
};

ExtContractorFilter.propTypes = {
  value: PropTypes.array,
  setFilter: PropTypes.func
};

export const ExtEmployeeFilter = ({ value = [], setFilter, ...extraProps }) => {
  return (
    <EmployeeFilter
      column={{ filterValue: value, setFilter }}
      {...extraProps}
    />
  );
};

ExtEmployeeFilter.propTypes = {
  value: PropTypes.array,
  setFilter: PropTypes.func
};

export const ExtAreaFilter = ({ value = [], setFilter, ...extraProps }) => {
  return (
    <AreaFilter column={{ filterValue: value, setFilter }} {...extraProps} />
  );
};

ExtAreaFilter.propTypes = {
  value: PropTypes.array,
  setFilter: PropTypes.func
};

export const ExtBoxUnitFilter = ({ value = [], setFilter, ...extraProps }) => {
  return (
    <BoxUnitFilter column={{ filterValue: value, setFilter }} {...extraProps} />
  );
};

ExtBoxUnitFilter.propTypes = {
  value: PropTypes.array,
  setFilter: PropTypes.func
};
