import PropTypes from "prop-types";
import React from "react";
import styles from "./QCFormSubmission.module.css";
import { Table } from "semantic-ui-react";
import moment from "moment";

const Header = ({ completedFormHeaders }) => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell className={styles.pl10}>Date</Table.HeaderCell>
      <Table.HeaderCell>Picker</Table.HeaderCell>
      <Table.HeaderCell>Bin No.</Table.HeaderCell>
      <Table.HeaderCell>Block Row ID</Table.HeaderCell>
      <Table.HeaderCell>Variety</Table.HeaderCell>
      <Table.HeaderCell>Time Received</Table.HeaderCell>
      {completedFormHeaders.map((header, i) => (
        <Table.HeaderCell key={`completed_form_header_${i}`}>
          {header}
        </Table.HeaderCell>
      ))}
      <Table.HeaderCell>
        Inspected by
        <br />
        (Name)
      </Table.HeaderCell>
      <Table.HeaderCell>
        Inspected by
        <br />
        (Signature)
      </Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

const QcFormSubmission = ({ submissions, formTemplate }) => {
  const formHeaders = Object.keys(formTemplate);

  return (
    <Table unstackable compact className={styles.qcSubmissionTable}>
      <Header completedFormHeaders={formHeaders} />
      <Table.Body>
        {submissions.map((submission, i) => (
          <Table.Row key={`submission_row_${i}`}>
            <Table.Cell className={styles.pl10}>
              {moment(submission.completedAt).format("DD/MM/YYYY")}
            </Table.Cell>
            <Table.Cell>
              {submission.barcode && submission.barcode.employeeBinParts
                ? submission.barcode.employeeBinParts
                    .map(
                      ebp =>
                        `${ebp.employee.firstName} ${ebp.employee.lastName}`
                    )
                    .join(", ")
                : "-"}
            </Table.Cell>
            <Table.Cell>{submission.barcode.id}</Table.Cell>
            <Table.Cell>
              {submission.barcode
                ? `${submission.barcode.rowsPrefix || ""}${submission.barcode
                    .row || ""}`
                : "-"}
            </Table.Cell>
            <Table.Cell>
              {submission.barcode && submission.barcode.variety
                ? submission.barcode.variety.name
                : "-"}
            </Table.Cell>
            <Table.Cell>
              {moment(submission.completedAt).format("hh:mm")}
            </Table.Cell>
            {formHeaders.map((header, i) => (
              <Table.Cell key={`completed_form_header_${i}`}>
                {formTemplate[header].type === "CHECKBOX"
                  ? submission.completedForm[header]
                    ? "Yes"
                    : "No"
                  : submission.completedForm[header]}
              </Table.Cell>
            ))}
            <Table.Cell>{`${submission.completedBy.firstName} ${submission.completedBy.lastName}`}</Table.Cell>
            <Table.Cell />
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

QcFormSubmission.propTypes = {
  submissions: PropTypes.array.isRequired,
  formTemplate: PropTypes.object
};

Header.propTypes = {
  completedFormHeaders: PropTypes.array
};

QcFormSubmission.defaultProps = {
  submission: []
};

export default QcFormSubmission;
