import Home from "../Home";
import Dashboard from "../Dashboard";
import NotFound from "../NotFound";
import Blank from "../Blank";
import Farms from "../Farms";
import SprayTasks from "../SprayDiary/Tasks";
import AddNewFarm from "../Farms/AddNewFarm";
import EditFarm from "../Farms/EditFarm";
import LogOff from "../Account/LogOff";
import CreateSprayTask from "../SprayDiary/CreateSprayTask/CreateSprayTask";
import Chemicals from "../Chemicals/Inventory/Inventory";
import Sheds from "../Chemicals/Sheds/Sheds";
import NutrientBreakdown from "../Reports/ProductReport/NutrientBreakdown/index";
import PlantingReport from "../Reports/PlantingReport";
import ProductTypeUsage from "../Reports/ProductReport/ProductTypeUsage/index";
import ProductUsage from "../Reports/ProductReport/ProductUsage/index";
import InventoryMovements from "../Chemicals/InventoryMovements/InventoryMovements";
import PendingMovements from "../Chemicals/PendingMovements/PendingMovements";
import PurchaseHistory from "../Chemicals/PurchaseHistory/PurchaseHistory";
import AddNewChemical from "../Chemicals/AddNewChemical";
import Machinery from "../Machinery";
import AddNewMachinery from "../Machinery/AddNewMachinery";
import Employees from "../Labour/Employees";
import Settings from "../Account/Settings";
import WeatherStations from "../WeatherStations";
import AddWeatherStation from "../WeatherStations/AddWeatherStation";
import Clients from "../Clients";
import AddNewClient from "../Clients/AddNewClient";
import SprayRecords from "../SprayDiary/SprayRecords";
import EmployeesBarcodesGenerator from "../Barcodes/GenerateEmployeesBarcodes";
import GeneralBarcodesGenerator from "../Barcodes/GenerateGeneralBarcodes";
import TagBarcodesGenerator from "../Barcodes/GenerateTagBarcodes";
import Harvest from "../Harvest/Harvest";
import HarvestUnitsControl from "../HarvestUnitsControl/index";
import HarvestByArea from "../Reports/Harvest/FieldScanning/HarvestByArea";
import PickerReport from "../Reports/Harvest/FieldScanning/PickerReport";
import PickToPackPickerReport from "../Reports/Harvest/PickToPack/PickerReport";
import ContractorReport from "../Reports/Harvest/FieldScanning/ContractorReport";
import PickToPackContractorReport from "../Reports/Harvest/PickToPack/ContractorReport";
import HarvestByVariety from "../Reports/Harvest/FieldScanning/HarvestByVariety";
import PickToPackHarvestByArea from "../Reports/Harvest/PickToPack/HarvestByArea";
import PickToPackHarvestByVariety from "../Reports/Harvest/PickToPack/HarvestByVariety";
import PackedBoxes from "../Harvest/PackedBoxes";
import FarmsRecords from "../Farms/FarmsRecords";
import BoxUnits from "../BoxUnits";
import AddBoxUnits from "../BoxUnits/AddBoxUnits";
import PackingSheds from "../PackingSheds";
import AddPackingSheds from "../PackingSheds/AddPackingSheds";
import Contractors from "../Labour/Contractors";
import WorkersPayroll from "../WorkersPayroll";
import ScanPackedBoxes from "../Harvest/PackedBoxes/Scan/ScanPackedBoxes";
import EditPackedBoxes from "../Harvest/PackedBoxes/Scan/EditPackedBoxes";
import Pallets from "../Harvest/Pallets";
import AddPallet from "../Harvest/Pallets/AddPallet";
import Stock from "../Harvest/Stock";
import AddStockItem from "../Harvest/Stock/AddStockItem";
import ProduceByVarietyReport from "../Harvest/PackedBoxes/Reports/ProduceByVarietyReport";
import ProduceByAreaReport from "../Harvest/PackedBoxes/Reports/ProduceByAreaReport";
import CustomPackedBoxesReport from "../Harvest/PackedBoxes/Reports/CustomPackedBoxesReport";
import PickerAndVarietyReport from "../Harvest/PackedBoxes/Reports/PickerAndVarietyReport";
import BatchEditPackedBoxes from "../Harvest/PackedBoxes/Scan/BatchEditPackedBoxes";
import ScannedGeneralBarcodesReport from "../Harvest/GeneralBarcodes/Reports/ScannedGeneralBarcodesReport";
import Vendors from "../Harvest/Vendors";
import AddVendor from "../Harvest/Vendors/AddVendor/AddVendor";
import Dispatches from "../Harvest/Dispatches";
import AddDispatch from "../Harvest/Dispatches/AddDispatch/AddDispatch";
import LaborGroups from "../Labour/LaborGroups";
import QualityControl from "../QualityControl";
import AddEditQCForm from "../QualityControl/AddEditQCForm";
/* import ScannedGeneralBarcodes from "../Barcodes/ScannedGeneralBarcodes";
import UpdateScannedGeneralBarcode from "../Barcodes/UpdateScannedGeneralBarcode"; */
import GeneralTasksList from "../GeneralTasks/GeneralTasksList/GeneralTasksList";
import CostEntering from "../GeneralTasks/CostEntering";
import OperatingCost from "../GeneralTasks/OperatingCost";
import AddCost from "../GeneralTasks/CostEntering/AddCost/AddCost";
import AddAltCost from "../GeneralTasks/OperatingCost/AddCost/AddCost";
import CreateTaskOptions from "../GeneralTasks/CreateTaskOptions";
import CreateOrUpdateTask from "../GeneralTasks/CreateOrUpdateTask";
import ScoutingList from "../Scouting/ScoutingList";
import RecommendationsList from "../Recommendations/components/RecommendationsList";
import UpdateScouting from "../Scouting/UpdateScouting";
import RecommendationForm from "../RecommendationForm";
import Reports from "../Reports";
import ApplicationsRecordByBlock from "../Reports/ApplicationsRecordByBlock";
import TotalCostPerArea from "../Reports/TotalCostPerArea/TotalCostPerArea";
import TotalCostPerFarm from "../Reports/TotalCostPerFarm/TotalCostPerFarm";
import HourlyPayroll from "../Reports/HourlyPayroll";
import PieceRatePayroll from "../Reports/PieceRatePayroll";

export const routesPages = {
  Home,
  Dashboard,
  Blank,
  Settings,
  Farms,
  FarmsRecords,
  AddNewFarm,
  EditFarm,
  SprayTasks,
  NutrientBreakdown,
  LogOff,
  CreateSprayTask,
  Chemicals,
  Sheds,
  NutrientBreakdown,
  ProductTypeUsage,
  ProductUsage,
  InventoryMovements,
  PendingMovements,
  PurchaseHistory,
  AddNewChemical,
  Machinery,
  AddNewMachinery,
  Employees,
  WeatherStations,
  AddWeatherStation,
  NotFound,
  Clients,
  AddNewClient,
  SprayRecords,
  EmployeesBarcodesGenerator,
  GeneralBarcodesGenerator,
  TagBarcodesGenerator,
  PackedBoxes,
  ScanPackedBoxes,
  EditPackedBoxes,
  Harvest,
  HarvestUnitsControl,
  HarvestByArea,
  PickerReport,
  PickToPackPickerReport,
  ContractorReport,
  PickToPackContractorReport,
  HarvestByVariety,
  PickToPackHarvestByArea,
  PickToPackHarvestByVariety,
  BoxUnits,
  AddBoxUnits,
  PackingSheds,
  AddPackingSheds,
  Contractors,
  Pallets,
  AddPallet,
  Stock,
  AddStockItem,
  PickerAndVarietyReport,
  ProduceByAreaReport,
  ProduceByVarietyReport,
  CustomPackedBoxesReport,
  ScannedGeneralBarcodesReport,
  BatchEditPackedBoxes,
  Vendors,
  AddVendor,
  Dispatches,
  AddDispatch,
  LaborGroups,
  QualityControl,
  AddEditQCForm,
  PlantingReport,
  /*   ScannedGeneralBarcodes, */
  TotalCostPerArea,
  TotalCostPerFarm,
  /*   UpdateScannedGeneralBarcode, */
  GeneralTasksList,
  CostEntering,
  OperatingCost,
  AddAltCost,
  AddCost,
  CreateTaskOptions,
  CreateOrUpdateTask,
  WorkersPayroll,
  ScoutingList,
  UpdateScouting,
  RecommendationsList,
  RecommendationForm,
  Reports,
  ApplicationsRecordByBlock,
  HourlyPayroll,
  PieceRatePayroll
};
