export const sortOptions = [
  {
    key: "totalPrice",
    text: `Sort by Total Price`,
    value: "totalPrice",
    content: "Total Price",
    sortfields: ["totalPrice"]
  },
  {
    key: "totalQuantity",
    text: `Sort by Total Quantity`,
    value: "totalQuantity",
    content: "Total Quantity",
    sortfields: ["totalQuantity"]
  }
];

export const filtersOptions = [
  {
    name: "Date",
    type: "DateRangeFilter",
    id: "date"
  },
  {
    name: "Employee",
    type: "EmployeeMultiSelectFilter",
    id: "employeeIds"
  },
  {
    name: "Variety",
    type: "VarietyMultiSelectFilter",
    id: "varietyIds"
  }
];
export const columnsConfig = [
  {
    id: "picker",
    accessor: "picker",
    Header: "Picker"
  },
  {
    id: "varietyName",
    accessor: "varietyName",
    Header: "Variety"
  },
  {
    id: "cropName",
    accessor: "cropName",
    Header: "Crop"
  },
  {
    id: "pricePartPerBarcode",
    Header: "Crop Price",
    accessor: "pricePartPerBarcode"
  },
  {
    id: "areaName",
    Header: "Area",
    accessor: "areaName"
  },
  {
    id: "row",
    Header: "Row",
    accessor: "row"
  },
  {
    id: "farmName",
    Header: "Farm",
    accessor: "farmName"
  },
  {
    id: "harvestUnitName",
    Header: "Harvest Unit",
    accessor: "harvestUnitName"
  },
  {
    id: "binPart",
    Header: "Bin Part",
    accessor: "binPart"
  },
  {
    id: "weightPartPerBarcode",
    Header: "Bin Part Weight",
    accessor: "weightPartPerBarcode"
  },
  {
    id: "scannedDate",
    Header: "Scanned Date",
    accessor: "scannedDate"
  },
  {
    id: "scannedTime",
    Header: "Scanned Time",
    accessor: "scannedTime"
  }
];
