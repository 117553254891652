import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import TablePageHolder from "../../../components/TablePageHolder/TablePageHolder";
import {
  getPendingOperations,
  getPendingOperationsList
} from "actions/PendingMovements/index";
import { fetchEmployees } from "actions/Employee/employees";
import { fetchListSheds } from "actions/Sheds/index";
import { getPendingMovementsTableColumns } from "utils/pendingMovements";
import {
  chemicalsOptionsSelector,
  productTypeSelector
} from "selectors/chemicals";
import { employeesOptionsSelector } from "selectors/employee";
import { shedListSelector } from "selectors/sheds";
import { shedShape } from "../../../constants/Sheds/types";
import styles from "./PendingMovements.module.css";
import { Button, Icon } from "semantic-ui-react";
import { excelExport } from "utils/excelExport";
import moment from "moment";
import { get } from "lodash";

const headerColumn = [
  "ID",
  "Date",
  "Product",
  "User",
  "Activity Type ",
  "ST number",
  "Block/Patch",
  "Price",
  "Shed",
  "Difference"
];

const PendingMovements = ({
  isFetching,
  location,
  route,
  tableData,
  purchaseList,
  pendingMovementsList,
  activeItem,
  areas,
  shedsList
}) => {
  const [filtersList, setFiltersList] = useState([]);
  const pendingMovements = true;
  const productList = useSelector(productTypeSelector);
  const chemicalsList = useSelector(chemicalsOptionsSelector);
  const employeesList = useSelector(employeesOptionsSelector);
  const shedList = useSelector(shedListSelector);
  const [selectedItemsIds, setSelectedItems] = useState([]);
  const dispatch = useDispatch();
  const tableColumns = getPendingMovementsTableColumns(
    chemicalsList,
    shedList,
    employeesList,
    areas,
    purchaseList,
    productList,
    isFetching
  );
  const getData = useCallback(
    params => {
      setFiltersList(params);
      dispatch(fetchEmployees({ unpaged: true }));
      dispatch(fetchListSheds());
      dispatch(getPendingOperations({ ...params }));
      dispatch(getPendingOperationsList());
    },
    [getPendingOperations]
  );
  const breadcrumbSections = [
    { content: "Shed and fertiliser", active: true },
    { content: route.name, active: false }
  ];
  const pendingMovementsLength = pendingMovementsList.content.length;

  const handleExcelExport = useCallback(
    async sheds => {
      const data = await dispatch(getPendingOperationsList(filtersList));

      if (data.content.length) {
        const excelData = data.content.map(
          ({
            id,
            createdAt,
            chemicalId,
            employeeId,
            operationType,
            entityId,
            areaId,
            price,
            shedId,
            quantity
          }) => {
            const matter = chemicalsList?.find(item => chemicalId === item.key)
              ?.matter;
            const area = areas.find(a => a.id === areaId);
            const priceUnit =
              matter && matter === "SOLID" ? "Kg" : matter ? "L" : "";

            return {
              ...{
                id: id ? `$ID-${id}` : "-"
              },
              ...{
                createdAt: moment(createdAt).format("DD/MM/YYYY") || "-"
              },
              ...{
                chemicalId:
                  chemicalsList?.find(item => chemicalId === item.key)?.text ||
                  "-"
              },
              ...{
                employeeId:
                  employeesList?.find(item => employeeId === item.key)?.text ||
                  "-"
              },
              ...{
                operationType:
                  (
                    operationType
                      .toLowerCase()
                      .charAt(0)
                      .toUpperCase() + operationType.toLowerCase().slice(1)
                  ).replace(/_/g, " ") || "-"
              },
              ...{ entityId: entityId ? `ST-${entityId}` : "-" },
              ...{
                areaId: areaId
                  ? area.parent
                    ? `${area.parent.name}/ ${area.name}`
                    : area.name
                  : "—"
              },
              ...{
                price: price
                  ? `${price.toFixed(price > 1000 ? 0 : 2)}/${priceUnit}`
                  : "0.00"
              },
              ...{
                shedId: shedId
                  ? get(
                      sheds.find(s => s.id === shedId),
                      "name"
                    )
                  : "-"
              },
              ...{
                quantity: quantity
                  ? `${quantity.toFixed(quantity > 1000 ? 0 : 2)}/${priceUnit}`
                  : "0.00"
              }
            };
          }
        );

        excelExport(excelData, headerColumn);
      }
    },
    [filtersList]
  );
  return (
    <>
      <TablePageHolder
        actionsButtons={
          <Button
            size="small"
            floated="right"
            className={styles.printButton}
            onClick={() => {
              return handleExcelExport(shedsList);
            }}
          >
            <div className={styles.iconWrapper}>
              <Icon name="download" />
              Export
            </div>
          </Button>
        }
        breadcrumbSections={breadcrumbSections}
        onRowClick={() => {}}
        getData={getData}
        withoutSort={true}
        pendingMovements={pendingMovements}
        isFetching={isFetching}
        location={location}
        pendingMovementsLength={pendingMovementsLength}
        pageTitle={route.name}
        route={route}
        selectedItems={selectedItemsIds}
        setSelectedItems={setSelectedItems}
        tableColumns={tableColumns}
        tableData={tableData}
        withSelection={false}
      />
    </>
  );
};
PendingMovements.propTypes = {
  tableData: PropTypes.shape({
    content: PropTypes.arrayOf(shedShape)
  }).isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  totalElements: PropTypes.number.isRequired,
  fetchSheds: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  route: PropTypes.object,
  filterValues: PropTypes.object,
  location: PropTypes.object
};
export default connect(state => {
  return {
    areas: state.areas.list.content,
    purchaseList: state.purchaseHistory.list.content,
    tableData: state.pendingMovements.data,
    shedsList: state.shed.list.content,
    pendingMovementsList: state.pendingMovements.list,
    filterValues: state.pendingMovements.filters,
    isFetching: state.pendingMovements.data.isFetching,
    error: state.pendingMovements.error
  };
})(PendingMovements);
