import { actionType } from '../../constants/QualityControl';

const initialState = {
  data: { content: [] },
  qcForm: {
    name: '',
    employeeIds: [],
    fields: [],
  },
  isFetching: false,
  error: null,
};

const CHECKBOX_INPUT = {
  type: 'CHECKBOX',
  label: '',
};

export default (state = initialState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case actionType.FETCH_QC_FORMS_START:
      return {
        ...state,
        data: {
          ...state.data,
          isFetching: true,
        },
      };
    case actionType.FETCH_QC_FORMS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
          isFetching: false,
        },
      };
    case actionType.QC_FORM_CHANGE:
      return {
        ...state,
        qcForm: {
          ...state.qcForm,
          [action.payload.name]: action.payload.value,
        },
      };

    case actionType.QC_FORM_SET_FORM_SET:
      return {
        ...state,
        qcForm: action.payload,
      };
    case actionType.ADD_FORM_INPUT:
      return {
        ...state,
        qcForm: {
          ...state.qcForm,
          fields: [
            ...state.qcForm.fields,
            CHECKBOX_INPUT,
          ],
        },
      };
    case actionType.EDIT_FORM_INPUT:
      return {
        ...state,
        qcForm: {
          ...state.qcForm,
          fields: state.qcForm.fields.map((field, i) => {
            if (i === action.payload.id) return {
              ...field,
              [action.payload.name]: action.payload.value,
            };

            return field;
          }),
        },
      };
    case actionType.REMOVE_FORM_INPUT:
      return {
        ...state,
        qcForm: {
          ...state.qcForm,
          fields: state.qcForm.fields.filter((_, i) => i !== action.payload),
        },
      };

    case actionType.QC_FORM_CLEAR:
      return {
        ...state,
        qcForm: initialState.qcForm,
      };

    case actionType.QC_FORM_ARCHIVE:
      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.map(form => {
            if (form.id === action.payload.id) return { ...form, archived: !form.archived };
            return form;
          }),
        },
      };

    case actionType.QC_FORM_UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.map(form => {
            if (form.id === action.payload.id) return action.payload;
            return form;
          }),
        },
      };

    case actionType.QC_FORM_CREATE_FAIL:
    case actionType.QC_FORM_UPDATE_FAIL:
    case actionType.QC_FORM_ARCHIVE_FAIL:
    case actionType.FETCH_QC_FORMS_FAIL:
      return {
        ...state,
        error: action.payload,
        data: {
          ...state.data,
          isFetching: false,
        },
      };

    default:
      return state;
  }
}