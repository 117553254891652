import React, {
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect
} from "react";
import { Dropdown } from "semantic-ui-react";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import styles from "./MultiSelectFilter.module.css";
import filterStyles from "../../Filters.module.css";

import {
  getHashParameter,
  setHashParameter
} from "../../../../utils/hashToObject";
import PropTypes from "prop-types";
import Loader from "../../../Loader";

const MultiSelectFilter = ({
  id,
  name,
  disabled,
  numbers,
  options,
  loading,
  location,
  onFilterClick = () => {}
}) => {
  const ref = useRef();
  const [listNode, setListNode] = useState(null);
  const value = useMemo(() => {
    const param = getHashParameter(location, id);

    if (!param || param === "*") {
      return [];
    }

    return param.split(",").map(param => (numbers ? parseInt(param) : param));
  }, [id, location, numbers]);

  const text = useMemo(
    () =>
      `${name}: ${
        value.length === 0
          ? "All"
          : options
              .filter(item => value.includes(item.value))
              .map(item => item.text)
              .join(",  ")
      }`,
    [name, options, value]
  );
  const onOpen = useCallback(
    (event, data) => {
      setTimeout(() => {
        // eslint-disable-next-line
        const listNode = ref.current && ReactDOM.findDOMNode(ref.current);
        setListNode(listNode);
        if (!listNode) {
          return;
        }

        if (listNode.querySelector("input")) {
          listNode.querySelector("input").focus();
        }
      });
    },
    [ref, setListNode]
  );

  const handleBlur = useCallback(
    event => {
      // eslint-disable-next-line
      if (listNode && !ReactDOM.findDOMNode(listNode).contains(event.target)) {
        if (listNode && listNode.classList.contains("visible")) {
          listNode.click();
        }
      }
    },
    [listNode]
  );

  useEffect(() => {
    document.body.addEventListener("mousedown", handleBlur);
    return () => document.body.removeEventListener("mousedown", handleBlur);
  }, [handleBlur]);

  return (
    <Dropdown
      filter={id}
      name={name}
      labeled
      button
      floating
      text={text}
      closeOnChange={false}
      closeOnBlur={false}
      /*  disabled={disabled} */
      icon={null}
      onOpen={onOpen}
      ref={ref}
    >
      <Dropdown.Menu
        className={`dropdownMenu ${filterStyles.dropdownMenu} ${filterStyles.selectItemsHolder}`}
      >
        <Dropdown
          fluid
          multiple
          search
          /* disabled={disabled} */
          options={options}
          className={filterStyles.selectItems}
          onChange={(_, data) => {
            return (
              onFilterClick(true), setHashParameter(location, id, data.value)
            );
          }}
          defaultOpen
          value={value}
          noResultsMessage={
            loading && options.length === 0 ? (
              <div className={styles.loadingPlaceHolder}>
                <Loader className={styles.loader} />
                Loading...
              </div>
            ) : (
              "Nothing was found"
            )
          }
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};

MultiSelectFilter.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  numbers: PropTypes.any,
  options: PropTypes.array.isRequired,
  location: PropTypes.object
};

MultiSelectFilter.defaultProps = {
  disabled: false,
  loading: false
};

function mapStateToProps(state) {
  const {
    router: { location }
  } = state;
  return {
    location
  };
}

export default connect(mapStateToProps)(MultiSelectFilter);
