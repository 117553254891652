import React, { useState } from "react";
import moment from "moment-timezone";
import {
  Form,
  Segment,
  Input,
  Container,
  Header,
  Popup,
  Icon
} from "semantic-ui-react";
import { Field, useFormikContext, FieldArray } from "formik";
import { get, map } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import DatePicker from "components/DatePicker/DatePicker";
import { getPurchaseHistorySupplierss } from "../../../../../actions/PurchaseHistory";
import { shedsListSelector } from "selectors/sheds";
import { supplierSelector } from "selectors/purchaseHistory";
import FileChooser from "components/Attachments/FileChooser";
import { activeIngredientsSelector } from "selectors/chemicals";
import ObjectID from "bson-objectid";
import { uploadAttachment } from "actions/Chemicals";
import CollapsibleBlock from "components/CollapsibleBlock";
import AttachmentLabel from "components/Attachments/AttachmentLabel";
import styles from "./PurchaseHistoryModal.module.css";

const GeneralInformation = () => {
  const { options: suppliersOptions, isFetching } = useSelector(
    supplierSelector
  );
  const shownOptions = map(suppliersOptions, ({ label, ...rest }) => ({
    ...rest,
    text: label
  }));

  const { values, setFieldValue, validateForm, setValues } = useFormikContext();
  const moment = require("moment-timezone");
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const userTimeZoneDate = values.deliveryDate
    ? moment.utc(values.deliveryDate)
    : null;
  const convertedDate = userTimeZoneDate
    ? userTimeZoneDate.tz(userTimeZone).format("YYYY-MM-DDTHH:mm:ss")
    : null;
  const [searchQuery, setSearchQuery] = useState();
  const [orderDate, setOrderDate] = useState();
  const [loadingAttachments, setLoadingAttachments] = useState([]);
  const [deliveryDate, setDeliveryDate] = useState(
    !!convertedDate ? moment(convertedDate) : ""
  );
  const shedsList = useSelector(shedsListSelector);
  const dispatch = useDispatch();
  const fileInputRef = React.createRef();
  const onAttachmentSelected = (fieldName, multi = false) => files => {
    setLoadingAttachments(attachments => [...attachments, fieldName]);
    files.forEach(file => {
      const dataToSend = {
        file,
        idReplace: ObjectID().toHexString(),
        callBack: loadedFile => {
          setFieldValue(
            fieldName,
            !multi
              ? loadedFile
              : values[fieldName]
              ? [...values[fieldName], loadedFile]
              : [loadedFile]
          );
          setLoadingAttachments(attachments =>
            attachments.filter(i => i !== fieldName)
          );
        }
      };
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onloadend = () => {
        dispatch(uploadAttachment(dataToSend));
      };
    });
  };

  return (
    <Segment>
      <CollapsibleBlock title="General Information">
        <Form>
          <Form.Group className={styles.firstRow}>
            <Form.Field required width={8}>
              <label>Receipt number</label>
              <Field
                as={Input}
                required
                name="orderNumber"
                value={values.orderNumber}
              />
            </Form.Field>
            <Form.Field width={8} required>
              <label>Order date</label>
              <DatePicker
                name="orderDate"
                trigger={false}
                focused={false}
                value={values.orderDate}
                onChange={orderDate => {
                  return (
                    setOrderDate(orderDate),
                    setValues({
                      ...values,
                      orderDate: moment(orderDate)
                        .add(1, "days")
                        .utc()
                        .format("YYYY-MM-DDTHH:mm:ss")
                    })
                  );
                }}
                disableClear
              />
            </Form.Field>
          </Form.Group>
          <Form.Group className={styles.secondRow}>
            <Form.Field required width={8}>
              <label>Suppliers</label>
              <FieldArray
                name="supplier"
                render={() => (
                  <div className={styles.suppliersWrapper}>
                    {values.supplier.length != 0 && (
                      <Segment className={styles.suppliersSection}>
                        <Header
                          floated="left"
                          className={styles.suppliersHeader}
                          size="small"
                        >
                          {values.supplier}
                        </Header>
                        <Popup
                          content="Delete supplier"
                          position="bottom center"
                          inverted
                          trigger={
                            <Icon
                              name="trash alternate"
                              className={styles.removeSuppliers}
                              onClick={() => {
                                return setValues({
                                  ...values,
                                  supplier: ""
                                });
                              }}
                            />
                          }
                        />
                      </Segment>
                    )}
                    {values.supplier.length == 0 && (
                      <Field
                        fluid
                        as={Form.Select}
                        allowAdditions
                        search
                        searchQuery={searchQuery}
                        onSearchChange={(_, { searchQuery }) => {
                          setSearchQuery(searchQuery);
                        }}
                        // loading={isFetching}
                        name="deliveryDate"
                        onChange={(e, { value }) => {
                          return setValues({
                            ...values,
                            supplier: value
                          });
                        }}
                        options={shownOptions}
                      />
                    )}
                  </div>
                )}
              />
            </Form.Field>
            <Form.Field required width={8}>
              <label>Delivery date</label>
              <DatePicker
                name="deliveryDate"
                trigger={false}
                focused={false}
                value={
                  moment(deliveryDate).isValid() ? moment(deliveryDate) : ""
                }
                onChange={deliveryDate => {
                  const userTimeZoneDate = moment(deliveryDate)
                    .utc()
                    .format("YYYY-MM-DDTHH:mm:ss");
                  return (
                    setDeliveryDate(deliveryDate),
                    setValues({
                      ...values,
                      deliveryDate: userTimeZoneDate /* moment(userTimeZoneDate).format(
                        "YYYY-MM-DDTHH:mm:ss"
                      ) */
                    })
                  );
                }}
                disableClear
              />
            </Form.Field>
          </Form.Group>
          <div></div>
          <div>
            <Container fluid className={styles.generalAttachmentsContainer}>
              {map(values.attachments, (attachment, index) => (
                <AttachmentLabel
                  attachment={attachment}
                  onDeleteClick={() => {
                    setFieldValue(
                      "attachments",
                      values.attachments.filter((_, i) => i !== index)
                    );
                  }}
                />
              ))}

              <FileChooser
                className={styles.generalAttachmentsButton}
                onChange={onAttachmentSelected("attachments", true)}
                label="Attach order receipt"
                disabled={
                  loadingAttachments.includes("attachments") ||
                  values.attachments.length >= 3
                }
              />
            </Container>
          </div>
        </Form>
      </CollapsibleBlock>
    </Segment>
  );
};

export default GeneralInformation;
