import PropTypes from "prop-types";
import React, { useEffect, useMemo } from "react";
import { Grid } from "semantic-ui-react";
import { Form } from "formsy-semantic-ui-react";
import SelectWithSelectAll from "../../../../components/SelectWithSelectAll";
import { getFarmsList } from "../../../../actions/Farms/farms";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchAreasList } from "../../../../actions/Areas/areas";

const columnMiddle = {
  mobile: 16,
  tablet: 16,
  computer: 8,
  largeScreen: 8,
  widescreen: 8
};

const FarmRelatedFields = ({
  actions: { getFarmsList, fetchAreasList },
  farmsList,
  areasList,
  onChange,
  farm,
  block,
  patch
}) => {
  const farmID = farm ? farm.id : null;
  const blockID = block ? block.id : null;
  const patchID = patch ? patch.id : null;

  useEffect(() => {
    getFarmsList();
    fetchAreasList();
  }, [getFarmsList, fetchAreasList]);

  const { blocks, patches } = areasList.reduce(
    (prev, curr) => {
      const tempBlocks = [...prev.blocks];
      const tempPatches = [...prev.patches];

      curr.type === "BLOCK" ? tempBlocks.push(curr) : tempPatches.push(curr);

      return { blocks: tempBlocks, patches: tempPatches };
    },
    { blocks: [], patches: [] }
  );

  const farmOptions = useMemo(
    () =>
      farmsList
        .filter(data => !data.archived)
        .map(content => ({
          key: content.id,
          text: `${content.name}`,
          value: content.id
        })),
    [farmsList]
  );

  const blockOptions = useMemo(() => {
    if (!farm) return [];
    return blocks
      .filter(data => !data.archived)
      .filter(b => b.farm.id === farm.id)
      .map(content => ({
        key: content.id,
        text: `${content.name}`,
        value: content.id
      }));
  }, [blocks, farm]);

  const patchOptions = useMemo(() => {
    if (!block || !block.hasSubAreas) return [];
    return patches
      .filter(b => b.parent.id === block.id)
      .filter(data => !data.archived)
      .map(content => ({
        key: content.id,
        text: `${content.name}`,
        value: content.id
      }));
  }, [patches, block]);

  return (
    <>
      <Grid.Row>
        <Grid.Column {...columnMiddle}>
          <Form.Field className="sprayField">
            <label>Farm</label>
            <SelectWithSelectAll
              placeholder="Select Farm"
              showSelectAll={false}
              name={"farm"}
              noResultsMessage="Nothing was found"
              fluid
              closeOnBlur
              selection
              value={farmID}
              onChange={(_, data) => {
                onChange({
                  target: {
                    name: "farm",
                    value: farmsList.find(f => f.id === data.value)
                  }
                });
                onChange({ target: { name: "block", value: null } });
                onChange({ target: { name: "patch", value: null } });
                onChange({ target: { name: "crop", value: null } });
                onChange({ target: { name: "variety", value: null } });
              }}
              options={farmOptions}
            />
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column {...columnMiddle}>
          <Form.Field className="sprayField">
            <label>Block</label>
            <SelectWithSelectAll
              placeholder="Select Block"
              showSelectAll={false}
              name={"block"}
              noResultsMessage="Nothing was found"
              fluid
              disabled={!farmID}
              closeOnBlur
              selection
              value={blockID}
              onChange={(_, data) => {
                onChange({
                  target: {
                    name: "block",
                    value: blocks.find(b => b.id === data.value)
                  }
                });
                onChange({ target: { name: "patch", value: null } });
                onChange({ target: { name: "crop", value: null } });
                onChange({ target: { name: "variety", value: null } });
              }}
              options={blockOptions}
            />
          </Form.Field>
        </Grid.Column>
        <Grid.Column {...columnMiddle}>
          <Form.Field className="sprayField">
            <label>Patch</label>
            <SelectWithSelectAll
              placeholder="Select Block"
              showSelectAll={false}
              name={"block"}
              noResultsMessage="Nothing was found"
              fluid
              disabled={!farmID || !blockID || !blockOptions.length > 0}
              closeOnBlur
              selection
              value={patchID}
              onChange={(_, data) => {
                onChange({
                  target: {
                    name: "patch",
                    value: patches.find(p => p.id === data.value)
                  }
                });
                onChange({ target: { name: "crop", value: null } });
                onChange({ target: { name: "variety", value: null } });
              }}
              options={patchOptions}
            />
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
    </>
  );
};

const mapStateToProps = state => ({
  farmsList: state.farms.data.content,
  areasList: state.areas.list.content
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getFarmsList,
      fetchAreasList
    },
    dispatch
  )
});

FarmRelatedFields.propTypes = {
  areasList: PropTypes.array,
  block: PropTypes.object,
  actions: PropTypes.object,
  farm: PropTypes.object,
  farmsList: PropTypes.array,
  onChange: PropTypes.func,
  patch: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(FarmRelatedFields);
