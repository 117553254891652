import { createSelector } from "reselect";

export const seasons = state => state.settings.data;

export const seasonsOptionsSelector = createSelector(
  [seasons],
  ({ seasons }) => {
    return {
      options: seasons
        ? seasons.map(item => ({
            key: item.seasonName,
            label: item.seasonName,
            value: [item.startAt, item.endAt]
          }))
        : []
    };
  }
);
