import { actionType } from '../../constants/InfoMessages';
const initialState = {
  infoMessages: [],
};

export default (state = initialState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case actionType.PUT_MESSAGE:
      return {
        ...state,
        infoMessages: [...state.infoMessages, action.payload],
      };
    case actionType.REMOVE_MESSAGE:
      return {
        ...state,
        infoMessages: state.infoMessages.filter(message => message.id !== action.payload),
      };
    default:
      return state;
  }
};
