import { createSelector } from "reselect";
import get from "lodash/get";
import { chemicalsList } from "selectors/chemicals";

export const filters = state => state.inventoryMovements.filters;
export const shedList = state => state.shed.list;
export const checmicalList = state => state.chemical.list;
export const activeIngredients = state => state.chemical.activeIngredients;
export const areasList = state => state.areas;

export const activeIngredientOptionssSelector = createSelector(
  [checmicalList, filters],
  ({ content }, { chemicalIds }) => {
    return {
      options: content
        ? content
            .filter(item =>
              chemicalIds?.some(checmicalId => checmicalId === item.id)
            )
            .map(({ activeIngredients }) => [...activeIngredients])
            .flat()
            .map(item => ({
              key: item.id,
              label: item.activeIngredient,
              value: item.id
            }))
        : []
    };
  }
);

export const chemicalTypeOptionsSelector = createSelector(
  [checmicalList, filters],
  ({ content }, { chemicalIds }) => {
    return {
      options: [
        ...new Map(
          content
            ? content
                .filter(item =>
                  chemicalIds?.some(checmicalId => checmicalId === item.id)
                )
                .map(({ type }) => ({
                  key: type.id,
                  label: type.type,
                  value: type.id
                }))
                .map(item => [item["key"], item])
            : []
        ).values()
      ]
    };
  }
);

export const shedListOptionSelector = createSelector(
  [shedList, filters],
  ({ content }, { shedIds }) => {
    return {
      options: content
        ? content
            .filter(item => shedIds?.some(shedId => shedId === item.id))
            .map(item => ({
              key: item.id,
              label: item.name,
              value: item.id
            }))
        : []
    };
  }
  /*     [shedList], ({ content }) => ({
    options: content
      ? content.map(({ id, name, matter }) => ({
          key: id,
          label: name,
          value: id
        }))
      : []
  }) */
);

export const productFilterOptions = createSelector(
  [checmicalList, filters],
  ({ content }, { chemicalIds }) => {
    return {
      options: content
        ? content
            .filter(item =>
              chemicalIds?.some(checmicalId => checmicalId === item.id)
            )
            .map(item => ({
              key: item.id,
              label: item.name,
              value: item.id
            }))
        : []
    };
  }
);
export const operationTypeOptionSelector = createSelector(
  [filters],
  ({ operationType }) => {
    return {
      options: operationType
        ? operationType.map(item => ({
            key: item,
            label: (
              item
                .toLowerCase()
                .charAt(0)
                .toUpperCase() + item.toLowerCase().slice(1)
            ).replace(/_/g, " "),
            value: item
          }))
        : []
    };
  }
);

export const blocListOptionsSelector = createSelector(
  [areasList, filters],
  ({ list, isFetching }, { areaIds }) => ({
    options: list.content
      .filter(({ parent, archived }) => parent === null && !archived)
      .filter(({ id }) => areaIds?.some(areaId => areaId === id))
      .map(item => ({
        key: item.id,
        label: item.name,
        value: item.id
      })),
    isFetching
  })
);
export const patchListOptionSelector = createSelector(
  [areasList, filters],
  ({ list, isFetching }, { areaIds }) => ({
    options: list.content
      .filter(({ parent, archived }) => parent !== null && !archived)
      .filter(({ id }) => areaIds?.some(areaId => areaId === id))
      .map(item => ({
        key: item.id,
        label: item.name,
        value: item.id
      })),
    isFetching
  })
);
