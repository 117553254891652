import { actionType } from "constants/PurchaseHistory";
import { axiosProxy } from "utils/axiosProxy";
import { omitBy, isNil } from "lodash";
import uuid from "uuid/v4";

let uniqId;

export const getPurchaseHistory = (
  {
    page = 0,
    search,
    size = 10,
    to,
    from,
    unpaged = false,
    filters,
    sort = ["id,asc"]
  },
  shedIds
) => async dispatch => {
  await dispatch({
    type: actionType.PURCHASE_HISTORY_START,
    payload: shedIds
  });
  try {
    const paramsObject = omitBy(
      {
        page,
        search,
        size,
        unpaged,
        to,
        sort,
        from
      },
      isNil
    );
    const params = new URLSearchParams(paramsObject);

    if (Array.isArray(filters)) {
      filters.forEach(([filter, value]) => params.append(filter, value));
    }
    uniqId = uuid();
    const res = await axiosProxy({
      method: "GET",
      params,
      uniqId,
      url: `/purchase_orders`
    });
    if (uniqId === res.config.headers["X-REQUEST-ID"]) {
      await dispatch({
        type: actionType.PURCHASE_HISTORY,
        payload: res.data
      });
    }
    return res.data;
  } catch (error) {}
};
export const getPurchaseHistoryList = shedIds => async dispatch => {
  await dispatch({
    type: actionType.PURCHASE_HISTORY_LIST_START,
    payload: shedIds
  });
  try {
    const res = await axiosProxy({
      method: "GET",
      params: { unpaged: true },
      url: `/purchase_orders`
    });
    await dispatch({
      type: actionType.PURCHASE_HISTORY_LIST,
      payload: res.data
    });
    return res.data;
  } catch (error) {}
};

export const getPurchaseHistoryListForPrint = ({
  unpaged = true,
  sort = ["id,desc"],
  filters
}) => async dispatch => {
  try {
    dispatch({ type: actionType.PURCHASE_HISTORY_FOR_PRINT_START });
    const paramsObject = omitBy(
      {
        sort,
        unpaged: true
      },
      isNil
    );
    const params = new URLSearchParams(paramsObject);
    if (Array.isArray(filters)) {
      filters.forEach(([filter, value]) => params.append(filter, value));
    }
    const res = await axiosProxy({
      method: "GET",
      params,
      url: `/purchase_orders`
    });
    await dispatch({
      type: actionType.PURCHASE_HISTORY_FOR_PRINT,
      payload: res.data
    });
    return res.data;
  } catch (error) {
    console.log("errror");
  }
};

export const getPurchaseHistorySupplierss = () => async dispatch => {
  await dispatch({
    type: actionType.GET_SUPPLIERSS_LIST_START
  });
  try {
    const res = await axiosProxy({
      method: "GET",
      url: `/purchase_orders/suppliers`
    });
    await dispatch({
      type: actionType.GET_SUPPLIERSS_LIST,
      payload: res.data
    });
    return res.data;
  } catch (error) {}
};
export const getPurchaseHistoryManufactures = () => async dispatch => {
  await dispatch({
    type: actionType.GET_MANUFACTURER_LIST_START
  });
  try {
    const res = await axiosProxy({
      method: "GET",
      url: `/purchase_orders/manufacturers`
    });
    await dispatch({
      type: actionType.GET_MANUFACTURER_LIST,
      payload: res.data
    });
    return res.data;
  } catch (error) {}
};
export const getPurchaseHistoryBatchNumber = () => async dispatch => {
  await dispatch({
    type: actionType.GET_BATCHNUMBER_LIST_START
  });
  try {
    const res = await axiosProxy({
      method: "GET",
      url: `/purchase_orders/batch_numbers`
    });
    await dispatch({
      type: actionType.GET_BATCHNUMBER_LIST,
      payload: res.data
    });
    return res.data;
  } catch (error) {}
};
export const createPurchase = values => async dispatch => {
  dispatch({ type: actionType.ADD_PURCHASE_START });
  try {
    const res = await axiosProxy({
      method: "POST",
      url: "/purchase_orders",
      data: values
    });

    await dispatch({
      type: actionType.ADD_PURCHASE,
      payload: res.data
    });

    return res.data;
  } catch (error) {
    await dispatch({
      type: actionType.ADD_PURCHASE_FAIL,
      payload: error
    });
  }
};

export const updatePurchase = (id, data, parametrs) => async dispatch => {
  dispatch({
    type: actionType.UPDATE_PURCHASE_START
  });
  try {
    const res = await axiosProxy({
      method: "PUT",
      url: `/purchase_orders/${id}`,
      data
    });

    await dispatch({
      type: actionType.UPDATE_PURCHASE,
      payload: res.data
    });
    return res.data;
  } catch (error) {
    await dispatch({
      type: actionType.UPDATE_PURCHASE_FAIL,
      payload: error
    });
    dispatch(getPurchaseHistory(parametrs));
  }
};

export const deletePurchase = (id, parametrs) => async dispatch => {
  try {
    dispatch({
      type: actionType.DELETE_PURCHASE_START
    });
    await axiosProxy({
      method: "DELETE",
      url: `/purchase_orders/${id}`
    });

    await dispatch({
      type: actionType.DELETE_PURCHASE,
      payload: id
    });

    dispatch(getPurchaseHistory(parametrs));
  } catch (error) {
    dispatch({
      type: actionType.DELETE_PURCHASE_FAIL,
      payload: error
    });
    /*    dispatch(getPurchaseHistory(parametrs)) */
  }
};

export const clearError = () => dispatch => {
  dispatch({
    type: actionType.CLEAR_PURCHASE_ERROR
  });
};
export const setActiveItemId = id => dispatch => {
  dispatch({
    type: actionType.SET_ACTIVE_ITEM_ID,
    payload: id
  });
};
