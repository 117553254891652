import React, { useState, useCallback, useEffect } from "react";
import { get } from "lodash";
import {
  Grid,
  Checkbox,
  Form,
  Input,
  Label,
  Segment,
  Icon,
  Radio
} from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, useFormikContext, Field } from "formik";
import styles from "./SecondStep.module.css";
import { employeeList, employeeOptionsSelector } from "selectors/employee";
import { fetchGeneralTaskList } from "actions/GeneralTasks/generalTasks";
import {
  internalBinIdSelector,
  supervisorsFilterOptions,
  workersFilterOptions
} from "selectors/harvestUnits";
import MultiSelect from "components/MultiSelect/MultiSelect";
import { getEmployeeList } from "actions/SprayDiary/employee";
import { harvestUnitSelector } from "selectors/harvestUnits";
import { generalTasksOptionsSelector } from "selectors/generalTasks";

const AddModalSecondStep = ({ changeFieldValue, formValues, areas }) => {
  const [scanDate, setScanDate] = useState();
  const [search, setSearch] = useState("");
  const [radioButton, setRadioButton] = useState(false);
  const harvestUnitsList = useSelector(harvestUnitSelector);
  const internalBinIDSelector = useSelector(internalBinIdSelector);
  const generalTaskIDSelector = useSelector(generalTasksOptionsSelector);
  const employees = useSelector(employeeList);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchGeneralTaskList());
  }, []);

  const onSearchChange = useCallback((_, { value: search }) => {
    setSearch(search);
  }, []);

  const onSubmit = values => {};
  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      onSubmit={onSubmit}
      initialValues={formValues}
    >
      {({ dirty, isValid, values, setValues, setFieldValue, handleSubmit }) => {
        const changeNumberOfBins = value => {
          return setValues({
            ...values,
            blocks: values.areaIds.map((item, index) => {
              return {
                numberOfBins: value,
                harvestUnitIds: null,
                bins: Array.from(Array(+value || 1).keys()).map(
                  (item, index) => {
                    return {
                      supervisorsIds:
                        values?.blocks[index]?.bins[index]?.supervisorsIds ||
                        [],
                      workersId:
                        values?.blocks[index]?.bins[index]?.workersId || [],
                      row: values?.blocks[index]?.bins[index]?.row || "",
                      generalTaskIds:
                        values?.blocks[index]?.bins[index]?.generalTaskIds ||
                        "",
                      internalBinIds:
                        values?.blocks[index]?.bins[index]?.internalBinIds || ""
                    };
                  }
                )
              };
            })
          });
        };
        return (
          <div className={styles.container}>
            <div className={styles.binsDetails}>
              <span className={styles.binsDetailsHaeader}>
                {" "}
                Specify the bin details
              </span>
              <Radio
                className={styles.test}
                onChange={() => setRadioButton(!radioButton)}
                toggle
              />
            </div>
            {values.areaIds.map((block, blockIndex) => {
              const area = areas.content.find(a => {
                return a.id === block;
              });
              return (
                <div className={styles.formWrapper}>
                  <Form className={styles.secondStepForm}>
                    <Segment className={styles.formSegment}>
                      <Form.Group className={styles.tableGroup}>
                        <h1>
                          {area && area.parent
                            ? `${area.parent.name}-${area.name}`
                            : area.name}
                        </h1>
                      </Form.Group>
                      <Form.Group className={styles.superVisorRow}>
                        <Form.Field width={8} required>
                          <label>Number of bins</label>
                          <Field
                            as={Input}
                            name="numberOfBins *"
                            onKeyPress={event => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            onChange={(e, { value }) => {
                              return (
                                changeNumberOfBins(value),
                                changeFieldValue(
                                  `blocks[${blockIndex}]numberOfBins`,
                                  value
                                ),
                                changeFieldValue(
                                  `blocks[${blockIndex}]bins`,
                                  Array.from(Array(+value || 1).keys()).map(
                                    (item, index) => {
                                      return {
                                        supervisorsIds:
                                          values?.blocks[index]?.bins[index]
                                            ?.supervisorsIds || [],
                                        workersId:
                                          values?.blocks[index]?.bins[index]
                                            ?.workersId || [],
                                        row:
                                          values?.blocks[index]?.bins[index]
                                            ?.row || "",
                                        generalTaskIds:
                                          values?.blocks[index]?.bins[index]
                                            ?.generalTaskIds || "",
                                        internalBinIds:
                                          values?.blocks[index]?.bins[index]
                                            ?.internalBinIds || ""
                                      };
                                    }
                                  )
                                )
                              );
                            }}
                          />
                        </Form.Field>
                        <Form.Field width={8} required>
                          <label>Bin type </label>
                          <Form.Dropdown
                            selection
                            search
                            name="harvestUnitIds"
                            options={harvestUnitsList.options}
                            value={get(values, "harvestUnitIds")}
                            onChange={(e, { value }) => {
                              return (
                                setFieldValue(
                                  `blocks[${blockIndex}]harvestUnitIds`,
                                  value
                                ),
                                changeFieldValue(
                                  `blocks[${blockIndex}]harvestUnitIds`,
                                  value
                                )
                              );
                            }}
                          />
                        </Form.Field>
                      </Form.Group>
                      {radioButton &&
                        (values.blocks[blockIndex].bins?.length
                          ? values.blocks[blockIndex].bins
                          : [
                              {
                                generalTaskIds: "",
                                internalBinIds: "",
                                row: "",
                                supervisorsIds: [],
                                workersId: []
                              }
                            ]
                        ).map((item, index) => {
                          return (
                            <>
                              <Form.Group className={styles.tableGroup}>
                                <h1>Bin {index + 1}</h1>
                              </Form.Group>
                              <Form.Group>
                                <Form.Field width={8}>
                                  <label>Supervisor</label>
                                  <Field
                                    fluid
                                    className={styles.selectWorkersField}
                                    required
                                    as={MultiSelect}
                                    search={true}
                                    text={
                                      values.blocks[blockIndex].bins[index]
                                        ?.supervisorsIds?.length
                                        ? `${values.blocks[blockIndex].bins[index]?.supervisorsIds?.length} supervisors`
                                        : ""
                                    }
                                    onOptionChange={(name, value) => {
                                      return (
                                        setFieldValue(
                                          `blocks[${blockIndex}].bins[${index}].supervisorsIds`,
                                          value
                                        ) /* (`blocks[${blockIndex}].bins[${index}].supervisorsIds`, [...values.blocks[blockIndex].bins[index].supervisorsIds, values.value]) */,
                                        changeFieldValue(
                                          `blocks[${blockIndex}].bins[${index}].supervisorsIds`,
                                          value
                                        )
                                      );
                                    }}
                                    selector={supervisorsFilterOptions}
                                    value={
                                      values.blocks[blockIndex].bins[index]
                                        ?.supervisorsIds || []
                                    }
                                    name={`blocks[${blockIndex}].bins[${index}].supervisorsIds`}
                                    loaderAction={getEmployeeList}
                                  />
                                </Form.Field>
                                {
                                  <Form.Field width={8}>
                                    <label>Worker</label>
                                    <Field
                                      fluid
                                      className={styles.selectWorkersField}
                                      required
                                      as={MultiSelect}
                                      search={true}
                                      text={
                                        values.blocks[blockIndex].bins[index]
                                          ?.workersId?.length
                                          ? `${values.blocks[blockIndex].bins[index]?.workersId?.length} workers`
                                          : ""
                                      }
                                      onOptionChange={(name, value) => {
                                        return (
                                          setFieldValue(
                                            `blocks[${blockIndex}].bins[${index}].workersId`,
                                            value
                                          ),
                                          changeFieldValue(
                                            `blocks[${blockIndex}].bins[${index}].workersId`,
                                            value
                                          )
                                        );
                                      }}
                                      selector={workersFilterOptions}
                                      value={
                                        values.blocks[blockIndex].bins[index]
                                          ?.workersId || []
                                      }
                                      name={`blocks[${blockIndex}].bins[${index}].workersId`}
                                      loaderAction={getEmployeeList}
                                    />
                                  </Form.Field>
                                }
                              </Form.Group>
                              {!!values.blocks[blockIndex].bins[index]
                                ?.supervisorsIds?.length && (
                                <div className={styles.workersRow}>
                                  <span className={styles.workersRowHead}>
                                    Supervisor:
                                  </span>{" "}
                                  {values.blocks[blockIndex].bins[
                                    index
                                  ]?.supervisorsIds.map(item => {
                                    return (
                                      <Label className={styles.addedItem}>
                                        {
                                          employees.find(
                                            ({ key }) => key === item
                                          )?.text
                                        }
                                        <Icon
                                          className={styles.closeIcon}
                                          name="close"
                                          onClick={() => {
                                            const filteredValues = values.blocks[
                                              blockIndex
                                            ].bins[
                                              index
                                            ]?.supervisorsIds.filter(
                                              id => item !== id
                                            );
                                            setFieldValue(
                                              `blocks[${blockIndex}].bins[${index}].supervisorsIds`,
                                              filteredValues
                                            );
                                          }}
                                        />
                                      </Label>
                                    );
                                  })}
                                </div>
                              )}
                              {!!values.blocks[blockIndex].bins[index]
                                ?.workersId?.length && (
                                <div className={styles.workersRow}>
                                  <span className={styles.workersRowHead}>
                                    Workers:
                                  </span>{" "}
                                  {values.blocks[blockIndex].bins[
                                    index
                                  ]?.workersId.map(item => {
                                    return (
                                      <Label className={styles.addedItem}>
                                        {
                                          employees.find(
                                            ({ key }) => key === item
                                          )?.text
                                        }
                                        <Icon
                                          className={styles.closeIcon}
                                          name="close"
                                          onClick={() => {
                                            const filteredValues = values.blocks[
                                              blockIndex
                                            ].bins[index].workersId.filter(
                                              id => item !== id
                                            );
                                            setFieldValue(
                                              `blocks[${blockIndex}.bins[${index}].workersId`,
                                              filteredValues
                                            );
                                          }}
                                        />
                                      </Label>
                                    );
                                  })}
                                </div>
                              )}
                              <Form.Group>
                                <Form.Field
                                  width={8}
                                  className={styles.formField}
                                >
                                  <label>Row</label>
                                  <Field
                                    onChange={(e, { value }) => {
                                      return (
                                        setFieldValue(
                                          `blocks[${blockIndex}].bins[${index}].row`,
                                          value
                                        ),
                                        changeFieldValue(
                                          `blocks[${blockIndex}].bins[${index}].row`,
                                          value
                                        )
                                      );
                                    }}
                                    as={Input}
                                    name="row"
                                    onKeyPress={event => {
                                      if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                  />
                                </Form.Field>
                                <Form.Field
                                  width={4}
                                  className={styles.formSmallField}
                                >
                                  <label>Task ID</label>
                                  <Form.Dropdown
                                    className={styles.formSmallDropDown}
                                    selection
                                    search
                                    name="generalTaskIds"
                                    options={generalTaskIDSelector}
                                    onChange={(e, { value }) => {
                                      return (
                                        setFieldValue(
                                          `blocks[${blockIndex}].bins[${index}].generalTaskIds`,
                                          value
                                        ),
                                        changeFieldValue(
                                          `blocks[${blockIndex}].bins[${index}].generalTaskIds`,
                                          value
                                        )
                                      );
                                    }}
                                  />
                                </Form.Field>
                                <Form.Field
                                  width={4}
                                  className={styles.formSmallField}
                                >
                                  <label>Internal bin ID</label>
                                  <Field
                                    as={Input}
                                    name="internalBinIds"
                                    value={values.internalBinIds}
                                    onChange={(e, { value }) => {
                                      return (
                                        setFieldValue(
                                          `blocks[${blockIndex}].bins[${index}].internalBinIds`,
                                          value
                                        ),
                                        changeFieldValue(
                                          `blocks[${blockIndex}].bins[${index}].internalBinIds`,
                                          value
                                        )
                                      );
                                    }}
                                  />
                                </Form.Field>
                              </Form.Group>
                            </>
                          );
                        })}
                    </Segment>
                  </Form>
                </div>
              );
            })}
          </div>
        );
      }}
    </Formik>
  );
};

export default AddModalSecondStep;
