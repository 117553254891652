import React from "react";
import PropTypes from "prop-types";
import { Grid, Table } from "semantic-ui-react";
import moment from "moment";
import styles from "./PrintReport.module.css";
import Logo from "../../../../components/Logo";
import Numeric from "../../../../components/Numeric";
import { minutesToHM } from "../../../../utils/timeUtils";

const PrintReport = ({ data, aggregations, startDate, endDate }) => {
  return (
    <div className={styles.reportWrapper}>
      <div className={styles.reportHeaderRow}>
        <div>
          <div className={styles.reportHeader}>Payroll Report</div>
          {startDate && endDate && (
            <div className={styles.reportSubHeader}>
              {`Date: ${moment(startDate).format("DD/MM/YY")} - ${moment(
                endDate
              ).format("DD/MM/YY")}`}
            </div>
          )}
        </div>
        <Logo width={140} height={60} />
      </div>
      <Table basic="very" striped className={styles.tableElement}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>First Name</Table.HeaderCell>
            <Table.HeaderCell>Last Name</Table.HeaderCell>
            <Table.HeaderCell>Contractor</Table.HeaderCell>
            <Table.HeaderCell>Total Working Time</Table.HeaderCell>
            <Table.HeaderCell>Rate ($/H)</Table.HeaderCell>
            <Table.HeaderCell>Pay For Hours ($)</Table.HeaderCell>
            <Table.HeaderCell>Pay For Pieces ($)</Table.HeaderCell>
            <Table.HeaderCell>Total Pay ($)</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data.map(
            (
              {
                firstName,
                lastName,
                contractor,
                totalWorkingTimeInHM,
                ratePerHour,
                payPerHours,
                payPerPiece,
                totalPay
              },
              i
            ) => (
              <Table.Row key={`task_row_${i}`}>
                <Table.Cell>{firstName}</Table.Cell>
                <Table.Cell>{lastName}</Table.Cell>
                <Table.Cell>{contractor}</Table.Cell>
                <Table.Cell>{totalWorkingTimeInHM}</Table.Cell>
                <Table.Cell>
                  <Numeric
                    value={ratePerHour}
                    fractionDigits={2}
                    commaSeparatorOnThousands
                    units="$ "
                    unitsPosition="left"
                  />
                  /H
                </Table.Cell>
                <Table.Cell>$ {payPerHours}</Table.Cell>
                <Table.Cell>$ {payPerPiece}</Table.Cell>
                <Table.Cell>$ {totalPay}</Table.Cell>
              </Table.Row>
            )
          )}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan={3}>Total</Table.HeaderCell>
            <Table.HeaderCell>
              {minutesToHM(aggregations.totalWorkingTime)}
            </Table.HeaderCell>
            <Table.HeaderCell />
            <Table.HeaderCell>$ {aggregations.payPerHours}</Table.HeaderCell>
            <Table.HeaderCell />
            <Table.HeaderCell>$ {aggregations.totalPay}</Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
      <div className={styles.reportCreatedRow}>
        Report created {moment().format("DD/MM/YYYY")} using Tie Up Farming
      </div>
    </div>
  );
};

PrintReport.propTypes = {
  data: PropTypes.array.isRequired,
  aggregations: PropTypes.object.isRequired,
  endDate: PropTypes.any,
  startDate: PropTypes.any
};

export default PrintReport;
