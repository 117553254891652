export const copyStyles = (sourceDoc, targetDoc) => {
  Array.from(sourceDoc.styleSheets).forEach(styleSheet => {
    try {
      if (styleSheet.cssRules) {
        const newStyleEl = sourceDoc.createElement("style");

        Array.from(styleSheet.cssRules).forEach(cssRule => {
          newStyleEl.appendChild(sourceDoc.createTextNode(cssRule.cssText));
        });

        targetDoc.head.appendChild(newStyleEl);
      } else {
        const newLinkEl = sourceDoc.createElement("link");

        newLinkEl.rel = "stylesheet";
        newLinkEl.href = styleSheet.href;
        targetDoc.head.appendChild(newLinkEl);
      }
    } catch (e) {
      // console.log(e);
    }
  });
};

export const copyStylesWhere = (sourceDoc, targetDoc, lookFor) => {
  Array.from(sourceDoc.styleSheets).forEach(styleSheet => {
    try {
      if (styleSheet.cssRules) {
        const newStyleEl = sourceDoc.createElement("style");
        if (
          Array.from(styleSheet.cssRules).find(
            cssRule => cssRule.selectorText.indexOf(lookFor) >= 0
          )
        ) {
          Array.from(styleSheet.cssRules).forEach(cssRule => {
            newStyleEl.appendChild(sourceDoc.createTextNode(cssRule.cssText));
          });

          targetDoc.head.appendChild(newStyleEl);
        }
      } else {
        const newLinkEl = sourceDoc.createElement("link");

        newLinkEl.rel = "stylesheet";
        newLinkEl.href = styleSheet.href;
        targetDoc.head.appendChild(newLinkEl);
      }
    } catch (e) {
      // console.log(e);
    }
  });
};
