import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, Grid, Icon } from "semantic-ui-react";
import animateScrollTo from "animated-scroll-to";

import { prepareTaskData } from "../../Utils/prepareTaskData";
import { prepareTaskDataToSend } from "../../Utils/prepareTaskDataToSend";
import {
  clearAndDuplicate,
  createTask,
  setValidations
} from "../../../../actions/SprayDiary/common";
import { isEmpty } from "lodash";
import { updateTaskItem } from "../../../../actions/Spray/tasks";
import generatePrintPage from "../../TaskDetails/generatePrintPage";
import { history } from "../../../../store";
import { validateWorkingTimes } from "../WorkingTimes/WorkingTimes";
import { collectBlockData } from "../../Utils/collectBlockData";
import { selectSingleEmployee } from "../../../../actions/SprayDiary/employee";
import { getPendingOperationsList } from "../../../../actions/PendingMovements";
import { userSelector } from "../../../../selectors/user";
import { onlineSelector } from "../../../../selectors/common";
import { originalFetchedTaskSelector } from "../../../../selectors/sprayDiary";
import SaveAndDuplicateButton from "./SaveAndDuplicateButton";
import { scrollIntoError } from "../../../../utils/scrollIntoError";

import styles from "./sprayForm.module.css";

const CreateTaskFooter = ({
  interventionTypes,
  status,
  task: { plannedDate },
  selectedAreas,
  selectedMachinery,
  conc,
  machinerySpeed,
  nozzlesPerSide,
  pressure,
  pto,
  areasList,
  literPerHectare,
  applicationType,
  widthPerRow,
  selectedChemicals,
  showParentBlocks,
  windStrength,
  windDirection,
  ppeWorn,
  temperature,
  supervisors,
  id,
  actions: {
    createTask,
    clearAndDuplicate,
    selectSingleEmployee,
    setValidations,
    updateTaskItem,
    getPendingOperationsList
  },
  workingTimes,
  task,
  spayCondition,
  addChemicalSelection,
  sprayDiary,
  fetchedTask,
  online,
  saving,
  _id,
  user,
  attachments,
  taskName,
  isOverlapping
}) => {
  let valid = {
    selectedAreas: !!selectedAreas.length,
    conc: !selectedMachinery.id || (!!conc && conc > 0),
    machinerySpeed: !machinerySpeed || machinerySpeed > 0,
    nozzle: true,
    nozzlesPerSide: !nozzlesPerSide || nozzlesPerSide >= 0,
    pressure: !pressure || pressure >= 0,
    gear: true,
    rpm: true,
    pto: !pto || pto >= 0,
    widthPerRow: !!widthPerRow,
    selectedChemicals: !!selectedChemicals.length,
    plannedDate: !!plannedDate,
    supervisors: !!supervisors.length,
    literPerHectare:
      applicationType === "SPRAY"
        ? literPerHectare !== "" && literPerHectare >= 0
        : true,
    addChemicalSelection: !addChemicalSelection,
    taskName: !taskName || taskName.length <= 20
  };

  const isTaskCreating = !task.id;
  const needToValidateWorkingTimes = !isTaskCreating && status === "COMPLETED";
  const needToValidatePPE = !isTaskCreating && status === "COMPLETED";
  const completedDisabled = {
    windDirection: !!windDirection,
    windStrength: !!windStrength,
    temperature: !!temperature,
    workingTimes: !needToValidateWorkingTimes
      ? true
      : isEmpty(workingTimes)
      ? false
      : validateWorkingTimes(workingTimes).every(i => i),
    ppeWorn: !needToValidatePPE ? true : ppeWorn !== null
  };

  if (status === "COMPLETED") valid = { ...valid, ...completedDisabled };

  const submit = async printAfter => {
    if (
      Object.keys(valid)
        .map(item => {
          return valid[item];
        })
        .includes(false)
    ) {
      await setValidations(valid);
      scrollIntoError();
      return null;
    }
    const taskToSend = !id
      ? prepareTaskData({ newTask: true, sprayDiary })
      : prepareTaskDataToSend({ sprayDiary, fetchedTask });
    let taskData = prepareTaskData({ forTask: true, sprayDiary });
    const attachmentsToWatch = !id
      ? taskData.attachments
          .filter(file => file.idReplace)
          .map(file => file.idReplace)
      : null;
    if (Object.keys(taskToSend).length > 0) {
      if (online && printAfter) {
        taskData = {
          ...taskData,
          ...(await createTask({
            id,
            _id,
            data: taskToSend,
            attachmentsToWatch
          }))
        };
      } else {
        await createTask({ id, _id, data: taskToSend, attachmentsToWatch });
      }
    }
    updateTaskItem({ id, _id, data: taskData });
    return taskData;
  };

  const submitAndPrint = async () => {
    const taskData = await submit(true);
    const targetId =
      online && taskData && taskData.id ? taskData.id : id || _id;
    const printId =
      online && taskData && taskData.id ? taskData.id : id || "New";
    if (taskData) {
      history.replace(`/spray_diary/create_spray_task/${targetId}`);
      print(printId, interventionTypes);
    }
  };

  const submitAndClose = async () => {
    const taskData = await submit(false);
    getPendingOperationsList();
    if (taskData) {
      history.push("/spray_diary#fromSave");
    }
  };

  const scrollUp = () => {
    animateScrollTo(0, {
      elementToScroll: document.getElementById("sprayGrid") || window
    });
  };

  const submitAndDuplicate = async parts => {
    const taskData = await submit(false);
    if (taskData) {
      clearAndDuplicate(taskData, parts);
      //TODO: avoid this duplication
      selectSingleEmployee(user.profile.employee_id, "creator");
      history.push("/spray_diary/create_spray_task");
      scrollUp();
      return true;
    }

    return false;
  };

  const print = (id = task.id, interventionTypes) => {
    const taskToPrint = prepareTaskData({ forTask: true, sprayDiary });
    const blocksData = collectBlockData(
      taskToPrint,
      areasList,
      showParentBlocks
    );
    const newWindow = window.open();

    newWindow.document.title = `ST-${id}`;
    newWindow.document.body.innerHTML = generatePrintPage(
      { ...taskToPrint, id },
      blocksData,
      spayCondition,
      interventionTypes,
      showParentBlocks
    );
    newWindow.focus();
    setTimeout(() => {
      newWindow.print();
    }, 100);
  };

  const gotEmptyBatchNum =
    task &&
    !!task.chemicals.find(
      chemical => chemical.batchRequired && !chemical.batchNumbers?.length
    );
  const workingTimeList = workingTimes.map(i => ({
    start: i.workingFrom,
    end: i.workingTo,
    date: i.date
  }));
  const isButtonDisabled =
    isOverlapping(workingTimeList) ||
    !workingTimes.every(item => item.workingTo > item.workingFrom) ||
    (gotEmptyBatchNum && status === "COMPLETED") ||
    (attachments && attachments.find(file => file.idReplace) && online) ||
    !completedDisabled.workingTimes ||
    !completedDisabled.ppeWorn ||
    (saving && online);
  const buttonLoading = saving && online;

  return (
    <Grid className={styles.buttonsFooter} verticalAlign="middle">
      <Grid.Row>
        <Grid.Column width={5} className="show-sm">
          <Button
            className="button-text color-green"
            size="large"
            onClick={submitAndPrint}
            floated="left"
          >
            <Icon name="print" />
            Print
          </Button>
        </Grid.Column>
        <Grid.Column textAlign="right" mobile={16} computer={11}>
          <Button
            primary
            floated="right"
            color="grey"
            onClick={submitAndClose}
            size="large"
            loading={buttonLoading}
            disabled={isButtonDisabled}
          >
            {fetchedTask.status !== "COMPLETED" && status === "COMPLETED" ? (
              <>
                <Icon className="tuf-check-circle" />
                Mark Completed
              </>
            ) : (
              "Save"
            )}
          </Button>
          <SaveAndDuplicateButton
            onClick={submitAndDuplicate}
            loading={buttonLoading}
            disabled={isButtonDisabled}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const mapStateToProps = state => {
  const {
    applicationSettings: { showParentBlocks },
    sprayDiary,
    spray: {
      tasks: { interventionTypes }
    },
    sprayDiary: {
      id,
      _id,
      saving,
      status,
      areasList,
      selectedAreas,
      selectedMachinery,
      machinerySpeed,
      conc,
      ppeWorn,
      nozzle,
      nozzlesPerSide,
      gear,
      rpm,
      pressure,
      pto,
      applicationType,
      literPerHectare,
      widthPerRow,
      selectedChemicals,
      windDirection,
      temperature,
      deltaT,
      actualRowsToSprayChecked,
      assignees,
      supervisors,
      windStrength,
      rowsToSpray,
      strenghts,
      plannedDate,
      employee,
      addChemicalSelection,
      workingTimes,
      attachments,
      taskName
    }
  } = state;

  return {
    id,
    _id,
    status,
    machinerySpeed,
    conc,
    nozzle,
    nozzlesPerSide,
    gear,
    rpm,
    pressure,
    pto,
    selectedAreas,
    selectedMachinery,
    areasList,
    showParentBlocks,
    applicationType,
    literPerHectare,
    widthPerRow,
    selectedChemicals: selectedChemicals.filter(chemical => chemical),
    windStrength,
    windDirection,
    temperature,
    deltaT,
    actualRowsToSprayChecked,
    assignees,
    ppeWorn,
    supervisors,
    addChemicalSelection,
    taskName,
    spayCondition: {
      windStrength,
      temperature,
      strenghts,
      windDirection
    },
    task: {
      id,
      assignees: employee.content.filter(user =>
        assignees.find(employee => employee.id === user.id)
      ),
      supervisors: employee.content.filter(user =>
        supervisors.find(employee => employee.id === user.id)
      ),
      plannedDate,
      chemicals: selectedChemicals.filter(chemical => chemical),
      machinerySpeed,
      rowsToSpray,
      rowWidth: widthPerRow,
      literPerHectare,
      machinery: selectedMachinery,
      areas: selectedAreas,
      status
    },
    workingTimes,
    attachments,
    sprayDiary,
    fetchedTask: originalFetchedTaskSelector(state),
    online: onlineSelector(state),
    saving,
    interventionTypes,
    user: userSelector(state)
  };
};

CreateTaskFooter.propTypes = {
  actions: PropTypes.object,
  user: PropTypes.object,
  attachments: PropTypes.array,
  interventionTypes: PropTypes.object,
  plannedDate: PropTypes.object,
  status: PropTypes.string,
  taskName: PropTypes.string,
  selectedAreas: PropTypes.array,
  selectedMachinery: PropTypes.object,
  conc: PropTypes.number,
  ppeWorn: PropTypes.bool,
  machinerySpeed: PropTypes.number,
  nozzlesPerSide: PropTypes.number,
  pressure: PropTypes.number,
  pto: PropTypes.number,
  literPerHectare: PropTypes.number,
  widthPerRow: PropTypes.number,
  selectedChemicals: PropTypes.array,
  windStrength: PropTypes.string,
  windDirection: PropTypes.string,
  temperature: PropTypes.number,
  actualRowsToSprayChecked: PropTypes.bool,
  supervisors: PropTypes.array,
  id: PropTypes.number,
  workingTimes: PropTypes.array,
  task: PropTypes.object,
  spayCondition: PropTypes.object,
  addChemicalSelection: PropTypes.bool,
  sprayDiary: PropTypes.object,
  fetchedTask: PropTypes.object,
  online: PropTypes.bool,
  saving: PropTypes.bool,
  _id: PropTypes.string
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      createTask,
      clearAndDuplicate,
      selectSingleEmployee,
      setValidations,
      updateTaskItem,
      getPendingOperationsList
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateTaskFooter);
