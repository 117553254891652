import PropTypes from "prop-types";
import React, { useState } from "react";
import { Confirm, Icon, Table } from "semantic-ui-react";
import { useDispatch } from "react-redux";

import styles from "./styles.module.css";
import { deleteGeneralTaskWorkingTime } from "../../../../../actions/GeneralTasks/generalTasks";
import { addCommas } from "../../../../../components/Numeric";
import ShowMore from "../ShowMore";

const WorkingTimeRow = ({
  workingTime,
  onEditClick,
  showPieceRateFields,
  task
}) => {
  const [deleteWorkingTimeID, setDeleteWorkingTimeID] = useState(false);
  const dispatch = useDispatch();

  const onDeleteConfirm = () =>
    dispatch(
      deleteGeneralTaskWorkingTime(task.id, deleteWorkingTimeID)
    ).then(() => setDeleteWorkingTimeID(null));

  return (
    <Table.Row>
      <Table.Cell>{workingTime.id}</Table.Cell>
      <Table.Cell>{workingTime.employee}</Table.Cell>
      <Table.Cell>{workingTime.employeesGroup}</Table.Cell>
      <Table.Cell>{workingTime.contractor}</Table.Cell>
      <Table.Cell>
        <ShowMore
          items={
            workingTime.areas.map(({ area }) =>
              area.parent ? `${area.parent.name}/${area.name}` : area.name
            ) || ["-"]
          }
        />
      </Table.Cell>
      <Table.Cell>{workingTime.clockedIn}</Table.Cell>
      <Table.Cell>{workingTime.clockedOut}</Table.Cell>
      <Table.Cell>{workingTime.workingTime}</Table.Cell>
      <Table.Cell>{workingTime.lunchBreak} m</Table.Cell>
      {showPieceRateFields && (
        <>
          <Table.Cell>${addCommas(workingTime.pieceRate || 0)}</Table.Cell>
          <Table.Cell>{workingTime.piecesPerEmployee || 0}</Table.Cell>
          <Table.Cell>${workingTime.countTotal}</Table.Cell>
        </>
      )}
      <Table.Cell>
        <Confirm
          content={"Are you sure you want to delete this working time?"}
          open={!!deleteWorkingTimeID}
          onConfirm={onDeleteConfirm}
          onCancel={() => setDeleteWorkingTimeID(null)}
        />
        <div className={styles.buttonsHolder}>
          <Icon
            name={"pencil"}
            size={"mini"}
            color={"grey"}
            style={{ marginRight: 10 }}
            onClick={onEditClick}
          />
          <Icon
            name="trash alternate"
            size={"mini"}
            color={"grey"}
            onClick={() => setDeleteWorkingTimeID(workingTime.id)}
          />
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

WorkingTimeRow.propTypes = {
  onEditClick: PropTypes.func.isRequired,
  showPieceRateFields: PropTypes.bool,
  workingTime: PropTypes.object.isRequired
};

export default WorkingTimeRow;

WorkingTimeRow.defaultProps = {
  showPieceRateFields: false
};
