import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Button, Confirm } from 'semantic-ui-react';
import CheckboxDropdown from '../../../../components/CheckboxDropdown';
import { changeDuplicateSelection, setSprayDiaryField } from '../../../../actions/SprayDiary/common';
import styles from './sprayForm.module.css';

class SaveAndDuplicateButton extends React.Component {
  state = {
    showModal: false,
  };

  onOKClick = () => {
    const {
      onClick,
      duplicateSelectedItems,
      actions: { setSprayDiaryField },
    } = this.props;
    if (onClick) {
      onClick(duplicateSelectedItems.map(item => item.value)).then(res => {
        if (res) {
          setSprayDiaryField({ fieldName: 'activeDimmer', fieldValue: true });
          setTimeout(() => setSprayDiaryField({ fieldName: 'activeDimmer', fieldValue: false }), 1000);
        }
      });

      document.activeElement.blur();
    }

    this.hideModal();
  };

  showModal = () => {
    this.setState({ showModal: true });
  };

  hideModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    const {
      loading,
      disabled,
      duplicateOptions,
      duplicateSelectedItems,
      actions: { changeDuplicateSelection },
    } = this.props;
    const { showModal } = this.state;
    return (
      <>
        <Confirm
          header="Duplicate Task"
          content={
            <div className={styles.confirmContent}>
              <div className={styles.confirmContentLine}>Task details will be copied</div>
              <div className={styles.confirmContentLine}>Please fill in the required fields and click Save</div>
            </div>
          }
          open={showModal}
          onCancel={this.hideModal}
          onConfirm={this.onOKClick}
        />
        <Button.Group className="createTaskButton hide-on-mobile" size="large" floated="right">
          <Button
            className="color-green"
            onClick={this.showModal}
            loading={loading}
            disabled={disabled || duplicateSelectedItems.length === 0}
          >
            <span>Save and Duplicate</span>
            {duplicateSelectedItems.length !== duplicateOptions.length && (
              <span className={styles.countDuplicateSelectedItems}>{duplicateSelectedItems.length}</span>
            )}
          </Button>
          <CheckboxDropdown
            className="button icon relative"
            icon="caret up"
            upward
            floating
            options={duplicateOptions}
            value={duplicateSelectedItems}
            trigger={<React.Fragment />}
            onChange={(_, selectedItems) => changeDuplicateSelection(selectedItems)}
          />
        </Button.Group>
      </>
    );
  }
}

SaveAndDuplicateButton.propTypes = {
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  duplicateOptions: PropTypes.array,
  duplicateSelectedItems: PropTypes.array,
  actions: PropTypes.object,
};

const mapStateToProps = ({ sprayDiary: { duplicateOptions, duplicateSelectedItems } }) => {
  return {
    duplicateOptions,
    duplicateSelectedItems,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      changeDuplicateSelection,
      setSprayDiaryField,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SaveAndDuplicateButton);
