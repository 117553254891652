import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";

import {
  Button,
  Dropdown,
  Grid,
  Header,
  Icon,
  Segment
} from "semantic-ui-react";

import { abilitiesSelector } from "../../../selectors/user";
import styles from "./SprayRecords.module.css";
import Layout from "../../../components/Layout";
import DatePicker from "../../../components/DatePicker";
import {
  clearSprayTasksListForRecords,
  getSprayTasksListForRecords
} from "../../../actions/Spray/tasks";
import { areasSelectorWithoutVariety } from "../../../selectors/sprayDiary";
import { getAreasList } from "../../../actions/SprayDiary/areas";
import SprayAreaReport from "./SprayAreaReport/SprayAreaReport";
import ReactDOMServer from "react-dom/server";
import SprayRecordsTable, { tableColumns } from "./SprayRecordsTable";
import { copyStylesWhere } from "../../../utils/styles";
import { history } from "../../../store";
import { getParentPageHref } from "../../../utils/routeHelpers";
import ExportToExcel from "../../../components/ExportToExcel";
import { Area } from "../../../models/block.model";

const WindStrength = {
  WS_LESS_THEN_2: "Less then 2 km/h",
  WS_2_TO_5: "2-5 km/h",
  WS_5_TO_12: "5-12 km/h",
  WS_12_TO_20: "12-20 km/h",
  WS_20_TO_30: "20-30 km/h",
  WS_39_TO_49: "30-49 km/h"
};

const Status = {
  OPEN: "Open",
  IN_PROGRESS: "In progress",
  COMPLETED: "Completed"
};

const SprayRecords = ({
  route,
  location,
  areasOptions,
  areasList: { loaded },
  rawData,
  isFetching,
  actions: {
    getSprayTasksListForRecords,
    clearSprayTasksListForRecords,
    getAreasList
  }
}) => {
  const [from, setFrom] = useState(
    moment(moment.now())
      .subtract(1, "month")
      .format("YYYY-MM-DD")
  );
  const [to, setTo] = useState(moment(moment.now()).format("YYYY-MM-DD"));
  const [filtered, setFiltered] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);
  let reactTable = useRef(null);

  useEffect(() => {
    clearSprayTasksListForRecords();
    getAreasList();
  }, [clearSprayTasksListForRecords, getAreasList]);

  const data = useMemo(() => {
    const data = [];
    rawData &&
      rawData.forEach(task => {
        task.areas.forEach(area => {
          task.chemicals.forEach(chemical => {
            const row = {
              id: task.id,
              plannedDate: task.plannedDate,
              expirationDate: task.expirationDate,
              status: Status[task.status],
              areaId: area.area.id,
              area: area.area.name,
              variety: new Area(area.area).areaVarietyByDate(task.plannedDate)
                .name,
              chemical: chemical.chemical.name,
              purpose: chemical.chemicalRate && chemical.chemicalRate.purpose,
              resistanceGroup1:
                chemical.chemical.properties &&
                chemical.chemical.properties.resistanceGroup1,
              activeIngredient:
                chemical.chemical.properties &&
                chemical.chemical.properties.activeIngredient,
              ratePer100L:
                chemical.dilutionRateType === "PER_100_LITERS"
                  ? chemical.dilutionRate
                  : (
                      (chemical.dilutionRate / task.literPerHectare) *
                      100
                    ).toFixed(3),
              ratePerHectare:
                chemical.dilutionRateType === "PER_HECTARE"
                  ? chemical.dilutionRate
                  : (
                      (chemical.dilutionRate * task.literPerHectare) /
                      100
                    ).toFixed(3),
              literPerHectare: task.literPerHectare,
              withholdingPeriod: chemical.withholdingPeriod,
              plannedTanks: area.plannedTanks,
              actualTanks: area.actualTanks,
              conc: task.conc,
              comment: task.comment,
              machinery: task.machinery && task.machinery.name,
              supervisors:
                task.supervisors &&
                task.supervisors.map(
                  supervisor =>
                    supervisor.employee.firstName +
                    " " +
                    supervisor.employee.lastName
                ),
              assignees:
                task.assignees &&
                task.assignees.map(
                  assignee =>
                    assignee.employee.firstName +
                    " " +
                    assignee.employee.lastName
                ),
              creator: task.creator.firstName + " " + task.creator.lastName,
              interventionType: task.interventionType,
              shedId: task.shedId,
              rowsToSpray: task.rowsToSpray,
              machinerySize: task.machinerySize,
              machinerySpeed: task.machinerySpeed,
              nozzle: task.nozzle,
              nozzlesPerSide: task.nozzlesPerSide,
              gear: task.gear,
              rpm: task.rpm,
              pressure: task.pressure,
              pto: task.pto,
              workingTimes:
                task.workingTimes &&
                task.workingTimes.map(
                  workingTime =>
                    `${workingTime.date} ${workingTime.workingFrom} - ${workingTime.workingTo}`
                ),
              windDirection: task.windDirection,
              windStrength: WindStrength[task.windStrength],
              temperature: task.temperature
            };
            data.push(row);
          });
        });
      });

    return data;
  }, [rawData]);

  const onFilteredChangeCustom = useMemo(
    () => (value, accessor) => {
      let innerFiltered = [...filtered];
      let insertNewFilter = 1;

      if (innerFiltered.length) {
        innerFiltered.forEach((filter, i) => {
          if (filter["id"] === accessor) {
            if (value === "" || !value.length) innerFiltered.splice(i, 1);
            else filter["value"] = value;

            insertNewFilter = 0;
          }
        });
      }

      if (insertNewFilter) {
        innerFiltered.push({ id: accessor, value: value });
      }

      setFiltered(innerFiltered);
    },
    [filtered]
  );

  const refresh = useMemo(
    () => () => {
      getSprayTasksListForRecords({ from, to });
    },
    [getSprayTasksListForRecords, from, to]
  );

  const print = () => {
    const newWindow = window.open();
    newWindow.document.title = `Block spray report`;
    copyStylesWhere(window.document, newWindow.document, "SprayAreaReport");
    newWindow.document.body.innerHTML = ReactDOMServer.renderToString(
      <SprayAreaReport
        data={reactTable.current.getResolvedState().sortedData}
      />
    );
    newWindow.print();
  };

  const handelRawDataViewClick = useCallback(() => {
    history.push(getParentPageHref(route));
  }, [route]);
  return (
    <Layout route={route} location={location} classForMain={styles.mainHolder}>
      <Segment basic>
        <Grid>
          <Grid.Row>
            <Grid.Column width={8}>
              <Header as="h2" className={styles.taskName}>
                {route.name}
                <Dropdown
                  text={" "}
                  floating
                  className={styles.dropdownHeaderMenu}
                >
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={handelRawDataViewClick}>
                      List view
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Header>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <Segment>
        <Grid verticalAlign="middle">
          <Grid.Row>
            <Grid.Column width={16}>
              <div className={styles.barHolder}>
                <div>
                  <div className={styles.barHolderIn}>
                    <div>From:</div>
                    <DatePicker
                      value={from}
                      onChange={date => setFrom(date)}
                      size="small"
                      className={styles.dateInput}
                    />
                  </div>
                </div>
                <div>
                  <div className={styles.barHolderIn}>
                    <div>To:</div>
                    <DatePicker
                      value={to}
                      onChange={date => setTo(date)}
                      size="small"
                      className={styles.dateInput}
                    />
                  </div>
                </div>
                <div>
                  <div className={styles.barHolderIn}>
                    <div>Areas:</div>
                    <Dropdown
                      search
                      closeOnChange
                      closeOnBlur={false}
                      noResultsMessage="Nothing was found"
                      fluid
                      placeholder="Select areas"
                      selection
                      multiple
                      value={selectedAreas}
                      loading={!loaded}
                      renderLabel={item => item.shortText}
                      options={areasOptions}
                      onChange={(_, data) => {
                        setSelectedAreas(data.value);
                        onFilteredChangeCustom(
                          data.options
                            .filter(option =>
                              data.value.find(key => key === option.key)
                            )
                            .map(o => {
                              return o.text;
                            }),
                          "area"
                        );
                      }}
                      icon={null}
                      className={styles.areasDropdown}
                    />
                  </div>
                </div>
                <div>
                  <Button onClick={refresh}>
                    <Icon name="repeat" />
                    <span>Refresh</span>
                  </Button>
                  <Button onClick={print}>
                    <Icon name="print" />
                    <span>Print</span>
                  </Button>
                  <ExportToExcel
                    rawData={data}
                    columns={tableColumns}
                    name={"TUF Exported Data"}
                    alternativeButton
                  />
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <SprayRecordsTable
                ref1={reactTable}
                isFetching={isFetching}
                data={data}
                filtered={filtered}
                setFiltered={setFiltered}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </Layout>
  );
};

SprayRecords.propTypes = {
  route: PropTypes.object,
  actions: PropTypes.object,
  location: PropTypes.object,
  areasList: PropTypes.object,
  isFetching: PropTypes.bool,
  areasOptions: PropTypes.array,
  rawData: PropTypes.array
};

SprayRecords.defaultProps = {
  route: { name: "{% name %}" }
};

const mapStateToProps = state => {
  const {
    sprayDiary: { areasList },
    spray: {
      records: {
        isFetching,
        data: { content }
      }
    }
  } = state;
  return {
    Can: abilitiesSelector(state),
    areasList,
    rawData: content,
    isFetching,
    areasOptions: areasSelectorWithoutVariety(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        getAreasList,
        getSprayTasksListForRecords,
        clearSprayTasksListForRecords
      },
      dispatch
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SprayRecords);
