export const genders = [
  {
    key: "MALE",
    label: "Male",
    value: "MALE"
  },
  {
    key: "FEMALE",
    label: "Female",
    value: "FEMALE"
  }
];
