import React, { Fragment } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import Logo from "../../../../../../components/Logo";
import classNames from "classnames";
import { Header, Table, TableBody, TableRow } from "semantic-ui-react";
import Numeric from "../../../../../../components/Numeric/Numeric";
import { pickBy } from "lodash";
import {
  minutesToHM,
  dateRangeToString
} from "../../../../../../utils/timeUtils";
import styles from "./PrintReports.module.css";

const headerColumn = {
  varietyName: "Variety",
  size: "Size (Ha)",
  binUnits: "Box units"
};
const secondHeaderColumn = {
  binUnitsNumber: "No.units",
  weight: "Weight (T)",
  binUnits: "Box units",
  tonnePerHa: "Tonne/Ha (T/Ha)",
  cost: "Cost ($)",
  costPerTonne: "Cost/Tonne ($/T)",
  costPerBin: "Cost/Unit ($/Unit)",
  avgKgPerTree: "Avg. Kg/Tree"
};
class PrintReports extends React.Component {
  render() {
    const {
      tableData,
      data,
      cropIds,
      varietyIds,
      binUnitsIds,
      selectedItem,
      from,
      to,
      cropsList,
      varietiesList,
      binUnitsList
    } = this.props;

    const headerNewData = pickBy(headerColumn, function(value, key) {
      return (
        selectedItem &&
        selectedItem.find(item => {
          return value === item;
        })
      );
    });
    const secondHeaderNewData = pickBy(secondHeaderColumn, function(
      value,
      key
    ) {
      return (
        selectedItem &&
        selectedItem.find(item => {
          return value === item;
        })
      );
    });
    const totalValue = {
      size: data.content.reduce((prev, curr) => prev + curr.size, 0),
      weight: data.content.reduce((prev, curr) => prev + curr.weight, 0),
      binUnitsNumber: data.content.reduce(
        (prev, curr) => prev + curr.binUnitsNumber,
        0
      ),
      cost: data.content.reduce((prev, curr) => prev + curr.cost, 0)
    };
    const totalPlants = data.content.reduce(
      (prev, curr) => prev + curr.numberOfPlants,
      0
    );
    const tonnePerHa = totalValue.weight / totalValue.size;
    const costPerTonne = totalValue.cost / totalValue.weight;
    const costPerBin = totalValue.cost / totalValue.binUnitsNumber;
    const avgKgPerTree = (totalValue.weight * 1000) / totalPlants;
    const crops = [];
    const varieties = [];
    const binUnits = [];
    if (cropsList && cropIds && cropIds.length) {
      const cropIdsList = cropIds && cropIds.map(Number);
      crops.push(
        cropsList.options &&
          cropsList.options
            .filter(({ value }) => cropIdsList.includes(value))
            .map(({ label }) => label)
      );
    }
    if (varietiesList && varietyIds && varietyIds.length) {
      const varietyIdsList = varietyIds && varietyIds.map(Number);
      varieties.push(
        varietiesList.options &&
          varietiesList.options
            .filter(({ value }) => varietyIdsList.includes(value))
            .map(({ label }) => label)
      );
    }
    if (binUnitsList && binUnitsIds && binUnitsIds.length) {
      const binUnitsIdsList = binUnitsIds && binUnitsIds.map(Number);
      binUnits.push(
        binUnitsList.options &&
          binUnitsList.options
            .filter(({ value }) => binUnitsIdsList.includes(value))
            .map(({ label }) => label)
      );
    }

    return (
      <div className={styles.reportWrapper}>
        <div className={styles.reportHeaderRow}>
          <div>
            <div className={styles.reportHeader}>
              Field scanning - Harvest by area
            </div>
            <Table basic="very">
              <Table.Header>
                <Table.Row>
                  {from && to && (
                    <Table.HeaderCell className={styles.reportSubHeader}>
                      <div className={styles.dateRow}>Date Range</div>
                    </Table.HeaderCell>
                  )}
                  {!!crops.length && (
                    <Table.HeaderCell className={styles.reportSubHeader}>
                      Crop
                    </Table.HeaderCell>
                  )}
                  {!!varieties.length && (
                    <Table.HeaderCell className={styles.reportSubHeader}>
                      Variety
                    </Table.HeaderCell>
                  )}
                  {!!binUnits.length && (
                    <Table.HeaderCell className={styles.reportSubHeader}>
                      Box unit
                    </Table.HeaderCell>
                  )}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  {from && to && (
                    <Table.Cell className={styles.reportSubBody}>
                      <div className={styles.dateRow}>
                        {from ? `${moment(from).format("DD/MM/YY")}` : ""}-
                        {to ? `${moment(to).format("DD/MM/YY")}` : ""}
                      </div>
                    </Table.Cell>
                  )}
                  {!!crops.length && (
                    <Table.Cell>
                      <div className={styles.reportSubBody}>
                        {crops.join(",")}
                      </div>
                    </Table.Cell>
                  )}
                  {!!varieties.length && (
                    <Table.Cell>
                      <div className={styles.reportSubBody}>
                        {varieties.join(",")}
                      </div>
                    </Table.Cell>
                  )}
                  {!!binUnits.length && (
                    <Table.Cell>
                      <div className={styles.reportSubBody}>
                        {binUnits.join(",")}
                      </div>
                    </Table.Cell>
                  )}
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
          <div className={styles.logoContainet}>
            <Logo width={140} height={60} />
          </div>
        </div>
        <div className={styles.totalRowTitle}>Total</div>
        <div className={styles.totalRowHeader}>
          {headerNewData && <div className={styles.cell}>Size (Ha)</div>}
          {secondHeaderNewData &&
            Object.keys(secondHeaderNewData).map(head => {
              return (
                secondHeaderNewData[`${head}`] !== "Box units" && (
                  <div className={styles.cell}>
                    {secondHeaderNewData[`${head}`]}
                  </div>
                )
              );
            })}
        </div>
        <div className={styles.totalRowBody}>
          {headerNewData && (
            <div className={styles.cell}>
              <Numeric
                fractionDigits={totalValue.size > 1000 ? 0 : 2}
                value={totalValue.size}
                commaSeparatorOnThousands
                defaultValue="-"
              />
            </div>
          )}
          {secondHeaderNewData &&
            Object.keys(secondHeaderNewData).map(head => {
              return (
                <>
                  {head == "costPerTonne" ? (
                    <div className={styles.cell}>
                      <Numeric
                        fractionDigits={costPerTonne > 1000 ? 0 : 2}
                        value={costPerTonne}
                        commaSeparatorOnThousands
                        defaultValue="-"
                      />
                    </div>
                  ) : head == "costPerBin" ? (
                    <div className={styles.cell}>
                      <Numeric
                        fractionDigits={costPerBin > 1000 ? 0 : 2}
                        value={costPerBin}
                        commaSeparatorOnThousands
                        defaultValue="-"
                      />
                    </div>
                  ) : head == "tonnePerHa" ? (
                    <div className={styles.cell}>
                      <Numeric
                        fractionDigits={tonnePerHa > 1000 ? 0 : 2}
                        value={tonnePerHa}
                        commaSeparatorOnThousands
                        defaultValue="-"
                      />
                    </div>
                  ) : head == "avgKgPerTree" ? (
                    <div className={styles.cell}>
                      <Numeric
                        fractionDigits={avgKgPerTree > 1000 ? 0 : 2}
                        value={avgKgPerTree}
                        commaSeparatorOnThousands
                        defaultValue="-"
                      />
                    </div>
                  ) : (
                    head != "binUnits" && (
                      <div className={styles.cell}>
                        <Numeric
                          fractionDigits={totalValue[head] > 1000 ? 0 : 2}
                          value={totalValue[head]}
                          commaSeparatorOnThousands
                          defaultValue="-"
                        />
                      </div>
                    )
                  )}
                </>
              );
            })}
        </div>
        {tableData.length > 0 ? (
          tableData.map(item => (
            <>
              <Table basic="very" fixed={true} className={styles.reportTable}>
                <div className={styles.blockWrapper}>
                  <div className={styles.blockTableHeader}>
                    <Table.Header
                      fullWidth={true}
                      className={styles.blockHeaderTable}
                    >
                      {headerNewData &&
                        Object.keys(headerNewData).map(head => {
                          return (
                            <>
                              <Table.Cell
                                as="td"
                                className={styles.blockHeaderCellTable}
                              >
                                <div className={styles.tableHeader}>
                                  {" "}
                                  {headerNewData[`${head}`]}
                                </div>
                              </Table.Cell>
                              <Table.Cell
                                width={parseInt(
                                  16 / Object.keys(headerNewData).length
                                )}
                                as="td"
                                className={styles.blockHeaderValueCellTable}
                              >
                                <div className={styles.tableValue}>
                                  {head == "varietyName" ? (
                                    item[`${head}`]
                                  ) : head === "binUnits" ? (
                                    item.binUnits ? (
                                      item.binUnits.length > 1 ? (
                                        `${item.binUnits.length} Types`
                                      ) : (
                                        item.binUnits[0]
                                      )
                                    ) : (
                                      "-"
                                    )
                                  ) : isFinite(item[head]) ? (
                                    <Numeric
                                      fractionDigits={item[head] > 2000 ? 0 : 2}
                                      value={item[head]}
                                      commaSeparatorOnThousands
                                      defaultValue="-"
                                    />
                                  ) : (
                                    "-"
                                  )}
                                </div>
                              </Table.Cell>
                            </>
                          );
                        })}
                    </Table.Header>
                  </div>
                  <div className={styles.blockTableHeader}>
                    <Table.Header
                      fullWidth={true}
                      className={styles.blockHeaderTable}
                    >
                      {secondHeaderNewData &&
                        Object.keys(secondHeaderNewData).map(head => {
                          return (
                            <Table.Cell
                              as="td"
                              className={styles.blockSecondHeaderCellTable}
                            >
                              <div className={styles.tableHeader}>
                                {secondHeaderNewData[`${head}`]}
                              </div>
                            </Table.Cell>
                          );
                        })}
                    </Table.Header>
                    <TableBody className={styles.blockBodyTable}>
                      {secondHeaderNewData &&
                        Object.keys(secondHeaderNewData).map(head => {
                          return (
                            <Table.Cell
                              as="td"
                              width={22}
                              className={styles.blockSecondCellTable}
                            >
                              <div className={styles.tableBody}>
                                {head === "binUnits" ? (
                                  item.binUnits ? (
                                    item.binUnits.length > 1 ? (
                                      `${item.binUnits.length} Types`
                                    ) : (
                                      item.binUnits[0]
                                    )
                                  ) : (
                                    "-"
                                  )
                                ) : isFinite(item[head]) ? (
                                  <Numeric
                                    fractionDigits={item[head] > 2000 ? 0 : 2}
                                    value={item[head]}
                                    commaSeparatorOnThousands
                                    defaultValue="-"
                                  />
                                ) : (
                                  "-"
                                )}
                              </div>
                            </Table.Cell>
                          );
                        })}
                    </TableBody>
                  </div>
                </div>
              </Table>
              {item.subRows &&
                item.subRows.length > 0 &&
                item.subRows.map(patch => (
                  <Table
                    basic="very"
                    fixed={true}
                    className={styles.reportTable}
                  >
                    <div className={styles.patchWrapper}>
                      <div className={styles.patchTableHeader}>
                        <Table.Header
                          fullWidth={true}
                          className={styles.patchHeaderTable}
                        >
                          <>
                            <Table.Cell as="td">
                              <div className={styles.tableHeader}>
                                {" "}
                                Bin units
                              </div>
                            </Table.Cell>
                            <Table.Cell
                              as="td"
                              width={parseInt(
                                16 / Object.keys(headerNewData).length
                              )}
                            >
                              <div className={styles.patchTableValue}>
                                {patch.binUnits}
                              </div>
                            </Table.Cell>
                          </>
                        </Table.Header>
                      </div>
                      <Table.Header
                        fullWidth={true}
                        className={styles.patchHeaderTable}
                      >
                        {secondHeaderNewData &&
                          Object.keys(secondHeaderNewData).map(head => {
                            return (
                              <Table.Cell
                                as="td"
                                className={styles.patchSecondHeaderCellTable}
                              >
                                <div className={styles.tableHeader}>
                                  {secondHeaderNewData[`${head}`]}
                                </div>
                              </Table.Cell>
                            );
                          })}
                      </Table.Header>
                      <TableBody className={styles.patchBodyTable}>
                        {secondHeaderNewData &&
                          Object.keys(secondHeaderNewData).map(head => {
                            return (
                              <Table.Cell
                                className={styles.patchSecondCellTable}
                              >
                                <div className={styles.tableBody}>
                                  {head === "binUnits" ? (
                                    patch.binUnits ? (
                                      patch.binUnits.length > 1 ? (
                                        `${patch.binUnits.length} Types`
                                      ) : (
                                        patch.binUnits[0]
                                      )
                                    ) : (
                                      "-"
                                    )
                                  ) : isFinite(patch[head]) ? (
                                    <Numeric
                                      fractionDigits={
                                        patch[head] > 2000 ? 0 : 2
                                      }
                                      value={patch[head]}
                                      commaSeparatorOnThousands
                                      defaultValue="-"
                                    />
                                  ) : (
                                    "-"
                                  )}
                                </div>
                              </Table.Cell>
                            );
                          })}
                      </TableBody>
                    </div>
                  </Table>
                ))}
            </>
          ))
        ) : (
          <Header as="h4">Nothing found</Header>
        )}
      </div>
    );
  }
}

PrintReports.propTypes = {
  title: PropTypes.string,
  data: PropTypes.object.isRequired,
  showExpandedData: PropTypes.bool,
  startDate: PropTypes.any,
  endDate: PropTypes.any
};

export default PrintReports;
