import React from "react";
import PropTypes from "prop-types";
import { Header, Segment } from "semantic-ui-react";
import Layout from "../../components/Layout";

function Blank(props) {
  const { route, location } = props;
  return (
    <Layout route={route} location={location}>
      <Header as="h1">{route.name}</Header>
      <Segment>
        <Header>Coming soon...</Header>
      </Segment>
    </Layout>
  );
}

Blank.propTypes = {
  route: PropTypes.object.isRequired,
  location: PropTypes.object
};

Blank.defaultProps = {
  route: { name: "{%name%}" }
};

export default Blank;
