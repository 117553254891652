import PropTypes from "prop-types";
import React from "react";
import styles from "../../QualityControl/AddEditQCForm/AddEditQCForm.module.css";
import { Form, Input } from "formsy-semantic-ui-react";
import { Button, Grid, Header, Segment } from "semantic-ui-react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  addFormInput,
  handleChange,
  handleFormInputChange,
  removeFormInput
} from "../../../actions/QualityControl/forms";
import SelectEmployee from "../../../components/SelectEmployee";
import minus from "../assets/minus.svg";

const AddEditQCForm = ({
  actions: {
    handleChange,
    handleFormInputChange,
    addFormInput,
    removeFormInput
  },
  qcForm: { name, employeeIds, fields }
}) => {
  const changeValueShortHand = ({ target: { name, value } }) =>
    handleChange(name, value);
  const changeFormValueShortHand = id => ({ name, value }) =>
    handleFormInputChange(id, name, value);

  return (
    <div className={styles.accordionHolder}>
      <Segment>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Form.Field required>
                <label htmlFor="name">Name of checklist</label>
                <Input
                  value={name}
                  required
                  id="name"
                  name="name"
                  onChange={changeValueShortHand}
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Form.Field required>
                <label htmlFor="employeeIds">Select Employees</label>
                <SelectEmployee
                  multiple
                  required={false}
                  id={"employeeIds"}
                  name={"employeeIds"}
                  value={employeeIds}
                  onChange={(_, data) => changeValueShortHand({ target: data })}
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <Segment className={styles.flexColumnCentered}>
        <Header as="h2">Create parameters</Header>
        {fields.map((form, i) => (
          <div className={styles.row} key={`qc_form_field_${i}`}>
            <Input
              name={"label"}
              onChange={(_, data) => changeFormValueShortHand(i)(data)}
              value={form.label}
              style={{ marginRight: 10 }}
            />
            <button
              className={styles.minusButton}
              onClick={e => {
                e.preventDefault();
                removeFormInput(i);
              }}
            >
              <img src={minus} alt="" />
            </button>
          </div>
        ))}
        <div style={{ marginTop: 10 }} />
        <Button
          primary
          onClick={e => {
            e.preventDefault();
            addFormInput();
          }}
        >
          Add new field
        </Button>
      </Segment>
    </div>
  );
};

AddEditQCForm.propTypes = {
  actions: PropTypes.object,
  qcForm: PropTypes.object
};

const mapStateToProps = state => ({
  qcForm: state.qualityControl.qcForm
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      handleChange,
      addFormInput,
      removeFormInput,
      handleFormInputChange
    },
    dispatch
  )
});

AddEditQCForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleFormInputChange: PropTypes.func.isRequired,
  addFormInput: PropTypes.func.isRequired,
  removeFormInput: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditQCForm);
