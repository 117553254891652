export const actionType = {
  FETCH_WEATHER_STATIONS_START: 'WEATHER_STATION/FETCH_START',
  FETCH_WEATHER_STATIONS: 'WEATHER_STATION/FETCH',
  FETCH_WEATHER_STATIONS_FAIL: 'WEATHER_STATION/FETCH_FAIL',

  FETCH_WEATHER_STATIONS_LIST_START: 'WEATHER_STATION/FETCH_LIST_START',
  FETCH_WEATHER_STATIONS_LIST: 'WEATHER_STATION/FETCH_LIST',
  FETCH_WEATHER_STATIONS_LIST_FAIL: 'WEATHER_STATION/FETCH_LIST_FAIL',

  GET_WEATHER_STATION_BY_ID_START: 'WEATHER_STATION/GET_BY_ID_START',
  GET_WEATHER_STATION_BY_ID: 'WEATHER_STATION/GET_BY_ID',
  GET_WEATHER_STATION_BY_ID_FAIL: 'WEATHER_STATION/GET_BY_ID_FAIL',

  ADD_WEATHER_STATION_START: 'WEATHER_STATION/ADD_START',
  ADD_WEATHER_STATION: 'WEATHER_STATION/ADD',
  ADD_WEATHER_STATION_FAIL: 'WEATHER_STATION/ADD_FAIL',

  UPDATE_WEATHER_STATION_START: 'WEATHER_STATION/UPDATE_START',
  UPDATE_WEATHER_STATION: 'WEATHER_STATION/UPDATE',
  UPDATE_WEATHER_STATION_FAIL: 'WEATHER_STATION/UPDATE_FAIL',

  DELETE_WEATHER_STATION_START: 'WEATHER_STATION/DELETE_START',
  DELETE_WEATHER_STATION: 'WEATHER_STATION/DELETE',
  DELETE_WEATHER_STATION_FAIL: 'WEATHER_STATION/DELETE_FAIL',

  WEATHER_STATION_CLEAR_ERROR: 'WEATHER_STATION/CLEAR_ERROR',

  WEATHER_STATION_GET_DATA_BETWEEN_START: 'WEATHER_STATION/GET_DATA_BETWEEN_START',
  WEATHER_STATION_GET_DATA_BETWEEN: 'WEATHER_STATION/GET_DATA_BETWEEN_LIST',
  WEATHER_STATION_GET_DATA_BETWEEN_FAIL: 'WEATHER_STATION/GET_DATA_BETWEEN_FAIL',

  WEATHER_STATION_GET_TASK_WEATHER_CONDITION_START: 'WEATHER_STATION/GET_TASK_WEATHER_CONDITION_START',
  WEATHER_STATION_GET_TASK_WEATHER_CONDITION: 'WEATHER_STATION/GET_TASK_WEATHER_CONDITION',
  WEATHER_STATION_GET_TASK_WEATHER_CONDITION_FAIL: 'WEATHER_STATION/GET_TASK_WEATHER_CONDITION_FAIL',

  WEATHER_STATION_CLEAR_STATE: 'WEATHER_STATION/CLEAR_STATE',

  WEATHER_STATION_SET_STATION_DATA_FIELD: 'WEATHER_STATION/SET_STATION_DATA_FIELD',
};
