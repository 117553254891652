export const sortConfig = {
  sortOptions: [
    {
      key: "employeeName",
      text: "Sort by Employee Name",
      value: "employeeName",
      content: "Employee Name",
      sortfields: ["employeeName"]
    },
    {
      key: "contractor",
      text: "Sort by Contractor",
      value: "contractor",
      content: "Contractor",
      sortfields: ["contractor"]
    },
    {
      key: "workingSeconds",
      text: "Sort by Working time",
      value: "workingSeconds",
      content: "Working time",
      sortfields: ["workingSeconds"]
    },
    {
      key: "currentRate",
      text: "Sort by Rate",
      value: "currentRate",
      content: "Rate",
      sortfields: ["currentRate"]
    },
    {
      key: "totalPay",
      text: "Sort by Total Pay",
      value: "totalPay",
      content: "Total Pay",
      sortfields: ["totalPay"]
    }
  ]
};

export const filtersOptions = [
  {
    id: "date",
    name: "Date",
    type: "DateRangeFilter"
  },
  {
    id: "contractorNames",
    name: "Contractor",
    type: "ContractorMultiSelectFilter",
    valueKey: "name",
    numbers: false
  },
  {
    id: "employeeIds",
    name: "Employee",
    type: "EmployeeMultiSelectFilter"
  },
  {
    id: "seasons",
    name: "Season",
    type: "SeasonSingleSelectFilter"
  }
];
