import PropTypes from "prop-types";
import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Form } from "formsy-semantic-ui-react";
import { fetchAreasList } from "../../actions/Areas/areas";
import { Area } from "../../models/block.model";
import styles from "./SelectArea.module.css";
import { Dropdown } from "semantic-ui-react";

const SelectArea = ({
  date,
  actions: { fetchAreasList },
  areasList,
  farms,
  isFetching,
  error,
  shouldFetch,
  showArchived,
  native,
  filter,
  ...extraProps
}) => {
  useEffect(() => {
    fetchAreasList();
  }, [fetchAreasList]);

  const farmIDs = farms ? (Array.isArray(farms) ? farms : [farms]) : null;

  const farmFilter = farmIDs
    ? area => farmIDs.includes(area.farm.id)
    : () => true;

  const filterArchived = area =>
    showArchived ? true : area.archived === false;

  const getShortText = ({ name, parent }) =>
    (parent && parent.name ? `${parent.name} - ` : "") + name;

  const options = useMemo(
    () =>
      areasList
        .map(area => new Area(area))
        .filter(filterArchived)
        .filter(farmFilter)
        .filter(filter)
        .filter(area => !area.hasSubAreas)
        .sort((item1, item2) => item1.name.localeCompare(item2.name))
        .map(item => {
          const areaVariety = item.areaVarietyByDate(date);
          return {
            key: item.id,
            text: `${item.name} ${item.variety ? item.variety.name : ""}`,
            shortText: getShortText(item),
            value: item.id,
            variety: item.variety && item.variety.name,
            area: item,
            content: (
              <>
                <span>
                  {item.parent && `${item.parent.name} - `}
                  {item.name}
                </span>
                {areaVariety && (
                  <i className={!areaVariety.name ? "vacant" : ""}>
                    {areaVariety.name || "Vacant"}
                  </i>
                )}
              </>
            )
          };
        }),
    [areasList, filter, date, farms]
  );

  useEffect(() => {
    const valueIsArray = Array.isArray(extraProps.value);

    const allowedValues = options.map(({ key }) => key);
    if (valueIsArray) {
      if ((farmIDs || []).length) {
        const values = (extraProps.value || []).filter(value =>
          allowedValues.includes(value)
        );
        extraProps.onChange(null, { value: values, options });
      }
    } else {
      const value = allowedValues.includes(extraProps.value)
        ? extraProps.value
        : null;
      extraProps.onChange(null, { value, options });
    }

    //use effect is really bad with array of arrays
  }, [[farmIDs || []].join(","), options.length]);

  const props = {
    placeholder: "Select area",
    noResultsMessage: "Nothing was found",
    fluid: true,
    ...extraProps,
    error,
    closeOnBlur: true,
    search: true,
    loading: isFetching,
    selection: true,
    renderLabel: item => item.shortText,
    options,
    className: `${styles.areasDropdown} ${
      extraProps.className ? extraProps.className : ""
    }`
  };

  return native ? <Dropdown {...props} /> : <Form.Dropdown {...props} />;
};

SelectArea.propTypes = {
  actions: PropTypes.object.isRequired,
  areasList: PropTypes.array.isRequired,
  className: PropTypes.any,
  date: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  farms: PropTypes.number,
  filter: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  native: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  shouldFetch: PropTypes.bool,
  showArchived: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.number])
};

SelectArea.defaultProps = {
  areasList: [],
  disabled: false,
  error: false,
  farms: null,
  filter: () => true,
  isFetching: false,
  native: false,
  onChange: () => {},
  showArchived: false,
  value: []
};

const mapStateToProps = ({
  areas: {
    list: { content, isFetching }
  }
}) => {
  return {
    areasList: content,
    isFetching
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchAreasList
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectArea);
